import config from "./APIConfig"
import { postDataApi } from '../index'

export const resetUserProfileImage = (data) => {
  const { url } = config.resetUserProfileImage
  const payload = {
    contactId: data.contactId
    }
 
  return postDataApi(url, payload)
}

export default resetUserProfileImage
