export const CREATE_NEW_AUDIT_TRAIL_REQUEST = 'CREATE_NEW_AUDIT_TRAIL_REQUEST'
export const GET_AUDIT_TRAIL_MODAL_DATA = "GET_AUDIT_TRAIL_MODAL_DATA"
export const DELETE_AUDIT_TRAIL_REQUEST = "DELETE_AUDIT_TRAIL_REQUEST"
export const UPDATE_AUDIT_TRAIL_REQUEST = "UPDATE_AUDIT_TRAIL_REQUEST"
export const CREATE_AUDIT_TRAIL_REQUEST = 'CREATE_AUDIT_TRAIL_REQUEST'
export const CREATE_ASSIGNMENT_NOTE_REQUEST = 'CREATE_ASSIGNMENT_NOTE_REQUEST'


/****************************************************/
/** CREATE_NEW_AUDIT_TRAIL_REQUEST **/
/****************************************************/

/**
 * @method createNewAuditTrailRequest :  To create new audit trail
 *
 */
export const createNewAuditTrailRequest = (payload, callback) => {
  return {
    type: CREATE_NEW_AUDIT_TRAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_AUDIT_TRAIL_MODAL_DATA **/
/****************************************************/

/**
 * @method getAuditTrailModalData : to get audit trail modal data
 */

export const getAuditTrailModalData = (payload, callback) => ({
  type: GET_AUDIT_TRAIL_MODAL_DATA,
  payload,
  callback
})

/****************************************************/
/** DELETE_AUDIT_TRAIL_REQUEST **/
/****************************************************/

/**
 * @method deleteAuditTrailRequest : to delete audit trail modal
 */

export const deleteAuditTrailRequest = (payload, callback) => ({
  type: DELETE_AUDIT_TRAIL_REQUEST,
  payload,
  callback
})

/****************************************************/
/** UPDATE_AUDIT_TRAIL_REQUEST **/
/****************************************************/

/**
 * @method updateAuditTrailRequest :  To update audit trail
 *
 */
export const updateAuditTrailRequest = (payload, callback) => {
  return {
    type: UPDATE_AUDIT_TRAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_AUDIT_TRAIL_REQUEST **/
/****************************************************/

/**
 * @method createAuditTrailRequest :  To create audit trail
 *
 */
export const createAuditTrailRequest = (payload, callback) => {
  return {
    type: CREATE_AUDIT_TRAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_ASSIGNMENT_NOTE_REQUEST **/
/****************************************************/

/**
 * @method createAssignmentNoteRequest :  To create assignment note
 *
 */
export const createAssignmentNoteRequest = (payload, callback) => {
  return {
    type: CREATE_ASSIGNMENT_NOTE_REQUEST,
    payload,
    callback
  }
}
