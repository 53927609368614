import config from "./APIConfig"
import { postDataApi } from '../index'

export const uploadInspectionFile = (data) => {
    const { url } = config.uploadInspectionFile

    const queryPayload = {
        assignmentId: data.assignmentId,
        assignmentActivityId: data.assignmentActivityId,
        responseId: data.responseId,
        fileCategoryId: data.fileCategoryId
    }

    const payload = new FormData()
    payload.append("UploadInspectionFile.InspectionFile", data.file, data.name)

    return postDataApi(url(queryPayload), payload, {
        'Content-Type': 'multipart/form-data'
    })
}

export default uploadInspectionFile