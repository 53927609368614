import config from "./APIConfig"
import { postDataApi } from '../index'

export const addOrganizationDetail = (data) => {
  const { url } = config.addOrganizationDetail
  const payload = {
    entityId: data.entityId,
    organizationName: data.organizationName,
    organizationTypeId: data.organizationTypeId,
    organizationCategoryId: data.organizationCategoryId
  }
  if (data.organizationParentId) {
    payload.organizationParentId = data.organizationParentId
  }
  if (data.externalId) {
    payload.externalId = data.externalId
  }
  return postDataApi(url, payload)
}

export default addOrganizationDetail
