import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateCheckpointTraining
 * @param {object} data 
 *
 */
export const updateCheckpointTraining = (data) => {
  const { checkpointId, checkpointVersionId, checkpointTrainingId, trainingId, requiredLesson, isPrimary, isDeleted } = data

  const { url } = config.updateCheckpointTraining

  const payload = {
    checkpointId: checkpointId || 0,
    checkpointVersionId: checkpointVersionId || 1,
    trainingContentDtos: [
      {
        checkpointTrainingId: checkpointTrainingId || 0,
        trainingId: trainingId || 0,
        requiredLesson: requiredLesson || false,
        isPrimary: isPrimary || false,
        isDeleted: isDeleted || false
      }
    ]
  }

  return putDataApi(url({ checkpointId, checkpointVersionId }), payload)
}

export default updateCheckpointTraining
