import config from "./APIConfig"
import { deleteDataApi } from '../index'

export const deleteAdditionalPurchaseOrder = (data) => {
  const { url } = config.deleteAdditionalPurchaseOrder
  return deleteDataApi(url({ additionalPurchaseOrderId: data.additionalPurchaseOrderId }), null, data)
}

export const deleteAssignedTask = (idList = []) => {
  const { url } = config.deleteAssignedTask
  return deleteDataApi(url, null, idList)
}
