import cx from "classnames"
import DatePicker from "react-datepicker"
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState, useCallback } from "react"
import { useForm } from "react-hook-form"
import { ChevronLeft, ChevronRight } from 'react-feather'

import { getLocaleMessage, isToday } from "@utils"
import { ReactSelect } from "@views/components"
import { months, years } from "./helper"
import localeMessageWrapper from '../locale-message'
import '../../../../node_modules/react-datepicker/dist/react-datepicker.css'

const DatePickerComponent = ({ id, intl, isDisabled = false, className = "", placeholder, value, onChange, changeRawRequired = true, onChangeRaw = () => { }, minDate, isClearable = false, ...props }) => {

  const { control } = useForm({})
  const [yearOptions, setYearOptions] = useState([])
  const [monthOptions, setMonthOptions] = useState([])

  useEffect(() => {
    const yoptions = years.map(year => ({
      text: year,
      value: year
    }))
    const moptions = months.map(month => ({
      text: month,
      value: month
    }))
    setYearOptions(yoptions)
    setMonthOptions(moptions)
  }, [])

  const highlightDatesProps = useMemo(() => {
    if (!!value && isToday(new Date(value))) {
      return {}
    } else if (isToday(new Date())) {
      return {
        highlightDates: [new Date()]
      }
    }

    return {}
  }, [value])

  const handleChangeRaw = useCallback((e) => {
    e.preventDefault()
    onChangeRaw(e)
  }, [onChangeRaw])

  return (<DatePicker
    renderCustomHeader={({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled
    }) => (
      <div className="calender-head" >
        <button onClick={(e) => {
          e.preventDefault()
          decreaseMonth()
        }} disabled={prevMonthButtonDisabled}>

          <ChevronLeft />
        </button>
        <div className="date-select">
          <ReactSelect
            id='year_select'
            name='year_select'
            isInitialSelectOptionRequired={false}
            control={control}
            value={date.getFullYear()}
            options={yearOptions}
            onSelect={({ value }) => changeYear(value)}
            getOptionLabel={(item) => item.text}
            getOptionValue={(item) => item.value}
          />
          <ReactSelect
            id='month_select'
            name='month_select'
            isInitialSelectOptionRequired={false}
            control={control}
            value={months[date.getMonth()]}
            options={monthOptions}
            onSelect={({ value }) => {
              const month = months.indexOf(value)
              return changeMonth(month || 0)
            }}
            getOptionLabel={(item) => item.text}
            getOptionValue={(item) => item.value}
          />
        </div>
        <button onClick={(e) => {
          e.preventDefault()
          increaseMonth()
        }} disabled={nextMonthButtonDisabled}>

          <ChevronRight />
        </button>
      </div>
    )
    }
    id={id}
    disabled={isDisabled}
    placeholderText={getLocaleMessage(intl, placeholder)}
    selected={value ? new Date(value) : ''}
    onChange={onChange}
    onChangeRaw={changeRawRequired ? handleChangeRaw : ""}
    dateFormat="MMM dd, yyyy"
    className={`applied-date datepicker-show form-control form-group ${className}`}
    calendarClassName={cx({ "date-picker-input": !!highlightDatesProps.highlightDates?.length })}
    popperPlacement="auto"
    minDate={minDate}
    isClearable={isClearable}
    disabledKeyboardNavigation
    {...highlightDatesProps}
    {...props}
  />
  )
}

DatePickerComponent.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ])
}

export default localeMessageWrapper(DatePickerComponent)
