// ** Styles
import { useCallback, useState } from 'react'
import { bindActionCreators } from 'redux'
import { Edit3, MoreVertical } from 'react-feather'
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap'
import { connect } from 'react-redux'

// ** Custom Components
import { isPermissionDenied } from '@router/Helper'
import { CAMPAIGN_SERVICE_MODE, PERMISSION_ROUTES, TABLE_LIST_KEYS } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from '@store/actions'
import { getLocaleMessage } from "@utils"
import { DeleteConfirmationModal } from '@views/components'
import localeMessageWrapper from '@views/components/locale-message'

const { TABLE, SERVICES } = LANGUAGE_CONSTANTS

const RowActions = ({
  intl,
  data,
  deleteService,
  getServiceDetail,
  setServiceMode,
  refreshTableList
}) => {
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false)

  const setServiceDetail = useCallback((mode = CAMPAIGN_SERVICE_MODE.EDIT) => {
    getServiceDetail(data.campaignServiceId, (detail) => {
      setServiceMode({ mode, detail })
    })
  })

  const handleToggleDeleteService = useCallback((event) => {
    if (event) {
      event.preventDefault()
    }
    setOpenDeleteModal(!isOpenDeleteModal)
  }, [isOpenDeleteModal])

  const handleDeleteService = useCallback(() => {
    deleteService(data.campaignServiceId, () => {
      refreshTableList({ listKey: TABLE_LIST_KEYS.SERVICE_LIST_KEY, value: true })
    })
    handleToggleDeleteService()
  }, [data, handleToggleDeleteService])

  const handleEditService = useCallback((event) => {
    event.preventDefault()
    setServiceDetail()
  }, [data, setServiceDetail])

  return (
    <>
      <div className='d-flex'>
        {!isPermissionDenied(PERMISSION_ROUTES.updateService) && <a>
          <Edit3 size={15} onClick={handleEditService} />
        </a>}
        <UncontrolledDropdown>
          <DropdownToggle className='px-1' tag='span'>
            <MoreVertical size={15} />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag='a' className='w-100' onClick={(e) => {
              e.preventDefault()
              setServiceDetail(CAMPAIGN_SERVICE_MODE.VIEW)
            }}>
              <span className='align-middle ml-50'>{getLocaleMessage(intl, TABLE.VIEW)}</span>
            </DropdownItem>
            {!isPermissionDenied(PERMISSION_ROUTES.updateService) && <DropdownItem tag='a' className='w-100' onClick={handleEditService}>
              <span className='align-middle ml-50'>{getLocaleMessage(intl, TABLE.EDIT)}</span>
            </DropdownItem>}
            {!isPermissionDenied(PERMISSION_ROUTES.deleteService) && <DropdownItem tag='a' href='/' className='w-100' onClick={handleToggleDeleteService}>
              <span className='align-middle ml-50 text-danger'>{getLocaleMessage(intl, TABLE.DELETE)}</span>
            </DropdownItem>}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <DeleteConfirmationModal
        id={data.campaignServiceId}
        open={isOpenDeleteModal}
        onToggleModal={handleToggleDeleteService}
        onConfirmDeleteRecord={(e) => handleDeleteService(e, data.campaignServiceId)}
        type={SERVICES.SERVICE_CAMELCASE}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      deleteService: actions.deleteCampaignServiceRequest,
      getServiceDetail: actions.getCampaignServiceDetail,
      setServiceMode: actions.setCampaignServiceMode,
      refreshTableList: actions.refreshTableList
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(localeMessageWrapper(RowActions))
