import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Row,
  Col,
  Button,
  Input
} from 'reactstrap'

import * as actions from '@store/actions'
import {
  CAMPAIGN_SERVICE_MODE,
  CAMPAIGN_SCHEDULE_DRIVER,
  ACTIVITY_TYPE,
  ACTIVITY_TYPE_NAME,
  TABLE_LIST_KEYS
} from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { closeSVGIcon } from "@src/assets/images/new-theme"
import { getLocaleMessage } from "@utils"
import { ErrorMessage, ReactSelect, ProjectSiteSearch, ToggleButton, CustomLabel } from '@views/components'
import localeMessageWrapper from '@views/components/locale-message'
import { validateServiceDetails, getCompletionDaysOptions } from "./helpers"
import SchedulerDriver from "./SchedulerDriver"

const { SERVICES, ADVANCED_SEARCH, TABLE, ADD, CLEAR, SEARCH, CANCEL, UPDATE } = LANGUAGE_CONSTANTS

const initialServiceDetailVMState = {
  campaignSchedulerDriver: [],
  campaignServiceType: [],
  campaignTimeIncrementType: [],
  campaignTaskCreationFor: [],
  sites: {}
}

const initialServiceState = {
  campaignServiceName: '',
  serviceId: 0,
  schedulerDriverId: 0,
  timeIncrementTypeId: 0,
  taskCreationId: 0,
  siteIds: [],
  eventTypeId: 0,
  saturationRate: '',
  timeIncrement: 0,
  selfSchedule: true,
  completionAfterReady: '',
  startIntervalOn: new Date()
}

const ServiceDetails = ({
  intl,
  searchDetail = initialServiceState,
  isOpenAdvanceSearch = false,
  campaignId,
  campaignDetail = {},
  campaignType,
  isModal = false,
  isSearchMode = false,
  campaignServiceStore: { detail: modalServiceDetail, mode },
  getServiceDetailVM,
  addServiceDetail,
  updateService,
  setServiceMode,
  refreshTableList,
  onAddCampaignToggle = () => { },
  onChangeSearch = () => { },
  onToggleAdvanceSearch = () => { },
  onSearch = () => { },
  onClose = () => { },
  onResetAdvanceSearch = () => { },
  onFetchServiceList = () => { },
  onCustomAdvanceSearch = () => { }
}) => {
  const { control } = useForm({})

  const [serviceDetailVM, setServiceDetailVM] = useState(Object.assign({}, initialServiceDetailVMState))
  const [errors, setErrors] = useState({})
  const [serviceDetail, setServiceDetail] = useState(isSearchMode ? searchDetail : initialServiceState)
  const [completionDaysOptions] = useState(getCompletionDaysOptions())

  const serviceCampaignId = useMemo(() => { return campaignId || modalServiceDetail?.campaignId || 0 }, [campaignId, modalServiceDetail])
  const isEditMode = useMemo(() => {
    return mode === CAMPAIGN_SERVICE_MODE.EDIT
  }, [mode])
  const isViewMode = useMemo(() => {
    return mode === CAMPAIGN_SERVICE_MODE.VIEW
  }, [mode])
  const filterCampaignDriver = useMemo(() => {
    return serviceDetailVM.campaignSchedulerDriver.filter(x => x.value !== CAMPAIGN_SCHEDULE_DRIVER.EVENT_INTERVAL)
  }, [serviceDetailVM])

  const handleResetSearchDetail = useCallback(() => {
    const updatedState = !isSearchMode ? initialServiceState : { ...initialServiceState, selfSchedule: false }
    setServiceDetail(updatedState)
    onChangeSearch(updatedState)
    onResetAdvanceSearch()
  }, [isSearchMode, onChangeSearch, onResetAdvanceSearch])

  const handleToggleAdvanceSearch = useCallback(
    () => {
      onToggleAdvanceSearch(false)
    },
    [onToggleAdvanceSearch]
  )

  const handleChangeDetails = useCallback(
    (key, value, callback = () => { }) => {
      const updateServiceDetail = Object.assign({}, serviceDetail)
      if (key === 'siteIds') {
        let data = value
        const lastValue = value?.length ? value[value.length - 1] : {}
        if (lastValue.value === '-1') {
          data = [lastValue]
        } else {
          data = value.filter(item => item.value !== '-1')
        }
        updateServiceDetail[key] = data
      } else {
        updateServiceDetail[key] = value
      }
      setServiceDetail(updateServiceDetail)
      onChangeSearch(updateServiceDetail)
      callback(updateServiceDetail)
      setErrors({})
    }, [serviceDetail, onChangeSearch]
  )

  const handleRefreshServiceList = useCallback(() => {
    refreshTableList({ listKey: TABLE_LIST_KEYS.SERVICE_LIST_KEY, value: true })
  }, [])

  useEffect(() => {
    if ((isEditMode || isViewMode) && modalServiceDetail && mode) {
      const {
        campaignServiceName,
        campaignServiceTypeId,
        campaignSchedulerDriverId,
        campaignTimeIncrementTypeId,
        saturationRate,
        timeIncrement,
        campaignEventTypeId,
        campaignTaskCreationForId,
        siteIds = [],
        selfSchedule = true,
        completionAfterReady,
        startIntervalOn
      } = modalServiceDetail
      setServiceDetail({
        campaignServiceName: campaignServiceName || '',
        serviceId: campaignServiceTypeId ? String(campaignServiceTypeId) : 0,
        schedulerDriverId: campaignSchedulerDriverId ? String(campaignSchedulerDriverId) : 0,
        timeIncrementTypeId: campaignTimeIncrementTypeId ? String(campaignTimeIncrementTypeId || '') : 0,
        saturationRate: saturationRate || 0,
        timeIncrement: timeIncrement || 0,
        selfSchedule,
        completionAfterReady: completionAfterReady || '',
        startIntervalOn: startIntervalOn || new Date(),
        eventTypeId: campaignEventTypeId ? String(campaignEventTypeId || '') : 0,
        taskCreationId: campaignTaskCreationForId ? String(campaignTaskCreationForId || '') : 0,
        siteIds: siteIds.map(site => ({ text: site.siteName, value: site.siteId.toString() }))
      })
    }
  }, [])

  useEffect(() => {
    // if (campaignDetail.projectIds) {
    //   getServiceDetailVM(campaignDetail.projectIds)
    // }
    //Todo: Is this VM based on project id or not?
    getServiceDetailVM('', (data) => {
      if (data) {
        setServiceDetailVM(data)
      }
    })
  }, [])
  // }, [campaignDetail.projectIds])

  const handleAddService = useCallback(() => {
    if (serviceCampaignId) {
      const errors = validateServiceDetails(serviceDetail)
      if (!!Object.keys(errors).length) {
        setErrors(errors)
        return true
      }

      const {
        campaignServiceName,
        serviceId,
        schedulerDriverId,
        saturationRate,
        timeIncrement,
        timeIncrementTypeId,
        eventTypeId,
        taskCreationId,
        completionAfterReady,
        startIntervalOn,
        selfSchedule,
        siteIds
      } = serviceDetail

      const payload = {
        campaignId: parseInt(serviceCampaignId),
        campaignServiceName,
        campaignServiceTypeId: parseInt(serviceId),
        campaignSchedulerDriverId: parseInt(schedulerDriverId),
        campaignTaskCreationForId: parseInt(taskCreationId),
        siteIds: siteIds.map(site => ({ siteId: site.value })),
        selfSchedule,
        completionAfterReady
      }
      switch (schedulerDriverId) {
        case '1':
          payload.saturationRate = Number(saturationRate) || 0
          break
        case '2':
          payload.timeIncrement = timeIncrement
          payload.campaignTimeIncrementTypeId = parseInt(timeIncrementTypeId)
          payload.startIntervalOn = startIntervalOn
          break
        case '3':
          payload.campaignEventTypeId = parseInt(eventTypeId)
          break
      }
      if (isEditMode) {
        payload.campaignServiceId = modalServiceDetail.campaignServiceId
        updateService(payload, (result) => {
          if (result) {
            if (isSearchMode) {
              onFetchServiceList({})
            } else {
              handleRefreshServiceList()
            }
            setServiceMode()
          }
        })
      } else {
        addServiceDetail(payload, (result) => {
          if (result) {
            handleResetSearchDetail()
            if (isSearchMode) {
              onFetchServiceList({})
            } else {
              handleRefreshServiceList()
            }
            onAddCampaignToggle()
          }
        })
      }
    }
  }, [
    isSearchMode,
    serviceDetail,
    isEditMode,
    modalServiceDetail,
    serviceCampaignId,
    handleResetSearchDetail,
    handleRefreshServiceList,
    onFetchServiceList
  ])

  const isDisabledAddServiceBtn = useMemo(() => {
    const { serviceId,
      schedulerDriverId,
      saturationRate,
      timeIncrement,
      timeIncrementTypeId,
      eventTypeId,
      siteIds,
      campaignServiceName,
      taskCreationId,
      completionAfterReady
    } = serviceDetail

    let isDisabled = !(
      serviceId &&
      schedulerDriverId &&
      taskCreationId &&
      siteIds.length &&
      campaignServiceName.trim() &&
      completionAfterReady
    )

    switch (schedulerDriverId) {
      case '1':
        if (!saturationRate) {
          isDisabled = true
        }
        break
      case '2':
        if (!timeIncrement || !timeIncrementTypeId) {
          isDisabled = true
        }
        break
      case '3':
        if (!eventTypeId) {
          isDisabled = true
        }
        break
    }

    return isDisabled
  }, [serviceDetail])

  const isObservation = useMemo(() => {
    if (campaignDetail.campaignTypeName) {
      return campaignDetail.campaignTypeName !== ACTIVITY_TYPE_NAME.TRAINING
    } else {
      return String(campaignDetail.campaignTypeId) !== String(ACTIVITY_TYPE.TRAINING)
    }
  }, [campaignDetail.campaignTypeId, campaignDetail.campaignTypeName])

  return (
    <Card>
      <CardBody>
        {isOpenAdvanceSearch ? <CardHeader>
          <CardTitle tag='p'>{getLocaleMessage(intl, ADVANCED_SEARCH)}</CardTitle>
          <button
            type='button'
            className='waves-effect btn advanced-btn '
            onClick={handleToggleAdvanceSearch}>
            <img src={closeSVGIcon} alt="close" />
          </button>
        </CardHeader> : <>
          <CardHeader className='px-0'>
            <CardTitle tag='p'>{isEditMode ? getLocaleMessage(intl, TABLE.EDIT) : isViewMode ? getLocaleMessage(intl, TABLE.VIEW) : getLocaleMessage(intl, ADD)}{isObservation ? ` ${getLocaleMessage(intl, SERVICES.SERVICE_CAMELCASE)}` : ` ${getLocaleMessage(intl, SERVICES.COURSE_CAMELCASE)}`}</CardTitle>
          </CardHeader>
          <p>{getLocaleMessage(intl, SERVICES.CAMPAIGN_OBSERVATIONS_SERVICE_TEXT)}</p>
        </>}
        <Form>
          <Row form>
            <Col className='mb-1' sm={12} md={6} xl={4}>
              <FormGroup>
                <CustomLabel title={isObservation ? SERVICES.SERVICE_NAME : SERVICES.COURSE_NAME} required={!isSearchMode} />
                <Input
                  type='text'
                  name='campaignServiceName'
                  placeholder={isObservation ? getLocaleMessage(intl, SERVICES.SERVICE_NAME) : getLocaleMessage(intl, SERVICES.COURSE_NAME)}
                  value={serviceDetail.campaignServiceName}
                  onChange={(event) => handleChangeDetails('campaignServiceName', event.target.value)}
                  disabled={isViewMode}
                  onKeyDown={onCustomAdvanceSearch}
                />
              </FormGroup>
              <ErrorMessage
                isShow={!!errors['campaignServiceName']}
                message={errors['campaignServiceName']}
              />
            </Col>
            <Col className='mb-1' sm={12} md={6} xl={4}>
              <ReactSelect
                id='service'
                name='service'
                label={isObservation ? getLocaleMessage(intl, SERVICES.SERVICE_TYPE) : getLocaleMessage(intl, SERVICES.COURSE_TYPE)}
                control={control}
                isRequired={!isOpenAdvanceSearch}
                value={serviceDetail.serviceId}
                options={serviceDetailVM.campaignServiceType}
                onSelect={(selected) => handleChangeDetails('serviceId', selected?.value || '', (data) => { onCustomAdvanceSearch(null, true, data) })}
                getOptionLabel={(item) => item.text}
                getOptionValue={(item) => item.value}
                placeHolder={isObservation ? getLocaleMessage(intl, SERVICES.SERVICE_TYPE) : getLocaleMessage(intl, SERVICES.COURSE_TYPE)}
                isDisabled={isViewMode}
              />
              <ErrorMessage
                isShow={!!errors['serviceId']}
                message={errors['serviceId']}
              />
            </Col>
            <ProjectSiteSearch
              projectId={campaignDetail.projectIds}
              errors={errors}
              defaultOptions={serviceDetailVM.sites.items || []}
              value={serviceDetail.siteIds}
              isRequired={!isOpenAdvanceSearch}
              onSelect={(id, value) => handleChangeDetails(id, value, (data) => { onCustomAdvanceSearch(null, true, data) })}
              isShowCustomAction={false}
              isDisabled={isViewMode}
              isEditMode={isEditMode}
            />
            <Col className='mb-1' sm={12} md={6} xl={4}>
              <ReactSelect
                id='task_creation_for'
                name='task_creation_for'
                label={getLocaleMessage(intl, SERVICES.CREATE_TASKS_BY)}
                control={control}
                isRequired={!isOpenAdvanceSearch}
                value={serviceDetail.taskCreationId}
                options={serviceDetailVM.campaignTaskCreationFor}
                onSelect={(selected) => handleChangeDetails('taskCreationId', selected?.value || '', (data) => { onCustomAdvanceSearch(null, true, data) })}
                getOptionLabel={(item) => item.text}
                getOptionValue={(item) => item.value}
                placeHolder={getLocaleMessage(intl, SERVICES.SELECT_TASK)}
                isDisabled={isViewMode}
              />
              <ErrorMessage
                isShow={!!errors['taskCreationId']}
                message={errors['taskCreationId']}
              />
            </Col>
            <SchedulerDriver
              errors={errors}
              control={control}
              isRequired={!isOpenAdvanceSearch}
              serviceDetail={serviceDetail}
              campaignEventType={serviceDetailVM.campaignEventType}
              campaignTimeIncrementType={serviceDetailVM.campaignTimeIncrementType}
              campaignSchedulerDriver={filterCampaignDriver}
              isViewMode={isViewMode}
              onChangeServiceDetails={handleChangeDetails}
              onCustomAdvanceSearch={onCustomAdvanceSearch}
            />
            <Col className='mb-1' sm={12} md={6} xl={4}>
              <FormGroup>
                <CustomLabel title={SERVICES.TIMEFRAME_TO_COMPLETE} required={!isSearchMode} data-tip data-for='after_ready' />
                <ReactTooltip id="after_ready">
                  <span>{getLocaleMessage(intl, SERVICES.AFTER_MARKED_READY)}</span>
                </ReactTooltip>
                <Input
                  type='number'
                  name='completion_after_ready'
                  placeholder={getLocaleMessage(intl, SERVICES.TIMEFRAME_TO_COMPLETE)}
                  value={serviceDetail.completionAfterReady}
                  onChange={(event) => handleChangeDetails('completionAfterReady', event.target.value)}
                  disabled={isViewMode}
                  onKeyDown={onCustomAdvanceSearch}
                />
              </FormGroup>
            </Col>
          </Row >
          <Row form>
            <Col>
              <FormGroup>
                <ToggleButton
                  className="custom-control-secondary mb-1"
                  descriptionText={getLocaleMessage(intl, SERVICES.ALLOW_SELF_SCHEDULING)}
                  id={isModal ? "isEditSelfSchedule" : "selfSchedule"}
                  name={isModal ? "isEditSelfSchedule" : "selfSchedule"}
                  value={serviceDetail.selfSchedule}
                  isDisabled={isViewMode}
                  onChange={(value) => handleChangeDetails("selfSchedule", value)}
                />
              </FormGroup>
            </Col>
          </Row>
          {isOpenAdvanceSearch ? <div className="text-right col-12">
            <Button
              outline
              onClick={handleResetSearchDetail}
              className={classnames("delete-button mr-1")}
            >
              {getLocaleMessage(intl, CLEAR)}
            </Button>
            <Button className="common-button" onClick={() => {
              onSearch(serviceDetail)
            }}>
              {getLocaleMessage(intl, SEARCH)}
            </Button>
          </div> : <div className="d-flex text-right justify-content-md-end justify-content-center mb-0 ">
            {isModal && <Button
              outline
              onClick={(e) => {
                e.preventDefault()
                onClose()
              }}
              className={classnames("delete-button mr-1")}
            >
              {getLocaleMessage(intl, CANCEL)}
            </Button>}
            {(!isViewMode) && <Button
              className="common-button"
              onClick={(e) => {
                e.preventDefault()
                if (isViewMode) {
                  setServiceMode()
                } else {
                  handleAddService()
                }
              }}
              disabled={isViewMode ? false : isDisabledAddServiceBtn}
            >
              {isEditMode ? getLocaleMessage(intl, UPDATE) : isViewMode ? getLocaleMessage(intl, TABLE.VIEW) : getLocaleMessage(intl, ADD)} {!isViewMode && getLocaleMessage(intl, SERVICES.SERVICE_CAMELCASE)}
            </Button>}
          </div>}
        </Form >
      </CardBody>
    </Card >
  )
}

const mapStateToProps = (state) => {
  return {
    campaignServiceStore: state.campaignService
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceDetailVM: (payload, callback) => dispatch(actions.getServiceDetailVMRequest(payload, callback)),
    addServiceDetail: (payload, callback) => dispatch(actions.addCampaignServiceDetailRequest(payload, callback)),
    updateService: (payload, callback) => dispatch(actions.updateCampaignService(payload, callback)),
    setServiceMode: (payload) => dispatch(actions.setCampaignServiceMode(payload)),
    refreshTableList: payload => dispatch(actions.refreshTableList(payload))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(localeMessageWrapper(ServiceDetails)))
