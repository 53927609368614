import React from 'react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { REACT_APP_MONITORING_INSIGHTS } from '@app-settings'

const AppInsights = (Component) => (props) => {
  const useAppInsights = useAppInsightsContext()

  const handleTriggerInsightEvent = (method = () => { }, data) => {
    if (!REACT_APP_MONITORING_INSIGHTS) return
    if (!!data && useAppInsights) return useAppInsights[method](data)
  }

  return <Component onTriggerInsightEvent={handleTriggerInsightEvent} {...props} />
}

export default AppInsights