
const apiConfig = {
  getDataRetentionVM: {
    method: "GET",
    url: "api/Configuration/Settings/DataRetention/VM"
  },
  getCustomDataRetentionList: {
    method: "GET",
    url: "api/Configuration/Settings/CustomDataRetention"
  },
  addCustomDataRetention: {
    method: "POST",
    url: "api/Configuration/Settings/AddCustomDataRetention"
  },
  updateCustomDataRetention: {
    method: "PUT",
    url: (dataRetentionPolicyId) => `api/Configuration/Settings/UpdateCustomDataRetention/${dataRetentionPolicyId}`
  },
  deleteCustomDataRetention: {
    method: "DELETE",
    url: (dataRetentionPolicyId) => `api/Configuration/Settings/DeleteCustomDataRetention/${dataRetentionPolicyId}`
  },
  getCustomDataRetentionPolicyDetails: {
    method: "GET",
    url: (dataRetentionPolicyId) => `api/Configuration/Settings/Policy/${dataRetentionPolicyId}`
  }
}

export default apiConfig
