import cx from 'classnames'
import ReactTooltip from "react-tooltip"
import Avatar from '@components/avatar'
import PropTypes from 'prop-types'

const ALLOW_ROW_EVENT_KEY = "___react-data-table-allow-propagation___"
const cellStyle = {
  // width: "fit-content"
}

const TableCellToolTip = ({
  className,
  colNo,
  iconUrl,
  id,
  showIcon,
  suffixOfId,
  toolTipConfig,
  value,
  textClass,
  truncate
}) => (
  <>
    <div
      className='w-100'
      id={`${id}${suffixOfId}`}
      data-tip={value}
      data-tag={ALLOW_ROW_EVENT_KEY}
      data-for={`${id}${suffixOfId}_for`}
    >
      {!!colNo ? (
        <div
          className="d-flex align-items-center"
          data-tag={ALLOW_ROW_EVENT_KEY}
        >
          {showIcon && (
            iconUrl ? (
              <img src={iconUrl} className="contact-list-img" />
            ) : (
              <Avatar color={`light-success`} content={value} initials />
            )
          )
          }
          <div
            className={cx(`project-info ${truncate && 'text-truncate tooltip-text-truncate'} ml-0`, className)}
            data-tag={ALLOW_ROW_EVENT_KEY}
            style={cellStyle}
          >
            <span
              className={`d-block font-weight-bold ${truncate && 'text-truncate tooltip-text-truncate'} ${textClass}`}
              data-tag={ALLOW_ROW_EVENT_KEY}
              style={cellStyle}
            >
              {value}
            </span>
          </div>
        </div>
      ) : (
        <div
          className={cx(`table-tooltip-cell ml-0`, className, {
            'text-truncate tooltip-text-truncate': truncate
          })}
          data-tag={ALLOW_ROW_EVENT_KEY}
          style={cellStyle}
        >
          <span
            className={`d-block font-weight-bold ${truncate && 'text-truncate tooltip-text-truncate'} ${textClass}`}
            data-tag={ALLOW_ROW_EVENT_KEY}
            style={cellStyle}
          >
            {value}
          </span>
        </div>
      )}
    </div>
    <ReactTooltip
      id={`${id}${suffixOfId}_for`}
      place="bottom"
      type="dark"
      effect="solid"
      {...toolTipConfig}
      className="tooltip-text-wrap"
    />
  </>
)

TableCellToolTip.propTypes = {
  id: PropTypes.number,
  className: PropTypes.string,
  colNo: PropTypes.number,
  iconUrl: PropTypes.string,
  showIcon: PropTypes.bool,
  suffixOfId: PropTypes.string,
  toolTipConfig: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  textClass: PropTypes.string,
  truncate: PropTypes.bool
}

TableCellToolTip.defaultProps = {
  className: "",
  colNo: 0,
  iconUrl: "",
  showIcon: false,
  toolTipConfig: {},
  textClass: '',
  truncate: true
}

export default TableCellToolTip
