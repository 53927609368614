import { takeLatest, call, put } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

const { BUILD_WORKFLOW_SAGA, CAMPAIGN_WORKFLOW_SAGA, WORKFLOW_FINISH_SETUP_SAGA } = LANGUAGE_CONSTANTS

/** createCampaignWorkflow API */
function* createCampaignWorkflowRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.createCampaignWorkflow, action.payload)
    if (response?.data) {
      action.callback(response.data)
      Notification({ status: 200, message: BUILD_WORKFLOW_SAGA.ADDING_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: BUILD_WORKFLOW_SAGA.ERROR_IN_ADDING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** updateCampaignWorkflow API */
function* updateCampaignWorkflowRequest(action) {
  try {
    const response = yield call(services.updateCampaignWorkflow, action.payload)
    if (response?.data?.status) {
      action.callback(true)
      Notification({ status: 200, message: BUILD_WORKFLOW_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: BUILD_WORKFLOW_SAGA.ERROR_IN_UPDAING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** addCampaignWorkflow API **/
function* addCampaignWorkflowVMRequest(action) {
  try {
    const response = yield call(services.addCampaignWorkflowVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** deleteCampaignWorkflow API **/
function* deleteCampaignWorkflowRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteCampaignWorkflow, action.payload)
    if (response?.data?.status) {
      action.callback(true)
      Notification({ status: 200, message: CAMPAIGN_WORKFLOW_SAGA.DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: CAMPAIGN_WORKFLOW_SAGA.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** getCampaignWorkflow API **/
function* getCampaignWorkflowRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getCampaignWorkflow, action.payload)
    if (!response?.data) {
      Notification({ status: 400, message: CAMPAIGN_WORKFLOW_SAGA.ERROR_IN_GETTING_CAMPAIGN_WORKFLOW_DETAILS })
    }
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** getCampaignWorkflowListRequest API **/
function* getCampaignWorkflowListRequest(action) {
  try {
    const response = yield call(services.getCampaignWorkflowList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateFinishCampaignSetUp API **/
function* updateFinishCampaignSetUpRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateFinishCampaignSetUp, action.payload)
    if (response?.status === 200) {
      action.callback(response?.data)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: WORKFLOW_FINISH_SETUP_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: WORKFLOW_FINISH_SETUP_SAGA.ERROR_IN_UPDATING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getWorkflowTemplateStepStatus API **/
function* getWorkflowTemplateStepStatusRequest(action) {
  try {
    const response = yield call(services.getWorkflowTemplateStepStatus, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** cloneCampaignWorkflow API **/
function* cloneCampaignWorkflowRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.cloneCampaignWorkflow, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.data) {
      action.callback(response?.data)
      Notification({ status: 200, message: WORKFLOW_FINISH_SETUP_SAGA.CLONE_SUCCESS })
    } else {
      Notification({ status: 400, message: WORKFLOW_FINISH_SETUP_SAGA.ERROR_IN_CLONING })
      action.callback(null)
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateWFCampaignStatus API **/
function* updateWFCampaignStatusRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateWFCampaignStatus, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: CAMPAIGN_WORKFLOW_SAGA.STATUS_UPDATED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: CAMPAIGN_WORKFLOW_SAGA.ERROR_IN_UPDATING_STATUS })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getCanAddWorkflowRequest API **/
function* getCanAddWorkflowRequest(action) {
  try {
    const response = yield call(services.getCanAddWorkflow, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getWorkflowUnitList API **/
function* getWorkflowUnitListRequest(action) {
  try {
    const response = yield call(services.getWorkflowUnitList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** updateWorkflowUnitList API **/
function* updateWorkflowUnitListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateWorkflowUnitList, action.payload)
    if (response) {
      action.callback(true)
    } else {
      action.callback()
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getWorkflowUnitListCount API **/
function* getWorkflowUnitListCountRequest(action) {
  try {
    const response = yield call(services.getWorkflowUnitListCount, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getWorkflowOrganizationRoleList API **/
function* getWorkflowOrganizationRoleListRequest(action) {
  try {
    const response = yield call(services.getWorkflowOrganizationRoleList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export default function* buildWorkflowSagas() {
  yield takeLatest(actions.CREATE_CAMPAIGN_WORKFLOW_REQUEST, createCampaignWorkflowRequest)
  yield takeLatest(actions.UPDATE_CAMPAIGN_WORKFLOW_REQUEST, updateCampaignWorkflowRequest)
  yield takeLatest(actions.ADD_CAMPAIGN_WORKFLOW_VM_REQUEST, addCampaignWorkflowVMRequest)
  yield takeLatest(actions.DELETE_CAMPAIGN_WORKFLOW_REQUEST, deleteCampaignWorkflowRequest)
  yield takeLatest(actions.GET_CAMPAIGN_WORKFLOW_REQUEST, getCampaignWorkflowRequest)
  yield takeLatest(actions.GET_CANADD_WORKFLOW_REQUEST, getCanAddWorkflowRequest)
  yield takeLatest(actions.GET_CAMPAIGN_WORKFLOW_LIST_REQUEST, getCampaignWorkflowListRequest)
  yield takeLatest(actions.UPDATE_FINISH_CAMPAIGN_SETUP_REQUEST, updateFinishCampaignSetUpRequest)
  yield takeLatest(actions.GET_WORKFLOW_TEMPLATE_STEP_STATUS_REQUEST, getWorkflowTemplateStepStatusRequest)
  yield takeLatest(actions.CLONE_CAMPAIGN_WORKFLOW_REQUEST, cloneCampaignWorkflowRequest)
  yield takeLatest(actions.UPDATE_WORKFLOW_CAMPAIGN_STATUS_REQUEST, updateWFCampaignStatusRequest)
  yield takeLatest(actions.GET_WORKFLOW_UNIT_LIST_REQUEST, getWorkflowUnitListRequest)
  yield takeLatest(actions.UPDATE_WORKFLOW_UNIT_LIST_REQUEST, updateWorkflowUnitListRequest)
  yield takeLatest(actions.GET_WORKFLOW_UNIT_LIST_COUNT_REQUEST, getWorkflowUnitListCountRequest)
  yield takeLatest(actions.GET_WORKFLOW_ORGANIZATION_ROLE_LIST_REQUEST, getWorkflowOrganizationRoleListRequest)
}
