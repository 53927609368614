export const GET_AUTOMATE_REPORTS_REQUEST = 'GET_AUTOMATE_REPORTS_REQUEST'
export const GET_AUTOMATE_REPORT_VM_REQUEST = 'GET_AUTOMATE_REPORT_VM_REQUEST'
export const UPDATE_AUTOMATE_REPORT_REQUEST = 'UPDATE_AUTOMATE_REPORT_REQUEST'
export const CREATE_AUTOMATE_REPORT_REQUEST = 'CREATE_AUTOMATE_REPORT_REQUEST'

/****************************************************/
/** GET_AUTOMATE_REPORTS_REQUEST **/
/****************************************************/

/**
 * @method getAutomateReportsRequest:  To get Block Automate Reports
 *
 */
export const getAutomateReportsRequest = (payload, callback = () => { }) => {
  return {
    type: GET_AUTOMATE_REPORTS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_AUTOMATE_REPORT_VM_REQUEST **/
/****************************************************/

/**
 * @method getAutomateReportVMRequest :  To get automate report VM request
 *
 */
export const getAutomateReportVMRequest = (payload, callback = () => { }) => {
  return {
    type: GET_AUTOMATE_REPORT_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_AUTOMATE_REPORT_REQUEST **/
/****************************************************/

/**
 * @method updateAutomateReportRequest:  To update Block Automate Reports
 *
 */
export const updateAutomateReportsRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_AUTOMATE_REPORT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_AUTOMATE_REPORT_REQUEST **/
/****************************************************/
/**
 * @method createAutomateReportRequest:  To create Block Automate Reports
 *
 */
export const createAutomateReportsRequest = (payload, callback = () => { }) => {
  return {
    type: CREATE_AUTOMATE_REPORT_REQUEST,
    payload,
    callback
  }
}