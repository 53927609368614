import { LANGUAGE_CONSTANTS } from "@shared/language-constants"

const {
  OVERVIEW_PAGE_SECTIONS: { DIRECTORY_SECTION }
} = LANGUAGE_CONSTANTS

export const PRIMARY_CONTACT_TYPE = {
    YES: "1",
    NO: '0'
}

export const gridFiltersConfig = [
  {
    labelLocaleId: DIRECTORY_SECTION.ROLE,
    keyName: "sitePartyRoleId"
  },
  {
    labelLocaleId: DIRECTORY_SECTION.CONTACT,
    keyName: "contact",
    isArray: true
  },
  {
    labelLocaleId: DIRECTORY_SECTION.PRIMARY_CONTACT,
    keyName: "isPrimary"
  },
  {
    labelLocaleId: DIRECTORY_SECTION.ORGANIZATION,
    keyName: "organization",
    isArray: true,
    getFormattedValue: (obj) => {
      return {
        text: obj.organizationName,
        value: obj.organizationId 
      }
    }
  }
]
