import React, { useCallback } from "react"
import { useDispatch } from "react-redux"

import * as actions from "@store/actions"
import CustomCheckboxSearch from "./custom-checkbox-search"

const TaskName = ({
  id = "taskName",
  allSelectCheckboxLabel = "",
  inputPlaceholder,
  valueType = "value",
  labelType = "text",
  value = [],
  campaignId,
  siteId,
  programId,
  isResetClicked = false,
  onSelect = () => {},
  onResetClicked = () => {}
}) => {
  const dispatch = useDispatch()

  const handleFetchOptions = useCallback((params, callback = () => { }) => {
    const queryPayload = {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      genericSearch: params.searchText,
      campaignId,
      siteId,
      programId
    }
    dispatch(actions.getObservationTaskRequest(queryPayload, callback))
  }, [campaignId, siteId, programId])

  const handleSelectFilter = useCallback(
    (value) => {
      onSelect(id, value)
    },
    [onSelect]
  )

  return (
    <CustomCheckboxSearch
      id={id}
      valueType={valueType}
      labelType={labelType}
      allSelectCheckboxLabel={allSelectCheckboxLabel}
      inputPlaceholder={inputPlaceholder}
      onFetchOptions={handleFetchOptions}
      onSelectFilter={handleSelectFilter}
      onResetClicked={onResetClicked}
      selectedFilters={value}
      isResetClicked={isResetClicked}
    />
  )
}

export default TaskName
