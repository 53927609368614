import { LANGUAGE_CONSTANTS } from '@shared/language-constants'

export const OVERVIEW_TABS = {
    OVERVIEW: 1,
    SITES: 2,
    CAMPAIGNS: 3,
    ACCOUNTS: 4,
    ROLE_PERMISSION: 5,
    TRAINING_LOG: 6
}

const {
    OVERVIEW_SECTIONS,
    ROLE_PERMISSION,
    CONTACTS
  } = LANGUAGE_CONSTANTS

export const overviewSectionTabs = [
    { id: OVERVIEW_TABS.OVERVIEW, name: OVERVIEW_SECTIONS.OVERVIEW },
    { id: OVERVIEW_TABS.SITES, name: OVERVIEW_SECTIONS.SITES },
    // { id: OVERVIEW_TABS.CAMPAIGNS, name: OVERVIEW_SECTIONS.CAMPAIGNS }, // TODO tab hidden as per request
    { id: OVERVIEW_TABS.ACCOUNTS, name: CONTACTS.ACCOUNT },
    { id: OVERVIEW_TABS.ROLE_PERMISSION, name: ROLE_PERMISSION },
    { id: OVERVIEW_TABS.TRAINING_LOG, name: CONTACTS.TRAINING_LOG }
]

export const TAG_TYPES = {
    SKILLS: "skills",
    PRIMARY_MARKET: "primaryMarket",
    SECONDARY_MARKET: "secondaryMarket"
}