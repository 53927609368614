import cx from "classnames"
import React, { useCallback } from "react"

import { QUICK_SEARCH_KEY } from "@shared/constants"
import { genericSearchFilterConfig } from "./config"
import FilterItem from "./FilterItem"

/**
 * Note: Example for filtersConfig format
 * filtersConfig : [
 * {
    labelLocaleId: "localeId_used_For_translation",
    keyName: "keyName_here",
    keyNameForValue: "additional_keyName_here",
    isArray: "true_if_array_type_value",
    isDate: "true_if_date_type_value",
    getFormattedValue: (obj) => {
      return {
        text: obj.text,
        value: obj.value
      }
    }
  }
 * ]

  If Value is of type Object and label/value pair in object is not as follows -

   value => value
   label => text

   then we need to format object value in following format using getFormattedValue() from config

   getFormattedValue: (obj) => {
      return {
        text: obj.organizationName,
        value: obj.organizationId
      }
    }
 * 
 */

const GridFilters = ({
  isShow,
  /**
   * Note: isLeftMarginRequired , this property needs to be removed when all grids formatted to new theme
   */
  isLeftMarginRequired = false, 
  filtersConfig = [],
  filters,
  genericSearchFilter = "",
  initialFilterState,
  isSearchApplied = false,
  onRemoveFilter = () => { }
}) => {

  if (!isShow) {
    return null
  }

  const handleRemoveFilter = useCallback(({
    key,
    keyNameForValue,
    value,
    isArray,
    getFormattedValue = () => { }
  }) => {
    const filterState = Object.assign({}, filters)
    if (key === QUICK_SEARCH_KEY) {
      onRemoveFilter(key, "")
    } else {
      if (isArray) {
        /**
        * [{}], [1,2,3,...]
        */
        filterState[key] = [...filterState[key]].filter(filter => { return typeof value === "object" ? getFormattedValue(filter).value !== value.value : filter !== value })
      } else {
        /**
         * 1
         * ""
         * {key: '', value: ''}
         * true/false
         * date/time === ""
         */
        filterState[key] = initialFilterState[key]
        if (keyNameForValue) {
          filterState[keyNameForValue] = initialFilterState[keyNameForValue]
        }
        filterState[key] = initialFilterState[key]
      }
      onRemoveFilter(key, filterState)
    }
  }, [filters, onRemoveFilter])

  return (
    <div className={cx("grid-filters", { 'filter-left-margin': isLeftMarginRequired })}>
      {!!genericSearchFilter.length && <FilterItem
        {...genericSearchFilterConfig}
        value={genericSearchFilter}
        onRemoveFilter={handleRemoveFilter}
      />}
      {isSearchApplied && filtersConfig.map((config, index) => {
        const filterValue = filters[config.keyName]
        const isArray = Array.isArray(filterValue) || config.isArray

        if (!filterValue || (isArray && !filterValue.length)) {
          return null
        }

        if (isArray) {
          /**
           * [{}], [1,2,3,...]
           */
          return filterValue.map((val, i) => {
            return (
              <FilterItem
                key={`${config.keyName}_${index}_${i}`}
                {...config}
                isArray={isArray}
                value={val}
                onRemoveFilter={handleRemoveFilter}
              />
            )
          })
        } else {
          return (
            <FilterItem
              {...config}
              key={`${config.keyName}_${index}`}
              value={filters[config.keyName]}
              onRemoveFilter={handleRemoveFilter}
            />
          )
        }
      })}
    </div>
  )
}

export default GridFilters
