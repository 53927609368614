import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'

const { LOADER, REPORTS: { DATA_ACCESS } } = LANGUAGE_CONSTANTS

const ExpandableUnitCloseOutReportList = ({ data }) => {

  const selectedGrid = useMemo(() => {
    return {
      organization: data.organization || "",
      campaign: data.campaign || "",
      site: data.site || "",
      program: data.program || "",
      assignee: data.assignee || ""
    }
  }, [data])

  return (
    <div>
      <div className="site-units">
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.ORGANIZATION} />: </span>
          <span>{selectedGrid.organization || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.SITE} />: </span>
          <span>{selectedGrid.site || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.PROGRAM} />: </span>
          <span>{selectedGrid.program || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.CAMPAIGN} />: </span>
          <span>{selectedGrid.campaign || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.ASSIGNEE} />: </span>
          <span>{selectedGrid.assignee || '-'}</span>
        </div>
      </div>
    </div>
  )
}

ExpandableUnitCloseOutReportList.propTypes = {
  expandedRowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isLoadingChildGrid: PropTypes.bool,
  subData: PropTypes.object,
  noDataMessage: PropTypes.string,
  data: PropTypes.object
}

export default ExpandableUnitCloseOutReportList
