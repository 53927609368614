import config from "./APIConfig"
import { postDataApi } from '../../index'

/**
 * @method createBlockPartialSubmission
 * @param {object} data 
 * 
 * {
 *  wfCampaignSetupId: "",
 *  isPartialSubmissionRequired: ""
 * }
 * 
 * API payload:-
 * 
 * {
 *  "wfCampaignSetupId": 0,
 *  "partialSubmissionRequired": true
 * }
 */

export const createBlockPartialSubmission = (data) => {
  const { url } = config.createBlockPartialSubmission
  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    partialSubmissionRequired: !!Number(data.isPartialSubmissionRequired)
  }
  return postDataApi(url, payload, null, { isApiNeedsToHandleErrors: false })
}

export default createBlockPartialSubmission
