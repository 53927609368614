import React, { useMemo, useState, useCallback, useEffect } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"
import localeMessageWrapper from '@views/components/locale-message'
import { getReactSelectPositionProps } from "@views/helpers"

const { ACTIVITIES } = LANGUAGE_CONSTANTS

const AssemblyType = ({
  intl,
  errors,
  value = "",
  initialLabel = "",
  assemblyId,
  isMulti = true,
  isRequired = false,
  onChangeCheckpointCollection
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState([])
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  useEffect(() => {
    setResetCache((prevState) => !prevState)
    setOptions([])
    setSelectedOptionLabel(initialLabel)
  }, [assemblyId, initialLabel])

  const handleSelect = useCallback((id, selected) => {
    if (isMulti) {
      const lastElement = selected.length
        ? selected[selected.length - 1]
        : null
      onChangeCheckpointCollection(id, lastElement?.text !== "— Select —" ? selected : [])
    } else {
      onChangeCheckpointCollection(id, selected?.value || "", selected?.text !== "— Select —" ? selected?.text : "")
      setSelectedOptionLabel(selected?.text !== "— Select —" ? selected?.text : "")
    }
  }, [onChangeCheckpointCollection])

  const handleLoadMoreAssembly = useCallback(
    (search, page, prevOptions, others) => {
      return new Promise((resolve) => {
        if (others.isReset && !prevOptions.length && options.length) {
          resolve({
            optionList: options,
            hasMore: true
          })
          return
        }
        const params = {
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          assemblyTypeName: search,
          assemblyId
        }
        dispatch(
          actions.getAssemblyTypeDDLRequest(params, (response) => {
            if (response) {
              const { items, ...paginationData } = response
              resolve({
                optionList: items,
                hasMore: paginationData.hasNextPage
              })
              setOptions(() => (!!prevOptions.length ? [...prevOptions, ...items] : items))
            } else {
              resolve({
                optionList: [],
                hasMore: false
              })
            }
          })
        )
      })
    },
    [isResetCache, options, value, assemblyId]
  )

  useEffect(() => {
    if (!!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])

  const reactSelectPostionProps = useMemo(() => {
    return getReactSelectPositionProps()
  }, [])

  return (
    <Col className="mb-1" sm={12} md={6} xl={4}>
      <CustomLabel title={ACTIVITIES.ASSEMBLY_TYPE} required={isRequired} requiredIconClassName="required-field" />
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id="assemblyType"
        name="assemblyType"
        placeholder={getLocaleMessage(intl, ACTIVITIES.ASSEMBLY_TYPE)}
        options={options}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={
          !isMulti
            ? !!value
              ? {
                text: selectedOptionLabel,
                value
              }
              : null
            : value
        }
        isMulti={isMulti}
        isDisabled={!assemblyId.length}
        isShowCustomAction={false}
        hideSelectedOptions={false}
        onSelect={(selected) => handleSelect("assemblyType", selected)}
        onLoadOptions={handleLoadMoreAssembly}
        getOptionLabel={(option) => option.text}
        getOptionValue={(option) => option.value}
        {...reactSelectPostionProps}
      />
      {errors && (
        <ErrorMessage
          isShow={!!errors["assemblyType"]}
          message={errors["assemblyType"]}
        />
      )}
    </Col>
  )
}

export default localeMessageWrapper(AssemblyType)
