export const RESET_REDUCER_REQUEST = "RESET_REDUCER_REQUEST"

/**
 * @method resetReducer : To reset reducer state
 *
 */
export const resetReducer = () => {
  return {
    type: RESET_REDUCER_REQUEST
  }
}
