import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'

const { ERRORS } = LANGUAGE_CONSTANTS.PROJECTS

export const validateProjectDetails = (detailsList) => {
  const {
    projectName, projectOrganization, projectType, projectStatus, constructionStartDate
  } = detailsList
  const errors = {}
  if (!projectName.trim()) {
    errors['projectName'] = <LocaleMessage id={ERRORS.NAME} />
  }
  if (!projectOrganization) {
    errors['projectOrganization'] = <LocaleMessage id={ERRORS.ORGANIZATION} />
  }
  if (!projectType) {
    errors['projectType'] = <LocaleMessage id={ERRORS.TYPE} />
  }
  if (!constructionStartDate) {
    errors['constructionStartDate'] = <LocaleMessage id={ERRORS.CONSTRUCTION_START_DATE} />
  }
  if (!projectStatus) {
    errors['projectStatus'] = <LocaleMessage id={ERRORS.STATUS} />
  }

  return errors
}

export const validateAddress = (addressList) => {
  const errors = {}

  addressList.forEach((address, index) => {
    const { primaryPhone, city, countryCode, postCode, state, streetAddress } = address

    const phone = (primaryPhone || "").trim()
    if (phone.length && phone !== "NA") {
      if (phone === '+') {
        errors[`primaryPhone_${index}`] = <LocaleMessage id={ERRORS.PHONE} />
      } else if (phone[0] === '+') {
        const value = phone.slice(1)
        if (isNaN(value)) {
          errors[`primaryPhone_${index}`] = <LocaleMessage id={ERRORS.PHONE} />
        }
      } else if (phone.length > 10) {
        errors[`primaryPhone_${index}`] = <LocaleMessage id={ERRORS.INVALID_PHONE} />
      } else {
        if (isNaN(phone)) {
          errors[`primaryPhone_${index}`] = <LocaleMessage id={ERRORS.PHONE} />
        }
      }
    }
    if (!streetAddress?.trim().length) {
      errors[`streetAddress_${index}`] = <LocaleMessage id={ERRORS.STREET_ADDRESS} />
    }
    if (!countryCode?.trim().length) {
      errors[`countryCode_${index}`] = <LocaleMessage id={ERRORS.COUNTRY} />
    }
    if (!city?.trim().length) {
      errors[`city_${index}`] = <LocaleMessage id={ERRORS.CITY} />
    }
    if (!state?.trim().length) {
      errors[`state_${index}`] = <LocaleMessage id={ERRORS.STATE} />
    }
    if (!postCode?.trim().length) {
      errors[`postCode_${index}`] = <LocaleMessage id={ERRORS.ZIP_CODE} />
    }
  })

  return errors
}

export const validatePlanDetail = (data) => {
  const errors = {}

  if (!data.planName.trim()) {
    errors["planName"] = <LocaleMessage id={ERRORS.PLAN_NAME} />
  }
  if (!data.version.trim()) {
    errors["version"] = <LocaleMessage id={ERRORS.VERSION} />
  }

  if (!!data.stories) {
    const stories = data.stories.trim()
    if (!!stories.length && isNaN(stories)) {
      errors["stories"] = <LocaleMessage id={ERRORS.INVALID_NO_OF_STORIES} />
    }
    if (!!stories.length && (parseInt(stories) < 0)) {
      errors["stories"] = <LocaleMessage id={ERRORS.INVALID_NO_OF_STORIES} />
    }
  }

  if (!!data.numberOfBedroom) {
    const numberOfBedroom = data.numberOfBedroom.trim()
    if (!!numberOfBedroom.length && isNaN(numberOfBedroom)) {
      errors["numberOfBedroom"] = <LocaleMessage id={ERRORS.INVALID_NO_OF_BEDROOMS} />
    }
    if (!!numberOfBedroom.length && (parseInt(numberOfBedroom) < 0)) {
      errors["numberOfBedroom"] = <LocaleMessage id={ERRORS.INVALID_NO_OF_BEDROOMS} />
    }
  }

  if (!!data.bathrooms) {
    const bathrooms = data.bathrooms.trim()
    if (!!bathrooms.length && isNaN(bathrooms)) {
      errors["bathrooms"] = <LocaleMessage id={ERRORS.INVALID_NO_OF_BATHROOMS} />
    }
    if (!!bathrooms.length && (parseInt(bathrooms) < 0)) {
      errors["bathrooms"] = <LocaleMessage id={ERRORS.INVALID_NO_OF_BATHROOMS} />
    }
  }

  return errors
}

export const validateSiteDetails = (siteDetails) => {
  const errors = {}

  const {
    city,
    postCode,
    state,
    streetAddress,
    siteName,
    buildingType,
    pointOfContact,
    siteStatus,
    countryCode,
    useProjectAddress
  } = siteDetails
  if (!siteName.trim()) {
    errors["siteName"] = <LocaleMessage id={ERRORS.SITE_NAME} />
  }
  if (!buildingType) {
    errors["buildingType"] = <LocaleMessage id={ERRORS.BUILDING_TYPE} />
  }
  if (!pointOfContact) {
    errors["pointOfContact"] = <LocaleMessage id={ERRORS.POINT_OF_CONTACT} />
  }

  if (!siteStatus) {
    errors["siteStatus"] = <LocaleMessage id={ERRORS.SITE_STATUS} />
  }

  if (!useProjectAddress) {
    if (!streetAddress) {
      errors["streetAddress"] = <LocaleMessage id={ERRORS.STREET_ADDRESS} />
    }
    if (!city) {
      errors["city"] = <LocaleMessage id={ERRORS.CITY} />
    }
    if (!state) {
      errors["state"] = <LocaleMessage id={ERRORS.STATE} />
    }
    if (!postCode) {
      errors["postCode"] = <LocaleMessage id={ERRORS.ZIP_CODE} />
    }
    if (!countryCode) {
      errors["countryCode"] = <LocaleMessage id={ERRORS.COUNTRY} />
    }

  }

  return errors
}

export const validateUnitDetail = (isAdditionalLocationDetailsRequired = false, data) => {
  const errors = {}

  if (!data.unitName.trim()) {
    errors['unitName'] = <LocaleMessage id={ERRORS.UNIT_NAME} />
  }
  if (!data.unitType) {
    errors['unitType'] = <LocaleMessage id={ERRORS.UNIT_TYPE} />
  }
  // if (!data.unitSite) {
  //   errors['unitSite'] = 'Please enter unit site'
  // }

  if (!data.useSiteAddress) {
    if (!data.streetAddress) {
      errors['streetAddress'] = <LocaleMessage id={ERRORS.STREET_ADDRESS} />
    }
    if (!data.city) {
      errors['city'] = <LocaleMessage id={ERRORS.CITY} />
    }
    if (!data.state) {
      errors['state'] = <LocaleMessage id={ERRORS.STATE} />
    }
    if (!data.countryCode) {
      errors['country'] = <LocaleMessage id={ERRORS.COUNTRY} />
    }
    if (!data.postCode) {
      errors['postCode'] = <LocaleMessage id={ERRORS.ZIP_CODE} />
    }
  }
  if (isAdditionalLocationDetailsRequired && !data.building) {
    errors['building'] = <LocaleMessage id={ERRORS.BUILDING_NAME} />
  }
  if (isAdditionalLocationDetailsRequired && !data.floor) {
    errors['floor'] = <LocaleMessage id={ERRORS.FLOOR_NAME} />
  }

  return errors
}

export const validateRoleDetail = (data) => {
  const errors = {}
  if (!data.siteRole) {
    errors['siteRole'] = <LocaleMessage id={ERRORS.SITE_ROLE} />
  }
  if (!data.org) {
    errors['org'] = <LocaleMessage id={ERRORS.SELECT_ORGANIZATION} />
  }
  if (!data.contacts?.length) {
    errors['contacts'] = <LocaleMessage id={ERRORS.CONTACTS} />
  }

  return errors
}

export const handleFilterLocations = (addressList) => {
  const locations = {
    locationsToBeUpdated: [],
    locationsToBeAdded: []
  }
  locations.locationsToBeUpdated = addressList.filter(location => location.projectLocationId)
  locations.locationsToBeAdded = addressList.filter(location => !location.projectLocationId)
  return locations
}