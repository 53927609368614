import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method saveAdvanceSearchFilters
 * @param {object} data
 *
 * {
    searchFilterKey: "string",
    searchFilterName: "string",
    searchFilterJson: "string",
    rowsPerPage: number,
    isGlobal: bool,
    isDefault: bool
  }
 */
export const saveAdvanceSearchFilters = (data) => {
  const { url } = config.saveAdvanceSearchFilters
  const payload = {
    searchFilterKey: data.searchFilterKey,
    searchFilterName: data.searchFilterName,
    searchFilterJson: data.searchFilterJson,
    rowsPerPage: data.rowsPerPage,
    isGlobal: data.isGlobal,
    isDefault: data.isDefault
  }

  return postDataApi(url, payload)
}

export default saveAdvanceSearchFilters
