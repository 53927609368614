import config from "./APIConfig"
import { putDataApi } from "../index"

/**
 * @method updateBulkAssignCorrectionAction
 * This api is for updating contact to reassign
 * [Reassign Failed inspection correction]
 * 
 * @param {object} data 
 * 
 * {
 *  roleId: 0
 *  contactId: 0,
 *  wfActionIds: string
 *  note: string
 * }
 * 
 */
export const updateBulkAssignCorrectionAction = async (data) => {
  const { url } = config.updateBulkAssignCorrectionAction
  return await putDataApi(url, data)
}

export default updateBulkAssignCorrectionAction
