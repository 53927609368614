import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateOrganizationRoleContact
 * @param {object} data 
 * 
 * {
 *  "organizationRoleContactId": 0,
 *  "organization": 0,
 *  "contactId": "",
 *  "roleId": 0
 * }
 * 
 */

export const updateOrganizationRoleContact = (data) => {
  const { url } = config.updateOrganizationRoleContact
  const payload = {
    organizationRoleContactId: Number(data.organizationRoleContactId),
    externalOrganizationId: Number(data.organization),
    contactId: data.contactId,
    sitePartyRoleId: Number(data.roleId)
  }

  return putDataApi(url(data), payload)
}

export default updateOrganizationRoleContact
