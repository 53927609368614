import config from "./APIConfig"
import { putDataApi } from "../../index"

/**
 * @method updateBlockAddTraining
 * @param {object} data 
 * 
 * {
 *  wfCampaignSetupId: "",
 *  trainingName: "",
 *  "responsibleRole": [
    {
      "siteRoleId": 0,
      "name": ""
    }
   ]
 * }
 * 
 * API payload:-
 * 
 * {
 *  "wfCampaignSetupId": 0,
 *  "trainingName": "",
 *  "siteRoleIds": [
    {
      "siteRoleId": 0
    }
   ]
 * }
 */
export const updateBlockAddTraining = async (data) => {
  const { url } = config.updateBlockAddTraining
  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    trainingName: data.trainingName,
    siteRoleIds: data.responsibleRole?.map(x => ({ siteRoleId: x.siteRoleId }))
  }
  return await putDataApi(url(data.wfCampaignSetupId), payload, null, { isApiNeedsToHandleErrors: false })
}

export default updateBlockAddTraining
