import config from "./APIConfig"
import { postDataApi } from '../../index'

/**
 * @method updateWorkflowUnitList : To save selected units to workflow
 * 
 * @param {object} data
 * 
 * {
    "wfCampaignSetupId": 0,
    "selectedSiteUnitIds": [
      0
    ],
    "unSelectedSiteUnitIds": [
      0
    ]
  }
 */
export const updateWorkflowUnitList = async (data) => {
  const { url } = config.updateWorkflowUnitList
  return await postDataApi(url(data), data)
}

export default updateWorkflowUnitList