export const SIGN_OFF_REQUIRED_TYPE = {
  YES: "1",
  NO: "0"
}

export const ASSIGNMENT_TYPE = {
  SITE_ROLE: "1",
  CONTACT: "2"
}

export const responsiblePartyMockData = [
  {
    text: "Role",
    value: "1"
  },
  {
    text: "Person",
    value: "2"
  },
  {
    text: "Unit Primary Contact",
    value: "3"
  },
  {
    text: "Site Primary Contact",
    value: "4"
  }
]
