import React from "react"
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getUniqueId } from '@utils'
import { LocaleMessage } from '@views/components'

const { CLONE_MODAL } = LANGUAGE_CONSTANTS

const TemplateSelectOption = ({ data, label, value = '' }) => {
  const optionId = getUniqueId()
  return (
    <div className="list-unstyled orgnization-menu p-0">
      <a
        id={`${optionId}_option_label`}
        data-tip="tooltip"
        data-for={`${optionId}_option_description`}
      >
        <div className="orgnization-menu-list">
          {label}
        </div>
      </a>
      <ReactTooltip
        id={`${optionId}_option_description`}
        place={"right"}
        arrowColor={'transparent'}
        type="dark"
        effect="solid"
        multiline={true}
        disable={!value}
      >
        <div className="orgnization-submenu list-unstyled ">
          <div className="orgnization-submenu-list">
            <h4><LocaleMessage id={CLONE_MODAL.TEMPLATE_OPTION_HEADER} /></h4>
            <hr />
          </div>
          <div className="orgnization-submenu-list">
            <div className="address">
              {!!data && (
                <>
                  <h6>{data.categoryName}<br /></h6>
                  <h6>{data.buildingType}<br /></h6>
                  <h6>{data.scopeOfWork}<br /></h6>
                  <h6>{data.status}<br /></h6>
                </>
              )}
            </div>
          </div>
        </div>
      </ReactTooltip>
    </div>
  )
}

TemplateSelectOption.propTypes = {
  data: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.string
}

export default TemplateSelectOption
