import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateProjectAddress
 * @param {object} data 
 *  {
 *    "projectId": 0,
 *    "addresses": [
 *       {
 *        "projectAddressId": 0,
 *        "isPrimary": true,
 *        "streetAddress": "string",
 *        "addressUnit": "string",
 *        "city": "string",
 *        "state": "string",
 *        "postCode": "string",
 *        "countryCode": "string",
 *        "primaryPhone": "string",
 *        "addressName": "string",
 *        "county": "string",
 *       }, etc...
 *     ]
 *   }
 *
 * Note:
 *  "addresses" can contain multiple addresses.
 *  If some address is primary address then set "isPrimary" = true else false,
 *  There should be only one primary address in list
 * 
 */
export const updateProjectAddress = (data) => {
  const { url } = config.updateProjectAddress

  const addressList = []
  data.locations.forEach(address => {
    const updatedAddress = {
      isPrimary: address.isPrimary
    }
    if (!!address.projectLocationId) {
      updatedAddress.projectLocationId = address.projectLocationId
    }
    if (!!address.projectId) {
      updatedAddress.projectId = address.projectId
    }
    if (!!address.locationName) {
      updatedAddress.locationName = address.locationName
    }
    if (!!address.streetAddress) {
      updatedAddress.streetAddress = address.streetAddress
    }
    if (!!address.addressUnit) {
      updatedAddress.addressUnit = address.addressUnit
    }
    if (!!address.city) {
      updatedAddress.city = address.city
    }
    if (!!address.state) {
      updatedAddress.state = address.state
    }
    if (!!address.postCode) {
      updatedAddress.postCode = address.postCode
    }
    if (!!address.countryCode) {
      updatedAddress.countryCode = address.countryCode
    }
    if (!!address.primaryPhone) {
      updatedAddress.primaryPhone = address.primaryPhone
    }
    if (!!address.county) {
      updatedAddress.county = address.county
    }
    // ToDo: pending from backend side
    if (!!address.latitude) {
      updatedAddress.latitude = String(address.latitude)
    }
    if (!!address.longitude) {
      updatedAddress.longitude = String(address.longitude)
    }
    addressList.push(updatedAddress)
  })

  const payload = {
    projectId: data.projectId,
    locations: addressList
  }

  return putDataApi(url({ projectId: data.projectId }), payload)
}

export default updateProjectAddress
