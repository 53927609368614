import config from "./APIConfig"
import { putDataApi } from "../index"

/**
 * @method updateTemplateActivityGroup
 * 
 * @param data 
 * {
    "groupName": "string",
    "templateCheckpointGroupId": 0
  }
 * @returns 
 */
export const updateTemplateActivityGroup = (payload) => {
  const { url } = config.updateTemplateActivityGroup

  return putDataApi(url(payload), payload)
}

export default updateTemplateActivityGroup
