import classnames from "classnames"
import React from "react"
import PropTypes from 'prop-types'

import {
  Button,
  DropdownToggle,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"

const { TASKS } = LANGUAGE_CONSTANTS

const ActionsDropdown = ({
  intl,
  isDisabled = false,
  footerMarginClassRequired,
  isSplitBorder,
  primaryActionId,
  primaryActionLabel,
  actionConfig = [],
  onClickPrimary = () => { }
}) => {

  return (
    <div className={classnames("d-flex justify-content-end action-dropdown", {
      'mb-4': footerMarginClassRequired,
      "action-disabled": isDisabled
    })}>
      <UncontrolledButtonDropdown>
        <Button
          disabled={isDisabled}
          id={primaryActionId}
          outline
          color=""
          className={classnames("text-capitalize", {
            "split-border": isSplitBorder
          })}
          onClick={() => onClickPrimary()}
        >
          {primaryActionLabel}
        </Button>
        {
          !!actionConfig.length && <DropdownToggle
            outline
            color=""
            caret
            className={classnames({
              "dropdown-toggle-split dropdown-toggle-caret": isDisabled,
              "dropdown-toggle-split": !isDisabled
            })}
          />
        }
        <DropdownMenu right className="action-dropdown-menu">
          {actionConfig.map((action) => (
            <DropdownItem
              key={`dropdown_action_${action.id}`}
              tag="a"
              className="action-dropdown-item"
              disabled={isDisabled}
              onClick={action.onClick}
            >
              {!!action.labelLocaleId ? getLocaleMessage(intl, action.labelLocaleId) : action.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  )
}

ActionsDropdown.propTypes = {
  footerMarginClassRequired: PropTypes.bool,
  isSplitBorder: PropTypes.bool,
  primaryActionId: PropTypes.string,
  primaryActionLabel: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  actionConfig: PropTypes.array,
  onClickPrimary: PropTypes.func
}

ActionsDropdown.defaultProps = {
  footerMarginClassRequired: true,
  isSplitBorder: true,
  primaryActionId: "save",
  primaryActionLabel: "Save"
}

export default localeMessageWrapper(ActionsDropdown)
