import { takeLatest, takeEvery, call, put } from "redux-saga/effects"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as services from "@services/api"
import * as actions from "@store/actions"
import { Notification } from "@views/components"

const { CAMPAIGN_CHECKLIST_SAGA, WORKFLOW_ADD_TRAINING_SAGA, CAMPAIGN_WORKFLOW_SAGA } = LANGUAGE_CONSTANTS

/** getCreateTriggerVM API */
function* getCreateTriggerVMRequest(action) {
  try {
    const response = yield call(services.getCreateTriggerVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getInspectionCorrectionVM API */
function* getInspectionCorrectionVMRequest(action) {
  try {
    const response = yield call(services.getInspectionCorrectionVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getConfirmSiteVisitVM API */
function* getConfirmSiteVisitVMRequest(action) {
  try {
    const response = yield call(services.getConfirmSiteVisitVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getScheduleTaskVM API */
function* getScheduleTaskVMRequest(action) {
  try {
    const response = yield call(services.getScheduleTaskVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getFailedInspectionTrainingVM API */
function* getFailedInspectionTrainingVMRequest(action) {
  try {
    const response = yield call(services.getFailedInspectionTrainingVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTaskCloseOutVM API */
function* getTaskCloseOutVMRequest(action) {
  try {
    const response = yield call(services.getTaskCloseOutVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** createBlockTrigger API */
function* createBlockTriggerRequest(action) {
  try {
    const response = yield call(services.createBlockTrigger, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockTrigger API **/
function* getBlockTriggerRequest(action) {
  try {
    const response = yield call(services.getBlockTrigger, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBlockTrigger API **/
function* updateBlockTriggerRequest(action) {
  try {
    const response = yield call(services.updateBlockTrigger, action.payload)
    action.callback(!!response?.data?.status)
  } catch (error) {
    action.callback(false, error)
  }
}

/** createBlockScheduleTask API */
function* createBlockScheduleTaskRequest(action) {
  try {
    const response = yield call(services.createBlockScheduleTask, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockScheduleTask API **/
function* getBlockScheduleTaskRequest(action) {
  try {
    const response = yield call(services.getBlockScheduleTask, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}


/** updateBlockScheduleTask API */
function* updateBlockScheduleTaskRequest(action) {
  try {
    const response = yield call(services.updateBlockScheduleTask, action.payload)
    action.callback(!!response?.data?.status)
  } catch (error) {
    action.callback()
  }
}

/** createBlockConfirmSiteVisit API */
function* createBlockConfirmSiteVisitRequest(action) {
  try {
    const response = yield call(services.createBlockConfirmSiteVisit, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockConfirmSiteVisit API **/
function* getBlockConfirmSiteVisitRequest(action) {
  try {
    const response = yield call(services.getBlockConfirmSiteVisit, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBlockConfirmSiteVisit API **/
function* updateBlockConfirmSiteVisitRequest(action) {
  try {
    const response = yield call(services.updateBlockConfirmSiteVisit, action.payload)
    action.callback(!!response?.data?.status)
  } catch (error) {
    action.callback()
  }
}

/** createBlockFailedInspectionCorrection API */
function* createBlockFailedInspectionCorrectionRequest(action) {
  try {
    const response = yield call(services.createBlockFailedInspectionCorrection, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockFailedInspectionCorrection API */
function* getBlockFailedInspectionCorrectionRequest(action) {
  try {
    const response = yield call(services.getBlockFailedInspectionCorrection, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBlockFailedInspectionCorrection API */
function* updateBlockFailedInspectionCorrectionRequest(action) {
  try {
    const response = yield call(services.updateBlockFailedInspectionCorrection, action.payload)
    action.callback(!!response?.data?.status)
  } catch (error) {
    action.callback()
  }
}

/** getBlockObservationEscalationVM API */
function* getBlockObservationEscalationVMRequest(action) {
  try {
    const response = yield call(services.getObservationEscalationVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** createBlockEscalationRequest API */
function* createBlockEscalationRequest(action) {
  try {
    const response = yield call(services.createBlockEscalation, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}
/** createBlockFailedInspectionTraining API */
function* createBlockFailedInspectionTrainingRequest(action) {
  try {
    const response = yield call(services.createBlockFailedInspectionTraining, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}
/** updateBlockEscalationRequest API */
function* updateBlockEscalationRequest(action) {
  try {
    const response = yield call(services.updateBlockEscalation, action.payload)
    action.callback(!!response?.data?.status)
  } catch (error) {
    action.callback()
  }
}
/** getBlockEscalationRequest API */
function* getBlockEscalationRequest(action) {
  try {
    const response = yield call(services.getBlockEscalation, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockFailedInspectionTraining API */
function* getBlockFailedInspectionTrainingRequest(action) {
  try {
    const response = yield call(services.getBlockFailedInspectionTraining, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBlockFailedInspectionTraining API */
function* updateBlockFailedInspectionTrainingRequest(action) {
  try {
    const response = yield call(services.updateBlockFailedInspectionTraining, action.payload)
    action.callback(!!response?.data?.status)
  } catch (error) {
    action.callback()
  }
}

/** deleteBlockCampaignChecklistRequest API **/
function* deleteBlockCampaignChecklistRequest(action) {
  try {
    const { isTrainingWFTemplate, ...payload } = action.payload
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteCampaignChecklist, payload)
    if (response?.data?.status) {
      action.callback(true)
      Notification({ status: 200, message: isTrainingWFTemplate ? WORKFLOW_ADD_TRAINING_SAGA.DELETED_SUCCESS : CAMPAIGN_CHECKLIST_SAGA.DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: isTrainingWFTemplate ? WORKFLOW_ADD_TRAINING_SAGA.ERROR_IN_DELETING : CAMPAIGN_CHECKLIST_SAGA.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    const { isTrainingWFTemplate } = action.payload
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: isTrainingWFTemplate ? WORKFLOW_ADD_TRAINING_SAGA.ERROR_IN_DELETING : CAMPAIGN_CHECKLIST_SAGA.ERROR_IN_DELETING })
  }
}

/** createBlockTaskCloseout API */
function* createBlockTaskCloseoutRequest(action) {
  try {
    const response = yield call(services.createBlockTaskCloseout, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockTaskCloseout API */
function* getBlockTaskCloseoutRequest(action) {
  try {
    const response = yield call(services.getBlockTaskCloseout, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBlockTaskCloseout API */
function* updateBlockTaskCloseoutRequest(action) {
  try {
    const response = yield call(services.updateBlockTaskCloseout, action.payload)
    action.callback(!!response?.data?.status)
  } catch (error) {
    action.callback()
  }
}

/** getTemplateStepQuery API */
function* getTemplateStepQueryRequest(action) {
  try {
    const response = yield call(services.getTemplateStepQuery, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getSitePlanTriggerVM API */
function* getSitePlanTriggerVMRequest(action) {
  try {
    const response = yield call(services.getSitePlanTriggerVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getSitePlanScheduleLineItemDDL API */
function* getSitePlanScheduleLineItemDDLRequest(action) {
  try {
    const response = yield call(services.getSitePlanScheduleLineItemDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getFindingsCorrectionRequest API */
function* getFindingsCorrectionRequest(action) {
  try {
    const response = yield call(services.getFindingsCorrection, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getFindingsCorrectionVMRequest API */
function* getFindingsCorrectionVMRequest(action) {
  try {
    const response = yield call(services.getFindingsCorrectionVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** addFindingsCorrectionRequest API */
function* addFindingsCorrectionRequest(action) {
  try {
    const response = yield call(services.addFindingsCorrection, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateFindingsCorrectionRequest API */
function* updateFindingsCorrectionRequest(action) {
  try {
    const response = yield call(services.updateFindingsCorrection, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockScheduleRecordableSettingsVM API */
function* getBlockScheduleRecordableSettingsVMRequest(action) {
  try {
    const response = yield call(services.getBlockScheduleRecordableSettingsVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** createBlockScheduleRecordableSettings API */
function* createBlockScheduleRecordableSettingsRequest(action) {
  try {
    const response = yield call(services.createBlockScheduleRecordableSettings, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBlockScheduleRecordableSettings API */
function* updateBlockScheduleRecordableSettingsRequest(action) {
  try {
    const response = yield call(services.updateBlockScheduleRecordableSettings, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockScheduleRecordableSettings API */
function* getBlockScheduleRecordableSettingsRequest(action) {
  try {
    const response = yield call(services.getBlockScheduleRecordableSettings, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTaskReviewVM API */
function* getTaskReviewVMRequest(action) {
  try {
    const response = yield call(services.getTaskReviewVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockScheduleAutomateReportsVM API */
function* getBlockScheduleAutomateReportsVMRequest(action) {
  try {
    const response = yield call(services.getBlockScheduleAutomateReportsVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** createBlockTaskReview API */
function* createBlockTaskReviewRequest(action) {
  try {
    const response = yield call(services.createBlockTaskReview, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** createBlockScheduleAutomateReports API */
function* createBlockScheduleAutomateReportsRequest(action) {
  try {
    const response = yield call(services.createBlockScheduleAutomateReports, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBlockTaskReview API */
function* updateBlockTaskReviewRequest(action) {
  try {
    const response = yield call(services.updateBlockTaskReview, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBlockScheduleAutomateReports API */
function* updateBlockScheduleAutomateReportsRequest(action) {
  try {
    const response = yield call(services.updateBlockScheduleAutomateReports, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockTaskReview API */
function* getBlockTaskReviewRequest(action) {
  try {
    const response = yield call(services.getBlockTaskReview, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockScheduleAutomateReports API */
function* getBlockScheduleAutomateReportsRequest(action) {
  try {
    const response = yield call(services.getBlockScheduleAutomateReports, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getPartialSubmissionVM API */
function* getPartialSubmissionVM(action) {
  try {
    const response = yield call(services.getPartialSubmissionVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** createBlockPartialSubmission API */
function* createBlockPartialSubmissionRequest(action) {
  try {
    const response = yield call(services.createBlockPartialSubmission, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBlockPartialSubmission API */
function* updateBlockPartialSubmissionRequest(action) {
  try {
    const response = yield call(services.updateBlockPartialSubmission, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockPartialSubmission API */
function* getBlockPartialSubmissionRequest(action) {
  try {
    const response = yield call(services.getBlockPartialSubmission, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockTaskSignOffVM API */
function* getBlockTaskSignOffVMRequest(action) {
  try {
    const response = yield call(services.getBlockTaskSignOffVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockTaskSignOff API */
function* getBlockTaskSignOffRequest(action) {
  try {
    const response = yield call(services.getBlockTaskSignOff, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** createBlockTaskSignOff API */
function* createBlockTaskSignOffRequest(action) {
  try {
    const response = yield call(services.createBlockTaskSignOff, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBlockTaskSignOff API **/
function* updateBlockTaskSignOffRequest(action) {
  try {
    const response = yield call(services.updateBlockTaskSignOff, action.payload)
    action.callback(!!response?.data?.status)
  } catch (error) {
    action.callback()
  }
}

/** getBlockChecklistVM API */
function* getBlockChecklistVMRequest(action) {
  try {
    const response = yield call(services.getBlockChecklistVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockChecklist API */
function* getBlockChecklistRequest(action) {
  try {
    const response = yield call(services.getBlockChecklist, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** createBlockChecklist API */
function* createBlockChecklistRequest(action) {
  try {
    const response = yield call(services.createBlockChecklist, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBlockChecklist API **/
function* updateBlockChecklistRequest(action) {
  try {
    const response = yield call(services.updateBlockChecklist, action.payload)
    action.callback(!!response?.data?.status)
  } catch (error) {
    action.callback()
  }
}

/** deleteCustomizedChildCampaignBlock API **/
function* deleteCustomizedChildCampaignBlockRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteCustomizedChildCampaignBlock, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CAMPAIGN_WORKFLOW_SAGA.RESET_BLOCK_SUCCESS })
    } else {
      Notification({ status: 400, message: CAMPAIGN_WORKFLOW_SAGA.ERROR_IN_RESET_BLOCK })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** getFindingWorkflows API **/
function* getFindingWorkflowsRequest(action) {
  try {
    const response = yield call(services.getFindingWorkflows, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

export default function* workflowBlockSagas() {
  yield takeLatest(actions.GET_CREATE_TRIGGER_VM_REQUEST, getCreateTriggerVMRequest)
  yield takeLatest(actions.GET_INSPECTION_CORRECTION_VM_REQUEST, getInspectionCorrectionVMRequest)
  yield takeLatest(actions.GET_CONFIRM_SITE_VISIT_VM_REQUEST, getConfirmSiteVisitVMRequest)
  yield takeLatest(actions.GET_SCHEDULE_TASK_VM_REQUEST, getScheduleTaskVMRequest)
  yield takeLatest(actions.GET_FAILED_INSPECTION_TRAINING_VM_REQUEST, getFailedInspectionTrainingVMRequest)
  yield takeLatest(actions.GET_TASK_CLOSEOUT_VM_REQUEST, getTaskCloseOutVMRequest)
  yield takeLatest(actions.CREATE_BLOCK_TRIGGER_REQUEST, createBlockTriggerRequest)
  yield takeLatest(actions.CREATE_BLOCK_SCHEDULE_TASK_REQUEST, createBlockScheduleTaskRequest)
  yield takeLatest(actions.CREATE_BLOCK_CONFIRM_SITE_VISIT_REQUEST, createBlockConfirmSiteVisitRequest)
  yield takeLatest(actions.GET_BLOCK_TRIGGER_REQUEST, getBlockTriggerRequest)
  yield takeLatest(actions.GET_BLOCK_SCHEDULE_TASK_REQUEST, getBlockScheduleTaskRequest)
  yield takeLatest(actions.GET_BLOCK_CONFIRM_SITE_VISIT_REQUEST, getBlockConfirmSiteVisitRequest)
  yield takeLatest(actions.UPDATE_BLOCK_TRIGGER_REQUEST, updateBlockTriggerRequest)
  yield takeLatest(actions.UPDATE_BLOCK_SCHEDULE_TASK_REQUEST, updateBlockScheduleTaskRequest)
  yield takeLatest(actions.UPDATE_BLOCK_CONFIRM_SITE_VISIT_REQUEST, updateBlockConfirmSiteVisitRequest)
  yield takeLatest(actions.CREATE_BLOCK_FAILED_INSPECTION_CORRECTION, createBlockFailedInspectionCorrectionRequest)
  yield takeLatest(actions.GET_BLOCK_FAILED_INSPECTION_CORRECTION, getBlockFailedInspectionCorrectionRequest)
  yield takeLatest(actions.UPDATE_BLOCK_FAILED_INSPECTION_CORRECTION, updateBlockFailedInspectionCorrectionRequest)
  yield takeLatest(actions.GET_BLOCK_OBSERVATION_ESCALATION_VM_REQUEST, getBlockObservationEscalationVMRequest)
  yield takeLatest(actions.CREATE_BLOCK_ESCALATION_REQUEST, createBlockEscalationRequest)
  yield takeLatest(actions.CREATE_BLOCK_FAILED_INSPECTION_TRAINING, createBlockFailedInspectionTrainingRequest)
  yield takeLatest(actions.UPDATE_BLOCK_ESCALATION_REQUEST, updateBlockEscalationRequest)
  yield takeLatest(actions.GET_BLOCK_ESCALATION_REQUEST, getBlockEscalationRequest)
  yield takeLatest(actions.GET_BLOCK_FAILED_INSPECTION_TRAINING, getBlockFailedInspectionTrainingRequest)
  yield takeLatest(actions.UPDATE_BLOCK_FAILED_INSPECTION_TRAINING, updateBlockFailedInspectionTrainingRequest)
  yield takeEvery(actions.DELETE_CAMPAIGN_CHECKLIST_REQUEST, deleteBlockCampaignChecklistRequest)
  yield takeLatest(actions.CREATE_BLOCK_TASK_CLOSEOUT, createBlockTaskCloseoutRequest)
  yield takeLatest(actions.GET_BLOCK_TASK_CLOSEOUT, getBlockTaskCloseoutRequest)
  yield takeLatest(actions.UPDATE_BLOCK_TASK_CLOSEOUT, updateBlockTaskCloseoutRequest)
  yield takeLatest(actions.GET_TEMPLATE_STEP_QUERY_REQUEST, getTemplateStepQueryRequest)
  yield takeLatest(actions.GET_SITE_PLAN_TRIGGER_VM_REQUEST, getSitePlanTriggerVMRequest)
  yield takeEvery(actions.GET_SITE_PLAN_SCHEDULE_LINE_ITEM_DDL_REQUEST, getSitePlanScheduleLineItemDDLRequest)
  yield takeEvery(actions.GET_FINDINGS_CORRECTION_REQUEST, getFindingsCorrectionRequest)
  yield takeEvery(actions.GET_FINDINGS_CORRECTION_VM_REQUEST, getFindingsCorrectionVMRequest)
  yield takeEvery(actions.ADD_FINDINGS_CORRECTION_REQUEST, addFindingsCorrectionRequest)
  yield takeEvery(actions.UPDATE_FINDINGS_CORRECTION_REQUEST, updateFindingsCorrectionRequest)
  yield takeEvery(actions.GET_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_VM_REQUEST, getBlockScheduleRecordableSettingsVMRequest)
  yield takeEvery(actions.CREATE_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST, createBlockScheduleRecordableSettingsRequest)
  yield takeEvery(actions.UPDATE_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST, updateBlockScheduleRecordableSettingsRequest)
  yield takeEvery(actions.GET_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST, getBlockScheduleRecordableSettingsRequest)
  yield takeLatest(actions.GET_TASK_REVIEW_VM_REQUEST, getTaskReviewVMRequest)
  yield takeLatest(actions.CREATE_BLOCK_TASK_REVIEW_REQUEST, createBlockTaskReviewRequest)
  yield takeLatest(actions.UPDATE_BLOCK_TASK_REVIEW_REQUEST, updateBlockTaskReviewRequest)
  yield takeLatest(actions.GET_BLOCK_TASK_REVIEW_REQUEST, getBlockTaskReviewRequest)
  yield takeEvery(actions.GET_BLOCK_SCHEDULE_AUTOMATE_REPORTS_VM_REQUEST, getBlockScheduleAutomateReportsVMRequest)
  yield takeEvery(actions.CREATE_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST, createBlockScheduleAutomateReportsRequest)
  yield takeEvery(actions.UPDATE_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST, updateBlockScheduleAutomateReportsRequest)
  yield takeEvery(actions.GET_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST, getBlockScheduleAutomateReportsRequest)
  yield takeLatest(actions.CREATE_BLOCK_PARTIAL_SUBMISSION_REQUEST, createBlockPartialSubmissionRequest)
  yield takeLatest(actions.GET_PARTIAL_SUBMISSION_VM_REQUEST, getPartialSubmissionVM)
  yield takeLatest(actions.UPDATE_BLOCK_PARTIAL_SUBMISSION_REQUEST, updateBlockPartialSubmissionRequest)
  yield takeLatest(actions.GET_BLOCK_PARTIAL_SUBMISSION_REQUEST, getBlockPartialSubmissionRequest)
  yield takeLatest(actions.GET_BLOCK_TASK_SIGN_OFF_REQUEST, getBlockTaskSignOffRequest)
  yield takeLatest(actions.GET_BLOCK_TASK_SIGN_OFF_VM_REQUEST, getBlockTaskSignOffVMRequest)
  yield takeLatest(actions.CREATE_BLOCK_TASK_SIGN_OFF_REQUEST, createBlockTaskSignOffRequest)
  yield takeLatest(actions.UPDATE_BLOCK_TASK_SIGN_OFF_REQUEST, updateBlockTaskSignOffRequest)
  yield takeLatest(actions.GET_BLOCK_CHECKLIST_VM_REQUEST, getBlockChecklistVMRequest)
  yield takeLatest(actions.GET_BLOCK_CHECKLIST_REQUEST, getBlockChecklistRequest)
  yield takeLatest(actions.CREATE_BLOCK_CHECKLIST_REQUEST, createBlockChecklistRequest)
  yield takeLatest(actions.UPDATE_BLOCK_CHECKLIST_REQUEST, updateBlockChecklistRequest)
  yield takeLatest(actions.DELETE_CUSTOMIZED_CHILD_CAMPAIGN_BLOCK_REQUEST, deleteCustomizedChildCampaignBlockRequest)
  yield takeLatest(actions.GET_FINDING_WORKFLOWS_REQUEST, getFindingWorkflowsRequest)
}