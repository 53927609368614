import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import {
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"
import {
    CustomUILoader,
    FormLoader,
    SideModalCloseButton
} from "@views/components"
import { PreConditionsRemoveHoldModal, PurchaseOrderModal } from "../index"
import { checkIfOnHoldDueToPrecondition } from "./Helpers"

const {
    SCHEDULING: {
        REMOVE_HOLD
    }
} = LANGUAGE_CONSTANTS

const RemoveHoldModal = ({
    intl,
    isAdminTasks = false,
    taskList = [],
    onToggleModal,
    onUpdateRowData,
    onSetTaskStatusAlertContent
}) => {
    const dispatch = useDispatch()

    const [isFetchingOnHoldTaskListDetail, setFetchingOnHoldTaskListDetail] = useState(true)
    const [activeTask, setActiveTask] = useState({})
    const [activeTaskIndex, setActiveTaskIndex] = useState(0)
    const [onHoldStatusReasonsList, setOnHoldStatusReasonsList] = useState([]) // To store task list details regrading if task is on hold due to Preconditions or not

    const handleFetchOnHoldTaskListRequest = useCallback(() => {
        dispatch(actions.getOnHoldTaskListRequest({ observationTaskIds: taskList.map(task => task.observationTaskId) }, (res) => {
            if (res?.length) {
                setOnHoldStatusReasonsList(res)
                const selectedTask = taskList[0]
                setActiveTask(() => ({
                    ...selectedTask,
                    isPrecondition: checkIfOnHoldDueToPrecondition({
                        onHoldStatusReasonsList: res,
                        observationTaskId: selectedTask.observationTaskId
                    })
                }))
            }
            setFetchingOnHoldTaskListDetail(false)
        }))
    }, [taskList])

    useEffect(() => {
        if (taskList.length) {
            handleFetchOnHoldTaskListRequest()
        }
    }, [])

    const taskCountText = useMemo(() => {
        return taskList.length > 1 ? ` (${activeTaskIndex + 1}/${taskList.length})` : ""
    }, [activeTaskIndex, taskList])

    const handleSetNextActiveTask = useCallback(() => {
        const selectedTaskIndex = taskList.findIndex(
            (task) => task.observationTaskId === activeTask.observationTaskId
        )
        if (selectedTaskIndex !== -1) {
            const nextActiveTask = taskList[selectedTaskIndex + 1]
            if (nextActiveTask) {
                setActiveTask(() => ({
                    ...nextActiveTask,
                    isPrecondition: checkIfOnHoldDueToPrecondition({
                        onHoldStatusReasonsList,
                        observationTaskId: nextActiveTask.observationTaskId
                    })
                }))
                setActiveTaskIndex(selectedTaskIndex + 1)
            } else {
                onToggleModal()
            }
        } else {
            onToggleModal()
        }
    }, [
        activeTask,
        onHoldStatusReasonsList,
        taskList,
        onToggleModal
    ])

    return (
        <Modal
            isOpen
            className={"sidebar-sm"}
            modalClassName={"modal-slide-in note-media-modal schedule-time-modal remove-hold-modal"}
            contentClassName="p-0"
        >
            <ModalHeader
                className="mb-2"
                toggle={onToggleModal}
                close={<SideModalCloseButton onClose={onToggleModal} />}
                tag="div"
            >
                <h5 className="modal-title">
                    {getLocaleMessage(intl, REMOVE_HOLD)} {taskCountText}
                </h5>
            </ModalHeader>
            <CustomUILoader isLoading={isFetchingOnHoldTaskListDetail}>
                <ModalBody className="flex-grow-1">
                    {
                        isFetchingOnHoldTaskListDetail ? <FormLoader />
                            : (
                                activeTask.isPrecondition ? <PreConditionsRemoveHoldModal
                                    activeTask={activeTask}
                                    isAdminTasks={isAdminTasks}
                                    onSetNextActiveTask={handleSetNextActiveTask}
                                    onToggleModal={onToggleModal}
                                    onUpdateRowData={onUpdateRowData}
                                    onSetTaskStatusAlertContent={onSetTaskStatusAlertContent}
                                /> : <PurchaseOrderModal
                                    taskList={[activeTask]}
                                    isRemoveHold
                                    isAdminTasks={isAdminTasks}
                                    modalTitle={LANGUAGE_CONSTANTS.SCHEDULING.REMOVE_HOLD}
                                    onSetNextActiveTask={handleSetNextActiveTask}
                                    onToggleModal={onToggleModal}
                                    onUpdateRowData={onUpdateRowData}
                                />
                            )
                    }
                </ModalBody>
            </CustomUILoader>
        </Modal>
    )
}

export default localeMessageWrapper(RemoveHoldModal)
