import config from "./APIConfig"
import { postDataApi } from '../../index'
/**
 * @method createBlockEscalation
 * @param {object} data 
 * 
 * API payload:-
 * {
 *  "wfCampaignSetupId": 0,
 *  "escalations": [
 *      {
 *          "escalationName": "string",
 *          "wfEscalationTypeId": 0,
 *          "wfEscalationCriteriaId": 0,
 *          "wfCriteriaTypeId": 0,
 *          "escalateAfterUnapprovedUnitsPercentage": "string",
 *          "escalateAfterUnapprovedActivitiesNumber": 0,
 *          "escalateAfterDaysNumber": 0,
 *          "escalateAfterUnapprovedUnitsNumber": 0,
 *           "riskLevelIds": [
 *             {
 *              "riskLevelId": 0
 *             }
 *           ],
 *          "contactIds": [
 *              0
 *          ],
 *          "wfContactMethodIds":[
 *               0
 *          ]
 *      }
 *      ]
 * }
 */

export const createBlockEscalation = (data) => {
    const { url } = config.createBlockEscalation
    data.escalations.forEach((item) => {
        delete item.contactIdsValue
        delete item.wfContactMethodIdsValue
    })
    const escalationData = data.escalations.map((item) => ({
        ...item,
        contactIds: item.contactIds?.map(x => ({ contactId: x.contactId })) || [],
        riskLevelIds: item.riskLevelIds.map(riskLevel => ({ riskLevelId: Number(riskLevel.value) })) || []
    }))
    const payload = {
        wfCampaignSetupId: Number(data.wfCampaignSetupId),
        escalations: escalationData
    }
    return postDataApi(url, payload, null, { isApiNeedsToHandleErrors: false })
}

export default createBlockEscalation