import cx from "classnames"
import React, { useCallback, useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ChevronDown, ChevronRight } from 'react-feather'
import { CustomInput } from "reactstrap"

import { EXPANDABLE_TYPE } from "@views/components/table-view/config"
import { onGridBodyScroll } from "./helpers"
import TableHeader from "./TableHeader"
import ExpandabeActivityChildDetail from '../expandable-activity-child-detail'
import LocaleMessageWrapper from "../../locale-message"

const ScrollToLoadTable = ({
  intl,
  className,
  columns,
  data,
  keyField,
  isLoading,
  noDataMessage,
  pageNumber,
  expandableRows,
  expandedRowId,
  expandableRowsType,
  selectableRows,
  selectedRows = [],
  sortServer,
  totalCount,
  hasNextPage,
  onPagination,
  isLoadingChildGrid,
  subData,
  noDataMessageForSubTable,
  tableHeaderRequired = true,
  onSelectedRowsChange,
  onSelectableRowDisabled = () => {
    return false
  },
  onRowExpandToggled,
  onSort
}) => {
  const listRef = useRef()
  const [isRowExpanded, setRowExpanded] = useState(false)
  const [previousScroll, setPreviousScroll] = useState(0)
  const [isAllSelected, setAllSelected] = useState(false)

  useEffect(() => {
    if (!!selectedRows.length && !!data.length && selectedRows.length === data.length) {
      setAllSelected(true)
    }
  }, [data, selectedRows])

  useEffect(() => {
    if (!selectedRows.length) {
      setAllSelected(false)
    }
  }, [selectedRows])

  const handleSelectAllRows = useCallback(() => {
    setAllSelected(!isAllSelected)
    onSelectedRowsChange(isAllSelected ? [] : [...data.filter(row => !onSelectableRowDisabled(row))])
  }, [data, isAllSelected, onSelectedRowsChange, onSelectableRowDisabled])

  const handleSelectRow = useCallback(
    (selected) => {
      const updatedList = [...selectedRows]
      const index = updatedList.findIndex(
        (item) => item[keyField] === selected[keyField]
      )
      if (index === -1) {
        updatedList.push(selected)
      } else {
        updatedList.splice(index, 1)
      }

      setAllSelected(data.length === updatedList.length)
      onSelectedRowsChange(updatedList)
    },
    [data, selectedRows, onSelectedRowsChange]
  )

  const handleLoadNextPage = useCallback(() => {
    if (!isLoading && hasNextPage) {
      onPagination({ page: pageNumber + 1, isLoadMore: true }, () => {
        setAllSelected(false)
        listRef.current.scrollTo(0, previousScroll - 100)
        setPreviousScroll(prevState => prevState - 100)
      })
    }
  }, [isLoading, pageNumber, previousScroll, data, hasNextPage, onPagination])

  const handleListScroll = useCallback(() => {
    onGridBodyScroll()
    const target = listRef.current
    if (!!target) {
      if (
        previousScroll < target.scrollTop &&
        target.scrollTop + target.clientHeight >= target.scrollHeight - 10
      ) {
        handleLoadNextPage()
      }
      setPreviousScroll(target.scrollTop)
    }
  }, [previousScroll, handleLoadNextPage])

  const handleRowExpandToggled = useCallback((row, value) => {
    if (!isRowExpanded) {
      onRowExpandToggled(true, row)
      setRowExpanded(true)
    } else if (isRowExpanded && expandedRowId !== value) {
      onRowExpandToggled(true, row)
    } else {
      setRowExpanded('')
      onRowExpandToggled(false, row)
    }
  }, [expandedRowId, expandableRowsType, isRowExpanded, onRowExpandToggled])

  return (
    <div className={cx("table-responsive react-dataTable scroll-to-load-more-table", { [className]: true })}>
      <table className="table rdt_Table">
        {tableHeaderRequired && <TableHeader
          expandableRows={expandableRows}
          columns={columns}
          isAllSelected={isAllSelected}
          isLoading={isLoading}
          isDisableAllSelect={(isLoading || !data.length)}
          selectableRows={selectableRows}
          sortServer={sortServer}
          onSelectAllRows={handleSelectAllRows}
          onSort={onSort}
        />}
        <tbody
          ref={listRef}
          className="rdt_TableBody scroll-to-load-body"
          onScroll={handleListScroll}
          >
          {data.map((row) => {
            const isChecked = !!selectedRows.find((i) => i[keyField] === row[keyField])

            return (
              <>
                <tr
                  key={`row-key-${row[keyField]}`}
                  className={cx("rdt_TableRow", {
                    "selected-row": isChecked
                  })}
                >
                  {
                    expandableRows && <td className="expand-icon" onClick={() => handleRowExpandToggled(row, row[keyField])} >
                      {row[keyField] === expandedRowId ? <ChevronDown size={23} color={'gray'} />
                        : <ChevronRight size={23} color={'gray'} />
                      }
                    </td>
                  }
                  {selectableRows && (
                    <td className="rdt_TableCell row-select-checkbox-col">
                      <CustomInput
                        type="checkbox"
                        checked={isChecked}
                        disabled={onSelectableRowDisabled(row)}
                        id={`row_${row[keyField]}`}
                        onChange={() => handleSelectRow(row)}
                      />
                    </td>
                  )}
                  {columns.map((col, index) => {
                    return (
                      <td className="rdt_TableCell" key={`row-data-cell-${row[keyField]}-${index}`}>
                        {col.cell
                          ? col.cell(row)
                          : col.format
                            ? col.format(row)
                            : col.selector
                              ? row[col.selector]
                              : ""}
                      </td>
                    )
                  })}
                </tr>
                {
                  (expandableRowsType === EXPANDABLE_TYPE.CHECKPOINTS && row[keyField] === expandedRowId) &&
                  <ExpandabeActivityChildDetail
                    expandedRowId={expandedRowId}
                    data={row}
                    isLoadingChildGrid={isLoadingChildGrid}
                    subData={subData}
                    noDataMessage={noDataMessageForSubTable}
                  />
                }
              </>
            )
          })}
          {(isLoading || !data.length) && <div className="no-data-txt">{noDataMessage}</div>}
        </tbody>
      </table>
    </div>
  )
}

ScrollToLoadTable.propTypes = {
  intl: PropTypes.object,
  columns: PropTypes.array,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  keyField: PropTypes.string,
  noDataMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pageNumber: PropTypes.number,
  selectableRows: PropTypes.bool,
  selectedRows: PropTypes.array,
  totalCount: PropTypes.number,
  sortServer: PropTypes.bool,
  tableHeaderRequired: PropTypes.bool,
  onPagination: PropTypes.func,
  onSelectedRowsChange: PropTypes.func,
  onSort: PropTypes.func
}

export default LocaleMessageWrapper(ScrollToLoadTable)
