import moment from "moment"

import config from "./APIConfig"
import { putDataApi } from "../../index"

/**
 * @method updateBlockScheduleAutomateReports
 * @param {object} data 
 * 
 * API payload:-
 * 
 * {
  "wfCampaignSetupId": 0,
  "scheduleAutomateReports": [
    {
      "scheduleAutomateReportGuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "reportId": 0,
      "assignmentTypeId": 1,
      "siteRoleIds": [
        {
          "siteRoleId": 0
        }
      ],
      "contactIds": [
        {
          "contactId": 0
        }
      ],
      "organizationRoleIds": [
        {
          "sitePartyRoleId": 0
        }
      ],
      "contactGroupIds": [
        {
          "contactGroupId": 0
        }
      ],
      "wfbReportingFrequencyId": 1,
      "daysOfMonth": [
        0
      ],
      "dayOfWeekIds": [
        1
      ],
      "timeOfDay": "2023-06-12T06:41:16.643Z"
    }
  ]
* }
 * 
 */

export const updateBlockScheduleAutomateReports = async (data) => {
  const { url } = config.updateBlockScheduleAutomateReports
  const valueType = data.isSharedCampaign ? "value" : "sitePartyRoleId"
  const updatedScheduleAutomateReports = data.automateReports.map((item) => (
    {
      scheduleAutomateReportGuid: item.automateReportGuid,
      reportId: Number(item.windwardReport.value),
      assignmentTypeId: Number(item.assignmentType.value),
      siteRoleIds: item.siteRoleIds.map((role) => ({ siteRoleId: Number(role[valueType]) })),
      contactIds: item.contactIds.map((contact) => ({ contactId: Number(contact.contactId) })),
      contactGroupIds: item.contactGroupIds.map((contact) => ({ contactGroupId: Number(contact.value) })),
      wfbReportingFrequencyId: Number(item.frequencyCycle.value),
      daysOfMonth: item.dayOfMonth.map(month => Number(month.value)),
      dayOfWeekIds: item.dayOfWeek.map(week => Number(week.value)),
      timeOfDay: item.time ? moment(item.time).format("YYYY-MM-DD[T]HH:mm:ss") : null,
      organizationRoleIds: item.organizationRoleIds.map((role) => ({sitePartyRoleId: Number(role[valueType])})),
      reportAttachTypeId: Number(item.includeFileAs.value)
    }))

  const payload = {
    wfCampaignSetupId: data.wfCampaignSetupId,
    scheduleAutomateReports: updatedScheduleAutomateReports
  }

  return await putDataApi(url(data), payload)
}

export default updateBlockScheduleAutomateReports
