import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { LocaleMessage } from '@views/components'
import EnergyModelList from '@views/organizations/overview/organization-details/plans/energy-model-list'
import { GridContext } from "./ag-data-grid/GridContext"

const { LOADER, NO_RECORDS_TO_DISPLAY } = LANGUAGE_CONSTANTS

const ExpandableOrganisationPlanBlueprintList = ({
  data
}) => {
  const dispatch = useDispatch()
  const context = useContext(GridContext)

  const [isLoadingChildGrid, setLoadingChildGrid] = useState(false)
  const [isGridFetched, setGridFetched] = useState(false)
  const [tableData, setTableData] = useState([])

  const handleFetchTableData = useCallback(() => {
    setLoadingChildGrid(true)
    dispatch(
      actions.getOrganizationPlanBlueprintEnergyModelListRequest(
        {
          organizationId: context.organizationId,
          organizationPlanId: context.organizationPlanId,
          blueprintId: data.organizationBluePrintId
        },
        (res) => {
          setGridFetched(true)
          if (res) {
            setTableData(res)
          }
          setLoadingChildGrid(false)
        }
      )
    )
  }, [data, context.organizationId, context.organizationPlanId])

  useEffect(() => {
    if (!isGridFetched && !isLoadingChildGrid) {
      handleFetchTableData({ page: 1 })
    }
  }, [
    isGridFetched,
    isLoadingChildGrid
  ])

  if (!isGridFetched && isLoadingChildGrid) {
    return <p className="no-data-txt template-grid-height"><LocaleMessage id={LOADER.LOADING} /></p>
  }

  if (!isLoadingChildGrid && !tableData?.length) {
    return <p className="no-data-txt"><LocaleMessage id={NO_RECORDS_TO_DISPLAY} /></p>
  }

  return (<div className="expandable-right-content template-grid-height" >
    <EnergyModelList
      list={tableData}
      organizationId={context.organizationId}
      organizationPlanId={context.organizationPlanId}
      organizationBluePrintId={data.organizationBluePrintId}
    />
  </div>
  )
}

ExpandableOrganisationPlanBlueprintList.propTypes = {
  data: PropTypes.object
}

export default ExpandableOrganisationPlanBlueprintList
