import { useState, useCallback, useMemo, useContext } from "react"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import RowActionsComponent from "@views/components/table-view/row-actions"
import { ROW_ACTION_TYPES } from "@views/components/table-view/row-actions/config"
import { AdditionalPurchaseOrderContext } from './index'

const {
  SCHEDULING: { ADDITIONAL_PURCHASE_ORDER } } = LANGUAGE_CONSTANTS

const RowActions = ({ data }) => {

  const context = useContext(AdditionalPurchaseOrderContext)

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false)

  const config = useMemo(() => {
    const actionTypes = [ROW_ACTION_TYPES.EDIT, ROW_ACTION_TYPES.DELETE]
    return {
      actionTypes,
      deleteModal: {
        labelLocaleId: ADDITIONAL_PURCHASE_ORDER
      }
    }
  }, [])

  const handleEditAdditionalPurchaseOrderForm = useCallback(() => {
    context.onEditMode(data)
  }, [data, context.onEditMode])

  const handleToggleDeleteModal = useCallback(() => {
    setOpenDeleteModal((prevState) => !prevState)
  })

  const handleDelete = useCallback((id) => {
    context.onDeleteAdditionalPurchaseOrder(id)
    handleToggleDeleteModal()
  },
    [context.onDeleteAdditionalPurchaseOrder, handleToggleDeleteModal]
  )

  const handleClickActionItem = useCallback((id) => {
    if (id === ROW_ACTION_TYPES.EDIT) {
      handleEditAdditionalPurchaseOrderForm()
    } else if (id === ROW_ACTION_TYPES.DELETE) {
      handleToggleDeleteModal()
    }
  }, [handleEditAdditionalPurchaseOrderForm, handleToggleDeleteModal])

  return (
    <RowActionsComponent
      config={config}
      rowId={data.additionalPOId}
      isOpenDeleteModal={isOpenDeleteModal}
      onClickActionItem={handleClickActionItem}
      onDeleteRow={(e) => handleDelete(e, data.additionalPOId)}
      onToggleDeleteModal={handleToggleDeleteModal}
    />
  )
}

export default RowActions
