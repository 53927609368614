import { getSearchQuery } from '../config'

const apiConfig = {
  getActivityList: {
    method: "GET",
    url: ({
      pageSize,
      pageNumber,
      orderColumn,
      orderDirections,
      genericSearch = "",
      advanceSearch = {},
      activityType = ""
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ActivityName", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        `${orderDirections ? `&OrderDirections=${orderDirections}` : ''}`,
        getSearchQuery("ActivityTypeId", activityType)
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          activityName,
          activityId,
          activityVersionId,
          activityType,
          standardActivity,
          riskLevelIds,
          responsibleSiteRoleIds,
          assemblyIds,
          assemblyTypeIds,
          componentIds,
          subComponentIds,
          manufacturerIds,
          brandIds,
          regulationName,
          standardName,
          courses,
          subjects,
          chapters,
          lessons,
          source,
          author
        } = advanceSearch

        const riskLevelList = riskLevelIds.map((id) => getSearchQuery("RiskLevelIds", id)).join("")
        const responsibleSiteRoleList = responsibleSiteRoleIds.map((id) => getSearchQuery("ResponsibleSiteRoleIds", id)).join("")
        const assemblyList = assemblyIds.map((id) => getSearchQuery("AssemblyIds", id)).join("")
        const assemblyTypeList = assemblyTypeIds.map((id) => getSearchQuery("AssemblyTypeIds", id)).join("")
        const componentList = componentIds.map((id) => getSearchQuery("ComponentIds", id)).join("")
        const subComponentList = subComponentIds.map((id) => getSearchQuery("SubComponentIds", id)).join("")
        const manufacturerList = manufacturerIds.map((id) => getSearchQuery("ManufacturerIds", id)).join("")
        const brandList = brandIds.map((id) => getSearchQuery("BrandIds", id)).join("")
        const courseList = courses.map((id) => getSearchQuery("Courses", id)).join("")
        const subjectList = subjects.map((id) => getSearchQuery("Subjects", id)).join("")
        const chapterList = chapters.map((id) => getSearchQuery("Chapters", id)).join("")
        const lessonList = lessons.map((id) => getSearchQuery("Lessons", id)).join("")

        searchString = [
          searchString,
          getSearchQuery("ActivityName", !genericSearch ? activityName : '', false, true),
          getSearchQuery("ActivityId", activityId),
          getSearchQuery("ActivityVersionId", activityVersionId),
          getSearchQuery("ActivityTypeId", activityType),
          getSearchQuery("Standard", standardActivity),
          riskLevelList,
          responsibleSiteRoleList,
          assemblyList,
          assemblyTypeList,
          componentList,
          subComponentList,
          manufacturerList,
          brandList,
          getSearchQuery("RegulationName", regulationName, false, true),
          getSearchQuery("StandardName", standardName, false, true),
          courseList,
          subjectList,
          chapterList,
          lessonList,
          getSearchQuery("Source", source, false, true),
          getSearchQuery("Author", author, false, true)
        ].join('')
      }
      return `api/Checkpoint/ActivityList${searchString}`
    }
  },
  getActivityVM: {
    method: 'GET',
    url: "api/Checkpoint/ActivityList/vm?entityId=1"
  }
}

export default apiConfig