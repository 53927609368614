import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addOrganizationPlanBlueprintEnergyModel : To add new Blueprint Energy Model 
 * @param {*} data 
 * 
 * Payload: 
 * {
  "organizationBluePrintId": 0,
  "organizationPlanId": 0,
  "energyModelName": "string"
}
 * 
 * @returns 
 */
export const addOrganizationPlanBlueprintEnergyModel = (data) => {
  const { url } = config.addOrganizationPlanBlueprintEnergyModel
  return postDataApi(url(data), data, undefined, { pathConfig: { isCheckpointInspectionService: true } })
}

export default addOrganizationPlanBlueprintEnergyModel
