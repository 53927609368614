import { takeLatest, call, put, takeEvery } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

const { ACTIONS_PAGE_SAGA } = LANGUAGE_CONSTANTS

/** getActionList API **/
function* getActionListRequest(action) {
  try {
    const response = yield call(services.getActionList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** updateAcceptByTrade API **/
function* updateAcceptByTradeRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateAcceptByTrade, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.ACCEPT_BY_TRADE_UPDATE_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_IN_UPDATING_ACCEPT_BY_TRADE })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateVerifyBySuperintendent API **/
function* updateVerifyBySuperintendentRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateVerifyBySuperintendent, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.VERIFY_BY_SUPERINTENDENT_UPDATE_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_IN_UPDATING_VERIFY_BY_SUPERINTENDENT })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/**  getActionListVM API **/
function* getActionListVMRequest(action) {
  try {
    const response = yield call(services.getActionListVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** createConfirmSiteVisit API */
function* createConfirmSiteVisitRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.createConfirmSiteVisit, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.CONFIRM_SITE_VISIT_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_IN_CONFIRMING_SITE_VISIT })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getActionDetails API **/
function* getActionDetailsRequest(action) {
  try {
    const response = yield call(services.getActionDetails, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getActionAuditTrail API **/
function* getActionAuditTrailRequest(action) {
  try {
    const response = yield call(services.getActionAuditTrail, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTradeContactToReassign API **/
function* getTradeContactToReassignRequest(action) {
  try {
    const response = yield call(services.getTradeContactToReassign, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateTradeActionReassign API **/
function* updateTradeActionReassignRequest(action) {
  try {
    const response = yield call(services.updateTradeActionReassign, action.payload)
    action.callback(response?.data)
    Notification({ status: 200, message: ACTIONS_PAGE_SAGA.UPDATE_CONTACT_TRADE_REASSIGN_SUCCESS })
  } catch (error) {
    action.callback()
  }
}

/** getSuperintendentToReassign API **/
function* getSuperintendentToReassignRequest(action) {
  try {
    const response = yield call(services.getSuperintendentToReassign, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateSuperintendentActionReassign API **/
function* updateSuperintendentActionReassignRequest(action) {
  try {
    const response = yield call(services.updateSuperintendentActionReassign, action.payload)
    action.callback(response?.data)
    Notification({ status: 200, message: ACTIONS_PAGE_SAGA.UPDATE_CONTACT_TRADE_REASSIGN_SUCCESS })
  } catch (error) {
    action.callback()
  }
}

/** getReassignFailedInspectionContact API **/
function* getReassignFailedInspectionContactRequest(action) {
  try {
    const response = yield call(services.getReassignFailedInspectionContact, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateReassignFailedInspection API **/
function* updateReassignFailedInspectionRequest(action) {
  try {
    const response = yield call(services.updateReassignFailedInspection, action.payload)
    action.callback(response?.data)
    Notification({ status: 200, message: ACTIONS_PAGE_SAGA.UPDATE_REASSIGN_FAILED_INSPECTION_SUCCESS })
  } catch (error) {
    action.callback()
  }
}

/** getCorrectionDetails API **/
function* getCorrectionDetailsRequest(action) {
  try {
    const response = yield call(services.getCorrectionDetails, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getConfirmSiteVisitOverdueWidget API **/
function* getConfirmSiteVisitOverdueWidgetRequest(action) {
  try {
    const response = yield call(services.getConfirmSiteVisitOverdueWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getFailedInspectionWidget API **/
function* getFailedInspectionWidgetRequest(action) {
  try {
    const response = yield call(services.getFailedInspectionWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getFailedInspectionOverdueWidget API **/
function* getFailedInspectionOverdueWidgetRequest(action) {
  try {
    const response = yield call(services.getFailedInspectionOverdueWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getVerifyBySuperIntendentWidget API **/
function* getVerifyBySuperIntendentWidgetRequest(action) {
  try {
    const response = yield call(services.getVerifyBySuperIntendentWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getVerifyBySuperIntendentOverdueWidget API **/
function* getVerifyBySuperIntendentOverdueWidgetRequest(action) {
  try {
    const response = yield call(services.getVerifyBySuperIntendentOverdueWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getReassignFailedInspectionCorrectionWidget API **/
function* getReassignFailedInspectionCorrectionWidgetRequest(action) {
  try {
    const response = yield call(services.getReassignFailedInspectionCorrectionWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getReassignFailedInspectionCorrectionOverdueWidget API **/
function* getReassignFailedInspectionCorrectionOverdueWidgetRequest(action) {
  try {
    const response = yield call(services.getReassignFailedInspectionCorrectionOverdueWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getConfirmSiteVisitWidget API **/
function* getConfirmSiteVisitWidgetRequest(action) {
  try {
    const response = yield call(services.getConfirmSiteVisitWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getReadyActionCountWidget API **/
function* getReadyActionCountWidgetRequest(action) {
  try {
    const response = yield call(services.getReadyActionCountWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getReadyActionOverdueCountWidget API **/
function* getReadyActionOverdueCountWidgetRequest(action) {
  try {
    const response = yield call(services.getReadyActionOverdueCountWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getEntityContacts API **/
function* getEntityContactsRequest(action) {
  try {
    const response = yield call(services.getEntityContacts, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBulkAssignActionItems API **/
function* updateBulkAssignActionItemsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateBulkAssignActionItems, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.UPDATE_BULK_ASSIGN_ACTION_ITEMS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_BULK_ASSIGN_ACTION_ITEMS })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/**  getSignOffReasonVM API **/
function* getSignOffReasonVMRequest(action) {
  try {
    const response = yield call(services.getSignOffReasonVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateSignOff API **/
function* updateSignOffRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateSignOff, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.UPDATE_SIGN_OFF_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_IN_SIGN_OFF_ACTION })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateReassignSignOff API **/
function* updateReassignSignOffRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateReassignSignOff, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.UPDATE_REASSIGN_SIGNOFF_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_IN_REASSIGN_SIGNOFF })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateBulkVerificationAction API **/
function* updateBulkVerificationActionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateBulkVerificationAction, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.VERIFY_BY_SUPERINTENDENT_UPDATE_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_IN_UPDATING_VERIFY_BY_SUPERINTENDENT })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateBulkConfirmSiteVisitAction API */
function* updateBulkConfirmSiteVisitActionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateBulkConfirmSiteVisitAction, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.CONFIRM_SITE_VISIT_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_IN_CONFIRMING_SITE_VISIT })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateBulkCorrectionAction API **/
function* updateBulkCorrectionActionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateBulkCorrectionAction, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.ACCEPT_BY_TRADE_UPDATE_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_IN_UPDATING_ACCEPT_BY_TRADE })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateBulkAssignCorrectionAction API **/
function* updateBulkAssignCorrectionActionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateBulkAssignCorrectionAction, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.UPDATE_REASSIGN_FAILED_INSPECTION_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_IN_UPDATING_REASSIGN_FAILED_INSPECTION })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateBulkSignOffAction API */
function* updateBulkSignOffActionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateBulkSignOffAction, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.UPDATE_SIGN_OFF_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_IN_SIGN_OFF_ACTION })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateBulkAssignSignOffAction API */
function* updateBulkAssignSignOffActionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateBulkAssignSignOffAction, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.UPDATE_REASSIGN_SIGNOFF_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_IN_REASSIGN_SIGNOFF })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

export default function* actionsPageSagas() {
  yield takeLatest(actions.GET_ACTION_LIST_REQUEST, getActionListRequest)
  yield takeLatest(actions.UPDATE_ACCEPT_BY_TRADE_REQUEST, updateAcceptByTradeRequest)
  yield takeLatest(actions.UPDATE_VERIFY_BY_SUPERINTENDENT_REQUEST, updateVerifyBySuperintendentRequest)
  yield takeLatest(actions.GET_ACTION_LIST_VM_REQUEST, getActionListVMRequest)
  yield takeLatest(actions.CREATE_CONFIRM_SITE_VISIT_REQUEST, createConfirmSiteVisitRequest)
  yield takeLatest(actions.GET_ACTION_DETAILS_REQUEST, getActionDetailsRequest)
  yield takeLatest(actions.GET_ACTION_AUDIT_TRAIL_REQUEST, getActionAuditTrailRequest)
  yield takeLatest(actions.GET_TRADE_CONTACT_TO_REASSIGN, getTradeContactToReassignRequest)
  yield takeLatest(actions.UPDATE_TRADE_ACTION_REASSIGN, updateTradeActionReassignRequest)
  yield takeLatest(actions.GET_SUPERINTENDENT_TO_REASSIGN, getSuperintendentToReassignRequest)
  yield takeLatest(actions.UPDATE_SUPERINTENDENT_ACTION_REASSIGN, updateSuperintendentActionReassignRequest)
  yield takeLatest(actions.GET_REASSIGN_FAILED_INSPECTION_CONTACT_REQUEST, getReassignFailedInspectionContactRequest)
  yield takeLatest(actions.UPDATE_REASSIGN_FAILED_INSPECTION_REQUEST, updateReassignFailedInspectionRequest)
  yield takeLatest(actions.GET_CORRECTION_DETAILS_REQUEST, getCorrectionDetailsRequest)
  yield takeLatest(actions.GET_CONFIRM_SITE_VISIT_OVERDUE_WIDGET_REQUEST, getConfirmSiteVisitOverdueWidgetRequest)
  yield takeLatest(actions.GET_FAILED_INSPECTION_WIDGET_REQUEST, getFailedInspectionWidgetRequest)
  yield takeLatest(actions.GET_FAILED_INSPECTION_OVERDUE_WIDGET_REQUEST, getFailedInspectionOverdueWidgetRequest)
  yield takeLatest(actions.GET_VERIFY_BY_SUPER_INTENDENT_WIDGET_REQUEST, getVerifyBySuperIntendentWidgetRequest)
  yield takeLatest(actions.GET_VERIFY_BY_SUPER_INTENDENT_OVERDUE_WIDGET_REQUEST, getVerifyBySuperIntendentOverdueWidgetRequest)
  yield takeLatest(actions.GET_REASSIGN_FAILED_INSPECTION_CORRECTION_WIDGET_REQUEST, getReassignFailedInspectionCorrectionWidgetRequest)
  yield takeLatest(actions.GET_REASSIGN_FAILED_INSPECTION_CORRECTION_OVERDUE_WIDGET_REQUEST, getReassignFailedInspectionCorrectionOverdueWidgetRequest)
  yield takeLatest(actions.GET_CONFIRM_SITE_VISIT_WIDGET_REQUEST, getConfirmSiteVisitWidgetRequest)
  yield takeLatest(actions.GET_READY_ACTION_COUNT_WIDGET_REQUEST, getReadyActionCountWidgetRequest)
  yield takeLatest(actions.GET_READY_ACTION_OVERDUE_COUNT_WIDGET_REQUEST, getReadyActionOverdueCountWidgetRequest)
  yield takeLatest(actions.GET_ENTITY_CONTACTS_REQUEST, getEntityContactsRequest)
  yield takeLatest(actions.UPDATE_BULK_ASSIGN_ACTION_ITEMS, updateBulkAssignActionItemsRequest)
  yield takeLatest(actions.GET_SIGN_OFF_REASON_VM_REQUEST, getSignOffReasonVMRequest)
  yield takeLatest(actions.UPDATE_SIGNOFF_REQUEST, updateSignOffRequest)
  yield takeLatest(actions.UPDATE_REASSIGN_SIGNOFF_REQUEST, updateReassignSignOffRequest)
  yield takeLatest(actions.UPDATE_BULK_VERIFICATION_ACTION_REQUEST, updateBulkVerificationActionRequest)
  yield takeLatest(actions.UPDATE_BULK_CONFIRM_SITE_VISIT_ACTION_REQUEST, updateBulkConfirmSiteVisitActionRequest)
  yield takeLatest(actions.UPDATE_BULK_CORRECTION_ACTION_REQUEST, updateBulkCorrectionActionRequest)
  yield takeLatest(actions.UPDATE_BULK_ASSIGN_CORRECTION_ACTION_REQUEST, updateBulkAssignCorrectionActionRequest)
  yield takeLatest(actions.UPDATE_BULK_SIGN_OFF_ACTION_REQUEST, updateBulkSignOffActionRequest)
  yield takeLatest(actions.UPDATE_BULK_ASSIGN_SIGN_OFF_ACTION_REQUEST, updateBulkAssignSignOffActionRequest)
}