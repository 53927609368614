import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addProjectSiteAutoGenerateUnits
 * @param {object} data 
 * {
  "unitTypeId": 0,
  "siteId": 0,
  "streetAddress": "string",
  "unitAddress": "string",
  "city": "string",
  "state": "string",
  "zipCode": "string",
  "countryCode": "string",
  "block": "string",
  "building": "string",
  "floor": "string",
  "permitNumber": "string",
  "phase": "string",
  "phaseStartDate": "2021-09-09T06:45:12.066Z",
  "sitePlanId": 0,
  "unitRangeMin": 0,
  "unitRangeMax": 0,
  "unitPrefix": "string",
  "useSameAddress": true,
  "useSamePlan": true,
  "useSiteAddress": false
}
 *
 */
export const addProjectSiteAutoGenerateUnits = (data) => {
  const { url } = config.addProjectSiteAutoGenerateUnits
  return postDataApi(url(data.siteId), data)
}

export default addProjectSiteAutoGenerateUnits
