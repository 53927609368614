import config from "../APIConfig"
import { putDataApi } from "../../../index"

/**
 * @method reorderActivityGroup
 * @param {object} data
 * {
  "groupId": 0,
  "prev_Node": 0,
  "next_Node": 0,
  "isReviewActivity": 0
}
 */

export const reorderActivityGroup = (data) => {
    const { url } = config.reorderActivityGroup
    return putDataApi(url, data)
}

export default reorderActivityGroup
