import config from "../APIConfig"
import { putDataApi } from "../../../index"

/**
 * @method reorderGroupActivitiesList
 * 
 * @param data 
 * {
  "wfCampaignSetupActivityId": 0,
  "prev_Node": 0,
  "next_Node": 0
}
 * @returns 
 */
export const reorderGroupActivitiesList = (payload) => {
  const { url } = config.reorderGroupActivitiesList

  return putDataApi(url, payload)
}

export default reorderGroupActivitiesList
