import config from "./APIConfig"
import { putDataApi } from '../index'

export const updateCopyAllowedActivity = (data) => {
  const { url } = config.updateCopyAllowedActivity

  return putDataApi(url(data), data)
}

export default updateCopyAllowedActivity
