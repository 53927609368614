import config from "./APIConfig"
import { getDataApi } from "../index"

export const getTaskAssignmentDownload = async (data) => {
  const { url } = config.getTaskAssignmentDownload
  
  return await getDataApi(
    url(data),
    {
      "Content-Type": "multipart/form-data"
    },
    {
      responseType: "arraybuffer"
    }
  )
}

export default getTaskAssignmentDownload
