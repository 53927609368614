
export const ADD_ACTIVITY_GROUP_REQUEST = 'ADD_ACTIVITY_GROUP_REQUEST'
export const GET_GROUP_LIST_REQUEST = 'GET_GROUP_LIST_REQUEST'
export const GET_GROUP_ACTIVITIES_LIST_REQUEST = 'GET_GROUP_ACTIVITIES_LIST_REQUEST'
export const DELETE_ACTIVITY_GROUP_REQUEST = 'DELETE_ACTIVITY_GROUP_REQUEST'
export const ADD_ACTIVITIES_TO_GROUP_REQUEST = 'ADD_ACTIVITIES_TO_GROUP_REQUEST'
export const UPDATE_ACTIVITY_GROUP_REQUEST = 'UPDATE_ACTIVITY_GROUP_REQUEST'
export const DELETE_ACTIVITY_FROM_GROUP_REQUEST = 'DELETE_ACTIVITY_FROM_GROUP_REQUEST'
export const REORDER_GROUP_ACTIVITIES_LIST_REQUEST = 'REORDER_GROUP_ACTIVITIES_LIST_REQUEST'
export const REORDER_ACTIVITY_GROUP_REQUEST = 'REORDER_ACTIVITY_GROUP_REQUEST'

/****************************************************/
/** ADD_ACTIVITY_GROUP_REQUEST **/
/****************************************************/

/**
 * @method addActivityGroupRequest :  To add new activities group for checklist activities
 *
 */
export const addActivityGroupRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_ACTIVITY_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_GROUP_LIST_REQUEST **/
/****************************************************/

/**
 * @method getGroupListRequest :  To get groups list for checklist activities
 *
 */
export const getGroupListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_GROUP_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_GROUP_ACTIVITIES_LIST_REQUEST **/
/****************************************************/

/**
 * @method getGroupActivitiesListRequest :  To get group activities list for checklist activities
 *
 */
export const getGroupActivitiesListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_GROUP_ACTIVITIES_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_ACTIVITY_GROUP_REQUEST **/
/****************************************************/

/**
 * @method deleteActivityGroupRequest : To delete checklist activity group
 *
 */
export const deleteActivityGroupRequest = (payload, callback) => {
  return {
    type: DELETE_ACTIVITY_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_ACTIVITIES_TO_GROUP_REQUEST **/
/****************************************************/

/**
 * @method addActivitiesToGroupRequest :  To add checklist activities to group for checklist activities
 *
 */
export const addActivitiesToGroupRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_ACTIVITIES_TO_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_ACTIVITY_GROUP_REQUEST **/
/****************************************************/

/**
 * @method updateActivityGroupRequest :  To update activities group for checklist activities
 *
 */
export const updateActivityGroupRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_ACTIVITY_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_ACTIVITY_FROM_GROUP_REQUEST **/
/****************************************************/

/**
 * @method deleteActivityFromGroupRequest : To delete checklist activity from already added activities to group
 *
 */
export const deleteActivityFromGroupRequest = (payload, callback) => {
  return {
    type: DELETE_ACTIVITY_FROM_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** REORDER_GROUP_ACTIVITIES_LIST_REQUEST **/
/****************************************************/

/**
 * @method reorderGroupActivitiesListRequest : To reorder group's activities list
 *
 */
export const reorderGroupActivitiesListRequest = (payload, callback) => {
  return {
    type: REORDER_GROUP_ACTIVITIES_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** REORDER_ACTIVITY_GROUP_REQUEST **/
/****************************************************/

/**
 * @method reorderActivityGroupRequest :  To update the order of activity groups
 *
 */
 export const reorderActivityGroupRequest = (payload, callback = () => { }) => {
  return {
    type: REORDER_ACTIVITY_GROUP_REQUEST,
    payload,
    callback
  }
}
