import config from "./APIConfig"
import { getDataApi } from "../index"

export const getFileTypeDDL = async (data) => {
  const { url } = config.getFileTypeDDL
  return await getDataApi(url(data))
}

export const getResourceCenterFileCategoryDDL = async () => {
  const { url } = config.getResourceCenterFileCategoryDDL
  return await getDataApi(url)
}

export const getResourceList = async (data) => {
  const { url } = config.getResourceList
  return await getDataApi(url(data))
}

export const getResourceListVM = async () => {
  const { url } = config.getResourceListVM
  return await getDataApi(url)
}

export const getUploadedByDDL = async (data) => {
  const { url } = config.getUploadedByDDL
  return await getDataApi(url(data))
}
