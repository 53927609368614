import React from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { Info, AlertTriangle } from 'react-feather'
import PropTypes from 'prop-types'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"

import localeMessageWrapper from '../locale-message'

const { DELETE_CONFIRMATION_MODAL, TABLE, CANCEL } = LANGUAGE_CONSTANTS

const DeleteConfirmationModal = ({
  intl,
  type,
  primaryActionLabel = '',
  open,
  id,
  isShowDeleteButton,
  upperText,
  isWarningIcon = false,
  lowerText,
  isDeleteConfirmed,
  onToggleModal,
  onConfirmDeleteRecord,
  children
}) => {

  return (
    <Modal
      isOpen={open}
      className='modal-dialog-centered all-modal'
    >
      <ModalBody className="text-center p-0">
        {isWarningIcon ? <AlertTriangle size={80} className="mb-2 warning" /> : <Info size={80} className="mb-2 info" />}
        {children}
        <h3>{getLocaleMessage(intl, upperText || DELETE_CONFIRMATION_MODAL.UPPER_TEXT)}</h3>
        <h6 className="mb-1">{getLocaleMessage(intl, lowerText || DELETE_CONFIRMATION_MODAL.LOWER_TEXT)}</h6>
      </ModalBody>
      <ModalFooter className="justify-content-center border-0 p-0">
        {isShowDeleteButton && <Button disabled={isDeleteConfirmed} outline className="delete-btn mr-1" onClick={() => onConfirmDeleteRecord(id)}>
          {isDeleteConfirmed ? getLocaleMessage(intl, TABLE.DELETION_CONFIRMED) : !!primaryActionLabel ? getLocaleMessage(intl, primaryActionLabel) : `${getLocaleMessage(intl, TABLE.DELETE)} ${getLocaleMessage(intl, type)}`}
        </Button>}{' '}
        <Button disabled={isDeleteConfirmed} className="bg-btn" onClick={onToggleModal}>
          {getLocaleMessage(intl, CANCEL)}
        </Button>{' '}
      </ModalFooter>
    </Modal>
  )
}

DeleteConfirmationModal.propTypes = {
  intl: PropTypes.object,
  type: PropTypes.string,
  isShowDeleteButton: PropTypes.bool,
  primaryActionLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  open: PropTypes.bool,
  isDeleteConfirmed: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onToggleModal: PropTypes.func,
  onConfirmDeleteRecord: PropTypes.func
}

DeleteConfirmationModal.defaultProps = {
  primaryActionLabel: "",
  upperText: "",
  lowerText: "",
  isShowDeleteButton: true,
  isDeleteConfirmed: false
}

export default localeMessageWrapper(DeleteConfirmationModal)
