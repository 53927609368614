import { takeLatest, call } from "redux-saga/effects"

import * as actions from "@store/actions"
import {
    getIntercomSecureHMAC
} from "@services/api"

/** getIntercomSecureHmac API **/
function* getIntercomSecureHmacRequest(action) {
  try {
    const response = yield call(getIntercomSecureHMAC, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export function* intercomSagas() {
  yield takeLatest(actions.GET_INTERCOM_SECURE_HMAC_REQUEST, getIntercomSecureHmacRequest)
}

export default intercomSagas
