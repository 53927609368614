import config from "./APIConfig"
import { getDataApi } from "../index"

export const getAllCourses = async (data) => {
  const { url } = config.getAllCourses
  return await getDataApi(url(data))
}

export const getTemplateTrainingActivity = async (data) => {
  const { url } = config.getTemplateTrainingActivity
  return await getDataApi(url(data))
}

export const getTemplateTrainingActivityChildDetail = async (data) => {
  const { url } = config.getTemplateTrainingActivityChildDetail
  return await getDataApi(url(data))
}

export const getTemplateTrainingActivityVM = async (data) => {
  const { url } = config.getTemplateTrainingActivityVM
  return await getDataApi(url(data))
}
