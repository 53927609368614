const initialState = {
  isLoading: false,
  menuIsLoading: false
}

function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        isLoading: action.payload
      }
    }
    case 'SET_MENU_IS_LOADING': {
      return {
        ...state,
        menuIsLoading: action.payload
      }
    }
    default:
      return state
  }
}

export default loadingReducer
