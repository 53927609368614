import PropTypes from 'prop-types'
import React from "react"

import ServiceListTable from "@views/campaigns/add-new-campaign/services/service-list-table"

const ExpandableServiceTable = ({
  subData,
  data,
  noDataMessage = "NO DATA"
}) => {
  if (!subData) {
    return <p className="no-data-txt">{noDataMessage}</p>
  }
  const selectedGrid = subData[`sub_grid_key_${data.campaignId}`]

  if (!selectedGrid) {
    return <p className="no-data-txt">{noDataMessage}</p>
  }


  return (
    <div className="template-right-content">
      <ServiceListTable
        campaignId={data.campaignId}
        isChildGrid
      />
    </div>
  )
}

ExpandableServiceTable.propTypes = {
  subData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  noDataMessage: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
}

export default ExpandableServiceTable
