import { FAILED_INSPECTION_CORRECTION_TYPE } from "@views/campaigns/add-new-campaign/workflow/block/failed-inspection-correction/config"
import config from "./APIConfig"
import { putDataApi } from "../../index"
/**
 * @method updateBlockFailedInspectionCorrection
 * @param {object} data 
 * 
 * {
 * correctItemsByRiskLevel: 0,
 * requireTradeCorrection: 0,
 * requireSuperintendentVerification: 0,
 * requireReInspection: 0,
 * riskLevelItems: []
 * }
 * 
 * API payload:-
 * 
 * {
 *  "wfCampaignSetupId": 0,
 *  "correctItemsByRiskLevel": true,
 *  "requireTradeCorrection": true,
 *  "requireSuperintendentVerification": true,
 *  "requireReInspection": true,
 *  "riskLevelItems": []
 * }
 */
export const updateBlockFailedInspectionCorrection = async (data) => {
  const { url } = config.updateBlockFailedInspectionCorrection
  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    correctItemsByRiskLevel: data.correctItemsByRiskLevel === FAILED_INSPECTION_CORRECTION_TYPE.YES
  }
  if (data.correctItemsByRiskLevel === FAILED_INSPECTION_CORRECTION_TYPE.NO) {
    payload.requireTradeCorrection = data.requireTradeCorrection === FAILED_INSPECTION_CORRECTION_TYPE.YES
    payload.requireSuperintendentVerification = data.requireSuperintendentVerification === FAILED_INSPECTION_CORRECTION_TYPE.YES
    payload.requireReInspection = data.requireReInspection === FAILED_INSPECTION_CORRECTION_TYPE.YES
    payload.holdProductionOnOpenItems = data.holdProductionOnOpenItems === FAILED_INSPECTION_CORRECTION_TYPE.YES
    payload.contactListToNotify = data.contactListToNotify.map(item => ({ siteRoleId: item.siteRoleId }))
    payload.responsiblePartyForCorrectionId = Number(data.responsiblePartyForCorrectionId)
    payload.responsiblePartyForVerificationId = Number(data.responsiblePartyForVerificationId)
    payload.responsiblePartyForCorrectionContactId = data.responsiblePartyForCorrectionContactId ? { contactId: data.responsiblePartyForCorrectionContactId } : null
    payload.responsiblePartyForVerificationContactId = data.responsiblePartyForVerificationContactId ? { contactId: data.responsiblePartyForVerificationContactId } : null
    payload.responsiblePartyForVerificationSitePartyRoleId = data.responsiblePartyForVerificationSitePartyRoleId ? { sitePartyRoleId: data.responsiblePartyForVerificationSitePartyRoleId } : null
    payload.responsiblePartyForCorrectionSitePartyRoleId = data.responsiblePartyForCorrectionSitePartyRoleId ? { sitePartyRoleId: data.responsiblePartyForCorrectionSitePartyRoleId } : null
    payload.findingWorkflowIdList = data.findingWorkflows.map((item) => ({ wfCampaignSetupId: item.value })) || []
  } else {
    payload.riskLevelItems = data.riskLevelItems.map(item => {
      const { riskLevelId, riskLevelData } = item
      return {
        riskLevelId: Number(riskLevelId),
        riskLevelData: {
          requireTradeCorrection: riskLevelData.requireTradeCorrection === FAILED_INSPECTION_CORRECTION_TYPE.YES,
          requireSuperintendentVerification: riskLevelData.requireSuperintendentVerification === FAILED_INSPECTION_CORRECTION_TYPE.YES,
          requireReInspection: riskLevelData.requireReInspection === FAILED_INSPECTION_CORRECTION_TYPE.YES,
          holdProductionOnOpenItems: riskLevelData.holdProductionOnOpenItems === FAILED_INSPECTION_CORRECTION_TYPE.YES,
          contactListToNotify: riskLevelData.contactListToNotify.map(item => ({ siteRoleId: item.siteRoleId })),
          responsiblePartyForCorrectionId: Number(riskLevelData.responsiblePartyForCorrectionId),
          responsiblePartyForVerificationId: Number(riskLevelData.responsiblePartyForVerificationId),
          responsiblePartyForCorrectionContactId: riskLevelData.responsiblePartyForCorrectionContactId ? { contactId: riskLevelData.responsiblePartyForCorrectionContactId } : null,
          responsiblePartyForVerificationContactId: riskLevelData.responsiblePartyForVerificationContactId ? { contactId: riskLevelData.responsiblePartyForVerificationContactId } : null,
          responsiblePartyForVerificationSitePartyRoleId: riskLevelData.responsiblePartyForVerificationSitePartyRoleId ? { sitePartyRoleId: riskLevelData.responsiblePartyForVerificationSitePartyRoleId } : null,
          responsiblePartyForCorrectionSitePartyRoleId: riskLevelData.responsiblePartyForCorrectionSitePartyRoleId ? { sitePartyRoleId: riskLevelData.responsiblePartyForCorrectionSitePartyRoleId } : null,
          findingWorkflowIdList: riskLevelData.findingWorkflows.map((item) => ({ wfCampaignSetupId: item.value })) || []
        }
      }
    })
  }
  return await putDataApi(url(data.wfCampaignSetupId), payload, null, { isApiNeedsToHandleErrors: false })
}

export default updateBlockFailedInspectionCorrection
