import {
  GET_NOTIFICATION,
  GET_WELCOME_NOTIFICATION
} from "@store/actions"

// ** Initial State
const initialState = {
  welcomeNotification: [],
  latestNotification: {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 0,
    totalCount: 0,
    totalPages: 0
  },
  notificationList: {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 0,
    totalCount: 0,
    totalPages: 0
  },
  isFetching: false
}

const NotificationReducer = (state = initialState, action) => {

  switch (action.type) {
    case GET_NOTIFICATION.REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case GET_NOTIFICATION.SUCCESS:
      if (action.payload.pageIndex === 1) {
        return {
          ...state,
          latestNotification: { ...action.payload },
          notificationList: { ...action.payload },
          isFetching: false
        }
      } else {
        return {
          ...state,
          notificationList: { ...action.payload },
          isFetching: false
        }
      }
    case GET_NOTIFICATION.FAILURE:
      return {
        ...state,
        isFetching: false
      }
    case GET_WELCOME_NOTIFICATION.SUCCESS:
      return {
        ...state,
        welcomeNotification: action.payload
      }
    default:
      return state
  }
}

export default NotificationReducer