import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
  Button,
  Col,
  FormGroup,
  Row,
  Input,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap'
import { useDispatch } from 'react-redux'

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from '@store/actions'
import { getLocaleMessage, asyncForEach } from '@utils'
import {
  CustomUILoader,
  CustomLabel,
  FileUploader,
  SideModalCloseButton
} from '@views/components'
import localeMessageWrapper from '@views/components/locale-message'
import { RESOURCE_CENTER_SOURCE_TYPES } from '@views/components/resource-center-uploader/config'
import { ROW_ACTION_TYPES } from './config'

const { ORG_PLANS, CANCEL, SAVE } = LANGUAGE_CONSTANTS

const EnergyModelsModel = ({
  intl,
  config = {},
  onClose = () => { },
  onRefreshDetails = () => { }
}) => {
  const dispatch = useDispatch()

  const [energyModelName, setEnergyModelName] = useState("")
  const [energyModelFiles, setEnergyModelFiles] = useState([])
  const [isFetching, setFetching] = useState(false)

  const { data, type } = useMemo(
    () => config,
    [config]
  )

  const handleCloseModal = useCallback(() => {
    onClose()
  }, [onClose])

  const handleDownloadFile = useCallback(({ fileContent, fileURL, title }) => {
    const link = document.createElement('a')
    let url = fileURL
    if (!url) {
      if (!fileContent) {
        return
      }
      const blob = new Blob([fileContent])
      url = URL.createObjectURL(blob)
    }

    link.setAttribute('href', url)
    link.setAttribute('download', title)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [])

  const handleDownloadMultipleFiles = useCallback(() => {
    energyModelFiles.forEach((obj, index) => {
      setTimeout(() => {
        handleDownloadFile(obj)
      }, index * 1000) // A delay of 1 second between each download
    })
  }, [energyModelFiles, handleDownloadFile])

  const handleFetchFiles = useCallback(
    ({ key, value, onSetDetail = () => { } }) => {
      setFetching(true)
      const queryPayload = {
        [key]: value,
        pageSize: 100,
        pageNumber: 1
      }
      dispatch(
        actions.getResourceListRequest(queryPayload, (res) => {
          if (res) {
            onSetDetail(res.items || [])
          }
          setFetching(false)
        })
      )
    },
    []
  )

  useEffect(() => {
    if (data.organizationEnergyModelId) {
      handleFetchFiles({
        key: 'organizationEnergyModelId',
        value: data.organizationEnergyModelId,
        onSetDetail: (res) => {
          setEnergyModelFiles(res)
        }
      })
    }
  }, [data.organizationEnergyModelId])

  const handleChangeDetail = useCallback(
    (key, value) => {
      if (key === 'energyModelFiles') {
        setEnergyModelFiles(value)
        return
      }
      setEnergyModelName(value)
    },
    []
  )

  const handleUploadFile = useCallback((payload, callback) => {
    dispatch(
      actions.uploadRCDocumentRequest(payload, (data) => {
        callback(!!data)
      })
    )
  }, [])

  const handleUploadFilesRequest = useCallback(
    async (fileList) => {
      try {
        const lastFileIndex = fileList.length - 1
        let isListRefreshRequired = false
        await asyncForEach(fileList, async (sFile, index) => {
          const payload = {
            ...sFile
          }
          const isLastFile = index === lastFileIndex
          handleUploadFile(payload, (isSaved) => {
            if (isSaved) {
              isListRefreshRequired = true
            }
            if (isLastFile && isListRefreshRequired) {
              handleCloseModal()
              onRefreshDetails({ page: 1 })
            }
          })
        })
      } catch (err) { }
    },
    [handleUploadFile, handleCloseModal, onRefreshDetails]
  )

  const handleSaveNewBlueprint = useCallback(() => {
    const payload = {
      organizationBluePrintId: data.organizationBluePrintId,
      organizationPlanId: data.organizationPlanId,
      energyModelName
    }
    dispatch(
      actions.addOrganizationPlanBlueprintEnergyModelRequest(payload, (res) => {
        if (res) {
          const updatedEnergyModelFiles = energyModelFiles.map((emf) => {
            return {
              ...emf,
              fileLocationType: RESOURCE_CENTER_SOURCE_TYPES.ENERGY_MODEL,
              organizationPlanId: data.organizationPlanId,
              fileSourceName: 'organizationEnergyModelId',
              fileSourceId: res
            }
          })
          handleUploadFilesRequest(updatedEnergyModelFiles)
        }
      })
    )
  }, [
    data.organizationPlanId,
    data.organizationBluePrintId,
    energyModelName,
    energyModelFiles,
    handleUploadFilesRequest
  ])

  return (
    <Modal
      isOpen
      className={'sidebar-sm'}
      modalClassName={'modal-slide-in note-media-modal schedule-time-modal'}
      contentClassName="p-0"
    >
      <ModalHeader
        className="mb-2"
        toggle={handleCloseModal}
        close={<SideModalCloseButton onClose={handleCloseModal} />}
        tag="div"
      >
        <h5 className="modal-title">
          {getLocaleMessage(
            intl,
            type === ROW_ACTION_TYPES.VIEW_FILES
              ? ORG_PLANS.VIEW_ENERGY_MODEL
              : ORG_PLANS.ADD_ENERGY_MODEL
          )}
        </h5>
      </ModalHeader>
      <CustomUILoader isLoading={isFetching}>
        {isFetching && <div style={{ height: '10px' }}></div>} 
        <ModalBody className="flex-grow-1">
          {type === ROW_ACTION_TYPES.VIEW_FILES ? (
            <Row>
              <Col md={12}>
                <FormGroup>
                  <CustomLabel title={ORG_PLANS.ENERGY_MODEL_NAME} />
                  <Input value={data.energyModelName} readOnly />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  {!!energyModelFiles.length && (
                    <>
                      <div className='d-flex justify-content-between'>
                        <CustomLabel title={ORG_PLANS.ENERGY_MODEL_FILES} />
                        <Button
                          className={'waves-effect primary-text-btn p-0 download-all'}
                          color="link"
                          onClick={handleDownloadMultipleFiles}
                        >
                          {getLocaleMessage(intl, ORG_PLANS.DOWNLOAD_ALL)}
                        </Button>
                      </div>
                      <label>
                        {' '}
                        {getLocaleMessage(intl, ORG_PLANS.ATTACHED_FILES, {
                          count: energyModelFiles.length
                        })}
                      </label>
                      <div>
                        {energyModelFiles.map((file, index) => {
                          return (
                            <div key={`file_response_${index}`}>
                              <span className="plan-details-attached-filename" onClick={() => handleDownloadFile(file)}>
                                {file.title}
                              </span>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  )}
                </FormGroup>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <div>
                      <CustomLabel title={ORG_PLANS.ENERGY_MODEL_NAME} required />
                    </div>
                    <Input
                      placeholder={getLocaleMessage(
                        intl,
                        ORG_PLANS.ENERGY_MODEL_NAME
                      )}
                      value={energyModelName}
                      onChange={(event) => handleChangeDetail('energyModelName', event.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm={12} md={8} xl={6}>
                  <FileUploader
                    id="energyModelFiles"
                    label={ORG_PLANS.ENERGY_MODEL_FILES}
                    isRequired
                    defaultFileCategoryLabel="Energy Models"
                    canUploadFiles
                    canDeleteFiles
                    isDisableFileCategory
                    value={energyModelFiles}
                    onChangeDetail={handleChangeDetail} />
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
        {isFetching && <div style={{ height: '100px' }}></div>}
      </CustomUILoader>
      <div className="modal-footer-btns">
        <Row>
          <Col md={type === ROW_ACTION_TYPES.VIEW_FILES ? 12 : 6}>
            <Button
              outline
              onClick={handleCloseModal}
              className={'secondary-outlined w-100 mw-100'}
            >
              {getLocaleMessage(intl, CANCEL)}
            </Button>
          </Col>
          {type === ROW_ACTION_TYPES.ADD_MODEL && (
            <Col md={6}>
              <Button
                className={'primary-solid w-100'}
                color="primary"
                disabled={!energyModelName || !energyModelFiles.length}
                onClick={handleSaveNewBlueprint}
              >
                {getLocaleMessage(intl, SAVE)}
              </Button>
            </Col>
          )}
        </Row>
      </div>
    </Modal>
  )
}

export default localeMessageWrapper(EnergyModelsModel)
