import config from "./APIConfig"
import { getDataApi } from "../index"

const REPORT_TYPES = {
  UNACCEPTABLE: "unacceptable"
}

export const getActivityTrainingChapterDDL = (data) => {
  const { url } = config.getActivityTrainingChapterDDL
  return getDataApi(url(data))
}

export const getActivityTrainingCourseDDL = (data) => {
  const { url } = config.getActivityTrainingCourseDDL
  return getDataApi(url(data))
}

export const getActivityTrainingLessonDDL = (data) => {
  const { url } = config.getActivityTrainingLessonDDL
  return getDataApi(url(data))
}

export const getActivityTrainingSubjectDDL = (data) => {
  const { url } = config.getActivityTrainingSubjectDDL
  return getDataApi(url(data))
}

export const getAverageCycleTimeWidget = async (data) => {
  const { url } = config.getAverageCycleTimeWidget
  return await getDataApi(url(data))
}

export const getAverageFailedActivitiesWidget = async (data) => {
  const { url } = config.getAverageFailedActivitiesWidget
  return await getDataApi(url(data))
}

export const getCampaignTaskStatusReport = (data) => {
  const { url } = config.getCampaignTaskStatusReport
  return getDataApi(url(data))
}

export const getCampaignTaskStatusReportVM = () => {
  const { url } = config.getCampaignTaskStatusReportVM
  return getDataApi(url)
}

export const getCompletedUnitsWidget = async (data) => {
  const { url } = config.getCompletedUnitsWidget
  return await getDataApi(url(data))
}

export const getConstructionCallbacksCostsWidget = async (data) => {
  const { url } = config.getConstructionCallbacksCostsWidget
  return await getDataApi(url(data))
}

export const getConstructionCallbacksWidget = async (data) => {
  const { url } = config.getConstructionCallbacksWidget
  return await getDataApi(url(data))
}

export const getDailyInspectionReport = async (payload) => {
  const { url } = config.getDailyInspectionReport
  return await getDataApi(url(payload))
}

export const getDashboardOrganizationDDL = (data) => {
  const { url } = config.getDashboardOrganizationDDL
  return getDataApi(url(data))
}

export const getDashboardSiteDDL = (data) => {
  const { url } = config.getDashboardSiteDDL
  return getDataApi(url(data))
}

export const getDashboardWidgets = async (data) => {
  const { url } = config.getDashboardWidgets
  return await getDataApi(url(data))
}

export const getEnergystarTaxIncentiveWidget = async (data) => {
  const { url } = config.getEnergystarTaxIncentiveWidget
  return await getDataApi(url(data))
}

export const getEnergystarUnitsCertifiedWidget = async (data) => {
  const { url } = config.getEnergystarUnitsCertifiedWidget
  return await getDataApi(url(data))
}

export const getEnergystarUnitsFailedWidget = async (data) => {
  const { url } = config.getEnergystarUnitsFailedWidget
  return await getDataApi(url(data))
}

export const getEnergystarUnitsOpenWidget = async (data) => {
  const { url } = config.getEnergystarUnitsOpenWidget
  return await getDataApi(url(data))
}

export const getExceptionDashboardList = async (data) => {
  const { url } = config.getExceptionDashboardList
  return await getDataApi(url(data))
}

export const getNewObservationCampaignWidget = async () => {
  const { url } = config.getNewObservationCampaignWidget
  return await getDataApi(url)
}

export const getOpenUnacceptableActivities = async (data) => {
  const { url } = config.getOpenUnacceptableActivities
  return await getDataApi(url(data))
}

export const getPowerBiAccessToken = async (reportId) => {
  const { url } = config.getPowerBiAccessToken
  return await getDataApi(url(reportId))
}

export const getProductionCycleTimeWidget = async (data) => {
  const { url } = config.getProductionCycleTimeWidget
  return await getDataApi(url(data))
}

export const getQualityScoreWidget = async (data) => {
  const { url } = config.getQualityScoreWidget
  return await getDataApi(url(data))
}

export const getReportAssignedContactList = async (payload) => {
  let url = () => ""
  if (payload.reportType === REPORT_TYPES.UNACCEPTABLE) {
    ({ url } = config.getUnacceptableAssignedContactList)
  }
  return await getDataApi(url(payload))
}

export const getReportDashboardsList = async (data) => {
  const { url } = config.getReportDashboardsList
  return await getDataApi(url(data))
}

export const getReportDashboardsVM = async () => {
  const { url } = config.getReportDashboardsVM
  return await getDataApi(url)
}

export const getReportDetail = async (data) => {
  const { url } = config.getReportDetail
  return await getDataApi(url(data.reportId))
}

export const getReportFileStatus = async (fileName) => {
  const { url } = config.getReportFileStatus
  return await getDataApi(url(fileName))
}

export const getReportList = async (pageName) => {
  const { url } = config.getReportList
  return await getDataApi(url(pageName))
}

export const getReportsSiteDDL = (data) => {
  const { url } = config.getReportsSiteDDL
  return getDataApi(url(data))
}

export const getSaturationDetailsSummary = async (data) => {
  const { url } = config.getSaturationDetailsSummary
  return await getDataApi(url(data))
}

export const getSaturateRateSummary = async (data) => {
  const { url } = config.getSaturateRateSummary
  return await getDataApi(url(data))
}

export const getTaskListDDL = async (data) => {
  const { url } = config.getTaskListDDL
  return await getDataApi(url(data))
}

export const getTaskSummaryVM = () => {
  const { url } = config.getTaskSummaryVM
  return getDataApi(url)
}

export const getTradePerformanceReport = async (payload) => {
  const { url } = config.getTradePerformanceReport
  return await getDataApi(url(payload))
}

export const getTrainingActivitiesFailed = async (data) => {
  const { url } = config.getTrainingActivitiesFailed
  return await getDataApi(url(data))
}

export const getTrainingActivitiesIncomplete = async (data) => {
  const { url } = config.getTrainingActivitiesIncomplete
  return await getDataApi(url(data))
}

export const getTrainingActivitiesStatus = async (data) => {
  const { url } = config.getTrainingActivitiesStatus
  return await getDataApi(url(data))
}

export const getTrainingActivitiesWidget = async () => {
  const { url } = config.getTrainingActivitiesWidget
  return await getDataApi(url)
}

export const getTrainingCampaignReport = async (payload) => {
  const { url } = config.getTrainingCampaignReport
  return await getDataApi(url(payload))
}

export const getTrainingCampaignVM = () => {
  const { url } = config.getTrainingCampaignVM
  return getDataApi(url)
}

export const getUnacceptableActivitiesReport = async (payload) => {
  const { url } = config.getUnacceptableActivitiesReport
  return await getDataApi(url(payload))
}

export const getUnacceptableActivitiesSitePartyRoleDDL = (payload) => {
  const { url } = config.getUnacceptableActivitiesSitePartyRoleDDL
  return getDataApi(url(payload))
}

export const getUnacceptableActivitiesVM = () => {
  const { url } = config.getUnacceptableActivitiesVM
  return getDataApi(url)
}

export const getUnaprovedCampaignsWidget = async () => {
  const { url } = config.getUnaprovedCampaignsWidget
  return await getDataApi(url)
}

export const getUnapprovedTrainingReport = async (payload) => {
  const { url } = config.getUnapprovedTrainingReport
  return await getDataApi(url(payload))
}

export const getUnitStatusReport = (data) => {
  const { url } = config.getUnitStatusReport
  return getDataApi(url(data))
}

export const getUnitCloseout = async (payload) => {
  const { url } = config.getUnitCloseout
  return await getDataApi(url(payload))
}

export const getWarrantyClaimEstimateWidget = async (data) => {
  const { url } = config.getWarrantyClaimEstimateWidget
  return await getDataApi(url(data))
}

export const getWarrantyCostEstimateWidget = async (data) => {
  const { url } = config.getWarrantyCostEstimateWidget
  return await getDataApi(url(data))
}

export const getWorkflowDDL = async (data) => {
  const { url } = config.getWorkflowDDL
  return await getDataApi(url(data))
}

