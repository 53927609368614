import { put, takeLatest, call, takeEvery } from "redux-saga/effects"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'
import * as actions from "@store/actions"
import {
  addOrganizationAddress,
  addOrganizationDetail,
  deleteOrganization,
  deleteOrganizationAddress,
  editOrganizationDetail,
  getAddOrganizationDetailVM,
  getFilteredOrganizations,
  getOrganizations,
  getOrganizationAddress,
  getOrganizationList,
  getOrganizationListVM,
  getOrganizationDetail,
  getOrganizationTreeVM,
  updateOrganizationAddress,
  validateOrganizationName,
  getChildOrganizationList,
  removeChildOrganization,
  getOrganizationSiteList,
  getOrganizationStatusListVM,
  getOrganizationPlanList,
  updateOrganizationPlan,
  getOrganizationPlan,
  getOrganizationPlanDDL,
  getOrganizationPlanBlueprintDDL,
  getOrganizationPlanBlueprintEnergyModelDDL,
  updateOrganizationUnitPlan,
  addOrganizationPlanBlueprint,
  addOrganizationPlanBlueprintEnergyModel,
  getOrganizationPlanBlueprintList,
  getOrganizationPlanBlueprintEnergyModelList
} from "@services/api"

const { ORGANIZATION_SAGA, ADDRESS_SAGA } = LANGUAGE_CONSTANTS

/** getOrganizationList API **/
function* getOrganizationListRequest(action) {
  try {
    const { isLoadingRequired = false, ...payload } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getOrganizationList, payload)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    if (response?.data) {
      action.callback({ ...response.data, pageSize: payload.pageSize })
    } else {
      action.callback()
      Notification({ status: 400, message: ORGANIZATION_SAGA.ERROR_IN_FETCHING_LIST })
    }
  } catch (error) {
    const { isLoadingRequired } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    action.callback()
  }
}

/** getFilteredOrganizations API **/
function* getFilteredOrganizationsRequest(action) {
  try {
    const response = yield call(getFilteredOrganizations, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback(null)
    }
  } catch (error) {
    action.callback(null)
  }
}

/** getOrganizations API **/
function* getOrganizationsRequest(action) {
  try {
    const response = yield call(getOrganizations, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback(null)
    }
  } catch (error) {
    action.callback(null)
  }
}

/** getAddOrganizationDetailVM API **/
function* getAddOrganizationDetailVMRequest({ callback }) {
  try {
    const response = yield call(getAddOrganizationDetailVM, {})
    callback(response?.data)
  } catch (error) {
    callback()
  }
}

/** getOrganizationListVM API **/
function* getOrganizationListVMRequest(action) {
  try {
    const response = yield call(getOrganizationListVM)
    action.callback(response?.data || null)
  } catch (error) {
    action.callback(null)
  }
}

/** addOrganizationDetail API **/
function* addOrganizationDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addOrganizationDetail, action.payload)
    if (response?.data) {
      action.callback(response.data)
      Notification({ status: 200, message: ORGANIZATION_SAGA.ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: ORGANIZATION_SAGA.ERROR_IN_ADDING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** editOrganizationDetail API **/
function* editOrganizationDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(editOrganizationDetail, action.payload)
    if (response?.status === 204) {
      action.callback()
      Notification({ status: 200, message: ORGANIZATION_SAGA.UPDATED_SUCCESS })
    } else {
      Notification({ status: 400, message: ORGANIZATION_SAGA.ERROR_IN_UPDATING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** getOrganizationDetail API **/
function* getOrganizationDetailRequest(action) {
  try {
    const { ...payload } = action.payload
    const response = yield call(getOrganizationDetail, payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback({})
    }
  } catch (error) {
    action.callback({})
  }
}

/** getOrganizationAddress API **/
function* getOrganizationAddressRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getOrganizationAddress, action.payload)
    action.callback(response?.data || [])
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback([])
    yield put(actions.setIsLoading(false))
  }
}

/** addOrganizationAddress API **/
function* addOrganizationAddressRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addOrganizationAddress, action.payload)
    if (response?.data) {
      action.callback(true)
      Notification({ status: 200, message: ADDRESS_SAGA.ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: ADDRESS_SAGA.ERROR_IN_ADDING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** updateOrganizationAddress API **/
function* updateOrganizationAddressRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateOrganizationAddress, action.payload)
    if (response?.status === 204) {
      action.callback()
      Notification({ status: 200, message: ORGANIZATION_SAGA.ADDRESS_UPDATE_SUCCESS })
    } else {
      Notification({ status: 400, message: ORGANIZATION_SAGA.ADDRESS_UPDATE_ERROR })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** deleteOrganization API **/
function* deleteOrganizationRequest(data) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteOrganization, data.payload)
    if (response?.status === 204) {
      if (data.callback) {
        data.callback()
      }
      Notification({ status: 200, message: ORGANIZATION_SAGA.DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: ORGANIZATION_SAGA.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** deleteOrganizationAddress API **/
function* deleteOrganizationAddressRequest(data) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteOrganizationAddress, data.payload)
    if (response?.status === 204) {
      if (data.callback) {
        data.callback()
      }
      Notification({ status: 200, message: ADDRESS_SAGA.DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: ADDRESS_SAGA.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** getOrganizationTreeVM API **/
function* getOrganizationTreeVMRequest(action) {
  try {
    const response = yield call(getOrganizationTreeVM, action.payload)
    if (response?.data?.organizationTree) {
      action.callback([response.data.organizationTree])
    } else {
      action.callback([])
    }
  } catch (error) {
    action.callback([])
  }
}

/** validateOrganizationName API **/
function* validateOrganizationNameRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(validateOrganizationName, action.payload)
    if (response?.data && action.payload.organizationId !== response.data) {
      action.callback(response.data)
      Notification({ status: 304, message: ORGANIZATION_SAGA.ALREADY_IN_USE })
    } else {
      action.callback('')
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback('')
  }
}

/** getChildOrganizationList API */
function* getChildOrganizationListRequest(action) {
  try {
    const response = yield call(getChildOrganizationList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** removeChildOrganization API */
function* removeChildOrganizationRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(removeChildOrganization, action.payload)
    if (response?.status === 204) {
      action.callback()
    } else {
      action.callback()
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getOrganizationSiteList API */
function* getOrganizationSiteListRequest(action) {
  try {
    const response = yield call(getOrganizationSiteList, action.payload)
    action.callback({ ...response?.data, pageSize: action.payload?.pageSize })
  } catch (error) {
    action.callback()
  }
}

/** getOrganizationStatusListVM API */
function* getOrganizationStatusListVMRequest(action) {
  try {
    const response = yield call(getOrganizationStatusListVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getOrganizationPlanList API **/
function* getOrganizationPlanListRequest(action) {
  try {
    const response = yield call(getOrganizationPlanList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** updateOrganizationPlan API **/
function* updateOrganizationPlanRequest(action) {
  try {
    const isUpdate = action.payload.organizationPlanId
    const isUnitPlan = action.payload.siteUnitId
    yield put(actions.setIsLoading(true))

    const response = yield call(updateOrganizationPlan, action.payload)
    if (response && (response.status === 204 || response.status === 200)) {
      action.callback(response.status === 204 ? true : response.data)
      Notification({ status: 200, message: isUnitPlan ? ORGANIZATION_SAGA.UNIT_PLAN_ADDED_SUCCESS : isUpdate ? ORGANIZATION_SAGA.PLAN_UPDATED_SUCCESS : ORGANIZATION_SAGA.PLAN_ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: isUnitPlan ? ORGANIZATION_SAGA.ERROR_IN_ADDING_UNIT_PLAN : isUpdate ? ORGANIZATION_SAGA.ERROR_IN_UPDATING_PLAN : ORGANIZATION_SAGA.ERROR_IN_ADDING_PLAN })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getOrganizationPlan API **/
function* getOrganizationPlanRequest(action) {
  try {
    const response = yield call(getOrganizationPlan, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getOrganizationPlanDDL API **/
function* getOrganizationPlanDDLRequest(action) {
  try {
    const response = yield call(getOrganizationPlanDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getOrganizationPlanBlueprintDDL API **/
function* getOrganizationPlanBlueprintDDLRequest(action) {
  try {
    const response = yield call(getOrganizationPlanBlueprintDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getOrganizationPlanBlueprintEnergyModelDDL API **/
function* getOrganizationPlanBlueprintEnergyModelDDLRequest(action) {
  try {
    const response = yield call(getOrganizationPlanBlueprintEnergyModelDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateOrganizationUnitPlan API **/
function* updateOrganizationUnitPlanRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateOrganizationUnitPlan, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: ORGANIZATION_SAGA.UNIT_PLAN_UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: ORGANIZATION_SAGA.ERROR_IN_UPDATING_UNIT_PLAN })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** addOrganizationPlanBlueprint API **/
function* addOrganizationPlanBlueprintRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addOrganizationPlanBlueprint, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      Notification({ status: 200, message: ORGANIZATION_SAGA.PLAN_BLUEPRINT_ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: ORGANIZATION_SAGA.ERROR_IN_ADDING_PLAN_BLUEPRINT })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** addOrganizationPlanBlueprintEnergyModel API **/
function* addOrganizationPlanBlueprintEnergyModelRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addOrganizationPlanBlueprintEnergyModel, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      Notification({ status: 200, message: ORGANIZATION_SAGA.PLAN_BLUEPRINT_ENERGY_MODEL_ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: ORGANIZATION_SAGA.ERROR_IN_ADDING_PLAN_BLUEPRINT_ENERGY_MODEL })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getOrganizationPlanBlueprintList API **/
function* getOrganizationPlanBlueprintListRequest(action) {
  try {
    const response = yield call(getOrganizationPlanBlueprintList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getOrganizationPlanBlueprintEnergyModelList API **/
function* getOrganizationPlanBlueprintEnergyModelListRequest(action) {
  try {
    const response = yield call(getOrganizationPlanBlueprintEnergyModelList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export function* organizationSagas() {
  yield takeLatest(actions.ADD_ORGANIZATION_ADDRESS_REQUEST, addOrganizationAddressRequest)
  yield takeLatest(actions.ADD_ORGANIZATION_DETAIL_REQUEST, addOrganizationDetailRequest)
  yield takeLatest(actions.DELETE_ORGANIZATION_REQUEST, deleteOrganizationRequest)
  yield takeLatest(actions.DELETE_ORGANIZATION_ADDRESS_REQUEST, deleteOrganizationAddressRequest)
  yield takeLatest(actions.EDIT_ORGANIZATION_DETAIL_REQUEST, editOrganizationDetailRequest)
  yield takeLatest(actions.GET_FILTERED_ORGANIZATIONS, getFilteredOrganizationsRequest)
  yield takeLatest(actions.GET_ORGANIZATIONS_REQUEST, getOrganizationsRequest)
  yield takeLatest(actions.GET_ORGANIZATION_ADDRESS_REQUEST, getOrganizationAddressRequest)
  yield takeEvery(actions.GET_ORGANIZATION_DETAIL_WITH_ID_REQUEST, getOrganizationDetailRequest)
  yield takeLatest(actions.GET_ORGANIZATION_LIST_REQUEST, getOrganizationListRequest)
  yield takeLatest(actions.GET_ADD_ORGANIZATION_DETAIL_VM_REQUEST, getAddOrganizationDetailVMRequest)
  yield takeLatest(actions.GET_ORGANIZATION_LIST_VM_REQUEST, getOrganizationListVMRequest)
  yield takeLatest(actions.GET_ORGANIZATION_TREE_VM_REQUEST, getOrganizationTreeVMRequest)
  yield takeLatest(actions.UPDATE_ORGANIZATION_ADDRESS_REQUEST, updateOrganizationAddressRequest)
  yield takeLatest(actions.VALIDATE_ORGNIZATION_NAME_REQUEST, validateOrganizationNameRequest)
  yield takeLatest(actions.GET_CHILD_ORGANIZATION_LIST_REQUEST, getChildOrganizationListRequest)
  yield takeLatest(actions.REMOVE_CHILD_ORGANIZATION_REQUEST, removeChildOrganizationRequest)
  yield takeLatest(actions.GET_ORGANIZATIONAL_SITE_LIST_REQUEST, getOrganizationSiteListRequest)
  yield takeLatest(actions.GET_ORGANIZATIONAL_STATUS_LIST_VM_REQUEST, getOrganizationStatusListVMRequest)
  yield takeLatest(actions.GET_ORGANIZATION_PLAN_LIST_REQUEST, getOrganizationPlanListRequest)
  yield takeLatest(actions.UPDATE_ORGANIZATION_PLAN_REQUEST, updateOrganizationPlanRequest)
  yield takeLatest(actions.GET_ORGANIZATION_PLAN_REQUEST, getOrganizationPlanRequest)
  yield takeLatest(actions.GET_ORGANIZATION_PLAN_DDL_REQUEST, getOrganizationPlanDDLRequest)
  yield takeLatest(actions.GET_ORGANIZATION_PLAN_BLUEPRINT_DDL_REQUEST, getOrganizationPlanBlueprintDDLRequest)
  yield takeLatest(actions.GET_ORGANIZATION_PLAN_BLUEPRINT_ENERGY_MODEL_DDL_REQUEST, getOrganizationPlanBlueprintEnergyModelDDLRequest)
  yield takeLatest(actions.UPDATE_ORGANIZATION_UNIT_PLAN_REQUEST, updateOrganizationUnitPlanRequest)
  yield takeLatest(actions.ADD_ORGANIZATION_PLAN_BLUEPRINT_REQUEST, addOrganizationPlanBlueprintRequest)
  yield takeLatest(actions.ADD_ORGANIZATION_PLAN_BLUEPRINT_ENERGY_MODEL_REQUEST, addOrganizationPlanBlueprintEnergyModelRequest)
  yield takeLatest(actions.GET_ORGANIZATION_PLAN_BLUE_PRINT_LIST_REQUEST, getOrganizationPlanBlueprintListRequest)
  yield takeLatest(actions.GET_ORGANIZATION_PLAN_BLUE_PRINT_ENERGY_MODEL_LIST_REQUEST, getOrganizationPlanBlueprintEnergyModelListRequest)
}

export default organizationSagas
