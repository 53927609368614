import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import { ConfirmationModal } from "@views/components"
import localeMessageWrapper from '@views/components/locale-message'

const { TABLE } = LANGUAGE_CONSTANTS

const CustomBulkActions = ({
  intl,
  bulkActionsConfig,
  bulkActionsList,
  isBulkAlertActionRequired,
  isControlledBulkActions,
  isDeleteBulkAction,
  isDisableDeleteAllAction,
  isShow,
  onCheckIfShowBulkAlert,
  onDelete
}) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false)
  const [isShowConfirmActionAlert, setShowConfirmActionAlert] = useState(false)

  const handleToggleActions = useCallback(() => {
    if (isBulkAlertActionRequired && onCheckIfShowBulkAlert()) {
      setShowConfirmActionAlert(true)
    } else {
      setOpenDropdown(!isOpenDropdown)
    }
  }, [
    isBulkAlertActionRequired,
    isOpenDropdown,
    onCheckIfShowBulkAlert
  ])

  const handleToggleAlert = useCallback(() => {
    setShowConfirmActionAlert(false)
  }, [])

  if (!isShow) {
    return null
  }

  if (isControlledBulkActions) {
    return (
      <>
        <div className="table-actions">
          <Dropdown isOpen={isOpenDropdown} toggle={handleToggleActions}>
            <DropdownToggle color="primary" caret className="table-actions-label">
              {getLocaleMessage(intl, bulkActionsConfig.titleLocaleMessageId || TABLE.COLUMN_ACTIONS)}
            </DropdownToggle>
            <DropdownMenu center="true">
              {bulkActionsList.map((item) => {
                return (
                  <DropdownItem tag="a" className='w-100' key={item.id} onClick={item.onClick}>
                    <span className="align-middle ml-50" >{item.label}</span>
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
        {isShowConfirmActionAlert && <ConfirmationModal
          open
          isWarning
          title={getLocaleMessage(intl, bulkActionsConfig.alertTextLocaleMessageId)}
          onToggleModal={handleToggleAlert}
        />}
      </>
    )
  }

  if (isDeleteBulkAction) {
    return (
      <div className="table-actions">
        <UncontrolledDropdown>
          <DropdownToggle color="primary" caret className="table-actions-label">
            {getLocaleMessage(intl, TABLE.COLUMN_ACTIONS)}
          </DropdownToggle>
          <DropdownMenu center="true">
            <DropdownItem tag="a" className='w-100' disabled={isDisableDeleteAllAction} onClick={onDelete}>
              <span className="align-middle ml-50 text-danger">
                {getLocaleMessage(intl, TABLE.DELETE)}
              </span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    )
  }

  return null
}

CustomBulkActions.propTypes = {
  bulkActionsConfig: PropTypes.object,
  bulkActionsList: PropTypes.arrayOf(PropTypes.object),
  isBulkAlertActionRequired: PropTypes.bool,
  isControlledBulkActions: PropTypes.bool,
  isDeleteBulkAction: PropTypes.bool,
  isDisableDeleteAllAction: PropTypes.bool,
  isShow: PropTypes.bool,
  onCheckIfShowBulkAlert: PropTypes.func,
  onDelete: PropTypes.func
}

CustomBulkActions.defaultProps = {
  bulkActionsConfig: {},
  bulkActionsList: [],
  isBulkAlertActionRequired: false,
  isControlledBulkActions: false,
  isDeleteBulkAction: true,
  isDisableDeleteAllAction: false,
  isShow: false,
  onCheckIfShowBulkAlert: () => { },
  onDelete: () => { }
}

export default localeMessageWrapper(CustomBulkActions)
