export const GET_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST = 'GET_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST'
export const ADD_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST = 'ADD_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST'
export const UPDATE_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST = 'UPDATE_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST'

/****************************************************/
/** GET_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST **/
/****************************************************/

/**
 * @method getOshaDocumentationCampaignListRequest : To Get Osha Documentation Campaign list
 *
 */
export const getOshaDocumentationCampaignListRequest = (payload, callback) => {
  return {
    type: GET_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST **/
/****************************************************/

/**
 * @method addOshaDocumentationCampaignListRequest : To Add Osha Documentation Campaign list
 *
 */
export const addOshaDocumentationCampaignListRequest = (payload, callback) => {
  return {
    type: ADD_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST **/
/****************************************************/

/**
 * @method updateOshaDocumentationCampaignListRequest : To Update Osha Documentation Campaign list
 *
 */
export const updateOshaDocumentationCampaignListRequest = (payload, callback) => {
  return {
    type: UPDATE_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST,
    payload,
    callback
  }
}
