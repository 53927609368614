import { put, takeLatest, takeEvery, call } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { Notification } from "@views/components"

const { PROGRAM_SAGA } = LANGUAGE_CONSTANTS

/** getProgramAssemblyList API  **/
function* getProgramAssemblyListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getProgramAssemblyList, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getProgramAssemblyListVM API **/
function* getProgramAssemblyListVMRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getProgramAssemblyListVM)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, isLocalizedMessage: false, message: error.message })
  }
}

/** addProgramAssemblyList API **/
function* addProgramAssemblyListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addProgramAssemblyList, action.payload)
    if (response?.data?.status) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.PROGRAM_ASSEMBLY_LIST_UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_UPDATING_PROGRAM_ASSEMBLY_LIST })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getProgramEscalationVM API **/
function* getProgramEscalationVMRequest(action) {
  try {
    const response = yield call(services.getProgramEscalationVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** getProgramEscalation API **/
function* getProgramEscalationRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getProgramEscalation, action.payload)
    if (response?.status === 204) {
      action.callback(false)
      yield put(actions.setIsLoading(false))
    } else {
      action.callback(response?.data)
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getProgramSelectiveAssemblyList API  **/
function* getProgramSelectiveAssemblyListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getProgramSelectiveAssemblyList, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getProgramAssemblyInspectionList API **/
function* getProgramAssemblyInspectionListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getProgramAssemblyInspectionList, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}


/** getProgramAssemblyInspectionVM API **/
function* getProgramAssemblyInspectionVMRequest(action) {
  try {
    const response = yield call(services.getProgramAssemblyInspectionVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getProgramAssemblyReinspectionList API **/
function* getProgramAssemblyReinspectionListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getProgramAssemblyReinspectionList, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getProgramAssemblyReinspectionVM API **/
function* getProgramAssemblyReinspectionVMRequest(action) {
  try {
    const response = yield call(services.getProgramAssemblyReinspectionVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateProgramAssemblyReinspectionList API **/
function* updateProgramAssemblyReinspectionListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateProgramAssemblyReinspectionList, action.payload)
    if (response?.data?.status) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.PROGRAM_ASSEMBLY_REINSPECTION_LIST_UPDATE_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_UPDATING_PROGRAM_ASSEMBLY_REINSPECTION_LIST })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}


/** createProgramEscalation API **/
function* createProgramEscalationRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.createProgramEscalation, action.payload)
    if (response.status === 200) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.PROGRAM_ESCALATION_ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_ADDING_PROGRAM_ESCALATION })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** updateProgramEscalation API **/
function* updateProgramEscalationRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateProgramEscalation, action.payload)
    if (response?.data?.status) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.PROGRAM_ESCALATION_UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_UPDATING_PROGRAM_ESCALATION })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getTaskCloseOutVM API **/
function* getProgramTaskCloseOutVMRequest(action) {
  try {
    const response = yield call(services.getProgramTaskCloseOutVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getProgramTaskCloseOut API **/
function* getProgramTaskCloseOutRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getProgramTaskCloseOut, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** addProgramTaskCloseOut API **/
function* addProgramTaskCloseOutRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addProgramTaskCloseOut, action.payload)
    if (response.status === 200) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.PROGRAM_TASK_CLOSE_OUT_ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_ADDIND_PROGRAM_TASK_CLOSE_OUT })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** updateProgramTaskCloseOut API **/
function* updateProgramTaskCloseOutRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateProgramTaskCloseOut, action.payload)
    if (response?.data?.status) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.PROGRAM_TASK_CLOSE_OUT_UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_UPDATING_TASK_CLOSE_OUT })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** updateProgramAssemblyInspectionList API **/
function* updateProgramAssemblyInspectionListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateProgramAssemblyInspectionList, action.payload)
    if (response?.data?.status) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.PROGRAM_ASSEMBLY_INSPECTION_LIST_UPDATE_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_UPDATING_PROGRAM_ASSEMBLY_INSPECTION_LIST })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getProgramConfirmSiteVisitList API **/
function* getProgramConfirmSiteVisitListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getProgramConfirmSiteVisitList, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getProgramConfirmSiteVisitVM API **/
function* getProgramConfirmSiteVisitVMRequest(action) {
  try {
    const response = yield call(services.getProgramConfirmSiteVisitVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** addProgramConfirmSiteVisit API **/
function* addProgramConfirmSiteVisitRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addProgramConfirmSiteVisit, action.payload)
    if (response.status === 200) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.PROGRAM_CONFIRM_SITE_VISIT_ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_ADDING_PROGRAM_CONFIRM_SITE_VISIT })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** updateProgramConfirmSiteVisit API **/
function* updateProgramConfirmSiteVisitRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateProgramConfirmSiteVisit, action.payload)
    if (response?.data?.status) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.PROGRAM_CONFIRM_SITE_VISIT_UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_UPDATING_PROGRAM_CONFIRM_SITE_VISIT })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getProgramBasicDetail API **/
function* getProgramBasicDetailRequest(action) {
  try {
    const response = yield call(services.getProgramBasicDetail, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getProgramAssemblyChecklistList API **/
function* getProgramAssemblyChecklistListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getProgramAssemblyChecklistList, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getObservationTemplateVM API **/
function* getObservationTemplateVMRequest(action) {
  try {
    const response = yield call(services.getObservationTemplateVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateFinishProgramWizard API **/
function* updateFinishProgramWizardRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateFinishProgramWizard, action.payload)
    if (response?.status === 200) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROGRAM_SAGA.UPDATED_SUCCESS_IN_FINISH_PROGRAM_WIZARD })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_UPDATING_FINISH_PROGRAM_WIZARD })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getObservationTemplateDDL API **/
function* getObservationTemplateDDLRequest(action) {
  try {
    const response = yield call(services.getObservationTemplateDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getProgramTaskReviewList API */
function* getProgramTaskReviewListRequest(action) {
  try {
    const response = yield call(services.getProgramTaskReviewList, action.payload)
    if (response?.data) {
      action.callback(response?.data)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

export function* programsQualityControlSagas() {
  yield takeLatest(actions.GET_PROGRAM_ASSEMBLY_LIST_REQUEST, getProgramAssemblyListRequest)
  yield takeLatest(actions.GET_PROGRAM_ASSEMBLY_LIST_VM_REQUEST, getProgramAssemblyListVMRequest)
  yield takeLatest(actions.ADD_PROGRAM_ASSEMBLY_LIST_REQUEST, addProgramAssemblyListRequest)
  yield takeLatest(actions.GET_PROGRAM_ESCALATION_VM_REQUEST, getProgramEscalationVMRequest)
  yield takeLatest(actions.GET_PROGRAM_ESCALATION_REQUEST, getProgramEscalationRequest)
  yield takeLatest(actions.CREATE_PROGRAM_ESCALATION_REQUEST, createProgramEscalationRequest)
  yield takeLatest(actions.UPDATE_PROGRAM_ESCALATION_REQUEST, updateProgramEscalationRequest)
  yield takeLatest(actions.GET_PROGRAM_SELECTIVE_ASSEMBLY_LIST, getProgramSelectiveAssemblyListRequest)
  yield takeLatest(actions.GET_PROGRAM_ASSEMBLY_INSPECTION_LIST_REQUEST, getProgramAssemblyInspectionListRequest)
  yield takeLatest(actions.GET_PROGRAM_ASSEMBLY_INSPECTION_VM_REQUEST, getProgramAssemblyInspectionVMRequest)
  yield takeLatest(actions.GET_PROGRAM_ASSEMBLY_REINSPECTION_LIST_REQUEST, getProgramAssemblyReinspectionListRequest)
  yield takeLatest(actions.GET_PROGRAM_ASSEMBLY_REINSPECTION_VM_REQUEST, getProgramAssemblyReinspectionVMRequest)
  yield takeLatest(actions.UPDATE_PROGRAM_ASSEMBLY_REINSPECTION_LIST_REQUEST, updateProgramAssemblyReinspectionListRequest)
  yield takeLatest(actions.GET_PROGRAM_TASK_CLOSE_OUT_VM_REQUEST, getProgramTaskCloseOutVMRequest)
  yield takeLatest(actions.GET_PROGRAM_TASK_CLOSE_OUT_REQUEST, getProgramTaskCloseOutRequest)
  yield takeLatest(actions.ADD_PROGRAM_TASK_CLOSE_OUT_REQUEST, addProgramTaskCloseOutRequest)
  yield takeLatest(actions.UPDATE_PROGRAM_TASK_CLOSE_OUT_REQUEST, updateProgramTaskCloseOutRequest)
  yield takeLatest(actions.UPDATE_PROGRAM_ASSEMBLY_INSPECTION_LIST_REQUEST, updateProgramAssemblyInspectionListRequest)
  yield takeLatest(actions.GET_PROGRAM_CONFIRM_SITE_VISIT_LIST_REQUEST, getProgramConfirmSiteVisitListRequest)
  yield takeLatest(actions.GET_PROGRAM_CONFIRM_SITE_VISIT_VM_REQUEST, getProgramConfirmSiteVisitVMRequest)
  yield takeLatest(actions.ADD_PROGRAM_CONFIRM_SITE_VISIT_REQUEST, addProgramConfirmSiteVisitRequest)
  yield takeLatest(actions.UPDATE_PROGRAM_CONFIRM_SITE_VISIT_REQUEST, updateProgramConfirmSiteVisitRequest)
  yield takeLatest(actions.GET_PROGRAM_BASIC_DETAIL_REQUEST, getProgramBasicDetailRequest)
  yield takeLatest(actions.UPDATE_FINISH_PROGRAM_WIZARD_REQUEST, updateFinishProgramWizardRequest)
  yield takeLatest(actions.GET_PROGRAM_ASSEMBLY_CHECKLIST_LIST_REQUEST, getProgramAssemblyChecklistListRequest)
  yield takeLatest(actions.GET_OBSERVATION_TEMPLATE_VM_REQUEST, getObservationTemplateVMRequest)
  yield takeEvery(actions.GET_OBSERVATION_TEMPLATE_DDL_REQUEST, getObservationTemplateDDLRequest)
  yield takeLatest(actions.GET_TASK_REVIEW_LIST_REQUEST, getProgramTaskReviewListRequest)
}

export default programsQualityControlSagas