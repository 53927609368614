
export const GET_DATA_RETENTION_VM_REQUEST = 'GET_DATA_RETENTION_VM_REQUEST'
export const GET_CUSTOM_DATA_RETENTION_LIST_REQUEST = 'GET_CUSTOM_DATA_RETENTION_LIST_REQUEST'
export const ADD_CUSTOM_DATA_RETENTION_REQUEST = 'ADD_CUSTOM_DATA_RETENTION_REQUEST'
export const UPDATE_CUSTOM_DATA_RETENTION_REQUEST = 'UPDATE_CUSTOM_DATA_RETENTION_REQUEST'
export const DELETE_CUSTOM_DATA_RETENTION_REQUEST = 'DELETE_CUSTOM_DATA_RETENTION_REQUEST'
export const GET_CUSTOM_DATA_RETENTION_POLICY_DETAILS_REQUEST = 'GET_CUSTOM_DATA_RETENTION_POLICY_DETAILS_REQUEST'

/****************************************************/
/** GET_DATA_RETENTION_VM_REQUEST **/
/****************************************************/

/**
 * @method getDataRetentionVMRequest :  To get VM for the data retention policy
 *
 */
export const getDataRetentionVMRequest = (callback = () => { }) => {
    return {
        type: GET_DATA_RETENTION_VM_REQUEST,
        callback
    }
}

/****************************************************/
/** GET_CUSTOM_DATA_RETENTION_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCustomDataRetentionListRequest :  To get list of custom data retention policies Ids
 *
 */
export const getCustomDataRetentionListRequest = (callback = () => { }) => {
    return {
        type: GET_CUSTOM_DATA_RETENTION_LIST_REQUEST,
        callback
    }
}

/****************************************************/
/** ADD_CUSTOM_DATA_RETENTION **/
/****************************************************/

/**
 * @method addCustomDataRetentionRequest :  To add a custom data retention policy
 *
 */
export const addCustomDataRetentionRequest = (payload, callback = () => { }) => {
    return {
        type: ADD_CUSTOM_DATA_RETENTION_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** UPDATE_CUSTOM_DATA_RETENTION **/
/****************************************************/

/**
 * @method updateCustomDataRetentionRequest :  To update a custom data retention policies by Id
 *
 */
export const updateCustomDataRetentionRequest = (payload, callback = () => { }) => {
    return {
        type: UPDATE_CUSTOM_DATA_RETENTION_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** DELETE_CUSTOM_DATA_RETENTION_REQUEST **/
/****************************************************/

/**
 * @method deleteCustomDataRetentionRequest :  To delete custom data retention policies by Id
 *
 */
export const deleteCustomDataRetentionRequest = (payload, callback) => {
    return {
        type: DELETE_CUSTOM_DATA_RETENTION_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** GET_CUSTOM_DATA_RETENTION_POLICY_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method getCustomDataRetentionPolicyDetailsRequest :  To get custom data retention policies by Ids
 *
 */
export const getCustomDataRetentionPolicyDetailsRequest = (payload, callback = () => { }) => {
    return {
        type: GET_CUSTOM_DATA_RETENTION_POLICY_DETAILS_REQUEST,
        payload,
        callback
    }
}
