import { getActionType } from '../action-type-generator'

export const DOWNLOAD_RESOURCE_DOCUMENT_REQUEST = 'DOWNLOAD_RESOURCE_DOCUMENT_REQUEST'
export const GET_RESOURCE_LIST_REQUEST = 'GET_RESOURCE_LIST_REQUEST'
export const GET_RESOURCE_LIST_VM = getActionType('GET_RESOURCE_LIST_VM')
export const DELETE_RESOURCE_CENTER = getActionType('DELETE_RESOURCE_CENTER')
export const UPLOAD_RC_DOCUMENT_REQUEST = 'UPLOAD_RC_DOCUMENT_REQUEST'
export const REPLACE_RC_DOCUMENT_REQUEST = 'REPLACE_RC_DOCUMENT_REQUEST'
export const RENAME_RC_DOCUMENT_REQUEST = 'RENAME_RC_DOCUMENT_REQUEST'
export const GET_FILE_TYPE_DDL_REQUEST = 'GET_FILE_TYPE_DDL_REQUEST'
export const GET_UPLOADED_BY_DDL_REQUEST = 'GET_UPLOADED_BY_DDL_REQUEST'
export const GET_RESOURCE_CENTER_FILE_CATEGORY_DDL_REQUEST = 'GET_RESOURCE_CENTER_FILE_CATEGORY_DDL_REQUEST'

/****************************************************/
/** GET_RESOURCE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getResourceListRequest : To get resource list
 *
 */
export const getResourceListRequest = (payload, callback) => {
  return {
    type: GET_RESOURCE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_RESOURCE_CENTER **/
/****************************************************/

/**
 * @method deleteResourceCenterRequest : To delete resource center
 *
 */
export const deleteResourceCenterRequest = (payload, callback) => {
  return {
    type: DELETE_RESOURCE_CENTER.REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_RESOURCE_LIST_VM **/
/****************************************************/

/**
 * @method getResourceListVMRequest :  To get dropdowns list for resource list
 *
 */
export const getResourceListVMRequest = (payload, callback) => {
  return {
    type: GET_RESOURCE_LIST_VM.REQUEST,
    payload,
    callback
  }
}

/** @method getResourceListVMSuccess : To set dropdowns list for resource list on success
*
*/
export const getResourceListVMSuccess = (data) => {
  return {
    type: GET_RESOURCE_LIST_VM.SUCCESS,
    data
  }
}

/** @method getResourceListVMFailure : To set error on getting dropdowns list for resource list
*
*/
export const getResourceListVMFailure = (error) => {
  return {
    type: GET_RESOURCE_LIST_VM.FAILURE,
    error
  }
}

/****************************************************/
/** DOWNLOAD_RESOURCE_DOCUMENT_REQUEST **/
/****************************************************/

/**
 * @method downloadResourceDocument :  To download resource center document
 *
 */
export const downloadResourceDocument = (payload, callback) => {
  return {
    type: DOWNLOAD_RESOURCE_DOCUMENT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPLOAD_RC_DOCUMENT_REQUEST **/
/****************************************************/

/**
 * @method uploadRCDocumentRequest :  To upload document to resource center
 *
 */
export const uploadRCDocumentRequest = (payload, callback) => {
  return {
    type: UPLOAD_RC_DOCUMENT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** REPLACE_RC_DOCUMENT_REQUEST **/
/****************************************************/

/**
 * @method replaceRCDocumentRequest :  To replace uploaded document to resource center
 *
 */
export const replaceRCDocumentRequest = (payload, callback) => {
  return {
    type: REPLACE_RC_DOCUMENT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** RENAME_RC_DOCUMENT_REQUEST **/
/****************************************************/

/**
 * @method renameRCDocumentRequest :  To rename uploaded document in resource center
 *
 */
export const renameRCDocumentRequest = (payload, callback) => {
  return {
    type: RENAME_RC_DOCUMENT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_FILE_TYPE_DDL_REQUEST **/
/****************************************************/

/**
 * @method getFileTypeDDLRequest : To get paginated file type list
 *
 */
export const getFileTypeDDLRequest = (payload, callback) => {
  return {
    type: GET_FILE_TYPE_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_UPLOADED_BY_DDL_REQUEST **/
/****************************************************/

/**
 * @method getUploadedByDDLRequest : To get paginated uploaded by list
 *
 */
export const getUploadedByDDLRequest = (payload, callback) => {
  return {
    type: GET_UPLOADED_BY_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_RESOURCE_CENTER_FILE_CATEGORY_DDL_REQUEST **/
/****************************************************/

/**
 * @method getResourceCenterFileCategoryDDLRequest :  To get dropdowns list for resource file category
 *
 */
export const getResourceCenterFileCategoryDDLRequest = (payload, callback) => {
  return {
    type: GET_RESOURCE_CENTER_FILE_CATEGORY_DDL_REQUEST,
    payload,
    callback
  }
}