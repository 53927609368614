import EXIF from "exif-js"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'

const { ERRORS } = LANGUAGE_CONSTANTS.ORG_PLANS

export const getInitialState = ({
  isOrgPlan,
  siteUnitPlanId,
  siteUnitPlanDetail,
  initialState
}) => {
  const updatedInitialState = Object.assign({}, { ...initialState })
  if (!isOrgPlan && siteUnitPlanId) {
    updatedInitialState.organizationPlanId = siteUnitPlanDetail.organizationPlanId
    updatedInitialState.planName = siteUnitPlanDetail.planName
    updatedInitialState.blueprintId = siteUnitPlanDetail.organizationBluePrintId ? siteUnitPlanDetail.organizationBluePrintId.toString() : ""
    updatedInitialState.blueprintName = siteUnitPlanDetail.organizationBluePrintName
    updatedInitialState.energyModelId = siteUnitPlanDetail.organizationEnergyModelId ? siteUnitPlanDetail.organizationEnergyModelId.toString() : ""
    updatedInitialState.energyModelName = siteUnitPlanDetail.organizationEnergyModelName
  }
  return updatedInitialState
}

export const validatePlanDetail = ({ data, blueprintFiles, energyModelFiles, siteUnitId, isEnableAddNewPlanMode }) => {
  const errors = {}
  if (!data.planName) {
    errors['planName'] = <LocaleMessage id={ERRORS.SELECT_PLAN_NAME} />
  }
  if (!data.totalConditionSpace) {
    errors['totalConditionSpace'] = <LocaleMessage id={ERRORS.ENTER_TOTAL_CONDITION_SPACE} />
  }
  if (!data.firstFloorArea) {
    errors['firstFloorArea'] = <LocaleMessage id={ERRORS.ENTER_FIRST_FLOOR_AREA} />
  }
  if (!data.secondFloorArea) {
    errors['secondFloorArea'] = <LocaleMessage id={ERRORS.ENTER_SECOND_FLOOR_AREA} />
  }
  if (!data.thirdFloorArea) {
    errors['thirdFloorArea'] = <LocaleMessage id={ERRORS.ENTER_THIRD_FLOOR_AREA} />
  }
  if (!data.fourthFloorArea) {
    errors['fourthFloorArea'] = <LocaleMessage id={ERRORS.ENTER_FOURTH_FLOOR_AREA} />
  }
  if (!data.volume) {
    errors['volume'] = <LocaleMessage id={ERRORS.ENTER_VOLUME} />
  }

  if (siteUnitId) {
    if (!data.organizationPlanId && !data.planName) {
      errors['organizationPlanId'] = <LocaleMessage id={ERRORS.SELECT_PLAN_NAME} />
    }

    if (isEnableAddNewPlanMode) {
      if (!data.blueprintName) {
        errors['blueprintName'] = <LocaleMessage id={ERRORS.ENTER_BLUEPRINT_NAME} />
      }
      if (!blueprintFiles.length) {
        errors['blueprintFiles'] = <LocaleMessage id={ERRORS.UPLOAD_BLUEPRINT_FILES} />
      }

      if (!!energyModelFiles.length && !data.energyModelName) {
        errors['energyModelName'] = <LocaleMessage id={ERRORS.ENTER_ENERGY_MODEL_NAME} />
      }
      if (!!data.energyModelName && !energyModelFiles.length) {
        errors['energyModelFiles'] = <LocaleMessage id={ERRORS.UPLOAD_ENERGY_MODEL_FILES} />
      }
    }
  } else {
    //For org plan creation
    if (!data.organizationPlanId) {
      if (!data.blueprintName) {
        errors['blueprintName'] = <LocaleMessage id={ERRORS.ENTER_BLUEPRINT_NAME} />
      }
      if (!blueprintFiles.length) {
        errors['blueprintFiles'] = <LocaleMessage id={ERRORS.UPLOAD_BLUEPRINT_FILES} />
      }

      if (!!energyModelFiles.length && !data.energyModelName) {
        errors['energyModelName'] = <LocaleMessage id={ERRORS.ENTER_ENERGY_MODEL_NAME} />
      }
      if (!!data.energyModelName && !energyModelFiles.length) {
        errors['energyModelFiles'] = <LocaleMessage id={ERRORS.UPLOAD_ENERGY_MODEL_FILES} />
      }
    }
  }

  return errors
}

export const validateBlueprintDetail = (data, blueprintFiles) => {
  const errors = {}
  if (!data.blueprintName) {
    errors['blueprintName'] = <LocaleMessage id={ERRORS.ENTER_BLUEPRINT_NAME} />
  }
  if (!blueprintFiles.length) {
    errors['blueprintFiles'] = <LocaleMessage id={ERRORS.UPLOAD_BLUEPRINT_FILES} />
  }
  return errors
}

export const validateEnergyModelDetail = (data, energyModelFiles) => {
  const errors = {}
  if (!data.energyModelName) {
    errors['energyModelName'] = <LocaleMessage id={ERRORS.ENTER_ENERGY_MODEL_NAME} />
  }
  if (!energyModelFiles.length) {
    errors['energyModelFiles'] = <LocaleMessage id={ERRORS.UPLOAD_ENERGY_MODEL_FILES} />
  }
  return errors
}

export const hasValidationErrorInPlanDetails = (errors) => {
  const planDetailFieldList = ["totalConditionSpace", "firstFloorArea", "secondFloorArea", "thirdFloorArea", "fourthFloorArea", "volume"]
  return  Object.keys(errors || {}).some(key => planDetailFieldList.includes(key))
}

export const hasValidationErrorInBlueprintDetails = (errors) => {
  const blueprintFieldList = ["blueprintName", "blueprintFiles"]
  return  Object.keys(errors || {}).some(key => blueprintFieldList.includes(key))
}

export const hasValidationErrorInEnergyModelDetails = (errors) => {
  const energyModelFieldList = ["energyModelName", "energyModelFiles"]
  return  Object.keys(errors || {}).some(key => energyModelFieldList.includes(key))
}

export const hasValidationErrorInPlanNameDetails = (errors) => {
  const planNameFieldList = ["planName"]
  return  Object.keys(errors || {}).some(key => planNameFieldList.includes(key))
}

export const getImageMetadata = (file, callback = () => { }) => {
  if (file && file.name) {
    EXIF.getData(file, function () {
      const exifData = EXIF.pretty(file)
      const allTags = EXIF.getAllTags(file)
      if (exifData && allTags["GPSLatitude"] && allTags["GPSLatitudeRef"]) {
        const metadata = {
          lat: converttoDMS(allTags["GPSLatitude"], allTags["GPSLatitudeRef"]),
          long: converttoDMS(allTags["GPSLongitude"], allTags["GPSLongitudeRef"]),
          captureDateAndTime: allTags["DateTime"],
          captureDevice: `${allTags["Make"]}${" "}${allTags["Model"]}`
        }
        callback(JSON.stringify(metadata))
      } else {
        callback(null)
      }
    })
  }
}

export const getFormattedOrgPlanPayload = ({
  planDetail,
  organizationId,
  siteUnitId,
  siteUnitPlanId
}) => {
  //Note: When organizationPlanId is there, its edit mode for plan details
  const payload = {
    organizationPlanId: Number(planDetail.organizationPlanId || ""),
    organizationId,
    planName: planDetail.planName,
    planNumber: planDetail.planNumber,
    elevation: planDetail.elevation,
    swing: planDetail.swing,
    totalConditionSpace: Number(planDetail.totalConditionSpace),
    firstFloorArea: Number(planDetail.firstFloorArea),
    secondFloorArea: Number(planDetail.secondFloorArea),
    thirdFloorArea: Number(planDetail.thirdFloorArea),
    fourthFloorArea: Number(planDetail.fourthFloorArea),
    volume: Number(planDetail.volume),
    mfncCompartmentalizationArea: Number(planDetail.mfncArea),
    isMissingItems: planDetail.missingItems === "true",
    organizationBluePrintId: Number(planDetail.blueprintId || ""),
    bluePrintName: planDetail.blueprintName,
    organizationEnergyModelId: Number(planDetail.energyModelId || ""),
    energyModelName: planDetail.energyModelName || ""
  }

  if (siteUnitId) {
    payload.siteUnitId = siteUnitId
  } else {
    if (!!payload.organizationPlanId) {
      //For org plan edit
      delete payload.bluePrintName
      delete payload.organizationBluePrintId
      delete payload.energyModelName
      delete payload.organizationEnergyModelId
    }
  }

  if (!!siteUnitPlanId) {
    payload.siteUnitPlanId = siteUnitPlanId

    if (!!planDetail.organizationPlanId) {
       //For unit plan edit for already attached unit plan, with plan selected from ddl
      delete payload.organizationId
      delete payload.bluePrintName
      delete payload.energyModelName
    }
  }

  return payload
}
