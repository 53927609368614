import { getSearchQuery } from "../config/index"

const apiConfig = {
    getCalendarOrganizationDDL: {
        method: "GET",
        url: ({
            organizationName = [],
            pageSize,
            pageNumber
        }) => {
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("OrganizationName", organizationName, false, true)
            ].join('')
            return `api/Scheduling/Calendar/DDL/Organizations${searchString}`
        }
    },
    getCalendarSiteDDL: {
        method: "GET",
        url: ({
            organizations = [],
            siteName,
            pageNumber,
            pageSize
        }) => {
            const organizationList = organizations.map((org) => getSearchQuery("OrganizationIds", org.value)).join("")
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("SiteName", siteName, false, true),
                organizationList
            ].join('')
            return `api/Scheduling/Calendar/DDL/Sites${searchString}`
        }
    },
    getCalendarWorkflowsDDL: {
        method: "GET",
        url: ({
            organizations = [],
            sites = [],
            regions = [],
            campaigns = [],
            subRegions = [],
            states = [],
            cities = [],
            zipCodes = [],
            workflowName,
            pageNumber,
            pageSize
        }) => {
            const organizationList = organizations.map((org) => getSearchQuery("OrganizationIds", org.value)).join("")
            const sitesList = sites.map((site) => getSearchQuery("SiteIds", site.value)).join("")
            const regionList = regions.map((region) => getSearchQuery("RegionIds", region.value)).join("")
            const subRegionList = subRegions.map((subRegion) => getSearchQuery("SubRegionIds", subRegion.value)).join("")
            const stateList = states.map((state) => getSearchQuery("States", state.value)).join("")
            const cityList = cities.map((city) => getSearchQuery("Cities", city.value)).join("")
            const zipCodeList = zipCodes.map((zipCode) => getSearchQuery("ZipCodes", zipCode.value)).join("")
            const campaignList = campaigns.map((campaign) => getSearchQuery("CampainIds", campaign.value)).join("")
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("WorkflowName", workflowName, false, true),
                organizationList,
                sitesList,
                regionList,
                subRegionList,
                stateList,
                cityList,
                zipCodeList,
                campaignList
            ].join('')
            return `api/Scheduling/Calendar/DDL/Workflows${searchString}`
        }
    },
    getCalendarContactsDDL: {
        method: "GET",
        url: ({
            organizations = [],
            sites = [],
            contactName,
            pageNumber,
            pageSize
        }) => {
            const organizationList = organizations.map((org) => getSearchQuery("OrganizationIds", org)).join("")
            const sitesList = sites.map((site) => getSearchQuery("SiteIds", site)).join("")
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("ContactName", contactName, false, true),
                organizationList,
                sitesList
            ].join('')
            return `api/Scheduling/Calendar/DDL/Contacts${searchString}`
        }
    },
    getCalendarStatesDDL: {
        method: "GET",
        url: ({
            countryCode = "us",
            regions = [],
            subRegions = [],
            cities = [],
            zipCodes = [],
            stateName,
            pageNumber,
            pageSize
        }) => {
            const regionList = regions.map((region) => getSearchQuery("RegionIds", region)).join("")
            const subRegionList = subRegions.map((site) => getSearchQuery("SubRegionIds", site)).join("")
            const cityList = cities.map((city) => getSearchQuery("Cities", city)).join("")
            const zipCodeList = zipCodes.map((zipCode) => getSearchQuery("ZipCodes", zipCode)).join("")
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("CountryCode", countryCode),
                getSearchQuery("StateName", stateName, false, true),
                regionList,
                subRegionList,
                cityList,
                zipCodeList
            ].join('')
            return `api/Scheduling/Calendar/DDL/States${searchString}`
        }
    },
    getCalendarFiltersVM: {
        method: 'GET',
        url: () => `api/Scheduling/Calendar/VM`
    },
    getCalendarScheduleList: {
        method: "GET",
        url: ({
            contactIds,
            organizationIds,
            siteIds,
            workflowIds,
            states,
            cities,
            zipCodes,
            regionIds,
            campaigns = [],
            subRegionIds,
            neededCountOfCards = -1,
            startDate,
            endDate,
            byViewTypeId
        }) => {
            const contactList = contactIds.map((contact) => getSearchQuery("AssigneeIds", contact.value)).join("")
            const organizationList = organizationIds.map((org) => getSearchQuery("OrganizationIds", org.value)).join("")
            const siteList = siteIds.map((site) => getSearchQuery("SiteIds", site.value)).join("")
            const workflowList = workflowIds.map((workflow) => getSearchQuery("WFCampaignSetupIds", workflow.value)).join("")
            const stateList = states.map((state) => getSearchQuery("States", state.value)).join("")
            const cityList = cities.map((city) => getSearchQuery("Cities", city.value, false, true)).join("")
            const zipCodeList = zipCodes.map((zipCode) => getSearchQuery("ZipCodes", zipCode.value)).join("")
            const regionList = regionIds.map((region) => getSearchQuery("RegionIds", region.value)).join("")
            const subRegionList = subRegionIds.map((subRegion) => getSearchQuery("SubRegionIds", subRegion.value)).join("")
            const campaignList = campaigns.map((campaign) => getSearchQuery("CampaignIds", campaign.value)).join("")

            const searchString = [
                getSearchQuery("StartDate", startDate, true),
                getSearchQuery("EndDate", endDate),
                contactList,
                organizationList,
                siteList,
                workflowList,
                stateList,
                regionList,
                subRegionList,
                cityList,
                zipCodeList,
                campaignList,
                getSearchQuery("ByViewTypeId", byViewTypeId),
                getSearchQuery("NeededCountOfCards", neededCountOfCards)
            ].join('')
            return `api/Scheduling/Calendar/List${searchString}`
        }
    }
}

export default apiConfig