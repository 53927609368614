
import { put, takeLatest, takeEvery, call } from "redux-saga/effects"

import { getCampaignServiceChecklistCheckpointList, deleteCampaignServiceChecklistCheckpoint } from "@services/api"
import * as actions from "@store/actions"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'

const { ACTIVITY_SAGA } = LANGUAGE_CONSTANTS

/** getCampaignServiceChecklistCheckpointList API **/
function* getCampaignServiceChecklistCheckpointListRequest({ payload, callback = () => { } }) {
  try {
    const { isSearchOnly = false, pageSize } = payload
    const response = yield call(getCampaignServiceChecklistCheckpointList, payload)
    if (response?.data) {
      const paginationData = { ...response.data, pageSize }
      callback(paginationData)
      if (!isSearchOnly) {
        yield put(actions.getCampaignServiceChecklistCheckpointListSuccess({
          checklistId: payload.checklistId,
          data: paginationData
        }))
      }
    } else {
      callback()
      if (!isSearchOnly) {
        yield put(actions.getCampaignServiceChecklistCheckpointListFailure("Unable to load campaign service checklist activity list"))
      }
    }
  } catch (error) {
    if (!payload.isSearchOnly) {
      yield put(actions.getCampaignServiceChecklistCheckpointListFailure(error.message))
    }
    callback()
  }
}

/** deleteCampaignServiceChecklistCheckpoint API **/
function* deleteCampaignServiceChecklistCheckpointRequest({ payload, callback = () => { } }) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteCampaignServiceChecklistCheckpoint, payload)
    if (response?.data?.status) {
      callback()
      Notification({ status: 200, message: ACTIVITY_SAGA.DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: ACTIVITY_SAGA.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
    callback()
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: ACTIVITY_SAGA.ERROR_IN_DELETING })
  }
}

export function* campaignServiceChecklistCheckpointSagas() {
  yield takeEvery(actions.GET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST.REQUEST, getCampaignServiceChecklistCheckpointListRequest)
  yield takeLatest(actions.DELETE_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT, deleteCampaignServiceChecklistCheckpointRequest)
}

export default campaignServiceChecklistCheckpointSagas