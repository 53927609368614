import { put, takeLatest, call } from "redux-saga/effects"

import {
  getTemplateListVM,
  getTemplateSetupVM,
  addTemplateDetail,
  updateTemplateDetail,
  getTemplateDetail,
  getTemplateList,
  deleteTemplate,
  getTemplateDescription,
  addNewTemplateVersion,
  cloneTemplate,
  getTemplateVersionDDL,
  getTemplateActivityGroupList,
  deleteTemplateActivityGroup,
  deleteActivityFromTemplateActivityGroup,
  addTemplateActivityGroup,
  updateTemplateActivityGroup,
  getTemplateActivityGroupChildrenList,
  reorderTemplateActivityGroup,
  reorderTemplateActivitiesGroupChildrenList,
  addActivitiesToTemplateActivityGroup,
  getUngroupedTemplateActivities
} from "@services/api"
import * as actions from "@store/actions"
import { API_ERROR_STATUS_CODE } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'

const { TEMPLATE_SAGA, TEMPLATE_ACTIVITIES_GROUP_SAGA } = LANGUAGE_CONSTANTS

/** getTemplateListVMRequest API **/
function* getTemplateListVMRequest(action) {
  try {
    const response = yield call(getTemplateListVM, {})
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTemplateSetupVMRequest API **/
function* getTemplateSetupVMRequest(action) {
  try {
    const response = yield call(getTemplateSetupVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** addTemplateDetail API **/
function* addTemplateDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addTemplateDetail, action.payload)
    if (response?.data) {
      yield put(actions.addTemplateDetailSuccess(response.data))
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: TEMPLATE_SAGA.ADDED_SUCCESS })
      action.callback(response.data)
    } else {
      yield put(actions.addTemplateDetailFailure("Unable to add template detail"))
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_IN_ADDING })
    }
  } catch (error) {
    yield put(actions.addTemplateDetailFailure(error.message))
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_IN_ADDING })
  }
}

/** updateTemplateDetail API **/
function* updateTemplateDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateTemplateDetail, action.payload)
    if (response?.status === 204) {
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: TEMPLATE_SAGA.UPDATED_SUCCESS })
      action.callback()
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_IN_UPDATING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_IN_UPDATING })
  }
}

/** getTemplateDetail API **/
function* getTemplateDetailRequest(action) {
  try {
    const response = yield call(getTemplateDetail, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      if (response?.statusText === API_ERROR_STATUS_CODE.NO_CONTENT) {
        Notification({ status: 400, message: TEMPLATE_SAGA.NO_CONTENT_FOR_SELECTED_TEMPLATE })
      } else {
        Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_IN_FETCHING_TEMPLATE_DETAIL })
      }
      action.callback()
    }
  } catch (error) {
    Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_IN_FETCHING_TEMPLATE_DETAIL })
    action.callback()
  }
}

/** getTemplateList API **/
function* getTemplateListRequest(action) {
  try {
    const { ...payload } = action.payload
    const response = yield call(getTemplateList, payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: payload.pageSize })
    } else {
      action.callback()
      Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_IN_FETCHING_LIST })
    }
  } catch (error) {
    Notification({ status: 400, isLocalizedMessage: !(error.response?.statusText || error.message), message: error.response?.statusText || error.message || TEMPLATE_SAGA.ERROR_IN_FETCHING_LIST })
    action.callback()
  }
}

/** deleteTemplate API **/
function* deleteTemplateRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteTemplate, action.payload)
    action.callback()
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      Notification({ status: 200, message: TEMPLATE_SAGA.DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_IN_DELETING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getTemplateDescription API **/
function* getTemplateDescriptionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getTemplateDescription, action.payload)
    yield put(actions.setIsLoading(false))
    action.callback(response?.data)
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** addNewTemplateVersion API **/
function* addNewTemplateVersionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addNewTemplateVersion, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.data) {
      action.callback(response.data)
      Notification({ status: 200, message: TEMPLATE_SAGA.NEW_VERSION_SUCCESS })
    } else {
      Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_ADDING_NEW_VERSION })
      action.callback(null)
    }
  } catch (error) {
    Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_ADDING_NEW_VERSION })
    yield put(actions.setIsLoading(false))
    action.callback(null)
  }
}

function* cloneTemplateRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(cloneTemplate, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.data) {
      action.callback(response.data)
      Notification({ status: 200, message: TEMPLATE_SAGA.CLONE_SUCCESS })
    } else {
      Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_IN_CLONING })
      action.callback(null)
    }
  } catch (error) {
    Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_IN_CLONING })
    yield put(actions.setIsLoading(false))
    action.callback(null)
  }
}

/** getTemplateVersionDDL API **/
function* getTemplateVersionDDLRequest(action) {
  try {
    const response = yield call(getTemplateVersionDDL, action.payload)
    action.callback(response?.data)
    if (!response?.data) {
      Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_IN_FETCHING_TEMPLATE_VERSION_DDL })
    }
  } catch (error) {
    Notification({ status: 400, message: TEMPLATE_SAGA.ERROR_IN_FETCHING_TEMPLATE_VERSION_DDL })
    action.callback(null)
  }
}

/** getTemplateActivityGroupList API **/
function* getTemplateActivityGroupListRequest(action) {
  try {
    const response = yield call(getTemplateActivityGroupList, action.payload)
    if (response?.data) {
      action.callback(response.data)
    }
  } catch (error) {
    action.callback()
  }
}

/** deleteTemplateActivityGroup API **/
function* deleteTemplateActivityGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteTemplateActivityGroup, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.REMOVED_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.ERROR_IN_REMOVING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** deleteActivityFromTemplateActivityGroup API **/
function* deleteActivityFromTemplateActivityGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteActivityFromTemplateActivityGroup, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.ACTIVITY_REMOVED_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.ERROR_IN_REMOVING_ACTIVITY })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** addTemplateActivityGroup API */
function* addTemplateActivityGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addTemplateActivityGroup, action.payload)
    if (response?.status === 200) {
      action.callback(response?.data)
      Notification({ status: 200, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.ADDING_GROUP_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.ERROR_IN_ADDING_GROUP })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** updateTemplateActivityGroup API */
function* updateTemplateActivityGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateTemplateActivityGroup, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.UPDATE_ACTIVITY_GROUP_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.ERROR_IN_UPDATING_GROUP })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getTemplateActivityGroupChildrenList API **/
function* getTemplateActivityGroupChildrenListRequest(action) {
  try {
    const response = yield call(getTemplateActivityGroupChildrenList, action.payload)
    if (response?.data) {
      action.callback(response.data)
    }
  } catch (error) {
    action.callback()
  }
}

/** reorderTemplateActivityGroup API */
function* reorderTemplateActivityGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(reorderTemplateActivityGroup, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.ACTIVITY_GROUP_REORDER_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.ERROR_IN_REORDERING_ACTIVITY_GROUP })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** reorderTemplateActivitiesGroupChildrenList API **/
function* reorderTemplateActivitiesGroupChildrenListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(reorderTemplateActivitiesGroupChildrenList, action.payload)
    if (response?.status === 204) {
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.GROUP_ACTIVITIES_REORDER_SUCCESS })
      action.callback()
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.ERROR_IN_REORDERING_GROUP_ACTIVITIES })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** addActivitiesToTemplateActivityGroup API **/
function* addActivitiesToTemplateActivityGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addActivitiesToTemplateActivityGroup, action.payload)
    if (response?.status === 204) {
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.ADDING_ACTIVITIES_TO_GROUP_SUCCESS })
      action.callback(true)
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: TEMPLATE_ACTIVITIES_GROUP_SAGA.ERROR_IN_ADDING_ACTIVITIES_TO_GROUP })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** getUngroupedTemplateActivities API **/
function* getUngroupedTemplateActivitiesRequest(action) {
  try {
    const response = yield call(getUngroupedTemplateActivities, action.payload)
    if (response?.data) {
      action.callback(response.data)
    }
  } catch (error) {
    action.callback()
  }
}

export function* templateSagas() {
  yield takeLatest(actions.GET_TEMPLATE_LIST_VM_REQUEST, getTemplateListVMRequest)
  yield takeLatest(actions.GET_TEMPLATE_SETUP_VM_REQUEST, getTemplateSetupVMRequest)
  yield takeLatest(actions.ADD_TEMPLATE_DETAIL.REQUEST, addTemplateDetailRequest)
  yield takeLatest(actions.UPDATE_TEMPLATE_DETAIL_REQUEST, updateTemplateDetailRequest)
  yield takeLatest(actions.GET_TEMPLATE_DETAIL_REQUEST, getTemplateDetailRequest)
  yield takeLatest(actions.GET_TEMPLATE_LIST_REQUEST, getTemplateListRequest)
  yield takeLatest(actions.DELETE_TEMPLATE_REQUEST, deleteTemplateRequest)
  yield takeLatest(actions.GET_TEMPLATE_DESCRIPTION_REQUEST, getTemplateDescriptionRequest)
  yield takeLatest(actions.ADD_NEW_TEMPLATE_VERSION_REQUEST, addNewTemplateVersionRequest)
  yield takeLatest(actions.CLONE_TEMPLATE_REQUEST, cloneTemplateRequest)
  yield takeLatest(actions.GET_TEMPLATE_VERSION_DDL_REQUEST, getTemplateVersionDDLRequest)
  yield takeLatest(actions.GET_TEMPLATE_ACTIVITY_GROUP_LIST_REQUEST, getTemplateActivityGroupListRequest)
  yield takeLatest(actions.DELETE_TEMPLATE_ACTIVITY_GROUP_REQUEST, deleteTemplateActivityGroupRequest)
  yield takeLatest(actions.DELETE_ACTIVITY_FROM_TEMPLATE_ACTIVITY_GROUP_REQUEST, deleteActivityFromTemplateActivityGroupRequest)
  yield takeLatest(actions.ADD_TEMPLATE_ACTIVITY_GROUP_REQUEST, addTemplateActivityGroupRequest)
  yield takeLatest(actions.UPDATE_TEMPLATE_ACTIVITY_GROUP_REQUEST, updateTemplateActivityGroupRequest)
  yield takeLatest(actions.GET_TEMPLATE_ACTIVITY_GROUP_CHILDREN_LIST_REQUEST, getTemplateActivityGroupChildrenListRequest)
  yield takeLatest(actions.REORDER_TEMPLATE_ACTIVITY_GROUP_REQUEST, reorderTemplateActivityGroupRequest)
  yield takeLatest(actions.REORDER_TEMPLATE_ACTIVITIES_GROUP_CHILDREN_LIST_REQUEST, reorderTemplateActivitiesGroupChildrenListRequest)
  yield takeLatest(actions.ADD_ACTIVITIES_TO_TEMPLATE_ACTIVITY_GROUP_REQUEST, addActivitiesToTemplateActivityGroupRequest)
  yield takeLatest(actions.GET_UNGROUPED_TEMPLATE_ACTIVITIES_REQUEST, getUngroupedTemplateActivitiesRequest)
}

export default templateSagas
