import config from "./APIConfig"
import { postDataApi } from '../index'

export const saveCompleteTask = (data) => {
  const { url } = config.saveCompleteTask

  return postDataApi(url(data), data)
}

export default saveCompleteTask
