import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addCampaignServiceDetail
 * @param {object} data 
 *{
  "campaignId": 0,
  "campaignServiceTypeId": 0,
  "campaignServiceName": '',
  "campaignSchedulerDriverId": 0,
  "saturationRate": 0,
  "timeIncrement": 0,
  "campaignTimeIncrementTypeId": 0,
  "campaignEventTypeId": 0,
  "organizationTypeId": 0,
  "organizationId": 0
  }
 *
 */
export const addCampaignServiceDetail = (data) => {
  const { url } = config.addCampaignServiceDetail

  return postDataApi(url, data)
}

export default addCampaignServiceDetail
