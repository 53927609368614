
import { GET_PROJECT_SITE_PLAN_LIST, RESET_PROJECT_SITE_PLAN_LIST, SET_EDIT_SITE_PLAN_MODE } from '../../actions'
import { DEFAULT_TABLE_PAGE_SIZE } from '@shared/constants'

// ** Initial State
const initialState = {
  list: [],
  pageNumber: 0,
  pageSize: DEFAULT_TABLE_PAGE_SIZE,
  totalCount: 0,
  totalPages: 0,
  error: "",
  hasPreviousPage: false,
  hasNextPage: false,
  isEditMode: false,
  sitePlanDetail: null
}

const ProjectSitePlansReducer = (state = initialState, action) => {
  switch (action.type) {

    /** GET_PROJECT_SITE_PLAN_LIST **/
    case GET_PROJECT_SITE_PLAN_LIST.REQUEST:
      return { ...state, error: "" }
    case GET_PROJECT_SITE_PLAN_LIST.SUCCESS:
      return {
        ...state,
        list: action.data.items,
        pageSize: action.data.pageSize,
        pageNumber: action.data.pageIndex,
        totalCount: action.data.totalCount,
        totalPages: action.data.totalPages,
        hasPreviousPage: action.data.hasPreviousPage,
        hasNextPage: action.data.hasNextPage
      }
    case GET_PROJECT_SITE_PLAN_LIST.FAILURE:
      return {
        ...state,
        error: action.error
      }

    /** RESET_PROJECT_SITE_PLAN_LIST **/
    case RESET_PROJECT_SITE_PLAN_LIST:
      return {
        ...state,
        list: [],
        pageNumber: 0,
        pageSize: DEFAULT_TABLE_PAGE_SIZE,
        totalCount: 0,
        totalPages: 0,
        error: "",
        hasPreviousPage: false,
        hasNextPage: false
      }

    /** SET_EDIT_SITE_PLAN_MODE **/
    case SET_EDIT_SITE_PLAN_MODE:
      return {
        ...state,
        isEditMode: action.payload.isEditMode,
        sitePlanDetail: action.payload.sitePlanDetail
      }

    default:
      return state
  }
}

export default ProjectSitePlansReducer
