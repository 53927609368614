import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addProjectSiteRole
 * @param {object} data 
 *  {
  "siteIds": "string",
  "siteRoles": [
    {
      "sitePartyRoleId": 0,
      "organizationId": 0,
      "poC_ContactId": 0
    }
  ]
}
 *
 */
export const addSiteRoles = (data) => {
  const { url } = config.addSiteRoles

  const payload = {
    siteIds: data.siteIds,
    siteRoles: [
      {
      sitePartyRoleId: Number(data.sitePartyRoleId),
      organizationId: Number(data.organizationId),
      poC_ContactId: Number(data.contactId),
      poC_OrganizationId: Number(data.poC_OrganizationId)
    }
  ]
  }
  return postDataApi(url, payload)
}

export default addSiteRoles
