import { LANGUAGE_CONSTANTS } from "@shared/language-constants"

const { DASHBOARD: { TABLE_COLUMNS, ROW_ACTIONS }, ADDRESS } = LANGUAGE_CONSTANTS

export const DASHBOARD_PAGES_TYPES = {
   PLAN_REVIEW_LEAD: 1,
   PLAN_REVIEW: 2,
   FILE_PROCESS_LEAD: 3,
   FILE_PROCESS: 4,
   PROVIDERSHIP: 5,
   REPORT_PROCESSOR: 6,
   WORK_ORDER: 7,
   PERFORMANCE: 8,
   FIELD_OPS: "",
   BUILD_QUALITY: ""
}

export const DASHBOARD_WIDGETS_TYPES = {
   [DASHBOARD_PAGES_TYPES.PLAN_REVIEW_LEAD]: 1,
   [DASHBOARD_PAGES_TYPES.PLAN_REVIEW]: 2,
   [DASHBOARD_PAGES_TYPES.FILE_PROCESS_LEAD]: 1,
   [DASHBOARD_PAGES_TYPES.FILE_PROCESS]: 2
}

export const PROVIDERSHIP_DASHBOARD_STATUS_TYPES = {
   TOGGLE_PLAN_REQUEST_TYPE : 'Plan Requested',
   AUDIT_WITH_CAUSE: 'Audit with Cause',
   AUDIT_RANDOM: 'Audit Random'
}

export const gridFiltersConfig = [
   {
      labelLocaleId: TABLE_COLUMNS.PRIORITY,
      keyName: "priority"
   },
   {
      labelLocaleId: TABLE_COLUMNS.PARENT_ORGANIZATION,
      keyName: "parentOrganizationName",
      keyNameForValue: "parentOrganizationId"
   },
   {
      labelLocaleId: TABLE_COLUMNS.ORGANISATION,
      keyName: "organizationName",
      keyNameForValue: "organization"
   },
   {
      labelLocaleId: TABLE_COLUMNS.SITE,
      keyName: "siteName",
      keyNameForValue: "site"
   },
   {
      labelLocaleId: TABLE_COLUMNS.UNIT_NAME,
      keyName: "unitName",
      keyNameForValue: "unit"
   },
   {
      labelLocaleId: TABLE_COLUMNS.ASSIGNEE,
      keyName: "assigneeName",
      keyNameForValue: "assignee"
   },
   {
      labelLocaleId: TABLE_COLUMNS.TASK,
      keyName: "task"
   },
   {
      labelLocaleId: TABLE_COLUMNS.PLAN_REVIEW,
      keyName: "planReview"
   },
   {
      labelLocaleId: TABLE_COLUMNS.FILE_PROCESS,
      keyName: "fileProcess"
   },
   {
      labelLocaleId: TABLE_COLUMNS.MISSING_PLAN,
      keyName: "missingPlan"
   },
   {
      labelLocaleId: TABLE_COLUMNS.ASSIGNED_ON_START,
      keyName: "assignedOnStart",
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.ASSIGNED_ON_END,
      keyName: "assignedOnEnd",
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.FINAL_INSPECTION_ON_START,
      keyName: "finalInspectionOnStart",
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.FINAL_INSPECTION_ON_END,
      keyName: "finalInspectionOnEnd",
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.MISSING_CHECKLIST,
      keyName: "missingChecklist"
   },
   {
      labelLocaleId: TABLE_COLUMNS.MISSING_DATE,
      keyName: "missingDate"
   },
   {
      labelLocaleId: TABLE_COLUMNS.RATER,
      keyName: "raterName",
      keyNameForValue: "rater"
   },
   {
      labelLocaleId: TABLE_COLUMNS.STATUS,
      keyName: "statusId"
   },
   {
      labelLocaleId: TABLE_COLUMNS.STREET_ADDRESS,
      keyName: "streetAddress"
   },
   {
      labelLocaleId: TABLE_COLUMNS.SUBMITTED_ON_START_DATE,
      keyName: "submittedOnStartDate",
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.SUBMITTED_ON_END_DATE,
      keyName: "submittedOnEndDate",
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.REGISTERED_ON_START_DATE,
      keyName: "registeredOnStartDate",
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.REGISTERED_ON_END_DATE,
      keyName: "registeredOnEndDate",
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.DIVISION,
      keyName: "divisionName",
      keyNameForValue: "division"
   },
   {
      labelLocaleId: TABLE_COLUMNS.PERMIT_NUMBER,
      keyName: "permitNumber"
   },
   {
      labelLocaleId: TABLE_COLUMNS.SITE,
      keyName: "workOrderSite"
   },
   {
      labelLocaleId: ADDRESS.CITY,
      keyName: "city"
   },
   {
      labelLocaleId: ADDRESS.STATE,
      keyName: "state"
   },
   {
      labelLocaleId: ADDRESS.MARKET,
      keyName: "region"
   },
   {
      labelLocaleId: ADDRESS.SUB_MARKET,
      keyName: "subRegion"
   },
   {
      labelLocaleId: ADDRESS.COUNTY,
      keyName: "county"
   },
   {
      labelLocaleId: TABLE_COLUMNS.MUNICIPALITY,
      keyName: "municipality"
   },
   {
      labelLocaleId: TABLE_COLUMNS.JOB_SITE_TYPE,
      keyName: "jobSiteType"
   },
   {
      labelLocaleId: TABLE_COLUMNS.BUILDER_TYPE,
      keyName: "builderType"
   },
   {
      labelLocaleId: TABLE_COLUMNS.SERVICE,
      keyName: "service"
   },
   {
      labelLocaleId: TABLE_COLUMNS.INSPECTOR,
      keyName: "inspectorName",
      keyNameForValue: "inspectorId"
   },
   {
      labelLocaleId: TABLE_COLUMNS.DUX_INSPECTION,
      keyName: "DUXInspection"
   },
   {
      labelLocaleId: TABLE_COLUMNS.INSPECTION_TYPE,
      keyName: "inspectionType"
   },
   {
      labelLocaleId: TABLE_COLUMNS.STATE_INSPECTION,
      keyName: "stateInspection"
   },
   {
      labelLocaleId: TABLE_COLUMNS.RECEIVED_START_DATE,
      keyName: "receivedStartDate",
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.RECEIVED_END_DATE,
      keyName: "receivedEndDate",
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.START_DATE,
      keyName: "startDate",
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.END_DATE,
      keyName: "endDate",
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.PASS_FAIL,
      keyName: "passFail"
   },
   {
      labelLocaleId: TABLE_COLUMNS.STATUS,
      keyName: 'workOrderStatus'
   }
]

export const exceptionDashboardGridFilterConfig = [
   {
      labelLocaleId: TABLE_COLUMNS.ORGANISATION,
      keyName: 'organizationName',
      keyNameForValue: 'organizationId'
   },
   {
      labelLocaleId: TABLE_COLUMNS.SITE_NAME,
      keyName: 'siteName',
      keyNameForValue: 'siteId'
   },
   {
      labelLocaleId: TABLE_COLUMNS.UNIT_NAME,
      kaeyName: 'unitName',
      keyNameForValue: 'unitId'
   },
   {
      labelLocaleId: TABLE_COLUMNS.TASK,
      keyName: 'taskName',
      keyNameForValue: 'taskId'
   },
   {
      labelLocaleId: TABLE_COLUMNS.MARKET,
      keyName: 'marketName',
      keyNameForValue: 'marketId'
   },
   {
      labelLocaleId: TABLE_COLUMNS.REASON_START_DATE,
      keyName: 'reasonStartDate',
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.REASON_END_DATE,
      keyName: 'reasonEndDate',
      isDate: true
   },
   {
      labelLocaleId: TABLE_COLUMNS.ASSIGNEE,
      keyName: 'assigneeName'
   },
   {
      labelLocaleId: TABLE_COLUMNS.REASON,
      keyName: 'reasonName',
      keyNameForValue: 'reason'
   }
]

export const REPORT_BOOL_TYPE = {
   YES: "1",
   NO: "0"
}

export const DASHBOARD_ROW_ACTION_TYPES = {
   ASSIGN_PLAN_REVIEW: "assign",
   REASSIGN_PLAN_REVIEW: "reassign",
   OPEN_UNIT: "open_unit",
   OPEN_PLAN_REVIEW: "open_plan_review",
   OPEN_ROUGH: "open_rough",
   OPEN_COMMENTS: "open_comments",
   ASSIGN_FILE_PROCESS: "assign",
   REASSIGN_FILE_PROCESS: "reassign",
   OPEN_FILE_PROCESS: "open_file_process",
   OPEN_FINAL: "open_final",
   TOGGLE_PRIORITY: 'toggle_priority',
   TOGGLE_PLAN_REQUESTED: 'toggle_plan_requested',
   AUDIT_WITH_CAUSE: 'audit_with_cause',
   AUDIT_RANDOM: 'audit_random'
}

export const DASHBOARD_ENTITY = {
   QUALITY_BUILT: 2
}

export const getRowActionsConfig = ({ data, dashboardType, entityId }) => {
   const rowActions = [
       {
           id: DASHBOARD_ROW_ACTION_TYPES.OPEN_UNIT,
           labelLocaleId: ROW_ACTIONS.OPEN_UNIT
       }
      //  {
      //      id: DASHBOARD_ROW_ACTION_TYPES.OPEN_COMMENTS,
      //      labelLocaleId: ROW_ACTIONS.OPEN_COMMENTS
      //  }
   ]

   const updatedRowActions = []

   switch (dashboardType) {
       case DASHBOARD_PAGES_TYPES.PLAN_REVIEW_LEAD:
           if (data.taskURL) {
               if (entityId === DASHBOARD_ENTITY.QUALITY_BUILT) {
                   updatedRowActions.push(
                       {
                           id: DASHBOARD_ROW_ACTION_TYPES.OPEN_PLAN_REVIEW,
                           labelLocaleId: ROW_ACTIONS.OPEN_PLAN_REVIEW
                       },
                       {
                           id: DASHBOARD_ROW_ACTION_TYPES.TOGGLE_PRIORITY,
                           labelLocaleId: ROW_ACTIONS.TOGGLE_PRIORITY
                       },
                       {
                           id: DASHBOARD_ROW_ACTION_TYPES.TOGGLE_PLAN_REQUESTED,
                           labelLocaleId: ROW_ACTIONS.TOGGLE_PLAN_REQUESTED
                       },
                       data.assignee
                           ? {
                                 id: DASHBOARD_ROW_ACTION_TYPES.REASSIGN_PLAN_REVIEW,
                                 labelLocaleId: ROW_ACTIONS.REASSIGN_PLAN_REVIEW
                             }
                           : {
                                 id: DASHBOARD_ROW_ACTION_TYPES.ASSIGN_PLAN_REVIEW,
                                 labelLocaleId: ROW_ACTIONS.ASSIGN_PLAN_REVIEW
                             }
                   )
               } else {
                   updatedRowActions.push({
                       id: DASHBOARD_ROW_ACTION_TYPES.OPEN_PLAN_REVIEW,
                       labelLocaleId: ROW_ACTIONS.OPEN_PLAN_REVIEW
                   })
               }
               updatedRowActions.push(...rowActions)
           }
           break

       case DASHBOARD_PAGES_TYPES.PLAN_REVIEW:
           if (data.taskURL) {
               updatedRowActions.push({
                   id: DASHBOARD_ROW_ACTION_TYPES.OPEN_PLAN_REVIEW,
                   labelLocaleId: ROW_ACTIONS.OPEN_PLAN_REVIEW
               })

               if (entityId === DASHBOARD_ENTITY.QUALITY_BUILT) {
                   updatedRowActions.push({
                       id: DASHBOARD_ROW_ACTION_TYPES.TOGGLE_PLAN_REQUESTED,
                       labelLocaleId: ROW_ACTIONS.TOGGLE_PLAN_REQUESTED
                   })
               }

               updatedRowActions.push(...rowActions)
           }
           break

       case DASHBOARD_PAGES_TYPES.FILE_PROCESS_LEAD:
           if (data.fpTaskURL) {
               if (entityId === DASHBOARD_ENTITY.QUALITY_BUILT) {
                   updatedRowActions.push(
                       {
                           id: DASHBOARD_ROW_ACTION_TYPES.OPEN_FILE_PROCESS,
                           labelLocaleId: ROW_ACTIONS.OPEN_FILE_PROCESS
                       },
                       {
                           id: DASHBOARD_ROW_ACTION_TYPES.TOGGLE_PRIORITY,
                           labelLocaleId: ROW_ACTIONS.TOGGLE_PRIORITY
                       },
                       data.fpAssignee
                           ? {
                                 id: DASHBOARD_ROW_ACTION_TYPES.REASSIGN_FILE_PROCESS,
                                 labelLocaleId: ROW_ACTIONS.REASSIGN_FILE_PROCESS
                             }
                           : {
                                 id: DASHBOARD_ROW_ACTION_TYPES.ASSIGN_FILE_PROCESS,
                                 labelLocaleId: ROW_ACTIONS.ASSIGN_FILE_PROCESS
                             }
                   )
               } else {
                   updatedRowActions.push({
                       id: DASHBOARD_ROW_ACTION_TYPES.OPEN_FILE_PROCESS,
                       labelLocaleId: ROW_ACTIONS.OPEN_FILE_PROCESS
                   })
               }
               updatedRowActions.push(...rowActions)
           }
           break

       case DASHBOARD_PAGES_TYPES.FILE_PROCESS:
           if (data.fpTaskURL) {
               updatedRowActions.push({
                   id: DASHBOARD_ROW_ACTION_TYPES.OPEN_FILE_PROCESS,
                   labelLocaleId: ROW_ACTIONS.OPEN_FILE_PROCESS
               })
               updatedRowActions.push(...rowActions)
           }
           break

       case DASHBOARD_PAGES_TYPES.PROVIDERSHIP:
       case DASHBOARD_PAGES_TYPES.REPORT_PROCESSOR:
           if (entityId === DASHBOARD_ENTITY.QUALITY_BUILT) {
               updatedRowActions.push(
                  {
                   id: DASHBOARD_ROW_ACTION_TYPES.TOGGLE_PRIORITY,
                   labelLocaleId: ROW_ACTIONS.TOGGLE_PRIORITY
                  }
            )
            if (dashboardType === DASHBOARD_PAGES_TYPES.PROVIDERSHIP) {
               if (data.status === PROVIDERSHIP_DASHBOARD_STATUS_TYPES.AUDIT_RANDOM) {
                  updatedRowActions.push({
                     id: DASHBOARD_ROW_ACTION_TYPES.AUDIT_RANDOM,
                     labelLocaleId: ROW_ACTIONS.REMOVE_AUDIT_RANDOM
                  })
               } else if (data.status === PROVIDERSHIP_DASHBOARD_STATUS_TYPES.AUDIT_WITH_CAUSE) {
                  updatedRowActions.push({
                     id: DASHBOARD_ROW_ACTION_TYPES.AUDIT_WITH_CAUSE,
                     labelLocaleId: ROW_ACTIONS.REMOVE_AUDIT_WITH_CAUSE
                  })
               } else if (![PROVIDERSHIP_DASHBOARD_STATUS_TYPES.AUDIT_WITH_CAUSE, PROVIDERSHIP_DASHBOARD_STATUS_TYPES.AUDIT_RANDOM].includes(data.status)) {
                  const auditActions = [
                     {
                        id: DASHBOARD_ROW_ACTION_TYPES.AUDIT_RANDOM,
                        labelLocaleId: ROW_ACTIONS.AUDIT_RANDOM
                     },
                     {
                        id: DASHBOARD_ROW_ACTION_TYPES.AUDIT_WITH_CAUSE,
                        labelLocaleId: ROW_ACTIONS.AUDIT_WITH_CAUSE
                     }
                  ]
                  updatedRowActions.push(...auditActions)
               }
            }
           }
           break

       default:
           break
   }

   return {
       actionTypes: [],
       rowActionItems: updatedRowActions
   }
}