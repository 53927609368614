import React, { useCallback, useContext, useMemo, useState } from 'react'

import { TableView } from "@views/components"
import localeMessageWrapper from '@views/components/locale-message'
import { EXPANDABLE_TYPE } from "@views/components/table-view/config"
import { getColumnConfig } from "./TableConfig"
import { BlueprintContext } from "./BlueprintContext"

const BluePrintList = ({ 
  intl, 
  list, 
  organizationId, 
  organizationPlanId,
  energyModelCount = 0, //Number of Energy model column value in Plan list 
  onSetUpdatedSpecificPlanRowValues = () => {} 
}) => {
  const [gridTransactionData, setGridTransactionData] = useState(null)

  const handleUpdateRowData = useCallback((value) => {
    onSetUpdatedSpecificPlanRowValues({ //To update "Number of Energy model" column value in Plan list 
      organizationPlanId,
      updatedValue: {
        energyModelCount: energyModelCount + 1
      }
    })
    setGridTransactionData(value) //To update blueprint list row
  }, [energyModelCount, organizationPlanId, onSetUpdatedSpecificPlanRowValues])

  const columns = useMemo(() => {
    return getColumnConfig({
      intl
    })
  }, [])

  const tableComponentProps = useMemo(() => {
    return {
      isStaticList: true,
      staticListHeight: 200,
      childComponentProps: {
        organizationId,
        organizationPlanId
      },
      gridTransactionData,
      onResetGridTransactionData: setGridTransactionData
    }
  }, [list, gridTransactionData, organizationId, organizationPlanId])

  const contextValue = useMemo(() => {
    return {
      organizationId,
      organizationPlanId,
      onUpdateRowData: handleUpdateRowData
    }
  }, [organizationId, organizationPlanId, handleUpdateRowData])

  return (
    <BlueprintContext.Provider value={contextValue}>
      {!!list.length && <TableView
        selectableRows={false}
        columns={columns}
        keyField={"organizationBluePrintId"}
        className="nt-rdt-list"
        isNewListTheme
        showAgGrid
        data={list}
        pagination={false}
        tableComponentProps={tableComponentProps}
        tableViewAdvanceSearchRequired={false}
        tableViewHeaderRequired={false}
        tableHeaderRequired={false}
        isSearchFilterRequired={false}
        expandableRows
        expandableRowsType={EXPANDABLE_TYPE.ORGANISATION_PLAN_BLUEPRINT_LIST}
      />}
    </BlueprintContext.Provider>
  )
}

export default localeMessageWrapper(BluePrintList)