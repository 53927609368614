export const getCampaignIdFromUrl = (match, campaignId) => {
  const { pathname } = window.location

  if (pathname.includes('campaigns/overview')) {
    return match?.params?.cid || 0
  }

  if (pathname.includes('campaigns/edit-campaign')) {
    return match?.params?.id || 0
  }

  if (pathname.includes('campaigns/add-new-campaign') || pathname.includes('programs/overview')) {
    return campaignId || 0
  }
  return 0
}

export const getFormattedAssignments = (type, list) => {
  return list.map((item) => ({
    contactId: item.contactId,
    name: type === 2 ? item.contactId === -1 ? "-- All --" : item.contactName : item.sitePartyRoleId === -1 ? "-- All --" : item.sitePartyRoleName,
    organizationId: item.organizationId,
    organizationName: item.organizationName,
    sitePartyRoleId: item.sitePartyRoleId,
    siteRoleId: item.siteRoleId
  }))
}
