import React, { useCallback } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { SideModalCloseButton } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const { SIGNATURE_PAD } = LANGUAGE_CONSTANTS

const SignaturePad = ({
  intl,
  open,
  signatureCanvasRef,
  onConfirm = () => { },
  onToggleModal = () => { }
}) => {
  const handleClearSignature = useCallback(() => {
    signatureCanvasRef.current.clear()
  }, [])

  const handleSaveSignature = useCallback(() => {
    const signatureImage = signatureCanvasRef.current.toDataURL()
    onConfirm(signatureImage)
  }, [onConfirm])

  return (
    <Modal
      isOpen={open}
      className="modal-dialog-centered signature-pad"
    >
      <ModalHeader
        className="px-0"
        toggle={onToggleModal}
        close={<SideModalCloseButton onClose={onToggleModal} />}
        tag="div"
      >
        <h5 className='modal-title'>{getLocaleMessage(intl, SIGNATURE_PAD.SIGNATURE_TITLE)}</h5>
      </ModalHeader>
      <ModalBody className="d-flex p-0 sigContainer">
        <SignatureCanvas
          ref={signatureCanvasRef}
          canvasProps={{ className: 'sigPad' }}
        />
      </ModalBody>
      <ModalFooter className="justify-content-center border-0 p-0 mt-1">
        <Button
          outline
          className="delete-btn cancel mr-1"
          onClick={handleClearSignature}
        >
          {getLocaleMessage(intl, SIGNATURE_PAD.CLEAR)}
        </Button>
        <Button outline className="primary-outlined" onClick={handleSaveSignature}>{getLocaleMessage(intl, SIGNATURE_PAD.SAVE)}</Button>
      </ModalFooter>
    </Modal>
  )
}

export default localeMessageWrapper(SignaturePad)
