import { put, takeLatest, call } from "redux-saga/effects"

import * as services from "@services/api"
import * as actions from "@store/actions"

/** getAutomateReportsRequest API */
function* getAutomateReportsRequest(action) {
  try {
    const response = yield call(services.getAutomateReports, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getAutomateReportVM API */
function* getAutomateReportVM(action) {
  try {
    const response = yield call(services.getAutomateReportVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateAutomateReportRequest API */
function* updateAutomateReportRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateAutomateReport, action.payload)
    if (response?.status === 200) {
      action.callback(true)
    } else {
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** createAutomateReportRequest API */
function* createAutomateReportRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.createAutomateReport, action.payload)
    if (response?.status === 200) {
      action.callback(true)
    } else {
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

export default function* automateReportSagas() {
  yield takeLatest(actions.GET_AUTOMATE_REPORTS_REQUEST, getAutomateReportsRequest)
  yield takeLatest(actions.GET_AUTOMATE_REPORT_VM_REQUEST, getAutomateReportVM)
  yield takeLatest(actions.UPDATE_AUTOMATE_REPORT_REQUEST, updateAutomateReportRequest)
  yield takeLatest(actions.CREATE_AUTOMATE_REPORT_REQUEST, createAutomateReportRequest)

}
