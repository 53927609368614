import { CLONE_TYPE } from '@shared/constants'
import PropTypes from 'prop-types'
import TemplateSelectOption from './TemplateSelectOption'
import CheckpointSelectOption from './CheckpointSelectOption'

const CustomSelectOption = ({ type, data, labelType, valueType }) => {
  switch (type) {
    case CLONE_TYPE.TEMPLATE:
      return <TemplateSelectOption data={data} label={data.templateName} value={data.templateId} />
    case CLONE_TYPE.CHECKPOINT:
      return <CheckpointSelectOption data={data} label={data[labelType]} value={data[valueType]} />
    default:
      return null
  }
}

CustomSelectOption.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object
}

export default CustomSelectOption
