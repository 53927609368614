import config from "./APIConfig"
import { getDataApi } from "../index"

export const getCreateProgramVM = async () => {
  const { url } = config.getCreateProgramVM
  return await getDataApi(url)
}

export const getDetailsRequirementsVM = async (programTypeId) => {
  const { url } = config.getDetailsRequirementsVM
  return await getDataApi(url(programTypeId))
}

export const getCampaignObservation = async ({ProgramId}) => {
  const { url } = config.getCampaignObservation
  return await getDataApi(url(ProgramId))
}

export const getCampaignObservationVM = async ({ProgramId}) => {
  const { url } = config.getCampaignObservationVM
  return await getDataApi(url(ProgramId))
}

export const getOshaDocumentationCampaignVM = async (programId) => {
  const { url } = config.getOshaDocumentationCampaignVM
  return await getDataApi(url(programId))
}

export const getOshaTrainingCoursesList = async (programId) => {
  const { url } = config.getOshaTrainingCoursesList
  return await getDataApi(url(programId))
}

export const getOshaWrittenPrograms = async (data) => {
  const { url } = config.getOshaWrittenPrograms
  return await getDataApi(url(data))
}

export const getProgramBasicDetails = async (data) => {
  const { url } = config.getProgramBasicDetails
  return await getDataApi(url(data))
}

export const getProgramDDL = async (data) => {
  const { url } = config.getProgramDDL
  return await getDataApi(url(data))
}

export const getProgramDetailList = async (data) => {
  const { url } = config.getProgramDetailList
  return await getDataApi(url(data))
}

export const getProgramDetails = async (data) => {
  const { url } = config.getProgramDetails
  return await getDataApi(url(data))
}

export const getProgramList = async (data) => {
  const { url } = config.getProgramList
  return await getDataApi(url(data))
}

export const getProgramListVM = async () => {
  const { url } = config.getProgramListVM
  return await getDataApi(url)
}

export const getProgramSafetyRequirements = async (data) => {
  const { url } = config.getProgramSafetyRequirements
  return await getDataApi(url(data))
}

export const getProjectContacts = async (data) => {
  const { url } = config.getProjectContacts
  return await getDataApi(url(data))
}
