import config from "./APIConfig"
import { deleteDataApi } from '../index'

export const deleteActivityFromTemplateActivityGroup = (data) => {
  const { url } = config.deleteActivityFromTemplateActivityGroup
  return deleteDataApi(url(data), null, data)
}

export const deleteApplicableRegulationData = (data) => {
  const { url } = config.deleteApplicableRegulationData
  return deleteDataApi(url(data))
}

export const deleteCheckpoint = (data) => {
  const { url } = config.deleteCheckpoint
  return deleteDataApi(url(data))
}

export const deleteIndustryInterpretationsData = (data) => {
  const { url } = config.deleteIndustryInterpretationsData
  return deleteDataApi(url(data))
}

export const deleteReferenceStandardData = (data) => {
  const { url } = config.deleteReferenceStandardData
  return deleteDataApi(url(data))
}

export const deleteTemplate = (data) => {
  const { url } = config.deleteTemplate
  return deleteDataApi(url(data))
}

export const deleteTemplateActivityGroup = (data) => {
  const { url } = config.deleteTemplateActivityGroup
  return deleteDataApi(url(data), null, data)
}
