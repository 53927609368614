import { getActionType } from '../action-type-generator'

export const GET_PROJECT_SITE_PLAN_LIST = getActionType('GET_PROJECT_SITE_PLAN_LIST')
export const RESET_PROJECT_SITE_PLAN_LIST = 'RESET_PROJECT_SITE_PLAN_LIST'
export const ADD_PROJECT_SITE_PLAN = getActionType('ADD_PROJECT_SITE_PLAN')
export const GET_PROJECT_SITE_PLAN = getActionType('GET_PROJECT_SITE_PLAN')
export const UPDATE_PROJECT_SITE_PLAN = getActionType('UPDATE_PROJECT_SITE_PLAN')
export const SET_EDIT_SITE_PLAN_MODE = 'SET_EDIT_SITE_PLAN_MODE'
export const DELETE_PROJECT_SITE_PLAN = getActionType('DELETE_PROJECT_SITE_PLAN')
export const GET_SITE_PLAN_SCHEDULE_LIST_REQUEST = "GET_SITE_PLAN_SCHEDULE_LIST_REQUEST"
export const DELETE_SITE_PLAN_SCHEDULE_REQUEST = "DELETE_SITE_PLAN_SCHEDULE_REQUEST"
export const ADD_SITE_PLAN_SCHEDULE_REQUEST = "ADD_SITE_PLAN_SCHEDULE_REQUEST"
export const GET_DDL_SITE_PLAN_SCHEDULE_PREREQS_REQUEST = "GET_DDL_SITE_PLAN_SCHEDULE_PREREQS_REQUEST"

/****************************************************/
/** GET_PROJECT_SITE_PLAN_LIST **/
/****************************************************/

/**
 * @method getProjectSitePlanListRequest :  To get project site plan list
 *
 */
export const getProjectSitePlanListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROJECT_SITE_PLAN_LIST.REQUEST,
    payload,
    callback
  }
}

/**
 * @method getProjectSitePlanListSuccess : To set project site plan list on success
 *
 */
export const getProjectSitePlanListSuccess = (data) => {
  return {
    type: GET_PROJECT_SITE_PLAN_LIST.SUCCESS,
    data
  }
}

/**
 * @method getProjectSitePlanListFailure : To set error on getting project site plan list
 *
 */
export const getProjectSitePlanListFailure = (error) => {
  return {
    type: GET_PROJECT_SITE_PLAN_LIST.FAILURE,
    error
  }
}

/****************************************************/
/** RESET_PROJECT_SITE_PLAN_LIST **/
/****************************************************/

/**
 * @method resetProjectSitePlanListRequest :  To reset project site plan list
 *
 */
export const resetProjectSitePlanListRequest = () => {
  return {
    type: RESET_PROJECT_SITE_PLAN_LIST
  }
}

/****************************************************/
/** GET_PROJECT_SITE_PLAN **/
/****************************************************/

/**
 * @method getProjectSitePlanRequest :  To get site plan detail of Project
 *
 */
export const getProjectSitePlanRequest = (payload, callback) => {
  return {
    type: GET_PROJECT_SITE_PLAN.REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_PROJECT_SITE_PLAN **/
/****************************************************/

/**
 * @method addProjectSitePlanRequest :  To add site plan detail of Project
 *
 */
export const addProjectSitePlanRequest = (payload, callback) => {
  return {
    type: ADD_PROJECT_SITE_PLAN.REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PROJECT_SITE_PLAN **/
/****************************************************/

/**
 * @method updateProjectSitePlanRequest :  To update site plan detail of Project
 *
 */
export const updateProjectSitePlanRequest = (payload, callback) => {
  return {
    type: UPDATE_PROJECT_SITE_PLAN.REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SET_EDIT_SITE_PLAN_MODE **/
/****************************************************/

/**
 * @method setEditSitePlanMode : To set edit mode for Site Plan
 *
 */
export const setEditSitePlanMode = (value) => {
  return {
    type: SET_EDIT_SITE_PLAN_MODE,
    payload: value
  }
}

/****************************************************/
/** DELETE_PROJECT_SITE_PLAN **/
/****************************************************/

/**
 * @method deleteProjectSitePlanRequest : To delete Project Site Plan
 *
 */
export const deleteProjectSitePlanRequest = (payload, callback) => {
  return {
    type: DELETE_PROJECT_SITE_PLAN.REQUEST,
    payload,
    callback
  }
}

/**
 * @method deleteProjectSitePlanSuccess : To handle success of delete Project Site Plan
 *
 */
export const deleteProjectSitePlanSuccess = (data) => {
  return {
    type: DELETE_PROJECT_SITE_PLAN.SUCCESS,
    data
  }
}

/**
* @method deleteProjectSitePlanFailure :  To handle failure of delete Project Site Plan
 *
 */
export const deleteProjectSitePlanFailure = (error) => {
  return {
    type: DELETE_PROJECT_SITE_PLAN.FAILURE,
    error
  }
}

/****************************************************/
/** GET_SITE_PLAN_SCHEDULE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getSitePlanScheduleListRequest : To get schedule list for site plans
 *
 */
export const getSitePlanScheduleListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_SITE_PLAN_SCHEDULE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_SITE_PLAN_SCHEDULE_REQUEST **/
/****************************************************/

/**
 * @method deleteProjectSitePlanScheduleRequest : To delete site plan schedule
 *
 */
export const deleteProjectSitePlanScheduleRequest = (payload, callback = () => { }) => {
  return {
    type: DELETE_SITE_PLAN_SCHEDULE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_SITE_PLAN_SCHEDULE_REQUEST **/
/****************************************************/

/**
 * @method addProjectSitePlanScheduleRequest : To add new line item for site plan schedule
 *
 */
export const addProjectSitePlanScheduleRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_SITE_PLAN_SCHEDULE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_DDL_SITE_PLAN_SCHEDULE_PREREQS_REQUEST **/
/****************************************************/

/**
 * @method getDDLSitePlanSchedulePrereqsRequest : To get ddl prereqs for each row for site plan schedule
 *
 */
export const getDDLSitePlanSchedulePrereqsRequest = (payload, callback = () => { }) => {
  return {
    type: GET_DDL_SITE_PLAN_SCHEDULE_PREREQS_REQUEST,
    payload,
    callback
  }
}
