export const checkIfSearchFiltersApplied = ({
    serviceName,
    siteName,
    unitName,
    checklistName,
    taskTypeId,
    taskStatusId,
    assignedTo
}) => {
    return (
        !!serviceName ||
        !!siteName ||
        !!unitName ||
        !!checklistName ||
        !!taskTypeId ||
        !!taskStatusId ||
        !!assignedTo
    )
}
