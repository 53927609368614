import React, { useCallback, useMemo } from "react"
import moment from "moment"
import ReactTooltip from "react-tooltip"

import { filterClose } from "@src/assets/images/new-theme"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"

const FilterItem = ({
  intl,
  value,
  labelLocaleId,
  keyName,
  keyNameForValue,
  isArray,
  isDate,
  getFormattedValue = (val) => val || value,
  onRemoveFilter = () => { }
}) => {
  const formattedValue = useMemo(() => getFormattedValue(value), [value])

  const handleRemoveFilter = useCallback(() => {
    onRemoveFilter({
      key: keyName,
      keyNameForValue,
      value: formattedValue,
      isArray,
      getFormattedValue
    })
  }, [
    isArray,
    keyName,
    keyNameForValue,
    formattedValue,
    onRemoveFilter,
    getFormattedValue
  ])

  const filterConfig = useMemo(() => {
    const val = isDate
      ? moment(formattedValue).format("MMM DD, yyyy").toString()
      : String(formattedValue.text || formattedValue)

    if (!!val?.length && val.length > 20) {
      return {
        tooltipText: val,
        labelText: val.slice(0, 17).concat("...")
      }
    } else {
      return {
        labelText: val
      }
    }
  }, [formattedValue, isDate])

  return (
    <div className="filter">
      <p>
        <span className="name">{getLocaleMessage(intl, labelLocaleId)}</span> :{" "}
        {filterConfig.tooltipText ? (
          <span
            className="value"
            data-tip="tooltip"
            data-for={`${keyName}_${filterConfig.tooltipText}`}
          >
            {filterConfig.labelText}
          </span>
        ) : (
          <span className="value">{filterConfig.labelText}</span>
        )}
      </p>
      {!!filterConfig.tooltipText && (
        <ReactTooltip id={`${keyName}_${filterConfig.tooltipText}`}>
          {filterConfig.tooltipText}
        </ReactTooltip>
      )}
      <img
        className="cursor-pointer"
        src={filterClose}
        alt="filterClose"
        onClick={handleRemoveFilter}
      />
    </div>
  )
}

export default localeMessageWrapper(FilterItem)
