export const GET_CHECKLIST_CHILDREN_LIST_REQUEST = 'GET_CHECKLIST_CHILDREN_LIST_REQUEST'

/****************************************************/
/** GET_CHECKLIST_CHILDREN_LIST_REQUEST **/
/****************************************************/

/**
 * @method getChecklistChildrenListRequest :  To get checklist's children activities list for assign activities block
 *
 */
export const getChecklistChildrenListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CHECKLIST_CHILDREN_LIST_REQUEST,
    payload,
    callback
  }
}
