import config from "./APIConfig"
import { putDataApi } from '../index'

export const updateProjectDetails = async (data) => {
  const { url } = config.updateProjectDetails

  const payload = {
    projectId: data.projectId || 0,
    projectName: data.projectName || '',
    projectTypeId: data.projectType || "",
    organizationId: data.projectOrganization || '',
    constructionStartDate: data.constructionStartDate || new Date(),
    projectStatusId: data.projectStatus || ''
  }
  if (data.authorityType) {
    payload.authorityTypeId = data.authorityType
  }
  if (data.authorityName) {
    payload.authorityNameValue = data.authorityName
  }
  return await putDataApi(url({projectId: data.projectId, organizationId: data.projectOrganization}), payload)
}

export default updateProjectDetails