export const REFRESH_TABLE_DATA = 'REFRESH_TABLE_DATA'

/**
 * @method refreshTableList : To refresh table list
 *
 */
export const refreshTableList = (payload) => {
  return {
    type: REFRESH_TABLE_DATA,
    payload
  }
}
