import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addProjectSitePlan
 * @param {object} data 
 *  {
      "siteId": 0,
      "sitePlans": [
        {
          "planName": "string",
          "planNumber": "string",
          "version": "string",
          "elevation": "string",
          "sitePlanNumberOfStoryId": 0,
          "numberOfBedroom": "string",
          "sitePlanNumberOfBathroomId": 0,
          "squareFootage": "string",
          "planDescription": "string"
          "ekoTrope": boolean,
          "numOfHVACSystems": number,
          "customJSONData": "string"
        }
      ]
    }
 *
 */
export const addProjectSitePlan = (data) => {
  const { url } = config.addProjectSitePlan

  const payload = {
    siteId: data.siteId,
    sitePlans: [
      {
        planName: data.planName,
        planNumber: data.planNumber,
        version: data.version,
        elevation: data.elevation,
        squareFootage: data.squareFootage,
        numberOfBedroom: data.numberOfBedroom,
        sitePlanNumberOfStoryId: Number(data.stories || ""),
        sitePlanNumberOfBathroomId: Number(data.bathrooms || ""),
        planDescription: data.planDescription,
        ekoTrope: data.ekoTrope,
        numberOfHVACSystem: Number(data.numOfHVACSystems),
        customJSONData: data.customJSONData?.trim()
      }
    ]
  }

  return postDataApi(url, payload)
}

export default addProjectSitePlan
