import config from "./APIConfig"
import { postDataApi } from "../index"

/**
 * @method addCustomDataRetention
 * @param {object} data 
 *{
  {
  "dataRetentionPolicyName": "string",
  "dataRetentionRemovalMethodId": 0,
  "dataRetentionRequiredById": 0,
  "retentionPeriod": 0
}
 *
 */
export const addCustomDataRetention = (data) => {
  const { url } = config.addCustomDataRetention
  return postDataApi(url, data)
}

export default addCustomDataRetention