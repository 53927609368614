import { getSearchQuery } from "../config"

const apiConfig = {
    getContactGroupList: {
        method: 'GET',
        url: ({
            contactGroupName,
            pageNumber,
            pageSize
        }) => {
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("ContactGroupName", contactGroupName, false, true)
            ].join('')
            return `api/Organization/ContactGroup${searchString}`
        }
    },
    createContactGroup: {
        method: "POST",
        url: 'api/Organization/ContactGroup'
    },
    getGroupContactList: {
        method: "GET",
        url: ({
            contactGroupId,
            name,
            pageNumber,
            pageSize,
            orderColumn,
            orderDirections = ""
        }) => {
            const searchString = [
                getSearchQuery("ContactGroupId", contactGroupId, true),
                getSearchQuery("PageNumber", pageNumber),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("Name", name, false, true),
                getSearchQuery("OrderColumn", orderColumn),
                orderDirections ? `&OrderDirections=${orderDirections}` : ""
            ].join('')

            return `api/Organization/ContactGroup/${contactGroupId}${searchString}`
        }
    },
    updateContactGroup: {
        method: "PUT",
        url: ({ contactGroupId }) => `api/Organization/ContactGroup/${contactGroupId}`
    },
    getContactGroupDDL: {
        method: "GET",
        url: ({
            pageNumber,
            pageSize,
            contactGroupName,
            allOption
        }) => {
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("ContactGroupName", contactGroupName, false, true),
                getSearchQuery("AllOption", allOption)
            ].join('')
            return `api/Organization/ContactGroup/ContactGroupDDL${searchString}`
        }
    },
    addContactsToContactGroups: {
        method: "POST",
        url: ({
            contactIds = [],
            contactGroupIds = []
        }) => {
            const contactIdsList = contactIds.map((contact) => getSearchQuery("ContactIds", contact.contactId)).join("")
            const contactGroupIdsList = contactGroupIds.map((contactGroup) => getSearchQuery("ContactGroupIds", contactGroup.value)).join("")
            const searchString = `?${contactIdsList}${contactGroupIdsList}`
            return `api/Organization/ContactGroup/AddContactsToContactGroups${searchString}`
        }
    },
    deleteContactGroup: {
        method: "DELETE",
        url: ({ contactGroupId }) => `api/Organization/ContactGroup/${contactGroupId}`
    },
    deleteContactsByContactGroup: {
        method: "DELETE",
        url: ({ contactGroupId, contactIds = [] }) => {
            const contactIdsList = contactIds.map((contact) => getSearchQuery("ContactIds", contact)).join("")
            const searchString = [
                getSearchQuery("ContactGroupId", contactGroupId, true),
                contactIdsList
            ].join('')

            return `api/Organization/ContactGroup/DeleteContactsByContactGroupId/${contactGroupId}${searchString}`
        }
    },
    updateContactGroupOrderIndex: {
        method: "PUT",
        url: 'api/Organization/ContactGroup/UpdateOrderIndex'
    },
    getAllContactList: {
        method: "GET",
        url: ({
            genericSearch,
            pageNumber,
            pageSize,
            orderColumn,
            orderDirections = ""
        }) => {
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("GenericSearch", genericSearch, false, true),
                getSearchQuery("OrderColumn", orderColumn),
                orderDirections ? `&OrderDirections=${orderDirections}` : ""
            ].join('')
            return `api/Organization/ContactList${searchString}`
        }
    },
    getContactListByGroup: {
        method: "GET",
        url: ({
            contactGroupId,
            contactName,
            pageNumber,
            pageSize
        }) => {
            const searchString = [
                getSearchQuery("ContactGroupId", contactGroupId, true),
                getSearchQuery("ContactName", contactName),
                getSearchQuery("PageNumber", pageNumber),
                getSearchQuery("PageSize", pageSize)
            ].join('')
            return `api/Organization/ContactGroup/${contactGroupId}/ContactList${searchString}`
        }
    }
}

export default apiConfig
