import React from "react"
import { Button } from "reactstrap"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"
import HeaderDropdown from "./HeaderDropdown"

const NoDataComponent = ({
  intl,
  addButtonLocaleId,
  noDataMessageLocaleId,
  isShowAddButton = true,
  isShowDropdownTypeAddButton = false,
  dropdownConfig,
  onClickAdd = () => { }
}) => {
  return (
    <div className="no-data-block">
      <div>
        <p>
          {getLocaleMessage(
            intl,
            noDataMessageLocaleId || LANGUAGE_CONSTANTS.NO_RECORDS_TO_DISPLAY
          )}
        </p>
        {!isShowDropdownTypeAddButton && isShowAddButton && (
          <Button className="common-button primary-solid" onClick={onClickAdd}>
            {getLocaleMessage(intl, addButtonLocaleId || LANGUAGE_CONSTANTS.ADD)}
          </Button>
        )}
        {isShowDropdownTypeAddButton && !!dropdownConfig && <div className="list-header d-flex justify-content-center">
          <HeaderDropdown config={dropdownConfig} />
        </div>}
      </div>
    </div>
  )
}

export default localeMessageWrapper(NoDataComponent)
