import config from "./APIConfig"
import { getDataApi } from '../index'

export const getCustomDataRetentionList = () => {
  const { url } = config.getCustomDataRetentionList
  return getDataApi(url)
}

export const getCustomDataRetentionPolicyDetails = async (data) => {
  const { url } = config.getCustomDataRetentionPolicyDetails
  return await getDataApi(url(data))
}

export const getDataRetentionVM = () => {
  const { url } = config.getDataRetentionVM
  return getDataApi(url)
}
