import { 
  GET_CONTACT_BY_ORGANIZATION,
  RESET_CONTACT_BY_ORGANIZATION,
  SET_EDIT_SITE_MODE
} from '../../actions'

// ** Initial State
const initialState = {
  isEditMode: false,
  siteDetail: null,
  pointOfContacts: {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageNumber: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0
  }
}

const ProjectSiteReducer = (state = initialState, action) => {
  switch (action.type) {
    /** SET_EDIT_SITE_MODE **/

    case SET_EDIT_SITE_MODE:
      return {
         ...state,
        isEditMode: action.payload.isEditMode,
        siteDetail: action.payload.siteDetail
      }

    /** GET_CONTACT_BY_ORGANIZATION **/
    
    case GET_CONTACT_BY_ORGANIZATION.REQUEST:
      return { ...state, error: "" }
    case GET_CONTACT_BY_ORGANIZATION.SUCCESS:
      return {
        ...state,
        pointOfContacts: {
          items: action.data.items,
          pageSize: action.data.pageSize,
          pageNumber: action.data.pageIndex,
          totalCount: action.data.totalCount,
          totalPages: action.data.totalPages,
          hasPreviousPage: action.data.hasPreviousPage,
          hasNextPage: action.data.hasNextPage
        }
      }
    case GET_CONTACT_BY_ORGANIZATION.FAILURE:
      return { 
        ...state,
        error: action.error
       }

    /** RESET_CONTACT_BY_ORGANIZATION **/

    case RESET_CONTACT_BY_ORGANIZATION:
      return {
        ...state,
        pointOfContacts: {
          hasNextPage: false,
          hasPreviousPage: false,
          items: [],
          pageNumber: 1,
          pageSize: 10,
          totalCount: 0,
          totalPages: 0
        }
      }

    default:
      return state
  }
}

export default ProjectSiteReducer
