export const OrganizationTypeList = [
  { value: '', text: 'Select Type' },
  { value: 'builder', text: 'Builder' },
  { value: 'vendor', text: 'Vendor' },
  { value: 'insurance', text: 'Insurance' }
]

export const ParentOrganizationList = [
  { value: 'parent_oragnization_1', text: 'Parent Oragnization 1' },
  { value: 'parent_oragnization_2', text: 'Parent Oragnization 2' },
  { value: 'parent_oragnization_3', text: 'Parent Oragnization 3' }
]

export const OrganizationCategoryList = [
  { value: 'none', text: 'None' },
  { value: 'region', text: 'Region' },
  { value: 'division', text: 'Division' },
  { value: 'headquarters (Coporate)', text: 'Headquarters (Coporate)' },
  { value: 'Add Category', text: 'Add Category' }
]

export const CityList = [
  { value: '', text: 'Choose City' },
  { value: 'city 1', text: 'City 1' },
  { value: 'city 2', text: 'City 2' },
  { value: 'city 3', text: 'City 3' }
]

export const StateList = [
  { value: '', text: 'Choose State' },
  { value: 'state 1', text: 'State 1' },
  { value: 'state 2', text: 'State 2' },
  { value: 'state 3', text: 'State 3' }
]

export const CountryList = [
  { value: '', text: 'Choose Country' },
  { value: 'country 1', text: 'Country 1' },
  { value: 'country 2', text: 'Country 2' },
  { value: 'country 3', text: 'Country 3' }
]

export const AccountAccessList = [
  { value: '', text: 'Account Access' },
  { value: 1, text: 'Admin' },
  { value: 2, text: 'User' }
]

export const AvailabilityList = [
  { value: 1, text: 'Fully Available' },
  { value: 2, text: 'Partially Available' },
  { value: 3, text: 'Not Available' }
]

/**
 * @constant ActivityTypeList
 */
 export const ActivityTypeList = [{ text: "Observation", value: "1" }, { text: "Training", value: "2" }]
