import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getFormattedDate } from "@utils"
import { LocaleMessage } from '@views/components'

const { LOADER, REPORTS: { DATA_ACCESS } } = LANGUAGE_CONSTANTS

const ExpandableTrainingCampaignReportList = ({ expandableRowData = {}, data }) => {

    const selectedGrid = useMemo(() => {
        return {
            organization: data.organizationName || "",
            project: data.projectName || "",
            campaign: data.campaignName || "",
            site: data.siteName || "",
            program: data.programName || "",
            chapter: data.chapter || "",
            course: data.course || "",
            activityName: data.activityName || "",
            subject: data.subject || "",
            dateAssigned: getFormattedDate(data.assignedDate) || ""
        }
    }, [data])

    return (
        <div>
            <div className="site-units">
                {
                    (!expandableRowData.isCampaignView || !expandableRowData.isProjectSiteView || !expandableRowData.isProgramView) && (
                        <>
                            <div className="site-units-details">
                                <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.ORGANIZATION} />: </span>
                                <span>{selectedGrid.organization}</span>
                            </div>
                            <div className="site-units-details">
                                <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.PROJECT} />: </span>
                                <span>{selectedGrid.project}</span>
                            </div>
                            <div className="site-units-details">
                                <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.SITE} />: </span>
                                <span>{selectedGrid.site}</span>
                            </div>
                            <div className="site-units-details">
                                <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.PROGRAM} />: </span>
                                <span>{selectedGrid.program}</span>
                            </div>
                            <div className="site-units-details">
                                <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.CAMPAIGN} />: </span>
                                <span>{selectedGrid.campaign}</span>
                            </div>
                        </>
                    )
                }
                <div className="site-units-details">
                    <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.CHAPTER} />: </span>
                    <span>{selectedGrid.chapter}</span>
                </div>
                <div className="site-units-details">
                    <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.COURSE} />: </span>
                    <span>{selectedGrid.course}</span>
                </div>
                <div className="site-units-details">
                    <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.SUBJECT} />: </span>
                    <span>{selectedGrid.subject}</span>
                </div>
                <div className="site-units-details">
                    <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.ACTIVITY_NAME} />: </span>
                    <span>{selectedGrid.activityName}</span>
                </div>
                <div className="site-units-details">
                    <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.DATE_ASSIGNED} />: </span>
                    <span>{selectedGrid.dateAssigned}</span>
                </div>
            </div>
        </div>
    )
}

ExpandableTrainingCampaignReportList.propTypes = {
    expandedRowId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    isLoadingChildGrid: PropTypes.bool,
    subData: PropTypes.object,
    noDataMessage: PropTypes.string,
    data: PropTypes.object
}

export default ExpandableTrainingCampaignReportList
