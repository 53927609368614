// ** Redux, Thunk & Root Reducer Imports
import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'

// app settings
import AppSettings from '@app-settings'

import rootReducers from './rootReducer'
import rootSagas from './rootSaga'
import { isLogger } from '../config'

const persistConfig = {
  debug: false,
  key: 'root',
  keyPrefix: 'v.1',
  storage,
  whiteList: ['auth']
}

// ** init middleware
const middleware = [thunk, createDebounce()]
const sagaMiddleware = createSagaMiddleware()
const persistedReducer = persistReducer(persistConfig, rootReducers)

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
export const store = isLogger ? createStore(
  persistedReducer,
  compose(
    AppSettings.ENVIRONMENT === 'prod'
      ? applyMiddleware(sagaMiddleware)
      : applyMiddleware(sagaMiddleware, logger)
  )
) : createStore(
  persistedReducer,
  compose(applyMiddleware(sagaMiddleware))
)

export const persistor = persistStore(store)

sagaMiddleware.run(rootSagas)

export default { store, persistor }