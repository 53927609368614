import config from "./APIConfig"
import { putDataApi } from '../index'

export const updateActivityOrderIndex = async (data) => {
  const { url } = config.updateActivityOrderIndex
  const payload = {
    templateCheckpointId: Number(data.templateCheckpointId) || 0,
    prev_node: Number(data.prev_node) || 0,
    next_node: Number(data.next_node) || 0
  }
  return await putDataApi(url, payload)
}

export default updateActivityOrderIndex