import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateServiceChecklist
 * @param {object} data
 *  {
    "campaignServiceCheckListId": 0,
    "checkListName": "string",
    "stageOfConstructionId": 0,
    "assignmentTypeId": 0
 }
 */

export const updateServiceChecklist = (data) => {
  const { url } = config.updateServiceChecklist
  const payload = {
    campaignServiceCheckListId: Number(data.campaignServiceCheckListId),
    checkListName: data.checklistName
  }

  return putDataApi(url(data.campaignServiceCheckListId), payload)
}

export default updateServiceChecklist
