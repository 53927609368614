import { DUE_DATE_REQUIRED_TYPE } from "@views/campaigns/add-new-campaign/workflow/block/training-template/due-date/config"
import config from "./APIConfig"
import { postDataApi } from '../../index'

/**
 * @method createBlockDueDate
 * @param {object} data 
 * 
 * {
 *  wfCampaignSetupId: "",
 *  dueDateRequired: "",
 *  dueDate: ""
 * }
 * 
 * API payload:-
 * 
 * {
 *  "wfCampaignSetupId": 0,
 *  "dueDateRequired": boolean,
 *  "dueDate": ""
 * }
 */

export const createBlockDueDate = (data) => {
  const { url } = config.createBlockDueDate
  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    dueDateRequired: data.dueDateRequired === DUE_DATE_REQUIRED_TYPE.YES,
    dueDate: data.dueDateRequired === DUE_DATE_REQUIRED_TYPE.YES ? data.dueDate : null
  }
  return postDataApi(url, payload, null, { isApiNeedsToHandleErrors: false })
}

export default createBlockDueDate
