import { put, takeLatest, call } from "redux-saga/effects"

import * as actions from "@store/actions"
import { getOshaDocumentationCampaignList, addOshaDocumentationCampaignList, updateOshaDocumentationCampaignList } from "@services/api"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { Notification } from "@views/components"

const { DOCUMENTATION_CAMPAIGN_SAGA } = LANGUAGE_CONSTANTS

/** getOshaDocumentationCampaignList API **/
function* getOshaDocumentationCampaignListRequest(action) {
  try {
    const response = yield call(getOshaDocumentationCampaignList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** addOshaDocumentationCampaignList API **/
function* addOshaDocumentationCampaignListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addOshaDocumentationCampaignList, action.payload)
    if (response.status === 204) {
      Notification({ status: 200, message: DOCUMENTATION_CAMPAIGN_SAGA.ADDING_SUCCESS })
      action.callback(true)
    } else {
      Notification({ status: 400, message: DOCUMENTATION_CAMPAIGN_SAGA.ERROR_IN_ADDING })
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** updateOshaDocumentationCampaignList API **/
function* updateOshaDocumentationCampaignListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateOshaDocumentationCampaignList, action.payload)
    if (response.status === 204) {
      Notification({ status: 200, message: DOCUMENTATION_CAMPAIGN_SAGA.UPDATED_SUCCESS })
      action.callback(true)
    } else {
      Notification({ status: 400, message: DOCUMENTATION_CAMPAIGN_SAGA.ERROR_IN_UPDAING })
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

export function* documentationCampaignSagas() {
  yield takeLatest(actions.GET_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST, getOshaDocumentationCampaignListRequest)
  yield takeLatest(actions.ADD_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST, addOshaDocumentationCampaignListRequest)
  yield takeLatest(actions.UPDATE_OSHA_DOCUMENTATION_CAMPAIGN_LIST_REQUEST, updateOshaDocumentationCampaignListRequest)
}

export default documentationCampaignSagas
