import config from "./APIConfig"
import { deleteDataApi } from '../index'

export const deleteContactGroup = async (data) => {
  const { url } = config.deleteContactGroup
  return await deleteDataApi(url(data))
}

export const deleteContactsByContactGroup = async (data) => {
  const { url } = config.deleteContactsByContactGroup
  return await deleteDataApi(url(data))
}
