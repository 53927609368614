import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"
import {
  ConversationThread,
  CustomUILoader,
  SideModalCloseButton
} from "@views/components"

const CORD_GROUPS = ['internal', 'external']

const CommentsModal = ({
  siteId,
  siteUnitId,
  siteName,
  unitName,
  onToggleModal
}) => {
  const dispatch = useDispatch()
  const [cordToken, setCordToken] = useState(undefined)
  const [isLoading, setLoading] = useState(true)


  useEffect(() => {
    dispatch(actions.getCordClientTokenRequest({
      groups: CORD_GROUPS
    }, (res) => {
      if (res) {
        setCordToken(res.clientToken)
        setLoading(false)
      }
    }))
  }, [])

  return (
    <Modal
      isOpen
      className={"sidebar-sm"}
      modalClassName={"modal-slide-in note-media-modal schedule-time-modal remove-hold-modal"}
      contentClassName="p-0"
    >
      <ModalHeader
        className="mb-2"
        toggle={onToggleModal}
        close={<SideModalCloseButton onClose={onToggleModal} />}
        tag="div"
      >
        <h5 className="modal-title">
          {siteName} - {unitName}
        </h5>
      </ModalHeader>
      <CustomUILoader isLoading={isLoading}>
        <ModalBody className="flex-grow-1">
            <ConversationThread threadId={`${siteId}-${siteUnitId}-Internal`}
              groupId={'internal'} cordToken={cordToken} />
        </ModalBody>
      </CustomUILoader>
    </Modal>
  )
}

export default localeMessageWrapper(CommentsModal)
