import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method saveActivityCollection
 * @param {object} data
 * {
  "assignmentId": 0,
  "taskActivityCollection": [
    {
      "assignmentActivityId": 0,
      "responseAnswerCollections": [
        {
          "responseId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          "responseTypeId": 0,
          "answerText": "string",
          "hiddenFieldText": "string",
          "otherOption": "string",
          "dateSelected": "2023-06-19T05:08:57.675Z",
          "singleNumber": 0,
          "minNumber": 0,
          "maxNumber": 0,
          "optionAnswers": [
            {
              "responseOptionId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
            }
          ],
          "responseImages": [
            {
              "fileName": "string",
              "imageContent": "string",
              "imageId": 0,
              "jsonImageMetaData": "string",
              "isDeleted": true,
              "isExternal": true,
              "externalFileUrl": "string",
              "externalResources": 1
            }
          ]
        }
      ],
      "tradeActivityCorrection": {
        "assignmentActivityFailureId": 0,
        "organizationId": 0,
        "tradeContactId": 0,
        "dueDate": "2023-06-19T05:08:57.675Z",
        "failedActivityNote": {
          "noteId": 0,
          "note": "string",
          "images": [
            {
              "fileName": "string",
              "imageContent": "string",
              "imageId": 0,
              "jsonImageMetaData": "string",
              "isDeleted": true,
              "isExternal": true,
              "externalFileUrl": "string",
              "externalResources": 1
            }
          ]
        }
      },
      "activityNoteMedias": [
        {
          "noteId": 0,
          "note": "string",
          "images": [
            {
              "fileName": "string",
              "imageContent": "string",
              "imageId": 0,
              "jsonImageMetaData": "string",
              "isDeleted": true,
              "isExternal": true,
              "externalFileUrl": "string",
              "externalResources": 1
            }
          ]
        }
      ],
      "tradeActivityCorrections": [
        {
          "assignmentActivityFailureId": 0,
          "organizationId": 0,
          "tradeContactId": 0,
          "dueDate": "2023-06-19T05:08:57.675Z",
          "failedActivityNote": {
            "noteId": 0,
            "note": "string",
            "images": [
              {
                "fileName": "string",
                "imageContent": "string",
                "imageId": 0,
                "jsonImageMetaData": "string",
                "isDeleted": true,
                "isExternal": true,
                "externalFileUrl": "string",
                "externalResources": 1
              }
            ]
          }
        }
      ],
      "isApprovedActivity": true,
      "taskActivityReviewNotes": [
        {
          "noteId": 0,
          "note": "string",
          "images": [
            {
              "fileName": "string",
              "imageContent": "string",
              "imageId": 0,
              "jsonImageMetaData": "string",
              "isDeleted": false,
              "isExternal": false,
              "externalFileUrl": "string",
              "externalResources": 0
            }
          ]
        }
      ]
    }
  ]
}
 * 
 *
 */
export const saveActivityCollection = (data) => {
  const { url } = config.saveActivityCollection
  
  const payload = {
    assignmentId: data.assignmentId,
    taskActivityCollection: data.taskActivityCollection
  }

  return postDataApi(url({ assignmentId: payload.assignmentId }), payload)
}

export default saveActivityCollection
