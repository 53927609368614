import { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import {
  ROUTES,
  TABLE_VIEW_CUSTOM_MESSAGES,
  ACTIVITY_TYPE_NAME,
  ACTIVITY_TYPE,
  LIST_SORT_ORDER_DIRECTION,
  TABLE_LIST_KEYS
} from '@shared/constants'
import * as actions from '@store/actions'
import { TableView } from "@views/components"
import { getInitialListState } from '@views/helpers'
// import { EXPANDABLE_TYPE } from '@views/components/table-view/config'
import EditServiceModal from "./EditServiceModal"
import { getColumns } from './TableConfig'
import { checkIfSearchFiltersApplied } from './helpers'
import AdvanceSearch from '../service-details'

const initialServiceSearchState = {
  campaignServiceName: '',
  serviceId: 0,
  schedulerDriverId: 0,
  timeIncrementTypeId: 0,
  entityId: 0,
  organizationDDLId: 0,
  eventTypeId: 0,
  saturationRate: '',
  siteIds: [],
  selfSchedule: false,
  timeIncrement: 0,
  completionAfterReady: ''
}

const ServiceListTable = ({
  isChildGrid = false,
  activeTab,
  activeSubTab,
  campaignId,
  campaignDetail = {},
  onGoToServiceChecklist = () => { },
  onSetCampaignServiceId = () => { }
}) => {

  const dispatch = useDispatch()
  const history = useHistory()

  const  initialListState  = useMemo(() => {
    return getInitialListState()
  }, [])

  const { detail: editDetail } = useSelector(state => state.campaignService)
  const { isLoading: isPageLoading } = useSelector(state => state.loader)
  const isListRefreshed = useSelector(state => state.tableActions[TABLE_LIST_KEYS.SERVICE_LIST_KEY]?.isListRefreshed || false)

  const [serviceList, setServiceList] = useState(Object.assign({}, initialListState))

  // for advance generic search
  const [genericSearch, setGenericSearch] = useState('')
  const [isOpenAdvanceSearch, setIsOpenAdvanceSearch] = useState(false)
  const [isGenericSearchApplied, setGenericSearchApplied] = useState(false)
  const [isFetching, setFetchingList] = useState(false)
  const [typingTimeout, setTypingTimeout] = useState(null)
  const [isSearchApplied, setSearchApplied] = useState(false)

  const [serviceDetail, setServiceDetail] = useState(initialServiceSearchState)

  const [sortOrderColumn, setSortOrderColumn] = useState('')
  const [sortOrderDirection, setSortOrderDirection] = useState('')

  // const [expandedServiceId, setExpandedServiceId] = useState('')
  // const [childChecklistGrid, setChildChecklistGrid] = useState(null)

  const { list, pageNumber, pageSize, totalCount, totalPages } = serviceList

  const handleChangeSearch = useCallback((data) => {
    setServiceDetail(data)
  }, [])

  const handleFetchServiceList = useCallback(({
    orderColumn,
    orderDirections,
    page = pageNumber,
    size = pageSize,
    search,
    canApplyFilters = true,
    isGSFilter = false,
    advanceSearchDetail = {},
    isAutoAdvanceSearch = false,
    isPageLoaderRequired = false,
    isResetAdvanceSearch = false,
    isResetSortOrder = false
  }) => {
    if (campaignId) {
      if (!isPageLoaderRequired) {
        setFetchingList(true)
      }
      let advanceSearch = {}
      let isSearchFilter = false

      const searchFormData = isAutoAdvanceSearch ? advanceSearchDetail : serviceDetail

      if (!isResetAdvanceSearch) {
        advanceSearch = searchFormData
        isSearchFilter = checkIfSearchFiltersApplied(searchFormData)
      }
      setSearchApplied(isSearchFilter)

      const searchText = isGSFilter
        ? search
          ? search.trim()
          : ""
        : genericSearch || ""
      const searchVal = canApplyFilters ? searchText : ''
      dispatch(actions.getCampaignServiceListRequest({
        campaignId,
        pageNumber: page,
        pageSize: size,
        genericSearch: searchVal,
        advanceSearch: canApplyFilters ? advanceSearch : {},
        orderColumn: isResetSortOrder ? orderColumn : sortOrderColumn,
        orderDirections: isResetSortOrder ? orderDirections : sortOrderDirection
      }, (data) => {
        if (data) {
          const listData = {
            list: data.items,
            pageSize: data.pageSize,
            pageNumber: data.pageIndex,
            totalCount: data.totalCount,
            totalPages: data.totalPages,
            hasPreviousPage: data.hasPreviousPage,
            hasNextPage: data.hasNextPage
          }
          setServiceList(listData)
        }
        setFetchingList(false)
        if (typingTimeout) {
          setTypingTimeout(null)
        }

        setGenericSearchApplied(!!searchVal)
      }))
    }
  }, [
    campaignId,
    genericSearch,
    pageNumber,
    pageSize,
    serviceDetail,
    sortOrderColumn,
    sortOrderDirection,
    typingTimeout
  ])

  const handleResetServiceList = useCallback(() => {
    setServiceList(Object.assign({}, initialListState))
  }, [])

  useEffect(() => {
    handleFetchServiceList({ page: 1 })
  }, [campaignId])

  useEffect(() => {
    if (isListRefreshed) {
      handleFetchServiceList({ canApplyFilters: isGenericSearchApplied || isSearchApplied })
      dispatch(actions.refreshTableList({ listKey: TABLE_LIST_KEYS.SERVICE_LIST_KEY, value: false }))

    }
  }, [isListRefreshed])

  const handleOnRowClicked = useCallback((data) => {
    const path = window.location.pathname
    const serviceId = data?.campaignServiceId || 0
    if (serviceId) {
      if (path.includes(ROUTES.campaignOverview)) {
        history.push(`${path}/service/${serviceId}`)
      } else if (isChildGrid) {
        history.push(`/campaigns/overview/${campaignId}/service/${serviceId}`)
      } else {
        onSetCampaignServiceId(serviceId)
        onGoToServiceChecklist()
      }
    }
  }, [campaignId])

  const handlePerPageRecords = useCallback((event) => {
    handleResetServiceList()
    handleFetchServiceList({
      page: 1,
      size: parseInt(event.target.value)
    })
  }, [handleFetchServiceList, handleResetServiceList])

  const handlePagination = useCallback(
    ({ selected }) => {
      handleFetchServiceList({
        page: selected + 1
      })
    },
    [handleFetchServiceList]
  )

  // ** Function to handle filter
  const handleFilter = useCallback(
    (value) => {
      if (typingTimeout) {
        clearTimeout(typingTimeout)
      }

      setTypingTimeout(
        setTimeout(() => {
          handleResetServiceList()
          handleFetchServiceList({ page: 1, search: value, isGSFilter: true })
        }, 1000)
      )
    },
    [genericSearch, handleFetchServiceList, handleResetServiceList]
  )

  // ** Function to handle change of generic search filter
  const handleChangeGenericSearch = useCallback(
    (e) => {
      const { value } = e.target
      setGenericSearch(value)
      const lengthOfSearch = value.trim().length
      if (
        (!!lengthOfSearch && lengthOfSearch >= 3) ||
        (!lengthOfSearch && !!genericSearch.length && isGenericSearchApplied)
      ) {
        handleFilter(value)
      }
    },
    [genericSearch, isGenericSearchApplied, handleFilter]
  )

  const handleToggleAdvanceSearch = useCallback(() => {
    setIsOpenAdvanceSearch(!isOpenAdvanceSearch)
  }, [isOpenAdvanceSearch])

  const handleAdvanceSearch = useCallback((isAutoAdvanceSearch, data) => {
    const isAutoSearch = isAutoAdvanceSearch && !!data
    if (checkIfSearchFiltersApplied(isAutoSearch ? data : serviceDetail) || isSearchApplied) {
      handleResetServiceList()
      const autoSearchConfig = isAutoSearch ? { isAutoAdvanceSearch, advanceSearchDetail: data } : {}
      handleFetchServiceList({ page: 1, ...autoSearchConfig })
    }
  }, [isSearchApplied, serviceDetail, handleFetchServiceList, handleResetServiceList])

  const handleResetAdvanceSearch = useCallback(() => {
    if (isSearchApplied) {
      handleResetServiceList()
      handleFetchServiceList({ page: 1, isResetAdvanceSearch: true })
    }
  }, [isSearchApplied, handleFetchServiceList, handleResetServiceList])

  const handleReFectchList = useCallback((data) => {
    handleResetServiceList()
    handleFetchServiceList(data)
  }, [handleFetchServiceList, handleResetServiceList])

  const handleSortList = useCallback((column, sortDirection) => {
    if (column.sortKey) {
      setSortOrderColumn(column.sortKey)
      const direction = sortDirection === LIST_SORT_ORDER_DIRECTION.ASC ? "0" : "1"
      setSortOrderDirection(direction)

      handleFetchServiceList({
        isPageLoaderRequired: false,
        orderColumn: column.sortKey,
        orderDirections: direction,
        isResetSortOrder: true
      })
    }
  }, [handleFetchServiceList])

  /**
   * Function to get search results when enter key is pressed or user select some value
   * from dropdown in advance search form
   */
  const handleCustomAdvanceSearch = useCallback(
    (event, isVMBasedSearch, data) => {
      if (event?.keyCode === 13) {
        handleAdvanceSearch()
      } else if (isVMBasedSearch) {
        handleAdvanceSearch(true, data)
      }
    },
    [handleAdvanceSearch]
  )

  // const handleRowExpandToggled = useCallback((expanded, { campaignServiceId }) => {
  //   if (expanded) {
  //     setExpandedServiceId(campaignServiceId)
  //     setChildChecklistGrid((prevGrid) => ({
  //       ...prevGrid,
  //       [`sub_grid_key_${campaignServiceId}`]: campaignServiceId
  //     }))
  //   }

  //   const selectedSubDetail = childChecklistGrid && childChecklistGrid[`sub_grid_key_${campaignServiceId}`]
  //   if (!expanded && !!selectedSubDetail) {
  //     setExpandedServiceId('')
  //     const updatedGrid = Object.assign({}, childChecklistGrid)
  //     delete updatedGrid[`sub_grid_key_${campaignServiceId}`]
  //     setChildChecklistGrid(updatedGrid)
  //   }
  // }, [childChecklistGrid, expandedServiceId])

  const isObservation = useMemo(() => {
    if (campaignDetail.campaignTypeName) {
      return campaignDetail.campaignTypeName !== ACTIVITY_TYPE_NAME.TRAINING
    } else {
      return String(campaignDetail.campaignTypeId) !== String(ACTIVITY_TYPE.TRAINING)
    }
  }, [campaignDetail.campaignTypeId, campaignDetail.campaignTypeName])


  if (!isChildGrid && !window.location.pathname.includes(ROUTES.campaignOverview) && !(activeTab === 2 && activeSubTab === 1)) {
    return null
  }

  const { campaignServiceList: serviceListCustomMessages } = TABLE_VIEW_CUSTOM_MESSAGES

  return <>
    {isOpenAdvanceSearch && <AdvanceSearch
      isOpenAdvanceSearch={isOpenAdvanceSearch}
      isSearchMode
      campaignId={campaignId}
      searchDetail={serviceDetail}
      campaignDetail={campaignDetail}
      onChangeSearch={handleChangeSearch}
      onResetAdvanceSearch={handleResetAdvanceSearch}
      onSearch={handleAdvanceSearch}
      onToggleAdvanceSearch={handleToggleAdvanceSearch}
      onFetchServiceList={handleReFectchList}
      onCustomAdvanceSearch={handleCustomAdvanceSearch}
    />}
    <TableView
      className="activity-list"
      selectableRows={false}
      columns={getColumns(isObservation)}
      keyField={'campaignServiceId'}
      data={list}
      // subData={childChecklistGrid}
      genericSearchRequired
      noDataMessage={
        isPageLoading
          ? serviceListCustomMessages.loading
          : isSearchApplied || isGenericSearchApplied
            ? serviceListCustomMessages.noDataForFilters
            : serviceListCustomMessages.noData
      }
      tableViewHeaderRequired={!isChildGrid}
      pathDetails={{ campaignId }}
      pageNumber={pageNumber}
      pageSize={pageSize}
      sortServer
      genericSearch={genericSearch}
      // expandableRows={isChildGrid}
      // expandedRowId={expandedServiceId}
      // expandableKey="checklistId"
      // expandableRowsType={EXPANDABLE_TYPE.CAMPAIGN_SERVICE_CHECKLIST}
      isLoading={isFetching}
      title={campaignDetail.campaignName}
      totalCount={totalCount}
      totalPages={totalPages}
      onRowClicked={handleOnRowClicked}
      onPerPageRecords={handlePerPageRecords}
      onPagination={handlePagination}
      onChangeSearchFilter={handleChangeGenericSearch}
      // onRowExpandToggled={handleRowExpandToggled}
      onSort={handleSortList}
      onToggleAdvanceSearch={handleToggleAdvanceSearch}
    />
    {!!editDetail && <EditServiceModal isOpen campaignId={campaignId} />}
  </>
}

export default ServiceListTable
