import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addCheckpoint
 * @param {object} data 
 *
 */
export const addCheckpoint = (data) => {
  const { url } = config.addCheckpoint

  return postDataApi(url(), data)
}

export default addCheckpoint
