import config from "./APIConfig"
import { postDataApi } from "../../index"

/**
 * @method addCampaignContractDetail
 * @param {object} data 
 *{
  "productTypeId": 1,
  "campaignId": 0,
  "wfCampaignSetupId": 0,
  "billingProductId": 0,
  "amount": 0
}
 *
 */
export const addCampaignContractDetail = (payload) => {
  const { url } = config.addCampaignContractDetail
  return postDataApi(url(payload), payload)
}

export default addCampaignContractDetail
