import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState, useCallback, useEffect, useMemo } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"
import RoleModal, {
  MODAL_TYPE
} from "@views/projects/add-new-project/sites/site-roles/EditRoleModal"
import { ASSIGNMENT_TYPES } from "./config"
import { getAssignedByKeyList } from "./helpers"
import localeMessageWrapper from "../locale-message"

const { SITE_ROLE_SEARCH } = LANGUAGE_CONSTANTS

const SiteRoleSearch = ({
  intl,
  isMulti,
  isEditMode,
  isUpdateEditModeValue,
  isDisabled = false,
  customActionLabel,
  errors,
  defaultOptions,
  id,
  isRequired,
  isOptionGridDropdown = true,
  isSharedCampaign,
  isShowCustomAction,
  isTableView,
  label,
  labelRequired,
  name,
  placeholder,
  value,
  reportParams,
  isContactSitePartyRole,
  initialLabel,
  valueType,
  labelType,
  siteId,
  wfCampaignSetupId,
  isWorkflowOrganizationRoles,
  organizationId,
  orgDetail,
  onSelect,
  isAssignmentPageSites,
  observationTaskIds,
  getOptionLabel,
  getOptionValue,
  onResetUpdateEditModeValue
}) => {

  const dispatch = useDispatch()

  const [options, setOptions] = useState(defaultOptions)

  /**
   * Note: Paginated select maintain cache of options,
   * so to set new default options we need to reset previous cache
   */
  const [isResetCache, setResetCache] = useState(false)
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")
  const [isOpenAddSiteRoleModal, setOpenAddSiteRoleModal] = useState(false)

  /**
   * @method handleSetDefaultOptions : To handle set default options in case of Edit mode
   *
   * Note: To handle case of duplicate option, when custom option is added, that is selected from edit mode
   */
  const handleSetDefaultOptions = useCallback(
    (selectedItem, selectedValue) => {
      const checkIfAlreadyPresent = defaultOptions.find(
        (option) => option[valueType] === selectedValue
      )
      if (!!checkIfAlreadyPresent) {
        setOptions(defaultOptions)
      } else {
        setOptions([selectedItem, ...defaultOptions])
      }
    },
    [defaultOptions, valueType]
  )

  useEffect(() => {
    setResetCache((prevState) => !prevState)
    setOptions(defaultOptions)
  }, [defaultOptions])

  useEffect(() => {
    if (!!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])


  //To handle edit mode when single select
  useEffect(() => {
    //Note: To get option details for selected value in edit mode 
    if (isEditMode && !!initialLabel && !isEditModeItemLoaded && !!value) {
      setEditModeItemLoaded(true)
      setSelectedOptionLabel(initialLabel)
    }
  }, [initialLabel, isEditMode, isEditModeItemLoaded, value])

  //To handle edit mode when single select
  useEffect(() => {
    //Note: To get option details for selected value in edit mode 
    if (isUpdateEditModeValue && !!initialLabel && !!value) {
      onResetUpdateEditModeValue(false)
      setSelectedOptionLabel(initialLabel)
    }
  }, [initialLabel, isUpdateEditModeValue, value])

  const handleLoadMore = useCallback((search, page, prevOptions) => {
    return new Promise((resolve) => {

      const handleLoadMoreCallback = (response) => {
        if (response) {
          const { items, hasNextPage } = response
          resolve({
            optionList: items,
            hasMore: hasNextPage
          })

          setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
        } else {
          resolve({
            optionList: [],
            hasMore: false
          })
        }
      }

      if (reportParams && !isAssignmentPageSites && !isContactSitePartyRole) {
        const payload = {
          pageSize: DEFAULT_VM_PAGE_SIZE,
          pageNumber: page,
          sitePartyRoleName: search,
          ...reportParams
        }
        dispatch(actions.getUnacceptableActivitiesSitePartyRoleDDLRequest(payload, handleLoadMoreCallback))
      } else if (!reportParams && isAssignmentPageSites && !isContactSitePartyRole && !isSharedCampaign) {
        const payload = {
          pageSize: DEFAULT_VM_PAGE_SIZE,
          pageNumber: page,
          sitePartyRoleName: search,
          observationTaskIds
        }
        dispatch(actions.getAssignmentRolesListRequest(payload, handleLoadMoreCallback))
      } else if ((isContactSitePartyRole && !reportParams && !isAssignmentPageSites) || isSharedCampaign) {
        const serachParams = {
          pageSize: DEFAULT_VM_PAGE_SIZE,
          pageNumber: page,
          search
        }
        dispatch(actions.getSitePartyRoleVMRequest(serachParams, handleLoadMoreCallback))
      } else if (isWorkflowOrganizationRoles && !isSharedCampaign) {
        const searchParams = {
          pageSize: DEFAULT_VM_PAGE_SIZE,
          pageNumber: page,
          sitePartyRoleName: search,
          wfCampaignSetupId
        }
        dispatch(actions.getWorkflowOrganizationRoleListRequest(searchParams, handleLoadMoreCallback))
      } else {
        const serachParams = {
          searchName: search,
          assignmentTypeId: ASSIGNMENT_TYPES.ROLE,
          siteId,
          organizationId,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          pageNumber: page
        }

        dispatch(actions.getSiteRoleAssignmentDDLRequest(serachParams, handleLoadMoreCallback))
      }
    })
  }, [options, isAssignmentPageSites, isContactSitePartyRole, observationTaskIds, isSharedCampaign, siteId, wfCampaignSetupId, isWorkflowOrganizationRoles])

  const handleToggleAddSiteRoleModal = useCallback((e) => {
    setOpenAddSiteRoleModal(prevState => !prevState)
  }, [])

  const handleSelect = useCallback(
    (selected) => {
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        let value = selected
        if (!!lastElement && !lastElement[valueType]) {
          value = []
        }
        onSelect(id, value)
      } else {
        const label = selected?.[valueType] && selected?.[labelType]
        onSelect(id, selected?.[valueType] || "", label || "")
        setSelectedOptionLabel(label || "")
      }
    },
    [id, labelType, valueType, onSelect]
  )

  const handleAutoSelectFromModal = useCallback((selected) => {
    onSelect(id, selected.value || '', selected.label || '')
    setSelectedOptionLabel(selected.label || '')
    setTimeout(() => {
      setResetCache(prevState => !prevState)
      const selectedItem = {
        [labelType]: selected.label,
        [valueType]: selected.value
      }
      handleSetDefaultOptions(selectedItem, selected.value)
    }, 100)
  }, [id, labelType, valueType, handleSetDefaultOptions, onSelect])

  const tablePropsConfig = useMemo(() => {
    return {
      menuPortalTarget: document.body,
      menuPosition: 'fixed',
      styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) }
    }
  }, [])

  return (
    <div className={cx({
      'table-view-list': isTableView
    })}>
      {labelRequired && <CustomLabel title={label || SITE_ROLE_SEARCH.LABEL} required={isRequired} isLocalizedTitle={!label} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        customActionLabel={
          customActionLabel || getLocaleMessage(intl, SITE_ROLE_SEARCH.ADD_ROLE)
        }
        id={id}
        name={name}
        placeholder={
          placeholder || getLocaleMessage(intl, SITE_ROLE_SEARCH.PLACEHOLDER)
        }
        options={options}
        defaultOptions={options}
        isOptionGridDropdown={isOptionGridDropdown}
        keyList={!isAssignmentPageSites && getAssignedByKeyList(intl, labelType)}
        isFormattedValue={!isMulti}
        isMulti={isMulti}
        hideSelectedOptions={false}
        value={!isMulti ? (!!value && !isDisabled)
          ? {
            [labelType]: selectedOptionLabel,
            [valueType]: value
          }
          : null
          : value}
        isShowCustomAction={isShowCustomAction}
        valueType={valueType}
        lableType={labelType}
        onClickCustomAction={handleToggleAddSiteRoleModal}
        onSelect={handleSelect}
        onLoadOptions={handleLoadMore}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isDisabled={isDisabled}
        {...tablePropsConfig}
      />
      {errors && <ErrorMessage isShow={!!errors[id]} message={errors[id]} />}
      {isOpenAddSiteRoleModal && (
        <RoleModal
          modalType={MODAL_TYPE.ADD}
          orgDetail={orgDetail}
          selectedSiteId={siteId}
          onCloseModal={handleToggleAddSiteRoleModal}
          onSelect={handleAutoSelectFromModal}
        />
      )}
    </div>
  )
}

SiteRoleSearch.propTypes = {
  intl: PropTypes.object,
  isMulti: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isUpdateEditModeValue: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errors: PropTypes.object,
  defaultOptions: PropTypes.array,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  isShowCustomAction: PropTypes.bool,
  isTableView: PropTypes.bool,
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  isAssignmentPageSites: PropTypes.bool,
  isContactSitePartyRole: PropTypes.bool,
  isWorkflowOrganizationRoles: PropTypes.bool,
  isSharedCampaign: PropTypes.bool,
  observationTaskIds: PropTypes.array,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  initialLabel: PropTypes.string,
  valueType: PropTypes.string,
  labelType: PropTypes.string,
  wfCampaignSetupId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  siteId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  organizationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  orgDetail: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  onSelect: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  onResetUpdateEditModeValue: PropTypes.func
}

SiteRoleSearch.defaultProps = {
  isEditMode: false,
  isMulti: false,
  isUpdateEditModeValue: false,
  isDisabled: false,
  defaultOptions: [],
  id: "roleId",
  isRequired: false,
  isShowCustomAction: false,
  isSharedCampaign: false,
  isTableView: false,
  labelRequired: true,
  isWorkflowOrganizationRoles: false,
  name: "role",
  value: "",
  initialLabel: "",
  valueType: "sitePartyRoleId",
  labelType: "name",
  isAssignmentPageSites: false,
  isContactSitePartyRole: false,
  observationTaskIds: [],
  orgDetail: { id: '-1' }, //Todo: set id to 0
  onSelect: () => { },
  getOptionLabel: (option) => option.name,
  getOptionValue: (option) => option.sitePartyRoleId,
  onResetUpdateEditModeValue: () => { }
}

export default localeMessageWrapper(SiteRoleSearch)
