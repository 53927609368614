import { put, takeLatest, call } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'
import * as actions from "@store/actions"

const { PROJECT_SITE_UNIT_SAGA, ACTIVITY_SAGA } = LANGUAGE_CONSTANTS

/** addProjectSiteUnit API **/
function* addProjectSiteUnitRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addProjectSiteUnit, action.payload)
    if (response?.data) {
      action.callback(response.data)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_SITE_UNIT_SAGA.ADDED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_SITE_UNIT_SAGA.ERROR_IN_ADDING })
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** updateProjectSiteUnit API **/
function* updateProjectSiteUnitRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateProjectSiteUnit, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_SITE_UNIT_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback(false)
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_SITE_UNIT_SAGA.ERROR_IN_UPDATING })
    }
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** addProjectSiteAutoGenerateUnits API **/
function* addProjectSiteAutoGenerateUnitsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addProjectSiteAutoGenerateUnits, action.payload)
    if (response?.status === 200) {
      action.callback(true)
      Notification({ status: 200, message: PROJECT_SITE_UNIT_SAGA.ADDED_MULTIPLE_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROJECT_SITE_UNIT_SAGA.ERROR_IN_ADDING_MULTIPLE })
    }
    yield put(actions.setIsLoading(false))

  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
    Notification({ status: 400, message: PROJECT_SITE_UNIT_SAGA.ERROR_IN_ADDING_MULTIPLE })
  }
}

/** getAddProjectSiteUnitVM API **/
function* getAddProjectSiteUnitVMRequest(action) {
  try {
    const response = yield call(services.getAddProjectSiteUnitVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getProjectSiteUnitList API **/
function* getProjectSiteUnitListRequest(action) {
  try {
    const { isLoadingRequired = false, ...payload } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(services.getProjectSiteUnitList, payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: payload.pageSize })
    } else {
      action.callback()
    }
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    const { isLoadingRequired = false } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    action.callback()
  }
}

/** deleteProjectSiteUnit API **/
function* deleteProjectSiteUnitRequest(data) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteProjectSiteUnit, data.payload)
    if (!!response?.data?.status) {
      data.callback(true)
      Notification({ status: 200, message: PROJECT_SITE_UNIT_SAGA.DELETED_SUCCESS })
    } else {
      data.callback()
      Notification({ status: 400, message: PROJECT_SITE_UNIT_SAGA.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    data.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getProjectSiteUnit API **/
function* getProjectSiteUnitRequest(action) {
  try {
    const response = yield call(services.getProjectSiteUnit, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** deleteMultipleProjectSiteUnits API **/
function* deleteMultipleProjectSiteUnitsRequest({ payload, callback = () => { } }) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteMultipleProjectSiteUnits, payload)
    if (response?.status === 204) {
      Notification({ status: 200, message: ACTIVITY_SAGA.DELETED_SUCCESS })
      callback(true)
    } else {
      Notification({ status: 400, message: ACTIVITY_SAGA.ERROR_IN_DELETING })
      callback()
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    callback()
  }
}

/** getUnitsListDDL API **/
function* getUnitsListDDLRequest(action) {
  try {
    const response = yield call(services.getUnitsListDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getWorkflowList API **/
function* getWorkflowListRequest(action) {
  try {
    const response = yield call(services.getWorkflowList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** updateUnitWorkflowList API **/
function* updateUnitWorkflowListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateUnitWorkflowList, action.payload)
    if (response) {
      action.callback(true)
    } else {
      action.callback()
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getUnitWorkflowListCount API **/
function* getUnitWorkflowListCountRequest(action) {
  try {
    const response = yield call(services.getUnitWorkflowListCount, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getUnitBlockList API **/
function* getUnitBlockListRequest(action) {
  try {
    const response = yield call(services.getUnitBlockList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getUnitPlanDetails API **/
function* getUnitPlanDetailsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getUnitPlanDetails, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

export function* projectSiteUnitsSagas() {
  yield takeLatest(actions.ADD_PROJECT_SITE_UNIT_REQUEST, addProjectSiteUnitRequest)
  yield takeLatest(actions.UPDATE_PROJECT_SITE_UNIT_REQUEST, updateProjectSiteUnitRequest)
  yield takeLatest(actions.ADD_PROJECT_SITE_AUTO_GENERATE_UNITS_REQUEST, addProjectSiteAutoGenerateUnitsRequest)
  yield takeLatest(actions.GET_ADD_PROJECT_SITE_UNIT_VM_REQUEST, getAddProjectSiteUnitVMRequest)
  yield takeLatest(actions.GET_PROJECT_SITE_UNIT_LIST_REQUEST, getProjectSiteUnitListRequest)
  yield takeLatest(actions.DELETE_PROJECT_SITE_UNIT_REQUEST, deleteProjectSiteUnitRequest)
  yield takeLatest(actions.GET_PROJECT_SITE_UNIT_REQUEST, getProjectSiteUnitRequest)
  yield takeLatest(actions.DELETE_MULTIPLE_PROJECT_SITE_UNITS_REQUEST, deleteMultipleProjectSiteUnitsRequest)
  yield takeLatest(actions.GET_UNITS_LIST_DDL_REQUEST, getUnitsListDDLRequest)
  yield takeLatest(actions.GET_WORKFLOW_LIST_REQUEST, getWorkflowListRequest)
  yield takeLatest(actions.UPDATE_UNIT_WORKFLOW_LIST_REQUEST, updateUnitWorkflowListRequest)
  yield takeLatest(actions.GET_UNIT_WORKFLOW_LIST_COUNT_REQUEST, getUnitWorkflowListCountRequest)
  yield takeLatest(actions.GET_UNIT_BLOCK_LIST_REQUEST, getUnitBlockListRequest)
  yield takeLatest(actions.GET_UNIT_PLAN_DETAILS_REQUEST, getUnitPlanDetailsRequest)
}

export default projectSiteUnitsSagas