import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"
import { Info } from "react-feather"
import PropTypes from 'prop-types'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'

const { DELETE_CONFIRMATION_MODAL, USER_MANAGEMENT_PERMISSION, CLOSE } = LANGUAGE_CONSTANTS

const ConfirmationModal = ({
  open,
  isWarning = false,
  isFooterChildren = false,
  title,
  name,
  children,
  onConfirm = () => { },
  onToggleModal = () => { }
}) => {
  return (
    <Modal
      isOpen={open}
      className="modal-dialog-centered all-modal"
    >
      <ModalBody className="text-center p-0">
        <Info size={80} className="mb-2 info" />
        <h6>
          {title || <LocaleMessage id={DELETE_CONFIRMATION_MODAL.UPPER_TEXT} />}{" "}
          {!!name && (
            <>
              <span className="underline-txt">
                <b>{name}</b>
              </span>
              <span> ?</span>
            </>
          )}
        </h6>
      </ModalBody>
      <ModalFooter className="justify-content-center border-0 p-0">
        { isFooterChildren && children }
        {isWarning ? <Button className="bg-btn" onClick={onToggleModal}>
          <LocaleMessage id={CLOSE} />
        </Button> : <>
          <Button className="bg-btn" onClick={onConfirm}>
            <LocaleMessage id={USER_MANAGEMENT_PERMISSION.YES} />
          </Button>
          <Button
            outline
            className="delete-btn cancel mr-1"
            onClick={onToggleModal}
          >
            <LocaleMessage id={USER_MANAGEMENT_PERMISSION.NO} />
          </Button>
        </>}
      </ModalFooter>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  name: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  onConfirm: PropTypes.func,
  onToggleModal: PropTypes.func
}

ConfirmationModal.defaultProps = {
  name: ""
}

export default ConfirmationModal
