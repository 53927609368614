import config from "./APIConfig"
import { putDataApi } from "../index"

/**
 * @method updateContactGroupOrderIndex
 * @param {object} data 
 * {
  "contactGroupId": 0,
  "prev_Node": 0,
  "next_Node": 0
}
 */
const updateContactGroupOrderIndex = async (data) => {
  const { url } = config.updateContactGroupOrderIndex
  return await putDataApi(url, data)
}

export default updateContactGroupOrderIndex
