import { takeLatest, call } from "redux-saga/effects"

import { getCordClientToken } from "@services/api"
import * as actions from "@store/actions"

/** getCordClientToken API **/
function* getCordClientTokenRequest(action) {
    try {
      const response = yield call(getCordClientToken, action.payload)
      action.callback(response?.data)
    } catch (error) {
      action.callback()
    }
  }

export function* cordSagas() {
    yield takeLatest(actions.GET_CORD_CLIENT_TOKEN_REQUEST, getCordClientTokenRequest)
  }
  
export default cordSagas