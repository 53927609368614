import { LANGUAGE_CONSTANTS } from '@shared/language-constants'

const {
  OVERVIEW_SECTIONS
} = LANGUAGE_CONSTANTS

export const REPORTS_TAB_TYPES = {
  UNACCEPTABLE: 1,
  DAILY_INSPECTION: 2,
  SATURATION_RATE: 3,
  TRAINING: 4,
  TRAINING_CAMPAIGN: 5,
  UNIT_CLOSEOUT: 6,
  TRADE_PERFORMANCE: 7,
  CAMPAIGN_STATUS: 8,
  UNIT_STATUS: 9
}

export const ReportsTab = [
  { text: 'Unapproved Activities', value: REPORTS_TAB_TYPES.UNACCEPTABLE },
  { text: 'Task Summary', value: REPORTS_TAB_TYPES.DAILY_INSPECTION },
  { text: 'Sample Rate', value: REPORTS_TAB_TYPES.SATURATION_RATE },
  // { text: 'Training', value: REPORTS_TAB_TYPES.TRAINING },
  // { text: 'Training Campaign', value: REPORTS_TAB_TYPES.TRAINING_CAMPAIGN }, TODO commented training and training campaign report as per request
  // { text: 'Unit Closeout', value: REPORTS_TAB_TYPES.UNIT_CLOSEOUT }, hide unit closeout report as per request
  { text: 'Trade Performance', value: REPORTS_TAB_TYPES.TRADE_PERFORMANCE }
]

export const CampaignStatusReport = { text: 'Campaign Status', value: REPORTS_TAB_TYPES.CAMPAIGN_STATUS }

export const UnitStatusReportList = { text: 'Unit Status', value: REPORTS_TAB_TYPES.UNIT_STATUS }

export const SECTION_TAB_TYPES = {
  STANDARD_REPORTS: 1,
  CUSTOM_REPORTS: 2
}


export const overviewSectionTabs = [
  { id: SECTION_TAB_TYPES.STANDARD_REPORTS, name: OVERVIEW_SECTIONS.STANDARD_REPORTS },
  { id: SECTION_TAB_TYPES.CUSTOM_REPORTS, name: OVERVIEW_SECTIONS.CUSTOM_REPORTS }
]

export const REPORT_PAGE_TYPE = {
  GENERAL: "General"
}

export const ENTITY_LIST = {
  WOODSIDE_HOMES: 23
}