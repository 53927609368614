import { getActionType } from '../action-type-generator'

export const GET_GLOBAL_SEARCH_LIST = getActionType('GET_GLOBAL_SEARCH_LIST')
export const RESET_GLOBAL_SEARCH_LIST = 'RESET_GLOBAL_SEARCH_LIST'
export const GET_GLOBAL_SEARCH_VM_REQUEST = 'GET_GLOBAL_SEARCH_VM_REQUEST'

/****************************************************/
/** GET_GLOBAL_SEARCH_LIST **/
/****************************************************/

/**
 * @method getGlobalSearchListRequest : To get global search list
 *
 */
export const getGlobalSearchListRequest = (payload, callback) => {
  return {
    type: GET_GLOBAL_SEARCH_LIST.REQUEST,
    payload,
    callback
  }
}

/**
 * @method getGlobalSearchListSuccess : To set global search list on success
 *
 */
export const getGlobalSearchListSuccess = (data) => {
  return {
    type: GET_GLOBAL_SEARCH_LIST.SUCCESS,
    data
  }
}

/**
 * @method getGlobalSearchListFailure : To set error on getting global search
 *
 */
export const getGlobalSearchListFailure = (error) => {
  return {
    type: GET_GLOBAL_SEARCH_LIST.FAILURE,
    error
  }
}

/**
 * @method resetGlobalSearchList : To reset global search list
 *
 */
 export const resetGlobalSearchList = () => {
  return {
    type: RESET_GLOBAL_SEARCH_LIST
  }
}

/****************************************************/
/** GET_GLOBAL_SEARCH_VM_REQUEST **/
/****************************************************/

/**
 * @method getGlobalSearchVM API : To get global search vm
 *
 */
export const getGlobalSearchVMRequest = (callback) => {
  return {
    type: GET_GLOBAL_SEARCH_VM_REQUEST,
    callback
  }
}