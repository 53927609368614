export const GET_POWER_BI_ACCESS_TOKEN_REQUEST = 'GET_POWER_BI_ACCESS_TOKEN_REQUEST'
export const GET_REPORT_LIST_REQUEST = "GET_REPORT_LIST_REQUEST"
export const SET_REPORTS_OPENED_TAB = "SET_REPORTS_OPENED_TAB"
export const GET_UNACCEPTABLE_ACTIVITIES_REPORT = "GET_UNACCEPTABLE_ACTIVITIES_REPORT"
export const GET_UNAPPROVED_TRAINING_REPORT = "GET_UNAPPROVED_TRAINING_REPORT"
export const GET_DAILY_INSPECTION_REPORT = "GET_DAILY_INSPECTION_REPORT"
export const GET_UNACCEPTABLE_ACTIVITIES_VM_REQUEST = "GET_UNACCEPTABLE_ACTIVITIES_VM_REQUEST"
export const GET_REPORT_ASSIGNED_CONTACTS_DDL_REQUEST = 'GET_REPORT_ASSIGNED_CONTACTS_DDL_REQUEST'
export const GET_UNACCEPTABLE_ACTIVITIES_SITE_PARTY_ROLE_DDL_REQUEST = 'GET_UNACCEPTABLE_ACTIVITIES_SITE_PARTY_ROLE_DDL_REQUEST'
export const GET_REPORT_SITE_DDL_REQUEST = "GET_REPORT_SITE_DDL_REQUEST"
export const GET_TRAINING_ACTIVITIES_INCOMPLETE_REQUEST = "GET_TRAINING_ACTIVITIES_INCOMPLETE_REQUEST"
export const GET_OPEN_UNACCEPTABLE_ACTIVITIES_REQUEST = "GET_OPEN_UNACCEPTABLE_ACTIVITIES_REQUEST"
export const GET_SATURATION_RATE_SUMMARY_REPORT_REQUEST = "GET_SATURATION_RATE_SUMMARY_REPORT_REQUEST"
export const GET_SATURATION_RATE_DETAILS_REPORT_REQUEST = "GET_SATURATION_RATE_DETAILS_REPORT_REQUEST"
export const GET_EXPORT_DOCUMENT_DETAILS_REQUEST = "GET_EXPORT_DOCUMENT_DETAILS_REQUEST"
export const GET_TRAINING_ACTIVITIES_FAILED_REQUEST = "GET_TRAINING_ACTIVITIES_FAILED_REQUEST"
export const GET_TRAINING_ACTIVITIES_STATUS_REQUEST = "GET_TRAINING_ACTIVITIES_STATUS_REQUEST"
export const GET_TRAINING_CAMPAIGN_REPORT_REQUEST = "GET_TRAINING_CAMPAIGN_REPORT_REQUEST"
export const GET_COMPLETED_UNITS_WIDGET_REQUEST = "GET_COMPLETED_UNITS_WIDGET_REQUEST"
export const GET_WARRANTY_CLAIM_ESTIMATE_WIDGET = 'GET_WARRANTY_CLAIM_ESTIMATE_WIDGET'
export const GET_WARRANTY_COST_ESTIMATE_WIDGET = 'GET_WARRANTY_COST_ESTIMATE_WIDGET'
export const GET_CONSTRUCTION_CALLBACKS_COSTS_WIDGET = 'GET_CONSTRUCTION_CALLBACKS_COSTS_WIDGET'
export const GET_PRODUCTION_CYCLE_TIME_WIDGET = 'GET_PRODUCTION_CYCLE_TIME_WIDGET'
export const GET_CONSTRUCTION_CALLBACKS_WIDGET = 'GET_CONSTRUCTION_CALLBACKS_WIDGET'
export const GET_REPORT_DETAIL_REQUEST = 'GET_REPORT_DETAIL_REQUEST'
export const GET_WORKFLOW_LIST_DDL_REQUEST = 'GET_WORKFLOW_LIST_DDL_REQUEST'
export const GET_TASK_LIST_DDL_REQUEST = 'GET_TASK_LIST_DDL_REQUEST'
export const GET_UNIT_CLOSEOUT_REQUEST = 'GET_UNIT_CLOSEOUT_REQUEST'
export const GET_TRADE_PERFORMANCE_REPORT_REQUEST = "GET_TRADE_PERFORMANCE_REPORT_REQUEST"
export const GET_NEW_OBSERVATION_CAMPAIGNS_WIDGET_REQUEST = "GET_NEW_OBSERVATION_CAMPAIGNS_WIDGET_REQUEST"
export const GET_UNAPPROVED_ACTIVITIES_WIDGET_REQUEST = "GET_UNAPPROVED_ACTIVITIES_WIDGET_REQUEST"
export const GET_TRAINING_ACTIVITIES_WIDGET_REQUEST = "GET_TRAINING_ACTIVITIES_WIDGET_REQUEST"
export const TRAINING_CAMPAIGN_VM_REQUEST = "TRAINING_CAMPAIGN_VM_REQUEST"
export const GET_ACTIVITY_TRAINING_SUBJECT_REQUEST = "GET_ACTIVITY_TRAINING_SUBJECT_REQUEST"
export const GET_ACTIVITY_TRAINING_CHAPTER_REQUEST = "GET_ACTIVITY_TRAINING_CHAPTER_REQUEST"
export const GET_ACTIVITY_TRAINING_COURSE_REQUEST = "GET_ACTIVITY_TRAINING_COURSE_REQUEST"
export const GET_ACTIVITY_TRAINING_LESSON_REQUEST = "GET_ACTIVITY_TRAINING_LESSON_REQUEST"
export const GET_ENERGY_STAR_UNITS_OPEN_WIDGET_REQUEST = "GET_ENERGY_STAR_UNITS_OPEN_WIDGET_REQUEST"
export const GET_ENERGY_STAR_UNITS_FAILED_WIDGET_REQUEST = "GET_ENERGY_STAR_UNITS_FAILED_WIDGET_REQUEST"
export const GET_ENERGY_STAR_UNITS_CERTIFIED_WIDGET_REQUEST = "GET_ENERGY_STAR_UNITS_CERTIFIED_WIDGET_REQUEST"
export const GET_ENERGY_STAR_TAX_INCENTIVE_WIDGET_REQUEST = "GET_ENERGY_STAR_TAX_INCENTIVE_WIDGET_REQUEST"
export const GET_QUALITY_SCORE_WIDGET_REQUEST = "GET_QUALITY_SCORE_WIDGET_REQUEST"
export const GET_RESOURCE_CENTER_EXPORT_DOCUMENT = "GET_RESOURCE_CENTER_EXPORT_DOCUMENT"
export const GET_AVERAGE_CYCLE_TIME_WIDGET_REQUEST = "GET_AVERAGE_CYCLE_TIME_WIDGET_REQUEST"
export const GET_AVERAGE_FAILED_ACTIVITIES_WIDGET_REQUEST = "GET_AVERAGE_FAILED_ACTIVITIES_WIDGET_REQUEST"
export const GET_CAMPAIGN_TASK_STATUS_REPORT_REQUEST = "GET_CAMPAIGN_TASK_STATUS_REPORT_REQUEST"
export const GET_CAMPAIGN_TASK_STATUS_REPORT_VM_REQUEST = "GET_CAMPAIGN_TASK_STATUS_REPORT_VM_REQUEST"
export const GET_TASK_SUMMARY_VM_REQUEST = "GET_TASK_SUMMARY_VM_REQUEST"
export const GET_UNIT_STATUS_REPORT_REQUEST = "GET_UNIT_STATUS_REPORT_REQUEST"
export const SEND_REPORT_EMAIL_REQUEST = "SEND_REPORT_EMAIL_REQUEST"
export const GET_REPORT_FILE_STATUS_REQUEST = "GET_REPORT_FILE_STATUS_REQUEST"
export const GET_REPORT_DASHBOARD_LIST_REQUEST = "GET_REPORT_DASHBOARD_LIST_REQUEST"
export const GET_DASHBOARD_WIDGETS_REQUEST = "GET_DASHBOARD_WIDGETS_REQUEST"
export const GET_REPORT_DASHBOARD_VM_REQUEST = "GET_REPORT_DASHBOARD_VM_REQUEST"
export const UPDATE_PRIORITY_STATUS_COLUMN_REQUEST = "UPDATE_PRIORITY_STATUS_COLUMN_REQUEST"
export const TOGGLE_AUDIT_RANDOM_PERCENT_REQUEST = "TOGGLE_AUDIT_RANDOM_PERCENT_REQUEST"
export const TOGGLE_AUDIT_WITH_CLAUSE_REQUEST = "TOGGLE_AUDIT_WITH_CLAUSE_REQUEST"
export const GET_DASHBOARD_ORGANIZATION_DDL_REQUEST = "GET_DASHBOARD_ORGANIZATION_DDL_REQUEST"
export const GET_DASHBOARD_SITE_DDL_REQUEST = "GET_DASHBOARD_SITE_DDL_REQUEST"
export const GET_EXCEPTION_DASHBOARD_LIST_REQUEST = "GET_EXCEPTION_DASHBOARD_LIST_REQUEST"
export const UPDATE_SCHEDULING_EXCEPTION = "UPDATE_SCHEDULING_EXCEPTION"

/****************************************************/
/** GET_POWER_BI_ACCESS_TOKEN **/
/****************************************************/

/**
 * @method getPowerBiAccessTokenRequest : To get power bi access token
 *
 */
export const getPowerBiAccessTokenRequest = (payload, callback) => ({
  type: GET_POWER_BI_ACCESS_TOKEN_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_REPORT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getReportListRequest : To get report list
 *
 */
export const getReportListRequest = (payload, callback) => ({
  type: GET_REPORT_LIST_REQUEST,
  payload,
  callback
})

/****************************************************/
/** SET_REPORTS_OPENED_TAB **/
/****************************************************/

/**
 * @method setReportOpenedTab : To set reports opened tab
 *
 */
export const setReportOpenedTab = (payload) => ({
  type: SET_REPORTS_OPENED_TAB,
  payload
})

/****************************************************/
/** GET_UNACCEPTABLE_ACTIVITIES_REPORT **/
/****************************************************/

/**
 * @method getUnacceptableActivitiesReport : To get unacceptable activities report
 *
 */
export const getUnacceptableActivitiesReport = (payload, callback) => ({
  type: GET_UNACCEPTABLE_ACTIVITIES_REPORT,
  payload,
  callback
})

/****************************************************/
/** GET_UNAPPROVED_TRAINING_REPORT **/
/****************************************************/

/**
 * @method getUnapprovedTrainingReport : To get unapproved training report
 *
 */
export const getUnpprovedTrainingReport = (payload, callback) => ({
  type: GET_UNAPPROVED_TRAINING_REPORT,
  payload,
  callback
})

/****************************************************/
/** GET_DAILY_INSPECTION_REPORT **/
/****************************************************/

/**
 * @method getDailyInspectionReport : To get daily inspection activities report
 *
 */
export const getDailyInspectionReport = (payload, callback) => ({
  type: GET_DAILY_INSPECTION_REPORT,
  payload,
  callback
})

/****************************************************/
/** GET_UNACCEPTABLE_ACTIVITIES_VM_REQUEST **/
/****************************************************/

/**
 * @method getUnacceptableActivitiesVMRequest : To get vm for unacceptable activities
 *
 */
export const getUnacceptableActivitiesVMRequest = (callback) => ({
  type: GET_UNACCEPTABLE_ACTIVITIES_VM_REQUEST,
  callback
})

/****************************************************/
/** GET_REPORT_ASSIGNED_CONTACTS_DDL_REQUEST **/
/****************************************************/

/**
 * @method getReportAssignedContactsDDLRequest : To Get Paginated pointOfContact DDL for report
 *
 */
export const getReportAssignedContactsDDLRequest = (payload, callback) => {
  return {
    type: GET_REPORT_ASSIGNED_CONTACTS_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_UNACCEPTABLE_ACTIVITIES_SITE_PARTY_ROLE_DDL_REQUEST **/
/****************************************************/

/**
 * @method getUnacceptableActivitiesSitePartyRoleDDLRequest : To get acceptable activities site party role ddl 
 *
 */
export const getUnacceptableActivitiesSitePartyRoleDDLRequest = (payload, callback) => {
  return {
    type: GET_UNACCEPTABLE_ACTIVITIES_SITE_PARTY_ROLE_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_REPORT_SITE_DDL_REQUEST **/
/****************************************************/

/**
 * @method getReportSiteDDLRequest : To Get Paginated site DDL for report
 *
 */
export const getReportSiteDDLRequest = (payload, callback) => {
  return {
    type: GET_REPORT_SITE_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TRAINING_ACTIVITIES_INCOMPLETE_REQUEST **/
/****************************************************/

/**
 * @method getTrainingActivitiesIncompleteRequest : To get incomplete training activities count
 *
 */
export const getTrainingActivitiesIncompleteRequest = (payload, callback) => {
  return {
    type: GET_TRAINING_ACTIVITIES_INCOMPLETE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_OPEN_UNACCEPTABLE_ACTIVITIES_REQUEST **/
/****************************************************/

/**
 * @method getOpenUnacceptableActivitiesRequest : To Get open unacceptable activities count
 *
 */
export const getOpenUnacceptableActivitiesRequest = (payload, callback) => {
  return {
    type: GET_OPEN_UNACCEPTABLE_ACTIVITIES_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SATURATION_RATE_SUMMARY_REPORT_REQUEST **/
/****************************************************/

/**
 * @method getRateSummarySaturationReportRequest : To get saturation report rate summary
 *
 */
export const getRateSummarySaturationReportRequest = (payload, callback) => ({
  type: GET_SATURATION_RATE_SUMMARY_REPORT_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_SATURATION_RATE_DETAILS_REPORT_REQUEST **/
/****************************************************/

/**
 * @method getRateDetailsSaturationReportRequest : To get saturation report details summary
 *
 */
export const getRateDetailsSaturationReportRequest = (payload, callback) => ({
  type: GET_SATURATION_RATE_DETAILS_REPORT_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_EXPORT_DOCUMENT_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method getExportDocumentDetailsRequest : To get guid for the document to be downloaded
 *
 */
export const getExportDocumentDetailsRequest = (payload, callback) => ({
  type: GET_EXPORT_DOCUMENT_DETAILS_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_TRAINING_ACTIVITIES_FAILED_REQUEST **/
/****************************************************/

/**
 * @method getTrainingActivitiesFailedRequest : To get failed training activities count
 *
 */
export const getTrainingActivitiesFailedRequest = (payload, callback) => {
  return {
    type: GET_TRAINING_ACTIVITIES_FAILED_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TRAINING_ACTIVITIES_STATUS_REQUEST **/
/****************************************************/

/**
 * @method getTrainingActivitiesStatusRequest : To get failed training status count
 *
 */
export const getTrainingActivitiesStatusRequest = (payload, callback) => {
  return {
    type: GET_TRAINING_ACTIVITIES_STATUS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TRAINING_CAMPAIGN_REPORT_REQUEST **/
/****************************************************/

/**
 * @method getTrainingCampaignReportRequest : To get training campaign reports
 *
 */
export const getTrainingCampaignReportRequest = (payload, callback) => ({
  type: GET_TRAINING_CAMPAIGN_REPORT_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_COMPLETED_UNITS_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getCompletedUnitsWidgetRequest : To get completed units widget
 *
 */
export const getCompletedUnitsWidgetRequest = (payload, callback) => {
  return {
    type: GET_COMPLETED_UNITS_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_WARRANTY_CLAIM_ESTIMATE_WIDGET **/
/****************************************************/

/**
 * @method getWarrantyClaimEstimateWidgetRequest : To get warranty claim estimate widget
 *
 */
export const getWarrantyClaimEstimateWidgetRequest = (payload, callback) => {
  return {
    type: GET_WARRANTY_CLAIM_ESTIMATE_WIDGET,
    payload,
    callback
  }
}

/****************************************************/
/** GET_WARRANTY_COST_ESTIMATE_WIDGET **/
/****************************************************/

/**
 * @method getWarrantyCostEstimateWidgetRequest : To get warranty cost estimate widget
 *
 */
export const getWarrantyCostEstimateWidgetRequest = (payload, callback) => {
  return {
    type: GET_WARRANTY_COST_ESTIMATE_WIDGET,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CONSTRUCTION_CALLBACKS_COSTS_WIDGET **/
/****************************************************/

/**
 * @method getConstructionCallbacksCostsWidgetRequest : To get construction callback costs widget
 *
 */
export const getConstructionCallbacksCostsWidgetRequest = (payload, callback) => {
  return {
    type: GET_CONSTRUCTION_CALLBACKS_COSTS_WIDGET,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PRODUCTION_CYCLE_TIME_WIDGET **/
/****************************************************/

/**
 * @method getProductionCycleTimeWidgetRequest : To get production cycle time widget
 *
 */
export const getProductionCycleTimeWidgetRequest = (payload, callback) => {
  return {
    type: GET_PRODUCTION_CYCLE_TIME_WIDGET,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CONSTRUCTION_CALLBACKS_WIDGET **/
/****************************************************/

/**
 * @method getConstructionCallbacksWidgetRequest : To get construction callbacks widget
 *
 */
export const getConstructionCallbacksWidgetRequest = (payload, callback) => {
  return {
    type: GET_CONSTRUCTION_CALLBACKS_WIDGET,
    payload,
    callback
  }
}

/****************************************************/
/** GET_REPORT_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method getReportDetailRequest : To get report detail
 *
 */
export const getReportDetailRequest = (payload, callback) => {
  return {
    type: GET_REPORT_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_WORKFLOW_LIST_DDL_REQUEST **/
/****************************************************/

/**
 * @method getWorkflowListDDLRequest : To get workflow list ddl list
 *
 */
export const getWorkflowListDDLRequest = (payload, callback) => {
  return {
    type: GET_WORKFLOW_LIST_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_LIST_DDL_REQUEST **/
/****************************************************/

/**
 * @method getTaskListDDLRequest : To get task list ddl list
 *
 */
export const getTaskListDDLRequest = (payload, callback) => {
  return {
    type: GET_TASK_LIST_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_UNIT_CLOSEOUT_REQUEST **/
/****************************************************/

/**
 * @method getUnitCloseoutRequest : To get unit closeout list request
 *
 */
export const getUnitCloseoutRequest = (payload, callback) => ({
  type: GET_UNIT_CLOSEOUT_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_TRADE_PERFORMANCE_REPORT_REQUEST **/
/****************************************************/

/**
 * @method getTradePerformanceReportRequest : To get trade performance list request
 *
 */
export const getTradePerformanceReportRequest = (payload, callback) => ({
  type: GET_TRADE_PERFORMANCE_REPORT_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_NEW_OBSERVATION_CAMPAIGNS_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getNewObservationCampaignsRequest : To get new observation campaign request
 *
 */
export const getNewObservationCampaignsRequest = (callback) => ({
  type: GET_NEW_OBSERVATION_CAMPAIGNS_WIDGET_REQUEST,
  callback
})

/****************************************************/
/** GET_UNAPPROVED_ACTIVITIES_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getUnapprovedActivitiesWidgetRequest : To get unapproved activities widget
 *
 */
export const getUnapprovedActivitiesWidgetRequest = (callback) => ({
  type: GET_UNAPPROVED_ACTIVITIES_WIDGET_REQUEST,
  callback
})

/****************************************************/
/** GET_TRAINING_ACTIVITIES_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getTrainingActivitiesWidgetRequest : To get training activities widget
 *
 */
export const getTrainingActivitiesWidgetRequest = (callback) => ({
  type: GET_TRAINING_ACTIVITIES_WIDGET_REQUEST,
  callback
})

/****************************************************/
/** TRAINING_CAMPAIGN_VM_REQUEST **/
/****************************************************/

/**
 * @method getTrainingCampaignVMRequest : To get training campaign VM 
 *
 */
export const getTrainingCampaignVMRequest = (callback) => ({
  type: TRAINING_CAMPAIGN_VM_REQUEST,
  callback
})


/****************************************************/
/** GET_ACTIVITY_TRAINING_SUBJECT_REQUEST **/
/****************************************************/

/**
 * @method getActivityTrainingSubjectRequest : To get activity training subject ddl
 *
 */
export const getActivityTrainingSubjectRequest = (payload, callback) => ({
  type: GET_ACTIVITY_TRAINING_SUBJECT_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_ACTIVITY_TRAINING_CHAPTER_REQUEST **/
/****************************************************/

/**
 * @method getActivityTrainingChapterRequest : To get activity training chapter ddl
 *
 */
export const getActivityTrainingChapterRequest = (payload, callback) => ({
  type: GET_ACTIVITY_TRAINING_CHAPTER_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_ACTIVITY_TRAINING_COURSE_REQUEST **/
/****************************************************/

/**
 * @method getActivityTrainingCourseRequest : To get activity training course ddl
 *
 */
export const getActivityTrainingCourseRequest = (payload, callback) => ({
  type: GET_ACTIVITY_TRAINING_COURSE_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_ACTIVITY_TRAINING_LESSON_REQUEST **/
/****************************************************/

/**
 * @method getActivityTrainingLessonRequest : To get activity training lesson ddl
 *
 */
export const getActivityTrainingLessonRequest = (payload, callback) => ({
  type: GET_ACTIVITY_TRAINING_LESSON_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_ENERGY_STAR_UNITS_OPEN_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getEnergystarUnitsOpenWidgetRequest : To get energy star units open widget
 *
 */
export const getEnergystarUnitsOpenWidgetRequest = (payload, callback) => ({
  type: GET_ENERGY_STAR_UNITS_OPEN_WIDGET_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_ENERGY_STAR_UNITS_FAILED_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getEnergystarUnitsFailedWidgetRequest : To get energy star units failed widget
 *
 */
export const getEnergystarUnitsFailedWidgetRequest = (payload, callback) => ({
  type: GET_ENERGY_STAR_UNITS_FAILED_WIDGET_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_ENERGY_STAR_UNITS_CERTIFIED_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getEnergystarUnitsCertifiedWidgetRequest : To get energy star units certified widget
 *
 */
export const getEnergystarUnitsCertifiedWidgetRequest = (payload, callback) => ({
  type: GET_ENERGY_STAR_UNITS_CERTIFIED_WIDGET_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_ENERGY_STAR_TAX_INCENTIVE_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getEnergystarTaxIncentiveWidgetRequest : To get energy star tax incentive widget
 *
 */
export const getEnergystarTaxIncentiveWidgetRequest = (payload, callback) => ({
  type: GET_ENERGY_STAR_TAX_INCENTIVE_WIDGET_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_QUALITY_SCORE_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getQualityScoreWidgetRequest : To get quality score widget
 *
 */
export const getQualityScoreWidgetRequest = (payload, callback) => ({
  type: GET_QUALITY_SCORE_WIDGET_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_RESOURCE_CENTER_EXPORT_DOCUMENT **/
/****************************************************/

/**
 * @method getResourceCenterExportDocumentRequest : To notify when export failed
 *
 */
export const getResourceCenterExportDocumentRequest = (payload, callback) => ({
  type: GET_RESOURCE_CENTER_EXPORT_DOCUMENT,
  payload,
  callback
})

/****************************************************/
/** GET_AVERAGE_CYCLE_TIME_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getAverageCycleTimeWidgetRequest : To get average cycle time widget
 *
 */
export const getAverageCycleTimeWidgetRequest = (payload, callback) => ({
  type: GET_AVERAGE_CYCLE_TIME_WIDGET_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_AVERAGE_FAILED_ACTIVITIES_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getAverageFailedActivitiesWidgetRequest : To get average failed activities widget
 *
 */
export const getAverageFailedActivitiesWidgetRequest = (payload, callback) => ({
  type: GET_AVERAGE_FAILED_ACTIVITIES_WIDGET_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_CAMPAIGN_TASK_STATUS_REPORT_REQUEST **/
/****************************************************/

/**
 * @method getCampaignTaskStatusReportRequest : To get campaign task report
 *
 */
export const getCampaignTaskStatusReportRequest = (payload, callback) => ({
  type: GET_CAMPAIGN_TASK_STATUS_REPORT_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_CAMPAIGN_TASK_STATUS_REPORT_VM_REQUEST **/
/****************************************************/

/**
 * @method getCampaignTaskStatusReportVMRequest : To get vm for campaign task status report
 *
 */
export const getCampaignTaskStatusReportVMRequest = (callback) => ({
  type: GET_CAMPAIGN_TASK_STATUS_REPORT_VM_REQUEST,
  callback
})

/****************************************************/
/** GET_TASK_SUMMARY_VM_REQUEST **/
/****************************************************/

/**
 * @method getTaskSummaryVMRequest : To get vm for task summary report
 *
 */
export const getTaskSummaryVMRequest = (callback) => ({
  type: GET_TASK_SUMMARY_VM_REQUEST,
  callback
})

/****************************************************/
/** GET_UNIT_STATUS_REPORT_REQUEST **/
/****************************************************/

/**
 * @method getUnitStatusReportRequest : To get unit status report
 *
 */
export const getUnitStatusReportRequest = (payload, callback = () => { }) => ({
  type: GET_UNIT_STATUS_REPORT_REQUEST,
  payload,
  callback
})

/****************************************************/
/** SEND_REPORT_EMAIL_REQUEST **/
/****************************************************/

/**
 * @method sendReportEmailRequest : To send email for generated report
 *
 */
export const sendReportEmailRequest = (payload, callback) => ({
  type: SEND_REPORT_EMAIL_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_REPORT_FILE_STATUS_REQUEST **/
/****************************************************/

/**
 * @method getReportFileStatusRequest : To get status of report being generated for view
 *
 */
export const getReportFileStatusRequest = (payload, callback) => ({
  type: GET_REPORT_FILE_STATUS_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_REPORT_DASHBOARD_LIST_REQUEST **/
/****************************************************/

/**
 * @method getReportDashboardsListRequest : To get report dashboards list for PlanReview and FileProcess
 *
 */
export const getReportDashboardsListRequest = (payload, callback) => ({
  type: GET_REPORT_DASHBOARD_LIST_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_DASHBOARD_WIDGETS_REQUEST **/
/****************************************************/

/**
 * @method getDashboardWidgetsRequest : To get dashboards widget reports
 *
 */
export const getDashboardWidgetsRequest = (payload, callback) => ({
  type: GET_DASHBOARD_WIDGETS_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_REPORT_DASHBOARD_VM_REQUEST **/
/****************************************************/

/**
 * @method getReportDashboardsVMRequest : To get report dashboards VM 
 *
 */
export const getReportDashboardsVMRequest = (callback) => ({
  type: GET_REPORT_DASHBOARD_VM_REQUEST,
  callback
})

/****************************************************/
/** GET_REPORT_DASHBOARD_VM_REQUEST **/
/****************************************************/

/**
 * @method updatePriorityAndStatusColumnRequest : To get report dashboards VM 
 *
 */
export const updatePriorityAndStatusColumnRequest = (payload, callback) => ({
  type: UPDATE_PRIORITY_STATUS_COLUMN_REQUEST,
  payload,
  callback
})

/****************************************************/
/** TOGGLE_AUDIT_RANDOM_PERCENT_REQUEST **/
/****************************************************/

/**
 * @method toggleAuditRandomPercentRequest : To toggle audit random percent
 *
 */
export const toggleAuditRandomPercentRequest = (payload, callback = () => { }) => ({
  type: TOGGLE_AUDIT_RANDOM_PERCENT_REQUEST,
  payload,
  callback
})

/****************************************************/
/** TOGGLE_AUDIT_WITH_CLAUSE_REQUEST **/
/****************************************************/

/**
 * @method toggleAuditWithClauseRequest : To toggle audit with clause
 *
 */
export const toggleAuditWithClauseRequest = (payload, callback = () => { }) => ({
  type: TOGGLE_AUDIT_WITH_CLAUSE_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_DASHBOARD_ORGANIZATION_DDL_REQUEST **/
/****************************************************/

/**
 * @method getDashboardOrganizationDDLRequest : To get organization ddl for dashboard
 *
 */
export const getDashboardOrganizationDDLRequest = (payload, callback = () => { }) => ({
  type: GET_DASHBOARD_ORGANIZATION_DDL_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_DASHBOARD_SITE_DDL_REQUEST **/
/****************************************************/

/**
 * @method getDashboardSiteDDLRequest : To get site ddl for dashboard
 *
 */
export const getDashboardSiteDDLRequest = (payload, callback = () => { }) => ({
  type: GET_DASHBOARD_SITE_DDL_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_EXCEPTION_DASHBOARD_LIST_REQUEST **/
/****************************************************/

/**
 * @method getExceptionDashboardListRequest : To get exception dashboard list
 *
 */
export const getExceptionDashboardListRequest = (payload, callback) => ({
  type: GET_EXCEPTION_DASHBOARD_LIST_REQUEST,
  payload,
  callback
})

/****************************************************/
/** UPDATE_SCHEDULING_EXCEPTION **/
/****************************************************/

/**
 * @method updateSchedulingExceptionRequest : To update scheduling exception
 *
 */
export const updateSchedulingExceptionRequest = (payload, callback = () => { }) => ({
  type: UPDATE_SCHEDULING_EXCEPTION,
  payload,
  callback
})

