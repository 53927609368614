export const CREATE_USER_MANAGEMENT_ROLE = 'CREATE_USER_MANAGEMENT_ROLE'
export const GET_ROLE_LIST_REQUEST = 'GET_ROLE_LIST_REQUEST'
export const GET_FORESITE_ROLE_REQUEST = 'GET_FORESITE_ROLE_REQUEST'
export const UPDATE_FORESITE_ROLE_REQUEST = 'UPDATE_FORESITE_ROLE_REQUEST'
export const DELETE_FORESITE_ROLE_REQUEST = 'DELETE_FORESITE_ROLE_REQUEST'
export const TOGGLE_USER_MANAGEMENT_ROLE_MODAL = 'TOGGLE_USER_MANAGEMENT_ROLE_MODAL'

/****************************************************/
/** TOGGLE_USER_MANAGEMENT_ROLE_MODAL **/
/****************************************************/

/**
 * @method toggleUserManagementRoleModal : To toggle user management role modal
 *
 */
export const toggleUserManagementRoleModal = (payload) => {
  return {
    type: TOGGLE_USER_MANAGEMENT_ROLE_MODAL,
    payload
  }
}

/****************************************************/
/** CREATE_USER_MANAGEMENT_ROLE **/
/****************************************************/

/**
 * @method createUserManagementRole :  To create user management role
 *
 */
export const createUserManagementRole = (payload, callback) => {
  return {
    type: CREATE_USER_MANAGEMENT_ROLE,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ROLE_LIST **/
/****************************************************/

/**
 * @method getRoleListRequest : To get role list
 *
 */
export const getRoleListRequest = (payload, callback) => {
  return {
    type: GET_ROLE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_FORESITE_ROLE_REQUEST **/
/****************************************************/

/**
 * @method getForesiteRoleRequest : To get foresite role request
 *
 */
export const getForesiteRoleRequest = (payload, callback) => {
  return {
    type: GET_FORESITE_ROLE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_FORESITE_ROLE_REQUEST **/
/****************************************************/

/**
 * @method updateForesiteRoleRequest : To update foresite role request
 *
 */
export const updateForesiteRoleRequest = (payload, callback) => {
  return {
    type: UPDATE_FORESITE_ROLE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_FORESITE_ROLE_REQUEST **/
/****************************************************/

/**
 * @method deleteForesiteRoleRequest : To delete foresite role request
 *
 */
export const deleteForesiteRoleRequest = (payload, callback) => {
  return {
    type: DELETE_FORESITE_ROLE_REQUEST,
    payload,
    callback
  }
}