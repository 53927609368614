import config from "./APIConfig"
import { postDataApi } from '../index'

export const updateTaskReviewAssignment = (data) => {
    const { url } = config.updateTaskReviewAssignment

    return postDataApi(url(data), data)
}

export default updateTaskReviewAssignment
