import { REFRESH_TABLE_DATA } from '@store/actions'

// ** Initial State
const initialState = {
  contactList: { isListRefreshed: false },
  campaignList: { isListRefreshed: false },
  programList: { isListRefreshed: false },
  resourceList: { isListRefreshed: false },
  campaignTaskList: { isListRefreshed: false },
  activityList: { isListRefreshed: false },
  organizationList: { isListRefreshed: false },
  projectList: { isListRefreshed: false },
  templateList: { isListRefreshed: false },
  siteList: { isListRefreshed: false },
  serviceList: { isListRefreshed: false },
  siteRoleList: { isListRefreshed: false },
  checkList: { isListRefreshed: false },
  sitePlanList: { isListRefreshed: false },
  siteUnitList: { isListRefreshed: false },
  siteUnitChildGridList: { isListRefreshed: false },
  contactProjectList: { isListRefreshed: false },
  contactCampaignList: { isListRefreshed: false },
  taskList: { isListRefreshed: false },
  workflowList: { isListRefreshed: false },
  workflowChecklist: { isListRefreshed: false },
  schedulingUnitList: { isListRefreshed: false },
  dataRetentionList: { isListRefreshed: false },
  notificationList: { isListRefreshed: false },
  samplingAllUnits: { isListRefreshed: false },
  samplingGroupUnits: { isListRefreshed: false },
  samplingAllGroups: { isListRefreshed: false },
  samplingSelectedGroup: { isListRefreshed: false },
  applicableRegulation: { isListRefreshed: false },
  refereceStandard: { isListRefreshed: false },
  industryInterpretations: { isListRefreshed: false },
  unitScheduleList: { isListRefreshed: false },
  tasksList: { isListRefreshed: false },
  groupActivityList: { isListRefreshed: false, groupId: 0 },
  checklistGroupAvailableActivityList: { isListRefreshed: false },
  groupContactList: { isListRefreshed: false, contactGroups: [] },
  activityTemplateList: { isListRefreshed: false },
  childOrganizationList: { isListRefreshed: false },
  campaignContractList: { isListRefreshed: false },
  campaignContractAdditionalFeeList: { isListRefreshed: false },
  schedulingContractOrderDetailsList: { isListRefreshed: false },
  contractWorkflowProductList: { isListRefreshed: false },
  templateGroupAvailableActivityList: { isListRefreshed: false },
  templateGroupActivityList: { isListRefreshed: false, templateCheckpointGroupId: 0 },
  contactSiteList: { isListRefreshed: false },
  externalDirectoryList: { isListRefreshed: false },
  internalDirectoryList: { isListRefreshed: false },
  communicationLogList: { isListRefreshed: false },
  orgPlanList: { isListRefreshed: false }
}

const TableActionsReducer = (state = initialState, action) => {
  switch (action.type) {
    /** REFRESH_TABLE_DATA **/
    case REFRESH_TABLE_DATA:
      const { listKey, value = false, ...payload } = action.payload
      const tableData = state[listKey] || ''
      return {
        ...state,
        ...(tableData ? {
          [listKey]: {
            ...tableData,
            ...payload,
            isListRefreshed: value
          }
        } : { [listKey]: { ...payload, isListRefreshed: value } })
      }

    default:
      return state
  }
}

export default TableActionsReducer
