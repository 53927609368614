export const GET_USER_MANAGEMENT_PERMISSION_VM = 'GET_USER_MANAGEMENT_PERMISSION_VM'
export const GET_USER_MANAGEMENT_ROLE_PERMISSION_LIST = 'GET_USER_MANAGEMENT_ROLE_PERMISSION_LIST'
export const UPDATE_USER_MANAGEMENT_ROLE_PERMISSION_LIST = 'UPDATE_USER_MANAGEMENT_ROLE_PERMISSION_LIST'

/****************************************************/
/** GET_USER_MANAGEMENT_PERMISSION_VM **/
/****************************************************/

/**
 * @method getUserManagementPermissionVM :  To get user management permission dropdowns list
 *
 */
export const getUserManagementPermissionVM = callback => {
  return {
    type: GET_USER_MANAGEMENT_PERMISSION_VM,
    callback
  }
}

/****************************************************/
/** GET_USER_MANAGEMENT_ROLE_PERMISSION_LIST **/
/****************************************************/

/**
 * @method getUserManagementRolePermissionList :  To get user management role permission list
 *
 */
export const getUserManagementRolePermissionList = (payload, callback) => {
  return {
    type: GET_USER_MANAGEMENT_ROLE_PERMISSION_LIST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_USER_MANAGEMENT_ROLE_PERMISSION_LIST **/
/****************************************************/

/**
 * @method updateUserManagementRolePermissionList :  To update user management role permission list
 *
 */
export const updateUserManagementRolePermissionList = (payload) => {
  return {
    type: UPDATE_USER_MANAGEMENT_ROLE_PERMISSION_LIST,
    payload
  }
}
