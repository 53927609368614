import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState, useCallback, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"
import RoleModal, { MODAL_TYPE } from "@views/projects/add-new-project/sites/site-roles/EditRoleModal"
import { ASSIGNMENT_TYPES } from "./config"
import { getAssignedByKeyList } from "./helpers"
import localeMessageWrapper from "../locale-message"

const { WORKFLOW, SITE_CONTACT_SEARCH } = LANGUAGE_CONSTANTS

const SiteContactSearch = ({
  intl,
  isEditMode,
  isUpdateEditModeValue,
  customActionLabel,
  isMulti,
  isDisabled,
  isFormattedValue = true,
  errors,
  defaultOptions,
  menuPlacement,
  id,
  orgDetail,
  isRequired,
  isShowCustomAction,
  isTableView,
  label,
  labelRequired,
  name,
  placeholder,
  siteId,
  organizationId,
  organizationName = "",
  value,
  initialLabel,
  valueType,
  labelType,
  onSelect,
  getOptionLabel,
  getOptionValue,
  onResetUpdateEditModeValue,
  ...props
}) => {

  const dispatch = useDispatch()

  const [options, setOptions] = useState([])
  const [isOpenAddSiteRoleModal, setOpenAddSiteRoleModal] = useState(false)

  /**
   * Note: Paginated select maintain cache of options,
   * so to set new default options we need to reset previous cache
   */
  const [isResetCache, setResetCache] = useState(false)
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  useEffect(() => {
    setResetCache((prevState) => !prevState)
    setOptions(defaultOptions)
  }, [defaultOptions])

  useEffect(() => {
    if (!!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])

  //To handle edit mode when single select
  useEffect(() => {
    //Note: To get option details for selected value in edit mode 
    if (isEditMode && !!initialLabel && !isEditModeItemLoaded && !!value) {
      setEditModeItemLoaded(true)
      setSelectedOptionLabel(initialLabel)
    }
  }, [initialLabel, isEditMode, isEditModeItemLoaded, value])

  // To handle edit mode when single select
  useEffect(() => {
    //Note: To get option details for selected value in edit mode 
    if (isUpdateEditModeValue && !!initialLabel && !!value) {
      onResetUpdateEditModeValue(false)
      setSelectedOptionLabel(initialLabel)
    }
  }, [initialLabel, isUpdateEditModeValue, value])

  /**
 * @method handleSetDefaultOptions : To handle set default options in case of Edit mode
 *
 * Note: To handle case of duplicate option, when custom option is added, that is selected from edit mode
 */
  const handleSetDefaultOptions = useCallback(
    (selectedItem, selectedValue) => {
      const checkIfAlreadyPresent = defaultOptions.find(
        (option) => option[valueType] === selectedValue
      )
      if (!!checkIfAlreadyPresent) {
        setOptions(defaultOptions)
      } else {
        const updatedOptions = [selectedItem, ...defaultOptions]
        setOptions(updatedOptions)
      }
    },
    [defaultOptions, valueType]
  )

  const handleLoadMore = useCallback((search, page, prevOptions) => {
    return new Promise((resolve) => {

      if (!siteId) {
        resolve({
          optionList: [],
          hasMore: false
        })
      }

      const serachParams = {
        searchName: search,
        assignmentTypeId: ASSIGNMENT_TYPES.CONTACT,
        siteId,
        organizationId,
        pageSize: DEFAULT_VM_PAGE_SIZE,
        pageNumber: page
      }

      dispatch(actions.getSiteRoleAssignmentDDLRequest(serachParams, (response) => {
        if (response) {
          const { items, hasNextPage } = response

          resolve({
            optionList: items,
            hasMore: hasNextPage
          })

          setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
        } else {
          resolve({
            optionList: [],
            hasMore: false
          })
        }
      })
      )
    })
  }, [options, siteId])

  const handleSelect = useCallback(
    (selected) => {
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        let value = selected
        if (!!lastElement && !lastElement[valueType]) {
          value = []
        }
        onSelect(id, value)
      } else {
        const label = (selected?.[valueType] && selected?.[labelType])
        onSelect(id, selected?.[valueType] || "", label || "")
        setSelectedOptionLabel(label || "")
      }
    },
    [id, labelType, valueType, onSelect]
  )

  const handleToggleAddSiteRoleModal = useCallback((e) => {
    setOpenAddSiteRoleModal(prevState => !prevState)
  }, [])

  const handleAutoSelectFromModal = useCallback((selected) => {
    // if (isMulti) {
    //   onSelect(id, [
    //     ...value,
    //     {
    //       [labelType]: selected.label,
    //       [valueType]: selected.value
    //     }
    //   ])
    // } else {
    //   onSelect(id, selected.value || '', selected.label || '')
    // }
    setTimeout(() => {
      setResetCache(prevState => !prevState)
    }, 100)
    // setSelectedOptionLabel(selected.label || '')
    // setTimeout(() => {
    //   setResetCache(prevState => !prevState)
    //   const selectedItem = {
    //     [labelType]: selected.label,
    //     [valueType]: selected.value
    //   }
    //   handleSetDefaultOptions(selectedItem, selected.value)
    // }, 100)
  }, [id, isMulti, labelType, valueType, value, handleSetDefaultOptions, onSelect])

  const tablePropsConfig = useMemo(() => {
    if (isTableView) {
      return {
        menuPortalTarget: document.body,
        styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) }
      }
    }
    return {}
  }, [isTableView])

  return (
    <div className={cx({
      'table-view-list': isTableView
    })}>
      {labelRequired && <CustomLabel title={label || WORKFLOW.BLOCK3.SELECT_CONTACT} required={isRequired} isLocalizedTitle={!label} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        customActionLabel={
          customActionLabel || getLocaleMessage(intl, SITE_CONTACT_SEARCH.ADD_CONTACT)
        }
        isShowCustomAction={isShowCustomAction}
        id={id}
        name={name}
        placeholder={
          placeholder || getLocaleMessage(intl, WORKFLOW.BLOCK3.SELECT_CONTACT)
        }
        options={options}
        defaultOptions={defaultOptions}
        isOptionGridDropdown
        keyList={getAssignedByKeyList(intl, labelType)}
        isFormattedValue={!isMulti}
        value={!isMulti
          ? !!value
            ? {
              [labelType]: selectedOptionLabel,
              [valueType]: value
            }
            : null
          : value}
        valueType={valueType}
        lableType={labelType}
        isMulti={isMulti}
        menuPlacement={menuPlacement}
        onSelect={handleSelect}
        onClickCustomAction={handleToggleAddSiteRoleModal}
        onLoadOptions={handleLoadMore}
        getOptionLabel={getOptionLabel}
        hideSelectedOptions={false}
        getOptionValue={getOptionValue}
        isDisabled={isDisabled}
        {...tablePropsConfig}
        {...props}
      />
      {errors && <ErrorMessage isShow={!!errors[id]} message={errors[id]} />}
      {isOpenAddSiteRoleModal && (
        <RoleModal
          modalType={MODAL_TYPE.ADD}
          orgDetail={orgDetail}
          organizationId={organizationId}
          organizationName={organizationName}
          selectedSiteId={siteId}
          onCloseModal={handleToggleAddSiteRoleModal}
          onSelect={handleAutoSelectFromModal}
        />
      )}
    </div>
  )
}

SiteContactSearch.propTypes = {
  intl: PropTypes.object,
  isEditMode: PropTypes.bool,
  isUpdateEditModeValue: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errors: PropTypes.object,
  defaultOptions: PropTypes.array,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  isShowCustomAction: PropTypes.bool,
  isTableView: PropTypes.bool,
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  initialLabel: PropTypes.string,
  valueType: PropTypes.string,
  labelType: PropTypes.string,
  siteId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  orgDetail: PropTypes.object,
  onSelect: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  onResetUpdateEditModeValue: PropTypes.func
}

SiteContactSearch.defaultProps = {
  isEditMode: false,
  isUpdateEditModeValue: false,
  isDisabled: false,
  defaultOptions: [],
  id: "contactId",
  isRequired: false,
  isShowCustomAction: false,
  isTableView: false,
  labelRequired: true,
  name: "contact",
  value: "",
  initialLabel: "",
  valueType: "siteRoleId",
  labelType: "name",
  orgDetail: { id: '-1' }, //Todo: set id to 0
  onSelect: () => { },
  getOptionLabel: (option) => option.name,
  getOptionValue: (option) => option.siteRoleId,
  onResetUpdateEditModeValue: () => { }
}

export default localeMessageWrapper(SiteContactSearch)
