import config from "./APIConfig"
import { getDataApi, getHeaders } from '../index'

export const getAdvanceSearchFilterList = (data) => {
  const { url } = config.getAdvanceSearchFilterList
  return getDataApi(url(data))
}

export const getForesiteRoleDetails = async (id) => {
  const { url } = config.getForesiteRoleDetails
  return await getDataApi(url(id))
}

export const getMenuList = () => {
  const { url } = config.getMenuList
  return getDataApi(url)
}

export const getRoleList = (data) => {
  const { url } = config.getRoleList
  return getDataApi(url(data))
}

export const getTimeZoneDDL = (data) => {
  const { url } = config.getTimezoneDDL
  return getDataApi(url(data))
}

/**
 * @method getUserEntityDDL
 * @param {object} data
 * Contains following :-
 * @param {string} email
 * @param {string} accessToken
 *
 */
export const getUserEntityDDL = (data) => {
  const { url } = config.getUserEntityDDL
  return getDataApi(url(data.email), getHeaders(data.accessToken), {
    preventTokenOverride: true
  })
}

export const getUserProfile = (data) => {
  const { url } = config.getUserProfile
  return getDataApi(url)
}
