import { useCallback, useState } from "react"
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as actions from '@store/actions'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import AddContactModal from '@views/organizations/add-new-organization/contacts/contact-modal'
import AddNewContactModal from "@views/organizations/overview/organization-details/directory/add-new-contact-modal"

const ContactModal = ({
  contactsStore: {
    contactDetail = {}, pageNumber, pageSize
  },
  isEditMode = false,
  isMulti,
  isOpen,
  orgDetail,
  onToggleModal,
  addContact,
  onDeleteContact,
  getContactList,
  onSelect,
  onGetSearchBasedContactList
}) => {
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false)

  const handleToggleDeleteContact = useCallback((event) => {
    if (event) {
      event.preventDefault()
    }
    setOpenDeleteModal(!isOpenDeleteModal)
  }, [isOpenDeleteModal])

  const handleDeleteContact = useCallback(({ contactId, organizationName }) => {
    onDeleteContact({ contactId }, () => {
      getContactList({
        organizationId: orgDetail?.id || 0,
        pageNumber,
        pageSize,
        advanceSearch: {
          organizationName
        }
      })
      handleToggleDeleteContact()
      onToggleModal()
    })
  }, [orgDetail, pageNumber, pageSize, onToggleModal, handleToggleDeleteContact])

  const handleUpdateContactCallback = useCallback(
    (value, data) => {
      if (isMulti) {
        onSelect([
          {
            contactId: value,
            contactOrganizationName: `${data.firstName} ${data.lastName} - ${orgDetail.name}`,
            contactName: `${data.firstName} ${data.lastName}`,
            organizationId: data.organizationID
          }
        ], () => {
          onGetSearchBasedContactList(
            {
              contactId: value,
              contactName: `${data.firstName} ${data.lastName}`,
              contactOrganizationName: `${data.firstName} ${data.lastName} - ${orgDetail.name}`,
              organizationId: data.organizationID
            }
          )
        })
      } else {
        onSelect({
          contactId: value,
          contactOrganizationName: `${data.firstName} ${data.lastName} - ${orgDetail.name}`,
          contactName: `${data.firstName} ${data.lastName}`,
          organizationId: data.organizationID
        }, () => {
          onGetSearchBasedContactList(
            {
              contactId: value,
              contactName: `${data.firstName} ${data.lastName}`,
              contactOrganizationName: `${data.firstName} ${data.lastName} - ${orgDetail.name}`,
              organizationId: data.organizationID
            }
          )
        })
      }
    },
    [isMulti, orgDetail]
  )

  return (
    <>
      {isOpen && (
        <AddNewContactModal
        isDDLContactView
        selectedRowData={contactDetail}
        organizationId={orgDetail.id}
        organizationName={orgDetail.name}
        contactId={contactDetail?.contactId}
        onToggleModal={onToggleModal}
        onUpdateContactCallback={handleUpdateContactCallback}
      />
      )}
      {isOpenDeleteModal &&
        <DeleteConfirmationModal
          id={contactDetail.contactId}
          open={isOpenDeleteModal}
          onToggleModal={handleToggleDeleteContact}
          onConfirmDeleteRecord={(id) => handleDeleteContact({ contactId: id, organizationName: contactDetail.organnizationName })}
          type={LANGUAGE_CONSTANTS.CONTACTS.CONTACT}
        />
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    contactsStore: state.contacts
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getContactList: (payload, callback) => dispatch(actions.getContactListRequest(payload, callback)),
    addContact: (payload, callback) => dispatch(actions.addContactRequest(payload, callback)),
    onDeleteContact: (payload, callback) => dispatch(actions.deleteContactRequest(payload, callback))
  }
}

ContactModal.propTypes = {
  contactsStore: PropTypes.object,
  isEditMode: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOpen: PropTypes.bool,
  orgDetail: PropTypes.object,
  onToggleModal: PropTypes.func,
  addContact: PropTypes.func,
  onDeleteContact: PropTypes.func,
  getContactList: PropTypes.func,
  onSelect: PropTypes.func,
  onGetSearchBasedContactList: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal)
