import config from "./APIConfig"
import { postDataApi } from '../../index'

/**
 * @method createCampaignWorkflow
 *
 */
export const createCampaignWorkflow = (data) => {
  const { url } = config.createCampaignWorkflow
  return postDataApi(url(data.campaignId), data)
}

export default createCampaignWorkflow
