import React, { useState, useCallback, useEffect } from "react"
import PropTypes from 'prop-types'
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { CustomReactSelect, CustomLabel, ErrorMessage } from "@views/components"
import localeMessageWrapper from '../locale-message'

const { SITE_PROJECT_PLANS, ORG_PLANS } = LANGUAGE_CONSTANTS

const OrgPlansSearch = ({
    organizationId,
    defaultOptions,
    id,
    intl,
    isEditMode,
    isDisabled,
    label,
    errors,
    labelRequired,
    name,
    placeholder,
    value,
    isRequired,
    onSelect
}) => {
    const dispatch = useDispatch()
    /**
   * Note: Paginated select maintain cache of options,
   * so to set new default options we need to reset previous cache
   */
    const [isResetCache, setResetCache] = useState(false)

    const [selectedOptionLabel, setSelectedOptionLabel] = useState('')
    const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)
    const [options, setOptions] = useState(defaultOptions)

    /**
     * @method handleSetDefaultOptions : To handle set default options in case of Edit mode, Add new from modal
     *
     * Note: To handle case of duplicate option, when custom option is added, that is selected from edit mode or add new modal
     */
    const handleSetDefaultOptions = useCallback((selectedItem, selectedPlanId) => {
        const checkIfAlreadyPresent = defaultOptions.find(option => option.value === selectedPlanId)
        if (!!checkIfAlreadyPresent) {
            setOptions(defaultOptions)
        } else {
            setOptions([selectedItem, ...defaultOptions])
        }
    }, [defaultOptions])

    useEffect(() => {
        setResetCache((prevState) => !prevState)
        setOptions(defaultOptions)
    }, [defaultOptions])

    //To handle edit mode when single select
    useEffect(() => {
        //Note: To get option details for selected value in edit mode 
        if (isEditMode) {
            if (!isEditModeItemLoaded && !!value) {
                /**
                 * Note: Currently label for value in edit mode is fetched from current options.
                 * If we have pagination concept, then we need to fetch inital label from plan detail api,
                 * Else we need initial label from parent component
                 */

                setEditModeItemLoaded(true)
                const planId = Number(value)
                const selectedItem = defaultOptions.find(item => Number(item.value) === planId)
                if (selectedItem) {
                    setSelectedOptionLabel(selectedItem.text || "")
                    setResetCache((prevState) => !prevState)
                    handleSetDefaultOptions(selectedItem, value)
                }
            }
        }
    }, [isEditMode, value, isEditModeItemLoaded])

    useEffect(() => {
        //Note: To get option details for selected value in edit mode 
        if (isEditMode) {
            if (!!value) {
                setEditModeItemLoaded(true)
                const planId = Number(value)
                const selectedItem = defaultOptions.find(item => Number(item.value) === planId)
                if (selectedItem) {
                    setSelectedOptionLabel(selectedItem.text || "")
                    setResetCache((prevState) => !prevState)
                    handleSetDefaultOptions(selectedItem, value)
                }
            }
        }
    }, [isEditMode, value])

    const handleSelectOption = useCallback((selected, data) => {
        if (data?.action === 'clear') {
            return null
        }

        onSelect(id, selected?.value || '', selected?.text || '')
        setSelectedOptionLabel(selected?.text || '')
    }, [onSelect])

    const handleLoadMoreOptions = useCallback(
        (search, page, prevOptions) => {
            return new Promise((resolve) => {

                const handleSearchResultCallback = (response) => {
                    if (!!response?.items) {
                        const { hasNextPage } = response
                        const { items } = response
                        setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
                        resolve({
                            optionList: items,
                            hasMore: hasNextPage
                        })
                    } else {
                        resolve({
                            optionList: [],
                            hasMore: false
                        })
                    }
                }
                const params = {
                    organizationId,
                    planName: search,
                    pageNumber: page,
                    pageSize: DEFAULT_VM_PAGE_SIZE
                }
                dispatch(actions.getOrganizationPlanDDLRequest(params, handleSearchResultCallback))
            }

            )
        }, [organizationId, options])

    return (
        <>
            <div className="form-group">
                {labelRequired && <CustomLabel title={label || SITE_PROJECT_PLANS.PLAN_NAME} isLocalizedTitle={!label} required={isRequired} />}
                <div className={"project-search"} >
                    <CustomReactSelect
                        additional= {{ page: 1 }}
                        cacheUniqs={[isResetCache]}
                        isShowCustomAction={false}
                        id={id}
                        name={name}
                        isDisabled={isDisabled}
                        isInitialSelectOptionRequired
                        isPaginationRequired
                        placeholder={placeholder || getLocaleMessage(intl, ORG_PLANS.SELECT)}
                        options={options}
                        defaultOptions={options}
                        isFormattedValue
                        value={!!value ? {
                            text: selectedOptionLabel,
                            value
                        } : null}
                        getOptionLabel={(option) => option.text}
                        getOptionValue={(option) => option.value}
                        onSelect={handleSelectOption}
                        onLoadOptions={handleLoadMoreOptions}
                    />
                </div>
            </div>
            {errors && <ErrorMessage isShow={!!errors[id]} message={errors[id]} />}
        </>
    )
}

OrgPlansSearch.propTypes = {
    defaultOptions: PropTypes.array,
    id: PropTypes.string,
    errors: PropTypes.object,
    intl: PropTypes.object,
    isEditMode: PropTypes.bool,
    label: PropTypes.string,
    labelRequired: PropTypes.bool,
    isRequired: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    organizationId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    value: PropTypes.string,
    onSelect: PropTypes.func
}

OrgPlansSearch.defaultProps = {
    defaultOptions: [],
    id: "planName",
    isEditMode: false,
    isRequired: false,
    labelRequired: true,
    name: "planName",
    onSelect: () => { }
}

export default localeMessageWrapper(OrgPlansSearch)
