import React from "react"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"

const { ACTIONS_PAGE, ACTIVITIES, VERSION } = LANGUAGE_CONSTANTS

const ActivityDetail = ({ intl, selectedGrid }) => {
  if (!selectedGrid) {
    return null
  }

  return (
    <div>
      <div className="site-units activities-meta-detail">
        <div>
          <h4 className="activity-detail-title">{getLocaleMessage(intl, ACTIVITIES.ACTIVITY_DETAILS)}</h4>
        </div>
        <div className="site-units-details">
          <span className="detail-label">
            {getLocaleMessage(intl, ACTIVITIES.STAGE_OF_CONSTRUCTION)}:{" "}
          </span>
          <span>{selectedGrid.stageOfConstruction || "-"}</span>
        </div>
        <div className="site-units-details">
          <span className="detail-label">
            {getLocaleMessage(intl, ACTIVITIES.SCOPE_OF_WORK)}:{" "}
          </span>
          <span>{selectedGrid.scopeOfWork || "-"}</span>
        </div>
        <div className="site-units-details">
          <span className="detail-label">
            {getLocaleMessage(intl, ACTIONS_PAGE.ASSIGNED_ROLE)}:{" "}
          </span>
          <span>{selectedGrid.responsibleSiteRole || "-"}</span>
        </div>
        <div className="site-units-details">
          <span className="detail-label">
            {getLocaleMessage(intl, ACTIVITIES.RISK_LEVEL)}:{" "}
          </span>
          <span>{selectedGrid.riskLevel || "-"}</span>
        </div>
        <div className="site-units-details">
          <span className="detail-label">
            {getLocaleMessage(intl, ACTIONS_PAGE.REQUIRE_PHOTOS)}:{" "}
          </span>
          <span>
            {selectedGrid.requiredPhotos === false
              ? getLocaleMessage(intl, ACTIVITIES.NO)
              : selectedGrid.requiredPhotos === true
              ? getLocaleMessage(intl, ACTIVITIES.YES)
              : "-"}
          </span>
        </div>
        <div className="site-units-details">
          <span className="detail-label">
            {getLocaleMessage(intl, VERSION)}:{" "}
          </span>
          <span>{selectedGrid.versionId || "-"}</span>
        </div>
          <h5 className="activit-subtitle">
            {getLocaleMessage(
              intl,
              LANGUAGE_CONSTANTS.ACTIONS_PAGE.SYSTEM_DEFINITION
            )}
          </h5>
        <div className="site-units-details">
          <span className="detail-label">
            {getLocaleMessage(intl, ACTIONS_PAGE.SYSTEM_CATEGORY)}:{" "}
          </span>
          <span>{selectedGrid.systemCategory || "-"}</span>
        </div>
        <div className="site-units-details">
          <span className="detail-label">
            {getLocaleMessage(intl, ACTIVITIES.ASSEMBLY)}:{" "}
          </span>
          <span>{selectedGrid.assembly || "-"}</span>
        </div>
        <div className="site-units-details">
          <span className="detail-label">
            {getLocaleMessage(intl, ACTIVITIES.COMPONENT)}:{" "}
          </span>
          <span>{selectedGrid.component || "-"}</span>
        </div>
        <div className="site-units-details">
          <span className="detail-label">
            {getLocaleMessage(intl, ACTIVITIES.SUB_COMPONENT)}:{" "}
          </span>
          <span>{selectedGrid.subComponent || "-"}</span>
        </div>
        <div className="site-units-details">
          <span className="detail-label">
            {getLocaleMessage(intl, ACTIVITIES.MANUFACTURER)}:{" "}
          </span>
          <span>{selectedGrid.manufacturer || "-"}</span>
        </div>
        <div className="site-units-details">
          <span className="detail-label">
            {getLocaleMessage(intl, ACTIVITIES.BRAND)}:{" "}
          </span>
          <span>{selectedGrid.brand || "-"}</span>
        </div>
      </div>
    </div>
  )
}

export default localeMessageWrapper(ActivityDetail)
