import cx from "classnames"
import React, { useEffect, useRef, useState, useCallback, useContext, useMemo } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'

import { LIST_SORT_ORDER_DIRECTION } from '@shared/constants'
import { menuIcon } from "@src/assets/images/new-theme"
import { LIST_SORT_ORDER_VALUES, ROW_MODEL_TYPE } from "./config"
import { GridContext } from "./GridContext"

const GridTableHeaderComponent = ({
  comp,
  showColumnMenu,
  column,
  enableMenu,
  enableSorting,
  gridRef,
  checkboxSelection,
  setSort = () => { }
}) => {
  const [sortOrder] = useState(Object.values(LIST_SORT_ORDER_DIRECTION))
  const [sortIcon, setSortIcon] = useState(LIST_SORT_ORDER_DIRECTION.NO_SORT)
  const [isAllSelected, setAllSelected] = useState(false)
  const [isDisableAllSelect, setDisableAllSelect] = useState(false)

  const refButton = useRef(null)
  const context = useContext(GridContext)

  const { sortOrderColumn, sortOrderDirection } = useMemo(() => context, [context.sortOrderColumn, context.sortOrderDirection])

  useEffect(() => {
    if (sortOrderColumn === column.colDef.colId) {
      const direction = sortOrderDirection === "0" ? LIST_SORT_ORDER_DIRECTION.ASC : sortOrderDirection === "1" ? LIST_SORT_ORDER_DIRECTION.DESC : LIST_SORT_ORDER_DIRECTION.NO_SORT
      setSortIcon(direction)
    } else {
      setSortIcon(LIST_SORT_ORDER_DIRECTION.NO_SORT)
    }
  }, [column, sortOrderColumn, sortOrderDirection])

  //To set and update isAllSelected value based on changes in grid state value
  useEffect(() => {
    setAllSelected(context.isAllSelected)
  }, [context.isAllSelected])

  //To disable All Select checkbox selection
  useEffect(() => {
    //Disable if no records or api is loading data
    setDisableAllSelect(context.isLoading || !context.totalCount)
  }, [context.isLoading, context.totalCount])

  // const maxWidth = useMemo(() => {
  //   return `${(column.actualWidth - 40) - ((column.actualWidth) * (12 / 100))}`
  // }, [column])

  // const calcMaxWidth = useMemo(() => {
  //   return maxWidth ? `${maxWidth}px` : '120px'
  // }, [maxWidth])

  const handleSelectAllRows = useCallback(() => {
    const gridRefProps = gridRef.current.props
    const value = gridRefProps.isAllSelected
    const checkboxSelectionFn = typeof (column.colDef.checkboxSelection) === "function" ? column.colDef.checkboxSelection : function () { return true }
    if (!value) {
      const rowDataList = []
      let hasRowData = false
      const lastRowIndex = gridRef.current.api.getDisplayedRowCount() - 1

      //To select all rendered rows of ag-grid
      gridRef.current.api.forEachNode((node, index) => {
        const canSelectNode = checkboxSelectionFn(node)
        if (canSelectNode && !!node.data) {
          node.setSelected(true)
          rowDataList.push(node.data)
          if ((index + 1) === lastRowIndex) {
            hasRowData = true
          }
        }
      })

      if (hasRowData) {
        //To set/unset header all select checkbox option
        gridRefProps.onHeaderCheckboxSelection(value)
      }

      //To set updated selected rows detail in parent component 
      gridRefProps.onSelectedRowsChange([...rowDataList.filter(row => !gridRefProps.onSelectableRowDisabled(row))], { isAllSelected: true })
    } else {

      //To set/unset header all select checkbox option
      gridRefProps.onHeaderCheckboxSelection(value)

      const selectedList = []
      //To un-select all rendered rows of ag-grid
      gridRef.current.api.forEachNode((node) => {
        //If node is already selected and checkboxSelectionFn returns false, 
        //user can't unselected that node
        const canUnSelectNode = checkboxSelectionFn(node)
        if (canUnSelectNode) {
          gridRefProps.onSetAutoUnselectRow(true)
          node.setSelected(false)
        } else {
          selectedList.push(node.data)
        }
      })

      setTimeout(() => {
        gridRefProps.onSetAutoUnselectRow(false)
      }, 100)

      //To reset updated selected rows detail in parent component 
      gridRefProps.onSelectedRowsChange(selectedList, { isAllSelected: false })
    }
  }, [column, gridRef?.current.props])

  const handleMenuClicked = useCallback((event) => {
    event.stopPropagation()
    showColumnMenu(refButton.current)
  }, [])

  const handleSortColumn = useCallback((event) => {
    const currentSortDirIndex = sortOrder.indexOf(sortIcon)
    const sortDir = currentSortDirIndex === sortOrder.length - 1 ? LIST_SORT_ORDER_VALUES[0] : LIST_SORT_ORDER_VALUES[currentSortDirIndex + 1]
    if (!!gridRef?.current) {
      gridRef.current.props?.onSort({ sortKey: column.colDef.colId }, sortDir)


      const isServerSide = gridRef.current.props?.rowModelType === ROW_MODEL_TYPE.SERVER_SIDE
      if (isServerSide && gridRef.current.props?.onSort) {
        //Note: For serverside sorting to call getRows(), need to reset datasource

        //If onSort() is called for Server side modal, 
        //setSort will call getRows() of server's data source, 
        //which in turn call api to load rows using sortModal's parameters
        // setSort(sortDir === "0" ? 'asc' : sortDir === "1" ? 'desc' : '', event.shiftKey)
      }
    }
  }, [column, sortIcon])

  let menu = null
  if (enableMenu) {
    menu = (
      <div
        ref={refButton}
        className="customHeaderMenuButton"
        onClick={handleMenuClicked}
        onTouchEnd={handleMenuClicked}
      >
        <img src={menuIcon} alt="menu" style={{ height: 16 }} />
      </div>
    )
  }

  let sort = null
  if (enableSorting) {
    sort = (
      <div
        className={cx("customSortDownLabel", {
          customSortUpLabel: sortIcon === LIST_SORT_ORDER_DIRECTION.ASC,
          customSortNoneLabel: sortIcon === LIST_SORT_ORDER_DIRECTION.NO_SORT
        })}
      >
        {sortIcon === LIST_SORT_ORDER_DIRECTION.ASC ? (
          <ChevronUp size={16} color={"gray"} />
        ) : sortIcon === LIST_SORT_ORDER_DIRECTION.NO_SORT ? (
          <span className="ag-header-icon ag-sort-order ag-hidden">
            <ChevronUp size={16} color={"gray"} />
          </span>
        ) : (
          <ChevronDown size={16} color={"gray"} />
        )}
      </div>
    )
  }

  let headerCheckbox = null
  if (checkboxSelection) {
    headerCheckbox = (
      <div className={cx("ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper", {
        "ag-checked": isAllSelected,
        disabled: isDisableAllSelect
      })}>
        <input
          type="checkbox"
          className={cx("ag-header-select-all", {
            disabled: isDisableAllSelect
          })}
          // className="ag-header-select-all ag-checkbox-input"
          checked={isAllSelected}
          // checked={gridRef.current.props.isAllSelected} //Todo: check how to set and update this value
          disabled={isDisableAllSelect} //Set to disabled if loading === true
          onChange={handleSelectAllRows}
        />
      </div>
    )
  }

  return (
    <div className="d-flex ag-header-cell-comp-wrapper">
      <div>{headerCheckbox}</div>
      {!checkboxSelection && <> <div
        className='ag-cell-label-container'
      >
        <div className="ag-header-cell-label ag-header-cell-sorted-asc" onClick={enableSorting ? handleSortColumn : undefined}>
          <span className="ag-header-cell-text text-capitalize">{comp} </span>
          <span className="ag-sort-indicator-container">{comp && sort}</span>
        </div>
      </div>
        <span className="ag-header-icon ag-header-cell-menu-button">{comp && menu}</span>
      </>}
    </div>
  )
}

export default GridTableHeaderComponent
