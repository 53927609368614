import config from "./APIConfig"
import { putDataApi } from '../index'

export const addSharedCampaignsToSite = (data) => {
  const { url } = config.addSharedCampaignsToSite

  return putDataApi(url(data), data)
}

export default addSharedCampaignsToSite
