import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method cloneTrainingActivity
 * @param {object} data 
 *
 */
export const cloneTrainingActivity = (data) => {
  const { url } = config.cloneTrainingActivity
  return postDataApi(url(data))
}

export default cloneTrainingActivity
