import PropTypes from 'prop-types'

function CheckBox ({value}) {
    return (
      <div className=' custom-control custom-checkbox'>
        <input type='checkbox' className='custom-control-input' checked={value} readOnly/>
        <label className='custom-control-label' />
      </div>    
    )
  }

  CheckBox.propTypes = {
    value: PropTypes.bool
  }

  export default CheckBox