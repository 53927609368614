import { COLUMN_LIST_TYPE } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'
import { getActivitiesListRowDragText } from '@views/helpers'
import AllowCopyActivity from './AllowCopyActivity'
import Required from './Required'
import RowActions from './RowActions'
import RowActionsGrid from './RowActionsGrid'
import StandardCell from './StandardCell'
import OrderActions from './OrderActions'

const { ACTIVITIES, TYPE } = LANGUAGE_CONSTANTS
const { TABLE_COLUMNS, SOURCE } = ACTIVITIES

const columnsConfig2 = (LocaleMessage, templateDetail) => ([
  {
    name: <LocaleMessage id={TABLE_COLUMNS.ORDER} />,
    minWidth: "130px",
    suppressRowClick: true,
    rowDrag: true,
    rowDragText: getActivitiesListRowDragText,
    cell: (row) => <OrderActions data={row} />
  },
  {
    name: <LocaleMessage id={ACTIVITIES.REQUIRED} />,
    selector: 'isRequired',
    allowOverflow: true,
    suppressRowClick: true,
    tooltipValueGetter: () => {
      return ''
    },
    cell: (row) => <Required data={row} templateDetail={templateDetail} />
  },
  {
    name: <LocaleMessage id={TABLE_COLUMNS.ACTIVITY_NAME} />,
    selector: 'checkPointName',
    wrap: true,
    minWidth: '420px',
    format: (row) => row.checkPointName
  },
  {
    name: <LocaleMessage id={TABLE_COLUMNS.ACTIVITY_TYPE} />,
    selector: 'activityName',
    format: (row) => row.activityName
  },
  {
    name: <LocaleMessage id={ACTIVITIES.ALLOW_COPY} />,
    selector: 'isCopyAllowed',
    allowOverflow: true,
    suppressRowClick: true,
    tooltipValueGetter: () => {
      return ''
    },
    format: (row) => (
      <AllowCopyActivity data={row} templateDetail={templateDetail} />
    )
  },
  {
    selector: 'actions',
    minWidth: '90px',
    maxWidth: '100px',
    allowOverflow: true,
    cell: (row) => <RowActionsGrid data={row} templateId={templateDetail.templateId} />
  }
])

const columnsConfig3 = (LocaleMessage) => ([
  {
    name: <LocaleMessage id={TABLE_COLUMNS.ORDER} />,
    rowDrag: true,
    rowDragText: getActivitiesListRowDragText,
    cell: (row) => <OrderActions data={row} />
  },
  {
    name: <LocaleMessage id={TABLE_COLUMNS.ACTIVITY_NAME} />,
    selector: 'checkPointName',
    minWidth: '420px',
    cell: (row) => <StandardCell data={row} />
  },
  {
    name: <LocaleMessage id={SOURCE} />,
    selector: 'originatedFrom',
    format: (row) => row.originatedFrom
  },
  {
    name: <LocaleMessage id={TABLE_COLUMNS.ACTIVITY_TYPE} />,
    selector: 'activityName',
    format: (row) => row.activityName
  }
])

const columnsConfig4 = (LocaleMessage) => ([
  ...columnsConfig3(LocaleMessage),
  {
    selector: 'actions',
    minWidth: '90px',
    maxWidth: '100px',
    allowOverflow: true,
    cell: (row) => <RowActions data={row} editFlag={row.canEdit} />
  }
])


export const columnsWorkflowConfig = (LocaleMessage, type) => ([
  {
    name: <LocaleMessage id={ACTIVITIES.ACTIVITIES} />,
    selector: 'checkPointName',
    sortKey: 'checkpointname',
    sortable: true,
    wrap: true,
    format: (row) => row.checkPointName
  },
  {
    name: <LocaleMessage id={TYPE} />,
    selector: 'activityName',
    sortKey: 'activityname',
    sortable: true,
    format: (row) => row.activityName
  },
  {
    selector: 'actions',
    minWidth: '90px',
    maxWidth: '100px',
    allowOverflow: true,
    cell: (row) => <RowActions data={row} type={type} editFlag />
  }
])

const columnsChecklistActivityModalConfig = (LocaleMessage, isCheckBoxSelectionNotRequired) => ([
  {
    customHeaderCheckboxSelection: !isCheckBoxSelectionNotRequired,
    checkboxSelection: !isCheckBoxSelectionNotRequired,
    selector: '',
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    name: <LocaleMessage id={TABLE_COLUMNS.ACTIVITY_NAME} />,
    selector: 'checkPointName',
    sortKey: 'checkpointname',
    sortable: true,
    wrap: true,
    minWidth: '420px',
    format: (row) => row.checkPointName
  },
  {
    name: <LocaleMessage id={TABLE_COLUMNS.ACTIVITY_TYPE} />,
    selector: 'activityName',
    sortKey: 'activityname',
    sortable: true,
    format: (row) => row.activityName
  }
])

export const getColumnList = ({
  type = COLUMN_LIST_TYPE.OVERVIEW,
  templateDetail,
  isCheckBoxSelectionNotRequired
}) => {

  if (type === COLUMN_LIST_TYPE.WORKFLOW_ASSIGN_CHECKLIST_CHECKPOINTS) {
    return columnsWorkflowConfig(LocaleMessage, type)
  }
  if (type === COLUMN_LIST_TYPE.SEARCH_FOR_CAMPAIGN) {
    //Todo: check if selection column added at right list
    return columnsChecklistActivityModalConfig(LocaleMessage, isCheckBoxSelectionNotRequired)
  }

  if (type === COLUMN_LIST_TYPE.CHECKPOINTS_FOR_CHECKLIST) {
    return columnsConfig4(LocaleMessage)
  }

  if (type === COLUMN_LIST_TYPE.PROGRAM_CAMPAIGN_SERVICE_GRID) {
    return columnsConfig3(LocaleMessage)
  }

  return columnsConfig2(LocaleMessage, templateDetail)
}

