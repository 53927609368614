import { getActionType } from '../action-type-generator'

export const GET_TEMPLATE_LIST_VM_REQUEST = 'GET_TEMPLATE_LIST_VM_REQUEST'
export const GET_TEMPLATE_SETUP_VM_REQUEST = 'GET_TEMPLATE_SETUP_VM_REQUEST'
export const ADD_TEMPLATE_DETAIL = getActionType('ADD_TEMPLATE_DETAIL')
export const GET_TEMPLATE_LIST_REQUEST = 'GET_TEMPLATE_LIST_REQUEST'
export const CLONE_TEMPLATE_REQUEST = 'CLONE_TEMPLATE_REQUEST'
export const UPDATE_TEMPLATE_DETAIL_REQUEST = 'UPDATE_TEMPLATE_DETAIL_REQUEST'
export const GET_TEMPLATE_DETAIL_REQUEST = 'GET_TEMPLATE_DETAIL_REQUEST'
export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST'
export const GET_TEMPLATE_DESCRIPTION_REQUEST = 'GET_TEMPLATE_DESCRIPTION_REQUEST'
export const ADD_NEW_TEMPLATE_VERSION_REQUEST = 'ADD_NEW_TEMPLATE_VERSION_REQUEST'
export const GET_TEMPLATE_VERSION_DDL_REQUEST = 'GET_TEMPLATE_VERSION_DDL_REQUEST'
export const GET_TEMPLATE_ACTIVITY_GROUP_LIST_REQUEST = 'GET_TEMPLATE_ACTIVITY_GROUP_LIST_REQUEST'
export const DELETE_TEMPLATE_ACTIVITY_GROUP_REQUEST = 'DELETE_TEMPLATE_ACTIVITY_GROUP_REQUEST'
export const DELETE_ACTIVITY_FROM_TEMPLATE_ACTIVITY_GROUP_REQUEST = 'DELETE_ACTIVITY_FROM_TEMPLATE_ACTIVITY_GROUP_REQUEST'
export const ADD_TEMPLATE_ACTIVITY_GROUP_REQUEST = 'ADD_TEMPLATE_ACTIVITY_GROUP_REQUEST'
export const UPDATE_TEMPLATE_ACTIVITY_GROUP_REQUEST = 'UPDATE_TEMPLATE_ACTIVITY_GROUP_REQUEST'
export const GET_TEMPLATE_ACTIVITY_GROUP_CHILDREN_LIST_REQUEST = 'GET_TEMPLATE_ACTIVITY_GROUP_CHILDREN_LIST_REQUEST'
export const REORDER_TEMPLATE_ACTIVITY_GROUP_REQUEST = 'REORDER_TEMPLATE_ACTIVITY_GROUP_REQUEST'
export const REORDER_TEMPLATE_ACTIVITIES_GROUP_CHILDREN_LIST_REQUEST = 'REORDER_TEMPLATE_ACTIVITIES_GROUP_CHILDREN_LIST_REQUEST'
export const ADD_ACTIVITIES_TO_TEMPLATE_ACTIVITY_GROUP_REQUEST = 'ADD_ACTIVITIES_TO_TEMPLATE_ACTIVITY_GROUP_REQUEST'
export const GET_UNGROUPED_TEMPLATE_ACTIVITIES_REQUEST = 'GET_UNGROUPED_TEMPLATE_ACTIVITIES_REQUEST'

/****************************************************/
/** GET_TEMPLATE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getTemplateListRequest : To get template list
 *
 */
export const getTemplateListRequest = (payload, callback) => {
  return {
    type: GET_TEMPLATE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TEMPLATE_LIST_VM_REQUEST **/
/****************************************************/

/**
 * @method getTemplateListVMRequest : To get dropdowns list for template
 *
 */
export const getTemplateListVMRequest = (callback = () => {}) => {
  return {
    type: GET_TEMPLATE_LIST_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_TEMPLATE_SETUP_VM_REQUEST **/
/****************************************************/

/**
 * @method getTemplateSetupVMRequest : To get dropdowns setup for template
 *
 */
 export const getTemplateSetupVMRequest = (payload, callback = () => {}) => {
  return {
    type: GET_TEMPLATE_SETUP_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_TEMPLATE_DETAIL **/
/****************************************************/

/**
 * @method addTemplateDetailRequest :  To add new template detail 
 *
 */
export const addTemplateDetailRequest = (payload, callback) => {
  return {
    type: ADD_TEMPLATE_DETAIL.REQUEST,
    payload,
    callback
  }
}

/**
 * @method addTemplateDetailSuccess : To handle success of add new template detail 
 *
 */
export const addTemplateDetailSuccess = (data) => {
  return {
    type: ADD_TEMPLATE_DETAIL.SUCCESS,
    data
  }
}

/**
 * @method addTemplateDetailFailure :  To handle failure of add new template detail 
 *
 */
export const addTemplateDetailFailure = (error) => {
  return {
    type: ADD_TEMPLATE_DETAIL.FAILURE,
    error
  }
}

/****************************************************/
/** UPDATE_TEMPLATE_DETAIL **/
/****************************************************/

/**
 * @method updateTemplateDetailRequest :  To update template detail 
 *
 */
export const updateTemplateDetailRequest = (payload, callback) => {
  return {
    type: UPDATE_TEMPLATE_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TEMPLATE_DETAIL **/
/****************************************************/

/**
 * @method getTemplateDetailRequest :  To get template detail 
 *
 */
export const getTemplateDetailRequest = (payload, callback) => {
  return {
    type: GET_TEMPLATE_DETAIL_REQUEST,
    payload,
    callback
  }
}


/****************************************************/
/** DELETE_TEMPLATE **/
/****************************************************/

/**
 * @method deleteTemplateRequest :  To delete template request 
 *
 */
export const deleteTemplateRequest = (payload, callback) => {
  return {
    type: DELETE_TEMPLATE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CLONE_TEMPLATE **/
/****************************************************/

/**
 * @method cloneTemplateRequest :  To clone template request 
 *
 */
 export const cloneTemplateRequest = (payload, callback) => {
  return {
    type: CLONE_TEMPLATE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TEMPLATE_DESCRIPTION **/
/****************************************************/

/**
 * @method getTemplateDescriptionRequest :  To get template description request 
 *
 */
export const getTemplateDescriptionRequest = (payload, callback) => {
  return {
    type: GET_TEMPLATE_DESCRIPTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_NEW_TEMPLATE_VERSION_REQUEST **/
/****************************************************/

/**
 * @method addNewTemplateVersionRequest :  To add new version of template
 *
 */
 export const addNewTemplateVersionRequest = (payload, callback) => {
  return {
    type: ADD_NEW_TEMPLATE_VERSION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TEMPLATE_VERSION_DDL_REQUEST **/
/****************************************************/

/**
 * @method getTemplateVersionDDLRequest :  To get version list of template
 *
 */
 export const getTemplateVersionDDLRequest = (payload, callback) => {
  return {
    type: GET_TEMPLATE_VERSION_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TEMPLATE_ACTIVITY_GROUP_LIST_REQUEST **/
/****************************************************/

/**
 * @method getTemplateActivityGroupListRequest :  To get template activities group list
 *
 */
export const getTemplateActivityGroupListRequest = (payload, callback) => {
  return {
    type: GET_TEMPLATE_ACTIVITY_GROUP_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_TEMPLATE_ACTIVITY_GROUP_REQUEST **/
/****************************************************/

/**
 * @method deleteTemplateActivityGroupRequest :  To delete template activity group 
 *
 */
export const deleteTemplateActivityGroupRequest = (payload, callback) => {
  return {
    type: DELETE_TEMPLATE_ACTIVITY_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_ACTIVITY_FROM_TEMPLATE_ACTIVITY_GROUP_REQUEST **/
/****************************************************/

/**
 * @method deleteActivityFromTemplateActivityGroupRequest :  To delete activity from template activity group 
 *
 */
export const deleteActivityFromTemplateActivityGroupRequest = (payload, callback) => {
  return {
    type: DELETE_ACTIVITY_FROM_TEMPLATE_ACTIVITY_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_TEMPLATE_ACTIVITY_GROUP_REQUEST **/
/****************************************************/

/**
 * @method addTemplateActivityGroupRequest :  To add new activities group for template activities
 *
 */
export const addTemplateActivityGroupRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_TEMPLATE_ACTIVITY_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_TEMPLATE_ACTIVITY_GROUP_REQUEST **/
/****************************************************/

/**
 * @method updateTemplateActivityGroupRequest :  To update  activities group for template activities
 *
 */
export const updateTemplateActivityGroupRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_TEMPLATE_ACTIVITY_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TEMPLATE_ACTIVITY_GROUP_CHILDREN_LIST_REQUEST **/
/****************************************************/

/**
 * @method getTemplateActivityGroupChildrenListRequest :  To get children of template activities group
 *
 */
export const getTemplateActivityGroupChildrenListRequest = (payload, callback) => {
  return {
    type: GET_TEMPLATE_ACTIVITY_GROUP_CHILDREN_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** REORDER_TEMPLATE_ACTIVITY_GROUP_REQUEST **/
/****************************************************/

/**
 * @method reorderTemplateActivityGroupRequest :  To reorder template activities group
 *
 */
export const reorderTemplateActivityGroupRequest = (payload, callback = () => { }) => {
  return {
    type: REORDER_TEMPLATE_ACTIVITY_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** REORDER_TEMPLATE_ACTIVITIES_GROUP_CHILDREN_LIST_REQUEST **/
/****************************************************/

/**
 * @method reorderTemplateActivitiesGroupChildrenListRequest :  To reorder template activity group children list
 *
 */
export const reorderTemplateActivitiesGroupChildrenListRequest = (payload, callback) => {
  return {
    type: REORDER_TEMPLATE_ACTIVITIES_GROUP_CHILDREN_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_ACTIVITIES_TO_TEMPLATE_ACTIVITY_GROUP_REQUEST **/
/****************************************************/

/**
 * @method addActivitiesToTemplateActivityGroupRequest :  To update to add activity to template group 
 *
 */
export const addActivitiesToTemplateActivityGroupRequest = (payload, callback) => {
  return {
    type: ADD_ACTIVITIES_TO_TEMPLATE_ACTIVITY_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_UNGROUPED_TEMPLATE_ACTIVITIES_REQUEST **/
/****************************************************/

/**
 * @method getUngroupedTemplateActivitiesRequest :  To get template activities for the ungrouped section.
 *
 */
export const getUngroupedTemplateActivitiesRequest = (payload, callback) => {
  return {
    type: GET_UNGROUPED_TEMPLATE_ACTIVITIES_REQUEST,
    payload,
    callback
  }
}
