// This Header dropdown is used to show dropdown in table header
// To add item using dropdown options instead of add new buttton in header

import React, { useState, useCallback } from "react"
import { X } from "react-feather"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"

import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"

const HeaderDropdown = ({ intl, config = {} }) => {
  const [showDropdown, setShowDropdown] = useState(false)

  const handleToggleSelect = useCallback((e) => {
    e?.stopPropagation()
    setShowDropdown((prevState) => !prevState)
  }, [])

  return (<div className="table-header-dropdown">
    <Dropdown
      isOpen={showDropdown}
      toggle={handleToggleSelect}
    >
      <DropdownToggle className="pl-2 selected-table-header-dropdown primary-text-btn">
        {getLocaleMessage(intl, config.title || "")}
      </DropdownToggle>
      <DropdownMenu center="true" className="table-header-dropdown-menu">
        <div className="table-header-dropdown-head">
          {getLocaleMessage(intl, config.menuTitle || "")}:
          <X size={15} onClick={handleToggleSelect} />
        </div>
        {(config.options || []).map((item) => {
          return (
            <DropdownItem
              tag="a"
              className="w-100"
              key={`type_${item.text}`}
              onClick={() => config?.onSelect(item.value)}
            >
              <span className="align-middle ml-50">{item.text}</span>
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  </div>)
}

export default localeMessageWrapper(HeaderDropdown)