import { takeLatest, call, put } from "redux-saga/effects"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'

import * as actions from "../../actions"
import { forgotPassword, login, signup, getUserProfile, updateUserProfile } from "../../../services/api"

const { AUTH_SAGA } = LANGUAGE_CONSTANTS

/** signup API **/
function* signupRequest(action) {
  try {
    const response = yield call(signup, action.payload)
    if (response?.data) {
      action.callback(response.data)
      Notification({ status: 200, message: AUTH_SAGA.SIGNUP_SUCCESS })
    } else {
      action.callback(null)
      Notification({ status: 400, message: AUTH_SAGA.FAILED_TO_SIGNUP })
    }
  } catch (error) {
    action.callback(null)
  }
}

/** login API **/
function* loginRequest(action) {
  try {
    const response = yield call(login, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback(response)
      Notification({ status: 400, message: response.error, isLocalizedMessage: false })
    }
  } catch (error) {
    action.callback({ error: true })
    Notification({ status: 400, message: AUTH_SAGA.FAILED_TO_LOGIN })
  }
}

/** forgotPassword API **/
function* forgotPasswordRequest(action) {
  try {
    const response = yield call(forgotPassword, action.payload)
    if (response?.data) {
      action.callback({})
      Notification({ status: 200, message: AUTH_SAGA.WE_VE_JUST_SENT_YOU_AN_EMAIL_TO_RESET_YOUR_PASSWORD })
    } else {
      action.callback({ error: true })
      Notification({ status: 400, message: AUTH_SAGA.FAILED_TO_CHANGE_PASSWORD })
    }
  } catch (error) {
    action.callback({ error: true })
    Notification({ status: 400, message: error.message, isLocalizedMessage: false })
  }
}

/** getUserProfile API **/
function* getUserProfileRequest(action) {
  try {
    const { payload = {}, callback } = action || {}

    const response = yield call(getUserProfile)
    if (response?.data) {
      if (callback && payload.noCacheRequired) {
        callback(response.data)
      } else {
        yield put(actions.getUserProfileSuccess(response.data))
      }
    } else {
      if (!payload.noCacheRequired) {
        yield put(actions.getUserProfileFailure("Unable to load user profile"))
      }
    }
  } catch (error) {
    const { payload = {} } = action || {}
    if (!payload.noCacheRequired) {
      yield put(actions.getUserProfileFailure(error.message))
    }
  }
}

/** updateUserProfile API **/
function* updateUserProfileRequest({ payload, callback = () => { } }) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateUserProfile, payload)
    if (response && response.status === 204) {
      Notification({ status: 200, message: AUTH_SAGA.PROFILE_UPDATED_SUCCESS })
      yield put(actions.getUserProfileRequest())
    } else {
      Notification({ status: 400, message: AUTH_SAGA.UNABLE_TO_UPDATE_USER_DATA })
    }
    yield put(actions.setIsLoading(false))
    callback()
  } catch (error) {
    Notification({ status: 400, isLocalizedMessage: false, message: error.message })
    yield put(actions.setIsLoading(false))
    callback()
  }
}

export function* authSagas() {
  yield takeLatest(actions.FORGOT_PASSWORD.REQUEST, forgotPasswordRequest)
  yield takeLatest(actions.GET_USER_PROFILE.REQUEST, getUserProfileRequest)
  yield takeLatest(actions.LOG_IN.REQUEST, loginRequest)
  yield takeLatest(actions.SIGN_UP.REQUEST, signupRequest)
  yield takeLatest(actions.UPDATE_USER_PROFILE_REQUEST, updateUserProfileRequest)
}

export default authSagas
