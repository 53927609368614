// app settings
import AppSettings from '@app-settings'
const { REACT_APP_AUTH0_AUDIENCE_API, REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DATABASE_CONNECTION_NAME } = AppSettings

export const login = (data) => {
  return new Promise((resolve) => {
    try {
      const auth0Client = new auth0.WebAuth({
        domain: REACT_APP_AUTH0_DOMAIN,
        clientID: REACT_APP_AUTH0_CLIENT_ID,
        redirectUri: window.location.origin,
        audience: REACT_APP_AUTH0_AUDIENCE_API,
        scope: "openid profile email",
        responseType: 'token id_token'
      })

      auth0Client.client.login({
        realm: REACT_APP_AUTH0_DATABASE_CONNECTION_NAME,
        username: data.email,
        password: data.password
      }, (err, authResult) => {
        if (err) {
          resolve({ success: false, error: err.description })
          return
        }
        if (authResult) {
          auth0Client.client.userInfo(authResult.accessToken, (error, user) => {
            if (error) {
              resolve({ success: false, error: error.description })
              return
            }

            if (user) {
              resolve({ success: true, data: { ...user, accessToken: authResult.accessToken, refreshToken: authResult.accessToken } })
            }
          })
        }
      })
    } catch (err) {
      resolve({ success: false, error: err.description })
    }
  })
}

export default login
