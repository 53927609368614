export const onGridBodyScroll = () => {
  //Note: To close row menu if opened , on grid scroll
  const element = [...document.getElementsByClassName("grid-row-actions-dropdown")]
  if (element?.length) {
   const elementIndex = element.findIndex(x => x.className.includes('show'))
   if (elementIndex !== -1) {
    window.dispatchEvent(
      new CustomEvent('onGridBodyScroll', {
        isScrollStart: true
      })
    )
   }
  }
}
