import { DASHBOARD_PAGES_TYPES, REPORT_BOOL_TYPE } from "@views/dashboard/config"
import { getSearchQuery } from "../config"

const getQueryPayload_1 = ({
  filterPage,
  campaignId,
  programId,
  projectId,
  siteId,
  contactName,
  pageNumber,
  pageSize,
  sitePartyRoleName
}) => {
  return [
    getSearchQuery("PageNumber", pageNumber, true),
    getSearchQuery("PageSize", pageSize),
    getSearchQuery("ContactName", contactName, false, true),
    getSearchQuery("FilterPage", filterPage),
    getSearchQuery("CampaignId", campaignId),
    getSearchQuery("ProgramId", programId),
    getSearchQuery("SiteId", siteId),
    getSearchQuery("ProjectId", projectId),
    getSearchQuery("SitePartyRoleName", sitePartyRoleName)
  ].join('')
}

const getQueryPayload_2 = ({ organizationId = 0, campaignId = 0, siteId = 0, projectId = 0, programId = 0, entityId = 0, workflowId = 0, taskId = 0, unitId = 0 }) => {
  return [
    getSearchQuery("OrganizationId", organizationId),
    getSearchQuery("CampaignId", campaignId),
    getSearchQuery("ProgramId", programId),
    getSearchQuery("SiteId", siteId),
    getSearchQuery("ProjectId", projectId),
    getSearchQuery("EntityId", entityId),
    getSearchQuery("WorkflowId", workflowId),
    getSearchQuery("TaskId", taskId),
    getSearchQuery("UnitId", unitId)
  ].join('').replace('&', '?')
}

const apiConfig = {
  getPowerBiAccessToken: {
    method: "GET",
    url: (reportId) => `api/Common/powerbi/token/${reportId}`
  },
  getReportList: {
    method: "GET",
    url: (pageName) => `api/Report/GetReportList/${pageName}`
  },
  getUnacceptableActivitiesReport: {
    method: "GET",
    url: ({
      campaignId,
      programId,
      entityId,
      siteId,
      pageNumber = 1,
      pageSize = 20,
      orderColumn,
      orderDirections,
      genericSearch,
      advanceSearch = {}
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("Campaign", campaignId),
        getSearchQuery("Program", programId),
        getSearchQuery("Site", siteId),
        getSearchQuery("Entity", entityId),
        getSearchQuery("ActivityName", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          organizations,
          programs,
          campaigns,
          site,
          assignee,
          riskLevel,
          activityStatus,
          startDate,
          endDate,
          unit,
          responsibleTrade,
          workflow,
          task,
          activity,
          tagIds
        } = advanceSearch

        const organizationSearch = organizations.map((x) => getSearchQuery("Organization", x.organizationId)).join("")
        const programSearch = programs.map((id) => getSearchQuery("Program", id)).join("")
        const campaignSearch = campaigns.map((id) => getSearchQuery("Campaign", id)).join("")
        const siteSearch = site.map((id) => getSearchQuery("Site", id)).join("")
        const assigneeSearch = assignee.map((id) => getSearchQuery("Assignee", id)).join("")
        const riskLevelSearch = riskLevel.map((level) => getSearchQuery("RiskLevel", level)).join("")
        const activityStatusSearch = getSearchQuery("ActivityStatus", activityStatus)
        const responsibleTradeSearch = responsibleTrade.map((trade) => getSearchQuery("Trade", trade)).join("")
        const unitIds = unit.map((x) => getSearchQuery("Unit", x)).join("")
        const workflowIds = workflow.map((wf) => getSearchQuery("Workflow", wf)).join("")
        const taskNameIds = task.map((x) => getSearchQuery("Task", x)).join("")
        const tagList = tagIds.map((x) => getSearchQuery("Tags", x)).join("")
        
        searchString = [
          searchString,
          organizationSearch,
          programSearch,
          campaignSearch,
          siteSearch,
          assigneeSearch,
          riskLevelSearch,
          activityStatusSearch,
          responsibleTradeSearch,
          unitIds,
          workflowIds,
          taskNameIds,
          tagList,
          getSearchQuery("ActivityName", activity, false, true),
          getSearchQuery("StartDate", startDate),
          getSearchQuery("EndDate", endDate)          
        ].join('')
      }

      return `api/Report/UnacceptableActivities${searchString}`
    }
  },
  getUnapprovedTrainingReport: {
    method: "GET",
    url: ({
      campaignId,
      projectId,
      programId,
      entityId,
      workflowId,
      taskId,
      siteId,
      pageNumber = 1,
      pageSize = 20,
      orderColumn,
      orderDirections,
      genericSearch,
      advanceSearch = {}
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("Campaign", campaignId),
        getSearchQuery("Project", projectId),
        getSearchQuery("Program", programId),
        getSearchQuery("Site", siteId),
        getSearchQuery("Entity", entityId),
        getSearchQuery("Workflow", workflowId),
        getSearchQuery("Task", taskId),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')
      if (!!Object.keys(advanceSearch).length) {
        const {
          chapter,
          lessonName,
          subject,
          startDate,
          endDate,
          unit,
          organizations,
          campaigns,
          programs,
          sites,
          trade,
          course,
          overdue,
          pass,
          fail,
          tagIds
        } = advanceSearch

        const organizationSearch = organizations.map((x) => getSearchQuery("Organization", x.organizationId)).join("")
        const siteSearch = sites.map((id) => getSearchQuery("Site", id)).join("")
        const programSearch = programs.map((id) => getSearchQuery("Program", id)).join("")
        const campaignSearch = campaigns.map((id) => getSearchQuery("Campaign", id)).join("")
        const responsibleTradeSearch = trade.map((id) => getSearchQuery("Trade", id)).join("")
        const courseIds = course.map((value) => getSearchQuery("Course", value)).join("")
        const subjectIds = subject.map((value) => getSearchQuery("Subject", value)).join("")
        const chapterIds = chapter.map((value) => getSearchQuery("Chapter", value)).join("")
        const lessonIds = lessonName.map((value) => getSearchQuery("Lesson", value)).join("")
        const overdueType = overdue ? 1 : ''
        const passType = pass ? 1 : ''
        const failType = fail ? 1 : ''
        const tagList = tagIds.map((x) => getSearchQuery("Tags", x)).join("")
        searchString = [
          searchString,
          organizationSearch,
          siteSearch,
          programSearch,
          campaignSearch,
          responsibleTradeSearch,
          courseIds,
          lessonIds,
          subjectIds,
          chapterIds,
          getSearchQuery("DaysOverdue", overdueType),
          getSearchQuery("Pass", passType),
          getSearchQuery("Fail", failType),
          getSearchQuery("StartDate", startDate),
          getSearchQuery("EndDate", endDate),
          getSearchQuery("Unit", unit, false, true),
          tagList
        ].join('')
      }

      return `api/Report/UnapprovedTraining${searchString}`
    }
  },
  getDailyInspectionReport: {
    method: "GET",
    url: ({
      campaignId,
      programId,
      entityId,
      workflowId,
      taskId,
      siteId,
      organizationId,
      riskLevel,
      activityStatus,
      trade,
      pageNumber,
      pageSize,
      orderColumn,
      orderDirections,
      genericSearch,
      advanceSearch = {}
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("Campaign", campaignId),
        getSearchQuery("Program", programId),
        getSearchQuery("Site", siteId),
        getSearchQuery("Entity", entityId),
        getSearchQuery("Workflow", workflowId),
        getSearchQuery("Task", taskId),
        getSearchQuery("RiskLevel", riskLevel),
        getSearchQuery("ActivityStatus", activityStatus),
        getSearchQuery("Trade", trade),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        getSearchQuery("Organization", organizationId)
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          organizations,
          sites,
          assignee,
          unit,
          startDate,
          endDate,
          tagIds,
          status
        } = advanceSearch

        const organizationSearch = organizations.map((x) => getSearchQuery("Organization", x.organizationId)).join("")
        const assigneeSearch = assignee.map((item) => getSearchQuery("Assignee", item)).join("")
        const siteSearch = sites.map((id) => getSearchQuery("Site", id)).join("")
        const tagList = tagIds.map((tag) => getSearchQuery("Tags", tag)).join("")
        const unitList = unit.map((item) => getSearchQuery("Unit", item)).join("")
        const statusList = status.map((item) => getSearchQuery("SimplifiedTaskStatus", item)).join("")

        searchString = [
          searchString,
          organizationSearch,
          siteSearch,
          assigneeSearch,
          tagList,
          getSearchQuery("StartDate", startDate),
          getSearchQuery("EndDate", endDate),
          unitList,
          statusList
        ].join('')
      }

      return `api/Report/DailyInspection${searchString}`
    }
  },
  getUnacceptableActivitiesVM: {
    method: 'GET',
    url: "api/Report/UnacceptableActivities/VM"
  },
  getUnacceptableAssignedContactList: {
    method: 'GET',
    url: (params) => {
      return `api/Report/UnacceptableActivities/GetAssignedContactsDDL${getQueryPayload_1(params)}`
    }
  },
  getUnacceptableActivitiesSitePartyRoleDDL: {
    method: 'GET',
    url: (params) => {
      return `api/Report/UnacceptableActivities/SitePartyRoleDDL${getQueryPayload_1(params)}`
    }
  },
  getReportsSiteDDL: {
    method: 'GET',
    url: ({
      siteName,
      organizationId,
      organizations = [],
      ...params
    }) => {

      const organizationList = organizations.map((org) => getSearchQuery("OrganizationIds", org.organizationId)).join("")
      let searchString = getQueryPayload_1(params)
      searchString = [
        searchString,
        getSearchQuery("SiteName", siteName, false, true),
        getSearchQuery("OrganizationIds", organizationId),
        organizationList
      ].join('')

      return `api/Report/UnacceptableActivities/SiteDDL/${filterPage}${searchString}`
    }
  },
  getTrainingActivitiesIncomplete: {
    method: 'GET',
    url: (params) => {
      return `api/Report/widget/TrainingActivitiesIncomplete${getQueryPayload_2(params)}`
    }
  },
  getOpenUnacceptableActivities: {
    method: 'GET',
    url: (params) => {
      return `api/Report/widget/OpenUnacceptableActivities${getQueryPayload_2(params)}`
    }
  },
  getSaturateRateSummary: {
    method: 'GET',
    url: ({
      campaignId,
      programId,
      projectId,
      organizationId,
      workflowId,
      siteId,
      pageNumber,
      pageSize,
      orderColumn,
      orderDirections,
      genericSearch,
      advanceSearch = {}
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("CampaignIds", campaignId),
        getSearchQuery("ProgramId", programId),
        getSearchQuery("SiteIds", siteId),
        getSearchQuery("ProjectId", projectId),
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("WorkFlowId", workflowId),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          campaignId,
          siteId,
          taskIds,
          unitIds,
          workflowId,
          programId,
          organizationId,
          tagIds
        } = advanceSearch
        const taskIDList = taskIds.map((task) => getSearchQuery("TaskIds", task.value)).join("")
        const unitIDList = unitIds.map((unit) => getSearchQuery("UnitIds", unit.value)).join("")
        const tagList = tagIds.map((tag) => getSearchQuery("Tags", tag)).join("")

        searchString = [
          searchString,
          getSearchQuery("CampaignIds", campaignId),
          getSearchQuery("SiteIds", siteId),
          getSearchQuery("ProgramId", programId),
          getSearchQuery("OrganizationId", organizationId),
          getSearchQuery("WorkFlowId", workflowId),
          taskIDList,
          unitIDList,
          tagList
        ].join('')
      }

      return `api/Report/SaturationRateSummary${searchString}`
    }
  },
  getSaturationDetailsSummary: {
    method: 'GET',
    url: ({
      campaignId,
      programId,
      projectId,
      organizationId,
      workflowId,
      siteId,
      pageNumber,
      pageSize,
      orderColumn,
      orderDirections,
      genericSearch,
      advanceSearch = {}
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("CampaignId", campaignId),
        getSearchQuery("ProgramId", programId),
        getSearchQuery("SiteId", siteId),
        getSearchQuery("ProjectId", projectId),
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("WorkFlowId", workflowId),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          campaignId,
          siteId,
          taskIds,
          unitIds,
          workflowId,
          programId,
          organizationId,
          tagIds
        } = advanceSearch
        const taskIDList = taskIds.map((task) => getSearchQuery("TaskIds", task.value)).join("")
        const unitIDList = unitIds.map((unit) => getSearchQuery("UnitIds", unit.value)).join("")
        const tagList = tagIds.map((tag) => getSearchQuery("Tags", tag)).join("")

        searchString = [
          searchString,
          getSearchQuery("CampaignId", campaignId),
          getSearchQuery("SiteId", siteId),
          getSearchQuery("ProgramId", programId),
          getSearchQuery("OrganizationId", organizationId),
          getSearchQuery("WorkFlowId", workflowId),
          taskIDList,
          unitIDList,
          tagList
        ].join('')
      }

      return `api/Report/SaturationRateDetail${searchString}`
    }
  },
  getExportReportDocument: {
    method: 'POST',
    url: `api/Report/GetExportDocumentDetails`
  },
  getTrainingActivitiesFailed: {
    method: 'GET',
    url: (params) => {
      return `api/Report/widget/FailedTraining${getQueryPayload_2(params)}`
    }
  },
  getTrainingActivitiesStatus: {
    method: 'GET',
    url: (params) => {
      return `api/Report/widget/TrainingStatus${getQueryPayload_2(params)}`
    }
  },
  getTrainingCampaignReport: {
    method: 'GET',
    url: ({
      campaignId,
      programId,
      entityId,
      siteId,
      pageNumber = 1,
      pageSize = 20,
      orderColumn,
      orderDirections,
      genericSearch,
      advanceSearch = {}
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("Campaign", campaignId),
        getSearchQuery("Program", programId),
        getSearchQuery("Site", siteId),
        getSearchQuery("Entity", entityId),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          organizations,
          programs,
          campaigns,
          sites,
          assignee,
          activityStatus,
          startDate,
          endDate,
          chapter,
          lessonName,
          subject,
          overdue,
          pass,
          fail,
          course,
          tagIds
        } = advanceSearch

        const organizationSearch = organizations.map((x) => getSearchQuery("Organization", x.organizationId)).join("")
        const programSearch = programs.map((id) => getSearchQuery("Program", id)).join("")
        const campaignSearch = campaigns.map((id) => getSearchQuery("Campaign", id)).join("")
        const siteSearch = sites.map((id) => getSearchQuery("Site", id)).join("")
        const assigneeSearch = assignee.map((id) => getSearchQuery("Assignee", id)).join("")
        const activityStatusSearch = activityStatus.map((status) => getSearchQuery("Status", status)).join("")
        const courseIds = course.map((value) => getSearchQuery("Course", value)).join("")
        const subjectIds = subject.map((value) => getSearchQuery("Subject", value)).join("")
        const chapterIds = chapter.map((value) => getSearchQuery("Chapter", value)).join("")
        const lessonIds = lessonName.map((value) => getSearchQuery("Lesson", value)).join("")
        const overdueType = overdue ? 1 : ''
        const passType = pass ? 1 : ''
        const failType = fail ? 1 : ''
        const tagList = tagIds.map((tag) => getSearchQuery("Tags", tag)).join("")

        searchString = [
          searchString,
          organizationSearch,
          programSearch,
          campaignSearch,
          siteSearch,
          assigneeSearch,
          courseIds,
          subjectIds,
          chapterIds,
          lessonIds,
          activityStatusSearch,
          getSearchQuery("StartDate", startDate),
          getSearchQuery("EndDate", endDate),
          getSearchQuery("DaysOverdue", overdueType),
          getSearchQuery("Pass", passType),
          getSearchQuery("Fail", failType),
          tagList
        ].join('')
      }
      return `api/Report/TrainingCampaignReport${searchString}`
    }
  },
  getCompletedUnitsWidget: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/Presentation/CompletedUnitsWidget${getQueryPayload_2(params)}`
    }
  },
  getWarrantyCostEstimateWidget: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/ZondaAligned/WarrantyCostEsitmateWidget${getQueryPayload_2(params)}`
    }
  },
  getWarrantyClaimEstimateWidget: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/ZondaAligned/WarrantyClaimEsitmateWidget${getQueryPayload_2(params)}`
    }
  },
  getConstructionCallbacksCostsWidget: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/ZondaAligned/ConstructionCallbacksCostsWidget${getQueryPayload_2(params)}`
    }
  },
  getProductionCycleTimeWidget: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/ZondaAligned/ProductionCycleTimeWidget${getQueryPayload_2(params)}`
    }
  },
  getConstructionCallbacksWidget: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/Presentation/ConstructionCallbacksWidget${getQueryPayload_2(params)}`
    }
  },
  getReportDetail: {
    method: 'GET',
    url: (reportId) => `api/Report/GetReportDetail/${reportId}`
  },
  getWorkflowDDL: {
    method: 'GET',
    url: ({
      workflowName,
      pageNumber,
      pageSize,
      campaignId,
      isDistinct
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("WorkflowName", workflowName, false, true),
        getSearchQuery("CampaignIds", campaignId)
      ].join('')

      if (isDistinct) {
        return `api/Campaign/DDL/DistinctWorkFlowList${searchString}`
      }

      return `api/Campaign/DDL/WorkFlowList${searchString}`
    }
  },
  getTaskListDDL: {
    method: 'GET',
    url: ({
      taskName,
      pageNumber,
      pageSize
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("TaskName", taskName, false, true)
      ].join('')

      return `api/Campaign/DDL/TaskList${searchString}`
    }
  },
  getUnitCloseout: {
    method: 'GET',
    url: ({
      campaignId,
      programId,
      projectId,
      siteId,
      organizationId,
      orderColumn,
      orderDirections,
      genericSearch,
      pageNumber,
      pageSize,
      advanceSearch = {}
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        getSearchQuery("Campaign", campaignId),
        getSearchQuery("Program", programId),
        getSearchQuery("Project", projectId),
        getSearchQuery("Site", siteId),
        getSearchQuery("Organization", organizationId)
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          organizations,
          programs,
          sites,
          units,
          campaigns,
          workflows,
          tagIds
        } = advanceSearch

        const orgIds = organizations.map((x) => getSearchQuery("Organization", x.organizationId)).join("")
        const programIds = programs.map((id) => getSearchQuery("Program", id)).join("")
        const siteIds = sites.map((id) => getSearchQuery("Site", id)).join("")
        const unitIds = units.map((id) => getSearchQuery("Unit", id)).join("")
        const campaignIds = campaigns.map((id) => getSearchQuery("Campaign", id)).join("")
        const workflowIds = workflows.map((id) => getSearchQuery("Workflow", id)).join("")
        const tagList = tagIds.map((tag) => getSearchQuery("Tags", tag)).join("")

        searchString = [
          searchString,
          orgIds,
          programIds,
          siteIds,
          unitIds,
          campaignIds,
          workflowIds,
          tagList
        ].join('')
      }

      return `api/Report/UnitCloseoutReport${searchString}`
    }
  },
  getTradePerformanceReport: {
    method: 'GET',
    url: ({
      programId,
      siteId,
      genericSearch,
      pageNumber,
      pageSize,
      orderColumn,
      orderDirections,
      language,
      advanceSearch = {}
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        getSearchQuery("Language", language),
        getSearchQuery("Program", programId),
        getSearchQuery("Site", siteId)
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          organizations,
          programs,
          sites,
          tagIds
        } = advanceSearch

        const orgIds = organizations.map((x) => getSearchQuery("Organization", x.organizationId)).join("")
        const programIds = programs.map((id) => getSearchQuery("Program", id)).join("")
        const siteIds = sites.map((id) => getSearchQuery("Site", id)).join("")
        const tagList = tagIds.map((tag) => getSearchQuery("Tags", tag)).join("")

        searchString = [
          searchString,
          orgIds,
          programIds,
          siteIds,
          tagList
        ].join('')
      }

      return `api/Report/TradePerformanceReport${searchString}`
    }
  },
  getNewObservationCampaignWidget: {
    method: 'GET',
    url: "api/Report/widget/NewObservationCampaigns"
  },
  getUnaprovedCampaignsWidget: {
    method: 'GET',
    url: "api/Report/widget/unapprovedActivities"
  },
  getTrainingActivitiesWidget: {
    method: 'GET',
    url: "api/Report/widget/trainingActivities"
  },
  getActivityTrainingSubjectDDL: {
    method: "GET",
    url: ({
      subject,
      pageNumber,
      pageSize,
      language
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("Subject", subject, false, true),
        getSearchQuery("Langauge", language)
      ].join('')
      return `api/Training/DDL/ActivityTrainingSubject${searchString}`
    }
  },
  getActivityTrainingChapterDDL: {
    method: "GET",
    url: ({
      chapter,
      pageNumber,
      pageSize,
      language
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("Chapter", chapter, false, true),
        getSearchQuery("Langauge", language)
      ].join('')
      return `api/Training/DDL/ActivityTrainingChapter${searchString}`
    }
  },
  getActivityTrainingCourseDDL: {
    method: "GET",
    url: ({
      course,
      pageNumber,
      pageSize,
      language
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("Course", course, false, true),
        getSearchQuery("Langauge", language)
      ].join('')
      return `api/Training/DDL/ActivityTrainingCourse${searchString}`
    }
  },
  getActivityTrainingLessonDDL: {
    method: "GET",
    url: ({
      lesson,
      pageNumber,
      pageSize,
      language
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("Lesson", lesson, false, true),
        getSearchQuery("Langauge", language)
      ].join('')
      return `api/Training/DDL/ActivityTrainingLesson${searchString}`
    }
  },
  getTrainingCampaignVM: {
    method: "GET",
    url: "api/Report/TrainingCampaign/VM"
  },
  getEnergystarUnitsOpenWidget: {
    method: 'GET',
    url: ({ programId }) => `api/Report/Widget/Energy/UnitsOpen?ProgramId=${programId}`
  },
  getEnergystarUnitsCertifiedWidget: {
    method: 'GET',
    url: ({ programId }) => `api/Report/Widget/Energy/UnitsCertified?ProgramId=${programId}`
  },
  getEnergystarUnitsFailedWidget: {
    method: 'GET',
    url: ({ programId }) => `api/Report/Widget/Energy/UnitsFailed?ProgramId=${programId}`
  },
  getEnergystarTaxIncentiveWidget: {
    method: 'GET',
    url: ({ programId }) => `api/Report/Widget/Energy/EstCertifiedDollars?ProgramId=${programId}`
  },
  getQualityScoreWidget: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/CorePlatform/QualityScore${getQueryPayload_2(params)}`
    }
  },
  getResourceCenterExportDocument: {
    method: 'POST',
    url: `api/ResourceCenter/ExportDocument`
  },
  getAverageFailedActivitiesWidget: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/CorePlatform/AvgFailedActivities${getQueryPayload_2(params)}`
    }
  },
  getAverageCycleTimeWidget: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/CorePlatform/AvgActivityCycleTime${getQueryPayload_2(params)}`
    }
  },
  getCampaignTaskStatusReport: {
    method: 'GET',
    url: ({
      campaignId,
      siteId,
      orderColumn,
      orderDirections,
      genericSearch,
      pageNumber,
      pageSize,
      advanceSearch = {}
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("CampaignId", campaignId),
        getSearchQuery("SiteId", siteId),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          units = [],
          workflows = [],
          status = [],
          startDate,
          endDate,
          simplifiedStatus = []
        } = advanceSearch

        const statusTypes = status.map((id) => getSearchQuery("StatusType", id)).join("")
        const unitIds = units.map((id) => getSearchQuery("SiteUnitId", id)).join("")
        const workflowIds = workflows.map((id) => getSearchQuery("WFCampaignSetupId", id)).join("")
        const simplifiedStatusTypes = simplifiedStatus.map((id) => getSearchQuery("TaskSimplifiedStatusIds", id)).join("")

        searchString = [
          searchString,
          statusTypes,
          unitIds,
          workflowIds,
          simplifiedStatusTypes,
          getSearchQuery("StartDate", startDate),
          getSearchQuery("EndDate", endDate)
        ].join('')
      }
      return `api/Report/CampaignTaskStatusReport${searchString}`
    }
  },
  getCampaignTaskStatusReportVM: {
    method: "GET",
    url: "api/Campaign/CampaignTaskStatusReportVM"
  },
  getTaskSummaryVM: {
    method: 'GET',
    url: "api/Report/TaskSummaryReport/VM"
  },
  getUnitStatusReport: {
    method: "GET",
    url: ({
      pageNumber,
      pageSize,
      genericSearch,
      orderColumn,
      orderDirections,
      siteUnitId
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        getSearchQuery("SiteUnitID", siteUnitId)
      ].join('')
      return `api/Report/UnitStatusReport${searchString}`
    }
  },
  sendReportEmail: {
    method: 'POST',
    url: (guid) => `api/Report/SendReportEmail/${guid}`
  },
  getReportFileStatus: {
    method: 'GET',
    url: (fileName) => `api/ResourceCenter/CheckFileStatus/${fileName}`
  },
  getReportDashboardsList: {
    method: "GET",
    url: ({
      dashboardsReportsType,
      pageNumber,
      pageSize,
      genericSearch = '',
      advanceSearch = {},
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')  

      if (![DASHBOARD_PAGES_TYPES.PROVIDERSHIP, DASHBOARD_PAGES_TYPES.REPORT_PROCESSOR, DASHBOARD_PAGES_TYPES.WORK_ORDER, DASHBOARD_PAGES_TYPES.PERFORMANCE].includes(dashboardsReportsType)) {
        searchString = [
          searchString,
          getSearchQuery("DashboardsReportsEnum", dashboardsReportsType)
        ].join('')
      }

      let isPriority = ''
      let isMissingPlan = ''
      let isMissingChecklists = ''
      let isMissingDate = ''
      let isPassFail = ''
      let isDuxInspection = ''

      if (!!Object.keys(advanceSearch).length) {
        const {
          parentOrganizationId,
          rater,
          streetAddress,
          registeredOnStartDate,
          registeredOnEndDate,
          submittedOnStartDate,
          submittedOnEndDate,
          organization,
          site,
          unit,
          missingPlan,
          missingChecklist,
          priority,
          assignee,
          task,
          planReview,
          fileProcess,
          assignedOnStart,
          assignedOnEnd,
          finalInspectionOnStart,
          finalInspectionOnEnd,
          missingDate,
          statusId,
          division,
          service,
          passFail,
          startDate,
          endDate,
          receivedStartDate,
          receivedEndDate,
          builderType,
          duxInspection,
          inspectionType,
          stateInspection,
          municipality,
          permitNumber,
          city,
          workOrderSite,
          inspectorId,
          state,
          region,
          subRegion,
          county,
          energyProgram,
          workOrderStatus = []
        } = advanceSearch

        isPriority = priority ? priority === REPORT_BOOL_TYPE.YES : ''
        isMissingPlan = missingPlan ? missingPlan === REPORT_BOOL_TYPE.YES : ''
        isMissingChecklists = missingChecklist ? missingChecklist === REPORT_BOOL_TYPE.YES : ''
        isMissingDate = missingDate ? missingDate === REPORT_BOOL_TYPE.YES : ''
        isPassFail = passFail ? passFail === REPORT_BOOL_TYPE.YES : ''
        isDuxInspection = duxInspection ? duxInspection === REPORT_BOOL_TYPE.YES : ''

        const workOrderStatusList = workOrderStatus.map((status) => status.value).join("")
        const passFailQuery = dashboardsReportsType === DASHBOARD_PAGES_TYPES.WORK_ORDER ? "IsWorkOrderPassFail" : "IsPerformancePassFail"
        const serviceQuery = dashboardsReportsType === DASHBOARD_PAGES_TYPES.WORK_ORDER ? "DashboardPagesWorkOrderServiceId" : "DashboardPagesPerformanceServiceId"
        searchString = [
          searchString,
          getSearchQuery("OrganizationIds", organization),
          getSearchQuery("SiteIds", site),
          getSearchQuery("SiteUnitIds", unit),
          getSearchQuery("AssigneeContactIds", assignee),
          getSearchQuery("DashboardPagesTaskTypeId", task),
          getSearchQuery("DashboardPagesPlanReviewStatusIds", planReview),
          getSearchQuery("DashboardPagesFileProcessStatusIds", fileProcess),
          getSearchQuery("AssignedOnStartDate", assignedOnStart),
          getSearchQuery("AssignedOnEndDate", assignedOnEnd),
          getSearchQuery("FinalInspectionOnStartDate", finalInspectionOnStart),
          getSearchQuery("FinalInspectionOnEndDate", finalInspectionOnEnd),
          getSearchQuery("RaterId", rater),
          getSearchQuery("Address", streetAddress, false, true),
          getSearchQuery("ParentOrganizationId", parentOrganizationId),
          getSearchQuery("RegisteredOnDateStart", registeredOnStartDate),
          getSearchQuery("RegisteredOnDateEnd", registeredOnEndDate),
          getSearchQuery("SubmittedOnDateStart", submittedOnStartDate),
          getSearchQuery("SubmittedOnDateEnd", submittedOnEndDate),
          getSearchQuery("DashboardPagesProvidershipStatusIds", statusId),
          getSearchQuery("CampaignTagId", division),
          getSearchQuery(`${serviceQuery}`, service),
          getSearchQuery("StartDate", startDate),
          getSearchQuery("EndDate", endDate),
          getSearchQuery("ReceivedStartDate", receivedStartDate),
          getSearchQuery("ReceivedEndDate", receivedEndDate),
          getSearchQuery("DashboardPagesWorkOrderStateInspectionId", stateInspection),
          getSearchQuery("InspectionTypeId", inspectionType),
          getSearchQuery("UnitTypeId", builderType),
          getSearchQuery("City", city, false, true),
          getSearchQuery("Site", workOrderSite),
          getSearchQuery("Municipality", municipality),
          getSearchQuery("PermitNumber", permitNumber),
          getSearchQuery("InspectorId", inspectorId),
          getSearchQuery("State", state),
          getSearchQuery("RegionId", region),
          getSearchQuery("SubRegionId", subRegion),
          getSearchQuery("County", county),
          isPriority !== '' ? `&IsPriority=${isPriority}` : '',
          isMissingPlan !== '' ? `&isMissingPlan=${isMissingPlan}` : '',
          isMissingChecklists !== '' ? `&isMissingChecklists=${isMissingChecklists}` : '',
          isMissingDate !== '' ? `&isMissingDate=${isMissingDate}` : '',
          isPassFail !== '' ? `&${passFailQuery}=${isPassFail}` : '',
          isDuxInspection !== '' ? `&IsWorkOrderDUXInspection=${isDuxInspection}` : '',
          getSearchQuery("DashboardPagesReportProEnergyProgramId", energyProgram),
          getSearchQuery("DashboardPagesWorkOrderStatusIds", workOrderStatusList)
        ].join('')
      }

      if ([DASHBOARD_PAGES_TYPES.PLAN_REVIEW_LEAD, DASHBOARD_PAGES_TYPES.PLAN_REVIEW].includes(dashboardsReportsType)) {
        return `api/Report/Dashboards/PlanReview${searchString}`
      }

      if (dashboardsReportsType === DASHBOARD_PAGES_TYPES.PROVIDERSHIP) {
        return `api/Report/Dashboards/Providership${searchString}`
      }

      if (dashboardsReportsType === DASHBOARD_PAGES_TYPES.REPORT_PROCESSOR) {
        return `api/Report/Dashboards/ReportProcessor${searchString}`
      }

      if (dashboardsReportsType === DASHBOARD_PAGES_TYPES.WORK_ORDER) {
        return `api/Report/Dashboards/WorkOrder${searchString}`
      }

      if (dashboardsReportsType === DASHBOARD_PAGES_TYPES.PERFORMANCE) {
        return `api/Report/Dashboards/Performance${searchString}`
      }

      return `api/Report/Dashboards/PlanReviewFileProcess${searchString}`
    }
  },
  getDashboardWidgets: {
    method: "GET",
    url: ({
      dashboardType,
      widgetType
    }) => {
      let searchString = ''
      const { PLAN_REVIEW_LEAD, PLAN_REVIEW, FILE_PROCESS_LEAD, FILE_PROCESS, PROVIDERSHIP } = DASHBOARD_PAGES_TYPES

      if (dashboardType === PROVIDERSHIP) {
        return `api/Report/Dashboards/Widget/Providership${searchString}`
      }

      if ([PLAN_REVIEW_LEAD, PLAN_REVIEW].includes(dashboardType)) {
        searchString = getSearchQuery(
          "DashboardsWidgetReportsPlanReviewerEnum",
          widgetType,
          true
        )

        return `api/Report/Dashboards/Widget/PlanReview${searchString}`
      }

      if ([FILE_PROCESS_LEAD, FILE_PROCESS].includes(dashboardType)) {
        searchString = getSearchQuery(
          "DashboardsWidgetReportsFileProcessorEnum",
          widgetType,
          true
        )
        return `api/Report/Dashboards/Widget/FileProcess${searchString}`
      }
    }
  },
  getReportDashboardsVM: {
    method: 'GET',
    url: 'api/Report/Dashboards/VM'
  },
  updatePriorityAndStatusColumn: {
    method: 'PUT',
    url: ({
      isPriority = false,
      siteUnitId
    }) => {
      if (isPriority) {
        return `api/Report/TogglePriority/${siteUnitId}`
      } 
      return `api/Report/TogglePlanRequested/${siteUnitId}`
    }
  },
  toggleAuditRandomPercent: {
    method: 'PUT',
    url: (siteUnitId) => `api/Report/ToggleAuditRandom10Percent/${siteUnitId}`
  },
  toggleAuditWithCause: {
    method: 'PUT',
    url: (siteUnitId) => `api/Report/ToggleAuditWithCause/${siteUnitId}`
  },
  getDashboardSiteDDL: {
    method: 'GET',
    url: ({
      siteName,
      pageNumber,
      pageSize,
      organizationId
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("SiteName", siteName, false, true),
        getSearchQuery("OrganizationIds", organizationId)
      ].join('')
      return `api/Report/Dashboards/Site/DDL${searchString}`
    }
  },
  getDashboardOrganizationDDL: {
    method: 'GET',
    url: ({
      organizationName,
      pageNumber,
      pageSize
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("OrganizationName", organizationName, false, true)
      ].join('')
      return `api/Report/Dashboards/Organization/DDL${searchString}`
    }
  },
  getExceptionDashboardList: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      genericSearch = '',
      advanceSearch = {},
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

    if (!!Object.keys(advanceSearch).length) {
      const {
        organization,
        site,
        unit,
        task,
        reason,
        market,
        assignee,
        reasonStartDate,
        reasonEndDate
      } = advanceSearch
      searchString = [
        searchString,
        getSearchQuery("OrganizationIds", organization),
        getSearchQuery("SiteIds", site),
        getSearchQuery("SiteUnitIds", unit),
        getSearchQuery("ObservationTaskIds", task),
        getSearchQuery("MarketIds", market),
        getSearchQuery("AssigneeIds", assignee),
        getSearchQuery("ReasonIds", reason),
        getSearchQuery("ReasonDateStart", reasonStartDate),
        getSearchQuery("ReasonDateEnd", reasonEndDate)
      ].join('')
    }
    return `api/Report/Dashboards/Exceptions${searchString}`
    }
  },
  updateSchedulingException: {
    method: 'PUT',
    url: `api/Scheduling/Exceptions/Resolve`
  }
}

export default apiConfig
