import config from "./APIConfig"
import { postDataApi } from '../index'

export const sendPasswordResetEmail = (data) => {
  const { url } = config.sendPasswordResetEmail
  const payload = {
    contactId: data.contactId
    }
 
  return postDataApi(url(data), payload)
}

export default sendPasswordResetEmail
