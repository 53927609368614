import config from "./APIConfig"
import { deleteDataApi } from '../index'

/**
 * @method removeTrainingFromCheckpoint
 * @param {object} data 
 *
 */
export const removeTrainingFromCheckpoint = (data) => {
  const { url } = config.removeTrainingFromCheckpoint

  return deleteDataApi(url(data))
}

export default removeTrainingFromCheckpoint
