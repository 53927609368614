import { CHECKPOINT_NUMERIC_TYPES } from '@shared/constants'
import { getUniqueUuid } from '@utils'
import { RESPONSE_CHOICE_TYPES } from "@views/projects/templates/add-new-template/checkpoints/new-checkpoint/choice-response"
import { addEnglishLanguageToOther } from "@views/projects/templates/add-new-template/checkpoints/new-checkpoint/Config"
import config from "./APIConfig"
import { postDataApi, putDataApi } from '../index'

const activityFormatter = (data, isUpdating) => {
  const {
    activityName,
    originatedFrom,
    originatedFromId,
    checkpointId,
    checkpointVersionId,
    isReviewActivity,
    checkpointResponses = []
  } = data
  const activity = {
    originatedFrom,
    isReviewActivity,
    originatedFromId: parseInt(originatedFromId),
    activityName: addEnglishLanguageToOther(activityName)
  }
  if (isUpdating) {
    activity.checkpointId = parseInt(checkpointId)
    activity.checkpointVersionId = parseInt(checkpointVersionId)
  }
  const resList = []
  const gotoConditionsList = []
  let primaryIndex = -1
  const primaryResponse = checkpointResponses.find(res => !!res.isPrimary)

  checkpointResponses.forEach((response, index) => {
    const {
      isPrimary,
      responseId,
      gotoConditions = {},
      checkpointResponseTypeId,
      required,
      isAllowedNA,
      otherOptions,
      checkpointResponseNumericTypeId,
      checkpointResponseNumericValidationId,
      numericMin,
      numericMax,
      orderBy,
      responseName,
      reportTag,
      isAllResponse = false,
      defaultValue,
      resultValue,
      checkpointResponseQuestions = [],
      responseCustomTypeId = 0
    } = response
    if (!!isPrimary) {
      primaryIndex = index
    } else {
      const { operator, gotoAnswer } = gotoConditions
      if (gotoAnswer) {
        const anwPos = gotoConditionsList.findIndex(cond => cond.gotoAnswer === gotoAnswer)
        if (anwPos === -1) {
          const gotoCond = { operator: operator.text, goto: [responseId] }
          if (RESPONSE_CHOICE_TYPES.NUMERIC === String(primaryResponse.checkpointResponseTypeId) && CHECKPOINT_NUMERIC_TYPES.NUMERIC_RANGE === String(primaryResponse.checkpointResponseNumericTypeId)) {
            gotoCond.gotoAnswer = `${gotoAnswer.min || 0},${gotoAnswer.max || 0}`
          } else {
            gotoCond.gotoAnswer = gotoAnswer
          }
          gotoConditionsList.push(gotoCond)
        } else {
          gotoConditionsList[anwPos].goto.push(responseId)
        }
      }
    }

    const resData = {
      ...(RESPONSE_CHOICE_TYPES.NUMERIC === String(checkpointResponseTypeId)
        ? {
          numericMin: parseInt(numericMin),
          numericMax: parseInt(numericMax),
          responseNumericTypeId: parseInt(checkpointResponseNumericTypeId),
          responseNumericValidationId: parseInt(checkpointResponseNumericValidationId)
        } : {}),
      responseId,
      isPrimary,
      responseTypeId: parseInt(checkpointResponseTypeId),
      required,
      isAllowedNA,
      otherOptions,
      orderIndex: parseInt(orderBy),
      responseName: !!isPrimary ? addEnglishLanguageToOther(responseName) : responseName,
      reportTag,
      isAllResponse,
      defaultValue,
      resultValue,
      responseCustomTypeId
    }

    const questionList = []
    checkpointResponseQuestions.forEach(question => {
      const { orderBy, options, questionId, buttonColor, fontColor } = question
      const payload = {
        responseOptionId: questionId || getUniqueUuid(), //getUniqueUuid() used to create new unique response option id if questionId is not available
        orderIndex: parseInt(orderBy),
        options
      }
      if (buttonColor) {
        payload.buttonColor = buttonColor
      }
      if (fontColor) {
        payload.fontColor = fontColor
      }
      
      questionList.push(payload)
    })
    if (questionList.length) {
      resData.responseOptions = questionList
    }
    resList.push(resData)
  })
  if (!!resList.length) {
    activity.responses = resList
  }
  if (!!gotoConditionsList.length && primaryIndex > -1) {
    activity.responses[primaryIndex].gotoConditions = gotoConditionsList
  }

  return activity
}

/**
 * @method upsertCheckpointResponseQuestions
 * @param {object} data
 */
export const upsertCheckpointResponseQuestions = (data) => {
  const isUpdating = !!parseInt(data.checkpointId)
  const { url } = config[isUpdating ? 'updateCheckpointResponseQuestions' : 'addCheckpointResponseQuestions']
  const formattedData = activityFormatter(data, isUpdating)

  if (isUpdating) {
    return putDataApi(url(data), formattedData)
  } else {
    return postDataApi(url(), formattedData)
  }
}

export default upsertCheckpointResponseQuestions
