import cx from "classnames"
import React, { useCallback, useMemo, useState } from "react"
import { ChevronRight, ChevronLeft } from "react-feather"
import { Button, Row, Col, Input, FormGroup } from "reactstrap"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import {
  CustomCheckbox,
  DeleteConfirmationModal,
  ErrorMessage,
  ReactSelect,
  CustomLabel
} from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import { getReactSelectPositionProps } from "@views/helpers"
import { rowsPerPageOptions, FILTER_SECTION_TYPE } from "./config"
import { validateSaveFilterConfig } from "./Helpers"

const { FILTER_TABLE_MODAL, SAVE, DELETE } = LANGUAGE_CONSTANTS

const SaveAsNewFilter = ({
  intl,
  expandedViewId,
  filterConfig,
  rowsPerPage,
  columns,
  isEditMode,
  searchFilterKey,
  searchComponentRef,
  isRowsPerPageConfigRequired,
  onCancelEditSavedFilter = () => { },
  onDeleteFilter = () => { },
  onEditFilter = () => { },
  onRefreshSavedFilter = () => { },
  onResetFilterConfig = () => { },
  onSetFilterConfig = () => { },
  onSetRowsPerPage = () => { },
  onToggleView = () => { }
}) => {
  const { control } = useForm({})
  const dispatch = useDispatch()

  const [errors, setErrors] = useState({})
  const [isOpenDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false)

  const handleToggleView = useCallback(() => {
    onToggleView(FILTER_SECTION_TYPE.SAVE_FILTER_CONFIG)
  }, [onToggleView])

  const reactSelectPostionProps = useMemo(() => {
    return getReactSelectPositionProps()
  }, [])

  const handleChangeFilterConfig = useCallback(
    (key, value) => {
      if (!!Object.keys(errors).length) {
        setErrors({})
      }

      if (key === "rowsPerPage") {
        onSetRowsPerPage(value)
        return
      }
      const updatedConfig = Object.assign({}, filterConfig)
      updatedConfig[key] = value
      onSetFilterConfig(updatedConfig)
    },
    [errors, filterConfig, onSetFilterConfig, onSetRowsPerPage]
  )

  const handleSaveFilterConfig = useCallback(() => {
    const errors = validateSaveFilterConfig({ intl, ...filterConfig })
    if (!!Object.keys(errors).length) {
      setErrors(errors)
      return
    }

    const searchDetails = searchComponentRef.current.getSearchFilterState()
    const payload = {
      searchFilterName: filterConfig.filterName,
      searchFilterJson: JSON.stringify({
        advanceSearchDetail: searchDetails,
        columnConfig: columns,
        rowsPerPage: rowsPerPage?.value
      }),
      isGlobal: filterConfig.isGlobal,
      isDefault: filterConfig.isDefault
    }

    if (isEditMode) {
      payload.advanceSearchFilterId = filterConfig.advanceSearchFilterId
      dispatch(
        actions.updateAdvanceSearchFiltersRequest(payload, (response) => {
          if (response) {
            /**
             * -Reset this section filter details
             * -Reset filter table modal state for columns and advance search filter to initial state
             */
            onResetFilterConfig()
            //update/refresh saved filter list
            onRefreshSavedFilter(filterConfig.advanceSearchFilterId, payload)
            //Cancel edit mode
            onCancelEditSavedFilter()
            //If applied filter is edited
            onEditFilter(filterConfig.advanceSearchFilterId)
          }
        })
      )
    } else {
      payload.searchFilterKey = searchFilterKey
      dispatch(
        actions.saveAdvanceSearchFiltersRequest(payload, (response) => {
          if (response) {
            /**
            * -Reset this section filter details
            * -Reset filter table modal state for columns and advance search filter to initial state
            */
            onResetFilterConfig()
            //update/refresh saved filter list
            onRefreshSavedFilter(response, payload)
          }
        })
      )
    }
  }, [
    columns,
    filterConfig,
    rowsPerPage,
    onCancelEditSavedFilter,
    onEditFilter,
    onResetFilterConfig,
    onRefreshSavedFilter
  ])

  const handleToggleDeleteModal = useCallback(() => {
    setOpenDeleteConfirmationModal((prevState) => !prevState)
  }, [])

  const handleDeleteFilter = useCallback(() => {
    dispatch(
      actions.deleteAdvanceSearchFiltersRequest(
        filterConfig.advanceSearchFilterId,
        (res) => {
          if (res) {
            //reset this section filter details
            onResetFilterConfig(true)
            //update/refresh saved filter list
            onRefreshSavedFilter(filterConfig.advanceSearchFilterId, {
              isDeleteFilter: true
            })
            //Cancel edit mode
            onCancelEditSavedFilter()
            //To close confirmation modal
            handleToggleDeleteModal()
            //If applied filter is deleted
            onDeleteFilter(filterConfig.advanceSearchFilterId)
          }
        }
      )
    )
  }, [
    filterConfig.advanceSearchFilterId,
    handleToggleDeleteModal,
    onCancelEditSavedFilter,
    onDeleteFilter,
    onResetFilterConfig,
    onRefreshSavedFilter
  ])

  const handleCancelEditSavedFilter = useCallback(() => {
    if (!!Object.keys(errors).length) {
      setErrors(errors)
    }
    onCancelEditSavedFilter()
  }, [errors, onCancelEditSavedFilter])

  const isOpen = useMemo(
    () => expandedViewId === FILTER_SECTION_TYPE.SAVE_FILTER_CONFIG,
    [expandedViewId]
  )

  return (
    <div className="setup-accordion-header save-as-filter">
      <button
        type="button"
        className={cx(
          "waves-effect btn advanced-btn more-less-btn text-capitalize",
          { active: isOpen }
        )}
        onClick={handleToggleView}
      >
        {getLocaleMessage(
          intl,
          isEditMode
            ? FILTER_TABLE_MODAL.EDIT_FILTER
            : FILTER_TABLE_MODAL.SAVE_AS_NEW_FILTER
        )}
        {isOpen ? <ChevronRight /> : <ChevronLeft />}
      </button>
      {isOpen && (
        <>
          <Row className="mt-2">
            <Col sm={12} md={6}>
              <FormGroup>
                <CustomLabel title={FILTER_TABLE_MODAL.FILTER_NAME} />
                <Input
                  value={filterConfig.filterName}
                  placeholder={getLocaleMessage(
                    intl,
                    FILTER_TABLE_MODAL.FILTER_NAME
                  )}
                  onChange={(e) => handleChangeFilterConfig("filterName", e.target.value)}
                />
              </FormGroup>
              <ErrorMessage
                isShow={!!errors["filterName"]}
                message={errors["filterName"]}
              />
            </Col>
            {isRowsPerPageConfigRequired && <Col sm={12} md={6}>
              <ReactSelect
                id="rowsPerPage"
                name="rowsPerPage"
                control={control}
                value={rowsPerPage}
                isFormattedValue
                options={rowsPerPageOptions}
                label={getLocaleMessage(
                  intl,
                  FILTER_TABLE_MODAL.ROWS_PER_PAGE
                )}
                placeHolder={getLocaleMessage(
                  intl,
                  FILTER_TABLE_MODAL.ROWS_PER_PAGE
                )}
                onSelect={(selected) => handleChangeFilterConfig(
                  "rowsPerPage",
                  selected?.value ? selected : null
                )
                }
                {...reactSelectPostionProps}
              />
            </Col>}
          </Row>
          <div className="check-list-outer custom-checkbox-search">
            <div className="check-list-box">
              <CustomCheckbox
                isNewListTheme
                isChecked={filterConfig.isGlobal}
                onChange={() => handleChangeFilterConfig("isGlobal", !filterConfig.isGlobal)}
              />
              <span>
                {getLocaleMessage(
                  intl,
                  FILTER_TABLE_MODAL.SAVE_AS_GLOBAL_FILTER
                )}
              </span>
            </div>
            <div className="check-list-box">
              <CustomCheckbox
                isNewListTheme
                isChecked={filterConfig.isDefault}
                onChange={() => handleChangeFilterConfig("isDefault", !filterConfig.isDefault)}
              />
              <span>
                {getLocaleMessage(
                  intl,
                  FILTER_TABLE_MODAL.SAVE_AS_DEFAULT_FILTER
                )}
              </span>
            </div>
          </div>
          {isEditMode ? (
            <Row className="edit-btns-outer">
              <Col>
                <Button
                  className="secondary-outlined w-100"
                  color=""
                  onClick={handleCancelEditSavedFilter}
                >
                  {getLocaleMessage(intl, FILTER_TABLE_MODAL.UNDO_CHANGES)}
                </Button>
              </Col>
              <Col>
                <Button
                  className="button-control w-100"
                  color="danger"
                  onClick={handleToggleDeleteModal}
                >
                  {getLocaleMessage(intl, DELETE)}
                </Button>
              </Col>
              <Col>
                <Button
                  className="primary-solid button-control w-100"
                  color=""
                  onClick={handleSaveFilterConfig}
                >
                  {getLocaleMessage(intl, SAVE)}
                </Button>
              </Col>
            </Row>
          ) : (
            <Button
              className="primary-solid w-100"
              color="primary"
              onClick={handleSaveFilterConfig}
            >
              {getLocaleMessage(intl, FILTER_TABLE_MODAL.ADD_AS_SAVED_FILTER)}
            </Button>
          )}
        </>
      )}
      {isOpenDeleteConfirmationModal && (
        <DeleteConfirmationModal
          id={filterConfig.advanceSearchFilterId}
          open
          type={FILTER_TABLE_MODAL.FILTER}
          onToggleModal={handleToggleDeleteModal}
          onConfirmDeleteRecord={handleDeleteFilter}
        />
      )}
    </div>
  )
}

export default localeMessageWrapper(SaveAsNewFilter)
