import { takeLatest, call, put } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

const {
  OVERVIEW_PAGE_SECTIONS: { UNITS_SECTION: { UNIT_SCHEDULE_SECTION } }, SITES_SECTION: {
    SITE_PLAN_SCHEDULE
  }
} = LANGUAGE_CONSTANTS

/** getProgramTaskOverviewList API **/
function* getProgramTaskOverviewListRequest(action) {
  try {
    const response = yield call(services.getProgramTaskOverviewList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getProgramTaskOverviewVM API **/
function* getProgramTaskOverviewVMRequest(action) {
  try {
    const response = yield call(services.getProgramTaskOverviewVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getProgramUnitOverviewList API **/
function* getProgramUnitOverviewListRequest(action) {
  try {
    const response = yield call(services.getProgramUnitOverviewList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getProgramUnitDetail API **/
function* getProgramUnitDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getProgramUnitDetail, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getProgramUnitOverviewVM API **/
function* getProgramUnitOverviewVMRequest(action) {
  try {
    const response = yield call(services.getProgramUnitOverviewVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** getProgramDirectoryOverviewList API **/
function* getProgramDirectoryOverviewListRequest(action) {
  try {
    const response = yield call(services.getProgramDirectoryOverviewList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getProgramDirectoryOverviewVM API **/
function* getProgramDirectoryOverviewVMRequest(action) {
  try {
    const response = yield call(services.getProgramDirectoryOverviewVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** getProgramObservationTaskOverdueStatus API **/
function* getProgramObservationTaskOverdueStatusRequest(action) {
  try {
    const response = yield call(services.getProgramObservationTaskOverdueStatus, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** getProgramUnitScheduleList API **/
function* getProgramUnitScheduleListRequest(action) {
  try {
    const response = yield call(services.getProgramUnitScheduleList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** getProgramScheduleVM API **/
function* getProgramScheduleVMRequest(action) {
  try {
    const response = yield call(services.getProgramScheduleVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** saveProgramUnitScheduleLine API **/
function* saveProgramUnitScheduleLineRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.saveProgramUnitScheduleLine, action.payload)
    if (response?.status === 200 || response?.status === 204) {
      Notification({ status: 200, message: UNIT_SCHEDULE_SECTION.SAVE_SUCCESS })
      action.callback(response.status === 204 ? true : response.data)
    } else {
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** deleteProgramUnitScheduleLine API **/
function* deleteProgramUnitScheduleLineRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteProgramUnitScheduleLine, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: UNIT_SCHEDULE_SECTION.DELETE_SUCCESS })
    } else {
      Notification({ status: 400, message: UNIT_SCHEDULE_SECTION.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** getUnitSchedulePrereqsVM API **/
function* getUnitSchedulePrereqsVMRequest(action) {
  try {
    const response = yield call(services.getUnitSchedulePrereqsVM, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    }
  } catch (error) {
    action.callback(false)
  }
}

/** reorderUnitScheduleLine API */
function* reorderUnitScheduleLineRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.reorderUnitScheduleLine, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: UNIT_SCHEDULE_SECTION.UNIT_SCHEDULE_LINE_REORDER_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: UNIT_SCHEDULE_SECTION.ERROR_IN_REORDERING_UNIT_SCHEDULE_LINE })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** startScheduleLine API **/
function* startScheduleLineRequest(action) {
  try {
    const response = yield call(services.startScheduleLine, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: UNIT_SCHEDULE_SECTION.STATUS_STARTED_SUCCESS })
    } else {
      Notification({ status: 200, message: UNIT_SCHEDULE_SECTION.STATUS_STARTED_FAILURE })
    }
  } catch (error) {
    action.callback(false)
  }
}

/** completeScheduleLine API **/
function* completeScheduleLineRequest(action) {
  try {
    const response = yield call(services.completeScheduleLine, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: UNIT_SCHEDULE_SECTION.STATUS_COMPLETED_SUCCESS })
    } else {
      Notification({ status: 200, message: UNIT_SCHEDULE_SECTION.STATUS_COMPLETED_FAILURE })
    }
  } catch (error) {
    action.callback(false)
  }
}

/** reorderSitePlanScheduleLine API */
function* reorderSitePlanScheduleLineRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.reorderSitePlanScheduleLine, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: SITE_PLAN_SCHEDULE.SITE_PLAN_SCHEDULE_LINE_REORDER_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: SITE_PLAN_SCHEDULE.ERROR_IN_REORDERING_SITE_PLAN_SCHEDULE_LINE })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

export function* programOverviewDetailsSagas() {
  yield takeLatest(actions.GET_PROGRAM_TASK_OVERVIEW_LIST_REQUEST, getProgramTaskOverviewListRequest)
  yield takeLatest(actions.GET_PROGRAM_TASK_OVERVIEW_VM_REQUEST, getProgramTaskOverviewVMRequest)
  yield takeLatest(actions.GET_PROGRAM_UNIT_OVERVIEW_LIST_REQUEST, getProgramUnitOverviewListRequest)
  yield takeLatest(actions.GET_PROGRAM_UNIT_DETAIL_REQUEST, getProgramUnitDetailRequest)
  yield takeLatest(actions.GET_PROGRAM_UNIT_OVERVIEW_VM_REQUEST, getProgramUnitOverviewVMRequest)
  yield takeLatest(actions.GET_PROGRAM_DIRECTORY_OVERVIEW_LIST_REQUEST, getProgramDirectoryOverviewListRequest)
  yield takeLatest(actions.GET_PROGRAM_DIRECTORY_OVERVIEW_VM_REQUEST, getProgramDirectoryOverviewVMRequest)
  yield takeLatest(actions.GET_PROGRAM_OBSERVATION_TASK_OVERDUE_STATUS_REQUEST, getProgramObservationTaskOverdueStatusRequest)
  yield takeLatest(actions.GET_PROGRAM_UNIT_SCHEDULE_LIST_REQUEST, getProgramUnitScheduleListRequest)
  yield takeLatest(actions.GET_PROGRAM_SCHEDULE_VM_REQUEST, getProgramScheduleVMRequest)
  yield takeLatest(actions.SAVE_PROGRAM_UNIT_SCHEDULE_LINE_REQUEST, saveProgramUnitScheduleLineRequest)
  yield takeLatest(actions.DELETE_PROGRAM_UNIT_SCHEDULE_LINE_REQUEST, deleteProgramUnitScheduleLineRequest)
  yield takeLatest(actions.GET_UNIT_SCHEDULE_PREREQS_VM_REQUEST, getUnitSchedulePrereqsVMRequest)
  yield takeLatest(actions.START_SCHEDULE_LINE_REQUEST, startScheduleLineRequest)
  yield takeLatest(actions.COMPLETE_SCHEDULE_LINE_REQUEST, completeScheduleLineRequest)
  yield takeLatest(actions.REORDER_UNIT_SCHEDULE_LINE_REQUEST, reorderUnitScheduleLineRequest)
  yield takeLatest(actions.REORDER_SITE_PLAN_SCHEDULE_LINE_REQUEST, reorderSitePlanScheduleLineRequest)
}

export default programOverviewDetailsSagas
