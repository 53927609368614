import config from "../APIConfig"
import { deleteDataApi, putDataApi } from '../../../index'

/**
 * @method deleteActivityFromGroup
 * 
 * @param data 
 * {
    "wfCampaignSetupActivityId": 0
  }
 * @returns 
 */
export const deleteActivityFromGroup = (data) => {
  const { url } = config.deleteActivityFromGroup

  return putDataApi(url(data), data)
}

export const deleteActivityGroup = (data) => {
  const { url } = config.deleteActivityGroup
  return deleteDataApi(url(data))
}
