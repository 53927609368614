import cx from "classnames"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FormGroup } from "reactstrap"
import { SketchPicker } from "react-color"
import PropTypes from "prop-types"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import { CustomLabel } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const ColorPicker = ({
  id,
  intl,
  isDisabled,
  isLocalizedTitle,
  isRequired,
  isOpenColorView,
  label,
  labelRequired,
  isColorPickerOnly,
  value,
  onClose,
  onSelect
}) => {
  const [isOpenColorPicker, setOpenColorPicker] = useState(isOpenColorView)
  const colorPickerRef = useRef(null)
  const colorPickerViewRef = useRef(null)

  const handleCloseColorPicker = useCallback(() => {
    setOpenColorPicker(false)
    onClose(false)
  }, [onClose])


  useEffect(() => {
    const handleToggleColorPicker = (event) => {
      if (event.detail.colorPickerId === id && event.detail.isOpen) {
        setOpenColorPicker(true)
      } else {
        handleCloseColorPicker()
      }
    }

    const handleClickWindow = () => {
      handleCloseColorPicker()
    }

    const handleModalViewColorPicker = (e) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(e.target)) {
        //To handle close color picker popup
        handleCloseColorPicker()
      } else if (colorPickerViewRef.current && !colorPickerViewRef.current.contains(e.target) && (e.target.id !== id)) {
         //To handle close color picker popup for color selection box view
        handleCloseColorPicker()
      } else if (e.target.id === id) {
        e.stopPropagation()
        //To handle open/close color picker popup for color selection box view
        if (e.target.classList.contains("open")) {
          handleCloseColorPicker()
        } else {
          setOpenColorPicker(true)
        }
      }
    }

    const element = [...document.getElementsByClassName("modal")]
    if (element?.length) {
      const elementIndex = element.findIndex(x => x.className.includes('show'))
      if (elementIndex !== -1) {
        element[0].addEventListener("click", handleModalViewColorPicker)
      }
    }

    window.addEventListener("onToggleColorPicker", handleToggleColorPicker)
    window.addEventListener("click", handleClickWindow)
    return () => {
      window.removeEventListener(
        "onToggleColorPicker",
        handleToggleColorPicker
      )
      if (element?.length) {    
          const elementIndex = element.findIndex(x => x.className.includes('show'))
          if (elementIndex !== -1) {
            element[0].removeEventListener("click", handleModalViewColorPicker)
          }
        }
      window.removeEventListener("click", handleClickWindow)
    }
  }, [])

  const handleToggleColorPicker = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      if (isDisabled) {
        return
      }

      window.dispatchEvent(
        new CustomEvent("onToggleColorPicker", {
          bubbles: false,
          detail: {
            colorPickerId: id,
            isOpen: !isOpenColorPicker
          }
        })
      )
    },
    [isDisabled, isOpenColorPicker]
  )

  const handleClick = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  const handleSelect = useCallback(
    (color) => {
      onSelect(JSON.stringify(color.rgb))
    },
    [onSelect]
  )

  const selectedColor = useMemo(() => {
    try {
      return JSON.parse(value)
    } catch (error) {
      return ""
    }
  }, [value])

  if (isColorPickerOnly) {
    return (
      <div className="custom-color-picker" ref={colorPickerRef}>
        <div className="picked-color-view-outer">
          {isOpenColorPicker && (
            <div id={"color_picker_container"} onClick={handleClick}>
              <SketchPicker color={selectedColor} onChange={handleSelect} />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <FormGroup className="custom-color-picker">
      {labelRequired && (
        <CustomLabel
          title={
            label ||
            getLocaleMessage(intl, LANGUAGE_CONSTANTS.COLOR_PICKER_LABEL)
          }
          required={isRequired}
          requiredIconClassName={isRequired ? "required-field" : ""}
          isLocalizedTitle={isLocalizedTitle}
        />
      )}
      <div className="picked-color-view-outer">
        <div
          id={id}
          className={cx("picked-color-view", {
            disabled: isDisabled,
            open: isOpenColorPicker
          })}
          style={{
            backgroundColor: !selectedColor
              ? value
              : `rgb(${selectedColor.r} ${selectedColor.g} ${selectedColor.b} / ${selectedColor.a})`
          }}
          onClick={handleToggleColorPicker}
        />
        <div ref={colorPickerViewRef}>
          {isOpenColorPicker && (
            <div id={"color_picker_container"} onClick={handleClick}>
              <SketchPicker color={selectedColor} onChange={handleSelect} />
            </div>
          )}
        </div>
      </div>
    </FormGroup>
  )
}

ColorPicker.propTypes = {
  id: PropTypes.string,
  intl: PropTypes.object,
  isDisabled: PropTypes.bool,
  isLocalizedTitle: PropTypes.bool,
  isRequired: PropTypes.bool,
  isColorPickerOnly: PropTypes.bool,
  isOpenColorView: PropTypes.bool,
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  value: PropTypes.string,
  onClose: PropTypes.func,
  onSelect: PropTypes.func
}

ColorPicker.defaultProps = {
  id: "colorPicker",
  isDisabled: false,
  isLocalizedTitle: true,
  labelRequired: true,
  isColorPickerOnly: false,
  isOpenColorView: false,
  value: "",
  onClose: () => {},
  onSelect: () => {}
}

export default localeMessageWrapper(ColorPicker)
