import config from "./APIConfig"
import { postDataApi } from '../index'
/**
 * @method updateUnitWorkflowList : To save selected workflow to units
 * 
 * @param {object} data
 * 
 {
  "siteUnitId": 0,
  "selectedWorkflowCampaignSetupIds": [
    0
  ],
  "unSelectedWorkflowCampaignSetupIds": [
    0
  ]
}
 */
export const updateUnitWorkflowList = async (data) => {
  const { url } = config.updateUnitWorkflowList
  return await postDataApi(url(data), data)
}
export default updateUnitWorkflowList