import config from "./APIConfig"
import { deleteDataApi } from '../index'

export const deleteSharedCampaignSite = (data) => {
  const { url } = config.deleteSharedCampaignSite
  return deleteDataApi(url(data), null, data)
}

export const deleteServiceChecklist = (data) => {
  const { url } = config.deleteServiceChecklist
  return deleteDataApi(url(data))
}

export const deleteCampaignServiceChecklistCheckpoint = (idList = []) => {
  const { url } = config.deleteCampaignServiceChecklistCheckpoint
  const payload = {
    wfCampaignSetupActivityIds: idList
  }
  return deleteDataApi(url, null, payload)
}

export const deleteCampaignService = (ids) => {
  const { url } = config.deleteCampaignService
  return deleteDataApi(url(ids))
}

export const deleteCampaign = (ids) => {
  const { url } = config.deleteCampaign
  return deleteDataApi(url(ids))
}
