import { LANGUAGE_CONSTANTS } from '@shared/language-constants'

const { TABLE } = LANGUAGE_CONSTANTS

export const ROW_ACTION_TYPES = {
  VIEW_FILES: "view_files",
  ADD_BLUEPRINT: 'add_blueprint',
  ADD_MODEL: 'add_model'
}

export const rowActionItems = [
  {
    id: ROW_ACTION_TYPES.VIEW_FILES,
    labelLocaleId: TABLE.VIEW_FILES
  },
  {
    id: ROW_ACTION_TYPES.ADD_MODEL,
    labelLocaleId: TABLE.ADD_MODEL
  }
]