import config from "./APIConfig"
import { putDataApi } from "../index"

/**
 * @method updateCustomDataRetention
 * @param {object} data 
 *{
  {
  "dataRetentionPolicyId": 0,
  "dataRetentionPolicyName": "string",
  "dataRetentionRemovalMethodId": 0,
  "dataRetentionRequiredById": 0,
  "retentionPeriod": 0
}
 *
 */
export const updateCustomDataRetention = async (data) => {
  const { url } = config.updateCustomDataRetention
  return await putDataApi(url(data.dataRetentionPolicyId), data)
}

export default updateCustomDataRetention
