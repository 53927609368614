import cx from "classnames"
import React, { useCallback, useState } from "react"
import { CustomInput } from "reactstrap"
import { ChevronUp } from "react-feather"

import { LIST_SORT_ORDER_DIRECTION } from "@shared/constants"

const TableHeader = ({
  expandableRows,
  columns,
  isAllSelected,
  isDisableAllSelect,
  isLoading,
  selectableRows,
  sortServer,
  onSelectAllRows = () => {},
  onSort = () => {}
}) => {
  const [sortKey, setSortKey] = useState("")
  const [selectedSortDirection, setSelectedSortDirection] = useState("")

  const handleSort = useCallback(
    (column) => {
      if (!column.sortKey || isLoading) {
        return
      }

      let direction =
        !!sortKey && sortKey !== column.sortKey ? selectedSortDirection : ""
      if (!direction) {
        if (selectedSortDirection === LIST_SORT_ORDER_DIRECTION.ASC) {
          direction = LIST_SORT_ORDER_DIRECTION.DESC
        } else if (selectedSortDirection === LIST_SORT_ORDER_DIRECTION.DESC) {
          direction = LIST_SORT_ORDER_DIRECTION.ASC
        } else {
          direction = LIST_SORT_ORDER_DIRECTION.ASC
        }
      }

      setSortKey(column.sortKey)
      onSort(column, direction)
      setSelectedSortDirection(direction)
    },
    [isLoading, selectedSortDirection, sortKey, onSort]
  )

  return (
    <thead className="scroll-to-load-thead rdt_TableHead">
      <tr className="rdt_TableHeadRow">
        {selectableRows && (
          <th scope="col rdt_TableCol row-select-checkbox-col">
            {expandableRows && <div className="checkbox-space"></div>}
            <CustomInput
              type="checkbox"
              checked={isAllSelected}
              id="table_header_row_cell"
              disabled={isDisableAllSelect}
              onChange={onSelectAllRows}
            />
          </th>
        )}
        {columns.map((col, index) => (
          <th scope="col rdt_TableCol" key={`col-head-${index}`}>
            <div
              className={cx("table-head-txt ", {
                rdt_TableCol_Sortable_Col: sortServer && col.sortable
              })}
            >
              <div>{col.name || ""}</div>
              {sortServer && col.sortable && (
                <div
                  id={`column-${col.selector}`}
                  role="button"
                  className={cx("rdt_TableCol_Sortable", {
                    "sort-rotate":
                      selectedSortDirection === LIST_SORT_ORDER_DIRECTION.DESC
                  })}
                >
                  <span
                    className={cx("__rdt_custom_sort_icon__", {
                      asc:
                        sortKey === col.sortKey &&
                        selectedSortDirection === LIST_SORT_ORDER_DIRECTION.ASC,
                      desc:
                        sortKey === col.sortKey &&
                        selectedSortDirection ===
                          LIST_SORT_ORDER_DIRECTION.DESC
                    })}
                  >
                    <ChevronUp
                      className="arrow-icon"
                      size="6"
                      onClick={() => handleSort(col)}
                    />
                  </span>
                </div>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHeader
