import config from "./APIConfig"
import { putDataApi } from "../index"

export const clearAllNotifications = async () => {
  const { url } = config.clearAllNotifications
  return await putDataApi(url)
}

export const clearNotifications = async (data) => {
  const { url } = config.clearNotification
  return await putDataApi(url(data))
}

export const clearSelectedNotifications = async (data) => {
  const { url } = config.clearSelectedNotification
  return await putDataApi(url, data)
}
