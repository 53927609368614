import { CustomInput } from "reactstrap"
import { Check, X } from "react-feather"
import { useCallback, useEffect, useState } from "react"
import PropTypes from 'prop-types'

const CustomInputLabel = () => (
  <>
    <span className="switch-icon-left">
      <Check size={14} color="white"/>
    </span>
    <span className="switch-icon-right">
      <X size={14} />
    </span>
  </>
)

const ToggleButton = ({
  className,
  descriptionText,
  id,
  inline,
  label,
  name,
  value,
  customInputLabelRequired,
  onChange = () => { },
  isDisabled
}) => {
  const [checked, setChecked] = useState(value)

  useEffect(() => {
    setChecked(value)
  }, [value])

  const handleChange = useCallback(() => {
    setChecked(!checked)
    onChange(!checked)
  }, [checked, onChange])

  return (
    <div className="slider">
      <div className="demo-inline-spacing">
        <CustomInput
          type="switch"
          checked={checked}
          className={className}
          id={id}
          label={customInputLabelRequired && (label || <CustomInputLabel />)} 
          name={name}
          inline={inline}
          onChange={handleChange}
          disabled={isDisabled}
        />
        {!!descriptionText && <span>{descriptionText}</span>}
      </div>
    </div>
  )
}

ToggleButton.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  id: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  customInputLabelRequired: PropTypes.bool
}

ToggleButton.defaultProps = {
  className: "",
  descriptionText: "",
  id: "toggleIcon",
  inline: true,
  label: "",
  name: "",
  value: false,
  isDisabled: false,
  customInputLabelRequired: true
}

export default ToggleButton
