import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addProjectSiteUnit
 * @param {object} data 
 * {
  "unitName": "string",
  "unitTypeId": 0,
  "siteId": 0,
  "streetAddress": "string",
  "unitNumber": "string",
  "city": "string",
  "state": "string",
  "zipCode": "string",
  "countryCode": "string",
  "block": "string",
  "building": "string",
  "floor": "string",
  "permitNumber": "string",
  "planNumber": "string",
  "phase": "string",
  "phaseStartDate": "2021-09-08T10:30:02.430Z",
  "sitePlanId": 0
  }
 *
 */
export const addProjectSiteUnit = (data) => {
  const { url } = config.addProjectSiteUnit

  return postDataApi(url(), data)
}

export default addProjectSiteUnit
