import config from "./APIConfig"
import { postDataApi } from "../index"

/**
 * @method upsertCCContacts : To save contacts for CC in emails
 * @param {Object} data 
 * 
 * {
    "sourceType": 1,
    "sourceId": 0,
    "contactIds": [
      0
    ]
  }
 * @returns 
 */
export const upsertCCContacts = async (data) => {
  const { url } = config.upsertCCContacts

  return await postDataApi(url(data), data)
}

export default upsertCCContacts
