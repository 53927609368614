import React, { useState, useCallback, useEffect, useMemo } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import * as actions from "@store/actions"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"
import localeMessageWrapper from '@views/components/locale-message'
import { getReactSelectPositionProps } from "@views/helpers"

const { ACTIVITIES } = LANGUAGE_CONSTANTS

const AssemblySearch = ({
  intl,
  errors,
  defaultOptions = [],
  value = "",
  isRequired = false,
  isEditMode = false,
  initialLabel,
  isMulti = false,
  onChangeCheckpointCollection
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState(defaultOptions || [])
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)

  const reactSelectPostionProps = useMemo(() => {
    return getReactSelectPositionProps()
  }, [])

  useEffect(() => {
    //Note: To set default options from API
    if (!options.length && !!defaultOptions.length) {
      setResetCache(true)
      setOptions(defaultOptions)
    }
  }, [options, defaultOptions])

  /**
 * @method handleSetDefaultOptions : To handle set default options in case of Edit mode
 *
 * Note: To handle case of duplicate option, when custom option is added, that is selected from edit mode
 */
  const handleSetDefaultOptions = useCallback(
    (selectedItem, selectedValue) => {
      const checkIfAlreadyPresent = defaultOptions.find(
        (option) => option.value === selectedValue
      )
      if (!!checkIfAlreadyPresent) {
        setOptions(defaultOptions)
      } else {
        setOptions([selectedItem, ...defaultOptions])
      }
    },
    [defaultOptions]
  )

  useEffect(() => {
    if (
      !isEditModeItemLoaded &&
      isEditMode &&
      !!value &&
      !!initialLabel
    ) {
      const selectedItem = {
        text: initialLabel,
        value: value.toString()
      }
      setEditModeItemLoaded(true)
      setSelectedOptionLabel(initialLabel)
      setResetCache((prevState) => !prevState)
      handleSetDefaultOptions(selectedItem, value)
    }
  }, [defaultOptions, initialLabel, isEditMode, isEditModeItemLoaded, value])

  const handleLoadMoreAssembly = useCallback(
    (search, page, prevOptions) => {
      return new Promise((resolve) => {
        if (!search && !prevOptions.length && options.length) {
          resolve({
            optionList: options,
            hasMore: true
          })
          return
        }
        const params = {
          entityId: 1,
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          assemblyName: search
        }
        if (search) setResetCache((prevState) => !prevState)
        dispatch(
          actions.getTemplateAssemblyRequest(params, (response) => {
            if (response) {
              const { items, ...paginationData } = response
              resolve({
                optionList: items,
                hasMore: paginationData.hasNextPage
              })
              setOptions(() => (!!prevOptions.length ? [...prevOptions, ...items] : items))
            } else {
              resolve({
                optionList: [],
                hasMore: false
              })
            }
          })
        )
      })
    },
    [isResetCache, options, value]
  )

  const handleSelect = useCallback(
    (selected) => {
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        onChangeCheckpointCollection("assembly", lastElement?.text !== "— Select —" ? selected : [])
      } else {
        onChangeCheckpointCollection("assembly", selected?.value || "", selected?.text || "")
        setSelectedOptionLabel((selected?.value && selected?.text) || "")
      }
    },
    [onChangeCheckpointCollection])

  return (
    <Col className="mb-1" sm={12} md={6} xl={4}>
      <CustomLabel title={ACTIVITIES.ASSEMBLY} required={isRequired} requiredIconClassName="required-field" />
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id="assembly"
        name="assembly"
        placeholder={getLocaleMessage(intl, ACTIVITIES.SELECT_ASSEMBLY)}
        options={options}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={isMulti ? value : !!value ? {
          text: selectedOptionLabel,
          value
        } : null}
        isMulti={isMulti}
        isShowCustomAction={false}
        onSelect={handleSelect}
        onLoadOptions={handleLoadMoreAssembly}
        getOptionLabel={(option) => option.text}
        getOptionValue={(option) => option.value}
        {...reactSelectPostionProps}
      />
      {errors && <ErrorMessage
        isShow={!!errors["assembly"]}
        message={errors["assembly"]}
      />}
    </Col>
  )
}

export default localeMessageWrapper(AssemblySearch)
