import config from "./APIConfig"
import { deleteDataApi } from '../index'

/**
 * @method deleteAdvanceSearchFilters
**/

export const deleteAdvanceSearchFilters = (advanceSearchFilterId) => {
  const { url } = config.deleteAdvanceSearchFilters
  return deleteDataApi(url(advanceSearchFilterId))
}

export const deleteForesiteRole = async (id) => {
  const { url } = config.deleteForesiteRole
  return await deleteDataApi(url(id))
}
