import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addProjectSiteQATag
 * @param {object} data 
 * {
  "siteId": 0,
  "assignmentActivityId": 0,
  "observationTaskId": 0,
  "actionId": 0
}
 */
export const addProjectSiteQATag = (data) => {
  const { url } = config.addProjectSiteQATag

  return postDataApi(url({siteId: data.siteId}), data)
}

export default addProjectSiteQATag
