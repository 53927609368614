import axios from 'axios'
import jwtDecode from "jwt-decode"

import AppSettings from '@app-settings'
import { API_SUBSCRIPTION_KEY, AUTH0_API_URL } from "@services/api/base-url"

import { updateUserAuth, setAccessDenied, resetMenuListRequest } from '@store/actions'
import jwtDefaultConfig from './jwtDefaultConfig'
import { store } from '../../../store'

const addHeaders = ({ tokenType, accessToken }) => ({
  Authorization: `${tokenType} ${JSON.parse(accessToken)}`,
  'Ocp-Apim-Subscription-Key': API_SUBSCRIPTION_KEY
})

const { REACT_APP_AUTH0_CLIENT_ID, REACT_APP_PERMISSIONS_URL } = AppSettings

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  isRefreshing = false
  failedQueue = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          if (!config.preventTokenOverride) {
            config.headers = {
              ...config.headers,
              ...addHeaders({
                tokenType: this.jwtConfig.tokenType,
                accessToken
              })
            }
          } else {
            delete config.preventTokenOverride
          }
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // TODO Test for case when the wrong refresh token is passed to auth0
        
        const { config, response } = error 
        const originalRequest = config
        if (response?.status === 401 && !originalRequest._retry) {
          if (this.isRefreshing) {
            return new Promise((resolve, reject) => {
              this.failedQueue.push({resolve, reject})
            }).then(token => {
              originalRequest.headers = {
                ...originalRequest.headers,
                ...addHeaders({
                  tokenType: this.jwtConfig.tokenType,
                  accessToken: token
                })
              }
              return axios(originalRequest)
            }).catch(err => {
              return Promise.reject(err)
            })
          }
          originalRequest._retry = true
          this.isRefreshing = true
          return new Promise((resolve, reject) => {
            this.refreshToken().then(r => {
              const accessToken = r.data.access_token
              const decodedAccessToken = jwtDecode(accessToken)
              if (decodedAccessToken) {
                const accessPermission = decodedAccessToken[REACT_APP_PERMISSIONS_URL]
                //To logout if there's no access permissions received with new access token
                if (!accessPermission || !accessPermission.length) {
                  this.logoutUser()
                  return
                }
              }
              this.updateAuthState(r)
              originalRequest.headers = {
                ...originalRequest.headers,
                ...addHeaders({
                  tokenType: this.jwtConfig.tokenType,
                  accessToken
                })
              }
              resolve(axios(originalRequest))
            }).catch((err) => {
              if (err.response) {
                const { response } = err
                if (response.config?.url.includes("oauth/token") && response.status !== 200) {
                  this.logoutUser()
                  return
                }
              }
              this.processQueue(err, null)
              reject(err)
            }).finally(() => {
              this.isRefreshing = false
            })
          })
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    const rfToken = window.localStorage.getItem('refreshToken')
    const axiosInstance = axios.create({})
    const options = {
      method: "POST",
      url: `${AUTH0_API_URL}/oauth/token`,
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: new URLSearchParams({
        grant_type: "refresh_token",
        client_id: REACT_APP_AUTH0_CLIENT_ID,
        refresh_token: JSON.parse(rfToken)
      })
    }
    return axiosInstance.request(options)
  }

  updateAuthState(response) {
    const payload = {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
      isAccessTokenFetched: true
    }
    store.dispatch(updateUserAuth(payload))
    this.processQueue(null, JSON.stringify(response.data.access_token))
  }

  logoutUser() {
    store.dispatch(setAccessDenied())
    store.dispatch(resetMenuListRequest())
  }

  processQueue(error, token = null) {
    this.failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })
    this.failedQueue = []
  }

}
