export const GET_CONTACT_GROUP_LIST_REQUEST = "GET_CONTACT_GROUP_LIST_REQUEST"
export const CREATE_CONTACT_GROUP_REQUEST = "CREATE_CONTACT_GROUP_REQUEST"
export const GET_GROUP_CONTACT_LIST_REQUEST = "GET_GROUP_CONTACT_LIST_REQUEST"
export const UPDATE_CONTACT_GROUP_REQUEST = "UPDATE_CONTACT_GROUP_REQUEST"
export const GET_CONTACT_GROUP_DDL_REQUEST = "GET_CONTACT_GROUP_DDL_REQUEST"
export const ADD_CONTACT_TO_CONTACT_GROUP_REQUEST = "ADD_CONTACT_TO_CONTACT_GROUP_REQUEST"
export const DELETE_CONTACT_GROUP_REQUEST = "DELETE_CONTACT_GROUP_REQUEST"
export const DELETE_CONTACTS_BY_CONTACT_GROUP_REQUEST = "DELETE_CONTACTS_BY_CONTACT_GROUP_REQUEST"
export const UPDATE_CONTACT_GROUP_ORDER_INDEX_REQUEST = " UPDATE_CONTACT_GROUP_ORDER_INDEX_REQUEST"
export const GET_ALL_CONTACT_LIST_REQUEST = "GET_ALL_CONTACT_LIST_REQUEST"
export const GET_CONTACT_LIST_BY_GROUP_REQUEST = "GET_CONTACT_LIST_BY_GROUP_REQUEST"

/****************************************************/
/** GET_CONTACT_GROUP_LIST_REQUEST **/
/****************************************************/

/**
 * @method getContactGroupListRequest : to get contact group list
 */

export const getContactGroupListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CONTACT_GROUP_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_CONTACT_GROUP_REQUEST **/
/****************************************************/

/**
 * @method createContactGroupRequest : To save / create  contact group
 *
 */
export const createContactGroupRequest = (payload, callback) => {
  return {
    type: CREATE_CONTACT_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_GROUP_CONTACT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getGroupContactListRequest : To get contact list for specific group
 */

export const getGroupContactListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_GROUP_CONTACT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_CONTACT_GROUP_REQUEST **/
/****************************************************/

/**
 * @method updateContactGroupRequest : To update contact group
 *
 */
export const updateContactGroupRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_CONTACT_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CONTACT_GROUP_DDL_REQUEST **/
/****************************************************/

/**
 * @method getContactGroupDDLRequest : To get contact group DDL
 */

export const getContactGroupDDLRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CONTACT_GROUP_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_CONTACT_TO_CONTACT_GROUP_REQUEST **/
/****************************************************/

/**
 * @method addContactsToContactGroupsRequest : To add multiple contacts to multiple groups
 */

export const addContactsToContactGroupsRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_CONTACT_TO_CONTACT_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_CONTACT_GROUP_REQUEST **/
/****************************************************/

/**
 * @method deleteContactGroupRequest : To delete contact group
 *
 */
export const deleteContactGroupRequest = (payload, callback) => {
  return {
    type: DELETE_CONTACT_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_CONTACTS_BY_CONTACT_GROUP_REQUEST **/
/****************************************************/

/**
 * @method deleteContactsByContactGroupRequest : To delete Contacts By Contact Group
 *
 */
export const deleteContactsByContactGroupRequest = (payload, callback) => {
  return {
    type: DELETE_CONTACTS_BY_CONTACT_GROUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_CONTACT_GROUP_ORDER_INDEX_REQUEST **/
/****************************************************/

/**
 * @method updateContactGroupOrderIndexRequest : To update order index of contact group 
 *
 */
export const updateContactGroupOrderIndexRequest = (payload) => {
  return {
    type: UPDATE_CONTACT_GROUP_ORDER_INDEX_REQUEST,
    payload
  }
}

/****************************************************/
/** GET_ALL_CONTACT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getAllContactListRequest : To get all available contacts list
 *
 */
export const getAllContactListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ALL_CONTACT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CONTACT_LIST_BY_GROUP_REQUEST **/
/****************************************************/

/**
 * @method getContactListByGroupRequest : To get contacts list by group
 *
 */
export const getContactListByGroupRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CONTACT_LIST_BY_GROUP_REQUEST,
    payload,
    callback
  }
}
