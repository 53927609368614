import { getTableMessages } from "@src/utility/Utils"

export const DEFAULT_TABLE_PAGE_SIZE = 100
export const DEFAULT_CHILD_TABLE_PAGE_SIZE = 10
export const DEFAULT_TABLE_PAGE_SIZE_FOR_BULK_ACTION = 200

export const INITIAL_LIST_STATE = {
  list: [],
  pageNumber: 0,
  totalCount: 0,
  totalPages: 0,
  pageSize: DEFAULT_TABLE_PAGE_SIZE
}

export const TABLE_SCROLL_CONFIG = {
  fixedHeaderScrollHeight: '360px'
}

export const TABLE_VIEW_CUSTOM_MESSAGES = {
  organizationList: getTableMessages('organizations'),
  contactsList: getTableMessages('contacts'),
  projectList: getTableMessages('projects'),
  projectSitesList: getTableMessages('sites'),
  sitePlansList: getTableMessages('site plans'),
  siteRolesList: getTableMessages('site roles'),
  siteUnitsList: getTableMessages('site units'),
  templateList: getTableMessages('templates'),
  checkpointList: getTableMessages('activities'),
  campaignList: getTableMessages('campaigns'),
  campaignServiceList: getTableMessages('campaign services'),
  campaignServiceChecklistList: getTableMessages('campaign service checklists'),
  schedulingSiteTaskList: getTableMessages('scheduling site tasks'),
  programsList: getTableMessages('programs'),
  resourcesList: getTableMessages('resources'),
  campaignObservationTaskList: getTableMessages('campaign observation tasks'),
  manageTaskList: getTableMessages('manage tasks'),
  taskDataCollectionList: getTableMessages('task data collection'),
  actionsList: getTableMessages('task actions'),
  assemblyList: getTableMessages('assembly'),
  siteInspectionList: getTableMessages('site inspection report'),
  programReportList: getTableMessages('program report list'),
  overviewWorkflowList: getTableMessages('overview workflow section'),
  overviewTaskSectionList: getTableMessages('overview tasks section'),
  overviewUnitSectionList: getTableMessages('overview units section'),
  overviewDirectorySectionList: getTableMessages('overview directory section'),
  dataAccess: getTableMessages('data access'),
  siteSectionList: getTableMessages('sites section'),
  saturationSummary: getTableMessages('saturation summary'),
  saturationDetails: getTableMessages('saturation details'),
  programBuilderCheckList: getTableMessages('program builder checklist'),
  sampleAllUnits: getTableMessages('sample all units'),
  siteUnits: getTableMessages('site units'),
  activityList: getTableMessages('activities'),
  activityTemplates: getTableMessages('activity templates'),
  campaignReportList: getTableMessages('campaign report'),
  childOrganizations: getTableMessages('child organizations'),
  relatedTaskDataCollectionList: getTableMessages('related task data collection'),
  communicationLogList: getTableMessages('communication log'),
  overviewPlanSectionList: getTableMessages('overview plan section'),
  campaignSectionList: getTableMessages('campaign section')
}

export const TABLE_LIST_KEYS = {
  CONTACT_LIST_KEY: 'contactList',
  CAMPAIGN_LIST_KEY: 'campaignList',
  PROGRAM_LIST_KEY: 'programList',
  RESOURCE_LIST_KEY: 'resourceList',
  CAMPAIGN_TASK_LIST_KEY: 'campaignTaskList',
  ACTIVITY_LIST_KEY: 'activityList',
  ORGANIZATION_LIST_KEY: 'organizationList',
  PROJECT_LIST_KEY: 'projectList',
  TEMPLATE_LIST_KEY: 'templateList',
  SITE_LIST_KEY: 'siteList',
  SERVICE_LIST_KEY: 'serviceList',
  SITE_ROLE_LIST_KEY: 'siteRoleList',
  CHECKLIST_KEY: 'checkList',
  SITE_PLAN_LIST_KEY: 'sitePlanList',
  SITE_UNIT_LIST_KEY: 'siteUnitList',
  SITE_UNIT_CHILD_GRID_LIST_KEY: 'siteUnitChildGridList',
  CONTACT_PROJECT_LIST_KEY: 'contactProjectList',
  TASK_LIST_KEY: 'taskList',
  WORKFLOW_LIST_KEY: "workflowList",
  WORKFLOW_CHECKLIST_KEY: 'workflowChecklist',
  SCHEDULING_UNIT_LIST_KEY: 'schedulingUnitList',
  DATA_RETENTION_LIST_KEY: 'dataRetentionList',
  NOTIFICATION_LIST: 'notificationList',
  SAMPLING_ALL_UNIT: 'samplingAllUnits',
  SAMPLING_GROUP_UNIT: 'samplingGroupUnits',
  SAMPLING_ALL_GROUPS: 'samplingAllGroups',
  SAMPLING_SELECTED_GROUP: 'samplingSelectedGroup',
  APPLICABLE_REGULATION_LIST: "applicableRegulation",
  REFERENCE_STANDARD_LIST: "refereceStandard",
  INDUSTRY_INTERPRETATION_LIST: "industryInterpretations",
  UNIT_SCHEDULE_LIST: "unitScheduleList",
  TASKS_LIST_KEY: "tasksList",
  GROUP_ACTIVITY_LIST_KEY: "groupActivityList",
  CHECKLIST_GROUP_AVAILABLE_ACTIVITY_LIST_KEY: "checklistGroupAvailableActivityList",
  GROUP_CONTACT_LIST_KEY: "groupContactList",
  ACTIVITY_TEMPLATE_LIST_KEY: "activityTemplateList",
  CHILD_ORGANIZATION_LIST_KEY: "childOrganizationList",
  CAMPAIGN_CONTRACT_LIST_KEY: "campaignContractList",
  CAMPAIGN_CONTRACT_ADDITIONAL_FEE_LIST_KEY: "campaignContractAdditionalFeeList",
  SCHEDULING_CONTRACT_ORDER_DETAILS_LIST_KEY: "schedulingContractOrderDetailsList",
  CONTRACT_WORKFLOW_PRODUCT_LIST_KEY: "contractWorkflowProductList",
  ORGANIZATION_SITE_LIST: "organizationSiteList",
  TEMPLATE_GROUP_AVAILABLE_ACTIVITY_LIST_KEY: "templateGroupAvailableActivityList",
  TEMPLATE_GROUP_ACTIVITY_LIST_KEY: "templateGroupActivityList",
  CONTACT_SITE_LIST_KEY: 'contactSiteList',
  EXTERNAL_DIRECTORY_LIST: 'externalDirectoryList',
  INTERNAL_DIRECTORY_LIST: 'internalDirectoryList',
  COMMUNICATION_LOG_LIST: 'communicationLogList',
  ORG_PLAN_LIST_KEY: 'orgPlanList'
}