import { TEMPLATE_ASSEMBLY_SELECTION_TYPES } from '@views/projects/templates/add-new-template/config'
import config from "./APIConfig"
import { postDataApi } from '../index'

export const addTemplateDetail = (data) => {
  const { url } = config.addTemplateDetail
  const payload = {
    templateName: data.templateName,
    templateCategoryId: data.templateCategory,
    buildingTypeId: data.buildingType.map(x => Number(x.value)),
    scopeOfWorkId: data.scopeOfWork,
    templateStatusId: data.templateStatus
  }
  if (data.templateDescription) {
    payload.templateDescription = data.templateDescription
  }

  if (TEMPLATE_ASSEMBLY_SELECTION_TYPES.includes(data.templateCategory)) {
    payload.assemblyId = Number(data?.assembly || '')
    payload.assemblyTypeId = Number(data?.assemblyType || '')
    payload.componentIds = data.component.map(x => Number(x.componentId)) || []
    payload.photoRequired = data.photoRequired
  }
 
  return postDataApi(url, payload)
}

export default addTemplateDetail
