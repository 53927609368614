import { takeLatest, call, put } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

const { CHECKLIST_ACTIVITIES_GROUP_SAGA } = LANGUAGE_CONSTANTS

/** addActivityGroup API */
function* addActivityGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addActivityGroup, action.payload)
    if (response?.data) {
      action.callback(response.data)
      Notification({ status: 200, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.ADDING_GROUP_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.ERROR_IN_ADDING_GROUP })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getGroupList API */
function* getGroupListRequest(action) {
  try {
    const response = yield call(services.getGroupList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getGroupActivitiesList API */
function* getGroupActivitiesListRequest(action) {
  try {
    const response = yield call(services.getGroupActivitiesList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** deleteActivityGroup API **/
function* deleteActivityGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteActivityGroup, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.REMOVED_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.ERROR_IN_REMOVING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** addActivitiesToGroup API */
function* addActivitiesToGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addActivitiesToGroup, action.payload)
    if (response?.status === 200) {
      action.callback(true)
      Notification({ status: 200, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.ADDING_ACTIVITIES_TO_GROUP_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.ERROR_IN_ADDING_ACTIVITIES_TO_GROUP })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** updateActivityGroup API */
function* updateActivityGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateActivityGroup, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.UPDATE_ACTIVITY_GROUP_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.ERROR_IN_UPDATING_GROUP })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** deleteActivityFromGroup API **/
function* deleteActivityFromGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteActivityFromGroup, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.ACTIVITY_REMOVED_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.ERROR_IN_REMOVING_ACTIVITY })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** reorderGroupActivitiesList API */
function* reorderGroupActivitiesListRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.reorderGroupActivitiesList, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.GROUP_ACTIVITIES_REORDER_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.ERROR_IN_REORDERING_GROUP_ACTIVITIES })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** reorderActivityGroup API */
function* reorderActivityGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.reorderActivityGroup, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.ACTIVITY_GROUP_REORDER_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: CHECKLIST_ACTIVITIES_GROUP_SAGA.ERROR_IN_REORDERING_ACTIVITY_GROUP })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

export default function* checklistActivitiesGroupSagas() {
  yield takeLatest(actions.ADD_ACTIVITY_GROUP_REQUEST, addActivityGroupRequest)
  yield takeLatest(actions.GET_GROUP_LIST_REQUEST, getGroupListRequest)
  yield takeLatest(actions.GET_GROUP_ACTIVITIES_LIST_REQUEST, getGroupActivitiesListRequest)
  yield takeLatest(actions.DELETE_ACTIVITY_GROUP_REQUEST, deleteActivityGroupRequest)
  yield takeLatest(actions.ADD_ACTIVITIES_TO_GROUP_REQUEST, addActivitiesToGroupRequest)
  yield takeLatest(actions.UPDATE_ACTIVITY_GROUP_REQUEST, updateActivityGroupRequest)
  yield takeLatest(actions.DELETE_ACTIVITY_FROM_GROUP_REQUEST, deleteActivityFromGroupRequest)
  yield takeLatest(actions.REORDER_GROUP_ACTIVITIES_LIST_REQUEST, reorderGroupActivitiesListRequest)
  yield takeLatest(actions.REORDER_ACTIVITY_GROUP_REQUEST, reorderActivityGroupRequest)
}
