import config from "./APIConfig"
import { deleteDataApi } from '../../index'

export const deleteCampaignChecklist = (data) => {
  if (data.isSharedTemplateDetail) {
    const { url } = config.deleteCheckpointFromCheckList
    const payload = {
      wfCampaignSetupActivityIds: data.wfCampaignSetupActivityId
    }
    return deleteDataApi(url, null, payload)
  }

  const { url } = config.deleteCampaignChecklist
  return deleteDataApi(url(data))
}

export const deleteCustomizedChildCampaignBlock = (data) => {
  const { url } = config.deleteCustomizedChildCampaignBlock
  return deleteDataApi(url(data))
}
