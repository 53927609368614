import config from "./APIConfig"
import { putDataApi } from "../index"

export const updateNoteHeadline = async (data) => {
  const { url } = config.updateNoteHeadline

  return await putDataApi(url(data))
}

export default updateNoteHeadline
