import config from "./APIConfig"
import { postDataApi } from "../../index"

export const createBlockChecklist = async (data) => {
  const { url } = config.createBlockChecklist
  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    wfbChecklistPhotoTypeId: Number(data.wfbChecklistPhotoTypeId),
    estimatedTime: Number(data.estimatedTime)
  }
  return postDataApi(url, payload, null, { isApiNeedsToHandleErrors: false })
}

export default createBlockChecklist
