// ** React Imports
import { useState, createContext } from 'react'
import { useSelector } from 'react-redux'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

import AppSettings from '@app-settings'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
import messagesEs from '@assets/data/locales/es.json'
import { PREFERRED_LANGUAGES, DEFAULT_LANGUAGE } from '@shared/constants'

// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesEs from '@src/assets/data/locales/es.json'

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  es: { ...messagesEs, ...userMessagesEs }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {

  const langText = useSelector(state => state.auth?.userData[AppSettings.REACT_APP_PREFERRED_LANGUAGE_URL] || DEFAULT_LANGUAGE)
  const langValue = PREFERRED_LANGUAGES.find(lang => lang.text === langText)?.value || ''

  // ** States
  const [locale, setLocale] = useState(langValue)
  const [messages, setMessages] = useState(menuMessages[langValue])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale={langValue}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
