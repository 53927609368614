import config from "../APIConfig"
import { getDataApi } from "../../../index"

export const getAutomateReportVM = async (data) => {
  const { url } = config.getAutomateReportVM
  return await getDataApi(url(data))
}

export const getAutomateReports = async (data) => {
  const { url } = config.getAutomateReports
  return await getDataApi(url(data))
}
