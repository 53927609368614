import { takeLatest, call } from "redux-saga/effects"

import * as services from "@services/api"
import * as actions from "@store/actions"

/** getOverviewDetails API **/
function* getOverviewDetailsRequest(action) {
    try {
      const response = yield call(services.getOverviewDetails, action.payload)
      action.callback(response?.data)
    } catch (error) {
      action.callback()
    }
  }

/** getActiveProjectSites API **/
function* getActiveProjectSitesRequest(action) {
  try {
    const response = yield call(services.getActiveProjectSites, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getActivePrograms API **/
function* getActiveProgramsRequest(action) {
  try {
    const response = yield call(services.getActivePrograms, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getUpcomingObservationTasks API **/
function* getUpcomingObservationTasksRequest(action) {
  try {
    const response = yield call(services.getUpcomingObservationTasks, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getReportWidgetStatus API */
function* getReportWidgetStatusRequest(action) {
  try {
    const response = yield call(services.getReportWidgetStatus, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getUnapprovedRoleChartWidget API */
function* getUnapprovedRoleChartWidgetRequest(action) {
  try {
    const response = yield call(services.getUnapprovedRoleChartWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
      action.callback()
  }
}

/** getUnapprovedRoleChartDelta API */
function* getUnapprovedRoleChartDeltaRequest(action) {
  try {
    const response = yield call(services.getUnapprovedRoleChartDelta, action.payload)
    action.callback(response?.data)
  } catch (error) {
      action.callback()
  }
}

/** getActivityListChartDetails API */
function* getActivityListChartDetailsRequest(action) {
  try {
    const response = yield call(services.getActivityTypeListChartWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
      action.callback()
  }
}

/** getTrainingChartWidget API */
function* getTrainingChartWidgetRequest(action) {
  try {
    const response = yield call(services.getTrainingChartWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
      action.callback()
  }
}

/** getTrainingChartWidgetDelta API */
function* getTrainingChartWidgetDeltaRequest(action) {
  try {
    const response = yield call(services.getTrainingChartDelta, action.payload)
    action.callback(response)
  } catch (error) {
      action.callback()
  }
}

/** getKnowledgeAssessmentWidget API */
function* getKnowledgeAssessmentWidgetRequest(action) {
  try {
    const response = yield call(services.getKnowledgeAssessmentChart, action.payload)
    action.callback(response?.data)
  } catch (error) {
      action.callback()
  }
}

/** getCourseParticipation API */
function* getCourseParticipationRequest(action) {
  try {
    const response = yield call(services.getCourseParticipationWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
      action.callback()
  }
}


/** getDuctLeakageWidgetRequest API */
function* getDuctLeakageWidgetRequest(action) {
  try {
    const response = yield call(services.getDuctLeakageChartRequest, action.payload)
    action.callback(response?.data)
  } catch (error) {
      action.callback()
  }
}

/** getHouseInfiltrationWidgetRequest API */
function* getHouseInfiltrationWidgetRequest(action) {
  try {
    const response = yield call(services.getHouseInfiltrationChartRequest, action.payload)
    action.callback(response?.data)
  } catch (error) {
      action.callback()
  }
}

/** getRecentCampaignActivityWidget API */
function* getRecentCampaignActivityWidgetRequest(action) {
  try {
    const response = yield call(services.getRecentCampaignActivityWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
      action.callback()
  }
}

export default function* overviewPagesSagas() {
    yield takeLatest(actions.GET_OVERVIEW_DETAILS_REQUEST, getOverviewDetailsRequest)
    yield takeLatest(actions.GET_ACTIVE_PROJECT_SITES_REQUEST, getActiveProjectSitesRequest)
    yield takeLatest(actions.GET_ACTIVE_PROGRAMS_REQUEST, getActiveProgramsRequest)
    yield takeLatest(actions.GET_UPCOMING_OBSERVATION_TASKS_REQUEST, getUpcomingObservationTasksRequest)
    yield takeLatest(actions.GET_REPORT_WIDGET_STATUS_REQUEST, getReportWidgetStatusRequest)
    yield takeLatest(actions.GET_UNAPPROVED_ACTIVITIES_ROLE_CHART_STATUS_REQUEST, getUnapprovedRoleChartWidgetRequest)
    yield takeLatest(actions.GET_UNAPPROVED_ROLE_CHART_DELTA_REQUEST, getUnapprovedRoleChartDeltaRequest)
    yield takeLatest(actions.GET_ACTIVITY_TYPE_CHART_REQUEST, getActivityListChartDetailsRequest)
    yield takeLatest(actions.GET_TRAINING_CHART_WIDGET_REQUEST, getTrainingChartWidgetRequest)
    yield takeLatest(actions.GET_TRAINING_CHART_DELTA_REQUEST, getTrainingChartWidgetDeltaRequest)
    yield takeLatest(actions.GET_COURSE_PARTICIPATION_CHART_WIDGET_REQUEST, getCourseParticipationRequest)
    yield takeLatest(actions.GET_KNOWLEDGE_ASSESSMENT_WIDGET_REQUEST, getKnowledgeAssessmentWidgetRequest)
    yield takeLatest(actions.GET_RECENT_CAMPAIGN_ACTIVITY_REQUEST, getRecentCampaignActivityWidgetRequest)
    yield takeLatest(actions.GET_DUCT_LEAKAGE_TRACING_WIDGET_REQUEST, getDuctLeakageWidgetRequest)
    yield takeLatest(actions.GET_HOUSE_INFILTRATION_TRACING_WIDGET_REQUEST, getHouseInfiltrationWidgetRequest)
}
