import { takeLatest, takeEvery, call } from "redux-saga/effects"

import * as services from "@services/api"
import * as actions from "@store/actions"

/** getProgramEnergyVM API  **/
function* getProgramEnergyVMRequest(action) {
  try {
    const response = yield call(services.getProgramEnergyVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** createProgramEnergy API  **/
function* createProgramEnergyRequest(action) {
  try {
    const response = yield call(services.createProgramEnergy, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false, error)
  }
}

/** getProgramEnergy API  **/
function* getProgramEnergyRequest(action) {
  try {
    const response = yield call(services.getProgramEnergy, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** getEnergyDiscrepancyRoles API  **/
function* getEnergyDiscrepancyRolesRequest(action) {
  try {
    const response = yield call(services.getEnergyDiscrepancyRoles, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** getProgramEnergyWithContacts API  **/
function* getProgramEnergyWithContactsRequest(action) {
  try {
    const response = yield call(services.getProgramEnergyWithContacts, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** getProgramEnergyVerificationOption API  **/
function* getProgramEnergyVerificationOptionRequest(action) {
  try {
    const response = yield call(services.getProgramEnergyVerificationOption, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** saveProgramEnergyVerificationOption API  **/
function* saveProgramEnergyVerificationOptionRequest(action) {
  try {
    const response = yield call(services.saveProgramEnergyVerificationOption, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** getRequirementServiceSummary API  **/
function* getRequirementServiceSummaryRequest(action) {
  try {
    const response = yield call(services.getRequirementServiceSummary, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** getProgramRequirementTypes API  **/
function* getProgramRequirementTypesRequest(action) {
  try {
    const response = yield call(services.getProgramRequirementTypes)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** getSiteOrganizationContactList API **/
function* getSiteOrganizationContactListRequest(action) {
  try {
    const response = yield call(services.getSiteOrganizationContactList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

export function* programsEnergyStarSagas() {
  yield takeLatest(actions.GET_PROGRAM_ENERGY_VM_REQUEST, getProgramEnergyVMRequest)
  yield takeLatest(actions.CREATE_PROGRAM_ENERGY_REQUEST, createProgramEnergyRequest)
  yield takeLatest(actions.GET_PROGRAM_ENERGY_REQUEST, getProgramEnergyRequest)
  yield takeLatest(actions.GET_ENERGY_DISCREPANCY_ROLES_REQUEST, getEnergyDiscrepancyRolesRequest)
  yield takeLatest(actions.GET_PROGRAM_ENERGY_WITH_CONTACT_DETAILS_REQUEST, getProgramEnergyWithContactsRequest)
  yield takeLatest(actions.GET_PROGRAM_ENERGY_VERIFICATION_OPTION_REQUEST, getProgramEnergyVerificationOptionRequest)
  yield takeLatest(actions.SAVE_PROGRAM_ENERGY_VERIFICATION_OPTION_REQUEST, saveProgramEnergyVerificationOptionRequest)
  yield takeEvery(actions.GET_REQUIREMENT_SERVICE_SUMMARY_REQUEST, getRequirementServiceSummaryRequest)
  yield takeEvery(actions.GET_PROGRAM_REQUIREMENT_TYPES_REQUEST, getProgramRequirementTypesRequest)
  yield takeEvery(actions.GET_SITE_ORGANIZATION_CONTACTS_REQUEST, getSiteOrganizationContactListRequest)
}

export default programsEnergyStarSagas