import PropTypes from 'prop-types'
import React, { useState, useCallback, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import * as actions from "@store/actions"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"

const WorkflowSearch = ({
  isEditMode,
  isWorkflowDependency,
  workflowSearchParams,
  errors,
  defaultOptions,
  isFindingWorkflows,
  wfCampaignSetupId,
  id,
  isMulti,
  fieldId,
  isRequired,
  label,
  labelRequired,
  name,
  placeholder,
  value,
  initialLabel,
  valueType,
  labelType,
  onSelect,
  getOptionLabel,
  getOptionValue
}) => {

  const dispatch = useDispatch()

  const [options, setOptions] = useState(defaultOptions)

  /**
   * Note: Paginated select maintain cache of options,
   * so to set new default options we need to reset previous cache
   */
  const [isResetCache, setResetCache] = useState(false)
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  useEffect(() => {
    setResetCache((prevState) => !prevState)
    setOptions(defaultOptions)
  }, [defaultOptions])

  useEffect(() => {
    if (!!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])

  //To handle edit mode when single select
  useEffect(() => {
    //Note: To get option details for selected value in edit mode 
    if (isEditMode && !!initialLabel && !isEditModeItemLoaded && !!value) {
      setEditModeItemLoaded(true)
      setSelectedOptionLabel(initialLabel)
    }
  }, [fieldId, initialLabel, isEditMode, isEditModeItemLoaded, value])

  const handleLoadMore = useCallback((search, page, prevOptions) => {
    return new Promise((resolve) => {

      const handleLoadMoreCallback = (response) => {
        if (response) {
          const { items, hasNextPage } = response
          setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
          const formattedItems = items.map(({ wfCampaignSetupId, name }) => { return { value: wfCampaignSetupId, text: name } })
          const updatedItems = isFindingWorkflows ? [...formattedItems] : [...items]
          resolve({
            optionList: updatedItems,
            hasMore: hasNextPage
          })
        } else {
          resolve({
            optionList: [],
            hasMore: false
          })
        }
      }

      let serachParams = {
        pageSize: DEFAULT_VM_PAGE_SIZE,
        pageNumber: page
      }

      if (isWorkflowDependency) {
        serachParams = {
          ...serachParams,
          ...workflowSearchParams,
          isWorkflowDependency,
          genericSearch: search
        }
        
        dispatch(actions.getCampaignWorkflowsRequest(serachParams, handleLoadMoreCallback))
      } else if (isFindingWorkflows) {
        serachParams = {
          ...serachParams,
          wfCampaignSetupId,
          findingWorkflowName: search
        }
        
        dispatch(actions.getFindingWorkflowsRequest(serachParams, handleLoadMoreCallback))
      } else {
        serachParams.workflowName = search
        dispatch(actions.getCalendarWorkflowsDDLRequest(serachParams, handleLoadMoreCallback))
      }
    })
  }, [workflowSearchParams, isWorkflowDependency, wfCampaignSetupId, isFindingWorkflows, options])

  const handleSelect = useCallback(
    (selected) => {
      const label = selected?.[valueType] && selected?.[labelType]
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        if (!!lastElement && !lastElement.value) {
          onSelect(id, [])
        } else {
          onSelect(id, selected)
        }
      } else {
        onSelect(id, selected?.[valueType] || "", label || "")
        setSelectedOptionLabel(label || "")
      }
    },
    [id, labelType, valueType, onSelect]
  )

  const tablePropsConfig = useMemo(() => {
    return {
      menuPortalTarget: document.body,
      menuPosition: 'fixed',
      styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) }
    }
  }, [])

  return (
    <>
      {labelRequired && <CustomLabel title={label} required={isRequired} isLocalizedTitle={!label} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id={fieldId || id}
        name={fieldId || name}
        placeholder={placeholder}
        hideSelectedOptions={false}
        options={options}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={
          !isMulti
            ? !!value
              ? {
                [labelType]: selectedOptionLabel,
                [valueType]: value
              }
              : null
            : value
        }
        isMulti={isMulti}
        isShowCustomAction={false}
        valueType={valueType}
        lableType={labelType}
        onSelect={handleSelect}
        onLoadOptions={handleLoadMore}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        {...tablePropsConfig}
      />
      {errors && <ErrorMessage isShow={!!errors[id]} message={errors[id]} />}
    </>
  )
}

WorkflowSearch.propTypes = {
  isEditMode: PropTypes.bool,
  isWorkflowDependency: PropTypes.bool,
  errors: PropTypes.object,
  workflowSearchParams: PropTypes.object,
  defaultOptions: PropTypes.array,
  wfCampaignSetupId: PropTypes.number,
  id: PropTypes.string,
  fieldId: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  isFindingWorkflows: PropTypes.bool,
  isMulti: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  initialLabel: PropTypes.string,
  valueType: PropTypes.string,
  labelType: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  onSelect: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func
}

WorkflowSearch.defaultProps = {
  isEditMode: false,
  isWorkflowDependency: false,
  isFindingWorkflows: false,
  workflowSearchParams: {},
  defaultOptions: [],
  id: "workflow",
  fieldId: "",
  isRequired: false,
  isMulti: false,
  labelRequired: true,
  name: "workflow",
  value: "",
  initialLabel: "",
  valueType: "value",
  labelType: "text",
  wfCampaignSetupId: 0,
  onSelect: () => { },
  getOptionLabel: (option) => option.text,
  getOptionValue: (option) => option.value
}

const WorkflowsSearch = (props) => {
  const [key, setKey] = useState("")
  
  useEffect(() => {
    setKey(props.fieldId || "")
  }, [props.fieldId])

  return <WorkflowSearch key={key} {...props} />
}

export default WorkflowsSearch
