import { SIGN_OFF_REQUIRED_TYPE, ASSIGNMENT_TYPE } from "@views/campaigns/add-new-campaign/workflow/block/sign-off/config"
import config from "./APIConfig"
import { putDataApi } from "../../index"

/**
 * @method updateBlockTaskSignOff
 * @param {object} data 
 * 
 * {
 *  wfCampaignSetupId: 0,
 *  signOff: string,
 *  responsiblePartyId: string,
 *  assignmentValue: string,
 *  assignmentLabel: string
 * }
 * 
 * API payload:-
 * 
{
  "wfCampaignSetupId": 0,
  "taskSignOffRequired": true,
  "responsiblePartyForSignOffId": 1,
  "responsiblePartyForSignOffContactId": {
    "contactId": 0
  },
  "responsiblePartyForSignOffSitePartyRoleId": {
    "sitePartyRoleId": 0
  }
}
 */

export const updateBlockTaskSignOff = async (data) => {
  const { url } = config.updateBlockTaskSignOff
  const payload = {
  wfCampaignSetupId: Number(data.wfCampaignSetupId),
  taskSignOffRequired: data.signOff === SIGN_OFF_REQUIRED_TYPE.YES,
  responsiblePartyForSignOffId: Number(data.responsiblePartyId),
  responsiblePartyForSignOffContactId: {
    contactId: data.responsiblePartyId === ASSIGNMENT_TYPE.CONTACT ? Number(data.assignmentValue) : 0
  },
  responsiblePartyForSignOffSitePartyRoleId: {
  sitePartyRoleId: data.responsiblePartyId === ASSIGNMENT_TYPE.SITE_ROLE ? Number(data.assignmentValue) : 0
  }
}
  return await putDataApi(url(data.wfCampaignSetupId), payload, null, { isApiNeedsToHandleErrors: false })
}

export default updateBlockTaskSignOff
