import { takeLatest, call } from "redux-saga/effects"

import { getActivityList, getActivityVM } from "@services/api"
import * as actions from "@store/actions"

/** getActivityList API **/
function* getActivityListRequest(action) {
  try {
    const response = yield call(getActivityList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getActivityVM API **/
function* getActivityVMRequest(action) {
  try {
    const response = yield call(getActivityVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export function* activitySagas() {
  yield takeLatest(actions.GET_ACTIVITY_LIST_REQUEST, getActivityListRequest)
  yield takeLatest(actions.GET_ACTIVITY_VM_REQUEST, getActivityVMRequest)
}

export default activitySagas