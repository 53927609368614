import config from "./APIConfig"
import { postDataApi } from "../index"

/**
 * @method sendEmail : To send email with/without files
 * @param {Object} data 
 * 
 * {
  "emailList": [
    {
      "contactId": 0,
      "email": "string"
    }
  ],
  "filesList": [
    {
      "fileURL": "string",
      "resourceCenterId": 0,
      "fileName": "string"
    }
  ],
  "body": "string",
  "subject": "string"
}

 * @returns 
 */
export const sendEmail = async (data) => {
  const { url } = config.sendEmail
  return postDataApi(url, data, undefined, { pathConfig: { isCheckpointInspectionService: true } })
}

export default sendEmail
