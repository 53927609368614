export const GET_PROGRAM_ENERGY_VM_REQUEST = "GET_PROGRAM_ENERGY_VM_REQUEST"
export const CREATE_PROGRAM_ENERGY_REQUEST = "CREATE_PROGRAM_ENERGY_REQUEST"
export const GET_PROGRAM_ENERGY_REQUEST = "GET_PROGRAM_ENERGY_REQUEST"
export const GET_ENERGY_DISCREPANCY_ROLES_REQUEST = "GET_ENERGY_DISCREPANCY_ROLES_REQUEST"
export const GET_PROGRAM_ENERGY_WITH_CONTACT_DETAILS_REQUEST = "GET_PROGRAM_ENERGY_WITH_CONTACT_DETAILS_REQUEST"
export const SAVE_PROGRAM_ENERGY_VERIFICATION_OPTION_REQUEST = "SAVE_PROGRAM_ENERGY_VERIFICATION_OPTION_REQUEST"
export const GET_PROGRAM_ENERGY_VERIFICATION_OPTION_REQUEST = "GET_PROGRAM_ENERGY_VERIFICATION_OPTION_REQUEST"
export const GET_PROGRAM_REQUIREMENT_TYPES_REQUEST = "GET_PROGRAM_REQUIREMENT_TYPES_REQUEST"
export const GET_REQUIREMENT_SERVICE_SUMMARY_REQUEST = "GET_REQUIREMENT_SERVICE_SUMMARY_REQUEST"
export const GET_SITE_ORGANIZATION_CONTACTS_REQUEST = "GET_SITE_ORGANIZATION_CONTACTS_REQUEST"

/****************************************************/
/** GET_PROGRAM_ENERGY_VM_REQUEST **/
/****************************************************/

/**
 * @method getProgramEnergyVMRequest : To get program energy vm
 *
 */
export const getProgramEnergyVMRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_ENERGY_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_PROGRAM_ENERGY_REQUEST **/
/****************************************************/

/**
 * @method createProgramEnergyRequest : To save program energy 
 *
 */
export const createProgramEnergyRequest = (payload, callback) => {
  return {
    type: CREATE_PROGRAM_ENERGY_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_ENERGY_REQUEST **/
/****************************************************/

/**
 * @method getProgramEnergyRequest : To get program energy 
 *
 */
export const getProgramEnergyRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_ENERGY_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ENERGY_DISCREPANCY_ROLES_REQUEST **/
/****************************************************/

/**
 * @method getEnergyDiscrepancyRolesRequest : To get energy discrepancy roles
 *
 */
export const getEnergyDiscrepancyRolesRequest = (payload, callback) => {
  return {
    type: GET_ENERGY_DISCREPANCY_ROLES_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_ENERGY_WITH_CONTACT_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method getProgramEnergyWithContactsRequest : To get program energy with contact details
 *
 */
export const getProgramEnergyWithContactsRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_ENERGY_WITH_CONTACT_DETAILS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SAVE_PROGRAM_ENERGY_VERIFICATION_OPTION_REQUEST **/
/****************************************************/

/**
 * @method saveProgramEnergyVerificationOptionRequest : To get program energy with contact details
 *
 */
export const saveProgramEnergyVerificationOptionRequest = (payload, callback) => {
  return {
    type: SAVE_PROGRAM_ENERGY_VERIFICATION_OPTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_ENERGY_VERIFICATION_OPTION_REQUEST **/
/****************************************************/

/**
 * @method getProgramEnergyVerificationOptionRequest : To get program energy verification option
 *
 */
export const getProgramEnergyVerificationOptionRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_ENERGY_VERIFICATION_OPTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_REQUIREMENT_TYPES_REQUEST **/
/****************************************************/

/**
 * @method getProgramRequirementTypesRequest : To get program requirement types
 *
 */
export const getProgramRequirementTypesRequest = (callback) => {
  return {
    type: GET_PROGRAM_REQUIREMENT_TYPES_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_REQUIREMENT_SERVICE_SUMMARY_REQUEST **/
/****************************************************/

/**
 * @method getRequirementServiceSummaryRequest : To get requirement service summary
 *
 */
export const getRequirementServiceSummaryRequest = (payload, callback) => {
  return {
    type: GET_REQUIREMENT_SERVICE_SUMMARY_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SITE_ORGANIZATION_CONTACTS_REQUEST **/
/****************************************************/

/**
 * @method getSiteOrganizationContactListRequest : To Get Paginated contact list
 *
 */
 export const getSiteOrganizationContactListRequest = (payload, callback) => {
  return {
    type: GET_SITE_ORGANIZATION_CONTACTS_REQUEST,
    payload,
    callback
  }
}
