import config from "./APIConfig"
import { getDataApi } from '../index'

export const getAddProjectSiteUnitVM = (siteId) => {
  const { url } = config.getAddProjectSiteUnitVM
  return getDataApi(url(siteId))
}

export const getAuthorityName = async (data) => {
  const { url } = config.getAuthorityName
  return await getDataApi(url({ authoritytypeid: data.authoritytypeid }))
}

export const getContactByOrganization = async (data) => {
  const { url } = config.getContactByOrganization
  return await getDataApi(url(data))
}

export const getContactProjectList = async (data) => {
  const { url } = config.getContactProjectList
  return await getDataApi(url(data))
}

export const getContactSiteRoleList = async (data) => {
  const { url } = config.getContactSiteRoleList
  return await getDataApi(url(data))
}

export const getCreateProjectVM = async () => {
  const { url } = config.getCreateProjectVM
  return await getDataApi(url)
}

export const getDDLSitePlanSchedulePrereqs = async (data) => {
  const { url } = config.getDDLSitePlanSchedulePrereqs
  return await getDataApi(url(data))
}

export const getHomeSiteDDL = async (data) => {
  const { url } = config.getHomeSiteDDL
  return await getDataApi(url(data))
}

export const getProjectAddress = async (data) => {
  const { url } = config.getProjectAddress
  return await getDataApi(url({ projectId: data.projectId }))
}

export const getProjectDetails = async (data) => {
  const { url } = config.getProjectDetails
  return await getDataApi(url({ projectId: data.projectId }))
}

export const getProjectList = async (data) => {
  const { url } = config.getProjectList
  return await getDataApi(url(data))
}

export const getProjectListVM = async () => {
  const { url } = config.getProjectListVM
  return await getDataApi(url)
}

export const getProjectSiteDDL = async (data) => {
  const { url } = config.getProjectSiteDDL
  return await getDataApi(url(data))
}

export const getProjectSiteDetails = async (data) => {
  const { url } = config.getProjectSiteDetails
  return await getDataApi(url({ siteId: data.siteId }))
}

export const getProjectSiteList = async (data) => {
  const { url } = config.getProjectSiteList
  return await getDataApi(url(data))
}

export const getProjectSitePlan = async (data) => {
  const { url } = config.getProjectSitePlan
  return await getDataApi(url({ planId: data.planId, version: data.version }))
}

export const getProjectSitePlanList = async (data) => {
  const { url } = config.getProjectSitePlanList
  return await getDataApi(url({
    siteId: data.siteId, pageNumber: data.pageNumber, pageSize: data.pageSize, advanceSearch: data.advanceSearch, genericSearch: data.genericSearch, orderColumn: data.orderColumn, orderDirections: data.orderDirections
  }))
}

export const getSitePlanScheduleList = async (data) => {
  const { url } = config.getSitePlanScheduleList
  return await getDataApi(url(data))
}

export const getProjectSiteRole = async (data) => {
  const { url } = config.getProjectSiteRole
  return await getDataApi(url({ roleId: data.roleId }))
}

export const getProjectSiteRoleList = async (data) => {
  const { url } = config.getProjectSiteRoleList
  return await getDataApi(
    url({
      siteId: data.siteId,
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
      advanceSearch: data.advanceSearch,
      genericSearch: data.genericSearch,
      orderColumn: data.orderColumn,
      orderDirections: data.orderDirections
    }))
}

export const getProjectSiteRolesVM = async (data) => {
  const { url } = config.getProjectSiteRolesVM
  return await getDataApi(url(data.orgId))
}

export const getProjectSiteSearch = async (data) => {
  const { url } = config.getProjectSiteSearch
  return await getDataApi(url(data))
}

export const getProjectSiteUnit = async (unitId) => {
  const { url } = config.getProjectSiteUnit
  return await getDataApi(url(unitId))
}

export const getProjectSiteUnitList = async (data) => {
  const { url } = config.getProjectSiteUnitList
  return await getDataApi(url({
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    genericSearch:
      data.genericSearch || '',
    advanceSearch:
      data.advanceSearch || {},
    additionalLocation: data.additionalLocation || {},
    planDetail: data.planDetail || {},
    orderColumn: data.orderColumn,
    orderDirections: data.orderDirections
  }))
}

export const getUnitWorkflowListCount = async (data) => {
  const { url } = config.getUnitWorkflowListCount
  return await getDataApi(url(data))
}

export const getProjectSiteVM = async (data) => {
  const { url } = config.getProjectSiteVM
  return await getDataApi(url(data))
}

export const getWorkflowList = async (data) => {
  const { url } = config.getWorkflowList
  return await getDataApi(url(data))
}

export const getProjectsFilteredOrganizations = async (data) => {
  const { url } = config.getProjectsFilteredOrganizations
  return await getDataApi(url(data))
}

export const getSiteCampaignsMissingSiteRoles = async (data) => {
  const { url } = config.getSiteCampaignsMissingSiteRoles
  return await getDataApi(url(data))
}

export const getSitePartyRoleVM = async (data) => {
  const { url } = config.getSitePartyRoleVM
  return await getDataApi(url(data))
}

export const getSiteRoleAssignmentDDL = async (payload) => {
  const { url } = config.getSiteRoleAssignmentDDL
  return await getDataApi(url(payload))
}

export const getSiteRolesVM = async () => {
  const { url } = config.getSiteRolesVM
  return await getDataApi(url())
}

export const getSiteUnitChildGrid = async (data) => {
  const { url } = config.getSiteUnitChildGrid
  return await getDataApi(url(data))
}

export const getSiteUnits = async (data) => {
  const { url } = config.getSiteUnits
  return await getDataApi(url(data))
}

export const getSiteUnLinkedSharedCampaigns = async (data) => {
  const { url } = config.getSiteUnLinkedSharedCampaigns
  return await getDataApi(url(data))
}

export const getUnitBlockList = async (data) => {
  const { url } = config.getUnitBlockList
  return await getDataApi(url(data))
}

export const getUnitPlanDetails = async (data) => {
  const { url } = config.getUnitPlanDetails
  return await getDataApi(url(data))
}

export const getUnitsListDDL = async (data) => {
  const { url } = config.getUnitsListDDL
  return await getDataApi(url(data))
}
