import { SET_INSPECTION_TYPE_ID } from '../../actions'

// ** Initial State
const initialState = {
  inspectionTypeId: null
}

const TaskDataCollectionReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_INSPECTION_TYPE_ID:
      return {
        ...state,
        inspectionTypeId: action.payload
      }

    default:
      return state
  }
}

export default TaskDataCollectionReducer
