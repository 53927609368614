import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method saveImagesAndNotes
 * @param {object} data
 * {
    "assignmentActivityLogId": 0,
    "note": "string",
    "isDeleted": true,
    "responseImages": [
      {
        "fileName": "string",
        "imageContent": "string",
        "imageId": 0,
        "jsonImageMetaData": "string",
        "isDeleted": true
      }
    ]
  }
 * 
 *
 */
export const saveImagesAndNotes = (payload) => {
  const { url } = config.saveImagesAndNotes

  return postDataApi(url(payload), payload.data)
}

export default saveImagesAndNotes
