import { TEMPLATE_ASSEMBLY_SELECTION_TYPES } from '@views/projects/templates/add-new-template/config'
import config from "./APIConfig"
import { putDataApi } from '../index'

export const updateTemplateDetail = async (data) => {
  const { url } = config.updateTemplateDetail
  const payload = {
    templateId: Number(data.templateId) || 0,
    templateName: data.templateName || '',
    templateCategoryId: Number(data.templateCategory) || '',
    buildingTypeId: data.buildingType.map(x => Number(x.value)),
    scopeOfWorkId: Number(data.scopeOfWork) || '',
    templateDescription: data.templateDescription || "",
    templateStatusId: Number(data.templateStatus) || '',
    templateVersionId: Number(data.templateVersionId) || ''
  }

  if (TEMPLATE_ASSEMBLY_SELECTION_TYPES.includes(data.templateCategory)) {
    payload.assemblyId = Number(data?.assembly || '')
    payload.assemblyTypeId = Number(data?.assemblyType || '')
    payload.componentIds = data.component.map(x => Number(x.componentId)) || []
    payload.photoRequired = data.photoRequired
  }

  return await putDataApi(url({ templateId: data.templateId, templateVersionId: data.templateVersionId }), payload)
}

export default updateTemplateDetail