import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"

const { FILTER_TABLE_MODAL: { ERRORS } } = LANGUAGE_CONSTANTS

export const validateSaveFilterConfig = ({
  intl,
  filterName
}) => {
  const errors = {}
  if (!filterName.trim()) {
    errors['filterName'] = getLocaleMessage(intl, ERRORS.FILTER_NAME_REQUIRED)
  }

  return errors
}

export const getUpdatedTableColumns = ({
  allColumns, 
  formattedColumns
}) => {
  const hasSelectionCol = allColumns.findIndex(c => c.colId === "0")
  const hasActionsCol = allColumns.findIndex(c => c.colId === "actions")
  if (hasSelectionCol !== -1) {
    formattedColumns.splice(0, 0, allColumns[hasSelectionCol])
  }
  if (hasActionsCol !== -1) {
    formattedColumns.push(allColumns[hasActionsCol])
  }
  return formattedColumns
}

export const getFilterSectionCount = ({
  filtersCount = {},
  prevValue,
  value,
  sectionKey
}) => {
  try {
    const updatedCountList = {
      ...filtersCount
    }
    let sectionCount = updatedCountList[sectionKey]

    if (typeof value === "object") {
      if (Array.isArray(value) && Array.isArray(prevValue)) {
        const addedItems = value.filter(item => !prevValue.includes(item))
        const removedItems = prevValue.filter(item => !value.includes(item))
        sectionCount += addedItems.length
        sectionCount -= removedItems.length
      } else {
        if ((value instanceof Date && !isNaN(value)) || (prevValue instanceof Date && !isNaN(prevValue))) {
          //For typeof value === "date"
          if (!!value && !prevValue) {
            sectionCount += 1
          } else if (!value && !!prevValue) {
            sectionCount -= 1
          }
        }
      }
    } else if (typeof value === "boolean") {
      if (value && prevValue !== value) {
        sectionCount += 1
      } else if (!value && prevValue !== value) {
        sectionCount -= 1
      }
    } else if (typeof value === "string") {
      if (!!value.trim() && !prevValue.trim()) {
        sectionCount += 1
      } else if (!value.trim() && !!prevValue.trim()) {
        sectionCount -= 1
      }
    }
    updatedCountList[sectionKey] = sectionCount
  
    return updatedCountList
  } catch (err) {}
}

export const getAllFilterSectionsCount = ({
  filterSectionList = {},
  filtersCount = {},
  searchDetail = {}
}) => {
  try {
    const updatedCountList = {
      ...filtersCount
    }
    let sectionCount = 0
    Object.keys(filterSectionList).forEach(sectionKey => {
    const searchKeyList = filterSectionList[sectionKey]
    searchKeyList.forEach(searchKey => {
      const value = searchDetail[searchKey]
      if (typeof value === "object") {
        if (Array.isArray(value)) {
          sectionCount += value.length
        } else if (!!value) {
          sectionCount += 1
        }
      } else if (typeof value === "boolean" && value) {
        sectionCount += 1
      } else if (typeof value === "string" && !!value) {
        sectionCount += 1
      }
    })
    
    updatedCountList[sectionKey] = sectionCount
    sectionCount = 0
    })
  
    return updatedCountList
  } catch (err) {}
}
