import cx from "classnames"
import React, { useCallback, useMemo } from "react"
import { ChevronRight, ChevronLeft } from "react-feather"
import { ReactSortable } from "react-sortablejs"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { cloneArrayOfObject, getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"
import { CustomCheckbox } from "@views/components"
import { dragDotsVer } from "@src/assets/images/new-theme"
import { FILTER_SECTION_TYPE } from "./config"
import { getUpdatedTableColumns } from "./Helpers"

const { FILTER_TABLE_MODAL } = LANGUAGE_CONSTANTS

const TableColumn = ({
  intl,
  columns,
  expandedViewId,
  selectedFilterId,
  onResetFilter,
  onSetColumns,
  onToggleView
}) => {
  const columnList = useMemo(() => columns.filter(c => c.colId !== "0" && c.colId !== "actions").map((item, index) => {
    return {
      ...item,
      itemIndex: index
    }
  }), [columns])

  const handleToggleView = useCallback(() => {
    onToggleView(FILTER_SECTION_TYPE.TABLE_COLUMN)
  }, [onToggleView])

  const handleChangeColumnConfig = useCallback(
    (index, key, value) => {
      const columnsConfig = cloneArrayOfObject(columnList)
      const updatedConfig = Object.assign({}, columnsConfig[index])
      updatedConfig[key] = value
      columnsConfig[index] = updatedConfig
      onSetColumns(getUpdatedTableColumns({
        allColumns: columns,
        formattedColumns: [...columnsConfig]
      }))
      if (!!selectedFilterId) {
        onResetFilter()
      }
    },
    [columnList, columns, selectedFilterId, onSetColumns, onResetFilter]
  )

  const handleChangeColumnOrder = useCallback((columnsConfig, sortable) => {
    onSetColumns(getUpdatedTableColumns({
      allColumns: columns,
      formattedColumns: columnsConfig.map((item) => ({ ...item, itemIndex: undefined }))
    }))
    if (!!selectedFilterId && !!sortable) {
      onResetFilter()
    }
  }, [columns, selectedFilterId, onSetColumns, onResetFilter])

  const handleResetFilter = useCallback(() => {
    onResetFilter(FILTER_SECTION_TYPE.TABLE_COLUMN)
  }, [onResetFilter])

  const isOpen = useMemo(
    () => (expandedViewId === FILTER_SECTION_TYPE.TABLE_COLUMN),
    [expandedViewId]
  )

  const hasNotSelectedColumn = useMemo(() => {
    return columnList.every(item => item.hide)
  }, [columnList])

  return (
    <div className={cx("setup-accordion-header", {
      'no-active-filter': hasNotSelectedColumn
    })}>
      <div className="d-flex justify-content-between setup-inner-header">
        <button
          type="button"
          className={cx(
            "waves-effect btn advanced-btn more-less-btn text-capitalize",
            { active: isOpen }
          )}
          onClick={handleToggleView}
        >
          {getLocaleMessage(intl, FILTER_TABLE_MODAL.TABLE_COLUMNS)}
          {isOpen ? <ChevronRight /> : <ChevronLeft />}
        </button>
        <button
          className={cx(
            "btn primary-text-btn",
            { active: isOpen }
          )}
          type="button"
          onClick={handleResetFilter}
        >
          {getLocaleMessage(intl, FILTER_TABLE_MODAL.RESET)}
        </button>
      </div>
      {isOpen && (
        <div className="check-list-outer custom-checkbox-search">
          <ReactSortable
            list={columnList || []}
            setList={handleChangeColumnOrder}
          >
            {columnList.map((col, index) => {
              return (
                <div key={`table-column-${col.colId}-${index}`} className="check-list-box">
                  {col.colName && (
                    <>
                      <CustomCheckbox
                        isNewListTheme
                        isChecked={!col.hide}
                        onChange={() => handleChangeColumnConfig(index, "hide", !col.hide)
                        }
                      />
                      <span>
                        {col.colName}
                        <img
                          src={dragDotsVer}
                          onClick={() => handleChangeColumnOrder()}
                        />
                      </span>
                    </>
                  )}
                </div>
              )
            })}
          </ReactSortable>
        </div>
      )}
    </div>
  )
}

export default localeMessageWrapper(TableColumn)
