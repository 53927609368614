// ** Initial user ability
export const initialAbility = [
  {
    //ToDo: To be uncommented when auth flow added
    action: 'read',
    subject: 'Auth'
  }
]

export const _ = undefined
