import PropTypes from 'prop-types'
import React, { useState, useCallback, useEffect, useMemo } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"
import { getReactSelectPositionProps } from "@views/helpers"

import localeMessageWrapper from "../locale-message"

const { PROJECT_SITE_SEARCH } = LANGUAGE_CONSTANTS

const ProjectSiteSearch = ({
  intl,
  isColWrapper,
  isEditMode,
  asyncInitialValue,
  isCampaignBasedReset, //Note: If sites DDL needs to be re-fetched based on campaign selection, then true else false
  campaigns,
  campaignId,
  siteSearchParams,
  reportParams,
  isAllOption, //For getting --All-- as a option in VM
  showAllSite,
  isDisabled,
  projectId,
  organizationId,
  customActionLabel,
  errors,
  customSelectedLable,
  isUnlinkedSiteList,
  defaultOptions,
  id,
  isMulti,
  isRequired,
  isShowCustomAction,
  label,
  labelRequired,
  name,
  placeholder,
  value,
  initialLabel,
  valueType,
  isCalendarPageSites,
  isCampaignPageSites,
  isHomePageSites,
  isAssignmentPageSites,
  observationTaskIds,
  labelType,
  wrapperStyle,
  isShowSelectedAtTop,
  isDashboardSiteList,
  customComponents = {},
  onSelect = () => { },
  getOptionLabel = (option) => option.text,
  getOptionValue = (option) => option.value
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState(defaultOptions)

  /**
   * Note: Paginated select maintain cache of options,
   * so to set new default options we need to reset previous cache
   */
  const [isResetCache, setResetCache] = useState(false)
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  /**
   * @method handleSetDefaultOptions : To handle set default options in case of Edit mode
   *
   * Note: To handle case of duplicate option, when custom option is added, that is selected from edit mode
   */
  const handleSetDefaultOptions = useCallback(
    (selectedItem, selectedValue) => {
      const checkIfAlreadyPresent = defaultOptions.find(
        (option) => option.value === selectedValue
      )
      if (!!checkIfAlreadyPresent) {
        setOptions(defaultOptions)
      } else {
        setOptions([selectedItem, ...defaultOptions])
      }
    },
    [defaultOptions]
  )

  useEffect(() => {
    if (!isMulti && !!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])

  useEffect(() => {
    const fn = () => {
      setTimeout(() => {
        setResetCache((prevState) => !prevState)
        setOptions(defaultOptions)
      }, [100])
    }
    fn()
  }, [projectId, organizationId, campaignId, reportParams, siteSearchParams?.organizations])

  useEffect(() => {
    if (isCampaignBasedReset) {
      setResetCache((prevState) => !prevState)
      setOptions(defaultOptions)
    }
  }, [campaigns, campaignId])

  //To handle edit mode when single select
  useEffect(() => {
    //Note: //Note: To get option details for selected value in edit mode 
    if (isEditMode && !isMulti && !initialLabel) {
      if (!isEditModeItemLoaded && !!value && projectId) {
        setEditModeItemLoaded(true)
        dispatch(
          actions.getProjectSiteDetailsRequest(
            {
              siteId: value,
              isLoadingRequired: false
            },
            (result) => {
              if (result) {
                const selectedItem = {
                  text: result.siteName,
                  value: result.siteId.toString()
                }
                setSelectedOptionLabel(selectedItem.text || "")
                setResetCache((prevState) => !prevState)
                handleSetDefaultOptions(selectedItem, value)
              }
            }
          )
        )
      }
    }
  }, [initialLabel, isEditMode, isEditModeItemLoaded, projectId, value])

  useEffect(() => {
    if (
      !isEditModeItemLoaded &&
      isEditMode &&
      asyncInitialValue &&
      !!value &&
      !!initialLabel
    ) {
      const selectedItem = {
        text: initialLabel,
        value: value.toString()
      }
      setEditModeItemLoaded(true)
      setSelectedOptionLabel(initialLabel)
      setResetCache((prevState) => !prevState)
      handleSetDefaultOptions(selectedItem, value)
    }
  }, [defaultOptions, asyncInitialValue, initialLabel, isEditMode, isEditModeItemLoaded, value])


  const handleLoadMoreOptions = useCallback(
    (search, page, prevOptions) => {
      return new Promise((resolve) => {
        const handleSearchResultCallback = (response) => {
          if (response) {
            const { items, hasNextPage } = response
            setOptions(() => (!!prevOptions.length ? [...prevOptions, ...items] : items))
            const formattedItems = items.map(({ siteId, siteName }) => { return { value: siteId, text: siteName } })
            let updatedItems = isUnlinkedSiteList ? [...formattedItems] : [...items]
            if (isShowSelectedAtTop && !search.trim()) {
              if (page === 1) {
                updatedItems = [...options, ...items.filter(item => options.every(option => item[valueType] !== option[valueType]))]
              } else {
                updatedItems = [...items.filter(item => value.every(option => item[valueType] !== option[valueType]))]
              }
            }

            resolve({
              optionList: updatedItems,
              hasMore: hasNextPage
            })
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }
        if (!projectId && !reportParams && !isCalendarPageSites && !isAssignmentPageSites && !isHomePageSites && !isCampaignPageSites && !isUnlinkedSiteList && !isDashboardSiteList) {
          const params = {
            siteName: search,
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            allOption: isAllOption,
            campaigns,
            campaignId,
            organizationId
          }

          dispatch(
            actions.getProjectSiteDDLRequest(params, handleSearchResultCallback)
          )
        } else if (!projectId && reportParams && !isCalendarPageSites && !isAssignmentPageSites && !isHomePageSites && !isDashboardSiteList) {
          const params = {
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            siteName: search,
            ...reportParams
          }
          dispatch(actions.getReportSiteDDLRequest(params, handleSearchResultCallback))
        } else if (isCalendarPageSites && !projectId && !reportParams && !isAssignmentPageSites && !isDashboardSiteList) {
          const params = {
            siteName: search,
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE
          }
          dispatch(
            actions.getCalendarSitesDDLRequest(
              params,
              handleSearchResultCallback
            )
          )
        } else if (!isCalendarPageSites && !projectId && !reportParams && isAssignmentPageSites && observationTaskIds && !isHomePageSites && !isDashboardSiteList) {
          const params = {
            observationTaskIds,
            siteName: search,
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE
          }
          dispatch(
            actions.getAssignmentSitesListRequest(
              params,
              handleSearchResultCallback
            )
          )
        } else if (isHomePageSites) {
          const params = {
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            siteName: search,
            ...siteSearchParams
          }
          dispatch(
            actions.getHomeSiteDDLRequest(
              params,
              handleSearchResultCallback
            )
          )
        } else if (isCampaignPageSites && campaignId) {
          const params = {
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            siteName: search,
            campaignId
          }
          dispatch(
            actions.getSiteDDLByCampaignIdRequest(
              params,
              handleSearchResultCallback
            )
          )
        } else if (isUnlinkedSiteList && campaignId) {
          const params = {
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            genericSearch: search,
            campaignId
          }
          dispatch(
            actions.getSharedCampaignUnLinkedSiteListRequest(
              params,
              handleSearchResultCallback
            )
          )
        } else if (reportParams && isDashboardSiteList) {
          const params = {
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            siteName: search,
            ...reportParams
          }
          dispatch(actions.getDashboardSiteDDLRequest(params, handleSearchResultCallback))
        } else {
          const params = {
            siteName: search,
            projectId,
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            showAllSite
          }
          dispatch(
            actions.getProjectSiteSearchRequest(
              params,
              handleSearchResultCallback
            )
          )
        }
      })
    },
    [isShowSelectedAtTop, value, campaigns, campaignId, projectId, organizationId, reportParams, options, isCampaignPageSites, isUnlinkedSiteList, isCalendarPageSites, isAssignmentPageSites, observationTaskIds, isHomePageSites, siteSearchParams]
  )

  const handleSelect = useCallback(
    (selected) => {
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        if (!!lastElement && !lastElement.value) {
          onSelect(id, [])
        } else {
          onSelect(id, selected)
        }
      } else {
        onSelect(id, selected?.value || "", (selected?.value && selected?.text) || "")
        setSelectedOptionLabel((selected?.value && selected?.text) || "")
      }
    },
    [onSelect, id]
  )

  const handleRefreshOptions = useCallback(() => {
    setOptions([...value])   
    setResetCache(prevState => !prevState)
  }, [value])
  
  useEffect(() => {
    if (!!observationTaskIds.length) {
      handleRefreshOptions()
    }
  }, [observationTaskIds])
  
  const reactSelectPostionProps = useMemo(() => {
    return getReactSelectPositionProps()
  }, [])

  const WrapperComponent = useMemo(() => (isColWrapper ? Col : 'div'), [isColWrapper])

  const colWrapperProps = useMemo(() => {
    if (!isColWrapper) {
      return {}
    }

    return {
      className: "mb-1",
      sm: wrapperStyle.sm,
      md: wrapperStyle.md,
      xl: wrapperStyle.xl
    }
  }, [isColWrapper, wrapperStyle])

  return (
    <WrapperComponent {...colWrapperProps}>
      {labelRequired && <CustomLabel title={label || PROJECT_SITE_SEARCH.LABEL} required={isRequired} isLocalizedTitle={!label} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        customActionLabel={
          customActionLabel || getLocaleMessage(intl, PROJECT_SITE_SEARCH.ADD_SITE)
        }
        id={id}
        name={name}
        placeholder={
          placeholder || getLocaleMessage(intl, PROJECT_SITE_SEARCH.PLACEHOLDER)
        }
        options={options}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        customSelectedLable={customSelectedLable}
        value={
          !isMulti
            ? !!value
              ? {
                text: selectedOptionLabel,
                value
              }
              : null
            : value
        }
        isShowCustomAction={isShowCustomAction}
        valueType={valueType}
        labelType={labelType}
        isMulti={isMulti}
        hideSelectedOptions={false}
        customComponents={customComponents}
        isDisabled={isDisabled}
        isShowSelectedAtTop={isShowSelectedAtTop}
        onSelect={handleSelect}
        onLoadOptions={handleLoadMoreOptions}
        onRefreshOptions={handleRefreshOptions}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        {...reactSelectPostionProps}
      />
      {errors && <ErrorMessage isShow={!!errors[id]} message={errors[id]} />}
    </WrapperComponent>
  )
}

ProjectSiteSearch.propTypes = {
  intl: PropTypes.object,
  isColWrapper: PropTypes.bool,
  isEditMode: PropTypes.bool,
  asyncInitialValue: PropTypes.bool,
  showAllSite: PropTypes.string,
  customSelectedLable: PropTypes.string,
  isDisabled: PropTypes.bool,
  wrapperStyle: PropTypes.object,
  projectId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  organizationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  errors: PropTypes.object,
  defaultOptions: PropTypes.array,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  isCalendarPageSites: PropTypes.bool,
  isCampaignPageSites: PropTypes.bool,
  isAssignmentPageSites: PropTypes.bool,
  observationTaskIds: PropTypes.array,
  isShowCustomAction: PropTypes.bool,
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  isUnlinkedSiteList: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  initialLabel: PropTypes.string,
  valueType: PropTypes.string,
  labelType: PropTypes.string,
  onSelect: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  isAllOption: PropTypes.bool,
  isCampaignBasedReset: PropTypes.bool,
  campaigns: PropTypes.array,
  campaignId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  reportParams: PropTypes.object,
  siteSearchParams: PropTypes.object, 
  isShowSelectedAtTop: PropTypes.bool
}

ProjectSiteSearch.defaultProps = {
  isColWrapper: true,
  isEditMode: false,
  asyncInitialValue: false,
  customSelectedLable: "",
  showAllSite: true,
  isCalendarPageSites: false,
  isCampaignPageSites: false,
  isAssignmentPageSites: false,
  observationTaskIds: [], 
  isDisabled: false,
  projectId: 0,
  organizationId: 0,
  defaultOptions: [],
  id: "siteIds",
  isMulti: true,
  isRequired: false,
  isShowCustomAction: false,
  isUnlinkedSiteList: false,
  isDashboardSiteList: false,
  labelRequired: true,
  name: "site",
  value: "",
  initialLabel: "",
  valueType: "value",
  labelType: "text",
  isAllOption: false,
  isCampaignBasedReset: false,
  wrapperStyle: {
    md: 6,
    sm: 12,
    xl: 4
  },
  isShowSelectedAtTop: false
}

export default localeMessageWrapper(ProjectSiteSearch)
