import config from "./APIConfig"
import { getDataApi } from '../index'

export const getUserManagementContactVM = () => {
  const { url } = config.getUserManagementContactVM
  return getDataApi(url)
}

export const getUserManagementUserAccess = (email) => {
  const { url } = config.getUserManagementUserAccess
  return getDataApi(url(email))
}

export const getUserManagementUserDetail = (email) => {
  const { url } = config.getUserManagementUserDetail
  return getDataApi(url(email))
}

export const getUserManagementUserRole = (data) => {
  const { url } = config.getUserManagementUserRole
  return getDataApi(url(data))
}

export const getUserManagementProfileType = (data) => {
  const { url } = config.getUserManagementProfileType
  return getDataApi(url(data))
}
