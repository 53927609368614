import { getSearchQuery } from '../config'

const apiConfig = {
    getCampaignDetailVM: {
        method: 'GET',
        url: () => `api/Campaign/SetupVM`
    },
    getCampaignDescription: {
        method: 'GET',
        url: ({ campaignId }) => `api/Campaign/description/${campaignId}`
    },
    addCampaignDetail: {
        method: 'POST',
        url: 'api/Campaign'
    },
    getCampaignDetail: {
        method: 'GET',
        url: ({ campaignId }) => `api/Campaign/${campaignId}`
    },
    getCampaignBasicDetail: {
        method: 'GET',
        url: ({ campaignId }) => `api/Campaign/BasicDetail/${campaignId}`
    },
    getServiceDetailVM: {
        method: 'GET',
        url: () => `api/Campaign/ServiceSetupVM`
    },
    updateCampaignDetail: {
        method: 'PUT',
        url: (CampaignId) => `api/Campaign/${CampaignId}`
    },
    addCampaignServiceDetail: {
        method: 'POST',
        url: 'api/Campaign/Service'
    },
    getCampaignServiceList: {
        method: 'GET',
        url: ({
            campaignId,
            pageNumber,
            pageSize,
            genericSearch,
            advanceSearch = {},
            orderColumn,
            orderDirections
        }) => {
            let searchString = [
                getSearchQuery("CampaignId", campaignId, true),
                getSearchQuery("PageNumber", pageNumber),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("GenericSearch", genericSearch, false, true),
                getSearchQuery("OrderColumn", orderColumn),
                orderDirections ? `&OrderDirections=${orderDirections}` : ''
            ].join('')

            if (Object.keys(advanceSearch).length) {
                const {
                    campaignServiceName,
                    serviceId,
                    schedulerDriverId,
                    saturationRate,
                    completionAfterReady,
                    entityId,
                    organizationDDLId,
                    siteIds = [],
                    selfSchedule,
                    timeIncrementTypeId,
                    timeIncrement
                } = advanceSearch
                const siteSearch = siteIds.map((site) => getSearchQuery("SiteIds", site.value)).join("")
                
                searchString = [
                    searchString, 
                    getSearchQuery("CampaignServiceTypeId", serviceId),
                    getSearchQuery("CampaignSchedulerDriverId", schedulerDriverId),
                    getSearchQuery("SaturationRate", saturationRate),
                    getSearchQuery("CompletionAfterReady", completionAfterReady),
                    getSearchQuery("CampaignTimeIncrementTypeId", timeIncrementTypeId),
                    getSearchQuery("OrganizationTypeId", entityId),
                    getSearchQuery("OrganizationId", organizationDDLId),
                    getSearchQuery("CampaignServiceName", campaignServiceName, false, true),
                    siteSearch,
                    selfSchedule ? `&SelfSchedule=${selfSchedule}` : '',
                    getSearchQuery("TimeIncrement", timeIncrement)
                ].join('')
            }

            return `api/Campaign/${campaignId}/SearchServiceList${searchString}`
        }
    },
    getCampaignServiceChecklist: {
        method: 'GET',
        url: ({
            entityId = 1,
            wfCampaignSetupId = 0,
            programQualityAssemblyId = 0,
            pageNumber,
            pageSize,
            genericSearch,
            advanceSearch = {},
            orderColumn,
            orderDirections,
            isReviewActivity
        }) => {
            let searchString = [
                getSearchQuery("EntityId", entityId, true),
                getSearchQuery("WFCampaignSetupId", wfCampaignSetupId),
                getSearchQuery("ProgramQualityAssemblyId", programQualityAssemblyId),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("PageNumber", pageNumber),
                getSearchQuery("GenericSearch", genericSearch, false, true),
                getSearchQuery("isReviewActivity", isReviewActivity),
                getSearchQuery("OrderColumn", orderColumn),
                orderDirections ? `&OrderDirections=${orderDirections}` : ''
            ].join('')

            if (Object.keys(advanceSearch).length) {
                const { checklistName } = advanceSearch
                searchString = [
                    searchString,
                    getSearchQuery("CheckListName", checklistName, false, true)
                ].join('')
            }

            return `api/Campaign/GetCheckListService${searchString}`
        }
    },
    addServiceChecklist: {
        method: 'POST',
        url: `api/Campaign/ServiceChecklist`
    },
    updateServiceChecklist: {
        method: 'PUT',
        url: (CampaignServiceCheckListId) => `api/Campaign/ServiceChecklist/${CampaignServiceCheckListId}`
    },
    deleteServiceChecklist: {
        method: 'DELETE',
        url: (campaignServiceCheckListId) => `api/Campaign/ServiceChecklist/${campaignServiceCheckListId}`
    },
    getServiceChecklistDetail: {
        method: 'GET',
        url: (campaignServiceCheckListId) => `api/Campaign/ServiceChecklist/${campaignServiceCheckListId}`
    },
    addCheckpointToCheckList: {
        method: 'POST',
        url: (campaignServiceCheckListId) => `api/Campaign/${campaignServiceCheckListId}/CheckList`
    },
    getCampaignCheckListVM: {
        method: 'GET',
        url: `api/Campaign/CheckListVM`
    },
    getCampaignSetupCheckListVM: {
        method: 'GET',
        url: () => `api/Campaign/SetupCheckListVM`
    },
    getProjectListDDL: {
        method: 'GET',
        url: ({ organizationId, projectName, pageNumber, pageSize }) => {
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("OrganizationId", organizationId),
                getSearchQuery("ProjectName", projectName, false, true)
            ].join('')
            return `api/Project/DDL${searchString}`
        }
    },
    deleteCampaignService: {
        method: 'DELETE',
        url: (ids = 0) => `api/Campaign/Service/${ids}`
    },
    getCampaignServiceChecklistCheckpointList: {
        method: 'GET',
        url: ({
            checklistId = 0,
            pageNumber,
            pageSize,
            genericSearch = '',
            advanceSearch = {},
            orderColumn,
            orderDirections = ''
        }) => {
            let searchString = [
                getSearchQuery("CampaignServiceCheckListId", checklistId, true),
                getSearchQuery("PageNumber", pageNumber),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("GenericSearch", genericSearch, false, true),
                getSearchQuery("OrderColumn", orderColumn),
                orderDirections ? `&OrderDirections=${orderDirections}` : ''
            ].join('')

            if (!!Object.keys(advanceSearch).length) {
                const {
                    checkpointName = '',
                    stageOfConstruction = '',
                    scopeOfWork = '',
                    responsibleSiteRole = '',
                    riskLevel = '',
                    assembly = '',
                    component = '',
                    subComponent = '',
                    manufacturer = '',
                    systemCategory = '',
                    brand = '',
                    activityType = ''
                } = advanceSearch

                searchString = [
                    searchString,
                    getSearchQuery("CheckPointName", checkpointName, false, true),
                    getSearchQuery("StageOfConstructionId", stageOfConstruction, false, true),
                    getSearchQuery("ScopeOfWorkId", scopeOfWork),
                    getSearchQuery("ResponsibleSiteRoleId", responsibleSiteRole),
                    getSearchQuery("RiskLevelId", riskLevel),
                    getSearchQuery("AssemblyId", assembly),
                    getSearchQuery("ComponentId", component),
                    getSearchQuery("SubComponentId", subComponent),
                    getSearchQuery("ManufacturerId", manufacturer),
                    getSearchQuery("SystemCategoryId", systemCategory),
                    getSearchQuery("BrandId", brand),
                    getSearchQuery("ActivityTypeId", activityType)
                ].join('')
            }

            return `api/Campaign/GetCheckListServiceChildren${searchString}`
        }
    },
    getCampaignList: {
        method: 'GET',
        url: ({
            pageNumber,
            pageSize,
            genericSearch = '',
            advanceSearch = {},
            orderColumn,
            siteId,
            orderDirections = '',
            isShowActiveCampaigns,
            campaignSharedTypeIds = []
        }) => {
            const campaignSharedTypeIdsSearch = campaignSharedTypeIds.map((type) => getSearchQuery("CampaignSharedTypeIds", type)).join("")
            
            let searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("GenericSearch", genericSearch, false, true),
                getSearchQuery("OrderColumn", orderColumn),
                orderDirections ? `&OrderDirections=${orderDirections}` : '',
                getSearchQuery("SiteIds", siteId),
                campaignSharedTypeIdsSearch
            ].join('')

            if (!!Object.keys(advanceSearch).length) {
                const {
                    campaignName = '',
                    projectId = 0,
                    campaignStatusId = '',
                    campaignTypeId = '',
                    campaignObjectiveId = '',
                    campaignTagIds = [],
                    programs = [],
                    sites = [],
                    organization = [],
                    region = []
                } = advanceSearch

                const siteSearch = sites.map((site) => getSearchQuery("SiteIds", site.value)).join("")
                const programSearch = programs.map((program) => getSearchQuery("ProgramIds", program.value)).join("")
                const campaignTagSearch = campaignTagIds.map((tag) => getSearchQuery("TagIds", tag.value)).join("")
                const organizationSearch = organization.map((org) => getSearchQuery("OrganizationIds", org.organizationId)).join("")
                const regionSearch = region.map((id) => getSearchQuery("RegionIds", id.value)).join("")

                searchString = [
                    searchString,
                    getSearchQuery("CampaignName", campaignName, false, true),
                    getSearchQuery("ProjectId", projectId),
                    siteSearch,
                    programSearch,
                    getSearchQuery("CampaignStatusId", campaignStatusId),
                    getSearchQuery("CampaignTypeId", campaignTypeId),
                    getSearchQuery("CampaignObjectiveId", campaignObjectiveId),
                    campaignTagSearch,
                    organizationSearch,
                    regionSearch,
                    getSearchQuery("ActiveTask", isShowActiveCampaigns)
                ].join('')
            }

            return `api/Campaign/SearchList${searchString}`
        }
    },
    deleteCampaignServiceChecklistCheckpoint: {
        method: 'DELETE',
        url: `api/Campaign/CheckList`
    },
    getCampaignServiceDetail: {
        method: 'GET',
        url: (campaignServiceId = 0) => `api/Campaign/Service/${campaignServiceId}`
    },
    getCampaignServiceBasicDetail: {
        method: 'GET',
        url: (campaignServiceId = 0) => `api/Campaign/Service/BasicDetail/${campaignServiceId}`
    },
    updateCampaignService: {
        method: 'PUT',
        url: (CampaignServiceId) => `api/Campaign/Service/${CampaignServiceId}`
    },
    deleteCampaign: {
        method: 'DELETE',
        url: ({ campaignId }) => `api/Campaign/${campaignId}`
    },

    getCampaignObservationList: {
        method: 'GET',
        url: ({
            campaignId = 0,
            pageNumber,
            pageSize,
            genericSearch,
            advanceSearch = {},
            orderColumn,
            orderDirections
        }) => {
            let searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("CampaignId", campaignId),
                getSearchQuery("GenericSearch", genericSearch, false, true),
                getSearchQuery("OrderColumn", orderColumn),
                orderDirections ? `&OrderDirections=${orderDirections}` : ''
            ].join('')

            if (Object.keys(advanceSearch).length) {
                const { serviceName, siteName, unitName, checklistName, taskTypeId, taskStatusId, assignedTo } = advanceSearch
                searchString = [
                    searchString,
                    getSearchQuery("ServiceName", serviceName, false, true),
                    getSearchQuery("SiteName", siteName, false, true),
                    getSearchQuery("UnitName", unitName, false, true),
                    getSearchQuery("ChecklistName", checklistName, false, true),
                    getSearchQuery("TaskTypeId", taskTypeId),
                    getSearchQuery("TaskStatusId", taskStatusId),
                    getSearchQuery("AssignedTo", assignedTo)
                ].join('')
            }

            return `api/Campaign/GetCampaignObservation/${campaignId}${searchString}`
        }
    },
    getCampaignServiceDDL: {
        method: 'GET',
        url: ({ pageNumber, pageSize, campaignServiceName = "" }) => {
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("CampaignServiceName", campaignServiceName, false, true)
            ].join('')
            return `api/Campaign/DDL/SearchCampaignService${searchString}`
        }
    },
    getCampaignDDL: {
        method: 'GET',
        url: ({
            programId,
            organizationId,
            pageNumber,
            pageSize,
            campaignName,
            allOption,
            isCalendarCampaigns = false,
            organizations = [],
            sites = [],
            workflows = [],
            regions = [],
            subRegionIds = [],
            states = [],
            cities = [],
            zipCodes = []
        }) => {
            const organizationList = organizations.map((org) => getSearchQuery("OrganizationIds", org.value)).join("")
            const sitesList = sites.map((site) => getSearchQuery("SiteIds", site.value)).join("")
            const workflowList = workflows.map((workflow) => getSearchQuery("WFCampaignSetupIds", workflow.value)).join("")
            const regionList = regions.map((region) => getSearchQuery("RegionIds", region.value)).join("")
            const subRegionList = subRegionIds.map((subRegion) => getSearchQuery("SubRegionIds", subRegion.value)).join("")
            const stateList = states.map((state) => getSearchQuery("States", state.value)).join("")
            const cityList = cities.map((city) => getSearchQuery("Cities", city.value, false, true)).join("")
            const zipCodeList = zipCodes.map((zipCode) => getSearchQuery("ZipCodes", zipCode.value, false, true)).join("")

            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("CampaignName", campaignName, false, true),
                getSearchQuery("ProgramId", programId),
                getSearchQuery("AllOption", allOption),
                getSearchQuery("OrganizationIds", organizationId),
                organizationList,
                sitesList,
                workflowList,
                regionList,
                subRegionList,
                stateList,
                cityList,
                zipCodeList
            ].join('')
            if (isCalendarCampaigns) {
                return `api/Scheduling/Calendar/DDL/Campaigns${searchString}`
            }
            return `api/Campaign/DDL/SearchCampaign${searchString}`
        }
    },
    getObservationTaskVM: {
        method: 'GET',
        url: () => `api/Campaign/ObservationTaskVM`
    },
    getCampaignOshaPDF: {
        method: 'GET',
        url: ({ campaignId = 0 }) => `api/Campaign/OshaPDF/${campaignId}`
    },
    updateChecklistOrder: {
        method: 'PUT',
        url: `api/Campaign/CheckList/UpdateOrderIndex`
    },
    getContactCampaignList: {
        method: 'GET',
        url: ({
            contactId = 0,
            pageNumber,
            pageSize,
            genericSearch = "",
            advanceSearch = {},
            orderColumn,
            orderDirections
        }) => {
            let searchString = [
                getSearchQuery("ContactId", contactId, true),
                getSearchQuery("PageNumber", pageNumber),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("GenericSearch", genericSearch, false, true),
                getSearchQuery("OrderColumn", orderColumn),
                orderDirections ? `&OrderDirections=${orderDirections}` : ''
            ].join('')
            
            if (!!Object.keys(advanceSearch).length) {
                const {
                    campaignName,
                    projectName,
                    roleName,
                    title
                } = advanceSearch
                searchString = [
                    searchString,
                    getSearchQuery("CampaignName", campaignName, false, true),
                    getSearchQuery("ProjectName", projectName, false, true),
                    getSearchQuery("RoleName", roleName, false, true),
                    getSearchQuery("Title", title, false, true)
                ].join('')
            }

            return `api/Campaign/SearchListByContactId/${contactId}${searchString}`
        }
    },
    createCampaignChecklist: {
        method: 'POST',
        url: (wfCampaignSetupId) => `api/Campaign/Checklist/${wfCampaignSetupId}`
    },
    updateCampaignChecklist: {
        method: 'PUT',
        url: (wfCampaignSetupChecklistId) => `api/Campaign/Checklist/${wfCampaignSetupChecklistId}`
    },
    getCampaignChecklistDetail: {
        method: 'GET',
        url: ({
            isReviewActivity,
            isQualityControl,
            programQualityAssemblyId,
            wfCampaignSetupId
        }) => {
            if (isQualityControl) {
                return `api/Campaign/Checklist/0?ProgramQualityAssemblyId=${programQualityAssemblyId}`
            }

            const searchString = getSearchQuery("isReviewActivity", isReviewActivity, !!isReviewActivity)
            return `api/Campaign/Checklist/${wfCampaignSetupId}${searchString}`
        }
    },
    getCampaignWorkflowVM: {
        method: 'GET',
        url: 'api/WorkFlow/CampaignWorkFlowVM'
    },
    getCampaignWorkflows: {
        method: 'GET',
        url: ({
            isWorkflowDependency,
            campaignId,
            wfCampaignSetupId,
            pageNumber,
            pageSize,
            genericSearch = ""
        }) => {
            let searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("GenericSearch", genericSearch, false, true)
            ].join('')

            if (isWorkflowDependency) {
                searchString = [
                    searchString,
                    getSearchQuery("CampaignId", campaignId),
                    getSearchQuery("WFCampaignSetupId", wfCampaignSetupId)
                ].join('')
                return `api/WorkFlow/WFDependency/WFCampaignSetupList/${campaignId}/${wfCampaignSetupId}${searchString}`
            }

            return `api/Common/filter/Workflow${searchString}`
        }
    },
    cloneCampaign: {
        method: 'POST',
        url: ({ campaignId }) => `api/Campaign/${campaignId}/clone`
    },
    getCampaignTagsDDL: {
        method: 'GET',
        url: ({ pageNumber, pageSize, tagName }) => {
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("tagName", tagName, false, true)
            ].join('')
                    
            return `api/Campaign/DDL/Tag${searchString}`
        }
    },
    updateChecklistRequiredActivity: {
        method: 'PUT',
        url: (wfCampaignSetupChecklistId) => `api/Checkpoint/RequiredActivity/${wfCampaignSetupChecklistId}`
    },
    getCampaignReportList: {
        method: 'GET',
        url: ({
            campaignId,
            resourceCenterSourceId,
            pageNumber,
            pageSize,
            genericSearch,
            orderColumn,
            orderDirections
        }) => {
            const searchString = [
                getSearchQuery("CampaignId", campaignId, true),
                getSearchQuery("ResourceCenterSourceId", resourceCenterSourceId),
                getSearchQuery("PageNumber", pageNumber),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("GenericSearch", genericSearch, false, true),
                getSearchQuery("OrderColumn", orderColumn),
                `${orderDirections ? `&OrderDirections=${orderDirections}` : ''}`
            ].join('')

            return `api/Campaign/CampaignReport/${campaignId}${searchString}`
        }
    },
    updateChecklistCopyAllowedActivity: {
        method: 'PUT',
        url: (wfCampaignSetupChecklistId) => `api/Checkpoint/CopyAllowedActivity/${wfCampaignSetupChecklistId}`
    },
    getSharedCampaignUnLinkedSiteList: {
        method: 'GET',
        url: ({
            campaignId = 0,
            pageNumber,
            pageSize,
            genericSearch = "",
            advanceSearch = {},
            orderColumn,
            orderDirections
        }) => {
            let searchString = [
                getSearchQuery("SharedCampaignId", campaignId, true),
                getSearchQuery("PageNumber", pageNumber),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("GenericSearch", genericSearch, false, true),
                getSearchQuery("OrderColumn", orderColumn),
                `${orderDirections ? `&OrderDirections=${orderDirections}` : ''}`
            ].join('')
           
            if (!!Object.keys(advanceSearch).length) {
                const {
                    siteIds,
                    organizationIds,
                    streetAddress,
                    city,
                    state,
                    postCode
                } = advanceSearch
                const sitesSearch = siteIds.map((data) => data.value).join(",")
                const organizationSearch = organizationIds.map((data) => data.organizationId).join(",")

                searchString = [
                    searchString,
                    getSearchQuery("Cities", city, false, true),
                    getSearchQuery("StreetAddress", streetAddress, false, true),
                    getSearchQuery("States", state),
                    getSearchQuery("ZipCodes", postCode, false, true),
                    getSearchQuery("SiteIds", sitesSearch),
                    getSearchQuery("OrganizationIds", organizationSearch)
                ].join('')
            }

            return `api/Campaign/SharedCampaign/UnLinkedSites/${campaignId}${searchString}`
        }
    },
    addSitesToSharedCampaign: {
        method: 'PUT',
        url: ({ campaignId }) => `api/Campaign/AddSites/${campaignId}`
    },
    getSharedCampaignSiteList: {
        method: 'GET',
        url: ({
            campaignId = 0,
            pageNumber,
            pageSize,
            genericSearch = "",
            advanceSearch = {},
            orderColumn,
            orderDirections
        }) => {
            let searchString = [
                getSearchQuery("SharedCampaignId", campaignId, true),
                getSearchQuery("PageNumber", pageNumber),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("GenericSearch", genericSearch, false, true),
                getSearchQuery("OrderColumn", orderColumn),
                `${orderDirections ? `&OrderDirections=${orderDirections}` : ''}`
            ].join('')

            if (!!Object.keys(advanceSearch).length) {
                const {
                    siteIds,
                    organizationIds,
                    streetAddress,
                    city,
                    state,
                    postCode
                } = advanceSearch

                const sitesSearch = siteIds.map((data) => getSearchQuery("SiteIds", data.value)).join("")
                const organizationSearch = organizationIds.map((data) => getSearchQuery("OrganizationIds", data.organizationId)).join("")

                searchString = [
                    searchString,
                    getSearchQuery("City", city, false, true),
                    getSearchQuery("StreetAddress", streetAddress, false, true),
                    getSearchQuery("StateValue", state),
                    getSearchQuery("ZipCode", postCode, false, true),
                    sitesSearch,
                    organizationSearch
                ].join('')
            }

            return `api/Campaign/GetCampaignSiteList/${campaignId}${searchString}`
        }
    },
    getCampaignMissingSiteRoles: {
        method: 'GET',
        url: ({ campaignId, sites = [] }) => {

            let searchString = sites.map((data) => getSearchQuery("SiteIds", data.siteId)).join("")
            searchString = searchString.replace('&', '?')

            return `api/Campaign/DiscrepancySiteRoles/${campaignId}${searchString}`
        }
    },
    getSiteDDLByCampaignId: {
        method: 'GET',
        url: ({ campaignId, siteName, pageNumber, pageSize }) => {
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("CampaignId", campaignId),
                getSearchQuery("SiteName", siteName, false, true)
            ].join('')
            return `api/Project/GetSiteDDLByCampaignId/${campaignId}${searchString}`
        }
    },
    getOrganizationDDLByCampaignId: {
        method: 'GET',
        url: ({ campaignId, organizationName, pageNumber, pageSize }) => {
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("CampaignId", campaignId),
                getSearchQuery("OrganizationName", organizationName, false, true)
            ].join('')
            return `api/Project/GetOrganizationDDLByCampaignId/${campaignId}${searchString}`
        }
    },
    deleteSharedCampaignSite: {
        method: 'DELETE',
        url: ({ campaignId, siteId }) => `api/Campaign/DeleteSite/${campaignId}/${siteId}`
    },
    getSharedCampaignUnLinkedSiteOrganizationDDLList: {
        method: 'GET',
        url: ({ campaignId, organizationName, pageNumber, pageSize }) => {
            const searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("CampaignId", campaignId),
                getSearchQuery("OrganizationName", organizationName, false, true)
            ].join('')
            
            return `api/Campaign/SharedCampaign/UnLinkedSites/Organization/DDL/${campaignId}${searchString}`
        }
    }
}

export default apiConfig
