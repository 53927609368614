import config from "./APIConfig"
import { putDataApi } from "../index"

export const updateUserProfile = async (data) => {
  const { url } = config.updateUserProfile
  const payload = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phoneNumber: data.phoneNumber,
    preferredLanguage: data.language,
    timeZone: data.timeZone
  }
  return await putDataApi(url, payload)
}

export default updateUserProfile
