import React from 'react'
import { Modal } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

export const BasicLoader = () => (
  <div className='lds-roller'>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

const Loader = ({ isLoading, isAuthLoading }) => {
  if (isLoading || isAuthLoading) {
    return (
      <div className='m-loader'>
        <Modal isOpen={true} className='m-loader modal-dialog-centered'>
          <BasicLoader />
        </Modal>
      </div>
    )
  }

  return <div />
}

const mapStateToProps = (state) => {
  return ({
    isLoading: state.loader.isLoading
  })
}

Loader.propTypes = {
  isLoading: PropTypes.bool,
  isAuthLoading: PropTypes.bool
}

Loader.defaultProps = {
  isAuthLoading: false
}

export default connect(mapStateToProps)(Loader)
