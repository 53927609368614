import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addProjectSitePlan
 * @param {object} data 
 * 
 * {
 *   sitePlanId: 0,
 *   payload: [
 *    {
 *     "sitePlanMasterScheduleId": 0,
 *      "scheduleOrder": 0,
 *      "scheduleLineItem": "string",
 *      "prereqs": [
 *         "string"
 *       ],
 *       "lagTime": 0,
 *       "autoStart": true,
 *       "usowIds": [
 *          0
 *        ]
 *      }
 *    ]
 * }
 *
 */
export const addProjectSitePlanSchedule = (data) => {
  const { url } = config.addProjectSitePlanSchedule

  return postDataApi(url(data), data.payload)
}

export default addProjectSitePlanSchedule
