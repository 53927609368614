import config from "./APIConfig"
import { putDataApi } from '../index'

export const updateOpenSamplingGroup = (data) => {
  const { url } = config.updateOpenSamplingGroup

  return putDataApi(url({ workflowId: data.workflowId, groupId: data.groupId }))
}

export default updateOpenSamplingGroup
