import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addCheckpointToTemplate
 * @param {object} data 
 *
 */
export const addCheckpointToTemplate = (data) => {
  const { url } = config.addCheckpointToTemplate

  return postDataApi(url(data), data)
}

export default addCheckpointToTemplate
