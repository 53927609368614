export const checkIfSearchFiltersApplied = ({
  campaignServiceName,
  serviceId,
  schedulerDriverId,
  timeIncrementTypeId,
  entityId,
  organizationDDLId,
  eventTypeId,
  sampleRate,
  siteIds,
  saturationRate,
  timeIncrement,
  selfSchedule,
  completionAfterReady
}) => {
  return (
    !!campaignServiceName ||
    !!serviceId ||
    !!schedulerDriverId ||
    !!timeIncrementTypeId ||
    !!entityId ||
    !!organizationDDLId ||
    !!eventTypeId ||
    !!sampleRate ||
    !!siteIds?.length ||
    !!timeIncrement ||
    !!saturationRate ||
    !!completionAfterReady ||
    !!selfSchedule
  )
}
