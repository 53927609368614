export const CREATE_CAMPAIGN_WORKFLOW_REQUEST = 'CREATE_CAMPAIGN_WORKFLOW_REQUEST'
export const UPDATE_CAMPAIGN_WORKFLOW_REQUEST = 'UPDATE_CAMPAIGN_WORKFLOW_REQUEST'
export const ADD_CAMPAIGN_WORKFLOW_VM_REQUEST = 'ADD_CAMPAIGN_WORKFLOW_VM_REQUEST'
export const DELETE_CAMPAIGN_WORKFLOW_REQUEST = 'DELETE_CAMPAIGN_WORKFLOW_REQUEST'
export const GET_CAMPAIGN_WORKFLOW_REQUEST = 'GET_CAMPAIGN_WORKFLOW_REQUEST'
export const GET_SITE_ROLE_BY_ASSIGNMENT_REQUEST = "GET_SITE_ROLE_BY_ASSIGNMENT_REQUEST"
export const SET_WORKFLOW_SITE_ID = "SET_WORKFLOW_SITE_ID"
export const RESET_WORKFLOW_SITE_ID = "RESET_WORKFLOW_SITE_ID"
export const GET_CAMPAIGN_WORKFLOW_LIST_REQUEST = 'GET_CAMPAIGN_WORKFLOW_LIST_REQUEST'
export const UPDATE_FINISH_CAMPAIGN_SETUP_REQUEST = 'UPDATE_FINISH_CAMPAIGN_SETUP_REQUEST'
export const GET_WORKFLOW_TEMPLATE_STEP_STATUS_REQUEST = 'GET_WORKFLOW_TEMPLATE_STEP_STATUS_REQUEST'
export const CLONE_CAMPAIGN_WORKFLOW_REQUEST = 'CLONE_CAMPAIGN_WORKFLOW_REQUEST'
export const UPDATE_WORKFLOW_CAMPAIGN_STATUS_REQUEST = 'UPDATE_WORKFLOW_CAMPAIGN_STATUS_REQUEST'
export const GET_CANADD_WORKFLOW_REQUEST = 'GET_CANADD_WORKFLOW_REQUEST'
export const GET_WORKFLOW_UNIT_LIST_REQUEST = 'GET_WORKFLOW_UNIT_LIST_REQUEST'
export const UPDATE_WORKFLOW_UNIT_LIST_REQUEST = 'UPDATE_WORKFLOW_UNIT_LIST_REQUEST'
export const GET_WORKFLOW_UNIT_LIST_COUNT_REQUEST = 'GET_WORKFLOW_UNIT_LIST_COUNT_REQUEST'
export const GET_WORKFLOW_ORGANIZATION_ROLE_LIST_REQUEST = 'GET_WORKFLOW_ORGANIZATION_ROLE_LIST_REQUEST'

/****************************************************/
/** CREATE_CAMPAIGN_WORKFLOW_REQUEST **/
/****************************************************/

/**
 * @method createCampaignWorkflowRequest : To create campaign workflow 
 *
 */
export const createCampaignWorkflowRequest = (payload, callback = () => { }) => {
  return {
    type: CREATE_CAMPAIGN_WORKFLOW_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_CAMPAIGN_WORKFLOW_VM_REQUEST **/
/****************************************************/

/**
 * @method addCampaignWorkflowVMRequest : To get campaign workflow detail VM
 *
 */
export const addCampaignWorkflowVMRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_CAMPAIGN_WORKFLOW_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_CAMPAIGN_WORKFLOW_REQUEST **/
/****************************************************/

/**
 * @method updateCampaignWorkflowRequest : To update campaign workflow 
 *
 */
export const updateCampaignWorkflowRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_CAMPAIGN_WORKFLOW_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_CAMPAIGN_WORKFLOW_REQUEST **/
/****************************************************/

/**
 * @method deleteCampaignWorkflowRequest :  To delete campaign workflow
 *
 */
export const deleteCampaignWorkflowRequest = (payload, callback = () => { }) => {
  return {
    type: DELETE_CAMPAIGN_WORKFLOW_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_WORKFLOW_REQUEST **/
/****************************************************/

/**
 * @method getCampaignWorkflowRequest : To get campaign workflow 
 *
 */
export const getCampaignWorkflowRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CAMPAIGN_WORKFLOW_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SET_WORKFLOW_SITE_ID **/
/****************************************************/

/**
 * @method setWorkflowSiteId : To set workflow site id
 * 
 */
export const setWorkflowSiteId = (payload) => {
  return {
    type: SET_WORKFLOW_SITE_ID,
    payload
  }
}

/****************************************************/
/** RESET_WORKFLOW_SITE_ID **/
/****************************************************/

/**
 * @method resetWorkflowSiteId : To reset workflow site id
 * 
 */
export const resetWorkflowSiteId = () => {
  return {
    type: RESET_WORKFLOW_SITE_ID
  }
}

/****************************************************/
/** GET_CAMPAIGN_WORKFLOW_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCampaignWorkflowListRequest :  To get workflow list of campaign
 *
 */
export const getCampaignWorkflowListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CAMPAIGN_WORKFLOW_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_FINISH_CAMPAIGN_SETUP_REQUEST **/
/****************************************************/

/**
 * @method updateFinishCampaignSetUpRequest :  To update finish campaign setup
 *
 */
export const updateFinishCampaignSetUpRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_FINISH_CAMPAIGN_SETUP_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_WORKFLOW_TEMPLATE_STEP_STATUS_REQUEST **/
/****************************************************/

/**
 * @method getWorkflowTemplateStepStatusRequest :  To get workflow template step status
 *
 */
export const getWorkflowTemplateStepStatusRequest = (payload, callback = () => { }) => {
  return {
    type: GET_WORKFLOW_TEMPLATE_STEP_STATUS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CLONE_CAMPAIGN_WORKFLOW_REQUEST **/
/****************************************************/

/**
 * @method cloneCampaignWorkflowRequest :  To clone campaign workflow
 *
 */
export const cloneCampaignWorkflowRequest = (payload, callback = () => { }) => {
  return {
    type: CLONE_CAMPAIGN_WORKFLOW_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_WORKFLOW_CAMPAIGN_STATUS_REQUEST **/
/****************************************************/

/**
 * @method updateWFCampaignStatusRequest :  To update wf campaign status
 *
 */
export const updateWFCampaignStatusRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_WORKFLOW_CAMPAIGN_STATUS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_WORKFLOW_CAMPAIGN_STATUS_REQUEST **/
/****************************************************/

/**
 * @method  : getCanAddWorkflowRequest To get workflow list of campaign
 *
 */
export const getCanAddWorkflowRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CANADD_WORKFLOW_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_WORKFLOW_UNIT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getWorkflowUnitListRequest :  To get unit list of workflow
 *
 */
export const getWorkflowUnitListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_WORKFLOW_UNIT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_WORKFLOW_UNIT_LIST_REQUEST **/
/****************************************************/

/**
 * @method updateWorkflowUnitListRequest :  To update unit list of workflow
 *
 */
export const updateWorkflowUnitListRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_WORKFLOW_UNIT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_WORKFLOW_UNIT_LIST_COUNT_REQUEST **/
/****************************************************/

/**
 * @method getWorkflowUnitListCount :  to get workflow unit list count   
 *
 */
export const getWorkflowUnitListCountRequest = (payload, callback = () => { }) => {
  return {
    type: GET_WORKFLOW_UNIT_LIST_COUNT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_WORKFLOW_ORGANIZATION_ROLE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getWorkflowOrganizationRoleListRequest:  To get organization role list of workflow
 *
 */
export const getWorkflowOrganizationRoleListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_WORKFLOW_ORGANIZATION_ROLE_LIST_REQUEST,
    payload,
    callback
  }
}
