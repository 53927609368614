import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addIndustryInterpretations
 * @param {object} data 
 *
 */
export const addIndustryInterpretations = (data) => {
  const { url } = config.addIndustryInterpretations

  return postDataApi(url(data), data)
}

export default addIndustryInterpretations
