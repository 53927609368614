import config from "./APIConfig"
import { getDataApi } from "../../index"

export const getOshaTrainingCampaign = async (data) => {
  const { url } = config.getOshaTrainingCampaign
  return await getDataApi(url(data))
}

export const getOshaTrainingSiteRole = async ({programId}) => {
  const { url } = config.getOshaTrainingSiteRole
  return await getDataApi(url(programId))
}
