import React, { useCallback, useState, useEffect } from "react"
import { ModalBody, Modal } from "reactstrap"
import { useForm } from 'react-hook-form'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'

import * as actions from '@store/actions'
import { MODAL_TYPES } from '@shared/constants'
import AddPlanCard from "./AddPlanCard"
import { validatePlanDetail } from '../../helpers'

const initialSitePlanDetail = {
  planName: '',
  planNumber: '',
  version: '',
  elevation: '',
  squareFootage: '',
  numberOfBedroom: '',
  bathrooms: '',
  stories: '',
  planDescription: ''
}

const EditPlanModal = ({
  modalType = MODAL_TYPES.EDIT,
  projectSitePlanStore : { sitePlanDetail = {}},
  selectedSiteId,
  addProjectSitePlan,
  onCloseModal = () => {},
  onFetchProjectSitePlans, 
  onSetEditSitePlanMode,
  onSelect,
  updateProjectSitePlan
}) => {
  const [planDetail, setPlanDetail] = useState({
    planName: sitePlanDetail?.planName || '',
    planNumber: sitePlanDetail?.planNumber || '',
    version: sitePlanDetail?.version || '',
    elevation: sitePlanDetail?.elevation || '',
    squareFootage: sitePlanDetail?.squareFootage || '',
    numberOfBedroom: sitePlanDetail?.numberOfBedroom || '',
    stories: sitePlanDetail?.stories || '',
    bathrooms: sitePlanDetail?.bathrooms || '',
    planDescription: sitePlanDetail?.planDescription || ''
  })
  const { control } = useForm({})
  const [errors, setErrors] = useState({})
  const [isShowPlanDescription, setShowPlanDescription] = useState(false)

  const handleToggleModal = useCallback(() => {
    setPlanDetail(initialSitePlanDetail)
    onSetEditSitePlanMode({ sitePlanDetail: null, isEditMode: false })
    onCloseModal()
  }, [])

  const handleChangePlanDetail = useCallback(
    (key, value) => {
      if (!!Object.keys(errors).length) {
        setErrors({})
      }

      const updatedPlan = Object.assign({}, planDetail)
      updatedPlan[key] = value
      setPlanDetail(updatedPlan)
    },
    [planDetail, errors]
  )

  const handleAddPlanDetail = useCallback(() => {
    const errors = validatePlanDetail(planDetail)
    if (!!Object.keys(errors).length) {
      setErrors(errors)
      return true
    }

    if (modalType === MODAL_TYPES.EDIT) {
      updateProjectSitePlan({
        siteId: sitePlanDetail?.siteId || '',
        sitePlanId: sitePlanDetail?.sitePlanId || '',
        ...planDetail
      }, () => {
        handleToggleModal()
        onFetchProjectSitePlans({siteId: sitePlanDetail?.siteId || selectedSiteId || ''})
      })
    } else if (modalType === MODAL_TYPES.ADD) {
      addProjectSitePlan({
        siteId: selectedSiteId || '',
        ...planDetail
      }, (res) => {
        if (res) {
          if (onSelect) {
            onSelect({
              value: res.toString(),
              text: planDetail.planName
            })
          }
        }
        handleToggleModal()
        if (onFetchProjectSitePlans) {
          onFetchProjectSitePlans({ siteId: selectedSiteId || '' })
        }
      })
    }    
  }, [modalType, planDetail, selectedSiteId, sitePlanDetail, handleToggleModal, onFetchProjectSitePlans, onSelect])

  const handleImportPlan = useCallback(() => {
  }, [])

  const handleTogglePlanDescription = useCallback(() => {
    setShowPlanDescription(!isShowPlanDescription)
  }, [isShowPlanDescription])

  return (
    <Modal
      isOpen
      className="modal-dialog-centered all-modal add-organization-modal"
    >
      <ModalBody className="p-0"> 
        <AddPlanCard
          errors={errors}
          control={control}
          isEditMode={modalType === MODAL_TYPES.EDIT}
          isModal
          planDetail={planDetail}
          onAddPlanDetail={handleAddPlanDetail}
          onChangePlanDetail={handleChangePlanDetail}
          onCloseModal={handleToggleModal}
          onImportPlan={handleImportPlan}
          onTogglePlanDescription={handleTogglePlanDescription}
          isShowPlanDescription= {isShowPlanDescription}
        />
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    projectSitePlanStore: state.projectSitePlans
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { 
      addProjectSitePlan: (payload, callback) => actions.addProjectSitePlanRequest(payload, callback),
      updateProjectSitePlan: (payload, callback) => actions.updateProjectSitePlanRequest(payload, callback),
      onSetEditSitePlanMode: (payload) => dispatch(actions.setEditSitePlanMode(payload))
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPlanModal)
