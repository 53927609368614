import { takeLatest, call, put, takeEvery } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

const { SAMPLING_GROUP_SAGAS, SAMPLING_GROUP_UNITS_SAGAS, SAMPLING_GROUP_UNITS_TYPE_SAGAS } = LANGUAGE_CONSTANTS

/** getSamplingWorkflowList API */
function* getSamplingWorkflowListRequest(action) {
  try {
    const response = yield call(services.getSamplingWorkflowList, action.payload)
    action.callback({ ...response?.data, pageSize: action.payload?.pageSize })
  } catch (error) {
    action.callback()
  }
}

/** getSamplingWorkflowListVM API */
function* getSamplingWorkflowListVMRequest(action) {
  try {
    const response = yield call(services.getSamplingWorkflowListVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getSamplingWorkflowUnits API */
function* getSamplingWorkflowUnitsRequest(action) {
  try {
    const response = yield call(services.getSamplingWorkflowUnits, action.payload)
    action.callback({ ...response?.data, pageSize: action.payload?.pageSize })
  } catch (error) {
    action.callback()
  }
}

/** getSamplingWorkflowUnitsVM API */
function* getSamplingWorkflowUnitsVMRequest(action) {
  try {
    const response = yield call(services.getSamplingWorkflowUnitsVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getSamplingOverviewDetails API */
function* getSamplingOverviewDetailsRequest(action) {
  try {
    const response = yield call(services.getSamplingOverviewDetails, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getSamplingWorkflowGroups API */
function* getSamplingWorkflowGroupsRequest(action) {
  try {
    const response = yield call(services.getSamplingWorkflowGroups, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getSamplingWorkflowGroupUnits API */
function* getSamplingWorkflowGroupUnitsRequest(action) {
  try {
    const response = yield call(services.getSamplingWorkflowGroupUnits, action.payload)
    action.callback({ ...response?.data, pageSize: action.payload?.pageSize })
  } catch (error) {
    action.callback()
  }
}

/** createSamplingWorkflowGroup API **/
function* createSamplingWorkflowGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.createSamplingWorkflowGroup, action.payload)
    if (response?.data) {
      action.callback(response.data)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: SAMPLING_GROUP_SAGAS.ADDED_SUCCESS })
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: SAMPLING_GROUP_SAGAS.ERROR_IN_ADDING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** addSamplingWorkflowGroupUnits API **/
function* addSamplingWorkflowGroupUnitsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addSamplingWorkflowGroupUnits, action.payload)
    if (response?.data) {
      action.callback(response.data)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: SAMPLING_GROUP_UNITS_SAGAS.ADDED_SUCCESS })
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: SAMPLING_GROUP_UNITS_SAGAS.ERROR_IN_ADDING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** deleteSamplingWorkflowGroupUnits API **/
function* deleteSamplingWorkflowGroupUnitsRequest(data) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteSamplingWorkflowGroupUnits, data.payload)
    if (response?.status === 204) {
      data.callback(true)
      Notification({ status: 200, message: SAMPLING_GROUP_UNITS_SAGAS.REMOVED_SUCCESS })
    } else {
      data.callback()
      Notification({ status: 400, message: SAMPLING_GROUP_UNITS_SAGAS.ERROR_IN_REMOVING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    data.callback()
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: SAMPLING_GROUP_UNITS_SAGAS.ERROR_IN_REMOVING })
  }
}

/** deleteSamplingWorkflowGroup API **/
function* deleteSamplingWorkflowGroupRequest(data) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteSamplingWorkflowGroup, data.payload)
    if (response?.status === 204) {
      data.callback(true)
      Notification({ status: 200, message: SAMPLING_GROUP_SAGAS.DELETED_SUCCESS })
    } else {
      data.callback()
      Notification({ status: 400, message: SAMPLING_GROUP_SAGAS.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    data.callback()
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: SAMPLING_GROUP_SAGAS.ERROR_IN_DELETING })
  }
}

/** updateSamplingWorkflowGroupUnitType API **/
function* updateSamplingWorkflowGroupUnitTypeRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateSamplingWorkflowGroupUnitType, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: SAMPLING_GROUP_UNITS_TYPE_SAGAS.UPDATED_SUCCESS })
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: SAMPLING_GROUP_UNITS_TYPE_SAGAS.ERROR_IN_UPDATING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** addSamplingWorkflowGroupUnitType API **/
function* addSamplingWorkflowGroupUnitTypeRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addSamplingWorkflowGroupUnitType, action.payload)
    if (response?.status === 200) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: SAMPLING_GROUP_UNITS_TYPE_SAGAS.ADDED_SUCCESS })
    } else {
      action.callback(false)
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: SAMPLING_GROUP_UNITS_TYPE_SAGAS.ERROR_IN_ADDING })
    }
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getSamplingWorkflowGroupDetails API */
function* getSamplingWorkflowGroupDetailsRequest(action) {
  try {
    const response = yield call(services.getSamplingWorkflowGroupDetails, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateOpenSamplingGroup API **/
function* updateOpenSamplingGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateOpenSamplingGroup, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: SAMPLING_GROUP_SAGAS.UPDATED_OPEN_GROUP_SUCCESS })
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: SAMPLING_GROUP_SAGAS.ERROR_IN_UPDATING_OPEN_GROUP })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** updateCloseSamplingGroup API **/
function* updateCloseSamplingGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateCloseSamplingGroup, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: SAMPLING_GROUP_SAGAS.UPDATED_CLOSE_GROUP_SUCCESS })
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: SAMPLING_GROUP_SAGAS.ERROR_IN_UPDATING_CLOSE_GROUP })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

export function* samplingSagas() {
  yield takeLatest(actions.GET_SAMPLING_WORKFLOW_LIST_REQUEST, getSamplingWorkflowListRequest)
  yield takeLatest(actions.GET_SAMPLING_WORKFLOW_LIST_VM_REQUEST, getSamplingWorkflowListVMRequest)
  yield takeLatest(actions.GET_SAMPLING_WORKFLOW_UNITS_REQUEST, getSamplingWorkflowUnitsRequest)
  yield takeLatest(actions.GET_SAMPLING_WORKFLOW_UNITS_VM_REQUEST, getSamplingWorkflowUnitsVMRequest)
  yield takeLatest(actions.GET_SAMPLING_OVERVIEW_DETAILS_REQUEST, getSamplingOverviewDetailsRequest)
  yield takeLatest(actions.GET_SAMPLING_WORKFLOW_GROUPS_REQUEST, getSamplingWorkflowGroupsRequest)
  yield takeEvery(actions.GET_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST, getSamplingWorkflowGroupUnitsRequest)
  yield takeLatest(actions.CREATE_SAMPLING_WORKFLOW_GROUP_REQUEST, createSamplingWorkflowGroupRequest)
  yield takeLatest(actions.DELETE_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST, deleteSamplingWorkflowGroupUnitsRequest)
  yield takeLatest(actions.DELETE_SAMPLING_WORKFLOW_GROUP_REQUEST, deleteSamplingWorkflowGroupRequest)
  yield takeLatest(actions.UPDATE_SAMPLING_WORKFLOW_GROUP_UNIT_TYPE_REQUEST, updateSamplingWorkflowGroupUnitTypeRequest)
  yield takeLatest(actions.ADD_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST, addSamplingWorkflowGroupUnitsRequest)
  yield takeLatest(actions.ADD_SAMPLING_WORKFLOW_GROUP_UNIT_TYPE_REQUEST, addSamplingWorkflowGroupUnitTypeRequest)
  yield takeLatest(actions.GET_SAMPLING_WORKFLOW_GROUP_DETAILS_REQUEST, getSamplingWorkflowGroupDetailsRequest)
  yield takeLatest(actions.UPDATE_OPEN_SAMPLING_GROUP_REQUEST, updateOpenSamplingGroupRequest)
  yield takeLatest(actions.UPDATE_CLOSE_SAMPLING_GROUP_REQUEST, updateCloseSamplingGroupRequest)
}

export default samplingSagas