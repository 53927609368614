import config from "./APIConfig"
import { postDataApi } from "../index"
/**
 * @method addObservationCampaign
 * @param {object} data 
 *{
   {
  "ProgramId": "integer",
  "observationStartDate": "2022-02-02T18:13:39.135Z",
  "observationAfterReady": 0,
  "observationServiceDtos": [
    {
      "programRequirementServiceId": 0,
      "incrementValue": 0,
      "timeIncrementTypeId": 0
    }
  ]
}
 *
 */
export const addObservationCampaign = (data) => {
  const { url } = config.addObservationCampaign
  return postDataApi(url(data.programId), data)
}

export default addObservationCampaign