import React, { useCallback, useEffect, useState, useMemo, Fragment } from "react"
import { Col } from "reactstrap"
import { Search } from 'react-feather'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { DEFAULT_VM_PAGE_SIZE } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from '@store/actions'
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomReactSelect, CustomLabel } from '@views/components'
import { getReactSelectPositionProps } from "@views/helpers"
import CustomSelectOption from "./CustomSelectOption"
import OrganizationModal from "./organization-modal"
import localeMessageWrapper from '../locale-message'

const { ORGANIZATION_SEARCH, CONTACTS } = LANGUAGE_CONSTANTS

const OrganizationSearch = ({
  intl,
  isWrapperStyleRequired,
  requireNoWrapper,
  isFocusedStyleRequired,
  asyncInitialValue,
  className,
  colSize = null,
  customActionLabel,
  errors,
  focusedPlaceholder,
  id,
  siteId,
  campaignId,
  contactId,
  isMulti,
  menuPlacement,
  organizationTypeId = "",
  initialLabel,
  initialValue,
  isEditMode,
  isShowCustomAction,
  isResetCacheRequiredInEditMode,
  isRequiredSelectPositionProps,
  isTableView,
  isRequired,
  label,
  labelRequired,
  name,
  defaultOptions,
  placeholder,
  value,
  onSelect,
  colx,
  isDisabled,
  defaultCustomOptions,
  menuPosition,
  isAssignmentPageSites,
  isSharedCampaignSitesOrg,
  isSharedCampaignUnlinkedSitesOrg,
  observationTaskIds,
  menuListClassName,
  isChildOrganizations,
  isContactSiteList,
  organizationId,
  isAHJOrganisation,
  isDashboardOrganizationList
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState(defaultOptions)
  const [isOpenModal, setOpenModal] = useState(false)
  const [isFocused, setFocused] = useState(false)

  /**
   * Note: Paginated select maintain cache of options,
   * so to set new default options we need to reset previous cache
   */
  const [isResetCache, setResetCache] = useState(false)

  const [selectedOptionLabel, setSelectedOptionLabel] = useState('')
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)

  /**
   * @method handleSetDefaultOptions : To handle set default options in case of Edit mode, Add new from modal
   *
   * Note: To handle case of duplicate option, when custom option is added, that is selected from edit mode or add new modal
   */
  const handleSetDefaultOptions = useCallback((selectedItem, selectedOrgId) => {
    const checkIfAlreadyPresent = defaultOptions.find(option => option.organizationId === selectedOrgId)
    if (!!checkIfAlreadyPresent) {
      setOptions(defaultOptions)
    } else {
      setOptions([selectedItem, ...defaultOptions])
    }
  }, [defaultOptions])

  useEffect(() => {
    setResetCache((prevState) => !prevState)
    setOptions(defaultOptions)
  }, [defaultOptions, organizationTypeId, organizationId])

  useEffect(() => {
    //Note: When organization detail is not fetched in parent component
    if (isEditMode && ((typeof value !== 'number') && !value.length) && !isMulti) {
      if (!!initialValue) {
        dispatch(actions.getOrganizationDetailWithIdRequest(
          {
            organizationId: initialValue
          }, (result) => {
            if (result) {
              const selectedItem = {
                organizationName: result.organizationName,
                organizationId: initialValue
              }
              onSelect(id, selectedItem.organizationId || '', selectedItem.organizationName || '', isEditMode)
              setSelectedOptionLabel(selectedItem.organizationName || '')
              setResetCache(prevState => !prevState)
              handleSetDefaultOptions(selectedItem, initialValue)
            }
          }))
      }
    }
  }, [defaultOptions])

  useEffect(() => {
    if (!!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])

  useEffect(() => {
    //Note: When organization detail is fetched in parent component
    if (
      (!isEditModeItemLoaded || (!!initialLabel?.length && !selectedOptionLabel?.length))
      && isEditMode && asyncInitialValue && !!value && !!initialLabel) {
      const selectedItem = {
        organizationName: initialLabel,
        organizationId: value
      }
      setEditModeItemLoaded(true)
      setSelectedOptionLabel(initialLabel)
      if (isResetCacheRequiredInEditMode) {
        setResetCache(prevState => !prevState)
      }
      handleSetDefaultOptions(selectedItem, value)
    }
  }, [defaultOptions, initialLabel, asyncInitialValue, isEditMode, isEditModeItemLoaded, selectedOptionLabel, value])

  const handleFocusSearch = useCallback((e) => {
    setFocused(true)
  }, [])

  const handleBlurSearch = useCallback(() => {
    setFocused(false)
  }, [])

  const handleToggleModal = useCallback(() => {
    setOpenModal(!isOpenModal)
    setFocused(false)
  }, [isOpenModal])

  const handleAutoSelectFromModal = useCallback((selected, callback) => {
    setFocused(false)
    onSelect(id, selected.organizationId || '', selected.organizationName || '')
    setSelectedOptionLabel(selected.organizationName || '')
    callback()
  }, [id, onSelect])

  const handleGetOptionsForAutoSelectedValue = useCallback((data) => {
    setTimeout(() => {
      setResetCache(prevState => !prevState)
      const selectedItem = {
        organizationName: data.organizationName,
        organizationId: data.organizationId
      }
      handleSetDefaultOptions(selectedItem, data.organizationId)
    }, 100)
  }, [defaultOptions, handleSetDefaultOptions])

  const handleSelectOption = useCallback((selected, data) => {
    if (isMulti) {
      const lastElement = selected.length
        ? selected[selected.length - 1]
        : null

      if (!!lastElement && (!lastElement.organizationName || !lastElement.organizationId)) {
        onSelect(id, [])
      } else {
        onSelect(id, selected)
      }
    } else {
      if (data?.action === 'clear') {
        return null
      }
      setFocused(false)
      onSelect(id, selected?.organizationId || '', selected?.organizationId ? selected.organizationName : '')
      setSelectedOptionLabel(selected?.organizationName || '')
    }
  }, [onSelect])

  const handleCustomAction = useCallback((e) => {
    e.preventDefault()
    handleToggleModal()
  }, [handleToggleModal])

  const handleLoadMore = useCallback((search, page, prevOptions, others) => {
    return new Promise((resolve) => {
      if (!siteId && !isAssignmentPageSites && !isChildOrganizations && !isContactSiteList && !campaignId && !isDashboardOrganizationList) {
        const searchParams = isAHJOrganisation
          ? {
            isAHJOrganisation,
            organizationTypeId,
            organizationName: search,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            pageNumber: page
          }
          : {
            orgName: search,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            pageNumber: page
          }

        const action = isAHJOrganisation
          ? actions.getProjectsFilteredOrganizationsRequest
          : actions.getFilteredOrganizationsRequest

        dispatch(action(searchParams, (response) => {
          if (response) {
            const { items, ...paginationData } = response
            resolve({
              optionList: items,
              hasMore: paginationData.hasNextPage
            })

            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }))
      } else if (!siteId && isAssignmentPageSites && !isChildOrganizations && !isContactSiteList && !campaignId && !isDashboardOrganizationList) {
        const searchParams = {
          organizationName: search,
          observationTaskIds,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          pageNumber: page
        }

        dispatch(actions.getAssignmentOrganizationsListRequest(searchParams, (response) => {
          if (response) {
            const { items, ...paginationData } = response

            // ToDo optimise to use valuType and labelType instead of organizationId & organizationName
            const formattedItems = items.map(({ text, value }) => { return { organizationId: value, organizationName: text } })
            resolve({
              optionList: formattedItems,
              hasMore: paginationData.hasNextPage
            })

            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...formattedItems]) : formattedItems))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        })
        )
      } else if (isChildOrganizations) {
        const searchParams = {
          parentOrganizationId: organizationId,
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          genericSearch: search
        }
        dispatch(actions.getChildOrganizationListRequest(searchParams, (response) => {
          if (response) {
            const { items, ...paginationData } = response
            const formattedItems = items.map(({ childOrganizationName, childOrganizationId }) => { return { organizationId: childOrganizationId, organizationName: childOrganizationName } })
            resolve({
              optionList: formattedItems,
              hasMore: paginationData.hasNextPage
            })

            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }))
      } else if (isContactSiteList) {
        const searchParams = {
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          genericSearch: search,
          contactId,
          organizationToIgnore: organizationId
        }
        dispatch(actions.getOrganizationsRequest(searchParams, (response) => {
          if (response) {
            const { items, ...paginationData } = response
            const formattedItems = items.map(({ text, value }) => { return { organizationId: value, organizationName: text } })
            resolve({
              optionList: formattedItems,
              hasMore: paginationData.hasNextPage
            })

            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }))
      } else if (campaignId && isSharedCampaignSitesOrg) {
        const searchParams = {
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          organizationName: search,
          campaignId
        }
        dispatch(actions.getOrganizationDDLByCampaignIdRequest(searchParams, (response) => {
          if (response) {
            const { items, ...paginationData } = response
            const formattedItems = items.map(({ text, value }) => { return { organizationId: value, organizationName: text } })
            resolve({
              optionList: formattedItems,
              hasMore: paginationData.hasNextPage
            })

            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }))
      } else if (campaignId && isSharedCampaignUnlinkedSitesOrg) {
        const searchParams = {
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          organizationName: search,
          campaignId
        }
        dispatch(actions.getSharedCampaignUnLinkedSiteOrganizationDDLListRequest(searchParams, (response) => {
          if (response) {
            const { items, ...paginationData } = response
            const formattedItems = items.map(({ text, value }) => { return { organizationId: value, organizationName: text } })
            resolve({
              optionList: formattedItems,
              hasMore: paginationData.hasNextPage
            })

            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }))
      } else if (isDashboardOrganizationList) {
        const searchParams = {
          organizationName: search,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          pageNumber: page
        }
        dispatch(actions.getDashboardOrganizationDDLRequest(searchParams, (response) => {
          if (response) {
            const { items, ...paginationData } = response
            const formattedItems = items.map(({ text, value }) => { return { organizationId: value, organizationName: text } })
            resolve({
              optionList: formattedItems,
              hasMore: paginationData.hasNextPage
            })

            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }))
      } else {
        const searchParams = {
          siteId,
          organizationTypeId,
          organizationName: search,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          pageNumber: page
        }
        dispatch(actions.getProjectsFilteredOrganizationsRequest(searchParams, (response) => {
          if (response) {
            const { items, ...paginationData } = response
            resolve({
              optionList: items,
              hasMore: paginationData.hasNextPage
            })

            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        })
        )
      }
    })
  }, [organizationId, isAHJOrganisation, contactId, campaignId, siteId, organizationTypeId, isAssignmentPageSites, isSharedCampaignSitesOrg, isSharedCampaignUnlinkedSitesOrg, observationTaskIds, options])

  const tablePropsConfig = useMemo(() => {
    if (isTableView) {
      return {
        menuPortalTarget: document.body,
        menuPosition,
        styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) }
      }
    }
    if (isRequiredSelectPositionProps) {
      return getReactSelectPositionProps()
    }
    return {}
  }, [isTableView, menuPosition, isRequiredSelectPositionProps])

  const { WrapperComponent, wrapperComponentProps } = useMemo(() => {
    if (requireNoWrapper) {
      return {
        WrapperComponent: Fragment,
        wrapperComponentProps: {}
      }
    }
    return {
      WrapperComponent: Col,
      wrapperComponentProps: {
        className: "organization-search-container",
        sm: isWrapperStyleRequired ? 12 : null,
        md: isWrapperStyleRequired ? colSize?.md || 6 : null,
        xl: isWrapperStyleRequired ? colx || 4 : null
      }
    }
  }, [colSize, isWrapperStyleRequired, requireNoWrapper])

  const focusInputProps = useMemo(() => {
    if (!isFocusedStyleRequired) {
      return {}
    }

    return {
      onBlur: handleBlurSearch,
      onFocus: handleFocusSearch
    }
  }, [isFocusedStyleRequired, handleBlurSearch, handleFocusSearch])

  return (
    <>
      <WrapperComponent {...wrapperComponentProps}>
        <div className="form-group">
          {labelRequired && <CustomLabel title={label || CONTACTS.ORGANIZATION} required={isRequired} isLocalizedTitle={!label} />}
          <div className={isFocused ? `project-search isFocused organization-select ${className}` : `project-search ${className}`}>
            <CustomReactSelect
              customActionLabel={customActionLabel || getLocaleMessage(intl, ORGANIZATION_SEARCH.PLUS_ADD_ORGANIZATION)}
              id={id}
              additional={{
                page: 1
              }}
              cacheUniqs={[isResetCache]}
              isShowCustomAction={isShowCustomAction}
              name={name}
              menuListClassName={menuListClassName}
              placeholder={(isFocused && focusedPlaceholder) || (placeholder || getLocaleMessage(intl, ORGANIZATION_SEARCH.SEARCH_ORGANIZATIONS))}
              options={options}
              defaultOptions={options}
              defaultCustomOptions={defaultCustomOptions}
              isDisabled={isDisabled}
              isMulti={isMulti}
              hideSelectedOptions={false}
              isFormattedValue={!isMulti}
              value={
                !isMulti ? !!value ? {
                  organizationName: selectedOptionLabel,
                  organizationId: value
                } : null : value}
              lableType={'organizationName'}
              valueType={'organizationId'}
              menuPlacement={menuPlacement}
              getOptionLabel={option => option.organizationName}
              getOptionValue={option => option.organizationId}
              formattedOption={(option) => {
                return <CustomSelectOption data={option} isDisableTooltip={option.organizationId === '-1' && !!defaultCustomOptions.find(option => option.organizationName === 'All Organizations')} label={option.organizationName} value={option.organizationId} />
              }}
              onClickCustomAction={handleCustomAction}
              onLoadOptions={handleLoadMore}
              onSelect={handleSelectOption}
              {...focusInputProps}
              {...tablePropsConfig}
            />
            {isFocused && <Search />}
          </div>
        </div>
        {errors && <ErrorMessage
          isShow={!!errors[id]}
          message={errors[id]}
        />}
      </WrapperComponent>
      {isShowCustomAction && isOpenModal && <OrganizationModal
        isOpen={isOpenModal}
        onToggleModal={handleToggleModal}
        onSelect={handleAutoSelectFromModal}
        onGetOrganizationList={handleGetOptionsForAutoSelectedValue}
      />}
    </>
  )
}

OrganizationSearch.propTypes = {
  intl: PropTypes.object,
  isWrapperStyleRequired: PropTypes.bool,
  requireNoWrapper: PropTypes.bool,
  isFocusedStyleRequired: PropTypes.bool,
  asyncInitialValue: PropTypes.bool,
  className: PropTypes.string,
  customActionLabel: PropTypes.string,
  errors: PropTypes.object,
  focusedPlaceholder: PropTypes.string,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  initialLabel: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  siteId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  campaignId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  contactId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isEditMode: PropTypes.bool,
  isShowCustomAction: PropTypes.bool,
  isTableView: PropTypes.bool,
  isRequired: PropTypes.bool,
  isAssignmentPageSites: PropTypes.bool,
  isSharedCampaignSitesOrg: PropTypes.bool,
  isSharedCampaignUnlinkedSitesOrg: PropTypes.bool,
  observationTaskIds: PropTypes.array,
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  name: PropTypes.string,
  defaultOptions: PropTypes.array,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  isDisabled: PropTypes.bool,
  defaultCustomOptions: PropTypes.array,
  menuPosition: PropTypes.string,
  menuListClassName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  isChildOrganizations: PropTypes.bool,
  isResetCacheRequiredInEditMode: PropTypes.bool,
  isRequiredSelectPositionProps: PropTypes.bool,
  isContactSiteList: PropTypes.bool
}

OrganizationSearch.defaultProps = {
  isWrapperStyleRequired: true,
  requireNoWrapper: false,
  isFocusedStyleRequired: true,
  asyncInitialValue: false,
  className: "",
  focusedPlaceholder: "",
  id: 'org',
  initialLabel: '',
  initialValue: '',
  isEditMode: false,
  isAssignmentPageSites: false,
  isSharedCampaignSitesOrg: false,
  isResetCacheRequiredInEditMode: true,
  isRequiredSelectPositionProps: false,
  isSharedCampaignUnlinkedSitesOrg: false,
  observationTaskIds: [],
  siteId: 0,
  campaignId: 0,
  contactId: 0,
  isShowCustomAction: true,
  isTableView: false,
  isRequired: true,
  labelRequired: true,
  name: 'org_name',
  isMulti: false,
  defaultOptions: [],
  isDisabled: false,
  defaultCustomOptions: [],
  menuListClassName: "",
  isChildOrganizations: false,
  isContactSiteList: false,
  isAHJOrganisation: false,
  isDashboardOrganizationList: false
}

export default localeMessageWrapper(OrganizationSearch)
