import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { getLastElementObserver } from "@utils"
import CustomCheckboxSearchComponent from "./component"
import { getFormattedOptions } from "../helpers"

const initialOptionDetails = {
  list: [],
  pageNumber: 0,
  hasNextPage: false
}

//Todo: handle for no options for search text fro api

const CustomCheckboxSearch = ({
  id = "custom_checkbox_search",
  valueType = "value",
  labelType = "text",
  allSelectCheckboxLabel = "",
  inputPlaceholder = "",
  selectedFilters = [],
  isResetClicked = false,
  onChangeHeaderCheckbox = () => {},
  onFetchOptions = () => {},
  onSelectFilter = () => {},
  onResetClicked = () => {}
}) => {
  const [searchText, setSearchText] = useState("")
  const [isSearchApplied, setSearchApplied] = useState(false)
  const [isSearchCleared, setSearchCleared] = useState(false)
  const [typingTimeout, setTypingTimeout] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [hasNoOptions, setHasNoOptions] = useState(false)
  const [options, setOptions] = useState(Object.assign({}, initialOptionDetails))

  /**
   * lastElement: Used for auto loading next options, when user scroll to last visible element in options list
   */
  const [lastElement, setLastElement] = useState(null)
  const observerRef = useRef(null)

  const { list, hasNextPage, pageNumber } = useMemo(() => options, [options])

  const unselectedFilters = useMemo(() => {
    return list.filter(
      (option) => !selectedFilters.some((selectedFilter) => selectedFilter[valueType] === option[valueType])
    )
  }, [list, selectedFilters, valueType])

  const filtersList = useMemo(
    () => [...selectedFilters, ...unselectedFilters],
    [selectedFilters, unselectedFilters]
  )

  const lastElementIndex = useMemo(() => filtersList.length - 1, [filtersList])

  const handleFetchOptions = useCallback(
    (params) => {
      const { page = 1, search = "", isSearchFilter = false } = params || {}

      setLoading(true)

      const searchVal = isSearchFilter
        ? search
          ? search.trim()
          : ""
        : searchText.trim() || ""

      if (!searchVal) {
        setSearchApplied(false)
        setTypingTimeout(null)
        setLoading(false)
        return
      }

      const queryPayload = {
        pageNumber: page,
        pageSize: DEFAULT_VM_PAGE_SIZE,
        searchText: searchVal
      }

      setSearchApplied(!!searchVal)

      onFetchOptions(queryPayload, (response) => {
        if (response) {
          setOptions((prevOptions) => {
            return {
              list:
                page === 1
                  ? response.items
                  : [...prevOptions.list, ...response.items],
              pageNumber: response.pageIndex,
              hasNextPage: response.hasNextPage
            }
          })
          if (!response.items.length) {
            setHasNoOptions(true)
          }
        } else {
          setHasNoOptions(true)
        }
        setLoading(false)
        setTypingTimeout(null)
      })
    },
    [searchText, onFetchOptions]
  )

  //To load next options page
  const handleLoadMore = useCallback(() => {
    handleFetchOptions({ page: pageNumber + 1 })
  }, [pageNumber, handleFetchOptions])

  //To get last element in option list from DOM
  useEffect(() => {
    (() => {
      setTimeout(() => {
        if (!filtersList?.length) {
          return null
        }
        const lastEleDetail = filtersList[filtersList.length - 1]
        if (!lastEleDetail || !lastEleDetail.hasOwnProperty(valueType)) {
          return null
        }

        setLastElement(
          document.getElementById(
            `last-activity-item-${lastEleDetail[valueType]}`
          )
        )
      })
    })()
  }, [filtersList])

  //To add observer on last element of options rendered
  useEffect(() => {
    if (hasNextPage && lastElement) {
      observerRef.current = getLastElementObserver({
        onLoadMore: handleLoadMore
      })
      // Observe the last element
      if (observerRef.current) {
        observerRef.current.observe(lastElement)
      }
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [hasNextPage, lastElement])

  const handleChangeSearchText = useCallback(
    (e) => {
      const { value } = e.target
      if (isResetClicked) {
        onResetClicked(false)
      }
      if (isSearchCleared) {
        setSearchCleared(false)
      }
      setSearchText(value)
      setHasNoOptions(false)
      //Todo: if need to reset here after user stoped typing
      //To reset already displayed options, on changing search
      if (!!list.length) {
        setOptions(Object.assign({}, initialOptionDetails))
      }

      if (typingTimeout) {
        clearTimeout(typingTimeout)
      }

      const lengthOfSearch = value.trim().length
      if (
        (!!lengthOfSearch && lengthOfSearch >= 1) ||
        (!lengthOfSearch && !!searchText.length && isSearchApplied)
      ) {
        setTypingTimeout(
          setTimeout(() => {
            handleFetchOptions({
              page: 1,
              search: value,
              isSearchFilter: true
            })
          }, 1000)
        )
      }
    },
    [isSearchApplied, isSearchCleared, list, searchText, typingTimeout, handleFetchOptions]
  )

  const handleSelectFilter = useCallback(
    (event, id, name) => {
      const selectedFilter = { [valueType]: id, [labelType]: name }
      let updatedFilters
      if (event.target.checked) {
        updatedFilters = [...selectedFilters, selectedFilter]
        setOptions((prevState) => ({
          ...prevState,
          list: getFormattedOptions(prevState.list, id, valueType)
        }))
      } else {
        updatedFilters = selectedFilters.filter(
          (item) => item[valueType] !== id
        )
        setOptions((prevState) => ({
          ...prevState,
          list: [...getFormattedOptions(prevState.list, id, valueType), selectedFilter]
        }))
      }
      onSelectFilter(updatedFilters)
    },
    [onSelectFilter, selectedFilters]
  )

  const handleClearSearchText = useCallback(() => { 
    setSearchText('') 
    setSearchCleared(true)
    setHasNoOptions(false)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (isResetClicked) {
      setSearchText('')
      setHasNoOptions(false)
    }
  }, [isResetClicked])

  return (
    <CustomCheckboxSearchComponent
      id={id}
      valueType={valueType}
      labelType={labelType}
      allSelectCheckboxLabel={allSelectCheckboxLabel}
      inputPlaceholder={inputPlaceholder}
      filtersList={isResetClicked ? [] : isSearchCleared ? selectedFilters : filtersList}
      selectedFilters={selectedFilters}
      lastElementIndex={lastElementIndex}
      isLoading={isLoading}
      hasNoOptions={hasNoOptions}
      searchText={searchText}
      onChangeSearchText={handleChangeSearchText}
      onChangeHeaderCheckbox={onSelectFilter}
      onSelectFilter={handleSelectFilter}
      onClearSearchText={handleClearSearchText}
    />
  )
}

export default CustomCheckboxSearch
