import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateOrganizationAddress
 * @param {object} data 
 *  {
 *    "organizationId": 0,
 *    "addresses": [
 *       {
 *        "organizationAddressId": 0,
 *        "isPrimary": true,
 *        "streetAddress": "string",
 *        "addressUnit": "string",
 *        "city": "string",
 *        "state": "string",
 *        "postCode": "string",
 *        "countryCode": "string",
 *        "primaryPhone": "string",
 *        "addressName": "string"
 *       }, etc...
 *     ]
 *   }
 *
 * Note:
 *  "addresses" can contain multiple addresses.
 *  If some address is primary address then set "isPrimary" = true else false,
 *  There should be only one primary address in list
 * 
 */
export const updateOrganizationAddress = (data) => {
  const { url } = config.updateOrganizationAddress

  const addressList = []
  data.addresses.forEach(address => {
    const updatedAddress = {
      isPrimary: address.isPrimary
    }
    if (!!address.organizationAddressId) {
      updatedAddress.organizationAddressId = address.organizationAddressId
    }
    if (!!address.streetAddress) {
      updatedAddress.streetAddress = address.streetAddress
    }
    if (!!address.addressUnit) {
      updatedAddress.addressUnit = address.addressUnit
    }
    if (!!address.city) {
      updatedAddress.city = address.city
    }
    if (!!address.state) {
      updatedAddress.state = address.state
    }
    if (!!address.postCode) {
      updatedAddress.postCode = address.postCode
    }
    if (!!address.countryCode) {
      updatedAddress.countryCode = address.countryCode
    }
    if (!!address.primaryPhone) {
      updatedAddress.primaryPhone = address.primaryPhone
    }
    if (!!address.addressName) {
      updatedAddress.addressName = address.addressName
    }
    addressList.push(updatedAddress)
  })
  
  const payload = {
    organizationId: data.organizationId,
    addresses: addressList
  }

  return putDataApi(url({ organizationId: data.organizationId }), payload)
}

export default updateOrganizationAddress
