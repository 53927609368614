import config from "./APIConfig"
import { putDataApi } from '../../index'
/**
 * @method updateBlockEscalation
 * @param {object} data 
 * 
 * API payload:-
 * {
 *  "wfCampaignSetupId": 0,
 *  "escalations": [
 *      {
 *          "escalationName": "string",
 *          "wfEscalationTypeId": 0,
 *          "wfEscalationCriteriaId": 0,
 *          "wfCriteriaTypeId": 0,
 *          "escalateAfterUnapprovedUnitsPercentage": "string",
 *          "escalateAfterUnapprovedActivitiesNumber": 0,
 *          "escalateAfterDaysNumber": 0,
 *          "escalateAfterUnapprovedUnitsNumber": 0,
 *           "riskLevelIds": [
 *             {
 *              "riskLevelId": 0
 *             }
 *           ],
 *          "contactIds": [
 *              0
 *          ],
 *          "wfContactMethodIds":[
 *               0
 *          ]
 *      }
 *      ]
 * }
 */

export const updateBlockEscalation = (data) => {
  const { url } = config.updateBlockEscalation
  data.escalations.forEach((item) => { delete item.contactIdsValue })
  const escalationData = data.escalations.map((item) => {
    return ({
      escalateAfterDaysNumber: Number(item.escalateAfterDaysNumber || 0),
      escalateAfterFailedTrainingsNumber: Number(item.escalateAfterFailedTrainingsNumber || 0),
      escalateAfterUnapprovedActivitiesNumber: Number(item.escalateAfterUnapprovedActivitiesNumber || ""),
      escalateAfterUnapprovedUnitsNumber: Number(item.escalateAfterUnapprovedUnitsNumber || 0),
      escalateAfterUnapprovedUnitsPercentage: item.escalateAfterUnapprovedUnitsPercentage || "",
      escalationName: item.escalationName || "",
      riskLevelIds: item.riskLevelIds.map(riskLevel => ({ riskLevelId: Number(riskLevel.value) })) || [],
      wfCriteriaTypeId: Number(item.wfCriteriaTypeId || 0),
      wfEscalationCriteriaId: Number(item.wfEscalationCriteriaId || 0),
      wfEscalationTypeId: Number(item.wfEscalationTypeId || 0),
      contactIds: item.contactIds?.map(x => ({ contactId: x.contactId })) || [],
      wfContactMethodIds: item.wfContactMethodIds || [0]
    })
  })

  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    escalations: escalationData
  }
  return putDataApi(url(data.wfCampaignSetupId), payload, null, { isApiNeedsToHandleErrors: false })
}

export default updateBlockEscalation