import config from "./APIConfig"
import { putDataApi } from "../index"

/**
 * @method updateBulkCorrectionAction
 * This api is called when the trade accept the correction for some activity
 * [Failed inspection correction]
 * 
 * @param {object} data 
 * 
 * {
 *  wfActionIds: "string",
 *  note: "string",
 *  responseImages: []
 * }
 * 
 */
export const updateBulkCorrectionAction = async (data) => {
  const { url } = config.updateBulkCorrectionAction
  return await putDataApi(url, data)
}

export default updateBulkCorrectionAction
