import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'

const { ERRORS } = LANGUAGE_CONSTANTS.ORGANIZATION_SEARCH

export const validateOrgDetails = ({
  organizationCategoryId,
  organizationName,
  organizationTypeId
}) => {
  const errors = {}

  if (!organizationName.trim().length) {
    errors['organizationName'] = <LocaleMessage id={ERRORS.ORGANIZATION_NAME} />
  }
  if (!organizationTypeId.trim().length) {
    errors['organizationTypeId'] = <LocaleMessage id={ERRORS.ORGANIZATION_TYPE} />
  }
  if (!organizationCategoryId.trim().length) {
    errors['organizationCategoryId'] = <LocaleMessage id={ERRORS.ORGANIZATION_CATEGORY} />
  }

  return errors
}
