export const GET_OVERVIEW_DETAILS_REQUEST = 'GET_OVERVIEW_DETAILS_REQUEST'
export const GET_REPORT_WIDGET_STATUS_REQUEST = 'GET_REPORT_WIDGET_STATUS_REQUEST'
export const GET_ACTIVE_PROJECT_SITES_REQUEST = 'GET_ACTIVE_PROJECT_SITES_REQUEST'
export const GET_ACTIVE_PROGRAMS_REQUEST = 'GET_ACTIVE_PROGRAMS_REQUEST'
export const GET_UPCOMING_OBSERVATION_TASKS_REQUEST = 'GET_UPCOMING_OBSERVATION_TASKS_REQUEST'
export const GET_UNAPPROVED_ACTIVITIES_ROLE_CHART_STATUS_REQUEST = 'GET_UNAPPROVED_ACTIVITIES_ROLE_CHART_STATUS_REQUEST'
export const GET_UNAPPROVED_ROLE_CHART_DELTA_REQUEST = 'GET_UNAPPROVED_ROLE_CHART_DELTA_REQUEST'
export const GET_ACTIVITY_TYPE_CHART_REQUEST = 'GET_ACTIVITY_TYPE_CHART_REQUEST'
export const GET_TRAINING_CHART_WIDGET_REQUEST = 'GET_TRAINING_CHART_WIDGET_REQUEST'
export const GET_TRAINING_CHART_DELTA_REQUEST = 'GET_TRAINING_CHART_DELTA_REQUEST'
export const GET_COURSE_PARTICIPATION_CHART_WIDGET_REQUEST = 'GET_COURSE_PARTICIPATION_CHART_WIDGET_REQUEST'
export const GET_KNOWLEDGE_ASSESSMENT_WIDGET_REQUEST = 'GET_KNOWLEDGE_ASSESSMENT_WIDGET_REQUEST'
export const GET_RECENT_CAMPAIGN_ACTIVITY_REQUEST = 'GET_RECENT_CAMPAIGN_ACTIVITY_REQUEST'
export const GET_DUCT_LEAKAGE_TRACING_WIDGET_REQUEST = 'GET_DUCT_LEAKAGE_TRACING_WIDGET_REQUEST'
export const GET_HOUSE_INFILTRATION_TRACING_WIDGET_REQUEST = 'GET_HOUSE_INFILTRATION_TRACING_WIDGET_REQUEST'


/****************************************************/
/** GET_OVERVIEW_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method getOverviewDetailsRequest :  To get the overview details
 *
 */
export const getOverviewDetailsRequest = (payload, callback = () => { }) => {
  return {
    type: GET_OVERVIEW_DETAILS_REQUEST,
    payload,
    callback
  }
}
  
/****************************************************/
/** GET_REPORT_WIDGET_STATUS_REQUEST **/
/****************************************************/

/**
 * @method getOverviewWidgetStatusRequest :  To get the overview details
 *
 */
 export const getOverviewWidgetStatusRequest = (payload, callback = () => { }) => {
  return {
    type: GET_REPORT_WIDGET_STATUS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTIVE_PROJECT_SITES_REQUEST **/
/****************************************************/

/**
 * @method getActiveProjectSitesRequest :  To get active project sites
 *
 */
export const getActiveProjectSitesRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ACTIVE_PROJECT_SITES_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTIVE_PROGRAMS_REQUEST **/
/****************************************************/

/**
 * @method getActiveProgramsRequest :  To get active programs
 *
 */
export const getActiveProgramsRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ACTIVE_PROGRAMS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_UPCOMING_OBSERVATION_TASKS_REQUEST **/
/****************************************************/

/**
 * @method getUpcomingObservationTasksRequest :  To get upcoming observation tasks
 *
 */
export const getUpcomingObservationTasksRequest = (payload, callback = () => { }) => {
  return {
    type: GET_UPCOMING_OBSERVATION_TASKS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_UNAPPROVED_ACTIVITIES_ROLE_CHART_STATUS_REQUEST **/
/****************************************************/

/**
 * @method getUnapprovedRoleChartStatusWidget :  To get the unapproved activities role or contact chart status
 *
 */
 export const getUnapprovedRoleChartStatusWidget = (payload, callback = () => { }) => {
  return {
    type: GET_UNAPPROVED_ACTIVITIES_ROLE_CHART_STATUS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_UNAPPROVED_ROLE_CHART_DELTA_REQUEST **/
/****************************************************/

/**
 * @method getUnapprovedRoleChartDeltaRequest :  To get the unapproved activities role delta
 *
 */
 export const getUnapprovedRoleChartDeltaRequest = (payload, callback = () => { }) => {
  return {
    type: GET_UNAPPROVED_ROLE_CHART_DELTA_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTIVITY_TYPE_CHART_REQUEST **/
/****************************************************/

/**
 * @method getActivityChartWidgetRequest :  To get the activity types chart widget 
 *
 */
 export const getActivityChartWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ACTIVITY_TYPE_CHART_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TRAINING_CHART_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getTrainingChartWidgetRequest :  To get the training chart widget 
 *
 */
 export const getTrainingChartWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_TRAINING_CHART_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TRAINING_CHART_DELTA_REQUEST **/
/****************************************************/

/**
 * @method getTrainingChartDeltaRequest :  To get the training chart delta 
 *
 */
 export const getTrainingChartDeltaRequest = (payload, callback = () => { }) => {
  return {
    type: GET_TRAINING_CHART_DELTA_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_KNOWLEDGE_ASSESSMENT_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getKnowledgeAssessmentWidgetRequest :  To get the knowledge assessment widget data
 *
 */
 export const getKnowledgeAssessmentWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_KNOWLEDGE_ASSESSMENT_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_COURSE_PARTICIPATION_CHART_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getCourseParticipationChartRequest :  To get the course participation chart widget 
 *
 */
 export const getCourseParticipationChartRequest = (payload, callback = () => { }) => {
  return {
    type: GET_COURSE_PARTICIPATION_CHART_WIDGET_REQUEST,
    payload,
    callback
  }
 }

 /****************************************************/
/** GET_DUCT_LEAKAGE_TRACING_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getDuctLeakageTracingWidgetRequest :  To get the duct leakage chart widget 
 *
 */
 export const getDuctLeakageTracingWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_DUCT_LEAKAGE_TRACING_WIDGET_REQUEST,
    payload,
    callback
  }
 }

 /****************************************************/
/** GET_HOUSE_INFILTRATION_TRACING_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getHouseInfiltrationTracingWidgetRequest :  To get the house infiltration chart widget 
 *
 */
 export const getHouseInfiltrationTracingWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_HOUSE_INFILTRATION_TRACING_WIDGET_REQUEST,
    payload,
    callback
  }
 }

/****************************************************/
/** GET_RECENT_CAMPAIGN_ACTIVITY_REQUEST **/
/****************************************************/

/**
 * @method getRecentCampaignActivityRequest :  To get the recent campaign activity
 *
 */
 export const getRecentCampaignActivityRequest = (payload, callback = () => { }) => {
  return {
    type: GET_RECENT_CAMPAIGN_ACTIVITY_REQUEST,
    payload,
    callback
  }
}