import AppSettings from '@app-settings'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { v4 as uuidv4, validate } from 'uuid'

import * as actions from "@store/actions"
import { ROUTES } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage, Notification } from '@views/components'

import { store } from '../store'

const { NO_DATA_MESSAGES, LOADER, REPORTS: { DATA_ACCESS: { EXPORT_MESSAGE } } } = LANGUAGE_CONSTANTS

const { REACT_APP_DOCUMENT_EXPORT_URL } = AppSettings

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

/**
 * @method getUniqueId : To get unique id value
 * @returns {string}
 */
export const getUniqueId = () => {
  return Math.random().toString(36).slice(2)
}

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

/**
 * @method validateEmail : Method to validate email, if email contains characters, not according to given email format, false is returned.
 * @param {string} value : email value to be tested.
 */
export const validateEmail = (value) => {
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(value)
}

/**
 * @method validatePassword : Method to validate password, if email contains characters, not according to given password format, false is returned.
 * @param {string} value : password value to be tested.
 */
export const validatePassword = (value = '') => {
  const regex = /^(?=.{5,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W])/
  return regex.test(value)
}

/**
 * @method getFormattedDate
 * @param {Date} date
 * @returns date in "MM/DD/yyyy" format i.e 08/31/2021
 */
export const getFormattedDate = (date, dateFormat) => {

  if (!date) return '-'

  if (dateFormat) {
    return moment(date).format(dateFormat).toString()
  }
  return moment(date).format("M/DD/YYYY").toString()
}

export const getDateWithTimeInterval = (date) => {

  if (!date) return '-'

  return moment(date).format("dddd, MMM D, LT").toString()
}

export const getFormattedTime = (date) => {

  if (!date) return '-'

  return moment(date).format("hh:mm A").toString()
}

// ** Checks if the passed date is today
export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return ROUTES.loginPage
}

/**
 * @method getMaskedPhone
 * @param {Number|String} phone
 * @returns masked phone number i.e (678) 444-9383
 */
export const getMaskedPhone = (phone) => {
  const phoneNumber = phone.toString()
  const isPlusFormat = phoneNumber[0] === '+'
  const value = phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
  const partialResult1 = !value[2] ? value[1] : `(${value[1]})  ${value[2]}`
  const partialResult2 = value[3] ? `-${value[3]}` : ''

  return `${!!isPlusFormat ? '+ ' : ''}${partialResult1}${partialResult2}`
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

/**
 * @method cloneArrayOfObject : To clone simple array of objects
 *
 * @param {Array<any>} data : Initial array value
 *
 * @returns {Array<any>} Cloned array
 */
export const cloneArrayOfObject = (data) => {
  return (
    (Array.isArray(data) && data.map((val) => Object.assign({}, val))) || []
  )
}

/**
 * @method getIdFromUrl : To find id from url
 */
export const getIdFromUrl = () => {
  const url = window.location.pathname
  const pathKeyList = url.split('/')
  const id = pathKeyList.pop()
  return id
}

/**
 * @method isValidObject : To verify object, Is it valid ?
 * @param {Object} object : object variable
 */
export const isValidObject = (object) => {
  return object && Object.keys(object).length
}

/**
 * @method getLocaleMessage : Method to format text to selected locale
 * @param {object} intl 
 * @param {string} id 
 * @returns {string} : Returns formatted text value
 */
export const getLocaleMessage = (intl, id, values = {}) => {
  if (id && intl) {
    return intl.formatMessage({ id, defaultMessage: id }, values)
  } else {
    return ''
  }
}

/**
 * @method toDataURL :  To get base64 url for file
 * @param {object} fileObj 
 * 
 * @returns base64 url
 */
export const toDataURL = fileObj => fetch(fileObj)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))


/**
 * Async For Each loop
 *
 * @param {Array} array
 * @param {Function} callback
 */
export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

/**
 * To check all array elements are selected or not
 *
 * @param {Array} all
 * @param {Array} selected
 */
export const isAllSelected = (key = '', all = [], selected = []) => {
  return key ? all.every(item => {
    const id = parseInt(item[key])
    const isPresent = selected.find(s => s === id)
    return isPresent
  }) : false
}

/**
 * @method getUniqueUuid : To get unique id
 * @returns {string}
 */
export const getUniqueUuid = () => {
  const id = uuidv4()
  return id
}

/**
 * @method validateUuid : To get unique id
 * @param {String} id Unique uuid
 * @returns {Boolean}
 */
export const validateUuid = (id) => {
  return validate(id)
}

/**
 * @method downloadFile : To download csv file
 * @param {array} data
 * @param {string} fileName
 * @param {string} fileType
 * @param {function} setLoading
 */

export const downloadFile = ({ data, fileName, fileType, onSetLoading }) => {
  const blob = new Blob([data], { type: fileType })
  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  })
  a.dispatchEvent(clickEvt)
  a.remove()
  window.URL.revokeObjectURL(blob)
  onSetLoading(false)
}

export const getDateTimeFormat = (date) => {
  return date ? moment(date).format('YYYY-MM-DDTHH:mm:ss') : ''
}

export const validatePhoneNumber = (event) => {
  if (event.key === "e") {
    return event.preventDefault()
  }
}

export const orderDirection = {
  1: "desc",
  0: "asc"
}

export const dateFormat = (date) => {
  return date ? moment(date).format('MM/DD/YYYY') : ''
}

export const getExportDocumentPayload = ({
  isFilterApplied,
  exportType,
  fileName,
  parameterName,
  parameterType,
  parameterValue
}) => {
  const documentExtension = exportType.toLowerCase()
  const payload = {
    format: documentExtension,
    exportDocumentName: fileName,
    parameters: []
  }
  if (isFilterApplied) {
    payload.parameters.push({
      name: parameterName,
      rawValue: String(parameterValue)
    })
  }
  return {
    payload,
    url: REACT_APP_DOCUMENT_EXPORT_URL
  }
}

// Third party API call to get status for report being generated
export const getExportDocumnentStatus = async( 
  fileURL,
  callback = () => { }
) => {
  const res = await window.fetch(fileURL)
  callback(res)
}

/**
 * @method isEmoji : Method returns true if your string contains one or more emojis.
 * @param {string} : string to check 
 * @returns {boolean} : Returns true if contains one or more emojis else false
 */

export const isEmoji = (str) => {
  const ranges = ['(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])']
  return str.match(ranges.join('|'))
}

export const formatCurrency = (value, fractionCount) => {
  fractionCount = fractionCount ? Number(fractionCount) : 0
  return Intl.NumberFormat('en-US', { notation: "compact", maximumFractionDigits: fractionCount }).format(value)
}

export const getDayOfMonth = (daysOfMonth) => {
  let result = ''
  daysOfMonth.forEach((number, index) => {
    if (index !== 0) {
      result += ', '
    }
    const suffixes = ['th', 'st', 'nd', 'rd']
    const digit = number % 100
    const element = number + (suffixes[(digit - 20) % 10] || suffixes[digit] || suffixes[0])
    result += element
  })
  return result
}

//To create the Intersection Observer for last element in rendered list
const elementObserverOptions = {
  root: null, // Use the viewport as the root
  rootMargin: '0px',
  threshold: 0.5 // Trigger when at least 50% of the last element is visible
}

//To create the Intersection Observer for last element in any rendered list
export const getLastElementObserver = ({ onLoadMore = () => { } }) => {
  return new IntersectionObserver(
    (entries) => {
      if (entries[0]?.isIntersecting) {
        onLoadMore()
      }
    },
    elementObserverOptions)
}

export const getTableMessages = (value) => {
  const messages = value ? NO_DATA_MESSAGES[value.replace(/\s/g, '_').toUpperCase()] : {}
  return ({
    loading: <LocaleMessage id={LOADER.LOADING} />,
    noData: <LocaleMessage id={messages.NO_DATA} />,
    noDataForFilters: <LocaleMessage id={messages.NO_DATA_FOR_FILTERS} />
  })
}

/**
 * To prevent accepting decimal, exponential, signed (negative/postive) number in Input element.
 */
export const blockInvalidCharForInput = e => ['.', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

/**
 * @method checkIfValidCharForNumbericInput : To Check if text has only numbers
 * @param {string} value : value to be tested.
 * @param {bool} isAllowNegativeNumber : To allow negative number.
 */
export const checkIfValidCharForNumbericInput = (value, isAllowNegativeNumber) => {
  const regex = isAllowNegativeNumber ? /^-?[0-9]+$/ : /^[0-9]+$/
  return regex.test(value)
}
