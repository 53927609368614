import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'

import { CustomUILoader } from "@views/components"
import LocaleMessageWrapper from '@views/components/locale-message'

import { DEFAULT_COL_DEF, DEFAULT_SIDEBAR_DEF, ROW_MODEL_TYPE, ROW_SELECTION_TYPE, TOOLTIP_SHOW_DELAY } from './config'
import GridTableHeaderComponent from "./GridTableHeaderComponent"
import {
  changeRowHeight,
  createServerSideDatasource,
  getContextMenuItems,
  getColConfig,
  getMainMenu,
  onGridBodyScroll,
  getUpdatedTableComponentProps,
  handleRegisterServerSideDatasource,
  handleRemoveServerSideDatasource,
  handleRefreshServerSideGrid,
  handleApplyTransaction,
  handleUpdateSpecificRowValues,
  getScreenBasedTableHeight
} from './helpers'
import { GridContext } from "./GridContext"

import ExpandableView from "../ExpandableView"

const GridViewComponent = ({
  intl,
  gridRef,
  className = "",
  keyField = "",
  columns = [],
  data = [],
  isLoadingChildGrid = false,
  expandedRowId = "",
  subData = {},
  isLoading = false,
  isColumnMenuRequired = false,
  expandableRows = false,
  noDataComponent,
  noDataMessage = "",
  isServerSide = false,
  pagination = true,
  tableComponentProps: tableComponentPropsList = {},
  pageNumber,
  totalCount,
  isTableMarginRequired,
  selectedRows,
  selectableRows,
  isClearSelectedRows,
  noDataMessageForSubTable,
  onSelectedRowsChange,
  onSelectableRowDisabled,
  pageSize,
  expandableRowsComponent = () => <></>,
  onRowClicked = () => { },
  onRowExpandToggled = () => { },
  onOpenNewTabClicked = () => { },
  onSort = () => { },
  onPagination = () => { },
  onFirstRenderCheckboxSelection = () => { }
}) => {
  const containerRef = useRef(null)
  const [updatedExpandedRows, setUpdatedExpandedRows] = useState({})
  const [rowData, setRowData] = useState([])

  const [isAllSelected, setAllSelected] = useState(false)
  const [isAutoUnselect, setAutoUnselect] = useState(false)
  const [isViewApplied, setViewApplied] = useState(false)
  const [tableSize, setTableSize] = useState(getScreenBasedTableHeight())

  useEffect(() => {
    const handleSelectView = () => {
      setViewApplied(false)
    }
    window.addEventListener("onResetView", handleSelectView)
    return () => window.removeEventListener("onResetView", handleSelectView)
  }, [])

  const tableComponentProps = useMemo(() => {
    return getUpdatedTableComponentProps(tableComponentPropsList)
  }, [tableComponentPropsList])

  const rowSelection = useMemo(() => {
    return tableComponentProps.rowSelection || ROW_SELECTION_TYPE.SINGLE
  }, [tableComponentProps.rowSelection])

  useEffect(() => {
    const container = containerRef.current

    const handleResize = () => {
      // Handle resize event here
      setTableSize(getScreenBasedTableHeight())
    }

    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(container)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    let gridRefTimeout = null
    if (!isServerSide) {
      setRowData(data)
      // SETTING SCROLL TO TOP WHEN TABLE DATA CHANGES
      if (gridRef?.current?.api) {
        gridRefTimeout = setTimeout(() => {
          gridRef?.current?.api.ensureIndexVisible(0)
        }, 100)
      }
    }
    return () => {
      clearTimeout(gridRefTimeout)
    }
  }, [data])

  useEffect(() => {
    if (gridRef.current.api && selectedRows.length === 0) {
      gridRef.current.props.onHeaderCheckboxSelection(false)
      tableComponentProps.onChangeAllSelectCheckbox(false)
      gridRef.current.api.forEachNode((node) => {
        node.setSelected(false)
      })
    }
  }, [selectedRows.length])

  const handleStopCellEditing = useCallback(() => {
    if (gridRef.current && tableComponentProps.isCellLooseFocus) {
      setTimeout(() => {
        gridRef.current.api.stopEditing()
      }, 100)
      tableComponentProps.onCellLooseFocus(false)
    }
  }, [tableComponentProps.isCellLooseFocus])

  useEffect(() => {

    const handleClick = (e) => {
      const agGridElement = document.querySelector('.ag-root-wrapper')

      if (agGridElement && agGridElement.contains(e.target)) {
        // Clicking inside ag-grid will stop cell editing due to ag grid's default stop editing logic as 
        // If focus in the grid goes to another cell, the editing will stop.
      } else {
        // Clicked outside the ag-Grid table
        handleStopCellEditing()
      }
    }

    document.body.addEventListener('click', handleClick)

    return () => {
      document.body.removeEventListener('click', handleClick)
    }
  }, [tableComponentProps.isCellLooseFocus])

  useEffect(() => {
    if (!!expandedRowId && isLoadingChildGrid && !!updatedExpandedRows[expandedRowId]) {
      const tran = {
        update: [{ ...updatedExpandedRows[expandedRowId], expandedRowId, isLoadingChildGrid }]
      }
      gridRef.current.api.applyTransaction(tran)
    } else if (!!expandedRowId && !!subData && !!updatedExpandedRows[expandedRowId]) {
      const tran = {
        update: [{ ...updatedExpandedRows[expandedRowId], expandedRowId, subData }]
      }
      gridRef.current.api.applyTransaction(tran)
      setUpdatedExpandedRows(prevState => ({ ...prevState, [expandedRowId]: undefined }))
    }
  }, [updatedExpandedRows, isLoadingChildGrid, expandedRowId, subData])

  const detailCellComponent = useMemo(() => {
    return (props) => <ExpandableView {...props} expandableRowsComponent={expandableRowsComponent} />
  }, [expandableRowsComponent])

  const getRowId = useMemo(() => {
    return (params) => {
      return tableComponentProps.useStringFormatKeyFieldValue ? `${params.data[keyField]}` : params.data[keyField]
    }
  }, [keyField, tableComponentProps.useStringFormatKeyFieldValue])

  const handleKeyDown = useCallback((event) => {
    if (event.isDefaultPrevented()) {
      event.stopPropagation()
    }
  }, [])

  const colConfig = useMemo(() => {
    return getColConfig({ columns, expandableRows, gridRef, sortOrderColumn: tableComponentProps.sortOrderColumn, sortOrderDirection: tableComponentProps.sortOrderDirection }).map(col => {
      Object.keys(col).forEach(key => col[key] === undefined && delete col[key])
      return { ...col }
    })
  }, [columns])

  const gridTableComponents = useMemo(() => {
    return {
      agColumnHeader: GridTableHeaderComponent
    }
  }, [])

  const handleCellClicked = useCallback((cell) => {
    if (cell.column.getColId() === 'actions' || cell.column.colDef.suppressRowClick) {
      return
    }
    if (!cell.data && !!cell.node?.group) {
      return
    }
    onRowClicked(cell.data)
  }, [onRowClicked])

  const handleModelUpdated = useCallback(e => {
    //Note: For resizing columns on user interaction with rows or columns eg. sort
    // e.api.sizeColumnsToFit() //Todo: check this, its causing issue in applying selected view on grid 
    //Note: For displaying and hidding overlays
    if (e.api.getDisplayedRowCount()) {
      e.api.hideOverlay()
    } else {
      e.api.showNoRowsOverlay()
    }

    //Note: To be used if need to use row expand feature based on logic in parent component
    // e.api.forEachNode(function (node) {
    //   const expandedRowData = subData ? subData[`sub_grid_key_${node.data[keyField]} `] : ''
    //   const isAlreadyExpanded = !!expandedRowData
    //   if (node.expanded && !isAlreadyExpanded) {
    //     onRowExpandToggled(node.expanded, node.data, () => {
    //       setUpdatedExpandedRows(prevState => ({ ...prevState, [node.data[keyField]]: node.data }))
    //     })
    //   } else if (isAlreadyExpanded && !node.expanded) {
    //     onRowExpandToggled(node.expanded, node.data)
    //   }
    // })
  }, [expandedRowId, subData, onRowExpandToggled])

  //To handle select/unselect and reset parent state if user select/inselect from individual row
  const handleRowSelected = useCallback((event) => {
    const gridRefProps = gridRef.current.props

    if (!isAutoUnselect && (!event.node.selected || !isAllSelected)) {
      // const selectedRowList = gridRef.current.api.getSelectedRows()
      const selectedRowList = []
      gridRef.current.api.forEachNode((node) => {
        if (node.selected) {
          selectedRowList.push(node.data)
        }
      })

      const displayedRowCount = gridRef.current.api.getDisplayedRowCount()
      const displayedRowCountVal = displayedRowCount % 10 === 1 ? displayedRowCount - 1 : displayedRowCount
      const rowListCount = totalCount > displayedRowCount ? displayedRowCountVal : displayedRowCountVal
      //To check if all selected then set setAllSelected === true else false
      const isAllRowsSelected = rowListCount === selectedRowList.length && !!selectedRowList.length
      //To update all selected checkbox value
      gridRefProps.onHeaderCheckboxSelection(isAllRowsSelected)
      //To update selected in parent component
      gridRefProps.onSelectedRowsChange([...selectedRowList], { isAllSelected: isAllRowsSelected })
    }
  }, [selectedRows, isAllSelected, isAutoUnselect, totalCount])

  const setColumnManagementPanel = useCallback(() => {
    const columnToolPanel = gridRef.current.api.getToolPanelInstance('columns')
    columnToolPanel.setColumnLayout(colConfig.filter(item => item.name))
  }, [])

  const handleLoadRowData = useCallback(({ currentPage, ...payload }) => {
    return new Promise(function (resolve, reject) {
      onPagination({ page: currentPage + 1, isLoadMore: currentPage !== 0, ...payload }, (response) => {
        if (!!response) {
          resolve(response)
        } else {
          reject()
        }
      })
    })
  }, [onPagination])

  const onServerSideBodyScroll = useCallback(() => {
    const selectedRowList = gridRef.current.api.getSelectedRows()
    if (tableComponentProps.isResetSelectedOnScroll && (selectedRowList.length > selectedRows.length)) {
      if (isAllSelected) {
        setAllSelected(false)
      }

      //To un-select all previous rendered rows of ag-grid
      gridRef.current.api.forEachNode((node) => {
        node.setSelected(false)
      }) //Todo: check if unselect required or not and update parent component logic accordingly

      gridRef.current.api.refreshHeader()
    }
  }, [gridRef, selectedRows, isAllSelected])

  const handleGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit()
    params.columnApi.applyColumnState({
      defaultState: { sort: null }
    })
    //Note: resizing columns on changing the window size
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit()
      })
    })
    if (isColumnMenuRequired) {
      setColumnManagementPanel()
    }
    if (isServerSide && tableComponentProps.allowRegisterDatasourceOnGridReady) {
      handleRegisterServerSideDatasource({
        gridRef,
        onLoadRowData: handleLoadRowData
      })
    }
  }, [handleLoadRowData])

  const isRowMaster = useCallback(() => {
    return (dataItem) => dataItem
  }, [])

  const handleBodyScroll = useCallback(() => {
    onGridBodyScroll()
  }, [])

  const loadingOverlayComponent = useMemo(() => {
    return () => <></>
  }, [])

  const noRowsOverlayComponent = useMemo(() => {
    return (props) => {
      return <div className="no-data-txt">{props.gridRef?.current?.props?.noDataMessage}</div>
    }
  }, [])

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      gridRef
    }
  }, [gridRef.current])

  const gridOptions = useMemo(() => {
    if (isServerSide) {
      return {
        suppressPropertyNamesCheck: true, //To allow use of custom properties on ag-grid column definition configuration.
        rowModelType: ROW_MODEL_TYPE.SERVER_SIDE,
        serverSideInfiniteScroll: true,
        cacheBlockSize: pageSize,
        maxConcurrentDatasourceRequests: 1,
        // debug: true,
        rowBuffer: 0,
        context: {
          pageNumber,
          pageSize,
          typingTimeout: tableComponentProps.typingTimeout,
          genericSearch: tableComponentProps.genericSearch,
          isAdvanceFiltersSelected: tableComponentProps.isAdvanceFiltersSelected,
          advanceSearchDetail: tableComponentProps.advanceSearchDetail,
          onResetViewApplied: tableComponentProps.onResetViewApplied,
          isViewApplied,
          onSetViewApplied: setViewApplied,
          sortOrderColumn: tableComponentProps.sortOrderColumn,
          sortOrderDirection: tableComponentProps.sortOrderDirection,
          selectedRows,
          onGridNewDataRendered: tableComponentProps.onGridNewDataRendered,
          onHeaderCheckboxSelection: setAllSelected,
          onChangeAllSelectCheckbox: tableComponentProps.onChangeAllSelectCheckbox,
          onSetAutoUnselectRow: setAutoUnselect
        },
        onBodyScroll: onServerSideBodyScroll,
        getRowId,
        onSort,
        getRowHeight: (params) => {
          const isRowHeightChangeRequired = changeRowHeight(params?.node?.data)
          if (isRowHeightChangeRequired) {
            return 125
          } else {
            return 50
          }
        }
      }
    } else {
      if (expandableRows) {
        return {
          suppressPropertyNamesCheck: true,
          rowData,
          getRowId: keyField ? getRowId : undefined, //Todo: check if we have unique row id for all grids
          onSort
        }
      }
      return {
        rowData,
        getRowId: keyField ? getRowId : undefined, //Todo: check if we have unique row id for all grids
        onSort,
        suppressPropertyNamesCheck: true,
        onBodyScroll: handleBodyScroll,
        getRowHeight: (params) => {
          const isRowHeightChangeRequired = changeRowHeight(params?.node?.data)
          if (isRowHeightChangeRequired) {
            return 125
          } else {
            return tableComponentProps.rowHeight || 50
          }
        }
      }
    }
  }, [
    rowData,
    pageNumber,
    isViewApplied,
    selectedRows,
    tableComponentProps.isAdvanceFiltersSelected,
    tableComponentProps.advanceSearchDetail,
    tableComponentProps.rowHeight,
    tableComponentProps.typingTimeout,
    tableComponentProps.genericSearch,
    tableComponentProps.onResetViewApplied,
    tableComponentProps.sortOrderColumn,
    tableComponentProps.sortOrderDirection,
    tableComponentProps.onGridNewDataRendered,
    onSort,
    handleBodyScroll,
    getRowId,
    onServerSideBodyScroll
  ])

  //Todo: Remove after testing
  // //refreshing after any button click eg: search click on advanced filters
  // const refreshCache = useCallback((route) => {
  //   gridRef.current.api.refreshServerSide({ route, purge: true })
  // }, [])

  useEffect(() => {
    //For resetting cache when required. 
    //eg: clicking on search button for advance search filters
    if (!!tableComponentProps.isResetCacheRequired) {
      // refreshCache()
      handleRefreshServerSideGrid({ gridRef })
      tableComponentProps.onResetCacheRequired(false)
    }
  }, [tableComponentProps.isResetCacheRequired])

  useEffect(() => {
    //For setting new datasource for server side row modal
    if (tableComponentProps.isNewDatasourceRegisterationRequired) {
      handleRegisterServerSideDatasource({
        gridRef,
        onLoadRowData: handleLoadRowData
      })
      tableComponentProps.onResetNewDatasourceRegisterationRequired(false)
    }
  }, [tableComponentProps.isNewDatasourceRegisterationRequired])

  useEffect(() => {
    //For removing datasource for server side row modal
    if (tableComponentProps.isCancelDatasourceRegisteration) {
      handleRemoveServerSideDatasource({
        gridRef
      })
      tableComponentProps.onResetCancelDatasourceRegisteration(false)
    }
  }, [tableComponentProps.isCancelDatasourceRegisteration])

  useEffect(() => {
    // setting new data source for generic search and advance Search filters
    if (gridRef.current.api && isServerSide) {
      if (!!tableComponentProps.typingTimeout) {
        handleGridReady(gridRef.current)
      }
    }
  }, [tableComponentProps.typingTimeout])

  useEffect(() => {
    //For add/remove/update rows in grid for server side/client side row modal
    if (tableComponentProps.gridTransactionData) {
      handleApplyTransaction({
        gridRef,
        gridTransactionData: tableComponentProps.gridTransactionData,
        isServerSide
      })
      tableComponentProps.onResetGridTransactionData(null)
    }
  }, [tableComponentProps.gridTransactionData])

  useEffect(() => {
    //For updating row values in grid
    if (tableComponentProps.updatedSpecificRowValues) {
      handleUpdateSpecificRowValues({
        gridRef,
        keyField,
        updatedSpecificRowValues: tableComponentProps.updatedSpecificRowValues
      })
    }
  }, [tableComponentProps.updatedSpecificRowValues])

  const handleRowDataUpdated = useCallback(() => {
    const params = {
      force: true,
      suppressFlash: true
    }
    gridRef.current.api.refreshCells(params)
    gridRef.current.api.resetRowHeights()
    gridRef.current.api.ensureColumnVisible(colConfig[0]?.colId, "start")
  }, [colConfig])

  //Note: Need to update height properly based on generic table UI requirement
  const tableHeight = useMemo(() => (isTableMarginRequired ? 500 : 250), [isTableMarginRequired])

  const dynamicHeight = useMemo(() => ((rowData.length * 50) + 48), [rowData])

  const contextValue = useMemo(() => {
    return {
      isAllSelected,
      isLoading,
      totalCount,
      isClearSelectedRows,
      noDataMessageForSubTable,
      onSelectedRowsChange,
      sortOrderColumn: tableComponentProps.sortOrderColumn,
      sortOrderDirection: tableComponentProps.sortOrderDirection,
      ...tableComponentProps.childComponentProps
    }
  }, [
    isAllSelected,
    isClearSelectedRows,
    noDataMessageForSubTable,
    isLoading,
    totalCount,
    tableComponentProps.sortOrderColumn,
    tableComponentProps.sortOrderDirection,
    tableComponentProps.childComponentProps,
    onSelectedRowsChange
  ])

  const rowMultiSelectWithClick = useMemo(() => (rowSelection === 'multiple'), [rowSelection])

  useEffect(() => {
    const handleLoadNextPage = (scrollableEle) => {
      setTimeout(() => {
        scrollableEle.scrollTo(0, 5000 * pageNumber)
        tableComponentProps.onResetLoadingNextPage(false)
      }, 1000)
    }

    //For performing auto scroll, without user's interaction, to auto load pending rows in grid.
    if (tableComponentProps.isLoadingNextPage) {
      const scrollableEle = document.getElementsByClassName("ag-body-viewport")?.[0]
      if (scrollableEle) {
        handleLoadNextPage(scrollableEle)
      }
    }
  }, [pageNumber, tableComponentProps.isLoadingNextPage])

  /**
   * Note: popupParent is used to fix Menu clipping issue for small height grids,
   * Todo: so check if menu is actually clipped or not.
   * As use of popupParent, is causing ag-grid to scroll up whenever user tries to access column context menu
   */
  // const popupParent = useMemo(() => {
  //   return document.querySelector('body')
  // }, [])

  //To handle row unselect on grid when the rows are rendered
  const handleViewportChanged = useCallback(() => {
    const rowCount = gridRef.current.api.getModel().getRowCount()
    const row = gridRef.current.api.getDisplayedRowAtIndex(0)
    if (rowCount > 0 && row?.id !== undefined && selectedRows.length === 0) {
      if (isServerSide) {
        gridRef.current.props.onSetAutoUnselectRow(true)
        gridRef.current.api.deselectAll()
        setTimeout(() => {
          gridRef.current?.props.onSetAutoUnselectRow(false)
        }, 500)
      } else {
        gridRef.current.api.forEachNode((node) => {
          node.setSelected(false)
        })
      }
    }
  }, [selectedRows.length])

  const getContextMenuItemsConfig = useCallback((params) => {
    return getContextMenuItems({ onOpenNewTabClicked, ...params })
  }, [onOpenNewTabClicked])

  const gridHeight = useMemo(() => {
    const listHasActions = columns.some(c => typeof c.selector === 'string' && c.selector === "actions")
    return !tableComponentProps.isStaticList &&
      rowData.length > 4 &&
      !pagination
      ? dynamicHeight
      : tableComponentProps.isStaticList
        ? rowData.length < 4 && !pagination
          ? listHasActions ? (tableComponentProps.staticListHeight || (rowData.length * 70) + 48) : (tableComponentProps.staticListHeight || (rowData.length * 50) + 48)
          : tableComponentProps.staticListHeight || tableHeight
        : tableSize
  }, [
    columns,
    dynamicHeight,
    pagination,
    rowData.length,
    tableComponentProps.isStaticList,
    tableComponentProps.staticListHeight,
    tableHeight,
    tableSize
  ])

  // Check if the clicked row is a master row (expandable row)
  const handleRowGroupOpened = useCallback((event) => {
    if (event.node && event.node.master) {
      // Get the row node and check if it is expanded or collapsed
      const rowNode = event.node
      onRowExpandToggled(rowNode.expanded)
    }
  }, [])

  const handleColumnRowGroupChanged = useCallback((event) => {
    const gridApi = gridRef.current.api
    const allColumnDefs = gridApi.getColumnDefs()
    const updatedColumnDef = []
    if (event.columns.length) {

      if (expandableRows) {
        allColumnDefs.forEach((colDef, index) => {
          if (colDef.cellRenderer === 'agGroupCellRenderer' && index === 0) {
            colDef.cellRenderer = (e) => {
              return (e.value !== "X" ? e.value : "")
            }
          }
          updatedColumnDef.push(colDef)
        })
      }

      gridApi.forEachNode((node) => { // Expand all groups if column grouping is active
        if (node.group) {
          node.setExpanded(true)
        }
      })

    } else {
      if (expandableRows) {
        allColumnDefs.forEach((colDef, index) => {
          if (colDef.cellRenderer !== 'agGroupCellRenderer' && index === 0) {
            colDef.cellRenderer = 'agGroupCellRenderer'
          }
          updatedColumnDef.push(colDef)
        })
      }
    }
    if (expandableRows) {
      gridApi.setColumnDefs(updatedColumnDef)
    }
  }, [])

  const columnTypes = useMemo(() => {
    return {
      groupByColumn: {
        enableRowGroup: true
      }
    }
  }, [])

  // Function to define row class rules
  const rowClassRules = useMemo(() => {
    return {
      'highlight-row': tableComponentProps.highlightRow
    }
}, [tableComponentProps.highlightRow])

  return (
    <div className="table-responsive react-dataTable" onKeyDown={handleKeyDown} ref={containerRef}>
      <CustomUILoader isLoading={!isServerSide && isLoading} isMessageRequired={false}>
        <div className={classnames(className, "ag-theme-alpine w-full")} style={{ height: gridHeight }}>
          <GridContext.Provider value={contextValue}>
            <AgGridReact
              ref={gridRef}
              {...gridOptions}
              // popupParent={popupParent} //Todo: check if menu is actually clipped or not.
              suppressRowClickSelection
              rowMultiSelectWithClick={rowMultiSelectWithClick}
              columnDefs={colConfig}
              detailCellRenderer={!isServerSide ? !!data?.length ? detailCellComponent : null : detailCellComponent}
              /**
              *If providing own Detail Cell Renderer, then we need to make sure the top most 
              * element of the Detail Cell Renderer has the correct height.
              * So make sure the CSS on the top most element is set so that its height is correct
              */
              detailRowAutoHeight={!isServerSide ? !!data?.length ? true : undefined : true}
              components={gridTableComponents}
              rowSelection={rowSelection}
              onCellClicked={handleCellClicked}
              defaultColDef={DEFAULT_COL_DEF}
              tooltipShowDelay={TOOLTIP_SHOW_DELAY}
              masterDetail={expandableRows}
              sideBar={isColumnMenuRequired ? DEFAULT_SIDEBAR_DEF : null}
              suppressRowTransform
              onRowGroupOpened={handleRowGroupOpened}
              onGridReady={handleGridReady}
              onViewportChanged={handleViewportChanged}
              enableRangeSelection
              enableCharts
              /**
               * isRowDraggable is used to make rows draggable
               * rowDragManaged : Is used here only for client side row modal
               */
              rowDragManaged={!isServerSide && tableComponentProps.isRowDraggable}
              onRowDragEnd={tableComponentProps.onRowDragEnd}
              /**
               * embedFullWidthRows 
               * mixing Embed Full Width Rows and  masterDetail (Custom Detail),
               * then be aware the Detail Panel will get rendered multi times acc to pinned rows
               */
              // embedFullWidthRows   
              animateRows
              isRowMaster={isRowMaster}
              alwaysShowVerticalScroll={false}
              // loadingOverlayComponent={loadingOverlayComponent}
              noRowsOverlayComponent={noRowsOverlayComponent}
              noRowsOverlayComponentParams={noRowsOverlayComponentParams}
              noDataMessage={noDataMessage}
              isAllSelected={isAllSelected}
              // keepDetailRows //Todo: check if this is actually required
              getMainMenuItems={getMainMenu}
              getContextMenuItems={getContextMenuItemsConfig}
              onRowDataUpdated={handleRowDataUpdated}
              rowClass={isLoading ? "disabled-row-selection" : ""}
              onRowSelected={handleRowSelected}
              onModelUpdated={handleModelUpdated}
              onHeaderCheckboxSelection={setAllSelected}
              onSetAutoUnselectRow={setAutoUnselect}
              onSelectedRowsChange={onSelectedRowsChange}
              onSelectableRowDisabled={onSelectableRowDisabled}
              rowGroupPanelShow={tableComponentProps.isGroupingRequired ? "always" : ""}
              columnTypes={columnTypes}
              rowClassRules={rowClassRules}
              groupSelectsChildren={tableComponentProps.isGroupingRequired}
              onColumnRowGroupChanged={handleColumnRowGroupChanged}
              onFirstDataRendered={onFirstRenderCheckboxSelection}
              onCellEditingStarted={tableComponentProps.onCellEditingStarted}
              onCellEditingStopped={tableComponentProps.onCellEditingStopped}
            />
          </GridContext.Provider>
        </div>
      </CustomUILoader>
    </div>
  )
}
GridViewComponent.propTypes = {
  intl: PropTypes.object,
  keyField: PropTypes.string,
  tableComponentProps: PropTypes.object,
  columns: PropTypes.array,
  data: PropTypes.array,
  isLoadingChildGrid: PropTypes.bool,
  expandedRowId: PropTypes.string,
  subData: PropTypes.object,
  isLoading: PropTypes.bool,
  isColumnMenuRequired: PropTypes.bool,
  rowSelection: PropTypes.string,
  expandableRows: PropTypes.bool,
  noDataComponent: PropTypes.func,
  isServerSide: PropTypes.bool,
  pageNumber: PropTypes.number,
  isTableMarginRequired: PropTypes.bool,
  expandableRowsComponent: PropTypes.func,
  onRowClicked: PropTypes.func,
  onRowExpandToggled: PropTypes.func,
  onOpenNewTabClicked: PropTypes.func,
  onSort: PropTypes.func,
  onPagination: PropTypes.func
}
export default LocaleMessageWrapper(GridViewComponent)