import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addProjectSiteRole
 * @param {object} data 
 *  {
      "siteId": 0,
      "siteRoles": [
        {
          "sitePartyRoleId": 0,
          "organizationId": 0,
          "poC_ContactId": 0,
          "poC_OrganizationId": 0
        }
      ]
    }
 *
 */
export const addProjectSiteRole = (data) => {
  const { url } = config.addProjectSiteRole

  const payload = !!data.campaignSiteRoles ?
    {
      siteId: Number(data.siteId),
      siteRoles: data.campaignSiteRoles
    } : {
      siteId: Number(data.siteId),
      siteRoles: data.contacts.map(contact => ({
        sitePartyRoleId: Number(data.siteRole),
        organizationId: Number(data.org),
        poC_ContactId: Number(contact.contactId),
        poC_OrganizationId: Number(contact.organizationId),
        isPrimary: data.isPrimary
      }))
    }
  return postDataApi(url, payload)
}

export default addProjectSiteRole
