export const TOGGLE_PROJECT_SITE_UNIT_EDIT_MODE = 'TOGGLE_PROJECT_SITE_UNIT_EDIT_MODE'
export const ADD_PROJECT_SITE_UNIT_REQUEST = 'ADD_PROJECT_SITE_UNIT_REQUEST'
export const UPDATE_PROJECT_SITE_UNIT_REQUEST = 'UPDATE_PROJECT_SITE_UNIT_REQUEST'
export const DELETE_PROJECT_SITE_UNIT_REQUEST = 'DELETE_PROJECT_SITE_UNIT_REQUEST'
export const ADD_PROJECT_SITE_AUTO_GENERATE_UNITS_REQUEST = 'ADD_PROJECT_SITE_AUTO_GENERATE_UNITS_REQUEST'
export const GET_ADD_PROJECT_SITE_UNIT_VM_REQUEST = 'GET_ADD_PROJECT_SITE_UNIT_VM_REQUEST'
export const GET_PROJECT_SITE_UNIT_LIST_REQUEST = 'GET_PROJECT_SITE_UNIT_LIST_REQUEST'
export const GET_PROJECT_SITE_UNIT_REQUEST = 'GET_PROJECT_SITE_UNIT_REQUEST'
export const DELETE_MULTIPLE_PROJECT_SITE_UNITS_REQUEST = 'DELETE_MULTIPLE_PROJECT_SITE_UNITS_REQUEST'
export const GET_UNITS_LIST_DDL_REQUEST = 'GET_UNITS_LIST_DDL_REQUEST'
export const GET_WORKFLOW_LIST_REQUEST = 'GET_WORKFLOW_LIST_REQUEST'
export const UPDATE_UNIT_WORKFLOW_LIST_REQUEST = 'UPDATE_UNIT_WORKFLOW_LIST_REQUEST'
export const GET_UNIT_WORKFLOW_LIST_COUNT_REQUEST = 'GET_UNIT_WORKFLOW_LIST_COUNT_REQUEST'
export const GET_UNIT_BLOCK_LIST_REQUEST = 'GET_UNIT_BLOCK_LIST_REQUEST'
export const GET_UNIT_PLAN_DETAILS_REQUEST = 'GET_UNIT_PLAN_DETAILS_REQUEST'

/****************************************************/
/** SET_PROJECT_SITE_UNIT_EDIT_MODE **/
/****************************************************/

/**
 * @method toggleProjectSiteUnitEditMode :  To set project site unit edit mode
 *
 */
export const toggleProjectSiteUnitEditMode = (payload) => {
  return {
    type: TOGGLE_PROJECT_SITE_UNIT_EDIT_MODE,
    payload
  }
}

/****************************************************/
/** ADD_PROJECT_SITE_UNIT_REQUEST **/
/****************************************************/

/**
 * @method addProjectSiteUnitRequest :  To add site unit detail of Project
 *
 */
export const addProjectSiteUnitRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_PROJECT_SITE_UNIT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PROJECT_SITE_UNIT_REQUEST **/
/****************************************************/

/**
 * @method updateProjectSiteUnitRequest :  To update site unit detail of Project
 *
 */
export const updateProjectSiteUnitRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_PROJECT_SITE_UNIT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_PROJECT_SITE_AUTO_GENERATE_UNITS_REQUEST **/
/****************************************************/

/**
 * @method addProjectSiteAutoGenerateUnitsRequest :  To add site auto-generate units detail of Project
 *
 */
export const addProjectSiteAutoGenerateUnitsRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_PROJECT_SITE_AUTO_GENERATE_UNITS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_PROJECT_SITE_UNIT_REQUEST **/
/****************************************************/

/**
 * @method deleteProjectSiteUnitRequest : To delete Project Site Unit
 *
 */
export const deleteProjectSiteUnitRequest = (payload, callback = () => { }) => {
  return {
    type: DELETE_PROJECT_SITE_UNIT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ADD_PROJECT_SITE_UNIT_VM_REQUEST **/
/****************************************************/

/**
 * @method getAddProjectSiteUnitVMRequest :  To get dropdowns for add site unit VM of Project
 *
 */
export const getAddProjectSiteUnitVMRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ADD_PROJECT_SITE_UNIT_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROJECT_SITE_UNIT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProjectSiteUnitListRequest :  To get project site units list
 *
 */
export const getProjectSiteUnitListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROJECT_SITE_UNIT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROJECT_SITE_UNIT_REQUEST **/
/****************************************************/

/**
 * @method getProjectSiteUnitRequest :  To get project site unit
 *
 */
export const getProjectSiteUnitRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROJECT_SITE_UNIT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_MULTIPLE_PROJECT_SITE_UNITS_REQUEST **/
/****************************************************/

/**
 * @method deleteMultipleProjectSiteUnitsRequest : To delete project site units
 *
 */
export const deleteMultipleProjectSiteUnitsRequest = (payload, callback) => {
  return {
    type: DELETE_MULTIPLE_PROJECT_SITE_UNITS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_UNITS_LIST_DDL_REQUEST **/
/****************************************************/

/**
 * @method getUnitsListDDLRequest : To get the units list ddl
 *
 */
export const getUnitsListDDLRequest = (payload, callback) => {
  return {
    type: GET_UNITS_LIST_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_WORKFLOW_LIST_REQUEST **/
/****************************************************/

/**
 * @method getWorkflowListRequest :  To get the workflows list 
 *
 */
export const getWorkflowListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_WORKFLOW_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_UNIT_WORKFLOW_LIST_REQUEST **/
/****************************************************/
/**
 * @method updateUnitWorkflowListRequest :  To add selected workflows to unit
 *
 */
export const updateUnitWorkflowListRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_UNIT_WORKFLOW_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_UNIT_WORKFLOW_LIST_COUNT_REQUEST **/
/****************************************************/

/**
 * @method getUnitWorkflowListCount :  To get the unit workflow list count 
 *
 */
export const getUnitWorkflowListCountRequest = (payload, callback = () => { }) => {
  return {
    type: GET_UNIT_WORKFLOW_LIST_COUNT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_UNIT_BLOCK_LIST_REQUEST **/
/****************************************************/

/**
 * @method getUnitBlockList :  To get the unit block list 
 *
 */
export const getUnitBlockListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_UNIT_BLOCK_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_UNIT_PLAN_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method getUnitPlanDetails :  To get the unit plan details 
 *
 */
export const getUnitPlanDetailsRequest = (payload, callback = () => { }) => {
  return {
    type: GET_UNIT_PLAN_DETAILS_REQUEST,
    payload,
    callback
  }
}
