import PropTypes from 'prop-types'
import React, { useMemo, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { ChevronLeft, ChevronRight } from 'react-feather'

import { ROUTES } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getFormattedDate } from "@utils"
import { LocaleMessage } from '@views/components'

const { REPORTS: { DATA_ACCESS }, ACTIONS_PAGE, OF } = LANGUAGE_CONSTANTS

const ExpandableUnacceptableActivitiesReportList = ({ expandableRowData = {}, data, onZoomImage = () => {} }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const selectedGrid = useMemo(() => {
    return {
      organization: data.organization || "",
      project: data.project || "",
      campaign: data.campaign || "",
      site: data.site || "",
      program: data.program || "",
      inspectionCompletionDate: getFormattedDate(data.inspectionDate) || "",
      tradeFixDate: getFormattedDate(data.tradeAcceptedDate) || "",
      superintendentVerificationDate: "",
      currentActionItem: data.currentAction || "",
      actionId: data.currentActionID || "",
      currentActionItemDueDate: getFormattedDate(data.currentActionDueDate) || "",
      currentActionItemAssignedTo: data.currentActionAssignee || "",
      lastActionItem: data.lastAction || "",
      lastActionItemNote: data.lastActionNote || "",
      reinspectionRequired: data.reinspectionRequired || "",
      holdProduction: data.holdProduction || "",
      photos: data.photo || "",
      contact: data.contact || ""
    }
  }, [data])

  const photoUrls = useMemo(() => {
    if (selectedGrid.photos) {
      return selectedGrid.photos.split(',')
    }
    return []
  }, [selectedGrid])

  const handlePaginate = useCallback((type) => {
    if (type === "prev") {
      setActiveIndex(prevState => prevState - 1)
    } else {
      setActiveIndex(prevState => prevState + 1)
    }
  }, [activeIndex])

  return (
      <div className='d-flex'>
        <div className="site-units">
          {
            !expandableRowData.isCampaignView && (
              <>
                <div className="site-units-details">
                  <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.ORGANIZATION} />: </span>
                  <span>{selectedGrid.organization}</span>
                </div>
                <div className="site-units-details">
                  <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.PROJECT} />: </span>
                  <span>{selectedGrid.project}</span>
                </div>
                <div className="site-units-details">
                  <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.PROGRAM} />: </span>
                  <span>{selectedGrid.program}</span>
                </div>
                <div className="site-units-details">
                  <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.CAMPAIGN} />: </span>
                  <span>{selectedGrid.campaign}</span>
                </div>
                <div className="site-units-details">
                  <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.SITE} />: </span>
                  <span>{selectedGrid.site}</span>
                </div>
                <div className="site-units-details">
                  <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.CONTACT} />: </span>
                  <span>{selectedGrid.contact}</span>
                </div>
              </>
            )
          }
          <div className="site-units-details">
            <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.INSPECTION_COMPLETION_DATE} />: </span>
            <span>{selectedGrid.inspectionCompletionDate}</span>
          </div>
          <div className="site-units-details">
            <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.TRADE_FIX_DATE} />: </span>
            <span>{selectedGrid.tradeFixDate}</span>
          </div>
          <div className="site-units-details">
            <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.SUPERINTENDENT_VERIFICATION_DATE} />: </span>
            <span>{selectedGrid.superintendentVerificationDate}</span>
          </div>
          <div className="site-units-details">
            <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.CURRENT_ACTION} />: </span>
            <span>{selectedGrid.currentActionItem}</span>
          </div>
          <div className="site-units-details">
            <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.CURRENT_ACTION_ID} />: </span>
            <Link target="_blank" rel="noopener noreferrer" to={`${ROUTES.actionsDetails}?viewDetail=true&actionId=${selectedGrid.actionId}`}>{selectedGrid.actionId}</Link>
          </div>
          <div className="site-units-details">
            <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.CURRENT_ACTION_ITEM_DUE_DATE} />: </span>
            <span>{selectedGrid.currentActionItemDueDate}</span>
          </div>
          <div className="site-units-details">
            <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.CURRENT_ACTION_ITEM_ASSIGNED_TO} />: </span>
            <span>{selectedGrid.currentActionItemAssignedTo}</span>
          </div>
          <div className="site-units-details">
            <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.LAST_ACTION_ITEM} />: </span>
            <span>{selectedGrid.lastActionItem}</span>
          </div>
          <div className="site-units-details">
            <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.LAST_ACTION_ITEM_NOTE} />: </span>
            <span>{selectedGrid.lastActionItemNote}</span>
          </div>
          <div className="site-units-details">
            <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.REINSPECTION_REQUIRED} />: </span>
            <span>{selectedGrid.reinspectionRequired}</span>
          </div>
          <div className="site-units-details">
            <span className="site-units-title"><LocaleMessage id={DATA_ACCESS.HOLD_PRODUCTION} />: </span>
            <span>{selectedGrid.holdProduction}</span>
          </div>
        </div>
        <>
          {
            !!photoUrls.length && (
              <div className="site-unit-image">
                <img src={photoUrls[activeIndex]} alt='' onClick={() => onZoomImage(photoUrls[activeIndex])} />
                <div>
                  <button className="waves-effect btn text-transform-capitalize" onClick={() => handlePaginate('prev')} disabled={activeIndex === 0}><ChevronLeft /> <LocaleMessage id={ACTIONS_PAGE.LAST} /></button>
                  <span className="mx-2">{activeIndex + 1} <LocaleMessage id={OF} /> {photoUrls.length}</span>
                  <button onClick={() => handlePaginate('next')} disabled={(activeIndex >= photoUrls.length - 1)} className="waves-effect btn text-transform-capitalize">
                    <LocaleMessage id={ACTIONS_PAGE.NEXT} /> <ChevronRight />
                  </button>
                </div>
              </div>
            )
          }
        </>
      </div>
  )
}

ExpandableUnacceptableActivitiesReportList.propTypes = {
  expandedRowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isLoadingChildGrid: PropTypes.bool,
  subData: PropTypes.object,
  noDataMessage: PropTypes.string,
  data: PropTypes.object
}

export default ExpandableUnacceptableActivitiesReportList
