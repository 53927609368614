import { LANGUAGE_CONSTANTS } from '@shared/language-constants'

const { ORG_PLANS } = LANGUAGE_CONSTANTS

export const MissingItemsOptions = [{ text: "Yes", value: "true" }, { text: "No", value: "false" }]

export const PLAN_FORM_SECTION_TYPES = {
    PLAN: 1,
    BLUEPRINT: 2,
    ENERGY_MODEL: 3,
    PLAN_DETAILS: 4
}

export const PlanSections = [
    {
        id: PLAN_FORM_SECTION_TYPES.PLAN,
        labelLocaleId: ORG_PLANS.PLAN
    },
    {
        id: PLAN_FORM_SECTION_TYPES.BLUEPRINT,
        labelLocaleId: ORG_PLANS.BLUEPRINT
    },
    {
        id: PLAN_FORM_SECTION_TYPES.ENERGY_MODEL,
        labelLocaleId: ORG_PLANS.ENERGY_MODEL
    },
    {
        id: PLAN_FORM_SECTION_TYPES.PLAN_DETAILS,
        labelLocaleId: ORG_PLANS.PLAN_DETAILS
    }
]
