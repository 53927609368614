import React, { useState, useCallback, useEffect } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"
import PropTypes from 'prop-types'
import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"

const { PROGRAMS } = LANGUAGE_CONSTANTS

const ProjectContactsSearch = ({
  isEditMode,
  isDisabled,
  projectId,
  siteId,
  errors,
  defaultOptions,
  id,
  isMulti,
  isRequired,
  isShowCustomAction,
  label,
  labelRequired,
  name,
  placeholder,
  asyncFetch,
  asyncFetchValueProcessed,
  value,
  initialLabel,
  valueType,
  labelType,
  onSelect = () => { },
  getOptionLabel = (option) => option.text,
  getOptionValue = (option) => option.value,
  onSetAsyncValueProcessed = () => { }
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState(defaultOptions)

  /**
   * Note: Paginated select maintain cache of options,
   * so to set new default options we need to reset previous cache
   */
  const [isResetCache, setResetCache] = useState(false)
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  /**
   * @method handleSetDefaultOptions : To handle set default options in case of Edit mode
   *
   * Note: To handle case of duplicate option, when custom option is added, that is selected from edit mode
   */
  const handleSetDefaultOptions = useCallback(
    (selectedItem, selectedValue) => {
      const checkIfAlreadyPresent = defaultOptions.find(
        (option) => option.value === selectedValue
      )
      if (!!checkIfAlreadyPresent) {
        setOptions(defaultOptions)
      } else {
        setOptions([selectedItem, ...defaultOptions])
      }
    },
    [defaultOptions]
  )
  //Todo: handle edit mode when single select

  useEffect(() => {
    setResetCache((prevState) => !prevState)
    setOptions(defaultOptions)
  }, [defaultOptions])

  //To handle edit mode when single select
  useEffect(() => {
    //Note: To get option details for selected value in edit mode 
    if (isEditMode && !isMulti && !initialLabel && (!asyncFetch || (asyncFetch && asyncFetchValueProcessed))) {
      if (!isEditModeItemLoaded && !!value && projectId && siteId) {
        setEditModeItemLoaded(true)
        //Todo: get contact name label either from parent or from get contact detail api
        const params = {
          projectId,
          siteId,
          pageSize: 1000 //Todo: Remove getProjectContacts list api call when label got from parent or from get contact detail api
        }
        dispatch(
          actions.getProjectContacts(params, (result) => {
            if (!!result?.items) {
              const { items } = result

              const selectedItem = items.find(
                (item) => item.value === value.toString()
              )

              // onSelect(id, selectedItem.value || '', selectedItem.text || '', true)
              setSelectedOptionLabel(selectedItem.text || "")
              setResetCache((prevState) => !prevState)
              handleSetDefaultOptions(selectedItem, value)
              if (asyncFetch) {
                onSetAsyncValueProcessed(false)
              } else {
                onSetAsyncValueProcessed(true)
              }
            }
          })
        )
      }
    }
  }, [
    asyncFetchValueProcessed,
    initialLabel,
    isEditMode,
    isEditModeItemLoaded,
    projectId,
    siteId,
    value
  ])

  const handleLoadMoreOptions = useCallback(
    (search, page, prevOptions) => {
      return new Promise((resolve) => {
        const handleSearchResultCallback = (response) => {
          if (!!response?.items) {
            const { items, hasNextPage } = response
            setOptions(() => (!!prevOptions.length ? [...prevOptions, ...items] : items))
            resolve({
              optionList: items,
              hasMore: hasNextPage
            })
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }

        const params = {
          contactName: search,
          projectId,
          siteId,
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE
        }

        dispatch(
          actions.getProjectContacts(params, handleSearchResultCallback)
        )
      })
    },
    [projectId, siteId, options]
  )

  const handleSelect = useCallback(
    (selected) => {
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        if (!!lastElement && !lastElement.value) {
          onSelect(id, [])
        } else {
          onSelect(id, selected)
        }
      } else {
        onSelect(id, selected?.value || "", selected?.text || "")
        setSelectedOptionLabel(selected?.text || "")
      }
    },
    [onSelect, id]
  )

  return (
    <Col className="mb-1" sm={12} md={6} xl={4}>
      {labelRequired && <CustomLabel title={label || PROGRAMS.SAFETY_MANAGER} requiredIconClassName="required-field" required={isRequired} isLocalizedTitle={!label} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id={id}
        name={name}
        placeholder={placeholder}
        options={options}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={
          !isMulti
            ? !!value
              ? {
                text: selectedOptionLabel,
                value
              }
              : null
            : value
        }
        isShowCustomAction={isShowCustomAction}
        valueType={valueType}
        labelType={labelType}
        onSelect={handleSelect}
        onLoadOptions={handleLoadMoreOptions}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isMulti={isMulti}
        hideSelectedOptions={false}
        isDisabled={isDisabled}
      />
      {errors && <ErrorMessage isShow={!!errors[id]} message={errors[id]} />}
    </Col>
  )
}

ProjectContactsSearch.propTypes = {
  isEditMode: PropTypes.bool,
  isDisabled: PropTypes.bool,
  siteId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  errors: PropTypes.object,
  defaultOptions: PropTypes.array,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  isShowCustomAction: PropTypes.bool,
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  asyncFetch: PropTypes.bool,
  asyncFetchValueProcessed: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  initialLabel: PropTypes.string,
  valueType: PropTypes.string,
  labelType: PropTypes.string,
  onSelect: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  onSetAsyncValueProcessed: PropTypes.func
}

ProjectContactsSearch.defaultProps = {
  isEditMode: false,
  isDisabled: false,
  projectId: 0,
  siteId: 0,
  defaultOptions: [],
  id: "",
  isMulti: true,
  isRequired: false,
  isShowCustomAction: false,
  labelRequired: true,
  name: "",
  asyncFetch: false,
  asyncFetchValueProcessed: false,
  value: "",
  initialLabel: "",
  valueType: "value",
  labelType: "text"
}

export default ProjectContactsSearch
