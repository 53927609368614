import { getSearchQuery } from '../config'

const apiConfig = {
  getSchedulingList: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize = 0,
      genericSearch = "",
      advanceSearch = {},
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          siteName = [],
          buildingType = [],
          pointOfContact = [],
          streetAddress,
          unit = [],
          city = [],
          // country = [],
          state = [],
          zipCode = [],
          siteStatus = [],
          organization = [],
          campaign = [],
          campaignStatus = [],
          campaignType = [],
          workflowName = '',
          checkListName = '',
          statusIdList = [],
          assigneeId = [],
          role = [],
          county = [],
          isReinspection,
          dueDateFrom,
          dueDateTo,
          scheduleDateFrom,
          scheduleDateTo,
          sitePlan = [],
          block = [],
          unitName = '',
          region = [],
          subRegion = [],
          campaignTag = [],
          campaignScope = [],
          sampleDateFrom,
          sampleDateTo,
          completedDateFrom,
          completedDateTo,
          workflowScopeTag = [],
          workflowSkillsTag = []
        } = advanceSearch
        const statesSearch = state.map((item) => getSearchQuery("States", item.value)).join("")
        const countyList = county.map((item) => getSearchQuery("Counties", item.countyName)).join("")
        // const countrySearch = country.map((item) => getSearchQuery("Countries", item.value)).join("")
        const statusSearch = statusIdList
          .map((status) => getSearchQuery("StatusId", status))
          .join("")
        const siteIds = siteName.map((site) => getSearchQuery("SiteIds", site.value)).join("")
        const regionSearchIds = region.map((id) => getSearchQuery("RegionIds", id.value)).join("")
        const subRegionSearchIds = subRegion.map((id) => getSearchQuery("SubRegionIds", id.value)).join("")
        const buildingTypeSearch = buildingType.map((item) => getSearchQuery("BuildingTypeIds", item.value)).join("")
        const siteStatusSearch = siteStatus.map((item) => getSearchQuery("SiteStatusIds", item.value)).join("")
        const campaignSearch = campaign.map((item) => getSearchQuery("CampaignIds", item.campaignId)).join("")
        const campaignStatusSearch = campaignStatus.map((item) => getSearchQuery("CampaignStatusIds", item.value)).join("")
        const campaignTypeSearch = campaignType.map((item) => getSearchQuery("CampaignTypeIds", item.value)).join("")
        const roleSearch = role.map((item) => getSearchQuery("RoleIds", item.value)).join("")
        const organizationSearch = organization.map((item) => getSearchQuery("OrganizationIds", item.value)).join("")
        const pointOfContactSearch = pointOfContact.map((item) => getSearchQuery("PointOfContactIds", item.value)).join("")
        const assigneeSearch = assigneeId.map((item) => getSearchQuery("AssigneeIds", item.value)).join("")
        const sitePlanSearch = sitePlan.map((item) => getSearchQuery("SitePlanIds", item.value)).join("")
        const cityList = city.map((item) => getSearchQuery("Cities", item.city)).join("")
        const zipCodeList = zipCode.map((zip) => getSearchQuery("ZipCodes", zip.zipCode)).join("")
        const blockList = block.map((item) => getSearchQuery("Blocks", item.unitBlock)).join("")
        const locationUnitList = unit.map((item) => getSearchQuery("UnitApts", item.unitApt)).join("")
        const campaignTagList = campaignTag.map((item) => getSearchQuery("CampaignTagIds", item.value)).join("")
        const campaignScopeList = campaignScope.map((item) => getSearchQuery("CampaignScopeIds", item.value)).join("")
        const workflowSkillsTagList = workflowSkillsTag.map((item) => getSearchQuery("WorkflowSkillIds", item.value)).join("")
        const workflowScopeTagList = workflowScopeTag.map((item) => getSearchQuery("WorkflowScopeIds", item.value)).join("")
        searchString = [
          searchString,
          statesSearch,
          countyList,
          organizationSearch,
          campaignSearch,
          siteIds,
          pointOfContactSearch,
          assigneeSearch,
          sitePlanSearch,
          locationUnitList,
          getSearchQuery("StreetAddress", streetAddress, false, true),
          getSearchQuery("IsReinspection", isReinspection),
          getSearchQuery("DueDateFrom", dueDateFrom),
          getSearchQuery("DueDateTo", dueDateTo),
          roleSearch,
          campaignTypeSearch,
          campaignStatusSearch,
          buildingTypeSearch,
          siteStatusSearch,
          getSearchQuery("ScheduleDateFrom", scheduleDateFrom),
          getSearchQuery("ScheduleDateTo", scheduleDateTo),
          getSearchQuery("WorkflowName", workflowName, false, true),
          getSearchQuery("UnitName", unitName, false, true),
          getSearchQuery("TaskName", checkListName, false, true),
          getSearchQuery("SampleDateFrom", sampleDateFrom),
          getSearchQuery("SampleDateTo", sampleDateTo),
          getSearchQuery("CompletionDateFrom", completedDateFrom),
          getSearchQuery("CompletionDateTo", completedDateTo),
          statusSearch,
          regionSearchIds,
          cityList,
          zipCodeList,
          blockList,
          subRegionSearchIds,
          campaignTagList,
          campaignScopeList,
          workflowSkillsTagList,
          workflowScopeTagList
        ].join('')
      }

      return `api/Scheduling${searchString}`
    }
  },
  getAdministrativeTasks: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize = 0,
      genericSearch = "",
      advanceSearch = {},
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          statusIdList = [],
          checkListName = '',
          assigneeId = [],
          region = [],
          subRegion = []
        } = advanceSearch

        const statusSearch = statusIdList.map((status) => getSearchQuery("StatusId", status)).join("")
        const assigneeSearch = assigneeId.map((item) => getSearchQuery("AssigneeIds", item.value)).join("")
        const regionSearchIds = region.map((id) => getSearchQuery("RegionIds", id.value)).join("")
        const subRegionSearchIds = subRegion.map((id) => getSearchQuery("SubRegionIds", id.value)).join("")
        searchString = [
          searchString,
          getSearchQuery("TaskName", checkListName, false, true),
          statusSearch,
          assigneeSearch,
          regionSearchIds,
          subRegionSearchIds
        ].join('')
      }

      return `api/Scheduling/AdministrativeTasks${searchString}`
    }
  },
  getSchedulingListTasks: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize = 0,
      statusId = "",
      organizationId,
      projectId,
      siteId,
      campaignService,
      task,
      StatusId,
      orderColumn,
      orderDirections
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("StatusId", statusId),
        getSearchQuery("CampaignServiceName", campaignService, false, true),
        getSearchQuery("CheckListName", task, false, true),
        getSearchQuery("StatusId", orderColumn),
        getSearchQuery("OrderColumn", StatusId),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')
      return `api/Scheduling/getunitlist/organization/${organizationId}/project/${projectId}/site/${siteId}${searchString}`
    }

  },
  getScheduleVM: {
    method: 'GET',
    url: ({ entityId = 1 }) => `api/Scheduling/GetScheduleVM?entityId=${entityId}`
  },
  getScheduleAssignVM: {
    method: 'GET',
    url: () => `api/Scheduling/Assignment/GetScheduleAssignVM`
  },
  getSchedulingContactCalendarList: {
    method: 'GET',
    url: ({ startDate, endDate, contactName, observationTaskIds, advanceSearch }) => {
      const oIds = getSearchQuery("ObservationTaskList", observationTaskIds)
      let searchString = [
        getSearchQuery("StartDate", startDate, true),
        getSearchQuery("EndDate", endDate),
        getSearchQuery("ContactName", contactName, false, true),
        oIds
      ].join('')

      if (!!Object.keys(advanceSearch || {}).length) {
        const { availabilityList, contactName } = advanceSearch
        const availabilitySearch = availabilityList
          .map((availability) => getSearchQuery("Availability", availability))
          .join("")
        searchString = [
          searchString,
          availabilitySearch,
          getSearchQuery("ContactName", contactName, false, true)
        ].join('')
      }

      return `api/Scheduling/GetContactCalendarList${searchString}`
    }
  },
  deleteAssignedTask: {
    method: 'DELETE',
    url: `api/Scheduling/Assignment`
  },
  getTaskHistoryDetail: {
    method: 'GET',
    url: ({ siteId = 0, organizationId = 0, projectId = 0, taskObservationId = 0 }) => {
      const searchString = [
        getSearchQuery("SiteId", siteId, true),
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("ProjectId", projectId),
        getSearchQuery("TaskObservationId", taskObservationId)
      ].join('')
      return `api/Scheduling/taskhistoryDetail${searchString}`
    }
  },
  scheduleTask: {
    method: 'POST',
    url: 'api/Scheduling'
  },
  getTaskHistoryList: {
    method: 'GET',
    url: ({
      siteId = 0,
      organizationId = 0,
      projectId = 0,
      taskObservationId = 0,
      statusId,
      pageNumber,
      pageSize,
      orderColumn,
      orderDirections
    }) => {
      const searchString = [
        getSearchQuery("SiteId", siteId, true),
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("ProjectId", projectId),
        getSearchQuery("TaskObservationId", taskObservationId),
        getSearchQuery("StatusId", statusId),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')
      return `api/Scheduling/taskhistory${searchString}`
    }
  },
  getTaskHistoryPendingUnitList: {
    method: 'GET',
    url: ({
      siteId = 0,
      organizationId = 0,
      projectId = 0,
      taskObservationId = 0,
      statusId,
      pageNumber,
      pageSize,
      orderColumn,
      orderDirections
    }) => {
      const searchString = [
        getSearchQuery("SiteId", siteId, true),
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("ProjectId", projectId),
        getSearchQuery("TaskObservationId", taskObservationId),
        getSearchQuery("StatusId", statusId),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')
      return `api/Scheduling/taskhistorypendingunit${searchString}`
    }
  },
  updateTaskStatus: {
    method: 'POST',
    url: 'api/Scheduling/updatetaskstatus'
  },
  getContactTaskList: {
    method: 'GET',
    url: ({ contactID = 0, scheduleDate }) => {
      const searchString = [
        getSearchQuery("ContactID", contactID, true),
        getSearchQuery("ScheduleDate", scheduleDate)
      ].join('')
      return `api/Scheduling/Assignment/GetContactTasksByDate${searchString}`
    }
  },
  getAssignedTaskContactVM: {
    method: 'GET',
    url: (organizationId = 0) => `api/Scheduling/Assignment/GetEditAssignedTaskVM/${organizationId}`
  },
  getAssignedTaskContactListDDL: {
    method: 'GET',
    url: ({ pageNumber, pageSize, contactName = 0, organizationId = 0 }) => {
      const searchString = [
        getSearchQuery("OrganizationId", organizationId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ContactName", contactName, false, true)
      ].join('')
      return `api/Scheduling/Assignment/GetContactListDDL${searchString}`
    }
  },
  getAssignmentObservationTaskList: {
    method: 'GET',
    url: ({ pageNumber, pageSize, observationTaskId = 0 }) => {
      const searchString = [
        getSearchQuery("ObservationTaskId", observationTaskId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')
      return `api/Scheduling/Assignment/ObservationTask/${observationTaskId}${searchString}`
    }
  },
  updateAssignmentActivities: {
    method: 'PUT',
    url: ({ observationTaskId = 0 }) => `api/Scheduling/Assignment/AssignmentActivities/${observationTaskId}`
  },
  getTaskAssignmentDownload: {
    method: 'GET',
    url: ({ observationTaskId }) => `api/Scheduling/Assignment/TaskReport/${observationTaskId}`
  },
  getAssignedContactsDDL: {
    method: 'GET',
    url: ({ searchName, pageNumber, pageSize, allOption }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("SearchName", searchName, false, true),
        getSearchQuery("AllOption", allOption)
      ].join('')
      return `api/Scheduling/GetAssignedContactsDDL${searchString}`
    }
  },
  getAssignedTaskDetail: {
    method: 'GET',
    url: ({ observationTaskId }) => {
      return `api/Scheduling/Assignment/TaskId/${observationTaskId}`
    }
  },
  getAssignedContactList: {
    method: 'GET',
    url: ({
      contactName,
      observationTaskIds = []
    }) => {
      const searchString = [
        getSearchQuery("ObservationTaskList", observationTaskIds, true),
        getSearchQuery("ContactName", contactName, false, true)
      ].join('')
      return `api/Scheduling/GetScheduleContactList${searchString}`
    }
  },
  getObservationTaskListSchedule: {
    method: 'GET',
    url: ({
      observationTaskIds = [],
      unitId,
      pageSize = 0,
      pageNumber,
      genericSearch = "",
      advanceSearch = {},
      orderColumn,
      orderDirections
    }) => {
      const oIds = getSearchQuery("ObservationTaskIds", observationTaskIds)
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        getSearchQuery("SiteUnitIds", unitId)
      ].join('')
      if (!!Object.keys(advanceSearch || {}).length) {
        const {
          taskStatus = [],
          tasks = [],
          assignees = [],
          sites = [],
          units = [],
          streetAddress = "",
          state = [],
          city = [],
          zipCode = []
        } = advanceSearch

        const taskStatusIds = taskStatus.map(status => getSearchQuery("TaskStatusIds", status.value)).join('')
        const taskIds = tasks.map(task => getSearchQuery("ObservationTaskIds", task.value)).join('')
        const assigneeIds = assignees.map(assignee => getSearchQuery("AssigneeIds", assignee.value)).join('')
        const siteIds = sites.map(site => getSearchQuery("SiteIds", site.value)).join('')
        const siteUnitIds = units.map(unit => getSearchQuery("SiteUnitIds", unit.value)).join('')
        const states = state.map(s => getSearchQuery("States", s.value)).join('')
        const cities = city.map(c => getSearchQuery("Cities", c.value, false, true)).join('')
        const zipCodes = zipCode.map(code => getSearchQuery("ZipCodes", code.value, false, true)).join('')

        if (!taskIds.length) {
          searchString = [
            searchString,
            oIds
          ].join('')
        }
        searchString = [
          searchString,
          taskStatusIds,
          siteIds,
          siteUnitIds,
          taskIds,
          assigneeIds,
          getSearchQuery("StreetAddress", streetAddress, false, true),
          zipCodes,
          states,
          cities,
          siteIds
        ].join('')
      } else {
        searchString = [
          searchString,
          oIds
        ].join('')
        searchString = `${searchString}${oIds}`
      }

      return `api/Scheduling/ObservationTaskListSchedule${searchString}`
    }
  },

  getAssignmentUnitsList: {
    method: 'GET',
    url: ({
      observationTaskIds = [],
      siteUnitName = "",
      pageSize = 0,
      pageNumber
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ObservationTaskList", observationTaskIds.join()),
        getSearchQuery("SiteUnitName", siteUnitName, false, true)
      ].join('')
      return `api/Scheduling/Assignment/GetAssignmentUnits/DDL${searchString}`
    }
  },

  getAssignmentObservationTasksList: {
    method: 'GET',
    url: ({
      observationTaskIds = [],
      observationTaskName = "",
      siteUnitId = 0,
      pageSize = 0,
      pageNumber
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ObservationTaskList", observationTaskIds.join()),
        getSearchQuery("ObservationTaskName", observationTaskName, false, true),
        getSearchQuery("SiteUnitId", siteUnitId)
      ].join('')
      return `api/Scheduling/Assignment/GetAssignmentObservationTasks/DDL${searchString}`
    }
  },

  getAssignmentOrganizationsList: {
    method: 'GET',
    url: ({
      observationTaskIds = [],
      organizationName = "",
      pageSize = 0,
      pageNumber
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ObservationTaskList", observationTaskIds.join()),
        getSearchQuery("OrganizationName", organizationName, false, true)
      ].join('')
      return `api/Scheduling/Assignment/GetAssignmentOrganizations/DDL${searchString}`
    }
  },

  getAssignmentSitesList: {
    method: 'GET',
    url: ({
      observationTaskIds = [],
      organizationIds = [],
      siteName = "",
      pageSize = 0,
      pageNumber
    }) => {
      const orgIds = organizationIds.map((orgid) => {
        return getSearchQuery("OrganizationIds", orgid)
      }).join('')

      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ObservationTaskList", observationTaskIds.join()),
        getSearchQuery("SiteName", siteName, false, true),
        orgIds
      ].join('')
      return `api/Scheduling/Assignment/GetAssignmentSites/DDL${searchString}`
    }
  },

  getAssignmentRolesList: {
    method: 'GET',
    url: ({
      observationTaskIds = [],
      organizationIds = [],
      siteIds = [],
      sitePartyRoleName = "",
      pageSize = 0,
      pageNumber
    }) => {
      const orgIds = organizationIds.map((orgid) => {
        return getSearchQuery("OrganizationIds", orgid)
      }).join('')
      const sIds = siteIds.map((sid) => {
        return getSearchQuery("SiteIds", sid)
      }).join('')
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ObservationTaskList", observationTaskIds.join()),
        getSearchQuery("SitePartyRoleName", sitePartyRoleName, false, true),
        orgIds,
        sIds
      ].join('')
      return `api/Scheduling/Assignment/GetAssignmentRoles/DDL${searchString}`
    }
  },

  getSchedulingContactList: {
    method: 'GET',
    url: ({
      observationTaskIdsList = [],
      observationTaskId,
      siteUnitId = "",
      pageSize = 0,
      genericSearch = '',
      pageNumber,
      orderColumn,
      orderDirections = '',
      advanceSearch = {}
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("SiteUnitId", siteUnitId),
        getSearchQuery("ObservationTaskId", observationTaskId),
        getSearchQuery("ObservationTaskList", observationTaskIdsList),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      //TODO handle for advance search
      if (!!Object.keys(advanceSearch).length) {
        const {
          firstName,
          lastName,
          phone,
          email,
          organization,
          site,
          sitePartyRole
        } = advanceSearch
        searchString = [
          searchString,
          getSearchQuery("FirstName", firstName, false, true),
          getSearchQuery("LastName", lastName, false, true),
          getSearchQuery("OrganizationIds", organization),
          getSearchQuery("SiteIds", site),
          getSearchQuery("SitePartyRoleIds", sitePartyRole),
          getSearchQuery("Phone", phone, false, true),
          getSearchQuery("Email", email, false, true)
        ].join('')
      }

      return `api/Scheduling/GetSchedulingContactList${searchString}`
    }
  },
  getReadyStatusCountWidget: {
    method: 'GET',
    url: ({
      advanceSearch = {}
    }) => {
      let searchString
      if (!!Object.keys(advanceSearch || {}).length) {
        const {
          checkListName = [],
          dueDateFrom,
          dueDateTo,
          isReinspection,
          workflowName = [],
          zipCode,
          city,
          streetAddress,
          country = [],
          unit,
          assigneeId = [],
          pointOfContact = [],
          buildingTypeId,
          organization = [],
          siteStatusId,
          campaignName,
          campaignStatusId,
          campaignTypeId,
          roleId,
          genericSearch,
          state = [],
          county = [],
          siteName = []
        } = advanceSearch
        const stateList = state.map((data) => getSearchQuery("StateAbbreviations", data.value)).join("")
        const siteIdList = siteName.map((site) => getSearchQuery("SiteIds", site.value)).join("")
        const countyList = county.map((item) => getSearchQuery("Counties", item.countyName)).join("")
        const organizationList = organization.map((item) => getSearchQuery("OrganizationIds", item.value)).join("")
        const countrySearch = country.map((item) => getSearchQuery("CountryCode", item.value)).join("")
        const workflowSearch = workflowName.map((item) => getSearchQuery("WorkflowName", item.value)).join("")
        const assigneeSearch = assigneeId.map((item) => getSearchQuery("AssigneeId", item.value)).join("")
        const checkListSearch = checkListName.map((item) => getSearchQuery("CheckListName", item.value, false, true)).join("")
        const pointOfContactSearch = pointOfContact.map((item) => getSearchQuery("PointOfContactId", item.value)).join("")

        searchString = [
          getSearchQuery("DueDateFrom", dueDateFrom),
          getSearchQuery("DueDateTo", dueDateTo),
          getSearchQuery("IsReinspection", isReinspection),
          getSearchQuery("ZipCode", zipCode, false, true),
          getSearchQuery("UnitName", unit, false, true),
          getSearchQuery("CityName", city, false, true),
          getSearchQuery("StreetAddress", streetAddress, false, true),
          getSearchQuery("BuildingTypeId", buildingTypeId),
          getSearchQuery("SiteStatusId", siteStatusId),
          getSearchQuery("CampaignName", campaignName, false, true),
          getSearchQuery("CampaignStatusId", campaignStatusId),
          getSearchQuery("CampaignTypeId", campaignTypeId),
          getSearchQuery("RoleId", roleId),
          getSearchQuery("GenericSearch", genericSearch, false, true),
          stateList,
          countyList,
          pointOfContactSearch,
          siteIdList,
          organizationList,
          countrySearch,
          workflowSearch,
          assigneeSearch,
          checkListSearch
        ].join('')
      }
      searchString = searchString.replace('&', '?')
      return `api/Report/Widget/Tasks/ReadyTaskCount${searchString}`
    }
  },
  getAssignedTaskCountWidget: {
    method: 'GET',
    url: ({
      advanceSearch = {}
    }) => {
      let searchString
      if (!!Object.keys(advanceSearch || {}).length) {
        const {
          checkListName = [],
          dueDateFrom,
          dueDateTo,
          isReinspection,
          workflowName = [],
          zipCode,
          city,
          streetAddress,
          country = [],
          unit,
          assigneeId = [],
          pointOfContact = [],
          buildingTypeId,
          organization = [],
          siteStatusId,
          campaignName,
          campaignStatusId,
          campaignTypeId,
          roleId,
          genericSearch,
          state = [],
          county = [],
          siteName = []
        } = advanceSearch
        const stateList = state.map((data) => getSearchQuery("StateAbbreviations", data.value)).join("")
        const siteIdList = siteName.map((site) => getSearchQuery("SiteIds", site.value)).join("")
        const countyList = county.map((item) => getSearchQuery("Counties", item.countyName)).join("")
        const organizationList = organization.map((item) => getSearchQuery("OrganizationIds", item.value)).join("")
        const countrySearch = country.map((item) => getSearchQuery("CountryCode", item.value)).join("")
        const workflowSearch = workflowName.map((item) => getSearchQuery("WorkflowName", item.value)).join("")
        const assigneeSearch = assigneeId.map((item) => getSearchQuery("AssigneeId", item.value)).join("")
        const checkListSearch = checkListName.map((item) => getSearchQuery("CheckListName", item.value)).join("")
        const pointOfContactSearch = pointOfContact.map((item) => getSearchQuery("PointOfContactId", item.value)).join("")

        searchString = [
          getSearchQuery("DueDateFrom", dueDateFrom),
          getSearchQuery("DueDateTo", dueDateTo),
          getSearchQuery("IsReinspection", isReinspection),
          getSearchQuery("ZipCode", zipCode, false, true),
          getSearchQuery("UnitName", unit, false, true),
          getSearchQuery("CityName", city, false, true),
          getSearchQuery("StreetAddress", streetAddress, false, true),
          getSearchQuery("BuildingTypeId", buildingTypeId),
          getSearchQuery("SiteStatusId", siteStatusId),
          getSearchQuery("CampaignName", campaignName, false, true),
          getSearchQuery("CampaignStatusId", campaignStatusId),
          getSearchQuery("CampaignTypeId", campaignTypeId),
          getSearchQuery("RoleId", roleId),
          getSearchQuery("GenericSearch", genericSearch, false, true),
          stateList,
          countyList,
          pointOfContactSearch,
          siteIdList,
          organizationList,
          countrySearch,
          workflowSearch,
          assigneeSearch,
          checkListSearch
        ].join('')
      }
      searchString = searchString.replace('&', '?')
      return `api/Report/Widget/Tasks/AssignedTaskCount${searchString}`
    }
  },
  getRescheduleTaskCountWidget: {
    method: 'GET',
    url: ({
      advanceSearch = {}
    }) => {
      let searchString
      if (!!Object.keys(advanceSearch || {}).length) {
        const {
          checkListName = [],
          dueDateFrom,
          dueDateTo,
          isReinspection,
          workflowName = [],
          zipCode,
          city,
          streetAddress,
          country = [],
          unit,
          assigneeId = [],
          pointOfContact = [],
          buildingTypeId,
          organization = [],
          siteStatusId,
          campaignName,
          campaignStatusId,
          campaignTypeId,
          roleId,
          genericSearch,
          state = [],
          county = [],
          siteName = []
        } = advanceSearch
        const stateList = state.map((data) => getSearchQuery("StateAbbreviations", data.value)).join("")
        const siteIdList = siteName.map((site) => getSearchQuery("SiteIds", site.value)).join("")
        const countyList = county.map((item) => getSearchQuery("Counties", item.countyName)).join("")
        const organizationList = organization.map((item) => getSearchQuery("OrganizationIds", item.value)).join("")
        const countrySearch = country.map((item) => getSearchQuery("CountryCode", item.value)).join("")
        const workflowSearch = workflowName.map((item) => getSearchQuery("WorkflowName", item.value)).join("")
        const assigneeSearch = assigneeId.map((item) => getSearchQuery("AssigneeId", item.value)).join("")
        const checkListSearch = checkListName.map((item) => getSearchQuery("CheckListName", item.value)).join("")
        const pointOfContactSearch = pointOfContact.map((item) => getSearchQuery("PointOfContactId", item.value)).join("")
        searchString = [
          getSearchQuery("DueDateFrom", dueDateFrom),
          getSearchQuery("DueDateTo", dueDateTo),
          getSearchQuery("IsReinspection", isReinspection),
          getSearchQuery("ZipCode", zipCode, false, true),
          getSearchQuery("UnitName", unit, false, true),
          getSearchQuery("CityName", city, false, true),
          getSearchQuery("StreetAddress", streetAddress, false, true),
          getSearchQuery("BuildingTypeId", buildingTypeId),
          getSearchQuery("SiteStatusId", siteStatusId),
          getSearchQuery("CampaignName", campaignName, false, true),
          getSearchQuery("CampaignStatusId", campaignStatusId),
          getSearchQuery("CampaignTypeId", campaignTypeId),
          getSearchQuery("RoleId", roleId),
          getSearchQuery("GenericSearch", genericSearch, false, true),
          stateList,
          countyList,
          pointOfContactSearch,
          siteIdList,
          organizationList,
          countrySearch,
          workflowSearch,
          assigneeSearch,
          checkListSearch
        ].join('')
      }
      searchString = searchString.replace('&', '?')
      return `api/Report/Widget/Tasks/NeedRescheduleTaskCount${searchString}`
    }
  },
  getUnitSitePlansVM: {
    method: 'GET',
    url: ({
      siteUnitIds = [],
      planName
    }) => {
      const siteUnitsList = siteUnitIds.map((unit, index) => {
        if (index === 0) {
          return getSearchQuery("SiteUnitIds", unit.value, true)
        } else {
          return getSearchQuery("SiteUnitIds", unit.value)
        }
      }).join('')

      const searchString = [siteUnitsList, getSearchQuery("PlanName", planName)].join('')
      return `api/Project/DDL/UnitSitePlans${searchString}`
    }
  },
  getSitePlansWithVersionVM: {
    method: 'GET',
    url: ({
      siteIds = [],
      planName
    }) => {

      const siteList = siteIds.map((site, index) => {
        if (index === 0) {
          return getSearchQuery("SiteIds", site.value, true)
        } else {
          return getSearchQuery("SiteIds", site.value)
        }
      }).join('')
      const searchString = [siteList, getSearchQuery("PlanName", planName)].join('')
      return `api/Project/DDL/SitePlansWithVersion${searchString}`
    }
  },
  getNotReadyReasonVM: {
    method: 'GET',
    url: ({
      observationTaskId
    }) => `api/Scheduling/ConfirmFCInspectionReady/VM?ObservationTaskId=${observationTaskId}`
  },
  getWorkOrder: {
    method: 'GET',
    url: (observationTaskId) => `api/Scheduling/WorkOrder/${observationTaskId}`
  },
  setWorkOrder: {
    method: 'POST',
    url: (observationTaskId) => `api/Scheduling/WorkOrder/${observationTaskId}`
  },
  updateWorkOrder: {
    method: 'PUT',
    url: (observationTaskId) => `api/Scheduling/WorkOrder/${observationTaskId}`
  },
  getIsWorkOrderNeeded: {
    method: 'GET',
    url: 'api/Scheduling/WorkOrder/IsWorkOrderNeeded'
  },
  setSchedulePendingAssignment: {
    method: 'POST',
    url: "api/Scheduling/SchedulePendingAssignment"
  },
  getTaskContractList: {
    method: 'GET',
    url: ({
      pageNumber,
      observationTaskIds = 0,
      pageSize = 0,
      orderColumn,
      orderDirections
    }) => {
      const oIds = getSearchQuery("ObservationTaskList", observationTaskIds, true)
      const searchString = [
        oIds,
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')
      return `api/Scheduling/TaskContract${searchString}`
    }
  },
  getTaskContractMissingItemsCount: {
    method: 'GET',
    url: ({ observationTaskIds = 0 }) => {
      return `api/Scheduling/TaskContract/MissingItemsCount${getSearchQuery("ObservationTaskList", observationTaskIds.join(), true)}`
    }
  },
  getPurchaseOrderList: {
    method: 'GET',
    url: ({ observationTaskId }) => {
      return `api/Scheduling/PurchaseOrder/${observationTaskId}`
    }
  },
  getPurchaseOrderVM: {
    method: 'GET',
    url: ({ observationTaskId }) => {
      return `api/Scheduling/PurchaseOrder/VM/${observationTaskId}`
    }
  },
  getCostCategoryDDL: {
    method: 'GET',
    url: ({ observationTaskId, pageNumber, pageSize }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ObservationTaskId", observationTaskId)
      ].join('')
      return `api/Scheduling/PurchaseOrder/CostCategory/DDL/${observationTaskId}${searchString}`
    }
  },
  addAdditionalPurchaseOrder: {
    method: 'POST',
    url: (observationTaskId) => `api/Scheduling/PurchaseOrder/Additional/${observationTaskId}`
  },
  updateAdditionalPurchaseOrder: {
    method: 'PUT',
    url: ({ additionalPurchaseOrderId = 0 }) => `api/Scheduling/PurchaseOrder/Additional/${additionalPurchaseOrderId}`
  },
  getAdditionalPurchaseOrder: {
    method: 'GET',
    url: ({ additionalPurchaseOrderId }) => `api/Scheduling/PurchaseOrder/Additional/${additionalPurchaseOrderId}`
  },
  deleteAdditionalPurchaseOrder: {
    method: 'DELETE',
    url: ({ additionalPurchaseOrderId }) => {
      const searchString = getSearchQuery("AdditionalPurchaseOrderId", additionalPurchaseOrderId, true)
      return `api/Scheduling/PurchaseOrder/Additional/${additionalPurchaseOrderId}${searchString}`
    }
  },
  updatePrimaryPurchaseOrder: {
    method: 'PUT',
    url: ({ observationTaskId = 0 }) => `api/Scheduling/PurchaseOrder/Primary/${observationTaskId}`
  },
  removeAssignment: {
    method: 'PUT',
    url: 'api/Scheduling/Assignment/RemoveAssignment'
  },
  removeScheduleDateTime: {
    method: 'PUT',
    url: 'api/Scheduling/SchedulePendingAssignment/RemoveScheduleDateTime'
  },
  getOnHoldTaskList: {
    method: 'GET',
    url: ({ observationTaskIds = [] }) => {
      const searchString = getSearchQuery("ObservationTaskIds", observationTaskIds.join(","), true)
      return `api/Scheduling/OnHold/TaskList${searchString}`
    }
  },
  getOnHoldDependencyDetail: {
    method: 'GET',
    url: ({ observationTaskId }) => `api/Scheduling/OnHold/DependencyDetail/${observationTaskId}`
  },
  updateOnHoldRecheck: {
    method: 'PUT',
    url: ({ observationTaskId = 0 }) => `api/Scheduling/OnHold/Recheck/${observationTaskId}`
  },
  updateOnHoldCancel: {
    method: 'PUT',
    url: ({ observationTaskId = 0 }) => `api/Scheduling/OnHold/Cancel/${observationTaskId}`
  },
  updateOnHoldOverride: {
    method: 'PUT',
    url: ({ observationTaskId = 0 }) => `api/Scheduling/OnHold/Override/${observationTaskId}`
  },
  reassignTaskReviewer: {
    method: 'PUT',
    url: 'api/Scheduling/BulkReAssignment/TaskReview'
  },
  reopenTask: {
    method: 'PUT',
    url: 'api/WorkFlow/ReopenTask'
  },
  getScheduledEarliestTime: {
    method: 'GET',
    url: ({ observationTaskIds }) => {
      return `api/Scheduling/GetScheduledEarliestStartTime?ObservationTaskList=${observationTaskIds}`
    }
  },
  getSchedulingRequiredSkillsMarkets: {
    method: 'GET',
    url: ({ observationTaskIds }) => {
      const searchString = getSearchQuery("ObservationTaskList", observationTaskIds, true)
      return `api/Scheduling/GetSchedulingRequiredSkillsMarkets${searchString}`
    }
  },
  updateCloseTaskManually: {
    method: 'PUT',
    url: 'api/Scheduling/CloseTaskManually'
  }
}

export default apiConfig