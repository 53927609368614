import { put, takeLatest, call, takeEvery } from "redux-saga/effects"

import {
    getTemplateCheckpointList,
    getAddNewCheckpointVM,
    getAllCheckpointList,
    addCheckpointToTemplate,
    getCheckpointDetailVMList,
    getTemplateSubComponent,
    getTemplateAssembly,
    getTemplateComponent,
    getTemplateManufacturer,
    removeCheckpointFromTemplate,
    getAllCheckpointTrainingList,
    upsertCheckpointDetails,
    addTrainingToCheckpoint,
    removeTrainingFromCheckpoint,
    getCheckpointTrainingList,
    cloneCheckpoint,
    cloneTemplateCheckpoint,
    updateCheckpointTraining,
    getCheckpointChildDetail,
    getCheckpointCollection,
    getTemplateTrainingActivityChildDetail,
    cloneTrainingActivity,
    cloneTemplateTrainingActivity,
    updateActivityOrderIndex,
    getAssemblyTypeComponentDDL,
    getAssemblyTypeDDL,
    getApplicableRegulationList,
    addApplicableRegulationData,
    updateApplicableRegulationData,
    deleteApplicableRegulationData,
    getReferenceStandardData,
    addReferenceStandardData,
    updateReferenceStandardData,
    deleteReferenceStandardData,
    getIndustryInterpretationsData,
    addIndustryInterpretationsData,
    updateIndustryInterpretationsData,
    deleteIndustryInterpretationsData,
    updateApplicableRegulationReorder,
    updateIndustryInterpretationsReorder,
    updateReferenceStandardsReorder,
    getActivityTemplateList,
    updateRequiredActivity,
    updateCopyAllowedActivity
} from "@services/api"
import { ACTIVITY_TYPE } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'
import * as actions from "@store/actions"

const { TEMPLATE_ACTIVITY_SAGA, ACTIVITY_SAGA, TRAINING_SAGA } = LANGUAGE_CONSTANTS

/** getTemplateCheckpointList API **/
function* getTemplateCheckpointListRequest(action) {
    try {
        const { isSearchOnly = false, ...payload } = action.payload
        const response = yield call(getTemplateCheckpointList, payload)
        if (response?.data) {
            const data = { ...response.data, pageSize: action.payload.pageSize }
            if (!isSearchOnly) {
                yield put(actions.getTemplateCheckpointListSuccess(data))
            }
            if (action.callback) {
                action.callback(data)
            }
        } else {
            if (!isSearchOnly) {
                yield put(actions.getTemplateCheckpointListFailure("Unable to load template activity list"))
            }
            if (action.callback) {
                action.callback(null)
            }
        }
    } catch (error) {
        const { isSearchOnly } = action.payload
        if (action.callback) {
            action.callback(null)
        }
        if (!isSearchOnly) {
            yield put(actions.getTemplateCheckpointListFailure(error.message))
        }
    }
}

function* getAddNewCheckpointVMRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(getAddNewCheckpointVM, action.payload)
        if (response?.data) {
            yield put(actions.getNewCheckpointVMSuccess(response.data))
        } else {
            yield put(actions.getNewCheckpointVMFailure("Unable to load activity response list"))
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        yield put(actions.getTemplateCheckpointListFailure(error.message))
        yield put(actions.setIsLoading(false))
    }
}

function* getCheckpointDetailVM(action) {
    try {
        const response = yield call(getCheckpointDetailVMList, action.payload)
        if (response?.data) {
            yield put(actions.getCheckpointDetailVMSuccess(response.data))
        } else {
            yield put(actions.getCheckpointDetailVMFailure("Unable to load activity detail VM list"))
        }
    } catch (error) {
        yield put(actions.getCheckpointDetailVMFailure(error.message))
    }
}

function* getAllCheckpointListRequest(action) {
    try {
        const response = yield call(getAllCheckpointList, action.payload)
        if (response?.status === 200) {
            action?.callback(response.data)
        } else {
            action?.callback()
        }
    } catch (error) {
        action?.callback()
    }
}

function* addCheckpointToTemplateRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(addCheckpointToTemplate, action.payload)
        if (response?.status === 200) {
            action.callback(response.data)
            Notification({ status: 200, message: ACTIVITY_SAGA.ADDED_SUCCESS })
        } else {
            action.callback()
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

function* getTemplateSubComponentListRequest(action) {
    try {
        const response = yield call(getTemplateSubComponent, action.payload)
        if (response?.data) {
            yield put(actions.getTemplateSubComponentListSuccess(response.data))
            if (action.callback) {
                action.callback({ ...response.data, pageSize: action.payload.pageSize })
            }
        } else {
            yield put(actions.getTemplateSubComponentListFailure("Unable to load sub component list"))
            if (action.callback) {
                action.callback(null)
            }
        }
    } catch (error) {
        yield put(actions.getTemplateSubComponentListFailure(error.message))
    }
}

function* getTemplateAssemblyRequest(action) {
    try {
        const response = yield call(getTemplateAssembly, action.payload)
        if (response?.status === 200) {
            action?.callback(response.data)
        } else {
            action?.callback()
        }
    } catch (error) {
        action?.callback()
    }
}

function* getTemplateComponentRequest(action) {
    try {
        const response = yield call(getTemplateComponent, action.payload)
        if (response?.status === 200) {
            action?.callback(response.data)
        } else {
            action?.callback()
        }
    } catch (error) {
        action?.callback()
    }
}

function* getTemplateManufacturerRequest(action) {
    try {
        const response = yield call(getTemplateManufacturer, action.payload)
        if (response?.status === 200) {
            action?.callback(response.data)
        } else {
            action?.callback()
        }
    } catch (error) {
        action?.callback()
    }
}

/** upsertCheckpointDetails API **/
function* upsertCheckpointDetailsRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(upsertCheckpointDetails, action.payload)
        if (response) {
            action.callback(true)
            yield put(actions.setIsLoading(false))
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.ACTIVITY_DETAILS_UPDATED_SUCCESS })
        } else {
            action.callback()
            yield put(actions.setIsLoading(false))
            Notification({ status: 400, message: TEMPLATE_ACTIVITY_SAGA.ERROR_IN_UPDATING_ACTIVITY_DETAILS })
        }
    } catch (error) {
        yield put(actions.setIsLoading(false))
        Notification({ status: 400, message: TEMPLATE_ACTIVITY_SAGA.ERROR_IN_UPDATING_ACTIVITY_DETAILS })
        action.callback()
    }
}

function* removeCheckpointFromTemplateRequest(action) {
    try {
        const { isRemoveActivityFromTemplate = false } = action.payload
        const response = yield call(removeCheckpointFromTemplate, action.payload)
        if (response?.status === 204) {
            Notification({ status: 200, message: !isRemoveActivityFromTemplate ? ACTIVITY_SAGA.DELETED_SUCCESS : ACTIVITY_SAGA.ACTIVITY_DELETE_SUCCESS })
        } else {
            Notification({ status: 400, message: !isRemoveActivityFromTemplate ? ACTIVITY_SAGA.ERROR_IN_DELETING : ACTIVITY_SAGA.ERROR_DELETING_ACTIVITY })
        }
        action.callback()
    } catch (error) {
        action.callback()
    }
}

function* getAllCheckpointTrainingListRequest(action) {
    try {
        const response = yield call(getAllCheckpointTrainingList, action.payload)
        action.callback(response.data || {})
    } catch (error) {
        action.callback({})
    }
}

function* addTrainingToCheckpointRequest(action) {
    try {
        const response = yield call(addTrainingToCheckpoint, action.payload)
        if (response?.data) {
            Notification({ status: 200, message: TRAINING_SAGA.ADDED_SUCCESS })
        } else {
            Notification({ status: 400, message: TRAINING_SAGA.ERROR_IN_ADDING })
        }
        action.callback()
    } catch (error) {
        action.callback()
    }
}

function* removeTrainingFromCheckpointRequest(action) {
    try {
        const response = yield call(removeTrainingFromCheckpoint, action.payload)
        if (response?.status === 204) {
            Notification({ status: 200, message: TRAINING_SAGA.DELETED_SUCCESS })
        } else {
            Notification({ status: 400, message: TRAINING_SAGA.ERROR_IN_DELETING })
        }
        action.callback()
    } catch (error) {
        action.callback()
    }
}

function* getCheckpointTrainingListRequest(action) {
    try {
        const response = yield call(getCheckpointTrainingList, action.payload)
        action.callback(response?.data || [])
    } catch (error) {
        action.callback([])
    }
}

function* cloneCheckpointRequest(action) {
    try {
        const { activityTypeId, ...payload } = action.payload
        /**
         * Note:
         * For Training Activity : activityTypeId = 2
         * For Observation Activity (i.e Checkpoint) : activityTypeId = 1
         * */
        yield put(actions.setIsLoading(true))
        const response = yield call(Number(activityTypeId) === Number(ACTIVITY_TYPE.TRAINING) ? cloneTrainingActivity : cloneCheckpoint, payload)
        yield put(actions.setIsLoading(false))
        if (response?.data) {
            action.callback(response.data)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.CLONE_OF_ACTIVITY_ADDED_SUCCESS })
        } else {
            Notification({ status: 400, message: TEMPLATE_ACTIVITY_SAGA.ERROR_CLONING_A_ACTIVITY })
            action.callback(null)
        }
    } catch (error) {
        Notification({ status: 400, message: TEMPLATE_ACTIVITY_SAGA.ERROR_CLONING_A_ACTIVITY })
        yield put(actions.setIsLoading(false))
        action.callback(null)
    }
}

function* cloneTemplateCheckpointRequest(action) {
    try {
        const { activityTypeId, ...payload } = action.payload
        /**
         * Note:
         * For Training Activity : activityTypeId = 2
         * For Observation Activity (i.e Checkpoint) : activityTypeId = 1
         * */
        yield put(actions.setIsLoading(true))
        const response = yield call(Number(activityTypeId) === Number(ACTIVITY_TYPE.TRAINING) ? cloneTemplateTrainingActivity : cloneTemplateCheckpoint, payload)
        yield put(actions.setIsLoading(false))
        if (response?.data) {
            action.callback(response.data)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.CLONE_OF_ACTIVITY_ADDED_SUCCESS })
        } else {
            Notification({ status: 400, message: TEMPLATE_ACTIVITY_SAGA.ERROR_CLONING_A_ACTIVITY })
            action.callback(null)
        }
    } catch (error) {
        Notification({ status: 400, message: TEMPLATE_ACTIVITY_SAGA.ERROR_CLONING_A_ACTIVITY })
        yield put(actions.setIsLoading(false))
        action.callback(null)
    }
}

function* getCheckpointChildDetailRequest(action) {
    try {
        const { activityTypeId, ...payload } = action.payload
        /**
         * Note:
         * For Training Activity : activityTypeId = 2
         * For Observation Activity (i.e Checkpoint) : activityTypeId = 1
         * */
        const response = yield call(Number(activityTypeId) === Number(ACTIVITY_TYPE.TRAINING) ? getTemplateTrainingActivityChildDetail : getCheckpointChildDetail, payload)
        action.callback(response?.data || [])
    } catch (error) {
        action.callback()
    }
}

function* getCheckpointCollectionRequest(action) {
    try {
        const { isCloning } = action.payload
        if (!isCloning) {
            yield put(actions.setIsLoading(true))
        }
        const response = yield call(getCheckpointCollection, action.payload)
        if (!isCloning) {
            yield put(actions.setIsLoading(false))
        }
        action.callback(response?.data || '')
    } catch (error) {
        const { isCloning } = action.payload
        action.callback()
        if (!isCloning) {
            yield put(actions.setIsLoading(false))
        }
    }
}

function* updateCheckpointTrainingRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(updateCheckpointTraining, action.payload)
        if (response?.status === 204) {
            Notification({ status: 200, message: TRAINING_SAGA.UPDATED_SUCCESS })
        } else {
            Notification({ status: 400, message: TRAINING_SAGA.ERROR_IN_UPDAING })
        }
        action.callback()
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

function* updateActivityOrderIndexRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(updateActivityOrderIndex, action.payload)
        if (response?.status === 204) {
            action.callback(true)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.ACTIVITY_DETAILS_UPDATED_SUCCESS })
        } else {
            action.callback()
            Notification({ status: 400, message: TEMPLATE_ACTIVITY_SAGA.ERROR_IN_UPDATING_ACTIVITY_DETAILS })
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

/** getComponentbyAssemblyType API */
function* getComponentbyAssemblyTypeRequest(action) {
    try {
        const response = yield call(getAssemblyTypeComponentDDL, action.payload)
        if (response?.data) {
            action.callback({ ...response.data, pageSize: action.payload.pageSize })
        } else {
            action.callback(null)
        }
    } catch (error) {
        action.callback(null)
    }
}

/** getAssemblyTypeDDL API */
function* getAssemblyTypeDDLRequest(action) {
    try {
        const response = yield call(getAssemblyTypeDDL, action.payload)
        if (response?.data) {
            action.callback({ ...response.data, pageSize: action.payload.pageSize })
        } else {
            action.callback(null)
        }
    } catch (error) {
        action.callback(null)
    }
}

/** getApplicableRegulationList API */
function* getApplicableRegulationRequest(action) {
    try {
        const response = yield call(getApplicableRegulationList, action.payload)
        if (response?.data) {
            action.callback({ ...response.data, pageSize: action.payload.pageSize })
        } else {
            action.callback(null)
        }
    } catch (error) {
        action.callback(null)
    }
}

/** addApplicableRegulationData API */
function* addApplicableRegulationDataRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(addApplicableRegulationData, action.payload)
        if (response?.status === 200) {
            action.callback(true)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.APPLICABLE_REGULATION.APPLICABLE_REGULATION_ADDED_SUCCESS })
        } else {
            action.callback(false)
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

/** updateApplicableRegulationData API */
function* updateApplicableRegulationDataRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(updateApplicableRegulationData, action.payload)
        if (response?.status === 204) {
            action.callback(true)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.APPLICABLE_REGULATION.APPLICABLE_REGULATION_UPDATED_SUCCESS })
        } else {
            action.callback(false)
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

/** deleteApplicableRegulationData API */
function* deleteApplicableRegulationDataRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(deleteApplicableRegulationData, action.payload)
        if (response?.status === 200) {
            action.callback(true)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.APPLICABLE_REGULATION.APPLICABLE_REGULATION_DELETED_SUCCESS })
        } else {
            action.callback(false)
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

/** getReferenceStandardList API */
function* getReferenceStandardDataRequest(action) {
    try {
        const response = yield call(getReferenceStandardData, action.payload)
        if (response?.data) {
            action.callback({ ...response.data, pageSize: action.payload.pageSize })
        } else {
            action.callback(null)
        }
    } catch (error) {
        action.callback(null)
    }
}

/** addReferenceStandardData API */
function* addReferenceStandardDataRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(addReferenceStandardData, action.payload)
        if (response?.status === 200) {
            action.callback(true)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.REFERENCE_STANDARD.REFERENCE_STANDARD_ADDED_SUCCESS })
        } else {
            action.callback(false)
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

/** updateReferenceStandardData API */
function* updateReferenceStandardDataRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(updateReferenceStandardData, action.payload)
        if (response?.status === 204) {
            action.callback(true)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.REFERENCE_STANDARD.REFERENCE_STANDARD_UPDATED_SUCCESS })
        } else {
            action.callback(false)
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

/** deleteReferenceStandardDataRequest API */
function* deleteReferenceStandardDataRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(deleteReferenceStandardData, action.payload)
        if (response?.status === 200) {
            action.callback(true)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.REFERENCE_STANDARD.REFERENCE_STANDARD_DELETED_SUCCESS })
        } else {
            action.callback(false)
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

/** getIndustryInterpretationsList API */
function* getIndustryInterpretationsRequest(action) {
    try {
        const response = yield call(getIndustryInterpretationsData, action.payload)
        if (response?.data) {
            action.callback({ ...response.data, pageSize: action.payload.pageSize })
        } else {
            action.callback(null)
        }
    } catch (error) {
        action.callback(null)
    }
}

/** updateIndustryInterpretationsData API */
function* updateIndustryInterpretationsRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(updateIndustryInterpretationsData, action.payload)
        if (response?.status === 204) {
            action.callback(true)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.INDUSTRY_INTERPERTATIONS.INDUSTRY_INTERPERTATIONS_UPDATED_SUCCESS })
        } else {
            action.callback(false)
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

/** addIndustryInterpretationsData API */
function* addIndustryInterpretationsRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(addIndustryInterpretationsData, action.payload)
        if (response?.status === 200) {
            action.callback(true)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.INDUSTRY_INTERPERTATIONS.INDUSTRY_INTERPERTATIONS_ADDED_SUCCESS })
        } else {
            action.callback(false)
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

/** deleteIndustryInterpretationsData API */
function* deleteIndustryInterpretationsRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(deleteIndustryInterpretationsData, action.payload)
        if (response?.status === 200) {
            action.callback(true)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.INDUSTRY_INTERPERTATIONS.INDUSTRY_INTERPERTATIONS_DELETED_SUCCESS })
        } else {
            action.callback(false)
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

/** updateApplicableRegulationReorderAPI */
function* updateApplicableRegulationReorderRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(updateApplicableRegulationReorder, action.payload)
        if (response?.status === 204) {
            action.callback(true)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.APPLICABLE_REGULATION.APPLICABLE_REGULATION_UPDATED_SUCCESS })
        } else {
            action.callback(false)
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

/** updateIndustryInterpretationsReorder API */
function* updateIndustryInterpretationsReorderRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(updateIndustryInterpretationsReorder, action.payload)
        if (response?.status === 204) {
            action.callback(true)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.INDUSTRY_INTERPERTATIONS.INDUSTRY_INTERPERTATIONS_UPDATED_SUCCESS })
        } else {
            action.callback(false)
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

/** updateReferenceStandardsReorder API */
function* updateReferenceStandardsReorderRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(updateReferenceStandardsReorder, action.payload)
        if (response?.status === 204) {
            action.callback(true)
            Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.REFERENCE_STANDARD.REFERENCE_STANDARD_UPDATED_SUCCESS })
        } else {
            action.callback(false)
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

function* getActivityTemplateListRequest(action) {
    try {
        const response = yield call(getActivityTemplateList, action.payload)
        if (response?.status === 200) {
            action.callback({ ...response?.data, pageSize: action.payload?.pageSize })
        } else {
            action.callback()
        }
    } catch (error) {
        action?.callback()
    }
}

function* updateRequiredActivityRequest(action) {
    try {
        yield put(actions.setIsLoading(true))
        const response = yield call(updateRequiredActivity, action.payload)
        if (response?.status === 204) {
            action.callback(true)
            if (action.payload?.templateActivites[0].isRequired) {
                Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.UPDATED_SUCCESS.ACTIVITY_REQUIRED })
            } else {
                Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.UPDATED_SUCCESS.ACTIVITY_NOT_REQUIRED })
            }
        } else {
            action.callback(false)
            Notification({ status: 400, message: TEMPLATE_ACTIVITY_SAGA.ERROR_IN_UPDAING })
        }
        yield put(actions.setIsLoading(false))
    } catch (error) {
        action.callback()
        yield put(actions.setIsLoading(false))
    }
}

/** updateCopyAllowedActivity API */
function* updateCopyAllowedActivityRequest(action) {
    try {
      yield put(actions.setIsLoading(true))
      const response = yield call(updateCopyAllowedActivity, action.payload)
      yield put(actions.setIsLoading(false))
      if (response?.status === 204) {
        action.callback(true)
        Notification({ status: 200, message: TEMPLATE_ACTIVITY_SAGA.ACTIVITY_COPY_ALLOWED })
      } else {
        Notification({ status: 400, message: TEMPLATE_ACTIVITY_SAGA.ERROR_IN_COPY_ALLOWED_ACTIVITY })
        action.callback()
      }
    } catch (error) {
      yield put(actions.setIsLoading(false))
      action.callback()
    }
  }

export function* templateCheckpointsSagas() {
    yield takeLatest(actions.GET_TEMPLATE_CHECKPOINT_LIST.REQUEST, getTemplateCheckpointListRequest)
    yield takeLatest(actions.GET_NEW_CHECKPOINT_VM.REQUEST, getAddNewCheckpointVMRequest)
    yield takeLatest(actions.GET_CHECKPOINT_DETAIL_VM.REQUEST, getCheckpointDetailVM)
    yield takeLatest(actions.GET_TEMPLATE_SUB_COMPONENT_LIST.REQUEST, getTemplateSubComponentListRequest)
    yield takeLatest(actions.GET_ALL_CHECKPOINT_LIST_REQUEST, getAllCheckpointListRequest)
    yield takeLatest(actions.ADD_CHECKPOINT_TO_TEMPLATE_REQUEST, addCheckpointToTemplateRequest)
    yield takeLatest(actions.REMOVE_CHECKPOINT_FROM_TEMPLATE_REQUEST, removeCheckpointFromTemplateRequest)
    yield takeLatest(actions.GET_TEMPLATE_ASSEMBLY_REQUEST, getTemplateAssemblyRequest)
    yield takeLatest(actions.GET_TEMPLATE_COMPONENT_REQUEST, getTemplateComponentRequest)
    yield takeLatest(actions.GET_TEMPLATE_MANUFACTURER_REQUEST, getTemplateManufacturerRequest)
    yield takeLatest(actions.GET_ALL_CHECKPOINT_TRAINING_LIST_REQUEST, getAllCheckpointTrainingListRequest)
    yield takeLatest(actions.UPSERT_CHECKPOINT_DETAILS_REQUEST, upsertCheckpointDetailsRequest)
    yield takeLatest(actions.ADD_TRAINING_TO_CHECKPOINT_REQUEST, addTrainingToCheckpointRequest)
    yield takeLatest(actions.REMOVE_TRAINING_FROM_CHECKPOINT_REQUEST, removeTrainingFromCheckpointRequest)
    yield takeLatest(actions.GET_CHECKPOINT_TRAINING_LIST_REQUEST, getCheckpointTrainingListRequest)
    yield takeLatest(actions.CLONE_CHECKPOINT_REQUEST, cloneCheckpointRequest)
    yield takeLatest(actions.CLONE_TEMPLATE_CHECKPOINT_REQUEST, cloneTemplateCheckpointRequest)
    yield takeLatest(actions.UPDATE_CHECKPOINT_TRAINING_REQUEST, updateCheckpointTrainingRequest)
    yield takeEvery(actions.GET_CHECKPOINT_CHILD_DETAIL_REQUEST, getCheckpointChildDetailRequest)
    yield takeLatest(actions.GET_CHECKPOINT_COLLECTION_REQUEST, getCheckpointCollectionRequest)
    yield takeLatest(actions.UPDATE_ACTIVITY_ORDER_INDEX_REQUEST, updateActivityOrderIndexRequest)
    yield takeLatest(actions.GET_ASSEMBLY_TYPE_COMPONENT_LIST_REQUEST, getComponentbyAssemblyTypeRequest)
    yield takeLatest(actions.GET_ASSEMBLY_TYPE_DDL_REQUEST, getAssemblyTypeDDLRequest)
    yield takeLatest(actions.GET_APPLICABLE_REGULATION_REQUEST, getApplicableRegulationRequest)
    yield takeLatest(actions.ADD_APPLICABLE_REGULATION_DATA_REQUEST, addApplicableRegulationDataRequest)
    yield takeLatest(actions.UPDATE_APPLICABLE_REGULATION_DATA_REQUEST, updateApplicableRegulationDataRequest)
    yield takeLatest(actions.DELETE_APPLICABLE_REGULATION_DATA_REQUEST, deleteApplicableRegulationDataRequest)
    yield takeLatest(actions.GET_REFERENCE_STANDARD_DATA_REQUEST, getReferenceStandardDataRequest)
    yield takeLatest(actions.ADD_REFERENCE_STANDARD_DATA_REQUEST, addReferenceStandardDataRequest)
    yield takeLatest(actions.UPDATE_REFERENCE_STANDARD_DATA_REQUEST, updateReferenceStandardDataRequest)
    yield takeLatest(actions.DELETE_REFERENCE_STANDARD_DATA_REQUEST, deleteReferenceStandardDataRequest)
    yield takeLatest(actions.GET_INDUSTRY_INTERPRETATIONS_DATA_REQUEST, getIndustryInterpretationsRequest)
    yield takeLatest(actions.ADD_INDUSTRY_INTERPRETATIONS_DATA_REQUEST, addIndustryInterpretationsRequest)
    yield takeLatest(actions.UPDATE_INDUSTRY_INTERPRETATIONS_DATA_REQUEST, updateIndustryInterpretationsRequest)
    yield takeLatest(actions.DELETE_INDUSTRY_INTERPRETATIONS_DATA_REQUEST, deleteIndustryInterpretationsRequest)
    yield takeLatest(actions.UPDATE_APPLICABLE_REGULATION_REORDER_DATA_REQUEST, updateApplicableRegulationReorderRequest)
    yield takeLatest(actions.UPDATE_INDUSTRY_INTREPRETATIONS_REORDER_DATA_REQUEST, updateIndustryInterpretationsReorderRequest)
    yield takeLatest(actions.UPDATE_REFERENCE_STANDARDS_REORDER_DATA_REQUEST, updateReferenceStandardsReorderRequest)
    yield takeLatest(actions.GET_ACTIVITY_TEMPLATE_LIST_REQUEST, getActivityTemplateListRequest)
    yield takeLatest(actions.UPDATE_REQUIRED_ACTIVITY_REQUEST, updateRequiredActivityRequest)
    yield takeLatest(actions.UPDATE_COPY_ALLOWED_ACTIVITY_REQUEST, updateCopyAllowedActivityRequest)
}

export default templateCheckpointsSagas
