import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { LocaleMessage } from '@views/components'
import BluePrintList from '@views/organizations/overview/organization-details/plans/blue-print-list'
import { GridContext } from "./ag-data-grid/GridContext"

const { LOADER } = LANGUAGE_CONSTANTS

const ExpandableOrganisationPlanList = ({
  data
}) => {
  const dispatch = useDispatch()

  const context = useContext(GridContext)

  const [isLoadingChildGrid, setLoadingChildGrid] = useState(false)
  const [isGridFetched, setGridFetched] = useState(false)
  const [tableData, setTableData] = useState([])
  const [energyModelCount, setEnergyModelCount] = useState(data.energyModelCount || 0)

  const handleFetchTableData = useCallback(() => {
    const handleSetTableData = (res) => {
      setGridFetched(true)
      if (res) {
        setTableData(res)
      }
      setLoadingChildGrid(false)
    }

    setLoadingChildGrid(true)
    dispatch(
      actions.getOrganizationPlanBlueprintListRequest(
        {
          organizationId: context.organizationId,
          organizationPlanId: data.organizationPlanId
        },
        handleSetTableData
      )
    )
  }, [data])

  useEffect(() => {
    if (!isGridFetched && !isLoadingChildGrid) {
      handleFetchTableData({ page: 1 })
    }
  }, [
    isGridFetched,
    isLoadingChildGrid
  ])

  const handleUpdateRowData = useCallback((value) => {
    setEnergyModelCount(value.updatedValue.energyModelCount)
    context.onSetUpdatedSpecificRowValues(value)
  }, [context.onSetUpdatedSpecificRowValues])

  if (!isGridFetched && isLoadingChildGrid) {
    return <p className="no-data-txt template-grid-height"><LocaleMessage id={LOADER.LOADING} /></p>
  }

  if (!isLoadingChildGrid && !tableData?.length) {
    return <p className="no-data-txt">{context.noDataMessageForSubTable}</p>
  }

  return (<div className="expandable-right-content template-grid-height" >
    <BluePrintList
      list={tableData}
      organizationId={context.organizationId}
      organizationPlanId={data.organizationPlanId}
      energyModelCount={energyModelCount}
      onSetUpdatedSpecificPlanRowValues={handleUpdateRowData}
      onRefreshTableData={handleFetchTableData}
    />
  </div>
  )
}

ExpandableOrganisationPlanList.propTypes = {
  data: PropTypes.object
}

export default ExpandableOrganisationPlanList
