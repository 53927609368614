import { SET_PROGRAM_OBSERVATION_DETAILS, SET_PROGRAM_OBSERVATION_TEMPLATE_DETAILS, SET_PROGRAM_OBSERVATION_SELECT_ALL } from '../../actions'

// ** Initial State
const initialState = {
  selectObservation: {
    selectedObservationDetails: {},
    observationList: {},
    isEditMode: false,
    isAllSelected: false
  }
}

const ProgramsReducer = (state = initialState, action) => {

  const updatedDetails = { ...state.selectObservation.selectedObservationDetails }
  const { assemblyTypeName = {}, assemblyName = "", value = "" } = action.payload || {}
  switch (action.type) {
    /** SET_PROGRAM_OBSERVATION_DETAILS **/


    case SET_PROGRAM_OBSERVATION_DETAILS:
      return {
        ...state,
        selectObservation: {
          ...state.selectObservation,
          ...action.payload
        }
      }

    case SET_PROGRAM_OBSERVATION_TEMPLATE_DETAILS:
      updatedDetails[assemblyName][assemblyTypeName] = {
        ...updatedDetails[assemblyName][assemblyTypeName],
        ...value
      }
      return {
        ...state,
        selectObservation: {
          ...state.selectObservation,
          selectedObservationDetails: updatedDetails
        }
      }

    case SET_PROGRAM_OBSERVATION_SELECT_ALL:
      updatedDetails[assemblyName] = {
        ...updatedDetails[assemblyName],
        ...value
      }
      return {
        ...state,
        selectObservation: {
          ...state.selectObservation,
          selectedObservationDetails: updatedDetails
        }
      }

    default:
      return state
  }
}

export default ProgramsReducer