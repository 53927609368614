import { Row, Col } from "reactstrap"
import Button from "reactstrap/lib/Button"

import { LANGUAGE_TYPE } from "@shared/constants"
import { ToggleButton } from "@views/components"

import { getLanguageText } from './Config'

const ResponseFooter = ({
  activeLocaleTab,
  isEditResponseMode = false,
  isRequired = true,
  isSpanishVersion = false,
  responseDetail,
  onCancel = () => { },
  onChangeDetail = () => { },
  onUpsertResponse = () => { }
}) => {

  if (isSpanishVersion && isEditResponseMode) {
    return (
      <Row>
        <Col className="add-response-toggle text-right d-flex justify-content-end align-items-center">
          <Button outline className={"delete-button mr-1"} onClick={onCancel}>
            {getLanguageText(LANGUAGE_TYPE.SPANISH, "CANCEL")}
          </Button>
          <Button className="mr-1 common-button" onClick={onUpsertResponse}>
            {getLanguageText(LANGUAGE_TYPE.SPANISH, "UPDATE_RESPONSE")}
          </Button>
        </Col>
      </Row>
    )
  }

  return (
    <Row>
      <Col className="add-response-toggle text-right d-flex justify-content-end align-items-center">
        {isRequired && (
          <ToggleButton
            descriptionText="Required"
            id="responseRequired"
            name="responseRequired"
            value={responseDetail.required}
            onChange={(value) => onChangeDetail("required", value)}
          />
        )}
        {isEditResponseMode && (
          <Button outline className={"delete-button mr-1"} onClick={onCancel}>
            {getLanguageText(activeLocaleTab, "CANCEL")}
          </Button>
        )}
        <Button className="mr-1 common-button" onClick={onUpsertResponse}>
          {getLanguageText(activeLocaleTab, isEditResponseMode ? "UPDATE_RESPONSE" : "ADD_RESPONSE")}
        </Button>
      </Col>
    </Row>
  )
}

export default ResponseFooter
