import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from "react-redux"

import { getEntityDisplayNameFromToken } from "@views/helpers"

const DocumentTitle = ({ title = "" }) => {
    const { auth: { userData } } = useSelector(state => state)

    const displayTitle = useMemo(() => {
        const entityDisplayName = getEntityDisplayNameFromToken(userData.accessToken)
        return !!entityDisplayName && !!title ? `${entityDisplayName} - ${title}` : title || "Foresite"
    }, [title, userData.accessToken])

    return (
        <Helmet>
            <title>{displayTitle}</title>
        </Helmet>
    )
}

export default DocumentTitle
