import { LANGUAGE_CONSTANTS } from '@shared/language-constants'

const { ACTIVITIES, ALL_ACTIVITIES: { ACTIVITY_DETAILS, OBSERVATION_ACTIVITY_DETAILS, TRAINING_ACTIVITY_DETAILS } } = LANGUAGE_CONSTANTS

export const activityTypeSelectOptions = [{ text: "Observation", value: "1" }, { text: "Training", value: "2" }]
export const standardActivitySelectOptions = [
  { text: "Yes", value: "1" },
  { text: "No", value: "2" }
]

export const gridFiltersConfig = [
  {
    labelLocaleId: ACTIVITY_DETAILS.ACTIVITY_NAME,
    keyName: "activityName"
  },
  {
    labelLocaleId: ACTIVITY_DETAILS.ACTIVITY_ID,
    keyName: "activityId"
  },
  {
    labelLocaleId: ACTIVITY_DETAILS.ACTIVITY_VERSION,
    keyName: "activityVersion"
  },
  {
    labelLocaleId: ACTIVITY_DETAILS.ACTIVITY_TYPE,
    keyName: "activityTypeName",
    keyNameForValue: "activityType"
  },
  {
    labelLocaleId: ACTIVITY_DETAILS.STANDARD_ACTIVITY,
    keyName: "standardActivityName",
    keyNameForValue: "standardActivity"
  },
  {
    labelLocaleId: OBSERVATION_ACTIVITY_DETAILS.RISK_LEVEL,
    keyName: "riskLevel"
  },
  {
    labelLocaleId: OBSERVATION_ACTIVITY_DETAILS.RESPONSIBLE_SITE_ROLE,
    keyName: "riskLresponsibleSiteRolevel"
  },
  {
    labelLocaleId: OBSERVATION_ACTIVITY_DETAILS.ASSEMBLY_TYPE,
    keyName: "assemblyType",
    isArray: true
  },
  {
    labelLocaleId: OBSERVATION_ACTIVITY_DETAILS.ASSEMBLY,
    keyName: "assembly",
    isArray: true
  },
  {
    labelLocaleId: ACTIVITIES.SELECT_COMPONENT,
    keyName: "mainComponent",
    isArray: true,
    getFormattedValue: (obj) => {
      return {
        text: obj.componentName,
        value: obj.componentId
      }
    }
  },
  {
    labelLocaleId: ACTIVITIES.SELECT_SUB_COMPONENT,
    keyName: "subComponent",
    isArray: true,
    getFormattedValue: (obj) => {
      return {
        text: obj.subComponentName,
        value: obj.subComponentId
      }
    }
  },
  {
    labelLocaleId: OBSERVATION_ACTIVITY_DETAILS.MANUFACTURER,
    keyName: "manufacturer",
    isArray: true
  },
  {
    labelLocaleId: OBSERVATION_ACTIVITY_DETAILS.BRAND,
    keyName: "brand",
    isArray: true
  },
  {
    labelLocaleId: OBSERVATION_ACTIVITY_DETAILS.REGULATION_NAME,
    keyName: "regulationName"
  },
  {
    labelLocaleId: OBSERVATION_ACTIVITY_DETAILS.STANDARD_NAME,
    keyName: "standardName"
  },
  {
    labelLocaleId: ACTIVITIES.SEARCH_BY_COURSE,
    keyName: "course",
    isArray: true
  },
  {
    labelLocaleId: OBSERVATION_ACTIVITY_DETAILS.RESPONSIBLE_SITE_ROLE,
    keyName: "responsibleSiteRole",
    isArray: true
  },
  {
    labelLocaleId: TRAINING_ACTIVITY_DETAILS.SUBJECT,
    keyName: "subject",
    isArray: true
  },
  {
    labelLocaleId: TRAINING_ACTIVITY_DETAILS.CHAPTER,
    keyName: "chapter",
    isArray: true
  },
  {
    labelLocaleId: TRAINING_ACTIVITY_DETAILS.LESSON,
    keyName: "lesson",
    isArray: true
  },
  {
    labelLocaleId: TRAINING_ACTIVITY_DETAILS.SOURCE,
    keyName: "source"
  },
  {
    labelLocaleId: TRAINING_ACTIVITY_DETAILS.AUTHOR,
    keyName: "author"
  }
]