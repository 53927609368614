import React, { useState, useCallback, useMemo, useEffect } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"
import PropTypes from 'prop-types'

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"
import { getReactSelectPositionProps } from "@views/helpers"
import localeMessageWrapper from '../locale-message'

const { CAMPAIGN_SEARCH } = LANGUAGE_CONSTANTS

const CampaignSearch = ({
  intl,
  asyncInitialValue,
  isColWrapper,
  isEditMode,
  wrapperStyle,
  isAllOption, //Note: For getting --All-- as a option in VM
  initialLabel,
  isViewMode,
  errors,
  defaultOptions = [],
  id,
  isMulti,
  isRequired,
  isShowCustomAction,
  label,
  labelRequired,
  name,
  placeholder,
  programId,
  organizationId,
  value,
  valueType,
  labelType,
  onSelect = () => { },
  getOptionLabel = (option) => option.text,
  getOptionValue = (option) => option.value
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState([])

  /**
  * Note: Paginated select maintain cache of options,
  * so to set new default options we need to reset previous cache
  */
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState('')
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)

  //Todo: handle edit mode when single select

  /**
 * @method handleSetDefaultOptions : To handle set default options in case of Edit mode, Add new from modal
 *
 * Note: To handle case of duplicate option, when custom option is added, that is selected from edit mode or add new modal
 */
  const handleSetDefaultOptions = useCallback((selectedItem, campaignId) => {
    const checkIfAlreadyPresent = defaultOptions.find(option => option.value === campaignId)
    if (!!checkIfAlreadyPresent) {
      setOptions(defaultOptions)
    } else {
      setOptions([selectedItem, ...defaultOptions])
    }
  }, [defaultOptions])

  useEffect(() => {
    const options = defaultOptions.map(option => ({
      text: option.campaignName,
      value: option.campaignId
    }))
    if (!isEditMode) {
      setResetCache(prevState => !prevState)
    }
    setOptions(options)
  }, [defaultOptions])

  useEffect(() => {
    //Note: When campaign detail is fetched in parent component
    if (!isEditModeItemLoaded && isEditMode && asyncInitialValue && !!value && !!initialLabel) {
      const selectedItem = {
        text: initialLabel,
        value
      }
      setEditModeItemLoaded(true)
      setSelectedOptionLabel(initialLabel)
      setResetCache(prevState => !prevState)
      handleSetDefaultOptions(selectedItem, value)
    }
  }, [defaultOptions, initialLabel, isEditMode, isEditModeItemLoaded, value])

  useEffect(() => {
    const fn = () => {
      setTimeout(() => {
        setResetCache((prevState) => !prevState)
        setOptions(defaultOptions)
      }, [100])
    }
    fn()
  }, [organizationId])

  const handleLoadMoreOptions = useCallback(
    (search, page, prevOptions) => {
      return new Promise((resolve) => {

        const handleSearchResultCallback = (response) => {
          if (!!response.items) {
            const { hasNextPage } = response
            let { items } = response
            items = items.map(option => ({
              text: option.campaignName,
              value: option.campaignId
            }))
            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
            resolve({
              optionList: items,
              hasMore: hasNextPage
            })
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }
        const campaignDDLParams = {
          programId,
          organizationId,
          campaignName: search,
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          allOption: isAllOption
        }
        dispatch(
          actions.getCampaignDDLRequest(campaignDDLParams, handleSearchResultCallback))

      }
      )
    }, [programId, organizationId, options])

  const handleSelect = useCallback((selected) => {
    if (isMulti) {
      const lastElement = selected.length
        ? selected[selected.length - 1]
        : null
      if (!!lastElement && !lastElement.value) {
        onSelect(id, [])
      } else {
        onSelect(id, selected)
      }
    } else {
      onSelect(id, selected?.value || "", (selected?.value && selected?.text) || "")
      setSelectedOptionLabel(selected?.text || "")
    }
  }, [onSelect, id])

  const reactSelectPostionProps = useMemo(() => {
    return getReactSelectPositionProps()
  }, [])

  const WrapperComponent = useMemo(() => (isColWrapper ? Col : 'div'), [isColWrapper])

  const colWrapperProps = useMemo(() => {
    if (!isColWrapper) {
      return {}
    }

    return {
      className: "mb-1",
      sm: wrapperStyle.sm,
      md: wrapperStyle.md,
      xl: wrapperStyle.xl
    }
  }, [isColWrapper])

  return (
    <WrapperComponent {...colWrapperProps}>
      {labelRequired && <CustomLabel title={label || CAMPAIGN_SEARCH.CAMPAIGN_NAME} requiredIconClassName="required-field" required={isRequired} isLocalizedTitle={!label} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id={id}
        name={name}
        placeholder={placeholder || getLocaleMessage(intl, CAMPAIGN_SEARCH.CAMPAIGN_NAME)}
        options={options}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={
          !isMulti
            ? !!value
              ? {
                text: selectedOptionLabel,
                value
              }
              : null
            : value
        }
        isShowCustomAction={isShowCustomAction}
        valueType={valueType}
        labelType={labelType}
        onSelect={handleSelect}
        onLoadOptions={handleLoadMoreOptions}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isMulti={isMulti}
        hideSelectedOptions={false}
        isDisabled={isViewMode}
        {...reactSelectPostionProps}
      />
      {errors && (
        <ErrorMessage
          isShow={!!errors[id]}
          message={errors[id]}
        />
      )}
    </WrapperComponent>
  )
}

CampaignSearch.propTypes = {
  intl: PropTypes.object,
  asyncInitialValue: PropTypes.bool,
  initialLabel: PropTypes.string,
  isAllOption: PropTypes.bool,
  isColWrapper: PropTypes.bool,
  isEditMode: PropTypes.bool,
  wrapperStyle: PropTypes.object,
  isViewMode: PropTypes.bool,
  defaultOptions: PropTypes.array,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  isShowCustomAction: PropTypes.bool,
  labelRequired: PropTypes.bool,
  name: PropTypes.string,
  valueType: PropTypes.string,
  labelType: PropTypes.string,
  onSelect: PropTypes.func,
  errors: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func
}

CampaignSearch.defaultProps = {
  wrapperStyle: {
    md: 6,
    sm: 12,
    xl: 4
  },
  isColWrapper: true,
  isEditMode: false,
  asyncInitialValue: false,
  isAllOption: false,
  initialLabel: '',
  isViewMode: false,
  id: "campaignId",
  isMulti: true,
  isRequired: false,
  isShowCustomAction: false,
  labelRequired: true,
  name: "campaignName",
  valueType: "value",
  labelType: "text",
  value: ""
}

export default localeMessageWrapper(CampaignSearch)
