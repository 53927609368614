import { LANGUAGE_CONSTANTS } from "@shared/language-constants"

const { ACTIVITIES } = LANGUAGE_CONSTANTS

export const gridFiltersConfig = [
  {
    labelLocaleId: ACTIVITIES.ACTIVITY_NAME,
    keyName: "checkpointName"
  },
  {
    labelLocaleId: ACTIVITIES.ACTIVITY_TYPE,
    keyName: "activityType"
  },
  {
    labelLocaleId: ACTIVITIES.RESPONSIBLE_SITE_ROLE,
    keyName: "responsibleSiteRole"
  },
  {
    labelLocaleId: ACTIVITIES.RISK_LEVEL,
    keyName: "riskLevel"
  },
  {
    labelLocaleId: ACTIVITIES.HOLD_PRODUCTION,
    keyName: "holdProduction"
  },
  {
    labelLocaleId: ACTIVITIES.ASSEMBLY,
    keyName: "assemblyName",
    keyNameForValue: "assembly"
  },
  {
    labelLocaleId: ACTIVITIES.ASSEMBLY_TYPE,
    keyName: "assemblyType"
  },
  {
    labelLocaleId: ACTIVITIES.MAIN_COMPONENT,
    keyName: "component",
    getFormattedValue: (obj) => ({
      value: obj.componentId,
      text: obj.componentName
    })
  },
  {
    labelLocaleId: ACTIVITIES.SUB_COMPONENT,
    keyName: "subComponent",
    getFormattedValue: (obj) => ({
      value: obj.subComponentId,
      text: obj.subComponentName
    })
  },
  {
    labelLocaleId: ACTIVITIES.MANUFACTURER,
    keyName: "manufacturerName",
    keyNameForValue: "manufacturer"
  },
  {
    labelLocaleId: ACTIVITIES.BRAND,
    keyName: "brand"
  }
]