export const getSearchQuery = (key = '', value = '', initialValue = false, encode = false) => {
  return !!value ? !!initialValue ? encode ? `?${key}=${encodeURIComponent(value)}` : `?${key}=${value}` : encode ? `&${key}=${encodeURIComponent(value)}` : `&${key}=${value}` : ''
}

const apiConfig = {
  signup: {
    method: 'POST',
    url: () => `dbconnections/signup`
  },
  forgotPassword: {
    method: 'POST',
    url: () => `dbconnections/change_password`
  },
  getContactTraininglogList: {
    method: 'GET',
    url: ({ learnerID }) => `api/Scorm/DigitalResume/${learnerID}`
  }
}

export default apiConfig
