import config from "./APIConfig"
import { postDataApi } from '../../index'

/**
 * @method addOshaTrainingCampaign
 * @param {object} data 
 *   {
    "startDate": "2022-01-28T08:54:10.742Z",
    "completionAfterReady": 0,
    "siteRoleId": 0,
    "serviceListDtos": [
      0
    ]
  }
 */

export const addOshaTrainingCampaign = async (data) => {
  const { url } = config.addOshaTrainingCampaign

  return await postDataApi(url(data.programId), data)
}

export default addOshaTrainingCampaign
