import config from "./APIConfig"
import { getDataApi } from "../../index"

export const getProgramDirectoryOverviewList = async (data) => {
  const { url } = config.getProgramDirectoryOverviewList
  return await getDataApi(url(data))
}

export const getProgramDirectoryOverviewVM = async () => {
  const { url } = config.getProgramDirectoryOverviewVM
  return await getDataApi(url)
}

export const getProgramObservationTaskOverdueStatus = async (data) => {
  const { url } = config.getProgramObservationTaskOverdueStatus
  return await getDataApi(url(data))
}

export const getProgramScheduleVM = async (data) => {
  const { url } = config.getProgramScheduleVM
  return await getDataApi(url(data))
}

export const getProgramTaskOverviewList = async (data) => {
  const { url } = config.getProgramTaskOverviewList
  return await getDataApi(url(data))
}

export const getProgramTaskOverviewVM = async () => {
  const { url } = config.getProgramTaskOverviewVM
  return await getDataApi(url)
}

export const getProgramUnitDetail = async (data) => {
  const { url } = config.getProgramUnitDetail
  return await getDataApi(url(data))
}

export const getProgramUnitOverviewList = async (data) => {
  const { url } = config.getProgramUnitOverviewList
  return await getDataApi(url(data))
}

export const getProgramUnitOverviewVM = async () => {
  const { url } = config.getProgramUnitOverviewVM
  return await getDataApi(url)
}

export const getProgramUnitScheduleList = async (data) => {
  const { url } = config.getProgramUnitScheduleList
  return await getDataApi(url(data))
}

export const getUnitSchedulePrereqsVM = async (data) => {
  const { url } = config.getUnitSchedulePrereqsVM
  return await getDataApi(url(data))
}
