import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'

const { OVERVIEW_PAGE_SECTIONS: { TASKS_SECTION: { PLEASE_SELECT_CLOSED_DATE } } } = LANGUAGE_CONSTANTS


export const validateCloseTaskDetails = (closedDate) => {
  const errors = {}

  if (!closedDate) {
    errors['closedDate'] = <LocaleMessage id={PLEASE_SELECT_CLOSED_DATE} />
  }

  return errors
}