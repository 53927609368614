import { all } from "redux-saga/effects"

import actionsPageSagas from '@store/sagas/actions-page'
import addressSagas from '@store/sagas/address'
import authSagas from '@store/sagas/auth'
import campaignSagas from "@store/sagas/campaigns"
import campaignServiceSagas from '@store/sagas/campaign-service'
import campaignServiceChecklistCheckpointsSagas from '@store/sagas/campaign-service-checklist-checkpoints'
import checkpointsSagas from '@store/sagas/checkpoints'
import contactsSagas from '@store/sagas/contacts'
import commonSagas from '@store/sagas/common'
import globalSearchSagas from '@store/sagas/global-search'
import organizationSagas from '@store/sagas/organization'
import programsQualityControlSagas from '@store/sagas/program-quality-control'
import programsSagas from '@store/sagas/programs'
import programsSpecificSagas from '@store/sagas/programs-specific'
import documentationCampaignSagas from '@store/sagas/programs/documentation-campaign'
import trainingCampaignSagas from "@store/sagas/programs/training-campaign"
import programOverviewDetailsSagas from "@store/sagas/programs/program-overview-details"
import projectsSagas from '@store/sagas/projects'
import projectSiteDetailsSagas from '@store/sagas/project-site-details'
import projectSitePlanSagas from '@store/sagas/project-site-plan'
import projectSiteRolesSagas from '@store/sagas/project-site-roles'
import projectSiteUnitsSagas from '@store/sagas/project-site-units'
import reportSagas from '@store/sagas/reports'
import resourcesSagas from '@store/sagas/resources'
import samplingSagas from "@store/sagas/sampling"
import schedulingSagas from '@store/sagas/scheduling'
import schedulingCalendarSagas from '@store/sagas/scheduling-calendar'
import serviceChecklistSagas from '@store/sagas/service-checklist'
import taskDataCollectionSagas from '@store/sagas/task-data-collection'
import templateSagas from '@store/sagas/template'
import templateCheckpointsSagas from '@store/sagas/template-checkpoints'
import templateTrainingActivtySagas from '@store/sagas/template-training-activity'
import userManagementSagas from '@store/sagas/user-management'
import userManagementContactSagas from '@store/sagas/user-management-contacts'
import userManagementPermissionSagas from '@store/sagas/user-management-permission'
import userManagementRoleSagas from '@store/sagas/user-management-role'
import dataRetentionPoliciesSagas from '@store/sagas/data-retention-policies'
import { assignActivitiesBlockSagas, checklistActivitiesGroupSagas, workflowBlockSagas, buildWorkflowSagas, workflowReviewSagas, workflowTrainingTemplateSagas, automateReportSagas } from '@store/sagas/workflow'
import overviewPagesSagas from '@store/sagas/overview-pages'
import programsEnergyStarSagas from '@store/sagas/program-energy-star'
import auditTrailSagas from '@store/sagas/audit-trail'
import activitySagas from '@store/sagas/activities'
import contactGroupSagas from "@store/sagas/contact-group"
import campaignContractSagas from "@store/sagas/campaigns/contract"
import cordSagas from '@store/sagas/cords'
import communicationLogSagas from "@store/sagas/communication-log"
import intercomSagas from "@store/sagas/intercom"

export default function* rootSagas() {
  yield all([
    addressSagas(),
    authSagas(),
    campaignSagas(),
    checkpointsSagas(),
    contactsSagas(),
    globalSearchSagas(),
    organizationSagas(),
    projectsSagas(),
    projectSiteDetailsSagas(),
    projectSitePlanSagas(),
    projectSiteRolesSagas(),
    projectSiteUnitsSagas(),
    serviceChecklistSagas(),
    schedulingSagas(),
    templateCheckpointsSagas(),
    templateSagas(),
    campaignServiceChecklistCheckpointsSagas(),
    campaignServiceSagas(),
    userManagementSagas(),
    userManagementContactSagas(),
    userManagementPermissionSagas(),
    templateTrainingActivtySagas(),
    userManagementRoleSagas(),
    reportSagas(),
    programsSagas(),
    programsSpecificSagas(),
    resourcesSagas(),
    trainingCampaignSagas(),
    documentationCampaignSagas(),
    taskDataCollectionSagas(),
    workflowBlockSagas(),
    buildWorkflowSagas(),
    assignActivitiesBlockSagas(),
    workflowReviewSagas(),
    actionsPageSagas(),
    programsQualityControlSagas(),
    dataRetentionPoliciesSagas(),
    programOverviewDetailsSagas(),
    overviewPagesSagas(),
    commonSagas(),
    workflowTrainingTemplateSagas(),
    programsEnergyStarSagas(),
    samplingSagas(),
    schedulingCalendarSagas(),
    auditTrailSagas(),
    activitySagas(),
    checklistActivitiesGroupSagas(),
    automateReportSagas(),
    contactGroupSagas(),
    campaignContractSagas(),
    cordSagas(),
    communicationLogSagas(),
    intercomSagas()
  ])
}
