import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addOrganizationRoleContact
 * @param {object} data 
 * 
 * {
 *  "organizationId": 0,
 *  "organization": 0,
 *  "contactIds": [],
 *  "roleId": 0
 * }
 * 
 */

export const addOrganizationRoleContact = (data) => {
  const { url } = config.addOrganizationRoleContact
  const payload = {
    organizationID: Number(data.organizationId) || 0,
    externalOrganizationID: Number(data.organization) || 0,
    contactIds: data.contactIds || [],
    sitePartyRoleId: Number(data.roleId) || 0
  }
  return postDataApi(url, payload)
}

export default addOrganizationRoleContact
