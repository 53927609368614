import { LicenseManager } from "ag-grid-enterprise"

import * as AppSettings from "@app-settings"
import { TableCellToolTip } from "./TableCellToolTip"

LicenseManager.setLicenseKey(AppSettings.REACT_APP_AGDATAGRID_LICENSE_KEY)

export const DEFAULT_COL_DEF = {
  editable: false,
  floatingFilterComponentParams: { suppressFilterButton: true },
  tooltipComponent: TableCellToolTip
}

export const TOOLTIP_SHOW_DELAY = 0

//rowModelType
export const ROW_MODEL_TYPE = {
  SERVER_SIDE: 'serverSide'
}

//rowSelection
export const ROW_SELECTION_TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple'
}

export const DEFAULT_SIDEBAR_DEF = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true
      }
    }
  ],
  defaultToolPanel: ''
}

/**
 * @const LIST_SORT_ORDER_VALUES:
 * 
 * ASC: "0",
 * DESC: "1",
 * NO_SORT: ""
 * 
 * Note: order of values in LIST_SORT_ORDER_VALUES should be same as in LIST_SORT_ORDER_DIRECTION
 * 
 * We can check for LIST_SORT_ORDER_DIRECTION in following component -
 * import { LIST_SORT_ORDER_DIRECTION } from '@shared/constants'
 * 
 */
export const LIST_SORT_ORDER_VALUES = ["0", "1", ""]
