export const GET_PROJECT_SITE_ROLE_LIST_REQUEST = 'GET_PROJECT_SITE_ROLE_LIST_REQUEST'
export const ADD_PROJECT_SITE_ROLE_REQUEST = 'ADD_PROJECT_SITE_ROLE_REQUEST'
export const GET_PROJECT_SITE_ROLE_REQUEST = 'GET_PROJECT_SITE_ROLE_REQUEST'
export const UPDATE_PROJECT_SITE_ROLE_REQUEST = 'UPDATE_PROJECT_SITE_ROLE_REQUEST'
export const GET_PROJECT_SITE_ROLES_VM_REQUEST = 'GET_PROJECT_SITE_ROLES_VM_REQUEST'
export const SET_EDIT_SITE_ROLE_MODE = 'SET_EDIT_SITE_ROLE_MODE'
export const DELETE_PROJECT_SITE_ROLE_REQUEST = 'DELETE_PROJECT_SITE_ROLE_REQUEST'
export const UPDATE_SITE_ROLE_PRIMARY_REQUEST = "UPDATE_SITE_ROLE_PRIMARY_REQUEST"
export const GET_SITE_ROLES_VM_REQUEST = 'GET_SITE_ROLES_VM_REQUEST'

/****************************************************/
/** GET_PROJECT_SITE_ROLE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProjectSiteRoleListRequest :  To get project site role list
 *
 */
export const getProjectSiteRoleListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROJECT_SITE_ROLE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROJECT_SITE_ROLES_VM_REQUEST **/
/****************************************************/

/**
 * @method getProjectSiteRolesVMRequest : To get dropdowns list for site roles setup/search
 *
 */
export const getProjectSiteRolesVMRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROJECT_SITE_ROLES_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROJECT_SITE_ROLE_REQUEST **/
/****************************************************/

/**
 * @method getProjectSiteRoleRequest :  To get site role detail
 *
 */
export const getProjectSiteRoleRequest = (payload, callback) => {
  return {
    type: GET_PROJECT_SITE_ROLE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_PROJECT_SITE_ROLE_REQUEST **/
/****************************************************/

/**
 * @method addProjectSiteRoleRequest :  To add site role detail of Project
 *
 */
export const addProjectSiteRoleRequest = (payload, callback) => {
  return {
    type: ADD_PROJECT_SITE_ROLE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PROJECT_SITE_ROLE_REQUEST **/
/****************************************************/

/**
 * @method updateProjectSiteRoleRequest :  To update site role detail of Project
 *
 */
export const updateProjectSiteRoleRequest = (payload, callback) => {
  return {
    type: UPDATE_PROJECT_SITE_ROLE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SET_EDIT_SITE_ROLE_MODE **/
/****************************************************/

/**
 * @method setEditSiteRoleMode : To set edit mode for Site Role
 *
 */
export const setEditSiteRoleMode = (value) => {
  return {
    type: SET_EDIT_SITE_ROLE_MODE,
    payload: value
  }
}

/****************************************************/
/** DELETE_PROJECT_SITE_ROLE_REQUEST **/
/****************************************************/

/**
 * @method deleteProjectSiteRoleRequest : To delete Project Site Role
 *
 */
export const deleteProjectSiteRoleRequest = (payload, callback = () => { }) => {
  return {
    type: DELETE_PROJECT_SITE_ROLE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_SITE_ROLE_PRIMARY_REQUEST **/
/****************************************************/
/**
* @method updateProjectSiteRolePrimaryRequest :  To update site role for primary role or not
 *
 */
export const updateProjectSiteRolePrimaryRequest = (payload, callback) => {
  return {
    type: UPDATE_SITE_ROLE_PRIMARY_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SITE_ROLES_VM_REQUEST **/
/****************************************************/

/**
 * @method getSiteRolesVMRequest : To get dropdowns list for site roles setup/search
 *
 */
export const getSiteRolesVMRequest = (callback = () => { }) => {
  return {
    type: GET_SITE_ROLES_VM_REQUEST,
    callback
  }
}
