import React from "react"
import { Col, Input } from "reactstrap"
import { Search, Sliders } from "react-feather"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { filter } from "@src/assets/images/new-theme"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"

const SearchFilter = ({
  intl,
  genericSearch,
  genericSearchRequired,
  isNewListTheme,
  pageSizeFilterRequired,
  tableViewAdvanceSearchRequired = true,
  onChangeSearchFilter = () => { },
  onKeyDown = () => { },
  onToggleAdvanceSearch = () => { }
}) => {
  if (isNewListTheme) {
    return (
      <div className="filterview-outer">
        {!!tableViewAdvanceSearchRequired && <div className="filter-view"><img src={filter} className="filter-icon" onClick={onToggleAdvanceSearch} /></div>}
        <div className="search-input-box">
          <Search className="search-icon" onClick={e => e.stopPropagation()} />
          <Input
            className="dataTable-filter  "
            type="text"
            placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.TABLE.SEARCH)}
            bsSize="sm"
            id="search-input"
            value={genericSearch}
            onChange={onChangeSearchFilter}
            onKeyDown={onKeyDown}
            onClick={e => e.stopPropagation()}
          />
        </div>
      </div>
    )
  }

  return (
    <Col
      className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
      sm={pageSizeFilterRequired ? "8" : "12"}
    >
      <div className="d-flex mt-md-0 mt-1 justify-content-end">
        <div className="d-flex">
          {!!genericSearchRequired && (
            <Input
              className="dataTable-filter  "
              type="text"
              placeholder={getLocaleMessage(
                intl,
                LANGUAGE_CONSTANTS.TABLE.SEARCH
              )}
              bsSize="sm"
              id="search-input"
              value={genericSearch}
              onChange={onChangeSearchFilter}
              onKeyDown={onKeyDown}
              onClick={e => e.stopPropagation()}
            />
          )}
          {!!tableViewAdvanceSearchRequired && (
            <button
              type="button"
              className="waves-effect btn advanced-btn"
              onClick={onToggleAdvanceSearch}
            >
              {getLocaleMessage(intl, LANGUAGE_CONSTANTS.TABLE.ADVANCED)}
            </button>
          )}
        </div>
      </div>
    </Col>
  )
}

export default localeMessageWrapper(SearchFilter)
