import config from "./APIConfig"
import { getDataApi } from "../../index"

export const getBlockAddTraining = async (data) => {
  const { url } = config.getBlockAddTraining
  return await getDataApi(url(data))
}

export const getBlockChecklist = async (data) => {
  const { url } = config.getBlockChecklist
  return await getDataApi(url(data))
}

export const getBlockChecklistVM = () => {
  const { url } = config.getBlockChecklistVM
  return getDataApi(url)
}

export const getBlockConfirmSiteVisit = async (data) => {
  const { url } = config.getBlockConfirmSiteVisit
  return await getDataApi(url(data))
}

export const getBlockDueDate = async (data) => {
  const { url } = config.getBlockDueDate
  return await getDataApi(url(data))
}

export const getBlockDueDateVM = () => {
  const { url } = config.getBlockDueDateVM
  return getDataApi(url)
}

export const getBlockEscalation = async (data) => {
  const { url } = config.getBlockEscalationRequest
  return await getDataApi(url(data))
}

export const getBlockFailedInspectionCorrection = async (data) => {
  const { url } = config.getBlockFailedInspectionCorrection
  return await getDataApi(url(data))
}

export const getBlockFailedInspectionTraining = async (data) => {
  const { url } = config.getBlockFailedInspectionTraining
  return await getDataApi(url(data))
}

export const getBlockFailedTraining = async (data) => {
  const { url } = config.getBlockFailedTraining
  return await getDataApi(url(data))
}

export const getBlockFailedTrainingVM = async () => {
  const { url } = config.getBlockFailedTrainingVM
  return await getDataApi(url)
}

export const getBlockPartialSubmission = async (data) => {
  const { url } = config.getBlockPartialSubmission
  return await getDataApi(url(data))
}

export const getBlockScheduleAutomateReports = async (data) => {
  const { url } = config.getBlockScheduleAutomateReports
  return await getDataApi(url(data))
}

export const getBlockScheduleAutomateReportsVM = async (data) => {
  const { url } = config.getBlockScheduleAutomateReportsVM
  return await getDataApi(url(data))
}

export const getBlockScheduleRecordableSettings = async (data) => {
  const { url } = config.getBlockScheduleRecordableSettings
  return await getDataApi(url(data))
}

export const getBlockScheduleRecordableSettingsVM = async () => {
  const { url } = config.getBlockScheduleRecordableSettingsVM
  return await getDataApi(url)
}

export const getBlockScheduleTask = async (data) => {
  const { url } = config.getBlockScheduleTask
  return await getDataApi(url(data))
}

export const getBlockTaskCloseout = async (data) => {
  const { url } = config.getBlockTaskCloseout
  return await getDataApi(url(data))
}

export const getBlockTaskReview = async (data) => {
  const { url } = config.getBlockTaskReview
  return await getDataApi(url(data))
}

export const getBlockTaskSignOff = async (data) => {
  const { url } = config.getBlockTaskSignOff
  return await getDataApi(url(data))
}

export const getBlockTaskSignOffVM = (data) => {
  const { url } = config.getBlockTaskSignOffVM
  return getDataApi(url(data))
}

export const getBlockTrigger = async (data) => {
  const { url } = config.getBlockTrigger
  return await getDataApi(url(data))
}

export const getConfirmSiteVisitVM = (data) => {
  const { url } = config.getConfirmSiteVisitVM
  return getDataApi(url(data))
}

export const getCreateTriggerVM = (data) => {
  const { url } = config.getCreateTriggerVM
  return getDataApi(url(data))
}

export const getFailedInspectionTrainingVM = () => {
  const { url } = config.getFailedInspectionTrainingVM
  return getDataApi(url)
}

export const getFindingsCorrection = (data) => {
  const { url } = config.getFindingsCorrection
  return getDataApi(url(data))
}

export const getFindingsCorrectionVM = () => {
  const { url } = config.getFindingsCorrectionVM
  return getDataApi(url)
}

export const getInspectionCorrectionVM = (data) => {
  const { url } = config.getInspectionCorrectionVM
  return getDataApi(url(data))
}

export const getObservationEscalationVM = (data) => {
  const { url } = config.getObservationEscalationVM
  return getDataApi(url(data))
}

export const getPartialSubmissionVM = async () => {
  const { url } = config.getPartialSubmissionVM
  return await getDataApi(url)
}

export const getScheduleTaskVM = (data) => {
  const { url } = config.getScheduleTaskVM
  return getDataApi(url(data))
}

export const getSitePlanScheduleLineItemDDL = (data) => {
  const { url } = config.getSitePlanScheduleLineItemDDL
  return getDataApi(url(data))
}

export const getSitePlanTriggerVM = (data) => {
  const { url } = config.getSitePlanTriggerVM
  return getDataApi(url(data))
}

export const getTaskCloseOutVM = () => {
  const { url } = config.getTaskCloseOutVM
  return getDataApi(url)
}

export const getTaskReviewVM = async (data) => {
  const { url } = config.getTaskReviewVM
  return await getDataApi(url(data))
}

export const getTemplateStepQuery = (data) => {
  const { url } = config.getTemplateStepQuery
  return getDataApi(url(data))
}

export const getTrainingEscalationVM = () => {
  const { url } = config.getTrainingEscalationVM
  return getDataApi(url)
}

export const getFindingWorkflows = async (data) => {
  const { url } = config.getFindingWorkflows
  return await getDataApi(url(data))
}
