import config from "./APIConfig"
import { getDataApi } from "../index"

export const getAllContactList = async (data) => {
  const { url } = config.getAllContactList
  return await getDataApi(url(data))
}

export const getContactGroupDDL = async (data) => {
  const { url } = config.getContactGroupDDL
  return await getDataApi(url(data))
}

export const getContactGroupList = async (data) => {
  const { url } = config.getContactGroupList
  return await getDataApi(url(data))
}

export const getContactListByGroup = async (data) => {
  const { url } = config.getContactListByGroup
  return await getDataApi(url(data))
}

export const getGroupContactList = async (data) => {
  const { url } = config.getGroupContactList
  return await getDataApi(url(data))
}
