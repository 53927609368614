import { takeLatest, put, call } from "redux-saga/effects"

import {
  addCheckpoint,
  addNewCheckpointVersion,
  addNewTrainingActivityVersion,
  deleteCheckpoint,
  getCheckpointResponseQuestions,
  upsertCheckpointResponseQuestions,
  getObservationActivityResponseQuestions,
  getReportTagDDL
} from "@services/api"
import { ACTIVITY_TYPE } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

const { ACTIVITY_SAGA } = LANGUAGE_CONSTANTS

/** addCheckpoint API **/
function* addCheckpointRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addCheckpoint, action.payload)
    if (response?.data) {
      if (action.callback) {
        action.callback(response.data)
      }
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIVITY_SAGA.ADDED_SUCCESS })
    } else {
      if (action.callback) {
        action.callback(null)
      }
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIVITY_SAGA.ERROR_IN_ADDING })
    }
  } catch (error) {
    if (action.callback) {
      action.callback(null)
    }
    yield put(actions.setIsLoading(false))
  }
}

/** getCheckpointResponseQuestions API **/
function* getCheckpointResponseQuestionsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getCheckpointResponseQuestions, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback(null)
      Notification({ status: 400, message: ACTIVITY_SAGA.ERROR_IN_FETCHING_QUESTIONS })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(null)
    yield put(actions.setIsLoading(false))
  }
}

/** getCheckpointResponseQuestions API **/
function* getObservationActivityResponseQuestionsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getObservationActivityResponseQuestions, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback(null)
      Notification({ status: 400, message: ACTIVITY_SAGA.ERROR_IN_FETCHING_QUESTIONS })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(null)
    yield put(actions.setIsLoading(false))
  }
}
/** upsertCheckpointResponseQuestions API **/
function* upsertCheckpointResponseQuestionsRequest(action) {
  try {
    const { isUpdate = false, isUpsertAlert = false, ...payload } = action.payload
    yield put(actions.setIsLoading(true))
    const response = yield call(upsertCheckpointResponseQuestions, payload)
    const result = response && (response.data || response.status === 204) ? response.data || payload.checkpointId : 0
    if (result) {
      action.callback(result)
      Notification({ status: 200, message: isUpsertAlert ? ACTIVITY_SAGA.NEW_VERSION_ADDED_SUCCESS : isUpdate ? ACTIVITY_SAGA.QUESTIONS_UPDATED_SUCCESS : ACTIVITY_SAGA.QUESTIONS_ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: isUpdate ? ACTIVITY_SAGA.ERROR_IN_UPDATING_QUESTIONS : ACTIVITY_SAGA.ERROR_IN_ADDING_QUESTIONS })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** deleteCheckpoint API **/
function* deleteCheckpointRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteCheckpoint, action.payload)
    yield put(actions.setIsLoading(false))
    action.callback(response?.status === 204)
    if (response?.status === 204) {
      Notification({ status: 200, message: ACTIVITY_SAGA.DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: ACTIVITY_SAGA.ERROR_IN_DELETING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** addNewCheckpointVersion API **/
function* addNewCheckpointVersionRequest(action) {
  try {
    const { isAlertRequired = true, activityTypeId, ...payload } = action.payload
    /**
     * Note:
     * For Training Activity : activityTypeId = 2
     * For Observation Activity (i.e Checkpoint) : activityTypeId = 1
     * */
    yield put(actions.setIsLoading(true))
    const response = yield call(Number(activityTypeId) === Number(ACTIVITY_TYPE.TRAINING) ? addNewTrainingActivityVersion : addNewCheckpointVersion, payload)
    yield put(actions.setIsLoading(false))
    if (response?.data) {
      action.callback(response.data)
      if (isAlertRequired) {
        Notification({ status: 200, message: ACTIVITY_SAGA.NEW_VERSION_ADDED_SUCCESS })
      }
    } else {
      Notification({ status: 400, message: ACTIVITY_SAGA.ERROR_ADDING_NEW_VERSION })
      action.callback(null)
    }
  } catch (error) {
    Notification({ status: 400, message: ACTIVITY_SAGA.ERROR_ADDING_NEW_VERSION })
    yield put(actions.setIsLoading(false))
    action.callback(null)
  }
}

/** getReportTagDDL API **/
function* getReportTagDDLRequest(action) {
  try {
    const response = yield call(getReportTagDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export function* checkpointsSagas() {
  yield takeLatest(actions.ADD_CHECKPOINT_REQUEST, addCheckpointRequest)
  yield takeLatest(actions.ADD_NEW_CHECKPOINT_VERSION_REQUEST, addNewCheckpointVersionRequest)
  yield takeLatest(actions.GET_CHECKPOINT_RESPONSE_QUESTIONS_REQUEST, getCheckpointResponseQuestionsRequest)
  yield takeLatest(actions.UPSERT_CHECKPOINT_RESPONSE_QUESTIONS_REQUEST, upsertCheckpointResponseQuestionsRequest)
  yield takeLatest(actions.DELETE_CHECKPOINT_REQUEST, deleteCheckpointRequest)
  yield takeLatest(actions.GET_OBSERVATION_ACTIVITY_RESPONSE_QUESTIONS_REQUEST, getObservationActivityResponseQuestionsRequest)
  yield takeLatest(actions.GET_REPORT_TAG_DDL_REQUEST, getReportTagDDLRequest)
}

export default checkpointsSagas
