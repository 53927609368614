import React, { useCallback } from "react"
import {
    Col,
    FormGroup,
    Input
} from "reactstrap"

import { CustomLabel } from "@views/components"
import { getLanguageText } from './Config'

const ResponseDescription = ({ id, isActivityDescription, activeLocaleTab, value = "", onChangeDetail = () => {} }) => {
    const handleChangeDetail = useCallback((event) => {
        onChangeDetail(id, event.target.value, { isDescriptionUpdate: true })
    }, [onChangeDetail])

    return (<Col sm={12} md={6} xl={isActivityDescription ? 8 : 6}>
        <FormGroup>
            <CustomLabel
                title={getLanguageText(activeLocaleTab, 'DESCRIPTION')}
                isLocalizedTitle={false}
            />
            <Input
                type="textarea"
                name="description"
                placeholder={getLanguageText(activeLocaleTab, 'ADD_DESCRIPTION_HERE')}
                value={value}
                onChange={handleChangeDetail}
            />
        </FormGroup>
    </Col>)
}

export default ResponseDescription
