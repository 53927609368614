
import { SET_EDIT_CHECKPOINT_MODE } from '../../actions'

// ** Initial State
const initialState = {
  isEditMode: false,
  data: null
}

const Checkpoints = (state = initialState, action) => {
  switch (action.type) {

    /** SET_EDIT_CHECKPOINT_MODE **/
    case SET_EDIT_CHECKPOINT_MODE:
      return {
          ...state,
        isEditMode: action.payload.isEditMode,
        data: action.payload.data
      }

    default:
      return state
  }
}

export default Checkpoints
