import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { CHECKPOINT_PARENT_TYPE, COLUMN_LIST_TYPE, DEFAULT_CHILD_TABLE_PAGE_SIZE } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { LocaleMessage } from '@views/components'
import ChecklistActivitiesTable from "@views/campaigns/add-new-campaign/workflow/block/assign-checklist/checklist-activities"
import CheckpointTable from "@views/projects/templates/add-new-template/checkpoints/checkpoints-table"
import { EXPANDABLE_TYPE } from './config'

const { LOADER } = LANGUAGE_CONSTANTS

/**
 * Note:
 * Set templateId = -1,
 * For fetching checkpoints when we have no templateId such as in case of campaign
 */
const templateConfig = {
  templateId: -1
}

const ExpandableCheckpointTable = ({
  clearSelectedRows = false,
  expandableRowsType = EXPANDABLE_TYPE.TEMPLATES_SEARCH,
  expandedRowId = "",
  isRefreshGrid = false,
  subData,
  subColumnConfigType,
  data,
  noDataMessage,
  onSelectedRowsChange
}) => {
  const dispatch = useDispatch()

  const [isLoadingChildGrid, setLoadingChildGrid] = useState(false)
  const [isGridFetched, setGridFetched] = useState(false)
  const [tableData, setTableData] = useState(null)

  const handleFetchTableData = useCallback(({
    page = 1
  }) => {
    const handleSetTableData = (res) => {
      setGridFetched(true)
      if (res) {
        setTableData({ ...res, pageNumber: res.pageIndex })
      }
      setLoadingChildGrid(false)
    }

    if (!isLoadingChildGrid && !!subData) {
      if (expandableRowsType === EXPANDABLE_TYPE.TEMPLATES_SEARCH && data.templateId === expandedRowId) {
        const selectedGrid = subData[`sub_grid_key_${expandedRowId}`]

        if (!!selectedGrid) {
          setLoadingChildGrid(true)
          dispatch(
            actions.getTemplateCheckpointListRequest(
              {
                templateId: expandedRowId,
                templateVersionId: data.templateVersionId || 1,
                pageSize: DEFAULT_CHILD_TABLE_PAGE_SIZE,
                pageNumber: page,
                isLoadingRequired: false,
                isSearchOnly: true
              },
              handleSetTableData
            )
          )
        }
      } else if (expandableRowsType === EXPANDABLE_TYPE.CHECKLIST_CHECKPOINTS && data.campaignServiceCheckListId === expandedRowId) {
        const selectedGrid = subData[`sub_grid_key_${expandedRowId}`]

        if (!!selectedGrid) {
          setLoadingChildGrid(true)
          dispatch(actions.getCampaignServiceChecklistCheckpointListRequest({
            checklistId: expandedRowId,
            pageSize: DEFAULT_CHILD_TABLE_PAGE_SIZE,
            isLoadingRequired: false,
            isSearchOnly: true,
            pageNumber: page
          }, handleSetTableData))
        }
      }
    }
  }, [])

  useEffect(() => {
    if ((isRefreshGrid || !isGridFetched) && !isLoadingChildGrid && !!subData) {
      handleFetchTableData({ page: 1 })
    }
  }, [
    data.templateId,
    data.campaignServiceCheckListId,
    expandedRowId,
    isGridFetched,
    isLoadingChildGrid,
    isRefreshGrid,
    subData
  ])

  const handleOnPageChange = useCallback(({ selected }) => {
    handleFetchTableData({ page: selected + 1 })
  }, [handleFetchTableData])


  if (!isGridFetched && (isLoadingChildGrid || isRefreshGrid)) {
    if ((expandableRowsType === EXPANDABLE_TYPE.TEMPLATES_SEARCH && expandedRowId === data.templateId) || (expandableRowsType === EXPANDABLE_TYPE.CHECKLIST_CHECKPOINTS && expandedRowId === data.campaignServiceCheckListId)) {
      return <p className="no-data-txt"><LocaleMessage id={LOADER.LOADING} /></p>
    }
  }

  if (!subData) {
    return <p className="no-data-txt">{noDataMessage}</p>
  }

  let selectedGrid = null
  if (expandableRowsType === EXPANDABLE_TYPE.TEMPLATES_SEARCH) {
    selectedGrid = subData[`sub_grid_key_${data.templateId}`]
  } else if (expandableRowsType === EXPANDABLE_TYPE.CHECKLIST_CHECKPOINTS) {
    selectedGrid = subData[`sub_grid_key_${data.wfCampaignSetupChecklistId}`]
  }

  if (!selectedGrid) {
    return <p className="no-data-txt">{noDataMessage}</p>
  }

  let otherConfig = {}
  if (subColumnConfigType === COLUMN_LIST_TYPE.CHECKPOINTS_FOR_CHECKLIST) {
    otherConfig = {
      checklistDetail: {
        checklistId: data.campaignServiceCheckListId
      }
    }
  }

  if (expandableRowsType === EXPANDABLE_TYPE.CHECKLIST_CHECKPOINTS) {
    return <ChecklistActivitiesTable
      wfCampaignSetupChecklistId={data.wfCampaignSetupChecklistId}
      isChildGrid
      isReviewActivity={selectedGrid.isReviewActivity}
      tableViewHeaderRequired={false}
    />
  }

  if (!tableData?.items.length) {
    return <p className="no-data-txt">{noDataMessage}</p>
  }

  const isTemplatesSearchType = expandableRowsType === EXPANDABLE_TYPE.TEMPLATES_SEARCH

  return (
    <div className="template-right-content">
      <CheckpointTable
        parentType={isTemplatesSearchType ? '' : CHECKPOINT_PARENT_TYPE.CAMPAIGN_SERVICE_CHECKLIST}
        clearSelectedRows={clearSelectedRows}
        columnConfigType={subColumnConfigType || (isTemplatesSearchType ? COLUMN_LIST_TYPE.SEARCH_FOR_CAMPAIGN : COLUMN_LIST_TYPE.CHECKPOINTS_FOR_CHECKLIST)}
        isChildGrid
        showExpandedPagination
        isModal={isTemplatesSearchType}
        isSearchMode={isTemplatesSearchType}
        isTemplateOverview={isTemplatesSearchType}
        tableViewHeaderRequired={false}
        templateDetail={isTemplatesSearchType ? selectedGrid : templateConfig}
        tableData={tableData}
        onSelectedRowsChange={(selectedRowState) => onSelectedRowsChange(selectedRowState, true)}
        {...otherConfig}
        childGridProps={{
          isLoading: isLoadingChildGrid,
          noDataMessage,
          onPagination: handleOnPageChange
        }}
      />
    </div >
  )
}

ExpandableCheckpointTable.propTypes = {
  clearSelectedRows: PropTypes.bool,
  expandableRowsType: PropTypes.string,
  isRefreshGrid: PropTypes.bool,
  subData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  noDataMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  onSelectedRowsChange: PropTypes.func
}

export default ExpandableCheckpointTable
