import config from "./APIConfig"
import { getDataApi } from "../index"

export const getAdditionalPurchaseOrder = async (data) => {
  const { url } = config.getAdditionalPurchaseOrder
  return await getDataApi(url(data))
}

export const getAssignedContactList = async (data) => {
  const { url } = config.getAssignedContactList
  return await getDataApi(url(data))
}

export const getAssignedContactsDDL = async (data) => {
  const { url } = config.getAssignedContactsDDL
  return await getDataApi(url(data))
}

export const getAssignedTaskContactListDDL = async (payload) => {
  const { url } = config.getAssignedTaskContactListDDL
  return await getDataApi(url(payload))
}

export const getAssignedTaskContactVM = async (organizationId) => {
  const { url } = config.getAssignedTaskContactVM
  return await getDataApi(url(organizationId))
}

export const getAssignedTaskDetail = async (data) => {
  const { url } = config.getAssignedTaskDetail
  return await getDataApi(url(data))
}

export const getAssignedTaskCountWidget = async (data) => {
  const { url } = config.getAssignedTaskCountWidget
  return await getDataApi(url(data))
}

export const getAssignmentObservationTaskList = async (data) => {
  const { url } = config.getAssignmentObservationTaskList
  return await getDataApi(url(data))
}

export const getAssignmentObservationTasksList = async (data) => {
  const { url } = config.getAssignmentObservationTasksList
  return await getDataApi(url(data))
}

export const getAssignmentOrganizationsList = async (data) => {
  const { url } = config.getAssignmentOrganizationsList
  return await getDataApi(url(data))
}

export const getAssignmentRolesList = async (data) => {
  const { url } = config.getAssignmentRolesList
  return await getDataApi(url(data))
}

export const getAssignmentSitesList = async (data) => {
  const { url } = config.getAssignmentSitesList
  return await getDataApi(url(data))
}

export const getAssignmentUnitsList = async (data) => {
  const { url } = config.getAssignmentUnitsList
  return await getDataApi(url(data))
}

export const getContactTaskList = async (data) => {
  const { url } = config.getContactTaskList
  return await getDataApi(url(data))
}

export const getCostCategoryDDL = async (data) => {
  const { url } = config.getCostCategoryDDL
  return await getDataApi(url(data))
}

export const getIsWorkOrderNeeded = async () => {
  const { url } = config.getIsWorkOrderNeeded
  return await getDataApi(url)
}

export const getNotReadyReasonVM = async (data) => {
  const { url } = config.getNotReadyReasonVM
  return await getDataApi(url(data))
}

export const getObservationTaskListSchedule = async (data) => {
  const { url } = config.getObservationTaskListSchedule
  return await getDataApi(url(data))
}

export const getOnHoldDependencyDetail = async (data) => {
  const { url } = config.getOnHoldDependencyDetail
  return await getDataApi(url(data))
}

export const getOnHoldTaskList = async (data) => {
  const { url } = config.getOnHoldTaskList
  return await getDataApi(url(data))
}

export const getPurchaseOrderList = async (data) => {
  const { url } = config.getPurchaseOrderList
  return await getDataApi(url(data))
}

export const getPurchaseOrderVM = async (data) => {
  const { url } = config.getPurchaseOrderVM
  return await getDataApi(url(data))
}

export const getReadyStatusCountWidget = async (data) => {
  const { url } = config.getReadyStatusCountWidget
  return await getDataApi(url(data))
}

export const getRescheduleTaskCountWidget = async (data) => {
  const { url } = config.getRescheduleTaskCountWidget
  return await getDataApi(url(data))
}

export const getScheduleAssignVM = async (data) => {
  const { url } = config.getScheduleAssignVM
  return await getDataApi(url(data || {}))
}

export const getSchedulingContactCalendarList = async (data) => {
  const { url } = config.getSchedulingContactCalendarList
  return await getDataApi(url(data))
}

export const getSchedulingRequiredSkillsMarkets = async (data) => {
  const { url } = config.getSchedulingRequiredSkillsMarkets
  return await getDataApi(url(data))
}

export const getScheduleVM = async (data) => {
  const { url } = config.getScheduleVM
  return await getDataApi(url(data || {}))
}

export const getScheduledEarliestTime = async (data) => {
  const { url } = config.getScheduledEarliestTime
  return await getDataApi(url(data))
}

export const getSchedulingContactList = async (data) => {
  const { url } = config.getSchedulingContactList
  return await getDataApi(url(data))
}

export const getSchedulingList = async (data) => {
  const { url } = data.isAdminTasks ? config.getAdministrativeTasks : config.getSchedulingList
  return await getDataApi(url(data))
}

export const getSchedulingListTasks = async (data) => {
  const { url } = config.getSchedulingListTasks
  return await getDataApi(url(data))
}

export const getSitePlansWithVersionVM = async (data) => {
  const { url } = config.getSitePlansWithVersionVM
  return await getDataApi(url(data))
}

export const getTaskContractList = async (data) => {
  const { url } = config.getTaskContractList
  return await getDataApi(url(data))
}

export const getTaskContractMissingItemsCount = async (data) => {
  const { url } = config.getTaskContractMissingItemsCount
  return await getDataApi(url(data))
}

export const getTaskHistoryDetail = async (data) => {
  const { url } = config.getTaskHistoryDetail
  return await getDataApi(url(data || {}))
}

export const getTaskHistoryList = async (data) => {
  const { url } = config.getTaskHistoryList
  return await getDataApi(url(data))
}

export const getTaskHistoryPendingUnitList = async (data) => {
  const { url } = config.getTaskHistoryPendingUnitList
  return await getDataApi(url(data))
}

export const getUnitSitePlansVM = async (data) => {
  const { url } = config.getUnitSitePlansVM
  return await getDataApi(url(data))
}

export const getWorkOrder = async (data) => {
  const { url } = config.getWorkOrder
  return await getDataApi(url(data.observationTaskId), data)
}
