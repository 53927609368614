import cx from "classnames"
import { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import { connect, useSelector } from 'react-redux'
import { withRouter, useLocation } from 'react-router-dom'
import { PlusSquare, Copy } from 'react-feather'
import { useForm } from 'react-hook-form'
import { Col, ModalBody, Row, Card } from "reactstrap"

import { isPermissionDenied } from '@router/Helper'
import {
  ROUTES,
  COLUMN_LIST_TYPE,
  CHECKPOINT_ORIGNATED_FROM_TYPES,
  CHECKPOINT_PARENT_TYPE,
  DEFAULT_CHILD_TABLE_PAGE_SIZE,
  PERMISSION_ROUTES,
  QUICK_SEARCH_KEY,
  TABLE_LIST_KEYS,
  TABLE_VIEW_CUSTOM_MESSAGES
} from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from '@store/actions'
import { getLocaleMessage } from "@utils"
import { ConfirmationModal, TableView, CustomLabel, ToggleButton, ReactSelect } from '@views/components'
import localeMessageWrapper from '@views/components/locale-message'
import { ROW_SELECTION_TYPE } from "@views/components/table-view/ag-data-grid/config"
import { EXPANDABLE_TYPE } from '@views/components/table-view/config'
import { formatAndUpdateSearchParamsInURL, updateFilterParamsInSearchHistoryRef, useFilterParamsFromSearchQueryInURLForGridPage } from '@views/helpers'
import AdvanceSearch from './AdvanceSearch'
import { gridFiltersConfig } from "./config"
import { checkIfSearchFiltersApplied, getInitialSearchState } from './helpers'
import { getColumnList } from './TableConfig'
import { activityTypeSelectOptions } from '../../../../../activities/overview/config'

const { ACTIVITIES, NEW_VERSION, TEMPLATES, CLONE, NEW_VERSION_TITLE, CLONE_TITLE, CLONE_ACTIVITIES } = LANGUAGE_CONSTANTS

const initialCheckpointDetail = {
  stageOfConstruction: '',
  scopeOfWork: '',
  responsibleSiteRole: null,
  riskLevel: null,
  checkpointName: '',
  systemCategory: '',
  assembly: '',
  assemblyName: '',
  component: [],
  subComponent: [],
  manufacturer: '',
  manufacturerName: '',
  brand: null,
  activityType: null,
  assemblyType: [],
  holdProduction: false
}

const Checkpoints = ({
  isFilterByTraining,
  intl,
  children = null,
  parentType = '',
  titleWarpperClassname = '',
  checklistTitle = "",
  clearSelectedRows = false,
  columnConfigType = COLUMN_LIST_TYPE.OVERVIEW,
  history,
  isChildGrid = false,
  showExpandedPagination = false,
  childGridProps = {},
  childGridTableComponentProps = {},
  isChecklistModal,
  isChecklistView = false,
  isModal = false,
  isOpenSearch = false,
  isSearchMode = false,
  isTemplateOverview = false,
  isShowInitialLoading = false,
  isViewMode = false,
  pagination = true,
  searchViewTitle,
  tableViewHeaderRequired = true,
  tableSubHeader = true,
  tableData = null,
  templateDetail = { templateName: '' },
  checklistDetail = { checkpointId: 0 },
  templateVersionDDL = [],
  addNewTemplateVersion,
  cloneTemplateRequest,
  deleteCampaignServiceChecklistCheckpoint,
  getTemplateCheckpointList,
  getCampaignCheckpointList,
  resetCheckpointList,
  refreshTableList,
  updateActivityOrderIndex,
  saveCurrentTemplate,
  onSelectedRowsChange = null,
  TableSubHeaderComponentProp = null,
  isIntialFetchRequired,
  isListTitleRequired = true,
  isNewListTheme,
  isSelectableRows,
  headerConfig = {},
  isRowClickable = true,
  isBorderNotRequired = false,
  advanceSearchConfig = {},
  className = '',
  activeSectionTabId = '',
  onSetActiveSectionTabId = () => { },
  isCheckBoxSelectionNotRequired = false,
  onFetchTemplateVersionDDL = () => { },
  onGetChecklistCheckpoints = () => { },
  onSaveList = () => { },
  onSetHasActivityListData = () => { },
  onToggleSearchModal = () => { },
  onAddCheckpoint = () => { }
}) => {
  const location = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const searchHistoryRef = useRef({})

  const { campaignServiceChecklistCheckpoints: { tableData: checkpointTableData }, templateCheckpoints, loader: { isLoading: isPageLoading = false } } = useSelector(state => state)
  const isListRefreshed = useSelector((state) => state.tableActions[TABLE_LIST_KEYS.ACTIVITY_LIST_KEY]?.isListRefreshed || false)

  const { list = [], pageNumber = 0, pageSize = 0, totalCount = 0, totalPages = 0 } = (parentType === CHECKPOINT_PARENT_TYPE.CAMPAIGN_SERVICE_CHECKLIST)
    ? (checkpointTableData[`checklist_${checklistDetail.checklistId}`]?.data || {}) : templateCheckpoints

  const { control } = useForm({})

  const [genericSearch, setGenericSearch] = useState('')
  const [isOpenNewTemplateVersionModal, setOpenNewTemplateVersionModal] = useState(false)
  const [isOpenTemplateCloneModal, setOpenTemplateCloneModal] = useState(false)
  const [isSearchApplied, setSearchApplied] = useState(false)
  const [isGenericSearchApplied, setGenericSearchApplied] = useState(false)
  const [appliedGenericSearch, setAppliedGenericSearch] = useState("")
  const [isFetching, setFetchingList] = useState((!isChildGrid && isSearchMode && isModal) || isShowInitialLoading)
  const [typingTimeout, setTypingTimeout] = useState(null)
  const [isActivityCloneRequired, setIsActivityCloneRequired] = useState(false)

  // Advance Search
  const [isOpenAdvanceSearch, setIsOpenAdvanceSearch] = useState(isOpenSearch)
  const [checkpointsSearchDetail, setCheckpointsSearchDetail] = useState(getInitialSearchState({
    initialCheckpointDetail,
    isFilterByTraining,
    isChildGrid,
    isSearchMode,
    isModal
  }))

  const [selectedCheckpointIdList, setSelectedCheckpointIdList] = useState([])
  const [selectedCheckpointList, setSelectedCheckpointList] = useState([])
  const [isToggledClearRows, setToggleClearRows] = useState(false)

  const [isShowBulkActionView, setShowBulkActionView] = useState(false)
  const [sortOrderColumn, setSortOrderColumn] = useState('')
  const [sortOrderDirection, setSortOrderDirection] = useState('')

  // View by template version
  const [viewByTemplateVersionId, setViewByTemplateVersionId] = useState("")

  const isBulkActionRequired = useMemo(() => {
    return columnConfigType === COLUMN_LIST_TYPE.CHECKPOINTS_FOR_CHECKLIST || columnConfigType === COLUMN_LIST_TYPE.WORKFLOW_ASSIGN_CHECKLIST_CHECKPOINTS
  }, [columnConfigType])

  const fetchCampaignServiceChecklistCheckpoints = useCallback(
    ({ payload, callback = () => { } }) => {
      getCampaignCheckpointList(payload, callback)
    }, [])

  const fetchTemplateCheckpoints = useCallback(({ payload, callback = () => { } }) => {
    getTemplateCheckpointList(payload, callback)
  }, [])

  const handleCloneActivities = useCallback(() => {
    setIsActivityCloneRequired(!isActivityCloneRequired)
  }, [isActivityCloneRequired])

  const fetchCheckpoints = useCallback(
    ({
      orderColumn,
      orderDirections,
      page,
      size,
      search,
      checkpointName = "",
      canApplyFilters = true,
      isGSFilter = false,
      advanceSearchDetail = {},
      isAutoAdvanceSearch = false,
      isResetAdvanceSearch = false,
      callback = () => { },
      isResetSortOrder = false,
      isViewByTemplateVersion,
      templateVersionNumber
    }) => {
        setFetchingList(true)
      let advanceSearch = {}
      let isSearchFilter = false

      const searchFormData = isAutoAdvanceSearch ? advanceSearchDetail : checkpointsSearchDetail

      if (!isResetAdvanceSearch) {
        searchFormData.checkpointName = isGSFilter ? checkpointName : searchFormData.checkpointName
        if (templateDetail.templateId && !isModal) {
          searchHistoryRef.current.advanceSearch = searchFormData
        }
        advanceSearch = {
          stageOfConstruction: searchFormData.stageOfConstruction,
          scopeOfWork: searchFormData.scopeOfWork,
          responsibleSiteRole: searchFormData.responsibleSiteRole?.value,
          riskLevel: searchFormData.riskLevel?.value,
          checkpointName: searchFormData.checkpointName,
          systemCategory: searchFormData.systemCategory,
          brand: searchFormData.brand?.value,
          assembly: searchFormData.assembly,
          component: searchFormData.component.map((component) => component.componentId) || [],
          subComponent: searchFormData.subComponent.map((subComp) => subComp.subComponentId) || [],
          manufacturer: searchFormData.manufacturer,
          activityType: searchFormData.activityType?.value,
          assemblyType: searchFormData.assemblyType.map((type) => type.value) || [],
          holdProduction: searchFormData.holdProduction || false
        }
        isSearchFilter = checkIfSearchFiltersApplied(searchFormData)
      }

      if (!isSearchFilter && isViewByTemplateVersion && !!templateVersionNumber) {
        isSearchFilter = true
      }

      setSearchApplied(isSearchFilter)

      const searchText = isGSFilter
        ? search
          ? search.trim()
          : ""
        : genericSearch || ""
      const searchVal = canApplyFilters ? searchText : ''
      setAppliedGenericSearch(searchVal)

      if (templateDetail.templateId && !isModal) {
        searchHistoryRef.current.genericSearch = searchVal
        
        updateFilterParamsInSearchHistoryRef({
          canApplyFilters,
          isResetSortOrder,
          orderColumn,
          orderDirections,
          sortOrderColumn,
          sortOrderDirection,
          searchHistoryRef
        })
      }

      const request = {
        payload: {
          templateId: templateDetail?.templateId || 0,
          templateVersionId: isViewByTemplateVersion ? templateVersionNumber || templateDetail?.templateVersionId || 1 : viewByTemplateVersionId || templateDetail?.templateVersionId || 1,
          checklistId: checklistDetail?.checklistId,
          pageNumber: page || pageNumber,
          pageSize: size || pageSize,
          genericSearch: searchVal,
          advanceSearch: canApplyFilters ? advanceSearch : '',
          orderColumn: isResetSortOrder ? orderColumn : sortOrderColumn,
          orderDirections: isResetSortOrder ? orderDirections : sortOrderDirection
        },
        callback: (data) => {
          callback()

          setFetchingList(false)
          if (typingTimeout) {
            setTypingTimeout(null)
          }

          setGenericSearchApplied(!!searchVal)

          if (!searchVal && !isSearchFilter && isChecklistModal && isSearchMode) {
            setIsOpenAdvanceSearch(true)
          }

          //Note: If search is not applied and it has data for activity list
          if (!isSearchFilter && !searchVal) {
            onSetHasActivityListData(!!data?.items?.length)
          }
        }
      }
      if (parentType === CHECKPOINT_PARENT_TYPE.CAMPAIGN_SERVICE_CHECKLIST) {
        fetchCampaignServiceChecklistCheckpoints(request)
      } else {
        fetchTemplateCheckpoints(request)
      }
    },
    [
      fetchCampaignServiceChecklistCheckpoints,
      fetchTemplateCheckpoints,
      onSetHasActivityListData,
      parentType,
      isChecklistModal,
      isSearchMode,
      templateDetail,
      viewByTemplateVersionId,
      pageNumber,
      pageSize,
      genericSearch,
      checklistDetail,
      checkpointsSearchDetail,
      sortOrderColumn,
      sortOrderDirection,
      typingTimeout
    ]
  )

  useEffect(() => {
    if (isListRefreshed) {
      fetchCheckpoints({ canApplyFilters: isGenericSearchApplied || isSearchApplied })
      refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: false })
    }
  }, [isListRefreshed])

  useEffect(() => {
    if ((!isChildGrid && isSearchMode && isModal) || isIntialFetchRequired) {
      fetchCheckpoints({ page: 1 })
    }
  }, [])

  useEffect(() => {
    if (templateDetail.templateId && !isModal) {
    formatAndUpdateSearchParamsInURL({
      filters: searchHistoryRef.current,
      advancedFilters: { activeTab: activeSectionTabId },
      history
    })
  }
  }, [
    templateDetail.templateId,
    searchHistoryRef.current.sortOrderDirection,
    searchHistoryRef.current.sortOrderColumn,
    searchHistoryRef.current.genericSearch,
    searchHistoryRef.current.advanceSearch,
    history
  ])

  useEffect(() => {
    if (templateDetail.templateId && !isModal) {
    useFilterParamsFromSearchQueryInURLForGridPage({
      queryParams,
      searchDetail: checkpointsSearchDetail,
      onSetGenericSearch: setGenericSearch,
      onSetSortOrderColumn: setSortOrderColumn,
      onSetSortOrderDirection: setSortOrderDirection,
      onUpdateTabSpecificFilterInState: (config) => {
        const { activeTab } = config || {}
        if (activeTab) {
          onSetActiveSectionTabId(JSON.parse(activeTab))
        }
      },
      onSetSearchDetail: setCheckpointsSearchDetail,
      onFetchList: fetchCheckpoints
    })
  }
  }, [templateDetail.templateId])

  const handlePagination = useCallback(
    ({ selected }) => {
      fetchCheckpoints({ page: selected + 1 })
    },
    [fetchCheckpoints]
  )

  const handleResetSearchDetail = useCallback(() => {
    setCheckpointsSearchDetail(Object.assign({}, initialCheckpointDetail))
    if (!isGenericSearchApplied) {
      setGenericSearch("")
    }
    if (templateDetail.templateId && !isModal) {
      searchHistoryRef.current.advanceSearch = {}
    }
    if (isSearchMode) {
      resetCheckpointList()
    } else {
      if (isSearchApplied) {
        resetCheckpointList()
        fetchCheckpoints({ page: 1, isResetAdvanceSearch: true })
      }
    }
  }, [templateDetail.templateId, isGenericSearchApplied, isSearchMode, isSearchApplied, fetchCheckpoints])

  const handleAdvancedSearch = useCallback((isAutoAdvanceSearch, data) => {
    const isAutoSearch = isAutoAdvanceSearch && !!data
    if (checkIfSearchFiltersApplied(isAutoSearch ? data : checkpointsSearchDetail) || isSearchApplied) {
      resetCheckpointList()
      const autoSearchConfig = isAutoSearch ? { isAutoAdvanceSearch, advanceSearchDetail: data } : {}
      fetchCheckpoints({ page: 1, size: DEFAULT_CHILD_TABLE_PAGE_SIZE, ...autoSearchConfig })
    }
    if (!isAutoSearch) {
      setIsOpenAdvanceSearch(false)
    }
  }, [isSearchApplied, checkpointsSearchDetail, fetchCheckpoints])

  const handleToggleAdvanceSearch = useCallback((event) => {
    if (event.target.id === "close-search" || isOpenAdvanceSearch) {
      if (!!checkpointsSearchDetail.checkpointName) {
        setGenericSearch(checkpointsSearchDetail.checkpointName)
      }
      if (isSearchMode) {
        resetCheckpointList()
        if (isModal && !isSearchApplied && !list.length) {
          onToggleSearchModal()
        } else {
          if (!isSearchApplied && !isGenericSearchApplied) {
            onToggleSearchModal()
          } else {
            setIsOpenAdvanceSearch(!isOpenAdvanceSearch)
            setCheckpointsSearchDetail(Object.assign({}, initialCheckpointDetail))
          }
        }
      } else {
        if (isSearchApplied) {
          resetCheckpointList()
          fetchCheckpoints({
            page: 1,
            isResetAdvanceSearch: true,
            callback: () => {
              setIsOpenAdvanceSearch(!isOpenAdvanceSearch)
              setCheckpointsSearchDetail(Object.assign({}, initialCheckpointDetail))
            }
          })
        } else {
          setIsOpenAdvanceSearch(!isOpenAdvanceSearch)
          setCheckpointsSearchDetail(Object.assign({}, initialCheckpointDetail))
        }
      }
    } else {
      if (genericSearch !== '') {
        setCheckpointsSearchDetail((prevState) => (
          Object.assign({}, {
            ...prevState,
            checkpointName: genericSearch
          }))
        )
        setGenericSearch('')
        setGenericSearchApplied(false)
        setSearchApplied(true)
      }
      setIsOpenAdvanceSearch(!isOpenAdvanceSearch)
    }
  }, [checkpointsSearchDetail, genericSearch, isGenericSearchApplied, isModal, isOpenAdvanceSearch, isSearchMode, isSearchApplied, list, fetchCheckpoints, onToggleSearchModal])

  // ** Function to handle filter
  const handleFilter = useCallback(
    (value, isAdvanceSearchFormOpened) => {
      if (typingTimeout) {
        clearTimeout(typingTimeout)
      }

      setTypingTimeout(
        setTimeout(() => {
          resetCheckpointList()
          fetchCheckpoints({ page: 1, search: value, isGSFilter: true, checkpointName: isAdvanceSearchFormOpened ? value : "" })
        }, 1000)
      )
    },
    [genericSearch, fetchCheckpoints]
  )

  // ** Function to handle change of generic search filter
  const handleChangeGenericSearch = useCallback(
    (e) => {
      const { value } = e.target
      if (isOpenAdvanceSearch) {
        setCheckpointsSearchDetail(prevSearch => (
          {
            ...prevSearch,
            checkpointName: value
          }
        ))
      }
      setGenericSearch(value)
      const lengthOfSearch = value.trim().length
      if (
        (!!lengthOfSearch && lengthOfSearch >= 3) ||
        (!lengthOfSearch && !!genericSearch.length && isGenericSearchApplied)
      ) {
        handleFilter(value, isOpenAdvanceSearch)
      }
    },
    [genericSearch, isGenericSearchApplied, isOpenAdvanceSearch, handleFilter]
  )

  const handlePerPageRecords = useCallback(
    (event) => {
      const size = parseInt(event.target.value)
      resetCheckpointList()
      fetchCheckpoints({ page: 1, size })
    },
    [fetchCheckpoints]
  )

  const handleChangeCheckpointsDetails = useCallback(
    (key, value, callback = () => { }, label) => {
      const updateCheckpointsDetail = Object.assign({}, checkpointsSearchDetail)
      if (key === "assembly") {
        updateCheckpointsDetail["assemblyName"] = label
        updateCheckpointsDetail["assemblyType"] = []
        updateCheckpointsDetail["component"] = []
        updateCheckpointsDetail["subComponent"] = []
      }
      if (key === "assemblyType") {
        updateCheckpointsDetail["component"] = []
        updateCheckpointsDetail["subComponent"] = []
      }
      if (key === "manufacturer") {
        updateCheckpointsDetail["manufacturerName"] = label
      }
      if (key === "component") updateCheckpointsDetail["subComponent"] = []
      if (key === "checkpointName") {
        setGenericSearch(value)
      }
      updateCheckpointsDetail[key] = value
      setCheckpointsSearchDetail(updateCheckpointsDetail)
      callback(updateCheckpointsDetail)
    },
    [checkpointsSearchDetail]
  )

  const handleOnRowClicked = useCallback((data) => {
    if (!isRowClickable) {
      return null
    }

    //ToDo: remove conditional check after workflow is done
    if (columnConfigType === COLUMN_LIST_TYPE.WORKFLOW_ASSIGN_CHECKLIST_CHECKPOINTS) {
      return null
    }

    // ToDo: set activity type
    const { campaignServiceCheckpointId, checkpointId, checkpointVersionId, activityTypeId = '1' } = data
    if (isSearchMode && isModal) {
      return null
    }
    const { pathname } = window.location
    let navigateToRoute = `${ROUTES.checkpointsDetail.slice(0, ROUTES.checkpointsDetail.length - 10)}${checkpointId}/${checkpointVersionId}`
    if (pathname.includes(CHECKPOINT_ORIGNATED_FROM_TYPES.CAMPAIGN)) {
      //Navigate to ROUTES.checklistCheckpointDetail
      if (pathname.includes('campaigns/overview')) {
        if (!pathname.includes('checklist')) {
          const { checklistId } = checklistDetail
          navigateToRoute = `${pathname}/checklist/${checklistId}/activities/${checkpointId}/${checkpointVersionId}`
        } else {
          navigateToRoute = `${pathname}/activities/${checkpointId}/${checkpointVersionId}`
        }
      } else {
        try {
          const { campaignId, serviceId, checklistId } = checklistDetail
          const url = `${ROUTES.campaignOverview}/${campaignId}/service/${serviceId}/checklist/${checklistId}`
          navigateToRoute = `${url}/activities/${checkpointId}/${checkpointVersionId}`
        } catch (err) { }
      }
    } else {
      saveCurrentTemplate({ templateName: templateDetail.templateName, templateId: templateDetail.templateId })
    }
    let search = campaignServiceCheckpointId ? `?cs_cp_id=${campaignServiceCheckpointId}` : ''
    if (activityTypeId) {
      search = `${search}${search ? '&' : '?'}at=${activityTypeId}`
    }

    history.push({
      pathname: `${navigateToRoute}${search}`,
      state: {
        templateVersionId: templateDetail.templateVersionId,
        templateId: templateDetail.templateId,
        isTemplateOverview
      }
    })
  }, [isModal, isSearchMode, checklistDetail, templateDetail])

  const handleToggleTemplateCloneModal = useCallback((event) => {
    if (event) {
      event.preventDefault()
    }
    setOpenTemplateCloneModal(!isOpenTemplateCloneModal)
  }, [isOpenTemplateCloneModal])

  const handleConfirmTemplateClone = useCallback(() => {
    cloneTemplateRequest({
      templateId: templateDetail.templateId,
      templateVersionId: templateDetail.templateVersionId,
      cloneActivities: isActivityCloneRequired
    }, (res) => {
      if (res) {
        handleToggleTemplateCloneModal()
        const path = ROUTES.editTemplate
        const searchParams = `?tp_v=${res.templateVersionId}`
        history.push(`${path.slice(0, path.length - 11)}${res.templateId}${searchParams}`)
      }
    })
  }, [templateDetail, handleToggleTemplateCloneModal, isActivityCloneRequired])

  const handleToggleAddNewTemplateVersion = useCallback((event) => {
    if (event) {
      event.preventDefault()
    }
    setOpenNewTemplateVersionModal(!isOpenNewTemplateVersionModal)
  }, [isOpenNewTemplateVersionModal])

  const handleConfirmAddNewTemplateVersion = useCallback(() => {
    addNewTemplateVersion({
      templateId: templateDetail.templateId
    }, () => {
      onFetchTemplateVersionDDL(templateDetail.templateId)
      setOpenNewTemplateVersionModal(false)
    })
  }, [templateDetail, isOpenNewTemplateVersionModal, onFetchTemplateVersionDDL])

  const handleClearRows = useCallback(() => {
    setToggleClearRows(!isToggledClearRows)
  }, [isToggledClearRows])

  const handleResetSelectedRows = useCallback(() => {
    setSelectedCheckpointIdList([])
    setSelectedCheckpointList([])
    handleClearRows()
  }, [handleClearRows])

  const handleSaveList = useCallback((isToggleAfterSave = false) => {
    if (!selectedCheckpointIdList.length) {
      onToggleSearchModal()
      return null
    }

    const payload = {
      checkpointIds: selectedCheckpointIdList,
      isSaveAndContinue: !isToggleAfterSave
    }
    onSaveList(payload, () => {
      if (isToggleAfterSave) {
        onToggleSearchModal()
      }
      handleResetSelectedRows()
    })
  }, [
    selectedCheckpointIdList,
    handleResetSelectedRows,
    onSaveList,
    onToggleSearchModal
  ])

  const handleSaveAndClose = useCallback(() => {
    handleSaveList(true)
  }, [handleSaveList])

  const handleSaveAndContinue = useCallback(() => {
    handleSaveList()
  }, [handleSaveList])

  const handleSelectedRowsChange = useCallback((selectedRows) => {
    if (columnConfigType === COLUMN_LIST_TYPE.CHECKPOINTS_FOR_CHECKLIST || columnConfigType === COLUMN_LIST_TYPE.WORKFLOW_ASSIGN_CHECKLIST_CHECKPOINTS) {
      //Note: To handle delete bulk action for delete checkpoint from checklist,
      //selectedCheckpointIdList contains array of campaignServiceCheckpointId
      setShowBulkActionView(!!selectedRows.length)
      setSelectedCheckpointIdList(selectedRows.map(row => row.campaignServiceCheckpointId))
      setSelectedCheckpointList(selectedRows)
    } else {
      //Note: To handle row selection, When checkpoint table is used as child grid
      if (onSelectedRowsChange) {
        onSelectedRowsChange(selectedRows)
       setSelectedCheckpointList(selectedRows) //for selected activities in template modal
      } else {
        //todo if allSelected true for checkpoint table
        if (isChecklistModal) {
          //Note: To handle select checkpoint records in modal component,
          //selectedCheckpointIdList contains array of checkpointId
          setSelectedCheckpointIdList(selectedRows.map(row => row.checkpointId))
          setSelectedCheckpointList(selectedRows)
        }
      }
    }
  }, [columnConfigType, isChecklistModal, onSelectedRowsChange])

  const handleBulkDelete = useCallback(() => {
    if (columnConfigType === COLUMN_LIST_TYPE.CHECKPOINTS_FOR_CHECKLIST) {
      deleteCampaignServiceChecklistCheckpoint(selectedCheckpointIdList, () => {
        const { checklistId } = checklistDetail
        setShowBulkActionView(false)
        handleResetSelectedRows()
        const { pathname } = window.location
        if (pathname.includes('campaigns/overview')) {
          if (pathname.includes('service') && !pathname.includes('checklist')) {
            //Note: We can use this where we do not have advance search form
            history.replace({
              search: `?cp_updated=${checklistId}`
            })
          } else {
            //Note: If we have advance search form , we have to call common api function 
            refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: true })
          }
        } else {
          //Todo: refresh data properly for all opened checklists after bulk delete
          //Todo: Fixes to reset checkbox for all select in setup phase sevice-checklist-checkpoints
          onGetChecklistCheckpoints(checklistId)
          // refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: true })
        }
      })
    }
  }, [checklistDetail, columnConfigType, selectedCheckpointIdList, handleResetSelectedRows, onGetChecklistCheckpoints])

  const handleSortList = useCallback((column, sortDirection) => {
    if (column.sortKey) {
      const updatedSortKey = sortDirection ? column.sortKey : ""
      setSortOrderColumn(updatedSortKey)
      setSortOrderDirection(sortDirection)
      resetCheckpointList()
      fetchCheckpoints({
        orderColumn: updatedSortKey,
        orderDirections: sortDirection,
        isResetSortOrder: true
      })
    }
  }, [fetchCheckpoints])

  const handleViewByTemplateVersion = useCallback((key, value) => {
    if (!value && !viewByTemplateVersionId) {
      return false
    }
    setViewByTemplateVersionId(value)
    resetCheckpointList()
    fetchCheckpoints({ page: 1, templateVersionNumber: value, isViewByTemplateVersion: true })
  }, [viewByTemplateVersionId, fetchCheckpoints])

  // ** Function to get search results when enter key is pressed or user select some value
  // from dropdown in advance search form
  const handleCustomAdvanceSearch = useCallback(
    (event, isVMBasedSearch, data) => {
      if (event?.keyCode === 13) {
        handleAdvancedSearch()
      } else if (isVMBasedSearch) {
        handleAdvancedSearch(true, data)
      }
    },
    [handleAdvancedSearch]
  )

  const handleRowReorder = useCallback((event) => {
    if (event.type === "rowDragEnd") {
      const draggedCheckpointId = Number(event.node?.id || "")
      const updatedList = event.api.getRenderedNodes().map(({ data }) => data)
      const selectedActivityIndex = updatedList.findIndex(i => i.checkpointId === draggedCheckpointId)

      if (selectedActivityIndex !== -1) {
        const draggedActivity = updatedList[selectedActivityIndex]
        const prevActivity = updatedList[selectedActivityIndex - 1]
        const nextActivity = updatedList[selectedActivityIndex + 1]

        const payload = {
          templateCheckpointId: draggedActivity.templateCheckpointId,
          prev_node:  prevActivity?.templateCheckpointId || null,
          next_node: nextActivity?.templateCheckpointId || null
        }
        updateActivityOrderIndex(payload, (res) => {
          if (res) {
            if (!isChildGrid) {
              refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: true })
            } else {
              refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: true })
              refreshTableList({ listKey: TABLE_LIST_KEYS.CHECKLIST_KEY, value: true })
            }
          }
        })
      }     
    }
  }, [refreshTableList])

  const handleRemoveFilter = useCallback((key, data) => {
    if (key === QUICK_SEARCH_KEY) {
      setGenericSearch("")
      resetCheckpointList()
      fetchCheckpoints({ page: 1, search: "", isGSFilter: true, checkpointName: checkpointsSearchDetail.checkpointName })
    } else {
      if (key === "assemblyName") {
        data["assemblyType"] = []
        data["component"] = []
        data["subComponent"] = []
      }
      if (key === "assemblyType") {
        data["component"] = []
        data["subComponent"] = []
      }
      if (key === "component") {
        data["subComponent"] = []
      }

      setCheckpointsSearchDetail(data)
      handleAdvancedSearch(true, data)
    }
  }, [checkpointsSearchDetail.checkpointName, handleAdvancedSearch, fetchCheckpoints])

  const columns = useMemo(() => {
    return getColumnList({ type: columnConfigType, templateDetail, isCheckBoxSelectionNotRequired })
  }, [isCheckBoxSelectionNotRequired, columnConfigType, templateDetail])

  const handleAddActivity = useCallback((type) => {
    onAddCheckpoint(type)
  }, [onAddCheckpoint])

  const isRowDraggable = useMemo(() => {
    return (columnConfigType !== COLUMN_LIST_TYPE.WORKFLOW_ASSIGN_CHECKLIST_CHECKPOINTS && columnConfigType !== COLUMN_LIST_TYPE.SEARCH_FOR_CAMPAIGN)
  }, [columnConfigType])

  const tableComponentProps = useMemo(() => {
    const isTemplateOverviewDetail = isTemplateOverview && isViewMode && !isModal && !isSearchMode
    return {
      tableHeaderDropdownConfig: !isTemplateOverviewDetail ? undefined : {
        title: ACTIVITIES.ADD_ACTIVITY,
        menuTitle: ACTIVITIES.SELECT_TYPE,
        options: activityTypeSelectOptions,
        onSelect: handleAddActivity
      },
      isShowAddButton: false,
      isShowVersionButton: isTemplateOverviewDetail && !isPermissionDenied(PERMISSION_ROUTES.newVersionTemplate),
      isShowCloneButton: isTemplateOverviewDetail && !isPermissionDenied(PERMISSION_ROUTES.cloneTemplate),
      newVersionButtonLocaleId: NEW_VERSION,
      cloneButtonLocaleId: CLONE,
      isListTitleRequired,
      headerConfig,
      rowSelection: isModal && isSearchMode && ROW_SELECTION_TYPE.MULTIPLE,
      sortOrderColumn,
      sortOrderDirection,
      isRowDraggable,
      onRowDragEnd: handleRowReorder,
      ...childGridTableComponentProps
    }
  }, [isRowDraggable, headerConfig, isTemplateOverview, isViewMode, isModal, isSearchMode, isListTitleRequired, sortOrderColumn, sortOrderDirection, childGridTableComponentProps, handleRowReorder])

  const gridFilterProps = useMemo(() => {
    return {
      isShow: !isOpenAdvanceSearch,
      isSearchApplied,
      filtersConfig: gridFiltersConfig,
      filters: checkpointsSearchDetail,
      genericSearchFilter: appliedGenericSearch,
      initialFilterState: initialCheckpointDetail,
      onRemoveFilter: handleRemoveFilter
    }
  }, [checkpointsSearchDetail, appliedGenericSearch, isOpenAdvanceSearch, isSearchApplied, handleRemoveFilter])

  const noDataMessage = useMemo(() => {
    const { checkpointList: checkpointListCustomMessages } = TABLE_VIEW_CUSTOM_MESSAGES
    return (isPageLoading || isFetching)
      ? checkpointListCustomMessages.loading
      : (isSearchApplied || isGenericSearchApplied)
        ? checkpointListCustomMessages.noDataForFilters
        : checkpointListCustomMessages.noData
  }, [isPageLoading, isFetching, isSearchApplied, isGenericSearchApplied])

  const AdvanceSearchCard = () => {
    if (!isOpenAdvanceSearch) {
      return null
    }

    let modalProps = {}
    if (isModal) {
      modalProps = {
        isModal: true,
        isShowSystemDefinition: false,
        searchViewTitle: searchViewTitle || getLocaleMessage(intl, ACTIVITIES.SEARCH_ACTIVITIES)
      }
    }

    return (
      <AdvanceSearch
        checkpointCollection={checkpointsSearchDetail}
        control={control}
        isCampaignView={isChecklistModal}
        isViewMode
        isNewListTheme={isNewListTheme}
        onChangeCheckpointCollection={handleChangeCheckpointsDetails}
        onResetSearchDetail={handleResetSearchDetail}
        onSearch={handleAdvancedSearch}
        onToggle={handleToggleAdvanceSearch}
        onCustomAdvanceSearch={handleCustomAdvanceSearch}
        {...modalProps}
        {...advanceSearchConfig}
      />
    )
  }

  const SearchCard = () => {
    if (isModal) {
      return null
    }

    return AdvanceSearchCard()
  }

  const SearchModalCard = () => {
    if (!isModal || (!isOpenAdvanceSearch && isSearchMode)) {
      return null
    }

    return (
      <ModalBody className="p-0">
        <Row form className="template-form-row">
          <Col className="mb-1" sm={12} md={12} xl={12}>
            {AdvanceSearchCard()}
          </Col>
        </Row>
      </ModalBody>
    )
  }

  const TableSubHeaderComponent = () => {

    return (
      <>
        <div className="d-flex text-right align-items-center justify-content-md-end justify-content-center">
          {!isPermissionDenied(PERMISSION_ROUTES.newVersionTemplate) && <div className="text-right mr-1">
            <button className="outline-button new-version-btn pl-1 pr-1 " onClick={handleToggleAddNewTemplateVersion}>
              <div className="d-flex align-items-center"><PlusSquare size={13} /><span>{getLocaleMessage(intl, NEW_VERSION)}</span></div>
            </button>
          </div>}
          {!isPermissionDenied(PERMISSION_ROUTES.cloneTemplate) && <div className="text-right">
            <button className="outline-button new-version-btn pl-1 pr-1" onClick={handleToggleTemplateCloneModal}>
              <div className="d-flex align-items-center"><Copy size={13} /><span>{getLocaleMessage(intl, CLONE)}</span></div>
            </button>
          </div>}
        </div>
      </>
    )
  }

  const CheckpointList = () => {
    let listData = {
      items: list,
      pageNumber,
      pageSize,
      totalCount,
      totalPages
    }

    if (((isChildGrid && !isChecklistView) || isSearchMode)) {
      listData = tableData
    }

    return (
      <Card className={cx("p-2", {
        'border-0 box-shadow-0': isBorderNotRequired,
        " overview-list-card" : isTemplateOverview && isViewMode && !isModal && !isSearchMode
      })}>
        {isTemplateOverview && isViewMode && !isModal && !isSearchMode && <h2 className="section-title">{getLocaleMessage(intl, TEMPLATES.TEMPLATE_VERSION)}</h2>}
        {isTemplateOverview && isViewMode && !isModal && !isSearchMode && <Row className="border-bottom form-row pb-1">
          <Col className="template-version">
            <ReactSelect
              name="templateVersion"
              control={control}
              value={viewByTemplateVersionId}
              options={templateVersionDDL || []}
              placeHolder='Select Version'
              defaultValue={{ text: 'Select Version', value: "" }}
              onSelect={(selected) => {
                handleViewByTemplateVersion("templateVersion", selected?.value || '')
              }}
            />
          </Col>
          <Col>
          </Col>
        </Row>}
        <TableView
          className={cx(className, "activity-list")}
          selectableRows={isSelectableRows || isModal || isBulkActionRequired}
          isChildGrid={isChildGrid}
          titleWarpperClassname={titleWarpperClassname}
          clearSelectedRows={isChildGrid ? clearSelectedRows : isToggledClearRows}
          columns={columns}
          keyField={"checkpointId"} //Todo: keyField causes render issue when we have duplicate keyField values
          data={listData.items || []}
          showAgGrid
          isNewListTheme
          tableComponentProps={tableComponentProps}
          gridFilterProps={gridFilterProps}
          noTableDataClassRequired={!isChildGrid && !isSearchMode && !isChecklistView}
          expandableRows
          expandableRowsType={EXPANDABLE_TYPE.CHECKPOINTS}
          genericSearch={genericSearch}
          isShowBulkActions={(columnConfigType === COLUMN_LIST_TYPE.CHECKPOINTS_FOR_CHECKLIST || columnConfigType === COLUMN_LIST_TYPE.WORKFLOW_ASSIGN_CHECKLIST_CHECKPOINTS) && isShowBulkActionView}
          isLoading={isFetching}
          isModal={isModal}
          noDataMessage={noDataMessage}
          noDataMessageForSubTable={getLocaleMessage(intl, ACTIVITIES.NO_ACTIVITY_DETAIL_MSG)}
          pagination={pagination}
          pageNumber={listData.pageNumber}
          pageSize={listData.pageSize}
          sortServer
          showExpandedPagination={showExpandedPagination}
          tableViewHeaderRequired={tableViewHeaderRequired}
          tableSubHeader={tableSubHeader}
          title={isChecklistView ? checklistTitle : (isChildGrid || isSearchMode) ? getLocaleMessage(intl, ACTIVITIES.ACTIVITIES) : templateDetail?.templateName || getLocaleMessage(intl, ACTIVITIES.TEMPLATE_ACTIVITIES)}
          totalCount={listData.totalCount}
          totalPages={listData.totalPages}
          headerConfig={!isChecklistModal && !isSearchMode && isTemplateOverview ? {
            reactSelect: {
              id: "templateVersion",
              name: "templateVersion",
              control,
              value: viewByTemplateVersionId,
              defaultValue: { text: 'Select Version', value: "" },
              placeholder: 'Select Version',
              options: templateVersionDDL || [],
              onSelect: handleViewByTemplateVersion
            }
          } : {}}
          hasCloseAction={isModal}
          selectedRows={selectedCheckpointList}
          onAddVersion={handleToggleAddNewTemplateVersion}
          onBulkDelete={handleBulkDelete}
          onClone={handleToggleTemplateCloneModal}
          onClose={onToggleSearchModal}
          onRowClicked={handleOnRowClicked}
          onChangeSearchFilter={handleChangeGenericSearch}
          onPerPageRecords={handlePerPageRecords}
          onPagination={handlePagination}
          onToggleAdvanceSearch={handleToggleAdvanceSearch}
          onSaveAndClose={handleSaveAndClose}
          onSaveAndContiue={handleSaveAndContinue}
          onSelectedRowsChange={handleSelectedRowsChange}
          onSort={handleSortList}
          TableHeaderComponent={TableSubHeaderComponentProp || (!isSearchMode && isTemplateOverview ? TableSubHeaderComponent : () => { return null })}
          {...childGridProps}
        >
          {children}
        </TableView>
      </Card>
    )
  }

  const ListCard = () => {
    if (isModal && !isChildGrid) {
      return null
    }

    return (
      <>
        {CheckpointList()}
      </>
    )
  }

  const ListModalCard = () => {
    if ((!isModal && isChildGrid) || (!isSearchApplied && !isGenericSearchApplied) || !isChecklistModal) {
      return null
    }

    return (
      <ModalBody className="p-0">
        <Row form={!isSearchMode}>
          <Col className="mb-1 pl-0 pr-0" sm={12} md={12} xl={12}>
            {CheckpointList()}
          </Col>
        </Row>
      </ModalBody>
    )
  }

  return (
    <>
      {SearchCard()}
      {SearchModalCard()}
      {ListCard()}
      {ListModalCard()}
      <ConfirmationModal
        open={isOpenNewTemplateVersionModal}
        name={templateDetail.templateName || getLocaleMessage(intl, TEMPLATES.TEMPLATE)}
        title={getLocaleMessage(intl, NEW_VERSION_TITLE)}
        onConfirm={handleConfirmAddNewTemplateVersion}
        onToggleModal={handleToggleAddNewTemplateVersion}
      />
      <ConfirmationModal
        open={isOpenTemplateCloneModal}
        name={templateDetail.templateName || getLocaleMessage(intl, TEMPLATES.TEMPLATE)}
        title={getLocaleMessage(intl, CLONE_TITLE)}
        onConfirm={handleConfirmTemplateClone}
        onToggleModal={handleToggleTemplateCloneModal}
        isFooterChildren
      >
        <CustomLabel title={CLONE_ACTIVITIES} />
        <ToggleButton
          className="custom-control-secondary"
          id={`template-clone-activities`}
          name={`template-clone-activities`}
          value={isActivityCloneRequired}
          onChange={handleCloneActivities}
        />
      </ConfirmationModal>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNewTemplateVersion: (payload, callback) => dispatch(actions.addNewTemplateVersionRequest(payload, callback)),
    cloneTemplateRequest: (payload, callback) => dispatch(actions.cloneTemplateRequest(payload, callback)),
    deleteCampaignServiceChecklistCheckpoint: (payload, callback) => dispatch(actions.deleteCampaignServiceChecklistCheckpoint(payload, callback)),
    getTemplateCheckpointList: (payload, callback) => dispatch(actions.getTemplateCheckpointListRequest(payload, callback)),
    getCampaignCheckpointList: (payload, callback) => dispatch(actions.getCampaignServiceChecklistCheckpointListRequest(payload, callback)),
    resetCheckpointList: () => dispatch(actions.resetCheckpointList()),
    saveCurrentTemplate: (payload) => dispatch(actions.saveCurrentTemplate(payload)),
    refreshTableList: (payload) => dispatch(actions.refreshTableList(payload)),
    updateActivityOrderIndex: (payload, callback) => dispatch(actions.updateActivityOrderIndexRequest(payload, callback))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(localeMessageWrapper(Checkpoints)))
