import {useMemo} from 'react'
import * as Icon from 'react-feather'
import PropTypes from 'prop-types'
import {getMenuIcon} from '@layouts/utils'

const SideBarIcon = ({ icon: { iconName = '', className = '', style = '' } }) => {

  const icon = useMemo(
    () => getMenuIcon(iconName),
    [iconName]
  )

  const TabIcon = iconName ? Icon[iconName] : ''

  if (!TabIcon) {
    return null
  }

 return icon ? <img src={icon} /> : <TabIcon className={className || ''} size={style ? parseInt(style) : ''} />
}

SideBarIcon.propTypes = {
  icon: PropTypes.object
}

export default SideBarIcon