import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addOrganizationPlanBlueprint : To add new Blueprint
 * @param {*} data 
 * 
 * Payload:
 * 
 * {
  "organizationPlanId": 0,
  "bluePrintName": "string"
}
 * 
 * @returns 
 */
export const addOrganizationPlanBlueprint = (data) => {
  const { url } = config.addOrganizationPlanBlueprint
  return postDataApi(url(data), data, undefined, { pathConfig: { isCheckpointInspectionService: true } })
}

export default addOrganizationPlanBlueprint
