import { getActionType } from '../action-type-generator'

export const DELETE_ASSIGNED_TASK_REQUEST = 'DELETE_ASSIGNED_TASK_REQUEST'
export const GET_SCHEDULE_VM = getActionType('GET_SCHEDULE_VM')
export const GET_SCHEDULE_ASSIGN_VM = getActionType('GET_SCHEDULE_ASSIGN_VM')
export const GET_SCHEDULING_LIST_REQUEST = 'GET_SCHEDULING_LIST_REQUEST'
export const GET_SCHEDULING_LIST_TASKS_REQUEST = 'GET_SCHEDULING_LIST_TASKS_REQUEST'
export const GET_SCHEDULING_CONTACT_CALENDAR_LIST = 'GET_SCHEDULING_CONTACT_CALENDAR_LIST'
export const GET_TASK_HISTORY_DETAIL_REQUEST = 'GET_TASK_HISTORY_DETAIL_REQUEST'
export const GET_TASK_HISTORY_LIST_REQUEST = 'GET_TASK_HISTORY_LIST_REQUEST'
export const GET_TASK_HISTORY_PENDING_UNIT_LIST_REQUEST = 'GET_TASK_HISTORY_PENDING_UNIT_LIST_REQUEST'
export const UPDATE_TASK_STATUS_REQUEST = 'UPDATE_TASK_STATUS_REQUEST'
export const SCHEDULE_TASK_REQUEST = 'SCHEDULE_TASK_REQUEST'
export const GET_CONTACT_TASK_LIST_REQUEST = 'GET_CONTACT_TASK_LIST_REQUEST'
export const SET_SELECTED_SITE_UNIT_LIST = 'SET_SELECTED_SITE_UNIT_LIST'
export const SET_TOGGLE_ASSIGN_CONTACT_MODE = 'SET_TOGGLE_ASSIGN_CONTACT_MODE'
export const REMOVE_SELECTED_SITE_UNIT = 'REMOVE_SELECTED_SITE_UNIT'
export const SET_SITE_EXPANDED_ROW_ID = 'SET_SITE_EXPANDED_ROW_ID'
export const CLEAR_SELECTED_CHILD_ROWS = 'CLEAR_SELECTED_CHILD_ROWS'
export const SET_SITE_TASK_MODE = 'SET_SITE_TASK_MODE'
export const REFRESH_SITE_CHILD_GRID = 'REFRESH_SITE_CHILD_GRID'
export const GET_ASSIGNED_TASK_CONTACT_VM_REQUEST = 'GET_ASSIGNED_TASK_CONTACT_VM_REQUEST'
export const GET_ASSIGNED_TASK_CONTACT_LIST_DDL_REQUEST = 'GET_ASSIGNED_TASK_CONTACT_LIST_DDL_REQUEST'
export const UPDATE_CONTACT_ASSIGNED_TASK_REQUEST = 'UPDATE_CONTACT_ASSIGNED_TASK_REQUEST'
export const GET_ASSIGNMENT_OBSERVATION_TASK_LIST_REQUEST = 'GET_ASSIGNMENT_OBSERVATION_TASK_LIST_REQUEST'
export const UPDATE_ASSIGNMENT_ACTIVITIES_REQUEST = 'UPDATE_ASSIGNMENT_ACTIVITIES_REQUEST'
export const DOWNLOAD_ASSIGNMENT_ACTIVITIES_REQUEST = 'DOWNLOAD_ASSIGNMENT_ACTIVITIES_REQUEST'
export const GET_ASSIGNED_CONTACTS_DDL_REQUEST = 'GET_ASSIGNED_CONTACTS_DDL_REQUEST'
export const SET_TASK_CONTEXT_MENU_ACTION_MODE = 'SET_TASK_CONTEXT_MENU_ACTION_MODE'
export const GET_ASSIGNED_TASK_DETAIL_REQUEST = 'GET_ASSIGNED_TASK_DETAIL_REQUEST'
export const GET_ASSIGNED_CONTACT_LIST_REQUEST = 'GET_ASSIGNED_CONTACT_LIST_REQUEST'
export const GET_OBSERVATION_TASK_LIST_SCHEDULE_REQUEST = 'GET_OBSERVATION_TASK_LIST_SCHEDULE_REQUEST'
export const GET_ASSIGNMENT_UNITS_LIST_REQUEST = 'GET_ASSIGNMENT_UNITS_LIST_REQUEST'
export const GET_ASSIGNMENT_OBSERVATION_TASKS_LIST_REQUEST = 'GET_ASSIGNMENT_OBSERVATION_TASKS_LIST_REQUEST'
export const GET_ASSIGNMENT_ORGANIZATIONS_LIST_REQUEST = 'GET_ASSIGNMENT_ORGANIZATIONS_LIST_REQUEST'
export const  GET_ASSIGNMENT_SITES_LIST_REQUEST = ' GET_ASSIGNMENT_SITES_LIST_REQUEST'
export const  GET_ASSIGNMENT_ROLES_LIST_REQUEST = ' GET_ASSIGNMENT_ROLES_LIST_REQUEST'
export const GET_SCHEDULING_CONTACT_LIST_REQUEST = 'GET_SCHEDULING_CONTACT_LIST_REQUEST'
export const GET_READY_STATUS_COUNT_WIDGET_REQUEST = "GET_READY_STATUS_COUNT_WIDGET_REQUEST"
export const GET_ASSIGNED_TASK_COUNT_WIDGET_REQUEST = "GET_ASSIGNED_TASK_COUNT_WIDGET_REQUEST"
export const GET_RESCHEDULE_TASK_COUNT_WIDGET_REQUEST = "GET_RESCHEDULE_TASK_COUNT_WIDGET_REQUEST"
export const GET_UNIT_SITE_PLAN_VM = "GET_UNIT_SITE_PLAN_VM"
export const GET_SITE_PLANS_WITH_VERSION_VM = "GET_SITE_PLANS_WITH_VERSION_VM"
export const GET_NOT_READY_REASON_VM_REQUEST = "GET_NOT_READY_REASON_VM_REQUEST"
export const SET_WORK_ORDER_REQUEST = "SET_WORK_ORDER_REQUEST"
export const GET_WORK_ORDER_REQUEST = "GET_WORK_ORDER_REQUEST"
export const UPDATE_WORK_ORDER_REQUEST = "UPDATE_WORK_ORDER_REQUEST"
export const GET_IS_WORK_ORDER_NEEDED = getActionType('GET_IS_WORK_ORDER_NEEDED')
export const SET_SCHEDULE_PENDING_ASSIGNMENT_REQUEST = "SET_SCHEDULE_PENDING_ASSIGNMENT_REQUEST"
export const GET_TASK_CONTRACT_LIST_REQUEST = 'GET_TASK_CONTRACT_LIST_REQUEST'
export const GET_TASK_CONTRACT_MISSING_ITEMS_COUNT_REQUEST = 'GET_TASK_CONTRACT_MISSING_ITEMS_COUNT_REQUEST'
export const GET_PURCHASE_ORDER_LIST_REQUEST = 'GET_PURCHASE_ORDER_LIST_REQUEST'
export const GET_PURCHASE_ORDER_VM_REQUEST = 'GET_PURCHASE_ORDER_VM_REQUEST'
export const GET_COST_CATEGORY_DDL_REQUEST = 'GET_COST_CATEGORY_DDL_REQUEST'
export const ADD_ADDITIONAL_PURCHASE_ORDER_REQUEST = 'ADD_ADDITIONAL_PURCHASE_ORDER_REQUEST'
export const UPDATE_ADDITIONAL_PURCHASE_ORDER_REQUEST = 'UPDATE_ADDITIONAL_PURCHASE_ORDER_REQUEST'
export const GET_ADDITIONAL_PURCHASE_ORDER_REQUEST = 'GET_ADDITIONAL_PURCHASE_ORDER_REQUEST'
export const DELETE_ADDITIONAL_PURCHASE_ORDER_REQUEST = 'DELETE_ADDITIONAL_PURCHASE_ORDER_REQUEST'
export const UPDATE_PRIMARY_PURCHASE_ORDER_REQUEST = 'UPDATE_PRIMARY_PURCHASE_ORDER_REQUEST'
export const REMOVE_ASSIGNMENT_REQUEST = 'REMOVE_ASSIGNMENT_REQUEST'
export const REMOVE_SCHEDULE_DATE_TIME_REQUEST = 'REMOVE_SCHEDULE_DATE_TIME_REQUEST'
export const GET_ON_HOLD_TASK_LIST_REQUEST = 'GET_ON_HOLD_TASK_LIST_REQUEST'
export const GET_ON_HOLD_DEPENDENCY_DETAIL_REQUEST = 'GET_ON_HOLD_DEPENDENCY_DETAIL_REQUEST'
export const UPDATE_ON_HOLD_RECHECK_REQUEST = 'UPDATE_ON_HOLD_RECHECK_REQUEST'
export const UPDATE_ON_HOLD_CANCEL_REQUEST = 'UPDATE_ON_HOLD_CANCEL_REQUEST'
export const UPDATE_ON_HOLD_OVERRIDE_REQUEST = 'UPDATE_ON_HOLD_OVERRIDE_REQUEST'
export const REOPEN_TASK_REQUEST = 'REOPEN_TASK_REQUEST'
export const REASSIGN_TASK_REVIEWER_REQUEST = 'REASSIGN_TASK_REVIEWER_REQUEST'
export const GET_SCHEDULED_EARLIEST_TIME_REQUEST = 'GET_SCHEDULED_EARLIEST_TIME_REQUEST'
export const GET_SCHEDULING_REQUIRED_SKILLS_MARKETS = 'GET_SCHEDULING_REQUIRED_SKILLS_MARKETS'
export const UPDATE_CLOSE_TASK_MANUALLY_REQUEST = 'UPDATE_CLOSE_TASK_MANUALLY_REQUEST'

/****************************************************/
/** GET_SCHEDULE_VM **/
/****************************************************/

/**
 * @method getScheduleVMRequest :  To get schedule VM request
 *
 */
export const getScheduleVMRequest = (payload) => {
  return {
    type: GET_SCHEDULE_VM.REQUEST,
    payload
  }
}

/**
 * @method getScheduleVMSuccess :  To get schedule VM on success
 *
 */
export const getScheduleVMSuccess = (data) => {
  return {
    type: GET_SCHEDULE_VM.SUCCESS,
    data
  }
}

/**
 * @method getScheduleVMFailure :  To set error on getting schedule VM failure
 *
 */
export const getScheduleVMFailure = (error) => {
  return {
    type: GET_SCHEDULE_VM.FAILURE,
    error
  }
}

/****************************************************/
/** GET_SCHEDULE_ASSIGN_VM **/
/****************************************************/

/**
 * @method getScheduleAssignVMRequest :  To get schedule assign VM request
 *
 */
export const getScheduleAssignVMRequest = (payload) => {
  return {
    type: GET_SCHEDULE_ASSIGN_VM.REQUEST,
    payload
  }
}

/**
 * @method getScheduleAssignVMSuccess :  To get schedule assign VM on success
 *
 */
export const getScheduleAssignVMSuccess = (data) => {
  return {
    type: GET_SCHEDULE_ASSIGN_VM.SUCCESS,
    data
  }
}

/**
 * @method getScheduleAssignVMFailure :  To set error on getting schedule assign VM failure
 *
 */
export const getScheduleAssignVMFailure = (error) => {
  return {
    type: GET_SCHEDULE_ASSIGN_VM.FAILURE,
    error
  }
}

/****************************************************/
/** GET_SCHEDULING_LIST **/
/****************************************************/

/**
 * @method getSchedulingListRequest :  To get scheduling list request
 *
 */
export const getSchedulingListRequest = (payload, callback) => {
  return {
    type: GET_SCHEDULING_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SCHEDULING_LIST_TASKS_REQUEST **/
/****************************************************/

/**
 * @method getSchedulingListTasksRequest :  To get scheduling list tasks
 *
 */
export const getSchedulingListTasksRequest = (payload, callback) => {
  return {
    type: GET_SCHEDULING_LIST_TASKS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SCHEDULING_CONTACT_CALENDAR_LIST **/
/****************************************************/
/**
 * @method getSchedulingContactCalendarList : To get scheduling contact calendar list
 *
 */

export const getSchedulingContactCalendarList = (payload, callback) => {
  return {
    type: GET_SCHEDULING_CONTACT_CALENDAR_LIST,
    payload,
    callback
  }
}

/** DELETE_ASSIGNED_TASK **/
/****************************************************/

/**
 * @method deleteAssignedTaskRequest :  To delete assigned task request
 *
 */
export const deleteAssignedTaskRequest = (payload, callback) => {
  return {
    type: DELETE_ASSIGNED_TASK_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_HISTORY_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method getTaskHistoryDetailRequest :  To get task history detail
 *
 */
export const getTaskHistoryDetailRequest = (payload, callback = () => { }) => {
  return {
    type: GET_TASK_HISTORY_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_HISTORY_LIST_REQUEST **/
/****************************************************/

/**
 * @method getTaskHistoryListRequest :  To get task history list
 *
 */
export const getTaskHistoryListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_TASK_HISTORY_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_HISTORY_PENDING_UNIT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getTaskHistoryPendingUnitListRequest :  To get task history pending unit list
 *
 */
export const getTaskHistoryPendingUnitListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_TASK_HISTORY_PENDING_UNIT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_TASK_STATUS_REQUEST **/
/****************************************************/

/**
 * @method updateTaskStatusRequest :  To update task status
 *
 */
export const updateTaskStatusRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_TASK_STATUS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SCHEDULE_TASK **/
/****************************************************/

/**
 * @method scheduleTaskRequest :  To schedule task request
 *
 */
export const scheduleTaskRequest = (payload, callback) => {
  return {
    type: SCHEDULE_TASK_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CONTACT_TASK_LIST **/
/****************************************************/

/**
 * @method getContactTaskListRequest :  To get contact task list
 *
 */
export const getContactTaskListRequest = (payload, callback) => {
  return {
    type: GET_CONTACT_TASK_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SET_TOGGLE_ASSIGN_CONTACT_MODE **/
/****************************************************/

/**
 * @method setToggleAssignContactMode: To set assign contact mode for selected pending unit list
 *
 */
export const setToggleAssignContactMode = (data) => {
  return {
    type: SET_TOGGLE_ASSIGN_CONTACT_MODE,
    data
  }
}

/****************************************************/
/** SET_SELECTED_SITE_UNIT_LIST **/
/****************************************************/

/**
 * @method setSelectedSiteUnitList :  To set selected site unit list
 *
 */
export const setSelectedSiteUnitList = (data) => {
  return {
    type: SET_SELECTED_SITE_UNIT_LIST,
    data
  }
}

/****************************************************/
/** REMOVE_SELECTED_SITE_UNIT **/
/****************************************************/

/**
 * @method removeSelectedSiteUnit :  To remove selected site unit from selected list
 *
 */
export const removeSelectedSiteUnit = (payload) => {
  return {
    type: REMOVE_SELECTED_SITE_UNIT,
    payload
  }
}

/****************************************************/
/** SET_SITE_EXPANDED_ROW_ID **/
/****************************************************/

/**
 * @method setSiteExpandedRowId :  To set site expanded row id
 *
 */
export const setSiteExpandedRowId = (payload) => {
  return {
    type: SET_SITE_EXPANDED_ROW_ID,
    payload
  }
}

/****************************************************/
/** CLEAR_SELECTED_CHILD_ROWS **/
/****************************************************/

/**
 * @method onClearSelectedChildRows :  To on clear selected child rows
 *
 */
export const onClearSelectedChildRows = (payload) => {
  return {
    type: CLEAR_SELECTED_CHILD_ROWS,
    payload
  }
}

/****************************************************/
/** SET_SITE_TASK_MODE **/
/****************************************************/

/**
 * @method setSiteTaskMode :  To set task mode
 *
 */
export const setSiteTaskMode = (payload) => {
  return {
    type: SET_SITE_TASK_MODE,
    payload
  }
}

/****************************************************/
/** REFRESH_SITE_CHILD_GRID **/
/****************************************************/

/**
 * @method refreshSiteChildGrid :  To refresh site child grid
 *
 */
export const refreshSiteChildGrid = (payload) => {
  return {
    type: REFRESH_SITE_CHILD_GRID,
    payload
  }
}

/****************************************************/
/** GET_ASSIGNED_TASK_CONTACT_VM **/
/****************************************************/

/**
 * @method getAssignedTaskContactVMRequest :  To get assigned task contact vm
 *
 */
export const getAssignedTaskContactVMRequest = (payload, callback) => {
  return {
    type: GET_ASSIGNED_TASK_CONTACT_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ASSIGNED_TASK_CONTACT_LIST_DDL **/
/****************************************************/

/**
 * @method getAssignedTaskContactListDDLRequest :  To get assigned task contact list
 *
 */
export const getAssignedTaskContactListDDLRequest = (payload, callback) => {
  return {
    type: GET_ASSIGNED_TASK_CONTACT_LIST_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ASSIGNMENT_OBSERVATION_TASK_LIST_REQUEST **/
/****************************************************/

/**
 * @method getAssignmentObservationTaskListRequest :  To get list of assignment observation tasks
 *
 */
export const getAssignmentObservationTaskListRequest = (payload, callback) => {
  return {
    type: GET_ASSIGNMENT_OBSERVATION_TASK_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_ASSIGNMENT_ACTIVITIES_REQUEST **/
/****************************************************/

/**
 * @method updateAssignmentActivitiesRequest :  To update assignment activities
 *
 */
export const updateAssignmentActivitiesRequest = (payload, callback) => {
  return {
    type: UPDATE_ASSIGNMENT_ACTIVITIES_REQUEST,
    payload,
    callback
  }
}


/****************************************************/
/** ASSIGNMENT_PDF_DOWNLOAD_REQUEST **/
/****************************************************/

/**
 * @method assignmentPDFDownloadRequest :  To download assignment activities
 *
 */
export const assignmentPDFDownloadRequest = (payload, callback) => {
  return {
    type: DOWNLOAD_ASSIGNMENT_ACTIVITIES_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ASSIGNED_CONTACTS_DDL_REQUEST **/
/****************************************************/

/**
 * @method getAssignedContactsDDLRequest : To Get Paginated pointOfContact DDL without depending on organization Id
 *
 */
export const getAssignedContactsDDLRequest = (payload, callback) => {
  return {
    type: GET_ASSIGNED_CONTACTS_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SET_TASK_CONTEXT_MENU_ACTION_MODE **/
/****************************************************/

/**
 * @method setTaskContextMenuActionMode : To trigger context menu action for scheduling task list
 *
 */
export const setTaskContextMenuActionMode = (value) => {
  return {
    type: SET_TASK_CONTEXT_MENU_ACTION_MODE,
    payload: value
  }
}

/****************************************************/
/** GET_ASSIGNED_TASK_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method getAssignedTaskDetailRequest : To Get assigned task detail
 *
 */
export const getAssignedTaskDetailRequest = (payload, callback) => {
  return {
    type: GET_ASSIGNED_TASK_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ASSIGNED_CONTACT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getAssignedContactListRequest : To Get assigned contact list 
 *
 */
export const getAssignedContactListRequest = (payload, callback) => {
  return {
    type: GET_ASSIGNED_CONTACT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_OBSERVATION_TASK_LIST_SCHEDULE **/
/****************************************************/

/**
 * @method getObservationTaskListScheduleRequest :  To get observation task scheduling list request
 *
 */
export const getObservationTaskListScheduleRequest = (payload, callback = () => { }) => {
  return {
    type: GET_OBSERVATION_TASK_LIST_SCHEDULE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ASSIGNMENT_UNITS_LIST **/
/****************************************************/

/**
 * @method getAssignmentUnitsListRequest :  To get assignment units list request
 *
 */
export const getAssignmentUnitsListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ASSIGNMENT_UNITS_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ASSIGNMENT_OBSERVATION_TASKS_LIST **/
/****************************************************/

/**
 * @method getAssignmentObservationTasksListRequest :  To get assignment tasks list request
 *
 */
export const getAssignmentObservationTasksListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ASSIGNMENT_OBSERVATION_TASKS_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ASSIGNMENT_ORGANIZATIONS_LIST **/
/****************************************************/

/**
 * @method getAssignmentOrganizationsListRequest :  To get assignment organizations list request
 *
 */
export const getAssignmentOrganizationsListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ASSIGNMENT_ORGANIZATIONS_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ASSIGNMENT_SITES_LIST **/
/****************************************************/

/**
 * @method getAssignmentSitesListRequest :  To get assignment sites list request
 *
 */
export const getAssignmentSitesListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ASSIGNMENT_SITES_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ASSIGNMENT_ROLES_LIST **/
/****************************************************/

/**
 * @method getAssignmentRolesListRequest :  To get assignment roles list request
 *
 */
export const getAssignmentRolesListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ASSIGNMENT_ROLES_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SCHEDULING_CONTACT_LIST **/
/****************************************************/

/**
 * @method getSchedulingContactListRequest :  To get scheduling contact list request
 *
 */
export const getSchedulingContactListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_SCHEDULING_CONTACT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_READY_STATUS_COUNT_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getReadyStatusCountWidgetRequest :  To get scheduling ready status count widget
 *
 */
export const getReadyStatusCountWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_READY_STATUS_COUNT_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ASSIGNED_TASK_COUNT_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getAssignedTaskCountWidgetRequest :  To get scheduling assigned task count widget
 *
 */
export const getAssignedTaskCountWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ASSIGNED_TASK_COUNT_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_RESCHEDULE_TASK_COUNT_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getRescheduleTaskCountWidgetRequest :  To get scheduling reschedule task count widget
 *
 */
export const getRescheduleTaskCountWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_RESCHEDULE_TASK_COUNT_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_UNIT_SITE_PLAN_VM **/
/****************************************************/

/**
 * @method getUnitSitePlanVmRequest :  To get unit  site plan VM
 *
 */
export const getUnitSitePlanVMRequest = (payload, callback = () => { }) => {
  return {
    type: GET_UNIT_SITE_PLAN_VM,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SITE_PLANS_WITH_VERSION_VM **/
/****************************************************/

/**
 * @method getSitePlansWithVersionVMRequest :  To get site plans with version VM
 *
 */
export const getSitePlansWithVersionVMRequest = (payload, callback = () => { }) => {
  return {
    type: GET_SITE_PLANS_WITH_VERSION_VM,
    payload,
    callback
  }
}

/****************************************************/
/** GET_NOT_READY_REASON_VM_REQUEST **/
/****************************************************/

/**
 * @method getNotReadyReasonVMRequest :  To get not ready reason vm
 *
 */
export const getNotReadyReasonVMRequest = (payload, callback = () => { }) => {
  return {
    type: GET_NOT_READY_REASON_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_WORK_ORDER_REQUEST **/
/****************************************************/

/**
 * @method getWorkOrderRequest :  To get Work Order request
 *
 */
export const getWorkOrderRequest = (payload, callback) => {
  return {
    type: GET_WORK_ORDER_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SET_WORK_ORDER_REQUEST **/
/****************************************************/

/**
 * @method setWorkOrderRequest :  To Create Work Order Details
 *
 */
export const setWorkOrderRequest = (payload, callback = () => { }) => {
  return {
    type: SET_WORK_ORDER_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_WORK_ORDER_REQUEST **/
/****************************************************/

/**
 * @method updateWorkOrderRequest :  To update Work Order Details
 *
 */
export const updateWorkOrderRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_WORK_ORDER_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_IS_WORK_ORDER_NEEDED **/
/****************************************************/

/**
 * @method getIsWorkOrderNeededRequest :  to get iswork order needed Request
 *
 */
export const getIsWorkOrderNeededRequest = () => {
  return {
    type: GET_IS_WORK_ORDER_NEEDED.REQUEST
  }
}

/**
 * @method getIsWorkOrderNeededSuccess :  To get get is work order needed on success
 *
 */
export const getIsWorkOrderNeededSuccess = (data) => {
  return {
    type: GET_IS_WORK_ORDER_NEEDED.SUCCESS,
    data
  }
}

/****************************************************/
/** SET_SCHEDULE_PENDING_ASSIGNMENT **/
/****************************************************/
/**
 * @method setSchedulePendingAssignmentRequest :  To set schedule date and time.
 *
 */
export const setSchedulePendingAssignmentRequest = (payload, callback = () => { }) => {
  return {
    type: SET_SCHEDULE_PENDING_ASSIGNMENT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_CONTRACT_LIST **/
/****************************************************/

/**
 * @method getTaskContractListRequest :  To get task contract list scheduling request
 *
 */
export const getTaskContractListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_TASK_CONTRACT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_CONTRACT_MISSING_ITEMS_COUNT **/
/****************************************************/

/**
 * @method getTaskContractMissingItemsCountRequest :  To get task contract Missing items count
 *
 */
export const getTaskContractMissingItemsCountRequest = (payload, callback = () => { }) => {
  return {
    type: GET_TASK_CONTRACT_MISSING_ITEMS_COUNT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PURCHASE_ORDER_LIST_REQUEST **/
/****************************************************/

/**
 * @method getPurchaseOrderListRequest : To Get purchase order list
 *
 */
export const getPurchaseOrderListRequest = (payload, callback) => {
  return {
    type: GET_PURCHASE_ORDER_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PURCHASE_ORDER_VM_REQUEST **/
/****************************************************/

/**
 * @method getPurchaseOrderVMRequest : To Get purchase order vm
 *
 */
export const getPurchaseOrderVMRequest = (payload, callback) => {
  return {
    type: GET_PURCHASE_ORDER_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_COST_CATEGORY_DDL_REQUEST **/
/****************************************************/

/**
 * @method getCostCategoryDDLRequest : To Get cost category ddl
 *
 */
export const getCostCategoryDDLRequest = (payload, callback) => {
  return {
    type: GET_COST_CATEGORY_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_ADDITIONAL_PURCHASE_ORDER_REQUEST **/
/****************************************************/

/**
 * @method addAdditionalPurchaseOrderRequest :  To add additional purchase order Details
 *
 */
export const addAdditionalPurchaseOrderRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_ADDITIONAL_PURCHASE_ORDER_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_ADDITIONAL_PURCHASE_ORDER_REQUEST **/
/****************************************************/

/**
 * @method updateAdditionalPurchaseOrderRequest :  To update additional purchase order Details
 *
 */
export const updateAdditionalPurchaseOrderRequest = (payload, callback) => {
  return {
    type: UPDATE_ADDITIONAL_PURCHASE_ORDER_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ADDITIONAL_PURCHASE_ORDER_REQUEST **/
/****************************************************/

/**
 * @method getAdditionalPurchaseOrderRequest :  To get additional purchase order Details
 *
 */
export const getAdditionalPurchaseOrderRequest = (payload, callback) => {
  return {
    type: GET_ADDITIONAL_PURCHASE_ORDER_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_ADDITIONAL_PURCHASE_ORDER_REQUEST **/
/****************************************************/

/**
 * @method deleteAdditionalPurchaseOrderRequest :  To delete additional purchase order Details
 *
 */
export const deleteAdditionalPurchaseOrderRequest = (payload, callback) => {
  return {
    type: DELETE_ADDITIONAL_PURCHASE_ORDER_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PRIMARY_PURCHASE_ORDER_REQUEST **/
/****************************************************/

/**
 * @method updatePrimaryPurchaseOrderRequest :  To update primary purchase order Details
 *
 */
export const updatePrimaryPurchaseOrderRequest = (payload, callback) => {
  return {
    type: UPDATE_PRIMARY_PURCHASE_ORDER_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** REMOVE_ASSIGNMENT_REQUEST **/
/****************************************************/

/**
 * @method removeAssignmentRequest :  To remove assignee from task
 *
 */
export const removeAssignmentRequest = (payload, callback) => {
  return {
    type: REMOVE_ASSIGNMENT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** REMOVE_SCHEDULE_DATE_TIME_REQUEST **/
/****************************************************/

/**
 * @method removeScheduleDateTimeRequest :  To remove schedule date and time
 *
 */
export const removeScheduleDateTimeRequest = (payload, callback) => {
  return {
    type: REMOVE_SCHEDULE_DATE_TIME_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ON_HOLD_TASK_LIST_REQUEST **/
/****************************************************/

/**
 * @method getOnHoldTaskListRequest :  To get on hold task list Details to check for on hold due to Precondition on task or Purchase order details required
 *
 */
export const getOnHoldTaskListRequest = (payload, callback) => {
  return {
    type: GET_ON_HOLD_TASK_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ON_HOLD_DEPENDENCY_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method getOnHoldDependencyDetailRequest :  To get on hold dependency Details
 *
 */
export const getOnHoldDependencyDetailRequest = (payload, callback) => {
  return {
    type: GET_ON_HOLD_DEPENDENCY_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_ON_HOLD_RECHECK_REQUEST **/
/****************************************************/

/**
 * @method updateOnHoldRecheckRequest :  To update on hold recheck
 *
 */
export const updateOnHoldRecheckRequest = (payload, callback) => {
  return {
    type: UPDATE_ON_HOLD_RECHECK_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_ON_HOLD_CANCEL_REQUEST **/
/****************************************************/

/**
 * @method updateOnHoldCancelRequest :  To update on hold cancel
 *
 */
export const updateOnHoldCancelRequest = (payload, callback) => {
  return {
    type: UPDATE_ON_HOLD_CANCEL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_ON_HOLD_OVERRIDE_REQUEST **/
/****************************************************/

/**
 * @method updateOnHoldOverrideRequest :  To update on hold override
 *
 */
export const updateOnHoldOverrideRequest = (payload, callback) => {
  return {
    type: UPDATE_ON_HOLD_OVERRIDE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** REASSIGN_TASK_REVIEWER_REQUEST **/
/****************************************************/

/**
 * @method reassignTaskReviewerRequest :  To reassign reviewer of task
 *
 */
export const reassignTaskReviewerRequest = (payload, callback) => {
  return {
    type: REASSIGN_TASK_REVIEWER_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** REOPEN_TASK_REQUEST **/
/****************************************************/

/**
 * @method reopentaskList :  To reopen the task
 *
 */
export const reopentaskRequest = (payload, callback = () => { }) => {
  return {
    type: REOPEN_TASK_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SCHEDULED_EARLIEST_TIME_REQUEST **/
/****************************************************/

/**
 * @method getScheduledEarliestTime :  To get getScheduledEarliestTimeRequest 
 *
 */
export const getScheduledEarliestTimeRequest = (payload, callback = () => { }) => {
  return {
    type: GET_SCHEDULED_EARLIEST_TIME_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SCHEDULING_REQUIRED_SKILLS_MARKETS **/
/****************************************************/
/**
 * @method getSchedulingRequiredSkillsMarkets : To get scheduling required skills and markets
 *
 */

export const getSchedulingRequiredSkillsMarketsRequest = (payload, callback) => {
  return {
    type: GET_SCHEDULING_REQUIRED_SKILLS_MARKETS,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_CLOSE_TASK_MANUALLY_REQUEST **/
/****************************************************/

/**
 * @method updateCloseTaskManuallyRequest :  To update close task manually
 *
 */
export const updateCloseTaskManuallyRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_CLOSE_TASK_MANUALLY_REQUEST,
    payload,
    callback
  }
}
