import { put, takeLatest, call } from "redux-saga/effects"

import {
  getProgramBasicDetails,
  getProgramDetails,
  updateProgramDetail,
  addProgramDetail,
  getProjectContacts,
  getProgramList,
  getProgramListVM,
  deleteProgram,
  getDetailsRequirementsVM,
  getCreateProgramVM,
  addProgramSafetyRequirements,
  getProgramSafetyRequirements,
  updateProgramSafetyRequirements,
  getProgramDDL,
  getOshaDocumentationCampaignVM,
  getCampaignObservationVM,
  getCampaignObservation,
  addObservationCampaign,
  updateObservationCampaign,
  cloneProgram
} from "@services/api"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { Notification } from "@views/components"
import * as actions from "@store/actions"

const { PROGRAM_SAGA } = LANGUAGE_CONSTANTS

/** getProgramList API **/
function* getProgramListRequest(action) {
  try {
    const response = yield call(getProgramList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_FETCHING_LIST })
    }
  } catch (error) {
    action.callback()
  }
}

/** deleteProgram API **/
function* deleteProgramRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteProgram, action.payload)
    if (response?.status === 204) {
      action.callback()
      Notification({ status: 200, message: PROGRAM_SAGA.DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** getProgramListVM API **/
function* getProgramListVMRequest(action) {
  try {
    const { isLoadingRequired = false } = action.payload || {}
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getProgramListVM)
    action.callback(response?.data)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    const { isLoadingRequired = false } = action.payload || {}
    Notification({ status: 400, isLocalizedMessage: false, message: error.message })
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    action.callback(null)
  }
}

/** getDetailsRequirementsVM API **/
function* getDetailsRequirementsVMRequest(action) {
  try {
    const { isLoadingRequired = false } = action.payload || {}
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getDetailsRequirementsVM, action.payload)
    action.callback(response?.data)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    const { isLoadingRequired = false } = action.payload || {}
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    action.callback(null)
  }
}

/** getProgramDetails API **/
function* getProgramDetailsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getProgramDetails, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(null)
  }
}

/** getProgramBasicDetails API **/
function* getProgramBasicDetailsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getProgramBasicDetails, action.payload)
    action.callback(response?.data, response?.statusText)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(null)
  }
}

/** getCreateProgramVM API **/
function* getCreateProgramVMRequest(action) {
  try {
    const { isLoadingRequired = false } = action.payload || {}
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getCreateProgramVM)
    action.callback(response?.data)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    const { isLoadingRequired = false } = action.payload || {}
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    action.callback(null)
  }
}

/** getProjectContacts API **/
function* getProjectContactsRequest(action) {
  try {
    const response = yield call(getProjectContacts, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback(null)
    }
  } catch (error) {
    action.callback(null)
  }
}


/** addProgramDetail API **/
function* addProgramDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addProgramDetail, action.payload)
    if (response) {
      action.callback(response.data)
      Notification({ status: 200, message: PROGRAM_SAGA.ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_ADDING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** updateProgramDetailRequest API **/
function* updateProgramDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateProgramDetail, action.payload)
    if (response?.data?.status) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.UPDATE_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_UPDATING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** addProgramSafetyRequirements API **/
function* addProgramSafetyRequirementsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addProgramSafetyRequirements, action.payload)
    if (response.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.SAFETY_REQUIREMENTS_ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_ADDING_SAFETY_REQUIREMENTS })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getProgramSafetyRequirements API **/
function* getProgramSafetyRequirementsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getProgramSafetyRequirements, action.payload)
    if (response.data) {
      action.callback(response.data)
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_GETTING_SAFETY_REQUIREMENTS })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** updateProgramSafetyRequirements API **/
function* updateProgramSafetyRequirementsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateProgramSafetyRequirements, action.payload)
    if (response.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.SAFETY_REQUIREMENTS_UPDATE_SUCCESS })
    } else {
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_UPDATING_SAFETY_REQUIREMENTS })
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getProgramDDL API **/
function* getProgramDDLRequest(action) {
  try {
    const response = yield call(getProgramDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getOshaDocumentationCampaignVM API **/
function* getOshaDocumentationCampaignVMRequest(action) {
  try {
    const response = yield call(getOshaDocumentationCampaignVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getCampaignObservationVM API **/

function* getCampaignObservationVMRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getCampaignObservationVM, action.payload)
    if (response) {
      action.callback(response.data)
    } else {
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}


/** getCampaignObservation API **/

function* getCampaignObservationRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getCampaignObservation, action.payload)
    if (response) {
      action.callback(response.data)
    } else {
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** addObservationCampaign API **/
function* addObservationCampaignRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addObservationCampaign, action.payload)
    if (response.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.OBSERVATION_CAMPAIGN_ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_ADDING_OBSERVATION_CAMPAIGN })

    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** updateObservationCampaign API  **/

function* updateObservationCampaignRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateObservationCampaign, action.payload)
    if (response.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: PROGRAM_SAGA.OBSERVATION_CAMPAIGN_UPDATE_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_UPDATING_OBSERVATION_CAMPAIGN })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** cloneProgram API */
function* cloneProgramRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(cloneProgram, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.data) {
      action.callback(response?.data)
      Notification({ status: 200, message: PROGRAM_SAGA.CLONE_SUCCESS })
    } else {
      Notification({ status: 400, message: PROGRAM_SAGA.ERROR_IN_CLONING })
      action.callback()
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

export function* programsSagas() {
  yield takeLatest(actions.GET_PROGRAM_BASIC_DETAILS_REQUEST, getProgramBasicDetailsRequest)
  yield takeLatest(actions.GET_PROGRAM_DETAILS_REQUEST, getProgramDetailsRequest)
  yield takeLatest(actions.GET_PROGRAM_LIST_REQUEST, getProgramListRequest)
  yield takeLatest(actions.GET_PROGRAM_LIST_VM_REQUEST, getProgramListVMRequest)
  yield takeLatest(actions.DELETE_PROGRAM_REQUEST, deleteProgramRequest)
  yield takeLatest(actions.GET_DETAILS__REQUIREMENTS_VM_REQUEST, getDetailsRequirementsVMRequest)
  yield takeLatest(actions.GET_CREATE_PROGRAM_VM_REQUEST, getCreateProgramVMRequest)
  yield takeLatest(actions.GET_PROJECT_CONTACTS, getProjectContactsRequest)
  yield takeLatest(actions.ADD_PROGRAM_DETAIL_REQUEST, addProgramDetailRequest)
  yield takeLatest(actions.UPDATE_PROGRAM_DETAILS_REQUEST, updateProgramDetailRequest)
  yield takeLatest(actions.ADD_PROGRAM_SAFETY_REQUIREMENTS_REQUEST, addProgramSafetyRequirementsRequest)
  yield takeLatest(actions.GET_PROGRAM_SAFETY_REQUIREMENTS_REQUEST, getProgramSafetyRequirementsRequest)
  yield takeLatest(actions.UPDATE_PROGRAM_SAFETY_REQUIREMENTS_REQUEST, updateProgramSafetyRequirementsRequest)
  yield takeLatest(actions.GET_PROGRAM_DDL_REQUEST, getProgramDDLRequest)
  yield takeLatest(actions.GET_OBSERVATIONAL_CAMPAIGN_VM_REQUEST, getCampaignObservationVMRequest)
  yield takeLatest(actions.GET_OBSERVATIONAL_CAMPAIGN_REQUEST, getCampaignObservationRequest)
  yield takeLatest(actions.ADD_OBSERVATIONAL_CAMPAIGN_REQUEST, addObservationCampaignRequest)
  yield takeLatest(actions.GET_OSHA_DOCUMENTATION_CAMPAIGN_VM_REQUEST, getOshaDocumentationCampaignVMRequest)
  yield takeLatest(actions.UPDATE_OBSERVATION_CAMPAIGN_REQUEST, updateObservationCampaignRequest)
  yield takeLatest(actions.CLONE_PROGRAM_REQUEST, cloneProgramRequest)
}

export default programsSagas
