import config from "./APIConfig"
import { putDataApi } from "../index"

/**
 * @method updateBulkAssignActionItems
 * This api is for updating bulk assign action items
 * [Reassign Failed inspection correction]
 * 
 * @param {object} data 
 * 
 * {
 *  contactId: 0,
 *  actionIds: [0]
 * }
 * 
 */
export const updateBulkAssignActionItems = async (data) => {
  const { url } = config.updateBulkAssignActionItems
  return await putDataApi(url(data), data)
}

export default updateBulkAssignActionItems
