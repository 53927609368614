import cx from "classnames"
import React, { useState } from "react"
import {
    Col,
    Row,
    Input,
    FormGroup
} from "reactstrap"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import {
    CustomLabel,
    ErrorMessage,
    OrgPlansSearch
} from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const { CANCEL, ORG_PLANS } = LANGUAGE_CONSTANTS

const PlanSection = ({
    intl,
    isOrgPlan,
    organizationId,
    colWrapperProps,
    errors,
    isEnableAddNewPlanMode, //Note: When going to add new plan by clicking "Add New Plan" button
    isEditMode,
    isModal,
    planDetail,
    onChangeDetail = () => { },
    onToggleAddNewPlanMode = () => { }
}) => {
    const [defaultOptions] = useState(isEditMode ? [
        {
            text: planDetail.planName,
            value: planDetail.organizationPlanId
        }
    ] : [])

    return (
        <>
            {!isOrgPlan && <Row>
                <Col className="mb-1" sm={12} md={isModal ? 12 : 8} xl={isModal ? 12 : 6} >
                    <>
                        <div className="unit-plan-subtitle">
                            {<CustomLabel title={isEnableAddNewPlanMode ? ORG_PLANS.PLAN_NAME : ORG_PLANS.SELECT_PLAN} required requiredIconClassName="required-field" />}
                            <button
                                className={cx("waves-effect btn", {
                                    "primary-text-btn": !isEnableAddNewPlanMode,
                                    "danger-text-btn": isEnableAddNewPlanMode
                                })}
                                onClick={onToggleAddNewPlanMode}
                            >
                                {getLocaleMessage(intl, isEnableAddNewPlanMode ? CANCEL : ORG_PLANS.ADD_NEW_PLAN)}
                            </button>
                        </div>
                        {!isEnableAddNewPlanMode ? <OrgPlansSearch
                            id={"organizationPlanId"}
                            name={"organizationPlanId"}
                            organizationId={organizationId}
                            errors={errors}
                            label={ORG_PLANS.SELECT}
                            labelRequired={false}
                            isEditMode={isEditMode}
                            isDisabled={isEnableAddNewPlanMode}
                            value={planDetail.organizationPlanId}
                            defaultOptions={defaultOptions}
                            onSelect={onChangeDetail}
                        /> : <>
                            <FormGroup>
                                <Input
                                    placeholder={getLocaleMessage(intl, ORG_PLANS.SELECT_PLAN_FIRST)}
                                    value={planDetail.planName}
                                    onChange={(event) => onChangeDetail("planName", event.target.value)}
                                />
                            </FormGroup>
                            <ErrorMessage
                                className="error-message"
                                isShow={!!errors["planName"]}
                                message={errors["planName"]}
                            />
                        </>}
                    </>
                </Col>
            </Row>}
            <Row>
                {isOrgPlan && <Col className="mb-1" {...colWrapperProps}>
                    <FormGroup>
                        <CustomLabel title={ORG_PLANS.PLAN_NAME} required={isEnableAddNewPlanMode} requiredIconClassName="required-field" />
                        <Input
                            placeholder={getLocaleMessage(intl, ORG_PLANS.SELECT_PLAN_FIRST)}
                            value={planDetail.planName}
                            onChange={(event) => onChangeDetail("planName", event.target.value)}
                        />
                    </FormGroup>
                    <ErrorMessage
                        className="error-message"
                        isShow={!!errors["planName"]}
                        message={errors["planName"]}
                    />
                </Col>}
                <Col className="mb-1" {...colWrapperProps}>
                    <FormGroup>
                        <CustomLabel title={ORG_PLANS.PLAN_NUMBER} />
                        <Input
                            placeholder={getLocaleMessage(intl, ORG_PLANS.ENTER_NUMBER)}
                            value={planDetail.planNumber}
                            disabled={!isEnableAddNewPlanMode}
                            onChange={(event) => onChangeDetail("planNumber", event.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col className="mb-1" {...colWrapperProps}>
                    <FormGroup>
                        <CustomLabel title={ORG_PLANS.PLAN_ELEVATION} />
                        <Input
                            type="text"
                            placeholder={getLocaleMessage(intl, ORG_PLANS.ENTER_TEXT)}
                            value={planDetail.elevation}
                            disabled={!isEnableAddNewPlanMode}
                            onChange={(event) => onChangeDetail("elevation", event.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col className="mb-1" {...colWrapperProps}>
                    <FormGroup>
                        <CustomLabel title={ORG_PLANS.SWING} />
                        <Input
                            placeholder={getLocaleMessage(intl, ORG_PLANS.ENTER_TEXT)}
                            value={planDetail.swing}
                            disabled={!isEnableAddNewPlanMode}
                            onChange={(event) => onChangeDetail("swing", event.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}

export default localeMessageWrapper(PlanSection)
