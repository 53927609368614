import { getSearchQuery } from '../config'

const apiConfig = {
  createUserManagementRole: {
    method: "POST",
    url: "api/UserManagement/Role"
  },
  getRoleList: {
    method: "GET",
    url: ({
      pageSize,
      pageNumber,
      isActive
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("isActive", isActive)
      ].join('')

      return `api/UserManagement/Role${searchString}`
    }
  },
  getMenuList: {
    method: "GET",
    url: "api/UserManagement/GetMenu"
  },
  getUserEntityDDL: {
    method: "GET",
    url: (userEmail) => `api/UserManagement/entities/${userEmail}`
  },
  getAdvanceSearchFilterList: {
    method: "GET",
    url: (searchFilterKey) => `api/UserManagement/AdvanceSearchFilter/${searchFilterKey}`
  },
  saveAdvanceSearchFilters: {
    method: "POST",
    url: 'api/UserManagement/AdvanceSearchFilter'
  },
  updateAdvanceSearchFilters: {
    method: "PUT",
    url: (advanceSearchFilterId) => `api/UserManagement/AdvanceSearchFilter/${advanceSearchFilterId}`
  },
  deleteAdvanceSearchFilters: {
    method: "DELETE",
    url: (advanceSearchFilterId) => `api/UserManagement/AdvanceSearchFilter/${advanceSearchFilterId}`
  },
  getForesiteRoleDetails: {
    method: 'GET',
    url: (roleId) => `api/UserManagement/Role/${roleId}`
  },
  updateForesiteRole: {
    method: 'PUT',
    url: (roleId) => `api/UserManagement/Role/${roleId}`
  },
  deleteForesiteRole: {
    method: 'DELETE',
    url: (roleId) => `api/UserManagement/Role/${roleId}`
  },
  getUserProfile: {
    method: 'GET',
    url: 'api/UserManagement/User/UserProfile'
  },
  updateUserProfile: {
    method: 'PUT',
    url: 'api/UserManagement/User/UserProfile'
  },
  getTimezoneDDL: {
    method: 'GET',
    url: ({
      timezoneDisplayName,
      pageSize,
      pageNumber
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("TimezoneDisplayName", timezoneDisplayName, false, true)
      ].join('')
      
      return `api/UserManagement/Timezone/DDL${searchString}`
    }
  }
}

export default apiConfig
