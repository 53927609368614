import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateProjectSiteRole
 * @param {object} data 
 *  {
      "siteId": 0,
      "siteRoles": [
        {
          "siteRoleId": 0,
          "sitePartyRoleId": 0,
          "organizationId": 0,
          "poC_ContactId": 0,
          "poC_OrganizationId": 0,
          "isPrimary": true
        }
      ]
    }
 *
 */
export const updateProjectSiteRole = (data) => {
  if (data.isSiteManagerUpdate) {
    const { url } = config.updateProjectSiteManagerForRole
    const payload = {
      siteRoleId: Number(data.siteRoleId),
      siteManager: data.siteManager
    }
    return putDataApi(url(payload.siteRoleId), payload)
  }

  const { url } = config.updateProjectSiteRole

  const payload = {
    siteId: Number(data.siteId),
    siteRoles: [
      {
        siteRoleId: Number(data.siteRoleId),
        sitePartyRoleId: data.sitePartyRoleId ? Number(data.sitePartyRoleId) : Number(data.siteRole || ''),
        organizationId: Number(data.org),
        poC_ContactId: data.contacts ? Number(data.contacts[0].contactId) : data.contactId,
        poC_OrganizationId: data.contacts ? Number(data.contacts[0].organizationId) : data.poC_OrganizationId,
        isPrimary: data.isPrimary
      }
    ]
  }
  return putDataApi(url({ roleId: data.roleId }), payload)
}

export default updateProjectSiteRole
