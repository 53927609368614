import React, { useCallback, useEffect, useState } from "react"
import { Search } from 'react-feather'
import { Col } from 'reactstrap'
import { useDispatch } from "react-redux"
import PropTypes from 'prop-types'
import * as actions from "@store/actions"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { DEFAULT_VM_PAGE_SIZE } from '@shared/constants'
import { getLocaleMessage } from "@utils"
import { CustomReactSelect, CustomLabel } from '@views/components'

import CustomSelectOption from './organization-modal/CustomSelectOption'
import LocaleMessageWrapper from "../locale-message"

const ParentOrganizationSearch = ({
  intl,
  value,
  orgTree,
  onSelect,
  ...props
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState([])
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOrg, setSelectedOrg] = useState(null)

  useEffect(() => {
    setResetCache(prevState => !prevState)
  }, [])

  useEffect(() => {
    if (!selectedOrg && value && orgTree) {
      const selected = orgTree.find(op => op.organizationId === value)
      if (selected) {
        setSelectedOrg(selected)
      }
    }
  }, [value, orgTree])

  const handleLoadMore = useCallback(
    (search, page, prevOptions) => {
      return new Promise((resolve) => {
        const params = {
          orgName: search,
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE
        }
        dispatch(actions.getFilteredOrganizationsRequest(params, (response) => {
          if (response) {
            const { items, ...paginationData } = response
            resolve({
              optionList: items,
              hasMore: paginationData.hasNextPage
            })
            setOptions(() => (!!prevOptions.length ? [...prevOptions, ...items] : items))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }))
      })
    },
    [options]
  )

  return (
    <Col className="mb-1 parent-organization" sm={12} md={6} xl={4}>
      <CustomLabel title={LANGUAGE_CONSTANTS.ORGANIZATIONS.PARENT_ORGANIZATION} />
      <CustomReactSelect
        additional={{
          page: 1
        }}
        isShowCustomAction={false}
        cacheUniqs={[isResetCache]}
        id="parent_organization"
        name="parent_organization"
        options={options}
        hideSelectedOptions={false}
        defaultOptions={options}
        placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.ORGANIZATIONS.PARENT_ORG)}
        isFormattedValue
        value={selectedOrg}
        getOptionLabel={(option) => option.organizationName}
        getOptionValue={(option) => option.organizationId}
        onSelect={(selected) => {
          setSelectedOrg(selected)
          onSelect('parentOrganizationName', selected ? selected.organizationId : '')
        }}
        onLoadOptions={handleLoadMore}
        formatOptionLabel={(option) => <CustomSelectOption data={option} label={option.organizationName} value={option.organizationId} />}
        {...props}
      />
      <Search />
    </Col>
  )
}

ParentOrganizationSearch.propTypes = {
  intl: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  orgTree: PropTypes.array,
  onSelect: PropTypes.func
}

ParentOrganizationSearch.defaultProps = {
  orgTree: []
}

export default LocaleMessageWrapper(ParentOrganizationSearch)
