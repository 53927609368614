import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { TableCellToolTip, LocaleMessage } from '@views/components'
import RowActions from './RowActions'

const { SERVICES, TABLE, PROGRAMS } = LANGUAGE_CONSTANTS

const serviceColumn = (LocaleMessage) => ({
  name: <LocaleMessage id={SERVICES.SERVICE} />,
  selector: 'campaignServiceTypeName',
  sortKey: 'campaignservicetypename',
  sortable: true,
  wrap: true,
  minWidth: '400px',
  right: true,
  format: (row) => (
    <TableCellToolTip
      id={row.campaignServiceId}
      value={row.campaignServiceTypeName}
      suffixOfId='_o_o_item_title'
      colNo={1}
      truncate={false}
    />
  )
})

const courseColumn = (LocaleMessage) => ({
  name: <LocaleMessage id={PROGRAMS.TRAINING_CAMPAIGN.TABLE_COLUMNS.COURSE} />,
  selector: 'campaignServiceTypeName',
  sortKey: 'campaignservicetypename',
  sortable: true,
  wrap: true,
  minWidth: '400px',
  right: true,
  format: (row) => (
    <TableCellToolTip
      id={row.campaignServiceId}
      value={row.campaignServiceTypeName}
      suffixOfId='_o_o_item_title'
      colNo={1}
      truncate={false}
    />
  )
})

const columns = (LocaleMessage) => ([
  {
    name: <LocaleMessage id={SERVICES.DRIVER} />,
    selector: 'campaignSchedulerDriverName',
    sortKey: 'campaignschedulerdrivername',
    sortable: true,
    maxWidth: '150px',
    right: true,
    format: (row) => (
      <TableCellToolTip
        id={row.campaignServiceId}
        value={row.campaignSchedulerDriverName}
        suffixOfId='_o_p_item_title'
      />
    )
  },
  {
    name: <LocaleMessage id={SERVICES.INTERVAL} />,
    selector: 'sampleRate',
    sortKey: 'samplerate',
    sortable: true,
    maxWidth: '150px',
    right: true,
    format: (row) => (
      <TableCellToolTip
        id={row.campaignServiceId}
        value={row.sampleRate ? `${row.sampleRate || ''}%` : '0'}
        suffixOfId='_o_c_item_title'
      />
    ),
    style: {
      display: 'flex !important',
      justifyContent: 'center !important'
    }
  },
  {
    name: <LocaleMessage id={SERVICES.CHECKLISTS} />,
    selector: 'numberOfChecklists',
    sortKey: 'numberofchecklists',
    sortable: true,
    maxWidth: '150px',
    right: true,
    format: (row) => (
      <TableCellToolTip
        id={row.campaignServiceId}
        value={row.numberOfChecklists}
        suffixOfId='_o_l_item_title'
      />
    ),
    style: {
      display: 'flex !important',
      justifyContent: 'center !important'
    }
  },
  {
    name: <LocaleMessage id={TABLE.COLUMN_ACTIONS} />,
    maxWidth: '30px',
    allowOverflow: true,
    cell: (row) => <RowActions data={row} />
  }
])

export const getColumns = (isObservation) => {
  return [(isObservation ? serviceColumn(LocaleMessage) : courseColumn(LocaleMessage)), ...columns(LocaleMessage)]
}
