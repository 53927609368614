import config from "../APIConfig"
import { putDataApi } from "../../../index"

/**
 * @method updateAutomateReport
 * @param {object} data 
 * 
 * API payload:-
 * 
 * {
 *   "wfCampaignSetupId": 0,
 *   "automateReports": [
 *     {
 *       "automateReportGuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
 *       "windwardReportId": 0,
 *       "triggerEventId": 0,
 *       "sendTypeId": 0,
 *       "assignmentTypeId": 1,
 *       "siteRoleIds": [
 *         {
 *           "siteRoleId": 0
 *         }
 *       ],
 *       "contactIds": [
 *         {
 *           "contactId": 0
 *         }
 *       ],
 *       "contactGroupIds": [
 *         {
 *           "contactGroupId": 0
 *         }
 *       ]
 *     }
 *   ]
 * }
 * 
 */

export const updateAutomateReport = async (data) => {
  const { url } = config.updateAutomateReport
  const valueType = data.isSharedCampaign ? "value" : "sitePartyRoleId"
  const updatedAutomateReports = data.automateReports.map((item) => (
    {
      automateReportGuid: item.automateReportGuid,
      windwardReportId: Number(item.windwardReport.value),
      triggerEventId: Number(item.triggerEvent.value),
      assignmentTypeId: Number(item.assignmentType.value),
      siteRoleIds: item.siteRoleIds.map((role) => ({ siteRoleId: Number(role[valueType])})),
      contactIds: item.contactIds.map((contact) => ({ contactId: Number(contact.contactId) })),
      contactGroupIds: item.contactGroupIds.map((contact) => ({ contactGroupId: Number(contact.value) })),
      organizationRoleIds: item.organizationRoleIds.map((role) => ({ sitePartyRoleId: Number(role[valueType]) })),
      sendTypeId: Number(item.sendType.value),
      reportAttachTypeId: Number(item.includeFileAs.value)
    }))

  const payload = {
    wfCampaignSetupId: data.wfCampaignSetupId,
    automateReports: updatedAutomateReports
  }

  return await putDataApi(url(data), payload)
}

export default updateAutomateReport
