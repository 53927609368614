import { getSearchQuery } from '../config'

const apiConfig = {
  addProjectAddress: {
    method: 'POST',
    url: ({ projectId }) => `api/Project/location/addprojectlocations/${projectId}`
  },
  addProjectDetails: {
    method: 'POST',
    url: (organizationId = 0) => `api/Project/${organizationId}/AddProject`
  },
  addProjectSiteAutoGenerateUnits: {
    method: 'POST',
    url: (siteid) => `api/Project/site/${siteid}/units`
  },
  addProjectSiteDetails: {
    method: 'POST',
    url: 'api/Project/Sites/AddSite'
  },
  addProjectSitePlan: {
    method: 'POST',
    url: 'api/Project/Sites/AddSitePlan'
  },
  addProjectSiteRole: {
    method: 'POST',
    url: 'api/Project/Sites/AddSiteRole'
  },
  addProjectSiteUnit: {
    method: 'POST',
    url: () => `api/Project/site/unit`
  },
  deleteProject: {
    method: 'DELETE',
    url: ({ projectId }) => `api/Project/${projectId}`
  },
  deleteProjectLocation: {
    method: 'DELETE',
    url: ({ projectLocationId }) => `api/Project/location/${projectLocationId}`
  },
  deleteProjectSite: {
    method: 'DELETE',
    url: ({ siteId }) => `api/Project/Sites/DeleteSite/${siteId}`
  },
  deleteProjectSitePlan: {
    method: 'DELETE',
    url: ({ sitePlanId }) => `api/Project/Sites/DeleteSitePlan/${sitePlanId}`
  },
  deleteProjectSiteRole: {
    method: 'DELETE',
    url: ({ siteRoleId }) => `api/Project/Sites/DeleteSiteRole/${siteRoleId}`
  },
  deleteProjectSiteUnit: {
    method: 'DELETE',
    url: ({ unitId }) => `api/Project/site/unit/${unitId}`
  },
  getAddProjectSiteUnitVM: {
    method: 'GET',
    url: (siteId = 0) => `api/Project/GetAddProjectSiteUnitVM/site/${siteId}`
  },
  getAuthorityName: {
    method: 'GET',
    url: ({ authoritytypeid }) => `api/Project/getauthorityname/${authoritytypeid}`
  },
  getContactByOrganization: {
    method: 'GET',
    url: ({ contactName, entityId = 1, organizationId, pageNumber, pageSize }) => {
      const searchString = [
        getSearchQuery("EntityId", entityId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("ContactName", contactName, false, true)
      ].join('')

      return `api/Project/Contact/GetContactByOrganization${searchString}`
    }
  },
  getCreateProjectVM: {
    method: 'GET',
    url: `api/Project/GetCreateProjectVM`
  },
  getProjectAddress: {
    method: 'GET',
    url: ({ projectId }) => `api/Project/location/getlocationsbyprojectid/${projectId}`
  },
  getProjectDetails: {
    method: 'GET',
    url: ({ projectId }) => `api/Project/${projectId}`
  },
  getProjectList: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      genericSearch = '',
      advanceSearch = {},
      authority = {},
      address = {},
      orderColumn,
      orderDirections = ''
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          projectName = '',
          organizationId = '',
          projectTypeId = '',
          projectStatusId = '',
          constructionStartDate = ''
        } = advanceSearch

        searchString = [
          searchString,
          getSearchQuery("ProjectName", projectName, false, true),
          getSearchQuery("OrganizationId", organizationId),
          getSearchQuery("ProjectTypeId", projectTypeId),
          getSearchQuery("ProjectStatusId", projectStatusId),
          getSearchQuery("ConstructionStartDate", constructionStartDate)
        ].join('')
      }

      if (!!Object.keys(authority).length) {
        const { authorityTypeId = '', authorityNameId = '' } = authority
        searchString = [
          searchString,
          getSearchQuery("AuthorityTypeId", authorityTypeId),
          getSearchQuery("authorityNameId", authorityNameId)
        ].join('')
      }

      if (!!Object.keys(address).length) {
        const {
          addressName = '',
          primaryPhone = '',
          streetAddress = '',
          addressUnit = '',
          countryCode = '',
          city = '',
          state = '',
          postCode = ''
        } = address
        searchString = [
          searchString,
          getSearchQuery("LocationName", addressName, false, true),
          getSearchQuery("PrimaryPhone", primaryPhone),
          getSearchQuery("StreetAddress", streetAddress, false, true),
          getSearchQuery("AddressUnit", addressUnit, false, true),
          getSearchQuery("CountryCode", countryCode),
          getSearchQuery("City", city, false, true),
          getSearchQuery("State", state),
          getSearchQuery("PostCode", postCode, false, true)
        ].join('')
      }

      return `api/Project/GetProjectList${searchString}`
    }
  },
  getProjectListVM: {
    method: 'GET',
    url: `api/Project/GetProjectListVM`
  },
  getProjectSiteDDL: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      siteName = "",
      campaigns = [],
      campaignId = "",
      allOption,
      organizationId = 0,
      programId
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("SiteName", siteName, false, true),
        getSearchQuery("CampaignIds", campaignId)
      ].join('')
      const campaignSearch = campaigns.map((campaign) => getSearchQuery("CampaignIds", campaign.value || campaign.campaignId)).join("")

      searchString = [
        searchString,
        getSearchQuery("AllOption", allOption),
        campaignSearch,
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("ProgramId", programId)
      ].join('')
      return `api/Project/siteddl${searchString}`
    }
  },
  getProjectSiteDetails: {
    method: 'GET',
    url: ({ siteId }) => `api/Project/Sites/GetSiteById?SiteId=${siteId}`
  },
  getProjectSiteList: {
    method: "GET",
    url: ({
      entityId = 1,
      projectId = 0,
      organizationId = 0,
      pageNumber,
      pageSize,
      genericSearch = "",
      advanceSearch = {},
      orderColumn,
      orderDirections,
      isSiteOverviewList = false
    }) => {
      let searchString = [
        getSearchQuery("EntityId", entityId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ProjectId", projectId),
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          siteIds,
          buildingType,
          pointOfContact,
          organization,
          streetAddress,
          unit,
          city,
          state,
          postCode,
          siteStatus,
          tract,
          buildingId,
          countryCode,
          region
        } = advanceSearch

        const buildingTypeIds = buildingType.map((type) => getSearchQuery("BuildingTypeIDs", type.value)).join("")
        const siteStatusIds = siteStatus.map((status) => getSearchQuery("SiteStatuses", status.value)).join("")
        const pointOfContactIds = pointOfContact.map((contact) => getSearchQuery("POC_ContactIDs", contact.value)).join("")
        const siteIdsList = siteIds.map((site) => getSearchQuery("SiteIds", site.value)).join("")
        const regionIds = region.map((type) => getSearchQuery("RegionIds", type.value)).join("")

        searchString = [
          searchString,
          siteIdsList,
          buildingTypeIds,
          pointOfContactIds,
          regionIds,
          getSearchQuery("StreetAddress", streetAddress),
          getSearchQuery("Unit", unit, false, true),
          getSearchQuery("City", city, false, true),
          getSearchQuery("StateValue", state),
          getSearchQuery("ZipCode", postCode, false, true),
          siteStatusIds,
          getSearchQuery("Tract", tract),
          getSearchQuery("BuildingId", buildingId),
          getSearchQuery("CountryCode", countryCode),
          getSearchQuery("OrganizationId", organization)
        ].join('')
      }

      if (isSiteOverviewList) {
        return `api/Project/sitelist${searchString}`
      }

      return `api/Project/${projectId}/sitelist${searchString}`
    }
  },
  getProjectSitePlan: {
    method: 'GET',
    url: ({ planId }) => {
      const searchString = getSearchQuery("SitePlanId", planId, true)
      return `api/Project/Sites/GetSitePlanById/${searchString}`
    }
  },
  getProjectSitePlanList: {
    method: "GET",
    url: ({
      siteId,
      pageNumber,
      pageSize,
      genericSearch = "",
      advanceSearch = {},
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("SiteId", siteId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const { planName, planNumber, version, elevation, squareFootage, numberOfBedroom, stories, bathrooms } = advanceSearch

        searchString = [
          searchString,
          getSearchQuery("PlanName", planName, false, true),
          getSearchQuery("PlanNumber", planNumber),
          getSearchQuery("squareFootage", squareFootage),
          getSearchQuery("Elevation", elevation),
          getSearchQuery("Version", version),
          getSearchQuery("NumberOfBedroom", numberOfBedroom),
          getSearchQuery("SitePlanNumberOfStoryId", stories),
          getSearchQuery("SitePlanNumberOfBathroomId", bathrooms)
        ].join('')
      }
      return `api/Project/Sites/GetSitePlanList${searchString}`
    }
  },
  getProjectSiteRole: {
    method: 'GET',
    url: ({ roleId }) => {
      const searchString = `${getSearchQuery("SiteRoleId", roleId, true)}`
      return `api/Project/Sites/GetSiteRoleById${searchString}`
    }
  },
  getProjectSiteRoleList: {
    method: 'GET',
    url: ({
      siteId,
      pageNumber,
      pageSize,
      genericSearch = '',
      advanceSearch = {},
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("SiteId", siteId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')
      if (!!Object.keys(advanceSearch).length) {
        const {
          sitePartyRoleId,
          organizationId,
          pocContactId,
          pocOrganizationId,
          isPrimary
        } = advanceSearch
        searchString = [
          searchString,
          getSearchQuery("SitePartyRoleId", sitePartyRoleId),
          getSearchQuery("OrganizationId", organizationId),
          getSearchQuery("PoC_ContactId", pocContactId),
          getSearchQuery("PoC_OrganizationId", pocOrganizationId),
          getSearchQuery("IsPrimary", isPrimary)
        ].join('')
      }

      return `api/Project/Sites/GetSiteRoleList${searchString}`
    }
  },
  getProjectSiteRolesVM: {
    method: 'GET',
    url: (orgId = 0) => `api/Project/Sites/GetSiteRolesVM/${orgId}`
  },
  getProjectSiteSearch: {
    method: 'GET',
    url: ({ projectId, pageNumber, pageSize, siteName, showAllSite }) => {
      const searchString = [
        getSearchQuery("ProjectId", projectId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("SiteName", siteName, false, true),
        getSearchQuery("ShowAllSite", showAllSite)
      ].join('')
      return `api/Project/DDL/ProjectSites${searchString}`
    }
  },
  getProjectSiteUnit: {
    method: 'GET',
    url: (unitId) => `api/Project/site/unit/${unitId}`
  },
  getProjectSiteUnitList: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      genericSearch = '',
      advanceSearch = {},
      additionalLocation = {},
      planDetail = {},
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')
      if (!!Object.keys(advanceSearch).length) {
        const { unitName = '', unitTypeId = '', siteId = '', streetAddress = '', unitNumber = '', city = '', state = '', postCode = '', country = '' } =
          advanceSearch
        searchString = [
          searchString,
          getSearchQuery("UnitName", unitName, false, true),
          getSearchQuery("UnitTypeId", unitTypeId),
          getSearchQuery("SiteId", siteId),
          getSearchQuery("StreetAddress", streetAddress, false, true),
          getSearchQuery("UnitNumber", unitNumber),
          getSearchQuery("City", city, false, true),
          getSearchQuery("State", state, false, true),
          getSearchQuery("ZipCode", postCode, false, true),
          getSearchQuery("Country", country)
        ].join('')
      }

      if (!!Object.keys(additionalLocation).length) {
        const { block = '', building = '', floor = '' } = additionalLocation
        searchString = [
          searchString,
          getSearchQuery("Block", block, false, true),
          getSearchQuery("Building", building),
          getSearchQuery("Floor", floor)
        ].join('')
      }

      if (!!Object.keys(planDetail).length) {
        const { sitePlanId = '', permitNumber = '', permitDate = '', phase = '', phaseStartDate = '', planName = '' } = planDetail
        searchString = [
          searchString,
          getSearchQuery("PlanName", planName, false, true),
          getSearchQuery("PermitNumber", permitNumber),
          getSearchQuery("PermitDate", permitDate),
          getSearchQuery("Phase", phase),
          getSearchQuery("PhaseStartDate", phaseStartDate),
          getSearchQuery("SitePlanId", sitePlanId)
        ].join('')
      }
      return `api/Project/site/unit/GetSiteUnitList${searchString}`
    }
  },
  getProjectSiteVM: {
    method: 'GET',
    url: (data) => {
      const { projectId = 0 } = data || {}
      return `api/Project/Sites/GetProjectSiteVM/${projectId}`
    }
  },
  getSiteRoleAssignmentDDL: {
    method: 'GET',
    url: ({ pageNumber, pageSize, assignmentTypeId = 0, siteId = 0, organizationId = 0, searchName = "", isShowAllOption = false }) => {
      const searchString = [
        getSearchQuery("AssignmentTypeId", assignmentTypeId, true),
        getSearchQuery("SiteId", siteId),
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("SearchName", searchName, false, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ShowAllOption", isShowAllOption)
      ].join('')
      return `api/Project/Assignment/${assignmentTypeId}/${siteId}${searchString}`
    }
  },
  getSiteUnitChildGrid: {
    method: 'GET',
    url: ({ siteId, pageNumber, pageSize, orderColumn, orderDirections }) => {
      const searchString = [
        getSearchQuery("SiteID", siteId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      return `api/Project/site/unit/${siteId}/SiteUnitChildGrid${searchString}`
    }
  },
  getSiteUnits: {
    method: 'GET',
    url: ({ pageNumber, pageSize, siteId, unitName = "" }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("SiteId", siteId),
        getSearchQuery("UnitName", unitName, false, true)
      ].join('')
      return `api/Project/DDL/UnitList${searchString}`
    }
  },
  
  updateProjectAddress: {
    method: 'PUT',
    url: ({ projectId }) => `api/Project/location/updatelocations/projectid/${projectId}`
  },
  updateProjectDetails: {
    method: 'PUT',
    url: ({ projectId = 0, organizationId = 0 }) => `api/Project/${organizationId}/${projectId}`
  },
  updateProjectSiteDetails: {
    method: 'PUT',
    url: () => `api/Project/Sites/UpdateSite`
  },
  updateProjectSitePlan: {
    method: 'PUT',
    url: () => `api/Project/Sites/UpdateSitePlan`
  },
  updateProjectSiteRole: {
    method: 'PUT',
    url: () => `api/Project/Sites/UpdateSiteRole`
  },
  updateProjectSiteManagerForRole: {
    method: 'PUT',
    url: (siteRoleId) => `api/Project/site/UpdateSiteManager/${siteRoleId}`
  },
  updateProjectSiteUnit: {
    method: 'PUT',
    url: (unitId) => `api/Project/site/unit/${unitId}`
  },
  updateProjectSiteRolePrimary: {
    method: "PUT",
    url: ({ siteRoleId }) => `api/Project/site/UpdateSiteRolePrimary/${siteRoleId}`
  },
  getContactProjectList: {
    method: "GET",
    url: ({
      contactId = 0,
      pageNumber,
      pageSize,
      genericSearch = "",
      advanceSearch = {},
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("ContactId", contactId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ProjectName", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          projectName,
          siteName,
          organizationName,
          roleName
        } = advanceSearch
        searchString = [
          searchString,
          getSearchQuery("ProjectName", projectName, false, true),
          getSearchQuery("SiteName", siteName, false, true),
          getSearchQuery("OrganizationName", organizationName, false, true),
          getSearchQuery("RoleName", roleName, false, true)
        ].join('')
      }

      return `api/Project/${contactId}/Projects${searchString}`
    }
  },
  deleteMultipleProjectSiteUnits: {
    method: 'DELETE',
    url: `api/Project/site/units`
  },
  getProjectsFilteredOrganizations: {
    method: "GET",
    url: ({ organizationName, isAHJOrganisation, organizationTypeId, siteId, pageNumber, pageSize }) => {
      let searchString = ''
      if (isAHJOrganisation) {
        searchString = [
          getSearchQuery("OrganizationTypeId", organizationTypeId, true),
          getSearchQuery("PageNumber", pageNumber),
          getSearchQuery("PageSize", pageSize),
          getSearchQuery("OrganizationName", organizationName, false, true)
        ].join('')
      } else {
        searchString = [
          getSearchQuery("SiteId", siteId, true),
          getSearchQuery("PageNumber", pageNumber),
          getSearchQuery("PageSize", pageSize),
          getSearchQuery("OrganizationName", organizationName, false, true),
          getSearchQuery("OrganizationTypeId", organizationTypeId)
        ].join('')
      }
      return `api/Project/FilterOrganization${searchString}`
    }
  },
  getSiteRolesVM: {
    method: 'GET',
    url: () => `api/Project/Sites/GetSiteRoleOnlyVM`
  },
  getUnitsListDDL: {
    method: 'GET',
    url: ({
      siteUnitName,
      pageSize,
      pageNumber,
      organizationId,
      sites = [],
      sitesList = [],
      siteId,
      campaignId,
      programId,
      organizations = []
    }) => {

      const siteSearch = sites.map((site) => getSearchQuery("SiteIds", site.value)).join("")
      const organizationSearch = organizations.map((data) => data.organizationId).join(",")
      const sitesListSearch = sitesList.map((data) => data.value).join(",")
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("SiteUnitName", siteUnitName, false, true),
        getSearchQuery("SiteIds", siteId),
        siteSearch,
        getSearchQuery("CampaignId", campaignId),
        getSearchQuery("ProgramId", programId),
        getSearchQuery("OrganizationIdList", organizationId),
        getSearchQuery("OrganizationIdList", organizationSearch),
        getSearchQuery("SiteIdList", sitesListSearch)
      ].join('')

      return `api/Project/DDL/SiteUnitList${searchString}`
    }
  },
  getSitePlanScheduleList: {
    method: 'GET',
    url: ({
      sitePlanId,
      pageSize,
      pageNumber,
      genericSearch = "",
      advanceSearch = {}
    }) => {
      let searchString = [
        getSearchQuery("SitePlanId", sitePlanId, true),
        getSearchQuery("ScheduleLineItem", genericSearch, false, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          scheduleLineItem,
          autoStart,
          sitePlanPrereqs,
          usoWs
        } = advanceSearch
        const usoWsData = usoWs.map((usoW) => getSearchQuery("USOWIds", usoW.value)).join("")
        const prereqsData = sitePlanPrereqs.map((prereq) => getSearchQuery("Prereqs", prereq.value)).join("")
        searchString = [
          searchString,
          getSearchQuery("ScheduleLineItem", scheduleLineItem, false, true),
          getSearchQuery("AutoStart", autoStart),
          prereqsData,
          usoWsData
        ].join('')
      }
      return `api/Project/SitePlan/Schedule${searchString}`
    }
  },
  deleteProjectSitePlanSchedule: {
    method: 'DELETE',
    url: ({ sitePlanMasterScheduleId }) => `api/Project/SitePlan/Schedule/${sitePlanMasterScheduleId}`
  },
  addProjectSitePlanSchedule: {
    method: 'POST',
    url: ({ sitePlanId }) => `api/Project/SitePlan/Schedule/${sitePlanId}`
  },
  getDDLSitePlanSchedulePrereqs: {
    method: 'GET',
    url: ({ sitePlanId, pageNumber, pageSize, scheduleLineItem }) => {
      const searchString = [
        getSearchQuery("SitePlanId", sitePlanId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ScheduleLineItem", scheduleLineItem, false, true)
      ].join('')
      return `api/Project/DDL/SitePlanPrereq${searchString}`
    }
  },
  getWorkflowList: {
    method: 'GET',
    url: ({
      siteId,
      siteUnitId,
      pageNumber,
      pageSize = 0,
      genericSearch = "",
      orderColumn,
      orderDirections
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("WorkflowName", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!siteUnitId) {
        return `api/WorkFlow/UnitWorkflowList/${siteUnitId}${searchString}`
      }

      return `api/WorkFlow/WorkflowList/${siteId}${searchString}`
    }
  },
  updateUnitWorkflowList: {
    method: 'POST',
    url: ({
      siteUnitId
    }) => {
      return `api/WorkFlow/UnitWorkflowList/${siteUnitId}`
    }
  },
  getUnitWorkflowListCount: {
    method: 'GET',
    url: ({
      siteUnitId
    }) => {
      return `api/WorkFlow/UnitWorkflowListCount/${siteUnitId}`
    }
  },
  getUnitBlockList: {
    method: 'GET',
    url: ({
      block = '',
      pageNumber,
      pageSize
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("Block", block, false, true)
      ].join('')
      return `api/Common/filter/UnitBlock${searchString}`
    }
  },
  getUnitPlanDetails: {
    method: 'GET',
    url: ({ siteUnitId }) => {
      return `api/Project/site/unit/${siteUnitId}/Plan`
    }
  },
  addProjectSiteQATag: {
    method: 'POST',
    url: ({ siteId }) => `api/Project/site/${siteId}/QATag`
  },
  deleteProjectSiteQATag: {
    method: 'DELETE',
    url: ({ siteId, tagId }) => `api/Project/site/${siteId}/QATag/${tagId}`
  },
  getHomeSiteDDL: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      siteName,
      organizations = []
    }) => {
      const organizationSearch = organizations.map((data) => data.organizationId).join(",")
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("SiteName", siteName, false, true),
        getSearchQuery("OrganizationIdList", organizationSearch)
      ].join('')
      return `api/Project/DDL/SiteList${searchString}`
    }
  },
  validateAutoGeneratedUnitNames: {
    method: 'GET',
    url: ({
      siteId,
      unitRangeMax,
      unitRangeMin,
      unitPrefix
    }) => {
      const searchString = [
        getSearchQuery("SiteId", siteId, true),
        getSearchQuery("UnitRangeMin", unitRangeMin),
        getSearchQuery("UnitRangeMax", unitRangeMax),
        getSearchQuery("UnitPrefix", unitPrefix)
      ].join('')

      return `api/Project/ValidateAutoGenerateUnitName${searchString}`
    }
  },
  getContactSiteRoleList: {
    method: 'GET',
    url: ({
      contactId = 0,
      pageNumber,
      pageSize,
      genericSearch = "",
      advanceSearch = {},
      orderColumn,
      orderDirections
    }) => {
      let searchString = [
        getSearchQuery("ContactId", contactId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          organizations = [],
          sitePartyRoles = [],
          sites = []
        } = advanceSearch

        const siteIdsList = getSearchQuery("SiteIds", sites.map((site) => site.value).join(","))
        const sitePartyRoleIdsList = getSearchQuery("SitePartyRoleIds", sitePartyRoles.map((role) => role.value).join(","))
        const organizationIdsList = getSearchQuery("OrganizationIds", organizations.map((org) => org.organizationId).join(","))

        searchString = [
          searchString,
          siteIdsList,
          sitePartyRoleIdsList,
          organizationIdsList
        ].join('')
      }

      return `api/Project/Sites/${contactId}${searchString}`
    }
  },
  getSitePartyRoleVM: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      search
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", search, false, true)
      ].join('')

      return `api/Project/Sites/GetSitePartyRoleVM${searchString}`
    }
  },
  deleteSiteRoles: {
    method: 'DELETE',
    url: (siteRoleIds = []) => {
      return `api/Project/Sites/DeleteSiteRoles/${siteRoleIds}`
    }
  },
  addSiteRoles: {
    method: 'POST',
    url: 'api/Project/Sites/AddSiteRoles'
  },
  getSiteUnLinkedSharedCampaigns: {
    method: 'GET',
    url: ({
      siteId = 0,
      pageNumber,
      pageSize,
      genericSearch = "",
      orderColumn,
      orderDirections
    }) => {
      const searchString = [
        getSearchQuery("SiteId", siteId, true),
        getSearchQuery("CampaignName", genericSearch, false, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      return `api/Project/GetSiteUnLinkedSharedCampaigns/${siteId}${searchString}`
    }
  },
  getSiteCampaignsMissingSiteRoles: {
    method: 'GET',
    url: ({ siteId, campaigns = [] }) => {

      let searchString = campaigns.map((data) => getSearchQuery("CampaignIds", data.campaignId)).join("")
      searchString = searchString.replace('&', '?')

      return `api/Project/DiscrepancySiteRoles/${siteId}${searchString}`
    }
  },
  addSharedCampaignsToSite: {
    method: 'PUT',
    url: ({ siteId }) => `api/Project/AddSharedCampaigns/${siteId}`
  }
}

export default apiConfig