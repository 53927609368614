import { useCallback, useEffect, useState } from 'react'
import { ChevronDown, X } from "react-feather"
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Button, Form, Row, Col, FormGroup, Input, Modal, ModalBody } from 'reactstrap'

import '@styles/react/libs/charts/apex-charts.scss'
import '@styles/base/pages/dashboard-ecommerce.scss'
import PropTypes from 'prop-types'
import * as actions from '@store/actions/organization'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { ErrorMessage, ReactSelect, ParentOrganizationSearch, CustomLabel } from '@views/components'

import { validateOrgDetails } from './helpers'
import localeMessageWrapper from '../../locale-message'

const { ORGANIZATION_SEARCH, ORGANIZATIONS, CANCEL } = LANGUAGE_CONSTANTS

const OrganizationModal = ({
  intl,
  isEditMode,
  isOpen,
  fetchAddOrganizationDetailVM,
  addOrganizationDetail,
  editOrganizationDetail,
  validateOrganizationName,
  onGetOrganizationList,
  onSelect,
  onToggleModal
}) => {
  const { control } = useForm({})
  const [orgDetail, setOrgDetail] = useState({
    organizationTypeId: '',
    organizationCategoryId: '',
    organizationName: '',
    parentOrganizationName: '',
    organizationId: '',
    externalId: ''
  })
  const [errors, setErrors] = useState({})
  const [orgId, setOrgId] = useState(0)
  const [organizationDetailVM, setOganizationDetailVM] = useState({
    organizationType: [],
    parentOrganization: [],
    organizationCategory: []
  })

  const handleToggleModal = useCallback(() => {
    setOrgDetail({
      organizationTypeId: '',
      organizationCategoryId: '',
      organizationName: '',
      parentOrganizationName: '',
      organizationId: '',
      externalId: ''
    })
    onToggleModal()
  }, [onToggleModal])

  useEffect(() => {
    fetchAddOrganizationDetailVM((data) => {
      if (data) {
        setOganizationDetailVM(data)
      }
    })
  }, [])

  const handleChangeOrgDetail = useCallback((key, value) => {
    if (isEditMode) {
      setOrgDetailUpdated(true)
    }

    const updateOrgDetail = Object.assign({}, orgDetail)
    updateOrgDetail[key] = value
    setOrgDetail(updateOrgDetail)
    setErrors({})
  }, [orgDetail])

  const handleAddOrg = useCallback(() => {
    const errors = validateOrgDetails(orgDetail)

    if (!!Object.keys(errors).length) {
      setErrors(errors)
      return
    }

    const { externalId, organizationCategoryId, organizationName, organizationTypeId, parentOrganizationName } = orgDetail
    validateOrganizationName({ organizationName, organizationId: parseInt(orgId) }, (result) => {
      if (!result) {
        if (isEditMode) {
          editOrganizationDetail({
            organizationId: parseInt(orgId),
            organizationName,
            organizationTypeId: parseInt(organizationTypeId),
            organizationParentId: parseInt(parentOrganizationName),
            organizationCategoryId: parseInt(organizationCategoryId),
            externalId
          }, () => {
            handleToggleModal()
          })
        } else {
          addOrganizationDetail({
            entityId: 1,
            organizationName,
            organizationTypeId: parseInt(organizationTypeId),
            organizationParentId: parseInt(parentOrganizationName),
            organizationCategoryId: parseInt(organizationCategoryId),
            externalId
          }, (result) => {
            if (result) {
              onSelect({ organizationName, organizationId: result }, () => {
                onGetOrganizationList({ organizationName, organizationId: result })
              })
            }
            handleToggleModal()
          })
        }
      }
    })

  }, [isEditMode, orgId, orgDetail, addOrganizationDetail, editOrganizationDetail, onGetOrganizationList, onSelect, handleToggleModal])


  const pathKeyList = window.location.pathname.split("/")
  const id = pathKeyList.pop()

  const { organizationTypeId, organizationCategoryId, organizationName, parentOrganizationName, organizationId, externalId } = orgDetail


  // ** Custom close btn
  const CloseBtn = <X className='cursor-pointer' size={15} onClick={handleToggleModal} />

  return (
    <Modal
      isOpen={isOpen}
      className='modal-dialog-centered all-modal add-organization-modal'
      contentClassName=''
    >

      <ModalBody className='p-0'>
        <div className='mb-2' toggle={handleToggleModal} close={CloseBtn} tag='div'>
          <h5 className='modal-title'>{getLocaleMessage(intl, isEditMode ? ORGANIZATION_SEARCH.EDIT_ORGANIZATION : ORGANIZATIONS.ADD_ORGANIZATION)}</h5>
        </div>
        <Form>
          <Row form>
            <Col className="mb-1" sm={12} md={6} xl={4}>
              <FormGroup>
                <CustomLabel title={ORGANIZATIONS.ORGANIZATION_NAME} required />
                <Input
                  type="text"
                  name="organization_name"
                  placeholder={getLocaleMessage(intl, ORGANIZATIONS.ORGANIZATION_NAME)}
                  value={organizationName}
                  onChange={(event) => handleChangeOrgDetail('organizationName', event.target.value)}
                />
              </FormGroup>
              <ErrorMessage isShow={!!errors['organizationName']} message={errors['organizationName']} />
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={4}>
              <ReactSelect
                id="organization_type"
                name="organization_type"
                label={getLocaleMessage(intl, ORGANIZATIONS.ORGANIZATION_TYPE)}
                placeHolder={getLocaleMessage(intl, ORGANIZATIONS.ORGANIZATION_TYPE)}
                control={control}
                value={organizationTypeId}
                onSelect={(selected) => handleChangeOrgDetail('organizationTypeId', selected ? selected.value : '')}
                getOptionLabel={(item) => item.text}
                options={organizationDetailVM.organizationType}
                isRequired
              />
              <ErrorMessage isShow={!!errors['organizationTypeId']} message={errors['organizationTypeId']} />
            </Col>
            {isEditMode && <Col className="mb-1" sm={12} md={6} xl={4}>
              <FormGroup>
                <CustomLabel title={ORGANIZATIONS.ORGANIZATION_ID} />
                <Input
                  type="text"
                  name="text"
                  placeholder={getLocaleMessage(intl, ORGANIZATIONS.ORG_ID)}
                  value={organizationId}
                  onChange={(event) => handleChangeOrgDetail('organizationId', event.target.value)}
                  disabled
                />
              </FormGroup>
            </Col>}
            <ParentOrganizationSearch
              value={parentOrganizationName}
              onSelect={handleChangeOrgDetail}
            />
            <Col className="mb-1" sm={12} md={6} xl={4}>
              <ReactSelect
                id="organization_category"
                name="organization_category"
                label={getLocaleMessage(intl, ORGANIZATIONS.ORGANIZATION_CATEGORY)}
                placeHolder={getLocaleMessage(intl, ORGANIZATIONS.ORGANIZATION_CATEGORY)}
                control={control}
                value={organizationCategoryId}
                getOptionLabel={(item) => item.text}
                options={organizationDetailVM.organizationCategory}
                onSelect={(selected) => handleChangeOrgDetail('organizationCategoryId', selected ? selected.value : '')}
                isRequired
              />
              <ErrorMessage isShow={!!errors['organizationCategoryId']} message={errors['organizationCategoryId']} />
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={4}>
              <FormGroup>
                <CustomLabel title={ORGANIZATIONS.EXTERNAL_ID} forValue="react-select" />
                <Input type="text" placeholder="Enter ID" value={externalId} onChange={(event) => handleChangeOrgDetail('externalId', event.target.value)} />
              </FormGroup>
            </Col>
          </Row>
          {/* ToDo: No Designs */}
          {/* <div className="mb-1">
            <button
              type="button"
              className="waves-effect btn advanced-btn more-less-btn ml-0"
            >
              <ChevronDown />
              {getLocaleMessage(intl, ADDRESS.ADDRESS)}
            </button>
          </div> */}
          <div className="text-right">
            <Button
              outline
              onClick={handleToggleModal}
              className={"delete-button mr-1"}
            >
              {getLocaleMessage(intl, CANCEL)}
            </Button>
            <Button className="add-button" onClick={handleAddOrg}>
              {getLocaleMessage(intl, isEditMode ? ORGANIZATION_SEARCH.UPDATE_ORG : ORGANIZATION_SEARCH.ADD_ORG)}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addOrganizationDetail: (payload, callback) => actions.addOrganizationDetailRequest(payload, callback),
      editOrganizationDetail: (payload, callback) => actions.editOrganizationDetailRequest(payload, callback),
      fetchAddOrganizationDetailVM: actions.getAddOrganizationDetailVMRequest,
      validateOrganizationName: actions.validateOrganizationNameRequest
    },
    dispatch
  )
}

OrganizationModal.propTypes = {
  intl: PropTypes.object,
  isEditMode: PropTypes.bool,
  isOpen: PropTypes.bool,
  fetchAddOrganizationDetailVM: PropTypes.func,
  addOrganizationDetail: PropTypes.func,
  editOrganizationDetail: PropTypes.func,
  validateOrganizationName: PropTypes.func,
  onGetOrganizationList: PropTypes.func,
  onSelect: PropTypes.func,
  onToggleModal: PropTypes.func
}

export default withRouter(
  connect(null, mapDispatchToProps)(localeMessageWrapper(OrganizationModal))
)
