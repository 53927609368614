export const GET_CORD_CLIENT_TOKEN_REQUEST = 'GET_CORD_CLIENT_TOKEN_REQUEST'

/****************************************************/
/** GET_CORD_CLIENT_TOKEN_REQUEST **/
/****************************************************/

/**
 * @method getCordClientTokenRequest :  To get client token for cord
 *
 */
export const getCordClientTokenRequest = (payload, callback = () => { }) => {
    return {
      type: GET_CORD_CLIENT_TOKEN_REQUEST,
      payload,
      callback
    }
  }