import { put, takeLatest, call } from 'redux-saga/effects'

import {
  addProjectDetails,
  getProjectDetails,
  updateProjectDetails,
  addProjectAddress,
  getProjectAddress,
  updateProjectAddress,
  deleteProject,
  getCreateProjectVM,
  getProjectList,
  getAuthorityName,
  deleteProjectLocation,
  getProjectListVM,
  getProjectSiteSearch,
  getSiteRoleAssignmentDDL,
  getProjectSiteDDL,
  getSiteUnits,
  getContactProjectList,
  getProjectsFilteredOrganizations,
  addProjectSiteQATag,
  deleteProjectSiteQATag,
  getHomeSiteDDL,
  validateAutoGeneratedUnitNames,
  getContactSiteRoleList,
  getSitePartyRoleVM,
  deleteSiteRoles,
  addSiteRoles,
  getSiteUnLinkedSharedCampaigns,
  getSiteCampaignsMissingSiteRoles,
  addSharedCampaignsToSite
} from '@services/api'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'
import * as actions from '@store/actions'

const { PROJECT_SAGA, ADDRESS_SAGA, PROJECT_SITE_UNIT_SAGA } = LANGUAGE_CONSTANTS

/** getProjectDetails API **/
function* getProjectDetailsRequest(action) {
  try {
    const { isLoadingRequired = false } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getProjectDetails, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** addProjectDetails API **/
function* addProjectDetailsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addProjectDetails, action.payload)
    if (response?.data) {
      action.callback(response.data)
      Notification({ status: 200, message: PROJECT_SAGA.ADDED_SUCCESS })
    } else {
      Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_ADDING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_ADDING })
  }
}

/** updateProjectDetails API **/
function* updateProjectDetailsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateProjectDetails, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: PROJECT_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_UPDATING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_UPDATING })
    action.callback(false)
  }
}

/** getProjectAddress API **/
function* getProjectAddressRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getProjectAddress, action.payload)
    action.callback(response?.data || [])
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** addProjectAddress API **/
function* addProjectAddressRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addProjectAddress, action.payload)
    if (response?.data) {
      action.callback(response.data)
      if (!action.payload.isUpdate) {
        Notification({ status: 200, message: PROJECT_SAGA.ADDRESS_ADDED_SUCCESS })
      }
    } else {
      Notification({ status: 400, message: ADDRESS_SAGA.ERROR_IN_ADDING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: ADDRESS_SAGA.ERROR_IN_ADDING })
  }
}

/** updateProjectAddress API **/
function* updateProjectAddressRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateProjectAddress, action.payload)
    if (response?.status === 204) {
      action.callback()
      Notification({ status: 200, message: PROJECT_SAGA.ADDRESS_UPDATE_SUCCESS })
    } else {
      Notification({ status: 400, message: PROJECT_SAGA.ADDRESS_UPDATE_ERROR })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: PROJECT_SAGA.ADDRESS_UPDATE_ERROR })
  }
}

/** getAuthorityName API **/
function* getAuthorityNameRequest(action) {
  try {
    const response = yield call(getAuthorityName, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** deleteProjectApi API **/
function* deleteProjectRequest(data) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteProject, data.payload)
    if (response?.status === 204) {
      data.callback()
      Notification({ status: 200, message: PROJECT_SAGA.DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_DELETING })
  }
}

/** deleteProjectLocation API **/
function* deleteProjectLocationRequest(data) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteProjectLocation, data.payload)
    if (response?.status === 204) {
      data.callback()
      Notification({ status: 200, message: PROJECT_SAGA.ADDRESS_DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_DELETING_ADDRESS })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_DELETING_ADDRESS })
  }
}

/** getCreateProjectVM API **/
function* getCreateProjectVMRequest(action) {
  try {
    const response = yield call(getCreateProjectVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getProjectList API **/
function* getProjectListRequest(action) {
  try {
    const { isSearchVM, isLoadingRequired = true, ...payload } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getProjectList, payload)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    if (response?.data) {
      action.callback({ ...response.data, pageSize: payload.pageSize })
    } else {
      action.callback()
      Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_FETCHING_LIST })
    }
  } catch (error) {
    const { isLoadingRequired } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    Notification({ status: 400, isLocalizedMessage: !(error.response?.statusText || error.message), message: error.response?.statusText || error.message || PROJECT_SAGA.ERROR_IN_FETCHING_LIST })
    action.callback()
  }
}

/** getProjectListVM API **/
function* getProjectListVMRequest(action) {
  try {
    const response = yield call(getProjectListVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getProjectSiteSearch API **/
function* getProjectSiteSearchRequest(action) {
  try {
    const response = yield call(getProjectSiteSearch, action.payload)
    action.callback(response?.data || false)
  } catch (error) {
    action.callback(false)
  }
}

/** getSiteRoleAssignmentDDL API **/
function* getSiteRoleAssignmentDDLRequest(action) {
  try {
    const response = yield call(getSiteRoleAssignmentDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getProjectSiteDDL API **/
function* getProjectSiteDDLRequest(action) {
  try {
    const response = yield call(getProjectSiteDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getSiteUnits API **/
function* getSiteUnitsRequest(action) {
  try {
    const response = yield call(getSiteUnits, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getContactProjectList API **/
function* getContactProjectListRequest(action) {
  try {
    const { isLoadingRequired = false, ...payload } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getContactProjectList, payload)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    if (response?.data) {
      action.callback({ ...response.data, pageSize: payload.pageSize })
    } else {
      action.callback()
      Notification({ status: 400, message: PROJECT_SITE_SAGA.ERROR_IN_FETCHING_LIST })
    }
  } catch (error) {
    const { isLoadingRequired } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    Notification({ status: 400, isLocalizedMessage: !(error.response?.statusText || error.message), message: error.response?.statusText || error.message || PROJECT_SITE_SAGA.ERROR_IN_FETCHING_LIST })
    action.callback()
  }
}

/** getProjectsFilteredOrganizations API **/
function* getProjectsFilteredOrganizationsRequest(action) {
  try {
    const response = yield call(getProjectsFilteredOrganizations, action.payload)
    action.callback(response?.data || false)
  } catch (error) {
    action.callback(false)
  }
}

/** addProjectSiteQATag API **/
function* addProjectSiteQATagRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addProjectSiteQATag, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_SAGA.QA_TAG_ADDED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_ADDING_QA_TAG })
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** deleteProjectSiteQATag API **/
function* deleteProjectSiteQATagRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteProjectSiteQATag, action.payload)
    if (response && response.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_SAGA.QA_TAG_DELETED_SUCCESS })
    } else {
      action.callback(false)
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_DELETING_QA_TAG })
    }
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getHomeSiteDDL API **/
function* getHomeSiteDDLRequest(action) {
  try {
    const response = yield call(getHomeSiteDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** validateAutoGeneratedUnitNames API **/
function* validateAutoGeneratedUnitNamesRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(validateAutoGeneratedUnitNames, action.payload)
    if (response?.data) {
      action.callback(response.data)
      Notification({ status: 304, message: PROJECT_SITE_UNIT_SAGA.ALREADY_IN_USE })
    } else {
      action.callback(null)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(null)
  }
}

/** getContactSiteRoleList API **/
function* getContactSiteRoleListRequest(action) {
  try {
    const response = yield call(getContactSiteRoleList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
      Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_FETCHING_CONTACT_SITE_ROLE_LIST })
    }
  } catch (error) {
    Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_FETCHING_CONTACT_SITE_ROLE_LIST })
    action.callback()
  }
}

/** getSitePartyRoleVM API **/
function* getSitePartyRoleVMRequest(action) {
  try {
    const response = yield call(getSitePartyRoleVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** deleteSiteRoles API **/
function* deleteSiteRolesRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteSiteRoles, action.payload)
    if (response && response.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_SAGA.SITE_ROLE_DELETED_SUCCESS })
    } else {
      action.callback(false)
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_DELETING_SITE_ROLE })
    }
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** addSiteRoles API **/
function* addSiteRolesRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addSiteRoles, action.payload)
    if (response?.status === 200) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_SAGA.SITE_ROLES_ADDED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_ADDING_SITE_ROLES })
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getSiteUnLinkedSharedCampaigns API **/
function* getSiteUnLinkedSharedCampaignsRequest(action) {
  try {
    const response = yield call(getSiteUnLinkedSharedCampaigns, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getSiteCampaignsMissingSiteRoles API **/
function* getSiteCampaignsMissingSiteRolesRequest(action) {
  try {
    const response = yield call(getSiteCampaignsMissingSiteRoles, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** addSharedCampaignsToSite API */
function* addSharedCampaignsToSiteRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addSharedCampaignsToSite, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: PROJECT_SAGA.SHARED_CAMPAIGNS_ADDED_SUCCESS })
    } else {
      Notification({ status: 400, message: PROJECT_SAGA.ERROR_IN_ADDING_SHARED_CAMPAIGNS })
      action.callback()
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

export function* projectsSagas() {
  yield takeLatest(actions.ADD_PROJECT_DETAILS_REQUEST, addProjectDetailsRequest)
  yield takeLatest(actions.GET_PROJECT_DETAILS_REQUEST, getProjectDetailsRequest)
  yield takeLatest(actions.UPDATE_PROJECT_DETAILS_REQUEST, updateProjectDetailsRequest)
  yield takeLatest(actions.ADD_PROJECT_ADDRESS_REQUEST, addProjectAddressRequest)
  yield takeLatest(actions.GET_PROJECT_ADDRESS_REQUEST, getProjectAddressRequest)
  yield takeLatest(actions.UPDATE_PROJECT_ADDRESS_REQUEST, updateProjectAddressRequest)
  yield takeLatest(actions.GET_AUTHORITY_NAME_REQUEST, getAuthorityNameRequest)
  yield takeLatest(actions.DELETE_PROJECT_REQUEST, deleteProjectRequest)
  yield takeLatest(actions.DELETE_PROJECT_LOCATION_REQUEST, deleteProjectLocationRequest)
  yield takeLatest(actions.GET_CREATE_PROJECT_VM_REQUEST, getCreateProjectVMRequest)
  yield takeLatest(actions.GET_PROJECT_LIST_REQUEST, getProjectListRequest)
  yield takeLatest(actions.GET_PROJECT_LIST_VM_REQUEST, getProjectListVMRequest)
  yield takeLatest(actions.GET_PROJECT_SITE_SEARCH_REQUEST, getProjectSiteSearchRequest)
  yield takeLatest(actions.GET_SITE_ROLE_ASSIGNMENT_DDL_REQUEST, getSiteRoleAssignmentDDLRequest)
  yield takeLatest(actions.GET_PROJECT_SITE_DDL_REQUEST, getProjectSiteDDLRequest)
  yield takeLatest(actions.GET_SITE_UNITS_REQUEST, getSiteUnitsRequest)
  yield takeLatest(actions.GET_CONTACT_PROJECT_LIST_REQUEST, getContactProjectListRequest)
  yield takeLatest(actions.GET_PROJECTS_FILTERED_ORGANIZATIONS, getProjectsFilteredOrganizationsRequest)
  yield takeLatest(actions.ADD_PROJECT_SITE_QA_TAG_REQUEST, addProjectSiteQATagRequest)
  yield takeLatest(actions.DELETE_PROJECT_SITE_QA_TAG_REQUEST, deleteProjectSiteQATagRequest)
  yield takeLatest(actions.GET_HOME_SITE_DDL_REQUEST, getHomeSiteDDLRequest)
  yield takeLatest(actions.VALIDATE_AUTO_GENERATED_UNIT_NAMES_REQUEST, validateAutoGeneratedUnitNamesRequest)
  yield takeLatest(actions.GET_CONTACT_SITE_ROLE_LIST_REQUEST, getContactSiteRoleListRequest)
  yield takeLatest(actions.GET_SITE_PARTY_ROLE_VM_REQUEST, getSitePartyRoleVMRequest)
  yield takeLatest(actions.DELETE_SITE_ROLES_REQUEST, deleteSiteRolesRequest)
  yield takeLatest(actions.ADD_SITE_ROLES_REQUEST, addSiteRolesRequest)
  yield takeLatest(actions.GET_SITE_UNLINKED_SHARED_CAMPAIGNS_REQUEST, getSiteUnLinkedSharedCampaignsRequest)
  yield takeLatest(actions.GET_SITE_CAMPAIGNS_MISSING_SITE_ROLES_REQUEST, getSiteCampaignsMissingSiteRolesRequest)
  yield takeLatest(actions.ADD_SHARED_CAMPAIGNS_TO_SITE_REQUEST, addSharedCampaignsToSiteRequest)
}

export default projectsSagas
