import config from "../config"
import { postDataApi } from '../index'

// app settings
import AppSettings from '@app-settings'

const { REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DATABASE_CONNECTION_NAME } = AppSettings

export const forgotPassword = (data) => {
  const { url } = config.forgotPassword

  const payload = JSON.stringify({
    client_id: REACT_APP_AUTH0_CLIENT_ID,
    email: data.email,
    connection: REACT_APP_AUTH0_DATABASE_CONNECTION_NAME
  })

  return postDataApi(
    url(),
    payload,
    {
      'Content-Type': 'application/json'
    },
    { pathConfig: { auth0Api: true } }
  )
}

export default forgotPassword
