import { RECORDABLE_MANAGEMENT_TYPE } from "@views/campaigns/add-new-campaign/workflow/block/recordable-template/recordable-settings/config"
import config from "./APIConfig"
import { putDataApi } from '../../index'

/**
 * @method updateBlockScheduleRecordableSettings
 * @param {object} data 
 * 
 * {
 *  wfCampaignSetupId: "",
 *  criteria: "",
 *  recordableManagement: ""
 *  assignmentValue: [] or ""
 *  assignmentLabel: ""
 * }
 * 
 * API payload:-
 * 
 * {
  "wfCampaignSetupId": 0,
  "wfScheduleRecordablesPastDueDateId": 0,
  "wfRolePersonAssignmentId": 0,
  "siteRoleIds": [
    {
      "siteRoleId": 0
    }
  ]
}
 */

export const updateBlockScheduleRecordableSettings = (data) => {
  const { url } = config.updateBlockScheduleRecordableSettings

  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    wfScheduleRecordablesPastDueDateId: Number(data.recordableSettingsDetails.criteria),
    wfRolePersonAssignmentId: Number(data.recordableSettingsDetails.recordableManagement)
  }

  if (data.recordableSettingsDetails.recordableManagement === RECORDABLE_MANAGEMENT_TYPE.ROLE) {
    payload.sitePartyRoleIds = data.recordableSettingsDetails.assignmentValue.map(siteRole => ({ sitePartyRoleId: siteRole.sitePartyRoleId }))
  } else if (data.recordableSettingsDetails.recordableManagement === RECORDABLE_MANAGEMENT_TYPE.PERSON) {
    payload.contactIds = data.recordableSettingsDetails.assignmentValue.map(contact => ({ contactId: contact.contactId }))
  }

  return putDataApi(url(data.wfCampaignSetupId), payload)
}

export default updateBlockScheduleRecordableSettings
