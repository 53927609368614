import React, { useState, useCallback, useEffect, useMemo } from "react"
import {
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { TABLE_LIST_KEYS, SCHEDULING_TASK_STATUS_TYPES } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { SECTION_TAB_TYPES } from "@views/campaigns/overview/campaign-details/config"
import {
  CustomUILoader,
  SideModalCloseButton,
  DeleteConfirmationModal
} from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import { RESOURCE_CENTER_SOURCE_TYPES } from "@views/components/resource-center-uploader/config"
import { SECTION_TYPES } from "./config"
import { getPurchaseOrderDetailActiveTaskText, getFormattedPurchaseOrderDetails, validatePurchaseOrderDetails, uploadAttachedPOFiles } from "./helpers"
import PurchaseOrderDetailForm from "./PurchaseOrderDetailForm"

const {
  SCHEDULING: {
    ADDITIONAL_PURCHASE_ORDER
  }
} = LANGUAGE_CONSTANTS

const INITIAL_PURCHASE_ORDER_DATA = {
  additionalPOId: "",
  billingType: null,
  costCategory: null,
  additionalPOName: "",
  additionalPOFiles: []
}

const PurchaseOrderModal = ({
  intl,
  taskList = [],
  data,
  isRemoveHold = false,
  isAdminTasks,
  modalTitle = '',
  isManagePurchaseOrder = false,
  isSubGrid = false,

  /*******************/
  /** To handle modal state for updating individual additional purchase order item */
  isUpdatingOrderDetail,
  orderId,
  primaryPurchaseOrderNumber,
  /*******************/

  onSetNextActiveTask = () => { },
  onToggleModal = () => { },
  onUpdateRowData = () => { }
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(
    INITIAL_PURCHASE_ORDER_DATA
  )
  const [isFetchingAdditionalBilling, setFetchingAdditionalBilling] = useState(false)
  const [purchaseOrderList, setPurchaseOrderList] = useState([])
  const [primaryPurchaseOrder, setPrimaryPurchaseOrder] = useState(isUpdatingOrderDetail ? primaryPurchaseOrderNumber : "")
  const [productDetails, setProductDetails] = useState({})
  const [primaryPOFiles, setPrimaryPOFiles] = useState([])
  const [purchaseOrderVM, setPurchaseOrderVM] = useState({})
  const [activeTask, setActiveTask] = useState({})
  const [isEditMode, setEditMode] = useState(isUpdatingOrderDetail && orderId)
  const [isFetching, setFetching] = useState(false)
  const [isProductDetailsFetched, setProductDetailsFetched] = useState(false)
  const [isOpenPurchaseOrderForm, setIsOpenPurchaseOrderForm] = useState(isUpdatingOrderDetail || false)
  const [isEditPrimaryPurchaseOrder, setEditPrimaryPurchaseOrder] = useState(false)
  const [errors, setErrors] = useState({})
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false)
  const [activeSections, setActiveSections] = useState([SECTION_TYPES.PRIMARY, SECTION_TYPES.ADDITIONAL])

  const handleToggleActiveSection = useCallback((id) => {
    let updatedActiveSections = [...activeSections]
    if (updatedActiveSections.includes(id)) {
      updatedActiveSections = updatedActiveSections.filter((sectionId) => sectionId !== id)
    } else updatedActiveSections.push(id)
    setActiveSections(updatedActiveSections)
  }, [activeSections])

  const handleRefreshOrderDetailsPurchaseOrderList = useCallback(() => {
    dispatch(
      actions.refreshTableList({
        listKey: TABLE_LIST_KEYS.SCHEDULING_CONTRACT_ORDER_DETAILS_LIST_KEY,
        value: true
      }))
  }, [])

  const handleTogglePurchaseOrderForm = useCallback(() => {
    setPurchaseOrderDetails(INITIAL_PURCHASE_ORDER_DATA)
    setIsOpenPurchaseOrderForm(prev => !prev)
  }, [])

  const handleFetchPurchaseOrderList = useCallback(() => {
    if (!isUpdatingOrderDetail) {
      setFetching(true)
      dispatch(actions.getPurchaseOrderListRequest({ observationTaskId: activeTask?.observationTaskId || data?.observationTaskId }, res => {
        setProductDetailsFetched(true)
        if (res) {
          const pOList = getFormattedPurchaseOrderDetails(res.additionalPurchaseOrders)
          setPurchaseOrderList(pOList)
          setPrimaryPurchaseOrder(res.primaryPurchaseOrderNumber)
          setPrimaryPOFiles(res.primaryPurchaseOrderFiles || [])
          setProductDetails({
            campaignId: res.campaignId,
            primaryProductIdList: res.primaryProductIdList?.filter(id => !!id) || [],
            isNonBillable: res.isNonBillable,
            amount: res.amount
          })
        }
        setFetching(false)
      }))
    }
  }, [activeTask?.observationTaskId, data, isUpdatingOrderDetail])

  useEffect(() => {
    if (!!activeTask?.observationTaskId || !!data?.observationTaskId) {
      handleFetchPurchaseOrderList()
    }
  }, [activeTask?.observationTaskId, data?.observationTaskId])

  useEffect(() => {
    if (!!activeTask?.observationTaskId || !!data?.observationTaskId) {
      dispatch(actions.getPurchaseOrderVMRequest({ observationTaskId: activeTask.observationTaskId || data.observationTaskId }, (response) => {
        if (response) {
          setPurchaseOrderVM(response)
        }
      }))
    }
  }, [activeTask?.observationTaskId, data?.observationTaskId])

  //To select first task & start adding purchase order details for it
  useEffect(() => {
    if (!isRemoveHold) {
      const initialSelectedTask = taskList[0]
      setActiveTask(initialSelectedTask)
    }
  }, [])

  useEffect(() => {
    if (isRemoveHold) {
      const initialSelectedTask = taskList[0]
      setActiveTask(initialSelectedTask)
    }
  }, [taskList])

  const handleChangeDetails = useCallback(
    (key, value) => {
      if (!!Object.keys(errors).length) {
        setErrors({})
      }
      if (key === "primaryPOFiles") {
        setPrimaryPOFiles(value)
        return
      }
      if (key === 'primaryPurchaseOrder') {
        setPrimaryPurchaseOrder(value)
      }
      const updatedDetails = Object.assign({}, purchaseOrderDetails)
      updatedDetails[key] = value
      setPurchaseOrderDetails(updatedDetails)
    },
    [purchaseOrderDetails, primaryPurchaseOrder, errors])

  const handleTogglePurchaseOrderModal = useCallback(() => {
    if (isSubGrid) {
      handleRefreshOrderDetailsPurchaseOrderList()
    }
    onToggleModal()
  }, [onToggleModal, handleRefreshOrderDetailsPurchaseOrderList])

  const handleSelectNextTask = useCallback(() => {
    if (isRemoveHold) {
      onSetNextActiveTask()
    } else {
      const selectedTaskIndex = taskList.findIndex(
        (task) => task.observationTaskId === activeTask.observationTaskId
      )
      if (selectedTaskIndex !== -1) {
        const nextActiveTask = taskList[selectedTaskIndex + 1]
        if (nextActiveTask) {
          setActiveTask(nextActiveTask)
          setPurchaseOrderDetails(Object.assign({}, INITIAL_PURCHASE_ORDER_DATA))
          setPurchaseOrderList([])
        } else {
          onToggleModal()
        }
      } else {
        onToggleModal()
      }
    }
  }, [
    activeTask,
    isRemoveHold,
    taskList,
    onSetNextActiveTask,
    onToggleModal
  ])

  const handleSaveDetails = useCallback(() => {
    const error = validatePurchaseOrderDetails(purchaseOrderDetails)
    if (!!Object.keys(error).length) {
      setErrors(error)
      return
    }

    const payload = {
      observationTaskId: activeTask?.observationTaskId || data?.observationTaskId,
      additionalPOId: purchaseOrderDetails.additionalPOId,
      billingTypeId: parseInt(purchaseOrderDetails.billingType.value),
      contractId: parseInt(purchaseOrderDetails.costCategory.value),
      additionalPurchaseOrderNumber: purchaseOrderDetails.additionalPOName
    }

    const responseCallback = (res) => {
      if (res) {
        if (purchaseOrderDetails.additionalPOFiles?.length) {
          uploadAttachedPOFiles({
            attachedPOFiles: purchaseOrderDetails.additionalPOFiles,
            observationTaskId: payload.observationTaskId,
            fileLocationType: RESOURCE_CENTER_SOURCE_TYPES.ADDITIONAL_PURCHASE_ORDER,
            additionalPOId: isEditMode ? purchaseOrderDetails.additionalPOId : res,
            dispatch,
            callback: () => {
              if (!isUpdatingOrderDetail) {
                setPurchaseOrderDetails(INITIAL_PURCHASE_ORDER_DATA)
                handleFetchPurchaseOrderList()
                setEditMode(false)
              }
            }
          })
        } else {
          if (!isUpdatingOrderDetail) {
            setPurchaseOrderDetails(INITIAL_PURCHASE_ORDER_DATA)
            handleFetchPurchaseOrderList()
            setEditMode(false)
          }
        }
      }
    }

    if (isEditMode) {
      // To update purchase order details for selected task
      dispatch(actions.updateAdditionalPurchaseOrderRequest(payload, responseCallback))
    } else {
      // To add purchase order details for selected task
      dispatch(actions.addAdditionalPurchaseOrderRequest(payload, responseCallback))
    }
    setIsOpenPurchaseOrderForm(false)

  }, [activeTask, isEditMode, purchaseOrderDetails, handleFetchPurchaseOrderList, data, isUpdatingOrderDetail])

  const handleUpdateTaskStatus = useCallback(
    (statusId) => {
      dispatch(
        actions.updateTaskStatusRequest(
          {
            listOfTaskIds: [activeTask.observationTaskId],
            statusId,
            isLoadingRequired: true
          },
          (res) => {
            if (res) {
              const updatedRowData = isAdminTasks ?
              {
                taskStatus: res[0].taskStatusName
              } : {
                status: res[0].taskStatusName
              }
              handleSelectNextTask()
              onUpdateRowData({
                observationTaskId: activeTask.observationTaskId,
                updatedValue: updatedRowData
              })
            }
          }
        )
      )
    }, [activeTask?.observationTaskId, isAdminTasks, handleSelectNextTask, onUpdateRowData])

  const handleSubmit = useCallback(() => {
    if (activeTask?.observationTaskId) {
      handleUpdateTaskStatus((SCHEDULING_TASK_STATUS_TYPES.READY))
    }
  }, [activeTask?.observationTaskId, handleUpdateTaskStatus])

  const handleSavePrimaryPurchaseOrder = useCallback(() => {
    const payload = {
      observationTaskId: data?.observationTaskId || activeTask?.observationTaskId,
      primaryPurchaseOrderNumber: primaryPurchaseOrder
    }

    const responseCallback = (res) => {
      if (res) {
        if (primaryPOFiles.length) {
          uploadAttachedPOFiles({
            attachedPOFiles: primaryPOFiles,
            observationTaskId: payload.observationTaskId,
            fileLocationType: RESOURCE_CENTER_SOURCE_TYPES.PRIMARY_PURCHASE_ORDER,
            dispatch,
            callback: () => {
              handleTogglePurchaseOrderModal()
            }
          })
        } else {
          handleTogglePurchaseOrderModal()
        }
      }
    }
    dispatch(actions.updatePrimaryPurchaseOrderRequest(payload, responseCallback))
  }, [data, activeTask, primaryPurchaseOrder, primaryPOFiles, handleTogglePurchaseOrderModal])

  const handleToggleDeleteModal = useCallback(() => {
    setOpenDeleteModal((prevState) => !prevState)
  }, [])

  const handleGoBack = useCallback(() => {
    onToggleModal()
  }, [onToggleModal])

  const handleCancelEditMode = useCallback(() => {
    setPurchaseOrderDetails(Object.assign({}, INITIAL_PURCHASE_ORDER_DATA))
    setEditMode(false)
    setErrors({})
  }, [])

  const handleDelete = useCallback((id) => {
    if (!!id) {
      dispatch(actions.deleteAdditionalPurchaseOrderRequest({ additionalPurchaseOrderId: id }, (res) => {
        if (res) {
          if (isUpdatingOrderDetail) {
            onToggleModal()
            handleRefreshOrderDetailsPurchaseOrderList()
          } else {
            handleFetchPurchaseOrderList()
            setIsOpenPurchaseOrderForm(false)
            setEditMode(false)
            setErrors({})
            setPurchaseOrderDetails(INITIAL_PURCHASE_ORDER_DATA)
          }
        }
      }))
    }
  }, [handleFetchPurchaseOrderList, handleRefreshOrderDetailsPurchaseOrderList, isUpdatingOrderDetail])

  const handleSkip = useCallback(() => {
    if (activeTask?.observationTaskId) {
      handleUpdateTaskStatus(SCHEDULING_TASK_STATUS_TYPES.READY)
    }
  }, [activeTask?.observationTaskId, handleUpdateTaskStatus])

  const handleEditPrimaryPurchaseOrder = useCallback(() => {
    setEditPrimaryPurchaseOrder(true)
  }, [])

  const handleFetchPurchaseOrderDetail = useCallback((poId) => {
    setFetchingAdditionalBilling(true)
    dispatch(actions.getAdditionalPurchaseOrderRequest({ additionalPurchaseOrderId: poId }, (res) => {
      if (res) {
        setPurchaseOrderDetails({
          additionalPOId: res.additionalPurchaseOrderId,
          billingType: { text: res.billingTypeName, value: String(res.billingTypeId) },
          costCategory: { text: res.costCategoryName, value: String(res.costCategoryId) },
          additionalPOName: res.additionalPurchaseOrderNumber,
          additionalPOFiles: res.additionalPurchaseOrderFiles || []
        })
      }
      setFetchingAdditionalBilling(false)
    }))
  }, [])

  const handleEditMode = useCallback((data) => {
    setErrors({})
    if (isUpdatingOrderDetail) {
      handleRefreshOrderDetailsPurchaseOrderList()
    } else {
      handleTogglePurchaseOrderForm()
      setEditMode(true)
      if (!!data.additionalPOId) {
        handleFetchPurchaseOrderDetail(data.additionalPOId)
      }
    }
  }, [handleTogglePurchaseOrderForm, isEditMode, isUpdatingOrderDetail, handleFetchPurchaseOrderDetail, handleRefreshOrderDetailsPurchaseOrderList])

  useEffect(() => {
    if (isUpdatingOrderDetail && orderId) {
      handleFetchPurchaseOrderDetail(orderId)
    } else if (isUpdatingOrderDetail && !orderId) {
      onToggleModal()
    }
  }, [isUpdatingOrderDetail, orderId])

  const handleGoToContractPage = useCallback(() => {
    window.open(`/campaigns/overview/${productDetails.campaignId}?activeTab=${SECTION_TAB_TYPES.CONTRACTS}`, "_blank")
  }, [productDetails.campaignId])

  const activeTaskText = useMemo(() => {
    return getPurchaseOrderDetailActiveTaskText({
      activeTask,
      taskList
    })
  }, [activeTask, taskList]) 

  const getPurchaseOrderDetailForm = useCallback(() => {
    return (
      <PurchaseOrderDetailForm 
        activeTask={activeTask}
        data={data}
        errors={errors}
        isEditMode={isEditMode}
        isEditPrimaryPurchaseOrder={isEditPrimaryPurchaseOrder}
        isManagePurchaseOrder={isManagePurchaseOrder}
        isOpenPurchaseOrderForm={isOpenPurchaseOrderForm}
        isRemoveHold={isRemoveHold}
        isUpdatingOrderDetail={isUpdatingOrderDetail}
        purchaseOrderDetails={purchaseOrderDetails}
        isFetchingAdditionalBilling={isFetchingAdditionalBilling}
        purchaseOrderList={purchaseOrderList}
        purchaseOrderVM={purchaseOrderVM}
        primaryPurchaseOrder={primaryPurchaseOrder}
        productDetails={productDetails}
        primaryPOFiles={primaryPOFiles}
        activeSections={activeSections}
        isProductDetailsFetched={isProductDetailsFetched}
        onChangeDetails={handleChangeDetails}
        onDelete={handleDelete}
        onEditMode={handleEditMode}
        onCancelEditMode={handleCancelEditMode}
        onEditPrimaryPurchaseOrder={handleEditPrimaryPurchaseOrder}
        onGoBack={handleGoBack}
        onSaveDetails={handleSaveDetails}
        onSavePrimaryPurchaseOrder={handleSavePrimaryPurchaseOrder}
        onSkip={handleSkip}
        onSubmit ={handleSubmit}
        onTogglePurchaseOrderModal={handleTogglePurchaseOrderModal}
        onTogglePurchaseOrderForm={handleTogglePurchaseOrderForm}
        onToggleActiveSection={handleToggleActiveSection}
        onGoToContractPage={handleGoToContractPage}
      />
    )
  }, [
    isProductDetailsFetched,
    activeTask,
    activeSections,
    data,
    errors,
    isEditMode,
    isEditPrimaryPurchaseOrder,
    isManagePurchaseOrder,
    isOpenPurchaseOrderForm,
    isRemoveHold,
    isUpdatingOrderDetail,
    purchaseOrderDetails,
    isFetchingAdditionalBilling,
    purchaseOrderList,
    purchaseOrderVM,
    primaryPurchaseOrder,
    handleChangeDetails,
    handleDelete,
    handleEditMode,
    handleCancelEditMode,
    handleEditPrimaryPurchaseOrder,
    handleGoBack,
    handleSaveDetails,
    handleSavePrimaryPurchaseOrder,
    handleSkip,
    handleSubmit,
    handleTogglePurchaseOrderModal,
    handleTogglePurchaseOrderForm,
    handleToggleActiveSection,
    handleGoToContractPage
  ])

  if (isRemoveHold) {
    return (
      <>
        {getPurchaseOrderDetailForm()}
        {isEditMode && (
          <DeleteConfirmationModal
            id={purchaseOrderDetails.additionalPOId}
            open={isOpenDeleteModal}
            onToggleModal={handleToggleDeleteModal}
            onConfirmDeleteRecord={() => handleDelete(purchaseOrderDetails.additionalPOId)}
            type={ADDITIONAL_PURCHASE_ORDER}
          />
        )}
      </>
    )
  }

  return (
    <>
      <Modal
        isOpen
        className={"sidebar-sm"}
        modalClassName={"modal-slide-in note-media-modal schedule-time-modal purchase-order-modal"}
        contentClassName="p-0"
      >
        <ModalHeader
          className="mb-2"
          toggle={handleTogglePurchaseOrderModal}
          close={<SideModalCloseButton onClose={handleTogglePurchaseOrderModal} />}
          tag="div"
        >
          <h5 className="modal-title">
            {getLocaleMessage(intl, modalTitle)}
            {activeTaskText}
          </h5>
        </ModalHeader>
        <CustomUILoader isLoading={isFetching}>
          <ModalBody className="flex-grow-1">
            {getPurchaseOrderDetailForm()}
          </ModalBody>
        </CustomUILoader>
      </Modal>
      {isEditMode && (
        <DeleteConfirmationModal
          id={purchaseOrderDetails.additionalPOId}
          open={isOpenDeleteModal}
          onToggleModal={handleToggleDeleteModal}
          onConfirmDeleteRecord={() => handleDelete(purchaseOrderDetails.additionalPOId)}
          type={ADDITIONAL_PURCHASE_ORDER}
        />
      )}
    </>
  )
}

export default localeMessageWrapper(PurchaseOrderModal)
