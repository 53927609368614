import { useCallback } from "react"
import { Edit3, MoreVertical } from "react-feather"
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap"
import { useHistory } from "react-router-dom"

// ** Custom Components
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { LocaleMessage } from "@views/components"
import { ROUTES } from "@shared/constants"

const RowActions = ({ data }) => {
  const history = useHistory()

  const handleEditTask = useCallback(
    (event) => {
      event.preventDefault()
      const path = ROUTES.manageTaskById
      history.push(`${path.slice(0, path.length - 3)}${data.observationTaskId}`)
    },
    [data]
  )

  return (
    <>
      <div className="d-flex">
        <a>
          <Edit3 size={15} onClick={handleEditTask} />
        </a>
        <UncontrolledDropdown>
          <DropdownToggle className="px-1" tag="span">
            <MoreVertical size={15} />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag="a" className="w-100" onClick={() => {
              // ToDo
            }}>
              <span className="align-middle ml-50">
                <LocaleMessage id={LANGUAGE_CONSTANTS.TABLE.VIEW} />
              </span>
            </DropdownItem>
            <DropdownItem tag="a" className="w-100" onClick={handleEditTask}>
              <span className="align-middle ml-50">
                <LocaleMessage id={LANGUAGE_CONSTANTS.TABLE.EDIT} />
              </span>
            </DropdownItem>
            <DropdownItem
              tag="a"
              href="/"
              className="w-100"
              onClick={(event) => {
                // ToDo
                event.preventDefault()
              }}
            >
              <span className="align-middle ml-50 text-danger">
                <LocaleMessage id={LANGUAGE_CONSTANTS.TABLE.DELETE} />
              </span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </>
  )
}

export default RowActions
