import {
  GET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST,
  RESET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST
} from "@store/actions"

const initialState = {
  tableData: {},
  error: ''
}

const campaignServiceChecklistCheckpointReducer = (state = initialState, action) => {

  switch (action.type) {

    /** SET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST **/

    case GET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST.REQUEST:
      return { ...state, error: "" }

    case GET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST.SUCCESS:
      const { checklistId, data } = action.data
      return {
        ...state,
        tableData: {
          ...state.tableData,
          [`checklist_${checklistId}`]: {
            checklistId,
            data: {
              list: data.items,
              pageSize: data.pageSize,
              pageNumber: data.pageIndex,
              totalCount: data.totalCount,
              totalPages: data.totalPages,
              hasPreviousPage: data.hasPreviousPage,
              hasNextPage: data.hasNextPage
            }
          }
        }
      }

    case GET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST.FAILURE:
      return {
        ...state,
        error: action.error
      }

    /** RESET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST **/
    case RESET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST:
      const temp = Object.assign({}, state.tableData)
      delete temp[`checklist_${action.payload}`]
      return {
        ...state,
        ...temp
      }

    default:
      return state
  }
}

export default campaignServiceChecklistCheckpointReducer
