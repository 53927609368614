export const GET_SAMPLING_WORKFLOW_LIST_REQUEST = 'GET_SAMPLING_WORKFLOW_LIST_REQUEST'
export const GET_SAMPLING_WORKFLOW_LIST_VM_REQUEST = 'GET_SAMPLING_WORKFLOW_LIST_VM_REQUEST'
export const GET_SAMPLING_WORKFLOW_UNITS_REQUEST = 'GET_SAMPLING_WORKFLOW_UNITS_REQUEST'
export const GET_SAMPLING_WORKFLOW_UNITS_VM_REQUEST = 'GET_SAMPLING_WORKFLOW_UNITS_VM_REQUEST'
export const GET_SAMPLING_OVERVIEW_DETAILS_REQUEST = 'GET_SAMPLING_OVERVIEW_DETAILS_REQUEST'
export const GET_SAMPLING_WORKFLOW_GROUPS_REQUEST = 'GET_SAMPLING_WORKFLOW_GROUPS_REQUEST'
export const GET_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST = 'GET_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST'
export const CREATE_SAMPLING_WORKFLOW_GROUP_REQUEST = 'CREATE_SAMPLING_WORKFLOW_GROUP_REQUEST'
export const DELETE_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST = 'DELETE_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST'
export const DELETE_SAMPLING_WORKFLOW_GROUP_REQUEST = 'DELETE_SAMPLING_WORKFLOW_GROUP_REQUEST'
export const UPDATE_SAMPLING_WORKFLOW_GROUP_UNIT_TYPE_REQUEST = 'UPDATE_SAMPLING_WORKFLOW_GROUP_UNIT_TYPE_REQUEST'
export const ADD_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST = 'ADD_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST'
export const ADD_SAMPLING_WORKFLOW_GROUP_UNIT_TYPE_REQUEST = 'ADD_SAMPLING_WORKFLOW_GROUP_UNIT_TYPE_REQUEST'
export const GET_SAMPLING_WORKFLOW_GROUP_DETAILS_REQUEST = 'GET_SAMPLING_WORKFLOW_GROUP_DETAILS_REQUEST'
export const UPDATE_OPEN_SAMPLING_GROUP_REQUEST = 'UPDATE_OPEN_SAMPLING_GROUP_REQUEST'
export const UPDATE_CLOSE_SAMPLING_GROUP_REQUEST = 'UPDATE_CLOSE_SAMPLING_GROUP_REQUEST'

/****************************************************/
/** GET_SAMPLING_WORKFLOW_LIST_REQUEST **/
/****************************************************/

/**
 * @method getSamplingWorkflowListRequest : To get sampling workflow list
 *
 */
export const getSamplingWorkflowListRequest = (payload, callback) => ({
  type: GET_SAMPLING_WORKFLOW_LIST_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_SAMPLING_WORKFLOW_LIST_VM_REQUEST **/
/****************************************************/

/**
 * @method getSamplingWorkflowListVMRequest : To get sampling workflow list vm
 *
 */
export const getSamplingWorkflowListVMRequest = (callback) => ({
  type: GET_SAMPLING_WORKFLOW_LIST_VM_REQUEST,
  callback
})

/****************************************************/
/** GET_SAMPLING_WORKFLOW_UNITS **/
/****************************************************/

/**
 * @method getSamplingWorkflowUnitsRequest : To get sampling workflow units list
 *
 */
export const getSamplingWorkflowUnitsRequest = (payload, callback) => ({
  type: GET_SAMPLING_WORKFLOW_UNITS_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_SAMPLING_WORKFLOW_UNITS_VM **/
/****************************************************/

/**
 * @method getSamplingWorkflowUnitsVMRequest : To get sampling workflow units VM
 *
 */
export const getSamplingWorkflowUnitsVMRequest = (payload, callback) => ({
  type: GET_SAMPLING_WORKFLOW_UNITS_VM_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_SAMPLING_OVERVIEW_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method getSamplingOverviewDetailsRequest : To get sampling overview details
 *
 */
export const getSamplingOverviewDetailsRequest = (payload, callback) => ({
  type: GET_SAMPLING_OVERVIEW_DETAILS_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_SAMPLING_WORKFLOW_GROUPS **/
/****************************************************/

/**
 * @method getSamplingWorkflowGroupsRequest : To get sampling workflow groups list
 *
 */
export const getSamplingWorkflowGroupsRequest = (payload, callback) => ({
  type: GET_SAMPLING_WORKFLOW_GROUPS_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST **/
/****************************************************/

/**
 * @method getSamplingWorkflowGroupUnitsRequest : To get sampling workflow group units list
 *
 */
export const getSamplingWorkflowGroupUnitsRequest = (payload, callback) => ({
  type: GET_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST,
  payload,
  callback
})

/****************************************************/
/** CREATE_SAMPLING_WORKFLOW_GROUP_REQUEST **/
/****************************************************/

/**
 * @method createSamplingWorkflowGroupRequest : To add sampling workflow groups
 *
 */
export const createSamplingWorkflowGroupRequest = (payload, callback) => ({
  type: CREATE_SAMPLING_WORKFLOW_GROUP_REQUEST,
  payload,
  callback
})

/****************************************************/
/** DELETE_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST **/
/****************************************************/

/**
 * @method deleteSamplingWorkflowGroupUnitsRequest : To delete sampling workflow group units
 *
 */
export const deleteSamplingWorkflowGroupUnitsRequest = (payload, callback) => ({
  type: DELETE_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST,
  payload,
  callback
})

/****************************************************/
/** DELETE_SAMPLING_WORKFLOW_GROUP_REQUEST **/
/****************************************************/

/**
 * @method deleteSamplingWorkflowGroupRequest : To delete sampling workflow group
 *
 */
export const deleteSamplingWorkflowGroupRequest = (payload, callback) => ({
  type: DELETE_SAMPLING_WORKFLOW_GROUP_REQUEST,
  payload,
  callback
})

/****************************************************/
/** UPDATE_SAMPLING_WORKFLOW_GROUP_UNIT_TYPE_REQUEST **/
/****************************************************/

/**
 * @method updateSamplingWorkflowGroupUnitTypeRequest : To update sampling workflow group unit type
 *
 */
export const updateSamplingWorkflowGroupUnitTypeRequest = (payload, callback) => ({
  type: UPDATE_SAMPLING_WORKFLOW_GROUP_UNIT_TYPE_REQUEST,
  payload,
  callback
})

/****************************************************/
/** ADD_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST **/
/****************************************************/

/**
 * @method addSamplingWorkflowGroupUnitsRequest : To add new unit/s to a group
 *
 */
export const addSamplingWorkflowGroupUnitsRequest = (payload, callback) => ({
  type: ADD_SAMPLING_WORKFLOW_GROUP_UNITS_REQUEST,
  payload,
  callback
})

/****************************************************/
/** ADD_SAMPLING_WORKFLOW_GROUP_UNIT_TYPE_REQUEST **/
/****************************************************/

/**
 * @method addSamplingWorkflowGroupUnitTypeRequest : To add sampling workflow group unit type
 *
 */
export const addSamplingWorkflowGroupUnitTypeRequest = (payload, callback) => ({
  type: ADD_SAMPLING_WORKFLOW_GROUP_UNIT_TYPE_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_SAMPLING_WORKFLOW_GROUP_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method getSamplingWorkflowGroupDetailsRequest : To get sampling workflow group details
 *
 */
 export const getSamplingWorkflowGroupDetailsRequest = (payload, callback) => ({
  type: GET_SAMPLING_WORKFLOW_GROUP_DETAILS_REQUEST,
  payload,
  callback
})

/****************************************************/
/** UPDATE_OPEN_SAMPLING_GROUP_REQUEST **/
/****************************************************/

/**
 * @method updateOpenSamplingGroupRequest : To open sampling workflow group for adding units
 *
 */
 export const updateOpenSamplingGroupRequest = (payload, callback) => ({
  type: UPDATE_OPEN_SAMPLING_GROUP_REQUEST,
  payload,
  callback
})

/****************************************************/
/** UPDATE_CLOSE_SAMPLING_GROUP_REQUEST **/
/****************************************************/

/**
 * @method updateCloseSamplingGroupRequest : To close sampling workflow group
 *
 */
 export const updateCloseSamplingGroupRequest = (payload, callback) => ({
  type: UPDATE_CLOSE_SAMPLING_GROUP_REQUEST,
  payload,
  callback
})
