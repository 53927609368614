import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addNewCheckpointVersion
 * @param {object} data 
 *
 */
export const addNewCheckpointVersion = (data) => {
  const { url } = config.addNewCheckpointVersion

  return postDataApi(url(data), {})
}

export default addNewCheckpointVersion
