import { put, takeLatest, call } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

const { REPORTS: { EXPORT_EMAIL_SUCCESS, EXPORT_EMAIL_FAILED }, DASHBOARD: { ERRORS } } = LANGUAGE_CONSTANTS

/** getRoleList API **/
function* getPowerBiAccessTokenRequest(action) {
  try {
    const response = yield call(services.getPowerBiAccessToken, action.payload)
    action.callback(response?.data || false)
  } catch (error) {
    action.callback(false)
  }
}

/** getReportList API **/
function* getReportListRequest(action) {
  try {
    const response = yield call(services.getReportList, action.payload)
    action.callback(response?.data || false)
  } catch (error) {
    action.callback(false)
  }
}

/** getUnacceptableActivitiesReport API **/
function* getUnacceptableActivitiesReportRequest(action) {
  try {
    const response = yield call(services.getUnacceptableActivitiesReport, action.payload)
    action.callback({ ...response?.data, pageSize: action.payload?.pageSize })
  } catch (error) {
    action.callback()
  }
}

/** getUnapprovedTrainingReport API **/
function* getUnapprovedTrainingReportRequest(action) {
  try {
    const response = yield call(services.getUnapprovedTrainingReport, action.payload)
    action.callback({ ...response?.data, pageSize: action.payload?.pageSize })
  } catch (error) {
    action.callback()
  }
}

/** getDailyInspectionReport API **/
function* getDailyInspectionReportRequest(action) {
  try {
    const response = yield call(services.getDailyInspectionReport, action.payload)
    action.callback({ ...response?.data, pageSize: action.payload?.pageSize })
  } catch (error) {
    action.callback()
  }
}

/** getUnacceptableActivitiesVM API */
function* getUnacceptableActivitiesVMRequest(action) {
  try {
    const response = yield call(services.getUnacceptableActivitiesVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getReportAssignedContactsDDL API **/
function* getReportAssignedContactsDDLRequest(action) {
  try {
    const response = yield call(services.getReportAssignedContactList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getUnacceptableActivitiesSitePartyRoleDDL API **/
function* getUnacceptableActivitiesSitePartyRoleDDLRequest(action) {
  try {
    const response = yield call(services.getUnacceptableActivitiesSitePartyRoleDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getReportsSiteDDL API */
function* getReportsSiteDDLRequest(action) {
  try {
    const response = yield call(services.getReportsSiteDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getTrainingActivitiesIncomplete API */
function* getTrainingActivitiesIncompleteRequest(action) {
  try {
    const response = yield call(services.getTrainingActivitiesIncomplete, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getOpenUnacceptableActivities API */
function* getOpenUnacceptableActivitiesRequest(action) {
  try {
    const response = yield call(services.getOpenUnacceptableActivities, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getSaturationRateSummary API */
function* getSaturationRateSummaryRequest(action) {
  try {
    const response = yield call(services.getSaturateRateSummary, action.payload)
    action.callback({ ...response?.data, pageSize: action.payload?.pageSize })
  } catch (error) {
    action.callback(null)
  }
}

/** getSaturationRateDetails API */
function* getSaturationRateDetailsRequest(action) {
  try {
    const response = yield call(services.getSaturationDetailsSummary, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/**getReportExportDocument API */
function* getReportExportDocumentRequest(action) {
  try {
    const response = yield call(services.getExportReportDocument, action.payload)
    action.callback(response?.data)
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(null)
  }
}

/** getTrainingActivitiesFailed API */
function* getTrainingActivitiesFailedRequest(action) {
  try {
    const response = yield call(services.getTrainingActivitiesFailed, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getTrainingActivitiesStatus API */
function* getTrainingActivitiesStatusRequest(action) {
  try {
    const response = yield call(services.getTrainingActivitiesStatus, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getTrainingCampaignReports API */
function* getTrainingCampaignReportRequest(action) {
  try {
    const response = yield call(services.getTrainingCampaignReport, action.payload)
    action.callback({ ...response?.data, pageSize: action.payload?.pageSize })
  } catch (error) {
    action.callback(null)
  }
}

/** getCompletedUnitsWidget API */
function* getCompletedUnitsWidgetRequest(action) {
  try {
    const response = yield call(services.getCompletedUnitsWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getWarrantyClaimEstimateWidget API */
function* getWarrantyClaimEstimateWidgetRequest(action) {
  try {
    const response = yield call(services.getWarrantyClaimEstimateWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getWarrantyCostEstimateWidget API */
function* getWarrantyCostEstimateWidgetRequest(action) {
  try {
    const response = yield call(services.getWarrantyCostEstimateWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getConstructionCallbacksCostsWidget API */
function* getConstructionCallbacksCostsWidgetRequest(action) {
  try {
    const response = yield call(services.getConstructionCallbacksCostsWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getProductionCycleTimeWidget API */
function* getProductionCycleTimeWidgetRequest(action) {
  try {
    const response = yield call(services.getProductionCycleTimeWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getConstructionCallbacksWidget API */
function* getConstructionCallbacksWidgetRequest(action) {
  try {
    const response = yield call(services.getConstructionCallbacksWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getReportDetail API */
function* getReportDetailRequest(action) {
  try {
    const response = yield call(services.getReportDetail, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getWorkflowDDL API */
function* getWorkflowDDLRequest(action) {
  try {
    const response = yield call(services.getWorkflowDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getTaskListDDL API */
function* getTaskListDDLRequest(action) {
  try {
    const response = yield call(services.getTaskListDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getUnitCloseout API **/
function* getUnitCloseoutListRequest(action) {
  try {
    const response = yield call(services.getUnitCloseout, action.payload)
    action.callback({ ...response?.data, pageSize: action.payload?.pageSize })
  } catch (error) {
    action.callback()
  }
}

/** getTradePerformanceList API **/
function* getTradePerformanceListRequest(action) {
  try {
    const response = yield call(services.getTradePerformanceReport, action.payload)
    action.callback({ ...response?.data, pageSize: action.payload?.pageSize })
  } catch (error) {
    action.callback()
  }
}

/** getNewObservationCampaign API **/
function* getNewObservationCampaignRequest(action) {
  try {
    const response = yield call(services.getNewObservationCampaignWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getUnaprovedCampaigns API **/
function* getUnaprovedCampaignsRequest(action) {
  try {
    const response = yield call(services.getUnaprovedCampaignsWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTrainingActivities API **/
function* getTrainingActivitiesRequest(action) {
  try {
    const response = yield call(services.getTrainingActivitiesWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTrainingCampaignVM API **/
function* getTrainingCampaignVMRequest(action) {
  try {
    const response = yield call(services.getTrainingCampaignVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getActivityTrainingSubjectDDL API **/
function* getActivityTrainingSubjectDDLRequest(action) {
  try {
    const response = yield call(services.getActivityTrainingSubjectDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getActivityTrainingChapterDDL API **/
function* getActivityTrainingChapterDDLRequest(action) {
  try {
    const response = yield call(services.getActivityTrainingChapterDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getActivityTrainingCourseDDL API **/
function* getActivityTrainingCourseDDLRequest(action) {
  try {
    const response = yield call(services.getActivityTrainingCourseDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getActivityTrainingLessonDDL API **/
function* getActivityTrainingLessonDDLRequest(action) {
  try {
    const response = yield call(services.getActivityTrainingLessonDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getEnergystarUnitsOpenWidget API */
function* getEnergystarUnitsOpenWidgetRequest(action) {
  try {
    const response = yield call(services.getEnergystarUnitsOpenWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getEnergystarUnitsFailedWidget API */
function* getEnergystarUnitsFailedWidgetRequest(action) {
  try {
    const response = yield call(services.getEnergystarUnitsFailedWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getEnergystarUnitsCertifiedWidget API */
function* getEnergystarUnitsCertifiedWidgetRequest(action) {
  try {
    const response = yield call(services.getEnergystarUnitsCertifiedWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getEnergystarTaxIncentiveWidget API */
function* getEnergystarTaxIncentiveWidgetRequest(action) {
  try {
    const response = yield call(services.getEnergystarTaxIncentiveWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getQualityScoreWidget API */
function* getQualityScoreWidgetRequest(action) {
  try {
    const response = yield call(services.getQualityScoreWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getResourceCenterExportDocument API */
function* getResourceCenterExportDocumentRequest(action) {
  try {
    const response = yield call(services.getResourceCenterExportDocument, action.payload)
    action.callback(true)
  } catch (error) {
    action.callback()
  }
}

/** getAverageCycleTimeWidget API */
function* getAverageCycleTimeWidgetRequest(action) {
  try {
    const response = yield call(services.getAverageCycleTimeWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getAverageFailedActivitiesWidget API */
function* getAverageFailedActivitiesWidgetRequest(action) {
  try {
    const response = yield call(services.getAverageFailedActivitiesWidget, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getCampaignTaskStatusReport API */
function* getCampaignTaskStatusReportRequest(action) {
  try {
    const response = yield call(services.getCampaignTaskStatusReport, action.payload)
    action.callback({ ...response.data, pageSize: action.payload.pageSize })
  } catch (error) {
    action.callback()
  }
}

/** getCampaignTaskStatusReportVM API */
function* getCampaignTaskStatusReportVMRequest(action) {
  try {
    const response = yield call(services.getCampaignTaskStatusReportVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTaskSummaryReportVM API */
function* getTaskSummaryReportVMRequst(action) {
  try {
    const response = yield call(services.getTaskSummaryVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getUnitStatusReport API */
function* getUnitStatusReportRequest(action) {
  try {
    const response = yield call(services.getUnitStatusReport, action.payload)
    action.callback({ ...response.data, pageSize: action.payload.pageSize })
  } catch (error) {
    action.callback()
  }
}

/** sendReportEmail API */
function* sendReportEmailRequest(action) {
  try {
    const response = yield call(services.sendReportEmail, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: EXPORT_EMAIL_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: EXPORT_EMAIL_FAILED })
    }
  } catch (error) {
    action.callback()
  }
}

/** getReportFileStatus API */
function* getReportFileStatusRequest(action) {
  try {
    const response = yield call(services.getReportFileStatus, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getReportDashboardsList API **/
function* getReportDashboardsListRequest(action) {
  try {
    const response = yield call(services.getReportDashboardsList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback(false)
  }
}

/** getDashboardWidgets API */
function* getDashboardWidgetsRequest(action) {
  try {
    const response = yield call(services.getDashboardWidgets, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getReportDashboardsVM API */
function* getReportDashboardsVMRequest(action) {
  try {
    const response = yield call(services.getReportDashboardsVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updatePriorityAndStatusColumn API */
function* updatePriorityAndStatusColumnRequest(action) {
  try {
    const response = yield call(services.updatePriorityAndStatusColumn, action.payload)
    if (response?.status === 204) {
      action.callback(true)
    } else {
      action.callback(false)
    }
  } catch (error) {
    action.callback()
  }
}

/** toggleAuditRandomPercent API */
function* toggleAuditRandomPercentRequest(action) {
  try {
    const response = yield call(services.toggleAuditRandomPercent, action.payload)
    if (response?.status === 204) {
      action.callback(true)
    } else {
      action.callback(false)
    }
  } catch (error) {
    action.callback()
  }
}

/** toggleAuditWithCost API */
function* toggleAuditWithClauseRequest(action) {
  try {
    const response = yield call(services.toggleAuditWithCause, action.payload)
    if (response?.status === 204) {
      action.callback(true)
    } else {
      action.callback(false)
    }
  } catch (error) {
    action.callback()
  }
}

/** getDashboardOrganizationDDL API **/
function* getDashboardOrganizationDDLRequest(action) {
  try {
    const response = yield call(services.getDashboardOrganizationDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getDashboardSiteDDL API **/
function* getDashboardSiteDDLRequest(action) {
  try {
    const response = yield call(services.getDashboardSiteDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getExceptionDashboardList API **/
function* getExceptionDashboardListRequest(action) {
  try {
    const response = yield call(services.getExceptionDashboardList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback(false)
  }
}

/** updateSchedulingException API **/
function* updateSchedulingExceptionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateSchedulingException, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: ERRORS.EXCEPTION_RESOLVED_SUCCESS })
      yield put(actions.setIsLoading(false))
    } else {
      Notification({ status: 400, message: ERRORS.FAILED_TO_RESOLVE_EXCEPTION })
      action.callback(false)
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    Notification({ status: 400, message: ERRORS.FAILED_TO_RESOLVE_EXCEPTION })
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

export function* reportSagas() {
  yield takeLatest(actions.GET_POWER_BI_ACCESS_TOKEN_REQUEST, getPowerBiAccessTokenRequest)
  yield takeLatest(actions.GET_REPORT_LIST_REQUEST, getReportListRequest)
  yield takeLatest(actions.GET_UNACCEPTABLE_ACTIVITIES_REPORT, getUnacceptableActivitiesReportRequest)
  yield takeLatest(actions.GET_UNAPPROVED_TRAINING_REPORT, getUnapprovedTrainingReportRequest)
  yield takeLatest(actions.GET_DAILY_INSPECTION_REPORT, getDailyInspectionReportRequest)
  yield takeLatest(actions.GET_REPORT_ASSIGNED_CONTACTS_DDL_REQUEST, getReportAssignedContactsDDLRequest)
  yield takeLatest(actions.GET_UNACCEPTABLE_ACTIVITIES_VM_REQUEST, getUnacceptableActivitiesVMRequest)
  yield takeLatest(actions.GET_UNACCEPTABLE_ACTIVITIES_SITE_PARTY_ROLE_DDL_REQUEST, getUnacceptableActivitiesSitePartyRoleDDLRequest)
  yield takeLatest(actions.GET_REPORT_SITE_DDL_REQUEST, getReportsSiteDDLRequest)
  yield takeLatest(actions.GET_TRAINING_ACTIVITIES_INCOMPLETE_REQUEST, getTrainingActivitiesIncompleteRequest)
  yield takeLatest(actions.GET_OPEN_UNACCEPTABLE_ACTIVITIES_REQUEST, getOpenUnacceptableActivitiesRequest)
  yield takeLatest(actions.GET_SATURATION_RATE_SUMMARY_REPORT_REQUEST, getSaturationRateSummaryRequest)
  yield takeLatest(actions.GET_SATURATION_RATE_DETAILS_REPORT_REQUEST, getSaturationRateDetailsRequest)
  yield takeLatest(actions.GET_EXPORT_DOCUMENT_DETAILS_REQUEST, getReportExportDocumentRequest)
  yield takeLatest(actions.GET_TRAINING_ACTIVITIES_FAILED_REQUEST, getTrainingActivitiesFailedRequest)
  yield takeLatest(actions.GET_TRAINING_ACTIVITIES_STATUS_REQUEST, getTrainingActivitiesStatusRequest)
  yield takeLatest(actions.GET_TRAINING_CAMPAIGN_REPORT_REQUEST, getTrainingCampaignReportRequest)
  yield takeLatest(actions.GET_COMPLETED_UNITS_WIDGET_REQUEST, getCompletedUnitsWidgetRequest)
  yield takeLatest(actions.GET_WARRANTY_CLAIM_ESTIMATE_WIDGET, getWarrantyClaimEstimateWidgetRequest)
  yield takeLatest(actions.GET_WARRANTY_COST_ESTIMATE_WIDGET, getWarrantyCostEstimateWidgetRequest)
  yield takeLatest(actions.GET_CONSTRUCTION_CALLBACKS_COSTS_WIDGET, getConstructionCallbacksCostsWidgetRequest)
  yield takeLatest(actions.GET_PRODUCTION_CYCLE_TIME_WIDGET, getProductionCycleTimeWidgetRequest)
  yield takeLatest(actions.GET_CONSTRUCTION_CALLBACKS_WIDGET, getConstructionCallbacksWidgetRequest)
  yield takeLatest(actions.GET_REPORT_DETAIL_REQUEST, getReportDetailRequest)
  yield takeLatest(actions.GET_WORKFLOW_LIST_DDL_REQUEST, getWorkflowDDLRequest)
  yield takeLatest(actions.GET_TASK_LIST_DDL_REQUEST, getTaskListDDLRequest)
  yield takeLatest(actions.GET_UNIT_CLOSEOUT_REQUEST, getUnitCloseoutListRequest)
  yield takeLatest(actions.GET_TRADE_PERFORMANCE_REPORT_REQUEST, getTradePerformanceListRequest)
  yield takeLatest(actions.GET_NEW_OBSERVATION_CAMPAIGNS_WIDGET_REQUEST, getNewObservationCampaignRequest)
  yield takeLatest(actions.GET_UNAPPROVED_ACTIVITIES_WIDGET_REQUEST, getUnaprovedCampaignsRequest)
  yield takeLatest(actions.GET_TRAINING_ACTIVITIES_WIDGET_REQUEST, getTrainingActivitiesRequest)
  yield takeLatest(actions.TRAINING_CAMPAIGN_VM_REQUEST, getTrainingCampaignVMRequest)
  yield takeLatest(actions.GET_ACTIVITY_TRAINING_SUBJECT_REQUEST, getActivityTrainingSubjectDDLRequest)
  yield takeLatest(actions.GET_ACTIVITY_TRAINING_CHAPTER_REQUEST, getActivityTrainingChapterDDLRequest)
  yield takeLatest(actions.GET_ACTIVITY_TRAINING_COURSE_REQUEST, getActivityTrainingCourseDDLRequest)
  yield takeLatest(actions.GET_ACTIVITY_TRAINING_LESSON_REQUEST, getActivityTrainingLessonDDLRequest)
  yield takeLatest(actions.GET_ENERGY_STAR_UNITS_OPEN_WIDGET_REQUEST, getEnergystarUnitsOpenWidgetRequest)
  yield takeLatest(actions.GET_ENERGY_STAR_UNITS_FAILED_WIDGET_REQUEST, getEnergystarUnitsFailedWidgetRequest)
  yield takeLatest(actions.GET_ENERGY_STAR_UNITS_CERTIFIED_WIDGET_REQUEST, getEnergystarUnitsCertifiedWidgetRequest)
  yield takeLatest(actions.GET_ENERGY_STAR_TAX_INCENTIVE_WIDGET_REQUEST, getEnergystarTaxIncentiveWidgetRequest)
  yield takeLatest(actions.GET_QUALITY_SCORE_WIDGET_REQUEST, getQualityScoreWidgetRequest)
  yield takeLatest(actions.GET_RESOURCE_CENTER_EXPORT_DOCUMENT, getResourceCenterExportDocumentRequest)
  yield takeLatest(actions.GET_AVERAGE_CYCLE_TIME_WIDGET_REQUEST, getAverageCycleTimeWidgetRequest)
  yield takeLatest(actions.GET_AVERAGE_FAILED_ACTIVITIES_WIDGET_REQUEST, getAverageFailedActivitiesWidgetRequest)
  yield takeLatest(actions.GET_CAMPAIGN_TASK_STATUS_REPORT_REQUEST, getCampaignTaskStatusReportRequest)
  yield takeLatest(actions.GET_CAMPAIGN_TASK_STATUS_REPORT_VM_REQUEST, getCampaignTaskStatusReportVMRequest)
  yield takeLatest(actions.GET_TASK_SUMMARY_VM_REQUEST, getTaskSummaryReportVMRequst)
  yield takeLatest(actions.GET_UNIT_STATUS_REPORT_REQUEST, getUnitStatusReportRequest)
  yield takeLatest(actions.SEND_REPORT_EMAIL_REQUEST, sendReportEmailRequest)
  yield takeLatest(actions.GET_REPORT_FILE_STATUS_REQUEST, getReportFileStatusRequest)
  yield takeLatest(actions.GET_REPORT_DASHBOARD_LIST_REQUEST, getReportDashboardsListRequest)
  yield takeLatest(actions.GET_DASHBOARD_WIDGETS_REQUEST, getDashboardWidgetsRequest)
  yield takeLatest(actions.GET_REPORT_DASHBOARD_VM_REQUEST, getReportDashboardsVMRequest)
  yield takeLatest(actions.UPDATE_PRIORITY_STATUS_COLUMN_REQUEST, updatePriorityAndStatusColumnRequest)
  yield takeLatest(actions.TOGGLE_AUDIT_RANDOM_PERCENT_REQUEST, toggleAuditRandomPercentRequest)
  yield takeLatest(actions.TOGGLE_AUDIT_WITH_CLAUSE_REQUEST, toggleAuditWithClauseRequest)
  yield takeLatest(actions.GET_DASHBOARD_ORGANIZATION_DDL_REQUEST, getDashboardOrganizationDDLRequest)
  yield takeLatest(actions.GET_DASHBOARD_SITE_DDL_REQUEST, getDashboardSiteDDLRequest)
  yield takeLatest(actions.GET_EXCEPTION_DASHBOARD_LIST_REQUEST, getExceptionDashboardListRequest)
  yield takeLatest(actions.UPDATE_SCHEDULING_EXCEPTION, updateSchedulingExceptionRequest)
}

export default reportSagas
