import config from "./APIConfig"
import { getDataApi } from "../index"

export const getActionAuditTrail = async (data) => {
  const { url } = config.getActionAuditTrail
  return await getDataApi(url(data))
}

export const getActionDetails = async (data) => {
  const { url } = config.getActionDetails
  return await getDataApi(url(data))
}

export const getActionList = async (data) => {
  const { url } = config.getActionList
  return await getDataApi(url(data))
}

/**
 * @method getActionListVM
 *
 */
export const getActionListVM = () => {
  const { url } = config.getActionListVM
  return getDataApi(url)
}

export const getCorrectionDetails = async (data) => {
  const { url } = config.getCorrectionDetails
  return await getDataApi(url(data))
}

/**
 * @method getEntityContacts
 *
 */
export const getEntityContacts = (data) => {
  const { url } = config.getEntityContacts
  return getDataApi(url(data))
}

/**
 * @method getReassignFailedInspectionContact
 *
 */
export const getReassignFailedInspectionContact = (data) => {
  const { url } = config.getReassignFailedInspectionContact
  return getDataApi(url(data))
}

/**
 * @method getSignOffReasonVM
 *
 */
export const getSignOffReasonVM = () => {
  const { url } = config.getSignOffReasonVM
  return getDataApi(url)
}

/**
 * @method getSuperintendentToReassign
 *
 */
export const getSuperintendentToReassign = (data) => {
  const { url } = config.getSuperintendentToReassign
  return getDataApi(url(data))
}

/**
 * @method getTradeContactToReassign
 *
 */
export const getTradeContactToReassign = (data) => {
  const { url } = config.getTradeContactToReassign
  return getDataApi(url(data))
}
