import React, { useCallback, useEffect, useState, useMemo } from "react"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { CustomReactSelect, CustomLabel } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const { ADDRESS: { MARKET } } = LANGUAGE_CONSTANTS
const RegionSearch = ({
  intl,
  id = "region",
  name = "",
  value = "",
  initialLabel = "",
  stateCode = "",
  asyncInitialValue = false,
  isEditMode = false,
  isMulti = false,
  isDisabled = false,
  isCalendarPageRegionFilter = false,
  placeholder = "",
  labelRequired = true,
  isShowSelectedAtTop = false,
  customSelectedLable = "",
  customComponents = {},
  onSelect = () => { }
}) => {

  const dispatch = useDispatch()
  const [options, setOptions] = useState([])
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  useEffect(() => {
    const fn = () => {
      setTimeout(() => {
        setResetCache((prevState) => !prevState)
        setOptions([])
      }, [100])
    }
    fn()
  }, [stateCode])

  const handleLoadMore = useCallback((search, page, prevOptions, others) => {
    return new Promise((resolve) => {
      if (others.isReset && !prevOptions.length && options.length) {
        resolve({
          optionList: options,
          hasMore: true
        })
        return
      }
      const params = {
        pageNumber: page,
        pageSize: DEFAULT_VM_PAGE_SIZE,
        RegionName: search,
        isCalendarPage: isCalendarPageRegionFilter,
        stateCode
      }
      dispatch(
        actions.getRegionFilterListRequest(params, (response) => {
          if (response) {
            const { items, ...paginationData } = response
            let updatedItems = [...items]
            if (isShowSelectedAtTop && !search.trim()) {
              if (page === 1) {
                updatedItems = [...options, ...items.filter(item => options.every(option => item.value !== option.value))]
              } else {
                updatedItems = [...items.filter(item => value.every(option => item.value !== option.value))]
              }
            }

            resolve({
              optionList: updatedItems,
              hasMore: paginationData.hasNextPage
            })
            setOptions(() => (!!prevOptions.length ? [...prevOptions, ...response.items] : response.items))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        })
      )
    })
  }, [stateCode, isShowSelectedAtTop, options, value])

  const handleSelect = useCallback(
    (selected) => {
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        if (!!lastElement && !lastElement.value) {
          onSelect(id, [])
        } else {
          onSelect(id, selected)
        }
      } else {
        onSelect(id, selected?.value || "", (selected?.value && selected?.text) || "")
        setSelectedOptionLabel((selected?.value && selected?.text) || "")
      }
    },
    [onSelect, id]
  )

  useEffect(() => {
    if (!!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])

  useEffect(() => {
    if (!selectedOptionLabel?.length && !!value && !isMulti) {
      const selectedOption = options.find((item) => item.value === value)
      setSelectedOptionLabel(selectedOption?.text || "")
    }
  }, [selectedOptionLabel, value, options])

  useEffect(() => {
    if (isEditMode && asyncInitialValue && !!value && !!initialLabel) {
      setSelectedOptionLabel(initialLabel)
      setResetCache((prev) => !prev)
    }
  }, [asyncInitialValue, initialLabel, isEditMode, value])

  const handleRefreshOptions = useCallback(() => {
    setOptions([...value])   
    setResetCache(prevState => !prevState)
  }, [value])

  const tablePropsConfig = useMemo(() => {
    return {
      menuPortalTarget: document.body,
      styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) }
    }
  }, [])

  return (
    <>
      {labelRequired && <CustomLabel title={getLocaleMessage(intl, MARKET)} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        isDisabled={isDisabled}
        cacheUniqs={[isResetCache]}
        id={id}
        name={name}
        placeholder={placeholder || getLocaleMessage(intl, MARKET)}
        options={options}
        lableType={"text"}
        valueType={"value"}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={
          !isMulti
            ? !!value
              ? {
                text: selectedOptionLabel,
                value
              }
              : null
            : value
        }
        isMulti={isMulti}
        isShowCustomAction={false}
        customSelectedLable={customSelectedLable}
        hideSelectedOptions={false}
        isShowSelectedAtTop={isShowSelectedAtTop}
        getOptionLabel={(option) => option.text}
        getOptionValue={(option) => option.value}
        customComponents={customComponents}
        onLoadOptions={handleLoadMore}
        onRefreshOptions={handleRefreshOptions}
        onSelect={handleSelect}
        {...tablePropsConfig}
      />
    </>
  )
}

export default localeMessageWrapper(RegionSearch)