import PropTypes from 'prop-types'
import React, { useContext } from "react"

import ChecklistActivitiesTable from "@views/campaigns/add-new-campaign/workflow/block/assign-checklist/checklist-activities"
import { GridContext } from "./ag-data-grid/GridContext"

const ExpandableChecklistActivityList = ({
  data
}) => {
  const context = useContext(GridContext)

  return (<div className="expandable-table-content" >
    <ChecklistActivitiesTable
      wfCampaignSetupChecklistId={data.wfCampaignSetupChecklistId}
      isChildGrid
      isReviewActivity={context.isReviewActivity}
      tableViewHeaderRequired={false}
    />
  </div>
  )
}

ExpandableChecklistActivityList.propTypes = {
  data: PropTypes.object
}

export default ExpandableChecklistActivityList
