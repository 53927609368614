import React, { useState, useCallback, useEffect } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import * as actions from "@store/actions"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"
import PropTypes from 'prop-types'

const ProgramSearch = ({
  asyncInitialValue,
  initialLabel,
  isEditMode = false,
  isViewMode = false,
  errors,
  defaultOptions = [],
  id = "",
  isMulti = true,
  isRequired = false,
  isShowCustomAction = false,
  label,
  reportParams,
  labelRequired = true,
  name = "",
  placeholder,
  value = "",
  valueType = "value",
  labelType = "text",
  wrapperStyle,
  onSelect = () => { },
  getOptionLabel = (option) => option.text,
  getOptionValue = (option) => option.value
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState(defaultOptions)

  /**
  * Note: Paginated select maintain cache of options,
  * so to set new default options we need to reset previous cache
  */
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)

  /**
   * @method handleSetDefaultOptions : To handle set default options in case of Edit mode, Add new from modal
   *
   * Note: To handle case of duplicate option, when custom option is added, that is selected from edit mode or add new modal
   */
  const handleSetDefaultOptions = useCallback((selectedItem, programId) => {
    const checkIfAlreadyPresent = defaultOptions.find(option => option.value === programId)
    if (!!checkIfAlreadyPresent) {
      setOptions(defaultOptions)
    } else {
      setOptions([selectedItem, ...defaultOptions])
    }
  }, [defaultOptions])

  //Todo: handle edit mode when single select

  useEffect(() => {
    if (!isEditMode) {
      setResetCache(prevState => !prevState)
    }
    setOptions(defaultOptions)
  }, [defaultOptions, reportParams])

  useEffect(() => {
    //Note: When program detail is fetched in parent component
    if (!isEditModeItemLoaded && isEditMode && asyncInitialValue && !!value && !!initialLabel) {
      const selectedItem = {
        text: initialLabel,
        value
      }
      setEditModeItemLoaded(true)
      setSelectedOptionLabel(initialLabel)
      setResetCache(prevState => !prevState)
      handleSetDefaultOptions(selectedItem, value)
    }
  }, [defaultOptions, initialLabel, isEditMode, isEditModeItemLoaded, value])

  const handleLoadMoreOptions = useCallback(
    (search, page, prevOptions) => {
      return new Promise((resolve) => {

        const handleSearchResultCallback = (response) => {
          if (!!response.items) {
            const { hasNextPage } = response
            let { items } = response
            items = items.map(option => ({
              text: option.programName,
              value: option.programId
            }))
            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
            resolve({
              optionList: items,
              hasMore: hasNextPage
            })
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }
        const params = {
          programName: search,
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          ...reportParams
        }

        dispatch(
          actions.getProgramDDLRequest(params, handleSearchResultCallback))
      }

      )
    }, [options, reportParams])

  const handleSelect = useCallback((selected) => {
    if (isMulti) {
      const lastElement = selected.length
        ? selected[selected.length - 1]
        : null
      if (!!lastElement && !lastElement.value) {
        onSelect(id, [])
      } else {
        onSelect(id, selected)
      }
    } else {
      onSelect(id, selected?.value || "", (selected?.value && selected?.text) || "")
      setSelectedOptionLabel(selected?.text || "")
    }
  }, [onSelect, id])

  return (

    <Col className="mb-1" sm={wrapperStyle.sm} md={wrapperStyle.md} xl={wrapperStyle.xl}>
      {labelRequired && <CustomLabel title={label} requiredIconClassName="required-field" required={isRequired} isLocalizedTitle={false} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id={id}
        name={name}
        placeholder={placeholder}
        options={options}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={
          !isMulti
            ? !!value
              ? {
                text: selectedOptionLabel,
                value
              }
              : null
            : value
        }
        isShowCustomAction={isShowCustomAction}
        valueType={valueType}
        labelType={labelType}
        onSelect={handleSelect}
        onLoadOptions={handleLoadMoreOptions}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isMulti={isMulti}
        hideSelectedOptions={false}
        isDisabled={isViewMode}
      />
      {
        errors && (
          <ErrorMessage
            isShow={!!errors[id]}
            message={errors[id]}
          />
        )
      }
    </Col >
  )
}

ProgramSearch.propTypes = {
  asyncInitialValue: PropTypes.bool,
  initialLabel: PropTypes.string,
  isEditMode: PropTypes.bool,
  isViewMode: PropTypes.bool,
  errors: PropTypes.object,
  defaultOptions: PropTypes.array,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  isShowCustomAction: PropTypes.bool,
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  valueType: PropTypes.string,
  labelType: PropTypes.string,
  onSelect: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  wrapperStyle: PropTypes.object,
  reportParams: PropTypes.object
}

ProgramSearch.defaultProps = {
  asyncInitialValue: false,
  initialLabel: '',
  wrapperStyle: {
    md: 6,
    sm: 12,
    xl: 4
  },
  isEditMode: false,
  isViewMode: false,
  defaultOptions: [],
  id: "",
  isMulti: true,
  isRequired: false,
  isShowCustomAction: false,
  labelRequired: true,
  name: "",
  value: "",
  valueType: "value",
  labelType: "text"
}

export default ProgramSearch
