import { NOTIFY_CONTACTS_TYPE, REDO_TRAINING_TYPE } from "@views/campaigns/add-new-campaign/workflow/block/training-template/failed-training/config"
import { ADD_CAMPAGIN_FOR } from '@views/campaigns/overview/campaign-details/config'
import config from "./APIConfig"
import { putDataApi } from '../../index'

/**
 * @method updateBlockFailedTraining
 * @param {object} data 
 * 
 * {
 *  wfCampaignSetupId: "",
 *  redoTrainingId: "",
 *  notifyContactId: "",
 *  assignmentValue: ["siteRoleId": 0, "name": ""]
 *  notificationMethodIdsValue : ["value": 0, "text": ""]
 * }
 * 
 * API payload:-
 * 
 * {
 * "wfCampaignSetupId": 0,
 * "redoTraining": true,
 * "notifyContacts": true,
 * "siteRoleIds": [
 *   {
 *     "siteRoleId": 0
 *   }
 * ],
 * "wfNotificationMethodIds": [
 *   0
 * ]
}
 */

export const updateBlockFailedTraining = (data) => {
  const { url } = config.updateBlockFailedTraining
  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    redoTraining: data.redoTrainingId === REDO_TRAINING_TYPE.YES,
    notifyContacts: data.notifyContactId === NOTIFY_CONTACTS_TYPE.YES
  }
  if (data.notifyContactId === NOTIFY_CONTACTS_TYPE.YES) {
    if (data.addCampaignForId === ADD_CAMPAGIN_FOR.ORGANIZATION) {
      payload["contactIds"] = data.assignmentValue?.map(x => ({ contactId: x.contactId }))
    } else {
      payload["siteRoleIds"] = data.assignmentValue?.map(x => ({ siteRoleId: x.siteRoleId }))
    }
    payload["wfNotificationMethodIds"] = data.notificationMethodIdsValue.map(x => Number(x.value))
  }
  return putDataApi(url(data.wfCampaignSetupId), payload, null, { isApiNeedsToHandleErrors: false })
}

export default updateBlockFailedTraining
