import cx from "classnames"
import React, { useCallback, useState } from "react"
import { Badge, Button, Card, Col, Row } from "reactstrap"
import { ChevronUp, ChevronDown } from 'react-feather'
import PropTypes from 'prop-types'
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import LocaleMessageWrapper from "../locale-message"

const { LOADER, MORE_DETAILS, SEE_LESS, TABLE } = LANGUAGE_CONSTANTS

const OverviewDetailCard = ({
  intl,
  canEdit = true,
  config = [],
  data = {},
  isLoading = false,
  applyStyle,
  onEdit = () => {}
}) => {
  const [isShowMore, setShowMore] = useState(false)

  const handleToggleShowMore = useCallback(() => {
    setShowMore(prevState => !prevState)
  }, [])

  if (!isLoading && !data) {
    return null
  }

  let primaryConfig = config
  let moreConfig = []

  if (Array.isArray(config[0])) {
    primaryConfig = config[0]
    moreConfig = config.slice(1)
  }

  const hasMoreConfig = !!moreConfig.length

  return (
    <Card className={applyStyle && 'p-2 mt-2'}>
      <Row
        className={cx("overview-detail-view", {
          "justify-content-center": isLoading
        })}
      >
        {!isLoading &&
          !!data &&
          primaryConfig.map((item, index) => {
            return (
              <Col key={`${item.keyName}_${index}`} className="col-md-2">
                <h5 className="detail-item-title">{item.label}</h5>
                {!item.isBadgeRequired ? (
                  <h4 className="detail-item-value">{data[item.keyName] || '-'}</h4>
                ) : (
                  <Badge
                    tag="div"
                    color={item.getBadgeColor(data[item.keyName])}
                    pill
                    style={{ pointerEvents: "none" }}
                  >
                    {data[item.keyName]}
                  </Badge>
                )}
              </Col>
            )
          })}
          {
           !isLoading && primaryConfig.length === 4 && <Col className="col-md-2"></Col>
          }
        {!isLoading && canEdit && (
           <Col className="col-md-2 text-right">
            <Button className="edit-btn" onClick={onEdit}>
              {getLocaleMessage(intl, TABLE.EDIT)}
            </Button>
          </Col>
        )}
        {isLoading && (
          <p className="m-0">
            <strong>{getLocaleMessage(intl, LOADER.LOADING)}</strong>
          </p>
        )}
      </Row>
      {!isLoading && hasMoreConfig && <Row className={cx("mt-1", {
        "mb-2": isShowMore
      })}>
        <button
          type="button"
          className="waves-effect btn advanced-btn more-less-btn text-transform-capitalize"
          onClick={handleToggleShowMore}
        >
          {isShowMore ? <ChevronUp /> : <ChevronDown />}
          {getLocaleMessage(intl, isShowMore ? SEE_LESS : MORE_DETAILS)}
        </button>
      </Row>}
      {!isLoading && isShowMore && moreConfig.map((rowConfig, index) => {
        return (
        <Row  className={"overview-detail-view mb-2"} key={`overview-row_${index}`}>
           {!!data &&
            rowConfig.map((item) => {
            return (
              <Col key={`${item.keyName}_${index}`} className="col-md-2">
                <h5 className="detail-item-title">{item.label}</h5>
                {!item.isBadgeRequired ? (
                  <h4 className="detail-item-value">{data[item.keyName] || '-'}</h4>
                ) : (
                  <Badge
                    tag="div"
                    color={item.getBadgeColor(data[item.keyName])}
                    pill
                    style={{ pointerEvents: "none" }}
                  >
                    {data[item.keyName]}
                  </Badge>
                )}
              </Col>
            )
          })}
        </Row>
        )
      }) }
    </Card>
  )
}

OverviewDetailCard.propTypes = {
  intl: PropTypes.object,
  canEdit: PropTypes.bool,
  config: PropTypes.array,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  onEdit: PropTypes.func,
  applyStyle: PropTypes.bool
}

OverviewDetailCard.defaultProps = {
  canEdit: true,
  config: [],
  data: {},
  isLoading: false,
  applyStyle: true
}

export default LocaleMessageWrapper(OverviewDetailCard)
