import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addApplicableRegulationData
 * @param {object} data 
 *
 */
export const addApplicableRegulationData = (data) => {
  const { url } = config.addApplicableRegulationData

  return postDataApi(url(data), data)
}

export default addApplicableRegulationData
