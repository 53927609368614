import config from "./APIConfig"
import { postDataApi } from '../index'

export const createNewUserAccount = (data) => {
  const { url } = config.createNewUserAccount

  return postDataApi(url(data.contactId))
}

export default createNewUserAccount
