import React from 'react'
import { Col } from 'reactstrap'

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { ErrorMessage, ReactSelect } from '@views/components'
import localeMessageWrapper from '@views/components/locale-message'

const { EVENT_TYPE } = LANGUAGE_CONSTANTS.SERVICES

const EventType = ({
    intl,
    errors,
    control,
    isViewMode,
    serviceDetail,
    campaignEventType,
    onChangeServiceDetails
}) => {

    return <Col className='mb-1' sm={12} md={6} xl={4}>
        <ReactSelect
            id='event_type'
            name='event_type'
            label={getLocaleMessage(intl, EVENT_TYPE)}
            control={control}
            isRequired
            value={serviceDetail.eventTypeId}
            options={campaignEventType}
            onSelect={(selected) => onChangeServiceDetails('eventTypeId', selected?.value || '')}
            getOptionLabel={(item) => item.text}
            getOptionValue={(item) => item.value}
            placeHolder={getLocaleMessage(intl, EVENT_TYPE)}
            isDisabled={isViewMode}
        />
        <ErrorMessage
            isShow={!!errors['eventTypeId']}
            message={errors['eventTypeId']}
        />
    </Col>
}

export default localeMessageWrapper(EventType)
