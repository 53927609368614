import React, { useState, useCallback } from "react"
import { ChevronDown, ChevronUp } from 'react-feather'
import { Card } from 'reactstrap'

const ProgramAccordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true)

  const handleToggleAccordion = useCallback(() => {
    setIsOpen((prev) => !prev)
  }, [])

  return (
    <>
      <Card className="mb-1 p-2">
        <div className="construction-accordion">
          <button
            type="button"
            className="construction-accordion-title"
            onClick={handleToggleAccordion}
          >
            {isOpen ? (
              <>
                {title}
                <ChevronUp />
              </>
            ) : (
              <>
                {title}
                <ChevronDown />
              </>
            )}
          </button>
        </div>
        {isOpen && children}
      </Card>
    </>)
}
export default ProgramAccordion