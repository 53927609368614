import React, { Fragment, useCallback, useMemo } from "react"
import {
  Col,
  Row,
  Button
} from "reactstrap"
import { ChevronDown, ChevronLeft } from "react-feather"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"
import BlueprintSection from "./blueprint-section"
import { PLAN_FORM_SECTION_TYPES, PlanSections } from "./config"
import EnergyModelSection from "./energy-model-section"
import PlanDetailSection from "./PlanDetailSection"
import PlanSection from "./PlanSection"

const {
  SAVE,
  CANCEL,
  UPDATE
} = LANGUAGE_CONSTANTS

const getSectionComponent = (sectionId) => {
  switch (sectionId) {
    case PLAN_FORM_SECTION_TYPES.PLAN: return PlanSection
    case PLAN_FORM_SECTION_TYPES.BLUEPRINT: return BlueprintSection
    case PLAN_FORM_SECTION_TYPES.ENERGY_MODEL: return EnergyModelSection
    case PLAN_FORM_SECTION_TYPES.PLAN_DETAILS: return PlanDetailSection
    default: return null
  }
}

const PlanDetailForm = ({
  intl,
  errors,
  formSectionList,
  isEnableAddNewPlanMode,
  isEnableAddNewBlueprintMode,
  isEnableAddNewEnergyModelMode,
  isEditMode,
  isModal,
  isOrgPlan,
  planDetail,
  blueprintFiles,
  energyModelFiles,
  organizationId,
  onClose,
  onSavePlan,
  onSetActiveSectionList,
  onChangeDetail,
  onSaveNewBlueprint,
  onSaveNewBlueprintEnergyModel,
  onSetEnableAddNewPlanMode,
  onSetEnableAddNewBlueprintMode,
  onSetEnableAddNewEnergyModelMode
}) => {
  const handleToggleFormSection = useCallback((id) => {
    let updatedSectionList = [...formSectionList]
    if (updatedSectionList.includes(id)) {
      updatedSectionList = updatedSectionList.filter((sectionId) => sectionId !== id)
    } else updatedSectionList.push(id)
    onSetActiveSectionList(updatedSectionList)
  }, [formSectionList, onSetActiveSectionList])

  const handleToggleAddNewPlanMode = useCallback((e) => {
    e?.preventDefault()
    onChangeDetail("organizationPlanId", '')//To reset previous selected values
    onSetEnableAddNewPlanMode(!isEnableAddNewPlanMode)

    if (!isEnableAddNewPlanMode) {
      //To toggle ddl seelction view, as create new plan view opened
      onSetEnableAddNewBlueprintMode(true)
      onSetEnableAddNewEnergyModelMode(true)
    } else {
      onSetEnableAddNewBlueprintMode(false)
      onSetEnableAddNewEnergyModelMode(false)
    }
  }, [isEnableAddNewPlanMode, onChangeDetail])

  const handleToggleAddNewBlueprintMode = useCallback((e) => {
    e?.preventDefault()
    onChangeDetail("blueprintId", '')//To reset previous selected values
    onSetEnableAddNewBlueprintMode(!isEnableAddNewBlueprintMode)

    if (!isEnableAddNewBlueprintMode) {
      //To toggle ddl seelction view, as create new blueprint view opened
      onSetEnableAddNewEnergyModelMode(true)
    } else {
      onSetEnableAddNewEnergyModelMode(false)
    }
  }, [isEnableAddNewBlueprintMode, onChangeDetail])

  const handleToggleAddNewEnergyModelMode = useCallback((e) => {
    e?.preventDefault()
    onChangeDetail("energyModelId", '')//To reset previous selected values
    onSetEnableAddNewEnergyModelMode(prevState => !prevState)
  }, [onChangeDetail])

  const colWrapperProps = useMemo(() => {
    if (isModal) {
      return {
        sm: 12,
        md: 8,
        xl: 6
      }
    }

    return {
      sm: 12,
      md: 6,
      xl: 4
    }
  }, [isModal])

  const fileUploaderColWrapperProps = useMemo(() => {
    if (isModal) {
      return {
        sm: 12,
        md: 12,
        xl: 12
      }
    }

    return {
      sm: 12,
      md: 8,
      xl: 6
    }
  }, [isModal])

  const SingleParentRowWrapper = useMemo(() => (isModal ? 'div' : Fragment), [isModal])

  const singleParentRowWrapperProps = useMemo(() => {
    if (isModal) {
      return {
        className: "mt-1 add-plan-scroll"
      }
    }

    return {}
  }, [isModal])

  const AllowedPlanSections = useMemo(() => {
    if (planDetail.organizationPlanId && !planDetail.blueprintId) {
      return PlanSections.filter(sec => sec.id !== PLAN_FORM_SECTION_TYPES.ENERGY_MODEL)
    }
    return PlanSections
  }, [isOrgPlan, planDetail.organizationPlanId, planDetail.blueprintId])

  return (
    <>
      <SingleParentRowWrapper  {...singleParentRowWrapperProps}>
        <div className="plan-setup-page setup-accordion border-0 p-0">
          {AllowedPlanSections.map(section => {
            const sectionId = section.id
            const isShowSection = formSectionList.includes(sectionId)
            const SectionComponent = getSectionComponent(sectionId)
            return <div className="setup-accordion-header" key={`plan-section-${sectionId}`}>
              <button
                type='button'
                className='waves-effect btn advanced-btn more-less-btn mb-1'
                onClick={() => handleToggleFormSection(sectionId)}>
                {getLocaleMessage(intl, section.labelLocaleId)}
                {isShowSection ? <ChevronLeft /> : <ChevronDown />}
              </button>
              {isShowSection && !!SectionComponent && <SectionComponent
                errors={errors}
                isEditMode={isEditMode}
                isModal={isModal}
                isOrgPlan={isOrgPlan}
                planDetail={planDetail}
                blueprintFiles={blueprintFiles}
                energyModelFiles={energyModelFiles}
                organizationId={organizationId}
                isEnableAddNewPlanMode={isEnableAddNewPlanMode}
                isEnableAddNewBlueprintMode={isEnableAddNewBlueprintMode}
                isEnableAddNewEnergyModelMode={isEnableAddNewEnergyModelMode}
                colWrapperProps={colWrapperProps}
                fileUploaderColWrapperProps={fileUploaderColWrapperProps}
                onChangeDetail={onChangeDetail}
                onSaveNewBlueprint={onSaveNewBlueprint}
                onSaveNewBlueprintEnergyModel={onSaveNewBlueprintEnergyModel}
                onToggleAddNewPlanMode={handleToggleAddNewPlanMode}
                onToggleAddNewBlueprintMode={handleToggleAddNewBlueprintMode}
                onToggleAddNewEnergyModelMode={handleToggleAddNewEnergyModelMode}
                />}
            </div>
          })}
        </div>
      </SingleParentRowWrapper>
      {isModal ?
        <Row>
          <Col md={6}>
            <Button
              outline
              onClick={onClose}
              className={"delete-button secondary-outlined w-100"}
            >
              {getLocaleMessage(intl, CANCEL)}
            </Button>
          </Col>
          <Col md={6}>
            <Button
              className={"primary-solid w-100"}
              color="primary"
              onClick={onSavePlan}
            >
              {getLocaleMessage(intl, SAVE)}
            </Button>
          </Col>
        </Row> :
        <div className="text-right col-12">
          <Button
            className={"gradient-solid"}
            onClick={onSavePlan}
          >
            {isEditMode ? getLocaleMessage(intl, UPDATE) : getLocaleMessage(intl, SAVE)}
          </Button>
          <Button
            outline
            onClick={onClose}
            className={"secondary-outlined ml-1"}>
            {getLocaleMessage(intl, CANCEL)}
          </Button>
        </div>
      }
    </>
  )
}

export default localeMessageWrapper(PlanDetailForm)
