import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method cloneProgram
 *
 */
export const cloneProgram = (data) => {
  const { url } = config.cloneProgram
  return postDataApi(url(data))
}

export default cloneProgram
