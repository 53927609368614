import React, { useCallback } from "react"
import { useDispatch } from "react-redux"

import * as actions from "@store/actions"
import CustomCheckboxSearch from "./custom-checkbox-search"

const CampaignName = ({
  id = "campaign",
  allSelectCheckboxLabel = "",
  inputPlaceholder,
  valueType = "campaignId",
  labelType = "campaignName",
  value = [],
  organizations = [],
  sites = [],
  programId,
  isResetClicked = false,
  isCalendarPageCampaigns = false,
  onSelect = () => {},
  onResetClicked = () => {}
}) => {
  const dispatch = useDispatch()

  const handleFetchOptions = useCallback((params, callback = () => {}) => {
    const queryPayload = {
      ...params,
      campaignName: params.searchText,
      programId,
      organizations,
      sites,
      isCalendarCampaigns: isCalendarPageCampaigns
    }
    //TODO: Calendar Campign API 
    dispatch(actions.getCampaignDDLRequest(queryPayload, callback))
  }, [programId, organizations, sites])

  const handleSelectFilter = useCallback(
    (value) => {
      onSelect(id, value)
    },
    [onSelect]
  )

  return (
    <CustomCheckboxSearch
      id={id}
      valueType={valueType}
      labelType={labelType}
      allSelectCheckboxLabel={allSelectCheckboxLabel}
      inputPlaceholder={inputPlaceholder}
      onFetchOptions={handleFetchOptions}
      onSelectFilter={handleSelectFilter}
      onResetClicked={onResetClicked}
      selectedFilters={value}
      isResetClicked={isResetClicked}
    />
  )
}

export default CampaignName
