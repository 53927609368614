import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateAssignmentActivities
 * 
 * @param {Object} data 
 * {
  "observationTaskId": 0,
  "assignmentActivities": [
    {
      "assignmentId": 0,
      "checkpointResponseId": 0,
      "checkpointId": 0,
      "checkpointVersionId": 0,
      "foresiteStandardAnswerId": 0
    }
  ]
}
 */

export const updateAssignmentActivities = (data) => {
  const { url } = config.updateAssignmentActivities 
  return putDataApi(url(data), data)
}

export default updateAssignmentActivities
