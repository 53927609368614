import config from "./APIConfig"
import { putDataApi } from '../index'

export const updateCloseTaskManually = (data) => {

  const { url } = config.updateCloseTaskManually
  return putDataApi(url, data)
}

export default updateCloseTaskManually
