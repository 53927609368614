import config from "./APIConfig"
import { getDataApi } from "../index"

export const getSiteDDLByCampaignId = async (data) => {
  const { url } = config.getSiteDDLByCampaignId
  return await getDataApi(url(data))
}

export const getSharedCampaignUnLinkedSiteOrganizationDDLList = async (data) => {
  const { url } = config.getSharedCampaignUnLinkedSiteOrganizationDDLList
  return await getDataApi(url(data))
}

export const getSharedCampaignUnLinkedSiteList = async (data) => {
  const { url } = config.getSharedCampaignUnLinkedSiteList
  return await getDataApi(url(data))
}

export const getSharedCampaignSiteList = async (data) => {
  const { url } = config.getSharedCampaignSiteList
  return await getDataApi(url(data))
}

export const getServiceDetailVM = async () => {
  const { url } = config.getServiceDetailVM
  return await getDataApi(url())
}

export const getServiceChecklistDetail = async (data) => {
  const { url } = config.getServiceChecklistDetail
  return await getDataApi(url(data))
}

export const getProjectListDDL = async (payload) => {
  const { url } = config.getProjectListDDL
  return await getDataApi(url(payload))
}

export const getOrganizationDDLByCampaignId = async (data) => {
  const { url } = config.getOrganizationDDLByCampaignId
  return await getDataApi(url(data))
}

export const getObservationTaskVM = async () => {
  const { url } = config.getObservationTaskVM
  return await getDataApi(url())
}

export const getContactCampaignList = async (data) => {
  const { url } = config.getContactCampaignList
  return await getDataApi(url(data))
}

export const getCampaignWorkflows = async (data) => {
  const { url } = config.getCampaignWorkflows
  return await getDataApi(url(data))
}

export const getCampaignWorkflowVM = async () => {
  const { url } = config.getCampaignWorkflowVM
  return await getDataApi(url)
}

export const getCampaignTagsDDL = async (data) => {
  const { url } = config.getCampaignTagsDDL
  return await getDataApi(url(data))
}

export const getCampaignSetupCheckListVM = async () => {
  const { url } = config.getCampaignSetupCheckListVM
  return await getDataApi(url())
}

export const getCampaignServiceList = async (data) => {
  const { url } = config.getCampaignServiceList
  return await getDataApi(url(data))
}

export const getCampaignServiceDDL = async (data) => {
  const { url } = config.getCampaignServiceDDL
  return await getDataApi(url(data))
}

export const getCampaignServiceChecklist = async (data) => {
  const { url } = config.getCampaignServiceChecklist
  return await getDataApi(url(data))
}

export const getCampaignServiceChecklistCheckpointList = async (payload) => {
  const { url } = config.getCampaignServiceChecklistCheckpointList
  return await getDataApi(url(payload))
}

export const getCampaignServiceBasicDetail = async (id) => {
  const { url } = config.getCampaignServiceBasicDetail
  return await getDataApi(url(id))
}

export const getCampaignServiceDetail = async (id) => {
  const { url } = config.getCampaignServiceDetail
  return await getDataApi(url(id))
}

export const getCampaignReportList = async (data) => {
  const { url } = config.getCampaignReportList
  return await getDataApi(url(data))
}

export const getCampaignOshaPDF = async (data) => {
  const { url } = config.getCampaignOshaPDF
  
  return await getDataApi(
    url(data),
    {
      "Content-Type": "multipart/form-data"
    },
    {
      responseType: "arraybuffer"
    }
  )
}

export const getCampaignObservationList = async (data) => {
  const { url } = config.getCampaignObservationList
  return await getDataApi(url(data))
}

export const getCampaignMissingSiteRoles = async (data) => {
  const { url } = config.getCampaignMissingSiteRoles
  return await getDataApi(url(data))
}

export const getCampaignList = async (data) => {
  const { url } = config.getCampaignList
  return await getDataApi(url(data))
}

export const getCampaignDetailVM = async () => {
  const { url } = config.getCampaignDetailVM
  return await getDataApi(url())
}

export const getCampaignDetail = async (data) => {
  const { url } = config.getCampaignDetail
  return await getDataApi(url(data))
}

export const getCampaignDescription = async (data) => {
  const { url } = config.getCampaignDescription
  return await getDataApi(url(data))
}

export const getCampaignDDL = async (data) => {
  const { url } = config.getCampaignDDL
  return await getDataApi(url(data))
}

export const getCampaignCheckListVM = async () => {
  const { url } = config.getCampaignCheckListVM
  return await getDataApi(url)
}

export const getCampaignChecklistDetail = async (data) => {
  const { url } = config.getCampaignChecklistDetail
  return await getDataApi(url(data))
}

export const getCampaignBasicDetail = async (data) => {
  const { url } = config.getCampaignBasicDetail
  return await getDataApi(url(data))
}
