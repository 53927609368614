import config from "./APIConfig"
import { postDataApi } from '../index'

export const uploadResponseImage = (data) => {
    const { url } = config.uploadResponseImage

    const payload = new FormData()
    payload.append("UploadResponseImage.AssignmentId", data.assignmentId)
    payload.append("UploadResponseImage.AssignmentActivityId", data.assignmentActivityId)
    payload.append("UploadResponseImage.ResponseId", data.responseId)
    payload.append("UploadResponseImage.ImageFile", data.file)

    return postDataApi(url, payload, {
        'Content-Type': 'multipart/form-data'
    })
}

export default uploadResponseImage