import React from "react"

const OrderActions = ({ data }) => {
    return (
        <div className="d-flex">
            <span className="mr-2">{data.orderIndex}</span>
        </div>
    )
}

export default OrderActions