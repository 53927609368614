import { ASSIGNMENT_TYPE } from "@views/campaigns/add-new-campaign/workflow/block/task-review/config"
import config from "./APIConfig"
import { postDataApi } from '../../index'

/**
 * @method createBlockTaskReview
 * @param {object} data 
 * 
 * {
  wfCampaignSetupId: "",
  taskReviewRequired: "",
  triggerEvent: "",
  assignmentType: "",
  contactIds: [],
  siteRoleIds: [],
  contactGroupIds: []
 * }
 * 
 * API payload:-
 * 
{
  "wfCampaignSetupId": 0,
  "taskReviewRequired": true,
  "taskReviewTriggerId": 1,
  "assignmentTypeId": 1,
  "sitePartyRoleIds": [
    {
      "sitePartyRoleId": 0
    }
  ],
  "contactGroupIds": [
    {
      "contactGroupId": 0
    }
  ]
}
 */

export const createBlockTaskReview = (data) => {
  const { url } = config.createBlockTaskReview
  const valueType = data.isSharedCampaign ? "value" : "sitePartyRoleId"
  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    taskReviewRequired: !!Number(data.taskReviewRequired),
    taskReviewTriggerId: Number(data.triggerEvent),
    assignmentTypeId: Number(data.assignmentType),
    sitePartyRoleIds: data.assignmentType === ASSIGNMENT_TYPE.ROLE ? data.siteRoleIds.map((role) => ({ sitePartyRoleId: Number(role[valueType]) })) : [],
    contactIds: data.assignmentType === ASSIGNMENT_TYPE.CONTACT ? data.contactIds.map((contact) => ({ contactId: Number(contact.contactId) })) : [],
    contactGroupIds: data.contactGroupIds.map((contact) => ({ contactGroupId: Number(contact.value) }))
  }
  return postDataApi(url, payload, null, { isApiNeedsToHandleErrors: false })
}

export default createBlockTaskReview
