import { getActionType } from '../action-type-generator'

export const GET_ADVANCE_SEARCH_FILTER_LIST_REQUEST = 'GET_ADVANCE_SEARCH_FILTER_LIST_REQUEST'
export const GET_MENU_LIST = getActionType('GET_MENU_LIST')
export const GET_USER_ENTITY_DDL_REQUEST = 'GET_USER_ENTITY_DDL_REQUEST'
export const SAVE_ADVANCE_SEARCH_FILTERS_REQUEST = 'SAVE_ADVANCE_SEARCH_FILTERS_REQUEST'
export const REFRESH_FOR_SAVED_FILTER_LIST = 'REFRESH_FOR_SAVED_FILTER_LIST'
export const SET_SAVED_FILTER_AS_SELECTED = 'SET_SAVED_FILTER_AS_SELECTED'
export const CLEAR_SELECTED_FILTER = 'CLEAR_SELECTED_FILTER'
export const SET_USER_PERMISSION_LIST = 'SET_USER_PERMISSION_LIST'
export const UPDATE_ADVANCE_SEARCH_FILTERS_REQUEST = 'UPDATE_ADVANCE_SEARCH_FILTERS_REQUEST'
export const DELETE_ADVANCE_SEARCH_FILTERS_REQUEST = 'DELETE_ADVANCE_SEARCH_FILTERS_REQUEST'
export const GET_TIMEZONE_DDL_REQUEST = 'GET_TIMEZONE_DDL_REQUEST'
export const RESET_MENU_LIST_REQUEST = 'RESET_MENU_LIST_REQUEST'

/****************************************************/
/** GET_MENU_LIST **/
/****************************************************/

/**
 * @method getMenuListRequest : To get menu list
 *
 */
export const getMenuListRequest = payload => {
  return {
    type: GET_MENU_LIST.REQUEST,
    payload
  }
}

/**
 * @method getMenuListSuccess : To set menu list on success
 *
 */
export const getMenuListSuccess = data => {
  return {
    type: GET_MENU_LIST.SUCCESS,
    data
  }
}

/**
 * @method getMenuListFailure : To set error on getting menu list
 *
 */
export const getMenuListFailure = error => {
  return {
    type: GET_MENU_LIST.FAILURE,
    error
  }
}

/****************************************************/
/** SET_USER_PERMISSION_LIST **/
/****************************************************/

/**
 * @method setUserPermissionList : To set user permission list
 *
 */
export const setUserPermissionList = (data) => {
  return {
    type: SET_USER_PERMISSION_LIST,
    data
  }
}

/****************************************************/
/** GET_USER_ENTITY_DDL_REQUEST **/
/****************************************************/

/**
 * @method getUserEntityDDLRequest : To get user entity list
 *
 */
export const getUserEntityDDLRequest = (payload, callback) => {
  return {
    type: GET_USER_ENTITY_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ADVANCE_SEARCH_FILTER_LIST_REQUEST **/
/****************************************************/

/**
 * @method getAdvanceSearchFilterListRequest : To get advance search filter list
 *
 */
export const getAdvanceSearchFilterListRequest = (payload, callback) => {
  return {
    type: GET_ADVANCE_SEARCH_FILTER_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SAVE_ADVANCE_SEARCH_FILTERS_REQUEST **/
/****************************************************/

/**
 * @method saveAdvanceSearchFiltersRequest : To save advance search filters
 *
 */
export const saveAdvanceSearchFiltersRequest = (payload, callback) => {
  return {
    type: SAVE_ADVANCE_SEARCH_FILTERS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** REFRESH_FOR_SAVED_FILTER_LIST **/
/****************************************************/

/**
 * @method refreshForSavedFilterList : To refresh saved advance search filters list
 *
 */
export const refreshForSavedFilterList = () => {
  return {
    type: REFRESH_FOR_SAVED_FILTER_LIST
  }
}

/****************************************************/
/** SET_SAVED_FILTER_AS_SELECTED **/
/****************************************************/

/**
 * @method setSavedFilterAsSelected : To auto select saved filter value inside Select Filter dropdown
 *
 */
export const setSavedFilterAsSelected = (payload) => {
  return {
    type: SET_SAVED_FILTER_AS_SELECTED,
    payload
  }
}

/****************************************************/
/** CLEAR_SELECTED_FILTER **/
/****************************************************/

/**
 * @method clearSelectedFilter : To reset selected filter value from Select Filter dropdown
 *
 */
export const clearSelectedFilter = () => {
  return {
    type: CLEAR_SELECTED_FILTER
  }
}

/****************************************************/
/** UPDATE_ADVANCE_SEARCH_FILTERS_REQUEST **/
/****************************************************/

/**
 * @method updateAdvanceSearchFiltersRequest : To update advance search filters
 *
 */
export const updateAdvanceSearchFiltersRequest = (payload, callback) => {
  return {
    type: UPDATE_ADVANCE_SEARCH_FILTERS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_ADVANCE_SEARCH_FILTERS_REQUEST **/
/****************************************************/

/**
 * @method deleteAdvanceSearchFiltersRequest : To delete advance search filters
 *
 */
export const deleteAdvanceSearchFiltersRequest = (payload, callback) => {
  return {
    type: DELETE_ADVANCE_SEARCH_FILTERS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TIMEZONE_DDL_REQUEST **/
/****************************************************/

/**
 * @method getTimeZoneDDLRequest : To get the timezone list
 *
 */
export const getTimeZoneDDLRequest = (payload, callback = () => { }) => {
  return {
    type: GET_TIMEZONE_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** RESET_MENU_LIST_REQUEST **/
/****************************************************/

/**
 * @method resetMenuListRequest : To reset menu list
 *
 */
export const resetMenuListRequest = () => {
  return {
    type: RESET_MENU_LIST_REQUEST
  }
}