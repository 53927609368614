import config from "./APIConfig"
import { putDataApi } from '../../index'

/**
 * @method updateBlockTaskCloseout
 * @param {object} data 
 * 
 * API payload:-
 * 
 * {
 *  "wfCampaignSetupId": 0,
 *  "closeIncompleteTrainingAfter": 0,
 *  "closeIncompleteTraining_WFBCloseOutTrainingId": 0,
 *  "closeIncompleteTradeCorrectionAfter": 0,
 *  "closeIncompleteTradeCorrection_WFBCloseOutTrainingId": 0,
 *  "closeIncompleteSuperintendentVerificationAfter": 0,
 *  "closeIncompleteSuperintendentVerification_WFBCloseOutTrainingId": 0
 * }
 */
export const updateBlockTaskCloseout = (data) => {
  const { url } = config.updateBlockTaskCloseout

  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    closeIncompleteTrainingAfter: Number(data.trainingIncrement),
    closeIncompleteTraining_WFBCloseOutTrainingId: Number(data.trainingTypeId),
    closeIncompleteTradeCorrectionAfter: Number(data.tradeCorrectionIncrement),
    closeIncompleteTradeCorrection_WFBCloseOutTrainingId: Number(data.tradeCorrectionTypeId),
    closeIncompleteSuperintendentVerificationAfter: Number(data.superintendentVerificationIncrement),
    closeIncompleteSuperintendentVerification_WFBCloseOutTrainingId: Number(data.superintendentVerificationTypeId)
  }
  return putDataApi(url(data.wfCampaignSetupId), payload, null, { isApiNeedsToHandleErrors: false })
}

export default updateBlockTaskCloseout
