import config from "./APIConfig"
import { getDataApi } from "../index"

export const getAddOrganizationDetailVM = async () => {
  const { url } = config.getAddOrganizationDetailVM
  return await getDataApi(url)
}

export const getChildOrganizationList = async (data) => {
  const { url } = config.getChildOrganizationList
  return await getDataApi(url(data))
}

export const getUserAuth0ContactDetailsVM = async (data) => {
  const { url } = config.getUserAuth0ContactDetailsVM
  return await getDataApi(url(data))
}

export const getContactByEmail = async (emailAddress) => {
  const { url } = config.getContactByEmail
  return await getDataApi(url(emailAddress))
}

export const getContactListVM = async () => {
  const { url } = config.getContactListVM
  return await getDataApi(url)
}

export const getCreateAccountVM = async () => {
  const { url } = config.getAccountsByOrganizationId
  return await getDataApi(url)
}

export const getFilteredOrganizations = async (data) => {
  const { url } = config.getFilteredOrganizations
  return await getDataApi(url(data))
}

export const getOrganizationAddress = async (data) => {
  const { url } = config.getOrganizationAddress
  return await getDataApi(url({ organizationId: data.organizationId }))
}

export const getOrganizationContact = (data) => {
  const { url } = config.getOrganizationContact
  return getDataApi(url({ organizationId: data.organizationId, contactId: data.contactId }))
}

export const getOrganizationContactList = async (data) => {
  const { url } = config.getOrganizationContactList
  return await getDataApi(url(data))
}

export const getOrganizationDetail = async (data) => {
  const { url } = config.getOrganizationDetail
  return await getDataApi(url({ organizationId: data.organizationId }))
}

export const getOrganizationExternalContactList = async (data) => {
  const { url } = config.getOrganizationExternalContactList
  return await getDataApi(url(data))
}

export const getOrganizationList = async (data) => {
  const { url } = config.getOrganizationList
  return await getDataApi(url(data))
}

export const getOrganizationListVM = async () => {
  const { url } = config.getOrganizationListVM
  return await getDataApi(url)
}

export const getOrganizationPlan = async (data) => {
  const { url } = config.getOrganizationPlan
  return await getDataApi(url(data), undefined, { pathConfig: { isCheckpointInspectionService: true } })
}

export const getOrganizationPlanBlueprintDDL = async (data) => {
  const { url } = config.getOrganizationPlanBlueprintDDL
  return await getDataApi(url(data), undefined, { pathConfig: { isCheckpointInspectionService: true } })
}

export const getOrganizationPlanBlueprintEnergyModelDDL = async (data) => {
  const { url } = config.getOrganizationPlanBlueprintEnergyModelDDL
  return await getDataApi(url(data), undefined, { pathConfig: { isCheckpointInspectionService: true } })
}

export const getOrganizationPlanBlueprintEnergyModelList = async (data) => {
  const { url } = config.getOrganizationPlanBlueprintEnergyModelList
  return await getDataApi(url(data), undefined, { pathConfig: { isCheckpointInspectionService: true } })
}

export const getOrganizationPlanBlueprintList = async (data) => {
  const { url } = config.getOrganizationPlanBlueprintList
  return await getDataApi(url(data), undefined, { pathConfig: { isCheckpointInspectionService: true } })
}

export const getOrganizationPlanDDL = async (data) => {
  const { url } = config.getOrganizationPlanDDL
  return await getDataApi(url(data), undefined, { pathConfig: { isCheckpointInspectionService: true } })
}

export const getOrganizationPlanList = async (data) => {
  const { url } = config.getOrganizationPlanList
  return await getDataApi(url(data), undefined, { pathConfig: { isCheckpointInspectionService: true } })
}

export const getOrganizationRoleContact = (organizationRoleContactId) => {
  const { url } = config.getOrganizationRoleContact
  return getDataApi(url(organizationRoleContactId))
}

export const getOrganizationSiteList = async (data) => {
  const { url } = config.getOrganizationSiteList
  return await getDataApi(url(data))
}

export const getOrganizationStatusListVM = async () => {
  const { url } = config.getOrganizationStatusListVM
  return await getDataApi(url)
}

export const getOrganizationTreeVM = async (data) => {
  const { url } = config.getOrganizationTreeVM
  return await getDataApi(url({ entityId: data.entityId, organizationId: data.organizationId, organizationParentId: `${data.organizationParentId}` }))
}

export const getOrganizations = async (data) => {
  const { url } = config.getOrganizations
  return await getDataApi(url(data))
}

export const getUserAccountHistory = async (data) => {
  const { url } = config.getUserAccountHistory
  return await getDataApi(url(data))
}
