import cx from "classnames"
import React, { useCallback } from "react"

import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "../locale-message"

const SectionTab = ({
  intl,
  isActiveTab,
  tab = {},
  onChangeTab = () => { },
  count
}) => {
  const handleChangeTab = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      onChangeTab(tab.id)
    },
    [tab.id, onChangeTab]
  )

  return (
    <button
      disabled={tab.isDisabled}
      className={cx(tab.className, {
        active: isActiveTab,
        disabled: tab.isDisabled
      })}
      onClick={handleChangeTab}
    >
      {getLocaleMessage(intl, tab.name)}
      {!!count && ` (${count})`}
      {tab.isShowDot && (
        <span className={`bullet bullet-sm bullet-${tab.dotType}`}></span>
      )}
    </button>
  )
}

export default localeMessageWrapper(SectionTab)
