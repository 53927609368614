import { getSearchQuery } from '../config'

const apiConfig = {
    getCommunicationLogList: {
        method: 'GET',
        url: ({
            pageNumber,
            pageSize,
            genericSearch = '',
            advanceSearch = {},
            orderColumn,
            orderDirections = ''
        }) => {
            let searchString = [
                getSearchQuery("PageNumber", pageNumber, true),
                getSearchQuery("PageSize", pageSize),
                getSearchQuery("Subject", genericSearch, false, true),
                getSearchQuery("OrderColumn", orderColumn),
                orderDirections ? `&OrderDirections=${orderDirections}` : ''
              ].join('')

             if (!!Object.keys(advanceSearch).length) {
                const {
                    name,
                    organization,
                    email,
                    phone,
                    siteId,
                    unitId,
                    taskId,
                    channel,
                    type,
                    subject
                } = advanceSearch

                searchString = [
                    searchString,
                    getSearchQuery("Name", name, false, true),
                    getSearchQuery("Email", email, false, true),
                    getSearchQuery("Phone", phone, false, true),
                    getSearchQuery("OrganizationId", organization),
                    getSearchQuery("SiteId", siteId),
                    getSearchQuery("SiteUnitId", unitId),
                    getSearchQuery("ObservationTaskId", taskId),
                    getSearchQuery("NotificationMethodId", channel),
                    getSearchQuery("NotificationCategoryId", type),
                    getSearchQuery("Subject", subject, false, true)
                ].join('')
             }
             return `api/Common/GetCommunicationLogList${searchString}`
        }
    },
    getCommunicationLogNotificationDetail: {
        method: 'GET',
        url: (wFNotificationId) => `api/Common/GetNotificationDetail/${wFNotificationId}?WFNotificationId=${wFNotificationId}`
    },
    resendMessageNotification: {
        method: 'POST',
        url: (wFNotificationId) => `api/Common/ReSendNotification/${wFNotificationId}`
    },
    getCommunicationLogVM: {
        method: 'GET',
        url: () => `api/Common/CommunicationLog/vm`
    }
}

export default apiConfig