import config from "./APIConfig"
import { putDataApi } from "../index"

/**
 * @method updateBulkVerificationAction
 * This api is called when the superintendent accept/reject the verification for some activity
 * [Verify By Superintendent] 
 * 
 * @param {object} data 
 * 
 * {
 *  note: "",
 *  responseImages: [],
 *  wfActionIds: string,
 *  verifyBySuperintendent: 0
 * }
 * 
 */
export const updateBulkVerificationAction = async (data) => {
  const { url } = config.updateBulkVerificationAction
  return await putDataApi(url, data)
}

export default updateBulkVerificationAction
