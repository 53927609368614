import config from "./APIConfig"
import { deleteDataApi } from '../index'

export const deleteSamplingWorkflowGroup = (data) => {
  const { url } = config.deleteSamplingWorkflowGroup
  return deleteDataApi(url({ workflowId: data.workflowId, groupId: data.groupId }))
}

export const deleteSamplingWorkflowGroupUnits = (data) => {
  const { url } = config.deleteSamplingWorkflowGroupUnits
  return deleteDataApi(url({ workflowId: data.workflowId, groupId: data.groupId }), null, data.taskIdList)
}
