import React from "react"
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'
import { getUniqueId } from '@utils'

const CustomSelectOption = ({ data, label, value = '', isDisableTooltip }) => {
  const optionId = `org_opt_${data?.organizationId || ''}_${getUniqueId()}`
  return (
    <div className="list-unstyled orgnization-menu p-0">
      <a
        id={`${optionId}_option_label`}
        data-tip="tooltip"
        data-for={`${optionId}_option_description`}
      >
        <div className="orgnization-menu-list">
          {label}
        </div>
      </a>
      <ReactTooltip
        id={`${optionId}_option_description`}
        place={"right"}
        arrowColor={'transparent'}
        type="dark"
        effect="solid"
        multiline={true}
        disable={!value || isDisableTooltip}
      >
        <div className="orgnization-submenu list-unstyled ">
          <div className="orgnization-submenu-list">
            <h4><LocaleMessage id={LANGUAGE_CONSTANTS.ORGANIZATIONS.ORG_DETAILS} /></h4>
            <hr />
          </div>
          <div className="orgnization-submenu-list">
            <div className="address">
              {!!data && (
                <>
                  <h3>{data.organizationName}</h3>
                  <h6>{data.streetAddress} {data.city} <br /> {data.state ? `${data.state},` : ''} {data.postCode}</h6>
                </>
              )}
            </div>
          </div>
        </div>
      </ReactTooltip>
    </div>
  )
}

CustomSelectOption.propTypes = {
  data: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isDisableTooltip: PropTypes.bool
}

export default CustomSelectOption
