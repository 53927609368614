import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateProjectSiteUnit
 * @param {object} data 
 *  {
  "siteUnitId": 0,
  "unitName": "string",
  "unitTypeId": 0,
  "siteId": 0,
  "streetAddress": "string",
  "unitNumber": "string",
  "city": "string",
  "state": "string",
  "zipCode": "string",
  "countryCode": "string",
  "block": "string",
  "building": "string",
  "floor": "string",
  "permitNumber": "string",
  "planNumber": "string",
  "phase": "string",
  "phaseStartDate": "2021-09-08T10:39:40.031Z",
  "sitePlanId": 0
  }
 *
 */
export const updateProjectSiteUnit = (data) => {
  const { url } = config.updateProjectSiteUnit
  if (!data.sitePlanId) {
    delete data.sitePlanId
  }
  delete data.addToWorkflowList
  return putDataApi(url(data.siteUnitId), data)
}

export default updateProjectSiteUnit
