import { getSearchQuery } from "../config"

const apiConfig = {
  getUserManagementContactVM: {
    method: "GET",
    url: "api/UserManagement/UserManagementVM"
  },
  getUserManagementProfileType: {
    method: "GET",
    url: ({ entityId = 0 }) => {
      const searchString = [getSearchQuery("EntityId", entityId, true)].join('')
      return `api/UserManagement/entity/EntityTypes${searchString}`
    }
  },
  getUserManagementUserRole: {
    method: "GET",
    url: ({ entityId = 0 }) => {
      const searchString = [getSearchQuery("EntityId", entityId, true)].join('')
      return `api/UserManagement/entity/${entityId}/Roles${searchString}`
    }
  },
  getUserManagementUserDetail: {
    method: "GET",
    url: (email) => `api/UserManagement/${email}`
  },
  getUserManagementUserAccess: {
    method: "GET",
    url: (userId) => `api/UserManagement/GetUserAccessById/${userId}`
  },
  createUserManagementUserAccess: {
    method: "POST",
    url: 'api/UserManagement/CreateUserAccess'
  }
}

export default apiConfig
