const apiConfig = {
  getContactNotificationSettings: {
    method: 'GET',
    url: "api/Contact/NotificationSettings"

  },
  saveContactNotificationsSettings: {
    method: 'POST',
    url: "api/Contact/NotificationSettings"
  },
  getNotificationSettingsVM: {
    method: "GET",
    url: "api/Contact/NotificationSettingsVM"
  },
  updateContactEmailId: {
    method: 'PUT',
    url: (contactId) => `api/UserContact/${contactId}/email`
  }
}

export default apiConfig
