import config from "./APIConfig"
import { getDataApi } from "../index"

export const getCalendarStatesDDL = async (payload) => {
  const { url } = config.getCalendarStatesDDL
  return await getDataApi(url(payload))
}

export const getCalendarWorkflowsDDL = async (payload) => {
  const { url } = config.getCalendarWorkflowsDDL
  return await getDataApi(url(payload))
}

export const getCalendarContactsDDL = async (payload) => {
  const { url } = config.getCalendarContactsDDL
  return await getDataApi(url(payload))
}

export const getCalendarFiltersVM = async () => {
  const { url } = config.getCalendarFiltersVM
  return await getDataApi(url())
}

export const getCalendarOrganizationDDL = async (payload) => {
  const { url } = config.getCalendarOrganizationDDL
  return await getDataApi(url(payload))
}

export const getCalendarScheduleList = async (payload) => {
  const { url } = config.getCalendarScheduleList
  return await getDataApi(url(payload))
}

export const getCalendarSiteDDL = async (payload) => {
  const { url } = config.getCalendarSiteDDL
  return await getDataApi(url(payload))
}
