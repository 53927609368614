import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { TableCellToolTip, LocaleMessage } from '@views/components'

import RowActions from './RowActions'

const { PROJECTS, SCHEDULING, CHECKLISTS, CAMPAIGNS, TABLE } = LANGUAGE_CONSTANTS

export const columns = [
  {
    name: <LocaleMessage id={PROJECTS.TABLE_COLUMNS.WORKFLOW} />,
    selector: 'workflowName',
    sortKey: 'workflowName',
    sortable: true,
    minWidth: '250px',
    right: true,
    format: (row) => (
      <TableCellToolTip
        id={row.observationTaskId}
        value={row.workflowName}
        suffixOfId='_p_n_item_title'
        toolTipConfig={{ className: "table-data-tooltip" }}
        colNo={1}
      />
    )
  },
  {
    name: <LocaleMessage id={SCHEDULING.SITE} />,
    selector: 'siteName',
    sortKey: 'sitename',
    sortable: true,
    minWidth: '150px',
    right: true,
    format: (row) => (
      <TableCellToolTip
        id={row.observationTaskId}
        value={row.siteName}
        suffixOfId='_s_n_item_title'
      />
    )
  }, 
  {
    name: <LocaleMessage id={SCHEDULING.UNIT} />,
    selector: 'unitName',
    sortKey: 'unitname',
    sortable: true,
    maxWidth: '180px',
    minWidth: '50px',
    right: true,
    format: (row) => (
      <TableCellToolTip
        id={row.observationTaskId}
        value={row.unitName}
        suffixOfId='_u_n_item_title'
      />
    )
  },
  {
    name: <LocaleMessage id={CHECKLISTS.TABLE_COLUMNS.CHECKLIST} />,
    selector: 'checkListName',
    sortKey: 'checklistname',
    sortable: true,
    minWidth: '200px',
    maxWidth: '200px',
    right: true,
    format: (row) => (
      <TableCellToolTip
        id={row.observationTaskId}
        value={row.checkListName}
        suffixOfId='_c_n_item_title'
      />
    )
  },
  {
    name: <LocaleMessage id={CAMPAIGNS.CAMPAIGN_OBSERVATION_COLUMNS.TASK_TYPE} />,
    selector: 'taskTypeName',
    sortKey: 'tasktypename',
    sortable: true,
    minWidth: '200px',
    maxWidth: '200px',
    right: true,
    format: (row) => (
      <TableCellToolTip
        id={row.observationTaskId}
        value={row.taskTypeName}
        suffixOfId='_t_t_item_title'
      />
    )
  },
  {
    name: <LocaleMessage id={SCHEDULING.STATUS} />,
    selector: 'taskStatusName',
    sortKey: 'taskstatusname',
    sortable: true,
    minWidth: '200px',
    maxWidth: '200px',
    right: true,
    format: (row) => (
      <TableCellToolTip
        id={row.observationTaskId}
        value={row.taskStatusName}
        suffixOfId='_t_s_item_title'
      />
    )
  },
  {
    name: <LocaleMessage id={PROJECTS.TABLE_COLUMNS.ASSIGNED_TO}/>,
    selector: 'assignedTo',
    sortKey: 'assignedto',
    sortable: true,
    minWidth: '150px',
    right: true,
    format: (row) => (
      <TableCellToolTip
        id={row.observationTaskId}
        value={row.assignedTo}
        suffixOfId='_s_n_item_title'
      />
    )
  },   
  {
    name: <LocaleMessage id={TABLE.COLUMN_ACTIONS} />,
    maxWidth: '100px',
    allowOverflow: true,
    cell: (row) => <RowActions data={row} />
  }
]
