export const GET_OSHA_TRAINING_SITE_ROLE_REQUEST = 'GET_OSHA_TRAINING_SITE_ROLE_REQUEST'
export const GET_OSHA_TRAINING_CAMPAIGN_REQUEST = 'GET_OSHA_TRAINING_CAMPAIGN_REQUEST'
export const ADD_OSHA_TRAINING_CAMPAIGN_REQUEST = 'ADD_OSHA_TRAINING_CAMPAIGN_REQUEST'
export const GET_OSHA_TRAINING_COURSES_LIST_REQUEST = "GET_OSHA_TRAINING_COURSES_LIST_REQUEST"
export const UPDATE_OSHA_TRAINING_COURSES_LIST_REQUEST = "UPDATE_OSHA_TRAINING_COURSES_LIST_REQUEST"

/****************************************************/
/** GET_OSHA_TRAINING_SITE_ROLE **/
/****************************************************/

/**
 * @method getOshaTrainingSiteRoleRequest : To get osha training site role
 *
 */
 export const getOshaTrainingSiteRoleRequest = (payload, callback) => {
    return {
      type: GET_OSHA_TRAINING_SITE_ROLE_REQUEST,
      payload,
      callback
    }
  }
  
  /****************************************************/
  /** GET_OSHA_TRAINING_CAMPAIGN **/
  /****************************************************/
  
  /**
   * @method getOshaTrainingCampaignRequest : To get details of osha training campaign
   *
   */
   export const getOshaTrainingCampaignRequest = (payload, callback) => {
    return {
      type: GET_OSHA_TRAINING_CAMPAIGN_REQUEST,
      payload,
      callback
    }
  }
  
  /****************************************************/
  /** ADD_OSHA_TRAINING_CAMPAIGN **/
  /****************************************************/
  
  /**
   * @method addOshaTrainingCampaignRequest : To add details of osha training campaign
   *
   */
   export const addOshaTrainingCampaignRequest = (payload, callback) => {
    return {
      type: ADD_OSHA_TRAINING_CAMPAIGN_REQUEST,
      payload,
      callback
    }
  }
  
  /****************************************************/
  /** GET_OSHA_TRAINING_COURSES_LIST **/
  /****************************************************/
  
  /**
   * @method getOshaTrainingCoursesListRequest : To get osha training courses list
   *
   */
   export const getOshaTrainingCoursesListRequest = (payload, callback) => {
    return {
      type: GET_OSHA_TRAINING_COURSES_LIST_REQUEST,
      payload,
      callback
    }
  }

/****************************************************/
/** UPDATE_OSHA_TRAINING_COURSES_LIST_REQUEST **/
/****************************************************/

/**
 * @method updateOshaTrainingCampaignRequest :  To update osha training campaign
 *
 */
export const updateOshaTrainingCampaignRequest = (payload, callback) => {
  return {
    type: UPDATE_OSHA_TRAINING_COURSES_LIST_REQUEST,
    payload,
    callback
  }
}