export const ACTION_STATUS_TYPE = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning'
}

export const ACTION_STATUS_COLOR = {
  [ACTION_STATUS_TYPE.ERROR]: 'danger',
  [ACTION_STATUS_TYPE.SUCCESS]: 'success',
  [ACTION_STATUS_TYPE.WARNING]: 'warning'
}
