import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import PropTypes from 'prop-types'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'

const { TABLE_ROW_CELL_MODAL, LOADER, CLOSE } = LANGUAGE_CONSTANTS

const TableRowCellModal = ({
  children,
  description,
  isLoading,
  isOpen,
  title,
  onToggle = () => { }
}) => (
  <div>
    <Modal isOpen={isOpen} className="modal-dialog-centered template-row-modal template-modal">
      <ModalHeader toggle={onToggle}>{title || <LocaleMessage id={TABLE_ROW_CELL_MODAL.TEMPLATE_DESCRIPTION} />}</ModalHeader>
      <ModalBody>
        {isLoading ? <LocaleMessage id={LOADER.LOADING} /> : !!children ? children : description || <LocaleMessage id={TABLE_ROW_CELL_MODAL.NO_DESCRIPTION} />}
      </ModalBody>
      <ModalFooter className="justify-content-start">
        <Button color="primary common-button" onClick={onToggle}><LocaleMessage id={CLOSE} /></Button>
      </ModalFooter>
    </Modal>
  </div>
)

TableRowCellModal.propTypes = {
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onToggle: PropTypes.func
}

TableRowCellModal.defaultProps = {
description: '',
isLoading: false,
isOpen: false
}

export default TableRowCellModal
