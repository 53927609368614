import config from "./APIConfig"
import { getDataApi } from "../index"

export const getEnergyDiscrepancyRoles = async (data) => {
  const { url } = config.getEnergyDiscrepancyRoles
  return await getDataApi(url(data))
}

export const getProgramEnergy = async (data) => {
  const { url } = config.getProgramEnergy
  return await getDataApi(url(data))
}

export const getProgramEnergyVerificationOption = async (data) => {
  const { url } = config.getProgramEnergyVerificationOption
  return await getDataApi(url(data))
}

export const getProgramEnergyVM = async (data) => {
  const { url } = config.getProgramEnergyVM
  return await getDataApi(url(data))
}

export const getProgramEnergyWithContacts = async (data) => {
  const { url } = config.getProgramEnergyWithContacts
  return await getDataApi(url(data))
}

export const getProgramRequirementTypes = async () => {
  const { url } = config.getProgramRequirementTypes
  return await getDataApi(url)
}

export const getRequirementServiceSummary = async (data) => {
  const { url } = config.getRequirementServiceSummary
  return await getDataApi(url(data))
}

export const getSiteOrganizationContactList = async (data) => {
  const { url } = config.getSiteOrganizationContactList
  return await getDataApi(url(data))
}
