import config from "./APIConfig"
import { postDataApi } from "../index"

export const updateCommonTag = async (data) => {
  const { url } = config.updateCommonTag

  return await postDataApi(url(data), data)
}

export default updateCommonTag
