import config from "./APIConfig"
import { putDataApi } from '../index'

export const updateTaskReassignmentContact = (data) => {
  const { url } = config.updateTaskReassignmentContact

  return putDataApi(url(data), {})
}

export default updateTaskReassignmentContact
