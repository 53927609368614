import React, { useCallback } from "react"
import { useDispatch } from "react-redux"

import * as actions from "@store/actions"
import CustomCheckboxSearch from "./custom-checkbox-search"

const LocationUnitSearch = ({
  id = "unit",
  allSelectCheckboxLabel = "",
  inputPlaceholder,
  valueType = "unitApt",
  labelType = "unitApt",
  value = [],
  isResetClicked = false,
  onSelect = () => {},
  onResetClicked = () => {}
}) => {
  const dispatch = useDispatch()

  const handleFetchOptions = useCallback((params, callback = () => { }) => {
    const queryPayload = {
      ...params,
      unitApt: params.searchText
    }
    dispatch(actions.getLocationUnitListRequest(queryPayload, callback))
  }, [])

  const handleSelectFilter = useCallback(
    (value) => {
      onSelect(id, value)
    },
    [onSelect]
  )

  return (
    <CustomCheckboxSearch
      id={id}
      valueType={valueType}
      labelType={labelType}
      allSelectCheckboxLabel={allSelectCheckboxLabel}
      inputPlaceholder={inputPlaceholder}
      onFetchOptions={handleFetchOptions}
      onSelectFilter={handleSelectFilter}
      onResetClicked={onResetClicked}
      selectedFilters={value}
      isResetClicked={isResetClicked}
    />
  )
}

export default LocationUnitSearch