import React, { useEffect, useState } from "react"
import ReactTooltip from 'react-tooltip'
import { useDispatch, useSelector } from "react-redux"
import PropTypes from 'prop-types'
import * as actions from '@store/actions'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getUniqueId } from '@utils'
import { LocaleMessage } from '@views/components'

const { CLONE_MODAL } = LANGUAGE_CONSTANTS

const initialState = {
  stageOfConstruction: '',
  scopeOfWork: '',
  responsibleSiteRole: '',
  riskLevel: '',
  governanceRegulation: ''
}

const CheckpointSelectOption = ({ data: { checkPointId, checkpointVersionId = 1 }, label, value = '' }) => {

  const dispatch = useDispatch()
  const { stageOfConstruction, scopeOfWork, responsibleSiteRole, riskLevel } = useSelector(state => state.templateCheckpoints.checkpointDetailsVM)

  const optionId = getUniqueId()

  const [detail, setDetail] = useState(initialState)

  useEffect(() => {
    if (checkPointId) {
      dispatch(actions.getCheckpointCollectionRequest({
        checkpointId: checkPointId,
        checkpointVersionId,
        isCloning: true
      },
        (detail) => {
          if (detail) {
            const stageOfConstructionId = String(detail.stageOfConstructionId),
              scopeOfWorkId = String(detail.scopeOfWorkId),
              responsibleSiteRoleId = String(detail.responsibleSiteRoleId),
              riskLevelId = String(detail.riskLevelId)
            const detailList = {
              stageOfConstruction: stageOfConstructionId ? stageOfConstruction.find(sofc => sofc.value === stageOfConstructionId)?.text : '',
              scopeOfWork: scopeOfWorkId ? scopeOfWork.find(sofw => sofw.value === scopeOfWorkId)?.text : '',
              responsibleSiteRole: responsibleSiteRoleId ? responsibleSiteRole.find(rsr => rsr.value === responsibleSiteRoleId)?.text : '',
              riskLevel: riskLevelId ? riskLevel.find(rl => rl.value === riskLevelId)?.text : '',
              governanceRegulation: detail.goveranceRegulation || ''
            }
            setDetail(detailList)
          }
        }))
    }
    return () => {
      setDetail(initialState)
    }
  }, [checkPointId])

  return (
    <div className="list-unstyled orgnization-menu p-0">
      <a
        id={`${optionId}_option_label`}
        data-tip="tooltip"
        data-for={`${optionId}_option_description`}
      >
        <div className="orgnization-menu-list">
          {label}
        </div>
      </a>
      <ReactTooltip
        id={`${optionId}_option_description`}
        place={"right"}
        arrowColor={'transparent'}
        type="dark"
        effect="solid"
        multiline={true}
        disable={!value}
      >
        <div className="orgnization-submenu list-unstyled ">
          <div className="orgnization-submenu-list">
            <h4><LocaleMessage id={CLONE_MODAL.ACTIVITY_OPTION_HEADER} /></h4>
            <hr />
          </div>
          <div className="orgnization-submenu-list">
            <div className="address">
              {!!detail && (
                <>
                  <h6>{detail.stageOfConstruction || <LocaleMessage id={CLONE_MODAL.NO_STAGE_OF_CONSTRUCTION} />}<br /></h6>
                  <h6>{detail.scopeOfWork || <LocaleMessage id={CLONE_MODAL.NO_SCOPE_OF_WORK} />}<br /></h6>
                  <h6>{detail.responsibleSiteRole || <LocaleMessage id={CLONE_MODAL.NO_RESPONSIBLE_SITE_ROLE} />}<br /></h6>
                  <h6>{detail.riskLevel || <LocaleMessage id={CLONE_MODAL.NO_RISK_LEVEL} />}<br /></h6>
                  <h6>{detail.governanceRegulation || <LocaleMessage id={CLONE_MODAL.NO_GOVERNANCE_REGULATION} />}</h6>
                </>
              )}
            </div>
          </div>
        </div>
      </ReactTooltip>
    </div>
  )
}

CheckpointSelectOption.propTypes = {
  data: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.string
}

export default CheckpointSelectOption
