import { put, takeLatest, call } from "redux-saga/effects"

import {
  getOshaTrainingCoursesList,
  getOshaTrainingCampaign,
  getOshaTrainingSiteRole,
  addOshaTrainingCampaign,
  updateOshaTrainingCampaign
} from "@services/api"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { Notification } from "@views/components"
import * as actions from "@store/actions"

const { TRAINING_CAMPAIGN_SAGA } = LANGUAGE_CONSTANTS

/** getOshaTrainingSiteRole API **/
function* getOshaTrainingSiteRoleRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getOshaTrainingSiteRole, action.payload)
    if (response) {
      action.callback(response.data)
    } else {
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** addOshaTrainingCampaignRequest API **/
function* addOshaTrainingCampaignRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addOshaTrainingCampaign, action.payload)
    if (response.status === 204) {
      Notification({ status: 200, message: TRAINING_CAMPAIGN_SAGA.ADDING_SUCCESS })
      action.callback(true)
    } else {
      Notification({ status: 400, message: TRAINING_CAMPAIGN_SAGA.ERROR_IN_ADDING })
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getOshaTrainingCampaign API **/
function* getOshaTrainingCampaignRequest(action) {
  try {
    const { isLoadingRequired = false, ...payload } = action.payload || {}
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getOshaTrainingCampaign, payload)
    action.callback(response?.data)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    const { isLoadingRequired = false } = action.payload || {}
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    action.callback(null)
  }
}

/** getOshaTrainingCoursesList API **/
function* getOshaTrainingCoursesListRequest(action) {
  try {
    const response = yield call(getOshaTrainingCoursesList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** updateOshaTrainingCampaign API **/
function* updateOshaTrainingCampaignRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateOshaTrainingCampaign, action.payload)
    if (response.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: TRAINING_CAMPAIGN_SAGA.UPDATE_SUCCESS })
    } else {
      Notification({ status: 400, message: TRAINING_CAMPAIGN_SAGA.ERROR_IN_UPDATING_TRAINING_CAMPAIGN })
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

export function* trainingCampaignSagas() {
  yield takeLatest(actions.GET_OSHA_TRAINING_CAMPAIGN_REQUEST, getOshaTrainingCampaignRequest)
  yield takeLatest(actions.ADD_OSHA_TRAINING_CAMPAIGN_REQUEST, addOshaTrainingCampaignRequest)
  yield takeLatest(actions.GET_OSHA_TRAINING_SITE_ROLE_REQUEST, getOshaTrainingSiteRoleRequest)
  yield takeLatest(actions.GET_OSHA_TRAINING_COURSES_LIST_REQUEST, getOshaTrainingCoursesListRequest)
  yield takeLatest(actions.UPDATE_OSHA_TRAINING_COURSES_LIST_REQUEST, updateOshaTrainingCampaignRequest)
}

export default trainingCampaignSagas
