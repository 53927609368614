import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method cloneTemplate
 * @param {object} data 
 *
 */
export const cloneTemplate = (data) => {
  const { url } = config.cloneTemplate
  const payload = {
    cloneActivities: data.cloneActivities
  }
  return postDataApi(url(data), payload)
}

export default cloneTemplate