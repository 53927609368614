import { SET_WORKFLOW_SITE_ID, RESET_WORKFLOW_SITE_ID } from "@store/actions"

// ** Initial State
const initialState = {
  siteId: ""
}

const buildWorkflowReducer = (state = initialState, action) => {
  switch (action.type) {
    /** SET_WORKFLOW_SITE_ID **/
    case SET_WORKFLOW_SITE_ID:
      return { ...state, siteId: action.payload }

    /** RESET_WORKFLOW_SITE_ID **/
    case RESET_WORKFLOW_SITE_ID:
      return { ...state, siteId: "" }

    default:
      return state
  }
}

export default buildWorkflowReducer
