import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addTradeContact
 * @param {object} data
 * {
  "firstName": "string",
  "lastName": "string",
  "title": "string",
  "organizationID": 0,
  "email": "string",
  "phoneNumber": "string",
  "siteId": 0
}
 * 
 *
 */
export const addTradeContact = (payload) => {
  const { url } = config.addTradeContact
  return postDataApi(url, payload)
}

export default addTradeContact
