import { getSearchQuery } from "../../config"

const apiConfig = {
  createCampaignWorkflow: {
    method: 'POST',
    url: (campaignId) => `api/WorkFlow/setUp/CreateCampaignWorkflow/${campaignId}`
  },
  updateCampaignWorkflow: {
    method: 'PUT',
    url: (wfCampaignSetupId) => {
      const searchString = getSearchQuery('WFCampaignSetupId', wfCampaignSetupId, true)
      return `api/WorkFlow/setUp/UpdatedCampaignWorkflow${searchString}`
    }
  },
  addCampaignWorkflowVM: {
    method: 'GET',
    url: (campaignId) => `api/WorkFlow/setUp/addCampaignWorkFlowVM/${campaignId}`
  },
  deleteCampaignWorkflow: {
    method: 'DELETE',
    url: (wfCampaignSetupId) => {
      const searchString = getSearchQuery('WFCampaignSetupId', wfCampaignSetupId, true)
      return `api/WorkFlow/setUp/DeleteCampaignWorkflow${searchString}`
    }
  },
  getCampaignWorkflow: {
    method: 'GET',
    url: (wfCampaignSetupId) => {
      const searchString = getSearchQuery('WFCampaignSetupId', wfCampaignSetupId, true)
      return `api/WorkFlow/setUp/GetCampaignWorkFlow${searchString}`
    }
  },
  getCanAddWorkflow: {
    method: 'GET',
    url: (campaignId) => `api/WorkFlow/Canadd/${campaignId}`
  },
  getCampaignWorkflowList: {
    method: 'GET',
    url: ({
      campaignId,
      advanceSearch = {},
      genericSearch
    }) => {

      let searchString = ``
      if (!!Object.keys(advanceSearch || {}).length) {
        const {
          workflow, template, status, site
        } = advanceSearch
        const templateSearch = template.map((data) => getSearchQuery("WFTemplateIds", data.value)).join("")
        const statusSearch = status.map((data) => getSearchQuery("WFCampaignStatusIds", data.value)).join("")
        const siteSearch = site.map((data) => getSearchQuery("SiteIds", data.value)).join("")
        searchString = [
          getSearchQuery('CampaignId', campaignId, true),
          getSearchQuery('WorkflowName', workflow, false, true),
          templateSearch,
          statusSearch,
          siteSearch,
          getSearchQuery('WorkflowName', genericSearch, false, true)
        ].join('')
      }

      return `api/WorkFlow/GetCampaignWorkFlowList/${campaignId}${searchString}`
    }
  },
  updateFinishCampaignSetUp: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/FinishCampaignSetup/${wfCampaignSetupId}`
  },
  getWorkflowTemplateStepStatus: {
    method: 'GET',
    url: ({ wfCampaignSetupId, wfTemplateId }) => `api/WorkFlow/setUp/GetTemplateStepStatus/${wfCampaignSetupId}?WFTemplateId=${wfTemplateId}`
  },
  cloneCampaignWorkflow: {
    method: 'POST',
    url: (wfCampaignSetupId) => `api/WorkFlow/${wfCampaignSetupId}/clone`
  },
  updateWFCampaignStatus: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/Status/${wfCampaignSetupId}`
  },
  getWorkflowUnitList: {
    method: 'GET',
    url: ({
      campaignId,
      wfCampaignSetupId,
      pageSize,
      pageNumber,
      genericSearch,
      orderColumn,
      orderDirections
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        getSearchQuery("UnitName", genericSearch, false, true)
      ].join('')
      return `api/WorkFlow/WorkflowUnitList/${campaignId}/${wfCampaignSetupId}${searchString}`
    }
  },
  updateWorkflowUnitList: {
    method: 'POST',
    url: ({
      wfCampaignSetupId
    }) => {
      return `api/WorkFlow/WorkflowUnitList/${wfCampaignSetupId}`
    }
  },
  getWorkflowUnitListCount: {
    method: 'GET',
    url: ({
      wfCampaignSetupId,
      campaignId
    }) => {
      return `api/WorkFlow/WorkflowUnitListCount/${campaignId}/${wfCampaignSetupId}`
    }
  },

  getWorkflowOrganizationRoleList: {
    method: 'GET',
    url: ({
      wfCampaignSetupId,
      sitePartyRoleName = "",
      pageSize = 0,
      pageNumber
    }) => {
      const searchString = [
        getSearchQuery("WFCampaignSetupId", wfCampaignSetupId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("SitePartyRoleName", sitePartyRoleName, false, true)
      ].join('')
      return `api/WorkFlow/GetOrganizationRoleList/${wfCampaignSetupId}${searchString}`
    }
  }
}

export default apiConfig
