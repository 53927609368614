import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method reassignTaskReviewer
 * @param data 
 * {
  "observationTaskIds": [
    0
  ],
  "reviewContactId": 0
}
 */
export const reassignTaskReviewer = (data) => {
  const { url } = config.reassignTaskReviewer
  return putDataApi(url, data)
}

export default reassignTaskReviewer
