import { getActionType } from '../action-type-generator'

export const ADD_CHECKPOINT_TO_CHECKLIST_REQUEST = 'ADD_CHECKPOINT_TO_CHECKLIST_REQUEST'
export const GET_CAMPAIGN_CHECKLIST_VM_REQUEST = 'GET_CAMPAIGN_CHECKLIST_VM_REQUEST'
export const GET_CAMPAIGN_SETUP_CHECKLIST_VM_REQUEST = 'GET_CAMPAIGN_SETUP_CHECKLIST_VM_REQUEST'
export const ADD_SERVICE_CHECKLIST_REQUEST = 'ADD_SERVICE_CHECKLIST_REQUEST'
export const GET_SERVICE_CHECKLIST_REQUEST = 'GET_SERVICE_CHECKLIST_REQUEST'
export const DELETE_SERVICE_CHECKLIST_REQUEST = 'DELETE_SERVICE_CHECKLIST_REQUEST'
export const UPDATE_SERVICE_CHECKLIST_REQUEST = 'UPDATE_SERVICE_CHECKLIST_REQUEST'
export const UPDATE_CAMPAIGN_CHECKLIST_ORDER_REQUEST = 'UPDATE_CAMPAIGN_CHECKLIST_ORDER_REQUEST'

/****************************************************/
/** ADD_SERVICE_CHECKLIST_REQUEST **/
/****************************************************/

/**
 * @method addServiceChecklistRequest :  To add checklist to services
 *
 */
 export const addServiceChecklistRequest = (payload, callback) => {
  return {
      type: ADD_SERVICE_CHECKLIST_REQUEST,
      payload,
      callback
  }
}

/****************************************************/
/** GET_SERVICE_CHECKLIST_REQUEST **/
/****************************************************/

/**
 * @method getServiceChecklistDetailRequest : To get service checkist detail 
 *
 */
 export const getServiceChecklistDetailRequest = (payload, callback) => {
  return {
    type: GET_SERVICE_CHECKLIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_SERVICE_CHECKLIST_REQUEST **/
/****************************************************/

/**
 * @method deleteServiceChecklistRequest : To delete service checkist 
 *
 */
 export const deleteServiceChecklistRequest = (payload, callback) => {
  return {
    type: DELETE_SERVICE_CHECKLIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_SERVICE_CHECKLIST_REQUEST **/
/****************************************************/

/**
 * @method updateServiceChecklistRequest :  To update service checkist details
 *
 */
 export const updateServiceChecklistRequest = (payload, callback) => {
  return {
    type: UPDATE_SERVICE_CHECKLIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_CHECKPOINT_TO_CHECKLIST_REQUEST **/
/****************************************************/

/**
 * @method addCheckpointToChecklistRequest :  To add selected checkpoints to checklist
 *
 */
 export const addCheckpointToChecklistRequest = (payload, callback) => {
  return {
      type: ADD_CHECKPOINT_TO_CHECKLIST_REQUEST,
      payload,
      callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_CHECKLIST_VM_REQUEST **/
/****************************************************/

/**
 * @method getCampaignChecklistVM : To get campagin checklist VM
 *
 */
 export const getCampaignChecklistVMRequest = (callback = () => {}) => {
  return {
    type: GET_CAMPAIGN_CHECKLIST_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_SETUP_CHECKLIST_VM_REQUEST **/
/****************************************************/

/**
 * @method getCampaignSetupChecklistVM : To get campagin setupchecklist VM
 *
 */
 export const getCampaignSetupChecklistVMRequest = (callback = () => {}) => {
  return {
    type: GET_CAMPAIGN_SETUP_CHECKLIST_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** UPDATE_CAMPAIGN_CHECKLIST_ORDER_REQUEST **/
/****************************************************/

/**
 * @method updateCampaignChecklistOrder : To reorder the checklist
 *
 */
 export const updateCampaignChecklistOrder = (payload, callback) => {
  return {
    type: UPDATE_CAMPAIGN_CHECKLIST_ORDER_REQUEST,
    payload,
    callback
  }
}
