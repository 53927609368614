import config from "./APIConfig"
import { postDataApi } from '../index'

export const addSamplingWorkflowGroupUnitType = (data) => {
  const { url } = config.addSamplingWorkflowGroupUnitType

  return postDataApi(url({ workflowId: data.workflowId, groupId: data.groupId, sampleType: data.sampleType }), data.taskIdList)
}

export default addSamplingWorkflowGroupUnitType
