export const GET_PROGRAM_BASIC_DETAILS_REQUEST = "GET_PROGRAM_BASIC_DETAILS_REQUEST"
export const GET_PROGRAM_DETAILS_REQUEST = "GET_PROGRAM_DETAILS_REQUEST"
export const GET_PROGRAM_LIST_REQUEST = "GET_PROGRAM_LIST_REQUEST"
export const GET_PROGRAM_LIST_VM_REQUEST = "GET_PROGRAM_LIST_VM_REQUEST"
export const DELETE_PROGRAM_REQUEST = 'DELETE_PROGRAM_REQUEST'
export const GET_DETAILS__REQUIREMENTS_VM_REQUEST = "GET_DETAILS__REQUIREMENTS_VM_REQUEST"
export const GET_CREATE_PROGRAM_VM_REQUEST = "GET_CREATE_PROGRAM_VM_REQUEST"
export const GET_PROJECT_CONTACTS = "GET_PROJECT_CONTACTS"
export const ADD_PROGRAM_DETAIL_REQUEST = 'ADD_PROGRAM_DETAIL_REQUEST'
export const UPDATE_PROGRAM_DETAILS_REQUEST = "UPDATE_PROGRAM_DETAILS_REQUEST"
export const GET_PROGRAM_SAFETY_REQUIREMENTS_REQUEST = 'GET_PROGRAM_SAFETY_REQUIREMENTS_REQUEST'
export const ADD_PROGRAM_SAFETY_REQUIREMENTS_REQUEST = 'ADD_PROGRAM_SAFETY_REQUIREMENTS_REQUEST'
export const UPDATE_PROGRAM_SAFETY_REQUIREMENTS_REQUEST = 'UPDATE_PROGRAM_SAFETY_REQUIREMENTS_REQUEST'
export const GET_PROGRAM_DDL_REQUEST = 'GET_PROGRAM_DDL_REQUEST'
export const GET_OBSERVATIONAL_CAMPAIGN_VM_REQUEST = 'GET_OBSERVATIONAL_CAMPAIGN_VM_REQUEST'
export const GET_OBSERVATIONAL_CAMPAIGN_REQUEST = 'GET_OBSERVATIONAL_CAMPAIGN_REQUEST'
export const ADD_OBSERVATIONAL_CAMPAIGN_REQUEST = 'ADD_OBSERVATIONAL_CAMPAIGN_REQUEST'
export const GET_OSHA_DOCUMENTATION_CAMPAIGN_VM_REQUEST = 'GET_OSHA_DOCUMENTATION_CAMPAIGN_VM_REQUEST'
export const UPDATE_OBSERVATION_CAMPAIGN_REQUEST = 'UPDATE_OBSERVATION_CAMPAIGN_REQUEST'
export const CLONE_PROGRAM_REQUEST = 'CLONE_PROGRAM_REQUEST'

/****************************************************/
/** GET_PROGRAM_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProgramListRequest : To get program list
 *
 */
export const getProgramListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROGRAM_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_LIST_VM_REQUEST **/
/****************************************************/

/**
 * @method getProgramListVMRequest : To get program list VM
 *
 */
export const getProgramListVMRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_LIST_VM_REQUEST,
    payload,
    callback
  }
}


/****************************************************/
/** GET_DETAILS__REQUIREMENTS_VM_REQUEST **/
/****************************************************/

/**
 * @method getDetailRequirementsVMRequest : To get osha details requirement list VM
 *
 */
export const getDetailRequirementsVMRequest = (payload, callback) => {
  return {
    type: GET_DETAILS__REQUIREMENTS_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_PROGRAM_REQUEST **/
/****************************************************/

/**
 * @method deleteProgramRequest :  To delete program
 *
 */
export const deleteProgramRequest = (payload, callback) => {
  return {
    type: DELETE_PROGRAM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method getProgramDetailsRequest : To get program details
 *
 */
export const getProgramDetailsRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_DETAILS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PROGRAM_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method updateProgramDetailsRequest : To update program details
 *
 */
export const updateProgramDetailsRequest = (payload, callback) => {
  return {
    type: UPDATE_PROGRAM_DETAILS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_BASIC_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method getProgramBasicDetailsRequest : To get program basic details
 *
 */
export const getProgramBasicDetailsRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_BASIC_DETAILS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CREATE_PROGRAM_VM_REQUEST **/
/****************************************************/

/**
 * @method getCreateProgramVMRequest : To get program VM
 *
 */
export const getCreateProgramVMRequest = (payload, callback) => {
  return {
    type: GET_CREATE_PROGRAM_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROJECT_CONTACTS_REQUEST **/
/****************************************************/

/**
 * @method getProjectContacts : To get project contacts
 *
 */
export const getProjectContacts = (payload, callback) => {
  return {
    type: GET_PROJECT_CONTACTS,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_PROGRAM_DETAILS **/
/****************************************************/

/**
 * @method addProgramDetailRequest :  To add details of program
 *
 */
export const addProgramDetailRequest = (payload, callback) => {
  return {
    type: ADD_PROGRAM_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_PROGRAM_SAFETY_REQUIREMENTS **/
/****************************************************/

/**
 * @method addProgramSafetyRequirementsRequest :  To add program safety requirements
 *
 */
export const addProgramSafetyRequirementsRequest = (payload, callback) => {
  return {
    type: ADD_PROGRAM_SAFETY_REQUIREMENTS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_SAFETY_REQUIREMENTS **/
/****************************************************/

/**
 * @method getProgramSafetyRequirementsRequest :  To get program safety requirements
 *
 */
export const getProgramSafetyRequirementsRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_SAFETY_REQUIREMENTS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PROGRAM_SAFETY_REQUIREMENTS **/
/****************************************************/

/**
 * @method updateProgramSafetyRequirementsRequest :  To update program safety requirements
 *
 */
export const updateProgramSafetyRequirementsRequest = (payload, callback) => {
  return {
    type: UPDATE_PROGRAM_SAFETY_REQUIREMENTS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_DDL_REQUEST **/
/****************************************************/

/**
 * @method getProgramDDLRequest : To Get Paginated program DDL
 *
 */
export const getProgramDDLRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_OBSERVATIONAL_CAMPAIGN_VM **/
/****************************************************/

/**
 * @method getObservationalCampaignVMRequest : To get observation campaign VM
 *
 */
export const getObservationalCampaignVMRequest = (payload, callback) => {
  return {
    type: GET_OBSERVATIONAL_CAMPAIGN_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_OBSERVATIONAL_CAMPAIGN **/
/****************************************************/

/**
 * @method getObservationalCampaignRequest : To get observation campaign 
 *
 */
export const getObservationalCampaignRequest = (payload, callback) => {
  return {
    type: GET_OBSERVATIONAL_CAMPAIGN_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_OBSERVATIONAL_CAMPAIGN **/
/****************************************************/

/**
 * @method addObservationCampaign : To Add observation campaign
 *
 */
export const addObservationCampaign = (payload, callback) => {
  return {
    type: ADD_OBSERVATIONAL_CAMPAIGN_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_OSHA_DOCUMENTATION_CAMPAIGN_VM_REQUEST **/
/****************************************************/

/**
 * @method getOshaDocumentationCampaignVMRequest : To Get Osha Documentation Campaign VM
 *
 */
export const getOshaDocumentationCampaignVMRequest = (payload, callback) => {
  return {
    type: GET_OSHA_DOCUMENTATION_CAMPAIGN_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_OBSERVATION_CAMPAIGN_REQUEST **/
/****************************************************/

/**
 * @method updateObservationCampaign : To update Observation campaign
 *
 */
export const updateObservationCampaign = (payload, callback) => {
  return {
    type: UPDATE_OBSERVATION_CAMPAIGN_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CLONE_PROGRAM_REQUEST **/
/****************************************************/

/**
 * @method cloneProgramRequest : To clone existing program
 *
 */
export const cloneProgramRequest = (payload, callback) => {
  return {
    type: CLONE_PROGRAM_REQUEST,
    payload,
    callback
  }
}