import config from "./APIConfig"
import { getDataApi } from '../index'

export const getCommunicationLogList = async (data) => {
  const { url } = config.getCommunicationLogList
  return await getDataApi(url(data))
}

export const getCommunicationLogNotificationDetail = async (data) => {
  const { url } = config.getCommunicationLogNotificationDetail
  return await getDataApi(url(data))
}

export const getCommunicationLogVM = async () => {
  const { url } = config.getCommunicationLogVM
  return await getDataApi(url())
}
