import { getSearchQuery } from "../config"

const apiConfig = {
  getProgramEnergyVM: {
    method: "GET",
    url: (energyTypeId) => {
      const searchString = getSearchQuery('EnergyTypeId', energyTypeId, true)
      return `api/Program/EnergyVM${searchString}`
    }
  },
  createProgramEnergy: {
    method: "POST",
    url: 'api/Program/Energy'
  },
  getProgramEnergy: {
    method: "GET",
    url: ({ programId, energyTypeId }) => `api/Program/Energy/${programId}?ProgramId=${programId}&EnergyTypeId=${energyTypeId}`
  },
  getEnergyDiscrepancyRoles: {
    method: "GET",
    url: ({ siteId, pageNumber, pageSize }) => {
      const searchString = [
        getSearchQuery('PageNumber', pageNumber, true),
        getSearchQuery('PageSize', pageSize),
        getSearchQuery('SiteId', siteId)
      ].join('')
      return `api/Program/Energy/DiscrepanyRoles/${siteId}${searchString}`
    }
  },
  getProgramEnergyWithContacts: {
    method: "GET",
    url: ({ programId, energyTypeId }) => {
      const searchString = getSearchQuery('EnergyTypeId', energyTypeId, true)
      return `api/Program/Energy/ProgramEnergyWithContacts/${programId}${searchString}`
    }
  },
  saveProgramEnergyVerificationOption: {
    method: "POST",
    url: (programId) => `api/Program/Energy/VerificationOption/${programId}`
  },
  getProgramEnergyVerificationOption: {
    method: "GET",
    url: (programId) => `api/Program/Energy/VerificationOption/${programId}`
  },
  getProgramRequirementTypes: {
    method: "GET",
    url: 'api/Program/Energy/GetProgramRequirementTypes'
  },
  getRequirementServiceSummary: {
    method: "GET",
    url: ({ programRequirementTypeId, programId }) => `api/Program/Energy/RequirementServiceSummary/${programRequirementTypeId}/${programId}`
  },
  getSiteOrganizationContactList: {
    method: 'GET',
    url: ({ siteId, searchName, pageNumber, pageSize, allOption }) => {
      const searchString = [
        getSearchQuery('SiteId', siteId, true),
        getSearchQuery('PageNumber', pageNumber),
        getSearchQuery('PageSize', pageSize),
        getSearchQuery('SearchName', searchName),
        getSearchQuery('AllOption', allOption)
      ].join('')
      return `api/Program/Energy/SiteOrganizationContactList/${siteId}${searchString}`
    }
  }
}

export default apiConfig
