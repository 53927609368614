import React from 'react'

export const TableCellToolTip = (props) => {
  const { color, value, textColor } = props
  
  return (
    <div className="custom-tooltip" style={{ backgroundColor: color || 'white' }}>
      <p style={{ color: textColor || 'white' }}>
        {Array.isArray(value) ? value.join(',') : value}
      </p>
    </div>
  )
}
