import config from "./APIConfig"
import { deleteDataApi } from '../index'

/**
 * @method removeCheckpointFromTemplate
 * @param {object} data 
 *
 */
export const removeCheckpointFromTemplate = (templateCheckpointId) => {
  const { url } = config.removeCheckpointFromTemplate

  return deleteDataApi(url(templateCheckpointId))
}

export default removeCheckpointFromTemplate
