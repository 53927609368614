import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState, useCallback, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"
import { getAssignedByKeyList } from "./helpers"
import localeMessageWrapper from "../locale-message"

const { GROUP_ACTIVITES } = LANGUAGE_CONSTANTS

const ContactGroupSearch = ({
  intl,
  isMulti,
  isDisabled = false,
  isFormattedValue = true,
  isEditMode,
  isUpdateEditModeValue,
  errors,
  defaultOptions,
  menuPlacement,
  id,
  isRequired,
  isAllOption,
  isTableView,
  label,
  labelRequired,
  name,
  placeholder,
  value,
  initialLabel,
  valueType,
  labelType,
  onSelect,
  getOptionLabel,
  getOptionValue,
  onResetUpdateEditModeValue,
  ...props
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState(defaultOptions)

  /**
   * Note: Paginated select maintain cache of options,
   * so to set new default options we need to reset previous cache
   */
  const [isResetCache, setResetCache] = useState(false)
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  useEffect(() => {
    setResetCache((prevState) => !prevState)
    setOptions(defaultOptions)
  }, [defaultOptions])

  useEffect(() => {
    if (!!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])

  //To handle edit mode when single select
  useEffect(() => {
    //Note: To get option details for selected value in edit mode 
    if (isEditMode && !!initialLabel && !isEditModeItemLoaded && !!value) {
      setEditModeItemLoaded(true)
      setSelectedOptionLabel(initialLabel)
    }
  }, [initialLabel, isEditMode, isEditModeItemLoaded, value])

  //To handle edit mode when single select
  useEffect(() => {
    //Note: To get option details for selected value in edit mode 
    if (isUpdateEditModeValue && !!initialLabel && !!value) {
      onResetUpdateEditModeValue(false)
      setSelectedOptionLabel(initialLabel)
    }
  }, [initialLabel, isUpdateEditModeValue, value])

  const handleLoadMore = useCallback((search, page, prevOptions) => {
    return new Promise((resolve) => {

      const payload = {
        pageSize: DEFAULT_VM_PAGE_SIZE,
        pageNumber: page,
        contactGroupName: search,
        allOption: !!isAllOption
      }

      dispatch(actions.getContactGroupDDLRequest(payload, (response) => {
        if (response) {
          const { items, hasNextPage } = response

          resolve({
            optionList: items,
            hasMore: hasNextPage
          })

          setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
        } else {
          resolve({
            optionList: [],
            hasMore: false
          })
        }
      })
      )
    })
  }, [options])

  const handleSelect = useCallback(
    (selected) => {
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        let value = selected
        if (!!lastElement && !lastElement[valueType]) {
          value = []
        }
        onSelect(id, value)
      } else {
        const label = (selected?.[valueType] && selected?.[labelType])
        onSelect(id, selected?.[valueType] || "", label || "")
        setSelectedOptionLabel(label || "")
      }
    },
    [id, labelType, valueType, onSelect]
  )

  const tablePropsConfig = useMemo(() => {
    if (isTableView) {
      return {
        menuPortalTarget: document.body,
        styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) }
      }
    }
    return {}
  }, [isTableView])

  return (
    <div className={cx({
      'table-view-list': isTableView
    })}>
      {labelRequired && <CustomLabel title={label || GROUP_ACTIVITES.GROUPS} required={isRequired} isLocalizedTitle={!label} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id={id}
        name={name}
        placeholder={
          placeholder || getLocaleMessage(intl, GROUP_ACTIVITES.GROUPS)
        }
        options={options}
        defaultOptions={defaultOptions}
        keyList={getAssignedByKeyList(intl, labelType)}
        isFormattedValue={!isMulti}
        value={!isMulti
          ? !!value
            ? {
              [labelType]: selectedOptionLabel,
              [valueType]: value
            }
            : null
          : value}
        valueType={valueType}
        lableType={labelType}
        isMulti={isMulti}
        menuPlacement={menuPlacement}
        onSelect={handleSelect}
        onLoadOptions={handleLoadMore}
        getOptionLabel={getOptionLabel}
        hideSelectedOptions={false}
        getOptionValue={getOptionValue}
        isDisabled={isDisabled}
        isShowCustomAction={false}
        {...tablePropsConfig}
        {...props}
      />
      {errors && <ErrorMessage isShow={!!errors[id]} message={errors[id]} />}
    </div>
  )
}

ContactGroupSearch.propTypes = {
  intl: PropTypes.object,
  isMulti: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isUpdateEditModeValue: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errors: PropTypes.object,
  defaultOptions: PropTypes.array,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  isTableView: PropTypes.bool,
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  initialLabel: PropTypes.string,
  valueType: PropTypes.string,
  labelType: PropTypes.string,
  siteId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  organizationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  onSelect: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  onResetUpdateEditModeValue: PropTypes.func
}

ContactGroupSearch.defaultProps = {
  isEditMode: false,
  isMulti: false,
  isUpdateEditModeValue: false,
  isDisabled: false,
  defaultOptions: [],
  id: "groups",
  isRequired: false,
  isTableView: false,
  labelRequired: true,
  name: "groups",
  value: "",
  initialLabel: "",
  valueType: "value",
  labelType: "text",
  isAllOption: false,
  onSelect: () => { },
  getOptionLabel: (option) => option.text,
  getOptionValue: (option) => option.value,
  onResetUpdateEditModeValue: () => { }
}

export default localeMessageWrapper(ContactGroupSearch)
