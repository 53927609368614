import {
  GET_GLOBAL_SEARCH_LIST,
  RESET_GLOBAL_SEARCH_LIST
} from "../../actions"

// ** Initial State
const initialState = {
  totalCount: 0,
  searchItems: [],
  searchText: '',
  options: [],
  pageNumber: 0,
  isFetching: false
}

const formatSectionSearchList = (sectionKey, sectionSearchList) => {
  return sectionSearchList.map(item => ({
    ...item,
    sectionKey
  }))
}

const getFormattedList = (searchItemKeys, searchResult) => {
  let updatedSearchResult = []
  const itemKeyIndex = searchItemKeys.findIndex(itemKey => itemKey === 'totalCount')
  if (itemKeyIndex !== -1) {
    searchItemKeys.splice(itemKeyIndex, 1)
  }
  searchItemKeys.forEach(key => {
    if (searchResult[key]?.length) {
      updatedSearchResult = [
        ...updatedSearchResult,
        ...formatSectionSearchList(key, searchResult[key])
      ]
    }
  })

  return updatedSearchResult
}

const getResultList = (state, action) => {
  const previousSearchResult = state.searchItems
  const searchResult = action.data.results || {}
  const searchItemKeys = Object.keys(searchResult)
  const updatedSearchResult = getFormattedList(searchItemKeys, searchResult)
  
  if (action.data.pageNumber > 1) {
    if (!previousSearchResult.length) {
      return updatedSearchResult
    }

    return [
      ...previousSearchResult,
      ...updatedSearchResult
    ]
  }
  
  return updatedSearchResult
}

const globalSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GLOBAL_SEARCH_LIST.REQUEST:
      return {
        ...state,
        error: "",
        searchItems: action.payload.pageNumber === 1 ? [] : state.searchItems,
        searchText: action.payload.itemName,
        options: action.payload.options,
        isFetching: true
      }

    case GET_GLOBAL_SEARCH_LIST.SUCCESS:
      return {
        ...state,
        searchItems: getResultList(state, action),
        totalCount: action.data.results.totalCount,
        pageNumber: action.data.pageNumber,
        isFetching: false
      }

    case GET_GLOBAL_SEARCH_LIST.FAILURE:
      return { ...state, error: action.error, isFetching: false }

    case RESET_GLOBAL_SEARCH_LIST:
      return initialState

    default:
      return state
  }
}

export default globalSearchReducer
