import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch } from "react-redux"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { CustomReactSelect } from '@views/components'
import localeMessageWrapper from "@views/components/locale-message"

const SelectFilter = ({
  intl,
  searchFilterKey,
  agGridRef,
  selectedFilter: filterValue,
  isRefreshSavedFilterOptions,
  onSetRefreshSavedFilterOptions,
  isInitialSelectOptionRequired = false,
  onSelectFilter = () => { }
}) => {
  const dispatch = useDispatch()

  const [filterOptions, setFilterOptions] = useState([]) //For Select Filter Options
  const [isFetchingOptions, setFetchingOptions] = useState(true)
  const [isInitialRender, setInitialRender] = useState(true)

  const selectedFilter = useMemo(() => (filterValue?.searchFilterName === "0 Active Filter" ? null : filterValue), [filterValue])

  const handleFetchOptions = useCallback((data) => {
    const {
      isInitialFetch = false
    } = data || {}
    setFetchingOptions(true)
    if (!searchFilterKey) {
      setFetchingOptions(false)
      return
    }

    if (!isInitialFetch) {
      setInitialRender(false)
    }

    dispatch(
      actions.getAdvanceSearchFilterListRequest(searchFilterKey, (response) => {
        if (response) {
          setFilterOptions(response.lstOfSearchFilterListDto || [])
          try {
            const defaultSelectedFilter = response.lstOfSearchFilterListDto.find(option => option.isDefault === true)
            if (!selectedFilter && defaultSelectedFilter && isInitialRender) {
              const data = JSON.parse(defaultSelectedFilter.searchFilterJson.toString())
              onSelectFilter({
                value: {
                  advanceSearchFilterId: defaultSelectedFilter.advanceSearchFilterId,
                  searchFilterName: defaultSelectedFilter.searchFilterName,
                  columnConfig: data.columnConfig,
                  rowsPerPage: data.rowsPerPage,
                  advanceSearch: data.advanceSearchDetail ? data.advanceSearchDetail : data
                },
                isRefreshList: true,
                onApplyFilter: () => {
                  //To Apply View Column config
                  if (!!data.columnConfig) {
                    agGridRef?.current?.columnApi.applyColumnState({ state: data.columnConfig, applyOrder: true })
                  }
                }
              })
            } else if (!selectedFilter && !defaultSelectedFilter && isInitialRender) {
              onSelectFilter({
                hasNoDefaultFilter: true,
                isRefreshList: true
              })
            }
          } catch (err) { }
        }
        setFetchingOptions(false)
      })
    )
    setInitialRender(false)
  }, [selectedFilter, searchFilterKey, isInitialRender])

  useEffect(() => {
    handleFetchOptions({ isInitialFetch: true })
  }, [])

  useEffect(() => {
    if (isRefreshSavedFilterOptions) {
      handleFetchOptions()
      onSetRefreshSavedFilterOptions(false)
    }
  }, [isRefreshSavedFilterOptions])

  useEffect(() => {
    if (!isFetchingOptions) {
      if (selectedFilter?.advanceSearchFilterId === -1) {
        //To update Filter Options, when selected filter is not saved filter
        setFilterOptions(prevState => {
          const updatedState = [...prevState]
          const index = updatedState.findIndex(op => op.advanceSearchFilterId === -1)

          if (index !== -1) {
            updatedState[index] = selectedFilter
          } else {
            updatedState.push(selectedFilter)
          }
          return updatedState
        })
      } else if (!selectedFilter || selectedFilter.advanceSearchFilterId !== -1) {
        //To update Filter Options, when either no selected filter or selected filter is saved filter
        setFilterOptions(prevState => {
          const updatedState = [...prevState].filter(op => op?.advanceSearchFilterId !== -1)
          return updatedState
        })
      }
    }
  }, [isFetchingOptions, selectedFilter])

  const handleSelectFilter = useCallback(
    (selected) => {
      if (selected?.advanceSearchFilterId === -1) {
        /**
         * To disable selection of already selected, un-saved filter
         * Note: There will be only one un-saved filter, at any time in options list, that too, when its in applied state
         */
        return
      }

      let value = selected?.advanceSearchFilterId !== "" ? selected : null
      if (!!value && !value.searchFilterJson) {
        value = null
      }

      const data = value ? JSON.parse(value.searchFilterJson.toString()) : null

      const isRefreshList = true
      if (!!data) {
        onSelectFilter({
          value: {
            advanceSearchFilterId: selected.advanceSearchFilterId,
            searchFilterName: selected.searchFilterName,
            columnConfig: data.columnConfig,
            rowsPerPage: data.rowsPerPage,
            advanceSearch: data.advanceSearchDetail ? data.advanceSearchDetail : data
          },
          isRefreshList,
          onApplyFilter: () => {
            //To Apply View Column config
            if (!!data.columnConfig) {
              agGridRef?.current?.columnApi.applyColumnState({ state: data.columnConfig, applyOrder: true })
            }
          }
        })
      } else {
        onSelectFilter({
          value: {},
          isRefreshList,
          onApplyFilter: () => {}
        })
      }
    },
    [onSelectFilter]
  )

  return (
    <div sm={12} md={5} xl={3} className="mr-auto select-filter-outer">
      <CustomReactSelect
        isShowCustomAction={false}
        isPaginationRequired={false}
        hideSelectedOptions={false}
        isLoading={isFetchingOptions}
        isInitialSelectOptionRequired={isInitialSelectOptionRequired}
        id="select_filter"
        name="select_filter"
        isFormattedValue
        value={selectedFilter?.searchFilterName ? selectedFilter : null}
        options={filterOptions || []}
        defaultOptions={filterOptions || []}
        placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.SELECT_FILTER)}
        lableType={"searchFilterName"}
        valueType={"advanceSearchFilterId"}
        getOptionLabel={(item) => item.searchFilterName}
        getOptionValue={(item) => item.advanceSearchFilterId}
        onSelect={handleSelectFilter}
      />
    </div>
  )
}

export default localeMessageWrapper(SelectFilter)
