import cx from "classnames"
import React, { useCallback, useMemo } from "react"
import { FormGroup, CustomInput } from "reactstrap"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"
import CheckboxItem from './CheckboxItem'

const { NO_OPTIONS, LOADER } = LANGUAGE_CONSTANTS
const CheckboxFilter = ({
  intl,
  id = "",
  valueType = "value",
  labelType = "text",
  allSelectCheckboxLabel = "",
  values = [],
  options = [],
  isMulti = true,
  isLoading = false,
  onChange = () => { }
}) => {

  const handleAllSelectFilter = useCallback((e) => {
    if (e.target.checked) {
      onChange(id, options)
    } else {
      onChange(id, [])
    }
  }, [options, onChange])

  const isAllChecked = useMemo(() => !!options.length && values.length === options.length, [values, options])
  const hasNoOptions = useMemo(() => !options.length && !isLoading, [options, isLoading])

  return (
    <>
      <div className="custom-checkbox-search">
        <FormGroup>
          <CustomInput value={""} type="checkbox"
            id={`${id}_filter_}`}
            label={allSelectCheckboxLabel}
            onChange={handleAllSelectFilter}
            checked={isAllChecked}
            disabled={!options.length || !isMulti}
          />
          <div className={cx("filter-list", {
            "mb-1": hasNoOptions || isLoading
          })}>
            {options.map((filter, index) => {
              return (
                <CheckboxItem
                  key={`filter_${index}`}
                  id={id}
                  valueType={valueType}
                  labelType={labelType}
                  selectedFilters={values}
                  isMulti={isMulti}
                  index={index}
                  filter={filter}
                  onSelectFilter={onChange}
                />
              )
            })}
            {(isLoading || hasNoOptions) && (
              <div>
                <span>
                  <strong>{getLocaleMessage(intl, isLoading ? LOADER.LOADING : NO_OPTIONS)}</strong>
                </span>
              </div>
            )}
          </div>
        </FormGroup>
      </div>
    </>
  )
}

export default localeMessageWrapper(CheckboxFilter)