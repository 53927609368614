
export const GET_ACTION_LIST_REQUEST = 'GET_ACTION_LIST_REQUEST'
export const UPDATE_ACCEPT_BY_TRADE_REQUEST = 'UPDATE_ACCEPT_BY_TRADE_REQUEST'
export const GET_ACTION_LIST_VM_REQUEST = 'GET_ACTION_LIST_VM_REQUEST'
export const UPDATE_VERIFY_BY_SUPERINTENDENT_REQUEST = 'UPDATE_VERIFY_BY_SUPERINTENDENT_REQUEST'
export const CREATE_CONFIRM_SITE_VISIT_REQUEST = 'CREATE_CONFIRM_SITE_VISIT_REQUEST'
export const GET_ACTION_DETAILS_REQUEST = 'GET_ACTION_DETAILS_REQUEST'
export const GET_ACTION_AUDIT_TRAIL_REQUEST = 'GET_ACTION_AUDIT_TRAIL_REQUEST'
export const GET_TRADE_CONTACT_TO_REASSIGN = 'GET_TRADE_CONTACT_TO_REASSIGN'
export const UPDATE_TRADE_ACTION_REASSIGN = 'UPDATE_TRADE_ACTION_REASSIGN'
export const GET_SUPERINTENDENT_TO_REASSIGN = 'GET_SUPERINTENDENT_TO_REASSIGN'
export const UPDATE_SUPERINTENDENT_ACTION_REASSIGN = 'UPDATE_SUPERINTENDENT_ACTION_REASSIGN'
export const GET_REASSIGN_FAILED_INSPECTION_CONTACT_REQUEST = 'GET_REASSIGN_FAILED_INSPECTION_CONTACT_REQUEST'
export const UPDATE_REASSIGN_FAILED_INSPECTION_REQUEST = 'UPDATE_REASSIGN_FAILED_INSPECTION_REQUEST'
export const GET_CORRECTION_DETAILS_REQUEST = 'GET_CORRECTION_DETAILS_REQUEST'
export const GET_CONFIRM_SITE_VISIT_OVERDUE_WIDGET_REQUEST = 'GET_CONFIRM_SITE_VISIT_OVERDUE_WIDGET_REQUEST'
export const GET_FAILED_INSPECTION_WIDGET_REQUEST = 'GET_FAILED_INSPECTION_WIDGET_REQUEST'
export const GET_FAILED_INSPECTION_OVERDUE_WIDGET_REQUEST = 'GET_FAILED_INSPECTION_OVERDUE_WIDGET_REQUEST'
export const GET_VERIFY_BY_SUPER_INTENDENT_WIDGET_REQUEST = 'GET_VERIFY_BY_SUPER_INTENDENT_WIDGET_REQUEST'
export const GET_VERIFY_BY_SUPER_INTENDENT_OVERDUE_WIDGET_REQUEST = 'GET_VERIFY_BY_SUPER_INTENDENT_OVERDUE_WIDGET_REQUEST'
export const GET_REASSIGN_FAILED_INSPECTION_CORRECTION_WIDGET_REQUEST = 'GET_REASSIGN_FAILED_INSPECTION_CORRECTION_WIDGET_REQUEST'
export const GET_REASSIGN_FAILED_INSPECTION_CORRECTION_OVERDUE_WIDGET_REQUEST = 'GET_REASSIGN_FAILED_INSPECTION_CORRECTION_OVERDUE_WIDGET_REQUEST'
export const GET_CONFIRM_SITE_VISIT_WIDGET_REQUEST = 'GET_CONFIRM_SITE_VISIT_WIDGET_REQUEST'
export const GET_READY_ACTION_COUNT_WIDGET_REQUEST = 'GET_READY_ACTION_COUNT_WIDGET_REQUEST'
export const GET_READY_ACTION_OVERDUE_COUNT_WIDGET_REQUEST = 'GET_READY_ACTION_OVERDUE_COUNT_WIDGET_REQUEST'
export const GET_ENTITY_CONTACTS_REQUEST = 'GET_ENTITY_CONTACTS_REQUEST'
export const UPDATE_BULK_ASSIGN_ACTION_ITEMS = 'UPDATE_BULK_ASSIGN_ACTION_ITEMS'
export const GET_SIGN_OFF_REASON_VM_REQUEST = 'GET_SIGN_OFF_REASON_VM_REQUEST'
export const UPDATE_SIGNOFF_REQUEST = 'UPDATE_SIGNOFF_REQUEST'
export const UPDATE_REASSIGN_SIGNOFF_REQUEST = 'UPDATE_REASSIGN_SIGNOFF_REQUEST'
export const UPDATE_BULK_VERIFICATION_ACTION_REQUEST = 'UPDATE_BULK_VERIFICATION_ACTION_REQUEST'
export const UPDATE_BULK_CONFIRM_SITE_VISIT_ACTION_REQUEST = 'UPDATE_BULK_CONFIRM_SITE_VISIT_ACTION_REQUEST'
export const UPDATE_BULK_CORRECTION_ACTION_REQUEST = 'UPDATE_BULK_CORRECTION_ACTION_REQUEST'
export const UPDATE_BULK_ASSIGN_CORRECTION_ACTION_REQUEST = 'UPDATE_BULK_ASSIGN_CORRECTION_ACTION_REQUEST'
export const UPDATE_BULK_SIGN_OFF_ACTION_REQUEST = 'UPDATE_BULK_SIGN_OFF_ACTION_REQUEST'
export const UPDATE_BULK_ASSIGN_SIGN_OFF_ACTION_REQUEST = 'UPDATE_BULK_ASSIGN_SIGN_OFF_ACTION_REQUEST'

/****************************************************/
/** UPDATE_ACCEPT_BY_TRADE_REQUEST **/
/****************************************************/

/**
 * @method updateAcceptByTradeRequest :  To update failed inspection correction action for correction accept confirmation for some activity
 *
 */
export const updateAcceptByTradeRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_ACCEPT_BY_TRADE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_VERIFY_BY_SUPERINTENDENT_REQUEST **/
/****************************************************/

/**
 * @method updateVerifyBySuperintendentRequest :  To update verify by superintendent action for accept/reject verification for some activity
 *
 */
export const updateVerifyBySuperintendentRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_VERIFY_BY_SUPERINTENDENT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTION_LIST_REQUEST **/
/****************************************************/

/**
 * @method getActionListRequest :  To get list of actions for card view
 *
 */
export const getActionListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ACTION_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTION_LIST_VM_REQUEST **/
/****************************************************/

/**
 * @method getActionListVMRequest :  To get VM for action list
 *
 */
export const getActionListVMRequest = (callback = () => { }) => {
  return {
    type: GET_ACTION_LIST_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** CREATE_CONFIRM_SITE_VISIT **/
/****************************************************/

/**
 * @method createConfirmSiteVisitRequest :  To create a confirm site visit for workflow scheduling
 *
 */
export const createConfirmSiteVisitRequest = (payload, callback = () => { }) => {
  return {
    type: CREATE_CONFIRM_SITE_VISIT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTION_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method getActionDetailsRequest :  To get the action details
 *
 */
export const getActionDetailsRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ACTION_DETAILS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTION_AUDIT_TRAIL_REQUEST **/
/****************************************************/

/**
 * @method getActionAuditTrailRequest :  To get the action audit trail details
 *
 */
export const getActionAuditTrailRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ACTION_AUDIT_TRAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TRADE_CONTACT_TO_REASSIGN **/
/****************************************************/

/**
 * @method getTradeContactToReassignRequest:  To get the actions trade contacts list
 *
 */
export const getTradeContactToReassignRequest = (payload, callback = () => { }) => {
  return {
    type: GET_TRADE_CONTACT_TO_REASSIGN,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_TRADE_ACTION_REASSIGN **/
/****************************************************/

/**
 * @method updateTradeActionReassignRequest:  To update the actions trade contact
 *
 */
export const updateTradeActionReassignRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_TRADE_ACTION_REASSIGN,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SUPERINTENDENT_TO_REASSIGN **/
/****************************************************/

/**
 * @method getSuperintendentToReassignRequest:  To get the reassign failed inspection correction action contacts list
 *
 */
export const getSuperintendentToReassignRequest = (payload, callback = () => { }) => {
  return {
    type: GET_SUPERINTENDENT_TO_REASSIGN,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_SUPERINTENDENT_ACTION_REASSIGN **/
/****************************************************/

/**
 * @method updateSuperintendentActionReassignRequest:  To update the reassign failed inspection correction action contact
 *
 */
export const updateSuperintendentActionReassignRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_SUPERINTENDENT_ACTION_REASSIGN,
    payload,
    callback
  }
}

/****************************************************/
/** GET_REASSIGN_FAILED_INSPECTION_CONTACT_REQUEST **/
/****************************************************/

/**
 * @method getReassignFailedInspectionContactRequest:  To get the reassign failed inspection correction action contacts list
 *
 */
export const getReassignFailedInspectionContactRequest = (payload, callback = () => { }) => {
  return {
    type: GET_REASSIGN_FAILED_INSPECTION_CONTACT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_REASSIGN_FAILED_INSPECTION_REQUEST **/
/****************************************************/

/**
 * @method updateReassignFailedInspectionRequest:  To update the reassign failed inspection correction action contact
 *
 */
export const updateReassignFailedInspectionRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_REASSIGN_FAILED_INSPECTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CORRECTION_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method getCorrectionDetailsRequest :  To get the correction details
 *
 */
export const getCorrectionDetailsRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CORRECTION_DETAILS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CONFIRM_SITE_VISIT_OVERDUE_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getConfirmSiteVisitOverdueWidgetRequest :  To get the confirm site visit overdue count widget
 *
 */
export const getConfirmSiteVisitOverdueWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CONFIRM_SITE_VISIT_OVERDUE_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_FAILED_INSPECTION_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getFailedInspectionWidgetRequest :  To get failed inspection count widget
 *
 */
export const getFailedInspectionWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_FAILED_INSPECTION_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_FAILED_INSPECTION_OVERDUE_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getFailedInspectionOverdueWidgetRequest :  To get failed inspection overdue count widget
 *
 */
export const getFailedInspectionOverdueWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_FAILED_INSPECTION_OVERDUE_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_VERIFY_BY_SUPER_INTENDENT_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getVerifyBySuperIntendentWidgetRequest :  To get verify by superintendent count widget
 *
 */
export const getVerifyBySuperIntendentWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_VERIFY_BY_SUPER_INTENDENT_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_VERIFY_BY_SUPER_INTENDENT_OVERDUE_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getVerifyBySuperIntendentOverdueWidgetRequest :  To get verify by superintendent overdue count widget
 *
 */
export const getVerifyBySuperIntendentOverdueWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_VERIFY_BY_SUPER_INTENDENT_OVERDUE_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_REASSIGN_FAILED_INSPECTION_CORRECTION_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getReassignFailedInspectionCorrectionWidgetRequest :  To get reassign failed inspection correction count widget
 *
 */
export const getReassignFailedInspectionCorrectionWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_REASSIGN_FAILED_INSPECTION_CORRECTION_WIDGET_REQUEST,
    payload,
    callback
  }
}

/**********************************************************************/
/** GET_REASSIGN_FAILED_INSPECTION_CORRECTION_OVERDUE_WIDGET_REQUEST **/
/**********************************************************************/

/**
 * @method getReassignFailedInspectionCorrectionOverdueWidgetRequest :  To get reassign failed inspection correction overdue count widget
 *
 */
export const getReassignFailedInspectionCorrectionOverdueWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_REASSIGN_FAILED_INSPECTION_CORRECTION_OVERDUE_WIDGET_REQUEST,
    payload,
    callback
  }
}

/**********************************************************************/
/** GET_CONFIRM_SITE_VISIT_WIDGET_REQUEST **/
/**********************************************************************/

/**
 * @method getConfirmSiteVisitWidgetRequest :  To get confirm site visit count widget
 *
 */
export const getConfirmSiteVisitWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CONFIRM_SITE_VISIT_WIDGET_REQUEST,
    payload,
    callback
  }
}

/**********************************************************************/
/** GET_READY_ACTION_COUNT_WIDGET_REQUEST **/
/**********************************************************************/

/**
 * @method getReadyActionCountWidgetRequest :  To get ready action count widget
 *
 */
export const getReadyActionCountWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_READY_ACTION_COUNT_WIDGET_REQUEST,
    payload,
    callback
  }
}

/**********************************************************************/
/** GET_READY_ACTION_OVERDUE_COUNT_WIDGET_REQUEST **/
/**********************************************************************/

/**
 * @method getReadyActionOverdueCountWidgetRequest :  To get ready action overdue count widget
 *
 */
export const getReadyActionOverdueCountWidgetRequest = (payload, callback = () => { }) => {
  return {
    type: GET_READY_ACTION_OVERDUE_COUNT_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ENTITY_CONTACTS_REQUEST **/
/****************************************************/

/**
 * @method getEntityContactsRequest:  To get the Entity contacts list
 *
 */
export const getEntityContactsRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ENTITY_CONTACTS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BULK_ASSIGN_ACTION_ITEMS **/
/****************************************************/

/**
 * @method updateBulkAssignActionItemsRequest :  To update bulk assign action items
 *
 */
export const updateBulkAssignActionItemsRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_BULK_ASSIGN_ACTION_ITEMS,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SIGN_OFF_REASON_VM_REQUEST **/
/****************************************************/

/**
 * @method getSignOffReasonVMRequest :  To get VM for action sign off reason
 *
 */
export const getSignOffReasonVMRequest = (callback = () => { }) => {
  return {
    type: GET_SIGN_OFF_REASON_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** UPDATE_SIGNOFF_REQUEST **/
/****************************************************/

/**
 * @method updateSignOffRequest :  To update sign off action
 *
 */
export const updateSignOffRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_SIGNOFF_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_REASSIGN_SIGNOFF_REQUEST **/
/****************************************************/

/**
 * @method updateReassignSignOffRequest :  To update reassign sign off action
 *
 */
export const updateReassignSignOffRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_REASSIGN_SIGNOFF_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BULK_VERIFICATION_ACTION_REQUEST **/
/****************************************************/

/**
 * @method updateBulkVerificationActionRequest :  To update bulk verify by superintendent action for verify/reject verification for some activity
 *
 */
export const updateBulkVerificationActionRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_BULK_VERIFICATION_ACTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BULK_CONFIRM_SITE_VISIT_ACTION_REQUEST **/
/****************************************************/

/**
 * @method updateBulkConfirmSiteVisitActionRequest :  To update a confirm site visit for workflow scheduling
 *
 */
export const updateBulkConfirmSiteVisitActionRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_BULK_CONFIRM_SITE_VISIT_ACTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BULK_CORRECTION_ACTION_REQUEST **/
/****************************************************/

/**
 * @method updateBulkCorrectionActionItemsRequest :  To update failed inspection correction action for correction accept confirmation for some activity
 *
 */
export const updateBulkCorrectionActionItemsRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_BULK_CORRECTION_ACTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BULK_ASSIGN_CORRECTION_ACTION_REQUEST **/
/****************************************************/

/**
 * @method updateBulkAssignCorrectionActionItemsRequest :  To update the reassign failed inspection correction action contact
 *
 */
export const updateBulkAssignCorrectionActionItemsRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_BULK_ASSIGN_CORRECTION_ACTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BULK_SIGN_OFF_ACTION_REQUEST **/
/****************************************************/

/**
 * @method updateBulkSignOffActionRequest :  To update a sign off for workflow scheduling
 *
 */
export const updateBulkSignOffActionRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_BULK_SIGN_OFF_ACTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BULK_ASSIGN_SIGN_OFF_ACTION_REQUEST **/
/****************************************************/

/**
 * @method updateBulkAssignSignOffActionRequest :  To update a assign sign off for workflow scheduling
 *
 */
export const updateBulkAssignSignOffActionRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_BULK_ASSIGN_SIGN_OFF_ACTION_REQUEST,
    payload,
    callback
  }
}