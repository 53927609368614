import config from "./APIConfig"
import { postDataApi } from '../index'

export const addAdditionalPurchaseOrder = (data) => {
  const { url } = config.addAdditionalPurchaseOrder

  return postDataApi(url(data.observationTaskId), data)
}

export default addAdditionalPurchaseOrder
