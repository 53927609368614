export * from "./assign-activities"
export * from "./automate-report"
export * from "./checklist-activities-group"
export * from "./training-template"

export const GET_CREATE_TRIGGER_VM_REQUEST = 'GET_CREATE_TRIGGER_VM_REQUEST'
export const GET_INSPECTION_CORRECTION_VM_REQUEST = 'GET_INSPECTION_CORRECTION_VM_REQUEST'
export const GET_CONFIRM_SITE_VISIT_VM_REQUEST = 'GET_CONFIRM_SITE_VISIT_VM_REQUEST'
export const GET_SCHEDULE_TASK_VM_REQUEST = 'GET_SCHEDULE_TASK_VM_REQUEST'
export const GET_FAILED_INSPECTION_TRAINING_VM_REQUEST = 'GET_FAILED_INSPECTION_TRAINING_VM_REQUEST'
export const GET_TASK_CLOSEOUT_VM_REQUEST = 'GET_TASK_CLOSEOUT_VM_REQUEST'
export const CREATE_BLOCK_TRIGGER_REQUEST = 'CREATE_BLOCK_TRIGGER_REQUEST'
export const CREATE_BLOCK_SCHEDULE_TASK_REQUEST = 'CREATE_BLOCK_SCHEDULE_TASK_REQUEST'
export const CREATE_BLOCK_CONFIRM_SITE_VISIT_REQUEST = "CREATE_BLOCK_CONFIRM_SITE_VISIT_REQUEST"
export const GET_BLOCK_TRIGGER_REQUEST = 'GET_BLOCK_TRIGGER_REQUEST'
export const GET_BLOCK_SCHEDULE_TASK_REQUEST = 'GET_BLOCK_SCHEDULE_TASK_REQUEST'
export const GET_BLOCK_CONFIRM_SITE_VISIT_REQUEST = 'GET_BLOCK_CONFIRM_SITE_VISIT_REQUEST'
export const UPDATE_BLOCK_TRIGGER_REQUEST = 'UPDATE_BLOCK_TRIGGER_REQUEST'
export const UPDATE_BLOCK_SCHEDULE_TASK_REQUEST = 'UPDATE_BLOCK_SCHEDULE_TASK_REQUEST'
export const CREATE_BLOCK_FAILED_INSPECTION_CORRECTION = "CREATE_BLOCK_FAILED_INSPECTION_CORRECTION"
export const UPDATE_BLOCK_CONFIRM_SITE_VISIT_REQUEST = 'UPDATE_BLOCK_CONFIRM_SITE_VISIT_REQUEST'
export const GET_BLOCK_FAILED_INSPECTION_CORRECTION = "GET_BLOCK_FAILED_INSPECTION_CORRECTION"
export const UPDATE_BLOCK_FAILED_INSPECTION_CORRECTION = "UPDATE_BLOCK_FAILED_INSPECTION_CORRECTION"
export const GET_BLOCK_OBSERVATION_ESCALATION_VM_REQUEST = "GET_BLOCK_OBSERVATION_ESCALATION_VM_REQUEST"
export const CREATE_BLOCK_ESCALATION_REQUEST = "CREATE_BLOCK_ESCALATION_REQUEST"
export const CREATE_BLOCK_FAILED_INSPECTION_TRAINING = "CREATE_BLOCK_FAILED_INSPECTION_TRAINING"
export const GET_BLOCK_ESCALATION_REQUEST = "GET_BLOCK_ESCALATION_REQUEST"
export const GET_BLOCK_FAILED_INSPECTION_TRAINING = "GET_BLOCK_FAILED_INSPECTION_TRAINING"
export const UPDATE_BLOCK_FAILED_INSPECTION_TRAINING = "UPDATE_BLOCK_FAILED_INSPECTION_TRAINING"
export const DELETE_CAMPAIGN_CHECKLIST_REQUEST = "DELETE_CAMPAIGN_CHECKLIST_REQUEST"
export const UPDATE_BLOCK_ESCALATION_REQUEST = "UPDATE_BLOCK_ESCALATION_REQUEST"
export const CREATE_BLOCK_TASK_CLOSEOUT = "CREATE_BLOCK_TASK_CLOSEOUT"
export const GET_BLOCK_TASK_CLOSEOUT = "GET_BLOCK_TASK_CLOSEOUT"
export const UPDATE_BLOCK_TASK_CLOSEOUT = "UPDATE_BLOCK_TASK_CLOSEOUT"
export const GET_TEMPLATE_STEP_QUERY_REQUEST = "GET_TEMPLATE_STEP_QUERY_REQUEST"
export const GET_SITE_PLAN_TRIGGER_VM_REQUEST = 'GET_SITE_PLAN_TRIGGER_VM_REQUEST'
export const GET_SITE_PLAN_SCHEDULE_LINE_ITEM_DDL_REQUEST = 'GET_SITE_PLAN_SCHEDULE_LINE_ITEM_DDL_REQUEST'
export const GET_FINDINGS_CORRECTION_REQUEST = 'GET_FINDINGS_CORRECTION_REQUEST'
export const GET_FINDINGS_CORRECTION_VM_REQUEST = 'GET_FINDINGS_CORRECTION_VM_REQUEST'
export const UPDATE_FINDINGS_CORRECTION_REQUEST = 'UPDATE_FINDINGS_CORRECTION_REQUEST'
export const ADD_FINDINGS_CORRECTION_REQUEST = 'ADD_FINDINGS_CORRECTION_REQUEST'
export const GET_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_VM_REQUEST = 'GET_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_VM_REQUEST'
export const CREATE_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST = 'CREATE_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST'
export const UPDATE_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST = 'UPDATE_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST'
export const GET_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST = 'GET_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST'
export const GET_TASK_REVIEW_VM_REQUEST = 'GET_TASK_REVIEW_VM_REQUEST'
export const CREATE_BLOCK_TASK_REVIEW_REQUEST = 'CREATE_BLOCK_TASK_REVIEW_REQUEST'
export const UPDATE_BLOCK_TASK_REVIEW_REQUEST = 'UPDATE_BLOCK_TASK_REVIEW_REQUEST'
export const GET_BLOCK_TASK_REVIEW_REQUEST = 'GET_BLOCK_TASK_REVIEW_REQUEST'
export const GET_BLOCK_SCHEDULE_AUTOMATE_REPORTS_VM_REQUEST = 'GET_BLOCK_SCHEDULE_AUTOMATE_REPORTS_VM_REQUEST'
export const CREATE_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST = 'CREATE_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST'
export const UPDATE_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST = 'UPDATE_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST'
export const GET_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST = 'GET_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST'
export const CREATE_BLOCK_PARTIAL_SUBMISSION_REQUEST = 'CREATE_BLOCK_PARTIAL_SUBMISSION_REQUEST'
export const GET_PARTIAL_SUBMISSION_VM_REQUEST = 'GET_PARTIAL_SUBMISSION_VM_REQUEST'
export const UPDATE_BLOCK_PARTIAL_SUBMISSION_REQUEST = 'UPDATE_BLOCK_PARTIAL_SUBMISSION_REQUEST'
export const GET_BLOCK_PARTIAL_SUBMISSION_REQUEST = 'GET_BLOCK_PARTIAL_SUBMISSION_REQUEST'
export const GET_BLOCK_TASK_SIGN_OFF_REQUEST = 'GET_BLOCK_TASK_SIGN_OFF_REQUEST'
export const GET_BLOCK_TASK_SIGN_OFF_VM_REQUEST = 'GET_BLOCK_TASK_SIGN_OFF_VM_REQUEST'
export const CREATE_BLOCK_TASK_SIGN_OFF_REQUEST = 'CREATE_BLOCK_TASK_SIGN_OFF_REQUEST'
export const UPDATE_BLOCK_TASK_SIGN_OFF_REQUEST = 'UPDATE_BLOCK_TASK_SIGN_OFF_REQUEST'
export const GET_BLOCK_CHECKLIST_VM_REQUEST = 'GET_BLOCK_CHECKLIST_VM_REQUEST'
export const GET_BLOCK_CHECKLIST_REQUEST = 'GET_BLOCK_CHECKLIST_REQUEST'
export const CREATE_BLOCK_CHECKLIST_REQUEST = 'CREATE_BLOCK_CHECKLIST_REQUEST'
export const UPDATE_BLOCK_CHECKLIST_REQUEST = 'UPDATE_BLOCK_CHECKLIST_REQUEST'
export const DELETE_CUSTOMIZED_CHILD_CAMPAIGN_BLOCK_REQUEST = 'DELETE_CUSTOMIZED_CHILD_CAMPAIGN_BLOCK_REQUEST'
export const GET_FINDING_WORKFLOWS_REQUEST = 'GET_FINDING_WORKFLOWS_REQUEST'

/****************************************************/
/** GET_CREATE_TRIGGER_VM_REQUEST **/
/****************************************************/

/**
 * @method getCreateTriggerVMRequest :  To get VM for create trigger block
 *
 */
export const getCreateTriggerVMRequest = (payload, callback) => {
  return {
    type: GET_CREATE_TRIGGER_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_INSPECTION_CORRECTION_VM_REQUEST **/
/****************************************************/

/**
 * @method getInspectionCorrectionVMRequest :  To get VM for inspection correction block
 *
 */
export const getInspectionCorrectionVMRequest = (payload, callback) => {
  return {
    type: GET_INSPECTION_CORRECTION_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CONFIRM_SITE_VISIT_VM_REQUEST **/
/****************************************************/


/**
 * @method getConfirmSiteVisitVMRequest :  To get VM for confirm site visit block
 *
 */
export const getConfirmSiteVisitVMRequest = (payload, callback) => {
  return {
    type: GET_CONFIRM_SITE_VISIT_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SCHEDULE_TASK_VM_REQUEST **/
/****************************************************/

/**
 * @method getScheduleTaskVMRequest :  To get VM for schedule task block
 *
 */
export const getScheduleTaskVMRequest = (payload, callback) => {
  return {
    type: GET_SCHEDULE_TASK_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_FAILED_INSPECTION_TRAINING_VM_REQUEST **/
/****************************************************/

/**
 * @method getFailedInspectionTrainingVMRequest :  To get VM for failed inspection training block
 *
 */
export const getFailedInspectionTrainingVMRequest = (callback) => {
  return {
    type: GET_FAILED_INSPECTION_TRAINING_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_TASK_CLOSEOUT_VM_REQUEST **/
/****************************************************/

/**
 * @method getTaskCloseOutVMRequest :  To get VM for task closeout block
 *
 */
export const getTaskCloseOutVMRequest = (callback) => {
  return {
    type: GET_TASK_CLOSEOUT_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_TRIGGER_REQUEST **/
/****************************************************/

/**
 * @method createBlockTriggerRequest :  To create trigger block 
 *
 */
export const createBlockTriggerRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_TRIGGER_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_TRIGGER_REQUEST **/
/****************************************************/

/**
 * @method getBlockTriggerRequest : To get details of block trigger
 *
 */
export const getBlockTriggerRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_TRIGGER_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_TRIGGER_REQUEST **/
/****************************************************/

/**
 * @method updateBlockTriggerRequest : To update details of block trigger
 *
 */
export const updateBlockTriggerRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_TRIGGER_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_SCHEDULE_TASK_REQUEST **/
/****************************************************/

/**
 * @method createBlockScheduleTaskRequest :  To create schedule task block 
 *
 */
export const createBlockScheduleTaskRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_SCHEDULE_TASK_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_SCHEDULE_TASK_REQUEST **/
/****************************************************/

/**
 * @method getBlockScheduleTaskRequest : To get details of block schedule task
 *
 */
export const getBlockScheduleTaskRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_SCHEDULE_TASK_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_SCHEDULE_TASK_REQUEST **/
/****************************************************/

/**
 * @method updateBlockScheduleTaskRequest : To update details of block schedule task
 *
 */
export const updateBlockScheduleTaskRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_SCHEDULE_TASK_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_CONFIRM_SITE_VISIT_REQUEST **/
/****************************************************/

/**
 * @method createBlockConfirmSiteVisitRequest :  To create schedule task block 
 *
 */
export const createBlockConfirmSiteVisitRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_CONFIRM_SITE_VISIT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_CONFIRM_SITE_VISIT_REQUEST **/
/****************************************************/

/**
 * @method getBlockConfirmSiteVisitRequest : To get details of block confirm site visit
 *
 */
export const getBlockConfirmSiteVisitRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_CONFIRM_SITE_VISIT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_CONFIRM_SITE_VISIT_REQUEST **/
/****************************************************/

/**
 * @method updateBlockConfirmSiteVisitRequest : To update details of block confirm site visit
 *
 */
export const updateBlockConfirmSiteVisitRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_CONFIRM_SITE_VISIT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_FAILED_INSPECTION_CORRECTION **/
/****************************************************/

/**
 * @method createBlockFailedInspectionCorrectionRequest : To create block failed inspection correction
 *
 */
export const createBlockFailedInspectionCorrectionRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_FAILED_INSPECTION_CORRECTION,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_FAILED_INSPECTION_CORRECTION **/
/****************************************************/

/**
 * @method getBlockFailedInspectionCorrectionRequest : To get details of block failed inspection correction
 *
 */
export const getBlockFailedInspectionCorrectionRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_FAILED_INSPECTION_CORRECTION,
    payload,
    callback
  }
}
/****************************************************/
/** UPDATE_BLOCK_FAILED_INSPECTION_CORRECTION **/
/****************************************************/

/**
 * @method updateBlockFailedInspectionCorrectionRequest : To update details of block failed inspection correction
 *
 */
export const updateBlockFailedInspectionCorrectionRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_FAILED_INSPECTION_CORRECTION,
    payload,
    callback
  }
}
/****************************************************/
/** GET_BLOCK_OBSERVATION_ESCALATION_VM_REQUEST **/
/****************************************************/

/**
 * @method getBlockObservationEscalationVMRequest : To get VM for workflow observation escalation block
 *
 */
export const getBlockObservationEscalationVMRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_OBSERVATION_ESCALATION_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_ESCALATION_REQUEST **/
/****************************************************/

/**
 * @method createBlockEscalationRequest : To create block escalation 
 *
 */
export const createBlockEscalationRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_ESCALATION_REQUEST,
    payload,
    callback
  }
}
/** CREATE_BLOCK_FAILED_INSPECTION_TRAINING **/
/****************************************************/

/**
 * @method createBlockFailedInspectionTraining : To create block failed Inspection Training
 *
 */
export const createBlockFailedInspectionTrainingRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_FAILED_INSPECTION_TRAINING,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_ESCALATION_REQUEST **/
/****************************************************/

/**
 * @method getBlockEscalationRequest : To get details for workflow escalation block
 *
 */
export const getBlockEscalationRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_ESCALATION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_FAILED_INSPECTION_TRAINING **/
/****************************************************/

/**
 * @method getBlockFailedInspectionTrainingRequest : To get details of block failed inspection training
 *
 */
export const getBlockFailedInspectionTrainingRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_FAILED_INSPECTION_TRAINING,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_FAILED_INSPECTION_TRAINING **/
/****************************************************/

/**
 * @method updateBlockFailedInspectionTrainingRequest : To update details of block failed inspection training
 *
 */
export const updateBlockFailedInspectionTrainingRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_FAILED_INSPECTION_TRAINING,
    payload,
    callback
  }
}
/****************************************************/
/** UPDATE_BLOCK_ESCALATION_REQUEST **/
/****************************************************/

/**
 * @method updateBlockEscalation : To update details of block failed inspection training
 *
 */
export const updateBlockEscalationRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_ESCALATION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_CAMPAIGN_CHECKLIST_REQUEST **/
/****************************************************/

/**
 * @method deleteCampaignChecklistRequest : To delete the campaign checklist
 *
 */
export const deleteCampaignChecklistRequest = (payload, callback) => {
  return {
    type: DELETE_CAMPAIGN_CHECKLIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_TASK_CLOSEOUT **/
/****************************************************/

/**
 * @method createBlockTaskCloseout : To create task closeout
 *
 */
export const createBlockTaskCloseoutRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_TASK_CLOSEOUT,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_TASK_CLOSEOUT **/
/****************************************************/

/**
 * @method getBlockTaskCloseout : To get task closeout details
 *
 */
export const getBlockTaskCloseoutRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_TASK_CLOSEOUT,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_TASK_CLOSEOUT **/
/****************************************************/

/**
 * @method updateBlockTaskCloseout : To update task closeout details
 *
 */
export const updateBlockTaskCloseoutRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_TASK_CLOSEOUT,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TEMPLATE_STEP_QUERY_REQUEST **/
/****************************************************/

/**
 * @method getTemplateStepQueryRequest : To get workflow template steps details
 *
 */
export const getTemplateStepQueryRequest = (payload, callback) => {
  return {
    type: GET_TEMPLATE_STEP_QUERY_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SITE_PLAN_TRIGGER_VM_REQUEST **/
/****************************************************/
/**
 * @method getSitePlanTriggerVMRequest : To get site plan edit triggers table content
 *
 */
export const getSitePlanTriggerVMRequest = (payload, callback) => {
  return {
    type: GET_SITE_PLAN_TRIGGER_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SITE_PLAN_SCHEDULE_LINE_ITEM_DDL_REQUEST **/
/****************************************************/
/**
 * @method getSitePlanScheduleLineItemDDLRequest : To get site plan schedule line item VM
 *
 */
export const getSitePlanScheduleLineItemDDLRequest = (payload, callback) => {
  return {
    type: GET_SITE_PLAN_SCHEDULE_LINE_ITEM_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_FINDINGS_CORRECTION_REQUEST **/
/****************************************************/
/**
 * @method getFindingsCorrectionRequest : To get findings correction request
 *
 */
export const getFindingsCorrectionRequest = (payload, callback) => {
  return {
    type: GET_FINDINGS_CORRECTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_FINDINGS_CORRECTION_VM_REQUEST **/
/****************************************************/
/**
 * @method getFindingsCorrectionVMRequest : To get findings correction VM request
 *
 */
export const getFindingsCorrectionVMRequest = (callback) => {
  return {
    type: GET_FINDINGS_CORRECTION_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** ADD_FINDINGS_CORRECTION_REQUEST **/
/****************************************************/
/**
 * @method addFindingsCorrectionVMRequest : To add findings correction request
 *
 */
export const addFindingsCorrectionRequest = (payload, callback) => {
  return {
    type: ADD_FINDINGS_CORRECTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_FINDINGS_CORRECTION_REQUEST **/
/****************************************************/
/**
 * @method updateFindingsCorrectionRequest : To update findings correction request
 *
 */
export const updateFindingsCorrectionRequest = (payload, callback) => {
  return {
    type: UPDATE_FINDINGS_CORRECTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_VM_REQUEST **/
/****************************************************/
/**
 * @method getBlockScheduleRecordableSettingsVMRequest : To get block schedule recordable settings VM request
 *
 */
export const getBlockScheduleRecordableSettingsVMRequest = (callback) => {
  return {
    type: GET_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST **/
/****************************************************/

/**
 * @method createBlockScheduleRecordableSettingsRequest :  To create schedule recordable settings block 
 *
 */
export const createBlockScheduleRecordableSettingsRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST **/
/****************************************************/

/**
 * @method updateBlockScheduleRecordableSettingsRequest :  To update schedule recordable settings block 
 *
 */
export const updateBlockScheduleRecordableSettingsRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST **/
/****************************************************/

/**
 * @method getBlockScheduleRecordableSettingsRequest :  To get schedule recordable settings block details
 *
 */
export const getBlockScheduleRecordableSettingsRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_SCHEDULE_RECORDABLE_SETTINGS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_REVIEW_VM_REQUEST **/
/****************************************************/

/**
 * @method getTaskReviewVMRequest :  To get task review VM
 *
 */
export const getTaskReviewVMRequest = (payload, callback = () => { }) => {
  return {
    type: GET_TASK_REVIEW_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_SCHEDULE_AUTOMATE_REPORTS_VM_REQUEST **/
/****************************************************/
/**
 * @method getBlockScheduleAutomateReportsVMRequest : To get block schedule automate reports VM request
 *
 */
 export const getBlockScheduleAutomateReportsVMRequest = (payload, callback = () => { }) => {
  return {
    type: GET_BLOCK_SCHEDULE_AUTOMATE_REPORTS_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_TASK_REVIEW_REQUEST **/
/****************************************************/

/**
 * @method createBlockTaskReviewRequest :  To create task review block 
 *
 */
export const createBlockTaskReviewRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_TASK_REVIEW_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST **/
/****************************************************/

/**
 * @method createBlockScheduleAutomateReportsRequest :  To create schedule automate reports block 
 *
 */
 export const createBlockScheduleAutomateReportsRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_TASK_REVIEW_REQUEST **/
/****************************************************/

/**
 * @method updateBlockTaskReviewRequest :  To update task review block 
 *
 */
export const updateBlockTaskReviewRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_TASK_REVIEW_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST **/
/****************************************************/

/**
 * @method updateBlockScheduleAutomateReportsRequest :  To update schedule automate reports block 
 *
 */
 export const updateBlockScheduleAutomateReportsRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_TASK_REVIEW_REQUEST **/
/****************************************************/

/**
 * @method getBlockTaskReviewRequest :  To get task review block 
 *
 */
export const getBlockTaskReviewRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_TASK_REVIEW_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST **/
/****************************************************/

/**
 * @method getBlockScheduleAutomateReportsRequest:  To get Block Schedule Automate Reports
 *
 */
 export const getBlockScheduleAutomateReportsRequest = (payload, callback = () => { }) => {
  return {
    type: GET_BLOCK_SCHEDULE_AUTOMATE_REPORTS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PARTIAL_SUBMISSION_VM_REQUEST **/
/****************************************************/

/**
 * @method getPartialSubmissionVMRequest :  To get partial submission VM
 *
 */
export const getPartialSubmissionVMRequest = (callback = () => { }) => {
  return {
    type: GET_PARTIAL_SUBMISSION_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_PARTIAL_SUBMISSION_REQUEST **/
/****************************************************/

/**
 * @method createBlockPartialSubmissionRequest :  To create partial submission block 
 *
 */
export const createBlockPartialSubmissionRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_PARTIAL_SUBMISSION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_PARTIAL_SUBMISSION_REQUEST **/
/****************************************************/

/**
 * @method updateBlockPartialSubmissionRequest :  To update partial submission block 
 *
 */
export const updateBlockPartialSubmissionRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_PARTIAL_SUBMISSION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_PARTIAL_SUBMISSION_REQUEST **/
/****************************************************/

/**
 * @method getBlockPartialSubmissionRequest:  To get Block Partial submission
 *
 */
export const getBlockPartialSubmissionRequest = (payload, callback = () => { }) => {
  return {
    type: GET_BLOCK_PARTIAL_SUBMISSION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_TASK_SIGN_OFF_VM_REQUEST **/
/****************************************************/

/**
 * @method getBlockTaskSignOffVMRequest :  To get VM for task sign off block
 *
 */
export const getBlockTaskSignOffVMRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_TASK_SIGN_OFF_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_TASK_SIGN_OFF_REQUEST **/
/****************************************************/

/**
 * @method createBlockTaskSignOffRequest :  To create task sign off block 
 *
 */
export const createBlockTaskSignOffRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_TASK_SIGN_OFF_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_TASK_SIGN_OFF_REQUEST **/
/****************************************************/

/**
 * @method updateBlockTaskSignOffRequest :  To update task sign off block 
 *
 */
export const updateBlockTaskSignOffRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_TASK_SIGN_OFF_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_TASK_SIGN_OFF_REQUEST **/
/****************************************************/

/**
 * @method getBlockTaskSignOffRequest : To get details of block task sign off
 *
 */
export const getBlockTaskSignOffRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_TASK_SIGN_OFF_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_CHECKLIST_VM_REQUEST **/
/****************************************************/

/**
 * @method getBlockChecklistVMRequest : To get VM for workflow checklist block
 *
 */
export const getBlockChecklistVMRequest = (callback) => {
  return {
    type: GET_BLOCK_CHECKLIST_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_CHECKLIST_REQUEST **/
/****************************************************/

/**
 * @method getBlockChecklistRequest : To get details of the photo types for workflow checklist block
 *
 */
export const getBlockChecklistRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_CHECKLIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_CHECKLIST_REQUEST **/
/****************************************************/

/**
 * @method createBlockChecklistRequest :  To create workflow checklist for photo type block 
 *
 */
export const createBlockChecklistRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_CHECKLIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_CHECKLIST_REQUEST **/
/****************************************************/

/**
 * @method updateBlockChecklistRequest :  To update workflow checklist for photo type block
 *
 */
export const updateBlockChecklistRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_CHECKLIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_CUSTOMIZED_CHILD_CAMPAIGN_BLOCK_REQUEST **/
/****************************************************/

/**
 * @method deleteCustomizedChildCampaignBlockRequest : To delete the customized child campaign block
 *
 */
export const deleteCustomizedChildCampaignBlockRequest = (payload, callback) => {
  return {
    type: DELETE_CUSTOMIZED_CHILD_CAMPAIGN_BLOCK_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_FINDING_WORKFLOWS_REQUEST **/
/****************************************************/

/**
 * @method getFindingWorkflowsRequest : To Get Paginated finding workflows DDL
 *
 */
export const getFindingWorkflowsRequest = (payload, callback) => {
  return {
    type: GET_FINDING_WORKFLOWS_REQUEST,
    payload,
    callback
  }
}
