import cx from "classnames"
import { useCallback } from "react"
import { DropdownItem } from "reactstrap"

import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"
import { ROW_ACTION_TYPES } from "./config"

const ActionItem = ({ intl, item, onClick = () => { } }) => {
  const handleClickActionItem = useCallback(
    (event) => {
      event.preventDefault()
      onClick(item.id, item.labelLocaleId)
    },
    [item, onClick]
  )

  return (
    <DropdownItem tag="a" className="w-100 menu-option" onClick={handleClickActionItem}>
      <span
        className={cx("align-middle ml-50", {
          "text-danger": (item.id === ROW_ACTION_TYPES.DELETE || item.id === "clear")
        })}
      >
        {getLocaleMessage(intl, item.labelLocaleId)}
      </span>
    </DropdownItem>
  )
}

export default localeMessageWrapper(ActionItem)
