import config from "./APIConfig"
import { postDataApi, putDataApi } from '../index'

/**
 * @method updateOrganizationUnitPlan : To update existing unit plan
 * @param {*} data 
 * 
 * Payload for update Unit Plan: 
 * 
 *  {
  "siteUnitPlanId": 0,
  "siteUnitId": 0,
  "mfncCompartmentalizationArea": 0,
  // "equipmentRatedSEER": 0,
  // "enterMaximumTotalHeatGain": 0,
  // "meteringDeviceId": 0,
  // "targetSubcooling": 0,
  // "qDesignHeatingCFM": 0,
  // "qDesignCoolingCFM": 0,
  "isMissingItems": true,
  "planName": "string",
  "organizationEnergyModelId": 0,
  "organizationBluePrintId": 0,
  "swing": "string",
  "totalConditionSpace": 0,
  "firstFloorArea": 0,
  "secondFloorArea": 0,
  "thirdFloorArea": 0,
  "fourthFloorArea": 0,
  "organizationPlanId": 0,
  "planNumber": "string",
  "elevation": 0,
  "volume": 0
}
 * 
 * @returns 
 */
export const updateOrganizationUnitPlan = (data) => {
  const { url } = config.updateOrganizationUnitPlan
  return putDataApi(url(data), data, undefined, { pathConfig: { isCheckpointInspectionService: true } })
}

export default updateOrganizationUnitPlan
