import { getSearchQuery } from '../config'

const getQueryPayload = ({
  campaignId,
  projectId,
  programId,
  organizationId,
  entityId,
  workflowId,
  taskId,
  siteId,
  unitId
}) => {
  const searchString = [
    getSearchQuery("CampaignId", campaignId),
    getSearchQuery("ProjectId", projectId),
    getSearchQuery("ProgramId", programId),
    getSearchQuery("OrganizationId", organizationId),
    getSearchQuery("EntityId", entityId),
    getSearchQuery("WorkflowId", workflowId),
    getSearchQuery("TaskId", taskId),
    getSearchQuery("SiteId", siteId),
    getSearchQuery("UnitId", unitId)
  ].join('')

  return searchString.replace('&', '?')
}

const apiConfig = {
  getOverviewDetails: {
    method: 'GET',
    url: ({ organizationId, programId, siteId, campaignId }) => {

      if (programId) {
        return `api/Program/overview/${programId}`
      }

      if (siteId) {
        return `api/Project/Site/overview/${siteId}`
      }

      if (campaignId) {
        return `api/Campaign/overview/${campaignId}`
      }

      return `api/Organization/overview/${organizationId}`
    }
  },
  getReportWidgetStatus: {
    method: 'GET',
    url: (params) => {
      return `api/Report/widget/Status${getQueryPayload(params)}`
    }
  },
  getActiveProjectSites: {
    method: 'GET',
    url: (params) => {
      return `api/Report/widget/ActiveProjectSites${getQueryPayload(params)}`
    }
  },
  getActivePrograms: {
    method: 'GET',
    url: (params) => {
      return `api/Report/widget/ActivePrograms${getQueryPayload(params)}`
    }
  },
  getUpcomingObservationTasks: {
    method: 'GET',
    url: (params) => {
      return `api/Report/widget/UpcomingObservationTasks${getQueryPayload(params)}`
    }
  },
  getUnapprovedRoleChartWidget: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/Presentation/UnapprovedActivitiesbyRoleWidget${getQueryPayload(params)}`
    }
  },
  getUnapprovedRoleChartDelta: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/Presentation/UnapprovedActivitiesbyRoleWidgetDelta${getQueryPayload(params)}`
    }
  },
  getActivityTypeListChartWidget: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/Presentation/ActivityTypeWidget${getQueryPayload(params)}`
    }
  },
  getTrainingChartWidget: {
    method: 'GET',
    url: (params) => {
     return `api/Report/Widget/Presentation/UnapprovedvsTrainingWidget${getQueryPayload(params)}`
    }
  },
  getTrainingChartDelta: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/Presentation/UnapprovedvsTrainingWidgetDelta${getQueryPayload(params)}`
    }
  },
  getKnowledgeAssessmentChart: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/Presentation/KnowledgeAssessmentWidget${getQueryPayload(params)}`
    }
  },
  getCourseParticipationWidget: {
    method: 'GET',
    url: (params) => {
     return `api/Report/Widget/Presentation/CourseParticipationWidget${getQueryPayload(params)}`
    }
  },
  getDuctLeakageChartWidget: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/DuctLeakageTrackingWidget${getQueryPayload(params)}`
    }
  },
  getHouseInfiltrationChartRequest: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/HouseInfiltrationTrackingWidget${getQueryPayload(params)}`
    }
  },
  getRecentCampaignActivityWidget: {
    method: 'GET',
    url: (params) => {
      return `api/Report/Widget/Presentation/RecentCampaignActivityWidget${getQueryPayload(params)}`
    }
  }
}

export default apiConfig
