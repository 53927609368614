import React, { useMemo } from "react"
import { useForm } from 'react-hook-form'
import { Row, Col, Form, FormGroup, Input } from "reactstrap"
import { Trash2 } from "react-feather"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomLabel, ReactSelect } from "@views/components"
import { getReactSelectPositionProps } from "@views/helpers"
import PropTypes from 'prop-types'

const { RESOURCES } = LANGUAGE_CONSTANTS

const AcceptedFileList = ({
  errors = {},
  intl,
  selectedFiles = [],
  isResourceUploader = false,
  fileCategoryList = [],
  isDisableFileCategory = false,
  onChangeDetails = () => { },
  onRemoveDocument = () => { }
}) => {
  const { control } = useForm({})
  const reactSelectPositionProps = useMemo(() => {
    return getReactSelectPositionProps()
  }, [])

  return (
    <Form className={"accepted-files"}>
      <div>
        {selectedFiles.map((sFile, index) => {
          return (
            <Row className="file-details m-0" key={`resource-${index}`}>
              <Col className="p-0">
                <FormGroup>
                  <div className="d-flex file-details-inner align-items-center">
                    <CustomLabel title={RESOURCES.TITLE} />
                    <div className="resume-box">
                      <Input
                        type="text"
                        name="fileName"
                        placeholder={getLocaleMessage(intl, RESOURCES.TITLE)}
                        value={sFile.fileName}
                        onChange={(event) => onChangeDetails("fileName", event.target.value, index)}
                      />
                      <CustomLabel className="doc-ext" title={sFile.extension} isLocalizedTitle={false} />
                      <Trash2 size={15} onClick={() => onRemoveDocument(index)} />
                    </div>
                  </div>
                  <div className="d-flex file-details-inner align-items-center mt-1">
                    <div className="mr-4"><CustomLabel title={RESOURCES.NOTES} /></div>
                    <Input
                      type="textarea"
                      name="fileDescription"
                      placeholder={getLocaleMessage(intl, RESOURCES.FILE)}
                      value={sFile.fileDescription}
                      onChange={(event) => onChangeDetails(
                        "fileDescription",
                        event.target.value,
                        index
                      )}
                    />
                  </div>
                  {isResourceUploader && (
                    <>
                      <div className="d-flex file-category file-details-inner align-items-center mt-1">
                        <CustomLabel title={RESOURCES.CATEGORY} />
                        <div className="w-100">
                          <ReactSelect
                            isRequired
                            id='fileCategory'
                            name='fileCategory'
                            control={control}
                            value={sFile.fileCategoryId}
                            isDisabled={isDisableFileCategory}
                            placeHolder={getLocaleMessage(intl, RESOURCES.SELECT_FILE_CATEGORY)}
                            options={fileCategoryList}
                            onSelect={(selected) => onChangeDetails('fileCategoryId', selected?.value || '', index)}
                            getOptionLabel={(item) => item.text}
                            getOptionValue={(item) => item.value}
                            {...reactSelectPositionProps}
                          />
                        </div>
                      </div>
                    </>
                  )
                  }
                </FormGroup>
                <ErrorMessage
                  isShow={!!errors[`fileName_${index}`]}
                  message={errors[`fileName_${index}`]}
                />
              </Col>
            </Row>
          )
        })}
      </div>
    </Form>
  )
}

AcceptedFileList.propTypes = {
  errors: PropTypes.object,
  intl: PropTypes.object,
  selectedFiles: PropTypes.array,
  onChangeDetails: PropTypes.func,
  onRemoveDocument: PropTypes.func
}

export default AcceptedFileList
