export const CREATE_BLOCK_ADD_TRAINING_REQUEST = 'CREATE_BLOCK_ADD_TRAINING_REQUEST'
export const GET_BLOCK_ADD_TRAINING_REQUEST = 'GET_BLOCK_ADD_TRAINING_REQUEST'
export const UPDATE_BLOCK_ADD_TRAINING_REQUEST = 'UPDATE_BLOCK_ADD_TRAINING_REQUEST'
export const GET_BLOCK_DUE_DATE_VM_REQUEST = 'GET_BLOCK_DUE_DATE_VM_REQUEST'
export const GET_BLOCK_DUE_DATE_REQUEST = 'GET_BLOCK_DUE_DATE_REQUEST'
export const CREATE_BLOCK_DUE_DATE_REQUEST = 'CREATE_BLOCK_DUE_DATE_REQUEST'
export const UPDATE_BLOCK_DUE_DATE_REQUEST = 'UPDATE_BLOCK_DUE_DATE_REQUEST'
export const GET_BLOCK_FAILED_TRAINING_REQUEST = "GET_BLOCK_FAILED_TRAINING_REQUEST"
export const UPDATE_BLOCK_FAILED_TRAINING_REQUEST = "UPDATE_BLOCK_FAILED_TRAINING_REQUEST"
export const CREATE_BLOCK_FAILED_TRAINING_REQUEST = "CREATE_BLOCK_FAILED_TRAINING_REQUEST"
export const GET_BLOCK_FAILED_TRAINING_VM_REQUEST = "GET_BLOCK_FAILED_TRAINING_VM_REQUEST"
export const GET_BLOCK_TRAINING_ESCALATION_VM_REQUEST = "GET_BLOCK_TRAINING_ESCALATION_VM_REQUEST"

/****************************************************/
/** CREATE_BLOCK_ADD_TRAINING_REQUEST **/
/****************************************************/

/**
 * @method createBlockAddTrainingRequest :  To create add training block 
 *
 */
export const createBlockAddTrainingRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_ADD_TRAINING_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_ADD_TRAINING_REQUEST **/
/****************************************************/

/**
 * @method getBlockAddTrainingRequest : To get details of block add training
 *
 */
export const getBlockAddTrainingRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_ADD_TRAINING_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_ADD_TRAINING_REQUEST **/
/****************************************************/

/**
 * @method updateBlockAddTrainingRequest : To update details of block add training
 *
 */
export const updateBlockAddTrainingRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_ADD_TRAINING_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_DUE_DATE_VM_REQUEST **/
/****************************************************/

/**
 * @method getBlockDueDateVMRequest : To get VM for due date block
 *
 */
export const getBlockDueDateVMRequest = (callback = () => { }) => {
  return {
    type: GET_BLOCK_DUE_DATE_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_DUE_DATE_REQUEST **/
/****************************************************/

/**
 * @method getBlockDueDateRequest : To get details for due date block
 *
 */
export const getBlockDueDateRequest = (payload, callback = () => { }) => {
  return {
    type: GET_BLOCK_DUE_DATE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_DUE_DATE_REQUEST **/
/****************************************************/

/**
 * @method createBlockDueDateRequest :  To create due date block
 *
 */
export const createBlockDueDateRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_DUE_DATE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_DUE_DATE_REQUEST **/
/****************************************************/

/**
 * @method updateBlockDueDateRequest : To update details of block due date
 *
 */
export const updateBlockDueDateRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_DUE_DATE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_TRAINING_ESCALATION_VM_REQUEST **/
/****************************************************/

/**
 * @method getBlockTrainingEscalationVMRequest : To get VM for workflow training escalation block
 *
 */
export const getBlockTrainingEscalationVMRequest = (callback) => {
  return {
    type: GET_BLOCK_TRAINING_ESCALATION_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_FAILED_TRAINING_REQUEST **/
/****************************************************/

/**
 * @method getBlockFailedTrainingRequest : To get block failed training request
 *
 */
export const getBlockFailedTrainingRequest = (payload, callback) => {
  return {
    type: GET_BLOCK_FAILED_TRAINING_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_BLOCK_FAILED_TRAINING_REQUEST **/
/****************************************************/

/**
 * @method updateBlockFailedTrainingRequest : To update block failed training request
 *
 */
export const updateBlockFailedTrainingRequest = (payload, callback) => {
  return {
    type: UPDATE_BLOCK_FAILED_TRAINING_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_BLOCK_FAILED_TRAINING_REQUEST **/
/****************************************************/

/**
 * @method createBlockFailedTrainingRequest : To create block failed training request
 *
 */
export const createBlockFailedTrainingRequest = (payload, callback) => {
  return {
    type: CREATE_BLOCK_FAILED_TRAINING_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_BLOCK_FAILED_TRAINING_VM_REQUEST **/
/****************************************************/

/**
 * @method getBlockFailedTrainingVMRequest : To get block failed training vm request
 *
 */
export const getBlockFailedTrainingVMRequest = (callback) => {
  return {
    type: GET_BLOCK_FAILED_TRAINING_VM_REQUEST,
    callback
  }
}