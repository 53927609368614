
import { RESET_REDUCER_REQUEST, RESET_SEARCH_HISTORY, UPDATE_LIST_PAGE_SEARCH_HISTORY } from '../../actions'

// ** Initial State
const initialState = {}

const searchHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    /** UPDATE_LIST_PAGE_SEARCH_HISTORY **/
    case UPDATE_LIST_PAGE_SEARCH_HISTORY:
      return {
          ...state,
          [action.payload.keyName]: action.payload.searchValues
      }
      /** RESET_SEARCH_HISTORY **/
    case RESET_SEARCH_HISTORY:
      return initialState
    /** RESET_REDUCER_REQUEST **/
    case RESET_REDUCER_REQUEST:
      return initialState
    default:
      return state
  }
}

export default searchHistoryReducer
