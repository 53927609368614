import { SCHEDULING_TASK_STATUS_TYPES } from '@shared/constants'
import config from "./APIConfig"
import { postDataApi } from '../index'

export const updateTaskStatus = (data) => {
  const { url } = config.updateTaskStatus
  const payload = {
    listOfTasks: data.listOfTaskIds || [],
    statusId: data.statusId || 0
  }
 
  if (SCHEDULING_TASK_STATUS_TYPES.SAMPLED === data.statusId) {
    payload.sampleDate = new Date(data.sampleDate).toISOString()
  }

  return postDataApi(url, payload)
}

export default updateTaskStatus
