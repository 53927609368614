import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateProjectSitePlan
 * @param {object} data 
 *  {
      "siteId": 0,
      "sitePlans": [
         {
            "sitePlanId": 0,
            "planName": "string",
            "planNumber": "string",
            "version": "string",
            "elevation": "string",
            "sitePlanNumberOfStoryId": 0,
            "numberOfBedroom": "string",
            "sitePlanNumberOfBathroomId": 0,
            "squareFootage": "string",
            "planDescription": "string"
            "ekoTrope": boolean,
            "numOfHVACSystems": number,
            "customJSONData": "string"
          }        
      ]
    }
 *
 */
export const updateProjectSitePlan = (data) => {
  const { url } = config.updateProjectSitePlan
  const payload = {
    siteId: Number(data.siteId),
    sitePlans: [
      {
        sitePlanId: Number(data.sitePlanId),
        planName: data.planName,
        planNumber: data.planNumber,
        version: data.version,
        elevation: data.elevation,
        squareFootage: data.squareFootage,
        numberOfBedroom: data.numberOfBedroom,
        sitePlanNumberOfStoryId: Number(data.stories || ''),
        sitePlanNumberOfBathroomId: Number(data.bathrooms || ''),
        planDescription: data.planDescription,
        ekoTrope: data.ekoTrope,
        numberOfHVACSystem: Number(data.numOfHVACSystems),
        customJSONData: data.customJSONData?.trim()
      }
    ]
  }

  return putDataApi(url({ planId: data.planId }), payload)
}

export default updateProjectSitePlan
