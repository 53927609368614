import config from "./APIConfig"
import { postDataApi } from '../../index'

/**
 * @method createBlockAddTraining
 * @param {object} data 
 * 
 * {
 *  wfCampaignSetupId: "",
 *  trainingName: "",
 *  "responsibleRole": [
    {
      "siteRoleId": 0,
      "name": ""
    }
   ]
 * }
 * 
 * API payload:-
 * 
 * {
 *  "wfCampaignSetupId": 0,
 *  "trainingName": "",
 *  "siteRoleIds": [
    {
      "siteRoleId": 0
    }
   ]
 * }
 */

export const createBlockAddTraining = (data) => {
  const { url } = config.createBlockAddTraining
  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    trainingName: data.trainingName,
    siteRoleIds: data.responsibleRole?.map(x => ({ siteRoleId: x.siteRoleId }))
  }
  return postDataApi(url, payload, null, { isApiNeedsToHandleErrors: false })
}

export default createBlockAddTraining
