import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useState, useMemo } from "react"
import { useDispatch } from "react-redux"

import { COLUMN_LIST_TYPE, DEFAULT_CHILD_TABLE_PAGE_SIZE } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { LocaleMessage } from '@views/components'
import { getInitialListState } from '@views/helpers'
import CheckpointTable from "@views/projects/templates/add-new-template/checkpoints/checkpoints-table"
import { GridContext } from "./ag-data-grid/GridContext"

const { LOADER } = LANGUAGE_CONSTANTS

const ExpandableTemplateActivityList = ({
  data
}) => {
  const dispatch = useDispatch()

  const initialListState = useMemo(() => {
    return getInitialListState({ pageSize: DEFAULT_CHILD_TABLE_PAGE_SIZE, dataKey: 'items' })
  }, [])

  const context = useContext(GridContext)

  const [isLoadingChildGrid, setLoadingChildGrid] = useState(false)
  const [isGridFetched, setGridFetched] = useState(false)
  const [tableData, setTableData] = useState(Object.assign({}, initialListState))
  const [sortOrderColumn, setSortOrderColumn] = useState('')
  const [sortOrderDirection, setSortOrderDirection] = useState('')

  const handleFetchTableData = useCallback(({
    orderColumn,
    orderDirections,
    page = 1,
    isResetSortOrder = false
  }) => {
    const handleSetTableData = (res) => {
      setGridFetched(true)
      if (res) {
        setTableData({ ...res, pageNumber: res.pageIndex })
      }
      setLoadingChildGrid(false)
    }

    setLoadingChildGrid(true)
    dispatch(
      actions.getTemplateCheckpointListRequest(
        {
          templateId: data.templateId,
          templateVersionId: data.templateVersionId || 1,
          pageSize: DEFAULT_CHILD_TABLE_PAGE_SIZE,
          pageNumber: page,
          isSearchOnly: true,
          orderColumn: isResetSortOrder ? orderColumn : sortOrderColumn,
          orderDirections: isResetSortOrder ? orderDirections : sortOrderDirection
        },
        handleSetTableData
      )
    )
  }, [sortOrderColumn, sortOrderDirection])

  useEffect(() => {
    if (!isGridFetched && !isLoadingChildGrid) {
      handleFetchTableData({ page: 1 })
    }
  }, [
    isGridFetched,
    isLoadingChildGrid
  ])

  const handleOnPageChange = useCallback(({ selected }) => {
    handleFetchTableData({ page: selected + 1 })
  }, [handleFetchTableData])

  const handleSortList = useCallback((column, sortDirection) => {
    if (column.sortKey) {
      const updatedSortKey = sortDirection ? column.sortKey : ""
      setSortOrderColumn(updatedSortKey)
      setSortOrderDirection(sortDirection)

      /**To reset current state before sort */
      setTableData(prevState => (
        Object.assign({}, {
          ...initialListState,
          pageNumber: prevState.pageNumber,
          pageSize: prevState.pageSize
        }
        )))

      handleFetchTableData({
        orderColumn: updatedSortKey,
        orderDirections: sortDirection,
        isResetSortOrder: true
      })
    }
  }, [handleFetchTableData])

  if (!isGridFetched && isLoadingChildGrid) {
    return <p className="no-data-txt template-grid-height"><LocaleMessage id={LOADER.LOADING} /></p>
  }

  if (!isLoadingChildGrid && !tableData?.items.length) {
    return <p className="no-data-txt">{context.noDataMessageForSubTable}</p>
  }

  return (<div className="expandable-right-content template-grid-height" >
    <CheckpointTable
      clearSelectedRows={context.isClearSelectedRows}
      columnConfigType={COLUMN_LIST_TYPE.SEARCH_FOR_CAMPAIGN}
      isChildGrid
      showExpandedPagination
      isModal
      isSearchMode
      isTemplateOverview
      className='template-grid-height-activity'
      tableViewHeaderRequired={false}
      templateDetail={data}
      tableData={tableData}
      isCheckBoxSelectionNotRequired={context.isCheckBoxSelectionNotRequired}
      onSelectedRowsChange={(selectedRowState) => context.onSelectedRowsChange(selectedRowState, { isCheckpointSelection: true })}
      childGridTableComponentProps={{
        sortOrderColumn,
        sortOrderDirection
      }}
      childGridProps={{
        isLoading: isLoadingChildGrid,
        noDataMessage: isLoadingChildGrid ? <LocaleMessage id={LOADER.LOADING} /> : context.noDataMessageForSubTable,
        onPagination: handleOnPageChange,
        onSort: handleSortList
      }}
    />
  </div>
  )
}

ExpandableTemplateActivityList.propTypes = {
  data: PropTypes.object
}

export default ExpandableTemplateActivityList
