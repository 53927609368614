import { put, takeLatest, call } from 'redux-saga/effects'

import {
  deleteResourceCenter,
  downloadResourceDocument,
  getResourceList,
  getResourceListVM,
  renameRCDocument,
  replaceRCDocument,
  uploadRCDocument,
  getUploadedByDDL,
  getFileTypeDDL,
  getResourceCenterFileCategoryDDL
} from '@services/api'
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from '@store/actions'
import { Notification } from "@views/components"

const { RESOURCE_SAGA } = LANGUAGE_CONSTANTS

/** getResourceList API **/
function* getResourceListRequest({ payload, callback = () => { } }) {
  try {
    const response = yield call(getResourceList, payload)
    if (response?.data) {
      callback({ ...response.data, pageSize: payload.pageSize })
    } else {
      callback(false)
    }
  } catch (error) {
    callback(false)
  }
}

/** getResourceListVM API **/
function* getResourceListVMRequest() {
  try {
    const response = yield call(getResourceListVM)
    if (response?.data) {
      yield put(actions.getResourceListVMSuccess(response.data))
    } else {
      yield put(actions.getResourceListVMFailure('Unable to load resource list vm'))
    }
  } catch (error) {
    yield put(actions.getResourceListVMFailure(error.message))
  }
}

/** deleteResourceCenter API */
function* deleteResourceCenterRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteResourceCenter, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: RESOURCE_SAGA.DELETED_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: RESOURCE_SAGA.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    Notification({ status: 400, message: RESOURCE_SAGA.ERROR_IN_DELETING })
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** downloadResourceDocument API */
function* downloadResourceDocumentRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(downloadResourceDocument, action.payload)
    if (response) {
      action.callback(response)
    } else {
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** uploadRCDocument API **/
function* uploadRCDocumentRequest({ payload, callback }) {
  try {
    if (!payload.isLoadingNotRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(uploadRCDocument, payload)
    if (response?.data) {
      Notification({ status: 200, message: RESOURCE_SAGA.UPLOAD_SUCCESS })
      callback(response.data)
    } else {
      Notification({ status: 400, message: RESOURCE_SAGA.ERROR_IN_UPLOAD })
      callback()
    }
    if (!payload.isLoadingNotRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    if (!payload.isLoadingNotRequired) {
      yield put(actions.setIsLoading(false))
    }
    Notification({ status: 400, isLocalizedMessage: false, message: error.message })
    callback()
  }
}

/** replaceRCDocument API **/
function* replaceRCDocumentRequest({ payload, callback }) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(replaceRCDocument, payload)
    if (response?.data) {
      Notification({ status: 200, message: RESOURCE_SAGA.REPLACE_SUCCESS })
      callback(response.data)
    } else {
      Notification({ status: 400, message: RESOURCE_SAGA.ERROR_IN_REPLACE })
      callback()
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, isLocalizedMessage: false, message: error.message })
    callback()
  }
}

/** renameRCDocument API **/
function* renameRCDocumentRequest({ payload, callback }) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(renameRCDocument, payload)
    if (response?.data) {
      Notification({ status: 200, message: RESOURCE_SAGA.RENAME_SUCCESS })
      callback(response.data)
    } else {
      Notification({ status: 400, message: RESOURCE_SAGA.ERROR_IN_RENAME })
      callback()
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, isLocalizedMessage: false, message: error.message })
    callback()
  }
}

/** getFileTypeDDL API **/
function* getFileTypeDDLRequest(action) {
  try {
    const response = yield call(getFileTypeDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getUploadedByDDL API **/
function* getUploadedByDDLRequest(action) {
  try {
    const response = yield call(getUploadedByDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getResourceCenterFileCategoryDDL API **/
function* getResourceCenterFileCategoryDDLRequest(action) {
  try {
    const response = yield call(getResourceCenterFileCategoryDDL)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

export function* resourcesSagas() {
  yield takeLatest(actions.GET_RESOURCE_LIST_REQUEST, getResourceListRequest)
  yield takeLatest(actions.GET_RESOURCE_LIST_VM.REQUEST, getResourceListVMRequest)
  yield takeLatest(actions.DELETE_RESOURCE_CENTER.REQUEST, deleteResourceCenterRequest)
  yield takeLatest(actions.DOWNLOAD_RESOURCE_DOCUMENT_REQUEST, downloadResourceDocumentRequest)
  yield takeLatest(actions.UPLOAD_RC_DOCUMENT_REQUEST, uploadRCDocumentRequest)
  yield takeLatest(actions.REPLACE_RC_DOCUMENT_REQUEST, replaceRCDocumentRequest)
  yield takeLatest(actions.RENAME_RC_DOCUMENT_REQUEST, renameRCDocumentRequest)
  yield takeLatest(actions.GET_FILE_TYPE_DDL_REQUEST, getFileTypeDDLRequest)
  yield takeLatest(actions.GET_UPLOADED_BY_DDL_REQUEST, getUploadedByDDLRequest)
  yield takeLatest(actions.GET_RESOURCE_CENTER_FILE_CATEGORY_DDL_REQUEST, getResourceCenterFileCategoryDDLRequest)
}

export default resourcesSagas
