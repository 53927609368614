import config from "./APIConfig"
import { getDataApi } from "../index"

export const getSamplingOverviewDetails = async (payload) => {
  const { url } = config.getSamplingOverviewDetails
  return await getDataApi(url(payload))
}

export const getSamplingWorkflowGroupDetails = async (payload) => {
  const { url } = config.getSamplingWorkflowGroupDetails
  return await getDataApi(url(payload))
}

export const getSamplingWorkflowGroupUnits = async (payload) => {
  const { url } = config.getSamplingWorkflowGroupUnits
  return await getDataApi(url(payload))
}

export const getSamplingWorkflowGroups = async (payload) => {
  const { url } = config.getSamplingWorkflowGroups
  return await getDataApi(url(payload))
}

export const getSamplingWorkflowList = async (payload) => {
  const { url } = config.getSamplingWorkflowList
  return await getDataApi(url(payload))
}

export const getSamplingWorkflowListVM = async () => {
  const { url } = config.getSamplingWorkflowListVM
  return await getDataApi(url())
}

export const getSamplingWorkflowUnits = async (payload) => {
  const { url } = config.getSamplingWorkflowUnits
  return await getDataApi(url(payload))
}

export const getSamplingWorkflowUnitsVM = async (payload) => {
  const { url } = config.getSamplingWorkflowUnitsVM
  return await getDataApi(url(payload))
}
