import cx from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Search } from 'react-feather'
import { Col } from "reactstrap"
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { DEFAULT_VM_PAGE_SIZE } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from '@store/actions'
import { getLocaleMessage } from '@utils'
import { ErrorMessage, CustomReactSelect, CustomLabel } from '@views/components'
import { getReactSelectPositionProps } from "@views/helpers"
import ContactModal from './ContactModal'
import localeMessageWrapper from '../locale-message'

const { POINT_OF_CONTACTS_SELECT } = LANGUAGE_CONSTANTS

const PointOfContactSelect = ({
  asyncInitialValue,
  intl,
  isColWrapper,
  customActionLabel,
  defaultOptions,
  errors,
  id,
  initialValue,
  initialLabel,
  isDisabled,
  isEditMode,
  isMulti,
  isOrgBasedReset,
  isFocusedStyleRequired,
  isPartialMultiSelect,
  isShowCustomAction,
  isRequired,
  isAllContacts,
  isContactsBasedOnSelectedGroup,
  isRequiresUpdateForInitialLabelState,
  label,
  className,
  labelRequired,
  customSelectedLable,
  name,
  isAllOption,
  orgDetail,
  siteId,
  contactGroupId,
  isSiteAndOrgContacts,
  placeholder,
  value = [],
  isExternalOrganizationContacts,
  isInternalOrganizationContacts,
  wrapperClassName,
  customComponents,
  wrapperStyle,
  reportParams,
  isCalendarPageContacts,
  onSelect,
  lableType,
  valueType,
  isShowSelectedAtTop,
  getOptionLabel,
  getOptionValue
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState(defaultOptions)
  const [contactName, setContactName] = useState('')
  const [isOpenModal, setOpenModal] = useState(false)
  const [isFocused, setFocused] = useState(false)
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState('')
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)

  const handleSetDefaultOptions = useCallback((selectedItem, selectedContactId) => {
    const checkIfAlreadyPresent = defaultOptions.find(option => option[valueType] === selectedContactId)
    if (!!checkIfAlreadyPresent) {
      setOptions(defaultOptions)
    } else {
      setOptions([selectedItem, ...defaultOptions])
    }
  }, [defaultOptions, valueType])

  useEffect(() => {
    //Note: when selected org is empty in roles, reset options to []
    if (!isEditMode && isOrgBasedReset) {
      if (!orgDetail) {
        setResetCache(prevState => !prevState)
      } else {
        setResetCache(prevState => !prevState)
        setOptions(defaultOptions)
      }
    }
  }, [defaultOptions, orgDetail])

  useEffect(() => {
    if (!isMulti && !!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])

  useEffect(() => {
    //Note: When contact detail is fetched in parent component
    if (
      (!isEditModeItemLoaded || (!!initialLabel?.length && !selectedOptionLabel?.length))
      && isEditMode && asyncInitialValue && !!value && !!initialLabel && (isRequiresUpdateForInitialLabelState || isAllContacts || !!orgDetail?.id)) {
      const selectedItem = {
        [valueType]: value,
        [lableType]: (!isExternalOrganizationContacts || !isInternalOrganizationContacts) && !!orgDetail?.id ? `${initialLabel} - ${orgDetail.name || ''}` : `${initialLabel}`
      }

      if (!!orgDetail?.id) {
        selectedItem.organizationId = orgDetail?.id
      }
      setEditModeItemLoaded(true)
      setSelectedOptionLabel(initialLabel)

      if (!isRequiresUpdateForInitialLabelState) {
        setResetCache(prevState => !prevState)
      }
      handleSetDefaultOptions(selectedItem, value)
    }
  }, [defaultOptions, initialLabel, isEditMode, isEditModeItemLoaded, orgDetail, selectedOptionLabel, value])

  useEffect(() => {
    if (isEditMode && !value.length && !!orgDetail?.id) {
      if (!!initialValue) {
        dispatch(actions.getContactRequest(
          {
            contactId: initialValue,
            organizationId: orgDetail.id
          }, (result) => {
            if (result) {
              const selectedItem = {
                [valueType]: initialValue,
                [lableType]: (isExternalOrganizationContacts || isInternalOrganizationContacts) ? `${result.firstName} ${result.lastName}` : `${result.firstName} ${result.lastName} - ${orgDetail.name || ''}`,
                organizationId: orgDetail.id
              }

              if (isMulti) {
                onSelect(id, [selectedItem])
                setResetCache(prevState => !prevState)
                handleSetDefaultOptions(selectedItem, initialValue)
              } else {
                onSelect(id, selectedItem[valueType] || '', selectedItem.organizationId || '')
                setSelectedOptionLabel(selectedItem[lableType] || '')
                setResetCache(prevState => !prevState)
                handleSetDefaultOptions(selectedItem, initialValue)
              }
            }
          }))
      } else {
        setResetCache(prevState => !prevState)
        setOptions(defaultOptions)
      }
    } else {
      if (!isOrgBasedReset) {
        setResetCache(prevState => !prevState)
      }
      setOptions(defaultOptions)
    }
  }, [siteId, orgDetail, defaultOptions])

  const handleFocusSearch = useCallback(() => {
    setFocused(true)
  }, [])

  const handleBlurSearch = useCallback(() => {
    setFocused(false)
  }, [])

  const handleToggleModal = useCallback(() => {
    setOpenModal(!isOpenModal)
    setFocused(false)
  }, [isOpenModal])

  const handleSelectContact = useCallback((selected, { isAutoFill }) => {
    setFocused(false)

    //Note: For reseting options in search mode
    if (!!contactName.length) {
      const selectedItem = {
        [lableType]: selected[lableType],
        organizationId: selected.organizationId,
        [valueType]: selected[valueType]
      }
      handleSetDefaultOptions(selectedItem, selected[valueType])
      setContactName("")
    }

    if (isMulti) {
      if (isPartialMultiSelect) {
        if (isAutoFill) {
          onSelect(id, selected)
        } else {
          onSelect(id, selected ? [selected] : [])
        }
      } else {
        if (isAutoFill) {
          onSelect(id, [...value, ...selected])
        } else {
          const lastElement = selected.length ? selected[selected.length - 1] : null
          if (!!lastElement && !lastElement[valueType]) {
            onSelect(id, [])
          } else {
            onSelect(id, selected || [])
          }
        }

      }
    } else {
      onSelect(id, (selected && selected[valueType]) || '', (!isAllContacts && selected?.organizationId) || '', selected ? selected[lableType] : '')
      setSelectedOptionLabel((selected && selected[lableType]) || '')
    }
  }, [contactName, defaultOptions, id, isMulti, isAllContacts, isPartialMultiSelect, lableType, value, valueType, handleSetDefaultOptions, onSelect])

  const handleAutoSelectFromModal = useCallback((selected, callback) => {
    handleSelectContact(selected, { isAutoFill: true })
    setSelectedOptionLabel(selected[lableType] || '')
    callback()
  }, [lableType, handleSelectContact])

  const handleGetOptionsForAutoSelectedValue = useCallback((data) => {
    setResetCache(prevState => !prevState)
    setTimeout(() => {
      const selectedItem = {
        contactOrganizationName: data.contactOrganizationName,
        organizationId: data.organizationId,
        contactId: data.contactId
      }
      handleSetDefaultOptions(selectedItem, data.contactId)
    }, 100)

  }, [isMulti, isPartialMultiSelect, defaultOptions, handleSetDefaultOptions])

  const handleInputChange = useCallback((search) => {
    if (!!contactName.length && !search.length) {
      //Todo: reset for search
      // setOptions(defaultOptions)
    }
    setContactName(search)
  }, [contactName])

  const handleLoadMore = useCallback(
    (search, page, prevOptions) => {
      return new Promise((resolve) => {
        const handleSearchResultCallback = (response) => {
          if (response) {
            const { items, hasNextPage } = response
            setOptions(() => (!!prevOptions.length ? [...prevOptions, ...items] : items))
            const formattedItems = items.map(({ contactId, name }) => { return { contactId, contactOrganizationName: name } })
            let updatedItems = isContactsBasedOnSelectedGroup ? [...formattedItems] : [...items]
            if (isShowSelectedAtTop && !search.trim()) {
              if (page === 1) {
                updatedItems = [...options, ...items.filter(item => options.every(option => item[valueType] !== option[valueType]))]
              } else {
                updatedItems = [...items.filter(item => value.every(option => item[valueType] !== option[valueType]))]
              }
            }

            resolve({
              optionList: updatedItems,
              hasMore: hasNextPage
            })
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }
        if (isSiteAndOrgContacts) {
          const params = {
            siteId,
            searchName: search,
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            allOption: !!isAllOption
          }
          dispatch(
            actions.getSiteOrganizationContactListRequest(params, handleSearchResultCallback)
          )
        } else if (!orgDetail && isAllContacts && !reportParams) {
          const params = {
            searchName: search,
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            allOption: !!isAllOption
          }
          dispatch(
            actions.getAssignedContactsDDLRequest(params, handleSearchResultCallback)
          )
        } else if (reportParams && !isCalendarPageContacts) {
          const params = {
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            contactName: search,
            ...reportParams
          }
          dispatch(
            actions.getReportAssignedContactsDDLRequest(params, handleSearchResultCallback))
        } else if (isCalendarPageContacts && !reportParams) {
          const params = {
            sites: [Number(siteId)],
            contactName: search,
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE
          }
          dispatch(
            actions.getCalendarContactsDDLRequest(
              params,
              handleSearchResultCallback
            )
          )
        } else if (isExternalOrganizationContacts) {
          const params = {
            organizationId: orgDetail.id,
            contactName: search,
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE
          }
          dispatch(
            actions.getAssignedTaskContactListDDLRequest(
              params,
              handleSearchResultCallback
            )
          )
        } else if (isInternalOrganizationContacts) {
          const params = {
            organizationId: orgDetail.id,
            contactName: search,
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE
          }
          dispatch(
            actions.getContactListRequest(
              params,
              handleSearchResultCallback
            )
          )
        } else if (isContactsBasedOnSelectedGroup) {
          const params = {
            contactGroupId,
            contactName: search,
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE
          }
          dispatch(
            actions.getContactListByGroupRequest(
              params,
              handleSearchResultCallback
            )
          )
        } else {
          if (isOrgBasedReset && !orgDetail) {
            resolve({
              optionList: [],
              hasMore: false
            })
            return
          }

          const serachParams = {
            contactName: search,
            organizationId: orgDetail.id,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            pageNumber: page,
            isDropdownLoadMore: true
          }

          dispatch(actions.getContactByOrganizationRequest(serachParams, (response) => {
            if (response) {
              const { items, ...paginationData } = response
              resolve({
                optionList: items,
                hasMore: paginationData.hasNextPage
              })
              setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
            } else {
              resolve({
                optionList: [],
                hasMore: false
              })
            }
          }))
        }
      })
    },
    [isInternalOrganizationContacts, isShowSelectedAtTop, value, isOrgBasedReset, options, orgDetail, siteId, contactGroupId, isSiteAndOrgContacts, isAllContacts, isCalendarPageContacts]
  )

  const handleRefreshOptions = useCallback(() => {
    setOptions([...value])
    setResetCache(prevState => !prevState)
  }, [value])

  const reactSelectPostionProps = useMemo(() => {
    return getReactSelectPositionProps()
  }, [])

  const WrapperComponent = useMemo(() => (isColWrapper ? Col : 'div'), [isColWrapper])

  const colWrapperProps = useMemo(() => {
    if (!isColWrapper) {
      return {}
    }

    return {
      className: cx("mb-1", wrapperClassName),
      sm: wrapperStyle.sm,
      md: wrapperStyle.md,
      xl: wrapperStyle.xl
    }
  }, [isColWrapper, wrapperClassName, wrapperStyle])

  const focusInputProps = useMemo(() => {
    if (!isFocusedStyleRequired) {
      return {}
    }

    return {
      onBlur: handleBlurSearch,
      onFocus: handleFocusSearch
    }
  }, [isFocusedStyleRequired, handleBlurSearch, handleFocusSearch])

  return (
    <>
      <WrapperComponent {...colWrapperProps}>
        <div className="form-group">
          {labelRequired && <CustomLabel title={label || POINT_OF_CONTACTS_SELECT.LABEL} requiredIconClassName="required-field" required={isRequired} isLocalizedTitle={!label} />}
          <div className={isFocused ? "organization-searchbar isFocused organization-select" : 'organization-searchbar'}>
            <CustomReactSelect
              additional={{
                page: 1,
                isReset: isResetCache
              }}
              classNamePrefix={className || 'select'}
              cacheUniqs={[isResetCache]}
              customActionLabel={customActionLabel || getLocaleMessage(intl, POINT_OF_CONTACTS_SELECT.ADD_CONTACT)}
              hideSelectedOptions={false}
              id={id}
              isDisabled={isDisabled}
              isMulti={isMulti && !isPartialMultiSelect}
              isShowCustomAction={isShowCustomAction && !!orgDetail}
              name={name}
              placeholder={placeholder || getLocaleMessage(intl, POINT_OF_CONTACTS_SELECT.PLACEHOLDER)}
              options={options}
              defaultOptions={options}
              customSelectedLable={customSelectedLable}
              isFormattedValue={!isMulti}
              value={isMulti ? value : !!value ? {
                [lableType]: selectedOptionLabel,
                [valueType]: value
              } : null}
              lableType={lableType}
              valueType={valueType}
              isShowSelectedAtTop={isShowSelectedAtTop}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              onInputChange={handleInputChange}
              onClickCustomAction={(e) => {
                e.preventDefault()
                handleToggleModal()
              }}
              onLoadOptions={handleLoadMore}
              customComponents={customComponents}
              onSelect={handleSelectContact}
              onRefreshOptions={handleRefreshOptions}
              {...focusInputProps}
              {...reactSelectPostionProps}
            />
            {isFocused && <Search />}
          </div>
        </div>
        {errors && <ErrorMessage
          isShow={!!errors[id]}
          message={errors[id]}
        />}

      </WrapperComponent>
      {isOpenModal && <ContactModal
        isMulti={isMulti}
        isOpen={isOpenModal}
        orgDetail={orgDetail}
        onToggleModal={handleToggleModal}
        onSelect={handleAutoSelectFromModal}
        onGetSearchBasedContactList={handleGetOptionsForAutoSelectedValue}
      />
      }
    </>
  )
}

PointOfContactSelect.propTypes = {
  intl: PropTypes.object,
  customSelectedLable: PropTypes.string,
  customComponents: PropTypes.object,
  asyncInitialValue: PropTypes.bool,
  isFocusedStyleRequired: PropTypes.bool,
  isColWrapper: PropTypes.bool,
  isCalendarPageContacts: PropTypes.bool,
  isExternalOrganizationContacts: PropTypes.bool,
  isInternalOrganizationContacts: PropTypes.bool,
  customActionLabel: PropTypes.string,
  defaultOptions: PropTypes.array,
  errors: PropTypes.object,
  id: PropTypes.string,
  initialLabel: PropTypes.string,
  initialValue: PropTypes.string,
  isAllContacts: PropTypes.bool,
  isRequiresUpdateForInitialLabelState: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOrgBasedReset: PropTypes.bool,
  isPartialMultiSelect: PropTypes.bool,
  isShowCustomAction: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  lableType: PropTypes.string,
  name: PropTypes.string,
  orgDetail: PropTypes.object,
  placeholder: PropTypes.string,
  valueType: PropTypes.string,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  siteId: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number
  ]),
  contactGroupId: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number
  ]),
  isSiteAndOrgContacts: PropTypes.bool,
  wrapperStyle: PropTypes.object,
  isShowSelectedAtTop: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  onSelect: PropTypes.func
}

PointOfContactSelect.defaultProps = {
  defaultOptions: [],
  asyncInitialValue: false,
  isFocusedStyleRequired: true,
  isColWrapper: true,
  id: 'contacts',
  initialLabel: '',
  initialValue: '',
  isAllContacts: false,
  isRequiresUpdateForInitialLabelState: false,
  isCalendarPageContacts: false,
  isExternalOrganizationContacts: false,
  isInternalOrganizationContacts: false,
  isDisabled: false,
  isEditMode: false,
  customSelectedLable: "",
  isMulti: true,
  labelRequired: true,
  isOrgBasedReset: false,
  isPartialMultiSelect: false,
  isShowCustomAction: true,
  isRequired: true,
  customComponents: {},
  name: 'contacts',
  className: "",
  wrapperClassName: "",
  wrapperStyle: {
    md: 6,
    sm: 12,
    xl: 4
  },
  lableType: 'contactOrganizationName',
  valueType: 'contactId',
  siteId: 0,
  contactGroupId: 0,
  isSiteAndOrgContacts: false,
  isShowSelectedAtTop: false,
  getOptionLabel: (option) => option.contactOrganizationName,
  getOptionValue: (option) => option.contactId
}

export default localeMessageWrapper(PointOfContactSelect)
