export const GET_COMMUNICATION_LOG_LIST_REQUEST = "GET_COMMUNICATION_LOG_LIST_REQUEST"
export const GET_COMMUNICATION_LOG_NOTIFICATION_DETAILS_REQUEST = 'GET_COMMUNICATION_LOG_NOTIFICATION_DETAILS_REQUEST'
export const RESEND_MESSAGE_NOTIFICATION_REQUEST = 'RESEND_MESSAGE_NOTIFICATION_REQUEST'
export const GET_COMMUNICATION_LOG_VM_REQUEST = 'GET_COMMUNICATION_LOG_VM_REQUEST'

/****************************************************/
/** GET_COMMUNICATION_LOG_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCommunicationLogListRequest : to get communication log list
 */

export const getCommunicationLogListRequest = (payload, callback = () => { }) => {
    return {
      type: GET_COMMUNICATION_LOG_LIST_REQUEST,
      payload,
      callback
    }
}

/****************************************************/
/** GET_COMMUNICATION_LOG_NOTIFICATION_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method getCommunicationLogNotificationDetailRequest : to get communication log notification details
 */

export const getCommunicationLogNotificationDetailRequest = (payload, callback = () => { }) => {
    return {
      type: GET_COMMUNICATION_LOG_NOTIFICATION_DETAILS_REQUEST,
      payload,
      callback
    }
}

/****************************************************/
/** RESEND_MESSAGE_NOTIFICATION_REQUEST **/
/****************************************************/

/**
 * @method resendMessageNotificationRequest : to resend the notification 
 */

export const resendMessageNotificationRequest = (payload, callback = () => { }) => {
  return {
    type: RESEND_MESSAGE_NOTIFICATION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_COMMUNICATION_LOG_VM_REQUEST **/
/****************************************************/

/**
 * @method getCommunicationLogVMRequest : to get communication log VM
 */

export const getCommunicationLogVMRequest = (callback = () => { }) => {
  return {
    type: GET_COMMUNICATION_LOG_VM_REQUEST,
    callback
  }
}
