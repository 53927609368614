import { put, takeLatest, call, takeEvery } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

const { CONTACT_GROUP_SAGA } = LANGUAGE_CONSTANTS

/** getContactGroupList API **/
function* getContactGroupListRequest(action) {
  try {
    const response = yield call(services.getContactGroupList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback(null)
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(null)
  }
}

/** createContactGroupRequest API  **/
function* createContactGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.createContactGroup, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      Notification({ status: 200, message: CONTACT_GROUP_SAGA.ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: CONTACT_GROUP_SAGA.ERROR_IN_ADDING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** getGroupContactList API **/
function* getGroupContactListRequest(action) {
  try {
    const response = yield call(services.getGroupContactList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateContactGroupRequest API  **/
function* updateContactGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateContactGroup, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CONTACT_GROUP_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: CONTACT_GROUP_SAGA.ERROR_IN_UPDATING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** getContactGroupDDL API **/
function* getContactGroupDDLRequest(action) {
  try {
    const response = yield call(services.getContactGroupDDL, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** addContactsToContactGroups API **/
function* addContactsToContactGroupsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addContactsToContactGroups, action.payload)
    if (response?.status === 200) {
      action.callback(true)
      Notification({ status: 200, message: CONTACT_GROUP_SAGA.ADDED_CONTACT_TO_CONTACT_GROUP_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: CONTACT_GROUP_SAGA.ERROR_IN_ADDING_CONTACT_TO_CONTACT_GROUP })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** deleteContactGroupRequest API  **/
function* deleteContactGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteContactGroup, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CONTACT_GROUP_SAGA.DELETED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: CONTACT_GROUP_SAGA.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** deleteContactsByContactGroupRequest API  **/
function* deleteContactsByContactGroupRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteContactsByContactGroup, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CONTACT_GROUP_SAGA.DELETE_CONTACTS_BY_CONTACT_GROUP_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: CONTACT_GROUP_SAGA.ERROR_IN_DELETING_CONTACTS_BY_CONTACT_GROUP })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** updateContactGroupOrderIndexRequest API  **/
function* updateContactGroupOrderIndexRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateContactGroupOrderIndex, action.payload)
    if (response?.status === 204) {
      Notification({ status: 200, message: CONTACT_GROUP_SAGA.UPDATED_ORDER_INDEX_SUCCESS })
    } else {
      Notification({ status: 400, message: CONTACT_GROUP_SAGA.ERROR_IN_UPDATING_ORDER_INDEX })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** getAllContactList API **/
function* getAllContactListRequest(action) {
  try {
    const response = yield call(services.getAllContactList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getContactListByGroup API **/
function* getContactListByGroupRequest(action) {
  try {
    const response = yield call(services.getContactListByGroup, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

export function* contactGroupSagas() {
  yield takeLatest(actions.GET_CONTACT_GROUP_LIST_REQUEST, getContactGroupListRequest)
  yield takeLatest(actions.CREATE_CONTACT_GROUP_REQUEST, createContactGroupRequest)
  yield takeEvery(actions.GET_GROUP_CONTACT_LIST_REQUEST, getGroupContactListRequest)
  yield takeLatest(actions.UPDATE_CONTACT_GROUP_REQUEST, updateContactGroupRequest)
  yield takeLatest(actions.GET_CONTACT_GROUP_DDL_REQUEST, getContactGroupDDLRequest)
  yield takeLatest(actions.ADD_CONTACT_TO_CONTACT_GROUP_REQUEST, addContactsToContactGroupsRequest)
  yield takeLatest(actions.DELETE_CONTACT_GROUP_REQUEST, deleteContactGroupRequest)
  yield takeLatest(actions.DELETE_CONTACTS_BY_CONTACT_GROUP_REQUEST, deleteContactsByContactGroupRequest)
  yield takeLatest(actions.UPDATE_CONTACT_GROUP_ORDER_INDEX_REQUEST, updateContactGroupOrderIndexRequest)
  yield takeLatest(actions.GET_ALL_CONTACT_LIST_REQUEST, getAllContactListRequest)
  yield takeLatest(actions.GET_CONTACT_LIST_BY_GROUP_REQUEST, getContactListByGroupRequest)
}

export default contactGroupSagas
