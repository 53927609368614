import { useCallback, useContext, useMemo, useState } from 'react'

import RowActionsComponent from "@views/components/table-view/row-actions"
import { BlueprintContext } from "./BlueprintContext"
import { ROW_ACTION_TYPES, rowActionItems } from './config'
import BluePrintModal from "./Modal"
import AddEnergyModel from "../energy-model-list/Modal"

const RowActions = ({
  data
}) => {
  const bpContext = useContext(BlueprintContext)

  const [isOpenModel, setOpenModel] = useState(false)
  const [modelConfig, setModelConfig] = useState(null)

  const handleViewFiles = useCallback(() => {
    setOpenModel(true)
    setModelConfig({
      type: ROW_ACTION_TYPES.VIEW_FILES,
      data: {
        blueprintName: data.bluePrintName,
        organizationBluePrintId: data.organizationBluePrintId,
        organizationId: bpContext.organizationId,
        organizationPlanId: bpContext.organizationPlanId
      }
    })
  }, [bpContext, data])

  const handleAddEnergyModel = useCallback(() => {
    setOpenModel(true)
    setModelConfig({
      type: ROW_ACTION_TYPES.ADD_MODEL,
      data: {
        organizationId: bpContext.organizationId,
        organizationPlanId: bpContext.organizationPlanId,
        organizationBluePrintId: data.organizationBluePrintId
      }
    })
  }, [bpContext, data])

  const handleRefreshBlueprintDetails = useCallback(() => {
    //To refresh energy model count in effect row
    //and to refresh data in expandable view of this row
    bpContext.onUpdateRowData({
      update: [{ ...data, energyModelCount: data.energyModelCount + 1 }]
    })
  }, [data, bpContext.onUpdateRowData])

  const handleCloseModel = useCallback(() => {
    setOpenModel(false)
    setModelConfig(null)
  }, [])

  const handleClickActionItem = useCallback(
    (id) => {
      if (id === ROW_ACTION_TYPES.VIEW_FILES) {
        handleViewFiles()
      }
      if (id === ROW_ACTION_TYPES.ADD_MODEL) {
        handleAddEnergyModel()
      }
    },
    [handleViewFiles, handleAddEnergyModel]
  )

  const config = useMemo(() => {
    const actionTypes = [ROW_ACTION_TYPES.VIEW_FILES, ROW_ACTION_TYPES.ADD_MODEL]
    return {
      actionTypes,
      rowActionItems
    }
  }, [])

  return (
    <>
      <RowActionsComponent
        config={config}
        rowId={data.organizationBluePrintId}
        onClickActionItem={handleClickActionItem}
      />
      {isOpenModel && ((modelConfig?.type === ROW_ACTION_TYPES.VIEW_FILES && <BluePrintModal config={modelConfig} onClose={handleCloseModel} />) ||
        (modelConfig?.type === ROW_ACTION_TYPES.ADD_MODEL && <AddEnergyModel config={modelConfig} onClose={handleCloseModel} onRefreshDetails={handleRefreshBlueprintDetails} />))}
    </>
  )
}


export default RowActions
