import { getActionType } from '../action-type-generator'

export const GET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST = getActionType('GET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST')
export const RESET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST = 'RESET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST'
export const DELETE_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT = "DELETE_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT"

/****************************************************/
/** GET_CHECKLIST_CHECKPOINT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCampaignServiceChecklistCheckpointListRequest :  To get campaign service checklist checkpoints list
 *
 */
export const getCampaignServiceChecklistCheckpointListRequest = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST.REQUEST,
    payload,
    callback
  }
}

/**
 * @method getCampaignServiceChecklistCheckpointListSuccess : To set campaign service checklist checkpoints list on success
 *
 */
export const getCampaignServiceChecklistCheckpointListSuccess = data => {
  return {
    type: GET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST.SUCCESS,
    data
  }
}

/**
 * @method getCampaignServiceChecklistCheckpointListFailure : To set error on getting campaign service checklist checkpoints list on failure
 *
 */
export const getCampaignServiceChecklistCheckpointListFailure = error => {
  return {
    type: GET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST.FAILURE,
    error
  }
}

/****************************************************/
/** RESET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST **/
/****************************************************/

/**
 * @method resetCampaignServiceChecklistCheckpointList : To reset campaign service checklist checkpoint list
 *
 */
export const resetCampaignServiceChecklistCheckpointList = (payload) => {
  return {
    type: RESET_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT_LIST,
    payload
  }
}

/****************************************************/
/** DELETE_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT **/
/****************************************************/

/**
 * @method deleteCampaignServiceChecklistCheckpoint : To delete campaign service checklist checkpoint
 *
 */
export const deleteCampaignServiceChecklistCheckpoint = (payload, callback) => {
  return {
    type: DELETE_CAMPAIGN_SERVICE_CHECKLIST_CHECKPOINT,
    payload,
    callback
  }
}
