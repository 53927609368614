import { takeLatest, call, put } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from '@store/actions'
import { Notification } from '@views/components'

const { AUDIT_TRAIL_MODAL_SAGA } = LANGUAGE_CONSTANTS

/** createNewAuditTrail API **/
function* createNewAuditTrailRequest(action) {
  try {
    const response = yield call(services.createNewAuditTrail, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      Notification({ status: 200, message: AUDIT_TRAIL_MODAL_SAGA.AUDIT_TRIAL_SAVE_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: AUDIT_TRAIL_MODAL_SAGA.ERROR_IN_SAVING_AUDIT_TRIAL })
    }
  } catch (error) {
    action.callback()
  }
}

/** getAuditTrailModal API **/
function* getAuditTrailModalData(action) {
  try {
    const response = yield call(services.getAuditTrailModal, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** deleteAuditTrail API **/
function* deleteAuditTrailRequest(action) {
  try {
    const response = yield call(services.deleteAuditTrail, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: AUDIT_TRAIL_MODAL_SAGA.DELETE_SUCCESS })
    } else {
      Notification({ status: 400, message: AUDIT_TRAIL_MODAL_SAGA.DELETE_FAIL })
    }
  } catch (error) {
    action.callback()
  }
}

/** updateAuditTrail API **/
function* updateAuditTrailRequest(action) {
  try {
    const response = yield call(services.updateAuditTrail, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: AUDIT_TRAIL_MODAL_SAGA.AUDIT_TRIAL_UPDATE_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: AUDIT_TRAIL_MODAL_SAGA.ERROR_IN_UPDATING_AUDIT_TRIAL })
    }
  } catch (error) {
    action.callback()
  }
}

/** createAuditTrail API **/
function* createAuditTrailRequest(action) {
  try {
    const response = yield call(services.createAuditTrail, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      Notification({ status: 200, message: AUDIT_TRAIL_MODAL_SAGA.AUDIT_TRIAL_SAVE_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: AUDIT_TRAIL_MODAL_SAGA.ERROR_IN_SAVING_AUDIT_TRIAL })
    }
  } catch (error) {
    action.callback()
  }
}

/** createAssignmentNote API **/
function* createAssignmentNoteRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.createAssignmentNote, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: AUDIT_TRAIL_MODAL_SAGA.AUDIT_TRIAL_SAVE_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: AUDIT_TRAIL_MODAL_SAGA.ERROR_IN_SAVING_AUDIT_TRIAL })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

export function* auditTrailSagas() {
  yield takeLatest(actions.CREATE_NEW_AUDIT_TRAIL_REQUEST, createNewAuditTrailRequest)
  yield takeLatest(actions.GET_AUDIT_TRAIL_MODAL_DATA, getAuditTrailModalData)
  yield takeLatest(actions.DELETE_AUDIT_TRAIL_REQUEST, deleteAuditTrailRequest)
  yield takeLatest(actions.UPDATE_AUDIT_TRAIL_REQUEST, updateAuditTrailRequest)
  yield takeLatest(actions.CREATE_AUDIT_TRAIL_REQUEST, createAuditTrailRequest)
  yield takeLatest(actions.CREATE_ASSIGNMENT_NOTE_REQUEST, createAssignmentNoteRequest)
}

export default auditTrailSagas