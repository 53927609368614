import cx from "classnames"
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Button
} from 'reactstrap'
import '@styles/react/libs/charts/apex-charts.scss'
import '@styles/base/pages/dashboard-ecommerce.scss'
import { useSelector, useDispatch } from 'react-redux'
import 'react-datepicker/dist/react-datepicker.css'

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { ActivityTypeList } from '@shared/selectionListStore'
import { closeSVGIcon } from "@src/assets/images/new-theme"
import * as actions from '@store/actions'
import { getLocaleMessage } from "@utils"
import { ErrorMessage, ReactSelect, CustomLabel, ToggleButton } from '@views/components'
import { getReactSelectPositionProps } from "@views/helpers"
import localeMessageWrapper from '@views/components/locale-message'
import AssemblySearch from '@views/projects/templates/add-new-template/checkpoints/checkpoint-detail/AssemblySearch'
import AssemblyType from '@views/projects/templates/add-new-template/checkpoints/checkpoint-detail/AssemblyType'
import ComponentSearch from '@views/projects/templates/add-new-template/checkpoints/checkpoint-detail/ComponentSearch'
import ManufacturerSearch from '@views/projects/templates/add-new-template/checkpoints/checkpoint-detail/ManufacturerSearch'
import SubComponentSearch from '@views/projects/templates/add-new-template/checkpoints/checkpoint-detail/SubComponentSearch'

const { ACTIVITIES, CLEAR, SEARCH, CLOSE, ADVANCED_SEARCH } = LANGUAGE_CONSTANTS

const initialCampaignCheckListVMState = {
  assignmentType: [],
  stageOfConstruction: [],
  scopeOfWork: [],
  responsibleSiteRole: [],
  riskLevel: [],
  systemCategory: [],
  systemCategory: [],
  assembly: [],
  component: [],
  subComponent: [],
  manufacturer: [],
  brand: []
}

const AdvanceSearch = ({
  intl,
  hasCloseAction = false,
  isCampaignView = false,
  isEditMode = false,
  isModal = false,
  isShowHoldProduction = true,
  isShowOnlyActivityNameAndType = false,
  isShowSystemDefinition = true,
  isViewMode = false,
  searchViewTitle,
  control,
  errors = {},
  checkpointCollection,
  onChangeCheckpointCollection,
  isNewListTheme,
  onClose = () => { },
  onResetSearchDetail = () => { },
  onSearch = () => { },
  onToggle = () => { },
  onCustomAdvanceSearch = () => { }
}) => {
  const dispatch = useDispatch()
  const checkpointEditDetailsVM = useSelector(state => state.templateCheckpoints.checkpointDetailsVM)

  const [campaignCheckListVM, setCampaignCheckListVM] = useState(Object.assign({}, initialCampaignCheckListVMState))

  const checkpointDetailsVM = useMemo(() => {
    return isCampaignView ? campaignCheckListVM : checkpointEditDetailsVM
  }, [isCampaignView, campaignCheckListVM, checkpointEditDetailsVM])

  const ViewTitle = useMemo(() => searchViewTitle || getLocaleMessage(intl, ADVANCED_SEARCH), [searchViewTitle])

  useEffect(() => {
    if (!isCampaignView) {
      dispatch(actions.getCheckpointDetailVMRequest())
    } else {
      dispatch(actions.getCampaignChecklistVMRequest((data) => {
        if (data) {
          setCampaignCheckListVM(data)
        }
      }))
    }
  }, [])

  const handleSelectFilter = useCallback((key, value) => {
    let updatedValue = value
    const lastElement = value.length ? value[value.length - 1] : null
    if (!!lastElement && !lastElement.value && !lastElement.subComponentId && !lastElement.componentId) {
      updatedValue = []
    }
    onChangeCheckpointCollection(key, updatedValue, data => { onCustomAdvanceSearch(null, true, data) })
  }, [onChangeCheckpointCollection])

  const reactSelectPostionProps = useMemo(() => {
    return getReactSelectPositionProps()
  }, [])

  const HeaderContent = () => {
    return (
      <div className="d-flex align-items-center w-100 justify-content-between">
        <div className="mb-1">
          <CardTitle tag="h4" className="mb-0">
            {isViewMode ? ViewTitle : (isEditMode ? `${getLocaleMessage(intl, ACTIVITIES.EDIT_OBSERVATION_DETAILS)}` : `${getLocaleMessage(intl, ACTIVITIES.ADD_OBSERVATION_DETAILS)}`)}
          </CardTitle>
          {!isViewMode && <p>{getLocaleMessage(intl, ACTIVITIES.OBSERVATION_DETAILS_TITLE)}</p>}
        </div>
        {(isViewMode || hasCloseAction) && (
          <button
            id="close-search"
            type="button"
            className="waves-effect btn advanced-btn"
            onClick={hasCloseAction ? onClose : onToggle}
          >
            {isNewListTheme ? <img src={closeSVGIcon} alt="close" /> : getLocaleMessage(intl, CLOSE)}
          </button>
        )}
      </div>
    )
  }

  const SystemDefinitionHeaderContent = () => {
    if (!isShowSystemDefinition) {
      return null
    }

    return (
      <div className="d-flex align-items-center w-100 justify-content-between ">
        <div>
          <CardTitle className="as-sub-title" tag='h4'>{getLocaleMessage(intl, ACTIVITIES.SYSTEM_DEFINITION)}</CardTitle>
        </div>
      </div>
    )
  }

  const SearchCardFooter = () => {
    if (!isViewMode) {
      return null
    }

    return (
      <div className="text-right col-12">
        <Button
          outline
          onClick={onResetSearchDetail}
          className={isNewListTheme ? "secondary-outlined" : "delete-button"}
        >
          {getLocaleMessage(intl, CLEAR)}
        </Button>
        <Button className={isNewListTheme ? "ml-2 gradient-solid" : "ml-2 common-button"} onClick={onSearch}>
          {getLocaleMessage(intl, SEARCH)}
        </Button>
      </div>
    )
  }

  const CardContent = () => {
    return (
      <>
        {isModal ? (
          HeaderContent()
        ) : (
          <CardHeader className="p-0">{HeaderContent()}</CardHeader>
        )}
        <Form className="activity-collection">
          <Row form>
            {(isViewMode || isShowOnlyActivityNameAndType) && (
              <>
                <Col className="mb-1" sm={12} md={6} xl={4}>
                  <FormGroup>
                    <CustomLabel title={ACTIVITIES.ACTIVITY_NAME} />
                    <Input
                      type="text"
                      name="checkpoint_name"
                      id="checkpoint_name"
                      placeholder={getLocaleMessage(intl, ACTIVITIES.ACTIVITY_NAME)}
                      value={checkpointCollection?.checkpointName}
                      onChange={(event) => onChangeCheckpointCollection(
                        "checkpointName",
                        event.target.value
                      )}
                      onKeyDown={onCustomAdvanceSearch}
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm={12} md={6} xl={4}>
                  <FormGroup>
                    <ReactSelect
                      id="activity_type"
                      name="activity_type"
                      label={getLocaleMessage(intl, ACTIVITIES.ACTIVITY_TYPE)}
                      control={control}
                      isFormattedValue
                      value={checkpointCollection?.activityType}
                      options={ActivityTypeList}
                      placeHolder={getLocaleMessage(intl, ACTIVITIES.ACTIVITY_TYPE)}
                      onSelect={(selected) => onChangeCheckpointCollection(
                        "activityType",
                        selected?.value ? selected : null,
                        (data) => { onCustomAdvanceSearch(null, true, data) }
                      )}
                    />
                  </FormGroup>
                </Col>
              </>
            )}
            {!isShowOnlyActivityNameAndType && <>
              <Col className='mb-1' sm={12} md={6} xl={4}>
                <ReactSelect
                  id='responsible_site_role'
                  name='responsible_site_role'
                  label={getLocaleMessage(intl, ACTIVITIES.RESPONSIBLE_SITE_ROLE)}
                  control={control}
                  isRequired={!isViewMode}
                  isFormattedValue
                  value={checkpointCollection.responsibleSiteRole}
                  options={checkpointDetailsVM?.responsibleSiteRole || []}
                  placeHolder={getLocaleMessage(intl, ACTIVITIES.SELECT_SITE_ROLE)}
                  onSelect={(selected) => onChangeCheckpointCollection('responsibleSiteRole', selected?.value ? selected : null, (data) => { onCustomAdvanceSearch(null, true, data) })}
                  {...reactSelectPostionProps}
                />
                <ErrorMessage
                  isShow={!!errors['responsibleSiteRole']}
                  message={errors['responsibleSiteRole']}
                />
              </Col>
              <Col className='mb-1' sm={12} md={6} xl={4}>
                <ReactSelect
                  id='risk_level'
                  name='risk_level'
                  label={getLocaleMessage(intl, ACTIVITIES.RISK_LEVEL)}
                  control={control}
                  isRequired={!isViewMode}
                  isFormattedValue
                  value={checkpointCollection.riskLevel}
                  options={checkpointDetailsVM?.riskLevel || []}
                  placeHolder={getLocaleMessage(intl, ACTIVITIES.RISK_LEVEL)}
                  onSelect={(selected) => onChangeCheckpointCollection('riskLevel', selected?.value ? selected : null, (data) => { onCustomAdvanceSearch(null, true, data) })}
                />
                <ErrorMessage
                  isShow={!!errors['riskLevel']}
                  message={errors['riskLevel']}
                />
              </Col>
            </>
            }
            {isShowHoldProduction && <Col className='mb-1' sm={12} md={6} xl={4}>
              <FormGroup className="hold-production">
                <CustomLabel title={ACTIVITIES.HOLD_PRODUCTION} />
                <ToggleButton
                  className="custom-control-secondary"
                  id={`hold_production_on_open_items_activity`}
                  name={`hold_production_on_open_items_activity`}
                  value={checkpointCollection.holdProduction}
                  onChange={() => onChangeCheckpointCollection('holdProduction', !checkpointCollection.holdProduction, (data) => { onCustomAdvanceSearch(null, true, data) })}
                />
              </FormGroup>
            </Col>}
          </Row>
        </Form>
        {SystemDefinitionHeaderContent()}
        <Form>
          {isShowSystemDefinition && <Row form>
            <AssemblySearch
              errors={errors}
              defaultOptions={checkpointDetailsVM?.assembly || []}
              value={checkpointCollection.assembly}
              initialLabel={checkpointCollection.assemblyName}
              isEditMode={!!checkpointCollection.assemblyName}
              onChangeCheckpointCollection={(id, value, label) => onChangeCheckpointCollection(id, value, (data) => { onCustomAdvanceSearch(null, true, data) }, label)}
            />
            <AssemblyType
              errors={errors}
              value={checkpointCollection.assemblyType}
              assemblyId={checkpointCollection.assembly}
              onChangeCheckpointCollection={(id, value) => handleSelectFilter(id, value)}
            />
            <ComponentSearch
              errors={errors}
              assemblyTypeIds={checkpointCollection.assemblyType}
              value={checkpointCollection.component}
              isComponentDisabled={!!checkpointCollection?.assemblyType?.length}
              onChangeCheckpointCollection={(id, value) => handleSelectFilter(id, value)}
            />
            <SubComponentSearch
              errors={errors}
              componentId={checkpointCollection.component}
              value={checkpointCollection.subComponent}
              isViewMode={isViewMode}
              onChangeCheckpointCollection={(id, value) => handleSelectFilter(id, value)}
            />
            <ManufacturerSearch
              errors={errors}
              defaultOptions={checkpointDetailsVM?.manufacturer || []}
              initialLabel={checkpointCollection.manufacturerName}
              isEditMode={!!checkpointCollection.manufacturerName}
              value={checkpointCollection.manufacturer}
              isViewMode={isViewMode}
              onChangeCheckpointCollection={(id, value, label) => onChangeCheckpointCollection(id, value, (data) => { onCustomAdvanceSearch(null, true, data) }, label)}
            />
            <Col className='mb-1' sm={12} md={6} xl={4}>
              <FormGroup>
                <ReactSelect
                  id='brand'
                  name='brand'
                  label={getLocaleMessage(intl, ACTIVITIES.BRAND)}
                  control={control}
                  isFormattedValue
                  value={checkpointCollection.brand}
                  options={checkpointDetailsVM?.brand || []}
                  placeHolder={getLocaleMessage(intl, ACTIVITIES.SELECT_BRAND)}
                  onSelect={(selected) => onChangeCheckpointCollection('brand', selected?.value ? selected : null, (data) => { onCustomAdvanceSearch(null, true, data) })}
                />
              </FormGroup>
            </Col>
          </Row>}
          {SearchCardFooter()}
        </Form>
      </>
    )
  }

  if (isModal) {
    return CardContent()
  }

  return (
    <Card className={cx({
      "p-2 overview-list-card": isNewListTheme,
      "advance-search": isViewMode
    })}>
      <CardBody>{CardContent()}</CardBody>
    </Card>
  )
}

export default localeMessageWrapper(AdvanceSearch)
