import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateChecklistOrder
 * @param {object} data
 *  {
    "campaignServiceCheckpointId": 0,
    "prev_node": null,
    "next_node": null,
 }
 */

export const updateChecklistOrder = (data) => {
    const { url } = config.updateChecklistOrder
    return putDataApi(url, data)
}

export default updateChecklistOrder