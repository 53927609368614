import { useMemo } from "react"

import { LANGUAGE_TYPE } from '@shared/constants'

import { getSpecificLanguage } from '../Config'
import { RESPONSE_CHOICE_TYPES } from "../choice-response"

export const useSelectedLanguageAcitivity = (data, key, selectedTab) => {

  const responseIdList = [RESPONSE_CHOICE_TYPES.BOOLEAN, RESPONSE_CHOICE_TYPES.SINGLE, RESPONSE_CHOICE_TYPES.MULTI, RESPONSE_CHOICE_TYPES.DDL_SINGLE, RESPONSE_CHOICE_TYPES.DDL_MULTI, RESPONSE_CHOICE_TYPES.BUTTON]

  function setOptions(ques, responseTypeId) {
    const obj = Object.assign({}, ques)
    const options = getSpecificLanguage(ques.options, selectedTab)
    obj.options = options
    if (responseIdList.indexOf(responseTypeId) > -1) {
      obj.optionsEnglish = getSpecificLanguage(ques.options, LANGUAGE_TYPE.ENGLISH)
    }
    return obj
  }

  return useMemo(() => {
    const value = getSpecificLanguage(data[key], selectedTab, { isRequiresAllDetails: true })
    const updatedData = { ...data, [key]: value.languageValue, description: value.description }

    if (updatedData.checkpointResponses?.length) {
      const list = []
      let isFormatted = false
      for (const res of updatedData.checkpointResponses) {
        const internalList = []
        if (res.checkpointResponseQuestions?.length) {
          for (const ques of res.checkpointResponseQuestions) {
            if (typeof ques.options === 'string') {
              isFormatted = true
              break
            }
            internalList.push(setOptions(ques, res.checkpointResponseTypeId))
          }
        }
        if (!isFormatted) {
          const responseNameValue = getSpecificLanguage(res.responseName, selectedTab, { isRequiresAllDetails: true })
          list.push({ ...res, responseName: responseNameValue.languageValue, description: responseNameValue.description, checkpointResponseQuestions: internalList })
        }
      }
      if (!isFormatted) {
        updatedData.checkpointResponses = list
      }
    }
    if (updatedData.checkpointResponseQuestions?.length) {
      const list = []
      let isFormatted = false
      for (const ques of updatedData.checkpointResponseQuestions) {
        if (typeof ques.options === 'string') {
          isFormatted = true
          break
        }
        list.push(setOptions(ques, data.checkpointResponseTypeId))
      }
      if (!isFormatted) {
        updatedData.checkpointResponseQuestions = list
      }
    }
    return updatedData
  }, [
    selectedTab,
    data.activityName,
    data?.checkpointResponses,
    data?.checkpointVersionId,
    data.responseName,
    data.required,
    data.isAllowedNA,
    data?.checkpointResponseQuestions,
    data?.checkpointResponseNumericTypeId,
    data?.checkpointResponseNumericValidationId,
    data?.numericMax,
    data?.numericMin,
    data?.criteria
  ])
}
