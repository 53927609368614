import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method replaceRCDocument : To replace uploaded document to resource center
 * 
 * Payload contains following:-
 * ResourceCenterId : Current resource center id where document is uploaded
 * ResourceCenterSourceId : Source page id
 * Title
 * FileDescription
 * [data.fileSourceName]
 * file
 * 
 * data contains following:-
 * - resourceCenterId
 * - fileLocationType
 * - title
 * - fileDescription
 * - fileSourceName
 * - fileSourceId
 * - fileContent
 * 
 * data.fileSourceName can be following:-
 * - OrganizationId
 * - ProjectId
 * - SiteId
 * - SiteUnitId
 * - ProgramId
 * - CampaignId
 * - CampaignServiceId
 * - CheckListId
 * - ActivityId
 * - ActivityVersionId 
 * - ProgramRequirementId 
 * 
 */

export const replaceRCDocument = (data) => {
  const { url } = config.replaceRCDocument
  const queryPayload = {
    resourceCenterId: data.resourceCenterId,
    resourceCenterSourceId: Number(data.fileLocationType),
    title: data.title,
    fileDescription: data.fileDescription,
    fileSourceName: data.fileSourceName,
    fileSourceId: Number(data.fileSourceId),
    fileCategoryId: Number(data.fileCategoryId)
  }

  const payload = new FormData()
  payload.append("file", data.fileContent, data.fileContent.name)
 
  return putDataApi(url(queryPayload), payload, {
    'Content-Type': 'multipart/form-data'
  })
}

export default replaceRCDocument
