import { models } from 'powerbi-client'
import { PowerBIEmbed } from 'powerbi-client-react'
import { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import * as actions from '@store/actions'

const initialState = {
  reportId: '',
  embedToken: '',
  embedUrl: ''
}

const ReportView = (props) => {
  const { id, parameter = '' } = props
  const [reportsData, setReportsData] = useState(initialState)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => setReportsData(initialState)
  }, [])

  useEffect(() => {
    if (id) {
      setReportsData({
        ...reportsData,
        reportId: id
      })
    }
  }, [props])

  useEffect(() => {
    if (reportsData.reportId) {
      dispatch(actions.getPowerBiAccessTokenRequest(reportsData.reportId, (response) => {
        if (response) {
          setReportsData({
            reportId: response.ReportId,
            embedUrl: `${response.EmbedUrl} ${parameter ? `&${parameter}` : ''}`,
            embedToken: response.EmbedToken
          })
        }
      }))
    }
  }, [reportsData.reportId])

  const config = useMemo(() => {
    return {
      type: 'report',
      id: reportsData.reportId,
      embedUrl: reportsData.embedUrl,
      accessToken: reportsData.embedToken,
      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false
          }
        }
      }
    }
  }, [reportsData])

  if (!Object.keys(reportsData).every((value) => !!reportsData[value])) return null

  return <PowerBIEmbed
    embedConfig={config}
    key={reportsData.embedToken}
    eventHandlers={
      new Map([
        ['loaded', function () { }],
        ['rendered', function () { }],
        ['error', function (event) { }]
      ])
    }
    cssClassName={"report-style-class"}
  />
}

export default ReportView
