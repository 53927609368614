import {
  GET_SCHEDULE_VM,
  GET_SCHEDULE_ASSIGN_VM,
  SET_SELECTED_SITE_UNIT_LIST,
  REMOVE_SELECTED_SITE_UNIT,
  SET_SITE_EXPANDED_ROW_ID,
  CLEAR_SELECTED_CHILD_ROWS,
  SET_TOGGLE_ASSIGN_CONTACT_MODE,
  SET_SITE_TASK_MODE,
  REFRESH_SITE_CHILD_GRID,
  SET_TASK_CONTEXT_MENU_ACTION_MODE,
  GET_IS_WORK_ORDER_NEEDED
} from '@store/actions'

// ** Initial State
const initialState = {
  error: '',
  scheduleVM: {
    state: [],
    country: [],
    project: {},
    organization: {},
    campaign: [],
    campaignType: [],
    status: [],
    buildingType: [],
    campaignStatus: [],
    pointOfContact: [],
    projectType: [],
    projectStatus: [],
    siteStatus: [],
    markets: []
  },
  scheduleAssignVM: {
    state: [],
    country: [],
    availabilities: []
  },
  selectedUnitList: [],
  expandedRowId: null,
  clearSelectedChildRows: false,
  isOpenAssignContactMode: false,
  currentTask: {
    mode: '',
    detail: null
  },
  refreshSiteChildGrid: false,
  isContextMenuActionTriggered: false,
  contextMenuActionDetail: null,
  isWorkOrderNeeded: false
}

const SchedulingReducer = (state = initialState, action) => {
  switch (action.type) {

    /** GET_SCHEDULE_VM **/

    case GET_SCHEDULE_VM.REQUEST:
      return { ...state, error: '' }

    case GET_SCHEDULE_VM.SUCCESS:
      return {
        ...state,
        scheduleVM: action.data
      }

    case GET_SCHEDULE_VM.FAILURE:
      return { ...state, error: action.error }

    /** GET_SCHEDULE_ASSIGN_VM **/

    case GET_SCHEDULE_ASSIGN_VM.REQUEST:
      return { ...state, error: '' }

    case GET_SCHEDULE_ASSIGN_VM.SUCCESS:
      return {
        ...state,
        scheduleAssignVM: {
          state: action.data.states,
          country: action.data.countries,
          availabilities: action.data.availabilities
        }
      }

    case GET_SCHEDULE_ASSIGN_VM.FAILURE:
      return { ...state, error: action.error }

    case SET_SELECTED_SITE_UNIT_LIST:
      return { ...state, selectedUnitList: action.data }

    case REMOVE_SELECTED_SITE_UNIT:
      return {
        ...state,
        selectedUnitList: state.selectedUnitList.filter(unit => unit.observationTaskId !== action.payload)
      }

    case SET_SITE_EXPANDED_ROW_ID:
      return { ...state, expandedRowId: action.payload || null }

    case CLEAR_SELECTED_CHILD_ROWS:
      return { ...state, clearSelectedChildRows: action.payload ? action.payload : false }

    /** SET_TOGGLE_ASSIGN_CONTACT_MODE */
    case SET_TOGGLE_ASSIGN_CONTACT_MODE:
      return {
        ...state,
        isOpenAssignContactMode: action.data
      }

    case SET_SITE_TASK_MODE:
      const { mode = '', detail = null } = action.payload || {}
      return { ...state, currentTask: { mode, detail } }

    case REFRESH_SITE_CHILD_GRID:
      return { ...state, refreshSiteChildGrid: action.payload || false }

    /** SET_TASK_CONTEXT_MENU_ACTION_MODE **/
    case SET_TASK_CONTEXT_MENU_ACTION_MODE:
      return {
        ...state,
        isContextMenuActionTriggered: action.payload.isContextMenuActionTriggered,
        contextMenuActionDetail: action.payload.contextMenuActionDetail
      }

    case GET_IS_WORK_ORDER_NEEDED.REQUEST:
      return state

    case GET_IS_WORK_ORDER_NEEDED.SUCCESS:
      return {
        ...state,
        isWorkOrderNeeded: action.data
      }

    default:
      return state
  }
}

export default SchedulingReducer
