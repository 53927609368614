import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

// ** Styles
import { Trash2 } from 'react-feather'


import { TABLE_LIST_KEYS } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from '@store/actions'
import { DeleteConfirmationModal } from '@views/components'
import localeMessageWrapper from "@views/components/locale-message"

const { ACTIVITIES } = LANGUAGE_CONSTANTS

const RowActions = ({ intl, data, isChildGrid }) => {

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false)
  const dispatch = useDispatch()

  const handleToggleDeleteCheckpoint = useCallback((event) => {
    if (event) {
      event.preventDefault()
    }
    setOpenDeleteModal(!isOpenDeleteModal)
  }, [isOpenDeleteModal])

  const handleDeleteActivity = useCallback(() => {
    if (data?.wfCampaignSetupActivityId) {
      dispatch(actions.deleteCampaignServiceChecklistCheckpoint([data.wfCampaignSetupActivityId], () => {
        if (isChildGrid) {
          dispatch(actions.refreshTableList({ listKey: TABLE_LIST_KEYS.WORKFLOW_CHECKLIST_KEY, value: true }))
        } else {
          dispatch(actions.refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: true }))
        }
      }))
    }
    handleToggleDeleteCheckpoint()
  }, [data, handleToggleDeleteCheckpoint])

  return (
    <>
      <div className="d-flex nt-rdt-list-actions pt-1">
        <Trash2 size={15} className="trash" onClick={handleToggleDeleteCheckpoint} />
      </div>
      <DeleteConfirmationModal
        id={data.checkpointId}
        open={isOpenDeleteModal}
        onToggleModal={handleToggleDeleteCheckpoint}
        onConfirmDeleteRecord={handleDeleteActivity}
        type={ACTIVITIES.ACTIVITY}
      />
    </>
  )
}

export default localeMessageWrapper(RowActions)
