const apiConfig = {
  createRegistration: {
    method: 'POST',
    url: 'api/v2/registrations'
  },
  createRegistrationLaunchLink: {
    method: 'POST',
    url: ({ registrationId }) => `api/v2/registrations/${registrationId}/launchLink`
  },
  createScormCloudToken: {
    method: 'POST',
    url: 'api/v2/appManagement/token'
  },
  getRegistrationDetail: {
    method: 'GET',
    url: ({ registrationId }) => `api/v2/registrations/${registrationId}`
  },
  setRegistrationConfiguration: {
    method: 'POST',
    url: ({ registrationId }) =>  `api/v2/registrations/${registrationId}/configuration`
  }
}

export default apiConfig
