import React, { useCallback } from "react"
import { useDispatch } from "react-redux"

import * as actions from "@store/actions"
import CustomCheckboxSearch from "./custom-checkbox-search"

const ZipCodeSearch = ({
  id = "zipCode",
  allSelectCheckboxLabel = "",
  inputPlaceholder,
  valueType = "zipCode",
  labelType = "zipCode",
  value = [],
  region = [],
  subRegions = [],
  states = [],
  city = [],
  selectedStates = [],
  isResetClicked = false,
  isCalendarPageZipCodeFilter = false,
  onSelect = () => { },
  onResetClicked = () => { }
}) => {
  const dispatch = useDispatch()

  const handleFetchOptions = useCallback((params, callback = () => { }) => {
    const queryPayload = {
      ...params,
      zipCode: params.searchText,
      states: selectedStates,
      regions: region,
      states,
      city,
      subRegions,
      isCalendarPage: isCalendarPageZipCodeFilter
    }
    dispatch(actions.getZipCodeListRequest(queryPayload, callback))
  }, [selectedStates, states, city, subRegions, region])

  const handleSelectFilter = useCallback(
    (value) => {
      onSelect(id, value)
    },
    [onSelect]
  )

  return (
    <CustomCheckboxSearch
      id={id}
      valueType={valueType}
      labelType={labelType}
      allSelectCheckboxLabel={allSelectCheckboxLabel}
      inputPlaceholder={inputPlaceholder}
      onFetchOptions={handleFetchOptions}
      onSelectFilter={handleSelectFilter}
      onResetClicked={onResetClicked}
      selectedFilters={value}
      isResetClicked={isResetClicked}
    />
  )
}

export default ZipCodeSearch