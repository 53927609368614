import config from "../APIConfig"
import { putDataApi } from "../../../index"

/**
 * @method updateActivityGroup
 * 
 * @param data 
 * {
    "groupId": 0,
    "groupName": "string",
    "wfCampaignSetupChecklistId": 0
  }
 * @returns 
 */
export const updateActivityGroup = (payload) => {
  const { url } = config.updateActivityGroup

  return putDataApi(url(payload), payload)
}

export default updateActivityGroup
