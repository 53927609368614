const apiConfig = {
  getWorkflowReviewDetail: {
    method: 'GET',
    url: (wfCampaignSetupId) => `api/WorkFlow/GetWorkFlowReview/${wfCampaignSetupId}`
  },
  getWorkflowSummary: {
    method: 'GET',
    url: (wfCampaignSetupId) => `api/WorkFlow/Summary/${wfCampaignSetupId}`
  }
}

export default apiConfig
