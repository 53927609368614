import config from "./APIConfig"
import { putDataApi } from "../index"

export const updateContactEmailId = (data) => {
  const { url } = config.updateContactEmailId
  const payload = {
    ContactId: data.contactId,
    NewEmail: data.newEmail
  }
  return putDataApi(url(data.contactId), payload)
}

export default updateContactEmailId
