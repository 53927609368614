import config from "./APIConfig"
import { putDataApi } from "../index"

/**
 * @method updateContactGroup : To update name of contact group
 * @param {object} data 
 * {
  "contactGroupId": 0,
  "contactGroupName": "string"
 }
 */
const updateContactGroup = async (data) => {
  const { url } = config.updateContactGroup
  return await putDataApi(url(data), data)
}

export default updateContactGroup
