import { getSearchQuery } from '../config'

const apiConfig = {
  getTemplateTrainingActivityVM: {
    method: 'GET',
    url: (data) => {
      const { checkpointId } = data || {}
      const searchString = getSearchQuery("checkpointId", checkpointId, true)
      return `api/Training/Trainingcheckpointvm${searchString}`
    }
  },
  addTemplateTrainingActivity: {
    method: 'POST',
    url: 'api/Training'
  },
  getTemplateTrainingActivity: {
    method: 'GET',
    url: ({ checkpointId, checkpointVersionId }) => `api/Training/${checkpointId}/${checkpointVersionId}`
  },
  updateTemplateTrainingActivity: {
    method: 'PUT',
    url: ({ checkpointId, checkpointVersionId }) => `api/Training/${checkpointId}/${checkpointVersionId}`
  },
  getTemplateTrainingActivityChildDetail: {
    method: 'GET',
    url: ({ checkpointId, checkpointVersionId = 1 }) => `api/Training/${checkpointId}/${checkpointVersionId}/Trainingdetail`
  },
  deleteTrainingActivity: {
    method: 'DELETE',
    url: ({ checkpointId = 0, checkpointVersionId = 1 }) => `api/Training/${checkpointId}/${checkpointVersionId}`
  },
  cloneTemplateTrainingActivity: {
    method: 'POST',
    url: ({
      checkpointId = 0,
      checkpointVersionId = 1,
      templateId = 0,
      templateVersionId = 1
    }) => `api/Training/clone/${templateId}/${templateVersionId}/${checkpointId}/${checkpointVersionId}`
  },
  cloneTrainingActivity: {
    method: 'POST',
    url: ({
      checkpointId = 0,
      checkpointVersionId = 1,
      templateId = 0,
      templateVersionId = 1
    }) => `api/Training/clone/${templateId}/${templateVersionId}/${checkpointId}/${checkpointVersionId}`  //Todo: need to change the API as the activities are not associated with any template
  },
  addNewTrainingActivityVersion: {
    method: 'POST',
    url: ({ checkpointId }) => `api/Training/version/${checkpointId}`
  },
  getAllCourses: {
    method: 'GET',
    url: ({ courseName = "", moreToken = "" }) => {
      const searchString = [
        getSearchQuery("CourseName", courseName, true, true),
        getSearchQuery("MoreToken", moreToken, !courseName.length, true)
      ].join('')
      return `api/Scorm/getallcources${searchString}`
    }
  }
}

export default apiConfig