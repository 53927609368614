import { useCallback, useState, useContext } from "react"
import { useDispatch } from 'react-redux'

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from '@store/actions'
import { getLocaleMessage } from "@utils"
import { ToggleButton } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import { ChecklistActivitiesContext } from "./index"

const { WORKFLOW } = LANGUAGE_CONSTANTS

const MarkActivityRequired = ({ data, intl }) => {
  const dispatch = useDispatch()

  const context = useContext(ChecklistActivitiesContext)

  const [required, setRequired] = useState(!!data.isRequired)

  const handleToggleRequired = useCallback(() => {
    const payload = {
      wfCampaignSetupChecklistId: data.wfCampaignSetupChecklistId,
      checklistActivites: [
        {
          wfCampaignSetupActivityId: data.wfCampaignSetupActivityId,
          isRequired: !required
        }
      ]
    }
    dispatch(actions.updateCheckListRequiredActivityRequest(payload, (res) => {
      if (res) {
        setRequired(!required)
        context.onChange(data.checkpointId, !required, 'isRequired')
      }
    }))
  }, [data, required, context])

  return (
    <ToggleButton
      className="custom-control-secondary mt-0"
      id={`checklist_activity_${data.checkpointId}`}
      name={`checklist_activity_${data.checkpointId}`}
      label={getLocaleMessage(intl, required ? WORKFLOW.REQUIRED : WORKFLOW.OPTIONAL_NOT_REQUIRED)}
      value={!!required}
      onChange={handleToggleRequired}
    />
  )
}

export default localeMessageWrapper(MarkActivityRequired)
