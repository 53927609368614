import {
    GET_TEMPLATE_CHECKPOINT_LIST,
    GET_NEW_CHECKPOINT_VM,
    GET_CHECKPOINT_DETAIL_VM,
    GET_TEMPLATE_SUB_COMPONENT_LIST,
    GET_TEMPLATE_TRAINING_ACTIVITY_VM,
    RESET_TEMPLATE_TRAINING_ACTIVITY_VM,
    TEMPLATE_ACTION_TYPE,
    SAVE_CURRENT_TEMPLATE,
    RESET_CHECKPOINT_LIST
} from "../../actions"
import { DEFAULT_TABLE_PAGE_SIZE } from '@shared/constants'

// ** Initial State
const initialState = {
    list: [],
    pageNumber: 0,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    totalCount: 0,
    totalPages: 0,
    error: "",
    hasPreviousPage: false,
    hasNextPage: false,
    generalVM: {
        checkpointVersion: [],
        checkpointResponseType: [],
        checkpointResponseNumericType: [],
        checkpointResponseNumericValidation: [],
        disposition: []
    },
    checkpointDetailsVM: {
        stageOfConstruction: [],
        scopeOfWork: [],
        responsibleSiteRole: [],
        riskLevel: [],
        systemCategory: [],
        assembly: [],
        component: [],
        manufacturer: [],
        subComponent: [],
        brand: [],
        holdProduction: []
    },
    templateActionType: '',
    currentTemplate: {
        templateName: "",
        templateId: ""
    },
    trainingActivityVM: {
        author: [],
        checkpointStatus: [],
        checkpointVersion: [],
        source: [],
        stageOfConstruction: [],
        trainingChapter: [],
        trainingCourse: [],
        trainingLesson: [],
        trainingSubject: []
    }
}

const TemplateCheckpointsReducer = (state = initialState, action) => {
    switch (action.type) {
        /** GET_TEMPLATE_CHECKPOINT_LIST **/
        case GET_TEMPLATE_CHECKPOINT_LIST.REQUEST:
            return { ...state, error: "" }

        case GET_TEMPLATE_CHECKPOINT_LIST.SUCCESS:
            return {
                ...state,
                list: action.data.items,
                pageSize: action.data.pageSize,
                pageNumber: action.data.pageIndex,
                totalCount: action.data.totalCount,
                totalPages: action.data.totalPages,
                hasPreviousPage: action.data.hasPreviousPage,
                hasNextPage: action.data.hasNextPage
            }

        case GET_TEMPLATE_CHECKPOINT_LIST.FAILURE:
            return {
                ...state,
                error: action.error
            }

        /** RESET_CHECKPOINT_LIST **/
        case RESET_CHECKPOINT_LIST:
            return {
                ...state,
                list: [],
                pageNumber: 0,
                pageSize: DEFAULT_TABLE_PAGE_SIZE,
                totalCount: 0,
                totalPages: 0,
                hasPreviousPage: false,
                hasNextPage: false
            }

        case GET_NEW_CHECKPOINT_VM.REQUEST:
            return { ...state, error: '' }

        case GET_NEW_CHECKPOINT_VM.SUCCESS:
            return {
                ...state,
                generalVM: action.data
            }

        case GET_NEW_CHECKPOINT_VM.FAILURE:
            return { ...state, error: action.FAILURE }


        /** GET_CHECKPOINT_DETAIL_VM **/
        case GET_CHECKPOINT_DETAIL_VM.REQUEST:
            return { ...state, error: '' }

        case GET_CHECKPOINT_DETAIL_VM.SUCCESS:
            {
                const { stageOfConstruction, scopeOfWork, responsibleSiteRole, riskLevel, systemCategory, brand, assembly, component, manufacturer, assemblyType, holdProduction } = action.data
                return {
                    ...state,
                    checkpointDetailsVM: {
                        stageOfConstruction,
                        scopeOfWork,
                        responsibleSiteRole,
                        riskLevel,
                        systemCategory,
                        brand,
                        assembly: assembly?.items,
                        component: component?.items,
                        manufacturer: manufacturer?.items,
                        assemblyType,
                        holdProduction
                    }
                }
            }

        case GET_CHECKPOINT_DETAIL_VM.FAILURE:
            return { ...state, error: action.FAILURE }

        /** GET_TEMPLATE_SUB_COMPONENT_LIST **/
        case GET_TEMPLATE_SUB_COMPONENT_LIST.REQUEST:
            return { ...state, error: '' }

        case GET_TEMPLATE_SUB_COMPONENT_LIST.SUCCESS:
            return {
                ...state,
                checkpointDetailsVM: {
                    ...state.checkpointDetailsVM,
                    subComponent: action.data.items
                }
            }

        case GET_TEMPLATE_SUB_COMPONENT_LIST.FAILURE:
            return { ...state, error: action.FAILURE }

        case TEMPLATE_ACTION_TYPE:
            return { ...state, templateActionType: action.payload }

        case SAVE_CURRENT_TEMPLATE:
            return {
                ...state,
                currentTemplate: {
                    templateName: action.payload.templateName,
                    templateId: action.payload.templateId
                }
            }

        /** GET_TEMPLATE_TRAINING_ACTIVITY_VM */
        case GET_TEMPLATE_TRAINING_ACTIVITY_VM.SUCCESS:
            return {
                ...state,
                trainingActivityVM: action.data
            }

        /** RESET_TEMPLATE_TRAINING_ACTIVITY_VM */
        case RESET_TEMPLATE_TRAINING_ACTIVITY_VM:
            return {
                ...state,
                trainingActivityVM: {
                    author: [],
                    checkpointStatus: [],
                    checkpointVersion: [],
                    source: [],
                    stageOfConstruction: [],
                    trainingChapter: [],
                    trainingCourse: [],
                    trainingLesson: [],
                    trainingSubject: []
                }
            }

        default:
            return state
    }
}

export default TemplateCheckpointsReducer
