const apiConfig = {
  getOshaDocumentationCampaignList: {
    method: 'GET',
    url: (programId = 0) => `api/Program/OshaDocumentationCampaign/${programId}`
  },
  addOshaDocumentationCampaignList: {
    method: 'POST',
    url: (programId = 0) => `api/Program/OshaDocumentationCampaign/${programId}`
  },
  updateOshaDocumentationCampaignList: {
    method: 'PUT',
    url: (programId = 0) => `api/Program/OshaDocumentationCampaign/${programId}`
  }
}

export default apiConfig
