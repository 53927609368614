import { put, takeLatest, call } from "redux-saga/effects"

import {
  addProjectSitePlan,
  getProjectSitePlan,
  getProjectSitePlanList,
  updateProjectSitePlan,
  deleteProjectSitePlan,
  getSitePlanScheduleList,
  deleteProjectSitePlanSchedule,
  addProjectSitePlanSchedule,
  getDDLSitePlanSchedulePrereqs
} from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'

import * as actions from "../../actions"

const { PROJECT_PLAN_SAGA } = LANGUAGE_CONSTANTS

/** getProjectSitePlanList API **/
function* getProjectSitePlanListRequest(action) {
  try {
    const { isSearchVM, isLoadingRequired = false, ...payload } = action.payload
    if (!isSearchVM && isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getProjectSitePlanList, payload)
    if (!isSearchVM) {
      if (response?.data) {
        yield put(actions.getProjectSitePlanListSuccess({ ...response.data, pageSize: action.payload.pageSize }))
        action.callback({ ...response.data, pageSize: action.payload.pageSize })
      } else {
        yield put(actions.getProjectSitePlanListFailure("Unable to load project site plan list"))
        action.callback()
      }
    } else {
      action.callback()
    }
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    const { isSearchVM } = action.payload
    if (!isSearchVM) {
      yield put(actions.getProjectSitePlanListFailure(error.message))
    }
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getProjectSitePlan API **/
function* getProjectSitePlanRequest(action) {
  try {
    const response = yield call(getProjectSitePlan, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** addProjectSitePlan API **/
function* addProjectSitePlanRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addProjectSitePlan, action.payload)
    if (response?.data) {
      action.callback(response.data)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_PLAN_SAGA.ADDED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_PLAN_SAGA.ERROR_IN_ADDING })
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** updateProjectSitePlan API **/
function* updateProjectSitePlanRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateProjectSitePlan, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_PLAN_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_PLAN_SAGA.ERROR_IN_UPDATING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** deleteProjectSitePlanApi API **/
function* deleteProjectSitePlanRequest(data) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteProjectSitePlan, data.payload)
    if (response && response.status === 204) {
      yield put(actions.deleteProjectSitePlanSuccess(response.data))
      if (data.callback) {
        data.callback()
      }
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_PLAN_SAGA.DELETED_SUCCESS })
    } else {
      yield put(actions.deleteProjectSitePlanFailure("Unable to delete project site plan"))
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_PLAN_SAGA.ERROR_IN_DELETING })
    }
  } catch (error) {
    yield put(actions.deleteProjectSitePlanFailure(error.message))
    yield put(actions.setIsLoading(false))
  }
}

/** getSitePlanScheduleList API **/
function* getSitePlanScheduleListRequest(action) {
  try {
    const response = yield call(getSitePlanScheduleList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** deleteProjectSitePlanSchedule API **/
function* deleteProjectSitePlanScheduleRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteProjectSitePlanSchedule, action.payload)
    if (response && response.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_PLAN_SAGA.SCHEDULE_DELETED_SUCCESS })
    } else {
      action.callback(false)
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_PLAN_SAGA.ERROR_IN_DELETING_SCHEDULE })
    }
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** addProjectSitePlanSchedule API **/
function* addProjectSitePlanScheduleRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addProjectSitePlanSchedule, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: action.payload.isRowsReordered ? PROJECT_PLAN_SAGA.ROWS_REORDERED_SUCCESS : PROJECT_PLAN_SAGA.SCHEDULE_ADDED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: action.payload.isRowsReordered ? PROJECT_PLAN_SAGA.ERROR_IN_REORDERING_ROWS : PROJECT_PLAN_SAGA.ERROR_IN_ADDING_SCHEDULE })
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getDDLSitePlanSchedulePrereqs API **/
function* getDDLSitePlanSchedulePrereqsRequest(action) {
  try {
    const response = yield call(getDDLSitePlanSchedulePrereqs, action.payload)
    action.callback({ ...response.data, pageSize: action.payload.pageSize })
  } catch (error) {
    action.callback()
  }
}

export function* projectSitePlanSagas() {
  yield takeLatest(actions.ADD_PROJECT_SITE_PLAN.REQUEST, addProjectSitePlanRequest)
  yield takeLatest(actions.GET_PROJECT_SITE_PLAN.REQUEST, getProjectSitePlanRequest)
  yield takeLatest(actions.GET_PROJECT_SITE_PLAN_LIST.REQUEST, getProjectSitePlanListRequest)
  yield takeLatest(actions.UPDATE_PROJECT_SITE_PLAN.REQUEST, updateProjectSitePlanRequest)
  yield takeLatest(actions.DELETE_PROJECT_SITE_PLAN.REQUEST, deleteProjectSitePlanRequest)
  yield takeLatest(actions.GET_SITE_PLAN_SCHEDULE_LIST_REQUEST, getSitePlanScheduleListRequest)
  yield takeLatest(actions.DELETE_SITE_PLAN_SCHEDULE_REQUEST, deleteProjectSitePlanScheduleRequest)
  yield takeLatest(actions.ADD_SITE_PLAN_SCHEDULE_REQUEST, addProjectSitePlanScheduleRequest)
  yield takeLatest(actions.GET_DDL_SITE_PLAN_SCHEDULE_PREREQS_REQUEST, getDDLSitePlanSchedulePrereqsRequest)
}

export default projectSitePlanSagas
