import React, { useMemo, createContext } from 'react'

import { TableView } from "@views/components"
import localeMessageWrapper from '@views/components/locale-message'
import { getColumnConfig } from './TableConfig'

export const AdditionalPurchaseOrderContext = createContext()

const AdditionalPurchaseOrderList = ({ isManagePurchaseOrder, purchaseOrderList, intl, onEditMode, onDelete }) => {

  const tableComponentProps = useMemo(() => {
    return {
      isStaticList: true,
      staticListHeight: purchaseOrderList.length <= 3 ? 0 : 200
    }
  }, [purchaseOrderList])

  const columns = useMemo(() => {
    return getColumnConfig({ intl, isManagePurchaseOrder })
  }, [isManagePurchaseOrder])

  const contextValue = useMemo(() => {
    return {
      onEditMode,
      onDeleteAdditionalPurchaseOrder: onDelete
    }
  }, [onEditMode, onDelete])

  return (
    <AdditionalPurchaseOrderContext.Provider value={contextValue}>
      <TableView
        selectableRows={false}
        showAgGrid
        totalCount={purchaseOrderList.length}
        columns={columns}
        className="nt-rdt-list"
        isNewListTheme
        data={purchaseOrderList}
        pagination={false}
        tableViewHeaderRequired={false}
        tableComponentProps={tableComponentProps}
        tableHeaderRequired={false}
        isSearchFilterRequired={false}
      />
    </AdditionalPurchaseOrderContext.Provider>
  )
}

export default localeMessageWrapper(AdditionalPurchaseOrderList)
