import config from "./APIConfig"
import { putDataApi } from '../../index'

/**
 * @method updateBlockPartialSubmission
 * @param {object} data 
 * 
 * API payload:-
 * 
 * {
  "wfCampaignSetupId": 0,
  "partialSubmissionRequired": true
}
 */

export const updateBlockPartialSubmission = (data) => {
  const { url } = config.updateBlockPartialSubmission

  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    partialSubmissionRequired: !!Number(data.isPartialSubmissionRequired)
  }

  return putDataApi(url(data.wfCampaignSetupId), payload)
}

export default updateBlockPartialSubmission
