import config from "./APIConfig"
import { putDataApi } from '../index'

export const updateSamplingWorkflowGroupUnitType = (data) => {
  const { url } = config.updateSamplingWorkflowGroupUnitType

  return putDataApi(url({ workflowId: data.workflowId, groupId: data.groupId, sampleType: data.sampleType }), data.taskIdList)
}

export default updateSamplingWorkflowGroupUnitType
