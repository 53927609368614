import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { ACTIVITY_TYPE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { LocaleMessage } from '@views/components'
import ExpandableTrainingActivityChildDetail from './ExpandableTrainingActivityChildDetail'
import ExpandableObservationActivityChildDetail from './ExpandableObservationActivityChildDetail'

const { NO_DATA_FOUND, LOADER } = LANGUAGE_CONSTANTS

const ExpandableInspectionDetailsChildDetail = ({ data, noDataMessage }) => {
  const dispatch = useDispatch()
  const [subData, setSubData] = useState({})
  const [isLoadingChildGrid, setLoadingChildGrid] = useState(false)

  useEffect(() => {
    setLoadingChildGrid(true)
    dispatch(
      actions.getCheckpointChildDetailRequest(
        { checkpointId: data.checkpointId, checkpointVersionId: data.checkpointVersionId, activityTypeId: data.activityTypeId },
        (data) => {
          if (data) {
            setSubData(data)
          }
          setLoadingChildGrid(false)
        }
      )
    )
  }, [])

  if (isLoadingChildGrid) {
    return <p className="no-data-txt"><LocaleMessage id={LOADER.LOADING} /></p>
  }

  if (!subData) {
    return <p className="no-data-txt">{noDataMessage}</p>
  }

  if (!subData) {
    return <p className="no-data-txt">{noDataMessage}</p>
  }

  if (data.activityTypeId === Number(ACTIVITY_TYPE.OBSERVATION)) {
    return <ExpandableObservationActivityChildDetail selectedGrid={subData} />
  }

  if (data.activityTypeId === Number(ACTIVITY_TYPE.TRAINING)) {
    return <ExpandableTrainingActivityChildDetail selectedGrid={subData} />
  }

  return <p className="no-data-txt"><LocaleMessage id={NO_DATA_FOUND} /></p>
}

export default ExpandableInspectionDetailsChildDetail
