import { getActionType } from '../action-type-generator'

export const GET_COUNTRY_LIST = getActionType('GET_COUNTRY_LIST')
export const GET_STATE_LIST = getActionType('GET_STATE_LIST')
export const GET_COUNTY_LIST_REQUEST = 'GET_COUNTY_LIST_REQUEST'
export const GET_REGION_LIST_REQUEST = 'GET_REGION_LIST_REQUEST'
export const GET_CITY_BY_STATE_LIST_REQUEST = 'GET_CITY_BY_STATE_LIST_REQUEST'
export const GET_ZIPCODE_LIST_REQUEST = 'GET_ZIPCODE_LIST_REQUEST'
export const GET_LOCATION_UNIT_LIST_REQUEST = 'GET_LOCATION_UNIT_LIST_REQUEST'
export const GET_SUB_REGION_LIST_REQUEST = 'GET_SUB_REGION_LIST_REQUEST'

/** GET_COUNTRIES_LIST **/
/****************************************************/

/**
 * @method getCountryListRequest : To get country list
 *
 */
export const getCountryListRequest = () => {
  return {
    type: GET_COUNTRY_LIST.REQUEST
  }
}

/**
 * @method getCountryListSuccess : To set country list on success
 *
 */
export const getCountryListSuccess = (data) => {
  return {
    type: GET_COUNTRY_LIST.SUCCESS,
    data
  }
}

/**
 * @method getCountryListFailure : To set error on getting countries
 *
 */
export const getCountryListFailure = (error) => {
  return {
    type: GET_COUNTRY_LIST.FAILURE,
    error
  }
}

/** GET_STATE_LIST **/
/****************************************************/

/**
 * @method getStateListRequest : To get state list
 *
 */
export const getStateListRequest = (payload, options, callback) => {
  return {
    type: GET_STATE_LIST.REQUEST,
    payload,
    options,
    callback
  }
}

/**
 * @method getStateListSuccess : To set state list on success
 *
 */
export const getStateListSuccess = (data) => {
  return {
    type: GET_STATE_LIST.SUCCESS,
    data
  }
}

/**
 * @method getStateListFailure : To set error on getting state list
 *
 */
export const getStateListFailure = (error) => {
  return {
    type: GET_STATE_LIST.FAILURE,
    error
  }
}

/** GET_COUNTY_LIST **/
/****************************************************/

/**
 * @method getCountyListRequest : To get state list
 *
 */
export const getCountyListRequest = (payload, callback) => {
  return {
    type: GET_COUNTY_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_REGION_LIST_REQUEST **/
/****************************************************/

/**
 * @method getRegionListRequest : To get region list
 *
 */
export const getRegionListRequest = (payload, callback) => {
  return {
    type: GET_REGION_LIST_REQUEST,
    payload,
    callback
  }
}
/****************************************************/
/** GET_CITY_BY_STATE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCityByStatesListRequest : To get city list
 *
 */
export const getCityByStatesListRequest = (payload, callback) => {
  return {
    type: GET_CITY_BY_STATE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ZIPCODE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getZipCodeListRequest : To get zipcode list
 *
 */
export const getZipCodeListRequest = (payload, callback) => {
  return {
    type: GET_ZIPCODE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_LOCATION_UNIT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getLocationUnitListRequest : To get unit/Apt based on location list
 *
 */
export const getLocationUnitListRequest = (payload, callback) => {
  return {
    type: GET_LOCATION_UNIT_LIST_REQUEST,
    payload,
    callback
  }
}
/****************************************************/
/** GET_SUB_REGION_LIST_REQUEST **/
/****************************************************/

/**
 * @method getSubRegionListRequest : To get sub region list
 *
 */
export const getSubRegionListRequest = (payload, callback) => {
  return {
    type: GET_SUB_REGION_LIST_REQUEST,
    payload,
    callback
  }
}