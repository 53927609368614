import config from "../APIConfig"
import { postDataApi } from "../../../index"

/**
 * @method addActivitiesToGroup
 * 
 * @param data 
 * {
  "wfCampaignSetupChecklistId": 0,
  "groupId": 0,
  "activityIds": [
    0
  ]
}
 * @returns 
 */
export const addActivitiesToGroup = (payload) => {
  const { url } = config.addActivitiesToGroup

  return postDataApi(url(payload), payload)
}

export default addActivitiesToGroup
