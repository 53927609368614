import config from "./APIConfig"
import { putDataApi } from "../index"

const updatePriorityAndStatusColumn = async (data) => {
  const {isPriority, ...payload} = data
  const { url } = config.updatePriorityAndStatusColumn
  return await putDataApi(url(data), payload)
}

export default updatePriorityAndStatusColumn
