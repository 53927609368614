import { useState, useCallback, useEffect, useMemo, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'

import {
  QUICK_SEARCH_KEY,
  TABLE_LIST_KEYS,
  TABLE_VIEW_CUSTOM_MESSAGES
} from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from '@store/actions'
import { getLocaleMessage } from "@utils"
import { TableView, DeleteConfirmationModal } from '@views/components'
import localeMessageWrapper from '@views/components/locale-message'
import { ROW_SELECTION_TYPE } from "@views/components/table-view/ag-data-grid/config"
import { EXPANDABLE_TYPE } from '@views/components/table-view/config'
import { getInitialListState } from '@views/helpers'
import CheckpointSearch from '@views/projects/templates/add-new-template/checkpoints/checkpoints-table/AdvanceSearch'
import { gridFiltersConfig } from "./config"
import { checkIfSearchFiltersApplied } from './helpers'
import { getColumnsConfig } from './TableConfig'

const { ACTIVITIES } = LANGUAGE_CONSTANTS

const initialActivityDetail = {
  stageOfConstruction: '',
  scopeOfWork: '',
  holdProduction: false,
  responsibleSiteRole: '',
  riskLevel: '',
  checkpointName: '',
  systemCategory: '',
  assembly: '',
  assemblyType: [],
  component: [],
  subComponent: [],
  manufacturer: '',
  brand: '',
  activityType: ''
}

export const ChecklistActivitiesContext = createContext()

const ChecklistActivities = ({
  intl,
  isScrollToLoadRequired = true,
  isChildGrid = false,
  tableViewHeaderRequired = true, //todo
  wfCampaignSetupChecklistId,
  isReviewActivity
}) => {
  const dispatch = useDispatch()

  const initialListState = useMemo(() => {
    return getInitialListState()
  }, [])

  const { loader: { isLoading: isPageLoading = false } } = useSelector(state => state)
  const isListRefreshed = useSelector((state) => state.tableActions[TABLE_LIST_KEYS.ACTIVITY_LIST_KEY]?.isListRefreshed || false)

  const { control } = useForm({})

  const [isListReOrdered, setListReOrdered] = useState(false)
  const [isShowBulkActionView, setShowBulkActionView] = useState(false)
  const [isFetching, setFetchingList] = useState(false)
  const [tableData, setTableData] = useState(Object.assign({}, initialListState))
  const [selectedCheckpointList, setSelectedCheckpointList] = useState([])
  const [selectedCheckpointIdList, setSelectedCheckpointIdList] = useState([])
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false)

  const [genericSearch, setGenericSearch] = useState('')
  const [isSearchApplied, setSearchApplied] = useState(false)
  const [isGenericSearchApplied, setGenericSearchApplied] = useState(false)

  const [typingTimeout, setTypingTimeout] = useState(null)

  // Advance Search
  const [isOpenAdvanceSearch, setIsOpenAdvanceSearch] = useState(false)
  const [activitySearchDetail, setActivitySearchDetail] = useState(Object.assign({}, { ...initialActivityDetail }))
  const [appliedGenericSearch, setAppliedGenericSearch] = useState("")

  /**
  * For updating specific row fields to handle on change event
  */
  const [updatedSpecificRowValues, setUpdatedSpecificRowValues] = useState(null)

  //to reset grid datasource
  const [isNewDatasourceRegisterationRequired, setNewDatasourceRegisterationRequired] = useState(false)

  //When rows need to be refreshed with existing filters set isRequiresGridCacheReset = true
  const [isRequiresGridCacheReset, setRequiresGridCacheReset] = useState(false)
 
  const { list, pageNumber, pageSize, totalCount, totalPages } = useMemo(() => tableData, [tableData])

  const handleFetchActivities = useCallback((data, apiCallback) => {
    const {
      isGSFilter = false,
      canApplyFilters = true,
      isAutoAdvanceSearch = false,
      isResetAdvanceSearch = false,
      page,
      size,
      search,
      checkpointName = "",
      advanceSearchDetail = {},
      callback = () => { }
    } = data || {}

    setFetchingList(true)

    let advanceSearch = {}

    const searchFormData = isAutoAdvanceSearch ? advanceSearchDetail : activitySearchDetail

    if (!isResetAdvanceSearch) {
      searchFormData.checkpointName = isGSFilter ? checkpointName : searchFormData.checkpointName

      advanceSearch = {
        stageOfConstruction: searchFormData.stageOfConstruction,
        scopeOfWork: searchFormData.scopeOfWork,
        responsibleSiteRole: searchFormData.responsibleSiteRole?.value,
        riskLevel: searchFormData.riskLevel?.value,
        checkpointName: searchFormData.checkpointName,
        systemCategory: searchFormData.systemCategory,
        brand: searchFormData.brand?.value,
        assembly: searchFormData.assembly,
        component: searchFormData.component,
        subComponent: searchFormData.subComponent,
        manufacturer: searchFormData.manufacturer,
        activityType: searchFormData.activityType?.value,
        assemblyType: searchFormData.assemblyType,
        holdProduction: searchFormData.holdProduction || false
      }
      setSearchApplied(checkIfSearchFiltersApplied(searchFormData))
    } else {
      setSearchApplied(false)
    }

    const searchText = isGSFilter
      ? search
        ? search.trim()
        : ""
      : genericSearch || ""
    const searchVal = canApplyFilters ? searchText : ''
    setGenericSearchApplied(!!searchVal)
    setAppliedGenericSearch(searchVal)
    const payload = {
      isReviewActivity,
      wfCampaignSetupChecklistId,
      pageSize: size || pageSize,
      pageNumber: page || pageNumber,
      genericSearch: searchVal,
      advanceSearch: canApplyFilters ? advanceSearch : ''
    }

    if (isScrollToLoadRequired) {
      if (payload.pageNumber === 1) {
        //To reset existing state on fetching new data for 1st page
        setSelectedCheckpointIdList([])
        setSelectedCheckpointList([])
      }
    } else {
      //To reset already selected activities for paginated list
      setSelectedCheckpointIdList([])
      setSelectedCheckpointList([])
    }

    dispatch(actions.getChecklistChildrenListRequest(payload, (data) => {
      callback()
      if (data) {
        if (apiCallback) {
          apiCallback(data)
        }
        const listData = {
          list: data.items,
          pageSize: data.pageSize,
          pageNumber: data.pageIndex,
          totalCount: data.totalCount,
          totalPages: data.totalPages
        }
        setTableData(listData)
      }
      setFetchingList(false)
      setTypingTimeout(null)
    }))
  }, [
    tableData,
    pageNumber,
    pageSize,
    wfCampaignSetupChecklistId,
    activitySearchDetail,
    genericSearch
  ])

  useEffect(() => {
    if (!isScrollToLoadRequired) {
      handleFetchActivities({ page: 1 })
    }
  }, [])

  useEffect(() => {
    if (isListRefreshed) {
      handleFetchActivities()
      dispatch(actions.refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: false }))
    }
  }, [isListRefreshed])

  useEffect(() => {
    if (isListReOrdered) {
      handleFetchActivities({ page: isScrollToLoadRequired ? 1 : undefined })
      setListReOrdered(false)
    }
  }, [isListReOrdered, isScrollToLoadRequired])

  const handlePagination = useCallback(
    (data, callback) => {
      if (isScrollToLoadRequired) {
        handleFetchActivities(data, callback)
      } else {
        handleFetchActivities({ page: data.selected + 1 })
      }
    },
    [isScrollToLoadRequired, handleFetchActivities]
  )

  const handlePerPageRecords = useCallback(
    (event) => {
      const size = parseInt(event.target.value)
      /** To reset current state before fetching data based on selected page size */
      setTableData(prevState => (
        Object.assign({}, {
          ...initialListState,
          pageSize: prevState.pageSize
        }
        )))
      handleFetchActivities({ page: 1, size })
    },
    [handleFetchActivities]
  )

  const handleResetSelectedRows = useCallback(() => {
    setSelectedCheckpointIdList([])
    setSelectedCheckpointList([])
  }, [])

  const handleSelectedRowsChange = useCallback((selectedRows) => {
    if (isScrollToLoadRequired) {
      setShowBulkActionView(!!selectedRows.length)
      setSelectedCheckpointIdList(selectedRows.map(row => row.wfCampaignSetupActivityId))
      setSelectedCheckpointList(selectedRows)
    } else {
      setShowBulkActionView(!!selectedRows.length)
      setSelectedCheckpointList(selectedRows)
      setSelectedCheckpointIdList(selectedRows.map(row => row.wfCampaignSetupActivityId))
    }
  }, [isScrollToLoadRequired])

  const handleReorderElements = (prev_node, center_node, next_node, type, objIndex) => {
    let prev = prev_node, center = center_node, next = next_node
    let startElementIndex = 0
    let reorderedElements = []
    if (type === 'down') {
      startElementIndex = objIndex
      prev = { ...prev_node, orderIndex: center_node.orderIndex }
      center = { ...center_node, orderIndex: prev_node.orderIndex }
      if (objIndex < list.length - 2) {
        reorderedElements = [prev, center, next]
      } else {
        reorderedElements = [prev, center]
      }
    } else {
      startElementIndex = objIndex > 1 ? objIndex - 2 : objIndex - 1
      center = { ...center_node, orderIndex: next_node.orderIndex }
      next = { ...next_node, orderIndex: center_node.orderIndex }
      if (objIndex > 1) {
        reorderedElements = [prev, center, next]
      } else {
        reorderedElements = [center, next]
      }
    }
    return [startElementIndex, reorderedElements.length, ...reorderedElements]
  }

  const handleToggleDeleteModal = useCallback(() => {
    setOpenDeleteModal((prevState) => !prevState)
  }, [])

  const handleBulkDelete = useCallback(() => {
    dispatch(actions.deleteCampaignServiceChecklistCheckpoint(selectedCheckpointIdList, () => {
      setShowBulkActionView(false)
      handleResetSelectedRows()
      setOpenDeleteModal(false)
      //todo: check if list refreshed after bulk delete
      if (isChildGrid) {
        dispatch(actions.refreshTableList({ listKey: TABLE_LIST_KEYS.WORKFLOW_CHECKLIST_KEY, value: true }))
      } else {
        dispatch(actions.refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: true }))
      }
    }))
  }, [selectedCheckpointIdList, handleResetSelectedRows])

  const handleConfirmDelete = useCallback(() => {
    handleBulkDelete()
  }, [handleBulkDelete])

  const handleRowReorder = useCallback((event) => {
    if (event.type === "rowDragEnd") {
      const draggedCheckpointId = Number(event.node?.id || "")
      const updatedList = event.api.getRenderedNodes().map(({ data }) => data)
      const selectedActivityIndex = updatedList.findIndex(i => i.checkpointId === draggedCheckpointId)

      if (selectedActivityIndex !== -1) {
        const draggedActivity = updatedList[selectedActivityIndex]

        if (isScrollToLoadRequired) {
          const draggedOverNodeId = Number(event.overNode?.id || "")
          const overIndexInRenderedNodes = updatedList.findIndex(i => i.checkpointId === draggedOverNodeId)

          //Dragged item removed from list
          updatedList.splice(selectedActivityIndex, 1)
          if (draggedOverNodeId) {
            //Drag over item index after removing Dragged item from list
            const draggedOverItemIndex = updatedList.findIndex(i => i.checkpointId === draggedOverNodeId)
            const draggedNode = event.node.data

            if (draggedOverItemIndex !== -1) {
              //Dragged item added at draggedOverItemPosition
              updatedList.splice(selectedActivityIndex >= overIndexInRenderedNodes ? draggedOverItemIndex : draggedOverItemIndex + 1, 0, draggedNode)
              
              const reorderedList = [...updatedList]
              //Dragged item index after sorting list, in required order, to use values for API payload
              const selectedItemIndex = reorderedList.findIndex(i => i.checkpointId === draggedCheckpointId)

              const prevLineItem = reorderedList[selectedItemIndex - 1]
              const nextLineItem = reorderedList[selectedItemIndex + 1]

              const payload = {
                wfCampaignSetupActivityId: draggedActivity.wfCampaignSetupActivityId,
                prev_node:  prevLineItem?.wfCampaignSetupActivityId || null,
                next_node: nextLineItem?.wfCampaignSetupActivityId || null
              }

              dispatch(actions.updateCampaignChecklistOrder(payload, ((res) => {
                if (res) {
                  setRequiresGridCacheReset(true)
                }
              })))
            }
          }
        } else {
          const prevActivity = updatedList[selectedActivityIndex - 1]
          const nextActivity = updatedList[selectedActivityIndex + 1]

          const payload = {
            wfCampaignSetupActivityId: draggedActivity.wfCampaignSetupActivityId,
            prev_node:  prevActivity?.wfCampaignSetupActivityId || null,
            next_node: nextActivity?.wfCampaignSetupActivityId || null
          }

          dispatch(actions.updateCampaignChecklistOrder(payload, ((res) => {
            if (res) {
              setListReOrdered(true)
            }
          })))
        }
      }     
    }
  }, [isScrollToLoadRequired, list, handleReorderElements])

  const columns = useMemo(() => {
    return getColumnsConfig({ intl, isChildGrid })
  }, [])

  const { checkpointList: checkpointListCustomMessages } = TABLE_VIEW_CUSTOM_MESSAGES

  const handleChangeActivityDetails = useCallback(
    (key, value, callback = () => { }) => {
      const updateActivityDetail = Object.assign({}, activitySearchDetail)
      if (key === "assembly") {
        updateActivityDetail["assemblyType"] = []
        updateActivityDetail["component"] = []
        updateActivityDetail["subComponent"] = []
      }
      if (key === "assemblyType") {
        updateActivityDetail["component"] = []
        updateActivityDetail["subComponent"] = []
      }
      if (key === "component") updateActivityDetail["subComponent"] = []

      if (key === "checkpointName") {
        setGenericSearch(value)
      }
      updateActivityDetail[key] = value
      setActivitySearchDetail(updateActivityDetail)
      callback(updateActivityDetail)
    },
    [activitySearchDetail]
  )

  const handleResetSearchDetail = useCallback(() => {
    setActivitySearchDetail(Object.assign({}, initialActivityDetail))
    if (!isGenericSearchApplied) {
      setGenericSearch("")
    }
    if (isSearchApplied) {
      setTableData(prevState => (
        Object.assign({}, {
          ...initialListState,
          pageSize: prevState.pageSize
        }
        )))
      handleFetchActivities({ page: 1, isResetAdvanceSearch: true })
    }
  }, [isGenericSearchApplied, isSearchApplied, handleFetchActivities])

  const handleAdvancedSearch = useCallback((isAutoAdvanceSearch, data) => {
    const isAutoSearch = isAutoAdvanceSearch && !!data
    if (checkIfSearchFiltersApplied(isAutoSearch ? data : activitySearchDetail) || isSearchApplied) {
      setTableData(prevState => (
        Object.assign({}, {
          ...initialListState,
          pageSize: prevState.pageSize
        }
        )))
      const autoSearchConfig = isAutoSearch ? { isAutoAdvanceSearch, advanceSearchDetail: data } : {}
      handleFetchActivities({ page: 1, ...autoSearchConfig })
    }
    if (!isAutoSearch) {
      setIsOpenAdvanceSearch(false)
    }
  }, [isSearchApplied, activitySearchDetail, handleFetchActivities])

  const handleToggleAdvanceSearch = useCallback(
    (event) => {
      if (event.target.id === "close-search" || isOpenAdvanceSearch) {
        if (!!activitySearchDetail.checkpointName) {
          setGenericSearch(activitySearchDetail.checkpointName)
        }
        setIsOpenAdvanceSearch(!isOpenAdvanceSearch)
      } else {
        if (genericSearch !== '') {
          setActivitySearchDetail(prevSearch => (
            {
              ...prevSearch,
              checkpointName: genericSearch
            })
          )
          setGenericSearch('')
          setGenericSearchApplied(false)
          setSearchApplied(true)
        }
        setIsOpenAdvanceSearch(!isOpenAdvanceSearch)
      }
    },
    [isOpenAdvanceSearch, genericSearch, activitySearchDetail]
  )

  const handleFilter = useCallback(
    (value) => {
      if (typingTimeout) {
        clearTimeout(typingTimeout)
      }

      setTypingTimeout(
        setTimeout(() => {
          setTableData(prevState => (
            Object.assign({}, {
              ...initialListState,
              pageSize: prevState.pageSize
            }
            )))
          handleFetchActivities({ page: 1, search: value, isGSFilter: true, checkpointName: value })
        }, 1000)
      )
    },
    [genericSearch, typingTimeout, handleFetchActivities]
  )

  // ** Function to handle change of generic search filter
  const handleChangeGenericSearch = useCallback(
    (e) => {
      const { value } = e.target
      if (isOpenAdvanceSearch) {
        setActivitySearchDetail(prevSearch => (
          {
            ...prevSearch,
            checkpointName: value
          }
        ))
      }
      setGenericSearch(value)
      const lengthOfSearch = value.trim().length
      if (
        (!!lengthOfSearch && lengthOfSearch >= 3) ||
        (!lengthOfSearch && !!genericSearch.length && isGenericSearchApplied)
      ) {
        handleFilter(value)
      }
    },
    [genericSearch, isGenericSearchApplied, isOpenAdvanceSearch, handleFilter]
  )

  // ** Function to get search results when enter key is pressed or user select some value
  // from dropdown in advance search form
  const handleCustomAdvanceSearch = useCallback(
    (event, isVMBasedSearch, data) => {
      if (event?.keyCode === 13) {
        handleAdvancedSearch()
      } else if (isVMBasedSearch) {
        handleAdvancedSearch(true, data)
      }
    },
    [handleAdvancedSearch]
  )

  const noDataMessage = useMemo(() => {
    return (isPageLoading || isFetching)
      ? checkpointListCustomMessages.loading
      : isSearchApplied || isGenericSearchApplied
        ? checkpointListCustomMessages.noDataForFilters
        : checkpointListCustomMessages.noData
  }, [checkpointListCustomMessages, isPageLoading, isFetching, isGenericSearchApplied, isSearchApplied])

  const handleRemoveFilter = useCallback((key, data) => {
    if (key === QUICK_SEARCH_KEY) {
      setGenericSearch("")
      setTableData(prevState => (
        Object.assign({}, {
          ...initialListState,
          pageSize: prevState.pageSize
        }
        )))
      handleFetchActivities({ page: 1, search: "", isGSFilter: true, checkpointName: activitySearchDetail.checkpointName })
    } else {
      setActivitySearchDetail(data)
      handleAdvancedSearch(true, data)
    }
  }, [handleAdvancedSearch, handleFetchActivities])

  const gridFilterProps = useMemo(() => {
    return {
      isShow: !isOpenAdvanceSearch,
      isSearchApplied,
      filtersConfig: gridFiltersConfig,
      filters: activitySearchDetail,
      initialFilterState: initialActivityDetail,
      genericSearchFilter: appliedGenericSearch,
      onRemoveFilter: handleRemoveFilter
    }
  }, [appliedGenericSearch, isGenericSearchApplied, isSearchApplied, isOpenAdvanceSearch, activitySearchDetail, handleRemoveFilter])

  const handleChangeRowValues = useCallback((checkpointId, value, key) => {
    const updatedRowData = {}
    if (key === 'isRequired') {
      updatedRowData.isRequired = value
    } else {
      updatedRowData.isCopyAllowed = value
    }
    setUpdatedSpecificRowValues({
      checkpointId,
      updatedValue: updatedRowData
    })
  }, [])

  const contextValue = useMemo(() => {
    return {
      onChange: handleChangeRowValues
    }
  }, [handleChangeRowValues])

  const tableComponentProps = useMemo(() => {
    return {
      isNewDatasourceRegisterationRequired,
      onResetNewDatasourceRegisterationRequired: setNewDatasourceRegisterationRequired,
      isShowAddButton: false,
      updatedSpecificRowValues,
      rowSelection: ROW_SELECTION_TYPE.MULTIPLE,
      isResetSelectedOnScroll: false,
      isRowDraggable: !isScrollToLoadRequired,
      onRowDragEnd: handleRowReorder,
      isResetCacheRequired: isRequiresGridCacheReset,
      onResetCacheRequired: setRequiresGridCacheReset
    }
  }, [isNewDatasourceRegisterationRequired, isRequiresGridCacheReset, isScrollToLoadRequired, updatedSpecificRowValues, handleRowReorder])

  const AdvanceSearchCard = () => {
    if (!isOpenAdvanceSearch) {
      return null
    }

    return (
      <CheckpointSearch
        checkpointCollection={activitySearchDetail}
        control={control}
        isViewMode
        onChangeCheckpointCollection={handleChangeActivityDetails}
        onResetSearchDetail={handleResetSearchDetail}
        onSearch={handleAdvancedSearch}
        onToggle={handleToggleAdvanceSearch}
        onCustomAdvanceSearch={handleCustomAdvanceSearch}
      />
    )
  }

  return (
    <ChecklistActivitiesContext.Provider value={contextValue}>
      <div className='workflow-assign-chklist'>
        {AdvanceSearchCard()}
        <TableView
          className="nt-rdt-list"
          keyField={"checkpointId"}
          isNewListTheme
          showAgGrid
          tableComponentProps={tableComponentProps}
          gridFilterProps={gridFilterProps}
          columns={columns}
          data={tableData.list}
          expandableRows
          expandableRowsType={EXPANDABLE_TYPE.CHECKPOINTS}
          scrollToLoadRequired={isScrollToLoadRequired}
          pagination={!isScrollToLoadRequired}
          selectableRows
          selectedRows={selectedCheckpointList}
          isLoading={isFetching}
          noDataMessage={noDataMessage}
          noDataMessageForSubTable={getLocaleMessage(intl, ACTIVITIES.NO_ACTIVITY_DETAIL_MSG)}
          pageNumber={tableData.pageNumber}
          pageSize={tableData.pageSize}
          isShowBulkActions={isShowBulkActionView}
          onBulkDelete={handleToggleDeleteModal}
          tableViewHeaderRequired={tableViewHeaderRequired}
          title={getLocaleMessage(intl, ACTIVITIES.ACTIVITIES)}
          totalCount={totalCount}
          genericSearch={genericSearch}
          onChangeSearchFilter={handleChangeGenericSearch}
          onToggleAdvanceSearch={handleToggleAdvanceSearch}
          totalPages={totalPages}
          onPerPageRecords={handlePerPageRecords}
          onPagination={handlePagination}
          onSelectedRowsChange={handleSelectedRowsChange}
        />
        <DeleteConfirmationModal
          open={isOpenDeleteModal}
          onToggleModal={handleToggleDeleteModal}
          onConfirmDeleteRecord={handleConfirmDelete}
          type={ACTIVITIES.ACTIVITY}
        />
      </div>
    </ChecklistActivitiesContext.Provider>
  )
}

export default localeMessageWrapper(ChecklistActivities)
