import config from "./APIConfig"
import { putDataApi } from "../index"

/**
 * @method updateReassignFailedInspection
 * This api is for updating contact to reassign
 * [Reassign Failed inspection correction]
 * 
 * @param {object} data 
 * 
 * {
 *  roleId: 0
 *  contactId: 0,
 *  actionId: 0
 * }
 * 
 */
export const updateReassignFailedInspection = async (data) => {
  const { url } = config.updateReassignFailedInspection
  return await putDataApi(url({actionId:data.actionId, roleId:data.roleId, contactId:data.contactId}), data)
}

export default updateReassignFailedInspection
