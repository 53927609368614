import config from "./APIConfig"
import { postDataApi } from "../index"

/**
 * @method createUserManagementRole
 * @param {object} data
 *
 */

const createUserManagementRole = (data) => {
  const { url } = config.createUserManagementRole

  return postDataApi(url, data)
}

export default createUserManagementRole
