import { SCHEDULE_TASK_TYPE, SCHEDULE_SELF_ASSIGN_TYPE, SCHEDULE_AUTO_ASSIGN_TYPE, ELIGIBLE_REASSIGNMENT_TYPE } from "@views/campaigns/add-new-campaign/workflow/block/schedule-task/config"
import config from "./APIConfig"
import { putDataApi } from "../../index"

/**
 * @method updateBlockScheduleTask
 * @param {object} data 
 * 
 * {
 *  wfCampaignSetupId: "",
 *  scheduleMethod: "",
 *  assignmentValue: []
 * }
 * 
 *  API payload:-
 * 
 * {
 * "wfCampaignSetupId": 0,
 * "wfbSchedulingMethodId": 0,
 * "contactId": 0,
 *  "siteRoleIds": [
 *  {
 *   "siteRoleId": 0
 *  }
 * ],
 * "scheduleSelfAssignTypeId": 1,
 *   "contactGroupIds": [
 * {
 *       "contactGroupId": 0
 * }]
 * }
 * 
 */

export const updateBlockScheduleTask = async (data) => {
  const { url } = config.updateBlockScheduleTask
  let assignmentPayload = {}
  const { scheduleMethod, wfCampaignSetupId, selfAssignTypeId, assignmentValue, autoAssignTypeId, isSharedCampaign, eligibleReassignmentId, reassignmentValue } = data
  const valueType = isSharedCampaign ? "value" : "sitePartyRoleId"

  if (scheduleMethod === SCHEDULE_TASK_TYPE.AUTO_ASSIGN) {
    assignmentPayload = {
      scheduleAutoAssignTypeId: Number(autoAssignTypeId),
      contactId: !!data.groupContactValue ? data.groupContactValue : autoAssignTypeId === SCHEDULE_AUTO_ASSIGN_TYPE.CONTACT ? Number(assignmentValue) : 0,
      sitePartyRoleIds: (autoAssignTypeId === SCHEDULE_AUTO_ASSIGN_TYPE.SITE_ROLE || autoAssignTypeId === SCHEDULE_AUTO_ASSIGN_TYPE.ORGANIZATION_ROLE) ? [{ sitePartyRoleId: Number(assignmentValue) }] : null,
      contactGroupIds: autoAssignTypeId === SCHEDULE_AUTO_ASSIGN_TYPE.CONTACT_GROUP ? [{ contactGroupId: assignmentValue }] : null
    }
    if (autoAssignTypeId === SCHEDULE_AUTO_ASSIGN_TYPE.PRIMARY_CONTACT || autoAssignTypeId === SCHEDULE_AUTO_ASSIGN_TYPE.CONTACT) {
      assignmentPayload.eligibleReassignmentTypeId = Number(eligibleReassignmentId) || 0
      if (eligibleReassignmentId === ELIGIBLE_REASSIGNMENT_TYPE.CONTACT_GROUP) {
        assignmentPayload.reassignContactGroupId = Number(reassignmentValue) || 0
      } else if (eligibleReassignmentId === ELIGIBLE_REASSIGNMENT_TYPE.SITE_ROLE || eligibleReassignmentId === ELIGIBLE_REASSIGNMENT_TYPE.ORGANIZATION_ROLE) {
        assignmentPayload.reassignSitePartyRoleId = Number(reassignmentValue) || 0
      }
    }
  } else if (scheduleMethod === SCHEDULE_TASK_TYPE.SELF_ASSIGN) {
    assignmentPayload = {
      sitePartyRoleIds: selfAssignTypeId === SCHEDULE_SELF_ASSIGN_TYPE.SITE_ROLE ? assignmentValue.map(siteRole => ({ sitePartyRoleId: siteRole[valueType] })) : null,
      scheduleSelfAssignTypeId: Number(selfAssignTypeId),
      contactGroupIds: selfAssignTypeId === SCHEDULE_SELF_ASSIGN_TYPE.GROUP ? [{ contactGroupId: assignmentValue }] : null
    }
  } else if (scheduleMethod === SCHEDULE_TASK_TYPE.MANUAL) {
    assignmentPayload = {
      contactGroupIds: assignmentValue.map(contactGroup => ({ contactGroupId: contactGroup.value }))
    }
  }
  const payload = {
    wfCampaignSetupId: Number(wfCampaignSetupId),
    wfbSchedulingMethodId: Number(scheduleMethod),
    ...assignmentPayload
  }
  return await putDataApi(url(wfCampaignSetupId), payload, null, { isApiNeedsToHandleErrors: false })
}

export default updateBlockScheduleTask
