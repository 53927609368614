export const SET_CAMPAIGN_SERVICE_MODE = 'SET_CAMPAIGN_SERVICE_MODE'
export const GET_CAMPAIGN_SERVICE_DETAIL = 'GET_CAMPAIGN_SERVICE_DETAIL'
export const UPDATE_CAMPAIGN_SERVICE = "UPDATE_CAMPAIGN_SERVICE"
export const GET_CAMPAIGN_SERVICE_BASIC_DETAIL = 'GET_CAMPAIGN_SERVICE_BASIC_DETAIL'

/****************************************************/
/** SET_CAMPAIGN_SERVICE_MODE **/
/****************************************************/

/**
 * @method setCampaignServiceMode :  To set campaign service edit mode
 *
 */
export const setCampaignServiceMode = (payload) => {
  return {
    type: SET_CAMPAIGN_SERVICE_MODE,
    payload
  }
}

/****************************************************/
/** GET_CAMPAIGN_SERVICE_DETAIL **/
/****************************************************/

/**
 * @method getCampaignServiceDetail :  To get campaign service detail
 *
 */
export const getCampaignServiceDetail = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_SERVICE_DETAIL,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_SERVICE_BASIC_DETAIL **/
/****************************************************/

/**
 * @method getCampaignServiceBasicDetail :  To get campaign service basic detail
 *
 */
 export const getCampaignServiceBasicDetail = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_SERVICE_BASIC_DETAIL,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_CAMPAIGN_SERVICE **/
/****************************************************/

/**
 * @method updateCampaignService :  To update detail of campaign service
 *
 */
export const updateCampaignService = (payload, callback) => {
  return {
    type: UPDATE_CAMPAIGN_SERVICE,
    payload,
    callback
  }
}
