// ** Third Party Components
import { useCallback, useEffect, useState, useMemo } from 'react'
import { X } from 'react-feather'
import { useForm } from 'react-hook-form'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  InputGroup,
  Input,
  Col
} from 'reactstrap'
import { useDispatch } from "react-redux"

// ** Styles
import '@styles/react/libs/flatpickr/flatpickr.scss'

import { DEFAULT_VM_PAGE_SIZE } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { getLocaleMessage, validatePhoneNumber } from "@utils"
import { ErrorMessage, OrganizationSearch, ReactSelect, CustomLabel, SiteRoleSearch } from '@views/components'
import LocaleMessageWrapper from "@views/components/locale-message"

import { validateContactDetails } from "../helpers"

const defaultValues = {
  phoneNumber: '',
  ReactSelect: null,
  reactFlatpickr: null
}

const AddNewModal = ({
  intl,
  isEditMode = false,
  isModalCentered = false,
  data = {},
  orgDetail,
  open,
  onToggleModal,
  updateContactCallback = () => { },
  onAddContact = () => { },
  onToggleDeleteContact = () => { },
  onFetchContacts = () => { },
  onUpdateContact = () => { },
  onGetOrganizationList = () => { }
}) => {
  const orgId = parseInt(orgDetail?.id || data?.organizationID || 0)

  const dispatch = useDispatch()

  const [contactDetail, setContactDetail] = useState({
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phoneNumber: '',
    sitePartyRoleId: ''
  })
  const [organizationID, setOrganizationID] = useState(parseInt(orgDetail?.id || data?.organizationID || 0))
  const [originalOrganizationId, setOriginalOrganizationId] = useState(0)
  const [accountAccessId, setAccountAccessId] = useState('')

  const [errors, setErrors] = useState({})
  const [orgList, setSetOrgList] = useState([])
  const [fetchedOrgDetail, setFetchedOrgDetail] = useState(null)
  const [accountAccess, setAccountAccessList] = useState([])
  const [defaultOptions] = useState([])

  const handleGetOrgList = useCallback(({ organizationName, pageNumber, pageSize }) => {
    onGetOrganizationList({
      isLoadingRequired: false,
      pageNumber,
      pageSize,
      advanceSearch: {
        organizationName
      }
    }, (response) => {
      if (response && !!response.items.length) {
        // if (isEditMode && !orgDetail?.name && organizationName) {
        //   setFetchedOrgDetail(response.items[0])
        //   setOrganizationID(response.items[0].organizationId)
        // }
        setSetOrgList(response.items)
      } else {
        setSetOrgList([])
      }
    })
  }, [isEditMode, data, orgDetail])

  const handleFetchAccountAccessVM = useCallback((callback = () => { }) => {
    dispatch(
      actions.getCreateAccountVMRequest(
        (list) => {
          if (list.length) {
            setAccountAccessList(list)
            callback(list)
          }
        }
      )
    )
  }, [])

  useEffect(() => {
    if (isEditMode) {
      const name = (data.name ? data.name.trim() : '').split(' ')
      const totalParts = name.length
      const firstName = name.slice(0, totalParts - 1).join(' ')
      const lastName = name[totalParts - 1]
      const updatedData = {
        ...contactDetail,
        firstName,
        lastName,
        title: data.jobTitle || '',
        email: data.email || '',
        phoneNumber: data.phone?.replace("+1", '') || '',
        sitePartyRoleId: data.sitePartyRoleId,
        sitePartyRoleName: data.sitePartyRoleName
      }

      setContactDetail(updatedData)

      setFetchedOrgDetail({
        organizationName: data.organnizationName,
        organizationId: data.organizationId
      })
      setOrganizationID(data.organizationId)
      setOriginalOrganizationId(data.organizationId)
      handleFetchAccountAccessVM((list) => {
        const accountAccessVal = data.accountAccess?.split('-')[0]?.trim() || ''
        const selected = list.find(item => item.text === accountAccessVal)
        setAccountAccessId(selected?.value || '')
      })
    } else {
      handleFetchAccountAccessVM()
    }
  }, [])

  useEffect(() => {
    //Todo:
    if (!orgDetail?.name) {
      //Todo:
      if (isEditMode) {
        handleGetOrgList({
          pageNumber: 1,
          pageSize: DEFAULT_VM_PAGE_SIZE
        })
        // handleGetOrgList({ organizationName: data.organnizationName })
      } else {
        handleGetOrgList({
          pageNumber: 1,
          pageSize: DEFAULT_VM_PAGE_SIZE
        })
      }
    }
  }, [])

  const handleResetState = useCallback(() => {
    setContactDetail({
      firstName: '',
      lastName: '',
      title: '',
      email: '',
      phoneNumber: '',
      sitePartyRoleId: ''
    })
    setOrganizationID(0)
    setOriginalOrganizationId(0)
    setFetchedOrgDetail(null)
    setAccountAccessId('')
    setErrors({})
  }, [])

  const handleToggleModal = useCallback(() => {
    handleResetState()
    onToggleModal()
  }, [handleResetState, onToggleModal])

  const handleUpdateContactDetail = useCallback((key, value, name = "", isInitialLoad = false) => {

    if (!!Object.keys(errors).length) {
      setErrors({})
    }

    if (key === "phoneNumber" && value.length > 10) {
      return
    }

    if (key === "organizationID" && !isInitialLoad) {
      setOrganizationID(value)
      /**
       * To fetch account access list based on selected organization
       */
      setAccountAccessId('')
    } else if (key === "accountAccessId") {
      setAccountAccessId(value)
    } else {
      setContactDetail((prev) => ({ ...prev, [key]: value }))
    }
  }, [contactDetail, errors])

  const handleAddContact = useCallback(() => {
    const { errors } = validateContactDetails({ ...contactDetail, organizationID, accountAccessId })
    if (!!Object.keys(errors).length) {
      setErrors(errors)
    } else {
      if (isEditMode) {
        onUpdateContact({
          ...contactDetail,
          contactId: data.contactId,
          organizationId: orgId || organizationID,
          accountAccessId: Number(accountAccessId),
          originalOrganizationId,
          phoneNumber: `+1${contactDetail.phoneNumber}`
        }, () => {
          onFetchContacts(1, null, null, orgDetail?.name || '')
          handleToggleModal()
        })
      } else {
        const payload = {
          ...contactDetail,
          organizationID: orgId || organizationID,
          accountAccessId: Number(accountAccessId),
          phoneNumber: `+1${contactDetail.phoneNumber}`
        }
        onAddContact(payload, (result) => {
          if (result) {
            updateContactCallback(result, payload)
          }
          onFetchContacts(1, null, null, orgDetail?.name || '')
          handleToggleModal()
        })

      }
    }
  }, [data, contactDetail, organizationID, accountAccessId, onAddContact, onUpdateContact, handleToggleModal, updateContactCallback])

  const handleDeleteContact = useCallback(() => {
    onToggleDeleteContact()
  }, [onToggleDeleteContact])

  // ** State
  const { control } = useForm({ defaultValues })

  // ** Custom close btn
  const CloseBtn = <X className='cursor-pointer' size={15} onClick={handleToggleModal} />

  const tablePropsConfig = useMemo(() => {
    return {
      menuPortalTarget: document.body,
      menuPosition: 'fixed',
      styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) }
    }
  }, [])

  return (
    <Modal
      isOpen={open}
      className={`sidebar-sm ${isModalCentered ? 'modal-dialog-centered all-modal modal-dialog' : ''}`}
      modalClassName={isModalCentered ? '' : 'modal-slide-in'}
      contentClassName='p-0'
    >
      <ModalHeader className='mb-1' toggle={handleToggleModal} close={CloseBtn} tag='div'>
        <h5 className='modal-title'>{isEditMode ? `${getLocaleMessage(intl, LANGUAGE_CONSTANTS.CONTACTS.EDIT_CONTACT)}`
          : `${getLocaleMessage(intl, LANGUAGE_CONSTANTS.CONTACTS.ADD_CONTACT)}`}</h5>
      </ModalHeader>
      <ModalBody className='flex-grow-1'>
        <FormGroup>
          <CustomLabel title={LANGUAGE_CONSTANTS.CONTACTS.NAME} forValue='name' required />
          <div className="d-flex">
            <InputGroup>
              <Input
                id='first_name'
                className="mr-1"
                placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.CONTACTS.FIRST_NAME)}
                value={contactDetail.firstName}
                onChange={(event) => handleUpdateContactDetail('firstName', event.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <Input
                id='last_name'
                placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.CONTACTS.LAST_NAME)}
                value={contactDetail.lastName}
                onChange={(event) => handleUpdateContactDetail('lastName', event.target.value)}
              />
            </InputGroup>
          </div>
        </FormGroup>
        <ErrorMessage isShow={!!errors['firstName'] || !!errors['lastName']} message={errors['firstName'] || errors['lastName']} />
        <Col className='mb-1' sm={12} md={12} xl={12}>
              <SiteRoleSearch
                id={"sitePartyRoleId"}
                isRequired
                isOptionGridDropdown={false}
                errors={errors}
                value={contactDetail.sitePartyRoleId}
                label={getLocaleMessage(intl, LANGUAGE_CONSTANTS.CONTACTS.ORGANIZATION_ROLE)}
                placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.CONTACTS.ORGANIZATION_ROLE)}
                defaultOptions={defaultOptions}
                isContactSitePartyRole
                isEditMode={isEditMode}
                initialLabel={isEditMode ? data.sitePartyRoleName : ''}
                initialValue={isEditMode ? data.sitePartyRoleId : ''}
                labelType="text"
                valueType="value"
                getOptionLabel={(option) => option.text}
                getOptionValue={(option) => option.value}
                onSelect={(id, value) => handleUpdateContactDetail(id, value)}
              />
            </Col>
        <FormGroup>
          <CustomLabel title={LANGUAGE_CONSTANTS.CONTACTS.TITLE} forValue='title' />
          <InputGroup>
            <Input
              id='job_title'
              placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.CONTACTS.JOB_TITLE)}
              value={contactDetail.title}
              onChange={(event) => handleUpdateContactDetail('title', event.target.value)} />
          </InputGroup>
        </FormGroup>
        {!!orgDetail?.name ? (
          <FormGroup className={"mb-1 organization-select"}>
            <>
              <CustomLabel title={LANGUAGE_CONSTANTS.CONTACTS.ORGANIZATION} forValue="email" required />
              <InputGroup>
                <Input
                  type="email"
                  id="organization"
                  placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.CONTACTS.ORGANIZATION)}
                  value={orgDetail?.name || organizationID || ""}
                  disabled={orgDetail?.name}
                  onChange={(event) => handleUpdateContactDetail(
                    "organizationID",
                    event.target.value
                  )
                  }
                />
              </InputGroup>
            </>
            <ErrorMessage
              isShow={!!errors["organizationID"]}
              message={errors["organizationID"]}
            />
          </FormGroup>
        ) : (
          <FormGroup className="organizationSearch">
            <OrganizationSearch
              isWrapperStyleRequired={false}
              defaultOptions={orgList}
              errors={errors}
              id="organizationID"
              isEditMode={isEditMode}
              label={getLocaleMessage(intl, LANGUAGE_CONSTANTS.CONTACTS.ORGANIZATION)}
              asyncInitialValue={!!fetchedOrgDetail}
              initialValue={fetchedOrgDetail?.organizationId || ''}
              value={organizationID || ""}
              onSelect={handleUpdateContactDetail}
            />
          </FormGroup>
        )}
        <FormGroup>
          <CustomLabel title={LANGUAGE_CONSTANTS.CONTACTS.EMAIL_ADDRESS} forValue="email" required />
          <InputGroup>
            <Input
              type='email'
              id='email_address'
              placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.CONTACTS.EMAIL_ADDRESS)}
              value={contactDetail.email}
              disabled={isEditMode}
              onChange={(event) => handleUpdateContactDetail('email', event.target.value)} />
          </InputGroup>
        </FormGroup>
        <ErrorMessage isShow={!!errors['email']} message={errors['email']} />
        <FormGroup>
          <CustomLabel title={LANGUAGE_CONSTANTS.ADDRESS.PHONE_NUMBER} forValue="organization" required />
          <InputGroup>
            <div className="phone-number-input">
              <Input
                value={"+1"}
                disabled
                className="phone-country-code"
                onChange={() => { }}
              />
            </div>
            <Input
              type='number'
              id='phone_number'
              placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.ADDRESS.PHONE_NUMBER)}
              value={contactDetail.phoneNumber}
              onChange={(event) => handleUpdateContactDetail('phoneNumber', event.target.value)}
              onKeyDown={validatePhoneNumber}
            />
          </InputGroup>
        </FormGroup>
        <ErrorMessage isShow={!!errors['phoneNumber']} message={errors['phoneNumber']} />
        <FormGroup>
          <ReactSelect
            isClearable
            id="account_access"
            name="account_access"
            isRequired={true}
            label={getLocaleMessage(intl, LANGUAGE_CONSTANTS.CONTACTS.ACCOUNT_ACCESS)}
            placeHolder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.CONTACTS.SELECT_ACCOUNT_ACCESS_TYPE)}
            control={control}
            value={accountAccessId}
            options={accountAccess}
            onSelect={(selected) => handleUpdateContactDetail('accountAccessId', selected ? selected.value : '')}
            {...tablePropsConfig}
          />
          <ErrorMessage isShow={!!errors['accountAccessId']} message={errors['accountAccessId']} />
        </FormGroup>
        <FormGroup className={`d-flex ${isModalCentered ? 'justify-content-end' : ''}`}>
          <Button className=" add-button mr-1" onClick={handleAddContact}>
            {getLocaleMessage(intl, isEditMode ? LANGUAGE_CONSTANTS.UPDATE : LANGUAGE_CONSTANTS.ADD)}
          </Button>
          {isEditMode &&
            <Button className="delete-button mr-1" onClick={handleDeleteContact} outline>
              {getLocaleMessage(intl, LANGUAGE_CONSTANTS.TABLE.DELETE)}
            </Button>
          }
          <Button className="delete-button" onClick={handleToggleModal} outline>
            {getLocaleMessage(intl, LANGUAGE_CONSTANTS.CANCEL)}
          </Button>
        </FormGroup>

      </ModalBody>
    </Modal >
  )
}

export default LocaleMessageWrapper(AddNewModal)
