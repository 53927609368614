import config from "./APIConfig"
import { getDataApi } from "../index"

export const getConfirmSiteVisitOverdueWidget = async (data) => {
  const { url } = config.getWidget
  return await getDataApi(url("ConfirmSiteVisitOverdue", data))
}

export const getConfirmSiteVisitWidget = async (data) => {
  const { url } = config.getWidget
  return await getDataApi(url("ConfirmSiteVisit", data))
}

export const getFailedInspectionWidget = async (data) => {
  const { url } = config.getWidget
  return await getDataApi(url("FailedInspection", data))
}

export const getFailedInspectionOverdueWidget = async (data) => {
  const { url } = config.getWidget
  return await getDataApi(url("FailedInspectionOverdue", data))
}

export const getVerifyBySuperIntendentWidget = async (data) => {
  const { url } = config.getWidget
  return await getDataApi(url("VerifyBySuperIntendent", data))
}

export const getVerifyBySuperIntendentOverdueWidget = async (data) => {
  const { url } = config.getWidget
  return await getDataApi(url("VerifyBySuperIntendentOverdue", data))
}

export const getReassignFailedInspectionCorrectionWidget = async (data) => {
  const { url } = config.getWidget
  return await getDataApi(url("ReassignFailedInspectionCorrection", data))
}

export const getReassignFailedInspectionCorrectionOverdueWidget = async (data) => {
  const { url } = config.getWidget
  return await getDataApi(url("ReassignFailedInspectionCorrectionOverdue", data))
}

export const getReadyActionCountWidget = async (data) => {
  const { url } = config.getWidget
  return await getDataApi(url("ReadyActionCount", data))
}

export const getReadyActionOverdueCountWidget = async (data) => {
  const { url } = config.getWidget
  return await getDataApi(url("ReadyActionOverdueCount", data))
}
