import { getActionType } from '../action-type-generator'

export const GET_CONTACT_LIST = getActionType('GET_CONTACT_LIST')
export const GET_CONTACT_LIST_VM_REQUEST = 'GET_CONTACT_LIST_VM_REQUEST'
export const GET_CREATE_ACCOUNT_VM_REQUEST = 'GET_CREATE_ACCOUNT_VM_REQUEST'
export const ADD_CONTACT_REQUEST = 'ADD_CONTACT_REQUEST'
export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST'
export const RESET_CONTACT_LIST = 'RESET_CONTACT_LIST'
export const SET_EDIT_CONTACT_MODE = 'SET_EDIT_CONTACT_MODE'
export const UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST'
export const GET_CONTACT_REQUEST = 'GET_CONTACT_REQUEST'
export const UPLOAD_USER_PROFILE_IMAGE_REQUEST = 'UPLOAD_USER_PROFILE_IMAGE_REQUEST'
export const RESET_USER_PROFILE_IMAGE_REQUEST = 'RESET_USER_PROFILE_IMAGE_REQUEST'
export const GET_CONTACT_TRAINING_LOG_LIST = 'GET_CONTACT_TRAINING_LOG_LIST'
export const GET_CONTACT_NOTIFICATION_SETTINGS = 'GET_CONTACT_NOTIFICATION_SETTINGS'
export const SAVE_CONTACT_NOTIFICATION_SETTINGS = 'SAVE_CONTACT_NOTIFICATION_SETTINGS'
export const GET_NOTIFICATION_SETTINGS_VM_REQUEST = 'GET_NOTIFICATION_SETTINGS_VM_REQUEST'
export const ADD_ORGANIZATION_ROLE_CONTACT_REQUEST = 'ADD_ORGANIZATION_ROLE_CONTACT_REQUEST'
export const UPDATE_ORGANIZATION_ROLE_CONTACT_REQUEST = 'UPDATE_ORGANIZATION_ROLE_CONTACT_REQUEST'
export const GET_ORGANIZATION_ROLE_CONTACT_REQUEST = 'GET_ORGANIZATION_ROLE_CONTACT_REQUEST'
export const DELETE_ORGANIZATION_ROLE_CONTACT_REQUEST = 'DELETE_ORGANIZATION_ROLE_CONTACT_REQUEST'
export const GET_ORGANIZATION_EXTERNAL_CONTACT_LIST_REQUEST = 'GET_ORGANIZATION_EXTERNAL_CONTACT_LIST_REQUEST'
export const GET_USER_AUTH0_CONTACT_DETAILS_VM_REQUEST = 'GET_USER_AUTH0_CONTACT_DETAILS_VM_REQUEST'
export const SEND_PASSWORD_RESET_EMAIL_REQUEST = 'SEND_PASSWORD_RESET_EMAIL_REQUEST'
export const UPDATE_USER_ACCOUNT_PASSWORD_REQUEST = 'UPDATE_USER_ACCOUNT_PASSWORD_REQUEST'
export const GET_USER_ACCOUNT_HISTORY_REQUEST = "GET_USER_ACCOUNT_HISTORY_REQUEST"
export const CREATE_NEW_USER_ACCOUNT_REQUEST = "CREATE_NEW_USER_ACCOUNT_REQUEST"
export const UPDATE_CONTACT_EMAIL_ID_REQUEST = "UPDATE_CONTACT_EMAIL_ID_REQUEST"

/****************************************************/
/** RESET_CONTACT_LIST **/
/****************************************************/

/**
 * @method resetContactList : To reset organization contact list
 *
 */
export const resetContactList = () => {
  return {
    type: RESET_CONTACT_LIST
  }
}

/****************************************************/
/** GET_CONTACT_LIST **/
/****************************************************/

/**
 * @method getContactListRequest : To get organization contact list
 *
 */
export const getContactListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CONTACT_LIST.REQUEST,
    payload,
    callback
  }
}

/**
 * @method getContactListSuccess : To set organization contact list on success
 *
 */
export const getContactListSuccess = (data) => {
  return {
    type: GET_CONTACT_LIST.SUCCESS,
    data
  }
}

/****************************************************/
/** GET_CREATE_ACCOUNT_VM **/
/****************************************************/

/**
 * @method getCreateAccountVMRequest : To get create account list for create organization contact
 *
 */
export const getCreateAccountVMRequest = (callback) => {
  return {
    type: GET_CREATE_ACCOUNT_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_CONTACT_LIST_VM **/
/****************************************************/

/**
 * @method getContactListVMRequest : To get dropdown list for contacts
 *
 */
export const getContactListVMRequest = (callback) => {
  return {
    type: GET_CONTACT_LIST_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** ADD_CONTACT **/
/****************************************************/

/**
 * @method addContactRequest : To add organization contact
 *
 */
export const addContactRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_CONTACT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_CONTACT **/
/****************************************************/

/**
 * @method deleteContactRequest : To delete organization contact
 *
 */
export const deleteContactRequest = (payload, callback) => {
  return {
    type: DELETE_CONTACT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SET_EDIT_CONTACT_MODE **/
/****************************************************/

/**
 * @method setEditContactMode : To set edit mode for organization contact
 *
 */
export const setEditContactMode = (value) => {
  return {
    type: SET_EDIT_CONTACT_MODE,
    payload: value
  }
}

/****************************************************/
/** UPDATE_CONTACT **/
/****************************************************/

/**
 * @method updateContactRequest : To update organization contact detail
 *
 */
export const updateContactRequest = (payload, callback) => {
  return {
    type: UPDATE_CONTACT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CONTACT **/
/****************************************************/

/**
 * @method getContactRequest : To get organization contact
 *
 */
export const getContactRequest = (payload, callback) => {
  return {
    type: GET_CONTACT_REQUEST,
    payload,
    callback
  }
}

/**
 * @method uploadUserProfileImageRequest
 *
 */
export const uploadUserProfileImageRequest = (payload, callback) => {
  return {
    type: UPLOAD_USER_PROFILE_IMAGE_REQUEST,
    payload,
    callback
  }
}

/**
 * @method resetUserProfileImageRequest
 *
 */
export const resetUserProfileImageRequest = (payload, callback) => {
  return {
    type: RESET_USER_PROFILE_IMAGE_REQUEST,
    payload,
    callback
  }
}

/**
 * @method getContactTrainingLogRequest
 *
 */
export const getContactTrainingLogRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CONTACT_TRAINING_LOG_LIST,
    payload,
    callback
  }
}

/**
 * @method getProfileNotifications
 *
 */
export const getContactNotificationsSettings = (callback) => {
  return {
    type: GET_CONTACT_NOTIFICATION_SETTINGS,
    callback
  }
}
export const saveContactNotificationsSettings = (payload, callback = () => { }) => {
  return {
    type: SAVE_CONTACT_NOTIFICATION_SETTINGS,
    payload,
    callback
  }
}

/****************************************************/
/** GET_NOTIFICATION_SETTINGS_VM **/
/****************************************************/

/**
 * @method getNotificationSettingsVMRequest : To get create account list for create organization contact
 *
 */
export const getNotificationSettingsVMRequest = (callback) => {
  return {
    type: GET_NOTIFICATION_SETTINGS_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_USER_AUTH0_CONTACT_DETAILS_VM_REQUEST **/
/****************************************************/

/**
 * @method getUserAuth0ContactDetailsVMRequest : To get contact AUTHO Account Details
 *
 */
export const getUserAuth0ContactDetailsVMRequest = (payload, callback = () => { }) => {
  return {
    type: GET_USER_AUTH0_CONTACT_DETAILS_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SEND_PASSWORD_RESET_EMAIL_REQUEST **/
/****************************************************/

/**
 * @method sendPasswordResetEmailRequest : To send password email 
 *
 */
export const sendPasswordResetEmailRequest = (payload, callback = () => { }) => {
  return {
    type: SEND_PASSWORD_RESET_EMAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_USER_ACCOUNT_PASSWORD_REQUEST **/
/****************************************************/

/**
 * @method updateUserAccountPasswordRequest : To update user account password
 *
 */
export const updateUserAccountPasswordRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_USER_ACCOUNT_PASSWORD_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_USER_ACCOUNT_HISTORY_REQUEST **/
/****************************************************/

/**
 * @method getUserAccountHistoryRequest : To get user account history
 *
 */
export const getUserAccountHistoryRequest = (payload, callback = () => { }) => {
  return {
    type: GET_USER_ACCOUNT_HISTORY_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_NEW_USER_ACCOUNT_REQUEST **/
/****************************************************/

/**
 * @method createNewUserAccountRequest : To create a new user account in auth0
 *
 */
export const createNewUserAccountRequest = (payload, callback = () => { }) => {
  return {
    type: CREATE_NEW_USER_ACCOUNT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_CONTACT_EMAIL_ID_REQUEST **/
/****************************************************/

/**
 * @method updateContactEmailIdRequest : To update contact's email address
 *
 */
export const updateContactEmailIdRequest = (payload, callback) => {
  return {
    type: UPDATE_CONTACT_EMAIL_ID_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_ORGANIZATION_ROLE_CONTACT_REQUEST **/
/****************************************************/

/**
 * @method addOrganizationRoleContactRequest : To add organization role contact
 *
 */
export const addOrganizationRoleContactRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_ORGANIZATION_ROLE_CONTACT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_ORGANIZATION_ROLE_CONTACT_REQUEST **/
/****************************************************/

/**
 * @method updateOrganizationRoleContactRequest : To update organization role contact
 *
 */
export const updateOrganizationRoleContactRequest = (payload, callback) => {
  return {
    type: UPDATE_ORGANIZATION_ROLE_CONTACT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATION_ROLE_CONTACT_REQUEST **/
/****************************************************/

/**
 * @method getOrganizationRoleContactRequest : To get organization role contact
 *
 */
export const getOrganizationRoleContactRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ORGANIZATION_ROLE_CONTACT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_ORGANIZATION_ROLE_CONTACT_REQUEST **/
/****************************************************/

/**
 * @method deleteOrganizationRoleContactRequest : To delete organization role contact
 *
 */
export const deleteOrganizationRoleContactRequest = (payload, callback) => {
  return {
    type: DELETE_ORGANIZATION_ROLE_CONTACT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATION_EXTERNAL_CONTACT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getOrganizationExternalContactListRequest : To get organization external contact list
 *
 */
export const getOrganizationExternalContactListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ORGANIZATION_EXTERNAL_CONTACT_LIST_REQUEST,
    payload,
    callback
  }
}