import config from "./APIConfig"
import { postDataApi } from '../../index'

export const addFindingsCorrection = (data) => {
  const { correctionDetails } = data

  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    requireTradeCorrection: Number(correctionDetails.requireTradeCorrection) === 1,
    requireVerification: Number(correctionDetails.requireVerification) === 1
  }

  const { url } = config.addFindingsCorrection

  return postDataApi(url, payload)
}

export default addFindingsCorrection
