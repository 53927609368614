export const ADD_CHECKPOINT_REQUEST = 'ADD_CHECKPOINT_REQUEST'
export const GET_CHECKPOINT_RESPONSE_QUESTIONS_REQUEST = 'GET_CHECKPOINT_RESPONSE_QUESTIONS_REQUEST'
export const UPSERT_CHECKPOINT_RESPONSE_QUESTIONS_REQUEST = 'UPSERT_CHECKPOINT_RESPONSE_QUESTIONS_REQUEST'
export const DELETE_CHECKPOINT_REQUEST = 'DELETE_CHECKPOINT_REQUEST'
export const SET_EDIT_CHECKPOINT_MODE = 'SET_EDIT_CHECKPOINT_MODE'
export const ADD_NEW_CHECKPOINT_VERSION_REQUEST = 'ADD_NEW_CHECKPOINT_VERSION_REQUEST'
export const GET_OBSERVATION_ACTIVITY_RESPONSE_QUESTIONS_REQUEST = 'GET_OBSERVATION_ACTIVITY_RESPONSE_QUESTIONS_REQUEST'
export const GET_REPORT_TAG_DDL_REQUEST = 'GET_REPORT_TAG_DDL_REQUEST'

/****************************************************/
/** ADD_CHECKPOINT_REQUEST **/
/****************************************************/

/**
 * @method addCheckpointRequest :  To add new checkpoint
 *
 */
export const addCheckpointRequest = (payload, callback) => {
  return {
    type: ADD_CHECKPOINT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CHECKPOINT_RESPONSE_QUESTIONS_REQUEST **/
/****************************************************/

/**
 * @method getCheckpointResponseQuestionsRequest :  To get checkpoint response questions
 *
 */
export const getCheckpointResponseQuestionsRequest = (payload, callback) => {
  return {
    type: GET_CHECKPOINT_RESPONSE_QUESTIONS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_OBSERVATION_ACTIVITY_RESPONSE_QUESTIONS_REQUEST **/
/****************************************************/

/**
 * @method getObservationActivityResponseQuestionsRequest :  To get observation activity response questions
 *
 */
export const getObservationActivityResponseQuestionsRequest = (payload, callback) => {
  return {
    type: GET_OBSERVATION_ACTIVITY_RESPONSE_QUESTIONS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPSERT_CHECKPOINT_RESPONSE_QUESTIONS_REQUEST **/
/****************************************************/

/**
 * @method upsertCheckpointResponseQuestionsRequest :  To add/update/delete checkpoint response questions
 *
 */
export const upsertCheckpointResponseQuestionsRequest = (payload, callback) => {
  return {
    type: UPSERT_CHECKPOINT_RESPONSE_QUESTIONS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_CHECKPOINT **/
/****************************************************/

/**
 * @method deleteCheckpointRequest :  To delete Checkpoint request 
 *
 */
export const deleteCheckpointRequest = (payload, callback) => {
  return {
    type: DELETE_CHECKPOINT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SET_EDIT_CHECKPOINT_MODE **/
/****************************************************/

/**
 * @method setEditCheckpointMode : To set edit mode for checkpoint
 *
 */
export const setEditCheckpointMode = (value) => {
  return {
    type: SET_EDIT_CHECKPOINT_MODE,
    payload: value
  }
}

/****************************************************/
/** ADD_NEW_CHECKPOINT_VERSION_REQUEST **/
/****************************************************/

/**
 * @method addNewCheckpointVersionRequest :  To add new version of checkpoint
 *
 */
export const addNewCheckpointVersionRequest = (payload, callback) => {
  return {
    type: ADD_NEW_CHECKPOINT_VERSION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_REPORT_TAG_DDL_REQUEST **/
/****************************************************/

/**
 * @method getReportTagDDLRequest :  To get report tags list for activity creation
 *
 */
export const getReportTagDDLRequest = (payload, callback) => {
  return {
    type: GET_REPORT_TAG_DDL_REQUEST,
    payload,
    callback
  }
}
