export const GET_OSHA_WRITTEN_PROGRAM_LIST_REQUEST = 'GET_OSHA_WRITTEN_PROGRAM_LIST_REQUEST'

/****************************************************/
/** GET_OSHA_WRITTEN_PROGRAM_LIST_REQUEST **/
/****************************************************/

/**
 * @method getOshaWrittenProgramsRequest : To Get Osha Written Programs
 *
 */
 export const getOshaWrittenProgramsRequest = (payload, callback) => {
  return {
    type: GET_OSHA_WRITTEN_PROGRAM_LIST_REQUEST,
    payload,
    callback
  }
}

