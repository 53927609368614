import config from "./APIConfig"
import { postDataApi } from "../index"

/**
 * @method createContactGroup : To add new contact group
 * @param {object} data 
 * {
     "contactGroupName": "string"
   }
 */
const createContactGroup = async (data) => {
  const { url } = config.createContactGroup
  return await postDataApi(url, data)
}

export default createContactGroup
