import { put, takeLatest, call } from "redux-saga/effects"

import * as services from "@services/api"
import * as actions from "@store/actions"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'

const { USER_PERMISSIONS_SAGA } = LANGUAGE_CONSTANTS

/** getUserManagementPermissionVM API **/
function* getUserManagementPermissionVM(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getUserManagementPermissionVM)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getUserManagementRolePermissionList API **/
function* getUserManagementRolePermissionList(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getUserManagementRolePermissionList, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** updateUserManagementRolePermissionList API **/
function* updateUserManagementRolePermissionList(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateUserManagementRolePermissionList, action.payload)
    if (response?.status === 200) {
      Notification({ status: 200, message: USER_PERMISSIONS_SAGA.UPDATED_SUCCESS })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    Notification({ status: 400, message: USER_PERMISSIONS_SAGA.UPDATION_FAILED })
    yield put(actions.setIsLoading(false))
  }
}

export function* userManagementPermissionSagas() {
  yield takeLatest(actions.GET_USER_MANAGEMENT_PERMISSION_VM, getUserManagementPermissionVM)
  yield takeLatest(actions.GET_USER_MANAGEMENT_ROLE_PERMISSION_LIST, getUserManagementRolePermissionList)
  yield takeLatest(actions.UPDATE_USER_MANAGEMENT_ROLE_PERMISSION_LIST, updateUserManagementRolePermissionList)
}

export default userManagementPermissionSagas
