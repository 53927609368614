export const GET_PROGRAM_DIRECTORY_OVERVIEW_LIST_REQUEST = "GET_PROGRAM_DIRECTORY_OVERVIEW_LIST_REQUEST"
export const GET_PROGRAM_DIRECTORY_OVERVIEW_VM_REQUEST = "GET_PROGRAM_DIRECTORY_OVERVIEW_VM_REQUEST"
export const GET_PROGRAM_TASK_OVERVIEW_LIST_REQUEST = "GET_PROGRAM_TASK_OVERVIEW_LIST_REQUEST"
export const GET_PROGRAM_TASK_OVERVIEW_VM_REQUEST = "GET_PROGRAM_TASK_OVERVIEW_VM_REQUEST"
export const GET_PROGRAM_UNIT_OVERVIEW_LIST_REQUEST = "GET_PROGRAM_UNIT_OVERVIEW_LIST_REQUEST"
export const GET_PROGRAM_UNIT_DETAIL_REQUEST = "GET_PROGRAM_UNIT_DETAIL_REQUEST"
export const GET_PROGRAM_UNIT_OVERVIEW_VM_REQUEST = "GET_PROGRAM_UNIT_OVERVIEW_VM_REQUEST"
export const GET_PROGRAM_OBSERVATION_TASK_OVERDUE_STATUS_REQUEST = "GET_PROGRAM_OBSERVATION_TASK_OVERDUE_STATUS_REQUEST"
export const GET_PROGRAM_UNIT_SCHEDULE_LIST_REQUEST = "GET_PROGRAM_UNIT_SCHEDULE_LIST_REQUEST"
export const GET_PROGRAM_SCHEDULE_VM_REQUEST = "GET_PROGRAM_SCHEDULE_VM_REQUEST"
export const SAVE_PROGRAM_UNIT_SCHEDULE_LINE_REQUEST = "SAVE_PROGRAM_UNIT_SCHEDULE_LINE_REQUEST"
export const DELETE_PROGRAM_UNIT_SCHEDULE_LINE_REQUEST = "DELETE_PROGRAM_UNIT_SCHEDULE_LINE_REQUEST"
export const GET_UNIT_SCHEDULE_PREREQS_VM_REQUEST = "GET_UNIT_SCHEDULE_PREREQS_VM_REQUEST"
export const START_SCHEDULE_LINE_REQUEST = "START_SCHEDULE_LINE_REQUEST"
export const COMPLETE_SCHEDULE_LINE_REQUEST = "COMPLETE_SCHEDULE_LINE_REQUEST"
export const REORDER_UNIT_SCHEDULE_LINE_REQUEST = "REORDER_UNIT_SCHEDULE_LINE_REQUEST"
export const REORDER_SITE_PLAN_SCHEDULE_LINE_REQUEST = "REORDER_SITE_PLAN_SCHEDULE_LINE_REQUEST"

/****************************************************/
/** GET_PROGRAM_TASK_OVERVIEW_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProgramTaskOverviewListRequest : To get program task overview list
 *
 */
export const getProgramTaskOverviewListRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_TASK_OVERVIEW_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_TASK_OVERVIEW_VM_REQUEST **/
/****************************************************/

/**
 * @method getProgramTaskOverviewVMRequest : To get program task overview VM
 *
 */
export const getProgramTaskOverviewVMRequest = (callback) => {
  return {
    type: GET_PROGRAM_TASK_OVERVIEW_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_UNIT_OVERVIEW_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProgramUnitOverviewListRequest : To get program unit overview list
 *
 */
export const getProgramUnitOverviewListRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_UNIT_OVERVIEW_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_UNIT_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method getProgramUnitDetailRequest : To get program unit detail
 *
 */
export const getProgramUnitDetailRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_UNIT_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_UNIT_OVERVIEW_VM **/
/****************************************************/

/**
 * @method getProgramUnitOverviewVMRequest :  To get program unit overview request
 *
 */
export const getProgramUnitOverviewVMRequest = (callback) => {
  return {
    type: GET_PROGRAM_UNIT_OVERVIEW_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_DIRECTORY_OVERVIEW_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProgramDirectoryOverviewListRequest : To get program directory overview list
 *
 */
export const getProgramDirectoryOverviewListRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_DIRECTORY_OVERVIEW_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_DIRECTORY_OVERVIEW_VM_REQUEST **/
/****************************************************/

/**
 * @method getProgramDirectoryOverviewVMRequest : To get program directory overview VM
 *
 */
export const getProgramDirectoryOverviewVMRequest = (callback) => {
  return {
    type: GET_PROGRAM_DIRECTORY_OVERVIEW_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_OBSERVATION_TASK_OVERDUE_STATUS_REQUEST **/
/****************************************************/

/**
 * @method getProgramObservationTaskOverdueStatusRequest : To get program observation task overdue status
 *
 */
export const getProgramObservationTaskOverdueStatusRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_OBSERVATION_TASK_OVERDUE_STATUS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_UNIT_SCHEDULE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProgramUnitScheduleListRequest : To get program unit schedule list
 *
 */
export const getProgramUnitScheduleListRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_UNIT_SCHEDULE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_SCHEDULE_VM_REQUEST **/
/****************************************************/

/**
 * @method getProgramScheduleVMRequest : To get program schedule VM
 *
 */
export const getProgramScheduleVMRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_SCHEDULE_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SAVE_PROGRAM_UNIT_SCHEDULE_LINE_REQUEST **/
/****************************************************/

/**
 * @method saveProgramUnitScheduleLineRequest : To save program unit schedule line
 *
 */
export const saveProgramUnitScheduleLineRequest = (payload, callback) => {
  return {
    type: SAVE_PROGRAM_UNIT_SCHEDULE_LINE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_PROGRAM_UNIT_SCHEDULE_LINE_REQUEST **/
/****************************************************/

/**
 * @method deleteProgramUnitScheduleLineRequest : To delete program unit schedule line
 *
 */
export const deleteProgramUnitScheduleLineRequest = (payload, callback) => {
  return {
    type: DELETE_PROGRAM_UNIT_SCHEDULE_LINE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_UNIT_PREREQS_VM_REQUEST **/
/****************************************************/

/**
 * @method getUnitPrereqsVMRequest : To get preques VM in site unit schedule
 *
 */
export const getUnitPrereqsVMRequest = (payload, callback) => {
  return {
    type: GET_UNIT_SCHEDULE_PREREQS_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** START_SCHEDULE_LINE_REQUEST **/
/****************************************************/

/**
 * @method startScheduleLineRequest : To start schedule line item
 *
 */
export const startScheduleLineRequest = (payload, callback) => {
  return {
    type: START_SCHEDULE_LINE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** COMPLETE_SCHEDULE_LINE_REQUEST **/
/****************************************************/

/**
 * @method completeScheduleLineRequest : To complete schedule line item 
 *
 */
export const completeScheduleLineRequest = (payload, callback) => {
  return {
    type: COMPLETE_SCHEDULE_LINE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** REORDER_UNIT_SCHEDULE_LINE_REQUEST **/
/****************************************************/

/**
 * @method reorderUnitScheduleLineRequest : To reorder unit schedule line items
 *
 */
export const reorderUnitScheduleLineRequest = (payload, callback) => {
  return {
    type: REORDER_UNIT_SCHEDULE_LINE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** REORDER_SITE_PLAN_SCHEDULE_LINE_REQUEST **/
/****************************************************/

/**
 * @method reorderSitePlanScheduleLineRequest : To reorder site plan schedule line items
 *
 */
export const reorderSitePlanScheduleLineRequest = (payload, callback) => {
  return {
    type: REORDER_SITE_PLAN_SCHEDULE_LINE_REQUEST,
    payload,
    callback
  }
}
