import React, { useCallback, useMemo } from "react"

import { whiteClose } from "@src/assets/images/new-theme"

const AlertMessage = ({ text, content, bgColor = "#00bf27", onClose = () => {} }) => {
  const handleCloseAlert = useCallback((e) => {
    e.preventDefault()
    onClose()
  }, [onClose])

  const styleConfig = useMemo(() => {
    return {
      backgroundColor: bgColor
    }
  }, [bgColor])

  return (
    <div className="alert-msg" style={styleConfig}>
      <img src={whiteClose} alt="whiteClose" onClick={handleCloseAlert} />
      {content || <p>{text}</p>}
    </div>
  )
}

export default AlertMessage
