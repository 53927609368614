import config from "./APIConfig"
import { putDataApi } from '../index'

export const updateChecklistRequiredActivity = (data) => {
  const { url } = config.updateChecklistRequiredActivity

  return putDataApi(url(data.wfCampaignSetupChecklistId), data)
}

export default updateChecklistRequiredActivity
