import { FAILED_INSPECTION_TRAINING_TYPE } from '@views/campaigns/add-new-campaign/workflow/block/failed-inspection-training/config'
import config from "./APIConfig"
import { putDataApi } from '../../index'

/**
 * @method updateBlockFailedInspectionTraining
 * @param {object} data 
 * 
 * API payload:-
 * 
 * {
 *  "wfCampaignSetupId": 0,
 *  "assignTrainingForFailActivity": true,
 *  "requireTrainingCompletion": true
 * }
 */

export const updateBlockFailedInspectionTraining = async (data) => {
    const { url } = config.updateBlockFailedInspectionTraining
    const payload = {
        wfCampaignSetupId: data.wfCampaignSetupId,
        assignTrainingForFailActivity: data.assignTrainingId === FAILED_INSPECTION_TRAINING_TYPE.YES,
        requireTrainingCompletion: data.requireTrainingId === FAILED_INSPECTION_TRAINING_TYPE.YES
    }
    return await putDataApi(url(data.wfCampaignSetupId), payload, null, { isApiNeedsToHandleErrors: false })
}

export default updateBlockFailedInspectionTraining