import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { getActivitiesListRowDragText } from '@views/helpers'
import AllowCopyActivity from "./AllowCopyActivity"
import MarkActivityRequired from "./MarkActivityRequired"
import OrderActions from './OrderActions'
import RowActions from './RowActions'

const { ACTIVITIES, WORKFLOW: { REQUIRED }, ALL_ACTIVITIES: { TABLE_COLUMNS }, TABLE, TYPE } = LANGUAGE_CONSTANTS

export const getColumnsConfig = ({ intl, isChildGrid }) => [
  {
    customHeaderCheckboxSelection: true,
    checkboxSelection: true,
    selector: '',
    minWidth: '190px',
    maxWidth: '200px',
    rowDrag: true,
    rowDragText: getActivitiesListRowDragText
  },
  {
    name: getLocaleMessage(intl, TABLE.ORDER),
    allowOverflow: true,
    right: true,
    menuTabNotRequired: true,
    maxWidth: "150px",
    minWidth: "150px",
    tooltipValueGetter: () => {
      return ''
    },
    cell: (row) => <OrderActions data={row} />
  },
  {
    name: getLocaleMessage(intl, ACTIVITIES.ACTIVITIES),
    selector: 'checkPointName',
    menuTabNotRequired: true,
    wrap: true,
    format: (row) => row.checkPointName
  },
  {
    name: getLocaleMessage(intl, TABLE_COLUMNS.ACTIVITY_ID),
    selector: 'checkpointId',
    menuTabNotRequired: true,
    wrap: true,
    tooltipValueGetter: () => {
      return ''
    },
    cell: (row) => <div className="d-flex"><span className={'d-block font-weight-bold'}>{row.checkpointId}</span></div>
  },
  {
    name: getLocaleMessage(intl, TYPE),
    selector: 'activityName',
    menuTabNotRequired: true,
    format: (row) => row.activityName
  },
  {
    name: getLocaleMessage(intl, REQUIRED),
    selector: 'isRequired',
    menuTabNotRequired: true,
    tooltipValueGetter: () => {
      return ''
    },
    format: (row) => (
      <MarkActivityRequired data={row} />
    )
  },
  {
    name: getLocaleMessage(intl, ACTIVITIES.RISK_LEVEL),
    selector: 'riskLevelName',
    menuTabNotRequired: true,
    format: (row) => row.riskLevelName
  },
  {
    name: getLocaleMessage(intl, ACTIVITIES.ALLOW_COPY),
    selector: 'isCopyAllowed',
    menuTabNotRequired: true,
    tooltipValueGetter: () => {
      return ''
    },
    format: (row) => (
      <AllowCopyActivity data={row} />
    )
  },
  {
    selector: 'actions',
    maxWidth: "70px",
    minWidth: "60px",
    allowOverflow: true,
    cell: (row) => <RowActions data={row} isChildGrid={isChildGrid} />
  }
]
