const apiConfig = {
    getIntercomSecureHMAC: {
        method: 'GET',
        url: ({
            email
        }) => `api/Security/GenerateIntercomHMAC/${email}`
    }
}

export default apiConfig
