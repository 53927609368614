import config from "./APIConfig"
import { getDataApi } from '../index'

export const getActivityTemplateList = (data) => {
  const { url } = config.getActivityTemplateList
  return getDataApi(url(data))
}

export const getAddNewCheckpointVM = (data) => {
  const { url } = config.getAddNewCheckpointVM
  return getDataApi(url(data))
}

export const getAllCheckpointList = (data) => {
  const { url } = config.getAllCheckpointList
  return getDataApi(url(data))
}

export const getAllCheckpointTrainingList = async (data) => {
  const { url } = config.getAllCheckpointTrainingList
  return await getDataApi(url(data))
}

export const getApplicableRegulationList = async (data) => {
  const { url } = config.getApplicableRegulationList
  return await getDataApi(url(data))
}

export const getAssemblyTypeDDL = async (data) => {
  const { url } = config.getAssemblyTypeDDL
  return await getDataApi(url(data))
}

export const getCheckpointChildDetail = async (data) => {
  const { url } = config.getCheckpointChildDetail
  return await getDataApi(url(data))
}

export const getCheckpointCollection = async (data) => {
  const { url } = config.getCheckpointCollection
  return await getDataApi(url(data))
}

export const getCheckpointDetailVMList = async () => {
  const { url } = config.getCheckpointDetailVMList
  return await getDataApi(url())
}

export const getCheckpointResponseQuestions = async (data) => {
  const { url } = config.getCheckpointResponseQuestions
  return await getDataApi(url(data))
}

export const getCheckpointTrainingList = (data) => {
  const { url } = config.getCheckpointTrainingList
  return getDataApi(url(data))
}

export const getIndustryInterpretationsData = (data) => {
  const { url } = config.getIndustryInterpretationsData
  return getDataApi(url(data))
}

export const getObservationActivityResponseQuestions = async (data) => {
  const { url } = config.getObservationActivityResponseQuestions
  return await getDataApi(url(data))
}

export const getReferenceStandardData = async (data) => {
  const { url } = config.getReferenceStandardData
  return await getDataApi(url(data))
}

export const getReportTagDDL = (data) => {
  const { url } = config.getReportTagDDL
  return getDataApi(url(data))
}

export const getTemplateActivityGroupChildrenList = async (data) => {
  const { url } = config.getTemplateActivityGroupChildrenList
  return await getDataApi(url(data))
}

export const getTemplateActivityGroupList = async (data) => {
  const { url } = config.getTemplateActivityGroupList
  return await getDataApi(url(data))
}

export const getTemplateAssembly = async (data) => {
  const { url } = config.getTemplateAssembly
  return await getDataApi(url(data))
}

export const getAssemblyTypeComponentDDL = async (data) => {
  const { url } = config.getAssemblyTypeComponentDDL
  return await getDataApi(url(data))
}

/**
 * @method getTemplateCheckpointList
 * @param {object} data
 * 
 * Note:
 * Set templateId = -1,
 * For fetching checkpoints when we have no templateId such as in case of campaign
 * 
 * @description For fetching checkpoints for templates, API endpoint --> config.getTemplateCheckpointList
 * 
 * @description For fetching checkpoints without templates, API endpoint --> config.getCheckpointList
 */
export const getTemplateCheckpointList = async (data) => {
  const { url } = data.templateId === -1 ? config.getCheckpointList : config.getTemplateCheckpointList
  return await getDataApi(url(data))
}

export const getTemplateComponent = async (data) => {
  const { url } = config.getTemplateComponent
  return await getDataApi(url(data))
}

export const getTemplateDescription = async (data) => {
  const { url } = config.getTemplateDescription
  return await getDataApi(url(data))
}

export const getTemplateDetail = async (data) => {
  const { url } = config.getTemplateDetail
  return await getDataApi(url(data))
}

export const getTemplateList = async (data) => {
  const { url } = config.getTemplateList
  return await getDataApi(url(data))
}

export const getTemplateListVM = async () => {
  const { url } = config.getTemplateListVM
  return await getDataApi(url())
}

export const getTemplateManufacturer = async (data) => {
  const { url } = config.getTemplateManufacturer
  return await getDataApi(url(data))
}

export const getTemplateSetupVM = async (data) => {
  const { url } = config.getTemplateSetupVM
  return await getDataApi(url(data))
}

export const getTemplateSubComponent = async (data) => {
  const { url } = config.getTemplateSubComponent
  return await getDataApi(url(data))
}

export const getTemplateVersionDDL = async (data) => {
  const { url } = config.getTemplateVersionDDL
  return await getDataApi(url(data))
}

export const getUngroupedTemplateActivities = async (data) => {
  const { url } = config.getUngroupedTemplateActivities
  return await getDataApi(url(data))
}
