import config from "./APIConfig"
import { getDataApi } from "../index"

export const getObservationTemplateDDL = async (data) => {
  const { url } = config.getObservationTemplateDDL
  return await getDataApi(url(data))
}

export const getObservationTemplateVM = async () => {
  const { url } = config.getObservationTemplateVM
  return await getDataApi(url)
}

export const getProgramAssemblyChecklistList = async (data) => {
  const { url } = config.getProgramAssemblyChecklistList
  return await getDataApi(url(data))
}

export const getProgramAssemblyInspectionList = async (data) => {
  const { url } = config.getProgramAssemblyInspectionList
  return await getDataApi(url(data))
}

export const getProgramAssemblyInspectionVM = async (data) => {
  const { url } = config.getProgramAssemblyInspectionVM
  return await getDataApi(url(data))
}

export const getProgramAssemblyList = async (data) => {
  const { url } = config.getProgramAssemblyList
  return await getDataApi(url(data))
}

export const getProgramAssemblyListVM = async () => {
  const { url } = config.getProgramAssemblyListVM
  return await getDataApi(url)
}

export const getProgramAssemblyReinspectionList = async (data) => {
  const { url } = config.getProgramAssemblyReinspectionList
  return await getDataApi(url(data))
}

export const getProgramAssemblyReinspectionVM = async () => {
  const { url } = config.getProgramAssemblyReinspectionVM
  return await getDataApi(url)
}

export const getProgramBasicDetail = async (data) => {
  const { url } = config.getProgramBasicDetail
  return await getDataApi(url(data))
}

export const getProgramConfirmSiteVisitList = async (data) => {
  const { url } = config.getProgramConfirmSiteVisitList
  return await getDataApi(url(data))
}

export const getProgramConfirmSiteVisitVM = async () => {
  const { url } = config.getProgramConfirmSiteVisitVM
  return await getDataApi(url)
}

export const getProgramEscalation = (data) => {
  const { url } = config.getProgramEscalation
  return getDataApi(url(data))
}

export const getProgramEscalationVM = async (programId) => {
  const { url } = config.getProgramEscalationVM
  return await getDataApi(url(programId))
}

export const getProgramSelectiveAssemblyList = async (data) => {
  const { url } = config.getProgramSelectiveAssemblyList
  return await getDataApi(url(data))
}

export const getProgramTaskCloseOut = async (data) => {
  const { url } = config.getProgramTaskCloseOut
  return await getDataApi(url(data))
}

export const getProgramTaskCloseOutVM = async () => {
  const { url } = config.getProgramTaskCloseOutVM
  return await getDataApi(url)
}

export const getProgramTaskReviewList = async (data) => {
  const { url } = config.getProgramTaskReviewList
  return await getDataApi(url(data))
}
