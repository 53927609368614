import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addProjectAddress
 * @param {object} data 
 *  {
 *    "projectId": 0,
 *    "addresses": [
 *       {
 *        "isPrimary": true,
 *        "streetAddress": "string",
 *        "addressUnit": "string",
 *        "city": "string",
 *        "state": "string",
 *        "postCode": "string",
 *        "countryCode": "string",
 *        "primaryPhone": "string",
 *        "addressName": "string",
 *     "county": "string",
 *       }, etc...
 *     ]
 *   }
 *
 * Note:
 *  "addresses" can contain multiple addresses.
 *  If some address is primary address then set "isPrimary" = true else false,
 *  There should be only one primary address in list
 * 
 */
export const addProjectAddress = (data) => {
  const { url } = config.addProjectAddress
  const payload = {
    projectId: data.projectId || 0,
    locations: data.locations || []
  }
  return postDataApi(url({ projectId: data.projectId }), payload)
}

export default addProjectAddress
