import { getSearchQuery } from '../config'

const apiConfig = {
  createNewAuditTrail: {
    method: 'POST',
    url: ({ observationTaskId }) => {
      return `api/Checkpoint/AuditTrail/${observationTaskId}`
    }
  },
  getAuditTrailModal: {
    method: 'GET',
    url: ({
      observationTaskId,
      wfActionId,
      assignmentActivityId
    }) => {
      const searchString = [
        getSearchQuery("ObservationTaskId", observationTaskId, !!observationTaskId),
        getSearchQuery("WFActionId", wfActionId),
        getSearchQuery("AssignmentActivityId", assignmentActivityId)
      ].join('')
      return `api/Checkpoint/AuditTrail/${observationTaskId}${searchString}`
    }
  },
  deleteAuditTrail: {
    method: 'DELETE',
    url: ({
      noteId
    }) => {
      return `api/Checkpoint/AuditTrail/${noteId}`
    }
  },
  updateAuditTrail: {
    method: 'POST',
    url: ({ observationTaskId, auditTrailId, noteId }) => {
      return `api/Checkpoint/AuditTrail/${observationTaskId}/${auditTrailId}/${noteId}`
    }
  },
  createAuditTrail: {
    method: 'POST',
    url: ({ observationTaskId, auditTrailId }) => {
      return `api/Checkpoint/AuditTrail/${observationTaskId}/${auditTrailId}`
    }
  },
  createAssignmentNote: {
    method: 'POST',
    url: `api/Checkpoint/AuditTrail/CreateAssignmentNote`
  }
}

export default apiConfig
