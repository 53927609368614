import config from "./APIConfig"
import { putDataApi } from "../index"

/**
 * @method updateSuperintendentActionReassign
 * This api is for updating contact to reassign
 * [Reassign Failed inspection correction]
 * 
 * @param {object} data 
 * 
 * {
 *  contactId: 0,
 *  actionId: 0
 * }
 * 
 */
export const updateSuperintendentActionReassign = async (data) => {
  const { url } = config.updateSuperintendentActionReassign
  return await putDataApi(url(data.actionId), data)
}

export default updateSuperintendentActionReassign
