import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateAdvanceSearchFilters
 * @param {object} data
 *
 * {
    advanceSearchFilterId: "number",
    searchFilterName: "string",
    searchFilterJson: "string",
    rowsPerPage: number,
    isGlobal: bool,
    isDefault: bool
  }
 */
export const updateAdvanceSearchFilters = (data) => {
  const { url } = config.updateAdvanceSearchFilters
  const payload = {
    advanceSearchFilterId: data.advanceSearchFilterId,
    searchFilterName: data.searchFilterName,
    searchFilterJson: data.searchFilterJson,
    rowsPerPage: data.rowsPerPage,
    isGlobal: data.isGlobal,
    isDefault: data.isDefault
  }

  return putDataApi(url(data.advanceSearchFilterId), payload)
}

export default updateAdvanceSearchFilters
