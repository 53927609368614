import { getLocaleMessage } from "@utils"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { FILE_UPLOAD_ERROR_TYPES, MAX_FILE_SIZE } from '../config'

const { RESOURCES } = LANGUAGE_CONSTANTS

export const fileSelectionValidator = (file, isAllFilesAllowed, intl) => {
  if (file.size > (MAX_FILE_SIZE * 1024 * 1024)) {
    return {
      code: FILE_UPLOAD_ERROR_TYPES.FILE_TOO_LARGE,
      message: getLocaleMessage(intl, RESOURCES.FILE_SIZE_INVALID)
    }
  } else if (file.name.match(/\.(exe|dmg)$/i)) {
    return {
      code: FILE_UPLOAD_ERROR_TYPES.INVALID_FILE_FORMAT,
      message: getLocaleMessage(intl, RESOURCES.FILE_INVALID_FORMAT)
    }
  }

  return null
}

export const getCustomFileDetailList = (acceptedFileList, defaultFileCategoryId) => {
  return acceptedFileList.map(acceptedFile => {
    const lastIndex = acceptedFile.name.lastIndexOf('.')
    return ({
      fileName: lastIndex === -1 ? acceptedFile.name : acceptedFile.name.substr(0, lastIndex),
      extension: lastIndex === -1 ? '' : acceptedFile.name.substr(lastIndex),
      fileDescription: '',
      fileCategoryId: defaultFileCategoryId || ""
    })
  })
}

export const validateFileData = ({
  customFileDetailList,
  intl
}) => {
  const error = {}
  customFileDetailList.forEach((cFile, index) => {
    if (!cFile.fileName.trim().length) {
      error[`fileName_${index}`] = getLocaleMessage(intl, RESOURCES.FILE_TITLE_REQUIRED)
    }
  })

  return error
}

export const validateFileRenameData = ({
  fileName,
  intl
}) => {
  const error = {}
  if (!fileName.trim().length) {
    error['fileName'] = getLocaleMessage(intl, RESOURCES.FILE_TITLE_REQUIRED)
  }

  return error
}

export const getFileCurrentName = (title, actualFileName) => {
  const name = title || actualFileName || ""
  const lastIndex = name.lastIndexOf(".")
  const fileName = lastIndex === -1 ? name : name.substr(0, lastIndex)
  return fileName
}

export const getFileExtension = (title, actualFileName) => {
  const name = title || ""
  const lastIndex = name.lastIndexOf(".")
  let extension = lastIndex !== -1 ? name.substr(lastIndex) : ''

  if (!extension) {
    const lastIndex = actualFileName.lastIndexOf(".")
    extension = lastIndex !== -1 ? actualFileName.substr(lastIndex) : ''
  }

  return extension
}
