import React, { useCallback, useMemo } from "react"
import { CustomInput } from "reactstrap"

const CheckboxItem = ({
  id,
  valueType,
  labelType = "text",
  selectedFilters,
  index,
  filter,
  isMulti,
  onSelectFilter
}) => {
  const value = useMemo(() => filter[valueType], [filter, valueType])
  const textLabel = useMemo(() => filter[labelType], [filter, labelType])

  const isChecked = useMemo(() => {
    return isMulti ? selectedFilters.some((selected) => selected[valueType] === value) : selectedFilters === value
  }, [selectedFilters, value, valueType])

  const handleSelectFilter = useCallback((e) => {
    if (isMulti) {
      let updatedFilters
      if (e.target.checked) {
        updatedFilters = [...selectedFilters, filter]
      } else {
        updatedFilters = selectedFilters.filter(item => item[valueType] !== value)
      }
      onSelectFilter(id, updatedFilters)
    } else {
      onSelectFilter(id, value)
    }
  }, [onSelectFilter, selectedFilters, filter, value])

  return (
    <div key={`${value}_${index}`}>
      <CustomInput value={value}
        type="checkbox"
        id={`${id}_filter_${value}`}
        label={textLabel}
        className={
          isChecked
            ? "custom-checkbox-control"
            : "custom-unchecked-checkbox-control"
        }
        checked={isChecked}
        onChange={handleSelectFilter}
      />
    </div>
  )
}

export default CheckboxItem
