import React, { useCallback, useMemo, useState } from "react"
import { X } from "react-feather"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { pageSizeList } from "@shared/tables"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"

const PageSizeFilter = ({
  intl,
  pageSizeFilterRequired,
  pageSize,
  onPerPageRecords
}) => {
  const [isShowPageSizeDropdown, setShowPageSizeDropdown] = useState(false)

  const handleTogglePageSizeDropdown = useCallback((e) => {
    e?.stopPropagation()
    setShowPageSizeDropdown((prevState) => !prevState)
  }, [])

  const handleSelectPageSize = useCallback(
    (value) => {
      const event = {
        target: {
          value
        }
      }
      onPerPageRecords(event)
    },
    [onPerPageRecords]
  )

  if (!pageSizeFilterRequired) {
    return null
  }

  return (
    <div className="page-size-filter">
      <Dropdown
        isOpen={isShowPageSizeDropdown}
        toggle={handleTogglePageSizeDropdown}
      >
        <DropdownToggle className="selected-page-size">
          {getLocaleMessage(intl, LANGUAGE_CONSTANTS.VIEWING)}:
          <span className="ml-1 secondary-text-btn">{pageSize}</span>
        </DropdownToggle>
        <DropdownMenu center="true" className="page-size-menu">
          <div className="page-size-menu-header">
            {getLocaleMessage(intl, LANGUAGE_CONSTANTS.VIEWING)}:
            <X size={15} onClick={handleTogglePageSizeDropdown}/>
          </div>
          {pageSizeList.map((value) => {
            return (
              <DropdownItem
                tag="a"
                className="w-100"
                key={`page_size_${value}`}
                onClick={() => handleSelectPageSize(value)}
              >
                <span className="align-middle ml-50">{value}</span>
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default localeMessageWrapper(PageSizeFilter)
