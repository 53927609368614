import config from "./APIConfig"
import { postDataApi } from "../index"

/**
 * @method updateProgramAssemblyReinspectionList
 * 
 * @param {object} data 
 * 
 * {
 *  programId: 0,
 *  reinspectionRisks: [
 *   {
 *     "programQualityRiskLevelConfigId": 0,
 *     "programQualityAssemblyId": 0,
 *     "requiresReinspection": true,
 *     "holdProductionOnOpenItems": true,
 *     "contactListToNotify": 0
 *   }
 *  ]
 * }
 * 
 */
export const updateProgramAssemblyReinspectionList = (data) => {
  const { url } = config.updateProgramAssemblyReinspectionList

  return postDataApi(url(data), data)
}

export default updateProgramAssemblyReinspectionList
