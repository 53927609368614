import React, { useEffect, useState } from "react"
import { Button, Card, CardHeader, CardTitle, Row, Col, CardBody, Form, FormGroup, Input } from "reactstrap"
import { useForm } from 'react-hook-form'
import { useDispatch } from "react-redux"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { closeSVGIcon } from "@src/assets/images/new-theme"
import * as actions from "@store/actions"
import { getLocaleMessage } from '@utils'
import { ReactSelect, CustomLabel } from '@views/components'
import localeMessageWrapper from '@views/components/locale-message'

const { CLEAR, SEARCH, CLOSE, ADVANCED_SEARCH, CAMPAIGNS } = LANGUAGE_CONSTANTS

const AdvanceSearch = ({
  intl,
  searchDetail: {
    serviceName,
    siteName,
    unitName,
    checklistName,
    taskTypeId,
    taskStatusId,
    assignedTo
  },
  onAdvancedSearch,
  onChangeDetails,
  onCustomAdvanceSearch = () => { },
  onResetSearchDetail,
  onToggleAdvanceSearch
}) => {
  const { control } = useForm({})
  const dispatch = useDispatch()

  const [observationTaskVM, setObservationTaskVM] = useState(null)

  useEffect(() => {
    dispatch(actions.getObservationTaskVMRequest(null, (data) => {
      if (data) {
        setObservationTaskVM(data)
      }
    }))
  }, [])

  return (
    <Card >
      <CardBody>
        <CardHeader className="px-0 pt-0">
          <CardTitle tag="p">{getLocaleMessage(intl, ADVANCED_SEARCH)}</CardTitle>
          <button
            id="close-search"
            type="button"
            className="waves-effect btn advanced-btn"
            onClick={onToggleAdvanceSearch}
          >
            <img src={closeSVGIcon} alt="close" />
          </button>
        </CardHeader>
        <Form>
          <Row form>
            <Col className='mb-1' sm={12} md={6} xl={4}>
              <FormGroup>
                <CustomLabel title={CAMPAIGNS.SERVICE_NAME} />
                <Input
                  type='text'
                  name='serviceName'
                  placeholder={getLocaleMessage(intl, CAMPAIGNS.SERVICE_NAME)}
                  value={serviceName}
                  onChange={(event) => onChangeDetails('serviceName', event.target.value)}
                  onKeyDown={onCustomAdvanceSearch}
                />
              </FormGroup>
            </Col>
            <Col className='mb-1' sm={12} md={6} xl={4}>
              <FormGroup>
                <CustomLabel title={CAMPAIGNS.SITE_NAME} />
                <Input
                  type='text'
                  name='siteName'
                  placeholder={getLocaleMessage(intl, CAMPAIGNS.SITE_NAME)}
                  value={siteName}
                  onChange={(event) => onChangeDetails('siteName', event.target.value)}
                  onKeyDown={onCustomAdvanceSearch}
                />
              </FormGroup>
            </Col>
            <Col className='mb-1' sm={12} md={6} xl={4}>
              <FormGroup>
                <CustomLabel title={CAMPAIGNS.UNIT_NAME} />
                <Input
                  type='text'
                  name='unitName'
                  placeholder={getLocaleMessage(intl, CAMPAIGNS.UNIT_NAME)}
                  value={unitName}
                  onChange={(event) => onChangeDetails('unitName', event.target.value)}
                  onKeyDown={onCustomAdvanceSearch}
                />
              </FormGroup>
            </Col>
            <Col className='mb-1' sm={12} md={6} xl={4}>
              <FormGroup>
                <CustomLabel title={CAMPAIGNS.CHECKLIST_NAME} />
                <Input
                  type='text'
                  name='checklistName'
                  placeholder={getLocaleMessage(intl, CAMPAIGNS.CHECKLIST_NAME)}
                  value={checklistName}
                  onChange={(event) => onChangeDetails('checklistName', event.target.value)}
                  onKeyDown={onCustomAdvanceSearch}
                />
              </FormGroup>
            </Col>
            <Col className='mb-1' sm={12} md={6} xl={4}>
              <ReactSelect
                id='taskTypeId'
                name='taskTypeId'
                label={getLocaleMessage(intl, CAMPAIGNS.TASK_TYPE)}
                placeHolder={getLocaleMessage(intl, CAMPAIGNS.TASK_TYPE)}
                control={control}
                value={taskTypeId}
                options={observationTaskVM?.taskType || []}
                getOptionLabel={(item) => item.text}
                getOptionValue={(item) => item.value}
                onSelect={(selected) => {
                  onChangeDetails('taskTypeId', selected?.value,
                    (data) => {
                      onCustomAdvanceSearch(null, true, data)
                    }
                  )
                }}
              />
            </Col>
            <Col className='mb-1' sm={12} md={6} xl={4}>
              <ReactSelect
                id='taskStatusId'
                name='taskStatusId'
                label={getLocaleMessage(intl, CAMPAIGNS.TASK_STATUS)}
                placeHolder={getLocaleMessage(intl, CAMPAIGNS.TASK_STATUS)}
                control={control}
                value={taskStatusId}
                options={observationTaskVM?.taskStatus || []}
                getOptionLabel={(item) => item.text}
                getOptionValue={(item) => item.value}
                onSelect={(selected) => {
                  onChangeDetails('taskStatusId', selected?.value,
                    (data) => {
                      onCustomAdvanceSearch(null, true, data)
                    }
                  )
                }}
              />
            </Col>
            <Col className='mb-1' sm={12} md={6} xl={4}>
              <FormGroup>
                <CustomLabel title={CAMPAIGNS.ASSIGNED_TO} />
                <Input
                  type='text'
                  name='assignedTo'
                  placeholder={getLocaleMessage(intl, CAMPAIGNS.ASSIGNED_TO)}
                  value={assignedTo}
                  onChange={(event) => onChangeDetails('assignedTo', event.target.value)}
                  onKeyDown={onCustomAdvanceSearch}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="text-right col-12">
            <Button
              outline
              onClick={onResetSearchDetail}
              className={"delete-button"}
            >
              {getLocaleMessage(intl, CLEAR)}
            </Button>
            <Button className="ml-2 common-button" onClick={onAdvancedSearch}>
              {getLocaleMessage(intl, SEARCH)}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default localeMessageWrapper(AdvanceSearch)