import config from "./APIConfig"
import { putDataApi } from '../index'

export const updateCampaignDetail = (data) => {
    const { url } = config.updateCampaignDetail

    const payload = {
        entityId: 1,
        ...data
    }

    return putDataApi(url(data.campaignId), payload)
}

export default updateCampaignDetail
