import cx from "classnames"
import PropTypes from 'prop-types'

import { LANGUAGE_TYPE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"

const { ENGLISH, SPANISH } = LANGUAGE_CONSTANTS

const LocaleTabs = ({ activeTab = LANGUAGE_TYPE.ENGLISH, intl, onChangeTab = () => { } }) => {
  return (
    <div className="locale-tabs">
      <span
        className={cx({ active: activeTab === LANGUAGE_TYPE.ENGLISH })}
        onClick={() => onChangeTab(LANGUAGE_TYPE.ENGLISH)}
      >
        {getLocaleMessage(intl, ENGLISH)}
      </span>
      <span
        className={cx({ active: activeTab === LANGUAGE_TYPE.SPANISH })}
        onClick={() => onChangeTab(LANGUAGE_TYPE.SPANISH)}
      >
        {getLocaleMessage(intl, SPANISH)}
      </span>
    </div>
  )
}

LocaleTabs.propTypes = {
  activeTab: PropTypes.number,
  intl: PropTypes.object,
  onChangeTab: PropTypes.func
}

export default localeMessageWrapper(LocaleTabs)
