import config from "./APIConfig"
import { getDataApi } from '../index'

export const getUserManagementPermissionVM = () => {
  const { url } = config.getUserManagementPermissionVM
  return getDataApi(url)
}

export const getUserManagementRolePermissionList = (data) => {
  const { url } = config.getUserManagementRolePermissionList
  return getDataApi(url(data))
}
