import { Fragment } from "react"
import { CardText } from "reactstrap"
import Proptypes from "prop-types"
import LoaderSpinner from "react-loader-spinner"
import UILoader from "@components/ui-loader"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "../locale-message"
import "../../../../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const CustomUILoader = ({ children, intl, isLoading, width, height, isMessageRequired, overlayColor }) => {
  const Loader = () => {
    return (
      <Fragment>
        <LoaderSpinner type="TailSpin" height={height} width={width} color="#1C72E7" />
        {isMessageRequired && <CardText className="mb-0 mt-2 text-white">
          {getLocaleMessage(intl, LANGUAGE_CONSTANTS.PLEASE_WAIT_MESSAGE)}
        </CardText>}
      </Fragment>
    )
  }

  return (
    <UILoader blocking={isLoading} overlayColor={overlayColor} loader={<Loader />}>
      {children}
    </UILoader>
  )
}

export default localeMessageWrapper(CustomUILoader)

CustomUILoader.defaultProps = {
  height: 50,
  isLoading: false,
  isMessageRequired: true,
  width: 50
}

CustomUILoader.propTypes = {
  children: Proptypes.any,
  height: Proptypes.number,
  isLoading: Proptypes.bool.isRequired,
  overlayColor: Proptypes.string,
  width: Proptypes.number
}
