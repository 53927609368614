import { PRIMARY_CONTACT_TYPE } from "@views/overview-page-sections/directory/directory-list/config"
import { getSearchQuery } from "../../config"

const apiConfig = {
  getProgramTaskOverviewList: {
    method: "GET",
    url: ({
      isUnitTaskList,
      isSiteTaskList,
      programId,
      campaignId,
      siteId: parentSiteId,
      siteUnitId,
      workflowId,
      pageNumber,
      pageSize,
      genericSearch = "",
      advanceSearch = {},
      orderColumn = "",
      orderDirections = ""
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ProgramId", programId),
        getSearchQuery("CampaignId", campaignId),
        getSearchQuery("SiteId", parentSiteId),
        getSearchQuery("SiteUnitId", siteUnitId),
        getSearchQuery("WFCampaignSetupId", workflowId),
        getSearchQuery(isSiteTaskList ? "GenericSearch" : "TaskName", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch || {}).length) {
        const {
          campaigns = [],
          campaignType = [],
          sites = [],
          unitName = '',
          taskName = '',
          taskType = [],
          assignees = [],
          taskStatus = [],
          dueDateFrom,
          dueDateTo
        } = advanceSearch

        const campaignSearch = campaigns.map((campaign) => getSearchQuery("CampaignIds", campaign.campaignId)).join("")
        const assigneeSearch = assignees.map((assignee) => getSearchQuery("AssigneeIds", assignee.value)).join("")
        const taskStatusSearch = taskStatus.map((status) => getSearchQuery("TaskStatusIds", status.value)).join("")
        const campaignTypes = campaignType.map((type) => getSearchQuery("CampaignTypeIds", type.value)).join("")
        const sitesList = sites.map((site) => getSearchQuery("SiteIds", site.value)).join("")
        const taskTypeList = taskType.map((type) => getSearchQuery("TaskTypeIds", type.value)).join("")

        searchString = [
          searchString,
          getSearchQuery("TaskDueDateFrom", dueDateFrom),
          getSearchQuery("TaskDueDateTo", dueDateTo),
          getSearchQuery("UnitName", unitName, false, true),
          getSearchQuery("TaskName", taskName, false, true)
        ].join('')

        if (isSiteTaskList) {
          const taskStatusList = getSearchQuery("TaskStatusIds", taskStatus.map((status) => status.value).join(","))
          const campaignList = getSearchQuery("CampaignIds", campaigns.map((campaign) => campaign.value).join(","))
          const assigneeList = getSearchQuery("AssigneeIds", assignees.map((assignee) => assignee.value).join(","))
          const campaignTypes = getSearchQuery("CampaignTypeIds", campaignType.map((type) => type.value).join(","))
          const taskTypeList = getSearchQuery("TaskTypeIds", taskType.map((type) => type.value).join(","))

          searchString = [
            searchString,
            taskStatusList,
            campaignList,
            assigneeList,
            campaignTypes,
            taskTypeList
          ].join('')
        } else {
          searchString = [
            searchString,
            campaignSearch,
            taskTypeList,
            assigneeSearch,
            sitesList,
            campaignTypes,
            taskStatusSearch
          ].join('')
        }

      }

      if (isUnitTaskList) {
        return `api/Program/UnitTaskList/${programId}/${siteUnitId}${searchString}`
      }

      if (isSiteTaskList) {
        return `api/Scheduling/GetTasksList/${searchString}`
      }

      return `api/Program/TaskOverviewList/${programId}${searchString}`
    }
  },
  getProgramTaskOverviewVM: {
    method: "GET",
    url: 'api/Program/GetProgramTaskOverviewVM'
  },
  getProgramUnitOverviewList: {
    method: "GET",
    url: ({
      programId,
      campaignId,
      siteId,
      pageNumber,
      pageSize,
      genericSearch = "",
      advanceSearch = {},
      orderColumn = "",
      orderDirections = ""
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ProgramId", programId),
        getSearchQuery("CampaignId", campaignId),
        getSearchQuery("SiteId", siteId),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch || {}).length) {
        const {
          campaignIds = [],
          campaignTypeId,
          siteId,
          unitName,
          unitTypeId,
          streetAddress,
          countryCode,
          state,
          city,
          zipCode,
          block,
          building,
          floor,
          planName,
          permit,
          phase
        } = advanceSearch
        const campaignSearch = campaignIds.map((campaign) => getSearchQuery("CampaignIds", campaign.value)).join("")
        searchString = [
          searchString,
          campaignSearch,
          getSearchQuery("CampaignTypeId", campaignTypeId),
          getSearchQuery("SiteId", siteId),
          getSearchQuery("UnitName", unitName, false, true),
          getSearchQuery("UnitTypeId", unitTypeId),
          getSearchQuery("CountryCode", countryCode),
          getSearchQuery("State", state),
          getSearchQuery("City", city, false, true),
          getSearchQuery("ZipCode", zipCode, false, true),
          getSearchQuery("Block", block),
          getSearchQuery("Building", building),
          getSearchQuery("Floor", floor),
          getSearchQuery("PlanName", planName, false, true),
          getSearchQuery("Permit", permit, false, true),
          getSearchQuery("Phase", phase, false, true),
          getSearchQuery("StreetAddress", streetAddress, false, true)
        ].join('')
      }

      return `api/Program/UnitOverviewList/${programId}${searchString}`
    }
  },
  getProgramUnitDetail: {
    method: "GET",
    url: ({ programId = 0, campaignId = 0, siteId, siteUnitId }) => {
      const searchString = [
        getSearchQuery("SiteUnitId", siteUnitId, true),
        getSearchQuery("ProgramId", programId),
        getSearchQuery("CampaignId", campaignId),
        getSearchQuery("SiteId", siteId)
      ].join('')

      return `api/Program/UnitDetail/${programId}/${siteUnitId}${searchString}`
    }
  },
  getProgramUnitOverviewVM: {
    method: "GET",
    url: 'api/Program/GetProgramUnitOverviewVM'
  },
  getProgramDirectoryOverviewList: {
    method: "GET",
    url: ({
      programId = 0,
      campaignId,
      organizationId,
      siteId,
      pageNumber,
      pageSize,
      genericSearch = "",
      advanceSearch = {},
      orderColumn = "",
      orderDirections = ""
    }) => {
      let primaryContact = ''
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ProgramId", programId),
        getSearchQuery("CampaignId", campaignId),
        getSearchQuery("OrganizationId", organizationId),
        getSearchQuery("SiteId", siteId),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch || {}).length) {
        const {
          sitePartyRoleId = "",
          contact = [],
          isPrimary,
          organization
        } = advanceSearch

        const contactSearch = contact.map((x) => getSearchQuery("ContactIds", x.value)).join("")
        const organizationSearch = organization.map((x) => getSearchQuery("OrganizationIds", x.organizationId)).join("")
        primaryContact = isPrimary ? isPrimary === PRIMARY_CONTACT_TYPE.YES : ''
        searchString = [
          searchString,
          getSearchQuery("SitePartyRoleId", sitePartyRoleId),
          contactSearch,
          organizationSearch
        ].join('')
      }

      return `api/Program/DirectoryOverviewList/${programId}${searchString}${primaryContact !== '' ? `&IsPrimary=${primaryContact}` : ''}`
    }
  },
  getProgramDirectoryOverviewVM: {
    method: "GET",
    url: 'api/Program/GetProgramDirectoryOverviewVM'
  },
  getProgramObservationTaskOverdueStatus: {
    method: "GET",
    url: ({ programId, campaignId, wfCampaignSetupId }) => {
      const searchString = [
        getSearchQuery("programId", programId, !!programId),
        getSearchQuery("campaignId", campaignId, !!campaignId),
        getSearchQuery("WFCampaignSetupId", wfCampaignSetupId, !!wfCampaignSetupId)
      ].join('')

      return `api/Program/ObservationTaskOverdueStatus${searchString}`
    }
  },
  getProgramUnitScheduleList: {
    method: "GET",
    url: ({ siteUnitId, advanceSearch = {}, genericSearch = "", pageNumber, pageSize }) => {
      let searchString = [
        getSearchQuery("SiteUnitId", siteUnitId, true),
        getSearchQuery("ScheduleLineItem", genericSearch, false, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const { autoStart, prereqs, status, usoWs } = advanceSearch
        const usoWsData = usoWs.map((usoW) => getSearchQuery("USOWIds", usoW.value)).join("")
        const prereqsData = prereqs.map((prereq) => getSearchQuery("Prereqs", prereq.value)).join("")
        const statusIdsData = getSearchQuery("ScheduleReadyStatusIds", status)
        searchString = [
          searchString,
          getSearchQuery("AutoStart", autoStart ? `${autoStart === 1}` : null),
          usoWsData,
          prereqsData,
          statusIdsData
        ].join('')
      }
      return `api/Project/Unit/Schedule${searchString}`
    }
  },
  getProgramScheduleVM: {
    method: "GET",
    url: ({ siteUnitId, sitePlanId }) => {
      const searchString = [
        getSearchQuery("SiteUnitId", siteUnitId, !!siteUnitId),
        getSearchQuery("SitePlanId", sitePlanId, !!sitePlanId)
      ].join('')
      return `api/Project/Schedule/VM${searchString}`
    }
  },
  saveProgramScheduleUnitLine: {
    method: "POST",
    url: ({ siteUnitId }) => `api/Project/Unit/Schedule/${siteUnitId}`
  },
  deleteProgramUnitScheduleLine: {
    method: "DELETE",
    url: ({ scheduleReadyId }) => {
      return `api/Project/Unit/Schedule/${scheduleReadyId}`
    }
  },
  getUnitSchedulePrereqsVM: {
    method: "GET",
    url: ({ siteUnitId, pageNumber, pageSize, scheduleLineItem }) => {
      const searchString = [
        getSearchQuery("SiteUnitId", siteUnitId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ScheduleLineItem", scheduleLineItem, false, true)
      ].join('')
      return `api/Project/DDL/UnitPrereq${searchString}`
    }
  },
  startScheduleLine: {
    method: "PUT",
    url: (scheduleReadyId) => `api/Project/Unit/StartScheduleLine/${scheduleReadyId}`
  },
  completeScheduleLine: {
    method: "PUT",
    url: (scheduleReadyId) => `api/Project/Unit/CompleteScheduleLine/${scheduleReadyId}`
  },
  reorderUnitScheduleLine: {
    method: "PUT",
    url: (siteUnitId) => `api/Project/Unit/Schedule/Reorder/${siteUnitId}`
  },
  reorderSitePlanScheduleLine: {
    method: "PUT",
    url: (sitePlanId) => `api/Project/SitePlan/Schedule/Reorder/${sitePlanId}`
  }
}

export default apiConfig
