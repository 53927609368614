import config from "./APIConfig"
import { deleteDataApi } from '../index'

export const deleteMultipleProjectSiteUnits = (idList = []) => {
  const { url } = config.deleteMultipleProjectSiteUnits
  const payload = {
    projectSiteUnitList: idList
  }
  return deleteDataApi(url, null, payload)
}

export const deleteProject = (data) => {
  const { url } = config.deleteProject
  return deleteDataApi(url({ projectId: data.projectId }))
}

export const deleteProjectLocation = (data) => {
  const { url } = config.deleteProjectLocation
  return deleteDataApi(url({ projectLocationId: data.projectLocationId }))
}

export const deleteProjectSite = (data) => {
  const { url } = config.deleteProjectSite
  return deleteDataApi(url({ siteId: data.siteId }))
}

export const deleteProjectSitePlan = (data) => {
  const { url } = config.deleteProjectSitePlan
  return deleteDataApi(url({ sitePlanId: data.sitePlanId }))
}

export const deleteProjectSitePlanSchedule = (data) => {
  const { url } = config.deleteProjectSitePlanSchedule
  return deleteDataApi(url(data))
}

export const deleteProjectSiteQATag = (data) => {
  const { url } = config.deleteProjectSiteQATag
  return deleteDataApi(url(data))
}

export const deleteProjectSiteRole = (data) => {
  const { url } = config.deleteProjectSiteRole
  return deleteDataApi(url({ siteRoleId: data.siteRoleId }))
}

export const deleteProjectSiteUnit = (data) => {
  const { url } = config.deleteProjectSiteUnit
  return deleteDataApi(url({ unitId: data.unitId }))
}

export const deleteSiteRoles = (idList = []) => {
  const { url } = config.deleteSiteRoles
  return deleteDataApi(url(idList))
}
