export const ASSIGNMENT_TYPE = {
  ROLE: "1",
  CONTACT: "2",
  CONTACT_GROUP: "3"
}

export const TASK_REVIEW_REQUIRED_TYPE = {
  YES: "1",
  NO: "0"
}
