// ** React Imports
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { DEFAULT_CHILD_TABLE_PAGE_SIZE, LIST_SORT_ORDER_DIRECTION } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { LocaleMessage } from '@views/components'
import { EXPANDABLE_TYPE } from "./config"
import TableView from "./index"

const { LOADER } = LANGUAGE_CONSTANTS

const ExpandableRowsTable = ({
  expandableRowsType,
  columns,
  data,
  expandedRowId,
  subData,
  isSubTable,
  expandableKey,
  keyField,
  noDataMessage,
  isChildHeaderRequired,
  isChildGridRefreshed,
  isDisableDeleteAllChildTableAction,
  onResetChildGridRefreshed = () => { },
  onSelectedRowsChange,
  clearSelectedChildRows,
  onChildSelectedRowsChange = () => { },
  onChildBulkDelete = () => { }
}) => {
  const dispatch = useDispatch()
  const [isLoadingChildGrid, setLoadingChildGrid] = useState(false)
  const [isGridFetched, setGridFetched] = useState(false)
  const [tableData, setTableData] = useState(null)

  const [sortOrderColumn, setSortOrderColumn] = useState('')
  const [sortOrderDirection, setSortOrderDirection] = useState('')

  const isUnitsTable = useMemo(() => expandableRowsType === EXPANDABLE_TYPE.SITE_UNITS_TABLE, [expandableRowsType])

  const handleFetchTableData = useCallback(
    ({
      orderColumn,
      orderDirections,
      page,
      isResetSortOrder = false
    }) => {
      const handleSetTableData = (res) => {
        if (!isGridFetched) {
          setGridFetched(true)
        }
        if (res) {
          setTableData(res)
        }
        setLoadingChildGrid(false)
      }

      const sortingData = isResetSortOrder ? { orderColumn, orderDirections } : { orderColumn: sortOrderColumn, orderDirections: sortOrderDirection }

      if (!isLoadingChildGrid && !!subData) {
        if (
          isUnitsTable &&
          data.siteId === expandedRowId
        ) {
          const selectedGrid = subData[`sub_grid_key_${expandedRowId}`]

          if (!!selectedGrid) {
            setLoadingChildGrid(true)
            dispatch(
              actions.getSiteUnitChildGridRequest(
                {
                  siteId: expandedRowId,
                  pageSize: DEFAULT_CHILD_TABLE_PAGE_SIZE,
                  pageNumber: page || tableData?.pageIndex || 1,
                  isLoadingRequired: false,
                  ...sortingData
                },
                handleSetTableData
              )
            )
          }
        } else if (
          expandableRowsType === EXPANDABLE_TYPE.SERVICE_CHECKLIST_TABLE &&
          data.campaignServiceCheckListId === expandedRowId
        ) {
          const selectedGrid = subData[`sub_grid_key_${expandedRowId}`]

          if (!!selectedGrid) {
            // setLoadingChildGrid(true)
            //Todo: call action
          }
        }
      }
    },
    [
      data.siteId,
      data.campaignServiceCheckListId,
      expandedRowId,
      isGridFetched,
      isLoadingChildGrid,
      subData,
      sortOrderColumn,
      sortOrderDirection,
      tableData
    ]
  )

  useEffect(() => {
    if (!isGridFetched && !isLoadingChildGrid && !!subData) {
      handleFetchTableData({ page: 1 })
    }
  }, [
    data.siteId,
    data.campaignServiceCheckListId,
    expandedRowId,
    isGridFetched,
    isLoadingChildGrid,
    subData
  ])

  useEffect(() => {
    if (isChildGridRefreshed) {
      handleFetchTableData({})
      onResetChildGridRefreshed()
    }
  }, [isChildGridRefreshed])

  const handleOnPageChange = useCallback(
    (page) => {
      handleFetchTableData({ page })
    },
    [handleFetchTableData]
  )

  const handleSortList = useCallback((column, sortDirection) => {
    if (column.sortKey) {
      setSortOrderColumn(column.sortKey)
      const direction = sortDirection === LIST_SORT_ORDER_DIRECTION.ASC ? "0" : "1"
      setSortOrderDirection(direction)

      handleFetchTableData({
        isPageLoaderRequired: false,
        orderColumn: column.sortKey,
        orderDirections: direction,
        isResetSortOrder: true
      })
    }
  }, [handleFetchTableData])

  //Todo: Note needs to be updated for campaignServiceCheckListId table
  let keyId = "siteId"
  if (data.campaignServiceCheckListId) {
    keyId = "campaignServiceCheckListId"
  }

  if (
    !isGridFetched &&
    isLoadingChildGrid &&
    (expandedRowId === data[keyId] ||
      (expandableRowsType === EXPANDABLE_TYPE.SCHEDULING_UNITS &&
        expandedRowId &&
        data.organizationId === expandedRowId.organizationId &&
        data.projectId === expandedRowId.projectId &&
        data.siteId === expandedRowId.siteId))
  ) {
    return <p className="no-data-txt"><LocaleMessage id={LOADER.LOADING} /></p>
  }

  if (isSubTable && !isGridFetched) {
    if (!subData) {
      return <p className="no-data-txt">{noDataMessage}</p>
    }

    const selectedGrid = subData[`sub_grid_key_${data[keyId]}`]

    if (!selectedGrid) {
      return <p className="no-data-txt">{noDataMessage}</p>
    }

    if (!tableData?.items.length) {
      return <p className="no-data-txt">{noDataMessage}</p>
    }
  }

  return (
    <div
      className={`p-1 ${isChildHeaderRequired ? "header-expandable-content" : ""
        } ${expandableKey === "units" ? "projects-expandable-content" : ""}`}
    >
      <TableView
        isShowBulkActions={isUnitsTable}
        columns={columns}
        data={tableData?.items || []}
        isChildGrid
        isLoading={isLoadingChildGrid}
        isTableMarginRequired={isUnitsTable}
        keyField={keyField}
        sortServer
        noDataMessage={noDataMessage}
        tableViewHeaderRequired={false}
        noTableDataClassRequired={false}
        totalCount={tableData?.totalCount || 0}
        isDisableDeleteAllAction={isDisableDeleteAllChildTableAction}
        onPagination={handleOnPageChange}
        onSelectedRowsChange={isUnitsTable ? onChildSelectedRowsChange : onSelectedRowsChange}
        clearSelectedRows={clearSelectedChildRows}
        onSort={handleSortList}
        onBulkDelete={onChildBulkDelete}
      />
    </div>
  )
}

ExpandableRowsTable.propTypes = {
  expandableRowsType: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  expandedRowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  subData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  isSubTable: PropTypes.bool,
  expandableKey: PropTypes.string,
  keyField: PropTypes.string,
  noDataMessage: PropTypes.string,
  isChildHeaderRequired: PropTypes.bool,
  isChildGridRefreshed: PropTypes.bool,
  onResetChildGridRefreshed: PropTypes.func,
  onSelectedRowsChange: PropTypes.func,
  clearSelectedChildRows: PropTypes.bool
}

ExpandableRowsTable.defaultProps = {
  columns: [],
  data: [],
  expandedRowId: "",
  subData: [],
  isSubTable: false,
  expandableKey: "",
  keyField: "",
  noDataMessage: "",
  isChildGridRefreshed: false
}

export default ExpandableRowsTable
