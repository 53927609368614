import config from "./APIConfig"
import { postDataApi } from '../index'

export const addProjectDetails = (data) => {
  const { url } = config.addProjectDetails
  const payload = {
    projectName: data.projectName || '',
    projectTypeId: parseInt(data.projectType || 0),
    organizationId: parseInt(data.projectOrganization || 0),
    projectStatusId: parseInt(data.projectStatus || 0)
  }
  return postDataApi(url(data.projectOrganization), payload)
}

export default addProjectDetails
