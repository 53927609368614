import { GET_RESOURCE_LIST_VM } from '../../actions'

// ** Initial State
const initialState = { 
  listVM: {
    organizations: [],
    projects: [],
    sites: [],
    units: [],
    resourceCenterSources: [],
    programType: [],
    campaignType: [],
    campaignServiceType: [],
    fileType: [],
    uploadedBy: []
  }
}

const ResourceReducer = (state = initialState, action) => {
  switch (action.type) {
    /** GET_RESOURCE_LIST_VM **/

    case GET_RESOURCE_LIST_VM.REQUEST:
      return {
        ...state,
        error: ''
      }
    case GET_RESOURCE_LIST_VM.SUCCESS:
      return {
        ...state,
        listVM: {
          organizations: action.data.organizationDDL.items,
          projects: action.data.projects.items,
          sites: action.data.sites.items,
          units: action.data.units.items,
          resourceCenterSources: action.data.resourceCenterSources,
          programType: action.data.program.items,
          campaignType: action.data.campaign.items,
          campaignServiceType: action.data.campaignService?.items || [],
          fileType: action.data.fileType.items,
          uploadedBy: action.data.uploadedBy.items,
          fileCategoryType: action.data.fileCategory || []
        }
      }
    case GET_RESOURCE_LIST_VM.FAILURE:
      return { ...state, error: action.error }

    default:
      return state
  }
}

export default ResourceReducer
