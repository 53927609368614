export const getTaskPageLink = ({
    isSchedulingPage,
    observationTaskId
}) => {
    let pathname = ""

    if (observationTaskId) {
        pathname = window.location.pathname

        if (isSchedulingPage) {
            pathname = pathname.concat("/task-overview/")
        } else {
            pathname = pathname.concat("/tasks/")
        }
        pathname = pathname.concat(observationTaskId)
    }

    return pathname
}
