import cx from "classnames"
import React, { useCallback, useMemo } from "react"
import { Col, CustomInput, Input, Row } from "reactstrap"
import { Search } from "react-feather"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import { SideModalCloseButton } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import CheckboxItem from "./CheckboxItem"

const { LOADER, SEARCH_NAME, NO_OPTIONS, SCHEDULING:{SEARCH_ABOVE_TO_GENERATE_OPTIONS} } = LANGUAGE_CONSTANTS

const CustomCheckboxSearchComponent = ({
  id,
  valueType,
  labelType = "text",
  allSelectCheckboxLabel = "",
  inputPlaceholder,
  intl,
  filtersList,
  selectedFilters,
  lastElementIndex,
  isLoading,
  hasNoOptions,
  onChangeSearchText,
  onChangeHeaderCheckbox,
  onClearSearchText,
  onSelectFilter,
  searchText
}) => {
  const handleHeaderCheckbox = useCallback((e) => {
    if (e.target.checked) {
      onChangeHeaderCheckbox(filtersList)
    } else {
      onChangeHeaderCheckbox([])
    }
  }, [filtersList, onChangeHeaderCheckbox])

  const isAllChecked = useMemo(() => !!filtersList.length && selectedFilters.length === filtersList.length, [selectedFilters, filtersList])
  const showSearchInfoText = useMemo(() => !searchText.length || (!searchText.length && selectedFilters.length), [searchText, selectedFilters])

  return (
    <div className="custom-checkbox-search">
      <div className="mb-1">
        <CustomInput
          value={""} /** Todo: handle value and on change */
          type="checkbox"
          className="mb-1"
          id={`${id}_filter`}
          label={allSelectCheckboxLabel}
          onChange={handleHeaderCheckbox}
          checked={isAllChecked}
          disabled={!filtersList.length}
        />
        <Row>
          <Col md={6}>
            <div className="search-input-box">
              <Search />
              <Input
                type="text"
                name={valueType}
                value={searchText}
                placeholder={inputPlaceholder || getLocaleMessage(intl, SEARCH_NAME)}
                onChange={onChangeSearchText}
              />
            </div>
          </Col>
          <div className="d-flex justify-content-center align-items-center">
            <SideModalCloseButton onClose={onClearSearchText} />
          </div>
        </Row>
      </div>
      {showSearchInfoText && <div className="filter-list mb-1">
        <span className="search-infotext">{getLocaleMessage(intl, SEARCH_ABOVE_TO_GENERATE_OPTIONS)}
        </span>
      </div>}
      <div className={cx("filter-list", {
        "mb-1": hasNoOptions || isLoading
      })}>
        {filtersList.map((filter, index) => (
          <CheckboxItem
            key={`filter_${index}`}
            id={id}
            valueType={valueType}
            labelType={labelType}
            selectedFilters={selectedFilters}
            lastElementIndex={lastElementIndex}
            index={index}
            filter={filter}
            onSelectFilter={onSelectFilter}
          />
        ))}
        {(isLoading || hasNoOptions) && (
          <div>
            <span>
              <strong>{getLocaleMessage(intl, isLoading ? LOADER.LOADING : NO_OPTIONS)}</strong>
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default localeMessageWrapper(CustomCheckboxSearchComponent)
