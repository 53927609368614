import config from "../APIConfig"
import { postDataApi } from "../../../index"

/**
 * @method addActivityGroup
 * 
 * @param data 
 * {
    "groupName": "string",
    "wfCampaignSetupChecklistId": 0
  }
 * @returns 
 */
export const addActivityGroup = (payload) => {
  const { url } = config.addActivityGroup

  return postDataApi(url, payload)
}

export default addActivityGroup
