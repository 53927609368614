import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method cloneTemplateTrainingActivity
 * @param {object} data 
 *
 */
export const cloneTemplateTrainingActivity = (data) => {
  const { url } = config.cloneTemplateTrainingActivity
  return postDataApi(url(data))
}

export default cloneTemplateTrainingActivity
