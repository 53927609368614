import cx from "classnames"
import React, { useEffect, useState } from "react"
import {
    Button,
    Col,
    FormGroup,
    Row,
    Input
} from "reactstrap"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import {
    CustomLabel,
    ErrorMessage,
    FileUploader
} from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import BlueprintSearch from "./BlueprintSearch"

const { REMOVE, ORG_PLANS } = LANGUAGE_CONSTANTS

const BlueprintSection = ({
    intl,
    errors,
    fileUploaderColWrapperProps,
    isEnableAddNewPlanMode,
    isEnableAddNewBlueprintMode, //Note: When going to add new plan by clicking "Add New Blueprint" button
    isEditMode,
    isOrgPlan,
    planDetail,
    blueprintFiles = [],
    onChangeDetail = () => { },
    onSaveNewBlueprint = () => { },
    onToggleAddNewBlueprintMode = () => { }
}) => {
    const [defaultOptions, setDefaultOptions] = useState(isEditMode && planDetail.blueprintId ? [
        {
            text: planDetail.blueprintName,
            value: planDetail.blueprintId
        }
    ] : [])

    const [isRequiresDefaultOptionsUpdates, setRequiresDefaultOptionsUpdates] = useState(false)

    useEffect(() => {
        if (!isEnableAddNewBlueprintMode) {
            setRequiresDefaultOptionsUpdates(true)
        }
    }, [isEnableAddNewBlueprintMode])

    useEffect(() => {
        if (isRequiresDefaultOptionsUpdates && !isOrgPlan && planDetail.blueprintId && planDetail.blueprintName) {
            setRequiresDefaultOptionsUpdates(false)
            setDefaultOptions([
                {
                    text: planDetail.blueprintName,
                    value: planDetail.blueprintId
                }
            ])

        }
    }, [isRequiresDefaultOptionsUpdates, planDetail.blueprintName, planDetail.blueprintId])

    return (
        <>
            <Row>
                <Col className="mb-1" {...fileUploaderColWrapperProps} >
                    <FormGroup>
                        <div className="unit-plan-subtitle">
                            <CustomLabel title={isEnableAddNewBlueprintMode ? ORG_PLANS.BLUEPRINT_NAME : ORG_PLANS.SELECT_BLUEPRINT} required={isOrgPlan ? isEnableAddNewBlueprintMode : isEnableAddNewPlanMode } requiredIconClassName="required-field" />
                            {((isOrgPlan && isEditMode && isEnableAddNewPlanMode) || (!isOrgPlan && !isEnableAddNewPlanMode)) && <button
                                className={cx("waves-effect btn", {
                                    "primary-text-btn": !isEnableAddNewBlueprintMode,
                                    "danger-text-btn": isEnableAddNewBlueprintMode
                                })}
                                onClick={onToggleAddNewBlueprintMode}
                            >
                                {getLocaleMessage(intl, isEnableAddNewBlueprintMode ? REMOVE : ORG_PLANS.ADD_NEW_BLUEPRINT)}
                            </button>}
                        </div>
                        {!isEnableAddNewBlueprintMode ? <BlueprintSearch
                            organizationPlanId={planDetail.organizationPlanId}
                            errors={errors}
                            isEditMode={isEditMode}
                            isDisabled={isEnableAddNewBlueprintMode || !planDetail.organizationPlanId}
                            value={planDetail.blueprintId}
                            defaultOptions={defaultOptions}
                            onSelect={onChangeDetail}
                        /> : <>
                            <FormGroup>
                                <Input
                                    placeholder={getLocaleMessage(intl, ORG_PLANS.SELECT_BLUEPRINT_FIRST)}
                                    value={planDetail.blueprintName}
                                    onChange={(event) => onChangeDetail("blueprintName", event.target.value)}
                                />
                            </FormGroup>
                            <ErrorMessage
                                className="error-message"
                                isShow={!!errors["blueprintName"]}
                                message={errors["blueprintName"]}
                            />
                        </>}
                    </FormGroup>
                </Col>
                <Col className="mb-1" {...fileUploaderColWrapperProps}>
                    <FileUploader
                        id="blueprintFiles"
                        label={ORG_PLANS.BLUEPRINT_FILES}
                        isRequired={isOrgPlan ? isEnableAddNewBlueprintMode : isEnableAddNewPlanMode}
                        defaultFileCategoryLabel="Blueprint"
                        isUploadDisabled={!isEnableAddNewBlueprintMode}
                        canUploadFiles={!planDetail.blueprintId}
                        canDeleteFiles={!planDetail.blueprintId}
                        isDisableFileCategory
                        value={blueprintFiles}
                        errors={errors}
                        onChangeDetail={onChangeDetail} />
                </Col>
            </Row>
            {!!planDetail.organizationPlanId && isEnableAddNewBlueprintMode && <Row className="mb-1">
                <Col md={4}>
                    <Button
                        className={"primary-solid w-100"}
                        color="primary"
                        onClick={onSaveNewBlueprint}
                    >
                        {getLocaleMessage(intl, ORG_PLANS.SAVE_BLUEPRINT)}
                    </Button>
                </Col>
            </Row>}
        </>
    )
}

export default localeMessageWrapper(BlueprintSection)
