export const rowsPerPageOptions = [
  { text: 100, value: 100 },
  { text: 500, value: 500 },
  { text: 1000, value: 1000 }
]

export const FILTER_SECTION_TYPE = {
  SAVED_FILTERS: 'saved_filters',
  TABLE_COLUMN: 'table_column',
  SAVE_FILTER_CONFIG: 'save_filter_config'
}
