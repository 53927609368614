import { takeLatest, put, call } from "redux-saga/effects"

import {
  addServiceChecklist,
  addCheckpointToCheckList,
  deleteServiceChecklist,
  getServiceChecklistDetail,
  getCampaignCheckListVM,
  getCampaignSetupCheckListVM,
  updateServiceChecklist,
  updateChecklistOrder
} from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'
import * as actions from '@store/actions'

const { SERVICE_CHECKLIST_SAGA, CHECKLIST_ACTIVITIES_SAGA, WORKFLOW_ADD_TRAINING_SAGA } = LANGUAGE_CONSTANTS

/** addServiceChecklist API **/
function* addServiceChecklistRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addServiceChecklist, action.payload)
    if (response?.data) {
      if (action.callback) {
        action.callback(response.data)
      }
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: SERVICE_CHECKLIST_SAGA.ADDED_SUCCESS })
    } else {
      if (action.callback) {
        action.callback(null)
      }
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: SERVICE_CHECKLIST_SAGA.ERROR_IN_ADDING })
    }
  } catch (error) {
    if (action.callback) {
      action.callback(null)
    }
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: SERVICE_CHECKLIST_SAGA.ERROR_IN_ADDING })
  }
}

/** getServiceChecklistDetail API **/
function* getServiceChecklistDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getServiceChecklistDetail, action.payload)
    if (!response?.data) {
      Notification({ status: 400, message: SERVICE_CHECKLIST_SAGA.INVALID_ID })
    }
    action.callback(response?.data || false)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** deleteServiceChecklist API **/
function* deleteServiceChecklistRequest(data) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteServiceChecklist, data.payload)
    if (response && (response.status === 204 || response.status === 200)) {
      if (data.callback) {
        data.callback()
      }
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: SERVICE_CHECKLIST_SAGA.DELETED_SUCCESS })
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: SERVICE_CHECKLIST_SAGA.ERROR_IN_DELETING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: SERVICE_CHECKLIST_SAGA.ERROR_IN_DELETING })
  }
}

/** updateServiceChecklist API **/
function* updateServiceChecklistRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateServiceChecklist, action.payload)
    if (response?.status === 200) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: SERVICE_CHECKLIST_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: SERVICE_CHECKLIST_SAGA.ERROR_IN_UPDATING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: SERVICE_CHECKLIST_SAGA.ERROR_IN_UPDATING })
    action.callback()
  }
}

/** addCheckpointToCheckList API **/
function* addCheckpointToChecklistRequest(action) {
  try {
    const { isTrainingWFTemplate, ...payload } = action.payload
    yield put(actions.setIsLoading(true))
    const response = yield call(addCheckpointToCheckList, payload)
    if (response?.data) {
      if (action.callback) {
        action.callback(response.data)
      }
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: isTrainingWFTemplate ? WORKFLOW_ADD_TRAINING_SAGA.ACTIVITIES_ADDED_SUCCESS : SERVICE_CHECKLIST_SAGA.ACTIVITIES_ADDED_SUCCESS })
    } else {
      if (action.callback) {
        action.callback(null)
      }
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: isTrainingWFTemplate ? WORKFLOW_ADD_TRAINING_SAGA.ACTIVITIES_ADDED_SUCCESS : SERVICE_CHECKLIST_SAGA.ERROR_IN_ADDING_ACTIVITIES })
    }
  } catch (error) {
    const { isTrainingWFTemplate } = action.payload
    if (action.callback) {
      action.callback(null)
    }
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: isTrainingWFTemplate ? WORKFLOW_ADD_TRAINING_SAGA.ACTIVITIES_ADDED_SUCCESS : SERVICE_CHECKLIST_SAGA.ERROR_IN_ADDING_ACTIVITIES })
  }
}

/** getCampaignCheckListVM API **/
function* getCampaignChecklistVMRequest(action) {
  try {
    const response = yield call(getCampaignCheckListVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getCampaignSetupCheckListVM API **/
function* getCampaignSetupChecklistVMRequest(action) {
  try {
    const response = yield call(getCampaignSetupCheckListVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateChecklistOrder API **/
function* updateChecklistOrderRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateChecklistOrder, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: CHECKLIST_ACTIVITIES_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: CHECKLIST_ACTIVITIES_SAGA.ERROR_IN_UPDATING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: CHECKLIST_ACTIVITIES_SAGA.ERROR_IN_UPDATING })
    action.callback()
  }
}

export function* serviceChecklistSagas() {
  yield takeLatest(actions.ADD_SERVICE_CHECKLIST_REQUEST, addServiceChecklistRequest)
  yield takeLatest(actions.ADD_CHECKPOINT_TO_CHECKLIST_REQUEST, addCheckpointToChecklistRequest)
  yield takeLatest(actions.GET_SERVICE_CHECKLIST_REQUEST, getServiceChecklistDetailRequest)
  yield takeLatest(actions.GET_CAMPAIGN_CHECKLIST_VM_REQUEST, getCampaignChecklistVMRequest)
  yield takeLatest(actions.GET_CAMPAIGN_SETUP_CHECKLIST_VM_REQUEST, getCampaignSetupChecklistVMRequest)
  yield takeLatest(actions.DELETE_SERVICE_CHECKLIST_REQUEST, deleteServiceChecklistRequest)
  yield takeLatest(actions.UPDATE_SERVICE_CHECKLIST_REQUEST, updateServiceChecklistRequest)
  yield takeLatest(actions.UPDATE_CAMPAIGN_CHECKLIST_ORDER_REQUEST, updateChecklistOrderRequest)
}

export default serviceChecklistSagas
