import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import * as actions from "@store/actions"
import { CustomReactSelect, CustomLabel } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const SitePlansSearch = ({
  id = "",
  name = "",
  value = "",
  initialLabel = "",
  siteIds = [],
  label = "",
  placeholder = "",
  isMulti = false,
  labelRequired = true,
  isColWrapper = true,
  onSelect = () => { }
}) => {

  const dispatch = useDispatch()
  const [options, setOptions] = useState([])
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  useEffect(() => {
    const fn = () => {
      setTimeout(() => {
        setResetCache((prevState) => !prevState)
        setOptions([])
        if (!!initialLabel && !!value) {
          setSelectedOptionLabel(initialLabel)
        }
      }, [100])
    }
    fn()
  }, [initialLabel, siteIds])

  const handleLoadMore = useCallback((search, page, prevOptions, others) => {
    return new Promise((resolve) => {
      if (others.isReset && !prevOptions.length && options.length) {
        resolve({
          optionList: options,
          hasMore: true
        })
        return
      }
      if (siteIds.length) {
        const params = {
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          planName: search,
          siteIds
        }
        dispatch(
          actions.getSitePlansWithVersionVMRequest(params, (response) => {
            if (response) {
              resolve({
                optionList: response
              })
              setOptions(() => (!!prevOptions.length ? [...prevOptions, ...response] : response))
            } else {
              resolve({
                optionList: [],
                hasMore: false
              })
            }
          })
        )
      } else {
        resolve({
          optionList: [],
          hasMore: false
        })
      }
    })
  }, [siteIds])

  useEffect(() => {
    if (!!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])

  const WrapperComponent = useMemo(() => (isColWrapper ? Col : 'div'), [isColWrapper])

  const colWrapperProps = useMemo(() => {
    if (!isColWrapper) {
      return {}
    }

    return {
      className: "mb-1",
      sm: 12,
      md: 6,
      xl: 4
    }
  }, [isColWrapper])

  return (
    <WrapperComponent {...colWrapperProps}>
      {labelRequired && <CustomLabel title={label} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id={id}
        name={name}
        placeholder={placeholder}
        options={options}
        lableType={"text"}
        valueType={"value"}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={
          !isMulti
            ? !!value
              ? {
                text: selectedOptionLabel,
                value
              }
              : null
            : value
        }
        isMulti={isMulti}
        isShowCustomAction={false}
        hideSelectedOptions={false}
        getOptionLabel={(option) => option.text}
        getOptionValue={(option) => option.value}
        onLoadOptions={handleLoadMore}
        onSelect={(selected) => onSelect(id, selected)}
      />
    </WrapperComponent>
  )
}

export default localeMessageWrapper(SitePlansSearch)