import React from 'react'
import { Button } from 'reactstrap'

import { EXPORT_TYPES } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { ConfirmationModal } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const { REPORTS: { DATA_ACCESS } } = LANGUAGE_CONSTANTS

const ExportDataConfirmModal = ({
    intl,
    isShowExportModal = false,
    exportType = "",
    onToggleExportModal = () => { },
    onConfirmExport = () => { }
}) => {
  return (
    <ConfirmationModal
    isWarning
    isFooterChildren
    open={isShowExportModal}
    title={
      exportType === EXPORT_TYPES.CSV ?
      getLocaleMessage(intl, DATA_ACCESS.EXPORT_TITLE)
      : exportType === EXPORT_TYPES.DOCX ?
      getLocaleMessage(intl, DATA_ACCESS.CONFIRM_DOC_EXPORT)
      : getLocaleMessage(intl, DATA_ACCESS.CONFIRM_PDF_EXPORT)
    }
    onToggleModal={onToggleExportModal}
  >
    {
      exportType === EXPORT_TYPES.CSV ? (
        <>
        <Button className="bg-btn" onClick={() => onConfirmExport(true)}>{getLocaleMessage(intl, DATA_ACCESS.ALL_ROWS)}</Button>
        <Button className="bg-btn" onClick={() => onConfirmExport(false)}>{getLocaleMessage(intl, DATA_ACCESS.CURRENT_PAGE)}</Button>
        </>
      ) : (
        <Button className="bg-btn" onClick={() => onConfirmExport(true, exportType)}>{getLocaleMessage(intl, DATA_ACCESS.EXPORT)}</Button>
      )
    }
  </ConfirmationModal>
  )
}

export default localeMessageWrapper(ExportDataConfirmModal)