import { put, takeLatest, call } from "redux-saga/effects"

import * as services from "@services/api"
import * as actions from "@store/actions"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'
const { USER_ACCESS_SAGA } = LANGUAGE_CONSTANTS

/** getUserManagementContactVM API **/
function* getUserManagementContactVM(action) {
  try {
    const response = yield call(services.getUserManagementContactVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getUserManagementUserDetail API **/
function* getUserManagementUserDetail(action) {
  try {
    const response = yield call(services.getUserManagementUserDetail, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getUserManagementProfileType API **/
function* getUserManagementProfileType(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.getUserManagementProfileType, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getUserManagementUserRole API **/
function* getUserManagementUserRole(action) {
  try {
    const response = yield call(services.getUserManagementUserRole, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getUserManagementUserAccess API **/
function* getUserManagementUserAccessRequest(action) {
  try {
    const response = yield call(services.getUserManagementUserAccess, action.payload)
    action.callback(response?.data)
    if (response?.data) {
      yield put(actions.getUserManagementUserAccessSuccess({ ...response.data, userId: action.payload }))
    } else {
      yield put(actions.getUserManagementUserAccessFailure("Unable to load user access list"))
    }
  } catch (error) {
    action.callback()
    yield put(actions.getUserManagementUserAccessFailure(error.message))
  }
}

/** createUserManagementUserAccess API **/
function* createUserManagementUserAccess({ payload, callback = () => { } }) {
  try {
    const { isDeleteAction = false, isUpdateAction = false, ...payloadData } = payload
    yield put(actions.setIsLoading(true))
    const response = yield call(services.createUserManagementUserAccess, payloadData)
    if (response?.status === 200) {
      callback(response?.data)
      Notification({ status: 200, message: isDeleteAction ? USER_ACCESS_SAGA.DELETED_SUCCESS : isUpdateAction ? USER_ACCESS_SAGA.UPDATE_SUCCESS : USER_ACCESS_SAGA.ADDED_SUCCESS })
    } else {
      callback()
      Notification({ status: 400, message: isDeleteAction ? USER_ACCESS_SAGA.ERROR_IN_DELETING : isUpdateAction ? USER_ACCESS_SAGA.ERROR_IN_UPDATING : USER_ACCESS_SAGA.ERROR_IN_ADDING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    callback()
    yield put(actions.setIsLoading(false))
  }
}

export function* userManagementContactSagas() {
  yield takeLatest(actions.GET_USER_MANAGEMENT_CONTACT_VM, getUserManagementContactVM)
  yield takeLatest(actions.GET_USER_MANAGEMENT_USER_DETAIL, getUserManagementUserDetail)
  yield takeLatest(actions.GET_USER_MANAGEMENT_USER_ACCESS.REQUEST, getUserManagementUserAccessRequest)
  yield takeLatest(actions.CREATE_USER_MANAGEMENT_USER_ACCESS, createUserManagementUserAccess)
  yield takeLatest(actions.GET_USER_MANAGEMENT_USER_ROLE, getUserManagementUserRole)
  yield takeLatest(actions.GET_USER_MANAGEMENT_PROFILE_TYPE, getUserManagementProfileType)
}

export default userManagementContactSagas