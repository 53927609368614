import React, { useCallback } from "react"
import { useDispatch } from "react-redux"

import * as actions from "@store/actions"
import CustomCheckboxSearch from "./custom-checkbox-search"

const StateName = ({
  id = "state",
  allSelectCheckboxLabel = "",
  inputPlaceholder,
  valueType = "text",
  labelType = "value",
  value = [],
  regions = [],
  subRegions = [],
  cities = [],
  zipCodes = [],
  isResetClicked = false,
  onSelect = () => { },
  onResetClicked = () => { }
}) => {
  const dispatch = useDispatch()

  const handleFetchOptions = useCallback((params, callback = () => { }) => {
    const queryPayload = {
      ...params,
      stateName: params.searchText,
      regions,
      subRegions,
      cities,
      zipCodes
    }
    dispatch(actions.getCalendarStatesDDLRequest(queryPayload, callback))
  }, [regions, subRegions, cities, zipCodes])

  const handleSelectFilter = useCallback(
    (value) => {
      onSelect(id, value)
    },
    [onSelect]
  )

  return (
    <CustomCheckboxSearch
      id={id}
      valueType={valueType}
      labelType={labelType}
      allSelectCheckboxLabel={allSelectCheckboxLabel}
      inputPlaceholder={inputPlaceholder}
      onFetchOptions={handleFetchOptions}
      onSelectFilter={handleSelectFilter}
      onResetClicked={onResetClicked}
      selectedFilters={value}
      isResetClicked={isResetClicked}
    />
  )
}

export default StateName
