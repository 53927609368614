import React, { useState, useCallback, useEffect, useMemo } from "react"
import { Search } from "react-feather"
import PropTypes from 'prop-types'
import { useDispatch } from "react-redux"

import * as actions from "@store/actions"
import { DEFAULT_VM_PAGE_SIZE, MODAL_TYPES } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { CustomReactSelect, CustomLabel } from "@views/components"
import EditPlanModal from "@views/projects/add-new-project/sites/site-plans/EditPlanModal"

import localeMessageWrapper from '../locale-message'

const { PLANS_SEARCH, SITE_PROJECT_PLANS } = LANGUAGE_CONSTANTS

const PlansSearch = ({
  customActionLabel,
  defaultOptions,
  id,
  intl,
  isEditMode,
  isPaginationRequired,
  isShowCustomAction,
  label,
  labelRequired,
  name,
  placeholder,
  siteId,
  value,
  onRefreshOptions,
  onSelect
}) => {
  const dispatch = useDispatch()
  /**
 * Note: Paginated select maintain cache of options,
 * so to set new default options we need to reset previous cache
 */
  const [isResetCache, setResetCache] = useState(false)

  const [selectedOptionLabel, setSelectedOptionLabel] = useState('')
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)

  const [isFocused, setFocused] = useState(false)
  const [options, setOptions] = useState(defaultOptions)
  const [isOpenAddSitePlanModal, setOpenAddSitePlanModal] = useState(false)

  /**
   * @method handleSetDefaultOptions : To handle set default options in case of Edit mode, Add new from modal
   *
   * Note: To handle case of duplicate option, when custom option is added, that is selected from edit mode or add new modal
   */
  const handleSetDefaultOptions = useCallback((selectedItem, selectedPlanId) => {
    const checkIfAlreadyPresent = defaultOptions.find(option => option.value === selectedPlanId)
    if (!!checkIfAlreadyPresent) {
      setOptions(defaultOptions)
    } else {
      setOptions([selectedItem, ...defaultOptions])
    }
  }, [defaultOptions])

  useEffect(() => {
    setResetCache((prevState) => !prevState)
    setOptions(defaultOptions)
  }, [defaultOptions])

  //To handle edit mode when single select
  useEffect(() => {
    //Note: To get option details for selected value in edit mode 
    if (isEditMode) {
      if (!isEditModeItemLoaded && !!value) {
        /**
         * Note: Currently label for value in edit mode is fetched from current options.
         * If we have pagination concept, then we need to fetch inital label from plan detail api,
         * Else we need initial label from parent component
         */

        setEditModeItemLoaded(true)
        const planId = Number(value)
        const selectedItem = defaultOptions.find(item => Number(item.value) === planId)
        if (selectedItem) {
          setSelectedOptionLabel(selectedItem.text || "")
          setResetCache((prevState) => !prevState)
          handleSetDefaultOptions(selectedItem, value)
        }
      }
    }
  }, [isEditMode, value, isEditModeItemLoaded])

  useEffect(() => {
    //Note: To get option details for selected value in edit mode 
    if (isEditMode) {
      if (!!value) {
        setEditModeItemLoaded(true)
        const planId = Number(value)
        const selectedItem = defaultOptions.find(item => Number(item.value) === planId)
        if (selectedItem) {
          setSelectedOptionLabel(selectedItem.text || "")
          setResetCache((prevState) => !prevState)
          handleSetDefaultOptions(selectedItem, value)
        }
      }
    }
  }, [isEditMode, value])

  const handleFocusSearch = useCallback(() => {
    setFocused(true)
  }, [])

  const handleBlurSearch = useCallback(() => {
    setFocused(false)
  }, [])

  const handleToggleAddSitePlanModal = useCallback(
    () => {
      setOpenAddSitePlanModal(!isOpenAddSitePlanModal)
    },
    [isOpenAddSitePlanModal]
  )

  const handleCustomAction = useCallback((e) => {
    e.preventDefault()
    handleToggleAddSitePlanModal()
  }, [handleToggleAddSitePlanModal])

  const handleAutoSelectFromModal = useCallback((selected) => {
    setFocused(false)
    onSelect(id, selected.value || '', selected.text || '', () => {
      setSelectedOptionLabel(selected.text || '')
    })
    onRefreshOptions()
  }, [id, onRefreshOptions, onSelect])

  const handleSelectOption = useCallback((selected, data) => {
    if (data?.action === 'clear') {
      return null
    }
    setFocused(false)
    onSelect(id, selected?.value || '', selected?.text || '', () => {
      setSelectedOptionLabel(selected?.text || '')
    })
  }, [onSelect])

  const handleLoadMoreOptions = useCallback(
    (search, page, prevOptions) => {
      return new Promise((resolve) => {

        const handleSearchResultCallback = (response) => {
          if (!!response.items) {
            const { hasNextPage } = response
            let { items } = response
            items = items.map(option => ({
              text: option.planName,
              value: option.sitePlanId
            }))
            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
            resolve({
              optionList: items,
              hasMore: hasNextPage
            })
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }
        const params = {
          siteId,
          advanceSearch: {
            planName: search
          },
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE
        }

        dispatch(
          actions.getProjectSitePlanListRequest(params, handleSearchResultCallback))
      }

      )
    }, [options, siteId])

  const paginatedListProps = useMemo(() => {
    if (!isPaginationRequired) {
      return {}
    }

    return {
      additional: { page: 1 },
      onLoadOptions: handleLoadMoreOptions
    }
  }, [isPaginationRequired, handleLoadMoreOptions])

  return (
    <>
      {labelRequired && <CustomLabel title={label || SITE_PROJECT_PLANS.PLAN_NAME} isLocalizedTitle={!label} />}
      <div className={isFocused ? "project-plan-search project-search  isFocused organization-select" : "project-search"} >
        <CustomReactSelect
          cacheUniqs={[isResetCache]}
          customActionLabel={customActionLabel || getLocaleMessage(intl, PLANS_SEARCH.PLUS_ADD_PLAN)}
          isShowCustomAction={isShowCustomAction}
          id={id}
          name={name}
          isInitialSelectOptionRequired
          isPaginationRequired={isPaginationRequired}
          placeholder={placeholder || getLocaleMessage(intl, PLANS_SEARCH.SEARCH_PLANS)}
          options={options}
          defaultOptions={options}
          isFormattedValue
          value={!!value ? {
            text: selectedOptionLabel,
            value
          } : null}
          getOptionLabel={(option) => option.text}
          getOptionValue={(option) => option.value}
          onClickCustomAction={handleCustomAction}
          onBlur={handleBlurSearch}
          onFocus={handleFocusSearch}
          onSelect={handleSelectOption}
          {...paginatedListProps}
        />
        {isFocused && <Search />}
      </div>
      {isShowCustomAction && isOpenAddSitePlanModal && (
        <EditPlanModal
          selectedSiteId={siteId}
          modalType={MODAL_TYPES.ADD}
          onCloseModal={handleToggleAddSitePlanModal}
          onSelect={handleAutoSelectFromModal}
        />
      )}
    </>
  )
}

PlansSearch.propTypes = {
  customActionLabel: PropTypes.string,
  defaultOptions: PropTypes.array,
  id: PropTypes.string,
  intl: PropTypes.object,
  isEditMode: PropTypes.bool,
  isPaginationRequired: PropTypes.bool,
  isShowCustomAction: PropTypes.bool,
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  siteId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  value: PropTypes.string,
  onRefreshOptions: PropTypes.func,
  onSelect: PropTypes.func
}

PlansSearch.defaultProps = {
  defaultOptions: [],
  id: "planName",
  isEditMode: false,
  isPaginationRequired: false,
  isShowCustomAction: true,
  labelRequired: true,
  name: "planName",
  onRefreshOptions: () => { },
  onSelect: () => { }
}

export default localeMessageWrapper(PlansSearch)
