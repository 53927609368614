import classnames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
  Button,
  FormGroup,
  InputGroup,
  Input
} from "reactstrap"
import { useDispatch } from 'react-redux'

import { DEFAULT_VM_PAGE_SIZE } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from '@store/actions'
import { getLocaleMessage } from "@utils"
import {
  ErrorMessage,
  OrganizationSearch,
  PointOfContactSelect,
  ReactSelect,
  LocaleMessage,
  CustomLabel
} from '@views/components'
import localeMessageWrapper from '@views/components/locale-message'

const initialSiteRoleVMState = {
  sitePartyRoles: [],
  organizationDDL: {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0
  },
  pointOfContact: {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0
  }
}

const AddRoleCard = ({
  intl,
  isEditMode,
  isSearchMode = false,
  isModal,
  projectOrgId = "",
  organizationId = "",
  organizationName = "",
  roleData = {},
  roleDetail: {
    siteRole = "",
    org = "",
    contacts = []
  },
  isImportRoleRequired = true,
  isMultiContactSelect = true,
  isShowCustomAction = true,
  errors,
  onAddRoleDetail,
  onChangeRoleDetail,
  onCloseModal = () => { },
  onImportRole = () => { },
  onResetForm = () => { },
  onSearch = () => { },
  onToggle = () => { }
}) => {
  const dispatch = useDispatch()

  const { control } = useForm({})

  const [orgDetail, setOrgDetail] = useState(null)
  const [siteRoleVM, setSiteRoleVM] = useState(Object.assign({}, initialSiteRoleVMState))

  useEffect(() => {
    if (organizationId && organizationName) {
      setOrgDetail({ id: organizationId, name: organizationName })
    }
  }, [])

  useEffect(() => {
    dispatch(actions.getProjectSiteRolesVMRequest({
      entityId: 1,
      orgId: projectOrgId
    }, (response) => {
      if (response) {
        setSiteRoleVM(prevState => ({
          ...prevState,
          organizationDDL: response.organizationDDL,
          sitePartyRoles: response.sitePartyRoles
        }))
      }
    }))
  }, [])

  const handleResetOrgContactList = useCallback(() => {
    setSiteRoleVM(prevState => ({
      ...prevState,
      pointOfContact: {
        hasNextPage: false,
        hasPreviousPage: false,
        items: [],
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0,
        totalPages: 0
      }
    }))
  }, [])

  const handleChangeInput = useCallback((...data) => {
    if (data[0] === 'org') {
      const orgBasedReset = 'orgContacts'
      onChangeRoleDetail('contacts', [], orgBasedReset)
      if (!data[1]) {
        setOrgDetail(null)
        handleResetOrgContactList()
      } else {
        setOrgDetail({ id: data[1], name: data[2] })
        dispatch(actions.getContactByOrganizationRequest({
          fieldType: 'siteRolesContact',
          organizationId: data[1],
          pageSize: DEFAULT_VM_PAGE_SIZE,
          pageNumber: 1
        }, (response) => {
          if (response) {
            setSiteRoleVM(prevState => ({
              ...prevState,
              pointOfContact: response
            }))
          }
        }))
      }
    }
    onChangeRoleDetail(...data)
  }, [handleResetOrgContactList, onChangeRoleDetail])

  /******************************************** */
  /** Render */
  /******************************************** */
  const isRoleSelected = !!siteRole

  const DetailCardFooter = () => {
    if (isSearchMode) {
      return null
    }

    return (
      <div className="text-right col-12">
        {isModal &&
          <Button
            outline
            onClick={onCloseModal}
            className={classnames("delete-button mr-1")}
          >
            {getLocaleMessage(intl, LANGUAGE_CONSTANTS.CANCEL)}
          </Button>
        }
        <Button
          className={classnames("common-button")}
          color="primary"
          onClick={onAddRoleDetail}
        >
          {isEditMode ? getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_ROLES.UPDATE_ROLE) : getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_ROLES.ADD_ROLE)}
        </Button>
      </div>
    )
  }

  const SearchCardFooter = () => {
    if (!isSearchMode) {
      return null
    }

    return (
      <div className="text-right col-12">
        <Button
          outline
          onClick={onResetForm}
          className={classnames("delete-button mr-1")}
        >
          <LocaleMessage id={LANGUAGE_CONSTANTS.CLEAR} />
        </Button>
        <Button className="common-button" onClick={onSearch}>
          <LocaleMessage id={LANGUAGE_CONSTANTS.SEARCH} />
        </Button>
      </div>
    )
  }

  const DetailCard = () => {
    const { items: orgDefaultOptions } = siteRoleVM.organizationDDL
    const { items: contactsDefaultOptions } = siteRoleVM.pointOfContact

    return (
      <Form>
        <Row form className="px-0 pb-1">
          <Col className="mb-1" sm={12} md={6} xl={4}>
            <ReactSelect
              id="siteRole"
              name="site_role"
              label={getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_ROLES.LOWERCASE_SITE_ROLE)}
              isClearable
              control={control}
              placeHolder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_ROLES.LOWERCASE_SITE_ROLE)}
              value={siteRole}
              valueType={"value"}
              options={siteRoleVM.sitePartyRoles}
              isRequired={!isSearchMode}
              getOptionLabel={(option) => option.text}
              getOptionValue={(option) => option.value}
              onSelect={(selected) => handleChangeInput("siteRole", selected?.value || "", selected?.text || "")}
            />
            <ErrorMessage
              isShow={!!errors["siteRole"]}
              message={errors["siteRole"]}
            />
          </Col>
          {(isRoleSelected || isSearchMode) && (
            <>
              {!!organizationId && !!organizationName ? (
                <FormGroup className={"mb-1 organization-select"}>
                  <>
                    <CustomLabel title={LANGUAGE_CONSTANTS.CONTACTS.ORGANIZATION} forValue="email" required />
                    <InputGroup>
                      <Input
                        type="email"
                        id="organization"
                        placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.CONTACTS.ORGANIZATION)}
                        value={organizationName || ""}
                        disabled
                      />
                    </InputGroup>
                  </>
                </FormGroup>
              ) : (
                <OrganizationSearch
                  defaultOptions={orgDefaultOptions}
                  errors={errors}
                  initialValue={roleData?.initialOrgItem?.organizationId || ''}
                  isEditMode={isEditMode}
                  isRequired={!isSearchMode}
                  isShowCustomAction={isShowCustomAction}
                  value={org || ""}
                  onSelect={handleChangeInput}
                />
              )}
              <PointOfContactSelect
                isMulti={isMultiContactSelect}
                isPartialMultiSelect={isEditMode || isSearchMode}
                defaultOptions={contactsDefaultOptions}
                errors={errors}
                isOrgBasedReset
                initialValue={roleData?.poC_ContactId || ''}
                isEditMode={isEditMode}
                isRequired={!isSearchMode}
                isShowCustomAction={isShowCustomAction}
                orgDetail={orgDetail}
                value={isEditMode || isSearchMode ? contacts[0]?.contactId || '' : contacts}
                onSelect={handleChangeInput}
              />
            </>
          )}
          {DetailCardFooter()}
          {SearchCardFooter()}
        </Row>
      </Form>
    )
  }

  if (isModal) {
    return (
      <>
        <div className='mb-2 header-content'>
          <div>
            <h4 className='modal-title'>{isEditMode ? `${getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_ROLES.EDIT_SITE_ROLES)}` : `${getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_ROLES.ADD_SITE_ROLES)}`}</h4>
            <p>
              {getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_ROLES.CONSTRUCTION_SITE_TEXT)}
            </p>
          </div>
          <Button outline color="primary" className=" outline-button" onClick={onImportRole}>
            {getLocaleMessage(intl, LANGUAGE_CONSTANTS.IMPORT)}
          </Button>
        </div>
        {DetailCard()}
      </>
    )
  }

  if (isSearchMode) {
    return (
      <Card>
        <CardBody className="p-0">
          <CardHeader>
            <CardTitle tag='p'>{getLocaleMessage(intl, LANGUAGE_CONSTANTS.ADVANCED_SEARCH)}</CardTitle>
            <button
              type='button'
              className='waves-effect btn advanced-btn '
              onClick={onToggle}>
              {getLocaleMessage(intl, LANGUAGE_CONSTANTS.CLOSE)}
            </button>
          </CardHeader>
          {DetailCard()}
        </CardBody>
      </Card>
    )
  }

  return (
    <Card p="4">
      <CardBody className="p-0">
        <CardHeader>
          <div>
            <CardTitle tag="p">{getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_ROLES.ADD_SITE_ROLES)}</CardTitle>
            <p className="m-0">{getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_ROLES.CONSTRUCTION_SITE_TEXT)}</p>
          </div>
          {isImportRoleRequired && <Button outline color='primary' className="outline-button" onClick={onImportRole}>
            {getLocaleMessage(intl, LANGUAGE_CONSTANTS.IMPORT)}
          </Button>}
        </CardHeader>
        {DetailCard()}
      </CardBody>
    </Card>
  )
}

export default localeMessageWrapper(AddRoleCard)
