import { call, takeLatest } from 'redux-saga/effects'

import * as services from "@services/api"
import * as actions from "@store/actions"

/** getCalendarOrganizationDDL API */
function* getCalendarOrganizationDDLRequest(action) {
    try {
      const response = yield call(services.getCalendarOrganizationDDL, action.payload)
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } catch (error) {
      action.callback()
    }
}

/** getCalendarSiteDDL API */
function* getCalendarSiteDDLRequest(action) {
    try {
      const response = yield call(services.getCalendarSiteDDL, action.payload)
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } catch (error) {
      action.callback()
    }
}

/** getCalendarWorkflowsDDL API */
function* getCalendarWorkflowsDDLRequest(action) {
  try {
    const response = yield call(services.getCalendarWorkflowsDDL, action.payload)
    action.callback({ ...response.data, pageSize: action.payload.pageSize })
  } catch (error) {
    action.callback()
  }
}

/** getCalendarContactsDDL API */
function* getCalendarContactsDDLRequest(action) {
  try {
    const response = yield call(services.getCalendarContactsDDL, action.payload)
    action.callback({ ...response.data, pageSize: action.payload.pageSize })
  } catch (error) {
    action.callback()
  }
}

/** getCalendarFiltersVM API */
function* getCalendarFiltersVMRequest(action) {
  try {
    const response = yield call(services.getCalendarFiltersVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getCalendarScheduleList API */
function* getCalendarScheduleListRequest(action) {
  try {
    const response = yield call(services.getCalendarScheduleList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getCalendarStatesDDL API */
function* getCalendarStatesDDLRequest(action) {
  try {
    const response = yield call(services.getCalendarStatesDDL, action.payload)
    action.callback({ ...response.data, pageSize: action.payload.pageSize })
  } catch (error) {
    action.callback()
  }
}

export function* schedulingCalendarSagas() {
    yield takeLatest(actions.GET_CALENDAR_ORGANIZATION_DDL_REQUEST, getCalendarOrganizationDDLRequest)
    yield takeLatest(actions.GET_CALENDAR_SITE_DDL_REQUEST, getCalendarSiteDDLRequest)
    yield takeLatest(actions.GET_CALENDAR_WORKFLOW_DDL_REQUEST, getCalendarWorkflowsDDLRequest)
    yield takeLatest(actions.GET_CALENDAR_CONTACTS_DDL_REQUEST, getCalendarContactsDDLRequest)
    yield takeLatest(actions.GET_CALENDAR_FILTERS_VM_REQUEST, getCalendarFiltersVMRequest)
    yield takeLatest(actions.GET_CALENDAR_SCHEDULE_LIST_REQUEST, getCalendarScheduleListRequest)
    yield takeLatest(actions.GET_CALENDAR_STATES_DDL_REQUEST, getCalendarStatesDDLRequest)
}

export default schedulingCalendarSagas