import config from "../APIConfig"
import { getDataApi } from '../../../index'


export const getGroupActivitiesList = (data) => {
  const { url } = config.getGroupActivitiesList
  return getDataApi(url(data))
}

export const getGroupList = (data) => {
  const { url } = config.getGroupList
  return getDataApi(url(data))
}
