import config from "./APIConfig"
import { postDataApi } from '../index'

export const setWorkOrder = (data) => {
  const { url } = config.setWorkOrder
  
  return postDataApi(url(data.observationTaskId), data)
}

export default setWorkOrder
