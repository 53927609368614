import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateOrganizationContact
 * @param {object} data 
 *       {
 *        "contactId": 0,
 *        "firstName": "string",
 *        "lastName": "string",
 *        "title": "string",
 *        "organizationId": 0,
 *        "email": "string",
 *        "phoneNumber": "string",
 *        "accountAccess": 0,
 *        "originalOrganizationId": 0
 *        "sitePartyRoleId": 0
 *       }, etc...
 * */

export const updateOrganizationContact = (data) => {
  const { url } = config.updateOrganizationContact
  const payload = {
    contactId: data.contactId,
    organizationId: data.organizationId,
    originalOrganizationId: data.organizationId,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phoneNumber: data.phoneNumber,
    accountAccessId: data.accountAccessId || 0,
    sitePartyRoleId: Number(data.sitePartyRoleId) || 0,
    title: data.title || "",
    streetAddress: data.streetAddress || "",
    city: data.city || "",
    state: data.state || "",
    postCode: data.postCode || "",
    countryCode: data.countryCode || ""
  }
  if (data.imageUrl) {
    payload.imageUrl = data.imageUrl
  }
  if (data.preferredLanguage) {
    payload.preferredLanguage = data.preferredLanguage
  }
  if (data.title) {
    payload.title = data.title
  }

  if (data.originalOrganizationId) {
    payload.originalOrganizationId = data.originalOrganizationId
  }

  return putDataApi(url({ contactId: data.contactId }), payload)
}

export default updateOrganizationContact
