import { getSearchQuery } from '../config'

const apiConfig = {
    getCordClientToken: {
        method: 'GET',
        url: ({
            groups
        }) => {
            const addGroups = groups.map((grp) => getSearchQuery("addGroups", grp)).join("")
            return `api/Cord/ClientToken?${addGroups}`
        } 
    }
}

export default apiConfig