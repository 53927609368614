import React, { useMemo } from 'react'

import { TableView } from "@views/components"
import localeMessageWrapper from '@views/components/locale-message'
import { EnergyModelContext } from "./EnergyModelContext"
import { getColumnConfig } from "./TableConfig"

const EnergyModelList = ({
  intl,
  list,
  organizationId,
  organizationPlanId,
  organizationBluePrintId
}) => {
  const columns = useMemo(() => {
    return getColumnConfig({
      intl
    })
  }, [])

  const tableComponentProps = useMemo(() => {
    return {
      isStaticList: true,
      staticListHeight: 200
    }
  }, [list])

  const contextValue = useMemo(() => {
    return {
      organizationId,
      organizationPlanId,
      organizationBluePrintId
    }
  }, [organizationId, organizationPlanId, organizationBluePrintId])

  return (
    <EnergyModelContext.Provider value={contextValue}>
      {!!list.length && <TableView
        selectableRows={false}
        columns={columns}
        keyField={"energyModelId"}
        className="nt-rdt-list"
        isNewListTheme
        showAgGrid
        data={list}
        pagination={false}
        tableComponentProps={tableComponentProps}
        tableViewAdvanceSearchRequired={false}
        tableViewHeaderRequired={false}
        tableHeaderRequired={false}
        isSearchFilterRequired={false}
      />}
    </EnergyModelContext.Provider>
  )
}

export default localeMessageWrapper(EnergyModelList)
