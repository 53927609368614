import { getSearchQuery } from '../config'

const apiConfig = {
  addCheckpoint: {
    method: 'POST',
    url: () => 'api/Checkpoint'
  },
  addCheckpointToTemplate: {
    method: 'POST',
    url: ({
      templateId = 0,
      templateVersionId = 1
    }) => `api/Template/${templateId}/${templateVersionId}/checkpoint`
  },
  addNewCheckpointVersion: {
    method: 'POST',
    url: ({ checkpointId }) => `api/Checkpoint/${checkpointId}/version`
  },
  addNewTemplateVersion: {
    method: 'POST',
    url: ({ templateId }) => `api/Template/${templateId}/Version`
  },
  addTemplateDetail: {
    method: 'POST',
    url: 'api/Template'
  },
  addTrainingToCheckpoint: {
    method: 'POST',
    url: ({ checkpointId, checkpointVersionId = 1, trainingId }) => `api/Checkpoint/${checkpointId}/${checkpointVersionId}/training/${trainingId}`
  },
  cloneCheckpoint: {
    method: 'POST',
    url: ({
      checkpointId = 0,
      checkpointVersionId = 1
    }) => `api/Checkpoint/Activity/${checkpointId}/${checkpointVersionId}/clone`
  },
  cloneTemplateCheckpoint: {
    method: 'POST',
    url: ({
      checkpointId = 0,
      checkpointVersionId = 1,
      templateId = 0,
      templateVersionId = 1
    }) => `api/Checkpoint/${templateId}/${templateVersionId}/${checkpointId}/${checkpointVersionId}/clone`
  },
  cloneTemplate: {
    method: 'POST',
    url: ({
      templateId = 0,
      templateVersionId = 1
    }) => `api/Template/${templateId}/${templateVersionId}/clone`
  },
  deleteCheckpoint: {
    method: 'DELETE',
    url: ({ checkpointId = 0, checkpointVersionId = 1 }) => `api/Checkpoint/${checkpointId}/${checkpointVersionId}`
  },
  deleteTemplate: {
    method: 'DELETE',
    url: ({ templateId = 0, templateVersionId = 1 }) => `api/Template/${templateId}/${templateVersionId}`
  },
  getAddNewCheckpointVM: {
    method: 'GET',
    url: ({ checkpointId, entityId = 1 }) => {
      const searchString = getSearchQuery("checkpointId", checkpointId, true)
      return `api/Checkpoint/edit/generalVM/entity/${entityId}${searchString}`
    }
  },
  getAllCheckpointList: {
    method: 'GET',
    url: ({
      templateId = 0,
      pageNumber,
      pageSize,
      genericSearch = '',
      activityTypeId = ''
    }) => {
      const searchString = [
        getSearchQuery("PageSize", pageSize, true),
        getSearchQuery("TemplateId", templateId),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("ActivityType", activityTypeId)
      ].join('')
      return `api/Checkpoint/DDL${searchString}`
    }
  },
  getAllCheckpointTrainingList: {
    method: 'GET',
    url: ({ checkpointId = 0,
      checkpointVersionId = 1,
      pageNumber,
      pageSize,
      checkpointName
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("CheckpointId", checkpointId),
        getSearchQuery("CheckpointVersionId", checkpointVersionId),
        getSearchQuery("CheckPointName", checkpointName, false, true)
      ].join('')
      return `api/Checkpoint/training/DDL${searchString}`
    }
  },
  getCheckpointChildDetail: {
    method: 'GET',
    url: ({ checkpointId, checkpointVersionId = 1 }) => `api/Checkpoint/${checkpointId}/${checkpointVersionId}/childdetail`
  },
  getCheckpointCollection: {
    method: 'GET',
    url: ({ checkpointId, checkpointVersionId = 1 }) => `api/Checkpoint/${checkpointId}/${checkpointVersionId}/detail`
  },
  getCheckpointDetailVMList: {
    method: 'GET',
    url: (entityId = 1) => `api/Checkpoint/editdetailvm/?entityId=${entityId}`
  },
  getCheckpointList: {
    method: "GET",
    url: ({
      entityId = 1,
      pageSize,
      pageNumber,
      genericSearch = '',
      advanceSearch = {},
      orderColumn,
      orderDirections = ''
    }) => {
      let searchString = [
        getSearchQuery("EntityId", entityId, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
       orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')
      if (!!Object.keys(advanceSearch).length) {
        const {
          checkpointName = '',
          stageOfConstruction = '',
          scopeOfWork = '',
          responsibleSiteRole = '',
          riskLevel = '',
          assembly = '',
          component = [],
          subComponent = [],
          manufacturer = '',
          systemCategory = '',
          brand = '',
          activityType = ''
        } = advanceSearch

        const componentIds = component.map((comp) => getSearchQuery("ComponentId", comp.componentId)).join("")
        const subComponentIds = subComponent.map((sub) => getSearchQuery("SubComponentId", sub.subComponentId)).join("")

        searchString = [
          searchString,
          getSearchQuery("CheckPointName", checkpointName, false, true),
          getSearchQuery("StageOfConstructionId", stageOfConstruction),
          getSearchQuery("ScopeOfWorkId", scopeOfWork),
          getSearchQuery("ResponsibleSiteRoleId", responsibleSiteRole),
          getSearchQuery("RiskLevelId", riskLevel),
          getSearchQuery("SystemCategoryId", systemCategory),
          getSearchQuery("AssemblyId", assembly),
          getSearchQuery("ManufacturerId", manufacturer),
          getSearchQuery("BrandId", brand),
          getSearchQuery("ActivityTypeId", activityType),
          componentIds,
          subComponentIds
        ].join('')
      }
      return `api/Checkpoint/SearchList${searchString}`
    }
  },
  getCheckpointResponseQuestions: {
    method: 'GET',
    url: ({ checkpointId = 0, checkpointVersionId = 1 }) => `api/Checkpoint/${checkpointId}/${checkpointVersionId}/ResponseQuestions`
  },
  getCheckpointTrainingList: {
    method: 'GET',
    url: ({ checkpointId = 0, checkpointVersionId = 1 }) => `api/Checkpoint/${checkpointId}/${checkpointVersionId}/training`
  },
  getTemplateAssembly: {
    method: 'GET',
    url: ({
      entityId = 0,
      pageNumber,
      pageSize,
      assemblyName
    }) => {
      const searchString = [
        getSearchQuery("EntityId", entityId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("AssemblyName", assemblyName, false, true)
      ].join('')
      return `api/Template/assembly/DDL${searchString}`
    }
  },
  getTemplateCheckpointList: {
    method: "GET",
    url: ({
      templateId,
      templateVersionId,
      pageSize,
      pageNumber,
      genericSearch = '',
      advanceSearch = {},
      orderColumn,
      orderDirections = ''
    }) => {
      let searchString = [
        getSearchQuery("PageSize", pageSize, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("TemplateId", templateId),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')
      if (!!Object.keys(advanceSearch).length) {
        const {
          checkpointName = '',
          stageOfConstruction = '',
          scopeOfWork = '',
          responsibleSiteRole = '',
          riskLevel = '',
          assembly = '',
          component = [],
          subComponent = [],
          manufacturer = '',
          systemCategory = '',
          brand = '',
          activityType = '',
          assemblyType = [],
          holdProduction
        } = advanceSearch

        const assemblyTypeIds = assemblyType.map((id) => getSearchQuery("assemblyTypeIds", id)).join("")
        const subComponentIds = subComponent.map((comp) => getSearchQuery("subComponentIds", comp)).join("")
        const componentIds = component.map((comp) => getSearchQuery("componentIds", comp)).join("")

        searchString = [
          searchString,
          getSearchQuery("CheckPointName", checkpointName, false, true),
          getSearchQuery("StageOfConstructionId", stageOfConstruction, false, true),
          getSearchQuery("ScopeOfWorkId", scopeOfWork, false, true),
          getSearchQuery("ResponsibleSiteRoleId", responsibleSiteRole),
          getSearchQuery("RiskLevelId", riskLevel),
          getSearchQuery("AssemblyId", assembly),
          componentIds,
          subComponentIds,
          getSearchQuery("ManufacturerId", manufacturer),
          getSearchQuery("SystemCategoryId", systemCategory),
          getSearchQuery("BrandId", brand),
          getSearchQuery("ActivityTypeId", activityType),
          assemblyTypeIds,
          getSearchQuery("holdProduction", holdProduction)
        ].join('')
      }
      return `api/Template/${templateId}/${templateVersionId}/checkpoint${searchString}`
    }
  },
  getTemplateComponent: {
    method: 'GET',
    url: ({
      entityId = 1,
      pageNumber,
      pageSize,
      componentName
    }) => {
      const searchString = [
        getSearchQuery("EntityId", entityId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ComponentName", componentName, false, true)
      ].join('')
      return `api/Template/component/DDL${searchString}`
    }
  },
  getTemplateDescription: {
    method: 'GET',
    url: ({ templateId = 0, templateVersionId = 1 }) => `api/Template/${templateId}/${templateVersionId}/description`
  },
  getTemplateDetail: {
    method: 'GET',
    url: ({ templateId = 0, templateVersionId = 1 }) => `api/Template/${templateId}/${templateVersionId}`
  },
  getTemplateList: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      genericSearch = '',
      advanceSearch = {},
      orderColumn,
      orderDirections = ''
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          templateName = '',
          templateCategory = '',
          buildingType = [],
          scopeOfWork = '',
          templateStatus = '',
          photoRequired = false,
          assembly = '',
          assemblyType = '',
          component = ''
        } = advanceSearch

        const buildingTypeList = buildingType.map((type) => getSearchQuery("BuildingTypeId", type.value)).join("")
        searchString = [
          searchString,
          getSearchQuery("TemplateName", templateName, false, true),
          getSearchQuery("TemplateCategoryId", templateCategory),
          buildingTypeList,
          getSearchQuery("ScopeOfWorkId", scopeOfWork),
          getSearchQuery("TemplateStatusId", templateStatus),
          getSearchQuery("AssemblyId", assembly),
          getSearchQuery("AssemblyTypeId", assemblyType),
          getSearchQuery("ComponentId", component),
          getSearchQuery("PhotoRequired", photoRequired)
        ].join('')
      }

      return `api/Template${searchString}`
    }
  },
  getTemplateListVM: {
    method: 'GET',
    url: (entityId = 1) => `api/Template/listVM/${entityId}`
  },
  getTemplateManufacturer: {
    method: 'GET',
    url: ({
      entityId = 0,
      pageNumber,
      pageSize,
      manufacturerName
    }) => {
      const searchString = [
        getSearchQuery("EntityId", entityId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ManufacturerName", manufacturerName, false, true)
      ].join('')
      return `api/Template/manufacturer/DDL${searchString}`
    }
  },
  getTemplateSetupVM: {
    method: 'GET',
    url: ({ templateId, entityId = 1 }) => {
      const searchString = getSearchQuery("templateId", templateId, true)
      return `api/Template/setupvm/${entityId}${searchString}`
    }
  },
  getTemplateSubComponent: {
    method: 'GET',
    url: ({
      componentId = [],
      pageNumber = 1,
      pageSize = 10,
      subComponentName = ""
    }) => {
      const componentIDs = componentId.map((comp) => getSearchQuery("ComponentIds", comp.componentId)).join("")
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        componentIDs,
        getSearchQuery("SubComponentName", subComponentName, false, true)
      ].join('')
      return `api/Template/subcomponentbycomponents/DDL${searchString}`
    }
  },
  getTemplateVersionDDL: {
    method: 'GET',
    url: ({ templateId = 0 }) => `api/Template/${templateId}/Version/DDL`
  },
  removeCheckpointFromTemplate: {
    method: 'DELETE',
    url: ({ templateCheckpointId = 0 }) => `api/Template/checkpoint/${templateCheckpointId}`
  },
  removeTrainingFromCheckpoint: {
    method: 'DELETE',
    url: (checkpointTrainingId) => `api/Checkpoint/training/${checkpointTrainingId}`
  },
  updateCheckpointTraining: {
    method: 'PUT',
    url: ({ checkpointId, checkpointVersionId = 1 }) => `api/Checkpoint/${checkpointId}/${checkpointVersionId}/training`
  },
  updateTemplateDetail: {
    method: 'PUT',
    url: ({ templateId = 0, templateVersionId = 1 }) => `api/Template/${templateId}/${templateVersionId}`
  },
  upsertCheckpointDetails: {
    method: 'PUT',
    url: ({ checkpointId, checkpointVersionId = 1 }) => `api/Checkpoint/${checkpointId}/${checkpointVersionId}/collection`
  },
  addCheckpointResponseQuestions: {
    method: 'POST',
    url: () => 'api/Checkpoint/SaveActivityResponseQuestions'
  },
  updateCheckpointResponseQuestions: {
    method: 'PUT',
    url: ({ checkpointId = 0, checkpointVersionId = 0 }) => `api/Checkpoint/UpdateActivityResponseQuestions/${checkpointId}/${checkpointVersionId}`
  },
  getObservationActivityResponseQuestions: {
    method: 'GET',
    url: ({ checkpointId = 0, checkpointVersionId = 1 }) => `api/Checkpoint/Activity/${checkpointId}/${checkpointVersionId}`
  },
  updateActivityOrderIndex: {
    method: 'PUT',
    url: "api/Template/UpdateActivityOrderIndex"
  },
  getAssemblyTypeComponentDDL: {
    method: 'GET',
    url: ({
      pageNumber = 1,
      pageSize = 10,
      componentName,
      assemblyTypeIds
    }) => {
      const assemblyIds = typeof (assemblyTypeIds) === "string" ? getSearchQuery("AssemblyTypeIds", assemblyTypeIds) :
        assemblyTypeIds.map((assembly) => getSearchQuery("AssemblyTypeIds", assembly.value)).join("")
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        assemblyIds,
        getSearchQuery("ComponentName", componentName, false, true)
      ].join('')
      return `api/Template/componentbyassemblytypes/DDL${searchString}`
    }
  },
  getAssemblyTypeDDL: {
    method: 'GET',
    url: ({
      pageNumber = 1,
      pageSize = 10,
      assemblyId,
      assemblyTypeName
    }) => {
      let assemblyList
      if (Array.isArray(assemblyId)) {
        assemblyList = assemblyId.map((id) => getSearchQuery("AssemblyIds", id)).join("")
      } else {
        assemblyList = getSearchQuery("AssemblyId", assemblyId)
      }

      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        assemblyList,
        getSearchQuery("AssemblyTypeName", assemblyTypeName, false, true)
      ].join('')
      return `api/Template/assemblytype/DDL${searchString}`
    }
  },
  getApplicableRegulationList: {
    method: 'GET',
    url: ({
      pageNumber = 1,
      pageSize = 10,
      checkpointId,
      checkpointVersionId
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("CheckpointId", checkpointId),
        getSearchQuery("CheckpointVersionId", checkpointVersionId)
      ].join('')
      return `api/Checkpoint/GetActivityRegulationList/${checkpointId}/${checkpointVersionId}${searchString}`
    }
  },
  addApplicableRegulationData: {
    method: 'POST',
    url: ({
      checkpointId,
      checkpointVersionId
    }) => {
      const searchString = [
        getSearchQuery("CheckpointId", checkpointId, true),
        getSearchQuery("CheckpointVersionId", checkpointVersionId)
      ].join('')
      return `api/Checkpoint/CreateActivityRegulation/${checkpointId}/${checkpointVersionId}${searchString}`
    }
  },
  updateApplicableRegulationData: {
    method: 'POST',
    url: ({
      activityRegulationId,
      checkpointId,
      checkpointVersionId
    }) => {
      const searchString = [
        getSearchQuery("activityregulationid", activityRegulationId, true),
        getSearchQuery("CheckpointId", checkpointId),
        getSearchQuery("CheckpointVersionId", checkpointVersionId)
      ].join('')
      return `api/Checkpoint/UpdateActivityRegulation/${activityRegulationId}/${checkpointId}/${checkpointVersionId}${searchString}`
    }
  },
  deleteApplicableRegulationData: {
    method: 'DELETE',
    url: ({
      activityregulationids
    }) => {
      return `api/Checkpoint/DeleteActivityRegulation/${activityregulationids}`
    }
  },
  getReferenceStandardData: {
    method: 'GET',
    url: ({
      pageNumber = 1,
      pageSize = 10,
      checkpointId,
      checkpointVersionId
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("CheckpointId", checkpointId),
        getSearchQuery("CheckpointVersionId", checkpointVersionId)
      ].join('')
      return `api/Checkpoint/GetActivityRefStandardList/${checkpointId}/${checkpointVersionId}${searchString}`
    }
  },
  addReferenceStandardData: {
    method: 'POST',
    url: ({
      checkpointId,
      checkpointVersionId
    }) => {
      const searchString = [
        getSearchQuery("CheckpointId", checkpointId, true),
        getSearchQuery("CheckpointVersionId", checkpointVersionId)
      ].join('')
      return `api/Checkpoint/CreateActivityRefStandard/${checkpointId}/${checkpointVersionId}${searchString}`
    }
  },
  updateReferenceStandardData: {
    method: 'PUT',
    url: ({
      activityRefStandardId,
      checkpointId,
      checkpointVersionId
    }) => {
      const searchString = [
        getSearchQuery("activityrefstandardid", activityRefStandardId, true),
        getSearchQuery("CheckpointId", checkpointId),
        getSearchQuery("CheckpointVersionId", checkpointVersionId)
      ].join('')
      return `api/Checkpoint/UpdateActivityRefStandard/${activityRefStandardId}/${checkpointId}/${checkpointVersionId}${searchString}`
    }
  },
  deleteReferenceStandardData: {
    method: 'DELETE',
    url: ({
      activityRefStandardIds
    }) => {
      const searchString = getSearchQuery("activityrefstandardids", activityRefStandardIds, true)
      return `api/Checkpoint/DeleteActivityRefStandard/${activityRefStandardIds}${searchString}`
    }
  },
  getIndustryInterpretationsData: {
    method: 'GET',
    url: ({
      checkpointId,
      checkpointVersionId
    }) => {
      const searchString = [
        getSearchQuery("CheckpointId", checkpointId, true),
        getSearchQuery("CheckpointVersionId", checkpointVersionId)
      ].join('')
      return `api/Checkpoint/GetActivityIndustryInterpList/${checkpointId}/${checkpointVersionId}${searchString}`
    }
  },
  addIndustryInterpretations: {
    method: 'POST',
    url: ({
      checkpointId,
      checkpointVersionId
    }) => {
      const searchString = [
        getSearchQuery("CheckpointId", checkpointId, true),
        getSearchQuery("CheckpointVersionId", checkpointVersionId)
      ].join('')
      return `api/Checkpoint/CreateActivityIndustryInterp/${checkpointId}/${checkpointVersionId}${searchString}`
    }
  },
  updateIndustryInterpretations: {
    method: 'PUT',
    url: ({
      industryInterpretationId,
      checkpointId,
      checkpointVersionId
    }) => {
      const searchString = [
        getSearchQuery("activityindustryinterpid", industryInterpretationId, true),
        getSearchQuery("CheckpointId", checkpointId),
        getSearchQuery("CheckpointVersionId", checkpointVersionId)
      ].join('')
      return `api/Checkpoint/UpdateActivityIndustryInterp/${industryInterpretationId}/${checkpointId}/${checkpointVersionId}${searchString}`
    }
  },
  deleteIndustryInterpretationsData: {
    method: 'POST',
    url: ({
      activityIndustryInterpId
    }) => {
      const searchString = getSearchQuery("activityindustryinterpids", activityIndustryInterpId, true)
      return `api/Checkpoint/DeleteActivityIndustryInterp/${activityIndustryInterpId}${searchString}`
    }
  },
  updateApplicableRegulationReorder: {
    method: 'PUT',
    url: 'api/Checkpoint/UpdateActivityRegulationOrderIndex'
  },
  updateReferenceStandardsReorder: {
    method: 'PUT',
    url: 'api/Checkpoint/UpdateActivityRefStandardOrderIndex'
  },
  updateIndustryInterpretationsReorder: {
    method: 'PUT',
    url: 'api/Checkpoint/UpdateActivityIndustryInterpOrderIndex'
  },
  getActivityTemplateList: {
    method: 'GET',
    url: ({
      checkpointId,
      checkpointVersionId,
      pageNumber,
      pageSize
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize)
      ].join('')
      return `api/Template/${checkpointId}/${checkpointVersionId}/TemplateList${searchString}`
    }
  },
  updateRequiredActivity: {
    method: 'PUT',
    url: ({
      templateId,
      templateVersionId
    }) => {
      return `api/Template/${templateId}/${templateVersionId}/RequiredActivity`
    }
  },
  getReportTagDDL: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      reportTagName
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ReportTagName", reportTagName, false, true)
      ].join('')
      return `api/Checkpoint/ReportTag/DDL${searchString}`
    }
  },
  updateCopyAllowedActivity: {
    method: 'PUT',
    url: ({
      templateId,
      templateVersionId
    }) => {
      return `api/Template/${templateId}/${templateVersionId}/CopyAllowedActivity`
    }
  },
  getTemplateActivityGroupList: {
    method: 'GET',
    url: ({
      pageSize,
      pageNumber,
      genericSearch = '',
      templateId,
      templateVersionId
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true)
      ].join('')
       return `api/Template/TemplateCheckpointGroup/${templateId}/${templateVersionId}${searchString}`
      }
  },
  deleteTemplateActivityGroup: {
    method: 'DELETE',
    url: ({ templateCheckpointGroupId }) => `api/Template/TemplateCheckpointGroup/${templateCheckpointGroupId}`
  },
  deleteActivityFromTemplateActivityGroup: {
    method: 'DELETE',
    url: ({ templateCheckpointGroupId, templateCheckpointId }) => `api/Template/TemplateCheckpointGroup/${templateCheckpointGroupId}/${templateCheckpointId}`
  },
  addTemplateActivityGroup: {
    method: "POST",
    url: ({ templateId, templateVersionId }) => `api/Template/TemplateCheckpointGroup/${templateId}/${templateVersionId}`
  },
  updateTemplateActivityGroup: {
    method: 'PUT',
    url: ({ templateCheckpointGroupId }) => `api/Template/TemplateCheckpointGroup/${templateCheckpointGroupId}`
  },
  getTemplateActivityGroupChildrenList: {
    method: 'GET',
    url: ({
      pageSize,
      pageNumber,
      templateCheckpointGroupId,
      orderColumn,
      orderDirections = ''
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')
      return `api/Template/TemplateCheckpointGroup/${templateCheckpointGroupId}${searchString}`
    }
  },
  reorderTemplateActivityGroup: {
    method: 'PUT',
    url: ({ templateCheckpointGroupId }) => `api/Template/TemplateCheckpointGroup/UpdateOrderIndex/${templateCheckpointGroupId}`
  },
  reorderTemplateActivitiesGroupChildrenList: {
    method: 'PUT',
    url: ({
      templateCheckpointId
    }) => {
      return `api/Template/TemplateCheckpointGroup/UpdateChildrenOrderIndex/${templateCheckpointId}`
    }
  },
  addActivitiesToTemplateActivityGroup: {
    method: 'PUT',
    url: ({
      templateCheckpointGroupId
    }) => {
      return `api/Template/TemplateCheckpointGroup/AddCheckpoint/${templateCheckpointGroupId}`
    }
  },
  getUngroupedTemplateActivities: {
    method: 'GET',
    url: ({
      templateId,
      templateVersionId,
      pageNumber,
      pageSize,
      genericSearch,
      orderColumn,
      orderDirections
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')
       return `api/Template/TemplateCheckpointGroup/Ungroupped/${templateId}/${templateVersionId}${searchString}`
      }
  }
}

export default apiConfig