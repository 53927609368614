export const checkIfSearchFiltersApplied = ({
  stageOfConstruction,
  scopeOfWork,
  responsibleSiteRole,
  riskLevel,
  checkpointName,
  systemCategory,
  assembly,
  assemblyType,
  component,
  subComponent,
  manufacturer,
  brand,
  version,
  activityType
}) => {
  return (
    !!stageOfConstruction ||
    !!scopeOfWork ||
    !!responsibleSiteRole ||
    !!riskLevel ||
    !!checkpointName ||
    !!systemCategory ||
    !!assembly ||
    !!assemblyType.length ||
    !!component ||
    !!subComponent ||
    !!manufacturer ||
    !!brand ||
    !!version ||
    !!activityType
  )
}
