import PropTypes from 'prop-types'
import React from 'react'

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'

const { LOADER, SITE_PROJECT_PLANS } = LANGUAGE_CONSTANTS

const ExpandablePlans = ({ expandedRowId = '', isLoadingChildGrid, subData, data, noDataMessage }) => {
  if (isLoadingChildGrid && expandedRowId === data.sitePlanId) {
    return <p className="no-data-txt"><LocaleMessage id={LOADER.LOADING} /></p>
  }

  if (!subData) {
    return <p className="no-data-txt">{noDataMessage}</p>
  }

  const selectedGrid = subData[`sub_grid_key_${data.sitePlanId}`]

  if (!selectedGrid) {
    return <p className="no-data-txt">{noDataMessage}</p>
  }

  return (
    <div>
      <div className="site-units">
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={SITE_PROJECT_PLANS.PLAN_NAME} />: </span>
          <span>{selectedGrid.planName || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={SITE_PROJECT_PLANS.PLAN} />: </span>
          <span>{selectedGrid.planNumber || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={SITE_PROJECT_PLANS.VERSION} />: </span>
          <span>{selectedGrid.version || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={SITE_PROJECT_PLANS.STORIES} />: </span>
          <span>{selectedGrid.sitePlanNumberOfStoryId || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={SITE_PROJECT_PLANS.BEDROOMS} />: </span>
          <span>{selectedGrid.numberOfBedroom || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={SITE_PROJECT_PLANS.BATHROOMS} />: </span>
          <span>{selectedGrid.sitePlanNumberOfBathroomId || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={SITE_PROJECT_PLANS.ELEVATION} />: </span>
          <span>{selectedGrid.elevation || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={SITE_PROJECT_PLANS.SQUARE_FOOTAGE} />: </span>
          <span>{selectedGrid.squareFootage || '-'}</span>
        </div>
      </div>
    </div>
  )
}

ExpandablePlans.propTypes = {
  expandedRowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isLoadingChildGrid: PropTypes.bool,
  subData: PropTypes.object,
  noDataMessage: PropTypes.string,
  data: PropTypes.object
}

export default ExpandablePlans
