
import React, { useState, useEffect, useCallback } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Maximize } from 'react-feather'

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import { Notification } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const { IMAGE_VIEW_MODAL } = LANGUAGE_CONSTANTS

const ImageViewModal = ({ intl, isOpen, imageUrl, imageMeta, onClose = () => { } }) => {
  const [isZoomed, setZoomed] = useState(false)

  const handleZoomToggle = useCallback(() => {
    setZoomed(prev => !prev)
  }, [])

  const handleCopy = useCallback((text) => {
    navigator.clipboard.writeText(text)
    Notification({ status: 200, message: IMAGE_VIEW_MODAL.TEXT_COPIED_SUCCESSFULLY})
  }, [])

  return (
    <Modal isOpen={isOpen} toggle={onClose} className="image-view-modal modal-dialog-centered">
      <div className="image-view-wrapper"></div>
      <ModalHeader toggle={onClose}>
        <button onClick={handleZoomToggle} className="zoom-button">
          <Maximize />
        </button>
      </ModalHeader>
      <ModalBody className="image-content">
        <div className={`image-container ${isZoomed ? 'zoomed' : ''}`}>
          <img id="zoom-image" src={imageUrl} alt="Zoomed" />
         {!!imageMeta && <div class="image-meta-details">
            <div class="image-meta-details-inner">
              <h5>{getLocaleMessage(intl, IMAGE_VIEW_MODAL.PHOTO_DETAILS)}</h5>
              <p>
                <strong>{getLocaleMessage(intl, IMAGE_VIEW_MODAL.LOCATION)}:</strong>
                <span>{imageMeta.latitude}</span>
                <span>{imageMeta.longitude}</span>
                <CopyToClipboard
                  text={`Location: ${imageMeta.latitude}, ${imageMeta.longitude}`}
                  onCopy={() => handleCopy(`Location: ${imageMeta.latitude}, ${imageMeta.longitude}`)}
                >
                  <span className="copy-link">{getLocaleMessage(intl, IMAGE_VIEW_MODAL.COPY)}</span>
                </CopyToClipboard>
              </p>
              <p>
                <strong>{getLocaleMessage(intl, IMAGE_VIEW_MODAL.TIME)}:</strong>
                <span>{imageMeta.dateTime}</span>
                <span>{imageMeta.time} {imageMeta.timeZone}</span>
                <CopyToClipboard
                  text={`Time: ${imageMeta.dateTime}, ${imageMeta.time} ${imageMeta.timeZone}`}
                  onCopy={() => handleCopy(`Time: ${imageMeta.dateTime}, ${imageMeta.time} ${imageMeta.timeZone}`)}
                >
                  <span className="copy-link">{getLocaleMessage(intl, IMAGE_VIEW_MODAL.COPY)}</span>
                </CopyToClipboard>
              </p>
            </div>
          </div>}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default localeMessageWrapper(ImageViewModal)