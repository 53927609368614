import React, { useCallback, useMemo } from "react"
import { CustomInput } from "reactstrap"

const CheckboxItem = ({
  id,
  valueType,
  labelType = "text",
  selectedFilters,
  lastElementIndex,
  index,
  filter,
  onSelectFilter
}) => {
  const value = useMemo(() => filter[valueType], [filter, valueType])
  const textLabel = useMemo(() => filter[labelType], [filter, labelType])

  const isChecked = useMemo(() => {
    return selectedFilters.some((selected) => selected[valueType] === value)
  }, [selectedFilters, value, valueType])

  const checkboxContainerId = useMemo(() => {
    return lastElementIndex === index
      ? value
        ? `last-activity-item-${value}`
        : "last-activity-item"
      : ""
  }, [index, lastElementIndex, value])

  const handleSelectFilter = useCallback(
    (e) => {
      onSelectFilter(e, value, textLabel)
    },
    [value, textLabel, onSelectFilter]
  )

  return (
    <div key={`${value}_${index}`} id={checkboxContainerId}>
      <CustomInput
        value={value}
        type="checkbox"
        id={`${id}_filter_${value}`}
        label={textLabel}
        className={
          isChecked
            ? "custom-checkbox-control"
            : "custom-unchecked-checkbox-control"
        }
        checked={isChecked}
        onChange={handleSelectFilter}
      />
    </div>
  )
}

export default CheckboxItem
