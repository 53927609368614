import { SET_CAMPAIGN_SERVICE_MODE } from "@store/actions"

const initialState = {
  mode: "",
  detail: null
}

const campaignServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_SERVICE_MODE:
      const { mode = "", detail = null } = action.payload || {}
      return {
        ...state,
        mode,
        detail
      }

    default:
      return state
  }
}

export default campaignServiceReducer
