import config from "./APIConfig"
import { postDataApi } from '../index'

export const updateUserAccountPassword = (data) => {
  const { url } = config.updateUserAccountPassword
  const payload = {
    Password: data.password
  }
 
  return postDataApi(url(data.contactId), payload)
}

export default updateUserAccountPassword
