import { put, takeLatest, call } from "redux-saga/effects"

import * as actions from "@store/actions"
import {
  getTemplateTrainingActivityVM,
  addTemplateTrainingActivity,
  getAllCourses,
  getTemplateTrainingActivity,
  updateTemplateTrainingActivity
} from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'

const { TEMPLATE_TRAINING_ACTIVITY_SAGA, ACTIVITY_SAGA } = LANGUAGE_CONSTANTS

/** getTemplateTrainingActivityVMRequest API **/
function* getTemplateTrainingActivityVMRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getTemplateTrainingActivityVM, action.payload)
    if (response) {
      yield put(actions.getTrainingActivityVMSuccess(response.data))
    } else {
      Notification({ status: 400, message: TEMPLATE_TRAINING_ACTIVITY_SAGA.UNABLE_TO_LOAD_OPTIONS })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: TEMPLATE_TRAINING_ACTIVITY_SAGA.UNABLE_TO_LOAD_OPTIONS })
  }
}

/** addTemplateTrainingActivityRequest API **/
function* addTemplateTrainingActivityRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addTemplateTrainingActivity, action.payload)
    if (response.status === 200 && response.data) {
      action.callback(response.data)
      Notification({ status: 200, message: TEMPLATE_TRAINING_ACTIVITY_SAGA.ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: TEMPLATE_TRAINING_ACTIVITY_SAGA.ERROR_IN_ADDING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    Notification({ status: 400, message: TEMPLATE_TRAINING_ACTIVITY_SAGA.ERROR_IN_ADDING })
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** getTemplateTrainingActivityRequest API **/
function* getTemplateTrainingActivityRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getTemplateTrainingActivity, action.payload)
    if (response?.status === 200) {
      action.callback(response.data)
    } else {
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** updateTemplateTrainingActivityRequest API **/
function* updateTemplateTrainingActivityRequest(action) {
  try {
    const { isUpsertAlert = false, ...payload } = action.payload

    yield put(actions.setIsLoading(true))
    const response = yield call(updateTemplateTrainingActivity, payload)
    if (response?.status === 204) {
      Notification({ status: 200, message: isUpsertAlert ? ACTIVITY_SAGA.NEW_VERSION_ADDED_SUCCESS : TEMPLATE_TRAINING_ACTIVITY_SAGA.UPDATED_SUCCESS })
      action.callback(true)
    } else {
      Notification({ status: 400, message: TEMPLATE_TRAINING_ACTIVITY_SAGA.ERROR_IN_UPDATING })
      action.callback(false)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    Notification({ status: 400, message: TEMPLATE_TRAINING_ACTIVITY_SAGA.ERROR_IN_UPDATING })
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** getAllCourses API **/
function* getAllCoursesRequest(action) {
  try {
    const response = yield call(getAllCourses, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

export function* templateTrainingActivtySagas() {
  yield takeLatest(actions.GET_TEMPLATE_TRAINING_ACTIVITY_VM.REQUEST, getTemplateTrainingActivityVMRequest)
  yield takeLatest(actions.ADD_TEMPLATE_TRAINING_ACTIVITY_REQUEST, addTemplateTrainingActivityRequest)
  yield takeLatest(actions.GET_TEMPLATE_TRAINING_ACTIVITY_REQUEST, getTemplateTrainingActivityRequest)
  yield takeLatest(actions.UPDATE_TEMPLATE_TRAINING_ACTIVITY_REQUEST, updateTemplateTrainingActivityRequest)
  yield takeLatest(actions.GET_ALL_COURSES_REQUEST, getAllCoursesRequest)
}

export default templateTrainingActivtySagas
