import config from "./APIConfig"
import { postDataApi } from '../index'

export const resendMessageNotification = (data) => {
  const { url } = config.resendMessageNotification

  return postDataApi(url(data), {})
}

export default resendMessageNotification
