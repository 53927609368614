import cx from 'classnames'
import React, { useMemo } from "react"
import {
  Col,
  Row,
  Input,
  Button,
  FormGroup
} from "reactstrap"
import { useForm } from "react-hook-form"
import { ChevronDown, ChevronLeft } from "react-feather"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { editIcon } from "@src/assets/images/new-theme"
import { getLocaleMessage } from "@utils"
import {
  CustomLabel,
  CustomUILoader,
  FileUploader,
  ReactSelect,
  ErrorMessage
} from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import AdditionalPurchaseOrderList from "./additional-purchase-order-list"
import { SECTION_TYPES } from "./config"
import CostCategory from './CostCategory'

const {
  SCHEDULING: {
    EPO,
    ENTER_EPO,
    ADDITIONAL_PURCHASE_ORDER,
    COST_CATEGORY,
    BILLING_TYPE,
    PRIMARY_PO,
    SKIP_AND_REMOVE_HOLD,
    COMPLETE,
    SUBMIT_AND_REMOVE_HOLD,
    TASK_NAME,
    ADD_ADDITIONAL_PURCHASE_ORDER,
    PRIMARY_PURCHASE_ORDER,
    PRIMARY_BILLING_SECTION,
    PRODUCT_ID,
    AMOUNT,
    ADDITIONAL_BILLING_SECTION,
    ADD_ADDITIONAL_BILLING,
    ALL_ADDITIONAL_BILLINGS,
    CLICK_TO_ADD,
    MISSING_ID,
    ADD_PRODUCT_ID_FIRST,
    NON_BILLABLE,
    PRIMARY_PURCHASE_ORDER_FILES,
    ADDITIONAL_PURCHASE_ORDER_FILES
  },
  SAVE,
  BACK,
  DELETE,
  CANCEL,
  SAVE_EDITS
} = LANGUAGE_CONSTANTS

const PurchaseOrderDetailForm = ({
  intl,
  isProductDetailsFetched,
  activeTask,
  data,
  errors,
  isEditMode,
  isEditPrimaryPurchaseOrder,
  isManagePurchaseOrder,
  isOpenPurchaseOrderForm,
  isRemoveHold,
  isUpdatingOrderDetail,
  isFetchingAdditionalBilling,
  purchaseOrderDetails = {},
  purchaseOrderList = [],
  purchaseOrderVM,
  primaryPurchaseOrder,
  primaryPOFiles = [],
  productDetails = {},
  activeSections = [],
  onChangeDetails = () => { },
  onDelete = () => { },
  onEditMode = () => { },
  onCancelEditMode = () => { },
  onEditPrimaryPurchaseOrder = () => { },
  onGoBack = () => { },
  onSaveDetails = () => { },
  onSavePrimaryPurchaseOrder = () => { },
  onSkip = () => { },
  onSubmit = () => { },
  onTogglePurchaseOrderModal = () => { },
  onTogglePurchaseOrderForm = () => { },
  onToggleActiveSection = () => { },
  onGoToContractPage = () => { }
}) => {
  const { control } = useForm()

  const isShowPrimarySection = useMemo(() => activeSections.includes(SECTION_TYPES.PRIMARY), [activeSections])
  const isShowAdditionalSection = useMemo(() => activeSections.includes(SECTION_TYPES.ADDITIONAL), [activeSections])
  
  const productIdText = useMemo(() => {
    return productDetails.primaryProductIdList?.join(', ') || ""
  }, [productDetails.primaryProductIdList])

  const isProductIdMissing = useMemo(() => {
    return isProductDetailsFetched ? productDetails.primaryProductIdList ? !productDetails.primaryProductIdList.length : false : false
  }, [isProductDetailsFetched, productDetails.primaryProductIdList])

  const productAmountText = useMemo(() => {
    if (!productDetails.primaryProductIdList?.length) {
      return getLocaleMessage(intl, ADD_PRODUCT_ID_FIRST)
    } else {
      if (productDetails.isNonBillable) {
        return getLocaleMessage(intl, NON_BILLABLE)
      }
      return productDetails.amount
    }
  }, [productDetails])

  return (
    <>
    <p className={cx("modal-sub-title", { "border-bottom pb-2": (!!isManagePurchaseOrder) })}>
      {getLocaleMessage(intl, TASK_NAME)}:<span> {activeTask?.task || data?.taskName || data?.checkListName}</span>
    </p>
    {!!isManagePurchaseOrder ?
      <div className="plan-setup-page setup-accordion border-0 p-0">
        <div className="setup-accordion-header">
          <button
            type='button'
            className='waves-effect btn advanced-btn more-less-btn mb-1'
            onClick={() => onToggleActiveSection(SECTION_TYPES.PRIMARY)}>
            {getLocaleMessage(intl, PRIMARY_BILLING_SECTION)}
            {isShowPrimarySection ? <ChevronLeft /> : <ChevronDown />}
          </button>
          {isShowPrimarySection && <Row className="purchase-order-field">
            <Col md={12}>
              <FormGroup>
              <div className='d-flex justify-content-between'>
                <CustomLabel title={PRODUCT_ID} />
                {isProductIdMissing && <div className='missing-product-id'>
                  <label className='missing-label'>*{getLocaleMessage(intl, MISSING_ID)}:</label>
                  <label className='missing-button' onClick={onGoToContractPage}>
                    {getLocaleMessage(intl, CLICK_TO_ADD)}
                  </label>
                </div>}
              </div>
              <div className='form-control product-id'>{productIdText || getLocaleMessage(intl, PRODUCT_ID)}</div>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <CustomLabel title={AMOUNT} />
                <Input
                  type="text"
                  name="text"
                  placeholder={getLocaleMessage(intl,
                    AMOUNT
                  )}
                  value={productAmountText}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={10} xs={10}>
              <FormGroup>
                <CustomLabel title={PRIMARY_PURCHASE_ORDER} />
                <Input
                  type="text"
                  name="text"
                  placeholder={getLocaleMessage(intl,
                    PRIMARY_PURCHASE_ORDER
                  )}
                  value={primaryPurchaseOrder}
                  disabled={!isEditPrimaryPurchaseOrder}
                  onChange={(event) => onChangeDetails(
                    "primaryPurchaseOrder",
                    event.target.value
                  )}
                />
              </FormGroup>
            </Col>
            <Col md={2} xs={2}>
              <div className="no-label"></div>
              <div className="edit-icon-border" onClick={onEditPrimaryPurchaseOrder}>
                <img src={editIcon} alt="editIcon" />
              </div>
            </Col>
            <Col className="mb-1" md={12}>
              <FileUploader
                  id="primaryPOFiles"
                  isRequiresSolidButton={false}
                  label={PRIMARY_PURCHASE_ORDER_FILES}
                  value={primaryPOFiles}
                  onChangeDetail={onChangeDetails} 
              />
            </Col>
          </Row>}
        </div>
      </div>
      :
      <p className="modal-sub-title border-bottom pb-2 mb-2">
        {getLocaleMessage(intl, PRIMARY_PO)} <span>{primaryPurchaseOrder}</span>
      </p>}
      {!!isManagePurchaseOrder ? <>
        <div className="plan-setup-page setup-accordion border-0 p-0">
          <div className="setup-accordion-header">
            <button
              type='button'
              className='waves-effect btn advanced-btn more-less-btn mb-1'
              onClick={() => onToggleActiveSection(SECTION_TYPES.ADDITIONAL)}>
              {getLocaleMessage(intl, ADDITIONAL_BILLING_SECTION)}
              {isShowAdditionalSection ? <ChevronLeft /> : <ChevronDown />}
            </button>
            {isShowAdditionalSection && <>
            <CustomUILoader isLoading={isFetchingAdditionalBilling}>
              <Row className="mt-1">
                <Col md={12}>
                  <FormGroup>
                    <CustomLabel title={isManagePurchaseOrder ? EPO : ADDITIONAL_PURCHASE_ORDER} required />
                    <Input
                      placeholder={getLocaleMessage(
                        intl,
                        isManagePurchaseOrder ? ENTER_EPO : ADDITIONAL_PURCHASE_ORDER
                      )}
                      value={purchaseOrderDetails.additionalPOName}
                      onChange={(event) => onChangeDetails(
                        "additionalPOName",
                        event.target.value
                      )}
                    />
                  </FormGroup>
                  <ErrorMessage
                    className="error-message"
                    isShow={!!errors["additionalPOName"]}
                    message={errors["additionalPOName"]}
                  />
                </Col>
              </Row>
              <Row>
                <CostCategory
                  id="costCategory"
                  name="costCategory"
                  isRequired
                  defaultOptions={purchaseOrderVM.costCategories?.items || []}
                  label={getLocaleMessage(intl, COST_CATEGORY)}
                  placeholder={getLocaleMessage(intl, COST_CATEGORY)}
                  observationTaskId={activeTask?.observationTaskId || data?.observationTaskId}
                  errors={errors}
                  value={purchaseOrderDetails.costCategory}
                  onSelect={(id, selected) => onChangeDetails(id, selected || null)}
                />
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <ReactSelect
                      id="billingType"
                      name="billingType"
                      label={getLocaleMessage(intl, BILLING_TYPE)}
                      placeHolder={getLocaleMessage(intl, BILLING_TYPE)}
                      control={control}
                      isRequired
                      isFormattedValue
                      value={purchaseOrderDetails.billingType}
                      options={purchaseOrderVM.billingTypes}
                      onSelect={(selected) => onChangeDetails("billingType", selected || null)}
                    />
                  </FormGroup>
                  <ErrorMessage
                    className="error-message"
                    isShow={!!errors["billingType"]}
                    message={errors["billingType"]}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-1" md={12}>
                  <FileUploader
                      id="additionalPOFiles"
                      isRequiresSolidButton={false}
                      label={ADDITIONAL_PURCHASE_ORDER_FILES}
                      value={purchaseOrderDetails.additionalPOFiles || []}
                      onChangeDetail={onChangeDetails} 
                  />
                </Col>
              </Row>
              <Row className="mb-1">
                {isEditMode && <Col md={6}>
                  <Button
                    outline
                    onClick={onCancelEditMode}
                    className={"delete-button secondary-outlined w-100"}
                  >
                    {getLocaleMessage(intl, CANCEL)}
                  </Button>
                </Col>}
                <Col md={isEditMode ? 6 : 12}>
                  <Button
                    className={"black-solid w-100"}
                    color="primary"
                    onClick={onSaveDetails}
                  >
                    {isEditMode ? getLocaleMessage(intl, SAVE_EDITS) : getLocaleMessage(intl, ADD_ADDITIONAL_BILLING)}
                  </Button>
                </Col>
              </Row>
              {isEditMode && <Row className="mb-1">
                <Col md={12}>
                  <Button
                    className={"btn w-100"}
                    color="danger"
                    onClick={() => onDelete(purchaseOrderDetails.additionalPOId)}
                  >
                    {getLocaleMessage(intl, DELETE)}
                  </Button>
                </Col>
              </Row>}
              </CustomUILoader>
              {!!purchaseOrderList.length && !isUpdatingOrderDetail && <div className='mb-1'>
                <hr />
                <label className='mb-1'>{getLocaleMessage(intl, ALL_ADDITIONAL_BILLINGS)}</label>
                <AdditionalPurchaseOrderList isManagePurchaseOrder purchaseOrderList={purchaseOrderList} onEditMode={onEditMode} onDelete={onDelete} />
              </div>}
            </>}
          </div>
        </div>
      </> : <> 
      {!!purchaseOrderList.length && !isUpdatingOrderDetail && <> <AdditionalPurchaseOrderList purchaseOrderList={purchaseOrderList} onEditMode={onEditMode} onDelete={onDelete} />
        {!isOpenPurchaseOrderForm && <Row className="mb-1 mt-1 border-bottom pb-1">
          <Col md={12}>
            <Button
              className={"black-solid w-100"}
              color="primary"
              onClick={onTogglePurchaseOrderForm}
            >
              {getLocaleMessage(intl, ADD_ADDITIONAL_PURCHASE_ORDER)}
            </Button>
          </Col>
        </Row>}
      </>}
      {(!purchaseOrderList.length || isOpenPurchaseOrderForm) && <>
        <Row className="mt-1">
          <Col md={12}>
            <FormGroup>
              <CustomLabel title={isManagePurchaseOrder ? EPO : ADDITIONAL_PURCHASE_ORDER} required />
              <Input
                placeholder={getLocaleMessage(
                  intl,
                  isManagePurchaseOrder ? ENTER_EPO : ADDITIONAL_PURCHASE_ORDER
                )}
                value={purchaseOrderDetails.additionalPOName}
                onChange={(event) => onChangeDetails(
                  "additionalPOName",
                  event.target.value
                )}
              />
            </FormGroup>
            <ErrorMessage
              className="error-message"
              isShow={!!errors["additionalPOName"]}
              message={errors["additionalPOName"]}
            />
          </Col>
        </Row>
        <Row>
          <CostCategory
            id="costCategory"
            name="costCategory"
            isRequired
            defaultOptions={purchaseOrderVM.costCategories?.items || []}
            label={getLocaleMessage(intl, COST_CATEGORY)}
            placeholder={getLocaleMessage(intl, COST_CATEGORY)}
            observationTaskId={activeTask?.observationTaskId || data?.observationTaskId}
            errors={errors}
            value={purchaseOrderDetails.costCategory}
            onSelect={(id, selected) => onChangeDetails(id, selected || null)}
          />
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <ReactSelect
                id="billingType"
                name="billingType"
                label={getLocaleMessage(intl, BILLING_TYPE)}
                placeHolder={getLocaleMessage(intl, BILLING_TYPE)}
                control={control}
                isRequired
                isFormattedValue
                value={purchaseOrderDetails.billingType}
                options={purchaseOrderVM.billingTypes}
                onSelect={(selected) => onChangeDetails("billingType", selected || null)}
              />
            </FormGroup>
            <ErrorMessage
              className="error-message"
              isShow={!!errors["billingType"]}
              message={errors["billingType"]}
            />
          </Col>
        </Row>
        <Row className="mb-1 pb-1">
          <Col md={12}>
            <Button
              className={"black-solid w-100"}
              color="primary"
              onClick={onSaveDetails}
            >
              {isEditMode ? getLocaleMessage(intl, SAVE_EDITS) : getLocaleMessage(intl, SAVE)}
            </Button>
          </Col>
        </Row>
        {isEditMode && <Row className="mb-1 border-bottom pb-1">
          <Col md={12}>
            <Button
              className={"btn w-100"}
              color="danger"
              onClick={() => onDelete(purchaseOrderDetails.additionalPOId)}
            >
              {getLocaleMessage(intl, DELETE)}
            </Button>
          </Col>
        </Row>}
        {!!purchaseOrderList.length && <Row className="mb-1 border-bottom pb-1">
          <Col md={12}>
            <Button
              outline
              onClick={onTogglePurchaseOrderForm}
              className={"delete-button secondary-outlined w-100"}
            >
              {getLocaleMessage(intl, CANCEL)}
            </Button>
          </Col>
        </Row>}
      </>}
    </>}
    {(!!isManagePurchaseOrder) ?
      <Row>
        <Col md={6}>
          <Button
            outline
            onClick={onTogglePurchaseOrderModal}
            className={"delete-button secondary-outlined w-100"}
          >
            {getLocaleMessage(intl, BACK)}
          </Button>
        </Col>
        <Col md={6}>
          <Button
            className={"primary-solid w-100"}
            color="primary"
            onClick={onSavePrimaryPurchaseOrder}
          >
            {getLocaleMessage(intl, COMPLETE)}
          </Button>
        </Col>
      </Row> :
      <Row>
        <Col md={6}>
          <Button
            outline
            onClick={onSkip}
            disabled={!!purchaseOrderList.length}
            className={"delete-button secondary-outlined w-100"}
          >
            {getLocaleMessage(intl, SKIP_AND_REMOVE_HOLD)}
          </Button>
        </Col>
        <Col md={6}>
          <Button
            className={"primary-solid w-100"}
            color="primary"
            disabled={!purchaseOrderList.length}
            onClick={onSubmit}
          >
            {getLocaleMessage(intl, SUBMIT_AND_REMOVE_HOLD)}
          </Button>
        </Col>
      </Row>}
    {isRemoveHold && <Row>
      <Col md={12}>
        <Button
          outline
          className={"delete-button secondary-outlined w-100 mt-1"}
          onClick={onGoBack}
        >
          {getLocaleMessage(intl, BACK)}
        </Button>
      </Col>
    </Row>}
  </>
  )
}

export default localeMessageWrapper(PurchaseOrderDetailForm)
