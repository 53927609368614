import { useCallback, useMemo, useState } from 'react'

// ** Styles
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  CHECKPOINT_ORIGNATED_FROM_TYPES,
  ROUTES,
  PERMISSION_ROUTES,
  TABLE_LIST_KEYS,
  TEMPLATE_ACTION_TYPE
} from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { ConfirmationModal } from '@views/components'
import * as actions from '@store/actions'
import { isPermissionDenied } from '@router/Helper'
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"
import RowActionsComponent from "@views/components/table-view/row-actions"
import { ROW_ACTION_TYPES } from "@views/components/table-view/row-actions/config"

const { CLONE_TITLE, NEW_VERSION_TITLE } = LANGUAGE_CONSTANTS

const RowActionsGrid = ({ intl, data, templateId }) => {

  const dispatch = useDispatch()
  const history = useHistory()

  const { campaignServiceChecklistCheckpoints: { tableData }, templateCheckpoints } = useSelector(state => state)
  const { pageSize = 0, pageNumber = 0 } = data?.campaignServiceCheckpointId
    ? (tableData[`checklist_${data.campaignServiceCheckListId}`]?.data || {}) : templateCheckpoints

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false)
  const [isOpenNewVersionModal, setOpenNewVersionModal] = useState(false)
  const [isOpenCloneModal, setOpenCloneModal] = useState(false)

  const templateInfo = useMemo(() => {
    const { search } = window.location
    let templateVersionId = 1
    if (search) {
      const query = new URLSearchParams(search)
      templateVersionId = query.get('tp_v') || 1
    }
    return {
      templateId,
      templateVersionId
    }
  }, [data, templateId])

  const handleGetCampaignCheckpointUrl = useCallback((
    {
      checkpointId,
      checkpointVersionId
    }
  ) => {
    const { pathname } = window.location
    //Navigate to ROUTES.checklistCheckpointDetail
    const search = data.campaignServiceCheckpointId ? `?cs_cp_id=${data.campaignServiceCheckpointId}&at=${data.activityTypeId || '1'}` : ''
    if (pathname.includes('campaigns/overview')) {
      if (!pathname.includes('checklist')) {
        return `${pathname}/checklist/${data.campaignServiceCheckListId}/activities/${checkpointId}/${checkpointVersionId}${search}`
      }
      return `${pathname}/activities/${checkpointId}/${checkpointVersionId}${search}`
    } else {
      try {
        const queryParams = new URLSearchParams(window.location.search)
        const serviceId = queryParams.get("sid")
        const campaignId = queryParams.get("cmpid")
        const url = `/campaigns/overview/${campaignId}/service/${serviceId}/checklist/${data.campaignServiceCheckListId}`
        return `${url}/activities/${checkpointId}/${checkpointVersionId}${search}`
      } catch (err) {
        return ''
      }
    }
  }, [data.activityTypeId, data.campaignServiceCheckListId, data.campaignServiceCheckpointId])

  const handleViewCheckpoint = useCallback((e) => {
    if (e) {
      e.preventDefault()
    }
    const { checkpointId, checkpointVersionId, activityTypeId = '1' } = data
    let navigateToRoute = `${ROUTES.checkpointsDetail.slice(0, ROUTES.checkpointsDetail.length - 10)}${checkpointId}/${checkpointVersionId}`
    if (window.location.pathname.includes(CHECKPOINT_ORIGNATED_FROM_TYPES.CAMPAIGN)) {
      navigateToRoute = handleGetCampaignCheckpointUrl({
        checkpointId,
        checkpointVersionId
      })
    } else {
      navigateToRoute = `${navigateToRoute}?at=${activityTypeId}`
    }

    if (!!navigateToRoute) {
      history.push(navigateToRoute)
    }
  }, [data, handleGetCampaignCheckpointUrl])

  const handleNavigateToEditCheckpointMode = useCallback((checkpointData) => {
    const url = window.location.pathname.toLowerCase()
    const pathKeyList = url.split('/')
    const templateId = pathKeyList.pop()
    const path = ROUTES.editTemplate

    const payload = {
      activeStep: 2,
      activeSubStep: 1,
      checkpointId: checkpointData ? checkpointData.checkpointId : data.checkpointId,
      checkpointVersionId: checkpointData ? checkpointData.checkpointVersionId : data.checkpointVersionId,
      //Note: set activityType when activity is cloned
      activityType: data.activityTypeId?.toString() || '1'
    }

    if (window.location.pathname.includes(CHECKPOINT_ORIGNATED_FROM_TYPES.CAMPAIGN)) {
      const navigateToRoute = handleGetCampaignCheckpointUrl({
        checkpointId: payload.checkpointId,
        checkpointVersionId: payload.checkpointVersionId
      })
      if (!!navigateToRoute) {
        history.push(navigateToRoute)
      }

      return false
    }

    if (url.includes(ROUTES.addNewTemplates.toLowerCase()) || url.includes(TEMPLATE_ACTION_TYPE.EDIT.toLowerCase())) {
      dispatch(actions.setEditCheckpointMode({
        isEditMode: true,
        data: payload
      }))
    } else {
      const { search } = window.location
      let templateVersion = 1
      if (search) {
        const query = new URLSearchParams(search)
        templateVersion = query.get('tp_v') || 1
      }
      const searchParams = `?tp_v=${templateVersion}&at=${payload.activityType}`
      history.push(`${path.slice(0, path.length - 11)}${templateId}${searchParams}`, payload)
    }
  }, [data, handleGetCampaignCheckpointUrl])


  const handleToggleDeleteCheckpoint = useCallback((event) => {
    if (event) {
      event.preventDefault()
    }
    setOpenDeleteModal(!isOpenDeleteModal)
  }, [isOpenDeleteModal])

  const handleDeleteCheckpoint = useCallback(() => {
    // Note: In case of campaign service checklist view ->
    // campaign service checkpoint relationship ID to delete checkpoint -------> campaignServiceCheckpointId
    if (data?.campaignServiceCheckpointId) {
      dispatch(actions.deleteCampaignServiceChecklistCheckpoint([data.campaignServiceCheckpointId], () => {
        let id = ''
        //todo : get child list for checklist based on selected child grid in overview
        if (data.campaignServiceCheckListId) {
          id = data.campaignServiceCheckListId
        }
        if (id && !isNaN(id)) {
          const { pathname } = window.location
          if (pathname.includes('campaigns/overview')) {
            //Todo: Refresh checklist in service detail overview after delete checkpoint from checklist
            // if (!window.location.pathname.includes('checklist')) {
            //   dispatch(actions.getCampaignServiceChecklistRequest({
            //     campaignServiceId: getIdFromUrl(),
            //     pageNumber: serviceChecklistsPageNumber,
            //     pageSize: serviceChecklistsPageSize }))
            // }

            if (pathname.includes('service') && !pathname.includes('checklist')) {
              //Note: We can use this where we do not have advance search form
              //When checkpoint list is used as child grid of checklist
              history.replace({
                search: `?cp_updated=${id}`
              })
            } else {
              //Note: If we have advance search form , we have to call common api function 
              dispatch(actions.refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: true }))
            }
          } else {
            dispatch(actions.refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: true }))
          }
        }
      }))
    } else {
      // Note: In case of template view ->
      // template checkpoint relationship ID to delete checkpoint -------> templateCheckpointId
      dispatch(actions.removeCheckpointFromTemplateRequest({
        templateCheckpointId: data.templateCheckpointId
      }, () => {
        dispatch(actions.refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: true }))
      }))
    }
    handleToggleDeleteCheckpoint()
  }, [data, handleToggleDeleteCheckpoint, pageNumber, pageSize])

  const handleToggleCloneModal = useCallback((event) => {
    if (event) {
      event.preventDefault()
    }
    setOpenCloneModal(!isOpenCloneModal)
  }, [isOpenCloneModal])

  const handleConfirmClone = useCallback(() => {
    dispatch(actions.cloneTemplateCheckpointRequest({
      checkpointId: data.checkpointId,
      checkpointVersionId: data.checkpointVersionId,
      activityTypeId: data.activityTypeId,
      ...templateInfo
    }, (res) => {
      if (res) {
        handleToggleCloneModal()
        handleNavigateToEditCheckpointMode(res)
      }
    }))
  }, [data, templateInfo, handleToggleCloneModal, handleNavigateToEditCheckpointMode])

  const handleToggleAddNewVersion = useCallback((event) => {
    if (event) {
      event.preventDefault()
    }
    setOpenNewVersionModal(!isOpenNewVersionModal)
  }, [isOpenNewVersionModal])

  const handleConfirmAddNewVersion = useCallback(() => {
    dispatch(actions.addNewCheckpointVersionRequest({
      checkpointId: data.checkpointId,
      activityTypeId: data.activityTypeId
    }, () => {
      setOpenNewVersionModal(false)
    }))
  }, [data, isOpenNewVersionModal])


  const handleClickActionItem = useCallback(
    (id) => {
      if (id === ROW_ACTION_TYPES.VIEW) {
        handleViewCheckpoint()
      } else if (id === ROW_ACTION_TYPES.DELETE) {
        handleToggleDeleteCheckpoint()
      } else if (id === ROW_ACTION_TYPES.CLONE) {
        handleToggleCloneModal()
      } else if (id === ROW_ACTION_TYPES.NEW_VERSION) {
        handleToggleAddNewVersion()
      }
    },
    [handleToggleAddNewVersion, handleToggleDeleteCheckpoint, handleViewCheckpoint, handleToggleCloneModal]
  )

  const config = useMemo(() => {
    const actionTypes = []
    if (!isPermissionDenied(ROUTES.checkpointsDetail)) {
      actionTypes.push(ROW_ACTION_TYPES.VIEW)
    }
    if (!isPermissionDenied(PERMISSION_ROUTES.cloneCheckpoint)) {
      actionTypes.push(ROW_ACTION_TYPES.CLONE)
    }
    if (!isPermissionDenied(PERMISSION_ROUTES.newVersionCheckpoint)) {
      actionTypes.push(ROW_ACTION_TYPES.NEW_VERSION)
    }
    if (!isPermissionDenied(PERMISSION_ROUTES.deleteCheckpoint)) {
      actionTypes.push(ROW_ACTION_TYPES.DELETE)
    }
    return {
      actionTypes,
      deleteModal: {
        nameLocaleId: data.checkPointName
      },
      cloneModal: {
        nameLocaleId: data.checkPointName,
        titleLocalId: CLONE_TITLE
      },
      newVersionModal: {
        nameLocaleId: data.checkPointName,
        titleLocalId: NEW_VERSION_TITLE
      }
    }
  }, [])

  return (
    <>
      <RowActionsComponent
        config={config}
        rowId={data.checkpointId}
        isOpenDeleteModal={isOpenDeleteModal}
        onClickActionItem={handleClickActionItem}
        onDeleteRow={handleDeleteCheckpoint}
        onToggleDeleteModal={handleToggleDeleteCheckpoint}
      />

      <ConfirmationModal
        open={isOpenCloneModal}
        name={data.checkPointName || 'activity'}
        title={getLocaleMessage(intl, CLONE_TITLE)}
        onConfirm={handleConfirmClone}
        onToggleModal={handleToggleCloneModal}
      />
      <ConfirmationModal
        open={isOpenNewVersionModal}
        name={data.checkPointName || 'activity'}
        title={getLocaleMessage(intl, NEW_VERSION_TITLE)}
        onConfirm={handleConfirmAddNewVersion}
        onToggleModal={handleToggleAddNewVersion}
      />
    </>
  )
}

export default localeMessageWrapper(RowActionsGrid)
