export const GET_CAMPAIGN_CONTRACT_LIST_REQUEST = 'GET_CAMPAIGN_CONTRACT_LIST_REQUEST'
export const GET_CAMPAIGN_CONTRACT_PRODUCT_LIST_REQUEST = 'GET_CAMPAIGN_CONTRACT_PRODUCT_LIST_REQUEST'
export const GET_CAMPAIGN_CONTRACT_VM_REQUEST = 'GET_CAMPAIGN_CONTRACT_VM_REQUEST'
export const GET_CAMPAIGN_CONTRACT_DETAIL_REQUEST = 'GET_CAMPAIGN_CONTRACT_DETAIL_REQUEST'
export const ADD_CAMPAIGN_CONTRACT_DETAIL_REQUEST = 'ADD_CAMPAIGN_CONTRACT_DETAIL_REQUEST'
export const UPDATE_CAMPAIGN_CONTRACT_DETAIL_REQUEST = 'UPDATE_CAMPAIGN_CONTRACT_DETAIL_REQUEST'
export const DELETE_CAMPAIGN_CONTRACT_REQUEST = 'DELETE_CAMPAIGN_CONTRACT_REQUEST'

/****************************************************/
/** GET_CAMPAIGN_CONTRACT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCampaignContractListRequest :  To Get Campaign Contract List
 *
 */
export const getCampaignContractListRequest = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_CONTRACT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_CAMPAIGN_CONTRACT_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method addCampaignContractDetailRequest :  To add campaign contract detail
 *
 */
export const addCampaignContractDetailRequest = (payload, callback) => {
  return {
    type: ADD_CAMPAIGN_CONTRACT_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_CONTRACT_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method getCampaignContractDetailRequest : To get campaign contract detail
 *
 */
export const getCampaignContractDetailRequest = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_CONTRACT_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_CAMPAIGN_CONTRACT_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method updateCampaignContractDetailRequest :  To update campaign contract detail
 *
 */
export const updateCampaignContractDetailRequest = (payload, callback) => {
  return {
    type: UPDATE_CAMPAIGN_CONTRACT_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_CONTRACT_PRODUCT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCampaignContractProductListRequest :  To Get Campaign Contract products list
 *
 */
export const getCampaignContractProductListRequest = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_CONTRACT_PRODUCT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CAMPAIGN_CONTRACT_VM_REQUEST **/
/****************************************************/

/**
 * @method getCampaignContractVMRequest :  To Get Campaign Contract VM
 *
 */
export const getCampaignContractVMRequest = (payload, callback) => {
  return {
    type: GET_CAMPAIGN_CONTRACT_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_CAMPAIGN_CONTRACT_REQUEST **/
/****************************************************/

/**
 * @method deleteCampaignContractRequest :  To delete campaign contract
 *
 */
export const deleteCampaignContractRequest = (payload, callback) => {
  return {
    type: DELETE_CAMPAIGN_CONTRACT_REQUEST,
    payload,
    callback
  }
}