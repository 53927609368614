import { encode } from "base-64"

import AppSettings from "@app-settings"
import config from "./APIConfig"
import { getDataApi, postDataApi } from "../index"

const createScormCloudToken = async () => {
  const { url } = config.createScormCloudToken
  const token = encode(
    `${AppSettings.REACT_APP_SCORM_CLOUD_APP_ID}:${AppSettings.REACT_APP_SCORM_CLOUD_SECRET_KEY}`
  )

  const payload = {
    permissions: {
      scopes: [
        "read",
        "write:registration",
        "read:registration",
        "write:course",
        "write:appmgmt",
        "read:appmgmt"
      ],
      applicationName: "string"
    },
    expiry: new Date(
      new Date().setHours(new Date().getHours() + 2)
    ).toISOString()
  }

  return await postDataApi(
    url,
    payload,
    {
      Authorization: `Basic ${token}`,
      "Content-Type": "application/json"
    },
    { preventTokenOverride: true, pathConfig: { isScormCloudApi: true } }
  )
}

const checkIfAlreadyRegistered = async (data) => {
  const { url } = config.getRegistrationDetail
  return await getDataApi(
    url(data),
    { Authorization: `Bearer ${data.token}` },
    { preventTokenOverride: true, pathConfig: { isScormCloudApi: true } }
  )
}

const createRegistration = async (data) => {
  const { courseId, registrationId, user } = data || {}
  const { url } = config.createRegistration

  const basicToken = encode(
    `${AppSettings.REACT_APP_SCORM_CLOUD_APP_ID}:${AppSettings.REACT_APP_SCORM_CLOUD_SECRET_KEY}`
  )

  const payload = {
    courseId,
    learner: {
      id: user.email,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName
    },
    registrationId
  }

  return await postDataApi(
    url,
    payload,
    { Authorization: `Basic ${basicToken}` },
    {
      preventTokenOverride: true,
      pathConfig: { isScormCloudApi: true }
    }
  )
}

const setRegistrationConfiguration = async (data) => {
  const { registrationId, token } = data || {}
  const { url } = config.setRegistrationConfiguration
  const payload = {
    settings: [
      {
        settingId: "PlayerScoLaunchType",
        value: "FRAMESET",
        explicit: false
      }
    ]
  }

  return await postDataApi(
    url({ registrationId }),
    payload,
    { Authorization: `Bearer ${token}` },
    {
      preventTokenOverride: true,
      pathConfig: { isScormCloudApi: true }
    }
  )
}

const createRegistrationLaunchLink = async (data) => {
  const { url } = config.createRegistrationLaunchLink

  const payload = {
    redirectOnExitUrl: "closer"
  }

  return setRegistrationConfiguration({
    registrationId: data.registrationId,
    token: data.token
  }).then(() => {
    return postDataApi(
      url(data),
      payload,
      {
        authorization: `Bearer ${data.token}`
      },
      { preventTokenOverride: true, pathConfig: { isScormCloudApi: true } }
    )
  })
}

export const getTrainingLaunchLink = (data) => {
  const { courseId, registrationId, user = {} } = data || {}

  return createScormCloudToken()
    .then((tokenResponse) => {
      if (tokenResponse.status === 200) {
        const token = tokenResponse.data.result
        return checkIfAlreadyRegistered({
          token,
          registrationId
        })
          .then((response) => {
            if (response.status === 200) {
              return createRegistrationLaunchLink({
                token,
                registrationId
              })
            } else {
              return createRegistration({
                user,
                registrationId,
                courseId
              }).then((res) => {
                if (res.status === 204 || res.status === 200) {
                  return createRegistrationLaunchLink({
                    token,
                    registrationId
                  })
                }
              })
            }
          })
          .catch((error) => {
            if (error.response.status === 404) {
              return createRegistration({
                user,
                registrationId,
                courseId
              }).then((res) => {
                if (res.status === 204 || res.status === 200) {
                  return createRegistrationLaunchLink({
                    token,
                    registrationId
                  })
                }
              })
            }
          })
      } else {
        return tokenResponse
      }
    })
    .catch((error) => {
      return error
    })
}

export default getTrainingLaunchLink
