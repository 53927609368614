import config from "./APIConfig"
import { postDataApi } from "../index"

/**
 * @method addTemplateActivityGroup
 * 
 * @param data 
 * {
    "groupName": "string",
    "templateId": 0,
    "templateVersionId": 0
  }
 * @returns 
 */
export const addTemplateActivityGroup = (payload) => {
  const { url } = config.addTemplateActivityGroup

  return postDataApi(url(payload), payload)
}

export default addTemplateActivityGroup
