export const GET_ORGANIZATION_LIST_REQUEST = 'GET_ORGANIZATION_LIST_REQUEST'
export const GET_ADD_ORGANIZATION_DETAIL_VM_REQUEST = 'GET_ADD_ORGANIZATION_DETAIL_VM_REQUEST'
export const GET_ORGANIZATION_LIST_VM_REQUEST = 'GET_ORGANIZATION_LIST_VM_REQUEST'
export const ADD_ORGANIZATION_DETAIL_REQUEST = 'ADD_ORGANIZATION_DETAIL_REQUEST'
export const ADD_ORGANIZATION_ADDRESS_REQUEST = 'ADD_ORGANIZATION_ADDRESS_REQUEST'
export const GET_ORGANIZATION_DETAIL_WITH_ID_REQUEST = 'GET_ORGANIZATION_DETAIL_WITH_ID_REQUEST'
export const EDIT_ORGANIZATION_DETAIL_REQUEST = 'EDIT_ORGANIZATION_DETAIL_REQUEST'
export const GET_ORGANIZATION_ADDRESS_REQUEST = 'GET_ORGANIZATION_ADDRESS_REQUEST'
export const UPDATE_ORGANIZATION_ADDRESS_REQUEST = 'UPDATE_ORGANIZATION_ADDRESS_REQUEST'
export const DELETE_ORGANIZATION_REQUEST = 'DELETE_ORGANIZATION_REQUEST'
export const DELETE_ORGANIZATION_ADDRESS_REQUEST = 'DELETE_ORGANIZATION_ADDRESS_REQUEST'
export const GET_ORGANIZATION_TREE_VM_REQUEST = 'GET_ORGANIZATION_TREE_VM_REQUEST'
export const VALIDATE_ORGNIZATION_NAME_REQUEST = 'VALIDATE_ORGNIZATION_NAME_REQUEST'
export const GET_FILTERED_ORGANIZATIONS = 'GET_FILTERED_ORGANIZATIONS'
export const GET_ORGANIZATIONS_REQUEST = 'GET_ORGANIZATIONS_REQUEST'
export const GET_CHILD_ORGANIZATION_LIST_REQUEST = "GET_CHILD_ORGANIZATION_LIST_REQUEST"
export const REMOVE_CHILD_ORGANIZATION_REQUEST = "REMOVE_CHILD_ORGANIZATION_REQUEST"
export const GET_ORGANIZATIONAL_SITE_LIST_REQUEST = "GET_ORGANIZATIONAL_SITE_LIST_REQUEST"
export const GET_ORGANIZATIONAL_STATUS_LIST_VM_REQUEST = "GET_ORGANIZATIONAL_STATUS_LIST_VM_REQUEST"
export const GET_ORGANIZATION_PLAN_LIST_REQUEST = 'GET_ORGANIZATION_PLAN_LIST_REQUEST'
export const UPDATE_ORGANIZATION_PLAN_REQUEST = 'UPDATE_ORGANIZATION_PLAN_REQUEST'
export const GET_ORGANIZATION_PLAN_REQUEST = 'GET_ORGANIZATION_PLAN_REQUEST'
export const GET_ORGANIZATION_PLAN_DDL_REQUEST = 'GET_ORGANIZATION_PLAN_DDL_REQUEST'
export const GET_ORGANIZATION_PLAN_BLUEPRINT_DDL_REQUEST = 'GET_ORGANIZATION_PLAN_BLUEPRINT_DDL_REQUEST'
export const GET_ORGANIZATION_PLAN_BLUEPRINT_ENERGY_MODEL_DDL_REQUEST = 'GET_ORGANIZATION_PLAN_BLUEPRINT_ENERGY_MODEL_DDL_REQUEST'
export const UPDATE_ORGANIZATION_UNIT_PLAN_REQUEST = 'UPDATE_ORGANIZATION_UNIT_PLAN_REQUEST'
export const ADD_ORGANIZATION_PLAN_BLUEPRINT_REQUEST = 'ADD_ORGANIZATION_PLAN_BLUEPRINT_REQUEST'
export const ADD_ORGANIZATION_PLAN_BLUEPRINT_ENERGY_MODEL_REQUEST = 'ADD_ORGANIZATION_PLAN_BLUEPRINT_ENERGY_MODEL_REQUEST'
export const GET_ORGANIZATION_PLAN_BLUE_PRINT_LIST_REQUEST = 'GET_ORGANIZATION_PLAN_BLUE_PRINT_LIST_REQUEST'
export const GET_ORGANIZATION_PLAN_BLUE_PRINT_ENERGY_MODEL_LIST_REQUEST = 'GET_ORGANIZATION_PLAN_BLUE_PRINT_ENERGY_MODEL_LIST_REQUEST'

/****************************************************/
/** GET_ORGANIZATION_LIST_REQUEST **/
/****************************************************/

/**
 * @method getOrganizationListRequest : To get organization list
 *
 */
export const getOrganizationListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ORGANIZATION_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_FILTERED_ORGANIZATIONS **/
/****************************************************/

/**
 * @method getFilteredOrganizationsRequest : To get filtered organization list for dropdowns
 *
 */
export const getFilteredOrganizationsRequest = (payload, callback) => {
  return {
    type: GET_FILTERED_ORGANIZATIONS,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATIONS_REQUEST **/
/****************************************************/

/**
 * @method getOrganizationsRequest : To get organization list with name, id search and without any search
 *
 */
export const getOrganizationsRequest = (payload, callback) => {
  return {
    type: GET_ORGANIZATIONS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ADD_ORGANIZATION_DETAIL_VM_REQUEST **/
/****************************************************/

/**
 * @method getAddOrganizationDetailVMRequest : To get dropdowns list for organization setup/search
 *
 */
export const getAddOrganizationDetailVMRequest = (callback = () => { }) => {
  return {
    type: GET_ADD_ORGANIZATION_DETAIL_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATION_LIST_VM_REQUEST **/
/****************************************************/

/**
 * @method getOrganizationListVMRequest :  To get dropdowns list for organization list
 *
 */
export const getOrganizationListVMRequest = (callback) => {
  return {
    type: GET_ORGANIZATION_LIST_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** ADD_ORGANIZATION_DETAIL **/
/****************************************************/

/**
 * @method addOrganizationDetailRequest :  To add new organization detail 
 *
 */
export const addOrganizationDetailRequest = (payload, callback) => {
  return {
    type: ADD_ORGANIZATION_DETAIL_REQUEST,
    payload,
    callback
  }
}

/** GET_ORGANIZATION_DETAIL_WITH_ID **/
/****************************************************/

/**
 * @method getOrganizationDetailWithIdRequest : To get organization detail with id
 *
 */
export const getOrganizationDetailWithIdRequest = (payload, callback) => {
  return {
    type: GET_ORGANIZATION_DETAIL_WITH_ID_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** EDIT_ORGANIZATION_DETAIL **/
/****************************************************/

/**
 * @method editOrganizationDetailRequest :  To edit organization detail 
 *
 */
export const editOrganizationDetailRequest = (payload, callback) => {
  return {
    type: EDIT_ORGANIZATION_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_ORGANIZATION **/
/****************************************************/

/**
 * @method deleteOrganizationRequest : To delete organization
 *
 */
export const deleteOrganizationRequest = (payload, callback) => {
  return {
    type: DELETE_ORGANIZATION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATION_ADDRESS **/
/****************************************************/

/**
 * @method getOrganizationAddressRequest :  To get address detail of organization
 *
 */
export const getOrganizationAddressRequest = (payload, callback) => {
  return {
    type: GET_ORGANIZATION_ADDRESS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_ORGANIZATION_ADDRESS **/
/****************************************************/

/**
 * @method addOrganizationAddressRequest :  To add address detail of organization
 *
 */
export const addOrganizationAddressRequest = (payload, callback) => {
  return {
    type: ADD_ORGANIZATION_ADDRESS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_ORGANIZATION_ADDRESS **/
/****************************************************/

/**
 * @method updateOrganizationAddressRequest :  To update address detail of organization
 *
 */
export const updateOrganizationAddressRequest = (payload, callback) => {
  return {
    type: UPDATE_ORGANIZATION_ADDRESS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATION_TREE_VM **/
/****************************************************/

/**
 * @method getOrganizationTreeVMRequest :  To get organization tree dropdown
 *
 */
export const getOrganizationTreeVMRequest = (payload, callback) => {
  return {
    type: GET_ORGANIZATION_TREE_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** VALIDATE_ORGANIZATION_NAME **/
/****************************************************/

/**
 * @method validateOrganizationNameRequest :  To validate organization name
 *
 */
export const validateOrganizationNameRequest = (payload, callback) => {
  return {
    type: VALIDATE_ORGNIZATION_NAME_REQUEST,
    payload,
    callback
  }
}


/****************************************************/
/** DELETE_ORGANIZATION_ADDRESS **/
/****************************************************/

/**
 * @method deleteOrganizationAddressRequest : To delete Organization Address
 *
 */
export const deleteOrganizationAddressRequest = (payload, callback) => {
  return {
    type: DELETE_ORGANIZATION_ADDRESS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CHILD_ORGANIZATION_LIST_REQUEST **/
/****************************************************/

/**
 * @method getChildOrganizationListRequest : To get child organization list
 *
 */
export const getChildOrganizationListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CHILD_ORGANIZATION_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** REMOVE_CHILD_ORGANIZATION_REQUEST **/
/****************************************************/

/**
 * @method removeChildOrganizationRequest : To Unlink Child Organization from parent
 *
 */
export const removeChildOrganizationRequest = (payload, callback = () => { }) => {
  return {
    type: REMOVE_CHILD_ORGANIZATION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATIONAL_SITE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getOrganizationalSiteListRequest : To get organization site list
 *
 */
export const getOrganizationalSiteListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ORGANIZATIONAL_SITE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATIONAL_STATUS_LIST_VM_REQUEST **/
/****************************************************/

/**
 * @method getOrganizationalStatusListVMRequest : To get organization status list VM 
 *
 */
export const getOrganizationalStatusListVMRequest = (callback = () => { }) => {
  return {
    type: GET_ORGANIZATIONAL_STATUS_LIST_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATION_PLAN_LIST_REQUEST **/
/****************************************************/

/**
 * @method getOrganizationPlanListRequest : To get organization plan list
 *
 */
export const getOrganizationPlanListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ORGANIZATION_PLAN_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_ORGANIZATION_PLAN_REQUEST **/
/****************************************************/

/**
 * @method updateOrganizationPlanRequest :  To create/update organization plan 
 *
 */
export const updateOrganizationPlanRequest = (payload, callback) => {
  return {
    type: UPDATE_ORGANIZATION_PLAN_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATION_PLAN_REQUEST **/
/****************************************************/

/**
 * @method getOrganizationPlanRequest : To get organization plan detail
 *
 */
export const getOrganizationPlanRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ORGANIZATION_PLAN_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATION_PLAN_DDL_REQUEST **/
/****************************************************/

/**
 * @method getOrganizationPlanDDLRequest : To get organization plan ddl
 *
 */
export const getOrganizationPlanDDLRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ORGANIZATION_PLAN_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATION_PLAN_BLUEPRINT_DDL_REQUEST **/
/****************************************************/

/**
 * @method getOrganizationPlanBlueprintDDLRequest : To get organization plan Blueprint ddl
 *
 */
export const getOrganizationPlanBlueprintDDLRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ORGANIZATION_PLAN_BLUEPRINT_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ORGANIZATION_PLAN_BLUEPRINT_ENERGY_MODEL_DDL_REQUEST **/
/****************************************************/

/**
 * @method getOrganizationPlanBlueprintEnergyModelDDLRequest : To get organization plan Blueprint EnergyModel ddl
 *
 */
export const getOrganizationPlanBlueprintEnergyModelDDLRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ORGANIZATION_PLAN_BLUEPRINT_ENERGY_MODEL_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_ORGANIZATION_UNIT_PLAN_REQUEST **/
/****************************************************/

/**
 * @method updateOrganizationUnitPlanRequest :  To update organization plan For Unit plan 
 *
 */
export const updateOrganizationUnitPlanRequest = (payload, callback) => {
  return {
    type: UPDATE_ORGANIZATION_UNIT_PLAN_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_ORGANIZATION_PLAN_BLUEPRINT_REQUEST **/
/****************************************************/

/**
 * @method addOrganizationPlanBlueprintRequest :  To create organization plan blueprint
 *
 */
export const addOrganizationPlanBlueprintRequest = (payload, callback) => {
  return {
    type: ADD_ORGANIZATION_PLAN_BLUEPRINT_REQUEST,
    payload,
    callback
  }
}


/****************************************************/
/** ADD_ORGANIZATION_PLAN_BLUEPRINT_ENERGY_MODEL_REQUEST **/
/****************************************************/

/**
 * @method addOrganizationPlanBlueprintEnergyModelRequest :  To create organization plan blueprint energy model
 *
 */
export const addOrganizationPlanBlueprintEnergyModelRequest = (payload, callback) => {
  return {
    type: ADD_ORGANIZATION_PLAN_BLUEPRINT_ENERGY_MODEL_REQUEST,
    payload,
    callback
  }
}

/**
 * @method getOrganizationPlanBlueprintListRequest : To get organization plan blueprint list
 *
 */
export const getOrganizationPlanBlueprintListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ORGANIZATION_PLAN_BLUE_PRINT_LIST_REQUEST,
    payload,
    callback
  }
}

/**
 * @method getOrganizationPlanBlueprintEnergyModelListRequest : To get organization plan blueprint's energy model list
 *
 */
export const getOrganizationPlanBlueprintEnergyModelListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ORGANIZATION_PLAN_BLUE_PRINT_ENERGY_MODEL_LIST_REQUEST,
    payload,
    callback
  }
}
