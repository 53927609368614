export const GET_PROGRAM_ASSEMBLY_LIST_VM_REQUEST = 'GET_PROGRAM_ASSEMBLY_LIST_VM_REQUEST'
export const GET_PROGRAM_ASSEMBLY_LIST_REQUEST = "GET_PROGRAM_ASSEMBLY_LIST_REQUEST"
export const ADD_PROGRAM_ASSEMBLY_LIST_REQUEST = "ADD_PROGRAM_ASSEMBLY_LIST_REQUEST"
export const GET_PROGRAM_SELECTIVE_ASSEMBLY_LIST = "GET_PROGRAM_SELECTIVE_ASSEMBLY_LIST"
export const GET_PROGRAM_ASSEMBLY_INSPECTION_LIST_REQUEST = "GET_PROGRAM_ASSEMBLY_INSPECTION_LIST_REQUEST"
export const GET_PROGRAM_ASSEMBLY_INSPECTION_VM_REQUEST = "GET_PROGRAM_ASSEMBLY_INSPECTION_VM_REQUEST"
export const GET_PROGRAM_ASSEMBLY_REINSPECTION_LIST_REQUEST = "GET_PROGRAM_ASSEMBLY_REINSPECTION_LIST_REQUEST"
export const GET_PROGRAM_ASSEMBLY_REINSPECTION_VM_REQUEST = "GET_PROGRAM_ASSEMBLY_REINSPECTION_VM_REQUEST"
export const UPDATE_PROGRAM_ASSEMBLY_REINSPECTION_LIST_REQUEST = "UPDATE_PROGRAM_ASSEMBLY_REINSPECTION_LIST_REQUEST"
export const GET_PROGRAM_ESCALATION_VM_REQUEST = "GET_PROGRAM_ESCALATION_VM_REQUEST"
export const GET_PROGRAM_ESCALATION_REQUEST = "GET_PROGRAM_ESCALATION_REQUEST"
export const CREATE_PROGRAM_ESCALATION_REQUEST = "CREATE_PROGRAM_ESCALATION_REQUEST"
export const UPDATE_PROGRAM_ESCALATION_REQUEST = "UPDATE_PROGRAM_ESCALATION_REQUEST"
export const GET_PROGRAM_TASK_CLOSE_OUT_VM_REQUEST = "GET_PROGRAM_TASK_CLOSE_OUT_VM_REQUEST"
export const GET_PROGRAM_TASK_CLOSE_OUT_REQUEST = "GET_PROGRAM_TASK_CLOSE_OUT_REQUEST"
export const ADD_PROGRAM_TASK_CLOSE_OUT_REQUEST = "ADD_PROGRAM_TASK_CLOSE_OUT_REQUEST"
export const UPDATE_PROGRAM_TASK_CLOSE_OUT_REQUEST = "UPDATE_PROGRAM_TASK_CLOSE_OUT_REQUEST"
export const UPDATE_PROGRAM_ASSEMBLY_INSPECTION_LIST_REQUEST = "UPDATE_PROGRAM_ASSEMBLY_INSPECTION_LIST_REQUEST"
export const GET_PROGRAM_CONFIRM_SITE_VISIT_LIST_REQUEST = "GET_PROGRAM_CONFIRM_SITE_VISIT_LIST_REQUEST"
export const GET_PROGRAM_CONFIRM_SITE_VISIT_VM_REQUEST = "GET_PROGRAM_CONFIRM_SITE_VISIT_VM_REQUEST"
export const ADD_PROGRAM_CONFIRM_SITE_VISIT_REQUEST = "ADD_PROGRAM_CONFIRM_SITE_VISIT_REQUEST"
export const UPDATE_PROGRAM_CONFIRM_SITE_VISIT_REQUEST = "UPDATE_PROGRAM_CONFIRM_SITE_VISIT_REQUEST"
export const GET_PROGRAM_BASIC_DETAIL_REQUEST = "GET_PROGRAM_BASIC_DETAIL_REQUEST"
export const UPDATE_FINISH_PROGRAM_WIZARD_REQUEST = "UPDATE_FINISH_PROGRAM_WIZARD_REQUEST"
export const GET_PROGRAM_ASSEMBLY_CHECKLIST_LIST_REQUEST = "GET_PROGRAM_ASSEMBLY_CHECKLIST_LIST_REQUEST"
export const GET_OBSERVATION_TEMPLATE_VM_REQUEST = "GET_OBSERVATION_TEMPLATE_VM_REQUEST"
export const GET_OBSERVATION_TEMPLATE_DDL_REQUEST = "GET_OBSERVATION_TEMPLATE_DDL_REQUEST"
export const SET_PROGRAM_OBSERVATION_DETAILS = "SET_PROGRAM_OBSERVATION_DETAILS"
export const SET_PROGRAM_OBSERVATION_TEMPLATE_DETAILS = "SET_PROGRAM_OBSERVATION_TEMPLATE_DETAILS"
export const SET_PROGRAM_OBSERVATION_SELECT_ALL = "SET_PROGRAM_OBSERVATION_SELECT_ALL"
export const GET_TASK_REVIEW_LIST_REQUEST = 'GET_TASK_REVIEW_LIST_REQUEST'

/****************************************************/
/** GET_PROGRAM_ASSEMBLY_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProgramAssemblyListRequest : To get program assembly list
 *
 */
export const getProgramAssemblyListRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_ASSEMBLY_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_ASSEMBLY_LIST_VM_REQUEST **/
/****************************************************/

/**
 * @method getProgramAssemblyListVMRequest : To get prgram assembly list VM
 *
 */
export const getProgramAssemblyListVMRequest = (callback) => {
  return {
    type: GET_PROGRAM_ASSEMBLY_LIST_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** ADD_PROGRAM_ASSEMBLY_LIST_REQUEST **/
/****************************************************/

/**
 * @method addProgramAssemblyListRequest : To add program assembly list
 *
 */
export const addProgramAssemblyListRequest = (payload, callback) => {
  return {
    type: ADD_PROGRAM_ASSEMBLY_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_SELECTIVE_ASSEMBLY_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProgramSelectiveAssemblyListRequest : To get program selective assembly list
 *
 */
export const getProgramSelectiveAssemblyListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROGRAM_SELECTIVE_ASSEMBLY_LIST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_ASSEMBLY_INSPECTION_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProgramAssemblyInspectionListRequest : To get program assembly inspection list
 *
 */
export const getProgramAssemblyInspectionListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROGRAM_ASSEMBLY_INSPECTION_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_ASSEMBLY_INSPECTION_VM_REQUEST **/
/****************************************************/

/**
 * @method getProgramAssemblyInspectionVMRequest : To get program assembly inspection VM
 *
 */
export const getProgramAssemblyInspectionVMRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROGRAM_ASSEMBLY_INSPECTION_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_ASSEMBLY_REINSPECTION_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProgramAssemblyReinspectionListRequest : To get program assembly reinspection list
 *
 */
export const getProgramAssemblyReinspectionListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROGRAM_ASSEMBLY_REINSPECTION_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_ASSEMBLY_REINSPECTION_VM_REQUEST **/
/****************************************************/

/**
 * @method getProgramAssemblyReinspectionVMRequest : To get program assembly reinspection VM
 *
 */
export const getProgramAssemblyReinspectionVMRequest = (callback = () => { }) => {
  return {
    type: GET_PROGRAM_ASSEMBLY_REINSPECTION_VM_REQUEST,
    callback
  }
}


/****************************************************/
/** UPDATE_PROGRAM_ASSEMBLY_REINSPECTION_LIST_REQUEST **/
/****************************************************/

/**
 * @method updateProgramAssemblyReinspectionListRequest : To update program assembly reinspection list configuration
 *
 */
export const updateProgramAssemblyReinspectionListRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_PROGRAM_ASSEMBLY_REINSPECTION_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_ESCALATION_VM_REQUEST **/
/****************************************************/

/**
 * @method getProgramEscalationVMRequest : To get program escalation VM
 *
 */
export const getProgramEscalationVMRequest = (payload, callback) => {
  return {
    type: GET_PROGRAM_ESCALATION_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** CREATE_PROGRAM_ESCALATION_REQUEST **/
/****************************************************/

/**
 * @method createProgramEscalationRequest : To create program escalation 
 *
 */
export const createProgramEscalationRequest = (payload, callback = () => { }) => {
  return {
    type: CREATE_PROGRAM_ESCALATION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PROGRAM_ESCALATION_REQUEST **/
/****************************************************/

/**
 * @method updateProgramEscalationRequest : To update program escalation 
 *
 */
export const updateProgramEscalationRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_PROGRAM_ESCALATION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_ESCALATION_REQUEST **/
/****************************************************/

/**
 * @method getProgramEscalationRequest : To get program escalation 
 *
 */
export const getProgramEscalationRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROGRAM_ESCALATION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_TASK_CLOSE_OUT_VM_REQUEST **/
/****************************************************/

/**
 * @method getTaskCloseOutVMRequest : To get task close out program VM
 *
 */
export const getProgramTaskCloseOutVMRequest = (callback = () => { }) => {
  return {
    type: GET_PROGRAM_TASK_CLOSE_OUT_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_TASK_CLOSE_OUT_REQUEST **/
/****************************************************/

/**
 * @method getProgramTaskCloseOutRequest : To get task close out program details
 *
 */
export const getProgramTaskCloseOutRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROGRAM_TASK_CLOSE_OUT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_PROGRAM_TASK_CLOSE_OUT_REQUEST **/
/****************************************************/

/**
 * @method addProgramTaskCloseOutRequest : To add task close out program details
 *
 */
export const addProgramTaskCloseOutRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_PROGRAM_TASK_CLOSE_OUT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PROGRAM_TASK_CLOSE_OUT_REQUEST **/
/****************************************************/

/**
 * @method updateProgramTaskCloseOutRequest : To update task close out program details
 *
 */
export const updateProgramTaskCloseOutRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_PROGRAM_TASK_CLOSE_OUT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PROGRAM_ASSEMBLY_INSPECTION_LIST_REQUEST **/
/****************************************************/

/**
 * @method updateProgramAssemblyInspectionListRequest : To update program assembly Inspection list configuration
 *
 */
export const updateProgramAssemblyInspectionListRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_PROGRAM_ASSEMBLY_INSPECTION_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_CONFIRM_SITE_VISIT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProgramConfirmSiteVisitListRequest : To get program confirm site visit list
 *
 */
export const getProgramConfirmSiteVisitListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROGRAM_CONFIRM_SITE_VISIT_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_CONFIRM_SITE_VISIT_VM_REQUEST **/
/****************************************************/

/**
 * @method getProgramConfirmSiteVisitVMRequest : To get program confirm site visit VM
 *
 */
export const getProgramConfirmSiteVisitVMRequest = (callback = () => { }) => {
  return {
    type: GET_PROGRAM_CONFIRM_SITE_VISIT_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** ADD_PROGRAM_CONFIRM_SITE_VISIT_REQUEST **/
/****************************************************/

/**
 * @method addProgramConfirmSiteVisitRequest : To add program confirm site visit
 *
 */
export const addProgramConfirmSiteVisitRequest = (payload, callback = () => { }) => {
  return {
    type: ADD_PROGRAM_CONFIRM_SITE_VISIT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PROGRAM_CONFIRM_SITE_VISIT_REQUEST **/
/****************************************************/

/**
 * @method updateProgramConfirmSiteVisitRequest : To update program confirm site visit
 *
 */
export const updateProgramConfirmSiteVisitRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_PROGRAM_CONFIRM_SITE_VISIT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_BASIC_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method getProgramBasicDetailRequest : To get program basic details
 *
 */
export const getProgramBasicDetailRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROGRAM_BASIC_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROGRAM_ASSEMBLY_CHECKLIST_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProgramAssemblyChecklistListRequest : To get program assembly checklist list
 *
 */
export const getProgramAssemblyChecklistListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_PROGRAM_ASSEMBLY_CHECKLIST_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_FINISH_PROGRAM_WIZARD_REQUEST **/
/****************************************************/

/**
 * @method updateFinishProgramWizardRequest :  To update finish program wizard
 *
 */
export const updateFinishProgramWizardRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_FINISH_PROGRAM_WIZARD_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_OBSERVATION_TEMPLATE_VM_REQUEST **/
/****************************************************/

/**
 * @method getObservationTemplateVMRequest :  To get observation template VM request
 *
 */
export const getObservationTemplateVMRequest = (callback = () => { }) => {
  return {
    type: GET_OBSERVATION_TEMPLATE_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** GET_OBSERVATION_TEMPLATE_DDL_REQUEST **/
/****************************************************/

/**
 * @method getObservationTemplateDDLRequest :  To get observation template DDL request
 *
 */
export const getObservationTemplateDDLRequest = (payload, callback = () => { }) => {
  return {
    type: GET_OBSERVATION_TEMPLATE_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SET_PROGRAM_OBSERVATION_DETAILS **/
/****************************************************/

/**
 * @method setProgramObservationDetails :  To set observation details request
 *
 */
export const setProgramObservationDetails = (payload) => {
  return {
    type: SET_PROGRAM_OBSERVATION_DETAILS,
    payload
  }
}

/**
 * @method setProgramObservationTemplateDetails :  To set observation details request
 *
 */
export const setProgramObservationTemplateDetails = (payload) => {
  return {
    type: SET_PROGRAM_OBSERVATION_TEMPLATE_DETAILS,
    payload
  }
}

/**
 * @method setProgramObservationSelectAll :  To set observation details request
 *
 */
export const setProgramObservationSelectAll = (payload) => {
  return {
    type: SET_PROGRAM_OBSERVATION_SELECT_ALL,
    payload
  }
}

/****************************************************/
/** GET_TASK_REVIEW_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProgramTaskReviewListRequest : To get program task review list
 *
 */
export const getProgramTaskReviewListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_TASK_REVIEW_LIST_REQUEST,
    payload,
    callback
  }
}