import React, { useState, useCallback, useEffect } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import * as actions from "@store/actions"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"
import localeMessageWrapper from '@views/components/locale-message'

const { ACTIVITIES } = LANGUAGE_CONSTANTS

const ManufacturerSearch = ({
  intl,
  errors,
  defaultOptions = [],
  value = "",
  id = "manufacturer",
  name = "manufacturer",
  isViewMode = false,
  isEditMode = false,
  isMulti = false,
  initialLabel,
  onChangeCheckpointCollection
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState(defaultOptions || [])
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)

  useEffect(() => {
    //Note: To set default options from API
    if (!options.length && !!defaultOptions.length) {
      setResetCache(true)
      setOptions(defaultOptions)
    }
  }, [options, defaultOptions])

  useEffect(() => {
    if (!isMulti && !!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])

  const handleLoadMoreManufacturer = useCallback(
    (search, page, prevOptions, others) => {
      return new Promise((resolve) => {
        if (!search && !prevOptions.length && options.length) {
          resolve({
            optionList: options,
            hasMore: true
          })
          return
        }
        const params = {
          entityId: 1,
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          manufacturerName: search
        }
        if (search) setResetCache((prevState) => !prevState)
        dispatch(
          actions.getTemplateManufacturerRequest(params, (response) => {
            if (response) {
              const { items, ...paginationData } = response
              resolve({
                optionList: items,
                hasMore: paginationData.hasNextPage
              })
              setOptions(() => (!!prevOptions.length ? [...prevOptions, ...items] : items))
            } else {
              resolve({
                optionList: [],
                hasMore: false
              })
            }
          })
        )
      })
    },
    [isResetCache, options, value]
  )

  /**
* @method handleSetDefaultOptions : To handle set default options in case of Edit mode
*
* Note: To handle case of duplicate option, when custom option is added, that is selected from edit mode
*/
  const handleSetDefaultOptions = useCallback(
    (selectedItem, selectedValue) => {
      const checkIfAlreadyPresent = defaultOptions.find(
        (option) => option.value === selectedValue
      )
      if (!!checkIfAlreadyPresent) {
        setOptions(defaultOptions)
      } else {
        setOptions([selectedItem, ...defaultOptions])
      }
    },
    [defaultOptions]
  )

  useEffect(() => {
    if (
      !isEditModeItemLoaded &&
      isEditMode &&
      !!value &&
      !!initialLabel
    ) {
      const selectedItem = {
        text: initialLabel,
        value: value.toString()
      }
      setEditModeItemLoaded(true)
      setSelectedOptionLabel(initialLabel)
      setResetCache((prevState) => !prevState)
      handleSetDefaultOptions(selectedItem, value)
    }
  }, [defaultOptions, initialLabel, isEditMode, isEditModeItemLoaded, value])

  const handleSelect = useCallback(
    (selected) => {
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        onChangeCheckpointCollection(id, lastElement?.text !== "— Select —" ? selected : [])
      } else {
        onChangeCheckpointCollection("manufacturer", selected?.value || "", selected?.text || "")
        setSelectedOptionLabel((selected?.value && selected?.text) || "")
      }
    },
    [onChangeCheckpointCollection])

  return (
    <Col className="mb-1" sm={12} md={6} xl={4}>
      <CustomLabel title={ACTIVITIES.MANUFACTURER} />
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id={id}
        name={name}
        placeholder={getLocaleMessage(intl, ACTIVITIES.SELECT_MANUFACTURER)}
        options={options}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={isMulti ? value : !!value ? {
          text: selectedOptionLabel,
          value
        } : null}
        isMulti={isMulti}
        isShowCustomAction={false}
        hideSelectedOptions={false}
        onSelect={handleSelect}
        onLoadOptions={handleLoadMoreManufacturer}
        getOptionLabel={(option) => option.text}
        getOptionValue={(option) => option.value}
      />
      {errors && (
        <ErrorMessage
          isShow={!!errors["manufacturer"]}
          message={errors["manufacturer"]}
        />
      )}
    </Col>
  )
}

export default localeMessageWrapper(ManufacturerSearch)
