import cx from "classnames"
import React, { useCallback, useRef } from "react"
import { FormGroup, Row, Col } from "reactstrap"
import Select, { components } from "react-select"
import { AsyncPaginate } from "react-select-async-paginate"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'

const MultiColumnOptionHeader = (props) => {
  /**
   * Note: To show header for multi column dropdown, 
   * we must pass keys list (keyList) to show header for all columns.
   * 
   * Format of keyList should be as follows :-
   * keyList: [{id: 'col_1', label: 'col_1_name'}, {id: 'col_2', label: 'col_2_name'},...]
   */

  if (!props.options.length || !props.selectProps || !props.selectProps.isOptionGridDropdown || !props.selectProps.keyList || !props.selectProps.keyList.length) {
    return null
  }

  return (
    <div className='option-grid-dropdown-header'>
      <Row>
        {props.selectProps.keyList.map(keyValue => <Col key={`option-head-col-${keyValue.id}`}>{keyValue.label}</Col>)}
      </Row>
    </div>
  )
}

const Menu = (props) => {
  return (
    <components.Menu {...props}
      className={cx(`${props.selectProps?.menuListClassName || ''}`, {
        'grid-list-loading': props.selectProps?.isOptionGridDropdown && props.isLoading,
        'has-no-options': !props.options.length && !props.isLoading,
        "has-options": !!props.options.length && !props.isLoading
      })}>
      <div className={cx({ 'option-grid-dropdown-list': props.selectProps?.isOptionGridDropdown, 'list-loading': props.isLoading })}>
        <div>
          {MultiColumnOptionHeader(props)}
          {props.children}
          {props.isLoading &&
            <div className="custom-react-select-loader-wrapper">
              <components.LoadingIndicator {...props} />
            </div>
          }
        </div>
        {!!props.selectProps.isShowCustomAction && (
          <div className="custom-react-select-action-wrapper">
            <button
              className={"custom-react-select-action"}
              onClick={props.selectProps.onClickCustomAction}
            >
              {props.selectProps.customActionLabel}
            </button>

          </div>
        )}
      </div>
    </components.Menu>
  )
}

const getGridOptionLabel = ({ data, keyValue }) => {
  if (!data[keyValue.id]) {
    return ""
  }

  if (Array.isArray(data[keyValue.id])) {
    if (keyValue.subId) {
      return ""
    }

    const count = data[keyValue.id].length

    if (count === 0) {
      return ""
    }

    if (count === 1) {
      return data[keyValue.id][0][keyValue.subId] || ''
    }

    return `${data[keyValue.id][0][keyValue.subId]} + ${count} more`
  }

  return data[keyValue.id]
}

const MultiColumnOptionLabel = (props) => {
  /**
   * Note: To show option label in multi column option label format, 
   * we must pass keys list (keyList) to show data in multiple columns
   */

  const { data, selectProps } = props
  const { keyList } = selectProps

  if (!keyList?.length) {
    return <components.Option {...props}>{props.children}</components.Option>
  }

  return (
    <components.Option {...props} className={cx('grid-list-option')}>
      <Row>
        {keyList.map(keyValue => (<Col key={`option-col-${keyValue.id}`}>{getGridOptionLabel({ data, keyValue })}</Col>))}
      </Row>
    </components.Option>
  )
}

const Option = (props) => {
  const hasNoValidOptions =
    props.options.length === 1 &&
    props.options[0][props.selectProps?.valueType || "value"] === ""

  if (hasNoValidOptions) {
    return (
      <div class="select__menu-notice select__menu-notice--no-options no-options-message">
        <LocaleMessage id={LANGUAGE_CONSTANTS.NO_OPTIONS} />
      </div>
    )
  }

  if (props.selectProps?.formattedOption) {
    return (
      <components.Option {...props}>
        {props.selectProps.formattedOption(props.data)}
      </components.Option>
    )
  }

  if (props.selectProps?.isOptionGridDropdown && props.children !== '— Select —') {
    return MultiColumnOptionLabel(props)
  }

  return <components.Option {...props}>{props.children}</components.Option>
}

const colourStyles = {
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#1b68b6 !important'
    }
  }
}

const CustomSelect = ({
  blurInputOnSelect = true,
  customActionLabel = "",
  defaultValue,
  menuPlacement = 'auto',
  isClearable = true,
  isFilter = false,
  /**
   * To be used when pagination is not required
   */
  isInitialSelectOptionRequired = false,
  isMulti = false,
  isPaginationRequired = true,
  isShowCustomAction = true,
  // To call load more without click on load of component
  isAutoLoadDefaultOptions = false,
  options = [],
  defaultOptions = [],
  /**
   * If isFormattedValue = true, means value is in following format :-
   *  {[lableType]: 'label here', [valueType]: 'value here }
   *
   * This property is for single select.
   */
  isFormattedValue = false,
  value,
  valueType = 'value',
  lableType = 'text',
  inputFieldClassName = "",
  onClickCustomAction = () => { },
  onLoadOptions,
  onSelect = () => { },
  customComponents = {},
  defaultCustomOptions = [],
  isShowSelectedAtTop,
  onRefreshOptions = () => {},
  ...props
}) => {
  const selectRef = useRef(null)

  const selectedValue = isMulti ? value.length ? value : defaultValue : isFormattedValue ? value || null : value ? options.find(op => op[valueType] === value) : defaultValue || null
  const updatedDefaultOptions = defaultOptions.length ? [{ [valueType]: "", [lableType]: '— Select —' }, ...defaultCustomOptions, ...defaultOptions] : []

  const loadPageOptions = useCallback(async (search, prevOptions, { page, ...others }) => {
    try {
      if (!!onLoadOptions) {
        const { optionList, hasMore } = await onLoadOptions(search, page, prevOptions, others)

        let updatedOptionList = optionList
        if (!prevOptions.length && !!optionList.length) {
          const defaultOption = {
            [valueType]: "", [lableType]: '— Select —'
          }
          updatedOptionList = [defaultOption, ...defaultCustomOptions, ...updatedOptionList]
        }

        return {
          options: updatedOptionList,
          hasMore,
          additional: {
            page: page + 1
          }
        }
      }
    } catch (err) {
      console.log('Error loading options', err)
    }
  }, [lableType, valueType, onLoadOptions])

  const SelectComponent = isPaginationRequired ? AsyncPaginate : Select
  const paginatedOptionLoadConfig = isAutoLoadDefaultOptions ? { defaultOptions: true } : {}

  const customProps = isPaginationRequired ? {
    selectRef,
    loadOptions: loadPageOptions,
    defaultOptions: updatedDefaultOptions,
    ...paginatedOptionLoadConfig,
    debounceTimeout: 1000,
    shouldLoadMore: (scrollHeight, clientHeight, scrollTop) => {
      const bottomBorder = (scrollHeight - clientHeight) / 2
      return bottomBorder < scrollTop
    }
  } : {
    ref: selectRef,
    options: (isFilter || isInitialSelectOptionRequired) ? updatedDefaultOptions : options,
    defaultOptions: updatedDefaultOptions
  }

  /**
   * @method handleCloseMenuOnScroll : To Close menu list if user scrolls away from menu list view
   * 
   */
  const handleCloseMenuOnScroll = useCallback((event) => {
    if (!!event?.target) {
      const { className } = event.target
      if (!!className && className.includes("select__menu-list")) {
        return false
      }
    }
    return true
  }, [])

  const handleBlurSelectList = useCallback(() => {
    if (isShowSelectedAtTop) {
      if (isMulti) {
        onRefreshOptions()
      }
    }
  }, [isMulti, isShowSelectedAtTop, onRefreshOptions])

  return (
    <FormGroup>
      <SelectComponent
        blurInputOnSelect={blurInputOnSelect && !isMulti}
        closeMenuOnSelect={!isMulti}
        classNamePrefix="select"
        menuPlacement={menuPlacement}
        closeMenuOnScroll={handleCloseMenuOnScroll}
        className={`select-input ${inputFieldClassName}`}
        components={{ Menu, Option, ...customComponents }}
        isClearable={isClearable}
        isMulti={isMulti}
        value={selectedValue || null}
        customActionLabel={customActionLabel}
        valueType={valueType}
        isShowCustomAction={isShowCustomAction}
        onChange={onSelect}
        onClickCustomAction={(e) => onClickCustomAction(e, selectRef)}
        styles={colourStyles}
        onBlur={handleBlurSelectList}
        {...customProps}
        {...props}
      />
    </FormGroup>
  )
}

export default CustomSelect
