import PropTypes from 'prop-types'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getFormattedDate } from '@utils'
import { LocaleMessage } from '@views/components'

const { PROJECT_UNITS, SITE_PROJECT_PLANS } = LANGUAGE_CONSTANTS

const ExpandableUnits = ({ data: { unitPlanDetail: data }, noDataMessage }) => {
  if (!data) {
    return <p className="no-data-txt">{noDataMessage}</p>
  }

  return (
    <div>
      <div className="site-units">
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={PROJECT_UNITS.PERMIT} />: </span>
          <span>{data.permitNumber || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={PROJECT_UNITS.PERMIT_DATE} />: </span>
          <span>{getFormattedDate(data.permitDate)}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={PROJECT_UNITS.PHASE} />: </span>
          <span>{data.phase || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={SITE_PROJECT_PLANS.PLAN_NAME} />: </span>
          <span>{data.planName || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={PROJECT_UNITS.READY_DATE} />: </span>
          <span>{getFormattedDate(data.readyDate)}</span>
        </div>
      </div>
    </div>
  )
}

ExpandableUnits.propTypes = {
  data: PropTypes.object,
  noDataMessage: PropTypes.string
}

export default ExpandableUnits
