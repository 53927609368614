import config from "./APIConfig"
import { putDataApi } from '../index'

/**
* @method updateCampaignChecklist
* @param {object} data
* 
* {
*  wfCampaignSetupChecklistId: "",
*  checklistName: "",
*  primaryRoleAssignment: "",
*  roleAssignments: false,
*  isReviewActivity: false
* }
* 
* API payload:-
* 
{
  "wfCampaignSetupChecklistId": 0,
  "checkListName": "string",
  "roleAssignments": [
    {
         "siteRoleId": 0
    }
  ],
  isReviewActivity: false
}
*/

export const updateCampaignChecklist = (data) => {
    const { url } = config.updateCampaignChecklist
    const payload = {
        wfCampaignSetupChecklistId: Number(data.wfCampaignSetupChecklistId),
        checkListName: data.checklistName,
        isReviewActivity: data.isReviewActivity
    }

    if (!data.isTrainingWFTemplate && data.isResponsibleRoleRequired) {
      payload.roleAssignments = [{ siteRoleId: data.primaryRoleAssignment }]
    }

    return putDataApi(url(data.wfCampaignSetupChecklistId), payload)
}

export default updateCampaignChecklist
