import config from "./APIConfig"
import { postDataApi } from '../../index'

/**
 * @method createBlockTrigger
 * @param {object} data 
 * 
 * {
 *  wfCampaignSetupId: "",
 *  assignedManualEventId: "",
 *  assignmentValue: "",
 *  eventId: "",
 *  sourceId: "",
 *  triggerEventId: "",
 *  planTriggers: "",
 *  workflow: "",
 *  triggerWorkflowEventId: "",
 *  lagTime: "",
 *  cancelledPrecedingWorkflow: "",
 *  hasPreCondition: false,
 *  preconditionSections: {
 *  sourceWFCampaignSetupId: "",
 *  triggerOnTaskStatusId: "",
 *  wfbPreconditionId: ""
 *  }
 *  }
 * 
 * API payload:-
 * 
 * {
 *  "wfCampaignSetupId": 0,
 *  "wfbTriggerSourceId": 0,
 *  "wfbTriggerEventId": 0,
 *  "wfRolePersonAssignmentId": 0,
 *  "siteRoleId": 0,
 *  "planTriggers": [],
 *  "wfDependency": {
    "wfDependencyId": 0,
    "hasPreCondition": true,
    "isAutoTrigger": false,
    "sourceWFCampaignSetupId": 0,
    "triggerOnTaskStatusId": 0,
    "wfbAutoTriggerId": 0,
    "lagDay": 0,
    "preConditions": [
      {
        "wfDependencyPreCondId": 0,
        "sourceWFCampaignSetupId": 0,
        "triggerOnTaskStatusId": 0,
        "wfbPreconditionId": 0,
        "isDeleted": false
      }
    ]
  }
 * }
 */

export const createBlockTrigger = (data) => {
  const { url } = config.createBlockTrigger

  const { createTriggerDetail, preconditionSections } = data

  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    wfbTriggerSourceId: Number(createTriggerDetail.sourceId),
    wfbTriggerEventId: Number(createTriggerDetail.triggerEventId),
    wfRolePersonAssignmentId: Number(createTriggerDetail.assignedManualEventId),
    sameSettingsForAllPlans: Number(createTriggerDetail.sameSettingsForAllPlans),
    contactId: Number(createTriggerDetail.assignmentValue),
    planTriggers: createTriggerDetail.planTriggers?.map(x => ({
      ...x,
      completeScheduleLine: x.completeScheduleLine === "1" ? true : x.completeScheduleLine === "0" ? false : null
    })) || [],
    wfDependency: {
      wfDependencyId: Number(createTriggerDetail.wfDependencyId),
      sourceWFCampaignSetupId: Number(createTriggerDetail.workflow),
      triggerOnTaskStatusId: Number(createTriggerDetail.triggerWorkflowEventId),
      lagDay: Number(createTriggerDetail.lagTime),
      wfbAutoTriggerId: Number(createTriggerDetail.cancelledPrecedingWorkflow),
      hasPreCondition: createTriggerDetail.hasPreCondition,
      isAutoTrigger: false,
      preConditions: preconditionSections?.map((preCondition) => {
        return {
          wfDependencyPreCondId: Number(preCondition.wfDependencyPreCondId),
          sourceWFCampaignSetupId: Number(preCondition.sourceWFCampaignSetupId),
          triggerOnTaskStatusId: Number(preCondition.triggerOnTaskStatusId),
          wfbPreconditionId: Number(preCondition.wfbPreconditionId),
          isDeleted: false
        }
      })
    }
  }
  return postDataApi(url, payload, null, { isApiNeedsToHandleErrors: false })
}

export default createBlockTrigger
