import config from "./APIConfig"
import { putDataApi } from "../../index"

/**
 * @method reorderSitePlanScheduleLine
 * 
 * @param data 
 * {
  "sitePlanId": 0,
  "previousOrderSitePlanMasterScheduleId": 0,
  "targetOrderSitePlanMasterScheduleIds": [
    0
  ],
  "nextOrderSitePlanMasterScheduleId": 0
}
 * @returns 
 */
const reorderSitePlanScheduleLine = async (data) => {
  const { url } = config.reorderSitePlanScheduleLine
  return await putDataApi(url(data.sitePlanId), data)
}

export default reorderSitePlanScheduleLine
