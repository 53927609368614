import { useCallback, useEffect, useState } from "react"
import { File, Clock, Briefcase } from "react-feather"
import { useDispatch, useSelector } from "react-redux"
import moment from 'moment'
import { DETAILS_SECTION_TAB_TYPE, REPORT_PAGE } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import *  as actions from '@store/actions'
import CampaignDashboard from '@src/assets/images/reports/campaign.png'
import ProjectDashboard from '@src/assets/images/reports/project.png'
import ProgramDashboard from '@src/assets/images/reports/program.png'
import { LocaleMessage, ReportView } from '@views/components'
import CampaignObservationList from "./campaign-observation-list"

const { REPORTS, GLOBAL_SEARCH } = LANGUAGE_CONSTANTS

const Details = ({ pageId = 0, parentId = 0, page, pageKey = '', children, type = '', isDashboardRequired = true, isReportDetails }) => {
  const dispatch = useDispatch()
  const [reportData, setReportData] = useState({})

  const { openedTab } = useSelector(state => state.reports)

  const DashboardImage = page === REPORT_PAGE.PROJECT ? ProjectDashboard : page === REPORT_PAGE.CAMPAIGN ? CampaignDashboard : page === REPORT_PAGE.PROGRAM ?
    ProgramDashboard : null

  const handleSetTab = useCallback((tab = DETAILS_SECTION_TAB_TYPE.DETAILS) => {
    dispatch(actions.setReportOpenedTab(tab))
  })

  const addIdToParameter = useCallback((parameter) => {
    const label = pageKey || page
    const upperCaseId = `{${label}Id}`
    const lowerCaseId = `${label.slice(0, 1).toLowerCase()}${label.slice(1)}`
    let final = parameter.slice()
    if (parameter.includes(upperCaseId)) {
      final = final.replace(upperCaseId, pageId)
    } else if (parameter.includes(`{${lowerCaseId}Id}`)) {
      final = final.replace(`{${lowerCaseId}Id}`, pageId)
    }
    if (page === REPORT_PAGE.ORGANIZATION && parameter.includes('{OrganizationParentId}')) {
      final = final.replace('{OrganizationParentId}', parentId)
    }
    if (parameter.includes('{StartDate}')) {
      const date = moment().startOf('year').format('DD-MM-YYYY')
      final = final.replace('{StartDate}', date)
    }
    if (parameter.includes('{EndDate}')) {
      const date = moment().endOf('year').format('DD-MM-YYYY')
      final = final.replace('{EndDate}', date)
    }
    return final
  }, [pageKey, page, parentId, pageId])

  const handleReportDetail = useCallback((report) => {
    if (!report) return
    const { reportURLID, parameter } = report
    setReportData({
      reportId: reportURLID,
      parameter: addIdToParameter(parameter)
    })
  }, [addIdToParameter])

  useEffect(() => {
    handleSetTab()
    return () => {
      handleSetTab()
    }
  }, [])

  if (!page) return null

  if (!isDashboardRequired && !!Object.keys(reportData).length) return <ReportView id={reportData.reportId} parameter={reportData.parameter} />

  return (
    <>
      {isDashboardRequired && <div className="power-tabs">
        <span
          className={openedTab === DETAILS_SECTION_TAB_TYPE.DETAILS ? "active" : ""}
          onClick={() => {
            handleSetTab()
          }}
        >
          <File size={15} /> <LocaleMessage id={REPORTS.DETAILS} />
        </span>
        {page === REPORT_PAGE.CAMPAIGN && <span
          className={openedTab === DETAILS_SECTION_TAB_TYPE.TASKS ? "active" : ""}
          onClick={() => {
            handleSetTab(DETAILS_SECTION_TAB_TYPE.TASKS)
          }}
        >
          {" "}
          <Briefcase size={15} /> <LocaleMessage id={GLOBAL_SEARCH.TASKS} />
        </span>}
        <span
          className={openedTab === DETAILS_SECTION_TAB_TYPE.REPORTS ? "active" : ""}
          onClick={() => {
            handleSetTab(DETAILS_SECTION_TAB_TYPE.REPORTS)
          }}
        >
          {" "}
          <Clock size={15} /> <LocaleMessage id={REPORTS.REPORTS} />
        </span>
      </div>}
      {openedTab === DETAILS_SECTION_TAB_TYPE.TASKS && <CampaignObservationList openedTabId={openedTab} campaignId={pageId} />}
      {openedTab !== DETAILS_SECTION_TAB_TYPE.TASKS && <div className={isReportDetails ? "campaign-report-view" : ""} style={{ display: "flex", flexWrap: 'wrap' }}>
        <div className="power-bi" style={{ flexDirection: "column" }}>
          <div>
            {children && openedTab === DETAILS_SECTION_TAB_TYPE.REPORTS &&
              <div>{children}</div>
            }
          </div>
          {/* Remove the Static Dashboard Image once reports are available */}
          {openedTab === DETAILS_SECTION_TAB_TYPE.DETAILS && <div><h4>{`Static ${page}-Dashboard`}</h4>
            <img src={DashboardImage} alt='' className='dashboard-report-image' /></div>}
          {/* Uncomment Below code when reports are available */}
          {/* {!!Object.keys(reportData).length && <ReportView id={reportData.reportId} parameter={reportData.parameter} />} */}
        </div>
        {!isReportDetails && children && openedTab === DETAILS_SECTION_TAB_TYPE.DETAILS && <div className="resource-center">
          <div className="resource-center">{children}</div>
        </div>}
      </div>}
    </>
  )
}

export default Details
