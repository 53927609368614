
import { put, takeLatest, call } from "redux-saga/effects"

import * as actions from "@store/actions"
import { getCampaignServiceDetail, getCampaignServiceBasicDetail, updateCampaignService } from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'

const { CAMPAIGN_SERVICE_SAGA } = LANGUAGE_CONSTANTS

/** getCampaignServiceDetail API **/
function* getCampaignServiceDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getCampaignServiceDetail, action.payload)
    if (response?.status === 200 && response.data) {
      action.callback(response.data)
    } else {
      action.callback()
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getCampaignServiceBasicDetail API **/
function* getCampaignServiceBasicDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getCampaignServiceBasicDetail, action.payload)
    if (response?.status === 200 && response.data) {
      action.callback(response.data)
    } else {
      action.callback()
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateCampaignService API **/
function* updateCampaignServiceRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateCampaignService, action.payload)
    if (response?.status === 200) {
      action.callback(true)
      Notification({ status: 200, message: CAMPAIGN_SERVICE_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: CAMPAIGN_SERVICE_SAGA.ERROR_IN_UPDATING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

export function* campaignServiceSagas() {
  yield takeLatest(actions.GET_CAMPAIGN_SERVICE_DETAIL, getCampaignServiceDetailRequest)
  yield takeLatest(actions.UPDATE_CAMPAIGN_SERVICE, updateCampaignServiceRequest)
  yield takeLatest(actions.GET_CAMPAIGN_SERVICE_BASIC_DETAIL, getCampaignServiceBasicDetailRequest)
}

export default campaignServiceSagas