// app settings
import AppSettings from '@app-settings'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import {
  SET_USER_AUTH,
  UPDATE_USER_AUTH,
  GET_USER_PROFILE,
  RESET_ACCESS_DENIED,
  RESET_REDUCER_REQUEST,
  SET_ACCESS_DENIED,
  ADD_USER_MANAGEMENT_ENTITY_TO_LIST,
  GET_USER_MANAGEMENT_USER_ACCESS,
  REMOVE_USER_MANAGEMENT_ENTITY_FROM_LIST,
  RESET_USER_MANAGEMENT_ENTITY_LIST,
  SET_USER_LANGUAGE,
  SET_USER_PROFILE_IMAGE,
  TOGGLE_USER_PROFILE_IMAGE_UPDATES
} from '@store/actions'

// **  Initial State
const initialState = {
  userData: {},
  userAccess: {
    userId: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    language: '',
    phone: '',
    isActive: false,
    entityList: []
  },
  isAccessDenied: true,
  error: '',
  userProfileImageUploadFlag: false
}

const config = useJwt.jwtConfig

const authReducer = (state = initialState, action) => {
  let tempList

  switch (action.type) {
    case SET_USER_AUTH:
      // ** Add to user, accessToken & refreshToken to localStorage
      localStorage.setItem('userData', JSON.stringify(action.data))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.data.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.data.refreshToken))

      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }

    case UPDATE_USER_AUTH:
      // ** Add to user, accessToken & refreshToken to localStorage
      localStorage.setItem('userData', JSON.stringify({ ...state.userData, ...action.data }))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.data.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.data.refreshToken))

      return {
        ...state,
        userData: { ...state.userData, ...action.data },
        [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }

    /** GET_USER_PROFILE **/
    case GET_USER_PROFILE.REQUEST:
      return { ...state, error: "" }

    case GET_USER_PROFILE.SUCCESS:
      {
        const {
          lastName,
          firstName,
          phoneNumber,
          preferredLanguage,
          timeZone,
          timeZoneDisplayName,
          userProfileImageUrl
        } = action.data

        const data = {
          ...state.userData,
          lastName,
          firstName,
          name: `${firstName} ${lastName}`.trim(),
          nickname: lastName,
          phoneNumber,
          preferredLanguage,
          timeZone,
          userProfileImageUrl,
          timeZoneDisplayName
        }
        localStorage.setItem('userData', JSON.stringify(data))
        return {
          ...state,
          userData: data
        }
      }

    case GET_USER_PROFILE.FAILURE:
      return { ...state, error: action.error }

    case RESET_REDUCER_REQUEST:
      return initialState

    /** SET_ACCESS_DENIED **/
    case SET_ACCESS_DENIED:
      return { ...state, isAccessDenied: true }

    /** RESET_ACCESS_DENIED **/
    case RESET_ACCESS_DENIED:
      return { ...state, isAccessDenied: false }

    /** ADD_USER_MANAGEMENT_ENTITY_TO_LIST **/
    case ADD_USER_MANAGEMENT_ENTITY_TO_LIST:
      tempList = state.userAccess.entityList.slice()
      if (action.payload.isUpdate) {
        delete action.payload.isUpdate
        const selectedListId = action.payload.listId
        const accessId = action.payload.userAccessId
        const index = tempList.findIndex(
          (i) => (!!i.listId && !!selectedListId && i.listId === selectedListId) ||
            (!selectedListId &&
              i.userAccessId &&
              accessId &&
              i.userAccessId === accessId)
        )
        if (index !== -1) {
          tempList[index] = action.payload
        }
      } else {
        tempList.push(action.payload)
      }
      return {
        ...state, userAccess: { ...state.userAccess, entityList: tempList }
      }

    /** GET_USER_MANAGEMENT_USER_ACCESS **/
    case GET_USER_MANAGEMENT_USER_ACCESS.REQUEST:
      return { ...state, error: "" }

    case GET_USER_MANAGEMENT_USER_ACCESS.SUCCESS:
      const { userId, firstName, lastName, jobTitle, phoneNumber, isActive, userAccesses } = action.data
      return {
        ...state,
        userAccess: {
          userId: userId || '',
          firstName: firstName || '',
          lastName: lastName || '',
          jobTitle: jobTitle || '',
          language: jobTitle || '',
          phone: phoneNumber || '',
          isActive: isActive || false,
          entityList: userAccesses
        }
      }

    case GET_USER_MANAGEMENT_USER_ACCESS.FAILURE:
      return { ...state, error: action.error }

    case REMOVE_USER_MANAGEMENT_ENTITY_FROM_LIST:
      tempList = state.userAccess.entityList.slice()
      const index = tempList.findIndex(access => access.listId === action.payload)
      if (index > -1) {
        tempList.splice(index, 1)
      }
      return {
        ...state, userAccess: { ...state.userAccess, entityList: tempList }
      }

    case RESET_USER_MANAGEMENT_ENTITY_LIST:
      return {
        ...state,
        userAccess: {
          userId: '',
          firstName: '',
          lastName: '',
          jobTitle: '',
          language: '',
          phone: '',
          isActive: false,
          entityList: []
        }
      }

    case SET_USER_LANGUAGE:
      const temp = Object.assign({}, state)
      if (action.payload) {
        temp.userData[AppSettings.REACT_APP_PREFERRED_LANGUAGE_URL] = action.payload
      }
      return temp

    /** SET_USER_PROFILE_IMAGE */
    case SET_USER_PROFILE_IMAGE:
      {
        const {
          imageUrl = null
        } = action.data

        const data = {
          ...state.userData,
          userProfileImageUrl: imageUrl
        }
        localStorage.setItem('userData', JSON.stringify(data))
        return {
          ...state,
          userData: data
        }
      }

    /** TOGGLE_USER_PROFILE_IMAGE_UPDATES */
    case TOGGLE_USER_PROFILE_IMAGE_UPDATES:
      return {
        ...state,
        userProfileImageUploadFlag: action.data
      }

    default:
      return state
  }
}

export default authReducer
