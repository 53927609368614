import { useCallback, useState, useContext } from "react"
import { useDispatch } from 'react-redux'

import * as actions from '@store/actions'
import { ToggleButton } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import { ChecklistActivitiesContext } from "./index"

const AllowCopyActivity = ({ data }) => {
  const dispatch = useDispatch()

  const context = useContext(ChecklistActivitiesContext)

  const [isAllowCopy, setAllowCopy] = useState(!!data.isCopyAllowed)

  const handleToggleAllowCopy = useCallback(() => {
    const payload = {
      wfCampaignSetupChecklistId: data.wfCampaignSetupChecklistId,
      checklistActivityList: [
        {
          wfCampaignSetupActivityId: data.wfCampaignSetupActivityId,
          isCopyAllowed: !isAllowCopy
        }
      ]
    }
    dispatch(actions.updateChecklistCopyAllowedActivityRequest(payload, (res) => {
      if (res) {
        setAllowCopy(!isAllowCopy)
        context.onChange(data.checkpointId, !isAllowCopy, 'isCopyAllowed')
      }
    }))
  }, [data, isAllowCopy, context])

  return (
    <ToggleButton
      className="custom-control-secondary mt-0"
      customInputLabelRequired={false}
      id={`checklist_copy_activity_${data.checkpointId}`}
      name={`checklist_copy_activity_${data.checkpointId}`}
      value={!!isAllowCopy}
      onChange={handleToggleAllowCopy}
    />
  )
}

export default localeMessageWrapper(AllowCopyActivity)
