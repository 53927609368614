import { ROW_MODEL_TYPE } from "../config"

const colFormattedValue = (e, col) => {
  let formattedValue = ''

  if (!e.data) {
    formattedValue = e.value !== 'X' ? e.value : ''
  } else {
    if (col.selector && typeof col.selector === 'string') {
      if (col.selector in e.data) {
        if (col.format) {
          formattedValue = col.format(e.data)
        } else if (col.cell) {
          formattedValue = col.cell(e.data)
        } else {
          formattedValue = e.value !== 'X' ? e.value : ''
        }
      } else if (col.selector === "actions" || col.unNamedCol) {
        formattedValue = col.cell(e.data)
      }
    } else {
      if (col.format) {
        formattedValue = col.format(e.data)
      } else if (col.cell) {
        formattedValue = col.cell(e.data)
      } else {
        formattedValue = e.value !== 'X' ? e.value : ''
      }
    }
  }

  if (formattedValue === undefined) return ''
  return formattedValue
}


export const getColConfig = ({ columns, expandableRows, gridRef }) => {
  const isMinWidthRequired = columns.length > 10
  return columns.map((col, index) => {
    if (!col.checkboxSelection) {
      return {
        name: col.name,
        cellClass: params => {
          return !!params.colDef.checkboxSelection ? ['col-cell-checkbox-input', `row-id-${params.node.id}`] : 'font-weight-bold'
        },
        pinned: col.pinned || null,
        lockPinned: col.lockPinned || false,
        field: typeof col.selector === "string" ? col.selector : undefined,
        colId: col.sortKey || (!!col.selector && typeof col.selector !== "function" && col.selector) || undefined,
        valueGetter: typeof col.selector === "function" ? col.selector : typeof col.selector === "string" ? ({ data }) => {
          return data ? data[col.selector] !== null ? data[col.selector] : "X" : "X"
        } : () => "X",
        resizable: col.resizable || true,
        sortable: col.sortable || false,
        editable: col.editable || false,
        cellEditor: col.cellEditor || undefined,
        showDisabledCheckboxes: col.showDisabledCheckboxes,
        checkboxSelection: col.checkboxSelection || false,
        headerCheckboxSelection: col.headerCheckboxSelection || false,
        rowDrag: col.rowDrag,
        hide: col.hide || false,
        rowDragText: col.rowDragText,
        chartDataType: col.chartDataType || undefined,
        width: col.minWidth ? Number(col.minWidth.split('px')[0]) : undefined,
        minWidth: isMinWidthRequired && Number(col.minWidth?.split('px')[0]),
        menuTabs: col.menuTabs || !col.menuTabNotRequired ? !!col.filter ? ['generalMenuTab', 'filterMenuTab'] : ['generalMenuTab'] : !!col.filter ? ['filterMenuTab'] : false,
        suppressMenu: col.suppressMenu || false,
        suppressRowClick: col.suppressRowClick || false,
        openNewTab: col.openNewTab || false,
        type: col.groupByColumnType || undefined,
        cellRenderer: expandableRows ? index === 0 ? 'agGroupCellRenderer' : (e) => colFormattedValue(e, col) : (e) => colFormattedValue(e, col),
        cellRendererParams: index === 0 ? {
          innerRenderer: (e) => colFormattedValue(e, col)
        } : undefined,
        headerValueGetter: typeof col.name === "string" ? () => col.name : undefined,
        tooltipField: !!col.tooltipValueGetter ? "" : (typeof col.selector === "function" ? "" : col.selector ?? ""),
        tooltipValueGetter: col.tooltipValueGetter,
        tooltipComponentParams: { color: "#222", textColor: '#fff' },
        headerComponentParams: { comp: col.name, gridRef, enableSorting: col.sortable || false, checkboxSelection: col.headerCheckboxSelection || col.customHeaderCheckboxSelection || false }
      }
    } else {
      return {
        name: col.name,
        cellClass: params => {
          return !!params.colDef.checkboxSelection ? ['col-cell-checkbox-input', `row-id-${params.node.id}`] : ''
        },
        headerClass: !!col.checkboxSelection ? "col-cell-header-checkbox" : "",
        showDisabledCheckboxes: col.showDisabledCheckboxes,
        field: typeof col.selector === "string" ? col.selector : undefined,
        colId: col.sortKey || undefined,
        valueGetter: typeof col.selector === "function" ? col.selector : typeof col.selector === "string" ? ({ data }) => { return data ? data[col.selector] !== null ? data[col.selector] : "X" : col.checkboxSelection ? "" : "X" } : () => "X",
        resizable: col.resizable || true,
        rowDrag: col.rowDrag,
        rowDragText: col.rowDragText,
        sortable: col.sortable || false,
        checkboxSelection: col.checkboxSelection || false,
        headerCheckboxSelection: gridRef?.current?.props?.rowModelType === ROW_MODEL_TYPE.SERVER_SIDE ? false : col.headerCheckboxSelection,
        filter: col.filter || false,
        chartDataType: col.chartDataType || undefined,
        menuTabs: col.menuTabs || !col.menuTabNotRequired ? ['generalMenuTab'] : false,
        suppressMenu: col.suppressMenu || false,
        suppressRowClick: col.suppressRowClick || false,
        openNewTab: col.openNewTab || false,
        width: col.minWidth ? Number(col.minWidth.split('px')[0]) : undefined,
        minWidth: isMinWidthRequired && Number(col.minWidth?.split('px')[0]),
        cellRenderer: expandableRows && index === 0 ? 'agGroupCellRenderer' : undefined,
        headerValueGetter: typeof col.name === "string" ? () => col.name : undefined,
        tooltipField: !!col.tooltipValueGetter ? "" : (typeof col.selector === "function" ? "" : col.selector ?? ""),
        tooltipValueGetter: col.tooltipValueGetter,
        tooltipComponentParams: { color: "#222", textColor: '#fff' },
        headerComponentParams: { comp: col.name, gridRef, enableSorting: col.sortable || false, checkboxSelection: col.headerCheckboxSelection || col.customHeaderCheckboxSelection || false }
      }
    }
  })
}

const getLastRowIndex = (request, results) => {
  if (!results) return undefined
  const currentLastRow = (request.startRow || 0) + results.length
  return currentLastRow < (request.endRow || 0) ? currentLastRow : undefined
}

export const createServerSideDatasource = (loadMoreApi) => {
  return {
    getRows: (params) => {
      const {
        pageNumber: currentPage,
        pageSize: currentPageSize,
        typingTimeout,
        genericSearch,
        isAdvanceFiltersSelected,
        advanceSearchDetail,
        isViewApplied,
        sortOrderColumn,
        sortOrderDirection,
        onSetViewApplied,
        onGridNewDataRendered,
        onHeaderCheckboxSelection = () => { },
        onChangeAllSelectCheckbox = () => { },
        onSetAutoUnselectRow = () => { }
      } = params.api.gridOptionsService?.gridOptions.context || {} //gridOptionsWrapper changed into gridOptionsService

      const apiPayload = {
        currentPage: !isViewApplied && isAdvanceFiltersSelected ? 0 : params.request.startRow === 0 ? 0 : currentPage,
        size: currentPageSize
      }

      if (!isViewApplied && isAdvanceFiltersSelected) {
        onSetViewApplied(true)
      }

      if (isAdvanceFiltersSelected) {
        if (typingTimeout) {
          apiPayload.isGSFilter = true
          apiPayload.search = genericSearch
        } else {
          apiPayload.advanceSearchDetail = advanceSearchDetail
          apiPayload.isAutoAdvanceSearch = true
        }
      }

      //Note: For Sorting grid rows
      if (!!sortOrderColumn) {
        apiPayload.isResetSortOrder = true //Whenever data loaded using this server data source isResetSortOrder === true,
        // to use updated orderColumn & orderDirections values
        apiPayload.orderColumn = sortOrderColumn
        apiPayload.orderDirections = sortOrderDirection
      }

      loadMoreApi(apiPayload).then(data => {
        const returnData = data.items
        const lastRow = data.totalCount <= params.request.endRow ? data.totalCount : null   //handling response count less than requested page size
        if (returnData.length > 0) {
          params.successCallback(
            returnData, lastRow
          )
        } else {
          params.success({
            rowData: [],
            rowCount: 0
          })
        }

        //Note: onGridNewDataRendered() used to handle use cases such as following:-
        //-- To add any logic on parent on initial grid data load/first page load after any filter/sorting
        //-- To show rows in selected state in UI if already selected in api response when next page loaded
        if (params.api.gridOptionsService) {
          const { gridOptions } = params.api.gridOptionsService
          onGridNewDataRendered({
            api: params.api,
            selectedRows: gridOptions.context.selectedRows,
            totalCount: data.totalCount,
            totalSelectedCount: data.totalSelectedCount,
            onHeaderCheckboxSelection,
            onChangeAllSelectCheckbox,
            onSetAutoUnselectRow
          })
        }
      }).catch(error => {
        params.failCallback()
      })
    }
  }
}

/**
 * @method handleRegisterServerSideDatasource
 * To Create and set datasource to fetch rows for the grid.
 *
 */
export const handleRegisterServerSideDatasource = ({
  gridRef,
  onLoadRowData = () => { }
}) => {
  const datasource = createServerSideDatasource(onLoadRowData)
  gridRef.current.api?.setServerSideDatasource(datasource)
}

/**
 * @method handleRemoveServerSideDatasource
 * To remove datasource for the grid.
 *
 */
export const handleRemoveServerSideDatasource = ({
  gridRef
}) => {
  gridRef.current.api.setServerSideDatasource(null)
}

/**
 * @method handleRefreshServerSideGrid
 * Instructs the grid to start reloading all loaded rows
 *
 */
export const handleRefreshServerSideGrid = (params) => {
  const {
    gridRef,
    purge = true,
    route
  } = params || {}
  gridRef.current.api.refreshServerSide({ route, purge })
}

export const getMainMenu = (params) => {
  const menuItems = []
  const itemsToExclude = ['autoSizeAll', 'autoSizeThis', 'resetColumns']
  params.defaultItems.forEach((item) => {
    if (itemsToExclude.indexOf(item) < 0) {
      menuItems.push(item)
    }
  })
  menuItems.push('separator')
  menuItems.push({
    name: 'Reset Columns',
    action: () => {
      params.columnApi.resetColumnState()
      params.api.sizeColumnsToFit()
    }
  })
  return menuItems
}

export const getContextMenuItems = (params) => {
  const menuItems = [
    'cut',
    'copy',
    'copyWithHeaders',
    'copyWithGroupHeaders',
    'paste',
    'separator',
    'chartRange',
    'export'
  ]
  return params.column?.colDef?.openNewTab ? [
    {
      name: 'Open in new Tab',
      action: () => {
        params.onOpenNewTabClicked(params.node.data, params.column.colId)
      }
    }, 'separator', ...menuItems
  ] : menuItems
}

export const onGridBodyScroll = () => {
  //Note: To close row menu if opened , on grid scroll
  const element = [...document.getElementsByClassName("grid-row-actions-dropdown")]
  if (element?.length) {
    const elementIndex = element.findIndex(x => x.className.includes('show'))
    if (elementIndex !== -1) {
      window.dispatchEvent(
        new CustomEvent('onGridBodyScroll', {
          isScrollStart: true
        })
      )
    }
  }
}

export const changeRowHeight = (data) => {
  const { prereqs, unitUSOWs, sitePlanUSOWs, isEditMode, sitePlanMasterScheduleId } = data || {}
  return (prereqs?.length || unitUSOWs?.length || sitePlanUSOWs?.length) && isEditMode && !(sitePlanMasterScheduleId && unitUSOWs)
}

export const getUpdatedTableComponentProps = (props) => {
  return {
    //////////////////////////////////////////////////
    ////////// SERVER SIDE ROW MODAL PROPS //////////
    //////////////////////////////////////////////////
    /**
     * @property {bool} allowRegisterDatasourceOnGridReady : To allow server side datasource registeration when grid is ready.
     */
    allowRegisterDatasourceOnGridReady: true,
    /**
     * @property {bool} isNewDatasourceRegisterationRequired : If datasource registeration is required based on some changes/action in parent component.
     */
    isNewDatasourceRegisterationRequired: false,
    /**
     * @method onResetNewDatasourceRegisterationRequired : Method to toggle state of isNewDatasourceRegisterationRequired.
     */
    onResetNewDatasourceRegisterationRequired: () => { },
    /**
     * @property {bool} isCancelDatasourceRegisteration : If datasource registeration is required to be removed based on some changes/action in parent component.
     */
    isCancelDatasourceRegisteration: false,
    /**
     * @method onResetCancelDatasourceRegisteration : Method to toggle state of isCancelDatasourceRegisteration.
     */
    onResetCancelDatasourceRegisteration: () => { },
    /**
     * @property {bool} isResetCacheRequired : To Instructs the grid to start reloading all loaded rows.
     */
    isResetCacheRequired: false,
    /**
     * @method onResetCacheRequired : Method to toggle state of isResetCacheRequired.
     */
    onResetCacheRequired: () => { },
    /**
     * @property {object} gridTransactionData : Contains transaction object for add/update/remove rows in grid
     * eg: { 
        add: [{ id: 1, name: 'row-1' }],
        update: [{ id: 1, name: 'row-1' }],
        remove: [{ id: 1 ]
      }
     */
    gridTransactionData: null,
    /**
     * @method onResetGridTransactionData : Method to reset state of gridTransactionData.
     */
    onResetGridTransactionData: () => { },
    /**
     * For updating specific row fields to handle on change event of input/dropdown/etc elements
     */
    updatedSpecificRowValues: null,
    /**
     * For performing auto scroll, without user's interaction, to auto load pending rows in grid.
     */
    isLoadingNextPage: false,

    //////////////////////////////////////////////////
    ////////// GENERAL PROPS //////////
    //////////////////////////////////////////////////
    /**
     * onGridNewDataRendered: To perform any logic in parent when new data is loaded in ag-grid table.
     */
    onGridNewDataRendered: () => { },
    /**
    * onChangeAllSelectCheckbox: To handle change in All Select checkbox in Header row.
    */
    onChangeAllSelectCheckbox: () => { },
    /**
    * isResetSelectedOnScroll: To unselect already selected on page scroll.
    */
    isResetSelectedOnScroll: true,
    /**
    * childComponentProps: To pass any props to child component from expandable component.
    */
    childComponentProps: {},
    /**
    * isShowDataToggleButton: To show data toggle button.
    */
    isShowDataToggleButton: false,
    /**
    * dataToggleButtonLocaleId: To set title for data toggle button.
    */
    dataToggleButtonLocaleId: '',
    /**
    * dataToggleButtonValue: To set data toggle button value.
    */
    dataToggleButtonValue: false,
    /**
   * onChangeDataToggle: To change value of data toggle button.
   */
    onChangeDataToggle: () => { },
    /**
     * isGroupingRequired: To enable row grouping feature for Ag-grid
     */
    isGroupingRequired: false,
     /**
     * onCellEditingStarted: To  inform when cell editing started.
     */
    onCellEditingStarted: () => { },
     /**
     * onCellEditingStopped: To get the  updated cell value after editing in ag-Grid.
     */
    onCellEditingStopped: () => { },
     /**
     * rowHeight: Fixed custom height for ag-Grid rows.
     */
     rowHeight: '',
    ...props
  }
}

/**
 * @method handleApplyTransaction
 * 
 * To add/update/remove rows in server side grid row modal or client side grid row modal
 * server side ex:-
    gridApi.applyServerSideTransaction({ 
        add: [{ id: 1, name: 'row-1' }],
        update: [{ id: 1, name: 'row-1' }],
        remove: [{ id: 1 }]
    })

  client side ex:-
    gridApi.applyTransaction({ 
      add: [{ id: 1, name: 'row-1' }],
      update: [{ id: 1, name: 'row-1' }],
      remove: [{ id: 1 }]
    })
 */
export const handleApplyTransaction = ({
  gridRef,
  gridTransactionData,
  isServerSide
}) => {
  if (isServerSide) {
    gridRef.current.api.applyServerSideTransaction(gridTransactionData)

    if (!!gridTransactionData.add?.length) {
      setTimeout(() => {
        const rowNode = gridRef.current.api?.getRowNode(`0`)
        gridRef.current.api.ensureNodeVisible(rowNode, 'bottom')
      }, 1000)
    }
  } else {
    gridRef.current.api.applyTransaction(gridTransactionData)
  }
}

/**
 * @method handleUpdateSpecificRowValues
 * For updating specific row fields to updated state based on change event in parent component
 * @property {object} updatedSpecificRowValues
 *  {
 *   [keyField]: value,
 *   ...updatedRowColumnStateValuesInKeyValuePairs
 *  }
 * 
 * eg:
 *   {
 *    id: 1,
 *    name: 'row-1-updated'
 *   }
 * 
 *   */
export const handleUpdateSpecificRowValues = ({
  gridRef,
  updatedSpecificRowValues,
  keyField
}) => {
  const rowId = updatedSpecificRowValues[keyField]
  const rowNode = gridRef.current.api?.getRowNode(`${rowId}`)
  if (!!rowNode) {
    if (!!updatedSpecificRowValues.getExistingRowValue) {
      updatedSpecificRowValues.getExistingRowValue(rowNode.data)
    }

    rowNode.updateData({
      ...rowNode.data,
      ...updatedSpecificRowValues.updatedValue
    })
    if (!updatedSpecificRowValues.closeEditModeForRowId) {
      gridRef.current.api.refreshCells({
        force: true,
        suppressFlash: true
      })
    }
  }

  if (!!updatedSpecificRowValues.closeEditModeForRowId) {
    //To close row edit mode
    const existingEditableRowNode = gridRef.current.api?.getRowNode(`${updatedSpecificRowValues.closeEditModeForRowId}`)
    if (existingEditableRowNode) {
      let updatedValue = existingEditableRowNode.data
      if (!!updatedSpecificRowValues.closeEditModeForRowOriginalState) {
        updatedValue = {
          ...updatedValue,
          ...updatedSpecificRowValues.closeEditModeForRowOriginalState
        }
      }

      updatedValue = {
        ...updatedValue,
        isEditMode: false
      }

      existingEditableRowNode.updateData(updatedValue)
      gridRef.current.api.refreshCells({
        force: true,
        suppressFlash: true
      })
    } else if (!!rowNode) {
      gridRef.current.api.refreshCells({
        force: true,
        suppressFlash: true
      })
    }
  }
}

export const getScreenBasedTableHeight = () => {
  const screenHight = window.innerHeight
  if (screenHight > 2500) {
    return 2200
  } else if (screenHight > 2000) {
    return 1800
  } else if (screenHight > 1800) {
    return 1350
  } else if (screenHight > 1500) {
    return 1200
  } else if (screenHight > 1300) {
    return 890
  } else if (screenHight > 1200) {
    return 750
  } else if (screenHight > 1100) {
    return 700
  } else if (screenHight > 768) {
    return 500
  } else {
    return 400
  }
}