import { getSearchQuery } from '../config'

const apiConfig = {
  getCountryList: {
    method: "GET",
    url: `api/Organization/Address/GetOrganizationAddressVM`
  },
  getStateList: {
    method: "GET",
    url: (countryCode = '') => `api/Organization/address/getstatelist/${countryCode}`
  },
  getCountyList: {
    method: "GET",
    url: ({
      stateAbbreviation = '',
      states = []
    }) => {
      const statesList = states.map((state, index) => getSearchQuery("States", state.value, !!(!stateAbbreviation && index === 0))).join("")
      const searchString = [
        getSearchQuery("stateAbbreviation", stateAbbreviation, true, true),
        statesList
      ].join('')
      return `api/Project/CountyList${searchString}`
    }
  },
  getRegionList: {
    method: "GET",
    url: ({ stateCode = '' }) => `api/Project/Sites/GetRegion?StateCode=${stateCode}`
  },
  getCityByStateList: {
    method: "GET",
    url: ({
      isCalendarPage = false,
      states = [],
      city = '',
      regions = [],
      subRegions = [],
      pageNumber,
      pageSize
    }) => {
      const statesList = states.map((state) => getSearchQuery(isCalendarPage ? "StateCodes" : "States", state.value)).join("")
      const cityList = getSearchQuery(isCalendarPage ? "CityName" : "City", city, false, true)
      const regionList = regions.map((region) => getSearchQuery("RegionIds", region.value)).join()
      const subRegionList = subRegions.map((subRegion) => getSearchQuery("SubRegionIds", subRegion.value)).join()

      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        cityList,
        statesList,
        regionList,
        subRegionList
      ].join('')

      if (isCalendarPage) {
        return `api/Scheduling/Calendar/DDL/Cities${searchString}`
      }
      return `api/Common/filter/CityByStates${searchString}`
    }
  },
  getZipCodeList: {
    method: "GET",
    url: ({
      states = [],
      zipCode = '',
      isCalendarPage = false,
      regions = [],
      subRegions = [],
      city = [],
      pageNumber,
      pageSize
    }) => {
      const statesList = states.map((state) => getSearchQuery("States", state.value)).join("")
      const regionsList = regions.map((region) => getSearchQuery("RegionIds", region.value)).join("")
      const cityList = city.map((c) => getSearchQuery("Cities", c.value, false, true)).join("")
      const subRegionList = subRegions.map((subRegion) => getSearchQuery("SubRegionIds", subRegion.value)).join()

      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ZipCode", zipCode, false, true),
        statesList,
        regionsList,
        cityList,
        subRegionList
      ].join('')

      if (isCalendarPage) {
        return `api/Scheduling/Calendar/DDL/ZipCodes${searchString}`
      }
      return `api/Common/filter/ZipCodeByStates${searchString}`
    }
  },
  getLocationUnitList: {
    method: 'GET',
    url: ({
      pageNumber,
      pageSize,
      unitApt = ''
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("UnitApt", unitApt, false, true)
      ].join('')
      return `api/Common/filter/UnitApartment${searchString}`
    }
  },
  getSubRegionList: {
    method: "GET",
    url: ({ regionId = '' }) => `api/Project/Sites/GetSubRegion?RegionId=${regionId}`
  }
}

export default apiConfig
