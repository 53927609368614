import { Fragment } from "react"
import { toast } from "react-toastify"
import { AlertTriangle, Check, X } from "react-feather"
import PropTypes from 'prop-types'
import Avatar from "@components/avatar"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'

import { ACTION_STATUS_COLOR, ACTION_STATUS_TYPE } from './config'

const ActionStatusToast = ({
  content = "",
  title = "",
  type = ACTION_STATUS_TYPE.SUCCESS
}) => {
  const IconName =
    type === ACTION_STATUS_TYPE.SUCCESS ? Check : type === ACTION_STATUS_TYPE.ERROR ? X : AlertTriangle
  return (
    <Fragment>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar size="sm" color={ACTION_STATUS_COLOR[type]} icon={<IconName size={12} />} />
          <h6 className="toast-title">{title}!</h6>
        </div>
      </div>
      <div className="toastify-body">
        <span role="img" aria-label="toast-text">
          {content}
        </span>
      </div>
    </Fragment>
  )
}

const Notification = ({ status, message = '', isLocalizedMessage = true, customHTMLMessage: CustomHTMLMessage }) => {
  if (status === 200) {
    toast.success(
      <ActionStatusToast content={CustomHTMLMessage ? <CustomHTMLMessage /> : isLocalizedMessage ? <LocaleMessage id={message} /> : message} title={<LocaleMessage id={LANGUAGE_CONSTANTS.SUCCESS} />} />,
      { toastId: message })
  } else if (status === 304) {
    toast.warn(
      <ActionStatusToast
        content={CustomHTMLMessage ? <CustomHTMLMessage /> : isLocalizedMessage ? <LocaleMessage id={message} /> : message}
        title={<LocaleMessage id={LANGUAGE_CONSTANTS.WARNING} />}
        type={ACTION_STATUS_TYPE.WARNING}
      />,
      { toastId: message }
    )
  } else {
    toast.error(
      <ActionStatusToast
        content={CustomHTMLMessage ? <CustomHTMLMessage /> : isLocalizedMessage ? <LocaleMessage id={message} /> : message}
        title={<LocaleMessage id={LANGUAGE_CONSTANTS.ERROR} />}
        type={ACTION_STATUS_TYPE.ERROR}
      />,
      { toastId: message }
    )
  }
}

ActionStatusToast.propTypes = {
  content: PropTypes.object,
  title: PropTypes.object,
  type: PropTypes.string
}

Notification.propTypes = {
  status: PropTypes.number,
  message: PropTypes.string, 
  isShow: PropTypes.bool, 
  delayTime: PropTypes.number
}

export default Notification
