import config from "./APIConfig"
import { putDataApi } from "../../index"

/**
 * @method reorderUnitScheduleLine
 * 
 * @param data 
 * {
  "siteUnitId": 0,
  "previousOrderScheduleReadyId": 0,
  "targetOrderScheduleReadyIds": [
    0
  ],
  "nextOrderScheduleReadyId": 0
}
 * @returns 
 */
const reorderUnitScheduleLine = async (data) => {
  const { url } = config.reorderUnitScheduleLine
  return await putDataApi(url(data.siteUnitId), data)
}

export default reorderUnitScheduleLine
