import config from "./APIConfig"
import { postDataApi } from "../index"

export const getExportReportDocument = async (data) => {
  const { url } = config.getExportReportDocument
  const payload = {
    IsManualExport: true,
    ...data
  }
  return await postDataApi(url, payload, null, { pathConfig: { isExportReports: true } })
}

export default getExportReportDocument