import config from "./APIConfig"
import { putDataApi } from '../index'

export const editOrganizationDetail = async (data) => {
  const { url } = config.editOrganizationDetail
  const payload = {
    organizationId: data.organizationId || 0,
    organizationName: data.organizationName || '',
    organizationTypeId: data.organizationTypeId || 0,
    organizationParentId: data.organizationParentId || 0,
    organizationCategoryId: data.organizationCategoryId || 0,
    externalId: data.externalId || ''
  }
  return await putDataApi(url(data.organizationId), payload)
}

export default editOrganizationDetail
