import config from "./APIConfig"
import { getDataApi } from "../index"

export const getCityByStateList = async (data) => {
  const { url } = config.getCityByStateList
  return await getDataApi(url(data))
}

export const getCountryList = async () => {
  const { url } = config.getCountryList
  return await getDataApi(url)
}

export const getCountyList = async (data) => {
  const { url } = config.getCountyList
  return await getDataApi(url(data))
}

export const getLocationUnitList = async (data) => {
  const { url } = config.getLocationUnitList
  return await getDataApi(url(data))
}

export const getRegionList = async (data) => {
  const { url } = config.getRegionList
  return await getDataApi(url(data))
}

export const getStateList = async (countryCode) => {
  const { url } = config.getStateList
  return await getDataApi(url(countryCode))
}

export const getSubRegionList = async (data) => {
  const { url } = config.getSubRegionList
  return await getDataApi(url(data))
}

export const getZipCodeList = async (data) => {
  const { url } = config.getZipCodeList
  return await getDataApi(url(data))
}
