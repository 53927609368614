import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'

const { ACTIVITIES } = LANGUAGE_CONSTANTS

const ExpandableTrainingActivityChildDetail = ({ selectedGrid }) => {
  return (
      <div className="site-units">
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.SUBJECT} />: </span>
          <span>{selectedGrid.subject || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.COURSE} />: </span>
          <span>{selectedGrid.course}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.CHAPTER} />: </span>
          <span>{selectedGrid.chapter || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.LESSON} />: </span>
          <span>{selectedGrid.lesson || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.AUTHOR} />: </span>
          <span>{selectedGrid.author || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.STAGE_OF_CONSTRUCTION} />: </span>
          <span>{selectedGrid.stageOfConstruction || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.SOURCE} />: </span>
          <span>{selectedGrid.source || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.STATUS} />: </span>
          <span>{selectedGrid.status || '-'}</span>
        </div>
      </div>
  )
}

export default ExpandableTrainingActivityChildDetail
