import { takeLatest, call } from "redux-saga/effects"

import * as services from "@services/api"
import * as actions from "@store/actions"

/** createBlockAddTraining API */
function* createBlockAddTrainingRequest(action) {
  try {
    const response = yield call(services.createBlockAddTraining, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockAddTraining API **/
function* getBlockAddTrainingRequest(action) {
  try {
    const response = yield call(services.getBlockAddTraining, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}


/** updateBlockAddTraining API **/
function* updateBlockAddTrainingRequest(action) {
  try {
    const response = yield call(services.updateBlockAddTraining, action.payload)
    action.callback(!!response?.data?.status)
  } catch (error) {
    action.callback()
  }
}

/** getBlockDueDateVM API **/
function* getBlockDueDateVMRequest(action) {
  try {
    const response = yield call(services.getBlockDueDateVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockDueDate API **/
function* getBlockDueDateRequest(action) {
  try {
    const response = yield call(services.getBlockDueDate, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** createBlockDueDate API */
function* createBlockDueDateRequest(action) {
  try {
    const response = yield call(services.createBlockDueDate, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBlockDueDate API */
function* updateBlockDueDateRequest(action) {
  try {
    const response = yield call(services.updateBlockDueDate, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}


/** getBlockTrainingEscalationVM API */
function* getBlockTrainingEscalationVMRequest(action) {
  try {
    const response = yield call(services.getTrainingEscalationVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockFailedTraining API */
function* getBlockFailedTrainingRequest(action) {
  try {
    const response = yield call(services.getBlockFailedTraining, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateBlockFailedTraining API */
function* updateBlockFailedTrainingRequest(action) {
  try {
    const response = yield call(services.updateBlockFailedTraining, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** createBlockFailedTraining API */
function* createBlockFailedTrainingRequest(action) {
  try {
    const response = yield call(services.createBlockFailedTraining, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBlockFailedTrainingVM API */
function* getBlockFailedTrainingVMRequest(action) {
  try {
    const response = yield call(services.getBlockFailedTrainingVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export default function* workflowTrainingTemplateSagas() {
  yield takeLatest(actions.CREATE_BLOCK_ADD_TRAINING_REQUEST, createBlockAddTrainingRequest)
  yield takeLatest(actions.GET_BLOCK_ADD_TRAINING_REQUEST, getBlockAddTrainingRequest)
  yield takeLatest(actions.UPDATE_BLOCK_ADD_TRAINING_REQUEST, updateBlockAddTrainingRequest)
  yield takeLatest(actions.GET_BLOCK_DUE_DATE_VM_REQUEST, getBlockDueDateVMRequest)
  yield takeLatest(actions.GET_BLOCK_DUE_DATE_REQUEST, getBlockDueDateRequest)
  yield takeLatest(actions.CREATE_BLOCK_DUE_DATE_REQUEST, createBlockDueDateRequest)
  yield takeLatest(actions.UPDATE_BLOCK_DUE_DATE_REQUEST, updateBlockDueDateRequest)
  yield takeLatest(actions.GET_BLOCK_FAILED_TRAINING_REQUEST, getBlockFailedTrainingRequest)
  yield takeLatest(actions.UPDATE_BLOCK_FAILED_TRAINING_REQUEST, updateBlockFailedTrainingRequest)
  yield takeLatest(actions.CREATE_BLOCK_FAILED_TRAINING_REQUEST, createBlockFailedTrainingRequest)
  yield takeLatest(actions.GET_BLOCK_FAILED_TRAINING_VM_REQUEST, getBlockFailedTrainingVMRequest)
  yield takeLatest(actions.GET_BLOCK_TRAINING_ESCALATION_VM_REQUEST, getBlockTrainingEscalationVMRequest)
}
