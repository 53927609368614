import { ROUTES } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { validateEmail } from '@utils'
import { LocaleMessage } from '@views/components/locale-message'

export const validateDetail = ({
  firstName,
  lastName,
  email,
  phoneNumber
}) => {
  const errors = {}
  if (!firstName.trim().length) {
    errors['firstName'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.FIRST_NAME} />
  }

  if (!lastName.trim().length) {
    errors['lastName'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.LAST_NAME} />
  }

  if (!email.trim().length) {
    errors['email'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.EMAIL} />
  } else if (!validateEmail(email)) {
    errors['email'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.INVALID_EMAIL} />
  }

  const phone = (phoneNumber || "").trim()
  if (phone.length) {
    if (phone === '+') {
      errors['phoneNumber'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.INVALID_PHONE} />
    } else if (phone[0] === '+') {
      const value = phone.slice(1)
      if (isNaN(value)) {
        errors['phoneNumber'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.INVALID_PHONE} />
      }
    } else if (phone.length !== 10) {
      errors['phoneNumber'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.INVALID_PHONE} />
    } else {
      if (isNaN(phone)) {
        errors['phoneNumber'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.INVALID_PHONE} />
      }
    }
  } else {
    errors['phoneNumber'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.PHONE} />
  }

  return { errors }
}

export const validateContactDetails = ({
  firstName,
  lastName,
  organizationID,
  email,
  phoneNumber,
  accountAccessId
}) => {
  const errors = {}
  if (!firstName.trim().length && !lastName.trim().length) {
    errors['firstName'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.NAME} />
  } else if (!firstName.trim().length) {
    errors['firstName'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.FIRST_NAME} />
  } else if (!lastName.trim().length) {
    errors['lastName'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.LAST_NAME} />
  }

  if (!email.trim().length) {
    errors['email'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.EMAIL} />
  } else if (!validateEmail(email)) {
    errors['email'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.INVALID_EMAIL} />
  }

  if (!organizationID) {
    errors['organizationID'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.ORGANIZATION} />
  } else if (isNaN(organizationID)) {
    errors['organizationID'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.INVALID_ORGANIZATION} />
  }

  const phone = (phoneNumber || "").trim()
  const phoneRegex = /^(1\s?)?(\(\d{3}\)|[1-9]\d{2})[\s\-]?\d{3}[\s\-]?\d{4}$/
  if (phone.length) {
    if (phone === '+') {
      errors['phoneNumber'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.INVALID_PHONE} />
    } else if (phone[0] === '+') {
      const value = phone.slice(1)
      if (!value.match(phoneRegex)) {
        errors['phoneNumber'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.INVALID_PHONE} />
      }
    } else {
      if (!phone.match(phoneRegex)) {
        errors['phoneNumber'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.INVALID_PHONE} />
      }
    }
  } else {
    errors['phoneNumber'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.PHONE} />
  }

  if (!accountAccessId) {
    errors['accountAccessId'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.ACCOUNT_ACCESS} />
  } else if (isNaN(accountAccessId)) {
    errors['accountAccessId'] = <LocaleMessage id={LANGUAGE_CONSTANTS.CONTACTS.ERRORS.INVALID_ACCOUNT_ACCESS} />
  }

  return { errors }
}

export const checkIfSearchFiltersApplied = ({
  firstName,
  lastName,
  email,
  phone,
  organizationId,
  organizationName,
  jobTitle,
  accountAccess,
  sitePartyRoleId
}) => {
  return (
    !!firstName ||
    !!lastName ||
    !!email ||
    !!phone ||
    !!organizationId ||
    !!organizationName ||
    !!jobTitle ||
    !!sitePartyRoleId ||
    !!accountAccess?.value
  )
}

export const checkIfContactSubSection = () => {
  const { pathname } = window.location
  return !pathname.includes(ROUTES.contacts)
}

export const checkIfContactGroupsView = () => {
  return window.location.pathname.includes("/contacts") && window.location.search.includes("contact-group")
}