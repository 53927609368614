import { put, takeLatest, call } from "redux-saga/effects"

import {
  createUserManagementRole,
  getRoleList,
  getForesiteRoleDetails,
  deleteForesiteRole,
  updateForesiteRole
} from "@services/api"
import * as actions from "@store/actions"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'

const { USER_ROLE_SAGA } = LANGUAGE_CONSTANTS

/** getRoleList API **/
function* getRoleListRequest(action) {
  try {
    const response = yield call(getRoleList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      Notification({ status: 400, message: USER_ROLE_SAGA.ERROR_IN_FETCHING_ROLE_LIST })
    }
  } catch (error) {
    action.callback()
    Notification({ status: 400, message: USER_ROLE_SAGA.ERROR_IN_FETCHING_ROLE_LIST })
  }
}

/** createUserManagementRole API **/
function* createUserManagementRoleRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(createUserManagementRole, action.payload)
    if (response?.status === 200) {
      action.callback(true)
      Notification({ status: 200, message: USER_ROLE_SAGA.ADDED_SUCCESS })
    } else {
      Notification({ status: 400, message: USER_ROLE_SAGA.ERROR_IN_ADDING })
      action.callback()
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: USER_ROLE_SAGA.ERROR_IN_ADDING })
  }
}

/** getForesiteRole API **/
function* getForesiteRoleRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getForesiteRoleDetails, action.payload)
    if (response?.status === 204) {
      yield put(actions.setIsLoading(false))
      action.callback()
    } else {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** updateForesiteRole API **/
function* updateForesiteRoleRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateForesiteRole, action.payload)
    if (response?.status === 204) {
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: USER_ROLE_SAGA.UPDATED_SUCCESS })
      action.callback(true)
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: USER_ROLE_SAGA.ERROR_IN_UPDATING })
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: USER_ROLE_SAGA.ERROR_IN_UPDATING })
  }
}


/** deleteForesiteRole API **/
function* deleteForesiteRoleRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteForesiteRole, action.payload)
    if (response?.status === 204) {
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: USER_ROLE_SAGA.DELETED_SUCCESS })
      action.callback()
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: USER_ROLE_SAGA.ERROR_IN_DELETING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: USER_ROLE_SAGA.ERROR_IN_DELETING })
  }
}
export function* userManagementRoleSagas() {
  yield takeLatest(actions.CREATE_USER_MANAGEMENT_ROLE, createUserManagementRoleRequest)
  yield takeLatest(actions.GET_ROLE_LIST_REQUEST, getRoleListRequest)
  yield takeLatest(actions.GET_FORESITE_ROLE_REQUEST, getForesiteRoleRequest)
  yield takeLatest(actions.UPDATE_FORESITE_ROLE_REQUEST, updateForesiteRoleRequest)
  yield takeLatest(actions.DELETE_FORESITE_ROLE_REQUEST, deleteForesiteRoleRequest)
}

export default userManagementRoleSagas
