import { call, takeEvery } from "redux-saga/effects"

import * as services from "@services/api"
import * as actions from "@store/actions"

/** getChecklistChildrenList API */
function* getChecklistChildrenListRequest(action) {
  try {
    const response = yield call(services.getChecklistChildrenList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

export default function* workflowBlockSagas() {
  yield takeEvery(actions.GET_CHECKLIST_CHILDREN_LIST_REQUEST, getChecklistChildrenListRequest)
}
