import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Col } from "reactstrap"

import { DEFAULT_VM_PAGE_SIZE } from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { CustomLabel, CustomReactSelect, ErrorMessage } from "@views/components"
import localeMessageWrapper from '@views/components/locale-message'
import { getReactSelectPositionProps } from "@views/helpers"

const TimeZoneSearch = ({
    intl,
    errors,
    defaultOptions = [],
    value = "",
    isRequired = false,
    initialLabel,
    isMulti = false,
    onChangeTimeZone = () => { }
}) => {

  const dispatch = useDispatch()

  const [options, setOptions] = useState(defaultOptions || [])
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  const reactSelectPostionProps = useMemo(() => {
    return getReactSelectPositionProps()
  }, [])

  useEffect(() => {
    setResetCache((prevState) => !prevState)
    setOptions([])
    if (!!initialLabel && !!value) {
      setSelectedOptionLabel(initialLabel)
    }
  }, [initialLabel])

  const handleLoadTimeZone = useCallback(
    (search, page, prevOptions) => {
      return new Promise((resolve) => {
        if (!search && !prevOptions.length && options.length) {
          resolve({
            optionList: options,
            hasMore: true
          })
          return
        }
        const payload = {
          timezoneDisplayName: search,
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE
        }
        dispatch(
          actions.getTimeZoneDDLRequest(payload, (response) => {
            if (response) {
              const { items, hasNextPage } = response
              resolve({
                optionList: items,
                hasMore: hasNextPage
              })
              setOptions(() => (!!prevOptions.length ? [...prevOptions, ...items] : items))
            } else {
              resolve({
                optionList: [],
                hasMore: false
              })
            }
          })
        )
      })
    },
    [isResetCache, options, value]
  )

  const handleSelect = useCallback(
    (selected) => {
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        onChangeTimeZone(
          "timeZone",
          lastElement?.text !== "— Select —" ? selected : []
        )
      } else {
        onChangeTimeZone(
          "timeZone",
          selected?.value || "",
          selected?.text || ""
        )
        setSelectedOptionLabel((selected?.value && selected?.text) || "")
      }
    },
    [onChangeTimeZone]
  )

  return (
    <Col className="mb-1" sm={12} md={6} xl={4}>
        <CustomLabel title={getLocaleMessage(intl, LANGUAGE_CONSTANTS.TIME_ZONE)} required={isRequired} requiredIconClassName="required-field" />
        <CustomReactSelect 
            additional={{
                page: 1
            }}
            cacheUniqs={[isResetCache]}
            id="timeZone"
            name="timeZone"
            placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.TIME_ZONE)}
            options={options}
            defaultOptions={defaultOptions}
            isFormattedValue={!isMulti}
            value={isMulti ? value : !!value ? {
              text: selectedOptionLabel,
              value
            } : null}
            isMulti={isMulti}
            isShowCustomAction={false}
            hideSelectedOptions={false}
            lableType={"text"}
            valueType={"value"}
            getOptionLabel={(option) => option.text}
            getOptionValue={(option) => option.value}
            onLoadOptions={handleLoadTimeZone}
            onSelect={handleSelect}
            {...reactSelectPostionProps}
        />
        {errors && <ErrorMessage
            isShow={!!errors["timeZone"]}
            message={errors["timeZone"]}
        />}
    </Col>
  )
}

export default localeMessageWrapper(TimeZoneSearch)