import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method createUserManagementUserAccess
 * @param {object} data
 *
 */
export const createUserManagementUserAccess = (data) => {
  const { url } = config.createUserManagementUserAccess
  const accessList = data.userAccesses.map(access => ({
    entityId: parseInt(access.entityId || 0),
    organizationId: parseInt(access.organizationId || 0),
    roleId: parseInt(access.roleId || 0)
  }))
  return postDataApi(url, { ...data, userAccesses: accessList })
}

export default createUserManagementUserAccess
