// ** Third Party Components
import { useCallback, useState, useRef, useEffect } from "react"
import { X, ChevronRight } from "react-feather"
import { useForm } from "react-hook-form"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Row,
  Col
} from "reactstrap"
import { useDispatch } from "react-redux"

// ** Styles
import "@styles/react/libs/flatpickr/flatpickr.scss"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { camera, whiteClose } from "@src/assets/images/new-theme"
import * as actions from "@store/actions"
import { getLocaleMessage, toDataURL } from "@utils"
import { CustomLabel, ErrorMessage, ReactSelect } from "@views/components"
import LocaleMessageWrapper from "@views/components/locale-message"

const {
  OVERVIEW_PAGE_SECTIONS: { TASKS_SECTION }, CANCEL
} = LANGUAGE_CONSTANTS

const AddNotesAndMediaModal = ({
  intl,
  isEditMode,
  isAddNotesMediaModal = true,
  isModalCentered = false,
  editDetails,
  modalConfig = {},
  data,
  auditTrailId,
  selectedTaskId,
  onToggleModal,
  onGoBackToList,
  onAuditNoteAdded = () => { }
}) => {
  const dispatch = useDispatch()
  const { control } = useForm()

  const [notReadyReason, setNotReadyReason] = useState(0)
  const [noteDetail, setNoteDetail] = useState("")
  const [selectedPhotos, setSelectedPhotos] = useState([])
  const [isUnSupportedFormatError, setUnSupportedFormatError] = useState(false)

  const hiddenFileInput = useRef(null)

  const handleChangeNoteDetail = useCallback((event) => {
    setNoteDetail(event.target.value)
  }, [])

  const handleAttachPhoto = useCallback(() => {
    hiddenFileInput.current.click()
  }, [])

  useEffect(() => {
    if (isEditMode) {
      setNoteDetail(editDetails.note.noteText)
      setSelectedPhotos(editDetails.files || [])
    }
  }, [isEditMode, editDetails])

  const handleSelectPhoto = useCallback(
    async (event) => {
      if (event.target.files?.length) {
        const currSelectedPhotos = Array.from(event.target.files)
        const prevPhotos = !!selectedPhotos ? [...selectedPhotos] : []
        const isUnsupportedFile = !(event.target.files[0].type.includes("jpeg") || event.target.files[0].type.includes("png")) || !selectedPhotos.every(item => item.fileType === "image/jpeg" || item.fileType === "image/png")
        for (const img of currSelectedPhotos) {
          const result = await toDataURL(URL.createObjectURL(img))
          prevPhotos.push({
            fileName: img.name,
            isDeleted: false,
            imageId: 0,
            imageFile: result,
            isExternal: false,
            fileType: img.type,
            imageContent: result.split(",").pop()
          })
        }
        if (prevPhotos.length) {
          setSelectedPhotos(prevPhotos)
        }
        setUnSupportedFormatError(isUnsupportedFile)
      }
    },
    [selectedPhotos]
  )

  const handleDeletePhoto = useCallback(
    (index) => {
      const prevPhotos = [...selectedPhotos]
      if (!!prevPhotos[index]?.imageId) {
        prevPhotos[index].isDeleted = true
      } else {
        prevPhotos.splice(index, 1)
      }
      const isUnsupportedFile = !prevPhotos.every(item => item.fileType === "image/jpeg" || item.fileType === "image/png")
      setUnSupportedFormatError(isUnsupportedFile)
      setSelectedPhotos(prevPhotos)
    },
    [selectedPhotos]
  )

  const handleToggleModal = useCallback(() => {
    onToggleModal()
  }, [onToggleModal])

  const handleSubmit = useCallback(() => {
    const updatedPhotos = selectedPhotos.map((item) => {
      delete item.imageFile
      delete item.type
      return ({ ...item })
    })
    //Todo: API Payload for notes and media
    if (isEditMode) {
      const payload = {
        observationTaskId: data?.observationTaskId || selectedTaskId,
        noteId: editDetails.note.noteId,
        auditTrailId,
        data:
        {
          observationTaskId: data?.observationTaskId || selectedTaskId,
          wfActionId: 0,
          noteId: editDetails.note.noteId,
          auditTrailId,
          noteTitle: "",
          noteText: noteDetail,
          responseImages: updatedPhotos
        }
      }
      dispatch(actions.updateAuditTrailRequest(payload, (response) => {
        if (response) {
          handleToggleModal()
          onAuditNoteAdded(true)
        }
      }))
    } else {
      if (isAddNotesMediaModal) {
        const payload = {
          observationTaskId: data?.observationTaskId || selectedTaskId,
          auditTrailId: auditTrailId || 0,
          data:
          {
            observationTaskId: data?.observationTaskId || selectedTaskId,
            wfActionId: 0,
            auditTrailId: auditTrailId || 0,
            noteTitle: "",
            noteText: noteDetail,
            responseImages: updatedPhotos
          }
        }
        if (auditTrailId) {
          dispatch(actions.createAuditTrailRequest(payload, (response) => {
            if (response) {
              handleToggleModal()
              onAuditNoteAdded(true)
            }
          }))
        } else {
          dispatch(actions.createNewAuditTrailRequest(payload, () => {
            handleToggleModal()
            onAuditNoteAdded(true)
          }))
        }
      } else {
        const payload = {
          note: noteDetail,
          noteReasonId: Number(notReadyReason),
          images: updatedPhotos
        }
        modalConfig.onSubmit(payload, () => {
          handleToggleModal()
          onGoBackToList()
        })
      }
    }
  }, [data, editDetails, selectedTaskId, auditTrailId, selectedPhotos, noteDetail, modalConfig, onAuditNoteAdded, handleToggleModal])

  // ** Custom close btn
  const CloseBtn = (
    <X className="cursor-pointer" size={15} onClick={handleToggleModal} />
  )

  return (
    <Modal
      isOpen
      className={`sidebar-sm ${isModalCentered ? "modal-dialog-centered all-modal modal-dialog" : ""
        }`}
      modalClassName={isModalCentered ? "" : "modal-slide-in note-media-modal "}
      contentClassName="p-0"
    >
      <ModalHeader
        toggle={handleToggleModal}
        close={CloseBtn}
        tag="div"
      >
        <h5 className='modal-title'>{data.checkListName || data.task || data.taskName || data.activityName}</h5>
        <ChevronRight className="right-arrow" size={20} />
        <h5 className="modal-title">{`${getLocaleMessage(
          intl,
          modalConfig.modalTitle || TASKS_SECTION.ADD_NOTES_MEDIA
        )}`}</h5>
      </ModalHeader>
      <ModalBody className="flex-grow-1">
        <div md={12}>
          <Row className="modal-media-note">
            <Col className="add-media-note" md={10}>
              {modalConfig.selectReasonDropdownRequired && <div className="select-reason-dropdown">
                <ReactSelect
                  isClearable
                  id="notReadyReason"
                  name="notReadyReason"
                  isRequired
                  placeHolder={getLocaleMessage(intl, TASKS_SECTION.SELECT_REASON)}
                  label={getLocaleMessage(intl, TASKS_SECTION.REASON_FOR_EXCEPTION)}
                  control={control}
                  value={notReadyReason}
                  options={modalConfig.dropDownOptions}
                  onSelect={(selected) => setNotReadyReason(selected.value)}
                />
              </div>}
              <div className="optional-note">
                <CustomLabel
                  title={modalConfig.optionalNoteTitle || TASKS_SECTION.OPTIONAL_NOTE}
                  required={modalConfig.isNoteTitleRequired}
                />
                <Input
                  type="textarea"
                  rows="5"
                  value={noteDetail}
                  onChange={handleChangeNoteDetail}
                />
              </div>
              <div className="upload-box d-flex justify-content mt-2 mb-1">
                <Input
                  type="file"
                  className="uploadInput"
                  accept="image/jpeg, image/png"
                  multiple
                  innerRef={hiddenFileInput}
                  onChange={handleSelectPhoto}
                />
                <Button
                  id="next"
                  className="activities-btn"
                  color="theme-color"
                  onClick={handleAttachPhoto}
                >
                  <img src={camera} />
                  {getLocaleMessage(
                    intl,
                    TASKS_SECTION.ADD_PHOTO
                  )}
                </Button>
              </div>
              {!!selectedPhotos?.length && (
                <div className="audit-trail-note">
                  {selectedPhotos.filter((item) => !item.isDeleted)
                  .map((img, index) => {
                    return (
                      <div
                        key={`upload-photo_${index}`}
                        className="add-note-photo"
                      >
                        <span
                          className="close-img"
                          onClick={() => handleDeletePhoto(index)}
                        >
                          <img src={whiteClose} alt="whiteClose" />
                        </span>
                        <img className="img-file" src={img.imageFile || img.fileURL} />
                      </div>
                    )
                  })}
                </div>
              )}
              <div className="ml-1">
                <ErrorMessage
                  isShow={isUnSupportedFormatError}
                  message={getLocaleMessage(intl, TASKS_SECTION.FILE_FORMAT_NOT_SUPPORTED_ERROR)}
                />
              </div>
              <div className="d-flex justify-content mt-1">
                <Button
                  className="cancel-button mr-1"
                  onClick={handleToggleModal}
                >
                  {getLocaleMessage(intl, CANCEL)}
                </Button>
                <Button
                  className="submit-button"
                  disabled={modalConfig.selectReasonDropdownRequired ? !(noteDetail && notReadyReason) : !(noteDetail || selectedPhotos.length) || isUnSupportedFormatError}
                  onClick={handleSubmit}
                >
                  {getLocaleMessage(intl, TASKS_SECTION.SUBMIT)}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default LocaleMessageWrapper(AddNotesAndMediaModal)
