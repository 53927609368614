import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addProjectSiteDetails
 * @param {object} data 
 *  {
 * {
  "projectId": 0,
  "sites": [
    {
      "siteName": "string",
      "buildingTypeId": 0,
      "tract": "string",
      "buildingID": "string",
      "contactId": 0,
      "organizationId": 0,
      "siteStatusId": 0,
      "constructionStartDate": "2022-01-18T05:30:33.748Z",
      "externalId": "string",
      "customerId": "string"
    }
  ]
 }
 *  }
 *
 */
export const addProjectSiteDetails = (data) => {
  const { url } = config.addProjectSiteDetails
  const payload = {
    projectId: Number(data.projectId),
    sites: [
      {
        siteName: data.siteName,
        siteBuildingTypeIds: data.buildingType.map((type) => type.value),
        tract: data.tract,
        buildingID: data.buildingId,
        contactId: Number(data.pointOfContact),
        organizationId: Number(data.organizationId),
        siteStatusId: Number(data.siteStatus),
        constructionStartDate: data.constructionStartDate || null,
        externalId: data.externalId,
        customerId: data.customerId,
        ahjOrganizationId: Number(data.ahjOrganizationId),
        isSynchronized: data.isSynchronized
      }
    ]
  }
  return postDataApi(url, payload)
}

export default addProjectSiteDetails
