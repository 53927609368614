import { useCallback, useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { useDispatch } from "react-redux"
import { useRouteMatch } from 'react-router-dom'

import * as actions from '@store/actions'
import { getCampaignIdFromUrl } from '../checklists/helpers'
import ServiceDetails from '../service-details'

const intialCampaignState = {
  projectIds: ""
}

const EditServiceModal = ({ isOpen, campaignId, onAddCampaignToggle, campaignType }) => {
  const dispatch = useDispatch()
  const match = useRouteMatch()

  const [campaignDetail, setCampaignDetail] = useState(intialCampaignState)

  const handleOnToggle = useCallback(() => {
    if (onAddCampaignToggle) {
      onAddCampaignToggle()
    } else {
      dispatch(actions.setCampaignServiceMode())
    }
  })

  const handleGetCampaignDetail = useCallback(() => {
    const campId = getCampaignIdFromUrl(match, campaignId)
    dispatch(actions.getCampaignDetailRequest({ campaignId : campId }, (result) => {
      if (result) {
        const projectDetail = result.projectIds?.length ? result.projectIds[0] : null
        setCampaignDetail({
          projectIds: projectDetail?.projectId.toString() || '',
          campaignTypeId: result.campaignTypeId || 0
        })
      }
    }))
  }, [match, campaignId])

  useEffect(() => {
    handleGetCampaignDetail()
  }, [campaignId])

  if (!isOpen) {
    return null
  }

  return <Modal
    isOpen={isOpen}
    className="modal-dialog-centered all-modal search-modal campaign-search-modal"
  >
    <ModalBody className="p-0">
      <ServiceDetails isModal campaignId={campaignId} campaignDetail={campaignDetail} onAddCampaignToggle={onAddCampaignToggle} onClose={handleOnToggle} campaignType={campaignType} />
    </ModalBody>
  </Modal>
}

export default EditServiceModal
