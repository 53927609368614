import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateProjectSiteDetails
 * @param {object} data 
 *  {
   "projectId": 0,
   "sites": [
        {
          "siteId": 0,
          "siteName": "string",
          "buildingTypeId": 0,
          "pointofContactId": 0,
          "tract": "string",
          "buildingID": "string",
          "contactId": 0,
          "organizationId": 0,
          "externalId": "string",
          "customerId": "string",
          "streetAddress": "string",
          "unit": "string",
          "city": "string",
          "countryCode": "string",
          "county": "string",
          "state": "string",
          "postCode": "string",
          "siteStatusId": "string",
          "constructionStartDate": "2022-01-18T05:30:33.748Z",
          "addressName": "",
          "phoneNumber": ""
        }
      ]
    }
 *
 */
export const updateProjectSiteDetails = (data) => {
  const { url } = config.updateProjectSiteDetails
  const payload = {
    projectId: Number(data.projectId),
    sites: [
      {
        siteId: Number(data.siteId),
        siteName: data.siteName,
        siteBuildingTypeIds: data.buildingType.map((type) => type.value),
        tract: data.tract,
        buildingID: data.buildingId,
        externalId: data.externalId,
        customerId: data.customerId,
        contactId: Number(data.pointOfContact),
        organizationId: Number(data.organizationId),
        ahjOrganizationId: Number(data.ahjOrganizationId),
        siteStatusId: Number(data.siteStatus),
        constructionStartDate: data.constructionStartDate || null,
        streetAddress: data.streetAddress,
        unit: data.unit,
        city: data.city,
        state: data.state,
        postCode: data.postCode,
        countryCode: data.countryCode,
        county: data.county,
        phoneNumber: data.phoneNumber,
        addressName: data.addressName,
        regionId: data.regionId,
        subRegionId: data.subRegionId,
        isSynchronized: data.isSynchronized
      }
    ]
  }
  return putDataApi(url({ siteId: data.siteId }), payload)
}

export default updateProjectSiteDetails
