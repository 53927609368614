import cx from "classnames"
import React, { useState, useCallback, useMemo, useEffect } from "react"
import {
  Col,
  Row,
  Button
} from "reactstrap"
import { useDispatch } from "react-redux"
import { ChevronRight, ChevronLeft } from 'react-feather'

import { ROUTES } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { CustomUILoader } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import { getTaskPageLink } from "./Helpers"

const {
  BACK,
  OF,
  SCHEDULING: {
    HOLD_REASON,
    HOLD_REASON_DESCRIPTION,
    SELECTED_TASK,
    TASK_ID,
    TASK_NAME,
    CAMPAIGN,
    WORKFLOW,
    PRECEDING_TASK,
    CURRENT_STATUS,
    STATUS_DATE,
    ASSIGNEE,
    CANCEL_NOT_READY,
    RECHECK,
    OVERRIDE_AND_MARK_AS_READY
  },
  PREVIOUS,
  NEXT
} = LANGUAGE_CONSTANTS

const initialTaskDetail = {
  observationTaskId: "",
  taskName: "",
  campaignName: "",
  workflowName: "",
  onHoldReason: "",
  precedingTasks: []
}

const PreConditionsRemoveHoldModal = ({
  intl,
  activeTask,
  isAdminTasks,
  onSetNextActiveTask = () => { },
  onToggleModal = () => { },
  onUpdateRowData = () => { },
  onSetTaskStatusAlertContent = () => { }
}) => {
  const dispatch = useDispatch()

  const [isFetching, setFetching] = useState(false)
  const [taskDetail, setTaskDetail] = useState(Object.assign({}, initialTaskDetail))
  const [activePrecedingTask, setActivePrecedingTask] = useState({})
  const [activePrecedingTaskIndex, setActivePrecedingTaskIndex] = useState(0)

  const precedingTaskCount = useMemo(() => taskDetail.precedingTasks?.length || 0, [taskDetail.precedingTasks])

  const isSchedulingPage = useMemo(() => window.location.pathname.includes(ROUTES.projectsSchedule), [window.location.pathname])

  const handleToggleModal = useCallback(() => {
    onToggleModal()
  }, [onToggleModal])

  const handleFetchOnHoldDependencyDetail = useCallback(() => {
    setFetching(true)
    dispatch(actions.getOnHoldDependencyDetailRequest({ observationTaskId: activeTask.observationTaskId }, (res) => {
      if (res) {
        setTaskDetail(res)
        if (res.precedingTasks?.length) {
          setActivePrecedingTask(res.precedingTasks[0])
        }
      }
      setFetching(false)
    }))
  }, [activeTask.observationTaskId])

  useEffect(() => {
    if (activeTask.observationTaskId) {
      handleFetchOnHoldDependencyDetail()
    }
  }, [activeTask.observationTaskId])

  const handleGoBack = useCallback(() => {
    handleToggleModal()
  }, [handleToggleModal])

  const handleUpdateTaskStatus = useCallback(
    (res) => {
      if (res) {
        const updatedRowData = {
          dueDate: res.taskDueDate,
          taskStatusId: res.taskStatusId
        }
        if (isAdminTasks) {
          updatedRowData['taskStatus'] = res.taskStatusName
          updatedRowData['assigneeInfo'] =  !!res.listOfAssigne?.length ? {
            assigneeName: res.listOfAssigne[0].assignee,
            assigneeId: res.listOfAssigne[0].assignmentId,
            assignmentId: res.listOfAssigne[0].assignmentId
          } || {} : {}
        } else {
          updatedRowData['status'] = res.taskStatusName
          updatedRowData['listOfAssigne'] = res.listOfAssigne || []
        }
        onSetNextActiveTask()
        if (res.message) {
          onSetTaskStatusAlertContent(res?.message)
        }
        onUpdateRowData({
          observationTaskId: activeTask.observationTaskId,
          updatedValue: updatedRowData 
        })
      }
    }, [activeTask.observationTaskId, isAdminTasks, onSetNextActiveTask, onSetTaskStatusAlertContent, onUpdateRowData])

  const handleCancelNotReady = useCallback(() => {
    if (activeTask.observationTaskId) {
      dispatch(actions.updateOnHoldCancelRequest({ observationTaskId: activeTask.observationTaskId }, (res) => {
        if (res) {
          handleUpdateTaskStatus(res)
        }
      }))
    }
  }, [activeTask.observationTaskId, handleUpdateTaskStatus])

  const handleRecheck = useCallback(() => {
    if (activeTask.observationTaskId) {
      dispatch(actions.updateOnHoldRecheckRequest({ observationTaskId: activeTask.observationTaskId }, (res) => {
        if (res) {
          handleUpdateTaskStatus(res)
        }
      }))
    }
  }, [activeTask.observationTaskId, handleUpdateTaskStatus])

  const handleOverrideAndMarkReady = useCallback(() => {
    if (activeTask.observationTaskId) {
      dispatch(actions.updateOnHoldOverrideRequest({ observationTaskId: activeTask.observationTaskId }, (res) => {
        if (res) {
          handleUpdateTaskStatus(res)
        }
      }))
    }
  }, [activeTask.observationTaskId, handleUpdateTaskStatus])

  const handleSetActivePrecedingTask = useCallback((isNext = false) => {
    if (isNext) {
      //For Next task
      if (activePrecedingTaskIndex === (precedingTaskCount - 1)) {
        return
      }
    } else {
      //For Previous task
      if (activePrecedingTaskIndex === 0) {
        return
      }
    }

    const taskIndex = isNext ? activePrecedingTaskIndex + 1 : activePrecedingTaskIndex - 1
    const nextTask = taskDetail.precedingTasks[taskIndex]

    if (nextTask) {
      setActivePrecedingTask(nextTask)
      setActivePrecedingTaskIndex(taskIndex)
    }
  }, [activePrecedingTaskIndex, precedingTaskCount, taskDetail.precedingTasks])

  const selectedTaskLink = useMemo(() => {
    return getTaskPageLink({
      isSchedulingPage,
      observationTaskId: taskDetail.observationTaskId
    }) 
  }, [isSchedulingPage, taskDetail])

  const precedingTaskLink = useMemo(() => {
    return getTaskPageLink({
      isSchedulingPage,
      observationTaskId: activePrecedingTask.observationTaskId
    })
  }, [activePrecedingTask, isSchedulingPage])

  const precedingTaskCountText = useMemo(() => {
    return precedingTaskCount ? `(${activePrecedingTaskIndex + 1} ${getLocaleMessage(intl, OF)} ${precedingTaskCount})` : ""
  }, [activePrecedingTaskIndex, precedingTaskCount])

  const onHoldReasonList = useMemo(() => {
    return taskDetail.onHoldReason?.split("\n\n").filter(reason => reason) || []
  }, [taskDetail.onHoldReason])

  return (
    <CustomUILoader isLoading={isFetching}>
      <p className={"modal-sub-title"}>
        {getLocaleMessage(intl, HOLD_REASON)}
      </p>
      <p className="hold-reason-title">{getLocaleMessage(intl, HOLD_REASON_DESCRIPTION)}</p>
      <ul className="hold-reason-list">{onHoldReasonList.map((reason) => <li>{reason}</li>)}</ul>
      <hr className="my-2" />
      <div className="task-detail">
        <p className={"modal-sub-title"}>
          {getLocaleMessage(intl, SELECTED_TASK)}
        </p>
        <p><span className="data-label">{getLocaleMessage(intl, TASK_ID)}:</span>{selectedTaskLink && <a href={selectedTaskLink} className="task-link">{activeTask.observationTaskId}</a>}</p>
        <p><span className="data-label">{getLocaleMessage(intl, TASK_NAME)}:</span><span>{taskDetail.taskName}</span></p>
        <p><span className="data-label">{getLocaleMessage(intl, CAMPAIGN)}:</span><span>{taskDetail.campaignName}</span></p>
        <p><span className="data-label">{getLocaleMessage(intl, WORKFLOW)}:</span><span>{taskDetail.workflowName}</span></p>
      </div>
      <hr className="my-2" />
      <div className="task-detail-scroll ">
        <span className="task-detail-pagination">{precedingTaskCountText}</span>
        <div className="task-detail">
          <p className={"modal-sub-title"}>
            {getLocaleMessage(intl, PRECEDING_TASK)}
          </p>
          <p><span className="data-label">{getLocaleMessage(intl, TASK_ID)}:</span>{precedingTaskLink && <a href={precedingTaskLink} className="task-link">{activePrecedingTask.observationTaskId || ""}</a>}</p>
          <p><span className="data-label">{getLocaleMessage(intl, TASK_NAME)}:</span><span>{activePrecedingTask.taskName || ""}</span> </p>
          <p><span className="data-label">{getLocaleMessage(intl, CURRENT_STATUS)}:</span><span>{activePrecedingTask.statusName || ""}</span> </p>
          <p><span className="data-label">{getLocaleMessage(intl, STATUS_DATE)}:</span><span>{activePrecedingTask.statusDate || ""}</span> </p>
          <p><span className="data-label">{getLocaleMessage(intl, ASSIGNEE)}: </span><span>{activePrecedingTask.assignee || ""}</span></p>
        </div>
        {(precedingTaskCount > 1) && <>
          <div className="next-previous-btn">
            <span className={cx("previous-btn", {
              active: activePrecedingTaskIndex !== 0
            })}
              onClick={() => handleSetActivePrecedingTask(false)}>
              <ChevronLeft size={18} />{getLocaleMessage(intl, PREVIOUS)}
            </span>
            <span className={cx("next-btn", {
              active: activePrecedingTaskIndex < (precedingTaskCount - 1)
            })}
              onClick={() => handleSetActivePrecedingTask(true)}>
              {getLocaleMessage(intl, NEXT)}<ChevronRight size={18} />
            </span>
          </div>
          <hr className="my-2" />
        </>}
      </div>
      <Row className="mt-2 footer-actions">
        <Col md={4}>
          <Button
            className={"orange-solid w-100"}
            onClick={handleCancelNotReady}
          >
            {getLocaleMessage(intl, CANCEL_NOT_READY)}
          </Button>
        </Col>
        <Col md={4}>
          <Button
            className={"primary-solid  w-100"}
            onClick={handleRecheck}
          >
            {getLocaleMessage(intl, RECHECK)}
          </Button>
        </Col>
        <Col md={4}>
          <Button
            className={"black-solid w-100"}
            onClick={handleOverrideAndMarkReady}
          >
            {getLocaleMessage(intl, OVERRIDE_AND_MARK_AS_READY)}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Button
            outline
            className={"delete-button secondary-outlined w-100 mt-2"}
            onClick={handleGoBack}
          >
            {getLocaleMessage(intl, BACK)}
          </Button>
        </Col>
      </Row>
    </CustomUILoader>
  )
}

export default localeMessageWrapper(PreConditionsRemoveHoldModal)
