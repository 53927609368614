import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'

const { ACTIVITIES, VERSION } = LANGUAGE_CONSTANTS

const ExpandableObservationActivityChildDetail = ({ selectedGrid }) => {
  return (
      <div className="site-units">
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.STAGE_OF_CONSTRUCTION} />: </span>
          <span>{selectedGrid.stageOfConstruction || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.SCOPE_OF_WORK} />: </span>
          <span>{selectedGrid.scopeOfWork || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.ASSIGNED_ROLE} />: </span>
          <span>{selectedGrid.responsibleSiteRole || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.RISK_LEVEL} />: </span>
          <span>{selectedGrid.riskLevel || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.REQUIRE_PHOTOS} />: </span>
          <span>{selectedGrid.requiredPhotos === false ? 'No' : selectedGrid.requiredPhotos === true ? 'Yes' : '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={VERSION} />: </span>
          <span>{selectedGrid.versionId || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.SYSTEM_CATEGORY} />: </span>
          <span>{selectedGrid.systemCategory || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.ASSEMBLY} />: </span>
          <span>{selectedGrid.assembly || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.ASSEMBLY_TYPE} />: </span>
          <span>{String(selectedGrid.assemblyTypes).replaceAll(',', ', ') || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.COMPONENT} />: </span>
          <span>{String(selectedGrid.components).replaceAll(',', ', ') || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.SUB_COMPONENT} />: </span>
          <span>{String(selectedGrid.subComponents).replaceAll(',', ', ') || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.MANUFACTURER} />: </span>
          <span>{selectedGrid.manufacturer || '-'}</span>
        </div>
        <div className="site-units-details">
          <span className="site-units-title"><LocaleMessage id={ACTIVITIES.BRAND} />: </span>
          <span>{selectedGrid.brand || '-'}</span>
        </div>
      </div>
  )
}

export default ExpandableObservationActivityChildDetail
