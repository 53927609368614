export const FAILED_INSPECTION_CORRECTION_TYPE = {
  YES: "1",
  NO: "0"
}

export const HOLD_PRODUCTION_ON_OPEN_ITEMS = {
  YES: "1"
}

export const FAILED_INSPECTION_CORRECTION_OPTIONS = [{ text: 'Yes', value: "1" }, { text: 'No', value: "0" }]

export const RESPONSIBLE_PARTY_CORRECTION_ASSIGNMENT_TYPES = {
  SITE_ROLE: "4",
  CONTACT: "5"
}

export const RESPONSIBLE_PARTY_VERIFICATION_ASSIGNMENT_TYPES = {
  SITE_ROLE: "1",
  CONTACT: "2"
}