import React, { useState, useEffect } from "react"
import { Col } from 'reactstrap'

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { ErrorMessage, ReactSelect } from '@views/components'
import localeMessageWrapper from '@views/components/locale-message'
import EventType from "./EventType"
import SaturationRate from "./SaturationRate"
import TimeIncrement from "./TimeIncrement"

const { SERVICES } = LANGUAGE_CONSTANTS

const getComponent = (activeSchedulerDriver) => {
    switch (activeSchedulerDriver) {
        case "1":
            return SaturationRate
        case "2":
            return TimeIncrement
        case "3":
            return EventType
        default:
            return null
    }
}

const SchedulerDriver = (props) => {
    const { errors, control, isViewMode, serviceDetail, campaignSchedulerDriver, onChangeServiceDetails, isRequired, onCustomAdvanceSearch } = props
    const [activeSchedulerDriver, setActiveSchedulerDriver] = useState('')

    useEffect(() => {
        setActiveSchedulerDriver(serviceDetail.schedulerDriverId)
    }, [serviceDetail.schedulerDriverId])

    const Component = getComponent(activeSchedulerDriver)

    return (
        <>
            <Col className='mb-1' sm={12} md={6} xl={4}>
                <ReactSelect
                    id='scheduler_driver'
                    name='scheduler_driver'
                    label={getLocaleMessage(props.intl, SERVICES.SCHEDULER_DRIVER)}
                    control={control}
                    isRequired={isRequired}
                    value={serviceDetail.schedulerDriverId}
                    options={campaignSchedulerDriver}
                    onSelect={(selected) => onChangeServiceDetails('schedulerDriverId', selected?.value || '', (data) => { onCustomAdvanceSearch(null, true, data) })}
                    getOptionLabel={(item) => item.text}
                    getOptionValue={(item) => item.value}
                    placeHolder={getLocaleMessage(props.intl, SERVICES.SCHEDULER_DRIVER)}
                    isDisabled={isViewMode}
                />
                <ErrorMessage
                    isShow={!!errors['schedulerDriverId']}
                    message={errors['schedulerDriverId']}
                />
            </Col>
            {activeSchedulerDriver && activeSchedulerDriver !== "4" ? <Component {...props} isViewMode={isViewMode} /> : ''}
        </>
    )
}

export default localeMessageWrapper(SchedulerDriver)
