import { getSearchQuery } from "../config"

const apiConfig = {
  getProgramAssemblyListVM: {
    method: "GET",
    url: "api/Program/AssemblyList/VM"
  },
  getProgramEscalationVM: {
    method: "GET",
    url: (programId) => `api/Program/block/vm/programescalation/${programId}`
  },
  getProgramEscalation: {
    method: "GET",
    url: (programId) => `api/Program/ProgramEscalation/${programId}`
  },
  createProgramEscalation: {
    method: 'POST',
    url: 'api/Program/ProgramEscalation'
  },
  updateProgramEscalation: {
    method: 'PUT',
    url: (programId) => `api/Program/ProgramEscalation/${programId}`
  },
  getProgramAssemblyList: {
    method: 'GET',
    url: (programId) => `api/Program/AssemblyList/${programId}`
  },
  addProgramAssemblyList: {
    method: 'POST',
    url: (programId) => `api/Program/AssemblyList/${programId}`
  },
  getProgramSelectiveAssemblyList: {
    method: 'GET',
    url: (programId) => `api/Program/SelectiveAssemblyList/${programId}`
  },
  getProgramAssemblyInspectionList: {
    method: 'GET',
    url: ({ programId }) => `api/Program/AssemblyInspectionList/${programId}`
  },
  getProgramAssemblyInspectionVM: {
    method: 'GET',
    url: (siteId) => {
      const searchString = getSearchQuery('SiteId', siteId, true)
      return `api/Program/AssemblyInspectionVM${searchString}`
    }
  },
  getProgramAssemblyReinspectionList: {
    method: 'GET',
    url: ({ programId }) => `api/Program/AssemblyReinspectionList/${programId}`
  },
  getProgramAssemblyReinspectionVM: {
    method: 'GET',
    url: 'api/Program/AssemblyReinspectionVM'
  },
  updateProgramAssemblyReinspectionList: {
    method: 'POST',
    url: ({ programId }) => `api/Program/AssemblyReinspectionList/${programId}`
  },
  getProgramTaskCloseOutVM: {
    method: 'GET',
    url: 'api/Program/block/vm/programtaskcloseout'
  },
  getProgramTaskCloseOut: {
    method: 'GET',
    url: (programId) => `api/Program/ProgramTaskCloseOut/${programId}`
  },
  addProgramTaskCloseOut: {
    method: 'POST',
    url: 'api/Program/ProgramTaskCloseOut'
  },
  updateProgramTaskCloseOut: {
    method: 'PUT',
    url: (programId) => `api/Program/ProgramTaskCloseOut/${programId}`
  },
  updateProgramAssemblyInspectionList: {
    method: 'POST',
    url: ({ programId }) => `api/Program/AssemblyInspectionList/${programId}`
  },
  getProgramConfirmSiteVisitList: {
    method: 'GET',
    url: ({ programId }) => `api/Program/ProgramConfirmSiteVisit/${programId}`
  },
  getProgramConfirmSiteVisitVM: {
    method: 'GET',
    url: 'api/Program/block/vm/programconfirmsitevisit'
  },
  addProgramConfirmSiteVisit: {
    method: 'POST',
    url: 'api/Program/ProgramConfirmSiteVisit'
  },
  updateProgramConfirmSiteVisit: {
    method: 'POST',
    url: ({ programId }) => `api/Program/ProgramConfirmSiteVisit/${programId}`
  },
  getProgramBasicDetail: {
    method: 'GET',
    url: (programId) => `api/Program/BasicDetail/${programId}`
  },
  getProgramAssemblyChecklistList: {
    method: 'GET',
    url: ({ programId }) => `api/Program/AssemblyCheckList/${programId}`
  },
  updateFinishProgramWizard: {
    method: 'PUT',
    url: (programId) => `api/Program/FinishProgramWizard/${programId}`
  },
  getObservationTemplateVM: {
    method: 'GET',
    url: "api/Program/AssemblyList/ObservationVM"
  },
  getObservationTemplateDDL: {
    method: 'GET',
    url: ({ templateName, requirePhoto = false, uniformScopeOfWorkId = 0, componentId, pageNumber, pageSize }) => {
      const searchString = [
        getSearchQuery('RequirePhoto', requirePhoto.toString(), true),
        getSearchQuery('UniformScopeOfWorkId', uniformScopeOfWorkId),
        getSearchQuery('TemplateName', templateName),
        getSearchQuery('ComponentId', componentId),
        getSearchQuery('PageNumber', pageNumber),
        getSearchQuery('PageSize', pageSize)
      ].join('')

      return `api/Program/AssemblyList/ObservationTemplateDDL${searchString}`
    }
  },
  getProgramTaskReviewList: {
    method: 'GET',
    url: ({
      programId
    }) => {
      return `api/Program/TaskReviewList/${programId}`
    }
  }
}

export default apiConfig
