import config from "./APIConfig"
import { putDataApi } from "../index"

export const updateNotification = async (data) => {
  const { url } = config.updateNotification

  return await putDataApi(url(data))
}

export default updateNotification
