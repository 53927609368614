import config from "./APIConfig"
import { postDataApi } from "../index"

/**
 * @method updateProgramAssemblyInspectionList
 * 
 * @param {object} data 
 * 
{
  "programId": 0,
  "assemblyInspections": [
    {
      "programQualityAssemblyId": 0,
      "saturationRate": 0,
      "inspectionOrgTypeId": 0,
      "organizationId": 0,
      "responsibleRoleforSchedulingIds": 0
    }
  ]
}
 * 
 */
export const updateProgramAssemblyInspectionList = (data) => {
  const { url } = config.updateProgramAssemblyInspectionList
  return postDataApi(url(data), data)
}

export default updateProgramAssemblyInspectionList
