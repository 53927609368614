
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { TableCellToolTip } from '@views/components'

const {
  SCHEDULING: {
    COST_CATEGORY,
    BILLING_TYPE,
    ADDITIONAL_PO,
    EPO
  }
} = LANGUAGE_CONSTANTS

import RowActions from './RowActions'

export const getColumnConfig = ({ intl, isManagePurchaseOrder }) => {
  return [
    {
      name: getLocaleMessage(intl, isManagePurchaseOrder ? EPO : ADDITIONAL_PO),
      selector: "additionalPOName",
      menuTabNotRequired: true,
      format: (row) => row.additionalPOName
    },
    {
      name: getLocaleMessage(intl, COST_CATEGORY),
      selector: "costCategory",
      menuTabNotRequired: true,
      tooltipValueGetter: (params) => {
        return params.data?.costCategory?.text
      },
      format: (row) => row.costCategory?.text
    },
    {
      name: getLocaleMessage(intl, BILLING_TYPE),
      selector: "billingType",
      menuTabNotRequired: true,
      tooltipValueGetter: (params) => {
        return params.data?.billingType?.text
      },
      format: (row) => row.billingType?.text
    },
    {
      selector: "actions",
      minWidth: "50px",
      maxWidth: "70px",
      cell: (row) => <RowActions data={row} />
    }
  ]
}
