export const GET_ACTIVITY_COLLECTION_REQUEST = 'GET_ACTIVITY_COLLECTION_REQUEST'
export const GET_ACTIVITY_COUNT_STATUS_REQUEST = 'GET_ACTIVITY_COUNT_STATUS_REQUEST'
export const SAVE_ACTIVITY_COLLECTION_REQUEST = 'SAVE_ACTIVITY_COLLECTION_REQUEST'
export const GET_TRAINING_LAUNCH_LINK_REQUEST = 'GET_TRAINING_LAUNCH_LINK_REQUEST'
export const UPDATE_COURSE_REGISTRATION_REQUEST = 'UPDATE_COURSE_REGISTRATION_REQUEST'
export const GET_OBSERVATION_TASK_LIST_VIEW_REQUEST = 'GET_OBSERVATION_TASK_LIST_VIEW_REQUEST'
export const GET_OBSERVATION_TASK_MY_VIEW_REQUEST = 'GET_OBSERVATION_TASK_MY_VIEW_REQUEST'
export const GET_SELF_ASSIGN_TASK_REQUEST = 'GET_SELF_ASSIGN_TASK_REQUEST'
export const GET_OBSERVATION_TASK_VIEW_VM_REQUEST = 'GET_OBSERVATION_TASK_VIEW_VM_REQUEST'
export const GET_TASK_HEADER_DETAIL_REQUEST = 'GET_TASK_HEADER_DETAIL_REQUEST'
export const SAVE_COMPLETE_TASK_REQUEST = 'SAVE_COMPLETE_TASK_REQUEST'
export const START_CHECKLIST_ACTIVITY_REQUEST = 'START_CHECKLIST_ACTIVITY_REQUEST'
export const GET_TASK_REASSIGNMENT_CONTACT_REQUEST = 'GET_TASK_REASSIGNMENT_CONTACT_REQUEST'
export const UPDATE_TASK_ASSIGNMENT_CONTACT_REQUEST = 'UPDATE_TASK_ASSIGNMENT_CONTACT_REQUEST'
export const SAVE_IMAGES_AND_NOTES_REQUEST = 'SAVE_IMAGES_AND_NOTES_REQUEST'
export const SET_INSPECTION_TYPE_ID = 'SET_INSPECTION_TYPE_ID'
export const ADD_NOTES_AND_MEDIA_REQUEST = 'ADD_NOTES_AND_MEDIA_REQUEST'
export const GET_IN_PROGRESS_WIDGET_REQUEST = 'GET_IN_PROGRESS_WIDGET_REQUEST'
export const GET_OVERDUE_TASK_WIDGET_REQUEST = 'GET_OVERDUE_TASK_WIDGET_REQUEST'
export const GET_PENDING_COMPLETION_TASK_WIDGET_REQUEST = 'GET_PENDING_COMPLETION_TASK_WIDGET_REQUEST'
export const GET_ACTIVITY_NOTE_AND_MEDIA_REQUEST = 'GET_ACTIVITY_NOTE_AND_MEDIA_REQUEST'
export const DELETE_NOTE_AND_MEDIA_REQUEST = 'DELETE_NOTE_AND_MEDIA_REQUEST'
export const ADD_TRADE_CONTACT_REQUEST = 'ADD_TRADE_CONTACT_REQUEST'
export const GET_ACTIVITY_REVIEW_VM_REQUEST = 'GET_ACTIVITY_REVIEW_VM_REQUEST'
export const UPDATE_TASK_REVIEW_ASSIGNMENT_REQUEST = 'UPDATE_TASK_REVIEW_ASSIGNMENT_REQUEST'
export const ASSIGN_SELF_SCHEDULE_REQUEST = 'ASSIGN_SELF_SCHEDULE_REQUEST'
export const GET_TASK_CAN_SELF_ASSIGN_REQUEST = 'GET_TASK_CAN_SELF_ASSIGN_REQUEST'
export const GET_TASK_COUNT_REQUEST = 'GET_TASK_COUNT_REQUEST'
export const GET_OBSERVATION_TASK_REQUEST = 'GET_OBSERVATION_TASK_REQUEST'
export const GET_ACTION_CONFIRMATION_VM_REQUEST = 'GET_ACTION_CONFIRMATION_VM_REQUEST'
export const UPLOAD_INSPECTION_FILE_REQUEST = 'UPLOAD_INSPECTION_FILE_REQUEST'
export const UPDATE_PRE_TASK_CHECKLIST_REQUEST = 'UPDATE_PRE_TASK_CHECKLIST_REQUEST'
export const GET_PRE_TASK_CHECKLIST_REQUEST = 'GET_PRE_TASK_CHECKLIST_REQUEST'
export const GET_TASK_LINKS_COUNT_REQUEST = 'GET_TASK_LINKS_COUNT_REQUEST'
export const GET_TASK_LINK_LIST_REQUEST = 'GET_TASK_LINK_LIST_REQUEST'
export const UPLOAD_RESPONSE_IMAGE_REQUEST = 'UPLOAD_RESPONSE_IMAGE_REQUEST'

/****************************************************/
/** GET_ACTIVITY_COLLECTION_REQUEST **/
/****************************************************/

/**
 * @method getActivityCollectionRequest :  To get task activity collection
 *
 */
export const getActivityCollectionRequest = (payload, callback) => {
  return {
    type: GET_ACTIVITY_COLLECTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTIVITY_COUNT_STATUS_REQUEST **/
/****************************************************/

/**
 * @method getActivityCountStatusRequest :  To get task activity count status
 *
 */
export const getActivityCountStatusRequest = (payload, callback) => {
  return {
    type: GET_ACTIVITY_COUNT_STATUS_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SAVE_ACTIVITY_COLLECTION_REQUEST **/
/****************************************************/

/**
 * @method saveActivityCollectionRequest :  To save task activity collection
 *
 */
export const saveActivityCollectionRequest = (payload, callback) => {
  return {
    type: SAVE_ACTIVITY_COLLECTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TRAINING_LAUNCH_LINK_REQUEST **/
/****************************************************/

/**
 * @method getTrainingLaunchLinkRequest :  To get launch link for training course
 *
 */
export const getTrainingLaunchLinkRequest = (payload, callback) => {
  return {
    type: GET_TRAINING_LAUNCH_LINK_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_COURSE_REGISTRATION_REQUEST **/
/****************************************************/

/**
 * @method updateCourseRegistrationRequest :  To update training course registration
 *
 */
export const updateCourseRegistrationRequest = (payload, callback) => {
  return {
    type: UPDATE_COURSE_REGISTRATION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_OBSERVATION_TASK_LIST_VIEW_REQUEST **/
/****************************************************/

/**
 * @method getObservationTaskListViewRequest :  To get data for task list view
 *
 */
export const getObservationTaskListViewRequest = (payload, callback) => {
  return {
    type: GET_OBSERVATION_TASK_LIST_VIEW_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_OBSERVATION_TASK_MY_VIEW_REQUEST **/
/****************************************************/

/**
 * @method getObservationTaskMyViewRequest :  To get data for task my view
 *
 */
export const getObservationTaskMyViewRequest = (payload, callback) => {
  return {
    type: GET_OBSERVATION_TASK_MY_VIEW_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SELF_ASSIGN_TASK_REQUEST **/
/****************************************************/

/**
 * @method getSelfAssignTaskRequest :  To get data for self assign task
 *
 */
export const getSelfAssignTaskRequest = (payload, callback) => {
  return {
    type: GET_SELF_ASSIGN_TASK_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_OBSERVATION_TASK_VIEW_VM_REQUEST **/
/****************************************************/

/**
 * @method getObservationTaskViewVMRequest : To get task list VM
 *
 */
export const getObservationTaskViewVMRequest = (payload, callback) => {
  return {
    type: GET_OBSERVATION_TASK_VIEW_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_HEADER_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method getTaskHeaderDetailRequest : To get task header deatils
 *
 */
export const getTaskHeaderDetailRequest = (payload, callback) => {
  return {
    type: GET_TASK_HEADER_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SAVE_COMPLETE_TASK_REQUEST **/
/****************************************************/

/**
 * @method saveCompleteTaskRequest :  To save complete task
 *
 */
export const saveCompleteTaskRequest = (payload, callback) => {
  return {
    type: SAVE_COMPLETE_TASK_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** START_CHECKLIST_ACTIVITY_REQUEST **/
/****************************************************/

/**
 * @method startChecklistActivityRequest :  To start the activity
 *
 */
export const startChecklistActivityRequest = (payload, callback) => {
  return {
    type: START_CHECKLIST_ACTIVITY_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_REASSIGNMENT_CONTACT_REQUEST **/
/****************************************************/

/**
 * @method getTaskReassignmentContactRequest :  To get training task reassignment contact list
 *
 */
export const getTaskReassignmentContactRequest = (payload, callback) => {
  return {
    type: GET_TASK_REASSIGNMENT_CONTACT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_TASK_ASSIGNMENT_CONTACT_REQUEST **/
/****************************************************/

/**
 * @method updateTaskAssignmentContactRequest :  To update training course registration
 *
 */
export const updateTaskAssignmentContactRequest = (payload, callback) => {
  return {
    type: UPDATE_TASK_ASSIGNMENT_CONTACT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SAVE_IMAGES_AND_NOTES_REQUEST **/
/****************************************************/

/**
 * @method saveImagesAndNotesRequest :  To save images and notes in task activity collection
 *
 */
export const saveImagesAndNotesRequest = (payload, callback) => {
  return {
    type: SAVE_IMAGES_AND_NOTES_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SET_INSPECTION_TYPE_ID **/
/****************************************************/

/**
 * @method setInspectionTypeId :  To set inspection type id value for task activity collection
 *
 */
export const setInspectionTypeId = (payload) => {
  return {
    type: SET_INSPECTION_TYPE_ID,
    payload
  }
}

/**
 * @method addNoteAndMediaRequest :  To save notes and media in task activity collection
 *
 */
export const addNoteAndMediaRequest = (payload, callback) => {
  return {
    type: ADD_NOTES_AND_MEDIA_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_IN_PROGRESS_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getInProgressWidgetRequest :  To get the task inprogress count widget
 *
 */
export const getInProgressWidgetRequest = (payload, callback) => {
  return {
    type: GET_IN_PROGRESS_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_OVERDUE_TASK_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getOverdueTaskWidgetRequest :  To get the task overdue count widget
 *
 */
export const getOverdueTaskWidgetRequest = (payload, callback) => {
  return {
    type: GET_OVERDUE_TASK_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PENDING_COMPLETION_TASK_WIDGET_REQUEST **/
/****************************************************/

/**
 * @method getPendingCompletionTaskWidgetRequest :  To get the task pending completion count widget
 *
 */
export const getPendingCompletionTaskWidgetRequest = (payload, callback) => {
  return {
    type: GET_PENDING_COMPLETION_TASK_WIDGET_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTIVITY_NOTE_AND_MEDIA_REQUEST **/
/****************************************************/

/**
 * @method getActivityNoteAndMediaRequest :  To get activity optional note and media
 *
 */
export const getActivityNoteAndMediaRequest = (payload, callback) => {
  return {
    type: GET_ACTIVITY_NOTE_AND_MEDIA_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** DELETE_NOTE_AND_MEDIA_REQUEST **/
/****************************************************/

/**
 * @method deleteActivityNoteAndMediaRequest :  To delete activity optional note and media
 *
 */
export const deleteActivityNoteAndMediaRequest = (payload, callback) => {
  return {
    type: DELETE_NOTE_AND_MEDIA_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_TRADE_CONTACT_REQUEST **/
/****************************************************/

/**
 * @method addTradeContactRequest :  To save new trade contact in trade correction
 *
 */
export const addTradeContactRequest = (payload, callback) => {
  return {
    type: ADD_TRADE_CONTACT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTIVITY_REVIEW_VM_REQUEST **/
/****************************************************/

/**
 * @method getActivityReviewVMRequest : To get viewing filter list VM in ActivityReview
 *
 */
export const getActivityReviewVMRequest = (payload, callback) => {
  return {
    type: GET_ACTIVITY_REVIEW_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_TASK_REVIEW_ASSIGNMENT_REQUEST **/
/****************************************************/

/**
 * @method updateTaskReviewAssignmentRequest :  To update task review assignment
 *
 */
export const updateTaskReviewAssignmentRequest = (payload, callback) => {
  return {
    type: UPDATE_TASK_REVIEW_ASSIGNMENT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ASSIGN_SELF_SCHEDULE_REQUEST **/
/****************************************************/

/**
 * @method assignSelfScheduleRequest :  To assign task self schedule
 *
 */
export const assignSelfScheduleRequest = (payload, callback) => {
  return {
    type: ASSIGN_SELF_SCHEDULE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_CAN_SELF_ASSIGN_REQUEST **/
/****************************************************/

/**
 * @method getTaskCanSelfAssignRequest :  To get task can self assignment
 *
 */
export const getTaskCanSelfAssignRequest = (payload, callback) => {
  return {
    type: GET_TASK_CAN_SELF_ASSIGN_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_COUNT_REQUEST **/
/****************************************************/

/**
 * @method getTaskCountRequest :  To get count for All task and self assign task
 *
 */
export const getTaskCountRequest = (payload, callback) => {
  return {
    type: GET_TASK_COUNT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_OBSERVATION_TASK_REQUEST **/
/****************************************************/

/**
 * @method getObservationTaskRequest :  To get observation task with name, id search and without any search
 *
 */
export const getObservationTaskRequest = (payload, callback) => {
  return {
    type: GET_OBSERVATION_TASK_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTION_CONFIRMATION_VM_REQUEST **/
/****************************************************/

/**
 * @method getActivityConfirmationVMRequest :  To get action confirmation vm
 *
 */
export const getActivityConfirmationVMRequest = (callback = () => { }) => {
  return {
    type: GET_ACTION_CONFIRMATION_VM_REQUEST,
    callback
  }
}

/****************************************************/
/** UPLOAD_INSPECTION_FILE_REQUEST **/
/****************************************************/

/**
 * @method uploadInspectionFileRequest :  To upload inspection file
 *
 */
export const uploadInspectionFileRequest = (payload, callback) => {
  return {
    type: UPLOAD_INSPECTION_FILE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTION_CONFIRMATION_VM_REQUEST **/
/****************************************************/

/**
 * @method getActionConfirmationVMRequest :  To get action confirmation vm
 *
 */
export const getActionConfirmationVMRequest = (payload, callback) => {
  return {
    type: GET_ACTION_CONFIRMATION_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_LINKS_COUNT_REQUEST **/
/****************************************************/

/**
 * @method getTaskLinksCountRequest :  To get count for related tasks
 *
 */
export const getTaskLinksCountRequest = (payload, callback) => {
  return {
    type: GET_TASK_LINKS_COUNT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PRE_TASK_CHECKLIST_REQUEST **/
/****************************************************/

/**
 * @method updatePreTaskCheckListRequest :  To update pre task check list
 *
 */
export const updatePreTaskCheckListRequest = (payload, callback) => {
  return {
    type: UPDATE_PRE_TASK_CHECKLIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TASK_LINK_LIST_REQUEST **/
/****************************************************/

/**
 * @method getTaskLinkListRequest :  To get the list of related tasks
 *
 */
export const getTaskLinkListRequest = (payload, callback) => {
  return {
    type: GET_TASK_LINK_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PRE_TASK_CHECKLIST_REQUEST **/
/****************************************************/

/**
 * @method getPreTaskCheckListRequest :  To get pre task check list
 *
 */
export const getPreTaskCheckListRequest = (payload, callback) => {
  return {
    type: GET_PRE_TASK_CHECKLIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPLOAD_RESPONSE_IMAGE_REQUEST **/
/****************************************************/

/**
 * @method uploadResponseImageRequest :  To upload response image
 *
 */
export const uploadResponseImageRequest = (payload, callback) => {
  return {
    type: UPLOAD_RESPONSE_IMAGE_REQUEST,
    payload,
    callback
  }
}
