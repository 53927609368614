import React from "react"
import { Row, Col, FormGroup, Input } from "reactstrap"

import { ErrorMessage, CustomLabel } from "@views/components"
import { getLanguageText } from '../Config'
import ResponseDescription from "../ResponseDescription"
import ResponseFooter from "../ResponseFooter"

const StandardChoiceResponseComponent = ({
  errors,
  isPrimary,
  activeLocaleTab,
  isEditResponseMode = false,
  isSpanishVersion = false,
  responseDetail = {},
  onCancelUpdate,
  onChangeDetail,
  onUpsertResponse
}) => {
  return (
    <>
      {!isPrimary && <Row form>
        <Col className="pl-0" sm={12} md={6} xl={6}>
          <FormGroup>
            <CustomLabel
              title={getLanguageText(activeLocaleTab, "RESPONSE_NAME")}
              isLocalizedTitle={false}
            />
            <Input
              type="text"
              name="responseName"
              placeholder={getLanguageText(activeLocaleTab, "RESPONSE_NAME")}
              value={responseDetail?.responseName}
              onChange={(event) => onChangeDetail(event.target.name, event.target.value)}
            />
          </FormGroup>
          <ErrorMessage
            isShow={!!errors["responseName"]}
            message={errors["responseName"]}
          />
        </Col>
        <ResponseDescription id="responseName" value={responseDetail?.description} onChangeDetail={onChangeDetail} />
      </Row>}
      {responseDetail?.checkpointResponseQuestions?.map((option, index) => {
          return (
            <Row key={`choice_response_${index}`}>
              <Col>
                <div className="d-flex align-items-center mb-1">
                  <Input
                    type={"radio"}
                    id={`choice_option_${index}_id`}
                    className="radio-btn"
                    label="Full Width"
                    disabled
                  />
                  <Col className={"ml-2"} sm={12} md={5} xl={3}>
                    <Input
                      type="text"
                      id={`choice_option_input_${index}_id`}
                      placeholder={`Choice ${index + 1}`}
                      disabled
                      value={option.options}
                    />
                  </Col>
                </div>
              </Col>
            </Row>
          )
        })}
      <ResponseFooter
        activeLocaleTab={activeLocaleTab}
        isEditResponseMode={isEditResponseMode}
        isSpanishVersion={isSpanishVersion}
        responseDetail={responseDetail}
        onCancel={onCancelUpdate}
        onChangeDetail={onChangeDetail}
        onUpsertResponse={onUpsertResponse}
      />
    </>
  )
}

export default StandardChoiceResponseComponent
