import config from "./APIConfig"
import { postDataApi } from "../index"

/**
 * @method addProgramConfirmSiteVisit
 * 
 * @param {object} data 
 * 
 * {
 *  programId: 0,
 *  confirmSiteVisits: [
 *   {
 *     "programQualityAssemblyId": 0,
 *     "confirmSiteVisit": true,
 *     "wfRolePersonAssignmentId": 0,
 *     "siteRoleId": 0
 *     "wfNotificationMethodIds": [0]
 *   }
 *  ]
 * }
 * 
 */
export const addProgramConfirmSiteVisit = (data) => {
  const { url } = config.addProgramConfirmSiteVisit
  return postDataApi(url, data)
}

export default addProgramConfirmSiteVisit
