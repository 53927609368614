import React from "react"
import { Col, Row } from "reactstrap"

const OverviewDetailCard = () => {
  return (
    <div className="skeleton-loader">
      <div className="d-flex justify-content-between mt-1">
        <h2 className="skeleton-title skeleton-style" />
        <Col className="text-right" md={3}>
          <div className="skeleton-style skeleton-edit-btn" />
        </Col>
      </div>
      <Row xs={1} sm={1}>
        {Array.from(Array(2)).map((x, index) => {
          return (
            <Col key={`skeleton_config_${x}_${index}`} md={5}>
              {Array.from(Array(4)).map((y, i) => (
                <div
                  key={`skeleton_sub_config_${y}_${i}`}
                  className="mt-1 d-flex"
                >
                  <div className="skeleton-paragraph skeleton-style mr-1" />
                  <div className="skeleton-data skeleton-style" />
                </div>
              ))}
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default OverviewDetailCard
