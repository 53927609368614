import { ACTIVITY_TYPE } from '@shared/constants'

export const checkIfSearchFiltersApplied = ({
  stageOfConstruction,
  scopeOfWork,
  responsibleSiteRole,
  riskLevel,
  checkpointName,
  systemCategory,
  assembly,
  component,
  subComponent,
  manufacturer,
  brand,
  version,
  activityType,
  assemblyType,
  holdProduction
}) => {
  return (
    !!stageOfConstruction ||
    !!scopeOfWork ||
    !!responsibleSiteRole?.value ||
    !!riskLevel?.value ||
    !!checkpointName ||
    !!systemCategory ||
    !!assembly ||
    !!component.length ||
    !!subComponent.length ||
    !!manufacturer ||
    !!brand?.value ||
    !!version ||
    !!activityType?.value ||
    !!assemblyType.length || 
    !!holdProduction
  )
}

export const getInitialSearchState = ({
  initialCheckpointDetail,
  isFilterByTraining,
  isChildGrid,
  isSearchMode,
  isModal
}) => {
  return Object.assign({}, { 
    ...initialCheckpointDetail, 
    activityType: isFilterByTraining ? { text: "Training", value: ACTIVITY_TYPE.TRAINING } : (!isChildGrid && isSearchMode && isModal) ? { text: "Observation", value: ACTIVITY_TYPE.OBSERVATION } : null 
  })
}
