import { takeLatest, call } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

const { CAMPAIGN_WORKFLOW_SAGA } = LANGUAGE_CONSTANTS

/** getWorkflowReviewDetail API **/
function* getWorkflowReviewDetailRequest(action) {
  try {
    const response = yield call(services.getWorkflowReviewDetail, action.payload)
    if (!response?.data) {
      Notification({ status: 400, message: CAMPAIGN_WORKFLOW_SAGA.ERROR_IN_GETTING_CAMPAIGN_WORKFLOW_DETAILS })
    }
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getWorkflowSummary API **/
function* getWorkflowSummaryRequest(action) {
  try {
    const response = yield call(services.getWorkflowSummary, action.payload)
    if (!response?.data) {
      Notification({ status: 400, message: CAMPAIGN_WORKFLOW_SAGA.ERROR_IN_GETTING_CAMPAIGN_WORKFLOW_DETAILS })
    }
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export default function* workflowReviewSagas() {
  yield takeLatest(actions.GET_WORKFLOW_REVIEW_DETAIL_REQUEST, getWorkflowReviewDetailRequest)
  yield takeLatest(actions.GET_WORKFLOW_SUMMARY_REQUEST, getWorkflowSummaryRequest)
}
