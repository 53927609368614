import { takeLatest, call, put, takeEvery } from "redux-saga/effects"

import * as services from "@services/api"
import * as actions from "@store/actions"
import { Notification } from '@views/components'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'

const { NOTIFICATIONS, COMMON_TAG_COLLECTION_SAGA, COMMON_NOTES_SAGA, COMMON_SAGA } = LANGUAGE_CONSTANTS

/** getBreadscrumbDetail API **/
function* getBreadscrumbDetailRequest(action) {
  try {
    const response = yield call(services.getBreadscrumbDetail, action.payload)
    action.callback(response?.data || {})
  } catch (error) {
    action.callback({})
  }
}

/** getSiteWeatherData API **/
function* getSiteWeatherDataRequest(action) {
  try {
    const response = yield call(services.getSiteWeatherData, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(false)
  }
}

/** getNotification API **/
function* getnotificationRequest(action) {
  try {
    const response = yield call(services.getNotification, action.payload)
    if (response?.data) {
      yield put(actions.getNotificationSuccess(response.data))
    }
  } catch (error) {
    yield put(actions.getNotificationFailure(error.message))
  }
}

/** getWelcomeNotification API **/
function* getWelcomeNotificationRequest(action) {
  try {
    const response = yield call(services.getWelcomeNotification)
    if (response?.data) {
      yield put(actions.getWelcomeNotificationSuccess(response.data))
    }
  } catch (error) {
    yield put(actions.getWelcomeNotificationFailure(error.message))
  }
}

/** getQuickLinkList API **/
function* getQuickLinkListRequest(action) {
  try {
    const response = yield call(services.getQuickLinkList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** updateNotification API **/
function* updateNotificationRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateNotification, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** clearAllNotifications API */
function* clearAllNotificationsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.clearAllNotifications)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: NOTIFICATIONS.CLEAR_ALL_NOTIFICATION_SUCCESS })
      yield put(actions.setIsLoading(false))
    } else {
      action.callback()
      Notification({ status: 400, message: NOTIFICATIONS.CLEAR_ALL_NOTIFICATION_FAILURE })
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** clearNotification API */
function* clearNotificationRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.clearNotifications, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: NOTIFICATIONS.CLEAR_NOTIFICATION_SUCCESS })
      yield put(actions.setIsLoading(false))
    } else {
      action.callback()
      Notification({ status: 400, message: NOTIFICATIONS.CLEAR_NOTIFICATION_FAILURE })
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getHomeSitesList API **/
function* getHomeSitesListRequest(action) {
  try {
    const response = yield call(services.getHomeSitesList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** clearSelectedNotification API */
function* clearSelectedNotificationRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.clearSelectedNotifications, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: NOTIFICATIONS.CLEAR_SELECTED_NOTIFICATION_SUCCESS })
      yield put(actions.setIsLoading(false))
    } else {
      action.callback()
      Notification({ status: 400, message: NOTIFICATIONS.CLEAR_SELECTED_NOTIFICATION_FAILURE })
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getDuxImage API **/
function* getDuxImageRequest(action) {
  try {
    const response = yield call(services.getDuxImage, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getRegionFilterList API **/

function* getRegionFilterListRequest(action) {
  try {
    const response = yield call(services.getRegionFilterList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getSubRegionFilterList API **/

function* getSubRegionFilterListRequest(action) {
  try {
    const response = yield call(services.getSubRegionFilterList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getCommonTagsDDL API **/
function* getCommonTagsDDLRequest(action) {
  try {
    const response = yield call(services.getCommonTagsDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** updateCommonTag API **/
function* updateCommonTagRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateCommonTag, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: COMMON_TAG_COLLECTION_SAGA.ADDED_SUCCESS })
    } else {
      Notification({ status: 400, message: COMMON_TAG_COLLECTION_SAGA.ERROR_IN_ADDING })
      action.callback(false)
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** getCommonTag API **/
function* getCommonTagRequest(action) {
  try {
    const response = yield call(services.getCommonTag, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** updateNoteHeadline API **/
function* updateNoteHeadlineRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateNoteHeadline, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: COMMON_NOTES_SAGA.UPDATE_NOTE_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: COMMON_NOTES_SAGA.ERROR_IN_UPDATING_NOTE })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getCommonTag API **/
function* getNoteHeadlineRequest(action) {
  try {
    const response = yield call(services.getNoteHeadline, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** updateCommonTag API **/
function* sendEmailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.sendEmail, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: COMMON_TAG_COLLECTION_SAGA.EMAIL_SEND_SUCCESS })
    } else {
      Notification({ status: 400, message: COMMON_TAG_COLLECTION_SAGA.ERROR_IN_SENDING_EMAIL })
      action.callback(false)
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** getCCContactList API **/
function* getCCContactListRequest(action) {
  try {
    const response = yield call(services.getCCContactList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** upsertCCContacts API **/
function* upsertCCContactsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.upsertCCContacts, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      Notification({ status: 200, message: COMMON_SAGA.UPSERT_CC_CONTACTS_SUCCESS })
      action.callback(true)
    } else {
      Notification({ status: 400, message: COMMON_SAGA.ERROR_IN_UPSERTING_CC_CONTACTS })
      action.callback(false)
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

export function* commonSagas() {
  yield takeLatest(actions.GET_BREADSCRUMB_DETAIL_REQUEST, getBreadscrumbDetailRequest)
  yield takeEvery(actions.GET_SITE_WEATHER_DATA_REQUEST, getSiteWeatherDataRequest)
  yield takeLatest(actions.GET_NOTIFICATION_REQUEST, getnotificationRequest)
  yield takeLatest(actions.GET_QUICK_LINK_LIST_REQUEST, getQuickLinkListRequest)
  yield takeLatest(actions.GET_WELCOME_NOTIFICATION.REQUEST, getWelcomeNotificationRequest)
  yield takeLatest(actions.UPDATE_NOTIFICATION_REQUEST, updateNotificationRequest)
  yield takeLatest(actions.CLEAR_ALL_NOTIFICATION_REQUEST, clearAllNotificationsRequest)
  yield takeLatest(actions.CLEAR_NOTIFICATION_REQUEST, clearNotificationRequest)
  yield takeLatest(actions.GET_HOME_SITES_LIST_REQUEST, getHomeSitesListRequest)
  yield takeLatest(actions.CLEAR_SELECTED_NOTIFICATION_REQUEST, clearSelectedNotificationRequest)
  yield takeLatest(actions.GET_DUX_IMAGE_REQUEST, getDuxImageRequest)
  yield takeLatest(actions.GET_REGION_FILTER_LIST, getRegionFilterListRequest)
  yield takeLatest(actions.GET_SUB_REGION_FILTER_LIST, getSubRegionFilterListRequest)
  yield takeLatest(actions.GET_COMMON_TAGS_DDL_REQUEST, getCommonTagsDDLRequest)
  yield takeLatest(actions.UPDATE_COMMON_TAGS_REQUEST, updateCommonTagRequest)
  yield takeEvery(actions.GET_COMMON_TAGS_REQUEST, getCommonTagRequest)
  yield takeEvery(actions.UPDATE_NOTE_HEADLINE_REQUEST, updateNoteHeadlineRequest)
  yield takeEvery(actions.GET_NOTE_HEADLINE_REQUEST, getNoteHeadlineRequest)
  yield takeEvery(actions.SEND_EMAIL_REQUEST, sendEmailRequest)
  yield takeEvery(actions.GET_CCCONTACT_LIST_REQUEST, getCCContactListRequest)
  yield takeEvery(actions.UPSERT_CCCONTACTS_REQUEST, upsertCCContactsRequest)
}

export default commonSagas
