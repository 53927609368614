const apiConfig = {
  getUserManagementPermissionVM: {
    method: "GET",
    url: "api/UserManagement/role/permission/vm"
  },
  getUserManagementRolePermissionList: {
    method: "GET",
    url: ({ roleId = 0, entityId = 0 }) => `api/UserManagement/role/permission/${roleId}/${entityId}`
  },
  updateUserManagementRolePermissionList: {
    method: "POST",
    url: ({ roleId = 0, entityId = 0 }) => `api/UserManagement/role/permission/${roleId}/${entityId}`
  }
}

export default apiConfig
