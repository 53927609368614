import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method updateCourseRegistration
 * @param {object} data
 * {
 *  "assignmentActivityId": 0,
 *  "langauge": 0
 * }
 *
 */
export const updateCourseRegistration = (data) => {
  const { url } = config.updateCourseRegistration

  return postDataApi(url, data)
}

export default updateCourseRegistration
