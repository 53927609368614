import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux"

import {
  DEFAULT_TABLE_PAGE_SIZE,
  LIST_SORT_ORDER_DIRECTION,
  DETAILS_SECTION_TAB_TYPE,
  TABLE_LIST_KEYS,
  TABLE_VIEW_CUSTOM_MESSAGES
} from "@shared/constants"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { getLocaleMessage } from '@utils'
import { TableView } from '@views/components'
import localeMessageWrapper from '@views/components/locale-message'
import { getInitialListState } from '@views/helpers'
import { columns } from './TableConfig'
import AdvanceSearch from "./advance-search"
import { checkIfSearchFiltersApplied } from "./helpers"

const CAMPAIGN_SEARCH_DETAIL = {
  serviceName: "",
  siteName: "",
  unitName: "",
  checklistName: "",
  taskTypeId: "",
  taskStatusId: "",
  assignedTo: ""
}

const CampaignObservationList = ({ intl, campaignId, openedTabId }) => {
  const dispatch = useDispatch()

  const initialListState = useMemo(() => {
    return getInitialListState()
  }, [])

  const [campaignTaskList, setCampaignTaskList] = useState(Object.assign({}, initialListState))
  const [isOpenAdvanceSearch, setIsOpenAdvanceSearch] = useState(false)
  const [searchDetail, setSearchDetail] = useState(
    Object.assign({}, CAMPAIGN_SEARCH_DETAIL)
  )
  const [isFetching, setFetchingList] = useState(false)
  const [genericSearch, setGenericSearch] = useState("")
  const [typingTimeout, setTypingTimeout] = useState(null)
  const [isSearchApplied, setSearchApplied] = useState(false)
  const [isGenericSearchApplied, setGenericSearchApplied] = useState(false)

  const [sortOrderColumn, setSortOrderColumn] = useState('')
  const [sortOrderDirection, setSortOrderDirection] = useState('')

  const isListRefreshed = useSelector((state) => state.tableActions[TABLE_LIST_KEYS.CAMPAIGN_TASK_LIST_KEY]?.isListRefreshed || false)

  const { list, pageNumber, pageSize, totalCount, totalPages } = campaignTaskList

  const handleFetchCampaignObservationList = useCallback((data) => {
    const {
      orderColumn,
      orderDirections,
      page,
      search,
      size,
      canApplyFilters = true,
      isGSFilter = false,
      advanceSearchDetail = {},
      isAutoAdvanceSearch = false,
      isPageLoaderRequired = false,
      isResetAdvanceSearch = false,
      isResetSortOrder = false
    } = data || {}
    if (isFetching) {
      return
    }

    if (!isPageLoaderRequired) {
      setFetchingList(true)
    }

    const searchFormData = isAutoAdvanceSearch ? advanceSearchDetail : searchDetail
    let advanceSearch = {}
    if (!isResetAdvanceSearch) {
      advanceSearch = {
        ...searchFormData
      }
      setSearchApplied(checkIfSearchFiltersApplied(searchFormData))
    } else {
      setSearchApplied(false)
    }

    const searchText = isGSFilter
      ? search
        ? search.trim()
        : ""
      : genericSearch || ""

    const searchVal = canApplyFilters ? searchText : ''
    dispatch(actions.getCampaignObservationListRequest({
      campaignId,
      pageNumber: page || pageNumber,
      pageSize: size || pageSize,
      genericSearch: searchVal,
      isLoadingRequired: isPageLoaderRequired,
      advanceSearch: canApplyFilters ? advanceSearch : '',
      orderColumn: isResetSortOrder ? orderColumn : sortOrderColumn,
      orderDirections: isResetSortOrder ? orderDirections : sortOrderDirection
    }, (response) => {
      if (response) {
        const listData = {
          list: response.items,
          pageSize: response.pageSize,
          pageNumber: response.pageIndex,
          totalCount: response.totalCount,
          totalPages: response.totalPages,
          hasPreviousPage: response.hasPreviousPage,
          hasNextPage: response.hasNextPage
        }

        setCampaignTaskList(listData)
      }
      setFetchingList(false)
      if (typingTimeout) {
        setTypingTimeout(null)
      }

      setGenericSearchApplied(!!searchVal)
    }))
  }, [
    isFetching,
    campaignId,
    pageNumber,
    pageSize,
    genericSearch,
    searchDetail,
    sortOrderColumn,
    sortOrderDirection,
    typingTimeout
  ])

  const handleResetCampaignTaskList = useCallback(() => {
    setCampaignTaskList(Object.assign({}, initialListState))
  }, [])

  useEffect(() => {
    if (openedTabId && openedTabId === DETAILS_SECTION_TAB_TYPE.TASKS) {
      handleResetCampaignTaskList()
      handleFetchCampaignObservationList({
        page: 1,
        size: DEFAULT_TABLE_PAGE_SIZE
      })
    }
  }, [openedTabId])

  useEffect(() => {
    if (isListRefreshed) {
      handleFetchCampaignObservationList({ canApplyFilters: isGenericSearchApplied || isSearchApplied })
      dispatch(actions.refreshTableList({ listKey: TABLE_LIST_KEYS.CAMPAIGN_TASK_LIST_KEY, value: false }))
    }
  }, [isListRefreshed])

  const handlePerPageRecords = useCallback(
    (event) => {
      const size = parseInt(event.target.value)
      handleResetCampaignTaskList()
      handleFetchCampaignObservationList({ page: 1, size })
    },
    [handleFetchCampaignObservationList, handleResetCampaignTaskList]
  )

  const handlePagination = useCallback(
    ({ selected }) => {
      handleFetchCampaignObservationList({ page: selected + 1 })
    },
    [handleFetchCampaignObservationList]
  )

  // ** Function to handle filter
  const handleFilter = useCallback(
    (value) => {
      if (typingTimeout) {
        clearTimeout(typingTimeout)
      }

      setTypingTimeout(
        setTimeout(() => {
          handleResetCampaignTaskList()
          handleFetchCampaignObservationList({ page: 1, search: value, isGSFilter: true })
        }, 1000)
      )
    },
    [genericSearch, handleFetchCampaignObservationList, handleResetCampaignTaskList]
  )

  // ** Function to handle change of generic search filter
  const handleChangeGenericSearch = useCallback(
    (e) => {
      const { value } = e.target
      setGenericSearch(value)
      const lengthOfSearch = value.trim().length
      if (
        (!!lengthOfSearch && lengthOfSearch >= 3) ||
        (!lengthOfSearch && !!genericSearch.length && isGenericSearchApplied)
      ) {
        handleFilter(value)
      }
    },
    [genericSearch, isGenericSearchApplied, handleFilter]
  )

  const handleChangeDetails = useCallback(
    // (key, value) => {
    (key, value, callback = () => { }) => {
      const updatedDetail = Object.assign({}, searchDetail)
      updatedDetail[key] = value
      setSearchDetail(updatedDetail)
      callback(updatedDetail)
    },
    [searchDetail]
  )

  const handleAdvancedSearch = useCallback((isAutoAdvanceSearch, data) => {
    const isAutoSearch = isAutoAdvanceSearch && !!data
    if (checkIfSearchFiltersApplied(isAutoSearch ? data : searchDetail)) {
      handleResetCampaignTaskList()
      const autoSearchConfig = isAutoSearch ? { isAutoAdvanceSearch, advanceSearchDetail: data } : {}
      handleFetchCampaignObservationList({ page: 1, ...autoSearchConfig })
    }
  }, [searchDetail, handleFetchCampaignObservationList, handleResetCampaignTaskList])

  // ** Function to get search results when enter key is pressed or user select some value
  // from dropdown in advance search form
  const handleCustomAdvanceSearch = useCallback(
    (event, isVMBasedSearch, data) => {
      if (event?.keyCode === 13) {
        handleAdvancedSearch()
      } else if (isVMBasedSearch) {
        handleAdvancedSearch(true, data)
      }
    },
    [handleAdvancedSearch]
  )

  const handleResetSearchDetail = useCallback(() => {
    setSearchDetail(Object.assign({}, CAMPAIGN_SEARCH_DETAIL))
    if (isSearchApplied) {
      handleResetCampaignTaskList()
      handleFetchCampaignObservationList({ page: 1, isResetAdvanceSearch: true })
    }
  }, [isSearchApplied, handleResetCampaignTaskList, handleFetchCampaignObservationList])

  const handleToggleAdvanceSearch = useCallback(
    (event) => {
      if (event.target.id === "close-search" || isOpenAdvanceSearch) {
        setIsOpenAdvanceSearch(!isOpenAdvanceSearch)
      } else {
        setIsOpenAdvanceSearch(!isOpenAdvanceSearch)
      }
    },
    [isOpenAdvanceSearch]
  )

  const handleSortList = useCallback((column, sortDirection) => {
    if (column.sortKey) {
      setSortOrderColumn(column.sortKey)
      const direction = sortDirection === LIST_SORT_ORDER_DIRECTION.ASC ? "0" : "1"
      setSortOrderDirection(direction)

      handleFetchCampaignObservationList({
        isPageLoaderRequired: false,
        orderColumn: column.sortKey,
        orderDirections: direction,
        isResetSortOrder: true
      })
    }
  }, [handleFetchCampaignObservationList])

  const { campaignObservationTaskList: campaignObservationTaskListCustomMessages } =
    TABLE_VIEW_CUSTOM_MESSAGES

  return (<>
    {isOpenAdvanceSearch && (
      <AdvanceSearch
        searchDetail={searchDetail}
        onChangeDetails={handleChangeDetails}
        onAdvancedSearch={handleAdvancedSearch}
        onResetSearchDetail={handleResetSearchDetail}
        onToggleAdvanceSearch={handleToggleAdvanceSearch}
        onCustomAdvanceSearch={handleCustomAdvanceSearch}
      />
    )}
    <TableView
      selectableRows={false}
      columns={columns}
      keyField={'observationTaskId'}
      data={list}
      genericSearch={genericSearch}
      isLoading={isFetching}
      noDataMessage={
        isFetching
          ? campaignObservationTaskListCustomMessages.loading
          : isSearchApplied || isGenericSearchApplied
            ? campaignObservationTaskListCustomMessages.noDataForFilters
            : campaignObservationTaskListCustomMessages.noData
      }
      pageNumber={pageNumber}
      pageSize={pageSize}
      sortServer
      title={getLocaleMessage(intl, LANGUAGE_CONSTANTS.CAMPAIGNS.TASKS.HEADER_TITLE)}
      totalCount={totalCount}
      totalPages={totalPages}
      // noTableDataClassRequired={false}
      onChangeSearchFilter={handleChangeGenericSearch}
      onPerPageRecords={handlePerPageRecords}
      onPagination={handlePagination}
      onSort={handleSortList}
      onToggleAdvanceSearch={handleToggleAdvanceSearch}
    />
  </>
  )
}

export default localeMessageWrapper(CampaignObservationList)
