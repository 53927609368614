import React, { useState, useCallback, useEffect } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import * as actions from "@store/actions"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"
import localeMessageWrapper from '@views/components/locale-message'

const { ACTIVITIES } = LANGUAGE_CONSTANTS

const ComponentSearch = ({
  intl,
  errors,
  value = "",
  initialLabel = "",
  assemblyTypeIds = [],
  isRequired = false,
  isMulti = true,
  id = "component",
  name = "component",
  isComponentDisabled = false,
  onChangeCheckpointCollection
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState([])
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  useEffect(() => {
    setResetCache((prevState) => !prevState)
    setOptions([])
    if (!!initialLabel && !!value) {
      setSelectedOptionLabel(initialLabel)
    }
  }, [assemblyTypeIds, initialLabel])

  const handleSelect = useCallback((id, selected) => {
    if (isMulti) {
      const lastElement = selected.length
        ? selected[selected.length - 1]
        : null
      onChangeCheckpointCollection(id, lastElement?.componentName !== "— Select —" ? selected : [])
    } else {
      onChangeCheckpointCollection(id, selected?.componentId || "", selected?.componentName !== "— Select —" ? selected?.componentId : "")
      setSelectedOptionLabel(selected?.componentName !== "— Select —" ? selected?.componentId : "")
    }
  }, [onChangeCheckpointCollection])

  const handleLoadMoreAssembly = useCallback(
    (search, page, prevOptions, others) => {
      return new Promise((resolve) => {
        if (others.isReset && !prevOptions.length && options.length) {
          resolve({
            optionList: options,
            hasMore: true
          })
          return
        }
        const params = {
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          componentName: search,
          assemblyTypeIds
        }
        dispatch(
          actions.getAssemblyTypeComponentListRequest(params, (response) => {
            if (response) {
              const { items, ...paginationData } = response
              resolve({
                optionList: items,
                hasMore: paginationData.hasNextPage
              })
              setOptions(() => (!!prevOptions.length ? [...prevOptions, ...items] : items))
            } else {
              resolve({
                optionList: [],
                hasMore: false
              })
            }
          })
        )
      })
    },
    [isResetCache, options, value, assemblyTypeIds]
  )

  useEffect(() => {
    if (!!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])

  return (
    <Col className="mb-1" sm={12} md={6} xl={4}>
      <CustomLabel title={ACTIVITIES.MAIN_COMPONENT} required={isRequired} requiredIconClassName="required-field" />
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id={id}
        name={name}
        placeholder={getLocaleMessage(intl, ACTIVITIES.SELECT_COMPONENT)}
        options={options}
        lableType={"componentName"}
        valueType={"componentId"}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={
          !isMulti
            ? !!value
              ? {
                componentName: selectedOptionLabel,
                componentId: value
              }
              : null
            : value
        }
        isMulti={isMulti}
        isDisabled={!isComponentDisabled}
        isShowCustomAction={false}
        hideSelectedOptions={false}
        onSelect={(selected) => handleSelect(id, selected)}
        onLoadOptions={handleLoadMoreAssembly}
        getOptionLabel={(option) => option.componentName}
        getOptionValue={(option) => option.componentId}
      />
      {errors && (
        <ErrorMessage
          isShow={!!errors[id]}
          message={errors[id]}
        />
      )}
    </Col>
  )
}

export default localeMessageWrapper(ComponentSearch)
