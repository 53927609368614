import React from "react"
import { Col, Row } from "reactstrap"

const Form = () => {
  return (
    <div className="skeleton-loader">
      <div className="d-flex justify-content-between mt-1">
        <h2 className="skeleton-title skeleton-style" />
      </div>
      <Row xs={1} sm={1}>
        <Col md={12}>
            {Array.from(Array(5)).map((y, i) => (
            <div
                key={`skeleton_sub_config_${y}_${i}`}
                className="mt-1 d-flex"
            >
                <div className="skeleton-input-label skeleton-style mr-1" />
                <div className="skeleton-bar skeleton-style" />
            </div>
            ))}
        </Col>
      </Row>
      <Row xs={1} sm={1} className="mt-1">
      <Col className="text-right" md={12}>
          <div className="skeleton-style skeleton-btn" />
        </Col>
      </Row>
    </div>
  )
}

export default Form
