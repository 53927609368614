import { getActionType } from '../action-type-generator'

export const GET_TEMPLATE_TRAINING_ACTIVITY_VM = getActionType('GET_TEMPLATE_TRAINING_ACTIVITY_VM')
export const RESET_TEMPLATE_TRAINING_ACTIVITY_VM = 'RESET_TEMPLATE_TRAINING_ACTIVITY_VM'
export const ADD_TEMPLATE_TRAINING_ACTIVITY_REQUEST = 'ADD_TEMPLATE_TRAINING_ACTIVITY_REQUEST'
export const GET_TEMPLATE_TRAINING_ACTIVITY_REQUEST = 'GET_TEMPLATE_TRAINING_ACTIVITY_REQUEST'
export const UPDATE_TEMPLATE_TRAINING_ACTIVITY_REQUEST = 'UPDATE_TEMPLATE_TRAINING_ACTIVITY_REQUEST'
export const GET_ALL_COURSES_REQUEST = 'GET_ALL_COURSES_REQUEST'

/****************************************************/
/** GET_TEMPLATE_TRAINING_ACTIVITY_VM **/
/****************************************************/
/**
 * @method getTrainingActivityVMRequest : To get training activity VM
 *
 */
 export const getTrainingActivityVMRequest = (payload) => {
  return {
      type: GET_TEMPLATE_TRAINING_ACTIVITY_VM.REQUEST,
      payload
  }
}

/**
* @method getTrainingActivityVMSuccess : To set training activity VM on success
*
*/
export const getTrainingActivityVMSuccess = (data) => {
  return {
      type: GET_TEMPLATE_TRAINING_ACTIVITY_VM.SUCCESS,
      data
  }
}

/****************************************************/
/** RESET_TEMPLATE_TRAINING_ACTIVITY_VM **/
/****************************************************/
/**
 * @method resetTemplateTrainingActivityVM :  To reset training activity VM
 *
 */
 export const resetTemplateTrainingActivityVM = () => {
  return {
    type: RESET_TEMPLATE_TRAINING_ACTIVITY_VM
  }
}

/****************************************************/
/** ADD_TEMPLATE_TRAINING_ACTIVITY_REQUEST **/
/****************************************************/

/**
 * @method addTemplateTrainingActivity :  To add training activity 
 *
 */
export const addTemplateTrainingActivity = (payload, callback) => {
  return {
    type: ADD_TEMPLATE_TRAINING_ACTIVITY_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TEMPLATE_TRAINING_ACTIVITY_REQUEST **/
/****************************************************/
/**
 * @method getTemplateTrainingActivity :  To get training activity 
 *
 */
export const getTemplateTrainingActivity = (payload, callback) => {
  return {
    type: GET_TEMPLATE_TRAINING_ACTIVITY_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_TEMPLATE_TRAINING_ACTIVITY_REQUEST **/
/****************************************************/

/**
 * @method updateTemplateTrainingActivity :  To update training activity 
 *
 */
export const updateTemplateTrainingActivity = (payload, callback) => {
  return {
    type: UPDATE_TEMPLATE_TRAINING_ACTIVITY_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ALL_COURSES_REQUEST **/
/****************************************************/

/**
 * @method getAllCoursesRequest :  To get all courses for training activity
 *
 */
 export const getAllCoursesRequest = (payload, callback) => {
  return {
    type: GET_ALL_COURSES_REQUEST,
    payload,
    callback
  }
}
