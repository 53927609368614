import React, { useCallback, useEffect, useMemo, useState } from "react"

import { LANGUAGE_TYPE } from '@shared/constants'
import { cloneArrayOfObject } from "@utils"
import { 
  checkIfStandardChoiceResponse, 
  getStandardChoiceResponseName, 
  getStandardResponseQuestion, 
  validateChoiceResponse, 
  validateResponseGotoCondition 
} from "../helpers"
import ChoiceResponseComponnet from "./component"
import StandardChoiceResponseComponent from "./StandardChoiceResponseComponent"
import { getGivenTextLanguageList, setSpecificLanguage } from '../Config'
import { useSelectedLanguageAcitivity } from '../custom-hook/useSelectedLanguageAcitivity'

export const RESPONSE_CHOICE_TYPES = {
  BOOLEAN: "1",
  SINGLE: "2",
  MULTI: "3",
  DATE: '4',
  NUMERIC: "6",
  DDL_SINGLE: "8",
  DDL_MULTI: "9",
  QA_STANDARD: "10",
  PHOTO: '7',
  FREE_TEXT: '5',
  HIDDEN_FIELD: '11',
  DDL_CUSTOM_SINGLE_SELECT: '12',
  BUTTON: '13',
  SIGNATURE: '14',
  FILE:'15',
  CALCULATION: '18',
  ESTAR_STANDARD_WITH_BV: "19",
  ESTAR_STANDARD_WITHOUT_BV: "20",
  PASS_FAIL: "21",
  BEAZER_QA_STANDARD: "22"
}

export const OTHER_CHOICE = { [LANGUAGE_TYPE.ENGLISH]: "Other", [LANGUAGE_TYPE.SPANISH]: "Otra" }

export const TRUE_OPTION = { [LANGUAGE_TYPE.ENGLISH]: "True", [LANGUAGE_TYPE.SPANISH]: "Cierto" }

export const FALSE_OPTION = { [LANGUAGE_TYPE.ENGLISH]: "False", [LANGUAGE_TYPE.SPANISH]: "Falsa" }


const APPROVED_OPTION = { [LANGUAGE_TYPE.ENGLISH]: 'Approved', [LANGUAGE_TYPE.SPANISH]: "Aprobada" }
const UNAPPROVED_OPTION = { [LANGUAGE_TYPE.ENGLISH]: 'Unapproved', [LANGUAGE_TYPE.SPANISH]: "no aprobado" }
const CONCEALED_OPTION = { [LANGUAGE_TYPE.ENGLISH]: 'Concealed', [LANGUAGE_TYPE.SPANISH]: "Ocultado" }
const NA_OPTION = { [LANGUAGE_TYPE.ENGLISH]: 'N/A', [LANGUAGE_TYPE.SPANISH]: "N/A" }

export const QA_STANDARD_QUESTION = { [LANGUAGE_TYPE.ENGLISH]: 'QA Standard', [LANGUAGE_TYPE.SPANISH]: "Estándar de QA" }
export const QA_STANDARD_QUESTION_OPTIONS = [APPROVED_OPTION, UNAPPROVED_OPTION, CONCEALED_OPTION, NA_OPTION]

export const BEAZER_QA_STANDARD_QUESTION = { [LANGUAGE_TYPE.ENGLISH]: 'Beazer QA Standard', [LANGUAGE_TYPE.SPANISH]: "Estándar de Beazer QA" }
export const BEAZER_QA_STANDARD_QUESTION_OPTIONS = [APPROVED_OPTION, UNAPPROVED_OPTION, CONCEALED_OPTION, NA_OPTION]

const MUST_CORRECT_OPTION = { [LANGUAGE_TYPE.ENGLISH]: 'Must Correct', [LANGUAGE_TYPE.SPANISH]: "Debe corregir" }
const BUILDER_VERIFIED_OPTION = { [LANGUAGE_TYPE.ENGLISH]: 'Builder Verified', [LANGUAGE_TYPE.SPANISH]: "Constructor verificado" }
const RATER_VERIFIED_OPTION = { [LANGUAGE_TYPE.ENGLISH]: 'Rater Verified', [LANGUAGE_TYPE.SPANISH]: "Evaluador verificado" }
export const ESTAR_STANDARD_WITH_BV_QUESTION = { [LANGUAGE_TYPE.ENGLISH]: 'ESTAR Standard with BV', [LANGUAGE_TYPE.SPANISH]: "Estándar ESTAR con BV" }
export const ESTAR_STANDARD_WITH_BV_QUESTION_OPTIONS = [MUST_CORRECT_OPTION, BUILDER_VERIFIED_OPTION, RATER_VERIFIED_OPTION, NA_OPTION]

export const ESTAR_STANDARD_WITHOUT_BV_QUESTION = { [LANGUAGE_TYPE.ENGLISH]: 'ESTAR Standard without BV', [LANGUAGE_TYPE.SPANISH]: "Estándar ESTAR sin BV" }
export const ESTAR_STANDARD_WITHOUT_BV_QUESTION_OPTIONS = [MUST_CORRECT_OPTION, RATER_VERIFIED_OPTION, NA_OPTION]

const PASS_OPTION = { [LANGUAGE_TYPE.ENGLISH]: 'Pass', [LANGUAGE_TYPE.SPANISH]: "Aprobar" }
const FAIL_OPTION = { [LANGUAGE_TYPE.ENGLISH]: 'Fail', [LANGUAGE_TYPE.SPANISH]: "Fallar" }
export const PASS_FAIL_QUESTION = { [LANGUAGE_TYPE.ENGLISH]: 'Pass/Fail', [LANGUAGE_TYPE.SPANISH]: "Aprobar/Fallar" }
export const PASS_FAIL_QUESTION_OPTIONS = [PASS_OPTION, FAIL_OPTION]

export const otherChoiceOptionDetail = {
  options: getGivenTextLanguageList(OTHER_CHOICE),
  orderBy: 0
}

export const initialChoiceOption = {
  options: getGivenTextLanguageList(),
  orderBy: 0
}

export const initialResponseDetail = {
  checkpointResponseId: 0,
  checkpointResponseTypeId: 0,
  responseName: getGivenTextLanguageList(),
  orderBy: 0,
  required: true,
  isDeleted: false,
  isPrimary: false,
  otherOptions: false,
  dispositionId: 0,
  checkpointResponseQuestions: [initialChoiceOption, initialChoiceOption]
}

const ChoiceResponse = ({
  isPrimary,
  activeLocaleTab = 0,
  dispositionId = 0,
  isEditResponseMode = false,
  isSpanishVersion = false,
  responseEditableDetail = null,
  responseTypeId = 0,
  primaryResponse,
  gotoConditions,
  errors: activityErrors,
  onCancelUpdate,
  onUpsertResponse,
  onSetErrors
}) => {
  const isStandardChoiceResponseType = useMemo(() => {
    return checkIfStandardChoiceResponse(responseTypeId)
   }, [responseTypeId])

  const [responseDetail, setResponseDetail] = useState(isStandardChoiceResponseType ? {
    ...getStandardResponseQuestion(getStandardChoiceResponseName(responseTypeId), responseTypeId),
    isPrimary: false,
    responseId: undefined
  } : {
    ...initialResponseDetail, checkpointResponseTypeId: responseTypeId
  })
  const [errors, setErrors] = useState({})
  const [isOtherOptionAdded, setOtherOptionAdded] = useState(false)
  const choiceResponseDetail = useSelectedLanguageAcitivity(responseDetail, "responseName", activeLocaleTab)

  useEffect(() => {
    if (isEditResponseMode && responseEditableDetail) {
      if (responseEditableDetail.otherOptions) {
        setOtherOptionAdded(true)
        const updatedDetail = Object.assign({}, responseEditableDetail)
        const updatedResponses = cloneArrayOfObject(
          updatedDetail.checkpointResponseQuestions
        )
        // updatedResponses.push(otherChoiceOptionDetail)
        updatedDetail.checkpointResponseQuestions = updatedResponses
        setResponseDetail(updatedDetail)
      } else {
        setResponseDetail(responseEditableDetail)
      }
    }
  }, [])

  useEffect(() => {
    if (
      !isEditResponseMode &&
      responseTypeId !== responseDetail.checkpointResponseTypeId
    ) {
      setOtherOptionAdded(false)

      let responseData = {
        ...initialResponseDetail,
        checkpointResponseTypeId: responseTypeId
      }
      if (checkIfStandardChoiceResponse(responseTypeId)) {
        responseData = {
          ...getStandardResponseQuestion(getStandardChoiceResponseName(responseTypeId), responseTypeId),
          isPrimary: false,
          responseId: undefined
        }
      }
      setResponseDetail(responseData)
    }
  }, [responseTypeId])

  const handleChangeDetail = useCallback(
    (key, value, others) => {
      if (!!Object.keys(errors).length) {
        setErrors({})
      }
      if (!!Object.keys(activityErrors).length) {
        onSetErrors({})
      }

      const updatedDetail = Object.assign({}, responseDetail)
      if (Object.keys(others || {}).includes("isOtherOption")) {
        updatedDetail["otherOptions"] = others.isOtherOption
      }
      if (key === 'responseName') {
        updatedDetail[key] = setSpecificLanguage({ list: updatedDetail[key], value, activeLocaleTab, isDescriptionUpdate: others?.isDescriptionUpdate })
      } else {
        updatedDetail[key] = value
      }
      setResponseDetail(updatedDetail)
    },
    [errors, activityErrors, responseDetail, activeLocaleTab]
  )

  const handleChangeChoiceText = useCallback(
    (e, index) => {
      const updatedDetail = Object.assign({}, responseDetail)
      const updatedResponses = cloneArrayOfObject(
        updatedDetail.checkpointResponseQuestions
      )
      updatedResponses[index].options = setSpecificLanguage({ list: updatedResponses[index].options, value: e.target.value, activeLocaleTab })
      handleChangeDetail("checkpointResponseQuestions", updatedResponses)
    },
    [responseDetail, activeLocaleTab, handleChangeDetail]
  )

  const handleAddNewOption = useCallback(() => {
    const updatedDetail = Object.assign({}, responseDetail)
    const updatedResponses = cloneArrayOfObject(
      updatedDetail.checkpointResponseQuestions
    )
    if (isOtherOptionAdded) {
      const lastIndex = updatedResponses.length - 1
      updatedResponses.splice(lastIndex, 0, initialChoiceOption)
    } else {
      updatedResponses.push(initialChoiceOption)
    }
    handleChangeDetail("checkpointResponseQuestions", updatedResponses)
  }, [isOtherOptionAdded, responseDetail, handleChangeDetail])

  const handleAddOtherOption = useCallback(() => {
    const updatedDetail = Object.assign({}, responseDetail)
    const updatedResponses = cloneArrayOfObject(
      updatedDetail.checkpointResponseQuestions
    )
    updatedResponses.push(otherChoiceOptionDetail)
    handleChangeDetail("checkpointResponseQuestions", updatedResponses, {
      isOtherOption: true
    })
    setOtherOptionAdded(true)
  }, [responseDetail, handleChangeDetail])

  const handleReorderResponseOptions = useCallback((list) => {
    handleChangeDetail("checkpointResponseQuestions", list)
  }, [responseDetail, handleChangeDetail])

  const handleDeleteOption = useCallback(
    (index) => {
      const updatedDetail = Object.assign({}, responseDetail)
      const updatedResponses = cloneArrayOfObject(
        updatedDetail.checkpointResponseQuestions
      )
      const selectedOption = updatedResponses[index]
      updatedResponses.splice(index, 1)

      if (
        !!selectedOption &&
        selectedOption.options[activeLocaleTab].languageValue === otherChoiceOptionDetail.options[activeLocaleTab].languageValue
      ) {
        handleChangeDetail("checkpointResponseQuestions", updatedResponses, {
          isOtherOption: false
        })
        setOtherOptionAdded(false)
      } else {
        handleChangeDetail("checkpointResponseQuestions", updatedResponses)
      }
    },
    [activeLocaleTab, responseDetail, handleChangeDetail]
  )

  const handleUpsertResponse = useCallback(() => {
    let isError = false
    const errors = validateChoiceResponse(choiceResponseDetail, activeLocaleTab, isPrimary)
    const condErrors = validateResponseGotoCondition({ gotoConditions, isPrimary, primaryResponse, activeLocaleTab })

    if (!!Object.keys(errors).length) {
      setErrors(errors)
      isError = true
    }

    if (!!Object.keys(condErrors).length) {
      onSetErrors(condErrors)
      isError = true
    }

    if (isError) {
      return true
    }

    const updatedDetail = Object.assign({}, responseDetail)
    if (!!updatedDetail.otherOptions) {
      const updatedResponses = cloneArrayOfObject(
        updatedDetail.checkpointResponseQuestions
      )
      updatedDetail.checkpointResponseQuestions = updatedResponses
    }

    updatedDetail.checkpointResponseQuestions = updatedDetail.checkpointResponseQuestions.map((i, index) => ({
      ...i,
      orderBy: index + 1
    }))

    if (dispositionId) {
      updatedDetail.dispositionId = dispositionId
    }

    onUpsertResponse(updatedDetail, isEditResponseMode)
  }, [
    isPrimary,
    activeLocaleTab,
    dispositionId,
    isEditResponseMode,
    responseDetail,
    choiceResponseDetail,
    gotoConditions,
    primaryResponse,
    onUpsertResponse,
    onSetErrors
  ])

  if (isStandardChoiceResponseType) {
    return (
      <StandardChoiceResponseComponent
        isPrimary={isPrimary}
        activeLocaleTab={activeLocaleTab}
        errors={errors}
        isEditResponseMode={isEditResponseMode}
        isSpanishVersion={isSpanishVersion}
        responseDetail={choiceResponseDetail}
        onCancelUpdate={onCancelUpdate}
        onChangeDetail={handleChangeDetail}
        onUpsertResponse={handleUpsertResponse}
      />
    )
  }

  return (
    <ChoiceResponseComponnet
      isPrimary={isPrimary}
      activeLocaleTab={activeLocaleTab}
      errors={errors}
      isEditResponseMode={isEditResponseMode}
      isOtherOptionAdded={isOtherOptionAdded}
      isSpanishVersion={isSpanishVersion}
      responseDetail={choiceResponseDetail}
      responseTypeId={responseTypeId}
      onAddNewOption={handleAddNewOption}
      onAddOtherOption={handleAddOtherOption}
      onCancelUpdate={onCancelUpdate}
      onChangeDetail={handleChangeDetail}
      onChangeChoiceText={handleChangeChoiceText}
      onDeleteOption={handleDeleteOption}
      onUpsertResponse={handleUpsertResponse}
      onSortList={handleReorderResponseOptions}
    />
  )
}

export default ChoiceResponse
