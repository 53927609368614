const apiConfig = {
    getOshaTrainingSiteRole: {
      method: 'GET',
      url: (programId = 0) => `api/Program/OshaTrainingSiteRole/${programId}`
    },
    addOshaTrainingCampaign: {
      method: 'POST',
      url: (programId = 0) => `api/Program/OshaTrainingCampaign/${programId}`
    },
    getOshaTrainingCampaign: {
      method: "GET",
      url: ({ programId }) => `api/Program/OshaTrainingCampaign/${programId}`
    },
    updateOshaTrainingCampaign: {
      method: "PUT",
      url: (programId) => `api/Program/OshaTrainingCampaign/${programId}`
    }
  }
  
  export default apiConfig
  