import { put, takeLatest, call } from "redux-saga/effects"

import {
  addProjectSiteDetails,
  getContactByOrganization,
  getProjectSiteDetails,
  getProjectSiteList,
  getProjectSiteVM,
  getSiteUnitChildGrid,
  updateProjectSiteDetails,
  deleteProjectSite
} from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'
import * as actions from "@store/actions"

const { PROJECT_SITE_SAGA } = LANGUAGE_CONSTANTS

/** getProjectSiteList API **/
function* getProjectSiteListRequest(action) {
  try {
    const response = yield call(getProjectSiteList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getSiteUnitChildGrid API **/
function* getSiteUnitChildGridRequest(action) {
  try {
    const response = yield call(getSiteUnitChildGrid, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback(null)
    }
  } catch (error) {
    action.callback(null)
  }
}

/** getProjectSiteVM API **/
function* getProjectSiteVMRequest(action) {
  try {
    const response = yield call(getProjectSiteVM, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getProjectSiteRole API **/
function* getProjectSiteDetailsRequest(action) {
  try {
    const { isLoadingRequired = false } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getProjectSiteDetails, action.payload)
    if (response?.data) {
      action.callback(response.data)
      yield put(actions.setIsLoading(false))
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** addProjectSiteRole API **/
function* addProjectSiteDetailsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addProjectSiteDetails, action.payload)
    if (response?.data) {
      action.callback(response.data)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_SITE_SAGA.ADDED_SUCCESS })
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_SITE_SAGA.ERROR_IN_ADDING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** updateProjectSiteRole API **/
function* updateProjectSiteDetailsRequest(action) {
  try {
    const { isLocationDetail, isAddLocationDetail, ...payload } = action.payload

    yield put(actions.setIsLoading(true))
    const response = yield call(updateProjectSiteDetails, payload)

    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({
        status: 200,
        message: isLocationDetail ? isAddLocationDetail ? PROJECT_SITE_SAGA.LOCATION_ADD_SUCCESS : PROJECT_SITE_SAGA.LOCATION_UPDATE_SUCCESS : PROJECT_SITE_SAGA.UPDATED_SUCCESS
      })
    } else {
      yield put(actions.setIsLoading(false))
      Notification({
        status: 400,
        message: isLocationDetail ? isAddLocationDetail ? PROJECT_SITE_SAGA.ERROR_IN_ADDING_LOCATION : PROJECT_SITE_SAGA.ERROR_IN_UPDATING_LOCATION : PROJECT_SITE_SAGA.ERROR_IN_UPDATING
      })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** getContactByOrganization API **/
function* getContactByOrganizationRequest(action) {
  try {
    const { isDropdownLoadMore = false, fieldType = 'sitePointOfContact', ...payload } = action.payload
    const response = yield call(getContactByOrganization, payload)
    if (response?.data) {
      if (!isDropdownLoadMore) {
        if (fieldType === 'sitePointOfContact') {
          yield put(actions.getContactByOrganizationSuccess({ ...response.data, pageSize: payload.pageSize }))
        }
      }
      if (action.callback) {
        action.callback({ ...response.data, pageSize: payload.pageSize })
      }
    } else {
      if (!isDropdownLoadMore) {
        if (fieldType === 'sitePointOfContact') {
          yield put(actions.getContactByOrganizationFailure("Unable to load organization contact list"))
        }
        if (fieldType === 'siteRolesContact') {
          Notification({ status: 400, message: PROJECT_SITE_SAGA.UNABLE_TO_GET_LIST_OF_CONTACTS_FOR_SELECTED_ORGANIZATION })
        }
      }
      if (action.callback) {
        action.callback(null)
      }
    }
  } catch (error) {
    const { isDropdownLoadMore = false, fieldType = 'sitePointOfContact' } = action.payload
    if (!isDropdownLoadMore) {
      if (fieldType === 'sitePointOfContact') {
        yield put(actions.getContactByOrganizationFailure(error.message))
      }
      if (fieldType === 'siteRolesContact') {
        Notification({ status: 400, message: PROJECT_SITE_SAGA.UNABLE_TO_GET_LIST_OF_CONTACTS_FOR_SELECTED_ORGANIZATION })
      }
    }
    if (action.callback) {
      action.callback(null)
    }
  }
}
/** deleteProjectSiteApi API **/
function* deleteProjectSiteRequest(data) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteProjectSite, data.payload)
    if (response?.status === 200) {
      yield put(actions.deleteProjectSiteSuccess(response.data))
      if (data.callback) {
        data.callback()
      }
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: PROJECT_SITE_SAGA.DELETED_SUCCESS })
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: PROJECT_SITE_SAGA.ERROR_IN_DELETING })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: PROJECT_SITE_SAGA.ERROR_IN_DELETING })
  }
}

export function* projectSiteDetailsSagas() {
  yield takeLatest(actions.GET_CONTACT_BY_ORGANIZATION.REQUEST, getContactByOrganizationRequest)
  yield takeLatest(actions.ADD_PROJECT_SITE_DETAILS.REQUEST, addProjectSiteDetailsRequest)
  yield takeLatest(actions.GET_PROJECT_SITE_DETAILS.REQUEST, getProjectSiteDetailsRequest)
  yield takeLatest(actions.GET_PROJECT_SITE_LIST_REQUEST, getProjectSiteListRequest)
  yield takeLatest(actions.GET_PROJECT_SITE_VM_REQUEST, getProjectSiteVMRequest)
  yield takeLatest(actions.GET_SITE_UNIT_CHILD_GRID.REQUEST, getSiteUnitChildGridRequest)
  yield takeLatest(actions.UPDATE_PROJECT_SITE_DETAILS.REQUEST, updateProjectSiteDetailsRequest)
  yield takeLatest(actions.DELETE_PROJECT_SITE.REQUEST, deleteProjectSiteRequest)

}

export default projectSiteDetailsSagas
