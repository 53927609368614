import config from "./APIConfig"
import { postDataApi } from '../index'

export const updatePreTaskCheckList = (payload) => {
  const { url } = config.updatePreTaskCheckList

  return postDataApi(url(payload), payload.data)
}

export default updatePreTaskCheckList
