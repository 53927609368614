import { put, takeLatest, call } from "redux-saga/effects"

import {
  getAdvanceSearchFilterList,
  getMenuList,
  getUserEntityDDL,
  saveAdvanceSearchFilters,
  updateAdvanceSearchFilters,
  deleteAdvanceSearchFilters,
  getTimeZoneDDL
} from "@services/api"
import * as actions from "@store/actions"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { Notification } from '@views/components'

const { USER_MANAGEMENT_SAGA } = LANGUAGE_CONSTANTS

/** getMenuList API **/
function* getMenuListRequest() {
  try {
    yield put(actions.setMenuIsLoading(true))
    const response = yield call(getMenuList, {})
    if (response?.data) {
      yield put(actions.getMenuListSuccess(response.data))
      yield put(actions.setMenuIsLoading(false))
    } else {
      yield put(actions.getMenuListFailure("Unable to load menu list"))
      yield put(actions.setMenuIsLoading(false))
    }
  } catch (error) {
    yield put(actions.getMenuListFailure(error.message))
    yield put(actions.setMenuIsLoading(false))
  }
}

/** getUserEntityDDL API **/
function* getUserEntityDDLRequest(action) {
  try {
    const response = yield call(getUserEntityDDL, action.payload)
    if (response?.data) {
      action.callback(response.data)
    } else {
      action.callback()
      Notification({ status: 400, message: USER_MANAGEMENT_SAGA.UNABLE_TO_GET_ENTITY_DDL_LIST })
    }
  } catch (error) {
    action.callback()
    Notification({ status: 400, message: USER_MANAGEMENT_SAGA.UNABLE_TO_GET_ENTITY_DDL_LIST })
  }
}

/** getAdvanceSearchFilterList API **/
function* getAdvanceSearchFilterListRequest(action) {
  try {
    const response = yield call(getAdvanceSearchFilterList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** saveAdvanceSearchFilters API **/
function* saveAdvanceSearchFiltersRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(saveAdvanceSearchFilters, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 200) {
      Notification({ status: 200, message: USER_MANAGEMENT_SAGA.NEW_FILTER_SAVED_SUCCESS })
      action.callback(response.data)
    } else {
      Notification({ status: 400, message: USER_MANAGEMENT_SAGA.ERROR_IN_SAVING_NEW_FILTER })
      action.callback(false)
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: USER_MANAGEMENT_SAGA.ERROR_IN_SAVING_NEW_FILTER })
    action.callback()
  }
}

/** updateAdvanceSearchFilters API **/
function* updateAdvanceSearchFiltersRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateAdvanceSearchFilters, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      Notification({ status: 200, message: USER_MANAGEMENT_SAGA.FILTER_UPDATED_SUCCESS })
      action.callback(true)
    } else {
      Notification({ status: 400, message: USER_MANAGEMENT_SAGA.ERROR_IN_UPDATING_FILTER })
      action.callback(false)
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}


/** deleteAdvanceSearchFilters API **/
function* deleteAdvanceSearchFiltersRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteAdvanceSearchFilters, action.payload)
    if (response?.status === 204) {
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: USER_MANAGEMENT_SAGA.FILTER_DELETED_SUCCESS })
      action.callback(true)
    } else {
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: USER_MANAGEMENT_SAGA.ERROR_IN_DELETING_FILTER })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** getTimeZoneDDL API */
function* getTimeZoneDDLRequest(action) {
  try {
    const response = yield call(getTimeZoneDDL, action.payload)
    action.callback({ ...response.data, pageSize: action.payload.pageSize })
  } catch (error) {
    action.callback()
  }
}

export function* userManagementSagas() {
  yield takeLatest(actions.GET_ADVANCE_SEARCH_FILTER_LIST_REQUEST, getAdvanceSearchFilterListRequest)
  yield takeLatest(actions.GET_MENU_LIST.REQUEST, getMenuListRequest)
  yield takeLatest(actions.GET_USER_ENTITY_DDL_REQUEST, getUserEntityDDLRequest)
  yield takeLatest(actions.SAVE_ADVANCE_SEARCH_FILTERS_REQUEST, saveAdvanceSearchFiltersRequest)
  yield takeLatest(actions.UPDATE_ADVANCE_SEARCH_FILTERS_REQUEST, updateAdvanceSearchFiltersRequest)
  yield takeLatest(actions.DELETE_ADVANCE_SEARCH_FILTERS_REQUEST, deleteAdvanceSearchFiltersRequest)
  yield takeLatest(actions.GET_TIMEZONE_DDL_REQUEST, getTimeZoneDDLRequest)
}

export default userManagementSagas
