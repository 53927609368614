export const GET_WORKFLOW_REVIEW_DETAIL_REQUEST = 'GET_WORKFLOW_REVIEW_DETAIL_REQUEST'
export const GET_WORKFLOW_SUMMARY_REQUEST = 'GET_WORKFLOW_SUMMARY_REQUEST'

/****************************************************/
/** GET_WORKFLOW_REVIEW_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method getWorkflowReviewDetailRequest : To get workflow review details
 *
 */
export const getWorkflowReviewDetailRequest = (payload, callback = () => { }) => {
  return {
    type: GET_WORKFLOW_REVIEW_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_WORKFLOW_SUMMARY_REQUEST **/
/****************************************************/

/**
 * @method getWorkflowSummaryRequest : To get workflow summary
 *
 */
 export const getWorkflowSummaryRequest = (payload, callback = () => { }) => {
  return {
    type: GET_WORKFLOW_SUMMARY_REQUEST,
    payload,
    callback
  }
}
