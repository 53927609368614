import config from "./APIConfig"
import { postDataApi } from '../index'

export const assignSelfSchedule = (data) => {
    const { url } = config.assignSelfSchedule

    return postDataApi(url, data)
}

export default assignSelfSchedule
