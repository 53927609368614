import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method removeAssignment
 * 
 * @param payload 
 * 
 * {
    "observationTaskIds": [
     0
    ]
   }
 */
export const removeAssignment = (payload) => {
  const { url } = config.removeAssignment
  return putDataApi(url, payload)
}

export default removeAssignment
