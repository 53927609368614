import classnames from 'classnames'
import React, { useCallback } from "react"
import { Row, Col, FormGroup, Input } from "reactstrap"
import Button from "reactstrap/lib/Button"
import { Trash2 } from "react-feather"
import { ReactSortable } from "react-sortablejs"

import { LANGUAGE_TYPE } from '@shared/constants'
import { ErrorMessage, CustomLabel } from "@views/components"
import { otherChoiceOptionDetail, RESPONSE_CHOICE_TYPES } from "./index"
import { getSpecificLanguage, getLanguageText, getGivenTextLanguageList } from '../Config'
import ResponseDescription from "../ResponseDescription"
import ResponseFooter from "../ResponseFooter"

const ChoiceResponse = ({
  errors,
  isPrimary,
  activeLocaleTab,
  isEditResponseMode = false,
  isOtherOptionAdded,
  isSpanishVersion = false,
  responseDetail = {},
  responseTypeId = 0,
  onAddNewOption,
  onAddOtherOption,
  onCancelUpdate,
  onChangeDetail,
  onChangeChoiceText,
  onDeleteOption,
  onUpsertResponse,
  onSortList = () => { }
}) => {
  const isShowDeleteButton =
    !isSpanishVersion && responseDetail?.checkpointResponseQuestions?.length > 2

  const otherChoiceOptionValue = getSpecificLanguage(otherChoiceOptionDetail.options, activeLocaleTab)
  const isMultiSelect = responseTypeId === RESPONSE_CHOICE_TYPES.MULTI || responseTypeId === RESPONSE_CHOICE_TYPES.DDL_MULTI

  const handleSortList = useCallback((sortedList) => {
    if (isOtherOptionAdded && sortedList[sortedList.length - 1].options !== otherChoiceOptionValue) {
      return
    }
    const updatedSortedList = sortedList.map((item, index) => ({ ...item, orderBy: index + 1, options: getGivenTextLanguageList(item.options) })
    )
    if (updatedSortedList.length) {
      onSortList(updatedSortedList)
    }
  }, [isOtherOptionAdded, onSortList])

  return (
    <>
      {!isPrimary && <Row form>
        <Col className="pl-0" sm={12} md={6} xl={6}>
          <FormGroup>
            <CustomLabel
              title={getLanguageText(activeLocaleTab, "RESPONSE_NAME")}
              isLocalizedTitle={false}
            />
            <Input
              type="text"
              name="responseName"
              placeholder={getLanguageText(activeLocaleTab, "RESPONSE_NAME")}
              value={responseDetail?.responseName}
              onChange={(event) => onChangeDetail(event.target.name, event.target.value)}
            />
          </FormGroup>
          <ErrorMessage
            isShow={!!errors["responseName"]}
            message={errors["responseName"]}
          />
        </Col>
        <ResponseDescription id="responseName" value={responseDetail?.description} onChangeDetail={onChangeDetail} />
      </Row>}
      <ReactSortable
        list={responseDetail?.checkpointResponseQuestions || []}
        setList={handleSortList}
      >
        {responseDetail?.checkpointResponseQuestions?.map((option, index) => {
          return (
            <Row key={`choice_response_${index}`}>
              <Col>
                <div className="d-flex align-items-center mb-1">
                  <Input
                    type={isMultiSelect ? "checkbox" : "radio"}
                    id={`choice_option_${index}_id`}
                    className="radio-btn"
                    label="Full Width"
                    disabled
                  />
                  <Col className={classnames("ml-2", {
                    "option-response-locked": isSpanishVersion
                  })} sm={12} md={5} xl={3}>
                    <Input
                      type="text"
                      id={`choice_option_input_${index}_id`}
                      placeholder={`Choice ${index + 1}`}
                      disabled={isSpanishVersion || (index === responseDetail.checkpointResponseQuestions.length - 1 && option.options === otherChoiceOptionValue)}
                      value={isSpanishVersion ? option.optionsEnglish : option.options}
                      onChange={(e) => onChangeChoiceText(e, index)}
                    />
                  </Col>
                  {isSpanishVersion && <Col className={"ml-2"} sm={12} md={3} xl={3}>
                    <Input
                      type="text"
                      id={`choice_spanish_option_input_${index}_id`}
                      placeholder={getLanguageText(LANGUAGE_TYPE.SPANISH, "BOOLEAN_OPTION_PLACEHOLDER")}
                      disabled={index === responseDetail.checkpointResponseQuestions.length - 1 && option.options === otherChoiceOptionValue}
                      value={option.options}
                      onChange={(e) => onChangeChoiceText(e, index)}
                    />
                  </Col>}
                  {isShowDeleteButton && (
                    <Trash2
                      className="edit"
                      onClick={() => onDeleteOption(index)}
                    />
                  )}
                </div>
                <ErrorMessage
                  isShow={!!errors[`responses_${index}`]}
                  message={errors[`responses_${index}`]}
                />
              </Col>
            </Row>
          )
        })}
      </ReactSortable>
      {
        !isSpanishVersion && <Row>
          <Col className="mt-2" md={12}>
            <Button
              type="button"
              color="btn waves-effect advanced-btn template-add-btn"
              onClick={onAddNewOption}
            >
              + Add Option
            </Button>
            <Button
              type="button"
              color="btn waves-effect advanced-btn template-add-btn"
              disabled={isOtherOptionAdded}
              onClick={onAddOtherOption}
            >
              Add "Other" Option
            </Button>
          </Col>
        </Row>
      }
      <ResponseFooter
        activeLocaleTab={activeLocaleTab}
        isEditResponseMode={isEditResponseMode}
        isSpanishVersion={isSpanishVersion}
        responseDetail={responseDetail}
        onCancel={onCancelUpdate}
        onChangeDetail={onChangeDetail}
        onUpsertResponse={onUpsertResponse}
      />
    </>
  )
}

export default ChoiceResponse
