import config from "./APIConfig"
import { getDataApi } from '../../index'

export const getWorkflowReviewDetail = async (data) => {
  const { url } = config.getWorkflowReviewDetail
  return await getDataApi(url(data))
}

export const getWorkflowSummary = async (data) => {
  const { url } = config.getWorkflowSummary
  return await getDataApi(url(data))
}
