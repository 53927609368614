import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method cloneCampaign
 *
 */
export const cloneCampaign = (data) => {
  const { url } = config.cloneCampaign
  return postDataApi(url(data))
}

export default cloneCampaign
