import config from "./APIConfig"
import { getDataApi } from "../index"

export const getActivityList = async (data) => {
  const { url } = config.getActivityList
  return await getDataApi(url(data))
}

export const getActivityVM = async () => {
  const { url } = config.getActivityVM
  return await getDataApi(url)
}
