export const GET_INTERCOM_SECURE_HMAC_REQUEST = 'GET_INTERCOM_SECURE_HMAC_REQUEST'

/****************************************************/
/** GET_INTERCOM_SECURE_HMAC_REQUEST **/
/****************************************************/

/**
 * @method getIntercomSecureHmacRequest :  To get hmac secure code for intercom
 *
 */
export const getIntercomSecureHmacRequest = (payload, callback = () => { }) => {
    return {
      type: GET_INTERCOM_SECURE_HMAC_REQUEST,
      payload,
      callback
    }
  }