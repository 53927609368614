import PropTypes from 'prop-types'
import { useMemo } from 'react'
import ReactPaginate from 'react-paginate'

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"

const CustomPagination = ({
  intl,
  isNewListTheme,
  pageNumber,
  totalPages,
  onPageChange
}) => {
  const paginationConfig = useMemo(() => {
    if (isNewListTheme) {
      return {
        previousLabel: getLocaleMessage(intl, LANGUAGE_CONSTANTS.PREVIOUS),
        nextLabel: getLocaleMessage(intl, LANGUAGE_CONSTANTS.NEXT),
        containerClassName: "pagination pagination-new"
      }
    }

    return {
      previousLabel: '',
      nextLabel: '',
      containerClassName: 'pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1'
    }
  }, [isNewListTheme])
  return (
    <ReactPaginate     
      forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
      onPageChange={onPageChange}
      pageCount={totalPages}
      breakLabel='...'
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      activeClassName={'active'}
      pageClassName={'page-item'}
      breakClassName={'page-item'}
      breakLinkClassName={'page-link'}
      nextLinkClassName={'page-link'}
      nextClassName={'page-item next'}
      previousClassName={'page-item prev'}
      previousLinkClassName={'page-link'}
      pageLinkClassName={'page-link'}
      {...paginationConfig}
    />
  )
}

CustomPagination.propTypes = {
  pageNumber: PropTypes.number,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func
}

export default CustomPagination
