import config from "./APIConfig"
import { putDataApi } from '../index'

export const updateWorkOrder = (data) => {
  const { url } = config.updateWorkOrder

  return putDataApi(url(data.observationTaskId), data)
}

export default updateWorkOrder
