import React, { useState, useCallback, useEffect } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import * as actions from "@store/actions"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"
import localeMessageWrapper from '@views/components/locale-message'

const { ACTIVITIES } = LANGUAGE_CONSTANTS

const SubComponentSearch = ({
  intl,
  errors,
  value = "",
  componentId = [],
  isViewMode = false,
  id = "subComponent",
  onChangeCheckpointCollection
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState([])
  const [isResetCache, setResetCache] = useState(false)

  useEffect(() => {
    setResetCache((prevState) => !prevState)
    if (!componentId.length) {
      setOptions([])
    }
  }, [componentId])


  const handleLoadMoreSubComponent = useCallback(
    (search, page, prevOptions, others) => {
      return new Promise((resolve) => {
        if (others.isReset && !prevOptions.length && options.length) {
          resolve({
            optionList: options,
            hasMore: true
          })
          return
        }
        const params = {
          componentId,
          entityId: 1,
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          subComponentName: search
        }
        dispatch(
          actions.getTemplateSubComponentListRequest(params, (response) => {
            if (response) {
              const { items, ...paginationData } = response
              resolve({
                optionList: items,
                hasMore: paginationData.hasNextPage
              })
              setOptions(() => (!!prevOptions.length ? [...prevOptions, ...items] : items))
            } else {
              resolve({
                optionList: [],
                hasMore: false
              })
            }
          })
        )
      })
    },
    [isResetCache, options, componentId, value]
  )
  const handleSelect = useCallback((id, selected) => {
    const lastElement = selected.length
      ? selected[selected.length - 1]
      : null
    onChangeCheckpointCollection(id, lastElement?.subComponentName !== "— Select —" ? selected : [])
  }, [onChangeCheckpointCollection])

  return (
    <Col className="mb-1" sm={12} md={6} xl={4}>
      <CustomLabel title={ACTIVITIES.SUB_COMPONENT} />
      <CustomReactSelect
        additional={{
          page: 1
        }}
        isDisabled={!componentId.length}
        cacheUniqs={[isResetCache]}
        id="sub_component"
        name="sub_component"
        placeholder={getLocaleMessage(intl, ACTIVITIES.SELECT_SUB_COMPONENT)}
        options={options}
        defaultOptions={options}
        lableType={"subComponentName"}
        valueType={"subComponentId"}
        value={value}
        isMulti
        isShowCustomAction={false}
        hideSelectedOptions={false}
        onSelect={(selected) => handleSelect(id, selected)}
        onLoadOptions={handleLoadMoreSubComponent}
        getOptionLabel={(option) => option.subComponentName}
        getOptionValue={(option) => option.subComponentId}
      />
      {/* {errors && (
        <ErrorMessage
          isShow={!!errors["subComponent"]}
          message={errors["subComponent"]}
        />
      )} */}
    </Col>
  )
}

export default localeMessageWrapper(SubComponentSearch)
