import { takeLatest, call, put } from "redux-saga/effects"

import { getCommunicationLogList, getCommunicationLogNotificationDetail, resendMessageNotification, getCommunicationLogVM } from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"

const { COMMUNICATION_LOGS: { COMMUNICATION_LOG_SAGA } } = LANGUAGE_CONSTANTS

/** getCommunicationLogList API **/
function* getCommunicationLogListRequest(action) {
    try {
      const response = yield call(getCommunicationLogList, action.payload)
      if (response?.data) {
        action.callback({ ...response.data, pageSize: action.payload.pageSize })
      } else {
        action.callback()
      }
    } catch (error) {
      action.callback()
    }
  }

/** getCommunicationLogNotificationDetail API **/
function* getCommunicationLogNotificationDetailRequest(action) {
  try {
    const response = yield call(getCommunicationLogNotificationDetail, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
} 

/** resendMessageNotification API **/
function* resendMessageNotificationRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(resendMessageNotification, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: COMMUNICATION_LOG_SAGA.MESSAGE_SENT_SUCCESSFULLY })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: COMMUNICATION_LOG_SAGA.ERROR_IN_SENDING_MESSAGE })
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
} 

/** getCommunicationLogVM API **/
function* getCommunicationLogVMRequest(action) {
  try {
    const response = yield call(getCommunicationLogVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
} 

export function* communicationLogSagas() {
    yield takeLatest(actions.GET_COMMUNICATION_LOG_LIST_REQUEST, getCommunicationLogListRequest)
    yield takeLatest(actions.GET_COMMUNICATION_LOG_NOTIFICATION_DETAILS_REQUEST, getCommunicationLogNotificationDetailRequest)
    yield takeLatest(actions.RESEND_MESSAGE_NOTIFICATION_REQUEST, resendMessageNotificationRequest)
    yield takeLatest(actions.GET_COMMUNICATION_LOG_VM_REQUEST, getCommunicationLogVMRequest)
}
  
export default communicationLogSagas