import config from "./APIConfig"
import { getDataApi } from "../../index"

export const addCampaignWorkflowVM = async (data) => {
  const { url } = config.addCampaignWorkflowVM

  return await getDataApi(url(data.campaignId))
}

export default addCampaignWorkflowVM
