// ** React Imports
import { forwardRef } from 'react'

const CustomCheckbox = forwardRef(({ onClick, ...rest }, ref) => (
  <div className=' custom-control custom-checkbox'>
    <input type='checkbox' className='custom-control-input' ref={ref} {...rest} />
    <label className='custom-control-label' onClick={onClick} />
  </div>
))


export default CustomCheckbox
