export const EXPANDABLE_TYPE = {
  PLANS: "plans",
  TABLE: "table",
  UNITS: "units",
  SITE_UNITS_TABLE: "site_units_table",
  SERVICE_CHECKLIST_TABLE: "service_checklist_table",
  SCHEDULING_UNITS: "scheduling_units",
  CHECKPOINTS: "checkpoints",
  TEMPLATES_SEARCH: "templates_search",
  CHECKLIST_CHECKPOINTS: "checklist_checkpoints",
  CAMPAIGN_SERVICE_LIST: "campaign_service_list",
  CAMPAIGN_SERVICE_CHECKLIST: "campaign_service_checklist",
  WORKFLOW_ASSIGN_ACTIVITIES_SITE_ROLE: "workflow_assign_activities_site_role",
  UNACCEPTABLE_ACTIVITIES: "unacceptable_activities",
  PROGRAMS_REPORT_LIST: "program_report_list",
  SITE_UNACCEPTABLE_ACTIVITIES_TRAINING_REPORT: "site_unacceptable_activities_training_report",
  GENERAL_UNACCEPTABLE_ACTIVITIES: "general_unacceptable_activities",
  FAILED_ACTIVITIES_TRAINING_REPORT: "failed_activities_training_report",
  TRAINING_CAMPAIGN_REPORT: "training_campaign_report",
  UNIT_CLOSEOUT_REPORT: "unit_closeout_report",
  CONTRACTS_ORDER_DETAILS: "contract_order_details",
  ORGANISATION_PLAN_LIST: "organisation_plan_list",
  ORGANISATION_PLAN_BLUEPRINT_LIST: "organisation_plan_blueprint_list"
}

export const TABLE_VIEW_ACTION_TYPE = {
  SAVE_CLOSE: "Save & Close",
  SAVE_CONTINUE: "Save & Continue"
}

export const TABLE_VIEW_ACTION_CONFIG = {
  id: 1,
  label: TABLE_VIEW_ACTION_TYPE.SAVE_CONTINUE,
  onClick: () => { }
}

export const getTableViewActionConfig = (action) => {
  return [
    {
      ...TABLE_VIEW_ACTION_CONFIG,
      onClick: () => {
        if (action) {
          action(true)
        }
      }
    }
  ]
}

export const customStyles = {
  rows: {
    selectedHighlightStyle: {
      background: '#c7d3d9 !important'
    }
  }
}

export const defaultProps = {
  defaultPropsListHeaderProps: {
    isExportRequired: false,
    isListTitleRequired: true,
    maxSelectedCount: 0,
    isPageSizeFilter: true
  }
}

export const VIEWS_OPTION_TYPES = {
  SELECT_VIEW: -2,
  DEFAULT: -1
}

export const SELECT_VIEWS_OPTION = {
  advanceSearchFilterId: VIEWS_OPTION_TYPES.SELECT_VIEW,
  searchFilterName: "-Select View-"
}
