import config from "./APIConfig"
import { putDataApi } from '../../index'

export const updateFindingsCorrection = (data) => {
  const { url } = config.updateFindingsCorrection
  const { correctionDetails } = data

  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    requireTradeCorrection: Number(correctionDetails.requireTradeCorrection) === 1,
    requireVerification: Number(correctionDetails.requireVerification) === 1
  }

  return putDataApi(url(data.wfCampaignSetupId), payload)
}

export default updateFindingsCorrection
