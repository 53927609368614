export const GET_ACTIVITY_LIST_REQUEST = 'GET_ACTIVITY_LIST_REQUEST'
export const GET_ACTIVITY_VM_REQUEST = 'GET_ACTIVITY_VM_REQUEST'


/****************************************************/
/** GET_ACTIVITY_LIST_REQUEST **/
/****************************************************/

/**
 * @method getActivityListRequest :  To get activities list
 *
 */
export const getActivityListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ACTIVITY_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTIVITY_VM_REQUEST **/
/****************************************************/

/**
 * @method getActivityVMRequest : To get dropdowns list for activities setup/search
 *
 */
export const getActivityVMRequest = (callback = () => { }) => {
  return {
    type: GET_ACTIVITY_VM_REQUEST,
    callback
  }
}