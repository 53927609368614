import { useCallback, useContext, useMemo, useState } from 'react'

import RowActionsComponent from "@views/components/table-view/row-actions"
import { ROW_ACTION_TYPES, rowActionItems } from './config'
import { EnergyModelContext } from './EnergyModelContext'
import EnergyModelsModel from "./Modal"

const RowActions = ({
  data
}) => {
  const context = useContext(EnergyModelContext)
  const [isOpenModel, setOpenModel] = useState(false)
  const [modelConfig, setModelConfig] = useState(null)

  const handleViewFiles = useCallback(() => {
    setOpenModel(true)
    setModelConfig({
      type: ROW_ACTION_TYPES.VIEW_FILES,
      data: {
        energyModelName: data.energyModelName,
        organizationEnergyModelId: data.energyModelId,
        organizationId: context.organizationId,
        organizationPlanId: context.organizationPlanId,
        organizationBluePrintId: context.organizationBluePrintId
      }
    })
  }, [context, data])

  const handleCloseModel = useCallback(() => {
    setOpenModel(false)
    setModelConfig(null)
  }, [])

  const handleClickActionItem = useCallback(
    (id) => {
      if (id === ROW_ACTION_TYPES.VIEW_FILES) {
        handleViewFiles()
      }
    },
    [handleViewFiles]
  )

  const config = useMemo(() => {
    const actionTypes = [ROW_ACTION_TYPES.VIEW_FILES]
    return {
      actionTypes,
      rowActionItems
    }
  }, [])

  return (
    <>
      <RowActionsComponent
        config={config}
        rowId={data.organizationBluePrintId}
        onClickActionItem={handleClickActionItem}
      />
      {isOpenModel && <EnergyModelsModel config={modelConfig} onClose={handleCloseModel} />}
    </>
  )
}


export default RowActions
