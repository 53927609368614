import React, { useCallback, useEffect, useState, useMemo } from "react"
import { useForm } from 'react-hook-form'
import {
  Button,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from "reactstrap"
import { useDispatch } from "react-redux"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomLabel, ReactSelect } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import { getReactSelectPositionProps } from "@views/helpers"
import { MAX_NAME_LENGTH, RESOURCE_CENTER_SOURCE_TYPE_VM } from "./config"
import { getFileCurrentName, getFileExtension, validateFileRenameData } from "./helpers"
import PropTypes from 'prop-types'
const { CANCEL, RESOURCES } = LANGUAGE_CONSTANTS

const DocumentRenameModal = ({
  title = "",
  actualFileName = "",
  resourceCenterId,
  /**
   * fileLocationType: Should be key of type config.RESOURCE_CENTER_SOURCE_TYPES
   */
  fileLocationType = "",
  fileSourceId = "",
  intl,
  fileCategory = false,
  fileCategoryId = '',
  fileDescription = '',
  onClose = () => { },
  onRefreshResourceList = () => { }
}) => {
  const dispatch = useDispatch()
  const { control } = useForm({})
  const [fileExtension, setFileExtension] = useState(getFileExtension(title, actualFileName) || "")
  const [fileExtensionLength, setFileExtensionLength] = useState(0)
  const [fileCategoryListVM, setFileCategoryListVM] = useState([])
  const [customFileDetail, setCustomFileDetail] = useState({
    fileName: getFileCurrentName(title, actualFileName) || "",
    fileCategoryId: String(fileCategoryId),
    fileDescription: fileDescription || ""
  })

  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (!!fileExtension) {
      setFileExtensionLength(fileExtension.length)
    }
  }, [fileExtension])

  const handleChangeDetails = useCallback((
    key, value
  ) => {
    if (Object.keys(errors)) {
      setErrors({})
    }

    if (key === 'fileName' && value.trim().length > (MAX_NAME_LENGTH - fileExtensionLength)) {
      return
    }
    const updatedFile = Object.assign({}, customFileDetail)
    updatedFile[key] = value
    setCustomFileDetail(updatedFile)
  }, [errors, fileExtensionLength])

  const handleRenameDocument = useCallback(async () => {
    const fileName = customFileDetail.fileName
    const errors = validateFileRenameData({ intl, fileName })
    if (!!Object.keys(errors).length) {
      setErrors(errors)
      return
    }

    const sourceKeyName =
      RESOURCE_CENTER_SOURCE_TYPE_VM[fileLocationType]?.sourceKeyName || ""

    try {
      const payload = {
        fileLocationType,
        title: `${fileName}${fileExtension || ''}`,
        fileSourceName: sourceKeyName,
        fileSourceId,
        resourceCenterId,
        fileCategoryId: customFileDetail.fileCategoryId,
        fileDescription: customFileDetail.fileDescription
      }

      dispatch(
        actions.renameRCDocumentRequest(payload, (data) => {
          if (data) {
            setTimeout(() => {
              onRefreshResourceList()
            }, 1000)
            setCustomFileDetail({
              fileName: '',
              fileCategoryId: '',
              fileDescription: ''
            })
            setFileExtension("")
            onClose()
          }
        })
      )
    } catch (err) { }
  }, [
    customFileDetail.fileName,
    customFileDetail.fileCategoryId,
    customFileDetail.fileDescription,
    fileLocationType,
    fileSourceId,
    resourceCenterId,
    onRefreshResourceList,
    onClose
  ])

  const reactSelectPositionProps = useMemo(() => {
    return getReactSelectPositionProps()
  }, [])

  useEffect(() => {
    if (fileCategory) {
      dispatch(
        actions.getResourceCenterFileCategoryDDLRequest({}, (data) => {
          if (data) {
            setFileCategoryListVM(data)
          }
        })
      )
    }
  }, [fileCategory])

  return (
    <Modal isOpen className="modal-dialog-centered all-modal xs-modal">
      <ModalBody className="p-0">
        <h3>{getLocaleMessage(intl, RESOURCES.EDIT_DOCUMENT_DETAILS)}</h3>
        <Row form className="file-details mb-2">
          <Col className="" sm={12} md={12} xl={12}>
            <FormGroup className="doc-ext-wrap mb-0">
              <CustomLabel title={RESOURCES.TITLE} /><span className="text-danger">*</span>
              <Input
                type="text"
                name="fileName"
                placeholder={getLocaleMessage(intl, RESOURCES.TITLE)}
                value={customFileDetail.fileName}
                onChange={(event) => handleChangeDetails("fileName", event.target.value)}
              />
              <CustomLabel title={fileExtension} className='doc-ext' isLocalizedTitle={false} />
            </FormGroup>
            <ErrorMessage
              isShow={!!errors["fileName"]}
              message={errors["fileName"]}
            />
          </Col>
          <Col sm={12} md={12} xl={12}>
            <FormGroup className="doc-ext-wrap mb-0 file-details-inner file-category">
                <CustomLabel title={RESOURCES.NOTES} />
                <div className="ml-2 w-100">
                <Input 
                  type="textarea"
                  name="fileDescription"
                  placeholder={getLocaleMessage(intl, RESOURCES.NOTES)}
                  value={customFileDetail.fileDescription}
                  onChange={(e) => handleChangeDetails("fileDescription", e.target.value)}
                />
                </div>
            </FormGroup>
          </Col>
          {fileCategory && <Col>
            <FormGroup className="doc-ext-wrap mb-0 file-details-inner file-category">
              <CustomLabel title={RESOURCES.CATEGORY} />
              <div className="w-100">
                <ReactSelect
                  isRequired
                  id='fileCategory'
                  name='fileCategory'
                  control={control}
                  value={customFileDetail.fileCategoryId}
                  placeHolder={getLocaleMessage(intl, RESOURCES.SELECT_FILE_CATEGORY)}
                  options={fileCategoryListVM}
                  onSelect={(selected) => handleChangeDetails('fileCategoryId', selected?.value || '')}
                  getOptionLabel={(item) => item.text}
                  getOptionValue={(item) => item.value}
                  {...reactSelectPositionProps}
                />
              </div>
            </FormGroup>
          </Col>}
        </Row>
      </ModalBody>
      <ModalFooter className="justify-content-center border-0 p-0">
        <Button outline className="delete-btn mr-1 " onClick={onClose}>
          {getLocaleMessage(intl, CANCEL)}
        </Button>
        <Button className="bg-btn" onClick={handleRenameDocument}>
          {getLocaleMessage(intl, RESOURCES.TABLE.UPDATE)}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

DocumentRenameModal.propTypes = {
  title: PropTypes.string,
  actualFileName: PropTypes.string,
  resourceCenterId: PropTypes.number,
  fileLocationType: PropTypes.string,
  fileSourceId: PropTypes.number,
  intl: PropTypes.object,
  onClose: PropTypes.func,
  onRefreshResourceList: PropTypes.func
}

export default localeMessageWrapper(DocumentRenameModal)
