import { takeLatest, call, put } from "redux-saga/effects"

import * as actions from "../../actions"
import { getGlobalSearchList, getGlobalSearchVM } from "@services/api"

/** getGlobalSearchList API **/
function* getGlobalSearchListRequest({ payload = {}, callback = () => {} }) {
  try {
    if (payload.isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getGlobalSearchList, payload)
    if (response?.data) {
      yield put(actions.getGlobalSearchListSuccess({ results: response.data, pageNumber: payload.pageNumber }))
    } else {
      yield put(actions.getGlobalSearchListFailure("Unable to get global search"))
    }
    callback()
    if (payload.isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    yield put(actions.getGlobalSearchListFailure(error.message))
    callback()
    if (payload.isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  }
}

function* getGlobalSearchVMRequest(action) {
  try {
    const response = yield call(getGlobalSearchVM)
    action.callback(response.data)
  } catch (error) {
    action.callback()
  }
}

export function* globalSearchSagas() {
  yield takeLatest(actions.GET_GLOBAL_SEARCH_LIST.REQUEST, getGlobalSearchListRequest)
  yield takeLatest(actions.GET_GLOBAL_SEARCH_VM_REQUEST, getGlobalSearchVMRequest)
}

export default globalSearchSagas
