import { ACTIVITY_TYPE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import FailedActivityDetails from "@views/actions/components/activities-list/ActivityDetail"
import { LocaleMessage } from '@views/components'
import ExpandableObservationActivityChildDetail from './ExpandableObservationActivityChildDetail'
import ExpandableTrainingActivityChildDetail from './ExpandableTrainingActivityChildDetail'

const { NO_DATA_FOUND, LOADER } = LANGUAGE_CONSTANTS

const ActivityChildDetail = ({isLoadingChildGrid, subData, data, noDataMessage }) => {

  if (isLoadingChildGrid) {
    return <p className="no-data-txt"><LocaleMessage id={LOADER.LOADING} /></p>
  }

  if (!subData) {
    return <p className="no-data-txt">{noDataMessage}</p>
  }

  if (subData.isShowActionsFailedActivityDetail) {
    return <FailedActivityDetails selectedGrid={subData} />
  }

  if (data.activityTypeId === Number(ACTIVITY_TYPE.OBSERVATION)) {
    return <ExpandableObservationActivityChildDetail selectedGrid={subData} />
  }

  if (data.activityTypeId === Number(ACTIVITY_TYPE.TRAINING)) {
    return <ExpandableTrainingActivityChildDetail selectedGrid={subData} />
  }

  return <p className="no-data-txt"><LocaleMessage id={NO_DATA_FOUND} /></p>
}

export default ActivityChildDetail
