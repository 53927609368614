import cx from "classnames"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Input,
    FormGroup
} from "reactstrap"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { CustomLabel } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const { ACTIVITIES: { REPORT_TAG, ADD_REPORT_TAG }, LOADER } = LANGUAGE_CONSTANTS

const ReportTagSearch = ({
    intl,
    id = "reportTag",
    value = "",
    onSelect = () => { }
}) => {
    const dispatch = useDispatch()

    const initialPaginationData = useMemo(() => ({
        pageNumber: 0,
        hasNextPage: false
    }), [])

    const [options, setOptions] = useState([])
    const [paginationData, setPaginationData] = useState(initialPaginationData)
    const [isOpenDropdown, setOpenDropdown] = useState(false)
    const [isFetching, setFetching] = useState(false)
    const [previousScroll, setPreviousScroll] = useState(0)
    const [typingTimeout, setTypingTimeout] = useState(null)

    const handleLoadOptions = useCallback((data) => {
        const {
            page = 1,
            search,
            isLoadMore = false,
            callback = () => { }
        } = data || {}

        const params = {
            pageNumber: page,
            pageSize: DEFAULT_VM_PAGE_SIZE,
            reportTagName: search
        }

        setFetching(true)
        dispatch(
            actions.getReportTagDDLRequest(params, (response) => {
                if (response) {
                    setOptions(isLoadMore ? [...options, ...response.items] : response.items)
                    setPaginationData({
                        pageNumber: page,
                        hasNextPage: response.hasNextPage
                    })
                } else {
                    if (!isLoadMore) {
                        setOptions([])
                        setPaginationData(initialPaginationData)
                    }
                }
                setFetching(false)
                callback()
                setTypingTimeout(null)
            })
        )
    }, [options, value])

    useEffect(() => {
        handleLoadOptions()
    }, [])

    const handleSelect = useCallback(
        (event, selected) => {
            event.preventDefault()
            event.stopPropagation()
            onSelect(id, selected?.value || "")
            setOpenDropdown(false)
        },
        [onSelect, id]
    )

    const handleFilter = useCallback(
        (value) => {
            if (typingTimeout) {
                clearTimeout(typingTimeout)
            }

            setTypingTimeout(
                setTimeout(() => {
                    setOptions([])
                    setPaginationData(initialPaginationData)
                    handleLoadOptions({ page: 1, search: value })
                }, 1000)
            )
        },
        [handleLoadOptions]
    )

    const handleChange = useCallback(
        (event) => {
            const newValue = event.target.value
            onSelect(id, newValue)
            handleFilter(newValue)
        },
        [id, onSelect, handleFilter]
    )

    const handleLoadNextPage = useCallback(() => {
        if (!isFetching && paginationData.hasNextPage) {
            const callback = () => {
                setPreviousScroll(prevState => prevState - 100)
            }
            handleLoadOptions({ page: paginationData.pageNumber + 1, isLoadMore: true, callback })
        }
    }, [isFetching, paginationData.pageNumber, previousScroll, paginationData.hasNextPage, handleLoadOptions])

    const handleScroll = useCallback((event) => {
        const target = event.target
        if (!!target) {
            if (
                previousScroll < target.scrollTop &&
                target.scrollTop + target.clientHeight >= target.scrollHeight - 1
            ) {
                handleLoadNextPage()
            }
            setPreviousScroll(target.scrollTop)
        }
    }, [previousScroll, handleLoadNextPage])

    const handleFocusInput = useCallback((event) => {
        event.preventDefault()
        if (!isOpenDropdown) {
            setOpenDropdown(true)
        }
    }, [isOpenDropdown])

    const handleCloseSuggestionList = useCallback(() => {
        setOpenDropdown(false)
    }, [])

    return (
        <>
            {isOpenDropdown && <div className="suggestion-menu-overlay" onClick={handleCloseSuggestionList} />}
            <Dropdown className={cx("suggestion-menu-dropdown", {
                "is-open": isOpenDropdown
            })} 
            isOpen={isOpenDropdown} 
            toggle={() => { }}>
                <FormGroup>
                    <CustomLabel
                        title={getLocaleMessage(intl, REPORT_TAG)}
                    />
                    <Input
                        type="text"
                        name={id}
                        placeholder={getLocaleMessage(intl, ADD_REPORT_TAG)}
                        value={value}
                        onFocus={handleFocusInput}
                        onChange={handleChange}
                    />
                </FormGroup>
                {!!options.length && <DropdownMenu id={"report_tag_options"} center="true" onScroll={handleScroll} className="suggestion-menu" style={{ maxHeight: "250px", overflow: "scroll" }}>
                    {options.map((item) => {
                        return (
                            <DropdownItem key={`report-tag-suggesstion-${item.value}`} tag="a" className='w-100' onClick={(e) => handleSelect(e, item)}>
                                <span className="align-middle ml-50" >{item.text}</span>
                            </DropdownItem>
                        )
                    })}
                    {isFetching && <DropdownItem>{getLocaleMessage(intl, LOADER.LOADING)}</DropdownItem>}
                </DropdownMenu>}
            </Dropdown>
        </>
    )
}

export default localeMessageWrapper(ReportTagSearch)
