import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { validateEmail } from '@utils'
import { LocaleMessage } from '@views/components/locale-message'

const {
  OVERVIEW_PAGE_SECTIONS: { DIRECTORY_SECTION: { ORGANIZATION_DIRECTORY } },
  CONTACTS
} = LANGUAGE_CONSTANTS

export const validateContactDetails = ({
  isExternalDirectory,
  firstName,
  lastName,
  email,
  phoneNumber,
  sitePartyRoleId,
  systemAccessId,
  roleId,
  contact,
  organizationID
}, isDirectoryView, isOrganizationDirectoryList) => {
  const errors = {}

    if (!firstName.trim().length) {
      errors['firstName'] = <LocaleMessage id={CONTACTS.ERRORS.FIRST_NAME} />
    }
    if (!lastName.trim().length) {
      errors['lastName'] = <LocaleMessage id={CONTACTS.ERRORS.LAST_NAME} />
    }
  
    if (!email.trim().length) {
      errors['email'] = <LocaleMessage id={CONTACTS.ERRORS.EMAIL} />
    } else if (!validateEmail(email)) {
      errors['email'] = <LocaleMessage id={CONTACTS.ERRORS.INVALID_EMAIL} />
    }
  
    const phone = (phoneNumber || "").trim()
    const phoneRegex = /^(1\s?)?(\(\d{3}\)|[1-9]\d{2})[\s\-]?\d{3}[\s\-]?\d{4}$/
    if (phone.length) {
      if (phone === '+') {
        errors['phoneNumber'] = <LocaleMessage id={CONTACTS.ERRORS.INVALID_PHONE} />
      } else if (phone[0] === '+') {
        const value = phone.slice(1)
        if (!value.match(phoneRegex)) {
          errors['phoneNumber'] = <LocaleMessage id={CONTACTS.ERRORS.INVALID_PHONE} />
        }
      } else {
        if (!phone.match(phoneRegex)) {
          errors['phoneNumber'] = <LocaleMessage id={CONTACTS.ERRORS.INVALID_PHONE} />
        }
      }
    } else {
      errors['phoneNumber'] = <LocaleMessage id={CONTACTS.ERRORS.PHONE} />
    }
    if (!sitePartyRoleId) {
      errors['sitePartyRoleId'] = <LocaleMessage id={ORGANIZATION_DIRECTORY.ERRORS.ORG_ROLE} />
    }

    if (!systemAccessId) {
      errors['systemAccessId'] = <LocaleMessage id={ORGANIZATION_DIRECTORY.ERRORS.SYSTEM_ACCESS} />
    }

    if (!isDirectoryView && !organizationID && !isOrganizationDirectoryList) {
      errors['organizationID'] = <LocaleMessage id={ORGANIZATION_DIRECTORY.ERRORS.ORGANIZATION} />
    }

  return errors
}
