import React, { useState, useCallback, useMemo, useEffect } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  InputGroup,
  Input,
  Row,
  Col
} from "reactstrap"
import { ChevronRight } from "react-feather"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import {
  TABLE_LIST_KEYS,
  TAG_SOURCE_TYPE,
  DEFAULT_VM_TAG_PAGE_SIZE
} from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage, validatePhoneNumber } from "@utils"
import {
  CustomLabel,
  CustomUILoader,
  ErrorMessage,
  ReactSelect,
  SideModalCloseButton,
  SiteRoleSearch,
  OrganizationSearch,
  TagsSearch,
  PlacesAutocomplete
} from "@views/components"
import { getCompleteAddress } from "@views/helpers"
import localeMessageWrapper from "@views/components/locale-message"
import { TAG_TYPES } from "@views/organizations/add-new-organization/contacts/details/config"
import { validateContactDetails } from "./helpers"
import { DIRECTORY_TYPES } from "../../config"

const {
  OVERVIEW_PAGE_SECTIONS: {
    DIRECTORY_SECTION: { ORGANIZATION_DIRECTORY }
  },
  CONTACTS,
  ADDRESS,
  SAVE,
  UPDATE,
  CANCEL,
  TAG,
  SELECT_TAG,
  SELECT
} = LANGUAGE_CONSTANTS

const getInitialContactSearchDetails = (defaultCountryCode) => ({
  firstName: "",
  lastName: "",
  email: "",
  title: "",
  phoneNumber: "",
  systemAccessId: "",
  sitePartyRoleId: "",
  organizationID: "",
  organizationName: "",
  originalOrganizationId: "",
  streetAddress: "",
  city: "",
  state: "",
  postCode: "",
  countryCode: defaultCountryCode ? defaultCountryCode : "",
  countryName: ""
})

const AddNewContactModal = ({
  intl,
  organizationName,
  organizationId,
  contactId,
  isDirectoryView = true,
  isOrganizationDirectoryList = false,
  isSiteDirectoryList = false,
  isDDLContactView = false,
  selectedRowData = {},
  onToggleModal = () => {},
  onUpdateContactCallback = () => {}
}) => {
  const dispatch = useDispatch()
  const { control } = useForm()

  const { addressStore } = useSelector((state) => {
    return {
      addressStore: state.address
    }
  })

  const [defaultOptions] = useState([])
  const [contactDetail, setContactDetail] = useState(Object.assign({}, getInitialContactSearchDetails(addressStore.defaultCountryCode)))
  const [isEditMode, setEditMode] = useState(false)
  const [isFetching, setFetching] = useState(false)
  const [errors, setErrors] = useState({})
  const [accountAccessVM, setAccountAccessVM] = useState([])
  const [clearAddress, setClearAddress] = useState(false)

  //Tags
  const [tagDefaultOptions] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [isTagsDetailUpdated, setTagsDetailUpdated] = useState(false)
  const [selectedMarket, setSelectedMarket] = useState([])
  const [isMarketDetailUpdated, setMarketDetailUpdated] = useState(false)
  const [selectedSecondaryMarket, setSelectedSecondaryMarket] = useState([])
  const [isSecondaryMarketDetailUpdated, setSecondaryMarketDetailUpdated] = useState(false)

  const tablePropsConfig = useMemo(() => {
    return {
      menuPortalTarget: document.body,
      styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) }
    }
  }, [])

  const fetchStates = useCallback((countryCode = "") => {
    dispatch(actions.getStateListRequest(countryCode))
  }, [])

  const handleResetState = useCallback(() => {
    setContactDetail(
      Object.assign(
        {},
        { ...getInitialContactSearchDetails(addressStore.defaultCountryCode) }
      )
    )
    setErrors({})
  }, [])

  const handleCloseForm = useCallback(() => {
    handleResetState()
    onToggleModal()
  }, [handleResetState, onToggleModal])

  const handleFetchTags = useCallback((payload, tagType) => {
    dispatch(
      actions.getCommonTagsRequest(payload, (res) => {
        if (res) {
          const data = res.items.map((item) => {
            return {
              text: item.tagName,
              value: item.tagId.toString()
            }
          })
          if (tagType === TAG_TYPES.SKILLS) {
            setSelectedTags(data)
          } else if (tagType === TAG_TYPES.PRIMARY_MARKET) {
            setSelectedMarket(data)
          } else if (tagType === TAG_TYPES.SECONDARY_MARKET) {
            setSelectedSecondaryMarket(data)
          }
        }
      })
    )
  }, [])

  const handleUpdateTags = useCallback((payload, tagType) => {
    dispatch(
      actions.updateCommonTagRequest(payload, (res) => {
        if (res) {
          if (tagType === TAG_TYPES.SKILLS) {
            setTagsDetailUpdated(false)
          } else if (tagType === TAG_TYPES.PRIMARY_MARKET) {
            setMarketDetailUpdated(false)
          } else if (tagType === TAG_TYPES.SECONDARY_MARKET) {
            setSecondaryMarketDetailUpdated(false)
          }
        }
      })
    )
  }, [])

  const handleChangeContactDetail = useCallback(
    (key, value, label) => {
      if (!!Object.keys(errors).length) {
        setErrors({})
      }
      if (key === "tags") {
        setSelectedTags(value)
        setTagsDetailUpdated(true)
        return
      }

      if (key === "market") {
        setSelectedMarket(value)
        setMarketDetailUpdated(true)
        return
      }

      if (key === "secondaryMarket") {
        setSelectedSecondaryMarket(value)
        setSecondaryMarketDetailUpdated(true)
        return
      }

      const updatedDetails = Object.assign({}, contactDetail)
      if (key === "organizationID") {
        updatedDetails["organizationName"] = label
      }

      if (key === "completeAddress") {
        updatedDetails["streetAddress"] = value.streetAddress || ""
        updatedDetails["countryCode"] = value.countryCode || ""
        updatedDetails["countryName"] = value.countryName || ""
        updatedDetails["state"] = value.state || ""
        updatedDetails["stateName"] = value.stateName || ""
        updatedDetails["city"] = value.city || ""
        updatedDetails["postCode"] = value.postCode || ""
        fetchStates(value.countryCode || "")
      } else {
        updatedDetails[key] = value
      }
      setContactDetail(updatedDetails)
    },
    [contactDetail, errors]
  )

  const handleAddressChange = useCallback(
    (addressName) => {
      handleChangeContactDetail("streetAddress", addressName)
    },
    [handleChangeContactDetail]
  )

  useEffect(() => {
    setEditMode(!!contactId)
  }, [contactId])

  const handleFetchTagsPayload = useCallback((contactId) => {
    const skillsPayload = {
      tagSourceId: TAG_SOURCE_TYPE.CONTACT,
      tagSourceKeyId: Number(contactId),
      sitePartyRoleId: contactDetail.sitePartyRoleId,
      tagIds: selectedTags.map((x) => Number(x.value))
    }
    const primaryMarketPayload = {
      tagSourceId: TAG_SOURCE_TYPE.CONTACT_MARKET,
      tagSourceKeyId: Number(contactId),
      sitePartyRoleId: contactDetail.sitePartyRoleId,
      tagIds: selectedMarket.map((x) => Number(x.value))
    }
    const secondaryMarketPayload = {
      tagSourceId: TAG_SOURCE_TYPE.CONTACT_SECONDARY_MARKET,
      tagSourceKeyId: Number(contactId),
      sitePartyRoleId: contactDetail.sitePartyRoleId,
      tagIds: selectedSecondaryMarket.map((x) => Number(x.value))
    }
    return {
      skillsPayload,
      primaryMarketPayload,
      secondaryMarketPayload
    }
  }, [selectedMarket, selectedTags, selectedSecondaryMarket])

  const handleAddContact = useCallback(() => {
    const errors = validateContactDetails(contactDetail, isDirectoryView, isOrganizationDirectoryList)
    if (!!Object.keys(errors).length) {
      setErrors(errors)
      return true
    }
    const tagUpdateQueue = []
    if (isEditMode) {
      const payload = {
        ...contactDetail,
        contactId,
        phoneNumber: `+1${contactDetail.phoneNumber}`,
        organizationId: contactDetail.organizationID || organizationId,
        accountAccessId: Number(contactDetail.systemAccessId),
        originalOrganizationId: contactDetail.originalOrganizationId
      }
      dispatch(
        actions.updateContactRequest(payload, (res) => {
          if (res) {
            const { skillsPayload, primaryMarketPayload, secondaryMarketPayload } = handleFetchTagsPayload(contactId)

            if (isTagsDetailUpdated) tagUpdateQueue.push(handleUpdateTags(skillsPayload, TAG_TYPES.SKILLS))
            if (isMarketDetailUpdated) tagUpdateQueue.push(handleUpdateTags(primaryMarketPayload, TAG_TYPES.PRIMARY_MARKET))
            if (isSecondaryMarketDetailUpdated) tagUpdateQueue.push(handleUpdateTags(secondaryMarketPayload, TAG_TYPES.SECONDARY_MARKET))

            if (tagUpdateQueue.length > 0) {
              const updateTags = async () => {
                await Promise.all(tagUpdateQueue)
              }
              updateTags()
            }
            if (!isDirectoryView) {
              dispatch(
                actions.refreshTableList({
                  listKey: TABLE_LIST_KEYS.CONTACT_LIST_KEY,
                  value: true
                })
              )
            } else if (isSiteDirectoryList) {
              dispatch(
                actions.refreshTableList({
                  listKey: TABLE_LIST_KEYS.SITE_ROLE_LIST_KEY,
                  value: true
                })
              )
            } else {
              dispatch(
                actions.refreshTableList({
                  listKey: TABLE_LIST_KEYS.INTERNAL_DIRECTORY_LIST,
                  value: true
                })
              )
            }
            handleCloseForm()
          }
        })
      )
    } else {
      const payload = {
        ...contactDetail,
        organizationID: contactDetail.organizationID || organizationId,
        phoneNumber: `+1${contactDetail.phoneNumber}`,
        accountAccessId: Number(contactDetail.systemAccessId)
      }
      dispatch(
        actions.addContactRequest(payload, (result) => {
          if (result) {
            const { skillsPayload, primaryMarketPayload, secondaryMarketPayload } = handleFetchTagsPayload(result)
            
            if (isTagsDetailUpdated) tagUpdateQueue.push(handleUpdateTags(skillsPayload, TAG_TYPES.SKILLS))
            if (isMarketDetailUpdated) tagUpdateQueue.push(handleUpdateTags(primaryMarketPayload, TAG_TYPES.PRIMARY_MARKET))
            if (isSecondaryMarketDetailUpdated) tagUpdateQueue.push(handleUpdateTags(secondaryMarketPayload, TAG_TYPES.SECONDARY_MARKET))

            if (tagUpdateQueue.length > 0) {
              const updateTags = async () => {
                await Promise.all(tagUpdateQueue)
              }
              updateTags()
            }

            if (isDDLContactView) {
              onUpdateContactCallback(result, payload)
            } else if (!isDirectoryView) {
              dispatch(
                actions.refreshTableList({
                  listKey: TABLE_LIST_KEYS.CONTACT_LIST_KEY,
                  value: true
                })
              )
            } else {
              dispatch(
                actions.refreshTableList({
                  listKey: TABLE_LIST_KEYS.INTERNAL_DIRECTORY_LIST,
                  value: true
                })
              )
            }
            handleCloseForm()
          }
        })
      )
    }
  }, [
    errors,
    organizationId,
    contactDetail,
    isEditMode,
    isDDLContactView,
    isDirectoryView,
    isSiteDirectoryList,
    handleCloseForm,
    onUpdateContactCallback,
    handleFetchTagsPayload
  ])

  const handleFetchAccountAccessVM = useCallback(() => {
    dispatch(
      actions.getCreateAccountVMRequest((list) => {
        if (list.length) {
          setAccountAccessVM(list)
        }
      })
    )
  }, [])

  useEffect(() => {
    handleFetchAccountAccessVM()
  }, [])

  useEffect(() => {
    if (addressStore.defaultCountryCode) {
      fetchStates(addressStore.defaultCountryCode)
    }
  }, [])

  useEffect(() => {
    if (isEditMode && !!contactId) {
      setFetching(true)
      dispatch(
        actions.getContactRequest({ contactId, organizationId }, (res) => {
          if (res) {
            const contactDetail = {
              contactId: res.contactId || "",
              firstName: res.firstName || "",
              lastName: res.lastName || "",
              organizationID: res.organizationId,
              phoneNumber: res.phoneNumber?.replace("+1", "") || "",
              title: res.title || "",
              email: res.emailAddress || "",
              sitePartyRoleId: res.sitePartyRoleId || "",
              sitePartyRoleName: res.sitePartyRoleName || "",
              systemAccessId: String(res.accountAccessId) || "",
              originalOrganizationId: res.organizationId,
              organizationName: res.organizationName || "",
              streetAddress: res.streetAddress || "",
              city: res.city || "",
              state: res.state || "",
              countryCode: res.countryCode || "",
              postCode: res.postCode || ""
            }
            setContactDetail(contactDetail)
          }
          setFetching(false)
        })
      )
    }
  }, [
    isEditMode,
    contactId,
    organizationId
  ])

  useEffect(() => {
    if (contactId) {
      const skillsPayload = {
        tagSourceKeyId: Number(contactId),
        tagSourceId: TAG_SOURCE_TYPE.CONTACT,
        pageSize: DEFAULT_VM_TAG_PAGE_SIZE
      }
      const primaryContactPayload = {
        tagSourceKeyId: Number(contactId),
        tagSourceId: TAG_SOURCE_TYPE.CONTACT_MARKET,
        pageSize: DEFAULT_VM_TAG_PAGE_SIZE
      }
      const secondaryMarketPayload = {
        tagSourceKeyId: Number(contactId),
        tagSourceId: TAG_SOURCE_TYPE.CONTACT_SECONDARY_MARKET,
        pageSize: DEFAULT_VM_TAG_PAGE_SIZE
      }

      const fetchTags = async () => {
        await Promise.all([
          handleFetchTags(skillsPayload, TAG_TYPES.SKILLS),
          handleFetchTags(primaryContactPayload, TAG_TYPES.PRIMARY_MARKET),
          handleFetchTags(secondaryMarketPayload, TAG_TYPES.SECONDARY_MARKET)
        ])
      }

      fetchTags()
    }
  }, [contactId])

  return (
    <Modal
      isOpen
      className={"sidebar-sm"}
      modalClassName={"modal-slide-in note-media-modal schedule-time-modal"}
      contentClassName="p-0"
    >
      <ModalHeader
        toggle={handleCloseForm}
        close={<SideModalCloseButton onClose={handleCloseForm} />}
        tag="div"
      >
        <h5 className="modal-title">
          {organizationName}
          <ChevronRight size={12} />
          {isEditMode
            ? getLocaleMessage(intl, ORGANIZATION_DIRECTORY.UPDATE_CONTACT)
            : getLocaleMessage(intl, ORGANIZATION_DIRECTORY.ADD_NEW_CONTACT)}
        </h5>
      </ModalHeader>
      <CustomUILoader isLoading={isFetching}>
        <ModalBody className="flex-grow-1">
          <Row form>
            {isDirectoryView && !isSiteDirectoryList && (
              <>
                {!isDDLContactView && (
                  <Col className="mb-1" sm={12} md={6} xl={6}>
                    <FormGroup>
                      <CustomLabel title={CONTACTS.TYPE} />
                      <Input
                        type="type"
                        id="type"
                        placeholder={getLocaleMessage(intl, CONTACTS.TYPE)}
                        value={DIRECTORY_TYPES.INTERNAL_CONTACT}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col className="mb-1" sm={12} md={6} xl={6}>
                  <FormGroup>
                    <CustomLabel title={CONTACTS.ORGANIZATION} />
                    <Input
                      type="email"
                      id="organization"
                      placeholder={getLocaleMessage(intl, CONTACTS.ORGANIZATION)}
                      value={organizationName}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </>
            )}
            {
              isOrganizationDirectoryList && (
                <Col className="mb-1" sm={12} md={6} xl={6}>
                <FormGroup>
                  <CustomLabel title={CONTACTS.ORGANIZATION} />
                  <Input
                    type="email"
                    id="organization"
                    placeholder={getLocaleMessage(
                      intl,
                      CONTACTS.ORGANIZATION
                    )}
                    value={organizationName}
                    disabled
                  />
                </FormGroup>
              </Col>
              )
            }
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <FormGroup>
                <CustomLabel title={CONTACTS.FIRST_NAME} required />
                <Input
                  id="first_name"
                  className="mr-1"
                  placeholder={getLocaleMessage(intl, CONTACTS.FIRST_NAME)}
                  value={contactDetail.firstName}
                  onChange={(event) => handleChangeContactDetail("firstName", event.target.value)}
                />
              </FormGroup>
              <ErrorMessage
                isShow={!!errors["firstName"]}
                message={errors["firstName"]}
              />
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <FormGroup>
                <CustomLabel title={CONTACTS.LAST_NAME} required />
                <Input
                  id="last_name"
                  placeholder={getLocaleMessage(intl, CONTACTS.LAST_NAME)}
                  value={contactDetail.lastName}
                  onChange={(event) => handleChangeContactDetail("lastName", event.target.value)}
                />
              </FormGroup>
              <ErrorMessage
                isShow={!!errors["lastName"]}
                message={errors["lastName"]}
              />
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <SiteRoleSearch
                id={"sitePartyRoleId"}
                isRequired
                isOptionGridDropdown={false}
                errors={errors}
                value={contactDetail.sitePartyRoleId}
                label={getLocaleMessage(
                  intl,
                  ORGANIZATION_DIRECTORY.ORGANIZATION_ROLE
                )}
                placeholder={getLocaleMessage(
                  intl,
                  ORGANIZATION_DIRECTORY.ORGANIZATION_ROLE
                )}
                defaultOptions={defaultOptions}
                isContactSitePartyRole
                isEditMode={isEditMode}
                initialLabel={
                  isEditMode ? selectedRowData.sitePartyRoleName : ""
                }
                initialValue={isEditMode ? selectedRowData.sitePartyRoleId : ""}
                labelType="text"
                valueType="value"
                getOptionLabel={(option) => option.text}
                getOptionValue={(option) => option.value}
                onSelect={(id, value) => handleChangeContactDetail(id, value)}
              />
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <FormGroup>
                <CustomLabel title={CONTACTS.JOB_TITLE} />
                <Input
                  id="job_title"
                  placeholder={getLocaleMessage(intl, CONTACTS.JOB_TITLE)}
                  value={contactDetail.title}
                  onChange={(event) => handleChangeContactDetail("title", event.target.value)}
                />
              </FormGroup>
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <FormGroup>
                <CustomLabel title={CONTACTS.EMAIL_ADDRESS} required />
                <Input
                  type="email"
                  id="email_address"
                  placeholder={getLocaleMessage(intl, CONTACTS.EMAIL_ADDRESS)}
                  value={contactDetail.email}
                  disabled={isEditMode}
                  onChange={(event) => handleChangeContactDetail("email", event.target.value)}
                />
              </FormGroup>
              <ErrorMessage
                isShow={!!errors["email"]}
                message={errors["email"]}
              />
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <FormGroup>
                <CustomLabel title={ADDRESS.PHONE_NUMBER} required />
                <InputGroup>
                  <div className="phone-number-input">
                    <Input
                      value={"+1"}
                      disabled
                      className="phone-country-code"
                      onChange={() => {}}
                    />
                  </div>
                  <Input
                    type="number"
                    id="phone_number"
                    placeholder={getLocaleMessage(intl, ADDRESS.PHONE_NUMBER)}
                    value={contactDetail.phoneNumber}
                    onChange={(event) => handleChangeContactDetail("phoneNumber", event.target.value)}
                    onKeyDown={validatePhoneNumber}
                  />
                </InputGroup>
              </FormGroup>
              <ErrorMessage
                isShow={!!errors["phoneNumber"]}
                message={errors["phoneNumber"]}
              />
            </Col>
            {(!isDirectoryView || isSiteDirectoryList) && !isOrganizationDirectoryList && (
              <Col className="mb-1" sm={12} md={6} xl={6}>
                <OrganizationSearch
                  isWrapperStyleRequired={false}
                  requireNoWrapper
                  defaultOptions={[]}
                  errors={errors}
                  id="organizationID"
                  isEditMode={isEditMode}
                  label={getLocaleMessage(intl, CONTACTS.ORGANIZATION)}
                  asyncInitialValue
                  initialLabel={organizationName || contactDetail.organizationName}
                  value={contactDetail.organizationID}
                  onSelect={(key, value, label) => handleChangeContactDetail("organizationID", value, label)}
                  {...tablePropsConfig}
                />
              </Col>
            )}
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <FormGroup>
                <ReactSelect
                  isClearable
                  id="system_access"
                  name="system_access"
                  isRequired={true}
                  label={getLocaleMessage(intl, CONTACTS.SYSTEM_ACCESS)}
                  placeHolder={getLocaleMessage(
                    intl,
                    CONTACTS.SELECT_SYSTEM_ACCESS_TYPE
                  )}
                  control={control}
                  value={contactDetail.systemAccessId}
                  options={accountAccessVM}
                  onSelect={(selected) => handleChangeContactDetail("systemAccessId", selected ? selected.value : "")}
                  {...tablePropsConfig}
                />
                <ErrorMessage
                  isShow={!!errors["systemAccessId"]}
                  message={errors["systemAccessId"]}
                />
              </FormGroup>
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <FormGroup>
                <CustomLabel title={ADDRESS.STREET_ADDRESS} />
                <PlacesAutocomplete
                  value={contactDetail.streetAddress}
                  onAddressChange={handleAddressChange}
                  onSelect={(value) => {
                    handleChangeContactDetail(
                      "completeAddress",
                      getCompleteAddress(value)
                    )
                  }}
                  isClearable={clearAddress}
                />
              </FormGroup>
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <ReactSelect
                isClearable
                id="country"
                name="country"
                label={getLocaleMessage(intl, ADDRESS.COUNTRY)}
                placeHolder={getLocaleMessage(intl, SELECT)}
                control={control}
                value={contactDetail.countryCode}
                options={addressStore?.countries || []}
                onSelect={(selected) => handleChangeContactDetail("countryCode", selected?.value)}
              />
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <ReactSelect
                isClearable
                id="state"
                name="state"
                label={getLocaleMessage(intl, ADDRESS.STATE)}
                placeHolder={getLocaleMessage(intl, SELECT)}
                control={control}
                value={contactDetail.state}
                options={addressStore?.states[contactDetail.countryCode] || []}
                onSelect={(selected) => handleChangeContactDetail("state", selected?.value)}
              />
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <FormGroup>
                <CustomLabel title={ADDRESS.CITY} />
                <Input
                  type="text"
                  placeholder={getLocaleMessage(intl, ADDRESS.CITY)}
                  value={contactDetail.city}
                  name="city"
                  onChange={(event) => handleChangeContactDetail("city", event.target.value)}
                />
              </FormGroup>
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <FormGroup>
                <CustomLabel title={ADDRESS.ZIP_CODE} />
                <Input
                  type="text"
                  placeholder={getLocaleMessage(intl, ADDRESS.ZIP_CODE)}
                  value={contactDetail.postCode}
                  name="postCode"
                  onChange={(event) => handleChangeContactDetail("postCode", event.target.value)}
                />
              </FormGroup>
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <TagsSearch
                id="tags"
                name="tags"
                label={getLocaleMessage(intl, TAG)}
                placeHolder={getLocaleMessage(intl, SELECT_TAG)}
                defaultOptions={tagDefaultOptions}
                isMulti
                isColWrapper={false}
                tagSourceId={TAG_SOURCE_TYPE.CONTACT}
                value={selectedTags || []}
                onSelect={(id, value) => handleChangeContactDetail(id, value)}
                {...tablePropsConfig}
              />
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <TagsSearch
                id="market"
                name="market"
                label={getLocaleMessage(intl, ADDRESS.PRIMARY_MARKET)}
                placeholder={getLocaleMessage(
                  intl,
                  ADDRESS.SELECT_PRIMARY_MARKET
                )}
                defaultOptions={tagDefaultOptions}
                isMulti
                isColWrapper={false}
                menuPlacement="top"
                tagSourceId={TAG_SOURCE_TYPE.CONTACT_MARKET}
                value={selectedMarket || []}
                onSelect={(id, value) => handleChangeContactDetail(id, value)}
                {...tablePropsConfig}
              />
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={6}>
              <TagsSearch
                id="secondaryMarket"
                name="secondaryMarket"
                label={getLocaleMessage(intl, ADDRESS.SECONDARY_MARKET)}
                placeholder={getLocaleMessage(
                  intl,
                  ADDRESS.SELECT_SECONDARY_MARKET
                )}
                defaultOptions={tagDefaultOptions}
                isMulti
                isColWrapper={false}
                menuPlacement="top"
                tagSourceId={TAG_SOURCE_TYPE.CONTACT_SECONDARY_MARKET}
                value={selectedSecondaryMarket || []}
                onSelect={(id, value) => handleChangeContactDetail(id, value)}
                {...tablePropsConfig}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Button
                onClick={() => {
                  handleCloseForm()
                  setClearAddress(!clearAddress)
                }}
                className={"grey-solid w-100"}
              >
                {getLocaleMessage(intl, CANCEL)}
              </Button>
            </Col>
            <Col md={6}>
              <Button
                className={"primary-solid w-100"}
                color="primary"
                onClick={handleAddContact}
              >
                {getLocaleMessage(intl, isEditMode ? UPDATE : SAVE)}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </CustomUILoader>
    </Modal>
  )
}

export default localeMessageWrapper(AddNewContactModal)
