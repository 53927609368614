import React, { useState, useCallback, useEffect, useRef } from "react"
import PropTypes from 'prop-types'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'

import { getCompleteAddress } from '../../helpers'
import LocaleMessageWrapper from "../../components/locale-message"

let typedStreetAddress = ''

const PlacesAutocompleteComponent = ({
  intl,
  id,
  value,
  isFetchingInitialValue = false,
  allowedCountryCodes = ["us", "ca", "mx"],
  onSelect,
  onAddressChange,
  isClearable
}) => {
  const [isFetching, setFetching] = useState(false)
  const [addressName, setAddressName] = useState(value)
  const [isAddressSelected, setAddressSelected] = useState(false)
  const input_ref = useRef()
  let autocomplete = ""

  useEffect(() => {
    const setAddressValue = () => {
      setTimeout(() => {
        setAddressName(value)
      }, 100)
    }

    if (isFetchingInitialValue && !isFetching) {
      setFetching(true)
    } else if (!isFetchingInitialValue && isFetching) {
      setAddressValue('')
      setFetching(false)
    }
  }, [isFetching, isFetchingInitialValue, value, addressName])

  useEffect(() => {
    setAddressName('')
  }, [isClearable])

  useEffect(() => {
    setAddressName(value)
  }, [value])

  useEffect(() => {
    const resetAddressValue = () => {
      setTimeout(() => {
        if (!value && !!addressName) {
          setAddressName('')
        }
      }, 300)
    }
    if (isAddressSelected && !value && !!addressName) {
      resetAddressValue()
      setAddressSelected(false)
    }
  }, [isAddressSelected, addressName, value])

  useEffect(() => {
    const handlePlaceSelect = () => {
      const place = autocomplete?.getPlace() || ''
      if (place.formatted_address) {
        place.updatedStreetAddress = typedStreetAddress
        typedStreetAddress = ''
        const address = getCompleteAddress(place)
        setAddressName(address.streetAddress)
        onSelect(place, id)
        setAddressSelected(true)
      }
    }
    autocomplete = new google.maps.places.Autocomplete(input_ref?.current)
    autocomplete.addListener("place_changed", handlePlaceSelect)
    autocomplete.setComponentRestrictions({
      country: allowedCountryCodes
    })

  }, [id, onSelect])

  const handleChange = useCallback(
    (e) => {
      const streetVal = e.target.value
      setAddressName(streetVal)
      typedStreetAddress = streetVal
      if (onAddressChange) {
        onAddressChange(streetVal, id)
      }
    },
    [addressName, id, onAddressChange]
  )

  return (
    <input
      placeholder={intl.formatMessage({
        id: LANGUAGE_CONSTANTS.ADDRESS.STREET_ADDRESS,
        defaultMessage: LANGUAGE_CONSTANTS.ADDRESS.STREET_ADDRESS
      })}
      className="form-control"
      ref={input_ref}
      value={addressName}
      onChange={handleChange}
    />
  )
}

PlacesAutocompleteComponent.propTypes = {
  intl: PropTypes.object,
  id: PropTypes.number,
  value: PropTypes.string,
  isFetchingInitialValue: PropTypes.bool,
  onSelect: PropTypes.func,
  onAddressChange: PropTypes.func,
  isClearable: PropTypes.bool
}

export default LocaleMessageWrapper(PlacesAutocompleteComponent)
