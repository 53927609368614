export const SCHEDULE_TASK_TYPE = {
  MANUAL: "1",
  AUTO_ASSIGN: "2",
  SELF_ASSIGN: "3"
}
export const SCHEDULE_SELF_ASSIGN_TYPE = {
  SITE_ROLE: "1",
  GROUP: "2"
}

export const SCHEDULE_AUTO_ASSIGN_TYPE = {
  CONTACT: "1",
  PRIMARY_CONTACT: "2",
  SITE_ROLE: "3",
  ORGANIZATION_ROLE: "4",
  CONTACT_GROUP: "5"
}

export const ELIGIBLE_REASSIGNMENT_TYPE = {
  CONTACT_GROUP: "1",
  SITE_ROLE: "2",
  ORGANIZATION_ROLE: "3"
}

export const SCHEDULE_TASK = {
  REASSIGNMENT: "Reassignment"
}

