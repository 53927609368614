import PropTypes from 'prop-types'
import { Label } from 'reactstrap'

import { LocaleMessage } from "@views/components"

const CustomLabel = ({
  className,
  title,
  forValue,
  required,
  requiredIconClassName,
  isLocalizedTitle,
  ...restProps
}) => {

  if (!title) {
    return null
  }

  const props = {}

  if (className) {
    props.className = className
  }
  if (forValue) {
    props.for = forValue
  }

  return (
    <>
      <Label {...restProps} {...props}>
        {isLocalizedTitle ? <LocaleMessage id={title} /> : title}
        {required && <>
          {' '}
          <span className={`text-danger ${requiredIconClassName}`}>*</span>
        </>}
      </Label>
    </>
  )
}

CustomLabel.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  forValue: PropTypes.string,
  required: PropTypes.bool,
  requiredIconClassName: PropTypes.string,
  isLocalizedTitle: PropTypes.bool
}

CustomLabel.defaultProps = {
  title: "",
  className: "",
  required: false,
  forValue: '',
  requiredIconClassName: '',
  isLocalizedTitle: true
}

export default CustomLabel
