import config from "./APIConfig"
import { getDataApi } from "../index"

export const getBreadscrumbDetail = (data) => {
  const { url } = config.getBreadscrumbDetail
  return getDataApi(url(data))
}

export const getCommonTag = async (data) => {
  const { url } = config.getCommonTag
  return await getDataApi(url(data))
}

export const getCommonTagsDDL = async (data) => {
  const { url } = config.getCommonTagsDDL
  return await getDataApi(url(data))
}

/**
 * @method getDuxImage : To import image using some external ref id
 * @param {*} data 
 * {
 *  externalRefId
 * }
 * 
 * @returns 
 * {
  "externalRefID": "string",
  "fileURL": "string",
  "externalResources": 1
}
*/
export const getDuxImage = async (data) => {
  const { url } = config.getDuxImage
  return await getDataApi(url(data))
}

export const getGlobalSearchList = async (data) => {
  const { url } = config.getGlobalSearchList
  return await getDataApi(url(data))
}

export const getGlobalSearchVM = async () => {
  const { url } = config.getGlobalSearchVM
  return await getDataApi(url)
}

export const getHomeSitesList = async (data) => {
  const { url } = config.getHomeSitesList
  return await getDataApi(url(data))
}

export const getNoteHeadline = async (data) => {
  const { url } = config.getNoteHeadline
  return await getDataApi(url(data))
}

export const getNotification = async (data) => {
  const { url } = config.getNotification
  return await getDataApi(url(data))
}

export const getQuickLinkList = async (data) => {
  const { url } = config.getQuickLinkList
  return await getDataApi(url(data))
}

export const getRegionFilterList = async (data) => {
  const { url } = config.getRegionFilterlist
  return await getDataApi(url(data))
}

export const getSiteWeatherData = async (data) => {
  const { url } = config.getSiteWeatherData

  return await getDataApi(url(data), {}, {
    preventTokenOverride: true
  })
}

export const getSubRegionFilterList = async (data) => {
  const { url } = config.getSubRegionFilterlist
  return await getDataApi(url(data))
}

export const getWelcomeNotification = async () => {
  const { url } = config.getWelcomeNotification
  return await getDataApi(url)
}

export const getCCContactList = async (data) => {
  const { url } = config.getCCContactList
  return await getDataApi(url(data))
}
