import React from 'react'
import { Col, FormGroup, Input } from 'reactstrap'

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomLabel } from '@views/components'
import localeMessageWrapper from '@views/components/locale-message'

const { SATURATION_RATE } = LANGUAGE_CONSTANTS.SERVICES

function SaturationRate({ intl, errors, serviceDetail, isViewMode, onChangeServiceDetails, onCustomAdvanceSearch }) {
  return (
    <Col className='mb-1' sm={12} md={6} xl={4}>
      <FormGroup>
        <CustomLabel title={SATURATION_RATE} required />
        <Input
          type='text'
          name='saturation_rate'
          placeholder={getLocaleMessage(intl, SATURATION_RATE)}
          value={serviceDetail.saturationRate}
          onChange={(event) => onChangeServiceDetails('saturationRate', event.target.value)}
          disabled={isViewMode}
          onKeyDown={onCustomAdvanceSearch}
        />
      </FormGroup>
      <ErrorMessage
        isShow={!!errors['saturationRate']}
        message={errors['saturationRate']}
      />
    </Col>
  )
}

export default localeMessageWrapper(SaturationRate)
