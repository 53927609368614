import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap"
import { useDropzone } from "react-dropzone"
import { useDispatch } from "react-redux"
import PropTypes from 'prop-types'
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { asyncForEach, cloneArrayOfObject, getLocaleMessage } from "@utils"
import { ErrorMessage } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import AcceptedFileList from './AcceptedFileList'
import {
  ACCEPTED_FILE_TYPES,
  ACCEPTED_IMAGE_FILE_TYPES,
  ACTION_TYPE,
  FILE_UPLOAD_ERROR_TYPES,
  MAX_NAME_LENGTH,
  RESOURCE_CENTER_SOURCE_TYPE_VM,
  UPLOADER_TAB_TYPES
} from "./config"
import { fileSelectionValidator, getCustomFileDetailList, validateFileData } from './helpers'
import * as styles from './styles'

const { ADD, ADD_AND_CLOSE, CANCEL, RESOURCES, UPLOAD, USER_PROFILE } = LANGUAGE_CONSTANTS

//todo: edit mode for files
const ResourceCenterUploader = ({
  isInternalUploadRequired = true,
  resourceCenterId,
  actionType = ACTION_TYPE.UPLOAD,
  isAllowImageTypeFilesOnly = false,
  isAllFilesAllowed = true, // allowed all files except executables and dmg format
  initialFileList = [],
  initialCustomFileDetailList = [],
  isSingleUpload = false,
  /**
   * fileLocationType: Should be key of type config.RESOURCE_CENTER_SOURCE_TYPES
   */
  fileLocationType = "",
  fileSourceId = "",
  intl,
  isResourceUploader = false,
  defaultFileCategoryLabel = "",
  isDisableFileCategory = false,
  defaultFileCategoryValue = "",
  onClose = () => { },
  onRefreshResourceList = () => { },
  onSetSelectedFiles = () => { }
}) => {
  const dispatch = useDispatch()

  const [fileList, setFileList] = useState(initialFileList)
  const [errors, setErrors] = useState({})
  const [customFileDetailList, setCustomFileDetailList] = useState(initialCustomFileDetailList)
  const [isEditMode] = useState(!!initialFileList?.length)
  const [fileCategoryListVM, setFileCategoryListVM] = useState([])

  const defaultFileCategoryId = useMemo(() => {
    if (defaultFileCategoryValue.trim()) {
      return fileCategoryListVM.find(x => x.value === defaultFileCategoryValue)?.value || "" 
    } else {
      const formatedDefaultFileCategoryLabel = defaultFileCategoryLabel.toLowerCase()
      return fileCategoryListVM.find(x => x.text.toLowerCase() === formatedDefaultFileCategoryLabel)?.value || ""
    }
  }, [defaultFileCategoryValue, defaultFileCategoryLabel, fileCategoryListVM])

  const handleDropFile = useCallback(
    (acceptedFileList, rejectedFileList) => {
      if (!!rejectedFileList?.length) {
        const rejectionErrors = {}
        rejectedFileList.forEach(item => {
          if (
            item?.errors[0]?.code === FILE_UPLOAD_ERROR_TYPES.FILE_TOO_LARGE &&
            !rejectionErrors[FILE_UPLOAD_ERROR_TYPES.FILE_TOO_LARGE]?.length
          ) {
            rejectionErrors[FILE_UPLOAD_ERROR_TYPES.FILE_TOO_LARGE] =
              item.errors[0].message
          } else if (
            item?.errors[0]?.code === FILE_UPLOAD_ERROR_TYPES.INVALID_FILE_FORMAT &&
            !rejectionErrors[FILE_UPLOAD_ERROR_TYPES.INVALID_FILE_FORMAT]?.length
          ) {
            rejectionErrors[FILE_UPLOAD_ERROR_TYPES.INVALID_FILE_FORMAT] =
            item.errors[0].message
          }
        })
        setErrors(rejectionErrors)
      } else if (Object.keys(errors)) {
        setErrors({})
      }

      if (actionType === ACTION_TYPE.REPLACE) {
        setFileList([...acceptedFileList])
        setCustomFileDetailList([...getCustomFileDetailList(acceptedFileList, defaultFileCategoryId)])
      } else {
        setFileList([...fileList, ...acceptedFileList])
        setCustomFileDetailList([
          ...customFileDetailList,
          ...getCustomFileDetailList(acceptedFileList, defaultFileCategoryId)
        ])
      }
    },
    [actionType, customFileDetailList, defaultFileCategoryId, errors, fileList]
  )

  const replaceFileActionConfig = useMemo(() => {
    if (actionType === ACTION_TYPE.REPLACE) {
      return {
        maxFiles: 1
      }
    }
    return {}
  }, [actionType])

  const { isDragAccept, isDragReject, isFocused, getRootProps, getInputProps } = useDropzone({
    accept: isAllowImageTypeFilesOnly ? ACCEPTED_IMAGE_FILE_TYPES : isAllFilesAllowed ? undefined : ACCEPTED_FILE_TYPES,
    validator: (file) => fileSelectionValidator(file, isAllFilesAllowed, intl),
    onDrop: handleDropFile,
    multiple: !isSingleUpload,
    ...replaceFileActionConfig
  })

  const style = useMemo(() => ({
    ...styles.baseStyle,
    ...(isFocused ? styles.focusedStyle : {}),
    ...(isDragAccept ? styles.acceptStyle : {}),
    ...(isDragReject ? styles.rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ])

  const handleChangeDetails = useCallback((
    key, value, index
  ) => {
    if (Object.keys(errors)) {
      setErrors({})
    }
    const updatedFileList = cloneArrayOfObject(customFileDetailList)
    const extLength = updatedFileList[index].extension?.length || 0
    if (key === 'fileName' && value.trim().length > (MAX_NAME_LENGTH - extLength)) {
      return
    }
    updatedFileList[index][key] = value
    setCustomFileDetailList(updatedFileList)
  }, [errors, customFileDetailList])

  const handleRemoveDocument = useCallback((index) => {
    if (Object.keys(errors)) {
      setErrors({})
    }
    const updatedCFileList = cloneArrayOfObject(customFileDetailList)
    updatedCFileList.splice(index, 1)
    setCustomFileDetailList(updatedCFileList)

    const updatedFileList = [...fileList]
    updatedFileList.splice(index, 1)
    setFileList(updatedFileList)
  }, [errors, customFileDetailList, fileList])

  const handleUploadFile = useCallback((payload, callback) => {
    if (actionType === ACTION_TYPE.REPLACE) {
      dispatch(
        actions.replaceRCDocumentRequest({
          resourceCenterId,
          ...payload
        }, (data) => {
          callback(!!data)
        })
      )
    } else {
      dispatch(
        actions.uploadRCDocumentRequest(payload, (data) => {
          callback(!!data)
        })
      )
    }
  }, [resourceCenterId, onClose, onRefreshResourceList])

  const handleSaveFile = useCallback(async () => {
    const errors = validateFileData({ intl, customFileDetailList })
    if (!!Object.keys(errors).length) {
      setErrors(errors)
      return
    }

    const sourceKeyName =
      RESOURCE_CENTER_SOURCE_TYPE_VM[fileLocationType]?.sourceKeyName || ""

    try {
      const lastFileIndex = fileList.length - 1
      let isListRefreshRequired = false
      await asyncForEach(fileList, async (sFile, index) => {
        const fileDetail = customFileDetailList[index]
        const payload = {
          fileLocationType,
          title: `${fileDetail.fileName}${fileDetail.extension}`,
          fileDescription: fileDetail.fileDescription,
          fileSourceName: sourceKeyName,
          fileSourceId,
          fileContent: sFile,
          fileCategoryId: fileDetail.fileCategoryId
        }
        const isLastFile = index === lastFileIndex
        handleUploadFile(payload, (isSaved) => {
          if (isSaved) {
            isListRefreshRequired = true
          }

          if (isLastFile && isListRefreshRequired) {
            setTimeout(() => {
              onRefreshResourceList()
            }, 1000)
            setFileList([])
            onClose()
          }
        })
      })
    } catch (err) { }
  }, [
    actionType,
    customFileDetailList,
    fileList,
    fileLocationType,
    fileSourceId,
    intl,
    handleUploadFile,
    onRefreshResourceList,
    onClose
  ])

  const handleAddFile = useCallback(() => {
    if (customFileDetailList.length) {
      const errors = validateFileData({ intl, customFileDetailList })
      if (!!Object.keys(errors).length) {
        setErrors(errors)
        return
      }
    }
    onSetSelectedFiles({
      fileList,
      customFileDetailList
    })
    onClose()
  }, [customFileDetailList, fileList, isEditMode, onClose, onSetSelectedFiles])

  useEffect(() => {
    if (isResourceUploader) {
      dispatch(
        actions.getResourceCenterFileCategoryDDLRequest({}, (data) => {
          if (data) {
            setFileCategoryListVM(data)
          }
        })
      )
    }
  }, [isResourceUploader])

  return (
    <Modal isOpen className="modal-dialog-centered all-modal upload-modal">
      <ModalBody className="p-0">
        <div className="rc-uploader-tabs">
          <span
            className={"active"}
          >
            {getLocaleMessage(intl, UPLOAD)}
          </span>
        </div>
        {(
          <>
            <div>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>
                  {getLocaleMessage(intl, RESOURCES.RESOURCE_CENTER_UPLOADER_DESCRIPTION)}
                </p>
                <p>
                  ({getLocaleMessage(intl, RESOURCES.RESOURCE_CENTER_UPLOADER_SUB_DESCRIPTION)})
                </p>
              </div>
            </div>
            <ErrorMessage
              isShow={!!errors[FILE_UPLOAD_ERROR_TYPES.FILE_TOO_LARGE] || !!errors[FILE_UPLOAD_ERROR_TYPES.INVALID_FILE_FORMAT]}
              message={errors[FILE_UPLOAD_ERROR_TYPES.FILE_TOO_LARGE] || errors[FILE_UPLOAD_ERROR_TYPES.INVALID_FILE_FORMAT]}
            />
            <div>
              <AcceptedFileList
                errors={errors}
                intl={intl}
                selectedFiles={customFileDetailList}
                fileCategoryList={fileCategoryListVM}
                isDisableFileCategory={isDisableFileCategory}
                onChangeDetails={handleChangeDetails}
                onRemoveDocument={handleRemoveDocument}
                isResourceUploader={isResourceUploader}
              />
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-center border-0 p-0">
        <Button outline className="delete-btn mr-1 " onClick={onClose}>
          {getLocaleMessage(intl, CANCEL)}
        </Button>
        {(
          <Button
            className="bg-btn"
            disabled={(isInternalUploadRequired || !isEditMode) && !fileList.length}
            onClick={!isInternalUploadRequired ? handleAddFile : handleSaveFile}
          >
            {getLocaleMessage(
              intl,
              !isInternalUploadRequired
                ? isEditMode ? ADD_AND_CLOSE : ADD
                : actionType === ACTION_TYPE.UPLOAD
                  ? USER_PROFILE.UPLOAD
                  : RESOURCES.TABLE.REPLACE
            )}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

ResourceCenterUploader.propTypes = {
  resourceCenterId: PropTypes.string,
  actionType: PropTypes.string,
  fileLocationType: PropTypes.string,
  fileSourceId: PropTypes.string,
  intl: PropTypes.object,
  isAllowImageTypeFilesOnly: PropTypes.bool,
  isAllFilesAllowed: PropTypes.bool,
  isInternalUploadRequired: PropTypes.bool,
  defaultFileCategoryLabel: PropTypes.string,
  defaultFileCategoryValue: PropTypes.string,
  initialFileList: PropTypes.array,
  initialCustomFileDetailList: PropTypes.array,
  onClose: PropTypes.func,
  onRefreshResourceList: PropTypes.func
}

export default localeMessageWrapper(ResourceCenterUploader)
