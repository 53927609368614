import { getActionType } from '../action-type-generator'

export const GET_TEMPLATE_CHECKPOINT_LIST = getActionType('GET_TEMPLATE_CHECKPOINT_LIST')
export const GET_NEW_CHECKPOINT_VM = getActionType('GET_NEW_CHECKPOINT_VM')
export const GET_CHECKPOINT_DETAIL_VM = getActionType('GET_CHECKPOINT_DETAIL_VM')
export const GET_TEMPLATE_SUB_COMPONENT_LIST = getActionType('GET_TEMPLATE_SUB_COMPONENT_LIST')
export const GET_ALL_CHECKPOINT_LIST_REQUEST = 'GET_ALL_CHECKPOINT_LIST_REQUEST'
export const ADD_CHECKPOINT_TO_TEMPLATE_REQUEST = 'ADD_CHECKPOINT_TO_TEMPLATE_REQUEST'
export const REMOVE_CHECKPOINT_FROM_TEMPLATE_REQUEST = 'REMOVE_CHECKPOINT_FROM_TEMPLATE_REQUEST'
export const GET_TEMPLATE_ASSEMBLY_REQUEST = 'GET_TEMPLATE_ASSEMBLY_REQUEST'
export const GET_TEMPLATE_COMPONENT_REQUEST = 'GET_TEMPLATE_COMPONENT_REQUEST'
export const GET_TEMPLATE_MANUFACTURER_REQUEST = 'GET_TEMPLATE_MANUFACTURER_REQUEST'
export const TEMPLATE_ACTION_TYPE = 'TEMPLATE_ACTION_TYPE'
export const GET_ALL_CHECKPOINT_TRAINING_LIST_REQUEST = 'GET_ALL_CHECKPOINT_TRAINING_LIST_REQUEST'
export const UPSERT_CHECKPOINT_DETAILS_REQUEST = 'UPSERT_CHECKPOINT_DETAILS_REQUEST'
export const ADD_TRAINING_TO_CHECKPOINT_REQUEST = "ADD_TRAINING_TO_CHECKPOINT_REQUEST"
export const REMOVE_TRAINING_FROM_CHECKPOINT_REQUEST = "REMOVE_TRAINING_FROM_CHECKPOINT_REQUEST"
export const GET_CHECKPOINT_TRAINING_LIST_REQUEST = "GET_CHECKPOINT_TRAINING_LIST_REQUEST"
export const CLONE_CHECKPOINT_REQUEST = "CLONE_CHECKPOINT_REQUEST"
export const CLONE_TEMPLATE_CHECKPOINT_REQUEST = "CLONE_TEMPLATE_CHECKPOINT_REQUEST"
export const UPDATE_CHECKPOINT_TRAINING_REQUEST = "UPDATE_CHECKPOINT_TRAINING_REQUEST"
export const GET_CHECKPOINT_CHILD_DETAIL_REQUEST = 'GET_CHECKPOINT_CHILD_DETAIL_REQUEST'
export const GET_CHECKPOINT_COLLECTION_REQUEST = "GET_CHECKPOINT_COLLECTION_REQUEST"
export const SAVE_CURRENT_TEMPLATE = "SAVE_CURRENT_TEMPLATE"
export const RESET_CHECKPOINT_LIST = 'RESET_CHECKPOINT_LIST'
export const UPDATE_ACTIVITY_ORDER_INDEX_REQUEST = "UPDATE_ACTIVITY_ORDER_INDEX_REQUEST"
export const GET_ASSEMBLY_TYPE_COMPONENT_LIST_REQUEST = "GET_ASSEMBLY_TYPE_COMPONENT_LIST_REQUEST"
export const GET_ASSEMBLY_TYPE_DDL_REQUEST = "GET_ASSEMBLY_TYPE_DDL_REQUEST"
export const GET_APPLICABLE_REGULATION_REQUEST = "GET_APPLICABLE_REGULATION_REQUEST"
export const ADD_APPLICABLE_REGULATION_DATA_REQUEST = "ADD_APPLICABLE_REGULATION_DATA_REQUEST"
export const UPDATE_APPLICABLE_REGULATION_DATA_REQUEST = "UPDATE_APPLICABLE_REGULATION_DATA_REQUEST"
export const DELETE_APPLICABLE_REGULATION_DATA_REQUEST = "DELETE_APPLICABLE_REGULATION_DATA_REQUEST"
export const GET_REFERENCE_STANDARD_DATA_REQUEST = "GET_REFERENCE_STANDARD_DATA_REQUEST"
export const ADD_REFERENCE_STANDARD_DATA_REQUEST = "ADD_REFERENCE_STANDARD_DATA_REQUEST"
export const UPDATE_REFERENCE_STANDARD_DATA_REQUEST = "UPDATE_REFERENCE_STANDARD_DATA_REQUEST"
export const DELETE_REFERENCE_STANDARD_DATA_REQUEST = "DELETE_REFERENCE_STANDARD_DATA_REQUEST"
export const ADD_INDUSTRY_INTERPRETATIONS_DATA_REQUEST = "ADD_INDUSTRY_INTERPRETATIONS_DATA_REQUEST"
export const GET_INDUSTRY_INTERPRETATIONS_DATA_REQUEST = "GET_INDUSTRY_INTERPRETATIONS_DATA_REQUEST"
export const UPDATE_INDUSTRY_INTERPRETATIONS_DATA_REQUEST = "UPDATE_INDUSTRY_INTERPRETATIONS_DATA_REQUEST"
export const DELETE_INDUSTRY_INTERPRETATIONS_DATA_REQUEST = "DELETE_INDUSTRY_INTERPRETATIONS_DATA_REQUEST"
export const UPDATE_APPLICABLE_REGULATION_REORDER_DATA_REQUEST = "UPDATE_APPLICABLE_REGULATION_REORDER_DATA_REQUEST"
export const UPDATE_INDUSTRY_INTREPRETATIONS_REORDER_DATA_REQUEST = "UPDATE_INDUSTRY_INTREPRETATIONS_REORDER_DATA_REQUEST"
export const UPDATE_REFERENCE_STANDARDS_REORDER_DATA_REQUEST = "UPDATE_REFERENCE_STANDARDS_REORDER_DATA_REQUEST"
export const GET_ACTIVITY_TEMPLATE_LIST_REQUEST = "GET_ACTIVITY_TEMPLATE_LIST_REQUEST"
export const UPDATE_REQUIRED_ACTIVITY_REQUEST = "UPDATE_REQUIRED_ACTIVITY_REQUEST"
export const UPDATE_COPY_ALLOWED_ACTIVITY_REQUEST = "UPDATE_COPY_ALLOWED_ACTIVITY_REQUEST"

/****************************************************/
/** GET_TEMPLATE_CHECKPOINT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getTemplateCheckpointListRequest :  To get template checkpoints list
 *
 */
export const getTemplateCheckpointListRequest = (payload, callback) => {
    return {
        type: GET_TEMPLATE_CHECKPOINT_LIST.REQUEST,
        payload,
        callback
    }
}

/**
 * @method getTemplateCheckpointListSuccess : To set template checkpoints list on success
 *
 */
export const getTemplateCheckpointListSuccess = (data) => {
    return {
        type: GET_TEMPLATE_CHECKPOINT_LIST.SUCCESS,
        data
    }
}

/**
 * @method getTemplateCheckpointListFailure : To set error on getting template checkpoints list on failure
 *
 */
export const getTemplateCheckpointListFailure = (error) => {
    return {
        type: GET_TEMPLATE_CHECKPOINT_LIST.FAILURE,
        error
    }
}

/****************************************************/
/** RESET_CHECKPOINT_LIST **/
/****************************************************/

/**
 * @method resetCheckpointList : To reset checkpoint list
 *
 */
export const resetCheckpointList = () => {
    return {
        type: RESET_CHECKPOINT_LIST
    }
}

/**
 * @method getNewCheckpointVMRequest :  To get new checkpoint VM
 *
 */
export const getNewCheckpointVMRequest = (payload) => {
    return {
        type: GET_NEW_CHECKPOINT_VM.REQUEST,
        payload
    }
}

/**
 * @method getNewCheckpointVMSuccess : To set new checkpoint VM on success
 *
 */
export const getNewCheckpointVMSuccess = (data) => {
    return {
        type: GET_NEW_CHECKPOINT_VM.SUCCESS,
        data
    }
}

/**
 * @method getNewCheckpointVMFailure : To set error on getting new checkpoint VM on failure
 *
 */
export const getNewCheckpointVMFailure = (error) => {
    return {
        type: GET_NEW_CHECKPOINT_VM.FAILURE,
        error
    }
}

/**
 * @method getCheckpointDetailVMRequest :  To get checkpoint details VM
 *
 */
export const getCheckpointDetailVMRequest = (payload) => {
    return {
        type: GET_CHECKPOINT_DETAIL_VM.REQUEST,
        payload
    }
}

/**
 * @method getCheckpointDetailVMSuccess : To set checkpoint details VM on success
 *
 */
export const getCheckpointDetailVMSuccess = (data) => {
    return {
        type: GET_CHECKPOINT_DETAIL_VM.SUCCESS,
        data
    }
}

/**
 * @method getCheckpointDetailVMFailure : To set error on getting checkpoint details VM on failure
 *
 */
export const getCheckpointDetailVMFailure = (error) => {
    return {
        type: GET_CHECKPOINT_DETAIL_VM.FAILURE,
        error
    }
}
/****************************************************/
/** GET_SUB_COMPONENT_LIST_REQUEST **/
/****************************************************/

/**
 * @method getTemplateSubComponentListRequest :  To get sub component checkpoint list
 *
 */
export const getTemplateSubComponentListRequest = (payload, callback) => {
    return {
        type: GET_TEMPLATE_SUB_COMPONENT_LIST.REQUEST,
        payload,
        callback
    }
}

/**
 * @method getTemplateSubComponentListSuccess : To set checkpoint details VM on success
 *
 */
export const getTemplateSubComponentListSuccess = (data) => {
    return {
        type: GET_TEMPLATE_SUB_COMPONENT_LIST.SUCCESS,
        data
    }
}

/**
 * @method getTemplateSubComponentListFailure : To set error on getting checkpoint details VM on failure
 *
 */
export const getTemplateSubComponentListFailure = (error) => {
    return {
        type: GET_TEMPLATE_SUB_COMPONENT_LIST.FAILURE,
        error
    }
}

/****************************************************/
/** ADD_CHECKPOINT_TO_TEMPLATE_REQUEST **/
/****************************************************/

/**
 * @method getAllCheckpointListRequest :  To add checkpoint to template
 *
 */
export const addCheckpointToTemplateRequest = (payload, callback) => {
    return {
        type: ADD_CHECKPOINT_TO_TEMPLATE_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** REMOVE_CHECKPOINT_FROM_TEMPLATE_REQUEST **/
/****************************************************/

/**
 * @method removeCheckpointFromTemplateRequest :  To remove checkpoint from template
 *
 */
export const removeCheckpointFromTemplateRequest = (payload, callback) => {
    return {
        type: REMOVE_CHECKPOINT_FROM_TEMPLATE_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** GET_SUB_COMPONENT_REQUEST **/
/****************************************************/

/**
 * @method getAllCheckpointListRequest :  To get all checkpoint list
 *
 */
export const getAllCheckpointListRequest = (payload, callback) => {
    return {
        type: GET_ALL_CHECKPOINT_LIST_REQUEST,
        payload,
        callback
    }
}

/**
 * @method getTemplateAssemblyRequest :  To get template assembly request
 *
 */
export const getTemplateAssemblyRequest = (payload, callback) => {
    return {
        type: GET_TEMPLATE_ASSEMBLY_REQUEST,
        payload,
        callback
    }
}

/**
 * @method getTemplateComponentRequest :  To get template Component request
 *
 */
export const getTemplateComponentRequest = (payload, callback) => {
    return {
        type: GET_TEMPLATE_COMPONENT_REQUEST,
        payload,
        callback
    }
}

/**
 * @method getTemplateManufacturerRequest :  To get template Manufacturer request
 *
 */
export const getTemplateManufacturerRequest = (payload, callback) => {
    return {
        type: GET_TEMPLATE_MANUFACTURER_REQUEST,
        payload,
        callback
    }
}

/**
 * @method templateActionType :  To show clone button and new version button
 *
 */
export const templateActionType = (payload) => {
    return {
        type: TEMPLATE_ACTION_TYPE,
        payload
    }
}
/****************************************************/
/** GET_ALL_CHECKPOINT_TRAINING_LIST_REQUEST **/
/****************************************************/

/**
 * @method getAllCheckpointTrainingListRequest :  To get all checkpoint training list
 *
 */
export const getAllCheckpointTrainingListRequest = (payload, callback) => {
    return {
        type: GET_ALL_CHECKPOINT_TRAINING_LIST_REQUEST,
        payload,
        callback
    }
}

/** 
* @method upsertCheckpointDetailsRequest :  To save the detail of the checkpoint collection detail work item
 *
 */
export const upsertCheckpointDetailsRequest = (payload, callback) => {
    return {
        type: UPSERT_CHECKPOINT_DETAILS_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** ADD_TRAINING_TO_CHECKPOINT_REQUEST **/
/****************************************************/

/**
 * @method addTrainingToCheckpointRequest :  To add training to checkpoint
 *
 */
export const addTrainingToCheckpointRequest = (payload, callback) => {
    return {
        type: ADD_TRAINING_TO_CHECKPOINT_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** REMOVE_TRAINING_FROM_CHECKPOINT_REQUEST **/
/****************************************************/

/**
 * @method removeTrainingFromCheckpointRequest :  To remove Training from Checkpoint
 *
 */
export const removeTrainingFromCheckpointRequest = (payload, callback) => {
    return {
        type: REMOVE_TRAINING_FROM_CHECKPOINT_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** GET_CHECKPOINT_TRAINING_LIST_REQUEST **/
/****************************************************/

/**
 * @method getCheckpointTrainingListRequest :  To get checkpoint training list
 *
 */
export const getCheckpointTrainingListRequest = (payload, callback) => {
    return {
        type: GET_CHECKPOINT_TRAINING_LIST_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** UPDATE_CHECKPOINT_TRAINING_REQUEST **/
/****************************************************/
/**
 * @method updateCheckpointTrainingRequest :  To update checkpoint training
 *
 */
export const updateCheckpointTrainingRequest = (payload, callback) => {
    return {
        type: UPDATE_CHECKPOINT_TRAINING_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** CLONE_CHECKPOINT_REQUEST **/
/****************************************************/

/**
 * @method cloneCheckpointRequest :  To clone checkpoint not associated with any template
 *
 */
export const cloneCheckpointRequest = (payload, callback) => {
    return {
        type: CLONE_CHECKPOINT_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** CLONE_TEMPLATE_CHECKPOINT_REQUEST **/
/****************************************************/

/**
 * @method cloneTemplateCheckpointRequest :  To clone template checkpoint 
 *
 */
export const cloneTemplateCheckpointRequest = (payload, callback) => {
    return {
        type: CLONE_TEMPLATE_CHECKPOINT_REQUEST,
        payload,
        callback
    }
}

/**
 * @method getCheckpointCollectionRequest : To get checkpoint collection request
 *
 */
export const getCheckpointCollectionRequest = (payload, callback) => {
    return {
        type: GET_CHECKPOINT_COLLECTION_REQUEST,
        payload,
        callback
    }
}

/** 
* @method getCheckpointChildDetailRequest :  To get checkpoint child detail work item
*
*/
export const getCheckpointChildDetailRequest = (payload, callback) => {
    return {
        type: GET_CHECKPOINT_CHILD_DETAIL_REQUEST,
        payload,
        callback
    }
}

/** 
* @method saveCurrentTemplate :  To save currently opened template
*
*/
export const saveCurrentTemplate = (payload) => {
    return {
        type: SAVE_CURRENT_TEMPLATE,
        payload
    }
}

/**
* @method updateActivityOrderIndexRequest : To update activity order index
*
*/
export const updateActivityOrderIndexRequest = (payload, callback) => {
    return {
        type: UPDATE_ACTIVITY_ORDER_INDEX_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** GET_ASSEMBLY_TYPE_COMPONENT_LIST_REQUEST **/
/****************************************************/

/** 
* @method getAssemblyTypeComponentList :  To get components based on assembly type
*
*/
export const getAssemblyTypeComponentListRequest = (payload, callback) => {
    return {
        type: GET_ASSEMBLY_TYPE_COMPONENT_LIST_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** GET_ASSEMBLY_TYPE_DDL_REQUEST **/
/****************************************************/

/** 
* @method getAssemblyTypeDDL :  To get assembly type based on assembly
*
*/
export const getAssemblyTypeDDLRequest = (payload, callback) => {
    return {
        type: GET_ASSEMBLY_TYPE_DDL_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** GET_APPLICABLE_REGULATION_REQUEST **/
/****************************************************/

/**
 * @method getApplicableRegulationList : To get applicable regulation list
 *
 */
export const getApplicableRegulationListRequest = (payload, callback) => {
    return {
        type: GET_APPLICABLE_REGULATION_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** ADD_APPLICABLE_REGULATION_DATA_REQUEST **/
/****************************************************/

/**
 * @method addApplicableRegulation : To add data in applicable regulation 
 *
 */
export const addApplicableRegulationDataRequest = (payload, callback) => {
    return {
        type: ADD_APPLICABLE_REGULATION_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** UPDATE_APPLICABLE_REGULATION_DATA_REQUEST **/
/****************************************************/

/**
 * @method updateApplicableRegulation : To update data in applicable regulation 
 *
 */
export const updateApplicableRegulationDataRequest = (payload, callback) => {
    return {
        type: UPDATE_APPLICABLE_REGULATION_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** DELETE_APPLICABLE_REGULATION_DATA_REQUEST **/
/****************************************************/

/**
 * @method deleteApplicableRegulation : To delete data in applicable regulation 
 *
 */
export const deleteApplicableRegulationDataRequest = (payload, callback) => {
    return {
        type: DELETE_APPLICABLE_REGULATION_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** GET_REFERENCE_STANDARD_DATA_REQUEST **/
/****************************************************/

/**
 * @method getApplicableRegulationList : To get refrence standard list
 *
 */
export const getRefrenceStandardListRequest = (payload, callback) => {
    return {
        type: GET_REFERENCE_STANDARD_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** ADD_REFERENCE_STANDARD_DATA_REQUEST **/
/****************************************************/

/**
 * @method getApplicableRegulationList : To add refrence standard list
 *
 */
export const addRefrenceStandardListRequest = (payload, callback) => {
    return {
        type: ADD_REFERENCE_STANDARD_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** UPDATE_REFERENCE_STANDARD_DATA_REQUEST **/
/****************************************************/

/**
 * @method updateApplicableRegulationList : To update refrence standard list
 *
 */
export const updateRefrenceStandardListRequest = (payload, callback) => {
    return {
        type: UPDATE_REFERENCE_STANDARD_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** DELETE_REFERENCE_STANDARD_DATA_REQUEST **/
/****************************************************/

/**
 * @method deleteApplicableRegulationList : To delete refrence standard list
 *
 */
export const deleteRefrenceStandardListRequest = (payload, callback) => {
    return {
        type: DELETE_REFERENCE_STANDARD_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** GET_INDUSTRY_INTERPRETATIONS_DATA_REQUEST **/
/****************************************************/

/**
 * @method getIndustryInterpretationsListRequest : To get industry interpretations list
 *
 */
export const getIndustryInterpretationsListRequest = (payload, callback) => {
    return {
        type: GET_INDUSTRY_INTERPRETATIONS_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** ADD_INDUSTRY_INTERPRETATIONS_DATA_REQUEST **/
/****************************************************/

/**
 * @method addIndustryInterpretationsListRequest : To add industry interpretations list
 *
 */
export const addIndustryInterpretationsListRequest = (payload, callback) => {
    return {
        type: ADD_INDUSTRY_INTERPRETATIONS_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** UPDATE_INDUSTRY_INTERPRETATIONS_DATA_REQUEST **/
/****************************************************/

/**
 * @method updateIndustryInterpretationsListRequest : To update industry interpretations list
 *
 */
export const updateIndustryInterpretationsListRequest = (payload, callback) => {
    return {
        type: UPDATE_INDUSTRY_INTERPRETATIONS_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** DELETE_INDUSTRY_INTERPRETATIONS_DATA_REQUEST **/
/****************************************************/

/**
 * @method deleteIndustryInterpretationsListRequest : To delete industry interpretations list
 *
 */
export const deleteIndustryInterpretationsListRequest = (payload, callback) => {
    return {
        type: DELETE_INDUSTRY_INTERPRETATIONS_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** UPDATE_APPLICABLE_REGULATION_REORDER_DATA_REQUEST **/
/****************************************************/

/**
 * @method updateApplicableRegulationReorderRequest : To update applicable regulation reorder
 *
 */
export const updateApplicableRegulationReorderRequest = (payload, callback) => {
    return {
        type: UPDATE_APPLICABLE_REGULATION_REORDER_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** UPDATE_INDUSTRY_INTREPRETATIONS_REORDER_DATA_REQUEST **/
/****************************************************/

/**
 * @method updateIndustryInterpretationsReorderRequest : To update industry interpretations reorder
 *
 */
export const updateIndustryInterpretationsReorderRequest = (payload, callback) => {
    return {
        type: UPDATE_INDUSTRY_INTREPRETATIONS_REORDER_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** UPDATE_REFERENCE_STANDARDS_REORDER_DATA_REQUEST **/
/****************************************************/

/**
 * @method updateReferenceStandardsReorderRequest : To update Reference standards reorder
 *
 */
export const updateReferenceStandardsReorderRequest = (payload, callback) => {
    return {
        type: UPDATE_REFERENCE_STANDARDS_REORDER_DATA_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** GET_ACTIVITY_TEMPLATE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getActivityTemplateListRequest : To get template list linked to activity
 *
 */
export const getActivityTemplateListRequest = (payload, callback) => {
    return {
        type: GET_ACTIVITY_TEMPLATE_LIST_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** UPDATE_REQUIRED_ACTIVITY_REQUEST **/
/****************************************************/

/**
 * @method updateRequiredActivityRequest : To update template list linked to required activity
 *
 */
export const updateRequiredActivityRequest = (payload, callback) => {
    return {
        type: UPDATE_REQUIRED_ACTIVITY_REQUEST,
        payload,
        callback
    }
}

/****************************************************/
/** UPDATE_COPY_ALLOWED_ACTIVITY_REQUEST **/
/****************************************************/

/**
 * @method updateCopyAllowedActivityRequest : To update template copy allowed activity
 *
 */
export const updateCopyAllowedActivityRequest = (payload, callback) => {
    return {
        type: UPDATE_COPY_ALLOWED_ACTIVITY_REQUEST,
        payload,
        callback
    }
}