export const GET_CALENDAR_ORGANIZATION_DDL_REQUEST = "GET_CALENDAR_ORGANIZATION_DDL_REQUEST"
export const GET_CALENDAR_SITE_DDL_REQUEST = "GET_CALENDAR_SITE_DDL_REQUEST"
export const GET_CALENDAR_WORKFLOW_DDL_REQUEST = "GET_CALENDAR_WORKFLOW_DDL_REQUEST"
export const GET_CALENDAR_CONTACTS_DDL_REQUEST = "GET_CALENDAR_CONTACTS_DDL_REQUEST"
export const GET_CALENDAR_FILTERS_VM_REQUEST = "GET_CALENDAR_FILTERS_VM_REQUEST"
export const GET_CALENDAR_SCHEDULE_LIST_REQUEST = "GET_CALENDAR_SCHEDULE_LIST_REQUEST"
export const GET_CALENDAR_STATES_DDL_REQUEST = 'GET_CALENDAR_STATES_DDL_REQUEST'

/****************************************************/
/** GET_CALENDAR_ORGANIZATION_DDL_REQUEST **/
/****************************************************/

/**
 * @method getCalendarOrganizationDDLRequest : To get organization ddl for calendar view
 *
 */
export const getCalendarOrganizationDDLRequest = (payload, callback) => ({
    type: GET_CALENDAR_ORGANIZATION_DDL_REQUEST,
    payload,
    callback
})

/****************************************************/
/** GET_CALENDAR_SITE_DDL_REQUEST **/
/****************************************************/

/**
 * @method getCalendarSitesDDLRequest : To get site ddl for calendar view
 *
 */
export const getCalendarSitesDDLRequest = (payload, callback) => ({
  type: GET_CALENDAR_SITE_DDL_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_CALENDAR_WORKFLOW_DDL_REQUEST **/
/****************************************************/

/**
 * @method getCalendarWorkflowsDDLRequest : To get workflow ddl for calendar view
 *
 */
export const getCalendarWorkflowsDDLRequest = (payload, callback) => ({
  type: GET_CALENDAR_WORKFLOW_DDL_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_CALENDAR_CONTACTS_DDL_REQUEST **/
/****************************************************/

/**
 * @method getCalendarContactsDDLRequest : To get contacts ddl for calendar view
 *
 */
export const getCalendarContactsDDLRequest = (payload, callback) => ({
  type: GET_CALENDAR_CONTACTS_DDL_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_CALENDAR_FILTERS_VM_REQUEST **/
/****************************************************/

/**
 * @method getCalendarFiltersVMRequest : To get calendar filters VM
 *
 */
export const getCalendarFiltersVMRequest = (callback = () => { }) => ({
  type: GET_CALENDAR_FILTERS_VM_REQUEST,
  callback
})

/****************************************************/
/** GET_CALENDAR_SCHEDULE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getScheduleCalendarListRequest : To get calendar schedule list
 *
 */
export const getScheduleCalendarListRequest = (payload, callback = () => { }) => ({
  type: GET_CALENDAR_SCHEDULE_LIST_REQUEST,
  payload,
  callback
})

/****************************************************/
/** GET_CALENDAR_STATES_DDL_REQUEST **/
/****************************************************/

/**
 * @method getCalendarStatesDDLRequest : To get calendar states ddl
 *
 */
export const getCalendarStatesDDLRequest = (payload, callback = () => { }) => ({
  type: GET_CALENDAR_STATES_DDL_REQUEST,
  payload,
  callback
})