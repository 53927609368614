import React, { useCallback, useState } from "react"
import { useDispatch } from 'react-redux'

import * as actions from '@store/actions'
import { ToggleButton } from '@views/components'

const AllowCopyActivity = ({ data, templateDetail }) => {
  const dispatch = useDispatch()

  const [isAllowCopy, setAllowCopy] = useState(!!data.isCopyAllowed)

  const handleToggleAllowCopy = useCallback(() => {
    const payload = {
      templateId: templateDetail.templateId,
      templateVersionId: templateDetail.templateVersionId,
      templateActivityList: [
        {
          templateCheckpointId: data.templateCheckpointId,
          isCopyAllowed: !isAllowCopy
        }
      ]
    }
    dispatch(actions.updateCopyAllowedActivityRequest(payload, (res) => {
      if (res) {
        setAllowCopy(!isAllowCopy)
      }
    }))
  }, [data, templateDetail, isAllowCopy])


  return <ToggleButton
    customInputLabelRequired={false}
    id={`temlate_allow_copyactivities-${data.templateCheckpointId}`}
    name={`temlate_allow_copy_activities-${data.templateCheckpointId}`}
    value={!!isAllowCopy}
    onChange={handleToggleAllowCopy}
  />
}

export default AllowCopyActivity