import config from "./APIConfig"
import { getDataApi } from "../index"

export const getActivePrograms = async (data) => {
  const { url } = config.getActivePrograms
  return await getDataApi(url(data))
}

export const getActiveProjectSites = async (data) => {
  const { url } = config.getActiveProjectSites
  return await getDataApi(url(data))
}

export const getActivityTypeListChartWidget = async (data) => {
  const { url } = config.getActivityTypeListChartWidget
  return await getDataApi(url(data))
}

export const getCourseParticipationWidget = async (data) => {
  const { url } = config.getCourseParticipationWidget
  return await getDataApi(url(data))
}

export const getDuctLeakageChartRequest = async (data) => {
  const { url } = config.getDuctLeakageChartWidget
  return await getDataApi(url(data))
}

export const getHouseInfiltrationChartRequest = async (data) => {
  const { url } = config.getHouseInfiltrationChartRequest
  return await getDataApi(url(data))
}

export const getKnowledgeAssessmentChart = async (data) => {
  const { url } = config.getKnowledgeAssessmentChart
  return await getDataApi(url(data))
}

export const getOverviewDetails = async (data) => {
  const { url } = config.getOverviewDetails
  return await getDataApi(url(data))
}

export const getRecentCampaignActivityWidget = async (data) => {
  const { url } = config.getRecentCampaignActivityWidget
  return await getDataApi(url(data))
}

export const getTrainingChartWidget = async (data) => {
  const { url } = config.getTrainingChartWidget
  return await getDataApi(url(data))
}

export const getTrainingChartDelta = async (data) => {
  const { url } = config.getTrainingChartDelta
  return await getDataApi(url(data))
}

export const getUnapprovedRoleChartWidget = async (data) => {
  const { url } = config.getUnapprovedRoleChartWidget
  return await getDataApi(url(data))
}

export const getUnapprovedRoleChartDelta = async (data) => {
  const { url } = config.getUnapprovedRoleChartDelta
  return await getDataApi(url(data))
}

export const getUpcomingObservationTasks = async (data) => {
  const { url } = config.getUpcomingObservationTasks
  return await getDataApi(url(data))
}

export const getReportWidgetStatus = async (data) => {
  const { url } = config.getReportWidgetStatus
  return await getDataApi(url(data))
}
