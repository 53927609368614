import React, { useCallback } from "react"
import { useDispatch } from "react-redux"

import * as actions from "@store/actions"
import CustomCheckboxSearch from "./custom-checkbox-search"

const WorkFlowName = ({
  id = "workflow",
  allSelectCheckboxLabel = "",
  inputPlaceholder,
  valueType = "value",
  labelType = "text",
  value = [],
  organizations,
  sites,
  campaigns,
  isCalendarPageWorkflows = false,
  isResetClicked = false,
  onSelect = () => {},
  onResetClicked = () => {}
}) => {
  const dispatch = useDispatch()

  const handleFetchOptions = useCallback((params, callback = () => { }) => {
    const queryPayload = {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize
    }
    if (isCalendarPageWorkflows) {
      dispatch(actions.getCalendarWorkflowsDDLRequest({...queryPayload, workflowName: params.searchText, organizations, sites, campaigns }, callback))
    } else {
      dispatch(actions.getCampaignWorkflowsRequest({...queryPayload, genericSearch: params.searchText}, callback))
    }
  }, [organizations, sites, campaigns])

  const handleSelectFilter = useCallback(
    (value) => {
      onSelect(id, value)
    },
    [onSelect]
  )

  return (
    <CustomCheckboxSearch
      id={id}
      valueType={valueType}
      labelType={labelType}
      allSelectCheckboxLabel={allSelectCheckboxLabel}
      inputPlaceholder={inputPlaceholder}
      onFetchOptions={handleFetchOptions}
      onSelectFilter={handleSelectFilter}
      onResetClicked={onResetClicked}
      selectedFilters={value}
      isResetClicked={isResetClicked}
    />
  )
}

export default WorkFlowName
