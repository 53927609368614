import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method createAuditTrail
 * @param {object} data
 * {
  {
  "observationTaskId": 0,
  "auditTrailId": 0,
  "wfActionId": 0,
  "noteTitle": "string",
  "noteText": "string",
  "responseImages": [
    {
      "fileName": "string",
      "imageContent": "string",
      "imageId": 0,
      "jsonImageMetaData": "string",
      "isDeleted": true,
      "isExternal": true,
      "externalFileUrl": "string",
      "externalResources": 1
    }
  ]
}
 * 
 *
 */
export const createAuditTrail = (payload) => {
  const { url } = config.createAuditTrail

  return postDataApi(url(payload), payload.data)
}

export default createAuditTrail
