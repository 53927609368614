import cx from 'classnames'
import React, { useCallback, useState, useMemo, useEffect } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap"
import { X, ChevronRight } from "react-feather"
import { useDispatch } from 'react-redux'
import "@styles/react/libs/flatpickr/flatpickr.scss"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from '@store/actions'
import { getLocaleMessage } from "@utils"
import { AddNotesAndMediaModal, DeleteConfirmationModal, CustomUILoader, SideModalCloseButton } from "@views/components"
import LocaleMessageWrapper from "@views/components/locale-message"
import AuditTrailItem from "./audit-trail-item"
import { getBlockTimelineColor } from "./helpers"
import { getUserTimezoneBasedDateTime } from '../../helpers'

const {
  NO_AUDIT_TRAILS,
  ADD_NOTES_MEDIA,
  ACTIONS_PAGE: { AUDIT_TRAIL }
} = LANGUAGE_CONSTANTS

const AuditTrailModal = ({
  intl,
  data,
  isModalCentered = false,
  selectedTaskId,
  isOpen,
  onToggleModal = () => { },
  onRefreshTaskList = () => { }
}) => {

  const dispatch = useDispatch()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  const [isOpenAddNotesMediaModal, setIsOpenAddNotesMediaModal] = useState(false)
  const [auditTrailDetails, setAuditTrailDetails] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [noteIdToDelete, setNoteIdToDelete] = useState(null)
  const [auditTrailId, setAuditTrailId] = useState(null)
  const [editDetails, setEditDetails] = useState(null)
  const [isAuditNoteAdded, setAuditNoteAdded] = useState(false)

  const handleFetchAuditTrail = useCallback(() => {
    setIsFetching(true)
    const payload = {
      observationTaskId: !!data.observationTaskId ? data.observationTaskId : selectedTaskId,
      assignmentActivityId: data.assignmentId
    }

    dispatch(actions.getAuditTrailModalData(payload, (res) => {
      if (res) {
        setAuditTrailDetails(res)
      }
      setIsFetching(false)
    }))
  }, [data])

  useEffect(() => {
    if (data.observationTaskId || selectedTaskId) {
      handleFetchAuditTrail()
    }
  }, [data, selectedTaskId, handleFetchAuditTrail])

  const colorCode = useMemo(() => getBlockTimelineColor(), [])

  const handleToggleAddNotesMediaModal = useCallback(({ id } = {}) => {
    if (id) {
      setAuditTrailId(id)
    }
    if (isOpenAddNotesMediaModal) {
      handleFetchAuditTrail()
    }
    setIsEditMode(false)
    setIsOpenAddNotesMediaModal(prevState => !prevState)
  }, [isOpenAddNotesMediaModal, handleFetchAuditTrail])

  const handleToggleEditMode = useCallback((data, auditTrailId) => {
    setAuditTrailId(auditTrailId)
    setEditDetails(data)
    setIsEditMode(true)
    setIsOpenAddNotesMediaModal(prevState => !prevState)
  }, [])

  const handleToggleDeleteModal = useCallback(() => {
    setIsOpenDeleteModal((prev) => !prev)
  }, [])

  const handleDeleteNote = useCallback((id) => {
    handleToggleDeleteModal()
    setNoteIdToDelete(id)
  }, [handleToggleDeleteModal])

  const handleConfirmDelete = useCallback(() => {
    setIsFetching(true)
    dispatch(actions.deleteAuditTrailRequest({ noteId: noteIdToDelete }, (res) => {
      if (res) {
        handleFetchAuditTrail()
      }
      setIsFetching(false)
    }))
    handleToggleDeleteModal()
  }, [noteIdToDelete, handleToggleDeleteModal, handleFetchAuditTrail])

  const handleToggleModal = useCallback(() => {
    onToggleModal()
    if (isAuditNoteAdded) {
      onRefreshTaskList()
    }
  }, [isAuditNoteAdded, onRefreshTaskList, onToggleModal])

  return (
    <Modal
      isOpen={isOpen}
      className={`sidebar-sm ${isModalCentered ? "modal-dialog-centered all-modal modal-dialog" : ""
        }`}
      modalClassName={isModalCentered ? "" : "modal-slide-in note-media-modal "}
      contentClassName="p-0"
    >
      <ModalHeader
        close={<SideModalCloseButton onClose={handleToggleModal} />}
        tag="div"
      >
        <h5 className="modal-title">{data.checkListName || data.task || data.taskName || data.activityName}
          <ChevronRight className="right-arrow" size={20} />
          {`${getLocaleMessage(
            intl,
            AUDIT_TRAIL
          )}`}</h5>
      </ModalHeader>
      <ModalBody className="flex-grow-1">
        <CustomUILoader isLoading={isFetching}>
          {isOpenDeleteModal && <DeleteConfirmationModal
            open={isOpenDeleteModal}
            onToggleModal={handleToggleDeleteModal}
            onConfirmDeleteRecord={handleConfirmDelete}
            type={AUDIT_TRAIL}
          />}
          {isOpenAddNotesMediaModal && <AddNotesAndMediaModal
            data={data}
            auditTrailId={auditTrailId}
            isEditMode={isEditMode}
            selectedTaskId={selectedTaskId}
            editDetails={editDetails}
            onAuditNoteAdded={setAuditNoteAdded}
            onToggleModal={handleToggleAddNotesMediaModal}
          />}
          {!isFetching && !auditTrailDetails.length &&
            <>
              <div className='text-right'>
                <button className="primary-text-btn waves-effect btn" onClick={handleToggleAddNotesMediaModal}>{getLocaleMessage(
                  intl,
                  ADD_NOTES_MEDIA
                )}</button>
              </div>
              <p className='no-data-block'>{getLocaleMessage(
                intl,
                NO_AUDIT_TRAILS
              )}</p>
            </>}
          <ul className={cx("timeline")}>
            {auditTrailDetails.map((item, index) => {

              const formattedDateTimeZone = getUserTimezoneBasedDateTime(item.insertedDate)

              return <li className="stepline-name timeline-item" key={`audit-trail-${item.auditTrailId}-${item.status}`}>
                <div className="audit-trail-header">
                  <h3>{getLocaleMessage(
                    intl,
                    AUDIT_TRAIL
                  )}:<span className='ml-1'>{item.status}</span></h3>
                  <div className='d-flex'>
                    {index === 0 && <button className="primary-text-btn waves-effect mr-4 btn" onClick={() => handleToggleAddNotesMediaModal({ id: item.auditTrailId })}>{getLocaleMessage(
                      intl,
                      ADD_NOTES_MEDIA
                    )}</button>}
                    <div className="audit-trail-details">
                      <span className="submitter">{item.contactName}</span>
                      <span className="creater">{formattedDateTimeZone}</span>
                    </div>
                  </div>
                </div>
                <AuditTrailItem
                  intl={intl}
                  data={item}
                  isCurrentStatus={index === 0}
                  onToggleEditMode={handleToggleEditMode}
                  onToggleDeleteModal={handleDeleteNote} />
                <span
                  className={cx(
                    "timeline-point timeline-point-indicator",
                    {
                      [`timeline-point-${colorCode}`]: colorCode
                    }
                  )}
                />
              </li>
            })}
          </ul>
        </CustomUILoader>
      </ModalBody>
    </Modal>
  )
}

export default LocaleMessageWrapper(AuditTrailModal)

