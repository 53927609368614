import config from "./APIConfig"
import { postDataApi } from '../index'

export const uploadUserProfileImage = (data) => {
  const { url } = config.uploadUserProfileImage
  const payload = {
    fileName: data.fileName,
    imageContent: data.imageContent,
    contactId: data.contactId
    }
 
  return postDataApi(url(), payload)
}

export default uploadUserProfileImage
