import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { asyncForEach } from "@utils"
import { LocaleMessage } from '@views/components'

export const validatePurchaseOrderDetails = ({
  additionalPOName,
  costCategory,
  billingType
}) => {
  const errors = {}

  if (!additionalPOName) {
    errors['additionalPOName'] = <LocaleMessage id={LANGUAGE_CONSTANTS.SCHEDULING.PURCHASE_ORDER_ERROR.PLEASE_SELECT_ADDITIONAL_PO} />
  }

  if (!costCategory) {
    errors['costCategory'] = <LocaleMessage id={LANGUAGE_CONSTANTS.SCHEDULING.PURCHASE_ORDER_ERROR.PLEASE_SELECT_COST_CATEGORY} />
  }

  if (!billingType) {
    errors['billingType'] = <LocaleMessage id={LANGUAGE_CONSTANTS.SCHEDULING.PURCHASE_ORDER_ERROR.PLEASE_SELECT_BILLING_TYPE} />
  }

  return errors
}

export const getPurchaseOrderDetailActiveTaskText = ({
  activeTask,
  taskList
}) => {
  if (taskList.length > 1) {
    const activeTaskId = activeTask.observationTaskId
    const activeTaskIndex = taskList.findIndex(
      (task) => task.observationTaskId === activeTaskId
    )
    if (activeTaskIndex !== -1) {
      return ` (${activeTaskIndex + 1}/${taskList.length})`
    }
  }

  return ""
}

export const getFormattedPurchaseOrderDetails = (purchaseOrderDetail) => {
  return purchaseOrderDetail.map((detail) => ({
    additionalPOId: detail.additionalPurchaseOrderId,
    costCategory: { value: String(detail.costCategoryId), text: detail.costCategoryName },
    billingType: { value: String(detail.billingTypeId), text: detail.billingTypeName },
    additionalPOName: detail.additionalPurchaseOrderNumber
  }))
}

const handleUploadFile = (payload, dispatch, callback) => {
  dispatch(
    actions.uploadRCDocumentRequest(payload, (data) => {
      callback(!!data)
    })
  )
}

export const uploadAttachedPOFiles = ({
  attachedPOFiles,
  observationTaskId,
  fileLocationType,
  additionalPOId,
  dispatch,
  callback = () => { }
}) => {
  const filesToBeUploaded = attachedPOFiles.filter(f => !f.resourceCenterId)
  if (filesToBeUploaded.length) {
    const fileList = filesToBeUploaded.map((f) => {
      return {
        ...f,
        fileLocationType,
        fileSourceName: "ObservationTaskId",
        fileSourceId: observationTaskId,
        additionalPOId
      }
    })
    const lastFileIndex = fileList.length - 1

    asyncForEach(fileList, async (sFile, index) => {
      const payload = {
        ...sFile,
        isLoadingNotRequired: false
      }
      const isLastFile = index === lastFileIndex
      handleUploadFile(payload, dispatch, () => {
        if (isLastFile) {
          callback()
        }
      })
    })
  } else {
    callback()
  }
}
