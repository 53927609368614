import { TOGGLE_PROJECT_SITE_UNIT_EDIT_MODE } from '../../actions'

// ** Initial State
const initialState = {
  isEditMode: false,
  currentUnitDetail: null
}

const ProjectSiteUnitsReducer = (state = initialState, action) => {
  switch (action.type) {
    /** TOGGLE_PROJECT_SITE_UNIT_EDIT_MODE */
    case TOGGLE_PROJECT_SITE_UNIT_EDIT_MODE:
      return {
        ...state, 
        isEditMode: action.payload.isEditMode, 
        currentUnitDetail: action.payload.currentUnitDetail
      }

    default:
      return state
  }
}

export default ProjectSiteUnitsReducer
