import config from "./APIConfig"
import { postDataApi } from '../index'

export const startChecklistActivity = (data) => {
  const { url } = config.startChecklistActivity

  return postDataApi(url(data), data)
}

export default startChecklistActivity
