import config from "./APIConfig"
import { putDataApi } from "../index"


export const updateReassignSignOff = async (data) => {
  const { url } = config.updateReassignSignOff
  return await putDataApi(url(data), data)
}

export default updateReassignSignOff
