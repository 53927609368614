// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { getActionType } from '../action-type-generator'

export const LOG_IN = getActionType('LOG_IN')
export const SIGN_UP = getActionType('SIGN_UP')
export const FORGOT_PASSWORD = getActionType('FORGOT_PASSWORD')
export const GET_USER_PROFILE = getActionType('GET_USER_PROFILE')
export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST'
export const SET_USER_AUTH = 'SET_USER_AUTH'
export const UPDATE_USER_AUTH = 'UPDATE_USER_AUTH'
export const LOGOUT = 'LOGOUT'
export const SET_ACCESS_DENIED = 'SET_ACCESS_DENIED'
export const RESET_ACCESS_DENIED = 'RESET_ACCESS_DENIED'
export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST'
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE'
export const SET_USER_PROFILE_IMAGE = 'SET_USER_PROFILE_IMAGE'
export const TOGGLE_USER_PROFILE_IMAGE_UPDATES = 'TOGGLE_USER_PROFILE_IMAGE_UPDATES'

// ** Handle User Login
export const setUserAuth = data => {
  const config = useJwt.jwtConfig
  return {
    type: SET_USER_AUTH,
    data,
    config,
    [config.storageTokenKeyName]: data[config.storageTokenKeyName],
    [config.storageRefreshTokenKeyName]: data[config.storageRefreshTokenKeyName]
  }
}

// ** Handle Update User Login data
export const updateUserAuth = data => {
  const config = useJwt.jwtConfig
  return {
    type: UPDATE_USER_AUTH,
    data,
    config,
    [config.storageTokenKeyName]: data[config.storageTokenKeyName],
    [config.storageRefreshTokenKeyName]: data[config.storageRefreshTokenKeyName]
  }
}

// ** Handle User Logout
export const logoutUser = () => {
  return { type: SIGNOUT_REQUEST }
}

/****************************************************/
/** SIGN_UP_REQUEST **/
/****************************************************/

/**
 * @method signupRequest : To sign up user
 *
 */
export const signupRequest = (payload, callback) => {
  return {
    type: SIGN_UP.REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** LOGIN_IN_REQUEST **/
/****************************************************/

/**
 * @method loginRequest : To authenticate user
 *
 */
export const loginRequest = (payload, callback) => {
  return {
    type: LOG_IN.REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** FORGOT_PASSWORD_REQUEST **/
/****************************************************/

/**
 * @method forgotPasswordRequest : To send send verification email for change password
 *
 */
export const forgotPasswordRequest = (payload, callback) => {
  return {
    type: FORGOT_PASSWORD.REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_USER_PROFILE **/
/****************************************************/

/**
 * @method getUserProfileRequest : To get data of the user profile
 *
 */
export const getUserProfileRequest = (payload, callback) => {
  return {
    type: GET_USER_PROFILE.REQUEST,
    payload,
    callback
  }
}

/**
* @method getUserProfileSuccess : To set data of the user profile on success
*
*/
export const getUserProfileSuccess = (data) => {
  return {
    type: GET_USER_PROFILE.SUCCESS,
    data
  }
}

/**
* @method getUserProfileFailure : To set error on getting user profile
*
*/
export const getUserProfileFailure = (error) => {
  return {
    type: GET_USER_PROFILE.FAILURE,
    error
  }
}


/****************************************************/
/** UPDATE_USER_PROFILE_REQUEST **/
/****************************************************/

/**
 * @method updateUserProfileRequest : To update profile detail of the user
 *
 */
export const updateUserProfileRequest = (payload, callback) => {
  return {
    type: UPDATE_USER_PROFILE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SET_ACCESS_DENIED **/
/****************************************************/

/**
* @method setAccessDenied : To set user access denied when user token has expired
*/
export const setAccessDenied = () => {
  return {
    type: SET_ACCESS_DENIED
  }
}

/****************************************************/
/** RESET_ACCESS_DENIED **/
/****************************************************/

/**
* @method resetAccessDenied : To reset user access denied state when logout 
*/
export const resetAccessDenied = () => {
  return {
    type: RESET_ACCESS_DENIED
  }
}


/****************************************************/
/** SET_USER_LANGUAGE **/
/****************************************************/

/**
 * @method setUserLanguage : To set user language
 *
 */
export const setUserLanguage = (payload) => ({
  type: SET_USER_LANGUAGE,
  payload
})

/****************************************************/
/** SET_USER_PROFILE_IMAGE **/
/****************************************************/

/**
 * @method setUserProfileImage : To set user profile image
 * 
 * @param data : 
 * 
 * {
 *  imageUrl: 'url_here'
 * }
 *
 */
 export const setUserProfileImage = (data) => ({
  type: SET_USER_PROFILE_IMAGE,
  data
})

/****************************************************/
/** TOGGLE_USER_PROFILE_IMAGE_UPDATES **/
/****************************************************/

/**
 * @method toggleUserProfileImageUpdates : To set/unset user profile image update processing
 * 
 * @param {boolean} data
 *
 */
 export const toggleUserProfileImageUpdates = (data) => ({
  type: TOGGLE_USER_PROFILE_IMAGE_UPDATES,
  data
})