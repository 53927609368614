const DisabledComponentOverlay = ({ children, disabled }) => {
    return (
      <div className="disabled-overlay">
        {children}
        {disabled && (
          <div className="disabled-overlay-inner"
          />
        )}
      </div>
    )
  }

  export default DisabledComponentOverlay