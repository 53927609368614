import config from "./APIConfig"
import { putDataApi } from '../index'

export const upsertCheckpointDetails = async (data) => {
  const { url } = config.upsertCheckpointDetails
  const payload = {
    checkPointId: data.checkPointId || 0,
    checkpointVersionId: data.checkpointVersionId || 0,
    stageOfConstructionId: data.stageOfConstructionId || 0,
    scopeOfWorkId: data.scopeOfWorkId || 0,
    responsibleSiteRoleId: data.responsibleSiteRoleId || 0,
    riskLevelId: data.riskLevelId || 0,
    goveranceRegulation: data.goveranceRegulation || '',
    systemCategoryId: data.systemCategoryId || 0,
    assemblyId: data.assemblyId || 0,
    manufacturerId: data.manufacturerId || 0,
    brandId: data.brandId || 0,
    holdProduction: data.holdProduction,
    alwaysReinspect: data.alwaysReinspect
  }

  if (data.componentIds) {
    payload.componentIds = data.componentIds.map((component) => {
      return {
        componentId: component
      }
    })
  }

  if (data.subComponentIds) {
    payload.subComponentIds = data.subComponentIds.map((sub) => {
      return {
        subComponentId: sub
      }
    })
  }

  if (data.assemblyTypeIds) {
    payload.assemblyTypeIds = data.assemblyTypeIds.map((assembly) => {
      return {
        assemblyTypeId: assembly
      }
    })
  }

  return await putDataApi(url({ checkpointId: data.checkPointId, checkpointVersionId: data.checkpointVersionId }), payload)
}

export default upsertCheckpointDetails