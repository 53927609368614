import config from "./APIConfig"
import { postDataApi } from "../index"

/**
 * 
 * @param {Object} data 
 * Data contains following
 * {
  "checkPointName": "string",
  "checkPointNameSpanish": "string",
  "trainingContentUrl": "string",
  "lmsId": 0,
  "subjectId": 0,
  "courseId": 0,
  "chapterId": 0,
  "lessonId": 0,
  "statusId": 0,
  "stageOfConstructionId": 0,
  "sourceId": 0,
  "trainingVersionId": "string",
  "trainingVersionDate": "2021-12-31T09:04:58.636Z",
  "authorId": 0,
  "trainingDescription": "string",
  "originatedFrom": "string",
  "originatedFromId": 0
}
 */

export const addTemplateTrainingActivity = async (data) => {
  const { url } = config.addTemplateTrainingActivity
  return await postDataApi(url, data)
}

export default addTemplateTrainingActivity
