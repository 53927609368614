import config from "./APIConfig"
import { postDataApi } from '../index'

export const addOrganizationContact = (data) => {
  const { url } = config.addOrganizationContact
  const payload = {
    firstName: data.firstName || '',
    lastName: data.lastName || '',
    organizationID: data.organizationID || 0,
    email: data.email || '',
    phoneNumber: data.phoneNumber || '',
    accountAccessId: data.accountAccessId || 0,
    sitePartyRoleId: Number(data.sitePartyRoleId) || 0,
    entityId: 1,
    title: data.title || "",
    streetAddress: data.streetAddress || "",
    city: data.city || "",
    state: data.state || "",
    postCode: data.postCode || "",
    countryCode: data.countryCode || ""
  }
  if (data.title) {
    payload.title = data.title
  }

  return postDataApi(url, payload)
}

export default addOrganizationContact
