import React, { useState, useCallback } from 'react'
import { Modal, ModalBody, ModalHeader, Col, Row, Input, Button, FormGroup } from 'reactstrap'
import { ChevronRight } from "react-feather"
import { useDispatch } from "react-redux"

import { TABLE_LIST_KEYS } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { CustomLabel, SideModalCloseButton } from '@views/components'
import localeMessageWrapper from "@views/components/locale-message"


const { SCHEDULING, SAVE, CANCEL } = LANGUAGE_CONSTANTS

const EditOrderDetailModal = ({
  intl,
  onToggleModal,
  orderDetails,
  title
}) => {
  const dispatch = useDispatch()
  const [data, setData] = useState(orderDetails)

  const handleChangeOrderDetails = useCallback((event) => {
    const { name, value } = event.target
    setData(prevState => {
      return { ...prevState, [name]: value }
    })
  }, [])

  const handleCloseModal = useCallback(() => {
    onToggleModal()
  }, [onToggleModal])

  const handleSubmit = useCallback(() => {
    const { observationTaskId, primaryWONumber, secondaryWONumber, workOrderId } = data
    const payload = {
      observationTaskId,
      primaryWONumber,
      secondaryWONumber
    }

    const responseCallback = (res) => {
      if (res) {
        dispatch(
          actions.refreshTableList({
            listKey: TABLE_LIST_KEYS.SCHEDULING_CONTRACT_ORDER_DETAILS_LIST_KEY,
            value: true
          }))
        handleCloseModal()
      }
    }

    if (!!workOrderId) {
      // To update work order details for selected task
      dispatch(actions.updateWorkOrderRequest(payload, responseCallback))
    } else {
      // To add work order details for selected task
      dispatch(actions.setWorkOrderRequest(payload, responseCallback))
    }
  }, [data, handleCloseModal])

  return (
    <Modal
      isOpen
      className={"sidebar-sm"}
      modalClassName={"modal-slide-in note-media-modal schedule-time-modal"}
      contentClassName="p-0"
    >
      <ModalHeader
        toggle={handleCloseModal}
        close={<SideModalCloseButton onClose={handleCloseModal} />}
        tag="div"
        className={"edit-order-detail-modal-header"}
      >
        <h5 className="modal-title">{data.checkListName}
          <ChevronRight size={12} />
          {title}
        </h5>
      </ModalHeader>
      <ModalBody className="flex-grow-1">
        <Row>
          <Col className='mb-1 select-time' sm={12} md={12}>
            <FormGroup>
              <CustomLabel title={SCHEDULING.TASK_NAME} />
              <Input
                id='checkListName'
                name='checkListName'
                disabled
                placeholder={getLocaleMessage(
                  intl,
                  SCHEDULING.TASK_NAME
                )}
                value={data.checkListName}
                onChange={() => { }}
              />
            </FormGroup>
          </Col>
          <Col className='mb-1 select-time' sm={12} md={12}>
            <FormGroup>
              <CustomLabel title={SCHEDULING.PURCHASE_ORDER} />
              <Input
                id='primaryWONumber'
                name='primaryWONumber'
                placeholder={getLocaleMessage(
                  intl,
                  SCHEDULING.PURCHASE_ORDER
                )}
                value={data.primaryWONumber}
                onChange={handleChangeOrderDetails}
              />
            </FormGroup>
          </Col>
          <Col className='mb-1 select-time' sm={12} md={12}>
            <FormGroup>
              <CustomLabel title={SCHEDULING.EXTRA_WORK_ORDER} />
              <Input
                id='secondaryWONumber'
                name='secondaryWONumber'
                placeholder={getLocaleMessage(
                  intl,
                  SCHEDULING.EXTRA_WORK_ORDER
                )}
                value={data.secondaryWONumber}
                onChange={handleChangeOrderDetails}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Button
              outline
              onClick={handleCloseModal}
              className={"delete-button secondary-outlined w-100"}
            >
              {getLocaleMessage(intl, CANCEL)}
            </Button>
          </Col>
          <Col md={6}>
            <Button
              className={"primary-solid w-100"}
              color="primary"
              onClick={handleSubmit}
            >
              {getLocaleMessage(intl, SAVE)}
            </Button>

          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default localeMessageWrapper(EditOrderDetailModal)