import { GET_CONTACT_LIST, RESET_CONTACT_LIST, SET_EDIT_CONTACT_MODE } from "../../actions"

import { DEFAULT_TABLE_PAGE_SIZE } from '@shared/constants'

// ** Initial State
const initialState = {
  contactList: [],
  pageNumber: 0,
  pageSize: DEFAULT_TABLE_PAGE_SIZE,
  totalCount: 0,
  totalPages: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  isEditMode: false,
  contactDetail: null
}

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {

    /** GET_CONTACT_LIST **/

    case GET_CONTACT_LIST.SUCCESS:
      return {
        ...state,
        contactList: action.data.items,
        pageSize: action.data.pageSize,
        pageNumber: action.data.pageIndex,
        totalCount: action.data.totalCount,
        totalPages: action.data.totalPages,
        hasPreviousPage: action.data.hasPreviousPage,
        hasNextPage: action.data.hasNextPage
      }

    /** RESET_CONTACT_LIST **/
    case RESET_CONTACT_LIST:
      return {
        ...state,
        contactList: [],
        pageNumber: 0,
        pageSize: DEFAULT_TABLE_PAGE_SIZE,
        totalCount: 0,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false
      }

    /** SET_EDIT_CONTACT_MODE **/

    case SET_EDIT_CONTACT_MODE:
      return {
        ...state,
        isEditMode: action.payload.isEditMode,
        contactDetail: action.payload.contactDetail
      }

    default:
      return state
  }
}

export default contactsReducer
