import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addTrainingToCheckpoint
 * @param {object} data 
 *
 */
export const addTrainingToCheckpoint = (data) => {
  const { url } = config.addTrainingToCheckpoint

  return postDataApi(url(data))
}

export default addTrainingToCheckpoint
