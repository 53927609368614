import React, { useCallback, useEffect, useState } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import * as actions from "@store/actions"
import { CustomReactSelect, CustomLabel, ErrorMessage } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const CostCategory = ({
  id = "",
  name = "",
  value = "",
  initialLabel = "",
  observationTaskId = "",
  label = "",
  errors,
  placeholder = "",
  isRequired = false,
  defaultOptions,
  onSelect = () => { }
}) => {

  const dispatch = useDispatch()
  const [options, setOptions] = useState([])
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  useEffect(() => {
    const fn = () => {
      setTimeout(() => {
        setResetCache((prevState) => !prevState)
        setOptions([])
        if (!!initialLabel && !!value) {
          setSelectedOptionLabel(initialLabel)
        }
      }, [100])
    }
    fn()
  }, [initialLabel, observationTaskId])

  const handleLoadMore = useCallback((search, page, prevOptions, others) => {
    return new Promise((resolve) => {
      if (others.isReset && !prevOptions.length && options.length) {
        resolve({
          optionList: options,
          hasMore: true
        })
        return
      }
      if (observationTaskId) {
        const params = {
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE,
          observationTaskId
        }
        dispatch(
          actions.getCostCategoryDDLRequest(params, (response) => {
            if (response) {
              const { items, hasNextPage } = response
              setOptions(() => (!!prevOptions.length ? [...prevOptions, ...items] : items))
              resolve({
                optionList: items,
                hasMore: hasNextPage
              })
            } else {
              resolve({
                optionList: [],
                hasMore: false
              })
            }
          })
        )
      } else {
        resolve({
          optionList: [],
          hasMore: false
        })
      }
    })
  }, [observationTaskId])

  useEffect(() => {
    if (!!selectedOptionLabel?.length && !value) {
      setSelectedOptionLabel("")
    }
  }, [selectedOptionLabel, value])

  const handleSelect = useCallback(
    (selected) => {
      onSelect(id, selected)
    },
    [id, onSelect]
  )

  return (
    <Col md={12}>
      <CustomLabel title={label} requiredIconClassName="required-field" required={isRequired} />
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id={id}
        name={name}
        placeholder={placeholder}
        options={options}
        lableType={"text"}
        valueType={"value"}
        defaultOptions={defaultOptions}
        isFormattedValue
        value={!!value ? value : null}
        isMulti={false}
        isShowCustomAction={false}
        hideSelectedOptions={false}
        getOptionLabel={(option) => option.text}
        getOptionValue={(option) => option.value}
        onLoadOptions={handleLoadMore}
        onSelect={handleSelect}
      />
      {errors && <ErrorMessage isShow={!!errors[id]} message={errors[id]} className="error-message" />}
    </Col>
  )
}

export default localeMessageWrapper(CostCategory)