import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addCheckpointToCheckList
 * @param {object} data 
 *  {
      "campaignServiceCheckListId": 0,
      "templateId": 0,
      "templateVersionId": 0,
      "checkpointIds": [
        0
      ],
      "responsibleSiteRoleId": 0,
      "isReviewActivity": false,
      "byTemplateId": 0
    }
 */

export const addCheckpointToCheckList = (data) => {
  const { url } = config.addCheckpointToCheckList

  return postDataApi(url(data.campaignServiceCheckListId), data)
}

export default addCheckpointToCheckList
