import React, { useCallback } from "react"
import {
  Row,
  Col,
  Button,
  FormGroup,
  Input
} from 'reactstrap'

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { ErrorMessage, ReactSelect, DatePicker, CustomLabel } from '@views/components'
import localeMessageWrapper from '@views/components/locale-message'

const { SERVICES } = LANGUAGE_CONSTANTS

function TimeIncrement({
  intl,
  errors,
  control,
  isViewMode,
  serviceDetail,
  campaignTimeIncrementType,
  onChangeServiceDetails,
  onCustomAdvanceSearch
}) {

  const handleTimeIncrementRange = useCallback((operation) => {
    const { timeIncrement } = serviceDetail
    if (timeIncrement === '') return
    let value = parseInt(timeIncrement)

    if (value) {
      value = parseInt(value)
    }

    if (operation === 'increment') {
      value += 1
    } else if (operation === 'decrement') {
      if (value > 0) value -= 1
    }
    onChangeServiceDetails('timeIncrement', value, (data) => { onCustomAdvanceSearch(null, true, data) })
  }, [serviceDetail.timeIncrement, onChangeServiceDetails])

  const handleTimeIncrement = useCallback((event) => {
    let value = event.target.value
    if (value) {
      value = parseInt(value)
    }
    if (value > 0 || !value) {
      onChangeServiceDetails('timeIncrement', value)
    }
  }, [serviceDetail.timeIncrement, onChangeServiceDetails])

  return <>
    <div className={`mb-1 col-sm-12 col-md-6 col-xl-4 `} >
      <Row>
        <Col md={12} lg={12} xl={12}>
          <Row>
            <Col sm={12} md={5} lg={4} xl={6}>
              <FormGroup>
                <CustomLabel className="time-increment-label" title={SERVICES.TIME_INCREMENT} required />
                <div className="d-flex range-input add-service-range-input">
                  <div className="range-box">
                    {!isViewMode && <Button
                      className="common-button"
                      onClick={() => handleTimeIncrementRange('decrement')}
                    >
                      -
                    </Button>}
                    <Input
                      type="number"
                      name="timeIncrement"
                      value={serviceDetail.timeIncrement}
                      onChange={(event) => handleTimeIncrement(event)}
                      onKeyDown={onCustomAdvanceSearch}
                    />
                    {!isViewMode && <Button
                      className="common-button"
                      onClick={() => handleTimeIncrementRange('increment')}
                    >
                      +
                    </Button>}
                  </div>
                </div>
              </FormGroup>
            </Col>
            <ErrorMessage isShow={!!errors['timeIncrement']} message={errors['timeIncrement']} />
            <Col className='mb-1 mt-2' sm={12} md={7} lg={8} xl={6}>
              <ReactSelect
                id='timeIncrementTypeId'
                name='timeIncrementTypeId'
                control={control}
                value={serviceDetail.timeIncrementTypeId}
                options={campaignTimeIncrementType}
                onSelect={(selected) => onChangeServiceDetails('timeIncrementTypeId', selected?.value || '', (data) => { onCustomAdvanceSearch(null, true, data) })}
                getOptionLabel={(item) => item.text}
                getOptionValue={(item) => item.value}
                placeHolder={getLocaleMessage(intl, SERVICES.SELECT_TYPE)}
                isDisabled={isViewMode}
              />
              <ErrorMessage
                isShow={!!errors['timeIncrementTypeId']}
                message={errors['timeIncrementTypeId']}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
    <Col className='mb-1' sm={12} md={6} xl={4}>
      <CustomLabel title={SERVICES.START_INTERVAL_ON} />
      <DatePicker
        id='startIntervalOn'
        placeholder={SERVICES.SELECT_DATE}
        value={serviceDetail.startIntervalOn}
        onChange={(selectedDate) => onChangeServiceDetails('startIntervalOn', selectedDate)}
        isClearable={!!serviceDetail.startIntervalOn}
      />
    </Col>
  </>
}

export default localeMessageWrapper(TimeIncrement)
