import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle, useMemo, useRef } from "react"
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap"
import { useDispatch } from "react-redux"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage, cloneArrayOfObject } from "@utils"
import { SideModalCloseButton } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import { FILTER_SECTION_TYPE } from "./config"
import SaveAsNewFilter from "./SaveAsNewFilter"
import SavedFilters from "./SavedFilters"
import TableColumn from "./TableColumn"

const { CANCEL, FILTER_TABLE_MODAL } = LANGUAGE_CONSTANTS

const initialFilterConfigState = {
  filterName: "",
  isGlobal: false,
  isDefault: false
}

const FilterTableModal = ({
  intl,
  appliedFilter = null,
  appliedTableColumnConfig = [],
  defaultTableColumnConfig = [],
  defaultRowsPerPage,
  searchFilterKey,
  isTableColumnRequired = true,
  searchComponentRef = {},
  isRowsPerPageConfigRequired = true,
  isSavedFilterConfigRequired = true,
  onApplySelections = () => { },
  getSearchFilterComponent = () => { },
  onResetAllAppliedFilters = () => { },
  onToggleModal = () => { },
  onSetRefreshSavedFilterOptions = () => { }
}, ref) => {
  const dispatch = useDispatch()

  const filterTableRef = useRef({})

  const initailRowsPerPage = useMemo(() => {
    if (!isRowsPerPageConfigRequired && !defaultRowsPerPage) {
      return null
    }

    return { text: defaultRowsPerPage, value: defaultRowsPerPage }
  }, [defaultRowsPerPage, isRowsPerPageConfigRequired])

  const [filterOptions, setFilterOptions] = useState([]) //Saved Filters
  const [columns, setColumns] = useState(appliedTableColumnConfig.length ? appliedTableColumnConfig : defaultTableColumnConfig)
  const [rowsPerPage, setRowsPerPage] = useState(initailRowsPerPage)
  const [filterConfig, setFilterConfig] = useState(Object.assign({}, initialFilterConfigState))
  const [expandedViewId, setExpandedViewId] = useState('')
  const [isEditMode, setEditMode] = useState(false)
  const [isFetchingFilters, setFetchingFilters] = useState(true)
  const [selectedFilterId, setSelectedFilterId] = useState("")
  const [selectedFilterName, setSelectedFilterName] = useState("")
  const [isRefreshSavedFilters, setRefreshSavedFilters] = useState(false)
  const [isAppliedFilterDeleted, setAppliedFilterDeleted] = useState(false)
  const [isAppliedFilterUpdated, setAppliedFilterUpdated] = useState(false)
  const [isResetAppliedFilter, setResetAppliedFilter] = useState(false)

  const handleSetEditMode = useCallback((value) => {
    setEditMode(value)
    filterTableRef.current.isEditMode = value
  }, [])

  const handleResetFilter = useCallback((type) => {
    /**
     * Note: If edit mode no need to clear other filter config.
     * -- If edit mode and reset is done for Table columns, clear table columns no need to clear other config
     * -- If edit mode and reset is done for advance search filters, reset for advance search is done in advance search component
     * -- If selection of saved filter is done (not edit mode), then reset done for Table columns Or Advance search filters, need to clear filter config (Filter name, filter id, etc) , 
     *    as after resetting table column or advance search, selected filter config will no longer be same as saved filter
     * 
     */
    if (!filterTableRef.current.isEditMode) {
      //To clear filterConfig if any
      setFilterConfig(Object.assign({}, initialFilterConfigState))

      if (isRowsPerPageConfigRequired) {
        setRowsPerPage(initailRowsPerPage)
      }
      //To clear selected saved filter if any
      setSelectedFilterId("")
      setSelectedFilterName("")
    }
    if (type === FILTER_SECTION_TYPE.TABLE_COLUMN) {
      //To set table column to default
      setColumns(defaultTableColumnConfig)
    }
  }, [initailRowsPerPage])

  useImperativeHandle(ref, () => ({
    resetExpandedView: () => {
      setExpandedViewId('')
    },
    resetFilter: handleResetFilter,
    checkIfHasSelectedSavedFilter: () => {
      return !!selectedFilterId
    }
  }), [selectedFilterId])

  const handleFetchSavedFilters = useCallback(() => {
    if (!searchFilterKey) {
      return
    }

    setFetchingFilters(true)
    dispatch(
      actions.getAdvanceSearchFilterListRequest(searchFilterKey, (response) => {
        if (response) {
          setFilterOptions(response.lstOfSearchFilterListDto || []
          )
        }
        setFetchingFilters(false)
      })
    )
  }, [searchFilterKey])

  useEffect(() => {
    handleFetchSavedFilters()
  }, [])

  useEffect(() => {
    if (appliedFilter && appliedFilter.advanceSearchFilterId !== -1) {
      setSelectedFilterId(appliedFilter.advanceSearchFilterId)
      setSelectedFilterName(appliedFilter.searchFilterName)
      if (isRowsPerPageConfigRequired) {
        setRowsPerPage({
          text: appliedFilter.rowsPerPage,
          value: appliedFilter.rowsPerPage
        })
      }
    }
  }, [])

  const handleToggleView = useCallback((type, isEditFilterAction) => {
    if (isEditFilterAction && expandedViewId === FILTER_SECTION_TYPE.SAVED_FILTERS) {
      setExpandedViewId(FILTER_SECTION_TYPE.SAVE_FILTER_CONFIG)
    } else if (expandedViewId === type) {
      if (!isEditFilterAction) {
        setExpandedViewId('')
      }
    } else {
      searchComponentRef.current.resetExpandedView()
      setExpandedViewId(type)
    }
  }, [expandedViewId, searchComponentRef.current])

  const handleSelectSavedFilter = useCallback((config = {}) => {
    setSelectedFilterId(config.advanceSearchFilterId)
    setSelectedFilterName(config.searchFilterName)
    handleSetEditMode(false)
    setFilterConfig(Object.assign({}, initialFilterConfigState))

    try {
      const parsedFilterConfig = JSON.parse(config.searchFilterJson.toString())
      if (parsedFilterConfig) {
        //Columns Details
        if (parsedFilterConfig.columnConfig) {
          setColumns(parsedFilterConfig.columnConfig)
        }

        //Rows per page
        if (isRowsPerPageConfigRequired) {
          if (parsedFilterConfig.rowsPerPage) {
            setRowsPerPage({
              text: parsedFilterConfig.rowsPerPage,
              value: parsedFilterConfig.rowsPerPage
            })
          } else {
            setRowsPerPage(null)
          }
        }

        //For Advance Search filters
        if (parsedFilterConfig.advanceSearchDetail) {
          searchComponentRef.current.setSearchFilterState(parsedFilterConfig.advanceSearchDetail)
        } else if (!parsedFilterConfig.columnConfig) {
          searchComponentRef.current.setSearchFilterState(parsedFilterConfig)
        }
      }
    } catch (e) { }
  }, [handleSetEditMode])

  const handleEditSavedFilter = useCallback((type, config = {}) => {
    setSelectedFilterId("")
    setSelectedFilterName("")
    handleToggleView(type, true)
    handleSetEditMode(true)

    //Filter Details
    setFilterConfig({
      advanceSearchFilterId: config.advanceSearchFilterId,
      filterName: config.searchFilterName,
      isGlobal: config.isGlobal,
      isDefault: config.isDefault
    })
    try {
      const parsedFilterConfig = JSON.parse(config.searchFilterJson.toString())
      if (parsedFilterConfig) {
        //Columns Details
        if (parsedFilterConfig.columnConfig) {
          setColumns(parsedFilterConfig.columnConfig)
        }

        //Rows per page
        if (isRowsPerPageConfigRequired) {
          if (parsedFilterConfig.rowsPerPage) {
            setRowsPerPage({
              text: parsedFilterConfig.rowsPerPage,
              value: parsedFilterConfig.rowsPerPage
            })
          } else {
            setRowsPerPage(null)
          }
        }

        //For Advance Search filters
        if (parsedFilterConfig.advanceSearchDetail) {
          searchComponentRef.current.setSearchFilterState(parsedFilterConfig.advanceSearchDetail)
        } else if (!parsedFilterConfig.columnConfig) {
          searchComponentRef.current.setSearchFilterState(parsedFilterConfig)
        }
      }
    } catch (e) { }
  }, [handleSetEditMode, handleToggleView])

  const handleCancelEditSavedFilter = useCallback(() => {
    handleSetEditMode(false)
    setFilterConfig(Object.assign({}, initialFilterConfigState))
    if (isRowsPerPageConfigRequired) {
      setRowsPerPage(initailRowsPerPage)
    }
    setColumns(defaultTableColumnConfig)
    searchComponentRef.current.resetSearchFilter()
  }, [initailRowsPerPage, handleSetEditMode])

  const handleDeleteFilter = useCallback((filterId) => {
    if ((appliedFilter?.advanceSearchFilterId !== -1) && (filterId === appliedFilter?.advanceSearchFilterId)) {
      setAppliedFilterDeleted(true)
    }
  }, [appliedFilter?.advanceSearchFilterId])

  const handleEditFilter = useCallback((filterId) => {
    if ((appliedFilter?.advanceSearchFilterId !== -1) && (filterId === appliedFilter?.advanceSearchFilterId)) {
      setAppliedFilterUpdated(true)
    }
  }, [appliedFilter?.advanceSearchFilterId])

  const handleResetFilterConfig = useCallback((isDelete) => {
    setFilterConfig(Object.assign({}, initialFilterConfigState))
    if (isRowsPerPageConfigRequired) {
      setRowsPerPage(initailRowsPerPage)
    }
    if (!isDelete) {
      setColumns(defaultTableColumnConfig)
      searchComponentRef.current.resetSearchFilter()
    }
  }, [initailRowsPerPage, defaultTableColumnConfig])

  const handleRefreshSavedFilter = useCallback((advanceSearchFilterId, updatedConfig) => {
    const updatedfilterOptions = cloneArrayOfObject(filterOptions)
    const index = updatedfilterOptions.findIndex(f => f.advanceSearchFilterId === advanceSearchFilterId)
    if (updatedConfig.isDeleteFilter) {
      if (index !== -1) {
        //To Refresh SavedFilter list after deleting filter
        delete updatedfilterOptions[index]
      }
    } else {
      if (index !== -1) {
        //To Refresh SavedFilter list after updating filter
        updatedfilterOptions[index] = {
          ...updatedfilterOptions[index],
          ...updatedConfig
        }
      } else {
        //To add new filter to saved filter list
        updatedfilterOptions.push({
          advanceSearchFilterId,
          ...updatedConfig,
          canEdit: true
        })
      }
    }
    setFilterOptions(updatedfilterOptions)
    setRefreshSavedFilters(true)
  }, [filterOptions])

  /**
   * Todo: Check for if need to use updated table config only , 
   *  without any saved filter,
   * 
   * What to show in Saved filter dropdown for that
   * Need to check for reset use case for this updated table config as well, on apply after Reset All clicked
   */
  const handleApplySelections = useCallback((e) => {
    const count = searchComponentRef.current.getTaskFiltersCount()
    if (isResetAppliedFilter && !selectedFilterId && !count && !!appliedFilter) {
      //When no saved filter or random filter values is selected and some filter is already applied
      //Reset applied search in parent component
      onResetAllAppliedFilters({
        columns: defaultTableColumnConfig
      })
    } else {
      onApplySelections({
        advanceSearchFilterId: selectedFilterId,
        searchFilterName: selectedFilterId ? selectedFilterName : count > 1 ? `${count} Active Filters` : `${count} Active Filter`,
        columns,
        advanceSearch: searchComponentRef.current.getSearchFilterState(),
        rowsPerPage: rowsPerPage?.value,
        isRequiresListRefresh: !!selectedFilterId || !!count || (count === 0 && (!appliedFilter || appliedFilter.searchFilterName !== "0 Active Filter"))
      })
    }
    if (isRefreshSavedFilters) {
      onSetRefreshSavedFilterOptions(true)
    }
    onToggleModal(e)
  }, [
    appliedFilter,
    columns,
    defaultTableColumnConfig,
    selectedFilterId,
    filterConfig,
    rowsPerPage,
    isRefreshSavedFilters,
    isResetAppliedFilter,
    selectedFilterName,
    onApplySelections,
    onResetAllAppliedFilters,
    onToggleModal
  ])

  const handleResetAll = useCallback(() => {
    if (isEditMode) {
      handleSetEditMode(false)
    } else {
      setSelectedFilterId("")
      setSelectedFilterName("")
    }
    setFilterConfig(Object.assign({}, initialFilterConfigState))
    setColumns(defaultTableColumnConfig)
    if (isRowsPerPageConfigRequired) {
      setRowsPerPage(initailRowsPerPage)
    }
    searchComponentRef.current.resetSearchFilter()
    if (!!appliedFilter) {
      setResetAppliedFilter(true)
    }
  }, [appliedFilter, isEditMode, initailRowsPerPage, handleSetEditMode])

  const handleToggleModal = useCallback((e) => {
    if (isRefreshSavedFilters) {
      onSetRefreshSavedFilterOptions(true, isAppliedFilterDeleted || isAppliedFilterUpdated)
    }
    onToggleModal(e)
  }, [onToggleModal, isRefreshSavedFilters, isAppliedFilterDeleted, isAppliedFilterUpdated, onSetRefreshSavedFilterOptions])

  return (
    <Modal
      isOpen
      className={`sidebar-sm`}
      modalClassName={"modal-slide-in note-media-modal filter-table"}
      contentClassName="p-0"
    >
      <ModalHeader
        tag="div"
        toggle={handleToggleModal}
        close={<SideModalCloseButton onClose={handleToggleModal} />}
      >
        <h5 className="modal-title">
          {getLocaleMessage(intl, FILTER_TABLE_MODAL.HEADER_TITLE)}
        </h5>
      </ModalHeader>
      <ModalBody className="m-0">
        <div className="setup-accordion">
          {isSavedFilterConfigRequired && <SavedFilters
            expandedViewId={expandedViewId}
            filterOptions={filterOptions}
            isFetchingFilters={isFetchingFilters}
            selectedFilterId={selectedFilterId}
            onEditSavedFilter={handleEditSavedFilter}
            onResetAll={handleResetAll}
            onSelectSavedFilter={handleSelectSavedFilter}
            onToggleView={handleToggleView} />}
          <div className="filter-inner-data">
            {isTableColumnRequired && <TableColumn
              columns={columns}
              expandedViewId={expandedViewId}
              selectedFilterId={selectedFilterId}
              onResetFilter={handleResetFilter}
              onSetColumns={setColumns}
              onToggleView={handleToggleView}
            />}
            <ul className="advance-search-filters">
              {getSearchFilterComponent()}
            </ul>
          </div>
          {isSavedFilterConfigRequired && <SaveAsNewFilter
            expandedViewId={expandedViewId}
            filterConfig={filterConfig}
            rowsPerPage={rowsPerPage}
            isEditMode={isEditMode}
            columns={columns}
            searchFilterKey={searchFilterKey}
            searchComponentRef={searchComponentRef}
            isRowsPerPageConfigRequired={isRowsPerPageConfigRequired}
            onCancelEditSavedFilter={handleCancelEditSavedFilter}
            onDeleteFilter={handleDeleteFilter}
            onEditFilter={handleEditFilter}
            onSetFilterConfig={setFilterConfig}
            onSetRowsPerPage={setRowsPerPage}
            onResetFilterConfig={handleResetFilterConfig}
            onRefreshSavedFilter={handleRefreshSavedFilter}
            onToggleView={handleToggleView}
          />}
        </div>
        <Row className="filter-btn">
          <Col md={6}>
            <Button
              className={"secondary-solid w-100"}
              color="primary"
              onClick={handleApplySelections}
              disabled={isEditMode}
            >
              {getLocaleMessage(intl, FILTER_TABLE_MODAL.APPLY_SELECTIONS)}
            </Button>
          </Col>
          <Col md={6}>
            <Button
              outline
              onClick={handleToggleModal}
              className={"delete-button secondary-outlined w-100 mw-100"}
            >
              {getLocaleMessage(intl, CANCEL)}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default localeMessageWrapper(forwardRef(FilterTableModal))
