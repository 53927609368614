import React from "react"
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row
} from "reactstrap"

import { getLocaleMessage } from "@utils"
import { ErrorMessage } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const SetupWizardDetailsCard = ({
  intl,
  errors = {},
  errorKeys = [],
  titleLocaleMessageId,
  subTitleLocaleMessageId
}) => {
  return (
    <Row>
      <Card className="shadow-none bg-transparent sw-details-card-outer mb-1">
        <CardBody className="sw-details-card p-0">
          {!!titleLocaleMessageId && <CardHeader className="px-0">
            <CardTitle tag="h2">
              {getLocaleMessage(intl, titleLocaleMessageId)}
            </CardTitle>
          </CardHeader>}
          <CardSubtitle>
            {getLocaleMessage(intl, subTitleLocaleMessageId)}
          </CardSubtitle>
          {errorKeys.map((eKey) => (
            <ErrorMessage key={`error-message-${eKey}`} isShow={!!errors[eKey]} message={errors[eKey]} />
          ))}
        </CardBody>
      </Card>
    </Row>
  )
}

export default localeMessageWrapper(SetupWizardDetailsCard)
