import { getActionType } from '../action-type-generator'

export const GET_USER_MANAGEMENT_CONTACT_VM = 'GET_USER_MANAGEMENT_CONTACT_VM'
export const GET_USER_MANAGEMENT_USER_DETAIL = 'GET_USER_MANAGEMENT_USER_DETAIL'
export const GET_USER_MANAGEMENT_USER_ROLE = 'GET_USER_MANAGEMENT_USER_ROLE'
export const GET_USER_MANAGEMENT_PROFILE_TYPE = 'GET_USER_MANAGEMENT_PROFILE_TYPE'
export const GET_USER_MANAGEMENT_USER_ACCESS = getActionType('GET_USER_MANAGEMENT_USER_ACCESS')
export const CREATE_USER_MANAGEMENT_USER_ACCESS = 'CREATE_USER_MANAGEMENT_USER_ACCESS'
export const ADD_USER_MANAGEMENT_ENTITY_TO_LIST = 'ADD_USER_MANAGEMENT_ENTITY_TO_LIST'
export const REMOVE_USER_MANAGEMENT_ENTITY_FROM_LIST = 'REMOVE_USER_MANAGEMENT_ENTITY_FROM_LIST'
export const RESET_USER_MANAGEMENT_ENTITY_LIST = 'RESET_USER_MANAGEMENT_ENTITY_LIST'
export const SET_CONTACT_ENTITY_LIST = 'RESET_USER_MANAGEMENT_ENTITY_LIST'
export const SET_EDIT_CONTACT_ROLE_PERMISSIONS_MODE = 'SET_EDIT_CONTACT_ROLE_PERMISSIONS_MODE'

/****************************************************/
/** GET_USER_MANAGEMENT_CONTACT_VM **/
/****************************************************/

/**
 * @method getUserManagementContactVM :  To get user management contacts dropdowns list
 *
 */
export const getUserManagementContactVM = callback => {
  return {
    type: GET_USER_MANAGEMENT_CONTACT_VM,
    callback
  }
}

/****************************************************/
/** GET_USER_MANAGEMENT_USER_DETAIL **/
/****************************************************/

/**
 * @method getUserManagementUserDetail :  To get user management user detail
 *
 */
export const getUserManagementUserDetail = (payload, callback) => {
  return {
    type: GET_USER_MANAGEMENT_USER_DETAIL,
    payload,
    callback
  }
}

/****************************************************/
/** GET_USER_MANAGEMENT_PROFILE_TYPE **/
/****************************************************/

/**
 * @method getUserManagementProfileType :  To get user management user type
 *
 */
export const getUserManagementProfileType = (payload, callback) => {
  return {
    type: GET_USER_MANAGEMENT_PROFILE_TYPE,
    payload,
    callback
  }
}

/****************************************************/
/** GET_USER_MANAGEMENT_USER_ROLE **/
/****************************************************/

/**
 * @method getUserManagementUserRole :  To get user management user role
 *
 */
export const getUserManagementUserRole = (payload, callback) => {
  return {
    type: GET_USER_MANAGEMENT_USER_ROLE,
    payload,
    callback
  }
}

/****************************************************/
/** GET_USER_MANAGEMENT_USER_ACCESS **/
/****************************************************/

/**
 * @method getUserManagementUserAccessRequest :  To get user management user access
 *
 */
export const getUserManagementUserAccessRequest = (payload, callback = () => { }) => {
  return {
    type: GET_USER_MANAGEMENT_USER_ACCESS.REQUEST,
    payload,
    callback
  }
}

/**
 * @method getUserManagementUserAccessSuccess :  To set user management user access list on success
 *
 */
export const getUserManagementUserAccessSuccess = data => {
  return {
    type: GET_USER_MANAGEMENT_USER_ACCESS.SUCCESS,
    data
  }
}

/**
 * @method getUserManagementUserAccessFailure :  To set error on getting user management user access list
 *
 */
export const getUserManagementUserAccessFailure = error => {
  return {
    type: GET_USER_MANAGEMENT_USER_ACCESS.FAILURE,
    error
  }
}

/****************************************************/
/** CREATE_USER_MANAGEMENT_USER_ACCESS **/
/****************************************************/

/**
 * @method createUserManagementUserAccess :  To create user management user access
 *
 */
export const createUserManagementUserAccess = (payload, callback) => {
  return {
    type: CREATE_USER_MANAGEMENT_USER_ACCESS,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_USER_MANAGEMENT_ENTITY_TO_LIST **/
/****************************************************/

/**
 * @method addUserManagementEntityToList :  To set user management entity list
 *
 */
export const addUserManagementEntityToList = payload => {
  return {
    type: ADD_USER_MANAGEMENT_ENTITY_TO_LIST,
    payload
  }
}

/****************************************************/
/** REMOVE_USER_MANAGEMENT_ENTITY_FROM_LIST **/
/****************************************************/

/**
 * @method removeUserManagementEntityFromList :  To remove user management entity from list
 *
 */
export const removeUserManagementEntityFromList = payload => {
  return {
    type: REMOVE_USER_MANAGEMENT_ENTITY_FROM_LIST,
    payload
  }
}

/****************************************************/
/** RESET_USER_MANAGEMENT_ENTITY_LIST **/
/****************************************************/

/**
 * @method resetUserManagementEntityList :  To reset user management entity list
 *
 */
export const resetUserManagementEntityList = () => {
  return {
    type: RESET_USER_MANAGEMENT_ENTITY_LIST
  }
}

/****************************************************/
/** SET_EDIT_CONTACT_ROLE_PERMISSIONS_MODE **/
/****************************************************/

/**
 * @method setEditContactRolePermissionMode : To set edit mode for contact's role permissons
 *
 */
 export const setEditContactRolePermissionMode = (value) => {
  return {
    type: SET_EDIT_CONTACT_ROLE_PERMISSIONS_MODE,
    payload: value
  }
}
