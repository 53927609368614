import config from "./APIConfig"
import { putDataApi } from '../index'

/**
 * @method updateProjectSiteRolePrimary
 * @param {object} data 
 *  {
      "siteRoleId": 0
    }
 *
 */
export const updateProjectSiteRolePrimary = (data) => {
  const { url } = config.updateProjectSiteRolePrimary
  return putDataApi(url({ siteRoleId: data.siteRoleId }), data)
}

export default updateProjectSiteRolePrimary
