import { getSearchQuery } from '../../config'

const apiConfig = {
  getCreateTriggerVM: {
    method: 'GET',
    url: (wfCampaignSetupId) => {
      const searchString = getSearchQuery("wfCampaignSetupId", wfCampaignSetupId, true)
      return `api/WorkFlow/block/vm/createtrigger${searchString}`
    }
  },
  getInspectionCorrectionVM: {
    method: 'GET',
    url: ({ wfCampaignSetupId, isSharedCampaign = false }) => {
      const searchString = getSearchQuery("IsMasterCampaign", isSharedCampaign, true)
      return `api/WorkFlow/block/vm/failedinspectioncorrection/${wfCampaignSetupId}${searchString}`
    }
  },
  getConfirmSiteVisitVM: {
    method: 'GET',
    url: ({ isSharedCampaign = false }) => {
      const searchString = getSearchQuery("IsMasterCampaign", isSharedCampaign, true)
      return `api/WorkFlow/block/vm/confirmsitevisit${searchString}`
    }
  },
  getScheduleTaskVM: {
    method: 'GET',
    url: ({ isSharedCampaign = false }) => {
      const searchString = getSearchQuery("IsMasterCampaign", isSharedCampaign, true)
      return `api/WorkFlow/block/vm/scheduletask${searchString}`
    }
  },
  getFailedInspectionTrainingVM: {
    method: "GET",
    url: "api/WorkFlow/block/vm/failedinspectiontraining"
  },
  getTaskCloseOutVM: {
    method: "GET",
    url: "api/WorkFlow/taskCloseOut/vm/taskCloseOutVM"
  },
  getObservationEscalationVM: {
    method: "GET",
    url: (wfCampaignSetupId) => `api/WorkFlow/block/vm/escalation/${wfCampaignSetupId}`
  },
  createBlockTrigger: {
    method: "POST",
    url: "api/WorkFlow/BlockTrigger"
  },
  createBlockScheduleTask: {
    method: "POST",
    url: "api/WorkFlow/BlockScheduleTask"
  },
  createBlockConfirmSiteVisit: {
    method: "POST",
    url: "api/WorkFlow/BlockConfirmSiteVisit"
  },
  getBlockTrigger: {
    method: 'GET',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockTrigger/${wfCampaignSetupId}`
  },
  getBlockScheduleTask: {
    method: 'GET',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockScheduleTask/${wfCampaignSetupId}`
  },
  getBlockConfirmSiteVisit: {
    method: 'GET',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockConfirmSiteVisit/${wfCampaignSetupId}`
  },
  updateBlockTrigger: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockTrigger/${wfCampaignSetupId}`
  },
  updateBlockScheduleTask: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockScheduleTask/${wfCampaignSetupId}`
  },
  updateBlockConfirmSiteVisit: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockConfirmSiteVisit/${wfCampaignSetupId}`
  },
  getBlockFailedInspectionCorrection: {
    method: 'GET',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockFailedInspectionCorrection/${wfCampaignSetupId}`
  },
  updateBlockFailedInspectionCorrection: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockFailedInspectionCorrection/${wfCampaignSetupId}`
  },
  createBlockFailedInspectionCorrection: {
    method: 'POST',
    url: 'api/WorkFlow/BlockFailedInspectionCorrection'
  },
  createBlockEscalation: {
    method: 'POST',
    url: 'api/WorkFlow/BlockEscalation'
  },
  updateBlockEscalation: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockEscalation/${wfCampaignSetupId}`
  },
  createBlockFailedInspectionTraining: {
    method: 'POST',
    url: 'api/WorkFlow/failedInspectionTraining'
  },
  getBlockEscalationRequest: {
    method: 'GET',
    url: (WFCampaignSetupId) => `api/WorkFlow/BlockEscalation/${WFCampaignSetupId}`
  },
  getBlockFailedInspectionTraining: {
    method: 'GET',
    url: (wfCampaignSetupId) => `api/WorkFlow/failedInspectionTraining/${wfCampaignSetupId}`
  },
  updateBlockFailedInspectionTraining: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/failedInspectionTraining/${wfCampaignSetupId}`
  },
  getChecklistChildrenList: {
    method: 'GET',
    url: ({
      entityId = 1,
      actionId = 0,
      onlyFailedActivity = false,
      wfCampaignSetupChecklistId = 0,
      genericSearch,
      advanceSearch,
      language,
      pageNumber,
      pageSize,
      orderColumn = '',
      orderDirections = '',
      isShowAvailableOnly,
      isReviewActivity
    }) => {

      let searchString = [
        getSearchQuery("EntityId", entityId, true),
        getSearchQuery("WFCampaignSetupChecklistId", wfCampaignSetupChecklistId),
        getSearchQuery("WFActionId", actionId),
        getSearchQuery("isReviewActivity", isReviewActivity),
        getSearchQuery("OnlyFailedActivity", onlyFailedActivity),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("HasNoGroup", isShowAvailableOnly),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        language ? `&Language=${language}` : '',
        getSearchQuery("GenericSearch", genericSearch, false, true)
      ].join('')

      if (!!Object.keys(advanceSearch || {}).length) {
        const {
          checkpointName = '',
          stageOfConstruction = '',
          scopeOfWork = '',
          responsibleSiteRole = '',
          riskLevel = '',
          assembly = '',
          component = [],
          subComponent = [],
          manufacturer = '',
          systemCategory = '',
          brand = '',
          activityType = ''
        } = advanceSearch

        const componentIds = component.map((comp) => getSearchQuery("ComponentId", comp.componentId)).join("")
        const subComponentIds = subComponent.map((sub) => getSearchQuery("SubComponentId", sub.subComponentId)).join("")

        searchString = [
          searchString,
          getSearchQuery("CheckPointName", checkpointName, false, true),
          getSearchQuery("StageOfConstructionId", stageOfConstruction, false, true),
          getSearchQuery("ScopeOfWorkId", scopeOfWork, false, true),
          getSearchQuery("ResponsibleSiteRoleId", responsibleSiteRole),
          getSearchQuery("RiskLevelId", riskLevel),
          getSearchQuery("AssemblyId", assembly),
          componentIds,
          subComponentIds,
          getSearchQuery("ManufacturerId", manufacturer),
          getSearchQuery("SystemCategoryId", systemCategory),
          getSearchQuery("BrandId", brand),
          getSearchQuery("ActivityTypeId", activityType)
        ].join('')
      }
      return `api/Campaign/GetCheckListChildren${searchString}`
    }
  },
  deleteCampaignChecklist: {
    method: 'DELETE',
    url: ({ wfCampaignSetupChecklistId }) => `api/Campaign/Checklist/${wfCampaignSetupChecklistId}`
  },
  createBlockTaskCloseout: {
    method: 'POST',
    url: `api/WorkFlow/taskCloseOut`
  },
  getBlockTaskCloseout: {
    method: 'GET',
    url: (wfCampaignSetupId) => `api/WorkFlow/taskCloseOut/${wfCampaignSetupId}`
  },
  updateBlockTaskCloseout: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/taskCloseOut/${wfCampaignSetupId}`
  },
  getTemplateStepQuery: {
    method: 'GET',
    url: (wfTemplateId) => {
      const searchString = `${getSearchQuery("wfTemplateId", wfTemplateId, true)}`
      return `api/WorkFlow/setUp/GetTemplateStep${searchString}`
    }
  },
  createBlockAddTraining: {
    method: "POST",
    url: "api/WorkFlow/BlockAddTraining"
  },
  updateBlockAddTraining: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockAddTraining/${wfCampaignSetupId}`
  },
  getBlockAddTraining: {
    method: 'GET',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockAddTraining/${wfCampaignSetupId}`
  },
  createBlockFailedTraining: {
    method: 'POST',
    url: 'api/WorkFlow/BlockFailedTraining'
  },
  getBlockFailedTraining: {
    method: 'GET',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockFailedTraining/${wfCampaignSetupId}`
  },
  getBlockFailedTrainingVM: {
    method: 'GET',
    url: 'api/WorkFlow/vm/BlockFailedTraining'
  },
  updateBlockFailedTraining: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockFailedTraining/${wfCampaignSetupId}`
  },
  getBlockDueDateVM: {
    method: "GET",
    url: "api/WorkFlow/vm/BlockTrainingDueDate"
  },
  getBlockDueDate: {
    method: 'GET',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockTrainingDueDate/${wfCampaignSetupId}`
  },
  createBlockDueDate: {
    method: "POST",
    url: "api/WorkFlow/BlockTrainingDueDate"
  },
  updateBlockDueDate: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockTrainingDueDate/${wfCampaignSetupId}`
  },
  getTrainingEscalationVM: {
    method: "GET",
    url: 'api/WorkFlow/block/vm/BlockTrainingEscalation'
  },
  getSitePlanTriggerVM: {
    method: 'GET',
    url: ({
      wfCampaignSetupId = 0,
      sameSettingsForAllPlans
    }) => {
      const searchString = [
        getSearchQuery("WFCampaignSetupId", wfCampaignSetupId, true),
        `&SameSettingsForAllPlans=${sameSettingsForAllPlans}`
      ].join('')
      return `api/WorkFlow/SitePlanTrigger/${wfCampaignSetupId}${searchString}`
    }
  },
  getSitePlanScheduleLineItemDDL: {
    method: 'GET',
    url: ({
      sitePlanId = 0,
      scheduleLineItem
    }) => {
      const searchString = [
        getSearchQuery("SitePlanId", sitePlanId, true),
        getSearchQuery("ScheduleLineItem", scheduleLineItem)
      ].join('')
      return `api/WorkFlow/DDL/ScheduleLineItem/${sitePlanId}${searchString}`
    }
  },
  getFindingsCorrection: {
    method: "GET",
    url: (wFCampaignSetupId) => `api/WorkFlow/BlockFindingCorrection/${wFCampaignSetupId}`
  },
  getFindingsCorrectionVM: {
    method: "GET",
    url: "api/WorkFlow/vm/BlockFindingCorrection"
  },
  addFindingsCorrection: {
    method: "POST",
    url: "api/WorkFlow/BlockFindingCorrection"
  },
  updateFindingsCorrection: {
    method: "POST",
    url: (wFCampaignSetupId) => `api/WorkFlow/BlockFindingCorrection/${wFCampaignSetupId}`
  },
  addActivityGroup: {
    method: "POST",
    url: "api/WorkFlow/ActivityGroup"
  },
  getGroupList: {
    method: "GET",
    url: ({ isReviewActivity, pageSize, pageNumber, wfCampaignSetupChecklistId }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("isReviewActivity", isReviewActivity)
      ].join('')
      return `api/WorkFlow/checklist/${wfCampaignSetupChecklistId}/group${searchString}`
    }
  },
  getGroupActivitiesList: {
    method: "GET",
    url: ({
      groupId,
      wfCampaignSetupChecklistId,
      pageNumber,
      pageSize,
      orderColumn,
      orderDirections,
      language,
      isReviewActivity
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("isReviewActivity", isReviewActivity),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : '',
        language ? `&Languag=${language}` : ''
      ].join('')
      return `api/WorkFlow/checklist/${wfCampaignSetupChecklistId}/group/${groupId}/activity${searchString}`
    }
  },
  deleteActivityGroup: {
    method: 'DELETE',
    url: ({ wfCampaignSetupChecklistId, groupId, isReviewActivity }) => {
      const searchString = getSearchQuery("isReviewActivity", isReviewActivity, !!isReviewActivity)
      return `api/WorkFlow/checklist/${wfCampaignSetupChecklistId}/group/${groupId}${searchString}`
    }
  },
  addActivitiesToGroup: {
    method: "POST",
    url: ({ groupId, wfCampaignSetupChecklistId }) => `api/WorkFlow/checklist/${wfCampaignSetupChecklistId}/group/${groupId}/activity`
  },
  updateActivityGroup: {
    method: 'PUT',
    url: ({ wfCampaignSetupChecklistId, groupId }) => `api/WorkFlow/checklist/${wfCampaignSetupChecklistId}/group/${groupId}`
  },
  deleteActivityFromGroup: {
    method: 'PUT',
    url: ({ wfCampaignSetupActivityId }) => `api/WorkFlow/DeleteActivityFromGroup/${wfCampaignSetupActivityId}`
  },
  reorderGroupActivitiesList: {
    method: 'PUT',
    url: 'api/WorkFlow/GroupActivity/UpdateOrderIndex'
  },
  reorderActivityGroup: {
    method: 'PUT',
    url: "api/WorkFlow/ActivityGroup/UpdateOrderIndex"
  },
  getBlockScheduleRecordableSettingsVM: {
    method: "GET",
    url: "api/WorkFlow/vm/BlockScheduleRecordableSettings"
  },
  createBlockScheduleRecordableSettings: {
    method: "POST",
    url: "api/WorkFlow/BlockScheduleRecordableSettings"
  },
  updateBlockScheduleRecordableSettings: {
    method: "PUT",
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockScheduleRecordableSettings/${wfCampaignSetupId}`
  },
  getBlockScheduleRecordableSettings: {
    method: "GET",
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockScheduleRecordableSettings/${wfCampaignSetupId}`
  },
  getAutomateReports: {
    method: 'GET',
    url: ({ wfCampaignSetupId }) => `api/WorkFlow/BlockAutomateReports/${wfCampaignSetupId}`
  },
  getAutomateReportVM: {
    method: "GET",
    url: ({ showOrganizationRole = false, isSharedCampaign = false }) => {
      let searchString = [
        getSearchQuery("showOrganizationRole", showOrganizationRole),
        getSearchQuery("IsMasterCampaign", isSharedCampaign),
        getSearchQuery("showAssigneeOption", isSharedCampaign)
      ].join('')
      searchString = searchString.replace('&', '?')
      return `api/WorkFlow/BlockAutomateReports/vm${searchString}`
    }
  },
  updateAutomateReport: {
    method: 'PUT',
    url: ({ wfCampaignSetupId }) => `api/WorkFlow/BlockAutomateReports/${wfCampaignSetupId}`
  },
  createAutomateReport: {
    method: 'POST',
    url: `api/WorkFlow/BlockAutomateReports`
  },
  getTaskReviewVM: {
    method: 'GET',
    url: ({ isSharedCampaign = false }) => {
      const searchString = getSearchQuery("IsMasterCampaign", isSharedCampaign, true)
      return `api/WorkFlow/BlockTaskReview/vm${searchString}`
    }
  },
  createBlockTaskReview: {
    method: 'POST',
    url: `api/WorkFlow/BlockTaskReview`
  },
  updateBlockTaskReview: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockTaskReview/${wfCampaignSetupId}`
  },
  getBlockTaskReview: {
    method: "GET",
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockTaskReview/${wfCampaignSetupId}`
  },
  getBlockScheduleAutomateReportsVM: {
    method: "GET",
    url: ({ showOrganizationRole = false, isMasterCampaign = false }) => {
      let searchString = [
        getSearchQuery("showOrganizationRole", showOrganizationRole),
        getSearchQuery("IsMasterCampaign", isMasterCampaign)
      ].join('')
      searchString = searchString.replace('&', '?')
      return `api/WorkFlow/BlockScheduleAutomateReports/vm${searchString}`
    }
  },
  createBlockScheduleAutomateReports: {
    method: "POST",
    url: "api/WorkFlow/BlockScheduleAutomateReports"
  },
  updateBlockScheduleAutomateReports: {
    method: "PUT",
    url: ({ wfCampaignSetupId }) => `api/WorkFlow/BlockScheduleAutomateReports/${wfCampaignSetupId}`
  },
  getBlockScheduleAutomateReports: {
    method: 'GET',
    url: ({ wfCampaignSetupId }) => `api/WorkFlow/BlockScheduleAutomateReports/${wfCampaignSetupId}`
  },
  getPartialSubmissionVM: {
    method: "GET",
    url: 'api/WorkFlow/BlockPartialSubmission/vm'
  },
  createBlockPartialSubmission: {
    method: "POST",
    url: "api/WorkFlow/BlockPartialSubmission"
  },
  updateBlockPartialSubmission: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockPartialSubmission/${wfCampaignSetupId}`
  },
  getBlockPartialSubmission: {
    method: 'GET',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockPartialSubmission/${wfCampaignSetupId}`
  },
  getBlockTaskSignOffVM: {
    method: 'GET',
    url: ({ isSharedCampaign = false }) => {
      const searchString = getSearchQuery("IsMasterCampaign", isSharedCampaign, true)
      return `api/WorkFlow/BlockTaskSignOff/vm${searchString}`
    }
  },
  createBlockTaskSignOff: {
    method: "POST",
    url: "api/WorkFlow/BlockTaskSignOff"
  },
  updateBlockTaskSignOff: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockTaskSignOff/${wfCampaignSetupId}`
  },
  getBlockTaskSignOff: {
    method: 'GET',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockTaskSignOff/${wfCampaignSetupId}`
  },
  getBlockChecklistVM: {
    method: "GET",
    url: "api/WorkFlow/BlockChecklist/vm"
  },
  getBlockChecklist: {
    method: "GET",
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockChecklist/${wfCampaignSetupId}`
  },
  createBlockChecklist: {
    method: "POST",
    url: "api/WorkFlow/BlockChecklist"
  },
  updateBlockChecklist: {
    method: 'PUT',
    url: (wfCampaignSetupId) => `api/WorkFlow/BlockChecklist/${wfCampaignSetupId}`
  },
  deleteCheckpointFromCheckList: {
    method: 'DELETE',
    url: `api/Campaign/CheckList`
  },
  deleteCustomizedChildCampaignBlock: {
    method: 'DELETE',
    url: ({ wfCampaignBlockSetupId }) => `api/WorkFlow/DeleteCustomizedChildCampaignBlock/${wfCampaignBlockSetupId}`
  },
  getFindingWorkflows: {
    method: "GET",
    url: ({
      wfCampaignSetupId,
      pageNumber,
      pageSize,
      findingWorkflowName = ""
    }) => {
      const searchString = [
        getSearchQuery("WFCampaignSetupId", wfCampaignSetupId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("FindingWorkflowName", findingWorkflowName, false, true)
      ].join('')

      return `api/WorkFlow/GetFindingWorkflows/${wfCampaignSetupId}${searchString}`
    }
  }
}

export default apiConfig
