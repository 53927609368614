import config from "./APIConfig"
import { putDataApi } from '../index'

export const addSitesToSharedCampaign = (data) => {
  const { url } = config.addSitesToSharedCampaign

  return putDataApi(url(data), data)
}

export default addSitesToSharedCampaign
