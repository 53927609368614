import config from "./APIConfig"
import { putDataApi } from "../index"

/**
 * @method updateBulkSignOffAction
 * This api is called to sign off for scheduling workflow
 * [Sign off]
 * 
 * @param {object} data 
 * 
 * {
 *   wfActionIds: string,
 *   note: string
 *   reasonId: 0,
 *   images: []
}
 * 
 */
export const updateBulkSignOffAction = async (data) => {
  const { url } = config.updateBulkSignOffAction
  return await putDataApi(url, data)
}

export default updateBulkSignOffAction
