import { put, takeLatest, call, takeEvery } from "redux-saga/effects"

import {
  getCampaignDetailVM,
  addCampaignDetail,
  getCampaignBasicDetail,
  getCampaignDetail,
  getServiceDetailVM,
  updateCampaignDetail,
  addCampaignServiceDetail,
  getCampaignServiceList,
  getCampaignServiceChecklist,
  getProjectListDDL,
  deleteCampaignService,
  getCampaignList,
  getCampaignDescription,
  deleteCampaign,
  getProgramDetailList,
  getCampaignObservationList,
  getCampaignDDL,
  getCampaignServiceDDL,
  getObservationTaskVM,
  getCampaignOshaPDF,
  getContactCampaignList,
  createCampaignChecklist,
  updateCampaignChecklist,
  getCampaignChecklistDetail,
  getCampaignWorkflowVM,
  getCampaignWorkflows,
  cloneCampaign,
  getCampaignTagsDDL,
  updateChecklistRequiredActivity,
  getCampaignReportList,
  updateChecklistCopyAllowedActivity,
  getSharedCampaignUnLinkedSiteList,
  addSitesToSharedCampaign,
  getSharedCampaignSiteList,
  getCampaignMissingSiteRoles,
  getSiteDDLByCampaignId,
  getOrganizationDDLByCampaignId,
  deleteSharedCampaignSite,
  getSharedCampaignUnLinkedSiteOrganizationDDLList
} from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

const { CAMPAIGN_SAGA, SERVICE_SAGA, CAMPAIGN_CHECKLIST_SAGA, WORKFLOW_ADD_TRAINING_SAGA } = LANGUAGE_CONSTANTS

/** getCampaignDetailVM API **/
function* getCampaignDetailVMRequest(action) {
  try {
    const response = yield call(getCampaignDetailVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** addCampaignDetail API **/
function* addCampaignDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addCampaignDetail, action.payload)
    if (response?.data) {
      action.callback(response.data)
      Notification({ status: 200, message: CAMPAIGN_SAGA.ADDED_SUCCESS })
    } else {
      action.callback()
      Notification({ status: 400, message: CAMPAIGN_SAGA.ERROR_IN_ADDING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getCampaignDetail API **/
function* getCampaignDetailRequest(action) {
  try {
    const { isLoadingRequired = true, ...payload } = action.payload

    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getCampaignDetail, payload)
    if (!response?.data) {
      Notification({ status: 400, message: CAMPAIGN_SAGA.WRONG_ID })
    }
    action.callback(response?.data)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    const { isLoadingRequired = true } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  }
}

/** getCampaignBasicDetail API **/
function* getCampaignBasicDetailRequest(action) {
  try {
    const { isLoadingRequired = true, ...payload } = action.payload

    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getCampaignBasicDetail, payload)
    if (!response?.data) {
      Notification({ status: 400, message: CAMPAIGN_SAGA.WRONG_ID })
    }
    action.callback(response?.data || false)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    const { isLoadingRequired = true } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  }
}

/** getServiceDetailVM API **/
function* getServiceDetailVMRequest({ payload, callback = () => { } }) {
  try {
    const response = yield call(getServiceDetailVM, payload)
    callback(response?.data)
  } catch (error) {
    callback()
  }
}

/** updateCampaignDetail API **/
function* updateCampaignDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateCampaignDetail, action.payload)
    if (response?.status === 200) {
      action.callback(true)
      Notification({ status: 200, message: CAMPAIGN_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: CAMPAIGN_SAGA.ERROR_IN_UPDATING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** addCampaignServiceDetail API **/
function* addCampaignServiceDetailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addCampaignServiceDetail, action.payload)
    if (response?.data) {
      Notification({ status: 200, message: SERVICE_SAGA.ADDED_SUCCESS })
      action.callback(response.data)
    } else {
      Notification({ status: 400, message: SERVICE_SAGA.ERROR_IN_ADDING })
      action.callback(0)
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(0)
    yield put(actions.setIsLoading(false))
  }
}

/** getCampaignServiceList API **/
function* getCampaignServiceListRequest({ payload, callback = () => { } }) {
  try {
    const { isLoadingRequired = false } = payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getCampaignServiceList, payload)
    if (response?.data) {
      callback({ ...response.data, pageSize: payload.pageSize })
    } else {
      callback()
      Notification({ status: 400, message: SERVICE_SAGA.ERROR_IN_FETCHING_LIST })
    }
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    if (payload.isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    Notification({ status: 400, isLocalizedMessage: !(error.response?.statusText || error.message), message: error.response?.statusText || error.message || SERVICE_SAGA.ERROR_IN_FETCHING_LIST })
    callback()
  }
}

/** getCampaignServiceCheckList API **/
function* getCampaignServiceChecklistRequest({ payload, callback = () => { } }) {
  try {
    const { isLoadingRequired = false } = payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getCampaignServiceChecklist, payload)

    if (response?.data) {
      callback({ ...response.data, pageSize: payload.pageSize })
    } else {
      callback()
      Notification(400, <LocaleMessage id={SERVICE_CHECKLIST_SAGA.ERROR_IN_FETCHING_LIST} />)
    }
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    if (payload.isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    Notification(400, error.response?.statusText || error.message || <LocaleMessage id={SERVICE_CHECKLIST_SAGA.ERROR_IN_FETCHING_LIST} />)
    callback()
  }
}

/** getProjectListDDL */
function* getProjectListDDLRequest(action) {
  try {
    const response = yield call(getProjectListDDL, action.payload)

    if (response) {
      action.callback(response.data || false)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}
/** deleteCampaignService API **/
function* deleteCampaignServiceRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteCampaignService, action.payload)
    if (response?.status === 200) {
      action.callback()
      Notification({ status: 200, message: SERVICE_SAGA.DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: SERVICE_SAGA.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** deleteCampaign API **/
function* deleteCampaignRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteCampaign, action.payload)
    if (response?.status === 200) {
      action.callback()
      Notification({ status: 200, message: CAMPAIGN_SAGA.DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: CAMPAIGN_SAGA.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** getCampaignList API **/
function* getCampaignListRequest(action) {
  try {
    const { programId, ...payload } = action.payload
    const response = yield call(programId ? getProgramDetailList : getCampaignList, { ...payload, programId })
    if (response?.data) {
      action.callback({ ...response.data, pageSize: payload.pageSize })
    } else {
      action.callback()
      Notification({ status: 400, message: CAMPAIGN_SAGA.ERROR_IN_FETCHING_LIST })
    }
  } catch (error) {
    action.callback()
  }
}

/** getCampaignDescription API **/
function* getCampaignDescriptionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getCampaignDescription, action.payload)
    yield put(actions.setIsLoading(false))
    action.callback(response?.data)
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getCampaignDDL API **/
function* getCampaignDDLRequest(action) {
  try {
    const response = yield call(getCampaignDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getCampaignServiceDDL API **/
function* getCampaignServiceDDLRequest(action) {
  try {
    const response = yield call(getCampaignServiceDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** getCampaignObservationList API **/
function* getCampaignObservationListRequest(action) {
  try {
    const response = yield call(getCampaignObservationList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getObservationTaskVM API **/
function* getObservationTaskVMRequest(action) {
  try {
    const { isLoadingRequired = false } = action.payload || {}
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getObservationTaskVM)
    action.callback(response?.data)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    const { isLoadingRequired = false } = action.payload || {}
    Notification({ status: 400, isLocalizedMessage: false, message: error.message })
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    action.callback(null)
  }
}

/** getCampaignOshaPDF API */
function* getCampaignOshaPDFRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getCampaignOshaPDF, action.payload)
    if (!response) {
      Notification({ status: 400, message: CAMPAIGN_SAGA.ERROR_IN_DOWNLOADING_OSHA_PDF })
    }
    action.callback(response)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
    if (error.response?.statusText === "Not Found") {
      Notification({ status: 400, message: CAMPAIGN_SAGA.OSHA_PDF_NO_DATA_YET })
    } else {
      Notification({ status: 400, message: CAMPAIGN_SAGA.ERROR_IN_DOWNLOADING_OSHA_PDF })
    }
  }
}

/** getContactCampaignList API */

function* getContactCampaignListRequest(action) {
  try {
    const { isLoadingRequired = false, ...payload } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getContactCampaignList, payload)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    if (response?.data) {
      action.callback({ ...response.data, pageSize: payload.pageSize })
    } else {
      action.callback()
      Notification({ status: 400, message: CAMPAIGN_SAGA.ERROR_IN_FETCHING_LIST })
    }
  } catch (error) {
    const { isLoadingRequired } = action.payload
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    action.callback()
  }
}

/** createCampaignChecklist API **/
function* createCampaignChecklistRequest(action) {
  try {
    const { isTrainingWFTemplate } = action.payload
    yield put(actions.setIsLoading(true))
    const response = yield call(createCampaignChecklist, action.payload)
    if (response?.data) {
      action.callback(response.data)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: isTrainingWFTemplate ? WORKFLOW_ADD_TRAINING_SAGA.ADDED_SUCCESS : CAMPAIGN_CHECKLIST_SAGA.ADDED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: isTrainingWFTemplate ? WORKFLOW_ADD_TRAINING_SAGA.ERROR_IN_ADDING : CAMPAIGN_CHECKLIST_SAGA.ERROR_IN_ADDING })
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

function* updateCampaignChecklistRequest(action) {
  try {
    const { isTrainingWFTemplate } = action.payload
    yield put(actions.setIsLoading(true))
    const response = yield call(updateCampaignChecklist, action.payload)
    if (response?.data?.status) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: isTrainingWFTemplate ? WORKFLOW_ADD_TRAINING_SAGA.UPDATED_SUCCESS : CAMPAIGN_CHECKLIST_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: isTrainingWFTemplate ? WORKFLOW_ADD_TRAINING_SAGA.ERROR_IN_UPDATING : CAMPAIGN_CHECKLIST_SAGA.ERROR_IN_UPDATING })
    }
  } catch (error) {
    const { isTrainingWFTemplate } = action.payload
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: isTrainingWFTemplate ? WORKFLOW_ADD_TRAINING_SAGA.ERROR_IN_UPDATING : CAMPAIGN_CHECKLIST_SAGA.ERROR_IN_UPDATING })
    action.callback()
  }
}

/** getCampaignChecklistDetail API **/
function* getCampaignChecklistDetailRequest(action) {
  try {
    const { payload: { isLoadingRequired = false, isTrainingWFTemplate } } = action
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(true))
    }
    const response = yield call(getCampaignChecklistDetail, action.payload)
    if (!response?.data && response.status !== 204) {
      Notification({ status: 400, message: isTrainingWFTemplate ? WORKFLOW_ADD_TRAINING_SAGA.ERROR_IN_FETCHING_TRAINING_DETAIL : CAMPAIGN_CHECKLIST_SAGA.ERROR_IN_FETCHING_CHECKLIST_DETAIL })
    }
    action.callback(response?.data)
    if (isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    if (action.payload.isLoadingRequired) {
      yield put(actions.setIsLoading(false))
    }
    action.callback()
  }
}

function* getCampaignWorkflowVMRequest(action) {
  try {
    const response = yield call(getCampaignWorkflowVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getCampaignWorkflows API **/
function* getCampaignWorkflowsRequest(action) {
  try {
    const response = yield call(getCampaignWorkflows, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback(null)
    }
  } catch (error) {
    action.callback(null)
  }
}

/** cloneCampaign API */
function* cloneCampaignRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(cloneCampaign, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.data) {
      action.callback(response?.data)
      Notification({ status: 200, message: CAMPAIGN_SAGA.CLONE_SUCCESS })
    } else {
      Notification({ status: 400, message: CAMPAIGN_SAGA.ERROR_IN_CLONING })
      action.callback()
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getCampaignTagsDDL API **/
function* getCampaignTagsDDLRequest(action) {
  try {
    const response = yield call(getCampaignTagsDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

/** updateChecklistRequiredActivity API **/
function* updateCheckListRequiredActivityRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateChecklistRequiredActivity, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      if (action.payload.checklistActivites[0].isRequired) {
        Notification({ status: 200, message: CAMPAIGN_SAGA.ACTIVITY_REQUIRED })
      } else {
        Notification({ status: 200, message: CAMPAIGN_SAGA.ACTIVITY_NOT_REQUIRED })
      }
    } else {
      action.callback(false)
      Notification({ status: 400, message: CAMPAIGN_SAGA.ERROR_IN_UPDATING_REQUIRED_ACTIVITY })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getCampaignReportList API **/
function* getCampaignReportListRequest(action) {
  try {
    const response = yield call(getCampaignReportList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** updateChecklistCopyAllowedActivity API */
function* updateChecklistCopyAllowedActivityRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateChecklistCopyAllowedActivity, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CAMPAIGN_SAGA.ACTIVITY_COPY_ALLOWED })
    } else {
      Notification({ status: 400, message: CAMPAIGN_SAGA.ERROR_IN_COPY_ALLOWED_ACTIVITY })
      action.callback()
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getSharedCampaignUnLinkedSiteList API **/
function* getSharedCampaignUnLinkedSiteListRequest(action) {
  try {
    const response = yield call(getSharedCampaignUnLinkedSiteList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** addSitesToSharedCampaign API */
function* addSitesToSharedCampaignRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addSitesToSharedCampaign, action.payload)
    yield put(actions.setIsLoading(false))
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CAMPAIGN_SAGA.SITES_ADDED_SUCCESS })
    } else {
      Notification({ status: 400, message: CAMPAIGN_SAGA.ERROR_IN_ADDING_SITES })
      action.callback()
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** getSharedCampaignSiteList API **/
function* getSharedCampaignSiteListRequest(action) {
  try {
    const response = yield call(getSharedCampaignSiteList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getCampaignMissingSiteRoles API **/
function* getCampaignMissingSiteRolesRequest(action) {
  try {
    const response = yield call(getCampaignMissingSiteRoles, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getSiteDDLByCampaignId API **/
function* getSiteDDLByCampaignIdRequest(action) {
  try {
    const response = yield call(getSiteDDLByCampaignId, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getOrganizationDDLByCampaignId API **/
function* getOrganizationDDLByCampaignIdRequest(action) {
  try {
    const response = yield call(getOrganizationDDLByCampaignId, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** deleteSharedCampaignSite API **/
function* deleteSharedCampaignSiteRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteSharedCampaignSite, action.payload)
    if (response?.status === 204) {
      action.callback()
      Notification({ status: 200, message: CAMPAIGN_SAGA.DELETED_SHARED_CAMPAIGN_SITE_SUCCESS })
    } else {
      Notification({ status: 400, message: CAMPAIGN_SAGA.ERROR_IN_DELETING_SHARED_CAMPAIGN_SITE })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** getSharedCampaignUnLinkedSiteOrganizationDDLList API **/
function* getSharedCampaignUnLinkedSiteOrganizationDDLListRequest(action) {
  try {
    const response = yield call(getSharedCampaignUnLinkedSiteOrganizationDDLList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export function* campaignSagas() {
  yield takeLatest(actions.GET_CAMPAIGN_DETAIL_VM_REQUEST, getCampaignDetailVMRequest)
  yield takeLatest(actions.ADD_CAMPAIGN_DETAIL_REQUEST, addCampaignDetailRequest)
  yield takeLatest(actions.GET_CAMPAIGN_DETAIL_REQUEST, getCampaignDetailRequest)
  yield takeLatest(actions.GET_CAMPAIGN_BASIC_DETAIL_REQUEST, getCampaignBasicDetailRequest)
  yield takeLatest(actions.GET_SERVICE_DETAIL_VM_REQUEST, getServiceDetailVMRequest)
  yield takeLatest(actions.UPDATE_CAMPAIGN_DETAIL_REQUEST, updateCampaignDetailRequest)
  yield takeLatest(actions.ADD_CAMPAIGN_SERVICE_DETAIL_REQUEST, addCampaignServiceDetailRequest)
  yield takeLatest(actions.GET_CAMPAIGN_SERVICE_LIST_REQUEST, getCampaignServiceListRequest)
  yield takeEvery(actions.GET_CAMPAIGN_SERVICE_CHECKLIST_LIST_REQUEST, getCampaignServiceChecklistRequest)
  yield takeLatest(actions.GET_PROJECT_LIST_DDL, getProjectListDDLRequest)
  yield takeLatest(actions.DELETE_CAMPAIGN_SERVICE_REQUEST, deleteCampaignServiceRequest)
  yield takeLatest(actions.GET_CAMPAIGN_LIST_REQUEST, getCampaignListRequest)
  yield takeLatest(actions.GET_CAMPAIGN_DESCRIPTION_REQUEST, getCampaignDescriptionRequest)
  yield takeLatest(actions.DELETE_CAMPAIGN_REQUEST, deleteCampaignRequest)
  yield takeLatest(actions.GET_CAMPAIGN_OBSERVATION_LIST_REQUEST, getCampaignObservationListRequest)
  yield takeLatest(actions.GET_CAMPAIGN_DDL_REQUEST, getCampaignDDLRequest)
  yield takeLatest(actions.GET_CAMPAIGN_SERVICE_DDL_REQUEST, getCampaignServiceDDLRequest)
  yield takeLatest(actions.GET_OBSERVATION_TASK_VM_REQUEST, getObservationTaskVMRequest)
  yield takeLatest(actions.GET_CAMPAIGN_OSHA_PDF_REQUEST, getCampaignOshaPDFRequest)
  yield takeLatest(actions.GET_CONTACT_CAMPAIGN_LIST_REQUEST, getContactCampaignListRequest)
  yield takeLatest(actions.CREATE_CAMPAIGN_CHECKLIST_REQUEST, createCampaignChecklistRequest)
  yield takeLatest(actions.UPDATE_CAMPAIGN_CHECKLIST_REQUEST, updateCampaignChecklistRequest)
  yield takeLatest(actions.GET_CAMPAIGN_CHECKLIST_DETAIL_REQUEST, getCampaignChecklistDetailRequest)
  yield takeLatest(actions.GET_CAMPAIGN_WORKFLOW_VM, getCampaignWorkflowVMRequest)
  yield takeLatest(actions.GET_CAMPAIGN_WORKFLOWS_REQUEST, getCampaignWorkflowsRequest)
  yield takeLatest(actions.CLONE_CAMPAIGN_REQUEST, cloneCampaignRequest)
  yield takeLatest(actions.GET_CAMPAIGN_TAGS_DDL_REQUEST, getCampaignTagsDDLRequest)
  yield takeLatest(actions.UPDATE_CHECKLIST_REQUIRED_ACTIVITY_REQUEST, updateCheckListRequiredActivityRequest)
  yield takeLatest(actions.GET_CAMPAIGN_REPORT_LIST_REQUEST, getCampaignReportListRequest)
  yield takeLatest(actions.UPDATE_CHECKLIST_COPY_ALLOWED_ACTIVITY_REQUEST, updateChecklistCopyAllowedActivityRequest)
  yield takeLatest(actions.GET_SHARED_CAMPAIGN_UNLINKED_SITE_LIST_REQUEST, getSharedCampaignUnLinkedSiteListRequest)
  yield takeLatest(actions.ADD_SITES_TO_SHARED_CAMPAIGN_REQUEST, addSitesToSharedCampaignRequest)
  yield takeLatest(actions.GET_SHARED_CAMPAIGN_SITE_LIST_REQUEST, getSharedCampaignSiteListRequest)
  yield takeLatest(actions.GET_CAMPAIGN_MISSING_SITE_ROLES_REQUEST, getCampaignMissingSiteRolesRequest)
  yield takeLatest(actions.GET_SITE_DDL_BY_CAMPAIGN_ID_REQUEST, getSiteDDLByCampaignIdRequest)
  yield takeLatest(actions.GET_ORGANIZATION_DDL_BY_CAMPAIGN_ID_REQUEST, getOrganizationDDLByCampaignIdRequest)
  yield takeLatest(actions.DELETE_SHARED_CAMPAIGN_SITE_REQUEST, deleteSharedCampaignSiteRequest)
  yield takeLatest(actions.GET_SHARED_CAMPAIGN_UNLINKED_SITE_ORGANIZATION_DDL_LIST_REQUEST, getSharedCampaignUnLinkedSiteOrganizationDDLListRequest)
}

export default campaignSagas
