import React, { useState, useCallback, useEffect } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"
import PropTypes from 'prop-types'
import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import * as actions from "@store/actions"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"

const CampaignServiceSearch = ({
  isEditMode,
  isViewMode,
  errors,
  defaultOptions,
  id,
  isMulti,
  isRequired,
  isShowCustomAction,
  label,
  labelRequired,
  name,
  placeholder,
  value,
  valueType,
  labelType,
  onSelect = () => { },
  getOptionLabel = (option) => option.text,
  getOptionValue = (option) => option.value
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState([])

  /**
  * Note: Paginated select maintain cache of options,
  * so to set new default options we need to reset previous cache
  */
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("")

  //Todo: handle edit mode when single select

  useEffect(() => {
    const options = defaultOptions.map(option => ({
      text: option.campaignServiceName,
      value: option.campaignServiceId
    }))
    if (!isEditMode) {
      setResetCache(prevState => !prevState)
    }
    setOptions(options)
  }, [defaultOptions])

  const handleLoadMoreOptions = useCallback(
    (search, page, prevOptions) => {
      return new Promise((resolve) => {

        const handleSearchResultCallback = (response) => {
          if (!!response.items) {
            const { hasNextPage } = response
            let { items } = response
            items = items.map(option => ({
              text: option.campaignServiceName,
              value: option.campaignServiceId
            }))
            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
            resolve({
              optionList: items,
              hasMore: hasNextPage
            })
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }
        const params = {
          campaignServiceName: search,
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE
        }

        dispatch(
          actions.getCampaignServiceDDLRequest(params, handleSearchResultCallback))
      }

      )
    }, [options])

  const handleSelect = useCallback((selected) => {
    if (isMulti) {
      const lastElement = selected.length
        ? selected[selected.length - 1]
        : null
      if (!!lastElement && !lastElement.value) {
        onSelect(id, [])
      } else {
        onSelect(id, selected)
      }
    } else {
      onSelect(id, selected?.value || "", selected?.text || "")
      setSelectedOptionLabel(selected?.text || "")
    }
  }, [onSelect, id])

  return (
    <Col className="mb-1" sm={12} md={6} xl={4}>
      {labelRequired && <CustomLabel title={label} requiredIconClassName="required-field" required={isRequired} isLocalizedTitle={false} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id={id}
        name={name}
        placeholder={placeholder}
        options={options}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={
          !isMulti
            ? !!value
              ? {
                text: selectedOptionLabel,
                value
              }
              : null
            : value
        }
        isShowCustomAction={isShowCustomAction}
        valueType={valueType}
        labelType={labelType}
        onSelect={handleSelect}
        onLoadOptions={handleLoadMoreOptions}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isMulti={isMulti}
        hideSelectedOptions={false}
        isDisabled={isViewMode}
      />
      {errors && (
        <ErrorMessage
          isShow={!!errors[id]}
          message={errors[id]}
        />
      )}
    </Col>
  )
}

CampaignServiceSearch.propTypes = {
  isEditMode: PropTypes.bool,
  isViewMode: PropTypes.bool,
  errors: PropTypes.object,
  defaultOptions: PropTypes.array,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  isShowCustomAction: PropTypes.bool,
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  valueType: PropTypes.string,
  labelType: PropTypes.string,
  onSelect: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func
}

CampaignServiceSearch.defaultProps = {
  isEditMode: false,
  isViewMode: false,
  defaultOptions: [],
  id: "",
  isMulti: true,
  isRequired: false,
  isShowCustomAction: false,
  labelRequired: true,
  name: "",
  value: "",
  valueType: "value",
  labelType: "text"
}

export default CampaignServiceSearch
