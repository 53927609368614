import cx from "classnames"
import React, { useCallback, useMemo } from "react"
import { ChevronRight, ChevronLeft } from "react-feather"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"
import { CustomCheckbox } from "@views/components"
import { FILTER_SECTION_TYPE } from "./config"

const { FILTER_TABLE_MODAL, LOADER, EDIT } = LANGUAGE_CONSTANTS

const SavedFilters = ({
  intl,
  expandedViewId,
  filterOptions,
  isFetchingFilters,
  selectedFilterId,
  onToggleView,
  onEditSavedFilter,
  onResetAll,
  onSelectSavedFilter
}) => {
  const handleToggleView = useCallback(() => {
    onToggleView(FILTER_SECTION_TYPE.SAVED_FILTERS)
  }, [onToggleView])

  const handleEditFilter = useCallback(
    (config) => {
      onEditSavedFilter(FILTER_SECTION_TYPE.SAVE_FILTER_CONFIG, config)
    },
    [onEditSavedFilter]
  )

  const handleSelectFilter = useCallback(
    (config) => {
      onSelectSavedFilter(config)
    },
    [onSelectSavedFilter]
  )

  const isOpen = useMemo(
    () => expandedViewId === FILTER_SECTION_TYPE.SAVED_FILTERS,
    [expandedViewId]
  )

  return (
    <div className="setup-accordion-header save-filter-header">
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className={cx(
            "waves-effect btn advanced-btn more-less-btn text-capitalize",
            { active: isOpen }
          )}
          onClick={handleToggleView}
        >
          {getLocaleMessage(intl, FILTER_TABLE_MODAL.SAVED_FILTERS)}
          {isOpen ? <ChevronRight /> : <ChevronLeft />}
        </button>
        <button
          type="button"
          className={cx(
            "btn primary-text-btn",
            { active: isOpen }
          )}
          onClick={onResetAll}
        >
          {getLocaleMessage(intl, FILTER_TABLE_MODAL.RESET_ALL)}
        </button>
      </div>
      {isOpen && (
        <div className="check-list-outer custom-checkbox-search">
          {filterOptions.map((filter, index) => {
            if (!filter) {
              return null
            }
            
            return (
              <div
                key={`saved-filter-${filter.advanceSearchFilterId}-${index}`}
                className="check-list-box"
              >
                <CustomCheckbox
                  isNewListTheme
                  isChecked={selectedFilterId === filter.advanceSearchFilterId}
                  onChange={() => handleSelectFilter(filter)}
                />
                <span>
                  {filter.searchFilterName}
                </span>
                {filter.canEdit && (
                  <span className="edit-text" onClick={() => handleEditFilter(filter)}>{getLocaleMessage(intl, EDIT)}</span>
                )}
              </div>
            )
          })}
          {isFetchingFilters && <div className="d-flex mb-1 mt-1">
            <span><strong>{getLocaleMessage(intl, LOADER.LOADING)}</strong></span>
          </div>}
          {!isFetchingFilters && !filterOptions.length && <div className="d-flex mb-1 mt-1">
            <span><strong>{getLocaleMessage(intl, FILTER_TABLE_MODAL.NO_SAVED_FILTERS)}</strong></span>
          </div>}
        </div>
      )}
    </div>
  )
}

export default localeMessageWrapper(SavedFilters)
