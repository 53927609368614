import React, { useMemo } from "react"
import {
    Col,
    FormGroup,
    Row
} from "reactstrap"
import { useForm } from "react-hook-form"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import {
    CustomLabel,
    ErrorMessage,
    ReactSelect,
    NumericInput
} from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import { getReactSelectPositionProps } from "@views/helpers"
import { MissingItemsOptions } from "./config"

const { ORG_PLANS } = LANGUAGE_CONSTANTS

const PlanDetailSection = ({
    intl,
    colWrapperProps,
    errors,
    planDetail,
    onChangeDetail = () => { }
}) => {
    const { control } = useForm()

    const reactSelectPostionProps = useMemo(() => {
        return getReactSelectPositionProps()
    }, [])

    return (
        <Row>
            <Col className="mb-1" {...colWrapperProps}>
                <FormGroup>
                    <NumericInput
                        id="totalConditionSpace"
                        name="totalConditionSpace"
                        isRequired
                        labelLocaleId={ORG_PLANS.TOTAL_CONDITION_SPACE}
                        placeholderLocaleId={ORG_PLANS.ENTER_NUMBER}
                        value={planDetail.totalConditionSpace}
                        onChange={onChangeDetail}
                    />
                </FormGroup>
                <ErrorMessage
                    className="error-message"
                    isShow={!!errors["totalConditionSpace"]}
                    message={errors["totalConditionSpace"]}
                />
            </Col>
            <Col className="mb-1" {...colWrapperProps}>
                <FormGroup>
                    <NumericInput
                        id="firstFloorArea"
                        name="firstFloorArea"
                        isRequired
                        labelLocaleId={ORG_PLANS.FIRST_FLOOR_AREA}
                        placeholderLocaleId={ORG_PLANS.ENTER_NUMBER}
                        value={planDetail.firstFloorArea}
                        onChange={onChangeDetail}
                    />
                </FormGroup>
                <ErrorMessage
                    className="error-message"
                    isShow={!!errors["firstFloorArea"]}
                    message={errors["firstFloorArea"]}
                />
            </Col>
            <Col className="mb-1" {...colWrapperProps}>
                <FormGroup>
                    <NumericInput
                        id="secondFloorArea"
                        name="secondFloorArea"
                        isRequired
                        labelLocaleId={ORG_PLANS.SECOND_FLOOR_AREA}
                        placeholderLocaleId={ORG_PLANS.ENTER_NUMBER}
                        value={planDetail.secondFloorArea}
                        onChange={onChangeDetail}
                    />
                </FormGroup>
                <ErrorMessage
                    className="error-message"
                    isShow={!!errors["secondFloorArea"]}
                    message={errors["secondFloorArea"]}
                />
            </Col>
            <Col className="mb-1" {...colWrapperProps}>
                <FormGroup>
                    <NumericInput
                        id="thirdFloorArea"
                        name="thirdFloorArea"
                        isRequired
                        labelLocaleId={ORG_PLANS.THIRD_FLOOR_AREA}
                        placeholderLocaleId={ORG_PLANS.ENTER_NUMBER}
                        value={planDetail.thirdFloorArea}
                        onChange={onChangeDetail}
                    />
                </FormGroup>
                <ErrorMessage
                    className="error-message"
                    isShow={!!errors["thirdFloorArea"]}
                    message={errors["thirdFloorArea"]}
                />
            </Col>
            <Col className="mb-1" {...colWrapperProps}>
                <FormGroup>
                    <NumericInput
                        id="fourthFloorArea"
                        name="fourthFloorArea"
                        isRequired
                        labelLocaleId={ORG_PLANS.FOURTH_FLOOR_AREA}
                        placeholderLocaleId={ORG_PLANS.ENTER_NUMBER}
                        value={planDetail.fourthFloorArea}
                        onChange={onChangeDetail}
                    />
                </FormGroup>
                <ErrorMessage
                    className="error-message"
                    isShow={!!errors["fourthFloorArea"]}
                    message={errors["fourthFloorArea"]}
                />
            </Col>
            <Col className="mb-1" {...colWrapperProps}>
                <FormGroup>
                    <NumericInput
                        id="volume"
                        name="volume"
                        isRequired
                        labelLocaleId={ORG_PLANS.VOLUME}
                        placeholderLocaleId={ORG_PLANS.ENTER_NUMBER}
                        value={planDetail.volume}
                        onChange={onChangeDetail}
                    />
                </FormGroup>
                <ErrorMessage
                    className="error-message"
                    isShow={!!errors["volume"]}
                    message={errors["volume"]}
                />
            </Col>
            <Col className="mb-1" {...colWrapperProps}>
                <FormGroup>
                    <NumericInput
                        id="mfncArea"
                        name="mfncArea"
                        labelLocaleId={ORG_PLANS.MFNC}
                        placeholderLocaleId={ORG_PLANS.ENTER_NUMBER}
                        value={planDetail.mfncArea}
                        onChange={onChangeDetail}
                    />
                </FormGroup>
            </Col>
            <Col className="mb-1" {...colWrapperProps}>
                <FormGroup>
                    <CustomLabel title={ORG_PLANS.MISSING_ITEMS} />
                    <ReactSelect
                        isClearable
                        id="missingItems"
                        name="missingItems"
                        placeHolder={getLocaleMessage(intl, ORG_PLANS.SELECT)}
                        control={control}
                        value={planDetail.missingItems}
                        options={MissingItemsOptions}
                        getOptionLabel={(item) => item.text}
                        getOptionValue={(item) => item.value}
                        onSelect={(selected) => onChangeDetail("missingItems", selected?.value, selected?.text)}
                        {...reactSelectPostionProps}
                    />
                </FormGroup>
            </Col>
        </Row>
    )
}

export default localeMessageWrapper(PlanDetailSection)
