import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from '@utils'
import RowActions from './RowActions'

const { ORGANIZATIONS: { ORG_PLAN: { TABLE_COLUMNS } } } = LANGUAGE_CONSTANTS

export const getColumnConfig = ({ intl }) => {
  return [
    {
      name: getLocaleMessage(
        intl,
        TABLE_COLUMNS.BLUEPRINT_NAME
      ),
      selector: 'bluePrintName',
      minWidth: '170px'
    },
    {
      name: getLocaleMessage(
        intl,
        TABLE_COLUMNS.NUMBER_OF_ENERGY_MODELS
      ),
      selector: 'energyModelCount',
      minWidth: '150px'
    },
    {
      name: getLocaleMessage(
        intl,
        TABLE_COLUMNS.UPDATED_DATE
      ),
      selector: 'lastUpdated',
      minWidth: '150px'
    },
    {
      selector: 'actions',
      minWidth: '90px',
      maxWidth: '100px',
      allowOverflow: true,
      cell: (row) => <RowActions data={row} />
    }
  ]
}