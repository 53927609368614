import { getActionType } from '../action-type-generator'

export const GET_PROJECT_SITE_LIST_REQUEST = 'GET_PROJECT_SITE_LIST_REQUEST'
export const GET_SITE_UNIT_CHILD_GRID = getActionType('GET_SITE_UNIT_CHILD_GRID')
export const ADD_PROJECT_SITE_DETAILS = getActionType('ADD_PROJECT_SITE_DETAILS')
export const GET_PROJECT_SITE_DETAILS = getActionType('GET_PROJECT_SITE_DETAILS')
export const UPDATE_PROJECT_SITE_DETAILS = getActionType('UPDATE_PROJECT_SITE_DETAILS')
export const SET_EDIT_SITE_MODE = 'SET_EDIT_SITE_MODE'
export const GET_PROJECT_SITE_VM_REQUEST = 'GET_PROJECT_SITE_VM_REQUEST'
export const GET_CONTACT_BY_ORGANIZATION = getActionType('GET_CONTACT_BY_ORGANIZATION')
export const RESET_CONTACT_BY_ORGANIZATION = getActionType('RESET_CONTACT_BY_ORGANIZATION')
export const DELETE_PROJECT_SITE = getActionType('DELETE_PROJECT_SITE')

/****************************************************/
/** GET_PROJECT_SITE_LIST_REQUEST **/
/****************************************************/

/**
 * @method getProjectSiteListRequest :  To get project sites list
 *
 */
 export const getProjectSiteListRequest = (payload, callback = () => {}) => {
  return {
    type: GET_PROJECT_SITE_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_SITE_UNIT_CHILD_GRID **/
/****************************************************/

/**
 * @method getSiteUnitChildGridRequest :  To get site unit child grid
 *
 */
 export const getSiteUnitChildGridRequest = (payload, callback) => {
  return {
    type: GET_SITE_UNIT_CHILD_GRID.REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROJECT_SITE_VM_REQUEST **/
/****************************************************/

/**
 * @method getProjectSiteVMRequest : To get dropdowns list for site setup/search
 *
 */
 export const getProjectSiteVMRequest = (payload, callback = () => {}) => {
  return {
    type: GET_PROJECT_SITE_VM_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_PROJECT_SITE_DETAILS **/
/****************************************************/

/**
 * @method getProjectSiteDetailsRequest :  To get site details of Project
 *
 */
 export const getProjectSiteDetailsRequest = (payload, callback = () => {}) => {
  return {
    type: GET_PROJECT_SITE_DETAILS.REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** ADD_PROJECT_DETAILS_ **/
/****************************************************/

/**
 * @method addProjectSiteDetailsRequest :  To add site details of Project
 *
 */
 export const addProjectSiteDetailsRequest = (payload, callback = () => {}) => {
  return {
    type: ADD_PROJECT_SITE_DETAILS.REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_PROJECT_SITE_DETAILS **/
/****************************************************/

/**
 * @method updateProjectSiteDetailsRequest :  To update site details of Project
 *
 */
 export const updateProjectSiteDetailsRequest = (payload, callback = () => {}) => {
  return {
    type: UPDATE_PROJECT_SITE_DETAILS.REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** SET_EDIT_SITE_MODE **/
/****************************************************/

/**
 * @method setEditSiteMode : To set edit mode for Site detail
 *
 */
 export const setEditSiteMode = (value) => {
  return {
    type: SET_EDIT_SITE_MODE,
    payload: value
  }
}

/****************************************************/
/** DELETE_PROJECT_SITE **/
/****************************************************/

/**
 * @method deleteProjectSiteRequest : To delete Project Site 
 *
 */
 export const deleteProjectSiteRequest = (payload, callback = () => {}) => {
  return {
    type: DELETE_PROJECT_SITE.REQUEST,
    payload,
    callback
  }
}
/**
* @method deleteProjectSiteSuccess : To handle success of delete Project Site 
 *
 */
 export const deleteProjectSiteSuccess = (data) => {
  return {
    type: DELETE_PROJECT_SITE.SUCCESS,
    data
  }
}
/**
* @method deleteProjectSiteFailure :  To handle failure of delete Project Site 
 *
 */
 export const deleteProjectSiteFailure = (error) => {
  return {
    type: DELETE_PROJECT_SITE.FAILURE,
    error
  }
}

/****************************************************/
/** GET_CONTACT_BY_ORGANIZATION **/
/****************************************************/

/**
 * @method getContactByOrganizationRequest : To get contacts by organization 
 *
 */
 export const getContactByOrganizationRequest = (payload, callback) => {
  return {
    type: GET_CONTACT_BY_ORGANIZATION.REQUEST,
    payload,
    callback
  }
}

/**
 * @method getContactByOrganizationSuccess : To set contacts by organization on success
 *
 */
export const getContactByOrganizationSuccess = (data) => {
  return {
    type: GET_CONTACT_BY_ORGANIZATION.SUCCESS,
    data
  }
}

/**
 * @method getContactByOrganizationFailure : To set error on getting contacts by organization 
 *
 */
export const getContactByOrganizationFailure = (error) => {
  return {
    type: GET_CONTACT_BY_ORGANIZATION.FAILURE,
    error
  }
}

/****************************************************/
/** RESET_CONTACT_BY_ORGANIZATION **/
/****************************************************/

/**
 * @method resetContactByOrganization : To reset contacts by organization 
 *
 */
 export const resetContactByOrganization = () => {
  return {
    type: RESET_CONTACT_BY_ORGANIZATION
  }
}
