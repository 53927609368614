import { SET_EDIT_SITE_ROLE_MODE } from "../../actions"

// ** Initial State
const initialState = {
  isEditMode: false,
  siteRoleDetail: null
}

const ProjectSiteRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    /** SET_EDIT_SITE_ROLE_MODE **/

    case SET_EDIT_SITE_ROLE_MODE:
      return {
        ...state,
        isEditMode: action.payload.isEditMode,
        siteRoleDetail: action.payload.siteRoleDetail
      }

    default:
      return state
  }
}

export default ProjectSiteRolesReducer
