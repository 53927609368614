import PropTypes from 'prop-types'
import React, { useState, useCallback, useEffect, useMemo } from "react"
import { Col } from "reactstrap"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "@shared/constants"
import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomReactSelect, CustomLabel } from "@views/components"
import localeMessageWrapper from '@views/components/locale-message'

const { TAG, SELECT_TAG } = LANGUAGE_CONSTANTS

const TagsSearch = ({
  intl,
  asyncInitialValue,
  isEditMode,
  wrapperStyle,
  initialLabel,
  errors,
  defaultOptions = [],
  id,
  isMulti,
  isRequired,
  isRequiresUpdateForInitialLabelState,
  label,
  labelRequired,
  name,
  placeholder,
  value,
  valueType,
  labelType,
  tagSourceId = 0,
  isDisabled,
  menuPlacement = 'auto',
  isColWrapper,
  customComponents = {},
  customSelectedLable,
  onSelect = () => { },
  getOptionLabel = (option) => option.text,
  getOptionValue = (option) => option.value,
  ...props
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState([])
  /**
  * Note: Paginated select maintain cache of options,
  * so to set new default options we need to reset previous cache
  */
  const [isResetCache, setResetCache] = useState(false)
  const [selectedOptionLabel, setSelectedOptionLabel] = useState('')
  const [isEditModeItemLoaded, setEditModeItemLoaded] = useState(false)

  /**
* @method handleSetDefaultOptions : To handle set default options in case of Edit mode, Add new from modal
*
* Note: To handle case of duplicate option, when custom option is added, that is selected from edit mode or add new modal
*/
  const handleSetDefaultOptions = useCallback(
    (selectedItem, selectedValue) => {
      const checkIfAlreadyPresent = defaultOptions.find(
        (option) => option.value === selectedValue
      )
      if (!!checkIfAlreadyPresent) {
        setOptions(defaultOptions)
      } else {
        setOptions([selectedItem, ...defaultOptions])
      }
    },
    [defaultOptions]
  )
  //Todo: handle edit mode when single select

  useEffect(() => {
    setResetCache((prevState) => !prevState)
    setOptions(defaultOptions)
  }, [defaultOptions])

  useEffect(() => {
    //Note: When tags are fetched in parent component
    if ((!isEditModeItemLoaded || (!!initialLabel?.length && !selectedOptionLabel?.length)) && isEditMode && asyncInitialValue && !!value && !!initialLabel && isRequiresUpdateForInitialLabelState) {
      const selectedItem = {
        text: initialLabel,
        value
      }
      setEditModeItemLoaded(true)
      setSelectedOptionLabel(initialLabel)
      if (!isRequiresUpdateForInitialLabelState) {
        setResetCache(prevState => !prevState)
      }
      handleSetDefaultOptions(selectedItem, value)
    }
  }, [defaultOptions, initialLabel, isEditMode, isEditModeItemLoaded, value])

  const handleLoadMoreOptions = useCallback(
    (search, page, prevOptions) => {
      return new Promise((resolve) => {
        const handleSearchResultCallback = (response) => {
          if (response) {
            const { items, hasNextPage } = response
            setOptions(() => (!!prevOptions.length ? [...prevOptions, ...items] : items))
            resolve({
              optionList: items,
              hasMore: hasNextPage
            })
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        }
        const params = {
          tagName: search,
          pageNumber: page,
          pageSize: DEFAULT_VM_PAGE_SIZE
        }
        if (tagSourceId) {
          params.tagSourceId = tagSourceId
          dispatch(
            actions.getCommonTagsDDLRequest(params, handleSearchResultCallback)
          )
        } else {
          dispatch(
            actions.getCampaignTagsDDLRequest(params, handleSearchResultCallback)
          )
        }
      })
    },
    [options, tagSourceId]
  )

  const handleSelect = useCallback(
    (selected) => {
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        if (!!lastElement && !lastElement.value) {
          onSelect(id, [])
        } else {
          onSelect(id, selected)
        }
      } else {
        onSelect(id, selected?.value || "", (selected?.value && selected?.text) || "")
        setSelectedOptionLabel((selected?.value && selected?.text) || "")
      }
    },
    [onSelect, id]
  )

  const WrapperComponent = useMemo(() => (isColWrapper ? Col : 'div'), [isColWrapper])

  const colWrapperProps = useMemo(() => {
    if (!isColWrapper) {
      return {}
    }

    return {
      className: "mb-1",
      sm: wrapperStyle.sm,
      md: wrapperStyle.md,
      xl: wrapperStyle.xl
    }
  }, [isColWrapper])

  return (
    <WrapperComponent {...colWrapperProps}>
      {labelRequired && <CustomLabel title={label || TAG} required={isRequired} />}
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id={id}
        name={name}
        placeholder={
          placeholder || getLocaleMessage(intl, SELECT_TAG)
        }
        options={options}
        isDisabled={isDisabled}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={
          !isMulti
            ? !!value
              ? {
                text: selectedOptionLabel,
                value
              }
              : null
            : value
        }
        isShowCustomAction={false}
        customComponents={customComponents}
        customSelectedLable={customSelectedLable}
        valueType={valueType}
        labelType={labelType}
        isMulti={isMulti}
        hideSelectedOptions={false}
        menuPlacement={menuPlacement}
        onSelect={handleSelect}
        onLoadOptions={handleLoadMoreOptions}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        {...props}
      />
      {errors && <ErrorMessage isShow={!!errors[id]} message={errors[id]} />}
    </WrapperComponent>
  )
}

TagsSearch.propTypes = {
  intl: PropTypes.object,
  wrapperStyle: PropTypes.object,
  errors: PropTypes.object,
  defaultOptions: PropTypes.array,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isRequiresUpdateForInitialLabelState: PropTypes.bool,
  initialLabel: PropTypes.string,
  valueType: PropTypes.string,
  labelType: PropTypes.string,
  onSelect: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  isColWrapper: PropTypes.bool,
  customSelectedLable: PropTypes.string
}

TagsSearch.defaultProps = {
  defaultOptions: [],
  id: "tagIds",
  isMulti: true,
  isRequired: false,
  isDisabled: false,
  labelRequired: true,
  isRequiresUpdateForInitialLabelState: false,
  name: "tag",
  value: "",
  initialLabel: "",
  valueType: "value",
  labelType: "text",
  wrapperStyle: {
    md: 6,
    sm: 12,
    xl: 4
  },
  isColWrapper: true,
  customSelectedLable: ""
}

export default localeMessageWrapper(TagsSearch)
