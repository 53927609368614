import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addNoteAndMedia
 * @param {object} data
 * {
  "assignmentActivityId": 0,
  "noteMedia": {
    "noteId": 0,
    "note": "string",
    "images": [
      {
        "fileName": "string",
        "imageContent": "string",
        "imageId": 0,
        "jsonImageMetaData": "string",
        "isDeleted": true,
        "isExternal": true,
        "externalFileUrl": "string",
        "externalResources": 1
      }
    ]
  }
}
 * 
 *
 */
export const addNoteAndMedia = (payload) => {
  const { url } = config.addNoteAndMedia

  return postDataApi(url(payload), payload.data)
}

export default addNoteAndMedia
