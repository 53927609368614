import { useCallback, useMemo, useState } from 'react'

// ** Styles
import { Edit3, MoreVertical } from 'react-feather'
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  CHECKPOINT_ORIGNATED_FROM_TYPES,
  ROUTES,
  PERMISSION_ROUTES,
  TABLE_LIST_KEYS,
  COLUMN_LIST_TYPE,
  TEMPLATE_ACTION_TYPE
} from '@shared/constants'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { ConfirmationModal, DeleteConfirmationModal } from '@views/components'
import * as actions from '@store/actions'
import { isPermissionDenied } from '@router/Helper'
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"

const { TABLE, CLONE, NEW_VERSION, CLONE_TITLE, NEW_VERSION_TITLE, ACTIVITIES, REMOVE_ACTIVITY } = LANGUAGE_CONSTANTS

const RowActions = ({ intl, data, editFlag, type }) => {

  const dispatch = useDispatch()
  const history = useHistory()

  const { campaignServiceChecklists, campaignServiceChecklistCheckpoints: { tableData }, templateCheckpoints } = useSelector(state => state)
  const { pageSize = 0, pageNumber = 0 } = data?.campaignServiceCheckpointId
    ? (tableData[`checklist_${data.campaignServiceCheckListId}`]?.data || {}) : templateCheckpoints

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false)
  const [isOpenNewVersionModal, setOpenNewVersionModal] = useState(false)
  const [isOpenCloneModal, setOpenCloneModal] = useState(false)

  const templateInfo = useMemo(() => {
    const url = window.location.pathname.toLowerCase()
    const pathKeyList = url.split('/')
    const templateId = pathKeyList.pop()
    const { search } = window.location
    let templateVersionId = 1
    if (search) {
      const query = new URLSearchParams(search)
      templateVersionId = query.get('tp_v') || 1
    }
    return {
      templateId,
      templateVersionId
    }
  }, [])

  const handleGetCampaignCheckpointUrl = useCallback((
    {
      checkpointId,
      checkpointVersionId
    }
  ) => {
    const { pathname } = window.location
    //Navigate to ROUTES.checklistCheckpointDetail
    const search = data.campaignServiceCheckpointId ? `?cs_cp_id=${data.campaignServiceCheckpointId}&at=${data.activityTypeId || '1'}` : ''
    if (pathname.includes('campaigns/overview')) {
      if (!pathname.includes('checklist')) {
        return `${pathname}/checklist/${data.campaignServiceCheckListId}/activities/${checkpointId}/${checkpointVersionId}${search}`
      }
      return `${pathname}/activities/${checkpointId}/${checkpointVersionId}${search}`
    } else {
      try {
        const queryParams = new URLSearchParams(window.location.search)
        const serviceId = queryParams.get("sid")
        const campaignId = queryParams.get("cmpid")
        const url = `/campaigns/overview/${campaignId}/service/${serviceId}/checklist/${data.campaignServiceCheckListId}`
        return `${url}/activities/${checkpointId}/${checkpointVersionId}${search}`
      } catch (err) {
        return ''
      }
    }
  }, [data.activityTypeId, data.campaignServiceCheckListId, data.campaignServiceCheckpointId])

  const handleViewCheckpoint = useCallback((e) => {
    e.preventDefault()
    const { checkpointId, checkpointVersionId, activityTypeId = '1' } = data
    let navigateToRoute = `${ROUTES.checkpointsDetail.slice(0, ROUTES.checkpointsDetail.length - 10)}${checkpointId}/${checkpointVersionId}`
    if (window.location.pathname.includes(CHECKPOINT_ORIGNATED_FROM_TYPES.CAMPAIGN)) {
      navigateToRoute = handleGetCampaignCheckpointUrl({
        checkpointId,
        checkpointVersionId
      })
    } else {
      navigateToRoute = `${navigateToRoute}?at=${activityTypeId}`
    }

    if (!!navigateToRoute) {
      history.push(navigateToRoute)
    }
  }, [data, handleGetCampaignCheckpointUrl])

  const handleNavigateToEditCheckpointMode = useCallback((checkpointData) => {
    const url = window.location.pathname.toLowerCase()
    const pathKeyList = url.split('/')
    const templateId = pathKeyList.pop()
    const path = ROUTES.editTemplate

    const payload = {
      activeStep: 2,
      activeSubStep: 1,
      checkpointId: checkpointData ? checkpointData.checkpointId : data.checkpointId,
      checkpointVersionId: checkpointData ? checkpointData.checkpointVersionId : data.checkpointVersionId,
      //Note: set activityType when activity is cloned
      activityType: data.activityTypeId?.toString() || '1'
    }

    if (window.location.pathname.includes(CHECKPOINT_ORIGNATED_FROM_TYPES.CAMPAIGN)) {
      const navigateToRoute = handleGetCampaignCheckpointUrl({
        checkpointId: payload.checkpointId,
        checkpointVersionId: payload.checkpointVersionId
      })
      if (!!navigateToRoute) {
        history.push(navigateToRoute)
      }

      return false
    }

    if (url.includes(ROUTES.addNewTemplates.toLowerCase()) || url.includes(TEMPLATE_ACTION_TYPE.EDIT.toLowerCase())) {
      dispatch(actions.setEditCheckpointMode({
        isEditMode: true,
        data: payload
      }))
    } else {
      const { search } = window.location
      let templateVersion = 1
      if (search) {
        const query = new URLSearchParams(search)
        templateVersion = query.get('tp_v')
      }
      const searchParams = `?tp_v=${templateVersion}&at=${payload.activityTypeId}`
      history.push(`${path.slice(0, path.length - 11)}${templateId}${searchParams}`, payload)
    }
  }, [data, handleGetCampaignCheckpointUrl])

  const handleEditCheckpoint = useCallback((e) => {
    e.preventDefault()
    handleNavigateToEditCheckpointMode()
  }, [data, handleNavigateToEditCheckpointMode])

  const handleToggleDeleteCheckpoint = useCallback((event) => {
    if (event) {
      event.preventDefault()
    }
    setOpenDeleteModal(!isOpenDeleteModal)
  }, [isOpenDeleteModal])

  const handleDeleteCheckpoint = useCallback(() => {
    // Note: In case of campaign service checklist view ->
    // campaign service checkpoint relationship ID to delete checkpoint -------> campaignServiceCheckpointId
    if (data?.campaignServiceCheckpointId) {
      dispatch(actions.deleteCampaignServiceChecklistCheckpoint([data.campaignServiceCheckpointId], () => {
        let id = ''
        //todo : get child list for checklist based on selected child grid in overview
        if (data.campaignServiceCheckListId) {
          id = data.campaignServiceCheckListId
        }
        if (id && !isNaN(id)) {
          const { pathname } = window.location
          if (pathname.includes('campaigns/overview')) {
            //Todo: Refresh checklist in service detail overview after delete checkpoint from checklist
            // if (!window.location.pathname.includes('checklist')) {
            //   dispatch(actions.getCampaignServiceChecklistRequest({
            //     campaignServiceId: getIdFromUrl(),
            //     pageNumber: serviceChecklistsPageNumber,
            //     pageSize: serviceChecklistsPageSize }))
            // }

            if (pathname.includes('service') && !pathname.includes('checklist')) {
              //Note: We can use this where we do not have advance search form
              //When checkpoint list is used as child grid of checklist
              history.replace({
                search: `?cp_updated=${id}`
              })
            } else {
              //Note: If we have advance search form , we have to call common api function 
              dispatch(actions.refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: true }))
            }
          } else {
            dispatch(actions.refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: true }))
          }
        }
      }))
    } else {
      // Note: In case of template view ->
      // template checkpoint relationship ID to delete checkpoint -------> templateCheckpointId
      dispatch(actions.removeCheckpointFromTemplateRequest({
        templateCheckpointId: data.templateCheckpointId
      }, () => {
        dispatch(actions.refreshTableList({ listKey: TABLE_LIST_KEYS.ACTIVITY_LIST_KEY, value: true }))
      }))
    }
    handleToggleDeleteCheckpoint()
  }, [data, handleToggleDeleteCheckpoint, pageNumber, pageSize])

  const handleToggleCloneModal = useCallback((event) => {
    if (event) {
      event.preventDefault()
    }
    setOpenCloneModal(!isOpenCloneModal)
  }, [isOpenCloneModal])

  const handleConfirmClone = useCallback(() => {
    dispatch(actions.cloneTemplateCheckpointRequest({
      checkpointId: data.checkpointId,
      checkpointVersionId: data.checkpointVersionId,
      activityTypeId: data.activityTypeId,
      ...templateInfo
    }, (res) => {
      if (res) {
        handleToggleCloneModal()
        handleNavigateToEditCheckpointMode(res)
      }
    }))
  }, [data, templateInfo, handleToggleCloneModal, handleNavigateToEditCheckpointMode])

  const handleToggleAddNewVersion = useCallback((event) => {
    if (event) {
      event.preventDefault()
    }
    setOpenNewVersionModal(!isOpenNewVersionModal)
  }, [isOpenNewVersionModal])

  const handleConfirmAddNewVersion = useCallback(() => {
    dispatch(actions.addNewCheckpointVersionRequest({
      checkpointId: data.checkpointId,
      activityTypeId: data.activityTypeId
    }, () => {
      setOpenNewVersionModal(false)
    }))
  }, [data, isOpenNewVersionModal])

  const canView = !isPermissionDenied(ROUTES.checkpointsDetail)
  const canClone = !isPermissionDenied(PERMISSION_ROUTES.cloneCheckpoint)
  const canNewVersion = !isPermissionDenied(PERMISSION_ROUTES.newVersionCheckpoint)
  const canEdit = !isPermissionDenied(PERMISSION_ROUTES.updateCheckpoint)
  const canDelete = !isPermissionDenied(PERMISSION_ROUTES.deleteCheckpoint)

  //ToDo: remove disabled functionality for edit and view after workflow is done
  const isDisableAction = type === COLUMN_LIST_TYPE.WORKFLOW_ASSIGN_CHECKLIST_CHECKPOINTS

  return (
    <>
      <div className='d-flex'>
        {canEdit && editFlag && <a style={{ pointerEvents: isDisableAction ? 'none' : 'unset' }}>
          <Edit3 size={15} onClick={handleEditCheckpoint} />
        </a>}
        {(canView || canClone || canNewVersion || canEdit || canDelete) && <UncontrolledDropdown>
          <DropdownToggle className='px-1' tag='span'>
            <MoreVertical size={15} />
          </DropdownToggle>
          <DropdownMenu right>
            {canView && <DropdownItem tag='a' className='w-100' disabled={isDisableAction} onClick={handleViewCheckpoint}>
              <span className='align-middle ml-50'>{getLocaleMessage(intl, TABLE.VIEW)}</span>
            </DropdownItem>}
            {canEdit && editFlag && <DropdownItem tag='a' className='w-100' disabled={isDisableAction} onClick={handleEditCheckpoint}>
              <span className='align-middle ml-50'>{getLocaleMessage(intl, TABLE.EDIT)}</span>
            </DropdownItem>}
            {canClone && <DropdownItem tag='a' className='w-100' onClick={handleToggleCloneModal}>
              <span className='align-middle ml-50'>{getLocaleMessage(intl, CLONE)}</span>
            </DropdownItem>}
            {canNewVersion && <DropdownItem tag='a' className='w-100' onClick={(e) => {
              e.preventDefault()
            }}>
              <span className='align-middle ml-50' onClick={handleToggleAddNewVersion}>{getLocaleMessage(intl, NEW_VERSION)}</span>
            </DropdownItem>}
            {canDelete && <DropdownItem tag='a' href='/' className='w-100' onClick={handleToggleDeleteCheckpoint}>
              <span className='align-middle ml-50 text-danger'>{getLocaleMessage(intl, TABLE.REMOVE)}</span>
            </DropdownItem>}
          </DropdownMenu>
        </UncontrolledDropdown>}
      </div>
      <ConfirmationModal
        open={isOpenCloneModal}
        name={data.checkPointName || 'activity'}
        title={getLocaleMessage(intl, CLONE_TITLE)}
        onConfirm={handleConfirmClone}
        onToggleModal={handleToggleCloneModal}
      />
      <ConfirmationModal
        open={isOpenNewVersionModal}
        name={data.checkPointName || 'activity'}
        title={getLocaleMessage(intl, NEW_VERSION_TITLE)}
        onConfirm={handleConfirmAddNewVersion}
        onToggleModal={handleToggleAddNewVersion}
      />
      <DeleteConfirmationModal
        id={data.checkpointId}
        open={isOpenDeleteModal}
        primaryActionLabel={REMOVE_ACTIVITY}
        onToggleModal={handleToggleDeleteCheckpoint}
        onConfirmDeleteRecord={handleDeleteCheckpoint}
        type={ACTIVITIES.ACTIVITY}
      />
    </>
  )
}

export default localeMessageWrapper(RowActions)
