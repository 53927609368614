import config from "./APIConfig"
import { postDataApi, putDataApi } from '../index'

/**
 * @method updateOrganizationPlan : To create new or update existing organization plan
 * @param {*} data 
 * 
 * Payload for Create Org Plan: 
 * 
 * {
  "organizationPlanId": 0,
  "organizationId": 0,
  "planName": "string",
  "planNumber": "string",
  "elevation": "string",
  "swing": "string",
  "totalConditionSpace": 0,
  "firstFloorArea": 0,
  "secondFloorArea": 0,
  "thirdFloorArea": 0,
  "fourthFloorArea": 0,
  "volume": 0,
  "mfncCompartmentalizationArea": 0,
  "isMissingItems": true,
  "organizationBluePrintId": 0,
  "bluePrintImages": [
    {
      "fileName": "string",
      "imageContent": "string",
      "fileDescription": "string",
      "imageId": 0,
      "isDeleted": false,
      "jsonImageMetaData": "string"
    }
  ],
  "bluePrintName": "string",
  "organizationEnergyModelId": 0,
  "energyModelName": "string",
  "energyModelImages": [
    {
      "fileName": "string",
      "imageContent": "string",
      "fileDescription": "string",
      "imageId": 0,
      "isDeleted": false,
      "jsonImageMetaData": "string"
    }
  ]
}
 * 
 * @returns 
 */
export const updateOrganizationPlan = (data) => {
  const isAddUnitPlan = data.siteUnitId

  if (!isAddUnitPlan && data.organizationPlanId) {
    //Todo: for PUT api
    const { url } = config.updateOrganizationPlan
    return putDataApi(url(data), data, undefined, { pathConfig: { isCheckpointInspectionService: true } })
  } else {
    const { url } = config.createOrganizationPlan
    return postDataApi(url, data, undefined, { pathConfig: { isCheckpointInspectionService: true } })
  }
}

export default updateOrganizationPlan
