import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method createAssignmentNote
 * @param {object} data
 * {
  "observationTaskIds": [
    0
  ],
  "noteText": "string",
  "responseImages": [
    {
      "fileName": "string",
      "imageContent": "string",
      "imageId": 0,
      "jsonImageMetaData": "string",
      "isDeleted": true,
      "isExternal": true,
      "externalFileUrl": "string",
      "externalResources": 1
    }
  ]
}
 * 
 *
 */
export const createAssignmentNote = (payload) => {
  const { url } = config.createAssignmentNote

  return postDataApi(url, payload)
}

export default createAssignmentNote
