import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method cloneTemplateCheckpoint
 * @param {object} data 
 *
 */
export const cloneTemplateCheckpoint = (data) => {
  const { url } = config.cloneTemplateCheckpoint
  return postDataApi(url(data))
}

export default cloneTemplateCheckpoint