import { takeLatest, call, put } from "redux-saga/effects"

import * as actions from "../../actions"
import { getCountryList, getStateList, getCountyList, getRegionList, getCityByStateList, getZipCodeList, getLocationUnitList, getSubRegionList } from "@services/api"

/** getCountryList API **/
function* getCountryListRequest() {
  try {
    const response = yield call(getCountryList)
    if (response?.data) {
      yield put(actions.getCountryListSuccess(response.data))
    } else {
      yield put(actions.getCountryListFailure("Unable to load country list"))
    }
  } catch (error) {
    yield put(actions.getCountryListFailure("Unable to load country list"))
  }
}

/** getStateList API **/
function* getStateListRequest({ payload, options = {}, callback = () => { } }) {
  try {
    const { isDropdownOptionsOnly = false } = options
    const response = payload ? yield call(getStateList, payload) : { data: [] }
    if (response?.data) {
      if (isDropdownOptionsOnly) {
        callback(response.data)
      } else {
        yield put(actions.getStateListSuccess({ countryCode: payload, stateList: response.data }))
      }
    } else {
      yield put(actions.getStateListFailure("Unable to load state list"))
      if (isDropdownOptionsOnly) {
        callback([])
      }
    }
  } catch (error) {
    const { isDropdownOptionsOnly = false } = options
    yield put(actions.getStateListFailure("Unable to load state list"))
    if (isDropdownOptionsOnly) {
      callback([])
    }
  }
}

function* getCountyListRequest(action) {
  try {
    const response = yield call(getCountyList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getRegionList API **/

function* getRegionListRequest(action) {
  try {
    const response = yield call(getRegionList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getCityByStatesList API **/

function* getCityByStatesListRequest(action) {
  try {
    const response = yield call(getCityByStateList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getZipCodeList API **/

function* getZipCodeListRequest(action) {
  try {
    const response = yield call(getZipCodeList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getLocationUnitList API **/

function* getLocationUnitListRequest(action) {
  try {
    const response = yield call(getLocationUnitList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getSubRegionList API **/

function* getSubRegionListRequest(action) {
  try {
    const response = yield call(getSubRegionList, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export function* addressSagas() {
  yield takeLatest(actions.GET_COUNTRY_LIST.REQUEST, getCountryListRequest)
  yield takeLatest(actions.GET_STATE_LIST.REQUEST, getStateListRequest)
  yield takeLatest(actions.GET_REGION_LIST_REQUEST, getRegionListRequest)
  yield takeLatest(actions.GET_COUNTY_LIST_REQUEST, getCountyListRequest)
  yield takeLatest(actions.GET_CITY_BY_STATE_LIST_REQUEST, getCityByStatesListRequest)
  yield takeLatest(actions.GET_ZIPCODE_LIST_REQUEST, getZipCodeListRequest)
  yield takeLatest(actions.GET_LOCATION_UNIT_LIST_REQUEST, getLocationUnitListRequest)
  yield takeLatest(actions.GET_SUB_REGION_LIST_REQUEST, getSubRegionListRequest)
}

export default addressSagas