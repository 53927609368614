import { LANGUAGE_CONSTANTS } from '@shared/language-constants'

const { TEMPLATES: { SETUP } } = LANGUAGE_CONSTANTS

export const TAB_TYPES = {
  OVERVIEW: 1,
  ADD_ACTIVITIES: 2,
  REVIEW: 3
}

export const TabList = [
  { id: TAB_TYPES.OVERVIEW, labelLocaleId: SETUP.OVERVIEW, hasNext: true },
  { id: TAB_TYPES.ADD_ACTIVITIES, labelLocaleId: SETUP.ADD_ACTIVITIES, hasNext: true },
  { id: TAB_TYPES.REVIEW, labelLocaleId: SETUP.REVIEW }
]

export const FOOTER_ACTION_TYPE = {
  NEXT: 'next',
  BACK: 'back',
  CANCEL: 'cancel'
}

export const TEMPLATE_CATEGORY_TYPES = {
  OBSERVATION: "1",
  TRAINING: "2",
  MIXED: "3"
}

export const TEMPLATE_ASSEMBLY_SELECTION_TYPES = [TEMPLATE_CATEGORY_TYPES.OBSERVATION, TEMPLATE_CATEGORY_TYPES.MIXED]