import cx from "classnames"
import React, { useEffect, useState } from "react"
import {
    Button,
    Col,
    FormGroup,
    Row,
    Input
} from "reactstrap"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import {
    CustomLabel,
    ErrorMessage,
    FileUploader
} from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"
import EnergyModelSearch from "./EnergyModelSearch"

const { REMOVE, ORG_PLANS } = LANGUAGE_CONSTANTS

const EnergyModelSection = ({
    intl,
    errors,
    fileUploaderColWrapperProps,
    isEnableAddNewPlanMode,
    isEnableAddNewBlueprintMode,
    isEnableAddNewEnergyModelMode, //Note: When going to add new plan by clicking "Add New Energy Model" button
    isEditMode,
    isOrgPlan,
    planDetail,
    energyModelFiles = [],
    onToggleAddNewEnergyModelMode = () => { },
    onChangeDetail = () => { },
    onSaveNewBlueprintEnergyModel = () => { }
}) => {
    const [defaultOptions, setDefaultOptions] = useState(isEditMode && planDetail.energyModelId ? [
        {
            text: planDetail.energyModelName,
            value: planDetail.energyModelId?.toString()
        }
    ] : [])
    const [isRequiresDefaultOptionsUpdates, setRequiresDefaultOptionsUpdates] = useState(false)

    useEffect(() => {
        if (!isEnableAddNewEnergyModelMode) {
            setRequiresDefaultOptionsUpdates(true)
        }
    }, [isEnableAddNewEnergyModelMode])

    useEffect(() => {
        if (isRequiresDefaultOptionsUpdates && !isOrgPlan && planDetail.energyModelId && planDetail.energyModelName) {
            setRequiresDefaultOptionsUpdates(false)
            setDefaultOptions([
                {
                    text: planDetail.energyModelName,
                    value: planDetail.energyModelId?.toString()
                }
            ])

        }
    }, [isRequiresDefaultOptionsUpdates, planDetail.energyModelName, planDetail.energyModelId])

    return (
        <>
            <Row>
                <Col className="mb-1" {...fileUploaderColWrapperProps} >
                    <FormGroup>
                        <div className="unit-plan-subtitle">
                            <CustomLabel title={isEnableAddNewEnergyModelMode ? ORG_PLANS.ENERGY_MODEL_NAME : ORG_PLANS.SELECT_ENERGY_MODEL} required={isOrgPlan ? (isEnableAddNewEnergyModelMode && !!energyModelFiles.length && !planDetail.energyModelName) : (isEnableAddNewEnergyModelMode && !!energyModelFiles.length && !planDetail.energyModelName)} requiredIconClassName="required-field" />
                            {((isOrgPlan && isEditMode && isEnableAddNewPlanMode && !isEnableAddNewBlueprintMode) || (!isOrgPlan && !isEnableAddNewPlanMode && !isEnableAddNewBlueprintMode)) && <button
                            className={cx("waves-effect btn", {
                                "primary-text-btn": !isEnableAddNewEnergyModelMode,
                                "danger-text-btn": isEnableAddNewEnergyModelMode
                                })}
                                onClick={onToggleAddNewEnergyModelMode}>
                            {getLocaleMessage(intl, isEnableAddNewEnergyModelMode ? REMOVE : ORG_PLANS.ADD_NEW_MODEL)}
                            </button>}
                        </div>
                        {!isEnableAddNewEnergyModelMode ? <EnergyModelSearch
                            organizationPlanId={planDetail.organizationPlanId}
                            blueprintId={planDetail.blueprintId}
                            isDisabled={isEnableAddNewEnergyModelMode || !planDetail.blueprintId}
                            isEditMode={isEditMode}
                            value={planDetail.energyModelId}
                            defaultOptions={defaultOptions}
                            onSelect={onChangeDetail}
                        /> : <>
                            <FormGroup>
                                <Input
                                    placeholder={getLocaleMessage(intl, ORG_PLANS.SELECT_ENERGY_MODEL_FIRST)}
                                    value={planDetail.energyModelName}
                                    onChange={(event) => onChangeDetail("energyModelName", event.target.value)}
                                />
                            </FormGroup>
                            <ErrorMessage
                                className="error-message"
                                isShow={!!errors["energyModelName"]}
                                message={errors["energyModelName"]}
                            />
                        </>}
                    </FormGroup>
                </Col>
                <Col className="mb-1" {...fileUploaderColWrapperProps}>
                    <FileUploader
                        id="energyModelFiles"
                        label={ORG_PLANS.ENERGY_MODEL_FILES}
                        isRequired={isOrgPlan ? (isEnableAddNewEnergyModelMode && !!planDetail.energyModelName && !energyModelFiles.length) : (isEnableAddNewEnergyModelMode && !!planDetail.energyModelName && !energyModelFiles.length)}
                        defaultFileCategoryLabel="Energy Models"
                        isUploadDisabled={!isEnableAddNewEnergyModelMode}
                        canUploadFiles={!planDetail.energyModelId}
                        canDeleteFiles={!planDetail.energyModelId}
                        isDisableFileCategory
                        value={energyModelFiles}
                        errors={errors}
                        onChangeDetail={onChangeDetail} />
                </Col>
            </Row>
            {!!planDetail.organizationPlanId && !!planDetail.blueprintId && isEnableAddNewEnergyModelMode && <Row className="mb-1">
                <Col md={4}>
                    <Button
                        className={"primary-solid w-100"}
                        color="primary"
                        onClick={onSaveNewBlueprintEnergyModel}
                    >
                        {getLocaleMessage(intl, ORG_PLANS.SAVE_ENERGY_MODEL)}
                    </Button>
                </Col>
            </Row>}
        </>
    )
}

export default localeMessageWrapper(EnergyModelSection)
