import config from "./APIConfig"
import { putDataApi } from '../../index'

/**
 * @method updateCampaignWorkflow
 *
 */
export const updateCampaignWorkflow = (data) => {
  const { url } = config.updateCampaignWorkflow
  return putDataApi(url(data.wfCampaignSetupId), data)
}

export default updateCampaignWorkflow
