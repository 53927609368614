import { call, put, takeEvery } from "redux-saga/effects"

import * as services from "@services/api"
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

const { DATA_RETENTION_SAGAS } = LANGUAGE_CONSTANTS

/**  getDataRetentionVM API **/
function* getDataRetentionVMRequest(action) {
  try {
    const response = yield call(services.getDataRetentionVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/**  getCustomDataRetentionList API **/
function* getCustomDataRetentionListRequest(action) {
  try {
    const response = yield call(services.getCustomDataRetentionList)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** addCustomDataRetention API **/
function* addCustomDataRetentionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.addCustomDataRetention, action.payload)
    if (response) {
      action.callback(response.data)
      Notification({ status: 200, message: DATA_RETENTION_SAGAS.ADDED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: DATA_RETENTION_SAGAS.ERROR_IN_ADDING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** updateCustomDataRetention API **/
function* updateCustomDataRetentionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.updateCustomDataRetention, action.payload)
    if (response.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: DATA_RETENTION_SAGAS.UPDATE_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: DATA_RETENTION_SAGAS.ERROR_IN_UPDATING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** deleteCustomDataRetention API **/
function* deleteCustomDataRetentionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(services.deleteCustomDataRetention, action.payload)
    if (response?.status === 204) {
      action.callback()
      Notification({ status: 200, message: DATA_RETENTION_SAGAS.DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: DATA_RETENTION_SAGAS.ERROR_IN_DELETING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/**  getCustomDataRetentionPolicyDetails API **/
function* getCustomDataRetentionPolicyDetailsRequest(action) {
  try {
    const response = yield call(services.getCustomDataRetentionPolicyDetails, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export default function* dataRetentionPoliciesSagas() {
  yield takeEvery(actions.GET_DATA_RETENTION_VM_REQUEST, getDataRetentionVMRequest)
  yield takeEvery(actions.GET_CUSTOM_DATA_RETENTION_LIST_REQUEST, getCustomDataRetentionListRequest)
  yield takeEvery(actions.ADD_CUSTOM_DATA_RETENTION_REQUEST, addCustomDataRetentionRequest)
  yield takeEvery(actions.UPDATE_CUSTOM_DATA_RETENTION_REQUEST, updateCustomDataRetentionRequest)
  yield takeEvery(actions.DELETE_CUSTOM_DATA_RETENTION_REQUEST, deleteCustomDataRetentionRequest)
  yield takeEvery(actions.GET_CUSTOM_DATA_RETENTION_POLICY_DETAILS_REQUEST, getCustomDataRetentionPolicyDetailsRequest)
}
