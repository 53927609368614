// app settings
import AppSettings from '@app-settings'

const {
  REACT_APP_API_ORIGIN,
  REACT_APP_DEV_APP_SLINGSHOT_API_ORIGIN,
  REACT_APP_API_SUBSCRIPTION_KEY,
  REACT_APP_SCORM_CLOUD_API_ORIGIN,
  REACT_APP_OPEN_WEATHER_MAP_API_URL,
  REACT_APP_OPEN_WEATHER_MAP_API_ID
} = AppSettings

export const PROJECT_SETUP_API_URL = `${REACT_APP_API_ORIGIN}/ProjectSetup/`
export const USER_MANAGEMENT_API_URL = `${REACT_APP_API_ORIGIN}/UserManagement/`
export const CHECKPOINT_INSPECTION_API_URL = `${REACT_APP_API_ORIGIN}/CheckpointInspection/`
export const SYSTEM_INTEGRATION_SERVICE_API_URL = `${REACT_APP_API_ORIGIN}/SystemIntegration/`
export const API_SUBSCRIPTION_KEY = REACT_APP_API_SUBSCRIPTION_KEY
export const AUTH0_API_URL = `https://${AppSettings.REACT_APP_AUTH0_DOMAIN}`
export const SCORM_CLOUD_API_URL = `${REACT_APP_SCORM_CLOUD_API_ORIGIN}/`
export const DEV_APP_SLINGSHOT_API_ORIGIN_URL = `${REACT_APP_DEV_APP_SLINGSHOT_API_ORIGIN}/`
export const OPEN_WEATHER_MAP_API_URL = `${REACT_APP_OPEN_WEATHER_MAP_API_URL}/`
export const OPEN_WEATHER_MAP_API_ID = REACT_APP_OPEN_WEATHER_MAP_API_ID
