import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addServiceChecklist
 * @param {object} data
 *  {
    "campaignServiceId": 0,
    "checkListName": "string",
 }
 */

export const addServiceChecklist = (data) => {
  const { url } = config.addServiceChecklist
  const payload = {
    campaignServiceId: Number(data.campaignServiceId),
    checkListName: data.checklistName
  }

  return postDataApi(url, payload)
}

export default addServiceChecklist
