import { takeLatest, call } from "redux-saga/effects"

import { getOshaWrittenPrograms } from "@services/api"
import * as actions from "../../actions"

/** getOshaWrittenPrograms API **/
function* getOshaWrittenProgramsRequest(action) {
  try {
    const response = yield call(getOshaWrittenPrograms, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback(null)
  }
}

export function* programsSpecificSagas() {
 yield takeLatest(actions.GET_OSHA_WRITTEN_PROGRAM_LIST_REQUEST, getOshaWrittenProgramsRequest)
}

export default programsSpecificSagas
