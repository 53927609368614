import React from "react"
import { Col, Row } from "reactstrap"

const TableChartView = () => {
  return (
    <div className="skeleton-loader table-skeleton">
      <h2 className="skeleton-title skeleton-style" />
      <Row className="mb-1">
        <Col md={10}>
          <div className="skeleton-box skeleton-style" />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md={12}>
          <div className="skeleton-paragraph skeleton-style mr-1" />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md={3}>
          <div className="skeleton-bar skeleton-style mr-1" />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md={10}>
          <div className="skeleton-box skeleton-style" />
        </Col>
      </Row>
    </div>
  )
}

export default TableChartView
