import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage } from '@views/components'

export const validateServiceDetails = ({
  schedulerDriverId,
  saturationRate
}) => {
  const errors = {}
  if (schedulerDriverId === '1') {
    if (!saturationRate.toString().trim().length || isNaN(saturationRate) || (Number(saturationRate) > 100 || Number(saturationRate) < 0)) {
      errors['saturationRate'] = <LocaleMessage id={LANGUAGE_CONSTANTS.SERVICES.ERRORS.SATURATION_RATE} />
    }
  }

  return errors
}

export const getCompletionDaysOptions = () => {
  return Array.from(Array(30), (val, i) => ({ text: `${i + 1}`, value: i + 1 }))
}
