import React, { useCallback } from "react"
import { useDispatch } from "react-redux"

import * as actions from "@store/actions"
import CustomCheckboxSearch from "./custom-checkbox-search"

const SubRegionName = ({
  id = "subRegion",
  allSelectCheckboxLabel = "",
  inputPlaceholder,
  valueType = "value",
  labelType = "text",
  value = [],
  region = [],
  isResetClicked = false,
  isCalendarPageSubRegionFilter = false,
  onSelect = () => {},
  onResetClicked = () => {}
}) => {
  const dispatch = useDispatch()

  const handleFetchOptions = useCallback((params, callback = () => { }) => {
    const queryPayload = {
      ...params,
      subRegionName: params.searchText,
      region,
      isCalendarPage: isCalendarPageSubRegionFilter
    }
    dispatch(actions.getSubRegionFilterListRequest(queryPayload, callback))
  }, [region])

  const handleSelectFilter = useCallback(
    (value) => {
      onSelect(id, value)
    },
    [onSelect]
  )

  return (
    <CustomCheckboxSearch
      id={id}
      valueType={valueType}
      labelType={labelType}
      allSelectCheckboxLabel={allSelectCheckboxLabel}
      inputPlaceholder={inputPlaceholder}
      onFetchOptions={handleFetchOptions}
      onSelectFilter={handleSelectFilter}
      onResetClicked={onResetClicked}
      selectedFilters={value}
      isResetClicked={isResetClicked}
    />
  )
}

export default SubRegionName