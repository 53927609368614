import config from "./APIConfig"
import { postDataApi } from '../../index'

/**
 * @method cloneCampaignWorkflow
 *
 */
export const cloneCampaignWorkflow = (data) => {
  const { url } = config.cloneCampaignWorkflow
  return postDataApi(url(data))
}

export default cloneCampaignWorkflow
