// ** Redux Imports
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'

import AppSettings from "@app-settings"
import useJwt from '@src/auth/jwt/useJwt'
import { SIGNOUT_REQUEST } from '@store/actions'

// ** Reducers Imports
import address from '../../redux/reducers/address'
import auth from '../../redux/reducers/auth'
import checkpoints from '../../redux/reducers/checkpoints'
import contacts from '../../redux/reducers/contacts'
import layout from '../../redux/reducers/layout'
import navbar from '../../redux/reducers/navbar'
import loader from '../../redux/reducers/loader'
import projectSite from '../../redux/reducers/project-site'
import projectSitePlans from '../../redux/reducers/project-site-plan'
import projectSiteRoles from '../../redux/reducers/project-site-roles'
import projectSiteUnits from '../../redux/reducers/project-site-units'
import templateCheckpoints from '../../redux/reducers/template-checkpoints'
import campaignService from '../../redux/reducers/campaign-service'
import scheduling from '../../redux/reducers/scheduling'
import campaignServiceChecklistCheckpoints from '../../redux/reducers/campaign-service-checklist-checkpoints'
import userManagement from '../../redux/reducers/user-management'
import tableActions from '../../redux/reducers/table-actions'
import globalSearch from '../../redux/reducers/global-search'
import resource from '../../redux/reducers/resources'
import reports from '../../redux/reducers/reports'
import buildWorkflow from '../../redux/reducers/workflow/build-workflow'
import programs from '../../redux/reducers/programs'
import notifications from '../../redux/reducers/notifications'
import taskDataCollection from "../../redux/reducers/task-data-collection"
import searchHistory from "../../redux/reducers/search-history"

const config = useJwt.jwtConfig

const { REACT_APP_FORESITE_TECH_URL } = AppSettings

const appReducer = combineReducers({
  address,
  auth,
  checkpoints,
  contacts,
  globalSearch,
  layout,
  navbar,
  loader,
  projectSite,
  projectSitePlans,
  projectSiteRoles,
  projectSiteUnits,
  scheduling,
  templateCheckpoints,
  campaignService,
  campaignServiceChecklistCheckpoints,
  userManagement,
  tableActions,
  resource,
  reports,
  buildWorkflow,
  programs,
  notifications,
  taskDataCollection,
  searchHistory
})

const rootReducers = (state, action) => {
  const obj = { ...action }
  if (action.type === SIGNOUT_REQUEST) {
    try {
      delete obj.type
      const user = localStorage.getItem("userData")
      const userId = JSON.parse(user)[`${REACT_APP_FORESITE_TECH_URL}/UserId`]
      storage.removeItem('persist:root')
      localStorage.clear()
      /**
       * To retain search history as SIGNOUT_REQUEST is triggered only when token expired,
       * So if token expired when user is active, we need to retain search history.
       * Later on if logged in user is different from last logged in user, search history will be cleared.
       */
      const { searchHistory } = state
      state = { searchHistory: { ...searchHistory, userId } }
      return appReducer(state, obj)
    } catch (err) {
      return appReducer(undefined, obj)
    }    
  }
  return appReducer(state, obj)
}

export default rootReducers
