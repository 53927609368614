import { getSearchQuery } from "../config/index"

const apiConfig = {
  getSamplingWorkflowList: {
    method: "GET",
    url: ({
      pageNumber,
      pageSize,
      genericSearch = '',
      advanceSearch = {},
      orderColumn,
      orderDirections = ''
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch || {}).length) {
        const {
          workflow = '',
          samplingRate = [],
          workflowStatus = [],
          streetAddress = '',
          unit = '',
          countryCode = '',
          campaigns = [],
          city = '',
          state = '',
          postCode = '',
          programs = [],
          site = [],
          campaignTypeId = '',
          programTypeId = '',
          region = []
        } = advanceSearch
        const campaignName = campaigns.map((campaign) => getSearchQuery("CampaignIds", campaign.value)).join("")
        const sampleRateListSearch = samplingRate.map((sampleRate) => getSearchQuery("SampleRateList", sampleRate)).join("")
        const workflowStatusSearch = workflowStatus.map((status) => getSearchQuery("StatusList", status)).join("")
        const programIdsSearch = programs.map((program) => getSearchQuery("ProgramIds", program)).join("")
        const siteSearch = site.map((id) => getSearchQuery("SiteIds", id)).join("")
        const regionSearch = region.map((id) => getSearchQuery("RegionIds", id)).join("")

        searchString = [
          searchString,
          getSearchQuery("WorkFlowName", workflow, false, true),
          getSearchQuery("ProgramTypeId", programTypeId),
          getSearchQuery("CampaignTypeId", campaignTypeId),
          getSearchQuery("StreetAddress", streetAddress, false, true),
          getSearchQuery("UnitName", unit, false, true),
          getSearchQuery("CountryCode", countryCode),
          getSearchQuery("City", city, false, true),
          getSearchQuery("State", state),
          getSearchQuery("ZipCode", postCode, false, true),
          campaignName,
          sampleRateListSearch,
          programIdsSearch,
          workflowStatusSearch,
          siteSearch,
          regionSearch
        ].join('')
      }
      return `api/Sampling${searchString}`
    }
  },
  getSamplingWorkflowListVM: {
    method: "GET",
    url: () => 'api/Sampling/ListVM'
  },
  getSamplingWorkflowUnits: {
    method: "GET",
    url: ({
      workflowId,
      pageNumber,
      pageSize,
      genericSearch,
      advanceSearch,
      listOfObservationTasks = []
    }) => {
      const taskIds = listOfObservationTasks.map((task) => getSearchQuery("ListOfObservationTasks", task.observationTaskId)).join("")
      
      let searchString = [
        getSearchQuery("WorkflowId", workflowId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        taskIds
      ].join('')

      if (!!Object.keys(advanceSearch || {}).length) {
        const {
          unit,
          unitType,
          taskStatus = [],
          streetAddress,
          country,
          state,
          city,
          postCode,
          block,
          building,
          floor,
          planName,
          permit,
          phase
        } = advanceSearch
        const taskStatusSearch = taskStatus.map((sampleRate) => getSearchQuery("TaskStatus", sampleRate.value)).join("")

        searchString = [
          searchString,
          getSearchQuery("UnitName", unit, false, true),
          getSearchQuery("UnitTypeId", unitType),
          getSearchQuery("StreetAddress", streetAddress, false, true),
          getSearchQuery("Country", country),
          getSearchQuery("State", state),
          getSearchQuery("City", city, false, true),
          getSearchQuery("ZipCode", postCode, false, true),
          getSearchQuery("Block", block, false, true),
          getSearchQuery("Building", building),
          getSearchQuery("Floor", floor, false, true),
          getSearchQuery("PlanName", planName, false, true),
          getSearchQuery("PermitNumber", permit),
          getSearchQuery("Phase", phase),
          taskStatusSearch
        ].join('')
      }
      return `api/Sampling/Detail/${workflowId}/Unit${searchString}`
    }
  },
  getSamplingWorkflowUnitsVM: {
    method: "GET",
    url: (workflowId) => `api/Sampling/Detail/${workflowId}/Unit/VM`
  },
  getSamplingOverviewDetails: {
    method: "GET",
    url: (workflowId) => `api/Sampling/Detail/${workflowId}/Overview`
  },
  getSamplingWorkflowGroups: {
    method: "GET",
    url: ({
      workflowId,
      genericSearch,
      advanceSearch
    }) => {
      let searchString = [
        getSearchQuery("WorkflowId", workflowId, true),
        getSearchQuery("GenericSearch", genericSearch, false, true)
      ].join('')

      if (!!Object.keys(advanceSearch || {}).length) {
        const {
          sampleGroupName = '',
          unitName = ''
        } = advanceSearch

        searchString = [
          searchString,
          getSearchQuery("SampleGroupName", sampleGroupName),
          getSearchQuery("UnitName", unitName, false, true)
        ].join('')
      }

      return `api/Sampling/Detail/${workflowId}/Group${searchString}`
    }
  },
  getSamplingWorkflowGroupUnits: {
    method: "GET",
    url: ({
      workflowId,
      groupId,
      pageNumber,
      pageSize
    }) => {
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("WorkflowId", workflowId),
        getSearchQuery("GroupId", groupId)
      ].join('')

      return `api/Sampling/Detail/${workflowId}/Group/${groupId}/Unit${searchString}`
    }
  },
  createSamplingWorkflowGroup: {
    method: 'POST',
    url: ({ workflowId, sampleType, observationTaskIds }) => {
      const taskObservationSearch = observationTaskIds.map((observationTask) => getSearchQuery("ObservationTaskIds", observationTask.value, true)).join("")
      const searchString = [
        getSearchQuery("SampleType", sampleType, true),
        taskObservationSearch
      ].join('')
      return `api/Sampling/Detail/${workflowId}/Group${searchString}`
    }
  },
  deleteSamplingWorkflowGroupUnits: {
    method: 'DELETE',
    url: ({ workflowId, groupId }) => `api/Sampling/Detail/${workflowId}/Group/${groupId}/Unit`
  },
  deleteSamplingWorkflowGroup: {
    method: 'DELETE',
    url: ({ workflowId, groupId }) => `api/Sampling/Detail/${workflowId}/Group/${groupId}`
  },
  updateSamplingWorkflowGroupUnitType: {
    method: 'PUT',
    url: ({ workflowId, groupId, sampleType }) => `api/Sampling/Detail/${workflowId}/Group/${groupId}/Unit/${sampleType}`
  },
  addSamplingWorkflowGroupUnits: {
    method: 'POST',
    url: (workflowId) => `api/Sampling/Detail/${workflowId}/Group`
  },
  addSamplingWorkflowGroupUnitType: {
    method: 'POST',
    url: ({ workflowId, groupId, sampleType }) => `api/Sampling/Detail/${workflowId}/Group/${groupId}/Unit/${sampleType}`
  },
  getSamplingWorkflowGroupDetails: {
    method: "GET",
    url: ({
      workflowId,
      groupId
    }) => {
      const searchString = [
        getSearchQuery("WorkflowId", workflowId, true),
        getSearchQuery("GroupId", groupId)
      ].join('')

      return `api/Sampling/Detail/${workflowId}/Group/${groupId}${searchString}`
    }
  },
  updateOpenSamplingGroup: {
    method: 'PUT',
    url: ({ workflowId, groupId }) => `api/Sampling/Detail/${workflowId}/Group/${groupId}/Open`
  },
  updateCloseSamplingGroup: {
    method: 'PUT',
    url: ({ workflowId, groupId }) => `api/Sampling/Detail/${workflowId}/Group/${groupId}/Close`
  }
}

export default apiConfig