import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addCampaignDetail
 * @param {object} data 
 *{
  "entityId": 0,
  "campaignName": "string",
  "campaignStatusId": 0,
  "campaignTypeId": 0,
  "campaignObjectiveId": 0,
  "description": "string",
  "level1Id": 0,
  "level2Id": 0,
  "level3Id": 0,
  "level4Id": 0,
  "level5Id": 0,
  "siteIds": [],
  "isSharedCampaign": true
}
 *
 */
export const addCampaignDetail = (data) => {
  const { url } = config.addCampaignDetail

  const payload = {
    entityId: 1,
    ...data
  }

  return postDataApi(url, payload)
}

export default addCampaignDetail
