import config from "./APIConfig"
import { putDataApi } from "../index"

/**
 * @method updateBulkAssignSignOffAction
 * This api is called to assign sign off for scheduling workflow
 * [Assign Sign off]
 * 
 * @param {object} data 
 * 
 * {
 *   wfActionIds: string,
 *   contactId: 0,
 *   note: string
 * }
 * 
 */
export const updateBulkAssignSignOffAction = async (data) => {
  const { url } = config.updateBulkAssignSignOffAction
  return await putDataApi(url, data)
}

export default updateBulkAssignSignOffAction
