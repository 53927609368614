import { put, takeLatest, call, takeEvery } from "redux-saga/effects"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from "@store/actions"
import { Notification } from '@views/components'

import { addOrganizationContact, deleteOrganizationContact, getContactListVM, getCreateAccountVM, getOrganizationContactList, updateOrganizationContact, getOrganizationContact, uploadUserProfileImage, resetUserProfileImage, getContactTraininglogList, getContactNotificationSettings, saveContactNotificationsSettings, getNotificationSettingsVM, getUserAuth0ContactDetailsVM, sendPasswordResetEmail, updateUserAccountPassword, getUserAccountHistory, createNewUserAccount, updateContactEmailId, addOrganizationRoleContact, updateOrganizationRoleContact, getOrganizationRoleContact, deleteOrganizationRoleContact, getOrganizationExternalContactList } from "../../../services/api"

const { USER_PROFILE: { NOTIFICATION_SETTINGS }, CONTACT_SAGA } = LANGUAGE_CONSTANTS

/** getOrganizationContactList API **/
function* getContactListRequest(data) {
  const { isSaveToReducer = true, ...payload } = data.payload
  try {
    const response = yield call(getOrganizationContactList, payload)
    if (response?.data && isSaveToReducer) {
      yield put(actions.getContactListSuccess({ ...response.data, pageSize: data.payload.pageSize }))
    }
    if (response?.data) {
      data.callback({ ...response.data, pageSize: data.payload.pageSize })
    } else {
      data.callback()
    }
   
  } catch (error) {
    data.callback()
  }
}

/** getContactListVM API **/
function* getContactListVMRequest(action) {
  try {
    const response = yield call(getContactListVM)
    const { accountAccess = [] } = response?.data || {}
    action.callback(accountAccess)
  } catch (error) {
    action.callback([])
  }
}

/** addOrganizationContact API **/
function* addContactRequest(data) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addOrganizationContact, data.payload)
    if (response?.data) {
      data.callback(response.data)
      Notification({ status: 200, message: CONTACT_SAGA.ADDED_SUCCESS })
    } else {
      data.callback(null)
      Notification({ status: 400, message: CONTACT_SAGA.ERROR_IN_ADDING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    data.callback(null)
  }
}

/** uploadUserProfileImage API **/
function* uploadUserProfileImageRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(uploadUserProfileImage, action.payload)
    if (response?.data) {
      Notification({ status: 200, message: CONTACT_SAGA.IMAGE_UPLOADED_SUCCESS })
      action.callback(response.data)
    } else {
      Notification({ status: 400, message: CONTACT_SAGA.ERROR_IN_UPLOADING_IMAGE })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
  }
}

/** resetUserProfileImage API **/
function* resetUserProfileImageRequest(data) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(resetUserProfileImage, data.payload)
    if (response?.status === 204) {
      data.callback()
      Notification({ status: 200, message: CONTACT_SAGA.IMAGE_RESET_SUCCESS })
    } else {
      Notification({ status: 400, message: CONTACT_SAGA.ERROR_IN_RESETTING_IMAGE })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    Notification({ status: 400, message: CONTACT_SAGA.ERROR_IN_RESETTING_IMAGE })
  }
}

/** deleteOrganizationContact API **/
function* deleteContactRequest(data) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteOrganizationContact, data.payload)
    if (response?.status === 204) {
      Notification({ status: 200, message: CONTACT_SAGA.DELETED_SUCCESS })
    } else {
      Notification({ status: 400, message: CONTACT_SAGA.ERROR_IN_DELETING })
    }
    data.callback()
    yield put(actions.setIsLoading(false))
  } catch (error) {
    data.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** getCreateAccountVM API **/
function* getCreateAccountVMRequest(action) {
  try {
    const response = yield call(getCreateAccountVM)
    action.callback(response?.data || [])
  } catch (error) {
    action.callback(null)
  }
}

/** updateOrganizationContact API **/
function* updateOrganizationContactRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateOrganizationContact, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CONTACT_SAGA.UPDATED_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: CONTACT_SAGA.ERROR_IN_UPDATING })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback(false)
  }
}

/** getOrganizationContact API **/
function* getContactRequest(data) {
  try {
    const { ...payload } = data.payload
    const response = yield call(getOrganizationContact, payload)
    if (response?.data) {
      if (data.callback) {
        data.callback(response.data)
      }
    } else {
      if (data.callback) {
        data.callback()
      }
    }
  } catch (error) {
    if (data.callback) {
      data.callback()
    }
  }
}

function* getContactTrainingLogRequest(data) {
  try {
    const response = yield call(getContactTraininglogList, data.payload)
    data.callback(response?.data)
  } catch (error) {
    data.callback()
  }
}

function* getContactNotificationSettingRequest(data) {
  try {
    const response = yield call(getContactNotificationSettings)
    data.callback(response?.data)
  } catch (error) {
    data.callback()
  }
}

function* saveContactNotificationsSettingsRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(saveContactNotificationsSettings, action.payload)
    if (response?.status === 204) {
      Notification({ status: 200, message: NOTIFICATION_SETTINGS.CONTACT_NOTIFICATIONS_SAVE_SUCCESS })
      action.callback(true)
    } else {
      action.callback(false)
      Notification({ status: 400, message: NOTIFICATION_SETTINGS.ERROR_IN_CONTACT_NOTIFICATIONS_SAVE })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** NotificationSettingsVM API **/
function* getNotificationSettingsVMRequest(action) {
  try {
    const response = yield call(getNotificationSettingsVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** addOrganizationRoleContact API **/
function* addOrganizationRoleContactRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(addOrganizationRoleContact, action.payload)
    if (response?.status === 200) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: CONTACT_SAGA.ADDED_ORG_ROLE_CONTACT_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: CONTACT_SAGA.ERROR_IN_ADDING_ORG_ROLE_CONTACT })
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** updateOrganizationRoleContact API **/
function* updateOrganizationRoleContactRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateOrganizationRoleContact, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: CONTACT_SAGA.UPDATED_ORG_ROLE_CONTACT_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: CONTACT_SAGA.ERROR_IN_UPDATING_ORG_ROLE_CONTACT })
    }
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** deleteOrganizationRoleContact API **/
function* deleteOrganizationRoleContactRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(deleteOrganizationRoleContact, action.payload)
    if (response && response.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: CONTACT_SAGA.DELETED_ORG_ROLE_CONTACT_SUCCESS })
    } else {
      action.callback(false)
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: CONTACT_SAGA.ERROR_IN_DELETING_ORG_ROLE_CONTACT })
    }
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getUserAuth0ContactDetailsVM API **/
function* getUserAuth0ContactDetailsVMRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(getUserAuth0ContactDetailsVM, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getOrganizationRoleContact API **/
function* getOrganizationRoleContactRequest(action) {
  try {
    const response = yield call(getOrganizationRoleContact, action.payload)
    if (response?.data) {
      action.callback(response?.data)
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getOrganizationExternalContactList API **/
function* getOrganizationExternalContactListRequest(action) {
  try {
    const response = yield call(getOrganizationExternalContactList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** sendPasswordResetEmail API **/
function* sendPasswordResetEmailRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(sendPasswordResetEmail, action.payload)
    if (response?.status === 204) {
      action.callback(true)
    } else {
      action.callback(false)
      Notification({ status: 400, message: CONTACT_SAGA.PASSWORD_RESET_ERROR })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** updateContactEmailId API **/
function* updateContactEmailIdRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateContactEmailId, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      Notification({ status: 200, message: CONTACT_SAGA.EMAIL_UPDATE_SUCCESS })
    } else {
      action.callback(false)
      Notification({ status: 400, message: CONTACT_SAGA.EMAIL_UPDATE_ERROR })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback()
    yield put(actions.setIsLoading(false))
  }
}

/** updateUserAccountPassword API **/
function* updateUserAccountPasswordRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(updateUserAccountPassword, action.payload)
    if (response?.status === 204) {
      Notification({ status: 200, message: CONTACT_SAGA.MANUAL_PASSWORD_RESET_SUCCESS })
      action.callback(true)
    } else {
      action.callback(false)
      Notification({ status: 400, message: CONTACT_SAGA.MANUAL_PASSWORD_RESET_ERROR })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

/** getUserAccountHistory API **/
function* getUserAccountHistoryRequest(action) {
  try {
    const response = yield call(getUserAccountHistory, action.payload)
    action.callback(response?.data || [])
  } catch (error) {
    action.callback(false)
  }
}

/** createNewUserAccount API **/
function* createNewUserAccountRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(createNewUserAccount, action.payload)
    if (response?.status === 200) {
      Notification({ status: 200, message: CONTACT_SAGA.USER_ACCOUNT_CREATE_SUCCESS })
      action.callback(true)
    } else {
      action.callback(false)
      Notification({ status: 400, message: CONTACT_SAGA.USER_ACCOUNT_CREATE_ERROR })
    }
    yield put(actions.setIsLoading(false))
  } catch (error) {
    action.callback(false)
    yield put(actions.setIsLoading(false))
  }
}

export function* contactsSagas() {
  yield takeEvery(actions.GET_CONTACT_LIST.REQUEST, getContactListRequest)
  yield takeLatest(actions.GET_CONTACT_LIST_VM_REQUEST, getContactListVMRequest)
  yield takeLatest(actions.ADD_CONTACT_REQUEST, addContactRequest)
  yield takeLatest(actions.UPLOAD_USER_PROFILE_IMAGE_REQUEST, uploadUserProfileImageRequest)
  yield takeLatest(actions.RESET_USER_PROFILE_IMAGE_REQUEST, resetUserProfileImageRequest)
  yield takeLatest(actions.DELETE_CONTACT_REQUEST, deleteContactRequest)
  yield takeLatest(actions.GET_CREATE_ACCOUNT_VM_REQUEST, getCreateAccountVMRequest)
  yield takeLatest(actions.UPDATE_CONTACT_REQUEST, updateOrganizationContactRequest)
  yield takeLatest(actions.GET_CONTACT_REQUEST, getContactRequest)
  yield takeLatest(actions.GET_CONTACT_TRAINING_LOG_LIST, getContactTrainingLogRequest)
  yield takeLatest(actions.GET_CONTACT_NOTIFICATION_SETTINGS, getContactNotificationSettingRequest)
  yield takeLatest(actions.SAVE_CONTACT_NOTIFICATION_SETTINGS, saveContactNotificationsSettingsRequest)
  yield takeLatest(actions.GET_NOTIFICATION_SETTINGS_VM_REQUEST, getNotificationSettingsVMRequest)
  yield takeLatest(actions.ADD_ORGANIZATION_ROLE_CONTACT_REQUEST, addOrganizationRoleContactRequest)
  yield takeLatest(actions.UPDATE_ORGANIZATION_ROLE_CONTACT_REQUEST, updateOrganizationRoleContactRequest)
  yield takeLatest(actions.GET_ORGANIZATION_ROLE_CONTACT_REQUEST, getOrganizationRoleContactRequest)
  yield takeLatest(actions.DELETE_ORGANIZATION_ROLE_CONTACT_REQUEST, deleteOrganizationRoleContactRequest)
  yield takeLatest(actions.GET_ORGANIZATION_EXTERNAL_CONTACT_LIST_REQUEST, getOrganizationExternalContactListRequest)
  yield takeLatest(actions.GET_USER_AUTH0_CONTACT_DETAILS_VM_REQUEST, getUserAuth0ContactDetailsVMRequest)
  yield takeLatest(actions.SEND_PASSWORD_RESET_EMAIL_REQUEST, sendPasswordResetEmailRequest)
  yield takeLatest(actions.UPDATE_USER_ACCOUNT_PASSWORD_REQUEST, updateUserAccountPasswordRequest)
  yield takeLatest(actions.GET_USER_ACCOUNT_HISTORY_REQUEST, getUserAccountHistoryRequest)
  yield takeLatest(actions.CREATE_NEW_USER_ACCOUNT_REQUEST, createNewUserAccountRequest)
  yield takeLatest(actions.UPDATE_CONTACT_EMAIL_ID_REQUEST, updateContactEmailIdRequest)
}

export default contactsSagas
