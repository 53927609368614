import config from "./APIConfig"
import { putDataApi } from '../index'

export const updateCloseSamplingGroup = (data) => {
  const { url } = config.updateCloseSamplingGroup

  return putDataApi(url({ workflowId: data.workflowId, groupId: data.groupId }))
}

export default updateCloseSamplingGroup
