import { GET_COUNTRY_LIST, GET_STATE_LIST } from "../../actions"


const DEFAULT_COUNTRY_CODE = 'US'

// ** Initial State
const initialState = {
  defaultCountryCode: DEFAULT_COUNTRY_CODE,
  countries: [],
  states: {}
}

const addressReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_COUNTRY_LIST.REQUEST:
      return {
        ...state,
        error: ""
      }

    case GET_COUNTRY_LIST.SUCCESS:
      return {
        ...state,
        countries: action.data.countries
      }

    case GET_COUNTRY_LIST.FAILURE:
      return { ...state, error: action.error }

    case GET_STATE_LIST.REQUEST:
      return {
        ...state,
        error: ""
      }

    case GET_STATE_LIST.SUCCESS:
      const { stateList, countryCode } = action.data
      return countryCode ? {
        ...state,
        states: {
          ...state.states,
          [countryCode]: stateList
        }
      } : state

    case GET_STATE_LIST.FAILURE:
      return { ...state, error: action.error }

    default:
      return state
  }
}

export default addressReducer
