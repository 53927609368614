import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method updateUserManagementRolePermissionList
 * @param {object} data
 *
 */
export const updateUserManagementRolePermissionList = (data) => {
  const { url } = config.updateUserManagementRolePermissionList
  return postDataApi(url({ roleId: data.roleId, entityId: data.entityId }), { menues: data.menues, permissions: data.permissions })
}

export default updateUserManagementRolePermissionList
