import { CONFIRM_SITE_VISIT_TYPE, CONFIRMATION_METHOD_ID_TYPE } from "@views/campaigns/add-new-campaign/workflow/block/confirm-site-visit/config"
import config from "./APIConfig"
import { putDataApi } from "../../index"

/**
 * @method updateBlockConfirmSiteVisit
 * @param {object} data 
 * 
 * {
 *  wfCampaignSetupId: "",
 *  assignmentValue: ""
 *  confirmSiteVisit: "",
 *  confirmationMethodId: "",
 *  notificationMethodId: ""
 * }
 * 
 * API payload:-
 * 
 * {
 *  "wfCampaignSetupId": 0,
 *  "confirmSiteVisit": true,
 *  "wfNotificationMethodIds": [],
 *   "wfbConfirmSiteVisitAssignmentTypeId": 1
 *  "siteRoleId": 0,
 *  "sitePartyRoleId": 0,
 *  "contactId": 0
 * }
 */
export const updateBlockConfirmSiteVisit = async (data) => {
  const { url } = config.updateBlockConfirmSiteVisit
  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    confirmSiteVisit: data.confirmSiteVisit === CONFIRM_SITE_VISIT_TYPE.YES,
    wfNotificationMethodIds: data.notificationMethodId.map(x => Number(x.value)),
    wfbConfirmSiteVisitAssignmentTypeId: Number(data.confirmationMethodId),
    siteRoleId: 0 // TODO to be removed later since no longer siteRole id will be in use
  }
  if (data.confirmationMethodId === CONFIRMATION_METHOD_ID_TYPE.ROLE) {
    payload.sitePartyRoleId = Number(data.assignmentValue)
  }
  if (data.confirmationMethodId === CONFIRMATION_METHOD_ID_TYPE.PERSON) {
    payload.contactId = Number(data.assignmentValue)
  }
  return await putDataApi(url(data.wfCampaignSetupId), payload, null, { isApiNeedsToHandleErrors: false })
}

export default updateBlockConfirmSiteVisit
