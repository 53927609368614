import React, { useState, useCallback } from 'react'
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { LocaleMessage, CustomReactSelect, CustomLabel } from '@views/components'

import CustomSelectOption from './custom-select-option'

const CloneModal = ({
  type,
  open,
  onToggleModal,
  onClone,
  value,
  lableType,
  valueType,
  getOptionValue,
  getOptionLabel,
  actionCalled,
  onSelect,
  getParams,
  modalHeader,
  modalText,
  selectLabel,
  name,
  placeholder
}) => {

  const [options, setOptions] = useState([])
  const dispatch = useDispatch()

  const handleLoadMoreOptions = useCallback((search, page, prevOptions, others) => {
    return new Promise((resolve) => {
      if (!search.trim().length) {
        resolve({
          optionList: [],
          hasMore: false
        })
        return
      }

      const params = getParams(search.trim(), page)
      dispatch(actionCalled(params, (response) => {
        if (response) {
          const { items, hasNextPage } = response
          setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
          resolve({
            optionList: items,
            hasMore: hasNextPage
          })
        } else {
          resolve({
            optionList: [],
            hasMore: false
          })
        }
      }))
    })
  }, [options])

  return (
    <Modal
      isOpen={open}
      className='modal-dialog-centered clone-modal all-modal'
    >
      <ModalBody className="p-0">
        <h3>{modalHeader}</h3>
        <p>{modalText}</p>
        <Row form>
          <Col className="mb-1" sm={12} md={12} xl={12}>
            <CustomLabel title={selectLabel} isLocalizedTitle={false} />
            <CustomReactSelect
              additional={{
                page: 0
              }}
              value={value}
              id={name}
              name={name}
              placeholder={placeholder}
              options={options}
              defaultOptions={options}
              lableType={lableType}
              valueType={valueType}
              onSelect={(selected) => {
                onSelect(selected)
              }}
              isShowCustomAction={false}
              onLoadOptions={handleLoadMoreOptions}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              formatOptionLabel={
                (option) => {
                  return <CustomSelectOption type={type} data={option} lableType={lableType} valueType={valueType} />
                }
              }
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="justify-content-end border-0 p-0">
        <Button
          className="bg-btn"
          onClick={() => onClone()}
          disabled={!value}
        >
          <LocaleMessage id={LANGUAGE_CONSTANTS.CLONE} />
        </Button>
        <Button
          outline
          onClick={onToggleModal}
          className={classnames("delete-button mr-1")}
        >
          <LocaleMessage id={LANGUAGE_CONSTANTS.CLOSE} />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

CloneModal.propTypes = {
  type: PropTypes.string,
  open: PropTypes.bool,
  onToggleModal: PropTypes.func,
  onClone: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  lableType: PropTypes.string,
  valueType: PropTypes.string,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  actionCalled: PropTypes.func,
  onSelect: PropTypes.func,
  getParams: PropTypes.func,
  modalHeader: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  modalText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  selectLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
}

export default CloneModal
