import { SET_REPORTS_OPENED_TAB } from '../../actions'
import { DETAILS_SECTION_TAB_TYPE } from '@shared/constants'

// ** Initial State
const initialState = {
  openedTab: DETAILS_SECTION_TAB_TYPE.DETAILS
}

const ReportsReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_REPORTS_OPENED_TAB:
      return {
        ...state,
        openedTab: action.payload
      }

    default:
      return state
  }
}

export default ReportsReducer
