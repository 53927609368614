import { DUE_DATE_REQUIRED_TYPE } from "@views/campaigns/add-new-campaign/workflow/block/training-template/due-date/config"
import config from "./APIConfig"
import { putDataApi } from "../../index"

/**
 * @method updateBlockDueDate
 * @param {object} data 
 * 
 * {
 *  "wfCampaignSetupId": 0,
 *  "dueDateRequired": "",
 *  "dueDate": ""
 * }
 * 
 * API payload:-
 * 
 * {
 *  "wfCampaignSetupId": 0,
 *  "dueDateRequired": boolean,
 *  "dueDate": ""
 * }
 */
export const updateBlockDueDate = async (data) => {
  const { url } = config.updateBlockDueDate
  const payload = {
    wfCampaignSetupId: Number(data.wfCampaignSetupId),
    dueDateRequired: data.dueDateRequired === DUE_DATE_REQUIRED_TYPE.YES,
    dueDate: data.dueDateRequired === DUE_DATE_REQUIRED_TYPE.YES ? data.dueDate : null
  }
  return await putDataApi(url(data.wfCampaignSetupId), payload, null, { isApiNeedsToHandleErrors: false })
}

export default updateBlockDueDate
