import React from "react"
import { Shield, ShieldOff } from 'react-feather'

const StandardCell = ({ data }) => {
    return (
        <div className="d-flex">
            {data.standard ? <Shield className="shield-icon" size={16} /> : <ShieldOff className="shield-icon" size={16} />}
            {data.checkPointName}
        </div>
    )
}

export default StandardCell