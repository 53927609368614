import config from "./APIConfig"
import { getDataApi } from "../index"

export const getContactNotificationSettings = () => {
  const { url } = config.getContactNotificationSettings
  return getDataApi(url)
}

export const getNotificationSettingsVM = () => {
  const { url } = config.getNotificationSettingsVM
  return getDataApi(url)
}
