import config from "./APIConfig"
import { postDataApi } from "../index"

/**
 * @method createProgramEscalation
 * @param {object} data 
 * 
 * API payload:-
 * {
 *  "programId": 0,
 *  "escalations": [
 *      {
 *          "escalationName": "string",
 *          "wfEscalationTypeId": 0,
 *          "wfEscalationCriteriaId": 0,
 *          "wfCriteriaTypeId": 0,
 *          "escalateAfterUnapprovedUnitsPercentage": "string",
 *          "escalateAfterUnapprovedActivitiesNumber": 0,
 *          "escalateAfterDaysNumber": 0,
 *          "escalateAfterUnapprovedUnitsNumber": 0,
 *           "riskLevelIds": [
 *             {
 *              "riskLevelId": 0
 *             }
 *           ],
 *          "contactIds": [
 *              0
 *          ],
 *          "wfContactMethodIds":[
 *               0
 *          ]
 *      }
 *      ]
 * }
 */

const createProgramEscalation = (data) => {
  const { url } = config.createProgramEscalation
  data.escalations.forEach((item) => {
    delete item.contactIdsValue
    delete item.wfContactMethodIdsValue
  })
  const escalationData = data.escalations.map((item) => ({
    ...item,
    riskLevelIds: item.riskLevelIds.map(riskLevel => ({ riskLevelId: Number(riskLevel.value) })) || []
  }))
  const payload = {
    programId: Number(data.programId),
    escalations: escalationData.map(escalation => {
      Object.keys(escalation).forEach(key => {
        if (!escalation[key]) {
          delete escalation[key]
        }
      })
      return escalation
    })
  }
  return postDataApi(url, payload)
}

export default createProgramEscalation