import config from "./APIConfig"
import { getDataApi } from '../../index'

export const getCampaignWorkflow = async (data) => {
  const { url } = config.getCampaignWorkflow
  return await getDataApi(url(data.wfCampaignSetupId))
}

export const getCampaignWorkflowList = async (data) => {
  const { url } = config.getCampaignWorkflowList
  return await getDataApi(url(data))
}

export const getCanAddWorkflow = async (data) => {
  const { url } = config.getCanAddWorkflow
  return await getDataApi(url(data))
}

export const getWorkflowOrganizationRoleList = async (data) => {
  const { url } = config.getWorkflowOrganizationRoleList
  return await getDataApi(url(data))
}

export const getWorkflowTemplateStepStatus = async (data) => {
  const { url } = config.getWorkflowTemplateStepStatus
  return await getDataApi(url(data))
}

export const getWorkflowUnitList = async (data) => {
  const { url } = config.getWorkflowUnitList
  return await getDataApi(url(data))
}

export const getWorkflowUnitListCount = async (data) => {
  const { url } = config.getWorkflowUnitListCount
  return await getDataApi(url(data))
}
