import React, { useState, useCallback, useEffect } from 'react'
import { Input, Button, Col, Row } from 'reactstrap'
import { useDispatch } from "react-redux"
// import { Thread, CordProvider } from "@cord-sdk/react"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import * as actions from "@store/actions"
import { getLocaleMessage } from "@utils"
import { FormLoader, CustomLabel } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const ConversationThread = ({ intl, threadId, groupId, siteId, organizationId, siteUnitId, cordToken }) => {
  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(true)
  const [noteDetail, setNoteDetail] = useState("")
  const [originalNote, setOriginalNote] = useState("")
  const [isDisablePinnedNote, setDisablePinnedNote] = useState(false)
  const [isEditMode, setEditMode] = useState(false)

  const handleChangeNoteDetail = useCallback((event) => {
    setNoteDetail(event.target.value)
  }, [])

  useEffect(() => {
    if (siteId || organizationId || siteUnitId) {
      const payload = {
        siteId,
        organizationId,
        unitId: siteUnitId
      }
      dispatch(actions.getNoteHeadlineRequest(payload, res => {
        if (res) {
          setNoteDetail(res)
          setOriginalNote(res)
          setDisablePinnedNote(true)
        }
      }))
    }
  }, [siteId, organizationId, siteUnitId])

  const handleSubmit = useCallback(() => {
    const payload = {
      siteId,
      organizationId,
      unitId: siteUnitId,
      noteDetail
    }
    dispatch(actions.updateNoteHeadlineRequest(payload, (res) => {
      if (res) {
        setOriginalNote(noteDetail)
        setDisablePinnedNote(true)
        setEditMode(false)
      }
    }))
  }, [noteDetail, siteId, organizationId, siteUnitId])

  const handleEditNote = useCallback(() => {
    setDisablePinnedNote(false)
    setEditMode(true)
  }, [])

  const handleCancelEdit = useCallback(() => {
    setDisablePinnedNote(true)
    setEditMode(false)
    setNoteDetail(originalNote)
  }, [originalNote])

  return (
    // <CordProvider clientAuthToken={cordToken} enableSlack={false}>
      <Row className="mt-1 mb-1 align-items-center">
      <Col md={12}><CustomLabel title={LANGUAGE_CONSTANTS.PINNED_NOTE} /></Col>
      <Col md={12}><CustomLabel title={LANGUAGE_CONSTANTS.PINNED_NOTE_DESCRIPTION} /></Col>
        <Col md="5">
          <Input
            type="textarea"
            placeholder={getLocaleMessage(
              intl,
              LANGUAGE_CONSTANTS.ADD_NOTE
            )}
            disabled={isDisablePinnedNote}
            value={noteDetail}
            onChange={handleChangeNoteDetail}
          />
        </Col>
        <Col>
        {
          !isDisablePinnedNote && (
            <Button
            className="common-button"
            disabled={!noteDetail}
            onClick={handleSubmit}
          >
            {getLocaleMessage(intl, LANGUAGE_CONSTANTS.SAVE)}
          </Button>
          )
        }
        {
          !!noteDetail && isDisablePinnedNote && (
            <Button
            className="common-button"
            onClick={handleEditNote}
            >
              {getLocaleMessage(intl, LANGUAGE_CONSTANTS.TABLE.EDIT)}
            </Button>
          )
        }
        {
            isEditMode && <Button
            className="common-button ml-2"
            onClick={handleCancelEdit}
          >
            {getLocaleMessage(intl, LANGUAGE_CONSTANTS.CANCEL)}
          </Button>
        }
        </Col>
      </Row>
      // <Thread
      //   threadId={threadId}
      //   groupId={groupId}
      //   onRender={() => setLoading(false)}
      // />
      // {isLoading && <FormLoader />}
      // </CordProvider>
  )
}

export default localeMessageWrapper(ConversationThread)