import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method addOrganizationAddress
 * @param {object} data 
 *  {
 *    "organizationId": 0,
 *    "addresses": [
 *       {
 *        "isPrimary": true,
 *        "streetAddress": "string",
 *        "addressUnit": "string",
 *        "city": "string",
 *        "state": "string",
 *        "postCode": "string",
 *        "countryCode": "string",
 *        "primaryPhone": "string",
 *        "addressName": "string"
 *       }, etc...
 *     ]
 *   }
 *
 * Note:
 *  "addresses" can contain multiple addresses.
 *  If some address is primary address then set "isPrimary" = true else false,
 *  There should be only one primary address in list
 * 
 */
export const addOrganizationAddress = (data) => {
  const { url } = config.addOrganizationAddress
  const payload = {
    organizationId: data.organizationId || 0,
    addresses: data.addresses || []
  }
  return postDataApi(url(data.organizationId), payload)
}

export default addOrganizationAddress
