import config from "./APIConfig"
import { postDataApi } from '../index'

/**
 * @method cloneCheckpoint
 * @param {object} data 
 *
 */
export const cloneCheckpoint = (data) => {
  const { url } = config.cloneCheckpoint
  return postDataApi(url(data))
}

export default cloneCheckpoint