import PropTypes from 'prop-types'

function CustomCheckbox({
  isNewListTheme,
  isChecked,
  label: title,
  isDisabled = false,
  onChange = () => { }
}) {
  return (
    <>
      {isNewListTheme ? <div className=' custom-control custom-checkbox'>
        <input disabled={isDisabled} type='checkbox' checked={isChecked} className='custom-control-input' />
        <label className='custom-control-label' onClick={onChange}>{title}</label>
      </div>
        : <>
          <input disabled={isDisabled} type='checkbox' checked={isChecked} onClick={onChange} />
          <label>{title}</label>
        </>}
    </>
  )
}

CustomCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func
}

CustomCheckbox.defaultProps = {
  isChecked: false
}

export default CustomCheckbox
