import React, { useCallback, useState, useEffect } from "react"
import { ModalBody, Modal } from "reactstrap"
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'

import * as actions from '@store/actions'
import AddRoleCard from "./add-role-card"
import { validateRoleDetail } from '../../helpers'

export const MODAL_TYPE = {
  ADD: 'ADD',
  EDIT: 'EDIT'
}

const initialRoleDetail = {
  siteRole: '',
  org: ''
}

const EditRoleModal = ({
  organizationId = "",
  organizationName = "",
  modalType = MODAL_TYPE.EDIT,
  orgDetail,
  projectSiteRolesStore: { siteRoleDetail },
  selectedSiteId,
  addProjectSiteRole,
  onCloseModal = () => { },
  onFetchProjectSiteRoleList = () => { },
  onSelect = () => { },
  onSetEditSiteRoleMode = () => { },
  updateProjectSiteRole
}) => {
  const [roleDetail, setRoleDetail] = useState({
    siteRole: siteRoleDetail?.sitePartyRoleId || '',
    org: '',
    isPrimary: siteRoleDetail?.isPrimary || false
  })
  const [roleData, setRoleData] = useState(siteRoleDetail)
  const [siteRoleName, setSiteRoleName] = useState("")
  const [roleContacts, setRoleContacts] = useState([])
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (organizationId) {
      setRoleDetail(prevState => ({ ...prevState, org: organizationId }))
    }
  }, [])

  const handleToggleModal = useCallback(() => {
    setRoleDetail(initialRoleDetail)
    setSiteRoleName("")
    setRoleContacts([])
    onSetEditSiteRoleMode({ siteRoleDetail: null, isEditMode: false })
    onCloseModal()
  }, [])

  const handleChangeRoleDetail = useCallback(
    (key, value, label) => {
      if (!!Object.keys(errors).length) {
        setErrors({})
      }

      if (
        modalType === MODAL_TYPE.EDIT &&
        key === "org" &&
        roleData?.initialOrgItem?.organizationId &&
        roleData.initialOrgItem.organizationId !== value
      ) {
        setRoleData(null)
      }

      if (key === "siteRole") {
        setSiteRoleName(label || "")
      }

      if (key === 'contacts') {
        setRoleContacts(value)
      } else {
        const updatedRole = Object.assign({}, roleDetail)
        updatedRole[key] = value
        setRoleDetail(updatedRole)
      }
    },
    [roleData, roleDetail, errors, modalType]
  )

  const handleAddRoleDetail = useCallback(() => {
    const errors = validateRoleDetail({ ...roleDetail, contacts: roleContacts })
    if (!!Object.keys(errors).length) {
      setErrors(errors)
      return true
    }

    if (modalType === MODAL_TYPE.EDIT) {
      updateProjectSiteRole({
        siteId: selectedSiteId,
        siteRoleId: siteRoleDetail.siteRoleId,
        contacts: roleContacts,
        ...roleDetail
      }, (response) => {
        if (response) {
          onFetchProjectSiteRoleList({ siteId: selectedSiteId })
          handleToggleModal()
        }
      })
    } else if (modalType === MODAL_TYPE.ADD) {
      addProjectSiteRole({
        siteId: selectedSiteId || '',
        contacts: roleContacts,
        ...roleDetail
      }, (response) => {
        if (response) {
          handleToggleModal()
          onFetchProjectSiteRoleList({ siteId: siteRoleDetail?.siteId || selectedSiteId || '' })

          onSelect({
            value: Number(response) || roleDetail.siteRole, //Type of response is object, but the response should be in number
            label: siteRoleName
          })
        }
      })
    }
  }, [
    modalType,
    roleContacts,
    roleDetail,
    selectedSiteId,
    siteRoleDetail,
    siteRoleName,
    onFetchProjectSiteRoleList,
    onSelect,
    handleToggleModal
  ])

  const handleImportRole = useCallback(() => {
  }, [])

  return (
    <Modal
      isOpen
      className="modal-dialog-centered all-modal add-organization-modal"
    >
      <ModalBody className="p-0">
        <AddRoleCard
          organizationId={organizationId}
          organizationName={organizationName}
          errors={errors}
          isEditMode={modalType === MODAL_TYPE.EDIT}
          isModal
          projectOrgId={orgDetail.id}
          roleData={roleData}
          roleDetail={{ ...roleDetail, contacts: roleContacts }}
          onAddRoleDetail={handleAddRoleDetail}
          onChangeRoleDetail={handleChangeRoleDetail}
          onCloseModal={handleToggleModal}
          onImportRole={handleImportRole}
        />
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    projectSiteRolesStore: state.projectSiteRoles
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addProjectSiteRole: actions.addProjectSiteRoleRequest,
      onSetEditSiteRoleMode: actions.setEditSiteRoleMode,
      updateProjectSiteRole: actions.updateProjectSiteRoleRequest
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditRoleModal)
