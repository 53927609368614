import React, { useCallback, useState, useEffect } from "react"
import { useDispatch } from 'react-redux'

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import * as actions from '@store/actions'
import { getLocaleMessage } from "@utils"
import { ToggleButton } from '@views/components'
import localeMessageWrapper from '@views/components/locale-message'

const { ACTIVITIES } = LANGUAGE_CONSTANTS
const RequiredToggleButton = ({ data, intl, templateDetail }) => {
  const dispatch = useDispatch()

  const [isToggle, setToggleButton] = useState(false)

  useEffect(() => {
    setToggleButton(data.isRequired)
  }, [data])

  const handleToggleButton = useCallback(() => {
    const payload = {
      templateId: templateDetail.templateId,
      templateVersionId: templateDetail.templateVersionId,
      templateActivites: [
        {
          templateCheckpointId: data.templateCheckpointId,
          isRequired: !isToggle
        }
      ]
    }
    dispatch(actions.updateRequiredActivityRequest(payload, (res) => {
      if (res) {
        setToggleButton((prev) => !prev)
      }
    }))
  }, [data, templateDetail, isToggle])


  return <ToggleButton
    label={getLocaleMessage(intl, !!isToggle ? ACTIVITIES.REQUIRED : ACTIVITIES.OPTIONAL_NOT_REQUIRED)}
    id={`temlate_activities-${data.templateCheckpointId}`}
    name={`temlate_activities-${data.templateCheckpointId}`}
    value={isToggle}
    onChange={handleToggleButton}
  />
}

export default localeMessageWrapper(RequiredToggleButton)