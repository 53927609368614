import cx from "classnames"
import React, { useCallback, useState } from "react"

import {
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem
} from "reactstrap"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import localeMessageWrapper from "@views/components/locale-message"

const { SCHEDULING } = LANGUAGE_CONSTANTS

const BulkActionsDropdown = ({
  intl,
  options = [],
  noContentMessageLocaleId = "",
  isListHeader = false,
  onClickAction = () => { }
}) => {

  const [isOpenDropdown, setOpenDropdown] = useState(false)

  const handleToggleActions = useCallback(() => {
    setOpenDropdown(prevState => !prevState)
  }, [])

  const handleClickAction = useCallback((action) => {
    if (action.id) {
      onClickAction(action.id)
    }

  }, [onClickAction])

  return (
    <div className={cx("d-flex justify-content-end action-dropdown bulk-action-list select-action-dropdown", {
      "table-list-header": isListHeader
    })}>
      <Dropdown className="" isOpen={isOpenDropdown} toggle={handleToggleActions}>
        <DropdownToggle outline color="" caret>
          {getLocaleMessage(intl, SCHEDULING.SELECT_ACTION)} <span className="split-border"></span>
        </DropdownToggle>

        <DropdownMenu right>
          {options.map((action) => (
            <DropdownItem
              key={`dropdown_action_${action.id}`}
              tag="a"
              onClick={() => handleClickAction(action)}
            >
              {getLocaleMessage(intl, action.labelLocaleId)}
            </DropdownItem>
          ))
          }
          {!!noContentMessageLocaleId && <a className="dropdown-item">{getLocaleMessage(intl, noContentMessageLocaleId)}</a>}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default localeMessageWrapper(BulkActionsDropdown)
