import classnames from "classnames"
import { ChevronDown, ChevronUp } from "react-feather"
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
  Button,
  FormGroup,
  Input
} from "reactstrap"

import { LANGUAGE_CONSTANTS } from '@shared/language-constants'
import { getLocaleMessage } from "@utils"
import { ErrorMessage, CustomLabel } from "@views/components"
import localeMessageWrapper from "@views/components/locale-message"

const AddPlanCard = ({
  intl,
  errors,
  isEditMode = false,
  isModal = false,
  isSearchMode = false,
  isShowPlanDescription = false,
  planDetail: {
    planName = "",
    planNumber = "",
    version = "",
    elevation = "",
    squareFootage = "",
    numberOfBedroom = "",
    stories = "",
    bathrooms = "",
    planDescription = ""
  },
  onAddPlanDetail,
  onChangePlanDetail,
  onTogglePlanDescription,
  onCloseModal = () => { },
  onImportPlan = () => { },
  onResetForm = () => { },
  onSearch = () => { },
  onCustomAdvanceSearch = () => { },
  onToggle = () => { }
}) => {

  const headerPart = () => {
    return (
      <div className="d-flex align-items-center w-100 justify-content-between">
        <div>
          <CardTitle tag="h4" className="mb-0">
            {isSearchMode ? getLocaleMessage(intl, LANGUAGE_CONSTANTS.ADVANCED_SEARCH) : getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.SITE_PLAN)}
          </CardTitle>
          <p>
            {!isSearchMode && getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.BUILDING_PLANS_TEXT)}
          </p>
        </div>
        {isSearchMode ? (
          <button
            type="button"
            className="waves-effect btn advanced-btn "
            onClick={onToggle}
          >
            {getLocaleMessage(intl, LANGUAGE_CONSTANTS.CLOSE)}
          </button>
        ) : (
          <Button
            outline
            color="primary"
            className="mr-1 outline-button"
            onClick={onImportPlan}
          >
            {getLocaleMessage(intl, LANGUAGE_CONSTANTS.IMPORT)}
          </Button>
        )}
      </div>
    )
  }

  const DetailCardFooter = () => {
    if (isSearchMode) {
      return null
    }

    return (
      <div className="text-right col-12">
        {isModal &&
          <Button
            outline
            onClick={onCloseModal}
            className={classnames("delete-button mr-1")}
          >
            {getLocaleMessage(intl, LANGUAGE_CONSTANTS.CANCEL)}
          </Button>
        }
        <Button
          className={classnames("common-button")}
          color="primary"
          onClick={onAddPlanDetail}
        >
          {isEditMode ? getLocaleMessage(intl, LANGUAGE_CONSTANTS.UPDATE) : getLocaleMessage(intl, LANGUAGE_CONSTANTS.ADD)}
        </Button>
      </div>
    )
  }

  const SearchCardFooter = () => {
    if (!isSearchMode) {
      return null
    }

    return (
      <div className="text-right col-12">
        <Button
          outline
          onClick={onResetForm}
          className={classnames("delete-button mr-1")}
        >
          {getLocaleMessage(intl, LANGUAGE_CONSTANTS.CLEAR)}
        </Button>
        <Button className="common-button" onClick={onSearch}>
          {getLocaleMessage(intl, LANGUAGE_CONSTANTS.SEARCH)}
        </Button>
      </div>
    )
  }

  const CardContent = () => {
    return (
      <>
        {isModal ? (
          headerPart()
        ) : (
          <CardHeader className="p-0">{headerPart()}</CardHeader>
        )}
        <Form>
          <Row form>
            <Col className="mb-1 plan-input-search" sm={12} md={6} xl={4}>
              <FormGroup>
                <CustomLabel title={LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.PLAN_NAME} required={!isSearchMode} />
                <Input
                  type="text"
                  placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.PLAN_NAME)}
                  name="plan_name"
                  value={planName}
                  onChange={(event) => onChangePlanDetail("planName", event.target.value)}
                  onKeyDown={onCustomAdvanceSearch}
                />
              </FormGroup>
              <ErrorMessage
                isShow={!!errors["planName"]}
                message={errors["planName"]}
              />
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={4}>
              <FormGroup>
                <CustomLabel title={LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.PLAN} />
                <Input
                  placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.PLAN)}
                  name="plan_number"
                  value={planNumber}
                  onChange={(event) => onChangePlanDetail("planNumber", event.target.value)}
                  onKeyDown={onCustomAdvanceSearch}
                />
              </FormGroup>
            </Col>
            <Col className="mb-1" sm={12} md={6} xl={4}>
              <FormGroup>
                <CustomLabel title={LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.VERSION} required={!isSearchMode} />
                <Input
                  type="text"
                  placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.VERSION)}
                  value={version}
                  onChange={(event) => onChangePlanDetail("version", event.target.value)}
                  onKeyDown={onCustomAdvanceSearch}
                />
              </FormGroup>
              <ErrorMessage
                isShow={!!errors["version"]}
                message={errors["version"]}
              />
            </Col>
          </Row>

          {isShowPlanDescription && (
            <>
              <div className='mb-1'>
                <button
                  type='button'
                  className='waves-effect btn advanced-btn more-less-btn'
                  onClick={onTogglePlanDescription}>
                  <ChevronUp />
                  {getLocaleMessage(intl, LANGUAGE_CONSTANTS.SHOW_LESS)}
                </button>
              </div>
              <h4 className='mb-2 mt-2 mx-1 font-weight-normal'>{getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.PLAN_DETAILS)}</h4>
              <Row form>
                <Col className='mb-1' sm={12} md={6} xl={4}>
                  <FormGroup>
                    <CustomLabel title={LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.STORIES} />
                    <Input
                      type='text'
                      placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.STORIES)}
                      name='stories'
                      value={stories}
                      onChange={(event) => onChangePlanDetail('stories', event.target.value)}
                      onKeyDown={onCustomAdvanceSearch}
                    />
                  </FormGroup>
                  <ErrorMessage
                    isShow={!!errors["stories"]}
                    message={errors["stories"]}
                  />
                </Col>
                <Col className='mb-1' sm={12} md={6} xl={4}>
                  <FormGroup>
                    <CustomLabel title={LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.BEDROOMS} />
                    <Input
                      type='text'
                      placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.BEDROOMS)}
                      name='numberOfBedroom'
                      value={numberOfBedroom}
                      onChange={(event) => onChangePlanDetail('numberOfBedroom', event.target.value)}
                      onKeyDown={onCustomAdvanceSearch}
                    />
                  </FormGroup>
                  <ErrorMessage
                    isShow={!!errors["numberOfBedroom"]}
                    message={errors["numberOfBedroom"]}
                  />
                </Col>
                <Col className='mb-1' sm={12} md={6} xl={4}>
                  <FormGroup>
                    <CustomLabel title={LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.BATHROOMS} />
                    <Input
                      type='text'
                      placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.BATHROOMS)}
                      name='bathrooms'
                      value={bathrooms}
                      onChange={(event) => onChangePlanDetail('bathrooms', event.target.value)}
                      onKeyDown={onCustomAdvanceSearch}
                    />
                  </FormGroup>
                  <ErrorMessage
                    isShow={!!errors["bathrooms"]}
                    message={errors["bathrooms"]}
                  />
                </Col>
                <Col className="mb-1" sm={12} md={6} xl={4}>
                  <FormGroup>
                    <CustomLabel title={LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.ELEVATION} />
                    <Input
                      type="text"
                      placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.ELEVATION)}
                      value={elevation}
                      onChange={(event) => onChangePlanDetail("elevation", event.target.value)}
                      onKeyDown={onCustomAdvanceSearch}
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-1" sm={12} md={6} xl={4}>
                  <FormGroup>
                    <CustomLabel title={LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.SQUARE_FOOTAGE} />
                    <Input
                      type="text"
                      placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.SQUARE_FOOTAGE)}
                      value={squareFootage}
                      onChange={(event) => onChangePlanDetail("squareFootage", event.target.value)}
                      onKeyDown={onCustomAdvanceSearch}
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-1 plan-input-search" sm={12} md={6} xl={4}>
                  <FormGroup>
                    <CustomLabel title={LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.PLAN_DESCRIPTION} />
                    <Input
                      type="text"
                      placeholder={getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.PLAN_DESCRIPTION)}
                      name="plan_description"
                      value={planDescription}
                      onChange={(event) => onChangePlanDetail("planDescription", event.target.value)}
                      onKeyDown={onCustomAdvanceSearch}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          {!isShowPlanDescription && (
            <button
              type='button'
              className='waves-effect btn advanced-btn more-less-btn'
              onClick={onTogglePlanDescription}>
              <ChevronDown />
              {getLocaleMessage(intl, LANGUAGE_CONSTANTS.SITE_PROJECT_PLANS.PLAN_DETAILS)}
            </button>
          )}
        </Form>
        {DetailCardFooter()}
        {SearchCardFooter()}
      </>
    )
  }

  if (isModal) {
    return CardContent()
  }

  return (
    <Card p="4">
      <CardBody className="">{CardContent()}</CardBody>
    </Card>
  )
}

export default localeMessageWrapper(AddPlanCard)
