import config from "./APIConfig"
import { postDataApi } from "../index"
/**
 * @method addProgramDetail
 * @param {object} data 
 *{
   {
  "programName": "string",
  "programTypeId": 0,
  "projectId": 0,
  "siteId": 0,
  "organizationId": 0,
  "safetyManagerId": 0,
  "superintendentId": 0,
  "programStatusId": 0,
  "programTagIds" :[]
}
 *
 */
export const addProgramDetail = (data) => {
  const { url } = config.addProgramDetail
  return postDataApi(url, data)
}

export default addProgramDetail