import config from "./APIConfig"
import { putDataApi } from "../../index"

/**
 * @method updateCampaignContractDetail
 * @param {object} data 
 * {
    "contractId": 0,
    "productTypeId": 1,
    "campaignId": 0,
    "wfCampaignSetupId": 0,
    "billingProductId": 0,
    "amount": 0
  }
 *
 */
export const updateCampaignContractDetail = (payload) => {
  const { url } = config.updateCampaignContractDetail

  return putDataApi(url(payload), payload)
}

export default updateCampaignContractDetail
