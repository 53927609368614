import React, { useCallback, useState } from "react"
import { Row, Col } from "reactstrap"

import { LANGUAGE_CONSTANTS } from "@shared/language-constants"
import { getLocaleMessage } from "@utils"
import { ImageFullViewModal } from "@views/components"

const {
  ACTIONS_PAGE,
  EDIT,
  DELETE
} = LANGUAGE_CONSTANTS

const AuditTrailItem = ({
  intl,
  data: { trailDetails, auditTrailId },
  isCurrentStatus,
  onToggleEditMode = () => { },
  onToggleDeleteModal = () => { }
}) => {

  const [isShowImageFullView, setShowImageFullView] = useState(false)
  const [selectedImageList, setSelectedImageList] = useState([])

  const handleToggleImageFullView = useCallback((images) => {
    setShowImageFullView(!isShowImageFullView)
    if (images) {
      setSelectedImageList(images)
    }
  }, [isShowImageFullView])

  return (<>
    {trailDetails.map((detail, index) => {
      const { files, note } = detail
      return <div className="audit-trail" key={`trail-item-${auditTrailId}-${index}`}>
        <Row>
          <Col xs={12} sm={4} md={4}>
            {!!files?.length && <>
              <img className="w-100" src={files[0].fileURL} alt="auditTrail"
                onClick={() => handleToggleImageFullView(files)} />
              <div className="multiple-imgs" onClick={() => handleToggleImageFullView(files)}>
                {files.length > 1 && <img className="w-100" src={files[1].fileURL} alt="auditTrail" />}
                {files.length > 2 && <span>+{files.length - 2}</span>}
              </div>
            </>}
          </Col>
          <Col xs={12} sm={8} md={8}>
            <div className="audit-trail-detail">
              <div className="d-flex justify-content-end align-items-center mb-1">
                <div className="d-flex">
                  {isCurrentStatus &&
                    <>
                      {note.canEdit && <button className="secondary-text-btn waves-effect btn" onClick={() => onToggleEditMode(detail, auditTrailId)}>{getLocaleMessage(intl, EDIT)}</button>}
                      {note.canDelete && <button className="secondary-text-btn waves-effect btn" onClick={() => onToggleDeleteModal(note.noteId)}>{getLocaleMessage(intl, DELETE)}</button>}
                    </>
                  }
                </div>
              </div>
              <h6 className="audit-trail-item"><span>{getLocaleMessage(intl, ACTIONS_PAGE.CREATED)}:</span>{note.createdTime} </h6>
              <h6 className="audit-trail-item"><span>{getLocaleMessage(intl, ACTIONS_PAGE.SUBMITTER)}:</span>{note.submitterName}</h6>
              {!!note.noteReasonName && <h6 className="audit-trail-item"><span>{getLocaleMessage(intl, ACTIONS_PAGE.REASON_FOR_NOT_READY)}</span>{note.noteReasonName || 'N/A'}</h6>}
              <p>{note.noteText}</p>
            </div>
          </Col>
        </Row>
      </div>
    })}
    <ImageFullViewModal
      isOpen={isShowImageFullView}
      images={selectedImageList}
      onToggle={handleToggleImageFullView}
    />
  </>)
}

export default AuditTrailItem
