import { getSearchQuery } from "../config"

const apiConfig = {
  getProgramList: {
    method: "GET",
    url: ({
      pageNumber,
      pageSize,
      genericSearch = "",
      advanceSearch = {},
      orderColumn = "",
      orderDirections = ""
    }) => {
      let searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          programName,
          programTypeId,
          programStatusId,
          projectId,
          siteId,
          programTagIds
        } = advanceSearch

        const TagIds = programTagIds.map((tag) => getSearchQuery("TagIds", tag.value)).join("")

        searchString = [
          searchString,
          getSearchQuery("ProgramName", programName, false, true),
          getSearchQuery("TypeId", programTypeId),
          getSearchQuery("StatusId", programStatusId),
          getSearchQuery("ProjectId", projectId),
          getSearchQuery("SiteId", siteId),
          TagIds
        ].join('')
      }

      return `api/Program${searchString}`
    }
  },
  getProgramListVM: {
    method: "GET",
    url: "api/Program/ProgramListVM"
  },
  getCreateProgramVM: {
    method: "GET",
    url: "api/Program/CreateProgramvm"
  },
  deleteProgram: {
    method: 'DELETE',
    url: ({ programId }) => `api/Program/${programId}`
  },
  getDetailsRequirementsVM: {
    method: "GET",
    url: (programTypeId) => `api/program/GetDetailRequirementsvm/${programTypeId}`
  },
  getProgramDetails: {
    method: "GET",
    url: ({ programId }) => `api/Program/${programId}`
  },
  getProgramSafetyRequirements: {
    method: "GET",
    url: (programId) => `api/Program/ProgramSafetyRequirments/${programId}`
  },
  addProgramSafetyRequirements: {
    method: "POST",
    url: (programId) => `api/Program/ProgramSafetyRequirments/${programId}`
  },
  updateProgramSafetyRequirements: {
    method: "PUT",
    url: (programId) => `api/Program/ProgramSafetyRequirments/${programId}`
  },
  getProgramBasicDetails: {
    method: "GET",
    url: ({ programId }) => `api/Program/programdetail/${programId}`
  },
  getProgramDetailList: {
    method: "GET",
    url: ({
      programId,
      pageNumber,
      pageSize,
      genericSearch = "",
      advanceSearch = {},
      orderColumn = "",
      orderDirections = ""
    }) => {
      let searchString = [
        getSearchQuery("ProgramId", programId, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("GenericSearch", genericSearch, false, true),
        getSearchQuery("OrderColumn", orderColumn),
        orderDirections ? `&OrderDirections=${orderDirections}` : ''
      ].join('')

      if (!!Object.keys(advanceSearch).length) {
        const {
          campaignName,
          projectId,
          campaignStatusId,
          campaignTypeId,
          campaignObjectiveId,
          sites = []
        } = advanceSearch

        const siteSearch = sites.map((site) => getSearchQuery("SiteIds", site.value)).join("")
        searchString = [
          searchString,
          getSearchQuery("CampaignName", campaignName, false, true),
          getSearchQuery("ProjectId", projectId),
          getSearchQuery("CampaignStatusId", campaignStatusId),
          getSearchQuery("CampaignTypeId", campaignTypeId),
          getSearchQuery("CampaignObjectiveId", campaignObjectiveId),
          siteSearch
        ].join('')
      }

      return `api/Program/programdetaillist/${programId}${searchString}`
    }
  },
  getProjectContacts: {
    method: 'GET',
    url: ({
      projectId,
      siteId,
      pageNumber,
      pageSize,
      contactName = ""
    }) => {
      const searchString = [
        getSearchQuery("ProjectId", projectId, true),
        getSearchQuery("SiteId", siteId),
        getSearchQuery("ContactName", contactName, false, true),
        getSearchQuery("PageNumber", pageNumber),
        getSearchQuery("PageSize", pageSize)
      ].join('')
      return `api/Program/getprojectcontacts/${projectId}/${siteId}${searchString}`
    }
  },
  addProgramDetail: {
    type: "POST",
    url: "api/Program"
  },
  updateProgramDetail: {
    type: "PUT",
    url: (programId) => `api/Program/${programId}`
  },
  getProgramDDL: {
    method: 'GET',
    url: ({ pageNumber, pageSize, programName = "", sites = [] }) => {
      const sitesList = sites.map((site) => getSearchQuery("SiteIds", site.value)).join("")
      const searchString = [
        getSearchQuery("PageNumber", pageNumber, true),
        getSearchQuery("PageSize", pageSize),
        getSearchQuery("ProgramName", programName, false, true),
        sitesList
      ].join('')
      return `api/Program/DDL/SearchProgram${searchString}`
    }
  },
  getCampaignObservationVM: {
    method: 'GET',
    url: (ProgramId) => `api/Program/ObservationCampaignVM/${ProgramId}`
  },
  getCampaignObservation: {
    method: 'GET',
    url: (ProgramId) => `api/Program/ObservationCampaign/${ProgramId}`
  },
  addObservationCampaign: {
    method: 'POST',
    url: (ProgramId) => `api/Program/ObservationCampaign/${ProgramId}`
  },
  getOshaTrainingSiteRole: {
    method: 'GET',
    url: (programId = 0) => `api/Program/OshaTrainingSiteRole/${programId}`
  },
  getOshaTrainingCoursesList: {
    method: 'GET',
    url: (programId = 0) => {
      const searchString = getSearchQuery("ProgramId", programId, true)
      return `api/Program/OshaTrainingCources/${programId}${searchString}`
    }
  },
  getOshaWrittenPrograms: {
    method: 'GET',
    url: ({ programId }) => {
      return `api/Program/OshaWrittenPrograms/${programId}`
    }
  },
  getOshaDocumentationCampaignVM: {
    method: 'GET',
    url: (programId = 0) => {
      const searchString = getSearchQuery("ProgramId", programId, true)
      return `api/Program/OshaDocumentationCampaignVM/${programId}${searchString}`
    }
  },
  updateObservationCampaign: {
    method: "PUT",
    url: (programId) => `api/Program/ObservationCampaign/${programId}`
  },
  cloneProgram: {
    method: 'POST',
    url: ({ programId }) => `api/Program/${programId}/clone`
  }
}

export default apiConfig
